import React, {useState, useRef} from 'react';
import styles from './Department.module.scss';
import axios from 'axios';

const DepartmentForm = ({department, index, openForm, refreshDepartment}) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [hintMsg, setHintMsg] = useState('')
  const curForm = useRef()
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const SendRequest = (ev) => {
      ev.preventDefault();
      
      const body = new FormData(curForm.current);
      // setHintMsg('... загрузка  ...')
      axios.post(`/persons/profile/${department}/update`, body, { headers: { 
        'Content-Type': 'multipart/form-data',
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          setHintMsg(response.data.message);
          refreshDepartment(index, response.data.updated);
          openForm(index, false);
        } else {
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        setHintMsg('');
        setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
      })
      // .finally(() => {  });
  }

  return (
      <li key={index}>
      <form ref={curForm} name="department" action={`/persons/profile/${department}/update`} acceptCharset="UTF-8" data-remote="true" method="post"  className={styles.PatientForm}>
        <div className="field-light">
          <label>Название</label>
          <input type="text"  name='department[new_name]' defaultValue={department} />
        </div>
        
        <p className='errors'>{errorMsg}</p>
        <p className='hint'>{hintMsg}</p>
        <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
        <button type="reset" className="btn-border" onClick={(ev)=> { openForm(index, false); }}><span>Не сохранять</span></button>
      </form>
    </li>
  );
};

export default DepartmentForm;