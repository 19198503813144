import React from 'react';
import styles from './Appliance.module.scss';
import Cogwheel from '../../../assets/images/cogwheel.svg';
import BatteryLevel from '../battery/BatteryLevel.jsx';

const Appliance = ({userId, appliance, index, openForm, deleteItem, permissions, batteryMin, isLast = false}) => {
  // sorry I'm in a hurry again
  const name = `${appliance.patient && appliance.patient.last_name && appliance.patient.last_name} ${appliance.patient && appliance.patient.first_name}`;
    return (
      <li key={index} className={isLast ? styles.AppliancesListItemNoBrd : styles.AppliancesListItem } >
        <span className={styles.ApplianceId}>
          <a href={`/private/${userId}/devices/${appliance.id}`}>{appliance.act}</a>
        </span>
        <span>{appliance && appliance.ward && appliance.ward.number}</span>
        <span>{name !== 'null null' ? name : '-'}</span>
        
        <span className="center-text">
          {/* <BatteryLevel val={appliance.charge_level} norms={parseInt(batteryMin)} isDark={true} showToolTipe={true} /> */}
        </span>
        <div>
          { permissions.delete ? <button className="btn-border btn-delete" onClick={(ev)=>{  ev.preventDefault(); deleteItem(index) }}> <span>Удалить</span> </button> : <></> }
          { permissions.update ? <button className="btn-gradient btn-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(index, true) }}>
            <img src={Cogwheel} alt="edit" />
            Редактировать
          </button> : <></> }
        </div>
      </li>
      
    );
};

export default Appliance;