import React, {useEffect} from 'react';

const inAllPage = ({callHelper}) => {
  useEffect(() => {
        let options = {}
        eval(callHelper)(options);
  }, []);
  
  return ( <></> );
};

export default inAllPage;