import React, { useState, useEffect } from 'react';
import styles from './Profile.module.scss';
import axios from 'axios';
import classNames from "classnames";


const ConfirmRoles = ({users, permission}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [unconfirmedUsers, setUnconfirmedUsers] = useState(users);
  // const [sexFilter, setSexFilter] = useState(2);
  const permissions = JSON.parse(permission);
  
  const role_option = [
    { label: "Администратор", value: "0" },
    { label: "Персонал", value: "1" },
    { label: "Техник", value: "2" }
  ]

  
  const refreshUsers = (id, text, flag) => {
    let confirmation = confirm(text);
    if (confirmation) {
      axios.post(`/persons/${id}/confirm_role`, { "allow": flag }, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
          setUnconfirmedUsers(unconfirmedUsers => {
            return unconfirmedUsers.filter(item => item.id !== id);
          });
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
        
    }
  }

//   
//   refreshUsers(user.id, `Уверены что хотите запретить ${user.name} быть ${role_option.filter((r) => { if (user.role.split(",").includes(r.value)) { return r} }).map((k) => k.label).join(',')}?`)
  const usersList = unconfirmedUsers.map((user, i) => {
    return <li key={i} className={styles.UnconfirmedUsersListItem}>
      <span>{user.name}</span>
      <span className="wdth-200">{role_option.filter((r) => { if (user.role.split(",").includes(r.value)) { return r} }).map((k) => k.label).join(',')}</span>
      <span className="btn btn-role"> {user.role_new ? role_option.filter((r) => { if (user.role_new.split(",").includes(r.value)) { return r} }).map((k) => k.label).join(', ') : 'new user' } </span>  
      
      <span>{user.post}</span>
      { permissions.update ? <button className="btn-border btn-allow" onClick={(ev)=>{ 
                    ev.preventDefault(); 
                    refreshUsers(user.id, `Уверены что хотите разрешить ${user.name} быть ${role_option.filter((r) => { if (user.role_new && user.role_new.split(",").includes(r.value) || (!user.confirmed_by_admin && user.role.split(",").includes(r.value)) ) { return r} }).map((k) => k.label).join(', ')}?`, "true") 
                  }
                }><span>Разрешить</span></button> : <></> }
      { permissions.update ? <button className="btn-gradient btn-disallow" onClick={
            (ev)=> { 
              ev.preventDefault(); 
              refreshUsers(user.id, `Уверены что хотите запретить ${user.name} быть ${role_option.filter((r) => { if (user.role_new && user.role_new.split(",").includes(r.value) || (!user.confirmed_by_admin && user.role.split(",").includes(r.value)) ) { return r} }).map((k) => k.label).join(', ')}?`, "false") 
            }
          }>Запретить</button> : <></> }
    </li>
  });
  
  const result = usersList && usersList.length > 0 ?  
    <>
      <h2 className="h2-title">Неподтвержденные пользователи</h2>
      <ul className={styles.UnconfirmedUsersList}>
        <li key={-1} className={classNames(styles.UnconfirmedUsersListItem, styles.UnconfirmedUsersListHeader)}>
          <span>ФИО</span>
          <span>роль была</span>
          <span>новая роль</span>
          <span>должность</span>
          <span></span>
          <span></span>
        </li>
        { usersList }
      </ul>
    </>
  : <></>;
    
  return ( <>{result}</> );

};

export default ConfirmRoles;
