import React, {useState, useCallback, useEffect} from 'react';
import styles from './Test.module.scss';
import axios from 'axios';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const Test = ({tokenPath, tokenParams, connectionPath, deviceId}) => {
  const [count, setCount] = useState(1);
  const [val, setVal] = useState('');
  const [testData, setTestData] = useState([]);
  const [testDataTemp, setTestDataTemp] = useState([]);
  const [testLabels, setTestLabels] = useState([]);
  const [battery, setBattery] = useState(80);

  const [jwtToken, setJWTToken] = useState('');

  connectionPath= "ws://demo.thingsboard.io/api/ws/plugins";
  tokenPath= "http://demo.thingsboard.io/api/auth/login";
  tokenParams= {"username":"palutova.elena@gmail.com", "password":"Qwerty)8"};

  // const entityId = deviceId;
  const entityId = "14a55740-0393-11ee-bc3e-11bdfd99be3f";
  const [socketUrl, setSocketUrl] = useState(`${connectionPath}/telemetry?token=${jwtToken}`);
  const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    onClose: (event) => {
      if (event.code===1006) {
        GetJWTTOken()
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  
  //
  const GetJWTTOken = (ev) => {
    axios.post(tokenPath, tokenParams, { headers: { 
      // 'Content-Type': 'multipart/form-data',
      // 'X-CSRF-Token': token
      'Accept': 'application/json'
    } },
    ).then(response => {
      if (response.status === 200) {
        setJWTToken(response.data.token);
        setSocketUrl(`${connectionPath}/telemetry?token=${jwtToken}`);
      } else {
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
        console.log(error);
    })
    .finally(() => { 
      // let d = new Date();
      // console.log(`finaly in ${d.toLocaleTimeString()}`);
      // setCount(count => {
      //   return count + 1;
      // });
    });
  }

  // grapfic
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  
 
  // const labels_pulse = info && info.pulse && info.pulse.map((p) => { const d = new Date(p.last_update*1000); return d.toLocaleTimeString() });
  const labels = testLabels;
  const data = {
    labels,
    datasets: [
      {
        label: 'Temperature',
        data: testDataTemp,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Rssi',
        data: testData,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    ],
  };
  // end graphic
  
  
  // for socket
  useEffect(() => {
    if (lastMessage !== null) {
      const result = JSON.parse(lastMessage.data)
      debugger
      if (result.data.status_battery_level) {
        setBattery(`${result.data.status_battery_level[0][1]} в ${new Date(result.data.status_battery_level[0][0]).toLocaleTimeString()} `);
      }
      const temperature_date = new Date(result.latestValues.data_temperature).toLocaleTimeString();
      const temperature = result.data && result.data.data_temperature ? { time: new Date(result.data.data_temperature[0][0]).toLocaleTimeString(), val: result.data.data_temperature[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      console.log(` ${temperature_date} в ${temperature.time} ${temperature.val}`);
      
      const rssi_date = new Date(result.latestValues.rssi).toLocaleTimeString();
      const rssi =  result.data && result.data.rssi ? { time: new Date(result.data.rssi[0][0]).toLocaleTimeString(), val: (-1)*result.data.rssi[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      console.log(` ${rssi_date} в ${rssi.time} ${rssi.val}`);
      console.log(" ____________________________________ ");

      // setVal(val=> { 
      //   return ` ${battery.val} в ${battery.time}`;
      // });
      
      setTestDataTemp(testDataTemp => {
        let v = [ ...testDataTemp, temperature.val];
        v.map(item => item.group).slice(-30);
        return v;
      });

      setTestData(testData => {
        let v = [ ...testData, rssi.val];
        v.map(item => item.group).slice(-30);
        return v;
      });

      setTestLabels(testLabels => {
        let l = [ ...testLabels, temperature.time];
        l.map(item => item.group).slice(-30);
        return l;
      });

      setMessageHistory((prev) => prev.concat(lastMessage));
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    console.log(' start useEffect only once !!!')
    if (readyState === ReadyState.CONNECTING) {
      getDataTelemetry();
    }
  }, []);

  const object = {
    tsSubCmds: [
      {
        entityType: "DEVICE",
        entityId: entityId,
        scope: "LATEST_TELEMETRY",
        cmdId: 10
      }
    ],
    historyCmds: [],
    attrSubCmds: []
  };
  const dataMsg = JSON.stringify(object);

  // const handleClickSendMessage = useCallback(() => sendMessage(dataMsg), []);

  // const handleClickSendMessage = useCallback(() => getDataTelemetry(), []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const getDataTelemetry = () => {
    console.log('start getDataTelemetry only once')
    sendMessage(dataMsg);
  }
  //


    return (
      <div>
        <div className={styles.Test}>{battery}</div>
        <Line options={options} data={data} />

        {/* <div className="socket-calback">
          <button
            onClick={handleClickSendMessage}
            disabled={readyState !== ReadyState.OPEN}
          >
            Click Me to get Graphic
          </button>
          <span>The WebSocket is currently {connectionStatus}</span>
          {lastMessage ? <span>Last message: {lastMessage.data}</span> : null}
          <ul>
            {messageHistory.map((message, idx) => (
              <span key={idx}>{message ? message.data : null}</span>
            ))}
          </ul>
        </div> */}
      </div>
      
    );
};

export default Test;