import React from 'react';
import styles from './Ward.module.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

const Graphic = ({info}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
      
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: '',
          },
        },
      };
      
      const labels_saturation = info && info.saturation && info.saturation.map((s) => { const d = new Date(s.last_update*1000); return d.toLocaleTimeString() });
      const labels = labels_saturation.slice(-30);
      
      // const labels_pulse = info && info.pulse && info.pulse.map((p) => { const d = new Date(p.last_update*1000); return d.toLocaleTimeString() });
     
      const data = {
        labels,
        datasets: [
          {
            label: 'Сатурация',
            data: info && info.saturation && info.saturation.map(s => s.mark).slice(-30),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          // {
          //   label: 'Сердечный ритм',
          //   data: info && info.heart_rate && info.heart_rate.map(s => s.mark),
          //   borderColor: 'rgb(53, 162, 235)',
          //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
          // },
          {
            label: 'Пульс',
            data: info && info.pulse && info.pulse.map(s => s.mark).slice(-30),
            borderColor: 'rgb(156, 204, 44)',
            backgroundColor: 'rgba(156, 204, 44, 0.5)',
          },
        ],
      };

    return (
        <Line options={options} data={data} />
    );
};

export default Graphic;