import React, {useRef, useState, useEffect} from 'react';
import DinamicGraphicPosition from './DinamicGraphPosition.jsx';
import styles from './Position.module.scss'; 

const MiniMapClear = ({ PositionSectors, wards, norms }) => {
  const sectors = PositionSectors;
  const mainSvg = useRef();
  
  return (
    <>
<svg ref={mainSvg} version="1.1" id="plan-main" className={'zoom-100'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 514.5"  xmlSpace="preserve">

<line className="st0" x1="152.6" y1="39.9" x2="127.2" y2="65.3"/>
<line className="st1" x1="150.1" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st1" x1="165.6" y1="54.2" x2="157.7" y2="54.2"/>
<polyline className="st1" points="150.8,37.5 150.8,52.2 141.4,52.2 141.4,37.5 150.8,37.5 141.4,52.2 "/>
<polyline className="st1" points="166.3,37.7 166.3,52.5 156.7,52.5 156.7,37.7 166.3,37.7 156.7,52.5 "/>
<line className="st1" x1="101.6" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st1" x1="86.2" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st1" x1="100.9" y1="37.5" x2="110.4" y2="52.2"/>
<polyline className="st1" points="99.5,47 99.5,51.6 100,51.6 100,47 99.5,47 "/>
<polyline className="st1" points="100.9,37.5 110.4,37.5 110.4,52.2 100.9,52.2 100.9,37.5 "/>
<line className="st1" x1="85.4" y1="37.7" x2="95" y2="52.5"/>
<polyline className="st1" points="84,47.2 84,51.9 84.5,51.9 84.5,47.2 84,47.2 "/>
<polyline className="st1" points="85.4,37.7 95,37.7 95,52.5 85.4,52.5 85.4,37.7 "/>
<line className="st1" x1="150.1" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st1" x1="165.6" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st1" x1="150.8" y1="37.5" x2="141.4" y2="52.2"/>
<polyline className="st1" points="152.3,47 152.3,51.6 151.8,51.6 151.8,47 152.3,47 "/>
<polyline className="st1" points="150.8,37.5 141.4,37.5 141.4,52.2 150.8,52.2 150.8,37.5 "/>
<line className="st1" x1="166.3" y1="37.7" x2="156.7" y2="52.5"/>
<polyline className="st1" points="167.8,47.2 167.8,51.9 167.3,51.9 167.3,47.2 167.8,47.2 "/>
<polyline className="st1" points="166.3,37.7 156.7,37.7 156.7,52.5 166.3,52.5 166.3,37.7 "/>
<line className="st1" x1="101.6" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st1" x1="86.2" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st1" x1="100.9" y1="37.5" x2="110.4" y2="52.2"/>
<polyline className="st1" points="99.5,47 99.5,51.6 100,51.6 100,47 99.5,47 "/>
<polyline className="st1" points="100.9,37.5 110.4,37.5 110.4,52.2 100.9,52.2 100.9,37.5 "/>
<line className="st1" x1="85.4" y1="37.7" x2="95" y2="52.5"/>
<polyline className="st1" points="84,47.2 84,51.9 84.5,51.9 84.5,47.2 84,47.2 "/>
<polyline className="st1" points="85.4,37.7 95,37.7 95,52.5 85.4,52.5 85.4,37.7 "/>
<line className="st1" x1="137.4" y1="61.5" x2="138.1" y2="61.5"/>
<polyline className="st1" points="138.1,54.8 138.1,62.2 137.4,62.2 137.4,54.8 "/>
<line className="st2" x1="137.8" y1="55.5" x2="137.8" y2="61.5"/>
<polyline className="st1" points="137.4,55.5 137.4,54.8 138.1,54.8 138.1,55.5 137.4,55.5 "/>
<line className="st1" x1="170" y1="66.2" x2="171.6" y2="66.2"/>
<line className="st1" x1="81.7" y1="66.2" x2="80.2" y2="66.2"/>
<line className="st2" x1="169" y1="54.2" x2="170" y2="54.2"/>
<line className="st2" x1="142.2" y1="54.8" x2="142.2" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="150.1" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="153.5" y2="54.8"/>
<line className="st2" x1="139.2" y1="54.8" x2="142.2" y2="54.8"/>
<line className="st2" x1="150.1" y1="54.2" x2="152.8" y2="54.2"/>
<line className="st2" x1="139.9" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st2" x1="157.7" y1="54.8" x2="157.7" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="165.6" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="169" y2="54.8"/>
<line className="st2" x1="154.8" y1="54.8" x2="157.7" y2="54.8"/>
<line className="st2" x1="165.6" y1="54.2" x2="168.2" y2="54.2"/>
<line className="st2" x1="155.4" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st2" x1="82.8" y1="54.2" x2="81.7" y2="54.2"/>
<line className="st2" x1="109.6" y1="54.8" x2="109.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="101.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="98.3" y2="54.8"/>
<line className="st2" x1="112.6" y1="54.8" x2="109.6" y2="54.8"/>
<line className="st2" x1="101.6" y1="54.2" x2="99" y2="54.2"/>
<line className="st2" x1="111.8" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st2" x1="94.1" y1="54.8" x2="94.1" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="86.2" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="82.8" y2="54.8"/>
<line className="st2" x1="97" y1="54.8" x2="94.1" y2="54.8"/>
<line className="st2" x1="86.2" y1="54.2" x2="83.5" y2="54.2"/>
<line className="st2" x1="96.4" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st2" x1="137.4" y1="54.8" x2="139.1" y2="54.8"/>
<line className="st2" x1="169" y1="54.2" x2="170" y2="54.2"/>
<line className="st2" x1="142.2" y1="54.8" x2="142.2" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="150.1" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="153.5" y2="54.8"/>
<line className="st2" x1="139.2" y1="54.8" x2="142.2" y2="54.8"/>
<line className="st2" x1="150.1" y1="54.2" x2="152.8" y2="54.2"/>
<line className="st2" x1="139.9" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st2" x1="157.7" y1="54.8" x2="157.7" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="165.6" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="169" y2="54.8"/>
<line className="st2" x1="154.8" y1="54.8" x2="157.7" y2="54.8"/>
<line className="st2" x1="165.6" y1="54.2" x2="168.2" y2="54.2"/>
<line className="st2" x1="155.4" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st2" x1="82.8" y1="54.2" x2="81.7" y2="54.2"/>
<line className="st2" x1="109.6" y1="54.8" x2="109.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="101.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="98.3" y2="54.8"/>
<line className="st2" x1="112.6" y1="54.8" x2="109.6" y2="54.8"/>
<line className="st2" x1="101.6" y1="54.2" x2="99" y2="54.2"/>
<line className="st2" x1="111.8" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st2" x1="94.1" y1="54.8" x2="94.1" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="86.2" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="82.8" y2="54.8"/>
<line className="st2" x1="97" y1="54.8" x2="94.1" y2="54.8"/>
<line className="st2" x1="86.2" y1="54.2" x2="83.5" y2="54.2"/>
<line className="st2" x1="96.4" y1="54.2" x2="94.1" y2="54.2"/>
<polyline className="st2" points="114.4,43.4 114.4,62.2 112.7,62.2 "/>
<line className="st2" x1="113.5" y1="62.2" x2="113.5" y2="43.4"/>
<line className="st2" x1="112.7" y1="62.2" x2="112.7" y2="43.4"/>
<polyline className="st3" points="111.4,110.9 112.7,110.9 112.7,117.2 111.4,110.9 "/>
<line className="st4" x1="101.8" y1="90.8" x2="76.3" y2="116.2"/>
<polyline className="st3" points="111.4,110.9 112.7,110.9 112.7,117.2 111.4,110.9 "/>
<line className="st0" x1="127.2" y1="116.2" x2="101.8" y2="141.6"/>
<polyline className="st3" points="111.4,110.9 112.7,110.9 112.7,117.2 111.4,110.9 "/>
<polyline className="st3" points="91.4,109.2 111.4,110.9 112.7,110.9 91.4,109.2 "/>
<line className="st4" x1="101.8" y1="90.8" x2="76.3" y2="116.2"/>
<polyline className="st3" points="91.4,109.2 111.4,110.9 112.7,110.9 91.4,109.2 "/>
<line className="st0" x1="127.2" y1="116.2" x2="101.8" y2="141.6"/>
<polyline className="st3" points="91.4,109.2 111.4,110.9 112.7,110.9 91.4,109.2 "/>
<polyline className="st3" points="91.4,109.2 112.7,109.2 112.7,110.9 91.4,109.2 "/>
<line className="st4" x1="101.8" y1="90.8" x2="76.3" y2="116.2"/>
<polyline className="st3" points="91.4,109.2 112.7,109.2 112.7,110.9 91.4,109.2 "/>
<line className="st0" x1="127.2" y1="116.2" x2="101.8" y2="141.6"/>
<polyline className="st3" points="91.4,109.2 112.7,109.2 112.7,110.9 91.4,109.2 "/>
<polyline className="st3" points="139.1,110.9 140.4,110.9 139.1,117.2 139.1,110.9 "/>
<line className="st4" x1="152.6" y1="90.8" x2="127.2" y2="116.2"/>
<polyline className="st3" points="139.1,110.9 140.4,110.9 139.1,117.2 139.1,110.9 "/>
<line className="st0" x1="178.1" y1="116.2" x2="152.6" y2="141.6"/>
<polyline className="st3" points="139.1,110.9 140.4,110.9 139.1,117.2 139.1,110.9 "/>
<polyline className="st3" points="139.1,109.2 139.1,110.9 140.4,110.9 139.1,109.2 "/>
<line className="st4" x1="152.6" y1="90.8" x2="127.2" y2="116.2"/>
<polyline className="st3" points="139.1,109.2 139.1,110.9 140.4,110.9 139.1,109.2 "/>
<line className="st0" x1="178.1" y1="116.2" x2="152.6" y2="141.6"/>
<polyline className="st3" points="139.1,109.2 139.1,110.9 140.4,110.9 139.1,109.2 "/>
<polyline className="st3" points="139.1,109.2 160.3,109.2 140.4,110.9 139.1,109.2 "/>
<line className="st4" x1="152.6" y1="90.8" x2="127.2" y2="116.2"/>
<polyline className="st3" points="139.1,109.2 160.3,109.2 140.4,110.9 139.1,109.2 "/>
<line className="st0" x1="178.1" y1="116.2" x2="152.6" y2="141.6"/>
<polyline className="st3" points="139.1,109.2 160.3,109.2 140.4,110.9 139.1,109.2 "/>
<line className="st1" x1="150.1" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st1" x1="165.6" y1="54.2" x2="157.7" y2="54.2"/>
<polyline className="st1" points="150.8,37.5 150.8,52.2 141.4,52.2 141.4,37.5 150.8,37.5 141.4,52.2 "/>
<polyline className="st1" points="166.3,37.7 166.3,52.5 156.7,52.5 156.7,37.7 166.3,37.7 156.7,52.5 "/>
<line className="st1" x1="101.6" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st1" x1="86.2" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st1" x1="100.9" y1="37.5" x2="110.4" y2="52.2"/>
<polyline className="st1" points="99.5,47 99.5,51.6 100,51.6 100,47 99.5,47 "/>
<polyline className="st1" points="100.9,37.5 110.4,37.5 110.4,52.2 100.9,52.2 100.9,37.5 "/>
<line className="st1" x1="85.4" y1="37.7" x2="95" y2="52.5"/>
<polyline className="st1" points="84,47.2 84,51.9 84.5,51.9 84.5,47.2 84,47.2 "/>
<polyline className="st1" points="85.4,37.7 95,37.7 95,52.5 85.4,52.5 85.4,37.7 "/>
<line className="st1" x1="171.6" y1="66.2" x2="171.6" y2="74.1"/>
<line className="st1" x1="170" y1="66.2" x2="170" y2="74.1"/>
<line className="st1" x1="171.6" y1="79.4" x2="171.6" y2="87.3"/>
<line className="st1" x1="170" y1="79.4" x2="170" y2="87.3"/>
<line className="st1" x1="148.8" y1="94.4" x2="142.2" y2="94.4"/>
<line className="st1" x1="163" y1="94.4" x2="156.4" y2="94.4"/>
<line className="st1" x1="149.5" y1="106.4" x2="141.4" y2="96.2"/>
<polyline className="st1" points="151,101.6 151,96.9 150.5,96.9 150.5,101.6 151,101.6 "/>
<polyline className="st1" points="149.5,106.4 141.4,106.4 141.4,96.2 149.5,96.2 149.5,106.4 "/>
<line className="st1" x1="163.7" y1="106.1" x2="155.4" y2="96"/>
<polyline className="st1" points="165.1,101.3 165.1,96.6 164.6,96.6 164.6,101.3 165.1,101.3 "/>
<polyline className="st1" points="163.7,106.1 155.4,106.1 155.4,96 163.7,96 163.7,106.1 "/>
<line className="st1" x1="150.1" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st1" x1="165.6" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st1" x1="150.8" y1="37.5" x2="141.4" y2="52.2"/>
<polyline className="st1" points="152.3,47 152.3,51.6 151.8,51.6 151.8,47 152.3,47 "/>
<polyline className="st1" points="150.8,37.5 141.4,37.5 141.4,52.2 150.8,52.2 150.8,37.5 "/>
<line className="st1" x1="166.3" y1="37.7" x2="156.7" y2="52.5"/>
<polyline className="st1" points="167.8,47.2 167.8,51.9 167.3,51.9 167.3,47.2 167.8,47.2 "/>
<polyline className="st1" points="166.3,37.7 156.7,37.7 156.7,52.5 166.3,52.5 166.3,37.7 "/>
<line className="st1" x1="101.6" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st1" x1="86.2" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st1" x1="100.9" y1="37.5" x2="110.4" y2="52.2"/>
<polyline className="st1" points="99.5,47 99.5,51.6 100,51.6 100,47 99.5,47 "/>
<polyline className="st1" points="100.9,37.5 110.4,37.5 110.4,52.2 100.9,52.2 100.9,37.5 "/>
<line className="st1" x1="85.4" y1="37.7" x2="95" y2="52.5"/>
<polyline className="st1" points="84,47.2 84,51.9 84.5,51.9 84.5,47.2 84,47.2 "/>
<polyline className="st1" points="85.4,37.7 95,37.7 95,52.5 85.4,52.5 85.4,37.7 "/>
<path className="st5" d="M139,124.5c3.2,0,5.8-2.5,5.9-5.7"/>
<polyline className="st5" points="145,118.8 145,118.6 139.1,118.6 139.1,118.8 145,118.8 "/>
<path className="st5" d="M106.7,118.8c0.1,3.2,2.8,5.7,5.9,5.7"/>
<polyline className="st5" points="106.8,118.8 106.8,118.6 112.7,118.6 112.7,118.8 106.8,118.8 "/>
<path className="st5" d="M130.6,131.3c-2.6,0.1-4.7,2.3-4.7,5"/>
<line className="st5" x1="130.9" y1="136.4" x2="130.9" y2="131.3"/>
<polyline className="st5" points="130.9,136.4 130.7,136.4 130.7,131.3 130.9,131.3 "/>
<path className="st5" d="M125.8,136.3c0-2.6-2.1-4.8-4.7-5"/>
<line className="st5" x1="120.8" y1="136.4" x2="120.8" y2="131.3"/>
<polyline className="st5" points="120.8,136.4 121.1,136.4 121.1,131.3 120.8,131.3 "/>
<line className="st6" x1="121" y1="136.4" x2="121" y2="131.3"/>
<line className="st6" x1="130.8" y1="136.4" x2="130.8" y2="131.3"/>
<line className="st1" x1="120.2" y1="137" x2="120.2" y2="136.4"/>
<line className="st1" x1="131.5" y1="136.4" x2="131.5" y2="137"/>
<polyline className="st1" points="136.8,137 130.9,137 130.9,136.4 136.8,136.4 "/>
<line className="st2" x1="136.8" y1="136.6" x2="131.5" y2="136.6"/>
<polyline className="st1" points="120.8,137 114.4,137 114.4,136.4 120.8,136.4 "/>
<polyline className="st1" points="120.2,136.4 120.8,136.4 120.8,137 120.2,137 120.2,136.4 "/>
<line className="st2" x1="120.2" y1="136.6" x2="115" y2="136.6"/>
<polyline className="st1" points="136.8,136.4 137.4,136.4 137.4,137 136.8,137 136.8,136.4 "/>
<line className="st1" x1="115" y1="136.4" x2="115" y2="137"/>
<path className="st5" d="M132.4,62.4c0.1,2.6,2.3,4.7,5,4.7"/>
<line className="st5" x1="137.4" y1="62.2" x2="132.4" y2="62.2"/>
<polyline className="st5" points="137.4,62.2 137.4,62.4 132.4,62.4 132.4,62.2 "/>
<path className="st5" d="M137.3,67.1c-2.6,0-4.8,2.1-5,4.7"/>
<line className="st5" x1="137.4" y1="72.2" x2="132.4" y2="72.2"/>
<polyline className="st5" points="137.4,72.2 137.4,71.9 132.4,71.9 132.4,72.2 "/>
<line className="st6" x1="137.4" y1="72" x2="132.4" y2="72"/>
<line className="st6" x1="137.4" y1="62.3" x2="132.4" y2="62.3"/>
<line className="st1" x1="137.4" y1="93" x2="138.1" y2="93"/>
<polyline className="st1" points="138.1,72.9 138.1,93.8 137.4,93.8 137.4,72.9 "/>
<line className="st2" x1="137.8" y1="72.9" x2="137.8" y2="93"/>
<polyline className="st1" points="137.4,72.9 137.4,72.2 138.1,72.2 138.1,72.9 137.4,72.9 "/>
<line className="st1" x1="137.4" y1="61.5" x2="138.1" y2="61.5"/>
<polyline className="st1" points="138.1,54.8 138.1,62.2 137.4,62.2 137.4,54.8 "/>
<line className="st2" x1="137.8" y1="55.5" x2="137.8" y2="61.5"/>
<polyline className="st1" points="137.4,55.5 137.4,54.8 138.1,54.8 138.1,55.5 137.4,55.5 "/>
<path className="st5" d="M114.3,67.1c2.6,0,4.8-2.1,5-4.7"/>
<line className="st5" x1="114.4" y1="62.2" x2="119.4" y2="62.2"/>
<polyline className="st5" points="114.4,62.2 114.4,62.4 119.4,62.4 119.4,62.2 "/>
<path className="st5" d="M119.3,71.8c-0.1-2.6-2.3-4.7-5-4.7"/>
<line className="st5" x1="114.4" y1="72.2" x2="119.4" y2="72.2"/>
<polyline className="st5" points="114.4,72.2 114.4,71.9 119.4,71.9 119.4,72.2 "/>
<line className="st6" x1="114.4" y1="72" x2="119.4" y2="72"/>
<line className="st6" x1="114.4" y1="62.3" x2="119.4" y2="62.3"/>
<line className="st5" x1="113.6" y1="76.4" x2="113.6" y2="76.1"/>
<line className="st5" x1="105.7" y1="76.1" x2="105.7" y2="76.4"/>
<line className="st5" x1="113.6" y1="76.1" x2="111.8" y2="76.1"/>
<line className="st5" x1="113.6" y1="76.4" x2="111.8" y2="76.4"/>
<path className="st5" d="M105.7,76.3c0.1,4.2,3.6,7.6,7.9,7.6"/>
<line className="st5" x1="111.8" y1="76.4" x2="111.8" y2="76.1"/>
<polyline className="st5" points="105.7,76.4 107.5,76.4 107.5,76.1 105.7,76.1 "/>
<line className="st5" x1="111.8" y1="76.1" x2="107.5" y2="76.1"/>
<line className="st6" x1="107.5" y1="76.2" x2="111.8" y2="76.2"/>
<line className="st5" x1="111.8" y1="76.4" x2="107.5" y2="76.4"/>
<polyline className="st1" points="91.4,109.2 111.4,110.9 112.7,117.2 "/>
<polyline className="st1" points="160.3,109.2 140.4,110.9 139.1,117.2 "/>
<line className="st1" x1="80.2" y1="66.2" x2="80.2" y2="74.1"/>
<line className="st1" x1="81.7" y1="66.2" x2="81.7" y2="74.1"/>
<line className="st1" x1="80.2" y1="79.4" x2="80.2" y2="87.3"/>
<line className="st1" x1="81.7" y1="79.4" x2="81.7" y2="87.3"/>
<polyline className="st1" points="112.7,109.2 91.4,109.2 111.4,110.9 112.7,117.2 112.7,109.2 "/>
<polyline className="st1" points="160.3,109.2 139.1,109.2 139.1,117.2 140.4,110.9 160.3,109.2 "/>
<line className="st1" x1="91.2" y1="75.4" x2="91.2" y2="85"/>
<line className="st1" x1="93.2" y1="75.4" x2="93.2" y2="85"/>
<line className="st1" x1="95.2" y1="75.4" x2="95.2" y2="85"/>
<line className="st1" x1="97.2" y1="75.4" x2="97.2" y2="85"/>
<line className="st1" x1="91.2" y1="108.4" x2="91.2" y2="95.6"/>
<line className="st1" x1="93.2" y1="108.4" x2="93.2" y2="98.8"/>
<line className="st1" x1="95.2" y1="108.4" x2="95.2" y2="98.8"/>
<line className="st1" x1="97.2" y1="108.4" x2="97.2" y2="98.8"/>
<polyline className="st1" points="91.2,85 97.2,85 97.2,98.8 91.2,98.8 91.2,85 "/>
<line className="st1" x1="97.2" y1="85" x2="106.8" y2="85"/>
<line className="st1" x1="97.2" y1="90.9" x2="106.8" y2="90.9"/>
<line className="st1" x1="97.2" y1="92.9" x2="106.8" y2="92.9"/>
<line className="st1" x1="97.2" y1="94.8" x2="106.8" y2="94.8"/>
<line className="st1" x1="97.2" y1="96.9" x2="106.8" y2="96.9"/>
<line className="st1" x1="97.2" y1="98.8" x2="106.8" y2="98.8"/>
<line className="st1" x1="91.2" y1="86.9" x2="81.7" y2="86.9"/>
<line className="st1" x1="91.2" y1="90.9" x2="81.7" y2="90.9"/>
<line className="st1" x1="91.2" y1="92.9" x2="81.7" y2="92.9"/>
<line className="st1" x1="91.2" y1="94.8" x2="81.7" y2="94.8"/>
<line className="st1" x1="91.2" y1="96.9" x2="81.7" y2="96.9"/>
<line className="st1" x1="91.2" y1="98.8" x2="81.7" y2="98.8"/>
<polyline className="st1" points="86.2,79.8 86.2,104 102.2,104 102.2,85 "/>
<polyline className="st1" points="95.6,81.3 97.2,79.8 95.6,78.4 95.6,81.3 "/>
<line className="st1" x1="97.2" y1="89" x2="106.8" y2="89"/>
<line className="st1" x1="91.2" y1="89" x2="81.7" y2="89"/>
<line className="st1" x1="95.6" y1="79.8" x2="86.2" y2="79.8"/>
<polyline className="st1" points="97.8,85 97.8,99.5 90.6,99.5 90.6,84.3 106.8,84.3 "/>
<line className="st1" x1="91.2" y1="85" x2="81.7" y2="85"/>
<line className="st1" x1="97.2" y1="86.9" x2="106.8" y2="86.9"/>
<line className="st1" x1="106.8" y1="84.6" x2="106.8" y2="84.3"/>
<line className="st1" x1="171.6" y1="74.1" x2="171.6" y2="79.4"/>
<line className="st1" x1="80.2" y1="87.3" x2="80.2" y2="109.1"/>
<line className="st1" x1="80.2" y1="74.1" x2="80.2" y2="79.4"/>
<line className="st1" x1="170" y1="79.4" x2="171.6" y2="79.4"/>
<line className="st1" x1="170" y1="87.3" x2="171.6" y2="87.3"/>
<line className="st1" x1="170" y1="66.2" x2="171.6" y2="66.2"/>
<line className="st1" x1="170" y1="74.1" x2="171.6" y2="74.1"/>
<polyline className="st1" points="81.8,119 80.8,117.9 84.8,113.8 80.2,109.1 "/>
<polyline className="st2" points="113.6,75.4 113.6,76.1 114.4,76.1 114.4,75.4 113.6,75.4 "/>
<line className="st1" x1="171.6" y1="109.1" x2="171.6" y2="87.3"/>
<line className="st1" x1="81.7" y1="79.4" x2="80.2" y2="79.4"/>
<line className="st1" x1="81.7" y1="87.3" x2="80.2" y2="87.3"/>
<line className="st1" x1="81.7" y1="66.2" x2="80.2" y2="66.2"/>
<line className="st1" x1="81.7" y1="74.1" x2="80.2" y2="74.1"/>
<line className="st2" x1="169" y1="54.2" x2="170" y2="54.2"/>
<line className="st2" x1="142.2" y1="54.8" x2="142.2" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="150.1" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="153.5" y2="54.8"/>
<line className="st2" x1="139.2" y1="54.8" x2="142.2" y2="54.8"/>
<line className="st2" x1="150.1" y1="54.2" x2="152.8" y2="54.2"/>
<line className="st2" x1="139.9" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st2" x1="157.7" y1="54.8" x2="157.7" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="165.6" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="169" y2="54.8"/>
<line className="st2" x1="154.8" y1="54.8" x2="157.7" y2="54.8"/>
<line className="st2" x1="165.6" y1="54.2" x2="168.2" y2="54.2"/>
<line className="st2" x1="155.4" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st2" x1="82.8" y1="54.2" x2="81.7" y2="54.2"/>
<line className="st2" x1="109.6" y1="54.8" x2="109.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="101.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="98.3" y2="54.8"/>
<line className="st2" x1="112.6" y1="54.8" x2="109.6" y2="54.8"/>
<line className="st2" x1="101.6" y1="54.2" x2="99" y2="54.2"/>
<line className="st2" x1="111.8" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st2" x1="94.1" y1="54.8" x2="94.1" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="86.2" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="82.8" y2="54.8"/>
<line className="st2" x1="97" y1="54.8" x2="94.1" y2="54.8"/>
<line className="st2" x1="86.2" y1="54.2" x2="83.5" y2="54.2"/>
<line className="st2" x1="96.4" y1="54.2" x2="94.1" y2="54.2"/>
<line className="st2" x1="170" y1="87.3" x2="170" y2="108.5"/>
<line className="st2" x1="170" y1="74.1" x2="170" y2="79.4"/>
<line className="st2" x1="170.9" y1="74.1" x2="170.9" y2="79.4"/>
<line className="st2" x1="170.9" y1="87.3" x2="170.9" y2="108.9"/>
<line className="st2" x1="81.7" y1="74.1" x2="81.7" y2="79.4"/>
<line className="st2" x1="80.9" y1="74.1" x2="80.9" y2="79.4"/>
<line className="st2" x1="80.9" y1="87.3" x2="80.9" y2="108.9"/>
<line className="st2" x1="81.7" y1="87.3" x2="81.7" y2="108.5"/>
<line className="st2" x1="166.3" y1="94.4" x2="170" y2="94.4"/>
<polyline className="st2" points="166.3,108.3 166.3,93.8 170,93.8 "/>
<polyline className="st2" points="152.2,93.8 152.2,108.3 139.2,108.3 "/>
<line className="st2" x1="166.3" y1="108.3" x2="153.5" y2="108.3"/>
<line className="st2" x1="142.2" y1="93.8" x2="142.2" y2="94.4"/>
<line className="st2" x1="148.8" y1="93.8" x2="148.8" y2="94.4"/>
<line className="st2" x1="139.2" y1="93.8" x2="139.2" y2="108.3"/>
<line className="st2" x1="139.9" y1="94.4" x2="139.9" y2="107.6"/>
<line className="st2" x1="151.4" y1="94.4" x2="151.4" y2="107.6"/>
<line className="st2" x1="148.8" y1="93.8" x2="152.2" y2="93.8"/>
<line className="st2" x1="139.2" y1="93.8" x2="142.2" y2="93.8"/>
<line className="st2" x1="148.8" y1="94.4" x2="151.4" y2="94.4"/>
<line className="st2" x1="139.9" y1="94.4" x2="142.2" y2="94.4"/>
<line className="st2" x1="139.9" y1="107.6" x2="151.4" y2="107.6"/>
<line className="st2" x1="153.5" y1="93.8" x2="153.5" y2="108.3"/>
<line className="st2" x1="154.1" y1="94.4" x2="154.1" y2="107.6"/>
<line className="st2" x1="156.4" y1="93.8" x2="156.4" y2="94.4"/>
<line className="st2" x1="163" y1="93.8" x2="163" y2="94.4"/>
<line className="st2" x1="165.6" y1="94.4" x2="165.6" y2="107.6"/>
<line className="st2" x1="163" y1="93.8" x2="166.3" y2="93.8"/>
<line className="st2" x1="153.5" y1="93.8" x2="156.4" y2="93.8"/>
<line className="st2" x1="163" y1="94.4" x2="165.6" y2="94.4"/>
<line className="st2" x1="154.1" y1="94.4" x2="156.4" y2="94.4"/>
<line className="st2" x1="154.1" y1="107.6" x2="165.6" y2="107.6"/>
<line className="st2" x1="137.4" y1="93.8" x2="139.1" y2="93.8"/>
<line className="st2" x1="137.4" y1="54.8" x2="139.1" y2="54.8"/>
<line className="st2" x1="169" y1="54.2" x2="170" y2="54.2"/>
<line className="st2" x1="142.2" y1="54.8" x2="142.2" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="150.1" y2="54.2"/>
<line className="st2" x1="150.1" y1="54.8" x2="153.5" y2="54.8"/>
<line className="st2" x1="139.2" y1="54.8" x2="142.2" y2="54.8"/>
<line className="st2" x1="150.1" y1="54.2" x2="152.8" y2="54.2"/>
<line className="st2" x1="139.9" y1="54.2" x2="142.2" y2="54.2"/>
<line className="st2" x1="157.7" y1="54.8" x2="157.7" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="165.6" y2="54.2"/>
<line className="st2" x1="165.6" y1="54.8" x2="169" y2="54.8"/>
<line className="st2" x1="154.8" y1="54.8" x2="157.7" y2="54.8"/>
<line className="st2" x1="165.6" y1="54.2" x2="168.2" y2="54.2"/>
<line className="st2" x1="155.4" y1="54.2" x2="157.7" y2="54.2"/>
<line className="st2" x1="82.8" y1="54.2" x2="81.7" y2="54.2"/>
<line className="st2" x1="109.6" y1="54.8" x2="109.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="101.6" y2="54.2"/>
<line className="st2" x1="101.6" y1="54.8" x2="98.3" y2="54.8"/>
<line className="st2" x1="112.6" y1="54.8" x2="109.6" y2="54.8"/>
<line className="st2" x1="101.6" y1="54.2" x2="99" y2="54.2"/>
<line className="st2" x1="111.8" y1="54.2" x2="109.6" y2="54.2"/>
<line className="st2" x1="94.1" y1="54.8" x2="94.1" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="86.2" y2="54.2"/>
<line className="st2" x1="86.2" y1="54.8" x2="82.8" y2="54.8"/>
<line className="st2" x1="97" y1="54.8" x2="94.1" y2="54.8"/>
<line className="st2" x1="86.2" y1="54.2" x2="83.5" y2="54.2"/>
<line className="st2" x1="96.4" y1="54.2" x2="94.1" y2="54.2"/>
<polyline className="st2" points="114.4,118.6 114.4,84.6 112.7,84.6 "/>
<line className="st2" x1="113.5" y1="118" x2="113.5" y2="108.9"/>
<line className="st2" x1="113.5" y1="118.6" x2="113.5" y2="84.6"/>
<line className="st2" x1="112.7" y1="118.6" x2="112.7" y2="84.6"/>
<line className="st2" x1="138.2" y1="108.9" x2="138.2" y2="95.3"/>
<line className="st2" x1="138.2" y1="118.6" x2="138.2" y2="93.8"/>
<line className="st2" x1="139.1" y1="118.6" x2="139.1" y2="93.8"/>
<line className="st2" x1="137.4" y1="118.6" x2="137.4" y2="93.8"/>
<line className="st2" x1="139.1" y1="108.4" x2="170" y2="108.4"/>
<line className="st2" x1="139.1" y1="109.2" x2="160.3" y2="109.2"/>
<line className="st2" x1="112.7" y1="109.2" x2="91.4" y2="109.2"/>
<line className="st2" x1="112.7" y1="108.4" x2="99.8" y2="108.4"/>
<polyline className="st2" points="86.2,108.4 99,108.4 81.7,108.4 "/>
<polyline className="st2" points="99.8,108.4 112.7,108.4 86.2,108.4 99,108.4 86.2,108.4 112.7,108.4 99.8,108.4 112.7,108.4 
	99.8,108.4 "/>
<polyline className="st2" points="86.2,108.4 99,108.4 86.2,108.4 "/>
<line className="st2" x1="112.7" y1="108.4" x2="99.8" y2="108.4"/>
<polyline className="st2" points="169.9,119 171,117.9 166.9,113.8 "/>
<line className="st2" x1="114.4" y1="124.6" x2="112.7" y2="124.6"/>
<line className="st2" x1="114.4" y1="118.6" x2="112.7" y2="118.6"/>
<line className="st2" x1="137.4" y1="124.6" x2="139.1" y2="124.6"/>
<line className="st2" x1="137.4" y1="118.6" x2="139.1" y2="118.6"/>
<line className="st2" x1="139.6" y1="139.1" x2="170" y2="108.5"/>
<line className="st2" x1="140.8" y1="140.3" x2="166" y2="115"/>
<line className="st2" x1="171.6" y1="109.1" x2="166.9" y2="113.8"/>
<line className="st2" x1="166" y1="113.8" x2="170.5" y2="118.4"/>
<line className="st2" x1="165.5" y1="114.4" x2="169.9" y2="119"/>
<line className="st2" x1="140.8" y1="140.3" x2="137.4" y2="137"/>
<line className="st2" x1="139.1" y1="137.4" x2="140.2" y2="138.5"/>
<line className="st2" x1="85.8" y1="113.8" x2="81.2" y2="118.4"/>
<line className="st2" x1="85.8" y1="115" x2="81.8" y2="119"/>
<line className="st2" x1="112.7" y1="137.4" x2="111.6" y2="138.5"/>
<line className="st2" x1="112.2" y1="139.1" x2="81.7" y2="108.5"/>
<line className="st2" x1="111.1" y1="140.3" x2="85.8" y2="115"/>
<line className="st2" x1="111.6" y1="139.7" x2="80.9" y2="108.9"/>
<line className="st2" x1="137.4" y1="137" x2="137.4" y2="124.6"/>
<polyline className="st2" points="138.2,124.6 138.2,137.8 138.2,124.6 "/>
<line className="st2" x1="139.1" y1="138.6" x2="139.1" y2="124.6"/>
<line className="st2" x1="140.2" y1="139.7" x2="170.9" y2="108.9"/>
<line className="st2" x1="114.4" y1="137" x2="114.4" y2="124.6"/>
<polyline className="st2" points="113.5,124.6 113.5,137.8 113.5,124.6 "/>
<line className="st2" x1="112.7" y1="138.6" x2="112.7" y2="124.6"/>
<line className="st2" x1="111.1" y1="140.3" x2="114.4" y2="137"/>
<line className="st2" x1="112.7" y1="75.4" x2="112.7" y2="72.2"/>
<line className="st2" x1="113.5" y1="75.4" x2="113.5" y2="72.2"/>
<line className="st2" x1="114.4" y1="75.4" x2="114.4" y2="72.2"/>
<line className="st2" x1="114.4" y1="62.2" x2="112.7" y2="62.2"/>
<line className="st2" x1="114.4" y1="72.2" x2="112.7" y2="72.2"/>
<line className="st2" x1="114.4" y1="75.4" x2="112.7" y2="75.4"/>
<line className="st2" x1="114.4" y1="62.2" x2="114.4" y2="43.4"/>
<line className="st2" x1="113.5" y1="62.2" x2="113.5" y2="43.4"/>
<line className="st2" x1="112.7" y1="62.2" x2="112.7" y2="43.4"/>
<line className="st2" x1="81.7" y1="75.4" x2="112.7" y2="75.4"/>
<line className="st2" x1="81.7" y1="74.6" x2="112.7" y2="74.6"/>
<line className="st2" x1="90.2" y1="117.2" x2="112.7" y2="117.2"/>
<line className="st2" x1="91.1" y1="118" x2="112.7" y2="118"/>
<line className="st2" x1="161.5" y1="117.2" x2="139.1" y2="117.2"/>
<line className="st2" x1="160.7" y1="118" x2="139.1" y2="118"/>
<line className="st2" x1="86.3" y1="113.2" x2="90.4" y2="109.2"/>
<line className="st2" x1="86.9" y1="113.8" x2="91.4" y2="109.2"/>
<line className="st2" x1="90.4" y1="109.2" x2="82.3" y2="109.2"/>
<line className="st2" x1="165.5" y1="113.2" x2="161.4" y2="109.2"/>
<line className="st2" x1="164.9" y1="113.8" x2="160.3" y2="109.2"/>
<line className="st2" x1="161.4" y1="109.2" x2="169.4" y2="109.2"/>
<polyline className="st2" points="113.6,84.6 113.6,84 114.4,84 114.4,84.6 113.6,84.6 "/>
<line className="st2" x1="106.8" y1="84.6" x2="112.7" y2="84.6"/>
<line className="st2" x1="107.4" y1="85.4" x2="112.7" y2="85.4"/>
<line className="st2" x1="106.8" y1="84.6" x2="106.8" y2="108.4"/>
<line className="st2" x1="107.4" y1="85.4" x2="107.4" y2="108.4"/>
<line className="st7" x1="364.4" y1="387.6" x2="363.6" y2="386.7"/>
<line className="st7" x1="365" y1="387" x2="366" y2="388"/>
<line className="st7" x1="364.4" y1="387.6" x2="363.6" y2="388.6"/>
<line className="st7" x1="365" y1="387" x2="366" y2="386.1"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 364.3202 386.3163)" className="st8 st9 st10">125</text>
<line className="st7" x1="364.4" y1="387.6" x2="365" y2="387"/>
<polyline className="st3" points="364.4,388.6 364.6,388.6 364.4,386.7 364.4,388.6 "/>
<line className="st4" x1="373.8" y1="354" x2="337.8" y2="354"/>
<polyline className="st3" points="364.4,388.6 364.6,388.6 364.4,386.7 364.4,388.6 "/>
<line className="st11" x1="373.8" y1="390" x2="337.8" y2="390"/>
<polyline className="st3" points="364.4,388.6 364.6,388.6 364.4,386.7 364.4,388.6 "/>
<line className="st11" x1="373.8" y1="354" x2="337.8" y2="354"/>
<line className="st11" x1="373.8" y1="390" x2="337.8" y2="390"/>
<polyline className="st3" points="364.6,388.6 364.4,386.7 364.6,386.7 364.6,388.6 "/>
<polyline className="st3" points="365.2,386.1 364.9,386.1 365.2,388 365.2,386.1 "/>
<line className="st4" x1="373.8" y1="354" x2="337.8" y2="354"/>
<polyline className="st3" points="365.2,386.1 364.9,386.1 365.2,388 365.2,386.1 "/>
<line className="st11" x1="373.8" y1="390" x2="337.8" y2="390"/>
<polyline className="st3" points="365.2,386.1 364.9,386.1 365.2,388 365.2,386.1 "/>
<line className="st11" x1="373.8" y1="354" x2="337.8" y2="354"/>
<line className="st11" x1="373.8" y1="390" x2="337.8" y2="390"/>
<polyline className="st3" points="364.9,386.1 365.2,388 364.9,388 364.9,386.1 "/>
<polyline className="st12" points="170.5,66.2 170.5,74.1 170.9,74.1 "/>
<line className="st12" x1="170.5" y1="73.6" x2="170.9" y2="73.6"/>
<polyline className="st12" points="170.9,74.1 170.9,66.2 170.5,66.2 "/>
<line className="st12" x1="170.5" y1="66.6" x2="170.9" y2="66.6"/>
<line className="st6" x1="170.8" y1="73.6" x2="170.8" y2="66.6"/>
<polyline className="st12" points="170.5,79.4 170.5,87.3 170.9,87.3 "/>
<line className="st12" x1="170.5" y1="86.8" x2="170.9" y2="86.8"/>
<polyline className="st12" points="170.9,87.3 170.9,79.4 170.5,79.4 "/>
<line className="st12" x1="170.5" y1="79.8" x2="170.9" y2="79.8"/>
<line className="st6" x1="170.8" y1="86.8" x2="170.8" y2="79.8"/>
<polyline className="st12" points="80.9,79.4 80.9,87.3 81.2,87.3 "/>
<line className="st12" x1="80.9" y1="86.8" x2="81.2" y2="86.8"/>
<polyline className="st12" points="81.2,87.3 81.2,79.4 80.9,79.4 "/>
<line className="st12" x1="80.9" y1="79.8" x2="81.2" y2="79.8"/>
<line className="st6" x1="81" y1="86.8" x2="81" y2="79.8"/>
<polyline className="st12" points="80.9,66.2 80.9,74.1 81.2,74.1 "/>
<line className="st12" x1="80.9" y1="73.6" x2="81.2" y2="73.6"/>
<polyline className="st12" points="81.2,74.1 81.2,66.2 80.9,66.2 "/>
<line className="st12" x1="80.9" y1="66.6" x2="81.2" y2="66.6"/>
<line className="st6" x1="81" y1="73.6" x2="81" y2="66.6"/>
<polyline className="st3" points="79.2,74.1 79.9,74.1 79.9,66.2 79.2,74.1 "/>
<line className="st4" x1="101.8" y1="39.9" x2="50.9" y2="90.8"/>
<polyline className="st3" points="79.2,74.1 79.9,74.1 79.9,66.2 79.2,74.1 "/>
<line className="st13" x1="127.2" y1="65.3" x2="76.3" y2="116.2"/>
<polyline className="st3" points="79.2,74.1 79.9,74.1 79.9,66.2 79.2,74.1 "/>
<polyline className="st3" points="79.2,74.1 79.2,66.2 79.9,66.2 79.2,74.1 "/>
<line className="st4" x1="101.8" y1="39.9" x2="50.9" y2="90.8"/>
<polyline className="st3" points="79.2,74.1 79.2,66.2 79.9,66.2 79.2,74.1 "/>
<line className="st13" x1="127.2" y1="65.3" x2="76.3" y2="116.2"/>
<polyline className="st3" points="79.2,74.1 79.2,66.2 79.9,66.2 79.2,74.1 "/>
<polyline className="st14" points="79.9,74.1 79.9,66.2 79.2,66.2 79.2,74.1 79.9,74.1 "/>
<polyline className="st3" points="79.2,87.3 79.9,87.3 79.9,79.4 79.2,87.3 "/>
<line className="st4" x1="76.3" y1="65.3" x2="50.9" y2="90.8"/>
<polyline className="st3" points="79.2,87.3 79.9,87.3 79.9,79.4 79.2,87.3 "/>
<line className="st13" x1="101.8" y1="90.8" x2="76.3" y2="116.2"/>
<polyline className="st3" points="79.2,87.3 79.9,87.3 79.9,79.4 79.2,87.3 "/>
<polyline className="st3" points="79.2,87.3 79.2,79.4 79.9,79.4 79.2,87.3 "/>
<line className="st4" x1="76.3" y1="65.3" x2="50.9" y2="90.8"/>
<polyline className="st3" points="79.2,87.3 79.2,79.4 79.9,79.4 79.2,87.3 "/>
<line className="st13" x1="101.8" y1="90.8" x2="76.3" y2="116.2"/>
<polyline className="st3" points="79.2,87.3 79.2,79.4 79.9,79.4 79.2,87.3 "/>
<polyline className="st14" points="79.9,87.3 79.9,79.4 79.2,79.4 79.2,87.3 79.9,87.3 "/>
<polyline className="st3" points="286.1,235.1 283.8,237.4 288.5,237.4 286.1,235.1 "/>
<line className="st4" x1="280" y1="218.1" x2="254.5" y2="243.5"/>
<polyline className="st3" points="286.1,235.1 283.8,237.4 288.5,237.4 286.1,235.1 "/>
<line className="st0" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="286.1,235.1 283.8,237.4 288.5,237.4 286.1,235.1 "/>
<polyline className="st3" points="273.2,248 277.9,248 275.6,250.2 273.2,248 "/>
<line className="st4" x1="280" y1="218.1" x2="254.5" y2="243.5"/>
<polyline className="st3" points="273.2,248 277.9,248 275.6,250.2 273.2,248 "/>
<line className="st0" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="273.2,248 277.9,248 275.6,250.2 273.2,248 "/>
<polyline className="st3" points="283.8,237.4 273.2,248 277.9,248 283.8,237.4 "/>
<line className="st4" x1="280" y1="218.1" x2="254.5" y2="243.5"/>
<polyline className="st3" points="283.8,237.4 273.2,248 277.9,248 283.8,237.4 "/>
<line className="st0" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="283.8,237.4 273.2,248 277.9,248 283.8,237.4 "/>
<polyline className="st3" points="283.8,237.4 288.5,237.4 277.9,248 283.8,237.4 "/>
<line className="st4" x1="280" y1="218.1" x2="254.5" y2="243.5"/>
<polyline className="st3" points="283.8,237.4 288.5,237.4 277.9,248 283.8,237.4 "/>
<line className="st0" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="283.8,237.4 288.5,237.4 277.9,248 283.8,237.4 "/>
<polyline className="st3" points="112.7,187.1 111.7,199.8 112.7,199.8 112.7,187.1 "/>
<line className="st4" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="112.7,187.1 111.7,199.8 112.7,199.8 112.7,187.1 "/>
<line className="st0" x1="152.6" y1="192.5" x2="127.2" y2="218.1"/>
<polyline className="st3" points="112.7,187.1 111.7,199.8 112.7,199.8 112.7,187.1 "/>
<polyline className="st3" points="114.4,174.5 114.4,201.4 115.3,201.4 114.4,174.5 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="114.4,174.5 114.4,201.4 115.3,201.4 114.4,174.5 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<line className="st0" x1="152.6" y1="192.5" x2="127.2" y2="218.1"/>
<polyline className="st3" points="114.4,174.5 114.4,201.4 115.3,201.4 114.4,174.5 "/>
<polyline className="st3" points="139.1,187.1 139.1,201.4 140,201.4 139.1,187.1 "/>
<line className="st4" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="139.1,187.1 139.1,201.4 140,201.4 139.1,187.1 "/>
<line className="st0" x1="152.6" y1="192.5" x2="127.2" y2="218.1"/>
<polyline className="st3" points="139.1,187.1 139.1,201.4 140,201.4 139.1,187.1 "/>
<polyline className="st3" points="167,161.2 165,163.4 169.2,163.4 167,161.2 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="167,161.2 165,163.4 169.2,163.4 167,161.2 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="167,161.2 165,163.4 169.2,163.4 167,161.2 "/>
<polyline className="st3" points="165,163.4 169.2,163.4 170,164.3 165,163.4 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="165,163.4 169.2,163.4 170,164.3 165,163.4 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="165,163.4 169.2,163.4 170,164.3 165,163.4 "/>
<polyline className="st3" points="154.1,148.4 152,150.4 155,150.4 154.1,148.4 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="154.1,148.4 152,150.4 155,150.4 154.1,148.4 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="154.1,148.4 152,150.4 155,150.4 154.1,148.4 "/>
<polyline className="st3" points="152,150.4 155,150.4 155.9,152.2 152,150.4 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="152,150.4 155,150.4 155.9,152.2 152,150.4 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="152,150.4 155,150.4 155.9,152.2 152,150.4 "/>
<polyline className="st3" points="155.9,152.2 156.6,153.9 159.7,153.9 155.9,152.2 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="155.9,152.2 156.6,153.9 159.7,153.9 155.9,152.2 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="155.9,152.2 156.6,153.9 159.7,153.9 155.9,152.2 "/>
<polyline className="st3" points="156.6,153.9 159.7,153.9 157.6,156 156.6,153.9 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="156.6,153.9 159.7,153.9 157.6,156 156.6,153.9 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="156.6,153.9 159.7,153.9 157.6,156 156.6,153.9 "/>
<polyline className="st3" points="162.6,158.9 163.4,160.7 166.4,160.7 162.6,158.9 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="162.6,158.9 163.4,160.7 166.4,160.7 162.6,158.9 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="162.6,158.9 163.4,160.7 166.4,160.7 162.6,158.9 "/>
<polyline className="st3" points="163.4,160.7 166.4,160.7 164.4,162.8 163.4,160.7 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="163.4,160.7 166.4,160.7 164.4,162.8 163.4,160.7 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="163.4,160.7 166.4,160.7 164.4,162.8 163.4,160.7 "/>
<polyline className="st3" points="160.9,155.1 158.8,157.1 161.9,157.1 160.9,155.1 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="160.9,155.1 158.8,157.1 161.9,157.1 160.9,155.1 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="160.9,155.1 158.8,157.1 161.9,157.1 160.9,155.1 "/>
<polyline className="st3" points="158.8,157.1 161.9,157.1 162.6,158.9 158.8,157.1 "/>
<line className="st4" x1="152.6" y1="141.6" x2="127.2" y2="167.1"/>
<polyline className="st3" points="158.8,157.1 161.9,157.1 162.6,158.9 158.8,157.1 "/>
<line className="st0" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="158.8,157.1 161.9,157.1 162.6,158.9 158.8,157.1 "/>
<polyline className="st3" points="181.4,204.5 167.2,218.7 168.5,218.7 181.4,204.5 "/>
<line className="st4" x1="178.1" y1="167.1" x2="127.2" y2="218.1"/>
<polyline className="st3" points="181.4,204.5 167.2,218.7 168.5,218.7 181.4,204.5 "/>
<line className="st0" x1="203.6" y1="192.5" x2="152.6" y2="243.5"/>
<line className="st0" x1="229.1" y1="218.1" x2="178.1" y2="269"/>
<polyline className="st3" points="181.4,204.5 167.2,218.7 168.5,218.7 181.4,204.5 "/>
<polyline className="st3" points="167.2,218.7 168.5,218.7 167.2,220.1 167.2,218.7 "/>
<line className="st4" x1="152.6" y1="192.5" x2="127.2" y2="218.1"/>
<polyline className="st3" points="167.2,218.7 168.5,218.7 167.2,220.1 167.2,218.7 "/>
<line className="st0" x1="178.1" y1="218.1" x2="152.6" y2="243.5"/>
<polyline className="st3" points="167.2,218.7 168.5,218.7 167.2,220.1 167.2,218.7 "/>
<polyline className="st3" points="70.3,204.5 83.3,218.7 84.6,218.7 70.3,204.5 "/>
<polyline className="st3" points="70.3,204.5 83.3,218.7 84.6,218.7 70.3,204.5 "/>
<polyline className="st3" points="70.3,204.5 83.3,218.7 84.6,218.7 70.3,204.5 "/>
<polyline className="st3" points="83.3,218.7 84.6,218.7 84.6,220.1 83.3,218.7 "/>
<line className="st4" x1="101.8" y1="192.5" x2="76.3" y2="218.1"/>
<polyline className="st3" points="83.3,218.7 84.6,218.7 84.6,220.1 83.3,218.7 "/>
<line className="st0" x1="127.2" y1="218.1" x2="101.8" y2="243.5"/>
<polyline className="st3" points="83.3,218.7 84.6,218.7 84.6,220.1 83.3,218.7 "/>
<polyline className="st3" points="182.8,181.2 191.9,190.2 193.3,190.2 182.8,181.2 "/>
<line className="st4" x1="203.6" y1="141.6" x2="152.6" y2="192.5"/>
<polyline className="st3" points="182.8,181.2 191.9,190.2 193.3,190.2 182.8,181.2 "/>
<line className="st0" x1="229.1" y1="167.1" x2="178.1" y2="218.1"/>
<polyline className="st3" points="182.8,181.2 191.9,190.2 193.3,190.2 182.8,181.2 "/>
<polyline className="st3" points="191.9,190.2 193.3,190.2 192.6,191 191.9,190.2 "/>
<line className="st4" x1="178.1" y1="167.1" x2="152.6" y2="192.5"/>
<polyline className="st3" points="191.9,190.2 193.3,190.2 192.6,191 191.9,190.2 "/>
<line className="st0" x1="203.6" y1="192.5" x2="178.1" y2="218.1"/>
<polyline className="st3" points="191.9,190.2 193.3,190.2 192.6,191 191.9,190.2 "/>
<polyline className="st3" points="229.4,226.5 228.7,227.2 230,227.2 229.4,226.5 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="229.4,226.5 228.7,227.2 230,227.2 229.4,226.5 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<polyline className="st3" points="229.4,226.5 228.7,227.2 230,227.2 229.4,226.5 "/>
<polyline className="st3" points="228.7,227.2 230,227.2 237.6,236 228.7,227.2 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="228.7,227.2 230,227.2 237.6,236 228.7,227.2 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<polyline className="st3" points="228.7,227.2 230,227.2 237.6,236 228.7,227.2 "/>
<polyline className="st3" points="232,255.2 231.5,255.9 232.7,255.9 232,255.2 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="232,255.2 231.5,255.9 232.7,255.9 232,255.2 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<polyline className="st3" points="232,255.2 231.5,255.9 232.7,255.9 232,255.2 "/>
<polyline className="st3" points="231.5,255.9 232.7,255.9 226.2,262.4 231.5,255.9 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="231.5,255.9 232.7,255.9 226.2,262.4 231.5,255.9 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<polyline className="st3" points="231.5,255.9 232.7,255.9 226.2,262.4 231.5,255.9 "/>
<polyline className="st3" points="233.9,257 233.2,257.7 234.6,257.7 233.9,257 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="233.9,257 233.2,257.7 234.6,257.7 233.9,257 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<polyline className="st3" points="233.9,257 233.2,257.7 234.6,257.7 233.9,257 "/>
<polyline className="st3" points="233.2,257.7 234.6,257.7 227.4,263.6 233.2,257.7 "/>
<line className="st4" x1="229.1" y1="218.1" x2="203.6" y2="243.5"/>
<polyline className="st3" points="233.2,257.7 234.6,257.7 227.4,263.6 233.2,257.7 "/>
<line className="st0" x1="254.5" y1="243.5" x2="229.1" y2="269"/>
<line className="st0" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="233.2,257.7 234.6,257.7 227.4,263.6 233.2,257.7 "/>
<polyline className="st3" points="313.7,243.5 313.1,244.1 314.4,244.1 313.7,243.5 "/>
<line className="st4" x1="330.8" y1="218.1" x2="305.4" y2="243.5"/>
<polyline className="st3" points="313.7,243.5 313.1,244.1 314.4,244.1 313.7,243.5 "/>
<line className="st0" x1="356.3" y1="243.5" x2="330.8" y2="269"/>
<polyline className="st3" points="313.7,243.5 313.1,244.1 314.4,244.1 313.7,243.5 "/>
<polyline className="st3" points="313.1,244.1 314.4,244.1 307.8,250.7 313.1,244.1 "/>
<line className="st4" x1="330.8" y1="218.1" x2="280" y2="269"/>
<polyline className="st3" points="313.1,244.1 314.4,244.1 307.8,250.7 313.1,244.1 "/>
<line className="st0" x1="356.3" y1="243.5" x2="305.4" y2="294.4"/>
<polyline className="st3" points="313.1,244.1 314.4,244.1 307.8,250.7 313.1,244.1 "/>
<polyline className="st3" points="315.5,245.3 314.8,246 316.2,246 315.5,245.3 "/>
<line className="st4" x1="330.8" y1="218.1" x2="305.4" y2="243.5"/>
<polyline className="st3" points="315.5,245.3 314.8,246 316.2,246 315.5,245.3 "/>
<line className="st0" x1="356.3" y1="243.5" x2="330.8" y2="269"/>
<polyline className="st3" points="315.5,245.3 314.8,246 316.2,246 315.5,245.3 "/>
<polyline className="st3" points="314.8,246 316.2,246 309,251.9 314.8,246 "/>
<line className="st4" x1="330.8" y1="218.1" x2="280" y2="269"/>
<polyline className="st3" points="314.8,246 316.2,246 309,251.9 314.8,246 "/>
<line className="st0" x1="356.3" y1="243.5" x2="305.4" y2="294.4"/>
<polyline className="st3" points="314.8,246 316.2,246 309,251.9 314.8,246 "/>
<polyline className="st3" points="348.6,278.4 348.1,279.2 349.3,279.2 348.6,278.4 "/>
<line className="st4" x1="356.3" y1="243.5" x2="330.8" y2="269"/>
<polyline className="st3" points="348.6,278.4 348.1,279.2 349.3,279.2 348.6,278.4 "/>
<line className="st0" x1="381.8" y1="269" x2="356.3" y2="294.4"/>
<polyline className="st3" points="348.6,278.4 348.1,279.2 349.3,279.2 348.6,278.4 "/>
<polyline className="st3" points="348.1,279.2 349.3,279.2 342.8,285.6 348.1,279.2 "/>
<line className="st4" x1="356.3" y1="243.5" x2="305.4" y2="294.4"/>
<polyline className="st3" points="348.1,279.2 349.3,279.2 342.8,285.6 348.1,279.2 "/>
<line className="st0" x1="381.8" y1="269" x2="330.8" y2="319.8"/>
<polyline className="st3" points="348.1,279.2 349.3,279.2 342.8,285.6 348.1,279.2 "/>
<polyline className="st3" points="350.5,280.4 349.8,281.1 351.2,281.1 350.5,280.4 "/>
<line className="st4" x1="356.3" y1="243.5" x2="330.8" y2="269"/>
<polyline className="st3" points="350.5,280.4 349.8,281.1 351.2,281.1 350.5,280.4 "/>
<line className="st0" x1="381.8" y1="269" x2="356.3" y2="294.4"/>
<polyline className="st3" points="350.5,280.4 349.8,281.1 351.2,281.1 350.5,280.4 "/>
<polyline className="st3" points="349.8,281.1 351.2,281.1 343.9,286.8 349.8,281.1 "/>
<line className="st4" x1="356.3" y1="243.5" x2="305.4" y2="294.4"/>
<polyline className="st3" points="349.8,281.1 351.2,281.1 343.9,286.8 349.8,281.1 "/>
<line className="st0" x1="381.8" y1="269" x2="330.8" y2="319.8"/>
<polyline className="st3" points="349.8,281.1 351.2,281.1 343.9,286.8 349.8,281.1 "/>
<polyline className="st3" points="383.6,313.5 383,314.2 384.4,314.2 383.6,313.5 "/>
<line className="st4" x1="381.8" y1="269" x2="356.3" y2="294.4"/>
<polyline className="st3" points="383.6,313.5 383,314.2 384.4,314.2 383.6,313.5 "/>
<line className="st0" x1="407.3" y1="294.4" x2="381.8" y2="319.8"/>
<polyline className="st3" points="383.6,313.5 383,314.2 384.4,314.2 383.6,313.5 "/>
<polyline className="st3" points="383,314.2 384.4,314.2 377.8,320.7 383,314.2 "/>
<line className="st4" x1="381.8" y1="269" x2="330.8" y2="319.8"/>
<polyline className="st3" points="383,314.2 384.4,314.2 377.8,320.7 383,314.2 "/>
<line className="st0" x1="407.3" y1="294.4" x2="356.3" y2="345.3"/>
<line className="st0" x1="432.7" y1="319.8" x2="381.8" y2="370.7"/>
<polyline className="st3" points="383,314.2 384.4,314.2 377.8,320.7 383,314.2 "/>
<polyline className="st3" points="385.4,315.3 384.8,316 386.2,316 385.4,315.3 "/>
<line className="st4" x1="381.8" y1="269" x2="356.3" y2="294.4"/>
<polyline className="st3" points="385.4,315.3 384.8,316 386.2,316 385.4,315.3 "/>
<line className="st0" x1="407.3" y1="294.4" x2="381.8" y2="319.8"/>
<line className="st0" x1="432.7" y1="319.8" x2="407.3" y2="345.3"/>
<polyline className="st3" points="385.4,315.3 384.8,316 386.2,316 385.4,315.3 "/>
<polyline className="st3" points="384.8,316 386.2,316 379,321.9 384.8,316 "/>
<line className="st4" x1="381.8" y1="269" x2="330.8" y2="319.8"/>
<polyline className="st3" points="384.8,316 386.2,316 379,321.9 384.8,316 "/>
<line className="st0" x1="407.3" y1="294.4" x2="356.3" y2="345.3"/>
<line className="st0" x1="432.7" y1="319.8" x2="381.8" y2="370.7"/>
<polyline className="st3" points="384.8,316 386.2,316 379,321.9 384.8,316 "/>
<polyline className="st3" points="296.6,264.3 294.5,266.3 297.8,266.3 296.6,264.3 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="296.6,264.3 294.5,266.3 297.8,266.3 296.6,264.3 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="296.6,264.3 294.5,266.3 297.8,266.3 296.6,264.3 "/>
<polyline className="st3" points="294.5,266.3 297.8,266.3 299.5,269.2 294.5,266.3 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="294.5,266.3 297.8,266.3 299.5,269.2 294.5,266.3 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="294.5,266.3 297.8,266.3 299.5,269.2 294.5,266.3 "/>
<polyline className="st3" points="299.5,269.2 301.1,272.1 304.4,272.1 299.5,269.2 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="299.5,269.2 301.1,272.1 304.4,272.1 299.5,269.2 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="299.5,269.2 301.1,272.1 304.4,272.1 299.5,269.2 "/>
<polyline className="st3" points="301.1,272.1 304.4,272.1 302.3,274.1 301.1,272.1 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="301.1,272.1 304.4,272.1 302.3,274.1 301.1,272.1 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="301.1,272.1 304.4,272.1 302.3,274.1 301.1,272.1 "/>
<polyline className="st3" points="295.1,280 294.5,280.7 295.8,280.7 295.1,280 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="295.1,280 294.5,280.7 295.8,280.7 295.1,280 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="295.1,280 294.5,280.7 295.8,280.7 295.1,280 "/>
<polyline className="st3" points="294.5,280.7 295.8,280.7 289.1,287.4 294.5,280.7 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="294.5,280.7 295.8,280.7 289.1,287.4 294.5,280.7 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<polyline className="st3" points="294.5,280.7 295.8,280.7 289.1,287.4 294.5,280.7 "/>
<polyline className="st3" points="301.2,273.9 300.6,274.6 301.9,274.6 301.2,273.9 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="301.2,273.9 300.6,274.6 301.9,274.6 301.2,273.9 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="301.2,273.9 300.6,274.6 301.9,274.6 301.2,273.9 "/>
<polyline className="st3" points="300.6,274.6 301.9,274.6 296.3,280.2 300.6,274.6 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="300.6,274.6 301.9,274.6 296.3,280.2 300.6,274.6 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="300.6,274.6 301.9,274.6 296.3,280.2 300.6,274.6 "/>
<polyline className="st3" points="305,272.7 300.1,277.5 302,277.5 305,272.7 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="305,272.7 300.1,277.5 302,277.5 305,272.7 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="305,272.7 300.1,277.5 302,277.5 305,272.7 "/>
<polyline className="st3" points="299.9,277.7 302.2,277.7 307.8,285.6 299.9,277.7 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="299.9,277.7 302.2,277.7 307.8,285.6 299.9,277.7 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="299.9,277.7 302.2,277.7 307.8,285.6 299.9,277.7 "/>
<polyline className="st3" points="300.1,277.5 299.9,277.7 302.2,277.7 300.1,277.5 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="300.1,277.5 299.9,277.7 302.2,277.7 300.1,277.5 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="300.1,277.5 299.9,277.7 302.2,277.7 300.1,277.5 "/>
<polyline className="st3" points="300.1,277.5 302,277.5 302.2,277.7 300.1,277.5 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="300.1,277.5 302,277.5 302.2,277.7 300.1,277.5 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="300.1,277.5 302,277.5 302.2,277.7 300.1,277.5 "/>
<polyline className="st3" points="309.2,303.4 307.1,305.6 310.4,305.6 309.2,303.4 "/>
<line className="st4" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="309.2,303.4 307.1,305.6 310.4,305.6 309.2,303.4 "/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="309.2,303.4 307.1,305.6 310.4,305.6 309.2,303.4 "/>
<polyline className="st3" points="307.1,305.6 310.4,305.6 312.1,308.4 307.1,305.6 "/>
<line className="st4" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="307.1,305.6 310.4,305.6 312.1,308.4 307.1,305.6 "/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="307.1,305.6 310.4,305.6 312.1,308.4 307.1,305.6 "/>
<polyline className="st3" points="312.1,308.4 313.8,311.3 317.2,311.3 312.1,308.4 "/>
<line className="st4" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="312.1,308.4 313.8,311.3 317.2,311.3 312.1,308.4 "/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="312.1,308.4 313.8,311.3 317.2,311.3 312.1,308.4 "/>
<polyline className="st3" points="313.8,311.3 317.2,311.3 315,313.5 313.8,311.3 "/>
<line className="st4" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="313.8,311.3 317.2,311.3 315,313.5 313.8,311.3 "/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="313.8,311.3 317.2,311.3 315,313.5 313.8,311.3 "/>
<polyline className="st3" points="359.2,339.4 349.3,347.8 350.8,347.8 359.2,339.4 "/>
<line className="st4" x1="356.3" y1="294.4" x2="305.4" y2="345.3"/>
<polyline className="st3" points="359.2,339.4 349.3,347.8 350.8,347.8 359.2,339.4 "/>
<line className="st0" x1="381.8" y1="319.8" x2="330.8" y2="370.7"/>
<line className="st0" x1="407.3" y1="345.3" x2="356.3" y2="396.2"/>
<polyline className="st3" points="359.2,339.4 349.3,347.8 350.8,347.8 359.2,339.4 "/>
<polyline className="st3" points="349.3,347.8 350.8,347.8 350,348.4 349.3,347.8 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="349.3,347.8 350.8,347.8 350,348.4 349.3,347.8 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="349.3,347.8 350.8,347.8 350,348.4 349.3,347.8 "/>
<polyline className="st3" points="395,376.7 389.4,382.5 390.6,382.5 395,376.7 "/>
<line className="st4" x1="407.3" y1="345.3" x2="356.3" y2="396.2"/>
<polyline className="st3" points="395,376.7 389.4,382.5 390.6,382.5 395,376.7 "/>
<line className="st0" x1="432.7" y1="370.7" x2="381.8" y2="421.7"/>
<polyline className="st3" points="395,376.7 389.4,382.5 390.6,382.5 395,376.7 "/>
<polyline className="st3" points="389.4,382.5 390.6,382.5 390.1,383.1 389.4,382.5 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="389.4,382.5 390.6,382.5 390.1,383.1 389.4,382.5 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="389.4,382.5 390.6,382.5 390.1,383.1 389.4,382.5 "/>
<polyline className="st3" points="388.3,382.5 386.2,384.6 389.3,384.6 388.3,382.5 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="388.3,382.5 386.2,384.6 389.3,384.6 388.3,382.5 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="388.3,382.5 386.2,384.6 389.3,384.6 388.3,382.5 "/>
<polyline className="st3" points="386.2,384.6 389.3,384.6 390.4,386.7 386.2,384.6 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="386.2,384.6 389.3,384.6 390.4,386.7 386.2,384.6 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="386.2,384.6 389.3,384.6 390.4,386.7 386.2,384.6 "/>
<polyline className="st3" points="390.4,386.7 391.3,388.7 394.6,388.7 390.4,386.7 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="390.4,386.7 391.3,388.7 394.6,388.7 390.4,386.7 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="390.4,386.7 391.3,388.7 394.6,388.7 390.4,386.7 "/>
<polyline className="st3" points="391.3,388.7 394.6,388.7 392.4,390.9 391.3,388.7 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="391.3,388.7 394.6,388.7 392.4,390.9 391.3,388.7 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="391.3,388.7 394.6,388.7 392.4,390.9 391.3,388.7 "/>
<polyline className="st3" points="395,389.2 392.9,391.4 396,391.4 395,389.2 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="395,389.2 392.9,391.4 396,391.4 395,389.2 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="395,389.2 392.9,391.4 396,391.4 395,389.2 "/>
<polyline className="st3" points="392.9,391.4 396,391.4 397.1,393.4 392.9,391.4 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="392.9,391.4 396,391.4 397.1,393.4 392.9,391.4 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="392.9,391.4 396,391.4 397.1,393.4 392.9,391.4 "/>
<polyline className="st3" points="397.1,393.4 398,395.4 401.3,395.4 397.1,393.4 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="397.1,393.4 398,395.4 401.3,395.4 397.1,393.4 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="397.1,393.4 398,395.4 401.3,395.4 397.1,393.4 "/>
<polyline className="st3" points="398,395.4 401.3,395.4 399.1,397.6 398,395.4 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="398,395.4 401.3,395.4 399.1,397.6 398,395.4 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="398,395.4 401.3,395.4 399.1,397.6 398,395.4 "/>
<polyline className="st3" points="401.8,396 399.7,398.1 403.9,398.1 401.8,396 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="401.8,396 399.7,398.1 403.9,398.1 401.8,396 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="401.8,396 399.7,398.1 403.9,398.1 401.8,396 "/>
<polyline className="st3" points="399.7,398.1 403.9,398.1 404.6,398.8 399.7,398.1 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="399.7,398.1 403.9,398.1 404.6,398.8 399.7,398.1 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="399.7,398.1 403.9,398.1 404.6,398.8 399.7,398.1 "/>
<polyline className="st3" points="373.8,397 380.3,404.7 381.5,404.7 373.8,397 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="373.8,397 380.3,404.7 381.5,404.7 373.8,397 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="373.8,397 380.3,404.7 381.5,404.7 373.8,397 "/>
<polyline className="st3" points="380.3,404.7 381.5,404.7 380.9,405.4 380.3,404.7 "/>
<line className="st4" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="380.3,404.7 381.5,404.7 380.9,405.4 380.3,404.7 "/>
<line className="st0" x1="407.3" y1="396.2" x2="381.8" y2="421.7"/>
<polyline className="st3" points="380.3,404.7 381.5,404.7 380.9,405.4 380.3,404.7 "/>
<polyline className="st3" points="356.3,379.5 355.7,380.2 357,380.2 356.3,379.5 "/>
<line className="st4" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="356.3,379.5 355.7,380.2 357,380.2 356.3,379.5 "/>
<line className="st0" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="356.3,379.5 355.7,380.2 357,380.2 356.3,379.5 "/>
<polyline className="st3" points="355.7,380.2 357,380.2 360.5,383.7 355.7,380.2 "/>
<line className="st4" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="355.7,380.2 357,380.2 360.5,383.7 355.7,380.2 "/>
<line className="st0" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="355.7,380.2 357,380.2 360.5,383.7 355.7,380.2 "/>
<polyline className="st3" points="89,153.3 90.7,156 91.9,156 89,153.3 "/>
<line className="st4" x1="76.3" y1="116.2" x2="50.9" y2="141.6"/>
<polyline className="st3" points="89,153.3 90.7,156 91.9,156 89,153.3 "/>
<line className="st0" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="89,153.3 90.7,156 91.9,156 89,153.3 "/>
<polyline className="st3" points="90.7,156 91.9,156 91.2,156.8 90.7,156 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="90.7,156 91.9,156 91.2,156.8 90.7,156 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="90.7,156 91.9,156 91.2,156.8 90.7,156 "/>
<polyline className="st3" points="314.2,281.7 303.6,292.2 305.9,292.2 314.2,281.7 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="314.2,281.7 303.6,292.2 305.9,292.2 314.2,281.7 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<line className="st0" x1="356.3" y1="294.4" x2="330.8" y2="319.8"/>
<polyline className="st3" points="314.2,281.7 303.6,292.2 305.9,292.2 314.2,281.7 "/>
<polyline className="st3" points="303.6,292.2 303.5,292.4 304.9,292.4 303.6,292.2 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="303.6,292.2 303.5,292.4 304.9,292.4 303.6,292.2 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="303.6,292.2 303.5,292.4 304.9,292.4 303.6,292.2 "/>
<polyline className="st3" points="303.6,292.2 305.2,292.2 304.9,292.4 303.6,292.2 "/>
<line className="st4" x1="305.4" y1="243.5" x2="280" y2="269"/>
<polyline className="st3" points="303.6,292.2 305.2,292.2 304.9,292.4 303.6,292.2 "/>
<line className="st0" x1="330.8" y1="269" x2="305.4" y2="294.4"/>
<polyline className="st3" points="303.6,292.2 305.2,292.2 304.9,292.4 303.6,292.2 "/>
<polyline className="st3" points="303.5,292.4 303.2,292.6 304.8,292.6 303.5,292.4 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="303.5,292.4 303.2,292.6 304.8,292.6 303.5,292.4 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<polyline className="st3" points="303.5,292.4 303.2,292.6 304.8,292.6 303.5,292.4 "/>
<polyline className="st3" points="303.5,292.4 304.9,292.4 304.8,292.6 303.5,292.4 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="303.5,292.4 304.9,292.4 304.8,292.6 303.5,292.4 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<polyline className="st3" points="303.5,292.4 304.9,292.4 304.8,292.6 303.5,292.4 "/>
<polyline className="st3" points="303.2,292.6 302.2,293.7 304.8,293.7 303.2,292.6 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="303.2,292.6 302.2,293.7 304.8,293.7 303.2,292.6 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<polyline className="st3" points="303.2,292.6 302.2,293.7 304.8,293.7 303.2,292.6 "/>
<polyline className="st3" points="303.2,292.6 304.8,292.6 304.8,293.7 303.2,292.6 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="303.2,292.6 304.8,292.6 304.8,293.7 303.2,292.6 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<polyline className="st3" points="303.2,292.6 304.8,292.6 304.8,293.7 303.2,292.6 "/>
<polyline className="st3" points="302.2,293.7 298.9,296.9 302.4,296.9 302.2,293.7 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="302.2,293.7 298.9,296.9 302.4,296.9 302.2,293.7 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="302.2,293.7 298.9,296.9 302.4,296.9 302.2,293.7 "/>
<polyline className="st3" points="302.2,293.7 304.8,293.7 302.4,296.9 302.2,293.7 "/>
<line className="st4" x1="305.4" y1="243.5" x2="254.5" y2="294.4"/>
<polyline className="st3" points="302.2,293.7 304.8,293.7 302.4,296.9 302.2,293.7 "/>
<line className="st0" x1="330.8" y1="269" x2="280" y2="319.8"/>
<line className="st0" x1="356.3" y1="294.4" x2="305.4" y2="345.3"/>
<polyline className="st3" points="302.2,293.7 304.8,293.7 302.4,296.9 302.2,293.7 "/>
<polyline className="st3" points="298.7,297.2 302.4,297.2 302.8,301.1 298.7,297.2 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="298.7,297.2 302.4,297.2 302.8,301.1 298.7,297.2 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="298.7,297.2 302.4,297.2 302.8,301.1 298.7,297.2 "/>
<polyline className="st3" points="298.9,296.9 298.7,297.2 302.4,297.2 298.9,296.9 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="298.9,296.9 298.7,297.2 302.4,297.2 298.9,296.9 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="298.9,296.9 298.7,297.2 302.4,297.2 298.9,296.9 "/>
<polyline className="st3" points="298.9,296.9 302.4,296.9 302.4,297.2 298.9,296.9 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="298.9,296.9 302.4,296.9 302.4,297.2 298.9,296.9 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="298.9,296.9 302.4,296.9 302.4,297.2 298.9,296.9 "/>
<polyline className="st3" points="97.9,148.6 97,150.4 99.7,150.4 97.9,148.6 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="97.9,148.6 97,150.4 99.7,150.4 97.9,148.6 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="97.9,148.6 97,150.4 99.7,150.4 97.9,148.6 "/>
<polyline className="st3" points="97,150.4 99.7,150.4 96,152.3 97,150.4 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="97,150.4 99.7,150.4 96,152.3 97,150.4 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="97,150.4 99.7,150.4 96,152.3 97,150.4 "/>
<polyline className="st3" points="96,152.3 92.3,154.1 95,154.1 96,152.3 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="96,152.3 92.3,154.1 95,154.1 96,152.3 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="96,152.3 92.3,154.1 95,154.1 96,152.3 "/>
<polyline className="st3" points="92.3,154.1 95,154.1 94.2,156 92.3,154.1 "/>
<line className="st4" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="92.3,154.1 95,154.1 94.2,156 92.3,154.1 "/>
<line className="st0" x1="127.2" y1="167.1" x2="101.8" y2="192.5"/>
<polyline className="st3" points="92.3,154.1 95,154.1 94.2,156 92.3,154.1 "/>
<polyline className="st3" points="167.2,212.8 168.1,212.8 167.2,216.4 167.2,212.8 "/>
<line className="st4" x1="152.6" y1="192.5" x2="127.2" y2="218.1"/>
<polyline className="st3" points="167.2,212.8 168.1,212.8 167.2,216.4 167.2,212.8 "/>
<line className="st0" x1="178.1" y1="218.1" x2="152.6" y2="243.5"/>
<polyline className="st3" points="167.2,212.8 168.1,212.8 167.2,216.4 167.2,212.8 "/>
<polyline className="st3" points="359.6,341.2 351.2,349.6 352.4,349.6 359.6,341.2 "/>
<line className="st4" x1="356.3" y1="294.4" x2="305.4" y2="345.3"/>
<polyline className="st3" points="359.6,341.2 351.2,349.6 352.4,349.6 359.6,341.2 "/>
<line className="st0" x1="381.8" y1="319.8" x2="330.8" y2="370.7"/>
<line className="st0" x1="407.3" y1="345.3" x2="356.3" y2="396.2"/>
<polyline className="st3" points="359.6,341.2 351.2,349.6 352.4,349.6 359.6,341.2 "/>
<polyline className="st3" points="351.2,349.6 352.4,349.6 351.8,350.3 351.2,349.6 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="351.2,349.6 352.4,349.6 351.8,350.3 351.2,349.6 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<line className="st0" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="351.2,349.6 352.4,349.6 351.8,350.3 351.2,349.6 "/>
<polyline className="st3" points="62.6,186 62.2,186.8 63.4,186.8 62.6,186 "/>
<line className="st4" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="62.6,186 62.2,186.8 63.4,186.8 62.6,186 "/>
<line className="st0" x1="101.8" y1="192.5" x2="76.3" y2="218.1"/>
<polyline className="st3" points="62.6,186 62.2,186.8 63.4,186.8 62.6,186 "/>
<polyline className="st3" points="62.2,186.8 63.4,186.8 59.2,191 62.2,186.8 "/>
<line className="st4" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="62.2,186.8 63.4,186.8 59.2,191 62.2,186.8 "/>
<line className="st0" x1="101.8" y1="192.5" x2="76.3" y2="218.1"/>
<polyline className="st3" points="62.2,186.8 63.4,186.8 59.2,191 62.2,186.8 "/>
<polyline className="st3" points="56.2,190.2 55.1,192.2 58,192.2 56.2,190.2 "/>
<line className="st4" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="56.2,190.2 55.1,192.2 58,192.2 56.2,190.2 "/>
<line className="st0" x1="101.8" y1="192.5" x2="76.3" y2="218.1"/>
<polyline className="st3" points="56.2,190.2 55.1,192.2 58,192.2 56.2,190.2 "/>
<polyline className="st3" points="55.1,192.2 58,192.2 53.9,194.4 55.1,192.2 "/>
<line className="st4" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="55.1,192.2 58,192.2 53.9,194.4 55.1,192.2 "/>
<line className="st0" x1="101.8" y1="192.5" x2="76.3" y2="218.1"/>
<polyline className="st3" points="55.1,192.2 58,192.2 53.9,194.4 55.1,192.2 "/>
<polyline className="st3" points="53.9,194.4 49.1,197.3 52.1,197.3 53.9,194.4 "/>
<polyline className="st3" points="53.9,194.4 49.1,197.3 52.1,197.3 53.9,194.4 "/>
<polyline className="st3" points="53.9,194.4 49.1,197.3 52.1,197.3 53.9,194.4 "/>
<polyline className="st3" points="49.1,197.3 52.1,197.3 51,199.1 49.1,197.3 "/>
<polyline className="st3" points="49.1,197.3 52.1,197.3 51,199.1 49.1,197.3 "/>
<polyline className="st3" points="49.1,197.3 52.1,197.3 51,199.1 49.1,197.3 "/>
<polyline className="st3" points="350.4,374.7 349.7,375.4 351.1,375.4 350.4,374.7 "/>
<line className="st4" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="350.4,374.7 349.7,375.4 351.1,375.4 350.4,374.7 "/>
<line className="st0" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="350.4,374.7 349.7,375.4 351.1,375.4 350.4,374.7 "/>
<polyline className="st3" points="349.7,375.4 351.1,375.4 354.6,378.9 349.7,375.4 "/>
<line className="st4" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="349.7,375.4 351.1,375.4 354.6,378.9 349.7,375.4 "/>
<line className="st0" x1="381.8" y1="370.7" x2="356.3" y2="396.2"/>
<polyline className="st3" points="349.7,375.4 351.1,375.4 354.6,378.9 349.7,375.4 "/>
<polyline className="st3" points="320.8,345 320,345.8 321.5,345.8 320.8,345 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="320.8,345 320,345.8 321.5,345.8 320.8,345 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="320.8,345 320,345.8 321.5,345.8 320.8,345 "/>
<polyline className="st3" points="320,345.8 321.5,345.8 325,349.2 320,345.8 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="320,345.8 321.5,345.8 325,349.2 320,345.8 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="320,345.8 321.5,345.8 325,349.2 320,345.8 "/>
<polyline className="st3" points="315.4,339.6 314.6,340.4 316.1,340.4 315.4,339.6 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="315.4,339.6 314.6,340.4 316.1,340.4 315.4,339.6 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="315.4,339.6 314.6,340.4 316.1,340.4 315.4,339.6 "/>
<polyline className="st3" points="314.6,340.4 316.1,340.4 319.6,343.8 314.6,340.4 "/>
<line className="st4" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="314.6,340.4 316.1,340.4 319.6,343.8 314.6,340.4 "/>
<line className="st0" x1="356.3" y1="345.3" x2="330.8" y2="370.7"/>
<polyline className="st3" points="314.6,340.4 316.1,340.4 319.6,343.8 314.6,340.4 "/>
<polyline className="st3" points="285.7,310.1 285.1,310.7 286.4,310.7 285.7,310.1 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="285.7,310.1 285.1,310.7 286.4,310.7 285.7,310.1 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="285.7,310.1 285.1,310.7 286.4,310.7 285.7,310.1 "/>
<polyline className="st3" points="285.1,310.7 286.4,310.7 289.9,314.3 285.1,310.7 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="285.1,310.7 286.4,310.7 289.9,314.3 285.1,310.7 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<line className="st0" x1="330.8" y1="319.8" x2="305.4" y2="345.3"/>
<polyline className="st3" points="285.1,310.7 286.4,310.7 289.9,314.3 285.1,310.7 "/>
<polyline className="st3" points="280.4,304.7 279.7,305.4 281.2,305.4 280.4,304.7 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="280.4,304.7 279.7,305.4 281.2,305.4 280.4,304.7 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="280.4,304.7 279.7,305.4 281.2,305.4 280.4,304.7 "/>
<polyline className="st3" points="279.7,305.4 281.2,305.4 284.6,308.9 279.7,305.4 "/>
<line className="st4" x1="280" y1="269" x2="254.5" y2="294.4"/>
<polyline className="st3" points="279.7,305.4 281.2,305.4 284.6,308.9 279.7,305.4 "/>
<line className="st0" x1="305.4" y1="294.4" x2="280" y2="319.8"/>
<polyline className="st3" points="279.7,305.4 281.2,305.4 284.6,308.9 279.7,305.4 "/>
<polyline className="st15" points="224.5,257.1 224.6,257.1 224.8,257.1 224.9,257.1 225,257.1 "/>
<line className="st15" x1="224.4" y1="257.1" x2="224.4" y2="257.1"/>
<polyline className="st15" points="224.5,257.1 224.6,257.1 224.8,257.1 224.9,257.1 225,257.1 "/>
<line className="st15" x1="225.1" y1="257.1" x2="225.1" y2="257.1"/>
<line className="st15" x1="224.3" y1="257.2" x2="224.4" y2="257.2"/>
<polyline className="st15" points="224.5,257.2 224.6,257.2 224.8,257.2 224.9,257.2 225,257.2 "/>
<line className="st15" x1="225.1" y1="257.2" x2="225.2" y2="257.2"/>
<polyline className="st15" points="224.2,257.2 224.3,257.2 224.4,257.2 "/>
<polyline className="st15" points="224.5,257.2 224.6,257.2 224.8,257.2 224.9,257.2 225,257.2 "/>
<polyline className="st15" points="225.1,257.2 225.2,257.2 225.4,257.2 "/>
<polyline className="st15" points="224,257.3 224.2,257.3 224.3,257.3 224.4,257.3 "/>
<polyline className="st15" points="224.5,257.3 224.6,257.3 224.8,257.3 224.9,257.3 225,257.3 "/>
<polyline className="st15" points="225.1,257.3 225.2,257.3 225.4,257.3 225.5,257.3 "/>
<polyline className="st15" points="224,257.3 224.2,257.3 224.3,257.3 224.4,257.3 "/>
<polyline className="st15" points="224.5,257.3 224.6,257.3 224.8,257.3 224.9,257.3 225,257.3 "/>
<polyline className="st15" points="225.1,257.3 225.2,257.3 225.4,257.3 225.5,257.3 "/>
<polyline className="st15" points="223.9,257.3 224,257.3 224.2,257.3 224.3,257.3 224.4,257.3 "/>
<polyline className="st15" points="224.5,257.3 224.6,257.3 224.8,257.3 224.9,257.3 225,257.3 "/>
<polyline className="st15" points="225.1,257.3 225.2,257.3 225.4,257.3 225.5,257.3 225.6,257.3 "/>
<polyline className="st15" points="223.9,257.4 224,257.4 224.2,257.4 224.3,257.4 224.4,257.4 "/>
<line className="st15" x1="224.5" y1="257.4" x2="224.5" y2="257.4"/>
<line className="st15" x1="225" y1="257.4" x2="225" y2="257.4"/>
<polyline className="st15" points="225.1,257.4 225.2,257.4 225.4,257.4 225.5,257.4 225.6,257.4 "/>
<polyline className="st15" points="223.9,257.4 224,257.4 224.2,257.4 224.3,257.4 224.4,257.4 "/>
<polyline className="st15" points="225.1,257.4 225.2,257.4 225.4,257.4 225.5,257.4 225.6,257.4 "/>
<line className="st15" x1="223.8" y1="257.4" x2="223.8" y2="257.4"/>
<polyline className="st15" points="223.9,257.4 224,257.4 224.2,257.4 224.3,257.4 "/>
<polyline className="st15" points="225.2,257.4 225.4,257.4 225.5,257.4 225.6,257.4 "/>
<line className="st15" x1="225.7" y1="257.4" x2="225.7" y2="257.4"/>
<line className="st15" x1="223.8" y1="257.6" x2="223.8" y2="257.6"/>
<polyline className="st15" points="223.9,257.6 224,257.6 224.2,257.6 "/>
<polyline className="st15" points="225.4,257.6 225.5,257.6 225.6,257.6 "/>
<line className="st15" x1="225.7" y1="257.6" x2="225.7" y2="257.6"/>
<line className="st15" x1="223.8" y1="257.6" x2="223.8" y2="257.6"/>
<polyline className="st15" points="223.9,257.6 224,257.6 224.2,257.6 "/>
<polyline className="st15" points="225.4,257.6 225.5,257.6 225.6,257.6 "/>
<line className="st15" x1="225.7" y1="257.6" x2="225.8" y2="257.6"/>
<line className="st15" x1="223.7" y1="257.7" x2="223.8" y2="257.7"/>
<polyline className="st15" points="223.9,257.7 224,257.7 224.2,257.7 "/>
<polyline className="st15" points="225.4,257.7 225.5,257.7 225.6,257.7 "/>
<line className="st15" x1="225.7" y1="257.7" x2="225.8" y2="257.7"/>
<line className="st15" x1="223.7" y1="257.7" x2="223.8" y2="257.7"/>
<line className="st15" x1="223.9" y1="257.7" x2="224" y2="257.7"/>
<line className="st15" x1="225.5" y1="257.7" x2="225.6" y2="257.7"/>
<line className="st15" x1="225.7" y1="257.7" x2="225.8" y2="257.7"/>
<line className="st15" x1="223.7" y1="257.7" x2="223.8" y2="257.7"/>
<line className="st15" x1="223.9" y1="257.7" x2="224" y2="257.7"/>
<line className="st15" x1="225.5" y1="257.7" x2="225.6" y2="257.7"/>
<polyline className="st15" points="225.7,257.7 225.8,257.7 226,257.7 "/>
<line className="st15" x1="223.7" y1="257.8" x2="223.8" y2="257.8"/>
<line className="st15" x1="223.9" y1="257.8" x2="224" y2="257.8"/>
<line className="st15" x1="225.5" y1="257.8" x2="225.6" y2="257.8"/>
<polyline className="st15" points="225.7,257.8 225.8,257.8 226,257.8 "/>
<line className="st15" x1="223.7" y1="257.8" x2="223.8" y2="257.8"/>
<line className="st15" x1="223.9" y1="257.8" x2="223.9" y2="257.8"/>
<line className="st15" x1="225.6" y1="257.8" x2="225.6" y2="257.8"/>
<polyline className="st15" points="225.7,257.8 225.8,257.8 226,257.8 "/>
<polyline className="st15" points="223.6,257.9 223.7,257.9 223.8,257.9 "/>
<line className="st15" x1="223.9" y1="257.9" x2="223.9" y2="257.9"/>
<line className="st15" x1="225.6" y1="257.9" x2="225.6" y2="257.9"/>
<polyline className="st15" points="225.7,257.9 225.8,257.9 226,257.9 226.1,257.9 "/>
<polyline className="st15" points="223.6,257.9 223.7,257.9 223.8,257.9 "/>
<line className="st15" x1="223.9" y1="257.9" x2="223.9" y2="257.9"/>
<polyline className="st15" points="225.7,257.9 225.8,257.9 226,257.9 226.1,257.9 "/>
<polyline className="st15" points="223.6,257.9 223.7,257.9 223.8,257.9 "/>
<line className="st15" x1="223.9" y1="257.9" x2="223.9" y2="257.9"/>
<polyline className="st15" points="225.7,257.9 225.8,257.9 226,257.9 226.1,257.9 226.2,257.9 "/>
<polyline className="st15" points="223.6,258 223.7,258 223.8,258 "/>
<line className="st15" x1="223.9" y1="258" x2="223.9" y2="258"/>
<polyline className="st15" points="225.7,258 225.8,258 226,258 226.1,258 226.2,258 "/>
<polyline className="st15" points="223.6,258 223.7,258 223.8,258 "/>
<polyline className="st15" points="225.7,258 225.8,258 226,258 226.1,258 226.2,258 "/>
<polyline className="st15" points="223.6,258 223.7,258 223.8,258 "/>
<polyline className="st15" points="225.8,258 226,258 226.1,258 226.2,258 "/>
<line className="st15" x1="226.3" y1="258" x2="226.3" y2="258"/>
<polyline className="st15" points="223.6,258.2 223.7,258.2 223.8,258.2 "/>
<polyline className="st15" points="225.8,258.2 226,258.2 226.1,258.2 226.2,258.2 "/>
<line className="st15" x1="226.3" y1="258.2" x2="226.3" y2="258.2"/>
<polyline className="st15" points="223.6,258.2 223.7,258.2 223.8,258.2 "/>
<polyline className="st15" points="225.8,258.2 226,258.2 226.1,258.2 226.2,258.2 "/>
<line className="st15" x1="226.3" y1="258.2" x2="226.4" y2="258.2"/>
<polyline className="st15" points="223.6,258.3 223.7,258.3 223.8,258.3 "/>
<polyline className="st15" points="225.8,258.3 226,258.3 226.1,258.3 226.2,258.3 "/>
<line className="st15" x1="226.3" y1="258.3" x2="226.4" y2="258.3"/>
<polyline className="st15" points="223.6,258.3 223.7,258.3 223.8,258.3 "/>
<polyline className="st15" points="225.8,258.3 226,258.3 226.1,258.3 226.2,258.3 "/>
<line className="st15" x1="226.3" y1="258.3" x2="226.4" y2="258.3"/>
<polyline className="st15" points="223.6,258.3 223.7,258.3 223.8,258.3 "/>
<polyline className="st15" points="226,258.3 226.1,258.3 226.2,258.3 "/>
<polyline className="st15" points="226.3,258.3 226.4,258.3 226.6,258.3 "/>
<polyline className="st15" points="223.6,258.4 223.7,258.4 223.8,258.4 "/>
<polyline className="st15" points="226,258.4 226.1,258.4 226.2,258.4 "/>
<polyline className="st15" points="226.3,258.4 226.4,258.4 226.6,258.4 "/>
<polyline className="st15" points="223.6,258.4 223.7,258.4 223.8,258.4 "/>
<polyline className="st15" points="226,258.4 226.1,258.4 226.2,258.4 "/>
<polyline className="st15" points="226.3,258.4 226.4,258.4 226.6,258.4 "/>
<polyline className="st15" points="223.6,258.5 223.7,258.5 223.8,258.5 "/>
<polyline className="st15" points="226,258.5 226.1,258.5 226.2,258.5 "/>
<polyline className="st15" points="226.3,258.5 226.4,258.5 226.6,258.5 226.7,258.5 "/>
<polyline className="st15" points="223.6,258.5 223.7,258.5 223.8,258.5 "/>
<polyline className="st15" points="226,258.5 226.1,258.5 226.2,258.5 "/>
<polyline className="st15" points="226.3,258.5 226.4,258.5 226.6,258.5 226.7,258.5 "/>
<polyline className="st15" points="223.6,258.5 223.7,258.5 223.8,258.5 "/>
<line className="st15" x1="223.9" y1="258.5" x2="223.9" y2="258.5"/>
<polyline className="st15" points="226,258.5 226.1,258.5 226.2,258.5 "/>
<polyline className="st15" points="226.3,258.5 226.4,258.5 226.6,258.5 226.7,258.5 226.8,258.5 "/>
<polyline className="st15" points="223.6,258.6 223.7,258.6 223.8,258.6 "/>
<line className="st15" x1="223.9" y1="258.6" x2="223.9" y2="258.6"/>
<polyline className="st15" points="226,258.6 226.1,258.6 226.2,258.6 "/>
<polyline className="st15" points="226.3,258.6 226.4,258.6 226.6,258.6 226.7,258.6 226.8,258.6 "/>
<polyline className="st15" points="223.6,258.6 223.7,258.6 223.8,258.6 "/>
<line className="st15" x1="223.9" y1="258.6" x2="223.9" y2="258.6"/>
<polyline className="st15" points="226,258.6 226.1,258.6 226.2,258.6 "/>
<polyline className="st15" points="226.3,258.6 226.4,258.6 226.6,258.6 226.7,258.6 226.8,258.6 "/>
<polyline className="st15" points="223.6,258.6 223.7,258.6 223.8,258.6 "/>
<line className="st15" x1="223.9" y1="258.6" x2="223.9" y2="258.6"/>
<polyline className="st15" points="226,258.6 226.1,258.6 226.2,258.6 "/>
<polyline className="st15" points="226.3,258.6 226.4,258.6 226.6,258.6 226.7,258.6 226.8,258.6 "/>
<line className="st15" x1="226.9" y1="258.6" x2="226.9" y2="258.6"/>
<line className="st15" x1="223.7" y1="258.8" x2="223.8" y2="258.8"/>
<line className="st15" x1="223.9" y1="258.8" x2="223.9" y2="258.8"/>
<polyline className="st15" points="226,258.8 226.1,258.8 226.2,258.8 "/>
<polyline className="st15" points="226.3,258.8 226.4,258.8 226.6,258.8 226.7,258.8 226.8,258.8 "/>
<line className="st15" x1="226.9" y1="258.8" x2="226.9" y2="258.8"/>
<line className="st15" x1="223.7" y1="258.8" x2="223.8" y2="258.8"/>
<line className="st15" x1="223.9" y1="258.8" x2="224" y2="258.8"/>
<polyline className="st15" points="226,258.8 226.1,258.8 226.2,258.8 "/>
<polyline className="st15" points="226.3,258.8 226.4,258.8 226.6,258.8 226.7,258.8 226.8,258.8 "/>
<line className="st15" x1="226.9" y1="258.8" x2="227" y2="258.8"/>
<line className="st15" x1="223.7" y1="258.9" x2="223.8" y2="258.9"/>
<line className="st15" x1="223.9" y1="258.9" x2="224" y2="258.9"/>
<polyline className="st15" points="226,258.9 226.1,258.9 226.2,258.9 "/>
<polyline className="st15" points="226.3,258.9 226.4,258.9 226.6,258.9 226.7,258.9 226.8,258.9 "/>
<line className="st15" x1="226.9" y1="258.9" x2="227" y2="258.9"/>
<line className="st15" x1="223.7" y1="258.9" x2="223.8" y2="258.9"/>
<line className="st15" x1="223.9" y1="258.9" x2="224" y2="258.9"/>
<polyline className="st15" points="226,258.9 226.1,258.9 226.2,258.9 "/>
<polyline className="st15" points="226.3,258.9 226.4,258.9 226.6,258.9 226.7,258.9 226.8,258.9 "/>
<line className="st15" x1="226.9" y1="258.9" x2="227" y2="258.9"/>
<line className="st15" x1="223.7" y1="258.9" x2="223.8" y2="258.9"/>
<line className="st15" x1="223.9" y1="258.9" x2="224" y2="258.9"/>
<polyline className="st15" points="225.8,258.9 226,258.9 226.1,258.9 226.2,258.9 "/>
<polyline className="st15" points="226.3,258.9 226.4,258.9 226.6,258.9 226.7,258.9 226.8,258.9 "/>
<polyline className="st15" points="226.9,258.9 227,258.9 227.2,258.9 "/>
<line className="st15" x1="223.8" y1="259" x2="223.8" y2="259"/>
<polyline className="st15" points="223.9,259 224,259 224.2,259 "/>
<polyline className="st15" points="225.8,259 226,259 226.1,259 226.2,259 "/>
<polyline className="st15" points="226.3,259 226.4,259 226.6,259 226.7,259 226.8,259 "/>
<polyline className="st15" points="226.9,259 227,259 227.2,259 "/>
<line className="st15" x1="223.8" y1="259" x2="223.8" y2="259"/>
<polyline className="st15" points="223.9,259 224,259 224.2,259 "/>
<polyline className="st15" points="225.8,259 226,259 226.1,259 226.2,259 "/>
<polyline className="st15" points="226.3,259 226.4,259 226.6,259 226.7,259 226.8,259 "/>
<polyline className="st15" points="226.9,259 227,259 227.2,259 "/>
<line className="st15" x1="223.8" y1="259.1" x2="223.8" y2="259.1"/>
<polyline className="st15" points="223.9,259.1 224,259.1 224.2,259.1 224.3,259.1 "/>
<polyline className="st15" points="225.8,259.1 226,259.1 226.1,259.1 226.2,259.1 "/>
<polyline className="st15" points="226.3,259.1 226.4,259.1 226.6,259.1 226.7,259.1 226.8,259.1 "/>
<polyline className="st15" points="226.9,259.1 227,259.1 227.2,259.1 227.3,259.1 "/>
<polyline className="st15" points="223.9,259.1 224,259.1 224.2,259.1 224.3,259.1 "/>
<polyline className="st15" points="225.8,259.1 226,259.1 226.1,259.1 226.2,259.1 "/>
<polyline className="st15" points="226.3,259.1 226.4,259.1 226.6,259.1 226.7,259.1 226.8,259.1 "/>
<polyline className="st15" points="226.9,259.1 227,259.1 227.2,259.1 227.3,259.1 "/>
<polyline className="st15" points="223.9,259.1 224,259.1 224.2,259.1 224.3,259.1 224.4,259.1 "/>
<polyline className="st15" points="225.7,259.1 225.8,259.1 226,259.1 226.1,259.1 226.2,259.1 "/>
<polyline className="st15" points="226.3,259.1 226.4,259.1 226.6,259.1 226.7,259.1 226.8,259.1 "/>
<polyline className="st15" points="226.9,259.1 227,259.1 227.2,259.1 227.3,259.1 227.4,259.1 "/>
<polyline className="st15" points="223.9,259.2 224,259.2 224.2,259.2 224.3,259.2 224.4,259.2 "/>
<polyline className="st15" points="225.7,259.2 225.8,259.2 226,259.2 226.1,259.2 226.2,259.2 "/>
<polyline className="st15" points="226.3,259.2 226.4,259.2 226.6,259.2 226.7,259.2 226.8,259.2 "/>
<polyline className="st15" points="226.9,259.2 227,259.2 227.2,259.2 227.3,259.2 227.4,259.2 "/>
<polyline className="st15" points="224,259.2 224.2,259.2 224.3,259.2 224.4,259.2 "/>
<polyline className="st15" points="225.7,259.2 225.8,259.2 226,259.2 226.1,259.2 226.2,259.2 "/>
<polyline className="st15" points="226.3,259.2 226.4,259.2 226.6,259.2 226.7,259.2 226.8,259.2 "/>
<polyline className="st15" points="226.9,259.2 227,259.2 227.2,259.2 227.3,259.2 227.4,259.2 "/>
<polyline className="st15" points="224,259.2 224.2,259.2 224.3,259.2 224.4,259.2 "/>
<line className="st15" x1="224.5" y1="259.2" x2="224.5" y2="259.2"/>
<line className="st15" x1="225.6" y1="259.2" x2="225.6" y2="259.2"/>
<polyline className="st15" points="225.7,259.2 225.8,259.2 226,259.2 226.1,259.2 226.2,259.2 "/>
<polyline className="st15" points="226.3,259.2 226.4,259.2 226.6,259.2 226.7,259.2 226.8,259.2 "/>
<polyline className="st15" points="226.9,259.2 227,259.2 227.2,259.2 227.3,259.2 227.4,259.2 "/>
<line className="st15" x1="227.5" y1="259.2" x2="227.5" y2="259.2"/>
<polyline className="st15" points="224,259.4 224.2,259.4 224.3,259.4 224.4,259.4 "/>
<line className="st15" x1="224.5" y1="259.4" x2="224.6" y2="259.4"/>
<line className="st15" x1="225.6" y1="259.4" x2="225.6" y2="259.4"/>
<polyline className="st15" points="225.7,259.4 225.8,259.4 226,259.4 226.1,259.4 226.2,259.4 "/>
<polyline className="st15" points="226.3,259.4 226.4,259.4 226.6,259.4 226.7,259.4 226.8,259.4 "/>
<polyline className="st15" points="226.9,259.4 227,259.4 227.2,259.4 227.3,259.4 227.4,259.4 "/>
<line className="st15" x1="227.5" y1="259.4" x2="227.5" y2="259.4"/>
<polyline className="st15" points="224.2,259.4 224.3,259.4 224.4,259.4 "/>
<line className="st15" x1="224.5" y1="259.4" x2="224.6" y2="259.4"/>
<line className="st15" x1="225.5" y1="259.4" x2="225.6" y2="259.4"/>
<polyline className="st15" points="225.7,259.4 225.8,259.4 226,259.4 226.1,259.4 226.2,259.4 "/>
<polyline className="st15" points="226.3,259.4 226.4,259.4 226.6,259.4 226.7,259.4 226.8,259.4 "/>
<polyline className="st15" points="226.9,259.4 227,259.4 227.2,259.4 227.3,259.4 227.4,259.4 "/>
<line className="st15" x1="227.5" y1="259.4" x2="227.6" y2="259.4"/>
<polyline className="st15" points="224.2,259.5 224.3,259.5 224.4,259.5 "/>
<polyline className="st15" points="224.5,259.5 224.6,259.5 224.8,259.5 "/>
<polyline className="st15" points="225.4,259.5 225.5,259.5 225.6,259.5 "/>
<polyline className="st15" points="225.7,259.5 225.8,259.5 226,259.5 226.1,259.5 226.2,259.5 "/>
<polyline className="st15" points="226.3,259.5 226.4,259.5 226.6,259.5 226.7,259.5 226.8,259.5 "/>
<polyline className="st15" points="226.9,259.5 227,259.5 227.2,259.5 227.3,259.5 227.4,259.5 "/>
<line className="st15" x1="227.5" y1="259.5" x2="227.6" y2="259.5"/>
<line className="st15" x1="224.3" y1="259.5" x2="224.4" y2="259.5"/>
<polyline className="st15" points="224.5,259.5 224.6,259.5 224.8,259.5 224.9,259.5 225,259.5 "/>
<polyline className="st15" points="225.1,259.5 225.2,259.5 225.4,259.5 225.5,259.5 225.6,259.5 "/>
<polyline className="st15" points="225.7,259.5 225.8,259.5 226,259.5 226.1,259.5 226.2,259.5 "/>
<polyline className="st15" points="226.3,259.5 226.4,259.5 226.6,259.5 226.7,259.5 226.8,259.5 "/>
<polyline className="st15" points="226.9,259.5 227,259.5 227.2,259.5 227.3,259.5 227.4,259.5 "/>
<line className="st15" x1="227.5" y1="259.5" x2="227.6" y2="259.5"/>
<line className="st15" x1="224.3" y1="259.5" x2="224.4" y2="259.5"/>
<polyline className="st15" points="224.5,259.5 224.6,259.5 224.8,259.5 224.9,259.5 225,259.5 "/>
<polyline className="st15" points="225.1,259.5 225.2,259.5 225.4,259.5 225.5,259.5 225.6,259.5 "/>
<polyline className="st15" points="225.7,259.5 225.8,259.5 226,259.5 226.1,259.5 226.2,259.5 "/>
<polyline className="st15" points="226.3,259.5 226.4,259.5 226.6,259.5 226.7,259.5 226.8,259.5 "/>
<polyline className="st15" points="226.9,259.5 227,259.5 227.2,259.5 227.3,259.5 227.4,259.5 "/>
<line className="st15" x1="227.5" y1="259.5" x2="227.6" y2="259.5"/>
<line className="st15" x1="224.3" y1="259.6" x2="224.4" y2="259.6"/>
<polyline className="st15" points="224.5,259.6 224.6,259.6 224.8,259.6 224.9,259.6 225,259.6 "/>
<polyline className="st15" points="225.1,259.6 225.2,259.6 225.4,259.6 225.5,259.6 225.6,259.6 "/>
<polyline className="st15" points="225.7,259.6 225.8,259.6 226,259.6 226.1,259.6 226.2,259.6 "/>
<polyline className="st15" points="226.3,259.6 226.4,259.6 226.6,259.6 226.7,259.6 226.8,259.6 "/>
<polyline className="st15" points="226.9,259.6 227,259.6 227.2,259.6 227.3,259.6 227.4,259.6 "/>
<line className="st15" x1="227.5" y1="259.6" x2="227.5" y2="259.6"/>
<line className="st15" x1="224.4" y1="259.6" x2="224.4" y2="259.6"/>
<polyline className="st15" points="224.5,259.6 224.6,259.6 224.8,259.6 224.9,259.6 225,259.6 "/>
<polyline className="st15" points="225.1,259.6 225.2,259.6 225.4,259.6 225.5,259.6 225.6,259.6 "/>
<polyline className="st15" points="225.7,259.6 225.8,259.6 226,259.6 226.1,259.6 226.2,259.6 "/>
<polyline className="st15" points="226.3,259.6 226.4,259.6 226.6,259.6 226.7,259.6 226.8,259.6 "/>
<polyline className="st15" points="226.9,259.6 227,259.6 227.2,259.6 227.3,259.6 227.4,259.6 "/>
<line className="st15" x1="227.5" y1="259.6" x2="227.5" y2="259.6"/>
<line className="st15" x1="224.4" y1="259.7" x2="224.4" y2="259.7"/>
<polyline className="st15" points="224.5,259.7 224.6,259.7 224.8,259.7 224.9,259.7 225,259.7 "/>
<polyline className="st15" points="225.1,259.7 225.2,259.7 225.4,259.7 225.5,259.7 225.6,259.7 "/>
<polyline className="st15" points="225.7,259.7 225.8,259.7 226,259.7 226.1,259.7 226.2,259.7 "/>
<polyline className="st15" points="226.3,259.7 226.4,259.7 226.6,259.7 226.7,259.7 226.8,259.7 "/>
<polyline className="st15" points="226.9,259.7 227,259.7 227.2,259.7 227.3,259.7 227.4,259.7 "/>
<line className="st15" x1="227.5" y1="259.7" x2="227.5" y2="259.7"/>
<polyline className="st15" points="224.5,259.7 224.6,259.7 224.8,259.7 224.9,259.7 225,259.7 "/>
<polyline className="st15" points="225.1,259.7 225.2,259.7 225.4,259.7 225.5,259.7 225.6,259.7 "/>
<polyline className="st15" points="225.7,259.7 225.8,259.7 226,259.7 226.1,259.7 226.2,259.7 "/>
<polyline className="st15" points="226.3,259.7 226.4,259.7 226.6,259.7 226.7,259.7 226.8,259.7 "/>
<polyline className="st15" points="226.9,259.7 227,259.7 227.2,259.7 227.3,259.7 227.4,259.7 "/>
<polyline className="st15" points="224.5,259.7 224.6,259.7 224.8,259.7 224.9,259.7 225,259.7 "/>
<polyline className="st15" points="225.1,259.7 225.2,259.7 225.4,259.7 225.5,259.7 225.6,259.7 "/>
<polyline className="st15" points="225.7,259.7 225.8,259.7 226,259.7 226.1,259.7 226.2,259.7 "/>
<polyline className="st15" points="226.3,259.7 226.4,259.7 226.6,259.7 226.7,259.7 226.8,259.7 "/>
<polyline className="st15" points="226.9,259.7 227,259.7 227.2,259.7 227.3,259.7 227.4,259.7 "/>
<polyline className="st15" points="224.5,259.8 224.6,259.8 224.8,259.8 224.9,259.8 225,259.8 "/>
<polyline className="st15" points="225.1,259.8 225.2,259.8 225.4,259.8 225.5,259.8 225.6,259.8 "/>
<polyline className="st15" points="225.7,259.8 225.8,259.8 226,259.8 226.1,259.8 226.2,259.8 "/>
<polyline className="st15" points="226.3,259.8 226.4,259.8 226.6,259.8 226.7,259.8 226.8,259.8 "/>
<polyline className="st15" points="226.9,259.8 227,259.8 227.2,259.8 227.3,259.8 227.4,259.8 "/>
<polyline className="st15" points="224.6,259.8 224.8,259.8 224.9,259.8 225,259.8 "/>
<polyline className="st15" points="225.1,259.8 225.2,259.8 225.4,259.8 225.5,259.8 225.6,259.8 "/>
<polyline className="st15" points="225.7,259.8 225.8,259.8 226,259.8 226.1,259.8 226.2,259.8 "/>
<polyline className="st15" points="226.3,259.8 226.4,259.8 226.6,259.8 226.7,259.8 226.8,259.8 "/>
<polyline className="st15" points="226.9,259.8 227,259.8 227.2,259.8 227.3,259.8 "/>
<polyline className="st15" points="224.6,259.8 224.8,259.8 224.9,259.8 225,259.8 "/>
<polyline className="st15" points="225.1,259.8 225.2,259.8 225.4,259.8 225.5,259.8 225.6,259.8 "/>
<polyline className="st15" points="225.7,259.8 225.8,259.8 226,259.8 226.1,259.8 226.2,259.8 "/>
<polyline className="st15" points="226.3,259.8 226.4,259.8 226.6,259.8 226.7,259.8 226.8,259.8 "/>
<polyline className="st15" points="226.9,259.8 227,259.8 227.2,259.8 227.3,259.8 "/>
<polyline className="st15" points="224.6,260 224.8,260 224.9,260 225,260 "/>
<polyline className="st15" points="225.1,260 225.2,260 225.4,260 225.5,260 225.6,260 "/>
<polyline className="st15" points="225.7,260 225.8,260 226,260 226.1,260 226.2,260 "/>
<polyline className="st15" points="226.3,260 226.4,260 226.6,260 226.7,260 226.8,260 "/>
<polyline className="st15" points="226.9,260 227,260 227.2,260 "/>
<polyline className="st15" points="224.8,260 224.9,260 225,260 "/>
<polyline className="st15" points="225.1,260 225.2,260 225.4,260 225.5,260 225.6,260 "/>
<polyline className="st15" points="225.7,260 225.8,260 226,260 226.1,260 226.2,260 "/>
<polyline className="st15" points="226.3,260 226.4,260 226.6,260 226.7,260 226.8,260 "/>
<polyline className="st15" points="226.9,260 227,260 227.2,260 "/>
<polyline className="st15" points="224.8,260.1 224.9,260.1 225,260.1 "/>
<polyline className="st15" points="225.1,260.1 225.2,260.1 225.4,260.1 225.5,260.1 225.6,260.1 "/>
<polyline className="st15" points="225.7,260.1 225.8,260.1 226,260.1 226.1,260.1 226.2,260.1 "/>
<polyline className="st15" points="226.3,260.1 226.4,260.1 226.6,260.1 226.7,260.1 226.8,260.1 "/>
<polyline className="st15" points="226.9,260.1 227,260.1 227.2,260.1 "/>
<line className="st15" x1="224.9" y1="260.1" x2="225" y2="260.1"/>
<polyline className="st15" points="225.1,260.1 225.2,260.1 225.4,260.1 225.5,260.1 225.6,260.1 "/>
<polyline className="st15" points="225.7,260.1 225.8,260.1 226,260.1 226.1,260.1 226.2,260.1 "/>
<polyline className="st15" points="226.3,260.1 226.4,260.1 226.6,260.1 226.7,260.1 226.8,260.1 "/>
<line className="st15" x1="226.9" y1="260.1" x2="227" y2="260.1"/>
<line className="st15" x1="224.9" y1="260.1" x2="225" y2="260.1"/>
<polyline className="st15" points="225.1,260.1 225.2,260.1 225.4,260.1 225.5,260.1 225.6,260.1 "/>
<polyline className="st15" points="225.7,260.1 225.8,260.1 226,260.1 226.1,260.1 226.2,260.1 "/>
<polyline className="st15" points="226.3,260.1 226.4,260.1 226.6,260.1 226.7,260.1 226.8,260.1 "/>
<line className="st15" x1="226.9" y1="260.1" x2="227" y2="260.1"/>
<line className="st15" x1="224.9" y1="260.2" x2="225" y2="260.2"/>
<polyline className="st15" points="225.1,260.2 225.2,260.2 225.4,260.2 225.5,260.2 225.6,260.2 "/>
<polyline className="st15" points="225.7,260.2 225.8,260.2 226,260.2 226.1,260.2 226.2,260.2 "/>
<polyline className="st15" points="226.3,260.2 226.4,260.2 226.6,260.2 226.7,260.2 226.8,260.2 "/>
<line className="st15" x1="226.9" y1="260.2" x2="226.9" y2="260.2"/>
<line className="st15" x1="225" y1="260.2" x2="225" y2="260.2"/>
<polyline className="st15" points="225.1,260.2 225.2,260.2 225.4,260.2 225.5,260.2 225.6,260.2 "/>
<polyline className="st15" points="225.7,260.2 225.8,260.2 226,260.2 226.1,260.2 226.2,260.2 "/>
<polyline className="st15" points="226.3,260.2 226.4,260.2 226.6,260.2 226.7,260.2 226.8,260.2 "/>
<line className="st15" x1="226.9" y1="260.2" x2="226.9" y2="260.2"/>
<line className="st15" x1="225" y1="260.3" x2="225" y2="260.3"/>
<polyline className="st15" points="225.1,260.3 225.2,260.3 225.4,260.3 225.5,260.3 225.6,260.3 "/>
<polyline className="st15" points="225.7,260.3 225.8,260.3 226,260.3 226.1,260.3 226.2,260.3 "/>
<polyline className="st15" points="226.3,260.3 226.4,260.3 226.6,260.3 226.7,260.3 226.8,260.3 "/>
<line className="st15" x1="226.9" y1="260.3" x2="226.9" y2="260.3"/>
<polyline className="st15" points="225.1,260.3 225.2,260.3 225.4,260.3 225.5,260.3 225.6,260.3 "/>
<polyline className="st15" points="225.7,260.3 225.8,260.3 226,260.3 226.1,260.3 226.2,260.3 "/>
<polyline className="st15" points="226.3,260.3 226.4,260.3 226.6,260.3 226.7,260.3 226.8,260.3 "/>
<polyline className="st15" points="225.1,260.3 225.2,260.3 225.4,260.3 225.5,260.3 225.6,260.3 "/>
<polyline className="st15" points="225.7,260.3 225.8,260.3 226,260.3 226.1,260.3 226.2,260.3 "/>
<polyline className="st15" points="226.3,260.3 226.4,260.3 226.6,260.3 226.7,260.3 226.8,260.3 "/>
<polyline className="st15" points="225.1,260.4 225.2,260.4 225.4,260.4 225.5,260.4 225.6,260.4 "/>
<polyline className="st15" points="225.7,260.4 225.8,260.4 226,260.4 226.1,260.4 226.2,260.4 "/>
<polyline className="st15" points="226.3,260.4 226.4,260.4 226.6,260.4 226.7,260.4 226.8,260.4 "/>
<polyline className="st15" points="225.2,260.4 225.4,260.4 225.5,260.4 225.6,260.4 "/>
<polyline className="st15" points="225.7,260.4 225.8,260.4 226,260.4 226.1,260.4 226.2,260.4 "/>
<polyline className="st15" points="226.3,260.4 226.4,260.4 226.6,260.4 226.7,260.4 "/>
<polyline className="st15" points="225.2,260.4 225.4,260.4 225.5,260.4 225.6,260.4 "/>
<polyline className="st15" points="225.7,260.4 225.8,260.4 226,260.4 226.1,260.4 226.2,260.4 "/>
<polyline className="st15" points="226.3,260.4 226.4,260.4 226.6,260.4 226.7,260.4 "/>
<polyline className="st15" points="225.2,260.6 225.4,260.6 225.5,260.6 225.6,260.6 "/>
<polyline className="st15" points="225.7,260.6 225.8,260.6 226,260.6 226.1,260.6 226.2,260.6 "/>
<polyline className="st15" points="226.3,260.6 226.4,260.6 226.6,260.6 "/>
<polyline className="st15" points="225.4,260.6 225.5,260.6 225.6,260.6 "/>
<polyline className="st15" points="225.7,260.6 225.8,260.6 226,260.6 226.1,260.6 226.2,260.6 "/>
<polyline className="st15" points="226.3,260.6 226.4,260.6 226.6,260.6 "/>
<polyline className="st15" points="225.4,260.7 225.5,260.7 225.6,260.7 "/>
<polyline className="st15" points="225.7,260.7 225.8,260.7 226,260.7 226.1,260.7 226.2,260.7 "/>
<polyline className="st15" points="226.3,260.7 226.4,260.7 226.6,260.7 "/>
<line className="st15" x1="225.5" y1="260.7" x2="225.6" y2="260.7"/>
<polyline className="st15" points="225.7,260.7 225.8,260.7 226,260.7 226.1,260.7 226.2,260.7 "/>
<line className="st15" x1="226.3" y1="260.7" x2="226.4" y2="260.7"/>
<line className="st15" x1="225.5" y1="260.7" x2="225.6" y2="260.7"/>
<polyline className="st15" points="225.7,260.7 225.8,260.7 226,260.7 226.1,260.7 226.2,260.7 "/>
<line className="st15" x1="226.3" y1="260.7" x2="226.4" y2="260.7"/>
<line className="st15" x1="225.5" y1="260.8" x2="225.6" y2="260.8"/>
<polyline className="st15" points="225.7,260.8 225.8,260.8 226,260.8 226.1,260.8 226.2,260.8 "/>
<line className="st15" x1="226.3" y1="260.8" x2="226.4" y2="260.8"/>
<line className="st15" x1="225.6" y1="260.8" x2="225.6" y2="260.8"/>
<polyline className="st15" points="225.7,260.8 225.8,260.8 226,260.8 226.1,260.8 226.2,260.8 "/>
<line className="st15" x1="226.3" y1="260.8" x2="226.3" y2="260.8"/>
<line className="st15" x1="225.6" y1="260.9" x2="225.6" y2="260.9"/>
<polyline className="st15" points="225.7,260.9 225.8,260.9 226,260.9 226.1,260.9 226.2,260.9 "/>
<line className="st15" x1="226.3" y1="260.9" x2="226.3" y2="260.9"/>
<polyline className="st15" points="226.2,260.9 226.1,260.9 226,260.9 225.8,260.9 225.7,260.9 225.8,260.9 226,260.9 226.1,260.9 
	226.2,260.9 "/>
<polyline className="st15" points="225.7,261 225.8,261 226,261 226.1,261 226.2,261 "/>
<polyline className="st15" points="226.1,261 226,261 225.8,261 226,261 226.1,261 "/>
<line className="st15" x1="225.8" y1="261.2" x2="226" y2="261.2"/>
<line className="st15" x1="223.6" y1="258.4" x2="223.6" y2="258.4"/>
<line className="st15" x1="223.6" y1="258.3" x2="223.6" y2="258.2"/>
<polyline className="st15" points="223.6,258.6 223.6,258.5 223.6,258.4 "/>
<line className="st15" x1="223.6" y1="258.3" x2="223.6" y2="258.2"/>
<line className="st15" x1="223.6" y1="258" x2="223.6" y2="257.9"/>
<line className="st15" x1="223.7" y1="258.8" x2="223.7" y2="258.8"/>
<polyline className="st15" points="223.7,258.6 223.7,258.5 223.7,258.4 "/>
<line className="st15" x1="223.7" y1="258.3" x2="223.7" y2="258.2"/>
<polyline className="st15" points="223.7,258 223.7,257.9 223.7,257.8 "/>
<line className="st15" x1="223.7" y1="258.9" x2="223.7" y2="258.8"/>
<polyline className="st15" points="223.7,258.6 223.7,258.5 223.7,258.4 "/>
<line className="st15" x1="223.7" y1="258.3" x2="223.7" y2="258.2"/>
<polyline className="st15" points="223.7,258 223.7,257.9 223.7,257.8 "/>
<line className="st15" x1="223.7" y1="257.7" x2="223.7" y2="257.7"/>
<line className="st15" x1="223.7" y1="259" x2="223.7" y2="259"/>
<line className="st15" x1="223.7" y1="258.9" x2="223.7" y2="258.8"/>
<polyline className="st15" points="223.7,258.6 223.7,258.5 223.7,258.4 "/>
<line className="st15" x1="223.7" y1="258.3" x2="223.7" y2="258.2"/>
<polyline className="st15" points="223.7,258 223.7,257.9 223.7,257.8 "/>
<line className="st15" x1="223.7" y1="257.7" x2="223.7" y2="257.7"/>
<line className="st15" x1="223.8" y1="259" x2="223.8" y2="259"/>
<line className="st15" x1="223.8" y1="258.9" x2="223.8" y2="258.8"/>
<polyline className="st15" points="223.8,258.6 223.8,258.5 223.8,258.4 "/>
<line className="st15" x1="223.8" y1="258.3" x2="223.8" y2="258.2"/>
<polyline className="st15" points="223.8,258 223.8,257.9 223.8,257.8 "/>
<line className="st15" x1="223.8" y1="257.7" x2="223.8" y2="257.6"/>
<line className="st15" x1="223.8" y1="259.1" x2="223.8" y2="259"/>
<line className="st15" x1="223.8" y1="258.9" x2="223.8" y2="258.8"/>
<polyline className="st15" points="223.8,258.6 223.8,258.5 223.8,258.4 "/>
<line className="st15" x1="223.8" y1="258.3" x2="223.8" y2="258.2"/>
<polyline className="st15" points="223.8,258 223.8,257.9 223.8,257.8 "/>
<line className="st15" x1="223.8" y1="257.7" x2="223.8" y2="257.6"/>
<line className="st15" x1="223.9" y1="259.1" x2="223.9" y2="259"/>
<line className="st15" x1="223.9" y1="258.9" x2="223.9" y2="258.8"/>
<line className="st15" x1="223.9" y1="258.6" x2="223.9" y2="258.5"/>
<polyline className="st15" points="223.9,258 223.9,257.9 223.9,257.8 "/>
<line className="st15" x1="223.9" y1="257.7" x2="223.9" y2="257.6"/>
<line className="st15" x1="223.9" y1="257.4" x2="223.9" y2="257.4"/>
<line className="st15" x1="223.9" y1="259.1" x2="223.9" y2="259"/>
<line className="st15" x1="223.9" y1="258.9" x2="223.9" y2="258.8"/>
<line className="st15" x1="223.9" y1="258.6" x2="223.9" y2="258.6"/>
<line className="st15" x1="223.9" y1="257.9" x2="223.9" y2="257.8"/>
<line className="st15" x1="223.9" y1="257.7" x2="223.9" y2="257.6"/>
<line className="st15" x1="223.9" y1="257.4" x2="223.9" y2="257.4"/>
<polyline className="st15" points="223.9,259.2 223.9,259.1 223.9,259 "/>
<line className="st15" x1="223.9" y1="258.9" x2="223.9" y2="258.8"/>
<line className="st15" x1="223.9" y1="257.8" x2="223.9" y2="257.8"/>
<line className="st15" x1="223.9" y1="257.7" x2="223.9" y2="257.6"/>
<line className="st15" x1="223.9" y1="257.4" x2="223.9" y2="257.3"/>
<polyline className="st15" points="224,259.2 224,259.1 224,259 "/>
<line className="st15" x1="224" y1="258.9" x2="224" y2="258.9"/>
<line className="st15" x1="224" y1="257.8" x2="224" y2="257.8"/>
<line className="st15" x1="224" y1="257.7" x2="224" y2="257.6"/>
<line className="st15" x1="224" y1="257.4" x2="224" y2="257.3"/>
<polyline className="st15" points="224,259.2 224,259.1 224,259 "/>
<line className="st15" x1="224" y1="258.9" x2="224" y2="258.9"/>
<line className="st15" x1="224" y1="257.7" x2="224" y2="257.6"/>
<line className="st15" x1="224" y1="257.4" x2="224" y2="257.3"/>
<line className="st15" x1="224" y1="259.4" x2="224" y2="259.4"/>
<polyline className="st15" points="224,259.2 224,259.1 224,259 "/>
<line className="st15" x1="224" y1="257.7" x2="224" y2="257.6"/>
<line className="st15" x1="224" y1="257.4" x2="224" y2="257.3"/>
<line className="st15" x1="224.2" y1="259.4" x2="224.2" y2="259.4"/>
<polyline className="st15" points="224.2,259.2 224.2,259.1 224.2,259 "/>
<line className="st15" x1="224.2" y1="257.6" x2="224.2" y2="257.6"/>
<polyline className="st15" points="224.2,257.4 224.2,257.3 224.2,257.2 "/>
<line className="st15" x1="224.2" y1="259.5" x2="224.2" y2="259.4"/>
<polyline className="st15" points="224.2,259.2 224.2,259.1 224.2,259 "/>
<line className="st15" x1="224.2" y1="257.6" x2="224.2" y2="257.6"/>
<polyline className="st15" points="224.2,257.4 224.2,257.3 224.2,257.2 "/>
<line className="st15" x1="224.3" y1="259.5" x2="224.3" y2="259.4"/>
<polyline className="st15" points="224.3,259.2 224.3,259.1 224.3,259 "/>
<line className="st15" x1="224.3" y1="257.6" x2="224.3" y2="257.6"/>
<polyline className="st15" points="224.3,257.4 224.3,257.3 224.3,257.2 "/>
<line className="st15" x1="224.3" y1="259.5" x2="224.3" y2="259.4"/>
<line className="st15" x1="224.3" y1="259.2" x2="224.3" y2="259.1"/>
<polyline className="st15" points="224.3,257.4 224.3,257.3 224.3,257.2 "/>
<polyline className="st15" points="224.3,259.6 224.3,259.5 224.3,259.4 "/>
<line className="st15" x1="224.3" y1="259.2" x2="224.3" y2="259.1"/>
<polyline className="st15" points="224.3,257.4 224.3,257.3 224.3,257.2 "/>
<polyline className="st15" points="224.4,259.6 224.4,259.5 224.4,259.4 "/>
<line className="st15" x1="224.4" y1="259.2" x2="224.4" y2="259.1"/>
<polyline className="st15" points="224.4,257.4 224.4,257.3 224.4,257.2 "/>
<line className="st15" x1="224.4" y1="257.1" x2="224.4" y2="257.1"/>
<polyline className="st15" points="224.4,259.7 224.4,259.6 224.4,259.5 224.4,259.4 "/>
<line className="st15" x1="224.4" y1="259.2" x2="224.4" y2="259.2"/>
<polyline className="st15" points="224.4,257.4 224.4,257.3 224.4,257.2 "/>
<line className="st15" x1="224.4" y1="257.1" x2="224.4" y2="257.1"/>
<polyline className="st15" points="224.5,259.7 224.5,259.6 224.5,259.5 224.5,259.4 "/>
<line className="st15" x1="224.5" y1="259.2" x2="224.5" y2="259.2"/>
<polyline className="st15" points="224.5,257.4 224.5,257.3 224.5,257.2 "/>
<line className="st15" x1="224.5" y1="257.1" x2="224.5" y2="257.1"/>
<polyline className="st15" points="224.5,259.7 224.5,259.6 224.5,259.5 224.5,259.4 "/>
<line className="st15" x1="224.5" y1="259.2" x2="224.5" y2="259.2"/>
<polyline className="st15" points="224.5,257.4 224.5,257.3 224.5,257.2 "/>
<line className="st15" x1="224.5" y1="257.1" x2="224.5" y2="257.1"/>
<polyline className="st15" points="224.5,259.8 224.5,259.7 224.5,259.6 224.5,259.5 224.5,259.4 "/>
<polyline className="st15" points="224.5,257.4 224.5,257.3 224.5,257.2 "/>
<line className="st15" x1="224.5" y1="257.1" x2="224.5" y2="257.1"/>
<polyline className="st15" points="224.6,259.8 224.6,259.7 224.6,259.6 224.6,259.5 224.6,259.4 "/>
<line className="st15" x1="224.6" y1="257.3" x2="224.6" y2="257.2"/>
<line className="st15" x1="224.6" y1="257.1" x2="224.6" y2="257.1"/>
<polyline className="st15" points="224.6,259.8 224.6,259.7 224.6,259.6 224.6,259.5 224.6,259.4 "/>
<line className="st15" x1="224.6" y1="257.3" x2="224.6" y2="257.2"/>
<line className="st15" x1="224.6" y1="257.1" x2="224.6" y2="257.1"/>
<line className="st15" x1="224.6" y1="260" x2="224.6" y2="260"/>
<polyline className="st15" points="224.6,259.8 224.6,259.7 224.6,259.6 224.6,259.5 224.6,259.4 "/>
<line className="st15" x1="224.6" y1="257.3" x2="224.6" y2="257.2"/>
<line className="st15" x1="224.6" y1="257.1" x2="224.6" y2="257.1"/>
<line className="st15" x1="224.8" y1="260" x2="224.8" y2="260"/>
<polyline className="st15" points="224.8,259.8 224.8,259.7 224.8,259.6 224.8,259.5 "/>
<line className="st15" x1="224.8" y1="257.3" x2="224.8" y2="257.2"/>
<line className="st15" x1="224.8" y1="257.1" x2="224.8" y2="257.1"/>
<line className="st15" x1="224.8" y1="260.1" x2="224.8" y2="260"/>
<polyline className="st15" points="224.8,259.8 224.8,259.7 224.8,259.6 224.8,259.5 "/>
<line className="st15" x1="224.8" y1="257.3" x2="224.8" y2="257.2"/>
<line className="st15" x1="224.8" y1="257.1" x2="224.8" y2="257.1"/>
<line className="st15" x1="224.9" y1="260.1" x2="224.9" y2="260"/>
<polyline className="st15" points="224.9,259.8 224.9,259.7 224.9,259.6 224.9,259.5 "/>
<line className="st15" x1="224.9" y1="257.3" x2="224.9" y2="257.2"/>
<line className="st15" x1="224.9" y1="257.1" x2="224.9" y2="257.1"/>
<line className="st15" x1="224.9" y1="260.1" x2="224.9" y2="260"/>
<polyline className="st15" points="224.9,259.8 224.9,259.7 224.9,259.6 224.9,259.5 "/>
<line className="st15" x1="224.9" y1="257.3" x2="224.9" y2="257.2"/>
<line className="st15" x1="224.9" y1="257.1" x2="224.9" y2="257.1"/>
<polyline className="st15" points="224.9,260.2 224.9,260.1 224.9,260 "/>
<polyline className="st15" points="224.9,259.8 224.9,259.7 224.9,259.6 224.9,259.5 "/>
<line className="st15" x1="224.9" y1="257.3" x2="224.9" y2="257.2"/>
<line className="st15" x1="224.9" y1="257.1" x2="224.9" y2="257.1"/>
<polyline className="st15" points="225,260.2 225,260.1 225,260 "/>
<polyline className="st15" points="225,259.8 225,259.7 225,259.6 225,259.5 "/>
<polyline className="st15" points="225,257.4 225,257.3 225,257.2 "/>
<line className="st15" x1="225" y1="257.1" x2="225" y2="257.1"/>
<polyline className="st15" points="225,260.3 225,260.2 225,260.1 225,260 "/>
<polyline className="st15" points="225,259.8 225,259.7 225,259.6 225,259.5 "/>
<polyline className="st15" points="225,257.4 225,257.3 225,257.2 "/>
<line className="st15" x1="225" y1="257.1" x2="225" y2="257.1"/>
<polyline className="st15" points="225.1,260.3 225.1,260.2 225.1,260.1 225.1,260 "/>
<polyline className="st15" points="225.1,259.8 225.1,259.7 225.1,259.6 225.1,259.5 "/>
<polyline className="st15" points="225.1,257.4 225.1,257.3 225.1,257.2 "/>
<line className="st15" x1="225.1" y1="257.1" x2="225.1" y2="257.1"/>
<polyline className="st15" points="225.1,260.3 225.1,260.2 225.1,260.1 225.1,260 "/>
<polyline className="st15" points="225.1,259.8 225.1,259.7 225.1,259.6 225.1,259.5 "/>
<polyline className="st15" points="225.1,257.4 225.1,257.3 225.1,257.2 "/>
<line className="st15" x1="225.1" y1="257.1" x2="225.1" y2="257.1"/>
<polyline className="st15" points="225.1,260.4 225.1,260.3 225.1,260.2 225.1,260.1 225.1,260 "/>
<polyline className="st15" points="225.1,259.8 225.1,259.7 225.1,259.6 225.1,259.5 "/>
<polyline className="st15" points="225.1,257.4 225.1,257.3 225.1,257.2 "/>
<polyline className="st15" points="225.2,260.4 225.2,260.3 225.2,260.2 225.2,260.1 225.2,260 "/>
<polyline className="st15" points="225.2,259.8 225.2,259.7 225.2,259.6 225.2,259.5 "/>
<polyline className="st15" points="225.2,257.4 225.2,257.3 225.2,257.2 "/>
<polyline className="st15" points="225.2,260.4 225.2,260.3 225.2,260.2 225.2,260.1 225.2,260 "/>
<polyline className="st15" points="225.2,259.8 225.2,259.7 225.2,259.6 225.2,259.5 "/>
<polyline className="st15" points="225.2,257.4 225.2,257.3 225.2,257.2 "/>
<line className="st15" x1="225.2" y1="260.6" x2="225.2" y2="260.6"/>
<polyline className="st15" points="225.2,260.4 225.2,260.3 225.2,260.2 225.2,260.1 225.2,260 "/>
<polyline className="st15" points="225.2,259.8 225.2,259.7 225.2,259.6 225.2,259.5 "/>
<line className="st15" x1="225.2" y1="257.6" x2="225.2" y2="257.6"/>
<polyline className="st15" points="225.2,257.4 225.2,257.3 225.2,257.2 "/>
<line className="st15" x1="225.4" y1="260.6" x2="225.4" y2="260.6"/>
<polyline className="st15" points="225.4,260.4 225.4,260.3 225.4,260.2 225.4,260.1 225.4,260 "/>
<polyline className="st15" points="225.4,259.8 225.4,259.7 225.4,259.6 225.4,259.5 "/>
<line className="st15" x1="225.4" y1="257.6" x2="225.4" y2="257.6"/>
<polyline className="st15" points="225.4,257.4 225.4,257.3 225.4,257.2 "/>
<line className="st15" x1="225.4" y1="260.7" x2="225.4" y2="260.6"/>
<polyline className="st15" points="225.4,260.4 225.4,260.3 225.4,260.2 225.4,260.1 225.4,260 "/>
<polyline className="st15" points="225.4,259.8 225.4,259.7 225.4,259.6 225.4,259.5 225.4,259.4 "/>
<line className="st15" x1="225.4" y1="257.6" x2="225.4" y2="257.6"/>
<polyline className="st15" points="225.4,257.4 225.4,257.3 225.4,257.2 "/>
<line className="st15" x1="225.5" y1="260.7" x2="225.5" y2="260.6"/>
<polyline className="st15" points="225.5,260.4 225.5,260.3 225.5,260.2 225.5,260.1 225.5,260 "/>
<polyline className="st15" points="225.5,259.8 225.5,259.7 225.5,259.6 225.5,259.5 225.5,259.4 "/>
<line className="st15" x1="225.5" y1="257.7" x2="225.5" y2="257.6"/>
<line className="st15" x1="225.5" y1="257.4" x2="225.5" y2="257.3"/>
<line className="st15" x1="225.5" y1="260.7" x2="225.5" y2="260.6"/>
<polyline className="st15" points="225.5,260.4 225.5,260.3 225.5,260.2 225.5,260.1 225.5,260 "/>
<polyline className="st15" points="225.5,259.8 225.5,259.7 225.5,259.6 225.5,259.5 225.5,259.4 "/>
<line className="st15" x1="225.5" y1="257.7" x2="225.5" y2="257.6"/>
<line className="st15" x1="225.5" y1="257.4" x2="225.5" y2="257.3"/>
<polyline className="st15" points="225.5,260.8 225.5,260.7 225.5,260.6 "/>
<polyline className="st15" points="225.5,260.4 225.5,260.3 225.5,260.2 225.5,260.1 225.5,260 "/>
<polyline className="st15" points="225.5,259.8 225.5,259.7 225.5,259.6 225.5,259.5 225.5,259.4 "/>
<line className="st15" x1="225.5" y1="257.8" x2="225.5" y2="257.8"/>
<line className="st15" x1="225.5" y1="257.7" x2="225.5" y2="257.6"/>
<line className="st15" x1="225.5" y1="257.4" x2="225.5" y2="257.3"/>
<polyline className="st15" points="225.6,260.8 225.6,260.7 225.6,260.6 "/>
<polyline className="st15" points="225.6,260.4 225.6,260.3 225.6,260.2 225.6,260.1 225.6,260 "/>
<polyline className="st15" points="225.6,259.8 225.6,259.7 225.6,259.6 225.6,259.5 225.6,259.4 "/>
<line className="st15" x1="225.6" y1="257.8" x2="225.6" y2="257.8"/>
<line className="st15" x1="225.6" y1="257.7" x2="225.6" y2="257.6"/>
<line className="st15" x1="225.6" y1="257.4" x2="225.6" y2="257.3"/>
<polyline className="st15" points="225.6,260.9 225.6,260.8 225.6,260.7 225.6,260.6 "/>
<polyline className="st15" points="225.6,260.4 225.6,260.3 225.6,260.2 225.6,260.1 225.6,260 "/>
<polyline className="st15" points="225.6,259.8 225.6,259.7 225.6,259.6 225.6,259.5 225.6,259.4 "/>
<line className="st15" x1="225.6" y1="259.2" x2="225.6" y2="259.2"/>
<line className="st15" x1="225.6" y1="257.9" x2="225.6" y2="257.8"/>
<line className="st15" x1="225.6" y1="257.7" x2="225.6" y2="257.6"/>
<line className="st15" x1="225.6" y1="257.4" x2="225.6" y2="257.4"/>
<polyline className="st15" points="225.7,260.9 225.7,260.8 225.7,260.7 225.7,260.6 "/>
<polyline className="st15" points="225.7,260.4 225.7,260.3 225.7,260.2 225.7,260.1 225.7,260 "/>
<polyline className="st15" points="225.7,259.8 225.7,259.7 225.7,259.6 225.7,259.5 225.7,259.4 "/>
<line className="st15" x1="225.7" y1="259.2" x2="225.7" y2="259.2"/>
<line className="st15" x1="225.7" y1="257.9" x2="225.7" y2="257.8"/>
<line className="st15" x1="225.7" y1="257.7" x2="225.7" y2="257.6"/>
<line className="st15" x1="225.7" y1="257.4" x2="225.7" y2="257.4"/>
<polyline className="st15" points="225.7,260.9 225.7,260.8 225.7,260.7 225.7,260.6 "/>
<polyline className="st15" points="225.7,260.4 225.7,260.3 225.7,260.2 225.7,260.1 225.7,260 "/>
<polyline className="st15" points="225.7,259.8 225.7,259.7 225.7,259.6 225.7,259.5 225.7,259.4 "/>
<line className="st15" x1="225.7" y1="259.2" x2="225.7" y2="259.2"/>
<polyline className="st15" points="225.7,258 225.7,257.9 225.7,257.8 "/>
<line className="st15" x1="225.7" y1="257.7" x2="225.7" y2="257.6"/>
<polyline className="st15" points="225.7,261 225.7,260.9 225.7,260.8 225.7,260.7 225.7,260.6 "/>
<polyline className="st15" points="225.7,260.4 225.7,260.3 225.7,260.2 225.7,260.1 225.7,260 "/>
<polyline className="st15" points="225.7,259.8 225.7,259.7 225.7,259.6 225.7,259.5 225.7,259.4 "/>
<line className="st15" x1="225.7" y1="259.2" x2="225.7" y2="259.1"/>
<polyline className="st15" points="225.7,258 225.7,257.9 225.7,257.8 "/>
<line className="st15" x1="225.7" y1="257.7" x2="225.7" y2="257.6"/>
<polyline className="st15" points="225.8,261 225.8,260.9 225.8,260.8 225.8,260.7 225.8,260.6 "/>
<polyline className="st15" points="225.8,260.4 225.8,260.3 225.8,260.2 225.8,260.1 225.8,260 "/>
<polyline className="st15" points="225.8,259.8 225.8,259.7 225.8,259.6 225.8,259.5 225.8,259.4 "/>
<line className="st15" x1="225.8" y1="259.2" x2="225.8" y2="259.1"/>
<polyline className="st15" points="225.8,258 225.8,257.9 225.8,257.8 "/>
<line className="st15" x1="225.8" y1="257.7" x2="225.8" y2="257.6"/>
<polyline className="st15" points="225.8,261 225.8,260.9 225.8,260.8 225.8,260.7 225.8,260.6 "/>
<polyline className="st15" points="225.8,260.4 225.8,260.3 225.8,260.2 225.8,260.1 225.8,260 "/>
<polyline className="st15" points="225.8,259.8 225.8,259.7 225.8,259.6 225.8,259.5 225.8,259.4 "/>
<polyline className="st15" points="225.8,259.2 225.8,259.1 225.8,259 "/>
<line className="st15" x1="225.8" y1="258.2" x2="225.8" y2="258.2"/>
<polyline className="st15" points="225.8,258 225.8,257.9 225.8,257.8 "/>
<line className="st15" x1="225.8" y1="257.7" x2="225.8" y2="257.7"/>
<line className="st15" x1="225.8" y1="261.2" x2="225.8" y2="261.2"/>
<polyline className="st15" points="225.8,261 225.8,260.9 225.8,260.8 225.8,260.7 225.8,260.6 "/>
<polyline className="st15" points="225.8,260.4 225.8,260.3 225.8,260.2 225.8,260.1 225.8,260 "/>
<polyline className="st15" points="225.8,259.8 225.8,259.7 225.8,259.6 225.8,259.5 225.8,259.4 "/>
<polyline className="st15" points="225.8,259.2 225.8,259.1 225.8,259 "/>
<line className="st15" x1="225.8" y1="258.9" x2="225.8" y2="258.9"/>
<line className="st15" x1="225.8" y1="258.3" x2="225.8" y2="258.2"/>
<polyline className="st15" points="225.8,258 225.8,257.9 225.8,257.8 "/>
<line className="st15" x1="225.8" y1="257.7" x2="225.8" y2="257.7"/>
<line className="st15" x1="226" y1="261.2" x2="226" y2="261.2"/>
<polyline className="st15" points="226,261 226,260.9 226,260.8 226,260.7 226,260.6 "/>
<polyline className="st15" points="226,260.4 226,260.3 226,260.2 226,260.1 226,260 "/>
<polyline className="st15" points="226,259.8 226,259.7 226,259.6 226,259.5 226,259.4 "/>
<polyline className="st15" points="226,259.2 226,259.1 226,259 "/>
<line className="st15" x1="226" y1="258.9" x2="226" y2="258.8"/>
<line className="st15" x1="226" y1="258.4" x2="226" y2="258.4"/>
<line className="st15" x1="226" y1="258.3" x2="226" y2="258.2"/>
<polyline className="st15" points="226,258 226,257.9 226,257.8 "/>
<line className="st15" x1="226" y1="261.2" x2="226" y2="261.2"/>
<polyline className="st15" points="226,261 226,260.9 226,260.8 226,260.7 226,260.6 "/>
<polyline className="st15" points="226,260.4 226,260.3 226,260.2 226,260.1 226,260 "/>
<polyline className="st15" points="226,259.8 226,259.7 226,259.6 226,259.5 226,259.4 "/>
<polyline className="st15" points="226,259.2 226,259.1 226,259 "/>
<line className="st15" x1="226" y1="258.9" x2="226" y2="258.8"/>
<polyline className="st15" points="226,258.6 226,258.5 226,258.4 "/>
<line className="st15" x1="226" y1="258.3" x2="226" y2="258.2"/>
<polyline className="st15" points="226,258 226,257.9 226,257.8 "/>
<line className="st15" x1="226.1" y1="261.2" x2="226.1" y2="261.2"/>
<polyline className="st15" points="226.1,261 226.1,260.9 226.1,260.8 226.1,260.7 226.1,260.6 "/>
<polyline className="st15" points="226.1,260.4 226.1,260.3 226.1,260.2 226.1,260.1 226.1,260 "/>
<polyline className="st15" points="226.1,259.8 226.1,259.7 226.1,259.6 226.1,259.5 226.1,259.4 "/>
<polyline className="st15" points="226.1,259.2 226.1,259.1 226.1,259 "/>
<line className="st15" x1="226.1" y1="258.9" x2="226.1" y2="258.8"/>
<polyline className="st15" points="226.1,258.6 226.1,258.5 226.1,258.4 "/>
<line className="st15" x1="226.1" y1="258.3" x2="226.1" y2="258.2"/>
<polyline className="st15" points="226.1,258 226.1,257.9 226.1,257.8 "/>
<polyline className="st15" points="226.1,261 226.1,260.9 226.1,260.8 226.1,260.7 226.1,260.6 "/>
<polyline className="st15" points="226.1,260.4 226.1,260.3 226.1,260.2 226.1,260.1 226.1,260 "/>
<polyline className="st15" points="226.1,259.8 226.1,259.7 226.1,259.6 226.1,259.5 226.1,259.4 "/>
<polyline className="st15" points="226.1,259.2 226.1,259.1 226.1,259 "/>
<line className="st15" x1="226.1" y1="258.9" x2="226.1" y2="258.8"/>
<polyline className="st15" points="226.1,258.6 226.1,258.5 226.1,258.4 "/>
<line className="st15" x1="226.1" y1="258.3" x2="226.1" y2="258.2"/>
<line className="st15" x1="226.1" y1="258" x2="226.1" y2="257.9"/>
<polyline className="st15" points="226.1,261 226.1,260.9 226.1,260.8 226.1,260.7 226.1,260.6 "/>
<polyline className="st15" points="226.1,260.4 226.1,260.3 226.1,260.2 226.1,260.1 226.1,260 "/>
<polyline className="st15" points="226.1,259.8 226.1,259.7 226.1,259.6 226.1,259.5 226.1,259.4 "/>
<polyline className="st15" points="226.1,259.2 226.1,259.1 226.1,259 "/>
<line className="st15" x1="226.1" y1="258.9" x2="226.1" y2="258.8"/>
<polyline className="st15" points="226.1,258.6 226.1,258.5 226.1,258.4 "/>
<line className="st15" x1="226.1" y1="258.3" x2="226.1" y2="258.2"/>
<line className="st15" x1="226.1" y1="258" x2="226.1" y2="257.9"/>
<polyline className="st15" points="226.2,260.9 226.2,260.8 226.2,260.7 226.2,260.6 "/>
<polyline className="st15" points="226.2,260.4 226.2,260.3 226.2,260.2 226.2,260.1 226.2,260 "/>
<polyline className="st15" points="226.2,259.8 226.2,259.7 226.2,259.6 226.2,259.5 226.2,259.4 "/>
<polyline className="st15" points="226.2,259.2 226.2,259.1 226.2,259 "/>
<line className="st15" x1="226.2" y1="258.9" x2="226.2" y2="258.8"/>
<polyline className="st15" points="226.2,258.6 226.2,258.5 226.2,258.4 "/>
<line className="st15" x1="226.2" y1="258.3" x2="226.2" y2="258.2"/>
<line className="st15" x1="226.2" y1="258" x2="226.2" y2="257.9"/>
<polyline className="st15" points="226.2,260.9 226.2,260.8 226.2,260.7 226.2,260.6 "/>
<polyline className="st15" points="226.2,260.4 226.2,260.3 226.2,260.2 226.2,260.1 226.2,260 "/>
<polyline className="st15" points="226.2,259.8 226.2,259.7 226.2,259.6 226.2,259.5 226.2,259.4 "/>
<polyline className="st15" points="226.2,259.2 226.2,259.1 226.2,259 "/>
<line className="st15" x1="226.2" y1="258.9" x2="226.2" y2="258.8"/>
<polyline className="st15" points="226.2,258.6 226.2,258.5 226.2,258.4 "/>
<line className="st15" x1="226.2" y1="258.3" x2="226.2" y2="258.2"/>
<line className="st15" x1="226.2" y1="258" x2="226.2" y2="258"/>
<polyline className="st15" points="226.3,260.9 226.3,260.8 226.3,260.7 226.3,260.6 "/>
<polyline className="st15" points="226.3,260.4 226.3,260.3 226.3,260.2 226.3,260.1 226.3,260 "/>
<polyline className="st15" points="226.3,259.8 226.3,259.7 226.3,259.6 226.3,259.5 226.3,259.4 "/>
<polyline className="st15" points="226.3,259.2 226.3,259.1 226.3,259 "/>
<line className="st15" x1="226.3" y1="258.9" x2="226.3" y2="258.8"/>
<polyline className="st15" points="226.3,258.6 226.3,258.5 226.3,258.4 "/>
<line className="st15" x1="226.3" y1="258.3" x2="226.3" y2="258.2"/>
<line className="st15" x1="226.3" y1="258" x2="226.3" y2="258"/>
<polyline className="st15" points="226.3,260.8 226.3,260.7 226.3,260.6 "/>
<polyline className="st15" points="226.3,260.4 226.3,260.3 226.3,260.2 226.3,260.1 226.3,260 "/>
<polyline className="st15" points="226.3,259.8 226.3,259.7 226.3,259.6 226.3,259.5 226.3,259.4 "/>
<polyline className="st15" points="226.3,259.2 226.3,259.1 226.3,259 "/>
<line className="st15" x1="226.3" y1="258.9" x2="226.3" y2="258.8"/>
<polyline className="st15" points="226.3,258.6 226.3,258.5 226.3,258.4 "/>
<line className="st15" x1="226.3" y1="258.3" x2="226.3" y2="258.2"/>
<polyline className="st15" points="226.3,260.8 226.3,260.7 226.3,260.6 "/>
<polyline className="st15" points="226.3,260.4 226.3,260.3 226.3,260.2 226.3,260.1 226.3,260 "/>
<polyline className="st15" points="226.3,259.8 226.3,259.7 226.3,259.6 226.3,259.5 226.3,259.4 "/>
<polyline className="st15" points="226.3,259.2 226.3,259.1 226.3,259 "/>
<line className="st15" x1="226.3" y1="258.9" x2="226.3" y2="258.8"/>
<polyline className="st15" points="226.3,258.6 226.3,258.5 226.3,258.4 "/>
<line className="st15" x1="226.3" y1="258.3" x2="226.3" y2="258.2"/>
<polyline className="st15" points="226.4,260.8 226.4,260.7 226.4,260.6 "/>
<polyline className="st15" points="226.4,260.4 226.4,260.3 226.4,260.2 226.4,260.1 226.4,260 "/>
<polyline className="st15" points="226.4,259.8 226.4,259.7 226.4,259.6 226.4,259.5 226.4,259.4 "/>
<polyline className="st15" points="226.4,259.2 226.4,259.1 226.4,259 "/>
<line className="st15" x1="226.4" y1="258.9" x2="226.4" y2="258.8"/>
<polyline className="st15" points="226.4,258.6 226.4,258.5 226.4,258.4 "/>
<line className="st15" x1="226.4" y1="258.3" x2="226.4" y2="258.2"/>
<line className="st15" x1="226.4" y1="260.7" x2="226.4" y2="260.6"/>
<polyline className="st15" points="226.4,260.4 226.4,260.3 226.4,260.2 226.4,260.1 226.4,260 "/>
<polyline className="st15" points="226.4,259.8 226.4,259.7 226.4,259.6 226.4,259.5 226.4,259.4 "/>
<polyline className="st15" points="226.4,259.2 226.4,259.1 226.4,259 "/>
<line className="st15" x1="226.4" y1="258.9" x2="226.4" y2="258.8"/>
<polyline className="st15" points="226.4,258.6 226.4,258.5 226.4,258.4 "/>
<line className="st15" x1="226.4" y1="258.3" x2="226.4" y2="258.3"/>
<line className="st15" x1="226.4" y1="260.7" x2="226.4" y2="260.6"/>
<polyline className="st15" points="226.4,260.4 226.4,260.3 226.4,260.2 226.4,260.1 226.4,260 "/>
<polyline className="st15" points="226.4,259.8 226.4,259.7 226.4,259.6 226.4,259.5 226.4,259.4 "/>
<polyline className="st15" points="226.4,259.2 226.4,259.1 226.4,259 "/>
<line className="st15" x1="226.4" y1="258.9" x2="226.4" y2="258.8"/>
<polyline className="st15" points="226.4,258.6 226.4,258.5 226.4,258.4 "/>
<line className="st15" x1="226.4" y1="258.3" x2="226.4" y2="258.3"/>
<line className="st15" x1="226.6" y1="260.6" x2="226.6" y2="260.6"/>
<polyline className="st15" points="226.6,260.4 226.6,260.3 226.6,260.2 226.6,260.1 226.6,260 "/>
<polyline className="st15" points="226.6,259.8 226.6,259.7 226.6,259.6 226.6,259.5 226.6,259.4 "/>
<polyline className="st15" points="226.6,259.2 226.6,259.1 226.6,259 "/>
<line className="st15" x1="226.6" y1="258.9" x2="226.6" y2="258.8"/>
<polyline className="st15" points="226.6,258.6 226.6,258.5 226.6,258.4 "/>
<line className="st15" x1="226.6" y1="260.6" x2="226.6" y2="260.6"/>
<polyline className="st15" points="226.6,260.4 226.6,260.3 226.6,260.2 226.6,260.1 226.6,260 "/>
<polyline className="st15" points="226.6,259.8 226.6,259.7 226.6,259.6 226.6,259.5 226.6,259.4 "/>
<polyline className="st15" points="226.6,259.2 226.6,259.1 226.6,259 "/>
<line className="st15" x1="226.6" y1="258.9" x2="226.6" y2="258.8"/>
<polyline className="st15" points="226.6,258.6 226.6,258.5 226.6,258.4 "/>
<line className="st15" x1="226.7" y1="260.6" x2="226.7" y2="260.6"/>
<polyline className="st15" points="226.7,260.4 226.7,260.3 226.7,260.2 226.7,260.1 226.7,260 "/>
<polyline className="st15" points="226.7,259.8 226.7,259.7 226.7,259.6 226.7,259.5 226.7,259.4 "/>
<polyline className="st15" points="226.7,259.2 226.7,259.1 226.7,259 "/>
<line className="st15" x1="226.7" y1="258.9" x2="226.7" y2="258.8"/>
<polyline className="st15" points="226.7,258.6 226.7,258.5 226.7,258.4 "/>
<polyline className="st15" points="226.7,260.4 226.7,260.3 226.7,260.2 226.7,260.1 226.7,260 "/>
<polyline className="st15" points="226.7,259.8 226.7,259.7 226.7,259.6 226.7,259.5 226.7,259.4 "/>
<polyline className="st15" points="226.7,259.2 226.7,259.1 226.7,259 "/>
<line className="st15" x1="226.7" y1="258.9" x2="226.7" y2="258.8"/>
<line className="st15" x1="226.7" y1="258.6" x2="226.7" y2="258.5"/>
<polyline className="st15" points="226.7,260.4 226.7,260.3 226.7,260.2 226.7,260.1 226.7,260 "/>
<polyline className="st15" points="226.7,259.8 226.7,259.7 226.7,259.6 226.7,259.5 226.7,259.4 "/>
<polyline className="st15" points="226.7,259.2 226.7,259.1 226.7,259 "/>
<line className="st15" x1="226.7" y1="258.9" x2="226.7" y2="258.8"/>
<line className="st15" x1="226.7" y1="258.6" x2="226.7" y2="258.5"/>
<polyline className="st15" points="226.8,260.3 226.8,260.2 226.8,260.1 226.8,260 "/>
<polyline className="st15" points="226.8,259.8 226.8,259.7 226.8,259.6 226.8,259.5 226.8,259.4 "/>
<polyline className="st15" points="226.8,259.2 226.8,259.1 226.8,259 "/>
<line className="st15" x1="226.8" y1="258.9" x2="226.8" y2="258.8"/>
<line className="st15" x1="226.8" y1="258.6" x2="226.8" y2="258.5"/>
<polyline className="st15" points="226.8,260.3 226.8,260.2 226.8,260.1 226.8,260 "/>
<polyline className="st15" points="226.8,259.8 226.8,259.7 226.8,259.6 226.8,259.5 226.8,259.4 "/>
<polyline className="st15" points="226.8,259.2 226.8,259.1 226.8,259 "/>
<line className="st15" x1="226.8" y1="258.9" x2="226.8" y2="258.8"/>
<line className="st15" x1="226.8" y1="258.6" x2="226.8" y2="258.6"/>
<polyline className="st15" points="226.9,260.3 226.9,260.2 226.9,260.1 226.9,260 "/>
<polyline className="st15" points="226.9,259.8 226.9,259.7 226.9,259.6 226.9,259.5 226.9,259.4 "/>
<polyline className="st15" points="226.9,259.2 226.9,259.1 226.9,259 "/>
<line className="st15" x1="226.9" y1="258.9" x2="226.9" y2="258.8"/>
<line className="st15" x1="226.9" y1="258.6" x2="226.9" y2="258.6"/>
<polyline className="st15" points="226.9,260.2 226.9,260.1 226.9,260 "/>
<polyline className="st15" points="226.9,259.8 226.9,259.7 226.9,259.6 226.9,259.5 226.9,259.4 "/>
<polyline className="st15" points="226.9,259.2 226.9,259.1 226.9,259 "/>
<line className="st15" x1="226.9" y1="258.9" x2="226.9" y2="258.8"/>
<polyline className="st15" points="226.9,260.2 226.9,260.1 226.9,260 "/>
<polyline className="st15" points="226.9,259.8 226.9,259.7 226.9,259.6 226.9,259.5 226.9,259.4 "/>
<polyline className="st15" points="226.9,259.2 226.9,259.1 226.9,259 "/>
<line className="st15" x1="226.9" y1="258.9" x2="226.9" y2="258.8"/>
<polyline className="st15" points="227,260.2 227,260.1 227,260 "/>
<polyline className="st15" points="227,259.8 227,259.7 227,259.6 227,259.5 227,259.4 "/>
<polyline className="st15" points="227,259.2 227,259.1 227,259 "/>
<line className="st15" x1="227" y1="258.9" x2="227" y2="258.8"/>
<line className="st15" x1="227" y1="260.1" x2="227" y2="260"/>
<polyline className="st15" points="227,259.8 227,259.7 227,259.6 227,259.5 227,259.4 "/>
<polyline className="st15" points="227,259.2 227,259.1 227,259 "/>
<line className="st15" x1="227" y1="258.9" x2="227" y2="258.9"/>
<line className="st15" x1="227" y1="260.1" x2="227" y2="260"/>
<polyline className="st15" points="227,259.8 227,259.7 227,259.6 227,259.5 227,259.4 "/>
<polyline className="st15" points="227,259.2 227,259.1 227,259 "/>
<line className="st15" x1="227" y1="258.9" x2="227" y2="258.9"/>
<line className="st15" x1="227.2" y1="260" x2="227.2" y2="260"/>
<polyline className="st15" points="227.2,259.8 227.2,259.7 227.2,259.6 227.2,259.5 227.2,259.4 "/>
<polyline className="st15" points="227.2,259.2 227.2,259.1 227.2,259 "/>
<line className="st15" x1="227.2" y1="260" x2="227.2" y2="260"/>
<polyline className="st15" points="227.2,259.8 227.2,259.7 227.2,259.6 227.2,259.5 227.2,259.4 "/>
<polyline className="st15" points="227.2,259.2 227.2,259.1 227.2,259 "/>
<line className="st15" x1="227.3" y1="260" x2="227.3" y2="260"/>
<polyline className="st15" points="227.3,259.8 227.3,259.7 227.3,259.6 227.3,259.5 227.3,259.4 "/>
<polyline className="st15" points="227.3,259.2 227.3,259.1 227.3,259 "/>
<polyline className="st15" points="227.3,259.8 227.3,259.7 227.3,259.6 227.3,259.5 227.3,259.4 "/>
<line className="st15" x1="227.3" y1="259.2" x2="227.3" y2="259.1"/>
<polyline className="st15" points="227.3,259.8 227.3,259.7 227.3,259.6 227.3,259.5 227.3,259.4 "/>
<line className="st15" x1="227.3" y1="259.2" x2="227.3" y2="259.1"/>
<polyline className="st15" points="227.4,259.8 227.4,259.7 227.4,259.6 227.4,259.5 227.4,259.4 "/>
<line className="st15" x1="227.4" y1="259.2" x2="227.4" y2="259.1"/>
<polyline className="st15" points="227.4,259.7 227.4,259.6 227.4,259.5 227.4,259.4 "/>
<line className="st15" x1="227.4" y1="259.2" x2="227.4" y2="259.2"/>
<polyline className="st15" points="227.5,259.7 227.5,259.6 227.5,259.5 227.5,259.4 "/>
<line className="st15" x1="227.5" y1="259.2" x2="227.5" y2="259.2"/>
<polyline className="st15" points="227.5,259.4 227.5,259.5 227.5,259.6 227.5,259.5 227.5,259.4 "/>
<polyline className="st15" points="227.6,259.6 227.6,259.5 227.6,259.4 "/>
<line className="st15" x1="227.6" y1="259.5" x2="227.6" y2="259.5"/>
<path className="st16" d="M234.8,222.5l-0.7,8.6l4.7-4.7L234.8,222.5z M230.3,227.2l4.6-4.7l-0.7,8.6L230.3,227.2z"/>
<path className="st17" d="M234.8,222.5l-0.7,8.6l4.7-4.7L234.8,222.5z M230.3,227.2l4.6-4.7l-0.7,8.6L230.3,227.2z"/>
<path className="st16" d="M234.8,222.5l-4,4.1L234.8,222.5z M230.9,226.5l4-4l-4,4.1V226.5z"/>
<path className="st17" d="M234.8,222.5l-4,4.1L234.8,222.5z M230.9,226.5l4-4l-4,4.1V226.5z"/>
<path className="st16" d="M230.8,226.6l-0.5,0.6l0.6-0.6H230.8z M230.2,227.2l0.6-0.6l-0.5,0.6H230.2z"/>
<path className="st17" d="M230.8,226.6l-0.5,0.6l0.6-0.6H230.8z M230.2,227.2l0.6-0.6l-0.5,0.6H230.2z"/>
<path className="st16" d="M234.7,222.4l-3.8,4.1l4-4L234.7,222.4z M230.9,226.4l3.8-4l-3.8,4.1V226.4z"/>
<path className="st17" d="M234.7,222.4l-3.8,4.1l4-4L234.7,222.4z M230.9,226.4l3.8-4l-3.8,4.1V226.4z"/>
<path className="st16" d="M230.5,226.6l-0.4,0.6l0.6-0.6H230.5z M230,227.1l0.5-0.5l-0.4,0.6L230,227.1z"/>
<path className="st17" d="M230.5,226.6l-0.4,0.6l0.6-0.6H230.5z M230,227.1l0.5-0.5l-0.4,0.6L230,227.1z"/>
<path className="st16" d="M234.6,222.3l-3.7,4.1l3.8-4L234.6,222.3z M230.8,226.2l3.8-4l-3.7,4.1L230.8,226.2z"/>
<path className="st17" d="M234.6,222.3l-3.7,4.1l3.8-4L234.6,222.3z M230.8,226.2l3.8-4l-3.7,4.1L230.8,226.2z"/>
<path className="st16" d="M230.5,226.5l-0.5,0.6l0.5-0.5V226.5z M230,227l0.5-0.5l-0.5,0.6V227z"/>
<path className="st17" d="M230.5,226.5l-0.5,0.6l0.5-0.5V226.5z M230,227l0.5-0.5l-0.5,0.6V227z"/>
<path className="st16" d="M234.6,222.3l-3.8,4L234.6,222.3z M230.5,226.4l4.1-4.1l-3.8,4L230.5,226.4z"/>
<path className="st17" d="M234.6,222.3l-3.8,4L234.6,222.3z M230.5,226.4l4.1-4.1l-3.8,4L230.5,226.4z"/>
<path className="st16" d="M230.5,226.4L230,227l0.5-0.5V226.4z M229.9,227l0.6-0.6L230,227H229.9z"/>
<path className="st17" d="M230.5,226.4L230,227l0.5-0.5V226.4z M229.9,227l0.6-0.6L230,227H229.9z"/>
<path className="st16" d="M234.1,221.8l-4.2,5.2l4.7-4.7L234.1,221.8z M229.4,226.5l4.7-4.7l-4.2,5.2L229.4,226.5z"/>
<path className="st17" d="M234.1,221.8l-4.2,5.2l4.7-4.7L234.1,221.8z M229.4,226.5l4.7-4.7l-4.2,5.2L229.4,226.5z"/>
<line className="st18" x1="231" y1="226.5" x2="238.8" y2="226.5"/>
<polyline className="st18" points="229.4,226.5 234.1,221.8 238.8,226.5 234.1,231.2 229.4,226.5 "/>
<line className="st18" x1="234.1" y1="231.2" x2="230.9" y2="226.7"/>
<path className="st18" d="M230.6,226.3c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C230.8,226.2,230.6,226.2,230.6,226.3"/>
<line className="st18" x1="230.9" y1="226.1" x2="234.1" y2="221.8"/>
<path className="st16" d="M298.2,276.9L298.2,276.9l1.7-1.7L298.2,276.9z M299.9,275.2l-1.7,1.7L299.9,275.2z M298.2,276.9l1.7-1.7
	L298.2,276.9z M298.2,276.9L298.2,276.9l1.7-1.7L298.2,276.9z M299.9,275.2l-1.7,1.7L299.9,275.2z M299.9,275.1v0.1l-1.7,1.7
	L299.9,275.1z"/>
<path className="st17" d="M298.2,276.9L298.2,276.9l1.7-1.7L298.2,276.9z M299.9,275.2l-1.7,1.7L299.9,275.2z M298.2,276.9l1.7-1.7
	L298.2,276.9z M298.2,276.9L298.2,276.9l1.7-1.7L298.2,276.9z M299.9,275.2l-1.7,1.7L299.9,275.2z M299.9,275.1v0.1l-1.7,1.7
	L299.9,275.1z"/>
<path className="st16" d="M298.2,276.9L298.2,276.9l1.7-1.8L298.2,276.9z M299.9,275.1l-1.7,1.8L299.9,275.1z"/>
<path className="st17" d="M298.2,276.9L298.2,276.9l1.7-1.8L298.2,276.9z M299.9,275.1l-1.7,1.8L299.9,275.1z"/>
<path className="st16" d="M297.1,275.8l2.8-0.7l-1.7,1.8L297.1,275.8z M298.8,274l-1.7,1.8l2.8-0.7L298.8,274z"/>
<path className="st17" d="M297.1,275.8l2.8-0.7l-1.7,1.8L297.1,275.8z M298.8,274l-1.7,1.8l2.8-0.7L298.8,274z"/>
<path className="st16" d="M296.9,275.6l1.1-0.7l-0.8,1L296.9,275.6z M297.4,275.2l-0.5,0.4l1.1-0.7L297.4,275.2z"/>
<path className="st17" d="M296.9,275.6l1.1-0.7l-0.8,1L296.9,275.6z M297.4,275.2l-0.5,0.4l1.1-0.7L297.4,275.2z"/>
<path className="st16" d="M298.2,274.2l0.6-0.2l-0.8,0.8L298.2,274.2z M298.7,273.9l-0.5,0.4l0.6-0.2L298.7,273.9z"/>
<path className="st17" d="M298.2,274.2l0.6-0.2l-0.8,0.8L298.2,274.2z M298.7,273.9l-0.5,0.4l0.6-0.2L298.7,273.9z"/>
<path className="st16" d="M296.9,275.6L296.9,275.6l0.5-0.4L296.9,275.6z M297.4,275.2l-0.5,0.4L297.4,275.2z"/>
<path className="st17" d="M296.9,275.6L296.9,275.6l0.5-0.4L296.9,275.6z M297.4,275.2l-0.5,0.4L297.4,275.2z"/>
<path className="st16" d="M297.5,274.7L297.5,274.7l0.4-0.2L297.5,274.7z M297.8,274.5l-0.4,0.2L297.8,274.5z M297.5,274.7l0.4-0.2
	L297.5,274.7z M297.5,274.7L297.5,274.7l0.4-0.2L297.5,274.7z M297.8,274.5l-0.4,0.2L297.8,274.5z M297.8,274.4v0.1l-0.4,0.2
	L297.8,274.4z"/>
<path className="st17" d="M297.5,274.7L297.5,274.7l0.4-0.2L297.5,274.7z M297.8,274.5l-0.4,0.2L297.8,274.5z M297.5,274.7l0.4-0.2
	L297.5,274.7z M297.5,274.7L297.5,274.7l0.4-0.2L297.5,274.7z M297.8,274.5l-0.4,0.2L297.8,274.5z M297.8,274.4v0.1l-0.4,0.2
	L297.8,274.4z"/>
<path className="st16" d="M297.5,274.7L297.5,274.7l0.4-0.4L297.5,274.7z M297.8,274.4l-0.4,0.4L297.8,274.4z"/>
<path className="st17" d="M297.5,274.7L297.5,274.7l0.4-0.4L297.5,274.7z M297.8,274.4l-0.4,0.4L297.8,274.4z"/>
<path className="st16" d="M297.6,274.2l-0.4,0.4l0.1-0.2L297.6,274.2z M297.8,274.4l-0.2-0.1l-0.4,0.4L297.8,274.4z"/>
<path className="st17" d="M297.6,274.2l-0.4,0.4l0.1-0.2L297.6,274.2z M297.8,274.4l-0.2-0.1l-0.4,0.4L297.8,274.4z"/>
<polygon className="st16" points="297.8,274.4 297.5,274.7 297.2,274.6 "/>
<polygon className="st17" points="297.8,274.4 297.5,274.7 297.2,274.6 "/>
<path className="st16" d="M298,273.6l0.7,0.2l-0.5,0.4L298,273.6z M298.2,273.4l-0.2,0.2l0.7,0.2L298.2,273.4z"/>
<path className="st17" d="M298,273.6l0.7,0.2l-0.5,0.4L298,273.6z M298.2,273.4l-0.2,0.2l0.7,0.2L298.2,273.4z"/>
<path className="st16" d="M296.5,275.2h0.8l-0.5,0.4L296.5,275.2z M296.8,275l-0.2,0.2h0.8L296.8,275z"/>
<path className="st17" d="M296.5,275.2h0.8l-0.5,0.4L296.5,275.2z M296.8,275l-0.2,0.2h0.8L296.8,275z"/>
<path className="st16" d="M296.2,274.8l0.6,0.1l-0.2,0.2L296.2,274.8z M296.4,274.6l-0.2,0.2l0.6,0.1L296.4,274.6z"/>
<path className="st17" d="M296.2,274.8l0.6,0.1l-0.2,0.2L296.2,274.8z M296.4,274.6l-0.2,0.2l0.6,0.1L296.4,274.6z"/>
<path className="st16" d="M297.7,273.3l0.5,0.1l-0.2,0.2L297.7,273.3z M297.8,273l-0.1,0.2l0.5,0.1L297.8,273z"/>
<path className="st17" d="M297.7,273.3l0.5,0.1l-0.2,0.2L297.7,273.3z M297.8,273l-0.1,0.2l0.5,0.1L297.8,273z"/>
<path className="st16" d="M297.1,273h0.7l-0.1,0.2L297.1,273z M297.4,272.8l-0.2,0.2h0.7L297.4,272.8z"/>
<path className="st17" d="M297.1,273h0.7l-0.1,0.2L297.1,273z M297.4,272.8l-0.2,0.2h0.7L297.4,272.8z"/>
<path className="st16" d="M295.8,274.2l0.6,0.4l-0.2,0.2L295.8,274.2z M296.2,274l-0.4,0.2l0.6,0.4L296.2,274z M296,274h0.1l-0.4,0.2
	L296,274z"/>
<path className="st17" d="M295.8,274.2l0.6,0.4l-0.2,0.2L295.8,274.2z M296.2,274l-0.4,0.2l0.6,0.4L296.2,274z M296,274h0.1l-0.4,0.2
	L296,274z"/>
<path className="st16" d="M296.2,274l-0.4,0.2L296.2,274z"/>
<path className="st17" d="M296.2,274l-0.4,0.2L296.2,274z"/>
<path className="st16" d="M297,273l0.4-0.2l-0.2,0.2H297z M297.2,272.8L297,273l0.4-0.2H297.2z"/>
<path className="st17" d="M297,273l0.4-0.2l-0.2,0.2H297z M297.2,272.8L297,273l0.4-0.2H297.2z"/>
<path className="st16" d="M295.8,273.9l0.2,0.1l-0.2,0.2V273.9z M296.4,273.3l-0.6,0.6l0.2,0.1L296.4,273.3z"/>
<path className="st17" d="M295.8,273.9l0.2,0.1l-0.2,0.2V273.9z M296.4,273.3l-0.6,0.6l0.2,0.1L296.4,273.3z"/>
<path className="st16" d="M296.4,273.3l0.8-0.5L297,273L296.4,273.3z M296.9,272.8l-0.5,0.5l0.8-0.5H296.9z"/>
<path className="st17" d="M296.4,273.3l0.8-0.5L297,273L296.4,273.3z M296.9,272.8l-0.5,0.5l0.8-0.5H296.9z"/>
<path className="st16" d="M295.8,273.6l1.1-0.8l-1.1,1.1V273.6z M296.8,272.8l-1,0.8l1.1-0.8H296.8z M296.6,272.8h0.1l-1,0.8
	L296.6,272.8z"/>
<path className="st17" d="M295.8,273.6l1.1-0.8l-1.1,1.1V273.6z M296.8,272.8l-1,0.8l1.1-0.8H296.8z M296.6,272.8h0.1l-1,0.8
	L296.6,272.8z"/>
<path className="st16" d="M296.8,272.8l-1,0.8L296.8,272.8z"/>
<path className="st17" d="M296.8,272.8l-1,0.8L296.8,272.8z"/>
<path className="st16" d="M296.5,272.9l-0.6,0.6l0.2-0.5L296.5,272.9z M296.6,272.8l-0.1,0.1l-0.6,0.6L296.6,272.8z"/>
<path className="st17" d="M296.5,272.9l-0.6,0.6l0.2-0.5L296.5,272.9z M296.6,272.8l-0.1,0.1l-0.6,0.6L296.6,272.8z"/>
<polygon className="st16" points="296.6,272.8 295.9,273.6 295.9,273.5 "/>
<polygon className="st17" points="296.6,272.8 295.9,273.6 295.9,273.5 "/>
<path className="st19" d="M298.1,276.7C298.1,276.8,298.2,276.8,298.1,276.7"/>
<line className="st19" x1="298.2" y1="276.9" x2="299.9" y2="275.2"/>
<path className="st19" d="M299.9,275.2C299.9,275.2,299.9,275.1,299.9,275.2"/>
<line className="st19" x1="299.9" y1="275.1" x2="297.8" y2="273"/>
<path className="st19" d="M297.8,273c-0.5-0.5-1.3-0.5-1.8,0s-0.5,1.3,0,1.8"/>
<line className="st19" x1="296.2" y1="274.8" x2="298.2" y2="276.9"/>
<path className="st19" d="M297.4,274.7C297.4,274.7,297.4,274.7,297.4,274.7"/>
<line className="st19" x1="297.5" y1="274.7" x2="297.2" y2="274.6"/>
<path className="st19" d="M297.5,274.1c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="297.6" y1="274.2" x2="297.8" y2="274.4"/>
<path className="st19" d="M297.7,274.5C297.7,274.4,297.7,274.4,297.7,274.5"/>
<line className="st19" x1="297.8" y1="274.5" x2="297.5" y2="274.7"/>
<line className="st19" x1="298" y1="273.6" x2="297.7" y2="273.3"/>
<path className="st19" d="M297.6,273.3c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="296.4" y1="274.6" x2="296.8" y2="275"/>
<path className="st19" d="M296.7,274.9c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<line className="st2" x1="395.9" y1="339.9" x2="394.7" y2="338.8"/>
<line className="st2" x1="395.3" y1="339.4" x2="395.9" y2="338.8"/>
<line className="st2" x1="394.7" y1="338.8" x2="416.4" y2="317.1"/>
<line className="st1" x1="109.9" y1="253.8" x2="100" y2="253.8"/>
<line className="st1" x1="109.9" y1="252.2" x2="100" y2="252.2"/>
<line className="st1" x1="73.4" y1="127.4" x2="81.8" y2="119"/>
<line className="st1" x1="80.6" y1="117.9" x2="72.2" y2="126.2"/>
<line className="st1" x1="38.4" y1="162.3" x2="46.8" y2="153.9"/>
<line className="st1" x1="45.7" y1="152.8" x2="37.3" y2="161.2"/>
<line className="st1" x1="55.9" y1="144.8" x2="64.3" y2="136.4"/>
<line className="st1" x1="63.2" y1="135.3" x2="54.8" y2="143.7"/>
<line className="st1" x1="141.8" y1="253.8" x2="151.8" y2="253.8"/>
<line className="st1" x1="141.8" y1="252.2" x2="151.8" y2="252.2"/>
<line className="st1" x1="121" y1="253.8" x2="130.8" y2="253.8"/>
<line className="st1" x1="121" y1="252.2" x2="130.8" y2="252.2"/>
<line className="st1" x1="393.4" y1="412.5" x2="401.8" y2="404.1"/>
<line className="st1" x1="392.2" y1="411.3" x2="400.6" y2="402.9"/>
<line className="st1" x1="355.8" y1="439.7" x2="347.4" y2="431.3"/>
<line className="st1" x1="346.2" y1="432.5" x2="354.6" y2="440.9"/>
<line className="st1" x1="338.3" y1="422.3" x2="329.9" y2="413.9"/>
<line className="st1" x1="328.7" y1="415" x2="337.1" y2="423.4"/>
<line className="st1" x1="320.8" y1="404.8" x2="312.4" y2="396.4"/>
<line className="st1" x1="311.3" y1="397.5" x2="319.6" y2="405.9"/>
<line className="st1" x1="303.2" y1="387.3" x2="294.8" y2="378.9"/>
<line className="st1" x1="293.8" y1="380.1" x2="302.2" y2="388.5"/>
<line className="st1" x1="285.7" y1="369.8" x2="277.3" y2="361.4"/>
<line className="st1" x1="276.2" y1="362.6" x2="284.6" y2="371"/>
<line className="st1" x1="268.3" y1="352.2" x2="259.9" y2="343.8"/>
<line className="st1" x1="258.7" y1="345" x2="267.1" y2="353.4"/>
<line className="st1" x1="250.8" y1="334.8" x2="242.4" y2="326.4"/>
<line className="st1" x1="241.2" y1="327.5" x2="249.6" y2="335.9"/>
<line className="st1" x1="242.5" y1="308.1" x2="234.1" y2="299.7"/>
<line className="st1" x1="232.9" y1="300.9" x2="241.3" y2="309.3"/>
<line className="st1" x1="225" y1="290.6" x2="216.6" y2="282.2"/>
<line className="st1" x1="215.5" y1="283.4" x2="223.9" y2="291.8"/>
<line className="st1" x1="207.5" y1="273" x2="199.1" y2="264.8"/>
<line className="st1" x1="198" y1="265.8" x2="206.4" y2="274.2"/>
<line className="st1" x1="190.1" y1="255.6" x2="181.7" y2="247.2"/>
<line className="st1" x1="180.5" y1="248.3" x2="188.9" y2="256.7"/>
<line className="st1" x1="449.9" y1="380.7" x2="441.5" y2="372.3"/>
<line className="st1" x1="442.6" y1="371.1" x2="451" y2="379.5"/>
<line className="st1" x1="467.4" y1="398.1" x2="459" y2="389.7"/>
<line className="st1" x1="460.1" y1="388.6" x2="468.5" y2="397"/>
<line className="st1" x1="432.4" y1="363.2" x2="424" y2="354.8"/>
<line className="st1" x1="425.2" y1="353.6" x2="433.6" y2="362"/>
<line className="st1" x1="412.4" y1="313.1" x2="404" y2="304.7"/>
<line className="st1" x1="405.1" y1="303.6" x2="413.5" y2="312"/>
<line className="st1" x1="394.9" y1="295.6" x2="386.5" y2="287.2"/>
<line className="st1" x1="387.6" y1="286.1" x2="396" y2="294.5"/>
<line className="st1" x1="377.4" y1="278.1" x2="369" y2="269.7"/>
<line className="st1" x1="370.1" y1="268.6" x2="378.5" y2="277"/>
<line className="st1" x1="360" y1="260.7" x2="351.6" y2="252.3"/>
<line className="st1" x1="352.7" y1="251.1" x2="361.1" y2="259.5"/>
<line className="st1" x1="342.5" y1="243.2" x2="334.1" y2="234.8"/>
<line className="st1" x1="335.2" y1="233.7" x2="343.6" y2="242.1"/>
<line className="st1" x1="325" y1="225.6" x2="316.6" y2="217.2"/>
<line className="st1" x1="317.6" y1="216.2" x2="326" y2="224.6"/>
<line className="st1" x1="178.3" y1="127.4" x2="169.9" y2="119"/>
<line className="st1" x1="171.1" y1="117.9" x2="179.5" y2="126.2"/>
<line className="st1" x1="283.3" y1="232.2" x2="274.9" y2="223.8"/>
<line className="st1" x1="276" y1="222.8" x2="284.4" y2="231.2"/>
<line className="st1" x1="265.8" y1="214.8" x2="257.4" y2="206.4"/>
<line className="st1" x1="258.5" y1="205.2" x2="266.9" y2="213.6"/>
<line className="st1" x1="248.3" y1="197.3" x2="240" y2="188.9"/>
<line className="st1" x1="241.1" y1="187.8" x2="249.5" y2="196.2"/>
<line className="st1" x1="230.9" y1="179.8" x2="222.5" y2="171.4"/>
<line className="st1" x1="223.6" y1="170.3" x2="232" y2="178.7"/>
<line className="st1" x1="213.4" y1="162.3" x2="205" y2="153.9"/>
<line className="st1" x1="206" y1="152.8" x2="214.4" y2="161.2"/>
<line className="st1" x1="195.8" y1="144.8" x2="187.4" y2="136.4"/>
<line className="st1" x1="188.5" y1="135.3" x2="196.9" y2="143.7"/>
<line className="st1" x1="302.4" y1="296.9" x2="314.2" y2="281.7"/>
<line className="st5" x1="107.2" y1="218.1" x2="107.4" y2="218.1"/>
<polyline className="st5" points="107.4,218.1 107.4,226 107.2,226 107.2,218.1 "/>
<path className="st5" d="M99.5,218c0,4.2,3.4,7.7,7.6,7.9"/>
<line className="st5" x1="144.6" y1="218.1" x2="144.4" y2="218.1"/>
<polyline className="st5" points="144.4,218.1 144.4,226 144.6,226 144.6,218.1 "/>
<path className="st5" d="M144.6,225.9c4.2-0.1,7.6-3.6,7.6-7.9"/>
<line className="st5" x1="211.3" y1="234.4" x2="211.4" y2="234.6"/>
<polyline className="st5" points="211.4,234.6 205.9,240.2 205.7,240 211.3,234.4 "/>
<path className="st5" d="M205.8,229c-3,3-3.1,7.8-0.2,10.9"/>
<line className="st5" x1="223.7" y1="222" x2="223.8" y2="222.3"/>
<polyline className="st5" points="223.8,222.3 229.4,216.6 229.3,216.5 223.7,222 "/>
<path className="st5" d="M229.1,216.5c-3.1-2.9-7.9-2.8-10.9,0.2"/>
<path className="st5" d="M145.9,144.3c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="146.2,136.1 145.9,136 141.7,140.2 142,140.3 146.2,136.1 "/>
<path className="st5" d="M178.5,168.6c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="178.7,168.6 178.8,168.9 174.6,173.1 174.5,172.8 178.7,168.6 "/>
<path className="st5" d="M180.9,179.3c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="181.1,171.2 181,170.9 176.8,175.1 176.9,175.4 181.1,171.2 "/>
<path className="st5" d="M103,192c2.6-0.1,4.7-2.3,4.7-5"/>
<line className="st5" x1="102.8" y1="187.1" x2="102.8" y2="192.2"/>
<polyline className="st5" points="102.8,187.1 103.1,187.1 103.1,192.2 102.8,192.2 "/>
<path className="st5" d="M143.9,187.1c0,2.6,2.1,4.8,4.7,5"/>
<line className="st5" x1="148.9" y1="187.1" x2="148.9" y2="192.2"/>
<polyline className="st5" points="148.9,187.1 148.7,187.1 148.7,192.2 148.9,192.2 "/>
<line className="st5" x1="249.6" y1="272.7" x2="249.7" y2="272.9"/>
<polyline className="st5" points="249.7,272.9 244.2,278.4 244,278.3 249.6,272.7 "/>
<path className="st5" d="M244.1,267.3c-3,3-3.1,7.8-0.2,10.9"/>
<line className="st5" x1="217.1" y1="240.2" x2="216.8" y2="239.9"/>
<polyline className="st5" points="216.8,239.9 211.2,245.6 211.4,245.8 217.1,240.2 "/>
<path className="st5" d="M211.4,245.6c3.1,2.9,7.9,2.8,10.9-0.2"/>
<line className="st5" x1="372" y1="395.1" x2="372.2" y2="395.3"/>
<polyline className="st5" points="372.2,395.3 366.6,401 366.4,400.7 372,395.1 "/>
<path className="st5" d="M366.6,389.7c-3,3-3.1,7.8-0.2,10.9"/>
<path className="st16" d="M378.6,403.2l1.7,1.6l-1.6-1.6H378.6z M380.3,404.9l-1.7-1.7l1.7,1.6V404.9z M378.6,403.2l1.7,1.7L378.6,403.2
	z M378.6,403.2L378.6,403.2l1.7,1.7L378.6,403.2z M380.3,404.9l-1.7-1.7L380.3,404.9z M378.6,403.2l1.7,1.7L378.6,403.2z
	 M378.6,403.2L378.6,403.2l1.7,1.7L378.6,403.2z M380.3,404.9l-1.7-1.7L380.3,404.9z"/>
<path className="st17" d="M378.6,403.2l1.7,1.6l-1.6-1.6H378.6z M380.3,404.9l-1.7-1.7l1.7,1.6V404.9z M378.6,403.2l1.7,1.7L378.6,403.2
	z M378.6,403.2L378.6,403.2l1.7,1.7L378.6,403.2z M380.3,404.9l-1.7-1.7L380.3,404.9z M378.6,403.2l1.7,1.7L378.6,403.2z
	 M378.6,403.2L378.6,403.2l1.7,1.7L378.6,403.2z M380.3,404.9l-1.7-1.7L380.3,404.9z"/>
<path className="st16" d="M377.5,404.3l2.8,0.6l-1.7-1.7L377.5,404.3z M379.2,406l-1.7-1.7l2.8,0.6L379.2,406z"/>
<path className="st17" d="M377.5,404.3l2.8,0.6l-1.7-1.7L377.5,404.3z M379.2,406l-1.7-1.7l2.8,0.6L379.2,406z"/>
<path className="st16" d="M377.4,404.4l1,0.7l-0.8-0.8L377.4,404.4z M377.8,404.9l-0.4-0.5l1,0.7L377.8,404.9z"/>
<path className="st17" d="M377.4,404.4l1,0.7l-0.8-0.8L377.4,404.4z M377.8,404.9l-0.4-0.5l1,0.7L377.8,404.9z"/>
<path className="st16" d="M378.6,405.8l0.6,0.2l-0.8-0.8L378.6,405.8z M379.1,406.2l-0.5-0.5l0.6,0.2L379.1,406.2z"/>
<path className="st17" d="M378.6,405.8l0.6,0.2l-0.8-0.8L378.6,405.8z M379.1,406.2l-0.5-0.5l0.6,0.2L379.1,406.2z"/>
<path className="st16" d="M377.4,404.4L377.4,404.4l0.4,0.5L377.4,404.4z M377.8,404.9l-0.4-0.5L377.8,404.9z"/>
<path className="st17" d="M377.4,404.4L377.4,404.4l0.4,0.5L377.4,404.4z M377.8,404.9l-0.4-0.5L377.8,404.9z"/>
<path className="st16" d="M377.9,405.3l0.4,0.2l-0.2-0.2H377.9z M378.2,405.6l-0.4-0.4l0.4,0.2V405.6z M377.9,405.3l0.4,0.4L377.9,405.3
	z M377.9,405.3L377.9,405.3l0.4,0.4L377.9,405.3z M378.2,405.6l-0.4-0.4L378.2,405.6z M377.9,405.3l0.4,0.4L377.9,405.3z
	 M377.9,405.3L377.9,405.3l0.4,0.4L377.9,405.3z M378.2,405.6l-0.4-0.4L378.2,405.6z"/>
<path className="st17" d="M377.9,405.3l0.4,0.2l-0.2-0.2H377.9z M378.2,405.6l-0.4-0.4l0.4,0.2V405.6z M377.9,405.3l0.4,0.4L377.9,405.3
	z M377.9,405.3L377.9,405.3l0.4,0.4L377.9,405.3z M378.2,405.6l-0.4-0.4L378.2,405.6z M377.9,405.3l0.4,0.4L377.9,405.3z
	 M377.9,405.3L377.9,405.3l0.4,0.4L377.9,405.3z M378.2,405.6l-0.4-0.4L378.2,405.6z"/>
<path className="st16" d="M378,405.9l-0.4-0.4l0.1,0.2L378,405.9z M378.2,405.6l-0.2,0.2l-0.4-0.4L378.2,405.6z"/>
<path className="st17" d="M378,405.9l-0.4-0.4l0.1,0.2L378,405.9z M378.2,405.6l-0.2,0.2l-0.4-0.4L378.2,405.6z"/>
<polygon className="st16" points="378.2,405.6 377.9,405.3 377.6,405.5 "/>
<polygon className="st17" points="378.2,405.6 377.9,405.3 377.6,405.5 "/>
<path className="st16" d="M378.5,406.4l0.6-0.1l-0.5-0.5L378.5,406.4z M378.6,406.6l-0.1-0.2l0.6-0.1L378.6,406.6z"/>
<path className="st17" d="M378.5,406.4l0.6-0.1l-0.5-0.5L378.5,406.4z M378.6,406.6l-0.1-0.2l0.6-0.1L378.6,406.6z"/>
<path className="st16" d="M376.9,404.9h0.8l-0.4-0.5L376.9,404.9z M377.2,405.2l-0.2-0.2h0.8L377.2,405.2z"/>
<path className="st17" d="M376.9,404.9h0.8l-0.4-0.5L376.9,404.9z M377.2,405.2l-0.2-0.2h0.8L377.2,405.2z"/>
<path className="st16" d="M376.6,405.3l0.6-0.1l-0.2-0.2L376.6,405.3z M376.8,405.4l-0.2-0.1l0.6-0.1L376.8,405.4z"/>
<path className="st17" d="M376.6,405.3l0.6-0.1l-0.2-0.2L376.6,405.3z M376.8,405.4l-0.2-0.1l0.6-0.1L376.8,405.4z"/>
<path className="st16" d="M378.1,406.7l0.5-0.1l-0.1-0.2L378.1,406.7z M378.4,407l-0.2-0.2l0.5-0.1L378.4,407z"/>
<path className="st17" d="M378.1,406.7l0.5-0.1l-0.1-0.2L378.1,406.7z M378.4,407l-0.2-0.2l0.5-0.1L378.4,407z"/>
<path className="st16" d="M377.5,407h0.8l-0.2-0.2L377.5,407z M377.8,407.2l-0.2-0.2h0.8L377.8,407.2z"/>
<path className="st17" d="M377.5,407h0.8l-0.2-0.2L377.5,407z M377.8,407.2l-0.2-0.2h0.8L377.8,407.2z"/>
<path className="st16" d="M376.3,405.8l0.5-0.4l-0.2-0.1L376.3,405.8z M376.6,406l-0.2-0.2l0.5-0.4L376.6,406z"/>
<path className="st17" d="M376.3,405.8l0.5-0.4l-0.2-0.1L376.3,405.8z M376.6,406l-0.2-0.2l0.5-0.4L376.6,406z"/>
<path className="st16" d="M376.3,405.9l0.2,0.1l-0.2-0.2V405.9z M376.6,406.1l-0.2-0.2l0.2,0.1V406.1z"/>
<path className="st17" d="M376.3,405.9l0.2,0.1l-0.2-0.2V405.9z M376.6,406.1l-0.2-0.2l0.2,0.1V406.1z"/>
<path className="st16" d="M377.4,407l0.4,0.2l-0.2-0.2H377.4z M377.6,407.3l-0.2-0.4l0.4,0.2L377.6,407.3z"/>
<path className="st17" d="M377.4,407l0.4,0.2l-0.2-0.2H377.4z M377.6,407.3l-0.2-0.4l0.4,0.2L377.6,407.3z"/>
<path className="st16" d="M376.3,406.2l0.2-0.1l-0.2-0.2V406.2z M376.8,406.7l-0.5-0.5l0.2-0.1L376.8,406.7z"/>
<path className="st17" d="M376.3,406.2l0.2-0.1l-0.2-0.2V406.2z M376.8,406.7l-0.5-0.5l0.2-0.1L376.8,406.7z"/>
<path className="st16" d="M376.8,406.7l0.8,0.6l-0.2-0.4L376.8,406.7z M377.3,407.3l-0.5-0.6l0.8,0.6H377.3z"/>
<path className="st17" d="M376.8,406.7l0.8,0.6l-0.2-0.4L376.8,406.7z M377.3,407.3l-0.5-0.6l0.8,0.6H377.3z"/>
<path className="st16" d="M376.3,406.4l1,1l-1-1.1V406.4z M377.2,407.3l-0.8-1l1,1H377.2z"/>
<path className="st17" d="M376.3,406.4l1,1l-1-1.1V406.4z M377.2,407.3l-0.8-1l1,1H377.2z"/>
<path className="st16" d="M376.3,406.5l0.8,0.8l-0.8-1V406.5z M377,407.2l-0.7-0.7l0.8,0.8L377,407.2z"/>
<path className="st17" d="M376.3,406.5l0.8,0.8l-0.8-1V406.5z M377,407.2l-0.7-0.7l0.8,0.8L377,407.2z"/>
<path className="st16" d="M376.9,407.2l-0.6-0.7l0.4,0.5L376.9,407.2z M377,407.2h-0.1l-0.6-0.7L377,407.2z"/>
<path className="st17" d="M376.9,407.2l-0.6-0.7l0.4,0.5L376.9,407.2z M377,407.2h-0.1l-0.6-0.7L377,407.2z"/>
<path className="st16" d="M377,407.2l-0.7-0.7L377,407.2z"/>
<path className="st17" d="M377,407.2l-0.7-0.7L377,407.2z"/>
<path className="st19" d="M378.6,403.1C378.6,403.1,378.5,403.1,378.6,403.1"/>
<line className="st19" x1="378.7" y1="403.2" x2="380.3" y2="404.8"/>
<path className="st19" d="M380.3,404.9C380.3,404.9,380.3,404.8,380.3,404.9"/>
<line className="st19" x1="380.3" y1="404.9" x2="378.4" y2="407"/>
<path className="st19" d="M376.4,405.2c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="376.6" y1="405.3" x2="378.6" y2="403.2"/>
<path className="st19" d="M377.9,405.2C377.8,405.2,377.8,405.2,377.9,405.2"/>
<line className="st19" x1="377.9" y1="405.3" x2="377.6" y2="405.5"/>
<path className="st19" d="M377.5,405.5c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="378" y1="405.9" x2="378.2" y2="405.6"/>
<path className="st19" d="M378.2,405.6C378.2,405.6,378.2,405.6,378.2,405.6"/>
<line className="st19" x1="378.2" y1="405.5" x2="378" y2="405.3"/>
<line className="st19" x1="378.5" y1="406.4" x2="378.1" y2="406.7"/>
<path className="st19" d="M376.7,405.4c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="376.8" y1="405.4" x2="377.2" y2="405.2"/>
<path className="st19" d="M378.4,406.3c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<path className="st5" d="M102.4,139.4c-2.1,2.3-2.1,6,0.2,8.2"/>
<polyline className="st5" points="102.4,139.4 102.6,139.2 106.8,143.4 106.6,143.6 102.4,139.4 "/>
<line className="st5" x1="197" y1="220.2" x2="197.3" y2="220.4"/>
<polyline className="st5" points="197.3,220.4 191.6,226 191.5,225.8 197,220.2 "/>
<path className="st5" d="M191.7,214.7c-3,3-3.1,7.8-0.2,10.9"/>
<path className="st5" d="M88.3,161.7c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="80.2,161.7 79.9,161.8 84.1,166 84.4,165.9 80.2,161.7 "/>
<path className="st5" d="M81.3,168.8c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="73.1,168.6 73,168.9 77.2,173.1 77.3,172.8 73.1,168.6 "/>
<path className="st5" d="M70.6,171c-2.1,2.3-2.1,6,0.2,8.2"/>
<polyline className="st5" points="70.7,171.2 70.8,170.9 75,175.1 74.9,175.4 70.7,171.2 "/>
<path className="st5" d="M143.6,173.6c0.1,3.2,2.8,5.7,5.9,5.7"/>
<polyline className="st5" points="143.6,173.7 143.6,173.4 149.6,173.4 149.6,173.7 143.6,173.7 "/>
<path className="st5" d="M102.1,179.3c3.2,0,5.8-2.5,5.9-5.7"/>
<polyline className="st5" points="108.1,173.7 108.1,173.4 102.1,173.4 102.1,173.7 108.1,173.7 "/>
<path className="st5" d="M152,142.9c2.2,2.2,5.9,2.3,8.2,0.2"/>
<polyline className="st5" points="160.2,143.1 160.4,143 156.2,138.8 156,138.9 160.2,143.1 "/>
<path className="st5" d="M196.2,200.2c-1.8,2-1.7,5,0.2,6.9"/>
<line className="st5" x1="199.9" y1="203.6" x2="196.3" y2="200"/>
<polyline className="st5" points="199.9,203.6 199.7,203.7 196.2,200.2 196.3,200 "/>
<path className="st5" d="M196.3,207.1c-1.9-1.9-4.9-2-6.9-0.2"/>
<line className="st5" x1="192.8" y1="210.6" x2="189.2" y2="207"/>
<polyline className="st5" points="192.8,210.6 193,210.4 189.5,206.9 189.2,207 "/>
<line className="st6" x1="192.8" y1="210.5" x2="189.4" y2="207"/>
<line className="st6" x1="199.8" y1="203.7" x2="196.2" y2="200.1"/>
<path className="st5" d="M213.6,203.6c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="213.6,203.7 213.8,203.8 209.6,208 209.4,207.9 213.6,203.7 "/>
<path className="st5" d="M343.3,310.8c-2.2,2.2-2.3,5.9-0.2,8.2"/>
<polyline className="st5" points="343.1,319.1 343.3,319.2 347.5,315 347.3,314.9 343.1,319.1 "/>
<path className="st5" d="M325.7,293.4c-2.2,2.2-2.3,5.9-0.2,8.2"/>
<polyline className="st5" points="325.6,301.6 325.8,301.8 330,297.6 329.8,297.4 325.6,301.6 "/>
<path className="st5" d="M345.5,321.5c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="345.6,321.5 345.4,321.4 349.6,317.2 349.8,317.3 345.6,321.5 "/>
<path className="st5" d="M338.3,336.7c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="338.5,328.6 338.4,328.4 334.2,332.6 334.3,332.8 338.5,328.6 "/>
<path className="st16" d="M226,261.2L226,261.2l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z M226,261.2l1.7-1.7L226,261.2z
	 M226,261.2L226,261.2l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z M226,261.2l1.7-1.7L226,261.2z M226,261.2L226,261.2
	l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z"/>
<path className="st17" d="M226,261.2L226,261.2l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z M226,261.2l1.7-1.7L226,261.2z
	 M226,261.2L226,261.2l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z M226,261.2l1.7-1.7L226,261.2z M226,261.2L226,261.2
	l1.7-1.7L226,261.2z M227.6,259.5l-1.7,1.7L227.6,259.5z"/>
<path className="st16" d="M224.9,260.1l2.8-0.6l-1.7,1.7L224.9,260.1z M226.6,258.4l-1.7,1.7l2.8-0.6L226.6,258.4z"/>
<path className="st17" d="M224.9,260.1l2.8-0.6l-1.7,1.7L224.9,260.1z M226.6,258.4l-1.7,1.7l2.8-0.6L226.6,258.4z"/>
<path className="st16" d="M224.6,260l1.1-0.7l-0.8,0.8L224.6,260z M225.1,259.5l-0.5,0.5l1.1-0.7L225.1,259.5z"/>
<path className="st17" d="M224.6,260l1.1-0.7l-0.8,0.8L224.6,260z M225.1,259.5l-0.5,0.5l1.1-0.7L225.1,259.5z"/>
<path className="st16" d="M226,258.6l0.6-0.2l-0.8,0.8L226,258.6z M226.4,258.2l-0.5,0.5l0.6-0.2L226.4,258.2z"/>
<path className="st17" d="M226,258.6l0.6-0.2l-0.8,0.8L226,258.6z M226.4,258.2l-0.5,0.5l0.6-0.2L226.4,258.2z"/>
<path className="st16" d="M224.6,260L224.6,260l0.5-0.5L224.6,260z M225.1,259.5l-0.5,0.5L225.1,259.5z"/>
<path className="st17" d="M224.6,260L224.6,260l0.5-0.5L224.6,260z M225.1,259.5l-0.5,0.5L225.1,259.5z"/>
<path className="st16" d="M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z M225.2,259.1l0.4-0.4
	L225.2,259.1z M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z M225.2,259.1l0.4-0.4L225.2,259.1
	z M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z"/>
<path className="st17" d="M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z M225.2,259.1l0.4-0.4
	L225.2,259.1z M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z M225.2,259.1l0.4-0.4L225.2,259.1
	z M225.2,259.1L225.2,259.1l0.4-0.4L225.2,259.1z M225.6,258.8l-0.4,0.4L225.6,258.8z"/>
<path className="st16" d="M225.4,258.5l-0.4,0.4l0.1-0.2L225.4,258.5z M225.6,258.8l-0.2-0.2l-0.4,0.4L225.6,258.8z"/>
<path className="st17" d="M225.4,258.5l-0.4,0.4l0.1-0.2L225.4,258.5z M225.6,258.8l-0.2-0.2l-0.4,0.4L225.6,258.8z"/>
<polygon className="st16" points="225.6,258.8 225.2,259.1 225,258.9 "/>
<polygon className="st17" points="225.6,258.8 225.2,259.1 225,258.9 "/>
<path className="st16" d="M225.7,258l0.7,0.1l-0.5,0.5L225.7,258z M226,257.8l-0.2,0.2l0.7,0.1L226,257.8z"/>
<path className="st17" d="M225.7,258l0.7,0.1l-0.5,0.5L225.7,258z M226,257.8l-0.2,0.2l0.7,0.1L226,257.8z"/>
<path className="st16" d="M224.3,259.5h0.8l-0.5,0.5L224.3,259.5z M224.5,259.2l-0.2,0.2h0.8L224.5,259.2z"/>
<path className="st17" d="M224.3,259.5h0.8l-0.5,0.5L224.3,259.5z M224.5,259.2l-0.2,0.2h0.8L224.5,259.2z"/>
<path className="st16" d="M223.9,259.1l0.6,0.1l-0.2,0.2L223.9,259.1z M224.2,259l-0.2,0.1l0.6,0.1L224.2,259z"/>
<path className="st17" d="M223.9,259.1l0.6,0.1l-0.2,0.2L223.9,259.1z M224.2,259l-0.2,0.1l0.6,0.1L224.2,259z"/>
<path className="st16" d="M225.4,257.7l0.6,0.1l-0.2,0.2L225.4,257.7z M225.6,257.4l-0.2,0.2l0.6,0.1L225.6,257.4z"/>
<path className="st17" d="M225.4,257.7l0.6,0.1l-0.2,0.2L225.4,257.7z M225.6,257.4l-0.2,0.2l0.6,0.1L225.6,257.4z"/>
<path className="st16" d="M224.9,257.4h0.7l-0.2,0.2L224.9,257.4z M225.1,257.1l-0.2,0.4h0.7L225.1,257.1z"/>
<path className="st17" d="M224.9,257.4h0.7l-0.2,0.2L224.9,257.4z M225.1,257.1l-0.2,0.4h0.7L225.1,257.1z"/>
<path className="st16" d="M223.6,258.6l0.6,0.4l-0.2,0.1L223.6,258.6z M223.8,258.3l-0.2,0.4l0.6,0.4L223.8,258.3z"/>
<path className="st17" d="M223.6,258.6l0.6,0.4l-0.2,0.1L223.6,258.6z M223.8,258.3l-0.2,0.4l0.6,0.4L223.8,258.3z"/>
<path className="st16" d="M223.6,258.5l0.2-0.2l-0.2,0.4V258.5z M223.6,258.5L223.6,258.5l0.2-0.2L223.6,258.5z"/>
<path className="st17" d="M223.6,258.5l0.2-0.2l-0.2,0.4V258.5z M223.6,258.5L223.6,258.5l0.2-0.2L223.6,258.5z"/>
<path className="st16" d="M224.8,257.3l0.4-0.2l-0.2,0.4L224.8,257.3z M225,257.1l-0.2,0.2l0.4-0.2H225z"/>
<path className="st17" d="M224.8,257.3l0.4-0.2l-0.2,0.4L224.8,257.3z M225,257.1l-0.2,0.2l0.4-0.2H225z"/>
<path className="st16" d="M223.6,258.2l0.2,0.1l-0.2,0.2V258.2z M224.2,257.7l-0.6,0.5l0.2,0.1L224.2,257.7z"/>
<path className="st17" d="M223.6,258.2l0.2,0.1l-0.2,0.2V258.2z M224.2,257.7l-0.6,0.5l0.2,0.1L224.2,257.7z"/>
<path className="st16" d="M224.2,257.7l0.8-0.6l-0.2,0.2L224.2,257.7z M224.6,257.1l-0.5,0.6l0.8-0.6H224.6z"/>
<path className="st17" d="M224.2,257.7l0.8-0.6l-0.2,0.2L224.2,257.7z M224.6,257.1l-0.5,0.6l0.8-0.6H224.6z"/>
<path className="st16" d="M223.6,258l1.1-1l-1.1,1.1V258z M224.5,257.1l-1,1l1.1-1H224.5z"/>
<path className="st17" d="M223.6,258l1.1-1l-1.1,1.1V258z M224.5,257.1l-1,1l1.1-1H224.5z"/>
<path className="st16" d="M223.6,257.9l1-0.8l-1,1V257.9z M224.4,257.2l-0.8,0.7l1-0.8L224.4,257.2z"/>
<path className="st17" d="M223.6,257.9l1-0.8l-1,1V257.9z M224.4,257.2l-0.8,0.7l1-0.8L224.4,257.2z"/>
<path className="st16" d="M224.3,257.2l-0.7,0.7l0.4-0.6L224.3,257.2z M224.4,257.2h-0.1l-0.7,0.7L224.4,257.2z"/>
<path className="st17" d="M224.3,257.2l-0.7,0.7l0.4-0.6L224.3,257.2z M224.4,257.2h-0.1l-0.7,0.7L224.4,257.2z"/>
<polygon className="st16" points="224.4,257.2 223.7,257.9 223.6,257.9 "/>
<polygon className="st17" points="224.4,257.2 223.7,257.9 223.6,257.9 "/>
<path className="st19" d="M225.9,261.1C225.9,261.2,225.9,261.2,225.9,261.1"/>
<line className="st19" x1="226" y1="261.2" x2="227.6" y2="259.5"/>
<path className="st19" d="M227.6,259.5C227.6,259.4,227.6,259.4,227.6,259.5"/>
<line className="st19" x1="227.6" y1="259.5" x2="225.6" y2="257.4"/>
<path className="st19" d="M225.6,257.3c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8"/>
<line className="st19" x1="223.9" y1="259.1" x2="226" y2="261.2"/>
<path className="st19" d="M225.1,259C225.2,259,225.2,259,225.1,259"/>
<line className="st19" x1="225.2" y1="259.1" x2="225" y2="258.9"/>
<path className="st19" d="M225.3,258.4c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="225.4" y1="258.5" x2="225.6" y2="258.8"/>
<path className="st19" d="M225.5,258.7C225.5,258.7,225.5,258.7,225.5,258.7"/>
<line className="st19" x1="225.6" y1="258.8" x2="225.2" y2="259.1"/>
<line className="st19" x1="225.7" y1="258" x2="225.4" y2="257.7"/>
<path className="st19" d="M225.3,257.6c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="224.2" y1="259" x2="224.5" y2="259.2"/>
<path className="st19" d="M224.5,259.3c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<path className="st5" d="M217.3,254.4c-2.2,2.2-2.3,5.9-0.2,8.2"/>
<polyline className="st5" points="217.1,262.6 217.3,262.8 221.5,258.6 221.3,258.4 217.1,262.6 "/>
<line className="st1" x1="232" y1="255.2" x2="225.5" y2="261.6"/>
<line className="st5" x1="389.5" y1="412.6" x2="389.6" y2="412.8"/>
<polyline className="st5" points="389.6,412.8 384.1,418.4 383.9,418.2 389.5,412.6 "/>
<path className="st5" d="M384,407.2c-3,3-3.1,7.8-0.2,10.9"/>
<path className="st5" d="M363.3,413.9c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="363.2,413.9 363.1,413.8 367.3,409.6 367.4,409.7 363.2,413.9 "/>
<line className="st5" x1="324.6" y1="267.5" x2="324.8" y2="267.8"/>
<polyline className="st5" points="324.8,267.8 330.5,262.1 330.2,262 324.6,267.5 "/>
<path className="st5" d="M330.1,262c-3.1-2.9-7.9-2.8-10.9,0.2"/>
<line className="st5" x1="292.2" y1="235" x2="292" y2="234.9"/>
<polyline className="st5" points="292,234.9 297.6,229.2 297.7,229.5 292.2,235 "/>
<path className="st5" d="M297.5,240.4c3-3,3.1-7.8,0.2-10.9"/>
<path className="st16" d="M313.2,244l-1.7,1.7l1.6-1.7H313.2z M311.4,245.7l1.8-1.7l-1.7,1.7H311.4z M313.2,244l-1.8,1.7L313.2,244z
	 M313.2,244L313.2,244l-1.8,1.7L313.2,244z"/>
<path className="st17" d="M313.2,244l-1.7,1.7l1.6-1.7H313.2z M311.4,245.7l1.8-1.7l-1.7,1.7H311.4z M313.2,244l-1.8,1.7L313.2,244z
	 M313.2,244L313.2,244l-1.8,1.7L313.2,244z"/>
<path className="st16" d="M313.1,243.9l-1.7,1.8l1.8-1.7L313.1,243.9z M313.1,243.9L313.1,243.9l-1.7,1.8L313.1,243.9z M311.4,245.7
	l1.7-1.8L311.4,245.7z M313.1,243.9l-1.7,1.8L313.1,243.9z"/>
<path className="st17" d="M313.1,243.9l-1.7,1.8l1.8-1.7L313.1,243.9z M313.1,243.9L313.1,243.9l-1.7,1.8L313.1,243.9z M311.4,245.7
	l1.7-1.8L311.4,245.7z M313.1,243.9l-1.7,1.8L313.1,243.9z"/>
<path className="st16" d="M312,242.8l-0.6,2.9l1.7-1.8L312,242.8z M310.3,244.6l1.7-1.8l-0.6,2.9L310.3,244.6z"/>
<path className="st17" d="M312,242.8l-0.6,2.9l1.7-1.8L312,242.8z M310.3,244.6l1.7-1.8l-0.6,2.9L310.3,244.6z"/>
<path className="st16" d="M311.9,242.7l-0.7,1l0.8-0.8L311.9,242.7z M311.4,243l0.5-0.4l-0.7,1L311.4,243z"/>
<path className="st17" d="M311.9,242.7l-0.7,1l0.8-0.8L311.9,242.7z M311.4,243l0.5-0.4l-0.7,1L311.4,243z"/>
<path className="st16" d="M310.6,244l-0.2,0.6l0.8-1L310.6,244z M310.2,244.4l0.4-0.4l-0.2,0.6L310.2,244.4z"/>
<path className="st17" d="M310.6,244l-0.2,0.6l0.8-1L310.6,244z M310.2,244.4l0.4-0.4l-0.2,0.6L310.2,244.4z"/>
<path className="st16" d="M311.9,242.7L311.9,242.7l-0.5,0.4L311.9,242.7z M311.4,243l0.5-0.4L311.4,243z"/>
<path className="st17" d="M311.9,242.7L311.9,242.7l-0.5,0.4L311.9,242.7z M311.4,243l0.5-0.4L311.4,243z"/>
<path className="st16" d="M310.8,243.5l0.2-0.2L310.8,243.5z M311,243.3l-0.2,0.2L311,243.3z M311,243.3L311,243.3l-0.2,0.2L311,243.3z
	 M310.8,243.5l0.2-0.2L310.8,243.5z"/>
<path className="st17" d="M310.8,243.5l0.2-0.2L310.8,243.5z M311,243.3l-0.2,0.2L311,243.3z M311,243.3L311,243.3l-0.2,0.2L311,243.3z
	 M310.8,243.5l0.2-0.2L310.8,243.5z"/>
<path className="st16" d="M311,243.2l-0.2,0.4l0.2-0.2V243.2z M310.7,243.5l0.4-0.4l-0.2,0.4H310.7z M311,243.2l-0.4,0.4L311,243.2z
	 M311,243.2L311,243.2l-0.4,0.4L311,243.2z"/>
<path className="st17" d="M311,243.2l-0.2,0.4l0.2-0.2V243.2z M310.7,243.5l0.4-0.4l-0.2,0.4H310.7z M311,243.2l-0.4,0.4L311,243.2z
	 M311,243.2L311,243.2l-0.4,0.4L311,243.2z"/>
<path className="st16" d="M310.4,243.4l0.4-0.5l-0.1,0.2L310.4,243.4z M310.7,243.5l-0.2-0.1l0.4-0.5L310.7,243.5z"/>
<path className="st17" d="M310.4,243.4l0.4-0.5l-0.1,0.2L310.4,243.4z M310.7,243.5l-0.2-0.1l0.4-0.5L310.7,243.5z"/>
<polygon className="st16" points="310.7,243.5 311,243.2 310.8,242.9 "/>
<polygon className="st17" points="310.7,243.5 311,243.2 310.8,242.9 "/>
<path className="st16" d="M310,243.8l0.2,0.6l0.4-0.4L310,243.8z M309.7,244l0.2-0.2l0.2,0.6L309.7,244z"/>
<path className="st17" d="M310,243.8l0.2,0.6l0.4-0.4L310,243.8z M309.7,244l0.2-0.2l0.2,0.6L309.7,244z"/>
<path className="st16" d="M311.5,242.2l-0.1,0.8l0.5-0.4L311.5,242.2z M311.3,242.4l0.2-0.2l-0.1,0.8L311.3,242.4z"/>
<path className="st17" d="M311.5,242.2l-0.1,0.8l0.5-0.4L311.5,242.2z M311.3,242.4l0.2-0.2l-0.1,0.8L311.3,242.4z"/>
<path className="st16" d="M311.2,241.8l0.1,0.6l0.2-0.2L311.2,241.8z M310.9,242.1l0.2-0.2l0.1,0.6L310.9,242.1z"/>
<path className="st17" d="M311.2,241.8l0.1,0.6l0.2-0.2L311.2,241.8z M310.9,242.1l0.2-0.2l0.1,0.6L310.9,242.1z"/>
<path className="st16" d="M309.6,243.4l0.1,0.6l0.2-0.2L309.6,243.4z M309.4,243.6l0.2-0.2l0.1,0.6L309.4,243.6z"/>
<path className="st17" d="M309.6,243.4l0.1,0.6l0.2-0.2L309.6,243.4z M309.4,243.6l0.2-0.2l0.1,0.6L309.4,243.6z"/>
<path className="st16" d="M309.4,242.8v0.8l0.2-0.2L309.4,242.8z M309.1,243l0.2-0.2v0.8L309.1,243z"/>
<path className="st17" d="M309.4,242.8v0.8l0.2-0.2L309.4,242.8z M309.1,243l0.2-0.2v0.8L309.1,243z"/>
<path className="st16" d="M310.6,241.6l0.4,0.5l0.2-0.2L310.6,241.6z M310.3,241.8l0.2-0.2l0.4,0.5L310.3,241.8z M310.6,241.6l-0.2,0.2
	L310.6,241.6z M310.6,241.6L310.6,241.6l-0.2,0.2L310.6,241.6z"/>
<path className="st17" d="M310.6,241.6l0.4,0.5l0.2-0.2L310.6,241.6z M310.3,241.8l0.2-0.2l0.4,0.5L310.3,241.8z M310.6,241.6l-0.2,0.2
	L310.6,241.6z M310.6,241.6L310.6,241.6l-0.2,0.2L310.6,241.6z"/>
<path className="st16" d="M309.4,242.8L309.4,242.8l-0.2,0.2L309.4,242.8z M309.1,243l0.2-0.2L309.1,243z"/>
<path className="st17" d="M309.4,242.8L309.4,242.8l-0.2,0.2L309.4,242.8z M309.1,243l0.2-0.2L309.1,243z"/>
<path className="st16" d="M310.1,241.6l0.2,0.2l0.2-0.2H310.1z M309.6,242.1l0.5-0.5l0.2,0.2L309.6,242.1z"/>
<path className="st17" d="M310.1,241.6l0.2,0.2l0.2-0.2H310.1z M309.6,242.1l0.5-0.5l0.2,0.2L309.6,242.1z"/>
<path className="st16" d="M309.6,242.1l-0.5,1l0.2-0.2L309.6,242.1z M309.1,242.7l0.5-0.6l-0.5,1V242.7z"/>
<path className="st17" d="M309.6,242.1l-0.5,1l0.2-0.2L309.6,242.1z M309.1,242.7l0.5-0.6l-0.5,1V242.7z"/>
<path className="st16" d="M310,241.6l-0.8,1.1l1-1.1H310z M309.1,242.4l0.8-0.8l-0.8,1.1V242.4z M310,241.6l-0.8,0.8L310,241.6z
	 M310,241.6L310,241.6l-0.8,0.8L310,241.6z"/>
<path className="st17" d="M310,241.6l-0.8,1.1l1-1.1H310z M309.1,242.4l0.8-0.8l-0.8,1.1V242.4z M310,241.6l-0.8,0.8L310,241.6z
	 M310,241.6L310,241.6l-0.8,0.8L310,241.6z"/>
<path className="st16" d="M309.1,242.3l0.7-0.7l-0.5,0.4L309.1,242.3z M309.1,242.4v-0.1l0.7-0.7L309.1,242.4z"/>
<path className="st17" d="M309.1,242.3l0.7-0.7l-0.5,0.4L309.1,242.3z M309.1,242.4v-0.1l0.7-0.7L309.1,242.4z"/>
<path className="st16" d="M309.1,242.4l0.7-0.8L309.1,242.4z"/>
<path className="st17" d="M309.1,242.4l0.7-0.8L309.1,242.4z"/>
<path className="st19" d="M313.1,244C313.1,244,313.1,243.9,313.1,244"/>
<line className="st19" x1="313.1" y1="244" x2="311.5" y2="245.7"/>
<path className="st19" d="M311.3,245.5C311.3,245.6,311.4,245.6,311.3,245.5"/>
<line className="st19" x1="311.4" y1="245.7" x2="309.4" y2="243.6"/>
<path className="st19" d="M311.2,241.8c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8"/>
<line className="st19" x1="311.2" y1="241.8" x2="313.1" y2="243.9"/>
<path className="st19" d="M311,243.3C311,243.2,311,243.2,311,243.3"/>
<line className="st19" x1="311" y1="243.2" x2="310.8" y2="242.9"/>
<path className="st19" d="M310.7,242.8c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="310.4" y1="243.4" x2="310.7" y2="243.5"/>
<path className="st19" d="M310.7,243.5C310.7,243.5,310.8,243.5,310.7,243.5"/>
<line className="st19" x1="310.8" y1="243.5" x2="311" y2="243.3"/>
<line className="st19" x1="310" y1="243.8" x2="309.6" y2="243.4"/>
<path className="st19" d="M310.9,242.1c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="310.9" y1="242.1" x2="311.3" y2="242.4"/>
<path className="st19" d="M309.9,243.7c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<path className="st5" d="M314.5,235.1c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="314.6,235.1 314.8,235.4 310.6,239.6 310.4,239.3 314.6,235.1 "/>
<line className="st1" x1="307.1" y1="250" x2="313.7" y2="243.5"/>
<line className="st5" x1="359.6" y1="302.6" x2="359.9" y2="302.7"/>
<polyline className="st5" points="359.9,302.7 365.4,297.2 365.3,296.9 359.6,302.6 "/>
<path className="st5" d="M365.2,296.9c-3.1-2.9-7.9-2.8-10.9,0.2"/>
<line className="st5" x1="327.1" y1="270" x2="327" y2="269.8"/>
<polyline className="st5" points="327,269.8 332.5,264.3 332.8,264.4 327.1,270 "/>
<path className="st5" d="M332.5,275.3c3-3,3.1-7.8,0.2-10.9"/>
<line className="st5" x1="394.7" y1="337.5" x2="394.8" y2="337.7"/>
<polyline className="st5" points="394.8,337.7 400.4,332.1 400.2,332 394.7,337.5 "/>
<path className="st5" d="M400.1,331.9c-3.1-2.9-7.9-2.8-10.9,0.2"/>
<line className="st5" x1="362.2" y1="305" x2="361.9" y2="304.8"/>
<polyline className="st5" points="361.9,304.8 367.6,299.2 367.7,299.4 362.2,305 "/>
<path className="st5" d="M367.4,310.3c3-3,3.1-7.8,0.2-10.9"/>
<polyline className="st18" points="183.5,216.9 177,210.4 182.2,205.2 188.6,211.7 183.5,216.9 "/>
<line className="st18" x1="188.6" y1="211.7" x2="183.7" y2="211.1"/>
<line className="st18" x1="182.6" y1="210" x2="182.2" y2="205.2"/>
<line className="st18" x1="181.6" y1="211.1" x2="177" y2="210.4"/>
<polyline className="st18" points="182.6,212.3 183.7,211.1 182.6,210 181.6,211.1 182.6,212.3 183.5,216.9 "/>
<line className="st18" x1="182.6" y1="211.8" x2="181.9" y2="211.2"/>
<line className="st18" x1="182.8" y1="211.7" x2="182" y2="211.1"/>
<line className="st18" x1="182.9" y1="211.6" x2="182.3" y2="211"/>
<line className="st18" x1="183" y1="211.5" x2="182.4" y2="210.9"/>
<line className="st18" x1="183.1" y1="211.4" x2="182.5" y2="210.8"/>
<line className="st18" x1="183.2" y1="211.2" x2="182.6" y2="210.6"/>
<line className="st18" x1="183.4" y1="211.1" x2="182.8" y2="210.4"/>
<path className="st16" d="M181.8,205.6l-0.5,0.4l0.5-0.5V205.6z M181.3,206.1l0.5-0.5l-0.5,0.4V206.1z"/>
<path className="st17" d="M181.8,205.6l-0.5,0.4l0.5-0.5V205.6z M181.3,206.1l0.5-0.5l-0.5,0.4V206.1z"/>
<path className="st16" d="M183.2,207.3l-1.8-1.4l0.2-0.1L183.2,207.3z M183,207.4l0.2-0.1l-1.8-1.4L183,207.4z"/>
<path className="st17" d="M183.2,207.3l-1.8-1.4l0.2-0.1L183.2,207.3z M183,207.4l0.2-0.1l-1.8-1.4L183,207.4z"/>
<path className="st16" d="M183.5,207.2l-0.5,0.4l0.4-0.4H183.5z M183.5,207.2L183.5,207.2l-0.5,0.4L183.5,207.2z"/>
<path className="st17" d="M183.5,207.2l-0.5,0.4l0.4-0.4H183.5z M183.5,207.2L183.5,207.2l-0.5,0.4L183.5,207.2z"/>
<path className="st16" d="M183,207.5L183,207.5l-1.6,1.6L183,207.5z M181.4,209.1l1.6-1.6L181.4,209.1z"/>
<path className="st17" d="M183,207.5L183,207.5l-1.6,1.6L183,207.5z M181.4,209.1l1.6-1.6L181.4,209.1z"/>
<path className="st16" d="M183.5,207.2l-0.5,0.4L183.5,207.2z M183,207.6l0.5-0.5l-0.5,0.4V207.6z"/>
<path className="st17" d="M183.5,207.2l-0.5,0.4L183.5,207.2z M183,207.6l0.5-0.5l-0.5,0.4V207.6z"/>
<path className="st16" d="M183.6,207.3l-0.6,0.4l0.5-0.5L183.6,207.3z M183.1,207.6l0.5-0.4l-0.6,0.4H183.1z"/>
<path className="st17" d="M183.6,207.3l-0.6,0.4l0.5-0.5L183.6,207.3z M183.1,207.6l0.5-0.4l-0.6,0.4H183.1z"/>
<path className="st16" d="M183.5,207.3l-0.4,0.4l0.5-0.4H183.5z M183.1,207.8l0.4-0.5l-0.4,0.4V207.8z"/>
<path className="st17" d="M183.5,207.3l-0.4,0.4l0.5-0.4H183.5z M183.1,207.8l0.4-0.5l-0.4,0.4V207.8z"/>
<path className="st16" d="M183.1,207.8l-1.7,1.3l1.6-1.6L183.1,207.8z M181.6,209.2l1.6-1.4l-1.7,1.3L181.6,209.2z"/>
<path className="st17" d="M183.1,207.8l-1.7,1.3l1.6-1.6L183.1,207.8z M181.6,209.2l1.6-1.4l-1.7,1.3L181.6,209.2z"/>
<path className="st16" d="M184.8,208.6l-1.3-1.2v-0.1L184.8,208.6z M184.7,208.7l0.1-0.1l-1.3-1.2L184.7,208.7z"/>
<path className="st17" d="M184.8,208.6l-1.3-1.2v-0.1L184.8,208.6z M184.7,208.7l0.1-0.1l-1.3-1.2L184.7,208.7z"/>
<path className="st16" d="M184.7,208.7l-1.4-1.1l0.2-0.2L184.7,208.7z M184.4,208.8l0.2-0.1l-1.4-1.1L184.4,208.8z"/>
<path className="st17" d="M184.7,208.7l-1.4-1.1l0.2-0.2L184.7,208.7z M184.4,208.8l0.2-0.1l-1.4-1.1L184.4,208.8z"/>
<path className="st16" d="M184.4,209l-2.9,0.2l1.6-1.4L184.4,209z M182.9,210.5l1.6-1.6l-2.9,0.2L182.9,210.5z"/>
<path className="st17" d="M184.4,209l-2.9,0.2l1.6-1.4L184.4,209z M182.9,210.5l1.6-1.6l-2.9,0.2L182.9,210.5z"/>
<path className="st16" d="M184.9,208.6l-0.5,0.4l0.4-0.4H184.9z M184.9,208.6L184.9,208.6l-0.5,0.4L184.9,208.6z"/>
<path className="st17" d="M184.9,208.6l-0.5,0.4l0.4-0.4H184.9z M184.9,208.6L184.9,208.6l-0.5,0.4L184.9,208.6z"/>
<path className="st16" d="M184.9,208.6l-0.5,0.4L184.9,208.6z M184.4,209.1l0.5-0.5l-0.5,0.4V209.1z"/>
<path className="st17" d="M184.9,208.6l-0.5,0.4L184.9,208.6z M184.4,209.1l0.5-0.5l-0.5,0.4V209.1z"/>
<path className="st16" d="M185,208.7l-0.6,0.4l0.5-0.5L185,208.7z M184.6,209.1l0.5-0.4l-0.6,0.4H184.6z"/>
<path className="st17" d="M185,208.7l-0.6,0.4l0.5-0.5L185,208.7z M184.6,209.1l0.5-0.4l-0.6,0.4H184.6z"/>
<path className="st16" d="M184.9,208.7l-0.4,0.4l0.5-0.4H184.9z M184.6,209.2l0.4-0.5l-0.4,0.4V209.2z"/>
<path className="st17" d="M184.9,208.7l-0.4,0.4l0.5-0.4H184.9z M184.6,209.2l0.4-0.5l-0.4,0.4V209.2z"/>
<path className="st16" d="M184.6,209.2l-1.7,1.3l1.6-1.6L184.6,209.2z M183,210.6l1.6-1.4l-1.7,1.3L183,210.6z"/>
<path className="st17" d="M184.6,209.2l-1.7,1.3l1.6-1.6L184.6,209.2z M183,210.6l1.6-1.4l-1.7,1.3L183,210.6z"/>
<path className="st16" d="M185.4,209.4l-0.7-0.4l0.1-0.2L185.4,209.4z M185.3,209.7l0.1-0.2l-0.7-0.4L185.3,209.7z"/>
<path className="st17" d="M185.4,209.4l-0.7-0.4l0.1-0.2L185.4,209.4z M185.3,209.7l0.1-0.2l-0.7-0.4L185.3,209.7z"/>
<path className="st16" d="M185.6,209.6l-0.4,0.1l0.1-0.2L185.6,209.6z M185.4,209.7l0.2-0.1l-0.4,0.1H185.4z"/>
<path className="st17" d="M185.6,209.6l-0.4,0.1l0.1-0.2L185.6,209.6z M185.4,209.7l0.2-0.1l-0.4,0.1H185.4z"/>
<path className="st16" d="M185.8,209.4l-0.4,0.2l0.2-0.1L185.8,209.4z M185.8,209.4L185.8,209.4l-0.4,0.2L185.8,209.4z"/>
<path className="st17" d="M185.8,209.4l-0.4,0.2l0.2-0.1L185.8,209.4z M185.8,209.4L185.8,209.4l-0.4,0.2L185.8,209.4z"/>
<path className="st16" d="M186.1,209.2l-0.4-0.1l0.1-0.1L186.1,209.2z M186,209.3l0.1-0.1l-0.4-0.1L186,209.3z"/>
<path className="st17" d="M186.1,209.2l-0.4-0.1l0.1-0.1L186.1,209.2z M186,209.3l0.1-0.1l-0.4-0.1L186,209.3z"/>
<path className="st16" d="M186,209.3l-0.6,0.4l0.5-0.5L186,209.3z M185.6,209.8l0.4-0.5l-0.6,0.4L185.6,209.8z"/>
<path className="st17" d="M186,209.3l-0.6,0.4l0.5-0.5L186,209.3z M185.6,209.8l0.4-0.5l-0.6,0.4L185.6,209.8z"/>
<path className="st16" d="M186.1,209.2L186.1,209.2l-0.1,0.1L186.1,209.2z M186,209.3l0.1-0.1L186,209.3z"/>
<path className="st17" d="M186.1,209.2L186.1,209.2l-0.1,0.1L186.1,209.2z M186,209.3l0.1-0.1L186,209.3z"/>
<path className="st16" d="M186,209.3L186,209.3l-0.4,0.5L186,209.3z M185.6,209.8l0.4-0.5L185.6,209.8z"/>
<path className="st17" d="M186,209.3L186,209.3l-0.4,0.5L186,209.3z M185.6,209.8l0.4-0.5L185.6,209.8z"/>
<path className="st16" d="M186.1,209.4l-0.5,0.4l0.4-0.5L186.1,209.4z M185.9,209.7l0.2-0.2l-0.5,0.4L185.9,209.7z"/>
<path className="st17" d="M186.1,209.4l-0.5,0.4l0.4-0.5L186.1,209.4z M185.9,209.7l0.2-0.2l-0.5,0.4L185.9,209.7z"/>
<path className="st16" d="M186.4,209.4l-0.4-0.1l0.1-0.1L186.4,209.4z M186.2,209.6l0.1-0.1l-0.4-0.1L186.2,209.6z"/>
<path className="st17" d="M186.4,209.4l-0.4-0.1l0.1-0.1L186.4,209.4z M186.2,209.6l0.1-0.1l-0.4-0.1L186.2,209.6z"/>
<line className="st18" x1="183.2" y1="207.3" x2="181.7" y2="205.7"/>
<line className="st18" x1="183" y1="207.4" x2="181.4" y2="205.8"/>
<line className="st18" x1="185.3" y1="209.7" x2="184.7" y2="209.1"/>
<line className="st18" x1="185.4" y1="209.4" x2="184.8" y2="208.8"/>
<line className="st18" x1="185.9" y1="209.7" x2="186.1" y2="209.4"/>
<line className="st18" x1="185.8" y1="209.4" x2="185.9" y2="209.2"/>
<path className="st18" d="M185.3,209.6c0.2,0.2,0.4,0.2,0.6,0"/>
<path className="st18" d="M185.5,209.4c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st18" x1="186.2" y1="209.6" x2="186.4" y2="209.4"/>
<line className="st18" x1="185.8" y1="209.1" x2="185.9" y2="209"/>
<polyline className="st18" points="186.2,209.6 186,209.3 185.8,209.1 "/>
<line className="st18" x1="181.3" y1="206.1" x2="181.3" y2="206"/>
<polyline className="st18" points="181.3,206.1 181.6,205.8 181.8,205.6 181.8,205.5 "/>
<polyline className="st18" points="184.9,208.7 184.7,209.1 183,210.6 181.4,209.1 183,207.4 183.4,207.2 "/>
<line className="st18" x1="183.1" y1="207.8" x2="183.5" y2="207.3"/>
<line className="st18" x1="184.4" y1="209" x2="184.8" y2="208.6"/>
<path className="st18" d="M185,208.8c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st18" d="M183.5,207.3c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0"/>
<polyline className="st18" points="183.5,207.4 184.7,208.7 183.5,207.4 "/>
<line className="st18" x1="184.4" y1="208.8" x2="183.2" y2="207.6"/>
<line className="st18" x1="184.6" y1="209.2" x2="183" y2="207.5"/>
<polyline className="st18" points="183.2,207.6 184.4,208.8 183.2,207.6 "/>
<line className="st18" x1="184.8" y1="208.6" x2="183.5" y2="207.3"/>
<path className="st16" d="M201,228l-1.9-1.6l1.4,1.3L201,228z M198.8,226l2.2,2l-1.9-1.6L198.8,226z M201,228l-2.2-2L201,228z M201,228
	L201,228l-2.2-2L201,228z"/>
<path className="st17" d="M201,228l-1.9-1.6l1.4,1.3L201,228z M198.8,226l2.2,2l-1.9-1.6L198.8,226z M201,228l-2.2-2L201,228z M201,228
	L201,228l-2.2-2L201,228z"/>
<path className="st16" d="M201.2,228l-2.4-2l2.2,2H201.2z M198.8,225.8l2.4,2.3l-2.4-2V225.8z"/>
<path className="st17" d="M201.2,228l-2.4-2l2.2,2H201.2z M198.8,225.8l2.4,2.3l-2.4-2V225.8z"/>
<path className="st16" d="M201.5,228l-0.7-0.5l0.5,0.5H201.5z M201.1,227.7l0.4,0.4l-0.7-0.5L201.1,227.7z"/>
<path className="st17" d="M201.5,228l-0.7-0.5l0.5,0.5H201.5z M201.1,227.7l0.4,0.4l-0.7-0.5L201.1,227.7z"/>
<path className="st16" d="M201.1,227.7l-1.8-1.4l1.4,1.3L201.1,227.7z M199.2,225.9l1.9,1.8l-1.8-1.4L199.2,225.9z"/>
<path className="st17" d="M201.1,227.7l-1.8-1.4l1.4,1.3L201.1,227.7z M199.2,225.9l1.9,1.8l-1.8-1.4L199.2,225.9z"/>
<path className="st16" d="M201.5,228l-0.4-0.4L201.5,228z M201.2,227.8l0.2,0.2l-0.4-0.4L201.2,227.8z"/>
<path className="st17" d="M201.5,228l-0.4-0.4L201.5,228z M201.2,227.8l0.2,0.2l-0.4-0.4L201.2,227.8z"/>
<path className="st16" d="M201.2,227.8l-2-1.9l1.9,1.8L201.2,227.8z M199.2,225.8l2,2l-2-1.9V225.8z"/>
<path className="st17" d="M201.2,227.8l-2-1.9l1.9,1.8L201.2,227.8z M199.2,225.8l2,2l-2-1.9V225.8z"/>
<path className="st16" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st17" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st16" d="M201.2,227.8L201.2,227.8l-2-2L201.2,227.8z M199.2,225.8l2,2L199.2,225.8z"/>
<path className="st17" d="M201.2,227.8L201.2,227.8l-2-2L201.2,227.8z M199.2,225.8l2,2L199.2,225.8z"/>
<path className="st16" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st17" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st16" d="M201.2,227.8L201.2,227.8l-2-2L201.2,227.8z M199.2,225.8l2,2L199.2,225.8z"/>
<path className="st17" d="M201.2,227.8L201.2,227.8l-2-2L201.2,227.8z M199.2,225.8l2,2L199.2,225.8z"/>
<path className="st16" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st17" d="M201.5,228L201.5,228l-0.2-0.2L201.5,228z M201.2,227.8l0.2,0.2L201.2,227.8z"/>
<path className="st16" d="M201.2,227.8l-2-2L201.2,227.8z M199.2,225.6l2,2.2l-2-2V225.6z"/>
<path className="st17" d="M201.2,227.8l-2-2L201.2,227.8z M199.2,225.6l2,2.2l-2-2V225.6z"/>
<path className="st16" d="M199.2,225.6l-0.4,0.1l0.5,0.5L199.2,225.6z M198.8,225.4l0.4,0.2l-0.4,0.1V225.4z"/>
<path className="st17" d="M199.2,225.6l-0.4,0.1l0.5,0.5L199.2,225.6z M198.8,225.4l0.4,0.2l-0.4,0.1V225.4z"/>
<path className="st16" d="M201.2,227.8L201.2,227.8l-2-2.2L201.2,227.8z M199.2,225.6l2,2.2L199.2,225.6z"/>
<path className="st17" d="M201.2,227.8L201.2,227.8l-2-2.2L201.2,227.8z M199.2,225.6l2,2.2L199.2,225.6z"/>
<path className="st16" d="M199.2,225.6L199.2,225.6l-0.4-0.2L199.2,225.6z M198.8,225.4l0.4,0.2L198.8,225.4z"/>
<path className="st17" d="M199.2,225.6L199.2,225.6l-0.4-0.2L199.2,225.6z M198.8,225.4l0.4,0.2L198.8,225.4z"/>
<polygon className="st16" points="202.3,228.4 202,227.9 202.4,228.4 "/>
<polygon className="st17" points="202.3,228.4 202,227.9 202.4,228.4 "/>
<path className="st16" d="M201.6,227.7l-2.4-2l2,2.2L201.6,227.7z M199.3,225.4l2.3,2.3l-2.4-2L199.3,225.4z"/>
<path className="st17" d="M201.6,227.7l-2.4-2l2,2.2L201.6,227.7z M199.3,225.4l2.3,2.3l-2.4-2L199.3,225.4z"/>
<path className="st16" d="M199.3,225.4h-0.5l0.4,0.2L199.3,225.4z M199.1,225.2l0.2,0.2h-0.5L199.1,225.2z"/>
<path className="st17" d="M199.3,225.4h-0.5l0.4,0.2L199.3,225.4z M199.1,225.2l0.2,0.2h-0.5L199.1,225.2z"/>
<path className="st16" d="M202.4,228.4l-0.5-0.5L202.4,228.4z M202,227.8l0.5,0.6l-0.5-0.5V227.8z"/>
<path className="st17" d="M202.4,228.4l-0.5-0.5L202.4,228.4z M202,227.8l0.5,0.6l-0.5-0.5V227.8z"/>
<path className="st16" d="M202.6,228.4l-0.6-0.6l0.5,0.6H202.6z M202.6,228.4L202.6,228.4l-0.6-0.6L202.6,228.4z"/>
<path className="st17" d="M202.6,228.4l-0.6-0.6l0.5,0.6H202.6z M202.6,228.4L202.6,228.4l-0.6-0.6L202.6,228.4z"/>
<path className="st16" d="M202,227.8h-0.7l0.2,0.2L202,227.8z M201.7,227.6l0.2,0.2h-0.7L201.7,227.6z"/>
<path className="st17" d="M202,227.8h-0.7l0.2,0.2L202,227.8z M201.7,227.6l0.2,0.2h-0.7L201.7,227.6z"/>
<path className="st16" d="M202.6,228.4L202.6,228.4l-0.6-0.6L202.6,228.4z M202,227.8l0.6,0.6L202,227.8z"/>
<path className="st17" d="M202.6,228.4L202.6,228.4l-0.6-0.6L202.6,228.4z M202,227.8l0.6,0.6L202,227.8z"/>
<path className="st16" d="M202,227.8L202,227.8l-0.2-0.2L202,227.8z M201.7,227.6l0.2,0.2L201.7,227.6z"/>
<path className="st17" d="M202,227.8L202,227.8l-0.2-0.2L202,227.8z M201.7,227.6l0.2,0.2L201.7,227.6z"/>
<path className="st16" d="M201.7,227.6l-2.4-2.2l2.3,2.3L201.7,227.6z M199.4,225.3l2.3,2.3l-2.4-2.2L199.4,225.3z"/>
<path className="st17" d="M201.7,227.6l-2.4-2.2l2.3,2.3L201.7,227.6z M199.4,225.3l2.3,2.3l-2.4-2.2L199.4,225.3z"/>
<path className="st16" d="M202.7,228.4l-0.7-0.6l0.6,0.6H202.7z M202.7,228.4L202.7,228.4l-0.7-0.6L202.7,228.4z"/>
<path className="st17" d="M202.7,228.4l-0.7-0.6l0.6,0.6H202.7z M202.7,228.4L202.7,228.4l-0.7-0.6L202.7,228.4z"/>
<path className="st16" d="M202,227.8L202,227.8l-0.2-0.2L202,227.8z M201.7,227.6l0.2,0.2L201.7,227.6z"/>
<path className="st17" d="M202,227.8L202,227.8l-0.2-0.2L202,227.8z M201.7,227.6l0.2,0.2L201.7,227.6z"/>
<path className="st16" d="M201.7,227.6l-2.3-2.3L201.7,227.6z M199.4,225.2l2.3,2.4l-2.3-2.3V225.2z"/>
<path className="st17" d="M201.7,227.6l-2.3-2.3L201.7,227.6z M199.4,225.2l2.3,2.4l-2.3-2.3V225.2z"/>
<path className="st16" d="M199.4,225.2h-0.4l0.2,0.2L199.4,225.2z M199.2,224.9l0.2,0.2h-0.4L199.2,224.9z"/>
<path className="st17" d="M199.4,225.2h-0.4l0.2,0.2L199.4,225.2z M199.2,224.9l0.2,0.2h-0.4L199.2,224.9z"/>
<path className="st16" d="M202.7,228.4l-0.7-0.6L202.7,228.4z M202.1,227.8l0.6,0.6l-0.7-0.6H202.1z"/>
<path className="st17" d="M202.7,228.4l-0.7-0.6L202.7,228.4z M202.1,227.8l0.6,0.6l-0.7-0.6H202.1z"/>
<path className="st16" d="M202.1,227.8l-0.4-0.2l0.2,0.2H202.1z M201.8,227.6l0.2,0.2l-0.4-0.2H201.8z"/>
<path className="st17" d="M202.1,227.8l-0.4-0.2l0.2,0.2H202.1z M201.8,227.6l0.2,0.2l-0.4-0.2H201.8z"/>
<path className="st16" d="M201.8,227.6l-2.4-2.4l2.3,2.4H201.8z M201.8,227.6L201.8,227.6l-2.4-2.4L201.8,227.6z"/>
<path className="st17" d="M201.8,227.6l-2.4-2.4l2.3,2.4H201.8z M201.8,227.6L201.8,227.6l-2.4-2.4L201.8,227.6z"/>
<path className="st16" d="M199.4,225.2L199.4,225.2l-0.2-0.2L199.4,225.2z M199.2,224.9l0.2,0.2L199.2,224.9z"/>
<path className="st17" d="M199.4,225.2L199.4,225.2l-0.2-0.2L199.4,225.2z M199.2,224.9l0.2,0.2L199.2,224.9z"/>
<path className="st16" d="M202.8,228.4l-0.7-0.6l0.6,0.6H202.8z M202.1,227.7l0.7,0.7l-0.7-0.6V227.7z"/>
<path className="st17" d="M202.8,228.4l-0.7-0.6l0.6,0.6H202.8z M202.1,227.7l0.7,0.7l-0.7-0.6V227.7z"/>
<path className="st16" d="M202.1,227.7l-0.2-0.1l0.2,0.2V227.7z M201.8,227.4l0.2,0.2l-0.2-0.1V227.4z"/>
<path className="st17" d="M202.1,227.7l-0.2-0.1l0.2,0.2V227.7z M201.8,227.4l0.2,0.2l-0.2-0.1V227.4z"/>
<path className="st16" d="M201.8,227.4l-2.4-2.3l2.4,2.4V227.4z M201.8,227.4L201.8,227.4l-2.4-2.3L201.8,227.4z"/>
<path className="st17" d="M201.8,227.4l-2.4-2.3l2.4,2.4V227.4z M201.8,227.4L201.8,227.4l-2.4-2.3L201.8,227.4z"/>
<path className="st16" d="M199.4,225.2l-0.2-0.2L199.4,225.2z M199.3,224.9l0.1,0.2l-0.2-0.2H199.3z"/>
<path className="st17" d="M199.4,225.2l-0.2-0.2L199.4,225.2z M199.3,224.9l0.1,0.2l-0.2-0.2H199.3z"/>
<path className="st16" d="M202.9,228.5l-0.8-0.8l0.7,0.7L202.9,228.5z M202.9,228.5L202.9,228.5l-0.8-0.8L202.9,228.5z"/>
<path className="st17" d="M202.9,228.5l-0.8-0.8l0.7,0.7L202.9,228.5z M202.9,228.5L202.9,228.5l-0.8-0.8L202.9,228.5z"/>
<path className="st16" d="M202.1,227.7L202.1,227.7l-0.2-0.2L202.1,227.7z M201.8,227.4l0.2,0.2L201.8,227.4z"/>
<path className="st17" d="M202.1,227.7L202.1,227.7l-0.2-0.2L202.1,227.7z M201.8,227.4l0.2,0.2L201.8,227.4z"/>
<path className="st16" d="M201.8,227.4l-1.2-1.1L201.8,227.4z M200.8,226.4l1.1,1.1l-1.2-1.1H200.8z"/>
<path className="st17" d="M201.8,227.4l-1.2-1.1L201.8,227.4z M200.8,226.4l1.1,1.1l-1.2-1.1H200.8z"/>
<path className="st16" d="M200.6,226.1l-1.2-1l1.2,1.2V226.1z M199.6,225.2l1.1,1l-1.2-1H199.6z"/>
<path className="st17" d="M200.6,226.1l-1.2-1l1.2,1.2V226.1z M199.6,225.2l1.1,1l-1.2-1H199.6z"/>
<path className="st16" d="M199.6,225.2l-0.2-0.2l0.1,0.2H199.6z M199.6,225.2L199.6,225.2l-0.2-0.2L199.6,225.2z"/>
<path className="st17" d="M199.6,225.2l-0.2-0.2l0.1,0.2H199.6z M199.6,225.2L199.6,225.2l-0.2-0.2L199.6,225.2z"/>
<path className="st16" d="M202.9,228.5L202.9,228.5l-0.8-0.8L202.9,228.5z M202.1,227.7l0.8,0.8L202.1,227.7z"/>
<path className="st17" d="M202.9,228.5L202.9,228.5l-0.8-0.8L202.9,228.5z M202.1,227.7l0.8,0.8L202.1,227.7z"/>
<path className="st16" d="M202.1,227.7l-0.2-0.2L202.1,227.7z M202,227.4l0.1,0.2l-0.2-0.2H202z"/>
<path className="st17" d="M202.1,227.7l-0.2-0.2L202.1,227.7z M202,227.4l0.1,0.2l-0.2-0.2H202z"/>
<path className="st16" d="M203,228.4l-1-0.7l0.8,0.8L203,228.4z M202.2,227.6l0.8,0.8l-1-0.7L202.2,227.6z"/>
<path className="st17" d="M203,228.4l-1-0.7l0.8,0.8L203,228.4z M202.2,227.6l0.8,0.8l-1-0.7L202.2,227.6z"/>
<path className="st16" d="M202.2,227.6l-0.2-0.1l0.1,0.2L202.2,227.6z M202,227.3l0.2,0.2l-0.2-0.1V227.3z"/>
<path className="st17" d="M202.2,227.6l-0.2-0.1l0.1,0.2L202.2,227.6z M202,227.3l0.2,0.2l-0.2-0.1V227.3z"/>
<path className="st16" d="M202,227.3l-1.2-1l1.1,1.1L202,227.3z M201,226.4l1,1l-1.2-1H201z"/>
<path className="st17" d="M202,227.3l-1.2-1l1.1,1.1L202,227.3z M201,226.4l1,1l-1.2-1H201z"/>
<path className="st16" d="M200.6,226l-1.1-0.8l1.1,1V226z M199.7,225l1,1l-1.1-0.8L199.7,225z"/>
<path className="st17" d="M200.6,226l-1.1-0.8l1.1,1V226z M199.7,225l1,1l-1.1-0.8L199.7,225z"/>
<path className="st16" d="M202.1,227.2l-1.1-0.8l1,1L202.1,227.2z M201,226.1l1.1,1.1l-1.1-0.8V226.1z"/>
<path className="st17" d="M202.1,227.2l-1.1-0.8l1,1L202.1,227.2z M201,226.1l1.1,1.1l-1.1-0.8V226.1z"/>
<path className="st16" d="M200.8,226l-1.1-1l1,1H200.8z M199.7,224.9l1.1,1.1l-1.1-1V224.9z"/>
<path className="st17" d="M200.8,226l-1.1-1l1,1H200.8z M199.7,224.9l1.1,1.1l-1.1-1V224.9z"/>
<path className="st16" d="M202.1,227.2l-1.1-1.1L202.1,227.2z M201,226l1.1,1.2l-1.1-1.1V226z"/>
<path className="st17" d="M202.1,227.2l-1.1-1.1L202.1,227.2z M201,226l1.1,1.2l-1.1-1.1V226z"/>
<path className="st16" d="M201,226l-1.3-1.1l1.1,1.1H201z M199.8,224.9l1.2,1.1l-1.3-1.1H199.8z"/>
<path className="st17" d="M201,226l-1.3-1.1l1.1,1.1H201z M199.8,224.9l1.2,1.1l-1.3-1.1H199.8z"/>
<path className="st16" d="M203.3,228.3l-1.1-0.7l0.8,0.8L203.3,228.3z M202.4,227.3l0.8,1l-1.1-0.7L202.4,227.3z"/>
<path className="st17" d="M203.3,228.3l-1.1-0.7l0.8,0.8L203.3,228.3z M202.4,227.3l0.8,1l-1.1-0.7L202.4,227.3z"/>
<path className="st16" d="M202.4,227.3H202l0.2,0.2L202.4,227.3z M202.2,227.1l0.2,0.2H202L202.2,227.1z"/>
<path className="st17" d="M202.4,227.3H202l0.2,0.2L202.4,227.3z M202.2,227.1l0.2,0.2H202L202.2,227.1z"/>
<path className="st16" d="M202.2,227.1L201,226l1.1,1.2L202.2,227.1z M201,225.9l1.2,1.2L201,226V225.9z"/>
<path className="st17" d="M202.2,227.1L201,226l1.1,1.2L202.2,227.1z M201,225.9l1.2,1.2L201,226V225.9z"/>
<path className="st16" d="M201,225.9l-1.2-1l1.2,1.1V225.9z M199.9,224.8l1.1,1.1l-1.2-1L199.9,224.8z"/>
<path className="st17" d="M201,225.9l-1.2-1l1.2,1.1V225.9z M199.9,224.8l1.1,1.1l-1.2-1L199.9,224.8z"/>
<path className="st16" d="M203.5,228.2l-1.1-0.8l0.8,1L203.5,228.2z M202.6,227.2l1,1l-1.1-0.8L202.6,227.2z"/>
<path className="st17" d="M203.5,228.2l-1.1-0.8l0.8,1L203.5,228.2z M202.6,227.2l1,1l-1.1-0.8L202.6,227.2z"/>
<path className="st16" d="M202.6,227.2l-0.4-0.1l0.2,0.2L202.6,227.2z M202.3,227l0.2,0.2l-0.4-0.1L202.3,227z"/>
<path className="st17" d="M202.6,227.2l-0.4-0.1l0.2,0.2L202.6,227.2z M202.3,227l0.2,0.2l-0.4-0.1L202.3,227z"/>
<path className="st16" d="M202.3,227l-1.2-0.8l1.1,1L202.3,227z M201.4,225.9l1,1.1l-1.2-0.8L201.4,225.9z"/>
<path className="st17" d="M202.3,227l-1.2-0.8l1.1,1L202.3,227z M201.4,225.9l1,1.1l-1.2-0.8L201.4,225.9z"/>
<path className="st16" d="M203.6,228l-1.4-1.2l1.3,1.3L203.6,228z M202.3,226.7l1.3,1.3l-1.4-1.2L202.3,226.7z"/>
<path className="st17" d="M203.6,228l-1.4-1.2l1.3,1.3L203.6,228z M202.3,226.7l1.3,1.3l-1.4-1.2L202.3,226.7z"/>
<path className="st16" d="M202.3,226.7l-1-0.8l0.8,1L202.3,226.7z M202.3,226.7L202.3,226.7l-1-0.8L202.3,226.7z"/>
<path className="st17" d="M202.3,226.7l-1-0.8l0.8,1L202.3,226.7z M202.3,226.7L202.3,226.7l-1-0.8L202.3,226.7z"/>
<path className="st16" d="M201.1,225.5l-1.2-0.7l1,1L201.1,225.5z M200.2,224.6l1,1l-1.2-0.7L200.2,224.6z"/>
<path className="st17" d="M201.1,225.5l-1.2-0.7l1,1L201.1,225.5z M200.2,224.6l1,1l-1.2-0.7L200.2,224.6z"/>
<path className="st16" d="M200.2,224.6l-0.8,0.4l0.2,0.2L200.2,224.6z M199.9,224.3l0.2,0.2l-0.8,0.4L199.9,224.3z"/>
<path className="st17" d="M200.2,224.6l-0.8,0.4l0.2,0.2L200.2,224.6z M199.9,224.3l0.2,0.2l-0.8,0.4L199.9,224.3z"/>
<path className="st16" d="M202.6,226.6l-1.2-0.7l1,0.8L202.6,226.6z M201.6,225.6l1,1l-1.2-0.7L201.6,225.6z M202.6,226.6l-1-1
	L202.6,226.6z M202.6,226.6L202.6,226.6l-1-1L202.6,226.6z M201.6,225.6l1,1L201.6,225.6z M202.6,226.6l-1-1L202.6,226.6z"/>
<path className="st17" d="M202.6,226.6l-1.2-0.7l1,0.8L202.6,226.6z M201.6,225.6l1,1l-1.2-0.7L201.6,225.6z M202.6,226.6l-1-1
	L202.6,226.6z M202.6,226.6L202.6,226.6l-1-1L202.6,226.6z M201.6,225.6l1,1L201.6,225.6z M202.6,226.6l-1-1L202.6,226.6z"/>
<path className="st16" d="M201.4,225.3l-0.7-0.2l0.5,0.5L201.4,225.3z M200.9,224.9l0.5,0.4l-0.7-0.2L200.9,224.9z"/>
<path className="st17" d="M201.4,225.3l-0.7-0.2l0.5,0.5L201.4,225.3z M200.9,224.9l0.5,0.4l-0.7-0.2L200.9,224.9z"/>
<path className="st16" d="M202.7,226.5l-1.1-0.8l1,1L202.7,226.5z M201.7,225.5l1,1l-1.1-0.8L201.7,225.5z"/>
<path className="st17" d="M202.7,226.5l-1.1-0.8l1,1L202.7,226.5z M201.7,225.5l1,1l-1.1-0.8L201.7,225.5z"/>
<path className="st16" d="M201.5,225.3l-0.6-0.4l0.5,0.4H201.5z M201,224.8l0.5,0.5l-0.6-0.4L201,224.8z"/>
<path className="st17" d="M201.5,225.3l-0.6-0.4l0.5,0.4H201.5z M201,224.8l0.5,0.5l-0.6-0.4L201,224.8z"/>
<path className="st16" d="M204,227.7l-1.7-1l1.3,1.3L204,227.7z M202.7,226.4l1.3,1.3l-1.7-1L202.7,226.4z"/>
<path className="st17" d="M204,227.7l-1.7-1l1.3,1.3L204,227.7z M202.7,226.4l1.3,1.3l-1.7-1L202.7,226.4z"/>
<path className="st16" d="M202.7,226.4l-1-0.8l1,1V226.4z M201.6,225.3l1.1,1.1l-1-0.8L201.6,225.3z"/>
<path className="st17" d="M202.7,226.4l-1-0.8l1,1V226.4z M201.6,225.3l1.1,1.1l-1-0.8L201.6,225.3z"/>
<path className="st16" d="M201.6,225.3l-0.6-0.5l0.5,0.5H201.6z M201,224.7l0.6,0.6l-0.6-0.5V224.7z"/>
<path className="st17" d="M201.6,225.3l-0.6-0.5l0.5,0.5H201.6z M201,224.7l0.6,0.6l-0.6-0.5V224.7z"/>
<path className="st16" d="M204.1,227.6l-1.4-1.2l1.3,1.3L204.1,227.6z M202.8,226.4l1.3,1.2l-1.4-1.2H202.8z"/>
<path className="st17" d="M204.1,227.6l-1.4-1.2l1.3,1.3L204.1,227.6z M202.8,226.4l1.3,1.2l-1.4-1.2H202.8z"/>
<path className="st16" d="M202.8,226.4l-1.2-1.1l1.1,1.1H202.8z M201.7,225.3l1.1,1.1l-1.2-1.1H201.7z"/>
<path className="st17" d="M202.8,226.4l-1.2-1.1l1.1,1.1H202.8z M201.7,225.3l1.1,1.1l-1.2-1.1H201.7z"/>
<path className="st16" d="M201.7,225.3l-0.7-0.6l0.6,0.6H201.7z M201.1,224.7l0.6,0.6l-0.7-0.6H201.1z"/>
<path className="st17" d="M201.7,225.3l-0.7-0.6l0.6,0.6H201.7z M201.1,224.7l0.6,0.6l-0.7-0.6H201.1z"/>
<path className="st16" d="M201.1,224.7l-0.7,0.1l0.2,0.2L201.1,224.7z M200.8,224.3l0.4,0.4l-0.7,0.1L200.8,224.3z"/>
<path className="st17" d="M201.1,224.7l-0.7,0.1l0.2,0.2L201.1,224.7z M200.8,224.3l0.4,0.4l-0.7,0.1L200.8,224.3z"/>
<path className="st16" d="M200.8,224.3l-0.6,0.2l0.2,0.2L200.8,224.3z M200.6,224.2l0.1,0.1l-0.6,0.2L200.6,224.2z"/>
<path className="st17" d="M200.8,224.3l-0.6,0.2l0.2,0.2L200.8,224.3z M200.6,224.2l0.1,0.1l-0.6,0.2L200.6,224.2z"/>
<path className="st16" d="M200.6,224.2l-0.7,0.1l0.2,0.2L200.6,224.2z M200.3,223.8l0.4,0.4l-0.7,0.1L200.3,223.8z"/>
<path className="st17" d="M200.6,224.2l-0.7,0.1l0.2,0.2L200.6,224.2z M200.3,223.8l0.4,0.4l-0.7,0.1L200.3,223.8z"/>
<path className="st16" d="M204.4,227.7l-2.2-1.9l2.2,2V227.7z M202.3,225.6l2,2l-2.2-1.9L202.3,225.6z"/>
<path className="st17" d="M204.4,227.7l-2.2-1.9l2.2,2V227.7z M202.3,225.6l2,2l-2.2-1.9L202.3,225.6z"/>
<path className="st16" d="M201.4,224.7l-1.2-1.1l1.1,1.2L201.4,224.7z M200.2,223.5l1.2,1.2l-1.2-1.1V223.5z"/>
<path className="st17" d="M201.4,224.7l-1.2-1.1l1.1,1.2L201.4,224.7z M200.2,223.5l1.2,1.2l-1.2-1.1V223.5z"/>
<path className="st16" d="M204.5,227.6l-2.6-2.4l2.5,2.5L204.5,227.6z M202,225l2.5,2.5l-2.6-2.4L202,225z"/>
<path className="st17" d="M204.5,227.6l-2.6-2.4l2.5,2.5L204.5,227.6z M202,225l2.5,2.5l-2.6-2.4L202,225z"/>
<path className="st16" d="M202,225l-1.8-1.6l1.7,1.7L202,225z M200.3,223.4l1.7,1.7l-1.8-1.6L200.3,223.4z"/>
<path className="st17" d="M202,225l-1.8-1.6l1.7,1.7L202,225z M200.3,223.4l1.7,1.7l-1.8-1.6L200.3,223.4z"/>
<line className="st19" x1="200.3" y1="226.7" x2="202.8" y2="224.2"/>
<line className="st19" x1="202.2" y1="226.8" x2="203.5" y2="228.2"/>
<line className="st19" x1="201.4" y1="225.9" x2="202.3" y2="226.7"/>
<line className="st19" x1="200.4" y1="224.8" x2="200.6" y2="225"/>
<polyline className="st19" points="200.3,223.8 199.9,224.3 200.2,224.6 200.6,224.2 "/>
<line className="st19" x1="200.4" y1="224.8" x2="200.8" y2="224.3"/>
<line className="st19" x1="200.6" y1="225" x2="201.1" y2="224.7"/>
<line className="st19" x1="202.2" y1="226.8" x2="202.8" y2="226.4"/>
<line className="st19" x1="203.5" y1="228.2" x2="204.1" y2="227.6"/>
<line className="st19" x1="200.9" y1="225.8" x2="201.1" y2="226.1"/>
<path className="st19" d="M201.5,225.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C201.6,225.5,201.6,225.4,201.5,225.3"/>
<line className="st19" x1="200.9" y1="225.8" x2="201.4" y2="225.3"/>
<line className="st19" x1="201.1" y1="226.1" x2="201.7" y2="225.5"/>
<polyline className="st19" points="201.2,224.8 201.4,224.7 202.3,225.6 202.2,225.8 "/>
<path className="st19" d="M200.8,225.9c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C200.9,226.1,200.9,226,200.8,225.9"/>
<path className="st19" d="M200.5,227.7c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3"/>
<path className="st19" d="M199.1,224.9c-0.2,0.2-0.4,0.5-0.4,0.7s0.1,0.6,0.3,0.8"/>
<path className="st19" d="M199.3,225.2c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.2,0.1,0.4,0.2,0.5"/>
<path className="st19" d="M200.7,227.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2"/>
<line className="st19" x1="199.3" y1="226.2" x2="200.8" y2="227.6"/>
<line className="st19" x1="201.7" y1="227.6" x2="202.3" y2="227"/>
<polyline className="st19" points="199.4,225.2 200.2,224.6 201.1,225.5 "/>
<line className="st19" x1="199.1" y1="226.5" x2="200.5" y2="227.8"/>
<path className="st19" d="M201.9,227.8c0.1,0.3,0.4,0.6,0.7,0.6c0.3,0.1,0.7-0.1,0.9-0.4"/>
<line className="st19" x1="200.2" y1="223.6" x2="204.4" y2="227.8"/>
<polyline className="st19" points="200.2,223.6 200.3,223.4 204.5,227.6 204.4,227.8 "/>
<line className="st19" x1="202" y1="227.8" x2="202.6" y2="227.2"/>
<line className="st19" x1="199.2" y1="224.9" x2="200.3" y2="223.8"/>
<path className="st20" d="M201.6,225.6L201.6,225.6z"/>
<path className="st21" d="M201.6,225.6L201.6,225.6z"/>
<path className="st20" d="M201.6,225.6L201.6,225.6z"/>
<path className="st21" d="M201.6,225.6L201.6,225.6z"/>
<path className="st20" d="M201.6,225.6L201.6,225.6z"/>
<path className="st21" d="M201.6,225.6L201.6,225.6z"/>
<path className="st20" d="M201.6,225.5h0.1H201.6z"/>
<path className="st21" d="M201.6,225.5h0.1H201.6z"/>
<polygon className="st20" points="201.6,225.6 201.6,225.5 201.7,225.5 "/>
<polygon className="st21" points="201.6,225.6 201.6,225.5 201.7,225.5 "/>
<path className="st20" d="M201.6,225.6l0.1-0.1L201.6,225.6z"/>
<path className="st21" d="M201.6,225.6l0.1-0.1L201.6,225.6z"/>
<line className="st1" x1="182.2" y1="205.2" x2="167.2" y2="220.1"/>
<line className="st5" x1="244.4" y1="242.8" x2="244.6" y2="243"/>
<polyline className="st5" points="244.6,243 250.2,237.4 250,237.3 244.4,242.8 "/>
<path className="st5" d="M249.9,237.2c-3.1-2.9-7.9-2.8-10.9,0.2"/>
<path className="st5" d="M247,225.6c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="247.2,217.5 247.1,217.2 242.9,221.4 243,221.7 247.2,217.5 "/>
<polyline className="st18" points="238.3,235.4 237.8,234.9 238,234.8 238.4,235.2 238.3,235.4 "/>
<line className="st18" x1="238.2" y1="235" x2="239.2" y2="234.2"/>
<path className="st18" d="M239.1,234.1c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0"/>
<line className="st18" x1="239" y1="233.9" x2="238.1" y2="234.9"/>
<polyline className="st18" points="235.8,232.8 235.3,232.4 235.4,232.2 235.9,232.7 235.8,232.8 "/>
<line className="st18" x1="235.7" y1="232.6" x2="236.6" y2="231.6"/>
<path className="st18" d="M236.6,231.6c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0"/>
<line className="st18" x1="236.5" y1="231.4" x2="235.6" y2="232.4"/>
<path className="st16" d="M235.9,233l1.7,1.7L236,233H235.9z M237.7,234.6l-1.8-1.7l1.7,1.7H237.7z M235.9,233l1.8,1.7L235.9,233z
	 M235.9,233L235.9,233l1.8,1.7L235.9,233z"/>
<path className="st17" d="M235.9,233l1.7,1.7L236,233H235.9z M237.7,234.6l-1.8-1.7l1.7,1.7H237.7z M235.9,233l1.8,1.7L235.9,233z
	 M235.9,233L235.9,233l1.8,1.7L235.9,233z"/>
<path className="st16" d="M236,232.8l1.7,1.8l-1.8-1.7L236,232.8z M236,232.8L236,232.8l1.7,1.8L236,232.8z M237.7,234.6l-1.7-1.8
	L237.7,234.6z M236,232.8l1.7,1.8L236,232.8z"/>
<path className="st17" d="M236,232.8l1.7,1.8l-1.8-1.7L236,232.8z M236,232.8L236,232.8l1.7,1.8L236,232.8z M237.7,234.6l-1.7-1.8
	L237.7,234.6z M236,232.8l1.7,1.8L236,232.8z"/>
<path className="st16" d="M237,231.8l0.7,2.9l-1.7-1.8L237,231.8z M238.8,233.6l-1.8-1.8l0.7,2.9L238.8,233.6z"/>
<path className="st17" d="M237,231.8l0.7,2.9l-1.7-1.8L237,231.8z M238.8,233.6l-1.8-1.8l0.7,2.9L238.8,233.6z"/>
<path className="st16" d="M237.2,231.6l0.7,1.1l-1-1L237.2,231.6z M237.6,232.1l-0.4-0.5l0.7,1.1L237.6,232.1z"/>
<path className="st17" d="M237.2,231.6l0.7,1.1l-1-1L237.2,231.6z M237.6,232.1l-0.4-0.5l0.7,1.1L237.6,232.1z"/>
<path className="st16" d="M238.6,233l0.2,0.6l-0.8-0.8L238.6,233z M238.9,233.3l-0.4-0.4l0.2,0.6L238.9,233.3z"/>
<path className="st17" d="M238.6,233l0.2,0.6l-0.8-0.8L238.6,233z M238.9,233.3l-0.4-0.4l0.2,0.6L238.9,233.3z"/>
<path className="st16" d="M237.2,231.6L237.2,231.6l0.4,0.5L237.2,231.6z M237.6,232.1l-0.4-0.5L237.6,232.1z"/>
<path className="st17" d="M237.2,231.6L237.2,231.6l0.4,0.5L237.2,231.6z M237.6,232.1l-0.4-0.5L237.6,232.1z"/>
<path className="st16" d="M238.3,232.6l-0.2-0.4L238.3,232.6z M238.1,232.2l0.2,0.4L238.1,232.2z M238.1,232.2L238.1,232.2l0.2,0.4
	L238.1,232.2z M238.3,232.6l-0.2-0.4L238.3,232.6z"/>
<path className="st17" d="M238.3,232.6l-0.2-0.4L238.3,232.6z M238.1,232.2l0.2,0.4L238.1,232.2z M238.1,232.2L238.1,232.2l0.2,0.4
	L238.1,232.2z M238.3,232.6l-0.2-0.4L238.3,232.6z"/>
<path className="st16" d="M238.1,232.1l0.2,0.5l-0.2-0.4V232.1z M238.4,232.6l-0.4-0.5l0.2,0.5H238.4z M238.1,232.1l0.4,0.5L238.1,232.1
	z M238.1,232.1L238.1,232.1l0.4,0.5L238.1,232.1z"/>
<path className="st17" d="M238.1,232.1l0.2,0.5l-0.2-0.4V232.1z M238.4,232.6l-0.4-0.5l0.2,0.5H238.4z M238.1,232.1l0.4,0.5L238.1,232.1
	z M238.1,232.1L238.1,232.1l0.4,0.5L238.1,232.1z"/>
<path className="st16" d="M238.7,232.4l-0.5-0.4l0.2,0.1L238.7,232.4z M238.4,232.6l0.2-0.2l-0.5-0.4L238.4,232.6z"/>
<path className="st17" d="M238.7,232.4l-0.5-0.4l0.2,0.1L238.7,232.4z M238.4,232.6l0.2-0.2l-0.5-0.4L238.4,232.6z"/>
<polygon className="st16" points="238.4,232.6 238.1,232.1 238.2,232 "/>
<polygon className="st17" points="238.4,232.6 238.1,232.1 238.2,232 "/>
<path className="st16" d="M239.2,232.7l-0.2,0.6l-0.4-0.4L239.2,232.7z M239.4,233l-0.2-0.2l-0.2,0.6L239.4,233z"/>
<path className="st17" d="M239.2,232.7l-0.2,0.6l-0.4-0.4L239.2,232.7z M239.4,233l-0.2-0.2l-0.2,0.6L239.4,233z"/>
<path className="st16" d="M237.6,231.3v0.8l-0.4-0.5L237.6,231.3z M237.8,231.5l-0.2-0.2v0.8L237.8,231.5z"/>
<path className="st17" d="M237.6,231.3v0.8l-0.4-0.5L237.6,231.3z M237.8,231.5l-0.2-0.2v0.8L237.8,231.5z"/>
<path className="st16" d="M238,230.9l-0.1,0.6l-0.2-0.2L238,230.9z M238.2,231l-0.2-0.1l-0.1,0.6L238.2,231z"/>
<path className="st17" d="M238,230.9l-0.1,0.6l-0.2-0.2L238,230.9z M238.2,231l-0.2-0.1l-0.1,0.6L238.2,231z"/>
<path className="st16" d="M239.5,232.4l-0.1,0.6l-0.2-0.2L239.5,232.4z M239.8,232.6l-0.2-0.2l-0.1,0.6L239.8,232.6z"/>
<path className="st17" d="M239.5,232.4l-0.1,0.6l-0.2-0.2L239.5,232.4z M239.8,232.6l-0.2-0.2l-0.1,0.6L239.8,232.6z"/>
<path className="st16" d="M239.8,231.8v0.8l-0.2-0.2L239.8,231.8z M240,232.1l-0.2-0.4v0.8L240,232.1z"/>
<path className="st17" d="M239.8,231.8v0.8l-0.2-0.2L239.8,231.8z M240,232.1l-0.2-0.4v0.8L240,232.1z"/>
<path className="st16" d="M238.6,230.6l-0.4,0.5l-0.2-0.1L238.6,230.6z M238.8,230.8l-0.2-0.2l-0.4,0.5L238.8,230.8z M238.6,230.6
	l0.2,0.2L238.6,230.6z M238.6,230.6L238.6,230.6l0.2,0.2L238.6,230.6z"/>
<path className="st17" d="M238.6,230.6l-0.4,0.5l-0.2-0.1L238.6,230.6z M238.8,230.8l-0.2-0.2l-0.4,0.5L238.8,230.8z M238.6,230.6
	l0.2,0.2L238.6,230.6z M238.6,230.6L238.6,230.6l0.2,0.2L238.6,230.6z"/>
<path className="st16" d="M239.8,231.8l0.2,0.4L239.8,231.8z M240,232l-0.2-0.2l0.2,0.4V232z"/>
<path className="st17" d="M239.8,231.8l0.2,0.4L239.8,231.8z M240,232l-0.2-0.2l0.2,0.4V232z"/>
<path className="st16" d="M239,230.6l-0.2,0.2l-0.2-0.2H239z M239.5,231l-0.5-0.5l-0.2,0.2L239.5,231z"/>
<path className="st17" d="M239,230.6l-0.2,0.2l-0.2-0.2H239z M239.5,231l-0.5-0.5l-0.2,0.2L239.5,231z"/>
<path className="st16" d="M239.5,231l0.5,1l-0.2-0.2L239.5,231z M240,231.6l-0.5-0.6l0.5,1V231.6z"/>
<path className="st17" d="M239.5,231l0.5,1l-0.2-0.2L239.5,231z M240,231.6l-0.5-0.6l0.5,1V231.6z"/>
<path className="st16" d="M239.2,230.6l0.8,1.1l-1-1.1H239.2z M240,231.5l-0.8-1l0.8,1.1V231.5z M240,231.4v0.1l-0.8-1L240,231.4z"/>
<path className="st17" d="M239.2,230.6l0.8,1.1l-1-1.1H239.2z M240,231.5l-0.8-1l0.8,1.1V231.5z M240,231.4v0.1l-0.8-1L240,231.4z"/>
<path className="st16" d="M240,231.5l-0.8-1L240,231.5z"/>
<path className="st17" d="M240,231.5l-0.8-1L240,231.5z"/>
<path className="st16" d="M240,231.3l-0.7-0.7l0.5,0.4L240,231.3z M240,231.4v-0.1l-0.7-0.7L240,231.4z"/>
<path className="st17" d="M240,231.3l-0.7-0.7l0.5,0.4L240,231.3z M240,231.4v-0.1l-0.7-0.7L240,231.4z"/>
<polygon className="st16" points="240,231.4 239.3,230.7 239.3,230.6 "/>
<polygon className="st17" points="240,231.4 239.3,230.7 239.3,230.6 "/>
<path className="st18" d="M235.9,232.9C235.9,232.9,235.9,232.9,235.9,232.9"/>
<line className="st18" x1="236" y1="233" x2="237.6" y2="234.6"/>
<path className="st18" d="M237.6,234.5C237.6,234.5,237.7,234.5,237.6,234.5"/>
<line className="st18" x1="237.7" y1="234.6" x2="239.8" y2="232.6"/>
<path className="st18" d="M239.6,232.6c0.5-0.5,0.5-1.3,0-1.8s-1.3-0.5-1.8,0"/>
<line className="st18" x1="238" y1="230.9" x2="236" y2="232.8"/>
<path className="st18" d="M238,232.1C238,232.2,238,232.2,238,232.1"/>
<line className="st18" x1="238.1" y1="232.1" x2="238.2" y2="232"/>
<path className="st18" d="M238.7,232.3c0-0.2-0.2-0.4-0.4-0.4"/>
<line className="st18" x1="238.7" y1="232.4" x2="238.4" y2="232.6"/>
<path className="st18" d="M238.2,232.5C238.2,232.5,238.3,232.5,238.2,232.5"/>
<line className="st18" x1="238.3" y1="232.6" x2="238.1" y2="232.2"/>
<line className="st18" x1="239.2" y1="232.7" x2="239.5" y2="232.4"/>
<path className="st18" d="M239.4,232.3c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-0.9-0.4-1.3,0"/>
<line className="st18" x1="238.2" y1="231" x2="237.8" y2="231.5"/>
<path className="st18" d="M237.8,231.3c-0.3,0.4-0.3,0.9,0,1.2c0.3,0.3,0.9,0.4,1.2,0"/>
<line className="st1" x1="244.9" y1="226.5" x2="244.7" y2="224.9"/>
<polyline className="st1" points="244.3,224.2 244,223.6 243.4,222.9 "/>
<polyline className="st1" points="242.8,222.4 241.4,221.8 241.3,221.7 "/>
<polyline className="st1" points="240.6,221.6 240,221.6 238.9,221.7 "/>
<polyline className="st1" points="238.2,221.9 237,222.4 236.9,222.6 "/>
<polyline className="st1" points="236.3,223.2 235.9,223.6 235.4,224.6 "/>
<polyline className="st1" points="235.2,225.3 235,226.5 235.1,226.8 "/>
<polyline className="st1" points="235.2,227.7 235.2,228 235.8,229.1 "/>
<polyline className="st1" points="236.3,229.7 237,230.4 237.5,230.7 "/>
<polyline className="st1" points="238.2,231 238.4,231.2 239.8,231.4 "/>
<polyline className="st1" points="240.6,231.3 241.4,231.2 242,230.9 "/>
<polyline className="st1" points="242.8,230.6 242.9,230.4 244,229.5 "/>
<polyline className="st1" points="244.3,228.8 244.7,228 244.8,227.2 "/>
<rect x="244.8" y="226.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -88.4074 239.5179)" className="st20" width="0.2" height="0.2"/>
<rect x="244.8" y="226.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -88.4074 239.5179)" className="st4" width="0.2" height="0.2"/>
<path className="st16" d="M141.8,181.1l0.2,2.5v-2L141.8,181.1z M141.8,184.1v-3l0.2,2.5L141.8,184.1z M141.8,181.1v3V181.1z
	 M141.8,181.1L141.8,181.1v3V181.1z"/>
<path className="st22" d="M141.8,181.1l0.2,2.5v-2L141.8,181.1z M141.8,184.1v-3l0.2,2.5L141.8,184.1z M141.8,181.1v3V181.1z
	 M141.8,181.1L141.8,181.1v3V181.1z"/>
<path className="st16" d="M141.7,181l0.1,3.1v-3L141.7,181z M141.7,184.4V181l0.1,3.1L141.7,184.4z"/>
<path className="st22" d="M141.7,181l0.1,3.1v-3L141.7,181z M141.7,184.4V181l0.1,3.1L141.7,184.4z"/>
<path className="st16" d="M141.6,180.9l0.1,0.7V181L141.6,180.9z M141.6,181.4v-0.5l0.1,0.7L141.6,181.4z"/>
<path className="st22" d="M141.6,180.9l0.1,0.7V181L141.6,180.9z M141.6,181.4v-0.5l0.1,0.7L141.6,181.4z"/>
<path className="st16" d="M141.6,181.4l0.1,2.3v-1.9L141.6,181.4z M141.6,183.9v-2.5l0.1,2.3L141.6,183.9z"/>
<path className="st22" d="M141.6,181.4l0.1,2.3v-1.9L141.6,181.4z M141.6,183.9v-2.5l0.1,2.3L141.6,183.9z"/>
<path className="st16" d="M141.5,180.8l0.1,0.6v-0.5L141.5,180.8z M141.5,181.2v-0.5l0.1,0.6L141.5,181.2z"/>
<path className="st22" d="M141.5,180.8l0.1,0.6v-0.5L141.5,180.8z M141.5,181.2v-0.5l0.1,0.6L141.5,181.2z"/>
<path className="st16" d="M141.5,181.2l0.1,2.6v-2.5L141.5,181.2z M141.5,184v-2.8l0.1,2.6L141.5,184z"/>
<path className="st22" d="M141.5,181.2l0.1,2.6v-2.5L141.5,181.2z M141.5,184v-2.8l0.1,2.6L141.5,184z"/>
<path className="st16" d="M141.5,180.8L141.5,180.8v0.5V180.8z M141.5,181.2v-0.5V181.2z"/>
<path className="st22" d="M141.5,180.8L141.5,180.8v0.5V180.8z M141.5,181.2v-0.5V181.2z"/>
<path className="st16" d="M141.5,181.2v2.8V181.2z M141.5,184.1v-2.9v2.8V184.1z"/>
<path className="st22" d="M141.5,181.2v2.8V181.2z M141.5,184.1v-2.9v2.8V184.1z"/>
<path className="st16" d="M141.5,180.8L141.5,180.8v0.5V180.8z M141.5,181.2v-0.5V181.2z"/>
<path className="st22" d="M141.5,180.8L141.5,180.8v0.5V180.8z M141.5,181.2v-0.5V181.2z"/>
<path className="st16" d="M141.5,181.2L141.5,181.2v2.9V181.2z M141.5,184.1v-2.9V184.1z"/>
<path className="st22" d="M141.5,181.2L141.5,181.2v2.9V181.2z M141.5,184.1v-2.9V184.1z"/>
<path className="st16" d="M141.5,180.8v0.5V180.8z M141.5,181.1v-0.4v0.5V181.1z"/>
<path className="st22" d="M141.5,180.8v0.5V180.8z M141.5,181.1v-0.4v0.5V181.1z"/>
<path className="st16" d="M141.5,181.1v3v-2.9V181.1z M141.5,181.1L141.5,181.1v3V181.1z"/>
<path className="st22" d="M141.5,181.1v3v-2.9V181.1z M141.5,181.1L141.5,181.1v3V181.1z"/>
<path className="st16" d="M141.5,184.1l0.2,0.2v-0.7L141.5,184.1z M141.5,184.5v-0.4l0.2,0.2L141.5,184.5z"/>
<path className="st22" d="M141.5,184.1l0.2,0.2v-0.7L141.5,184.1z M141.5,184.5v-0.4l0.2,0.2L141.5,184.5z"/>
<path className="st16" d="M141.5,181.1L141.5,181.1v3V181.1z M141.5,184.1v-3V184.1z"/>
<path className="st22" d="M141.5,181.1L141.5,181.1v3V181.1z M141.5,184.1v-3V184.1z"/>
<path className="st16" d="M141.5,184.1L141.5,184.1v0.4V184.1z M141.5,184.5v-0.4V184.5z"/>
<path className="st22" d="M141.5,184.1L141.5,184.1v0.4V184.1z M141.5,184.5v-0.4V184.5z"/>
<polygon className="st16" points="141.1,180 141.1,180.6 141.1,179.9 "/>
<polygon className="st22" points="141.1,180 141.1,180.6 141.1,179.9 "/>
<path className="st16" d="M141.1,181l0.4,3.1v-3L141.1,181z M141.1,184.2V181l0.4,3.1L141.1,184.2z"/>
<path className="st22" d="M141.1,181l0.4,3.1v-3L141.1,181z M141.1,184.2V181l0.4,3.1L141.1,184.2z"/>
<path className="st16" d="M141.1,184.2l0.4,0.2v-0.4L141.1,184.2z M141.1,184.6v-0.4l0.4,0.2L141.1,184.6z"/>
<path className="st22" d="M141.1,184.2l0.4,0.2v-0.4L141.1,184.2z M141.1,184.6v-0.4l0.4,0.2L141.1,184.6z"/>
<path className="st16" d="M141,179.9l0.1,0.7v-0.7H141z M141,180.6v-0.7l0.1,0.7H141z"/>
<path className="st22" d="M141,179.9l0.1,0.7v-0.7H141z M141,180.6v-0.7l0.1,0.7H141z"/>
<path className="st16" d="M141,179.8v0.8v-0.7V179.8z M141,179.8L141,179.8v0.8V179.8z"/>
<path className="st22" d="M141,179.8v0.8v-0.7V179.8z M141,179.8L141,179.8v0.8V179.8z"/>
<path className="st16" d="M141,180.6l0.5,0.5v-0.4L141,180.6z M141,181v-0.4l0.5,0.5L141,181z"/>
<path className="st22" d="M141,180.6l0.5,0.5v-0.4L141,180.6z M141,181v-0.4l0.5,0.5L141,181z"/>
<path className="st16" d="M141,179.8L141,179.8v0.8V179.8z M141,180.6v-0.8V180.6z"/>
<path className="st22" d="M141,179.8L141,179.8v0.8V179.8z M141,180.6v-0.8V180.6z"/>
<path className="st16" d="M141,180.6L141,180.6v0.4V180.6z M141,181v-0.4V181z"/>
<path className="st22" d="M141,180.6L141,180.6v0.4V180.6z M141,181v-0.4V181z"/>
<path className="st16" d="M141,181l0.1,3.2V181H141z M141,184.2V181l0.1,3.2H141z"/>
<path className="st22" d="M141,181l0.1,3.2V181H141z M141,184.2V181l0.1,3.2H141z"/>
<path className="st16" d="M141,179.8L141,179.8v0.8V179.8z M141,180.6v-0.8V180.6z"/>
<path className="st22" d="M141,179.8L141,179.8v0.8V179.8z M141,180.6v-0.8V180.6z"/>
<path className="st16" d="M141,180.6L141,180.6v0.4V180.6z M141,181v-0.4V181z"/>
<path className="st22" d="M141,180.6L141,180.6v0.4V180.6z M141,181v-0.4V181z"/>
<path className="st16" d="M141,181L141,181v3.2V181z M141,184.2V181V184.2z"/>
<path className="st22" d="M141,181L141,181v3.2V181z M141,184.2V181V184.2z"/>
<path className="st16" d="M141,184.2l0.1,0.4v-0.4H141z M141,184.6v-0.4l0.1,0.4H141z"/>
<path className="st22" d="M141,184.2l0.1,0.4v-0.4H141z M141,184.6v-0.4l0.1,0.4H141z"/>
<path className="st16" d="M140.9,179.7l0.1,1v-0.8L140.9,179.7z M140.9,180.6v-1l0.1,1H140.9z"/>
<path className="st22" d="M140.9,179.7l0.1,1v-0.8L140.9,179.7z M140.9,180.6v-1l0.1,1H140.9z"/>
<path className="st16" d="M140.9,180.6l0.1,0.4v-0.4H140.9z M140.9,181v-0.4l0.1,0.4H140.9z"/>
<path className="st22" d="M140.9,180.6l0.1,0.4v-0.4H140.9z M140.9,181v-0.4l0.1,0.4H140.9z"/>
<path className="st16" d="M140.9,181l0.1,3.2V181H140.9z M140.9,184.2V181l0.1,3.2H140.9z"/>
<path className="st22" d="M140.9,181l0.1,3.2V181H140.9z M140.9,184.2V181l0.1,3.2H140.9z"/>
<path className="st16" d="M140.9,184.2l0.1,0.4v-0.4H140.9z M140.9,184.6v-0.4l0.1,0.4H140.9z"/>
<path className="st22" d="M140.9,184.2l0.1,0.4v-0.4H140.9z M140.9,184.6v-0.4l0.1,0.4H140.9z"/>
<path className="st16" d="M140.9,179.6v1.1v-1V179.6z M140.9,179.6L140.9,179.6v1.1V179.6z"/>
<path className="st22" d="M140.9,179.6v1.1v-1V179.6z M140.9,179.6L140.9,179.6v1.1V179.6z"/>
<path className="st16" d="M140.9,180.6L140.9,180.6v0.4V180.6z M140.9,181v-0.4V181z"/>
<path className="st22" d="M140.9,180.6L140.9,180.6v0.4V180.6z M140.9,181v-0.4V181z"/>
<path className="st16" d="M140.9,181L140.9,181v3.2V181z M140.9,184.2V181V184.2z"/>
<path className="st22" d="M140.9,181L140.9,181v3.2V181z M140.9,184.2V181V184.2z"/>
<path className="st16" d="M140.9,184.2L140.9,184.2v0.4V184.2z M140.9,184.6v-0.4V184.6z"/>
<path className="st22" d="M140.9,184.2L140.9,184.2v0.4V184.2z M140.9,184.6v-0.4V184.6z"/>
<path className="st16" d="M140.8,179.6l0.1,1.1v-1.1H140.8z M140.8,180.6v-1.1l0.1,1.1H140.8z"/>
<path className="st22" d="M140.8,179.6l0.1,1.1v-1.1H140.8z M140.8,180.6v-1.1l0.1,1.1H140.8z"/>
<path className="st16" d="M140.8,180.6l0.1,0.4v-0.4H140.8z M140.8,181v-0.4l0.1,0.4H140.8z"/>
<path className="st22" d="M140.8,180.6l0.1,0.4v-0.4H140.8z M140.8,181v-0.4l0.1,0.4H140.8z"/>
<path className="st16" d="M140.8,181l0.1,1.7V181H140.8z M140.8,182.6V181l0.1,1.7L140.8,182.6z"/>
<path className="st22" d="M140.8,181l0.1,1.7V181H140.8z M140.8,182.6V181l0.1,1.7L140.8,182.6z"/>
<path className="st16" d="M140.8,182.8l0.1,1.4v-1.6L140.8,182.8z M140.8,184.2v-1.4l0.1,1.4H140.8z"/>
<path className="st22" d="M140.8,182.8l0.1,1.4v-1.6L140.8,182.8z M140.8,184.2v-1.4l0.1,1.4H140.8z"/>
<path className="st16" d="M140.8,184.2l0.1,0.4v-0.4H140.8z M140.8,184.6v-0.4l0.1,0.4H140.8z"/>
<path className="st22" d="M140.8,184.2l0.1,0.4v-0.4H140.8z M140.8,184.6v-0.4l0.1,0.4H140.8z"/>
<path className="st16" d="M140.8,179.4v1.2v-1.1V179.4z M140.8,179.4L140.8,179.4v1.2V179.4z"/>
<path className="st22" d="M140.8,179.4v1.2v-1.1V179.4z M140.8,179.4L140.8,179.4v1.2V179.4z"/>
<path className="st16" d="M140.8,180.6L140.8,180.6v0.4V180.6z M140.8,181v-0.4V181z"/>
<path className="st22" d="M140.8,180.6L140.8,180.6v0.4V180.6z M140.8,181v-0.4V181z"/>
<path className="st16" d="M140.6,179.4l0.1,1.2v-1.2H140.6z M140.6,180.6v-1.2l0.1,1.2H140.6z"/>
<path className="st22" d="M140.6,179.4l0.1,1.2v-1.2H140.6z M140.6,180.6v-1.2l0.1,1.2H140.6z"/>
<path className="st16" d="M140.6,180.6l0.1,0.4v-0.4H140.6z M140.6,181v-0.4l0.1,0.4H140.6z"/>
<path className="st22" d="M140.6,180.6l0.1,0.4v-0.4H140.6z M140.6,181v-0.4l0.1,0.4H140.6z"/>
<path className="st16" d="M140.6,181l0.1,1.6V181H140.6z M140.6,182.4V181l0.1,1.6L140.6,182.4z"/>
<path className="st22" d="M140.6,181l0.1,1.6V181H140.6z M140.6,182.4V181l0.1,1.6L140.6,182.4z"/>
<path className="st16" d="M140.6,182.8l0.1,1.4v-1.4H140.6z M140.6,184.2v-1.4l0.1,1.4H140.6z"/>
<path className="st22" d="M140.6,182.8l0.1,1.4v-1.4H140.6z M140.6,184.2v-1.4l0.1,1.4H140.6z"/>
<path className="st16" d="M140.5,181l0.1,1.4V181H140.5z M140.5,182.6V181l0.1,1.4L140.5,182.6z"/>
<path className="st22" d="M140.5,181l0.1,1.4V181H140.5z M140.5,182.6V181l0.1,1.4L140.5,182.6z"/>
<path className="st16" d="M140.5,182.8l0.1,1.4v-1.4H140.5z M140.5,184.2v-1.4l0.1,1.4H140.5z"/>
<path className="st22" d="M140.5,182.8l0.1,1.4v-1.4H140.5z M140.5,184.2v-1.4l0.1,1.4H140.5z"/>
<path className="st16" d="M140.5,181v1.6V181z M140.5,182.7V181v1.6V182.7z"/>
<path className="st22" d="M140.5,181v1.6V181z M140.5,182.7V181v1.6V182.7z"/>
<path className="st16" d="M140.5,182.7v1.6v-1.4V182.7z M140.5,182.7L140.5,182.7v1.6V182.7z"/>
<path className="st22" d="M140.5,182.7v1.6v-1.4V182.7z M140.5,182.7L140.5,182.7v1.6V182.7z"/>
<path className="st16" d="M140.3,179.4l0.4,1.2v-1.2H140.3z M140.3,180.6v-1.2l0.4,1.2H140.3z"/>
<path className="st22" d="M140.3,179.4l0.4,1.2v-1.2H140.3z M140.3,180.6v-1.2l0.4,1.2H140.3z"/>
<path className="st16" d="M140.3,180.6l0.4,0.4v-0.4H140.3z M140.3,181v-0.4l0.4,0.4H140.3z"/>
<path className="st22" d="M140.3,180.6l0.4,0.4v-0.4H140.3z M140.3,181v-0.4l0.4,0.4H140.3z"/>
<path className="st16" d="M140.3,181l0.2,1.7V181H140.3z M140.3,182.7V181l0.2,1.7H140.3z"/>
<path className="st22" d="M140.3,181l0.2,1.7V181H140.3z M140.3,182.7V181l0.2,1.7H140.3z"/>
<path className="st16" d="M140.3,182.7l0.2,1.6v-1.6H140.3z M140.3,184.2v-1.6l0.2,1.6H140.3z"/>
<path className="st22" d="M140.3,182.7l0.2,1.6v-1.6H140.3z M140.3,184.2v-1.6l0.2,1.6H140.3z"/>
<path className="st16" d="M140.2,179.3l0.1,1.3v-1.2L140.2,179.3z M140.2,180.6v-1.3l0.1,1.3H140.2z"/>
<path className="st22" d="M140.2,179.3l0.1,1.3v-1.2L140.2,179.3z M140.2,180.6v-1.3l0.1,1.3H140.2z"/>
<path className="st16" d="M140.2,180.6l0.1,0.4v-0.4H140.2z M140.2,181v-0.4l0.1,0.4H140.2z"/>
<path className="st22" d="M140.2,180.6l0.1,0.4v-0.4H140.2z M140.2,181v-0.4l0.1,0.4H140.2z"/>
<path className="st16" d="M140.2,181l0.1,1.4V181H140.2z M140.2,182.4V181l0.1,1.4H140.2z"/>
<path className="st22" d="M140.2,181l0.1,1.4V181H140.2z M140.2,182.4V181l0.1,1.4H140.2z"/>
<path className="st16" d="M140,179.3l0.1,1.8v-1.8H140z M140,181.1v-1.8l0.1,1.8H140z"/>
<path className="st22" d="M140,179.3l0.1,1.8v-1.8H140z M140,181.1v-1.8l0.1,1.8H140z"/>
<path className="st16" d="M140,181.1l0.1,1.3v-1.3H140z M140,182.4v-1.3l0.1,1.3H140z"/>
<path className="st22" d="M140,181.1l0.1,1.3v-1.3H140z M140,182.4v-1.3l0.1,1.3H140z"/>
<path className="st16" d="M140,182.8l0.2,1.4v-1.4H140z M140,184.2v-1.4l0.2,1.4H140z"/>
<path className="st22" d="M140,182.8l0.2,1.4v-1.4H140z M140,184.2v-1.4l0.2,1.4H140z"/>
<path className="st16" d="M140,184.2l0.7,0.4v-0.4H140z M140,184.6v-0.4l0.7,0.4H140z"/>
<path className="st22" d="M140,184.2l0.7,0.4v-0.4H140z M140,184.6v-0.4l0.7,0.4H140z"/>
<path className="st16" d="M139.8,181.1l0.2,1.3v-1.3H139.8z M139.8,182.4v-1.3l0.2,1.3H139.8z"/>
<path className="st22" d="M139.8,181.1l0.2,1.3v-1.3H139.8z M139.8,182.4v-1.3l0.2,1.3H139.8z"/>
<path className="st16" d="M139.7,181.1l0.1,1.3v-1.3H139.7z M139.7,182.4v-1.3l0.1,1.3H139.7z M139.7,181.1v1.3V181.1z M139.7,181.1
	L139.7,181.1v1.3V181.1z"/>
<path className="st22" d="M139.7,181.1l0.1,1.3v-1.3H139.7z M139.7,182.4v-1.3l0.1,1.3H139.7z M139.7,181.1v1.3V181.1z M139.7,181.1
	L139.7,181.1v1.3V181.1z"/>
<path className="st16" d="M139.7,182.8l0.4,0.7v-0.7H139.7z M139.7,183.5v-0.7l0.4,0.7H139.7z"/>
<path className="st22" d="M139.7,182.8l0.4,0.7v-0.7H139.7z M139.7,183.5v-0.7l0.4,0.7H139.7z"/>
<path className="st16" d="M139.6,181.1l0.1,1.3v-1.3H139.6z M139.6,182.6v-1.4l0.1,1.3L139.6,182.6z"/>
<path className="st22" d="M139.6,181.1l0.1,1.3v-1.3H139.6z M139.6,182.6v-1.4l0.1,1.3L139.6,182.6z"/>
<path className="st16" d="M139.6,182.8l0.1,0.7v-0.7H139.6z M139.6,183.5v-0.7l0.1,0.7H139.6z"/>
<path className="st22" d="M139.6,182.8l0.1,0.7v-0.7H139.6z M139.6,183.5v-0.7l0.1,0.7H139.6z"/>
<path className="st16" d="M139.4,179.3l0.6,1.8v-1.8H139.4z M139.4,181.1v-1.8l0.6,1.8H139.4z"/>
<path className="st22" d="M139.4,179.3l0.6,1.8v-1.8H139.4z M139.4,181.1v-1.8l0.6,1.8H139.4z"/>
<path className="st16" d="M139.4,181.1l0.1,1.4v-1.4H139.4z M139.4,182.7v-1.6l0.1,1.4L139.4,182.7z"/>
<path className="st22" d="M139.4,181.1l0.1,1.4v-1.4H139.4z M139.4,182.7v-1.6l0.1,1.4L139.4,182.7z"/>
<path className="st16" d="M139.4,182.7l0.1,0.8v-0.7L139.4,182.7z M139.4,183.5v-0.8l0.1,0.8H139.4z"/>
<path className="st22" d="M139.4,182.7l0.1,0.8v-0.7L139.4,182.7z M139.4,183.5v-0.8l0.1,0.8H139.4z"/>
<path className="st16" d="M139.3,179.3l0.1,1.8v-1.8H139.3z M139.3,181.1v-1.8l0.1,1.8H139.3z"/>
<path className="st22" d="M139.3,179.3l0.1,1.8v-1.8H139.3z M139.3,181.1v-1.8l0.1,1.8H139.3z"/>
<path className="st16" d="M139.3,181.1l0.1,1.6v-1.6H139.3z M139.3,182.7v-1.6l0.1,1.6H139.3z"/>
<path className="st22" d="M139.3,181.1l0.1,1.6v-1.6H139.3z M139.3,182.7v-1.6l0.1,1.6H139.3z"/>
<path className="st16" d="M139.3,182.7l0.1,0.8v-0.8H139.3z M139.3,183.5v-0.8l0.1,0.8H139.3z"/>
<path className="st22" d="M139.3,182.7l0.1,0.8v-0.8H139.3z M139.3,183.5v-0.8l0.1,0.8H139.3z"/>
<path className="st16" d="M139.3,183.5l0.7,0.5v-0.5H139.3z M139.3,184v-0.5l0.7,0.5H139.3z"/>
<path className="st22" d="M139.3,183.5l0.7,0.5v-0.5H139.3z M139.3,184v-0.5l0.7,0.5H139.3z"/>
<path className="st16" d="M139.3,184l0.7,0.1V184H139.3z M139.3,184.1V184l0.7,0.1H139.3z"/>
<path className="st22" d="M139.3,184l0.7,0.1V184H139.3z M139.3,184.1V184l0.7,0.1H139.3z"/>
<path className="st16" d="M139.3,184.1l0.7,0.5v-0.5H139.3z M139.3,184.6v-0.5l0.7,0.5H139.3z"/>
<path className="st22" d="M139.3,184.1l0.7,0.5v-0.5H139.3z M139.3,184.6v-0.5l0.7,0.5H139.3z"/>
<path className="st16" d="M139.2,179l0.1,3v-3H139.2z M139.2,182v-3l0.1,3H139.2z"/>
<path className="st22" d="M139.2,179l0.1,3v-3H139.2z M139.2,182v-3l0.1,3H139.2z"/>
<path className="st16" d="M139.2,183.3l0.1,1.7v-1.7H139.2z M139.2,185v-1.7l0.1,1.7H139.2z"/>
<path className="st22" d="M139.2,183.3l0.1,1.7v-1.7H139.2z M139.2,185v-1.7l0.1,1.7H139.2z"/>
<path className="st16" d="M139.1,179l0.1,3.7V179H139.1z M139.1,182.7V179l0.1,3.7H139.1z"/>
<path className="st22" d="M139.1,179l0.1,3.7V179H139.1z M139.1,182.7V179l0.1,3.7H139.1z"/>
<path className="st16" d="M139.1,182.7l0.1,2.3v-2.3H139.1z M139.1,185v-2.3l0.1,2.3H139.1z"/>
<path className="st22" d="M139.1,182.7l0.1,2.3v-2.3H139.1z M139.1,185v-2.3l0.1,2.3H139.1z"/>
<line className="st19" x1="141.4" y1="182.7" x2="137.9" y2="182.7"/>
<line className="st19" x1="140.2" y1="181.1" x2="140.2" y2="179.3"/>
<line className="st19" x1="140" y1="182.4" x2="140" y2="181.1"/>
<line className="st19" x1="140" y1="184" x2="140" y2="183.5"/>
<polyline className="st19" points="139.3,184.6 140,184.6 140,184.1 139.3,184.1 "/>
<line className="st19" x1="140" y1="184" x2="139.3" y2="184"/>
<line className="st19" x1="140" y1="183.5" x2="139.3" y2="183.5"/>
<line className="st19" x1="140.2" y1="181.1" x2="139.3" y2="181.1"/>
<line className="st19" x1="140.2" y1="179.3" x2="139.3" y2="179.3"/>
<line className="st19" x1="140.3" y1="182.8" x2="140.3" y2="182.4"/>
<path className="st19" d="M139.4,182.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2
	C139.5,182.4,139.4,182.5,139.4,182.6"/>
<line className="st19" x1="140.3" y1="182.8" x2="139.7" y2="182.8"/>
<line className="st19" x1="140.3" y1="182.4" x2="139.6" y2="182.4"/>
<polyline className="st19" points="139.3,183.3 139.2,183.3 139.2,182 139.3,182 "/>
<path className="st19" d="M140.4,182.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2s-0.1-0.2-0.2-0.2
	C140.5,182.4,140.4,182.5,140.4,182.6"/>
<path className="st19" d="M142,181.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3"/>
<path className="st19" d="M140.8,184.6c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8"/>
<path className="st19" d="M140.8,184.2c0.2,0,0.4,0,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5"/>
<path className="st19" d="M141.6,181.5c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2"/>
<line className="st19" x1="141.7" y1="183.6" x2="141.7" y2="181.6"/>
<line className="st19" x1="141" y1="181" x2="140.2" y2="181"/>
<polyline className="st19" points="141,184.2 140,184.2 140,182.8 "/>
<line className="st19" x1="142.1" y1="183.6" x2="142.1" y2="181.6"/>
<path className="st19" d="M141,180.6c0.2-0.3,0.1-0.7-0.1-1c-0.2-0.3-0.6-0.4-0.9-0.4"/>
<line className="st19" x1="139.3" y1="185" x2="139.3" y2="179"/>
<polyline className="st19" points="139.3,185 139.1,185 139.1,179 139.3,179 "/>
<line className="st19" x1="141" y1="180.6" x2="140.2" y2="180.6"/>
<line className="st19" x1="140.9" y1="184.6" x2="139.3" y2="184.6"/>
<path className="st20" d="M139.8,182.4h-0.1H139.8z"/>
<path className="st21" d="M139.8,182.4h-0.1H139.8z"/>
<path className="st20" d="M139.7,182.4L139.7,182.4z"/>
<path className="st21" d="M139.7,182.4L139.7,182.4z"/>
<path className="st20" d="M139.7,182.4L139.7,182.4z"/>
<path className="st21" d="M139.7,182.4L139.7,182.4z"/>
<path className="st20" d="M139.7,182.4h-0.1H139.7z"/>
<path className="st21" d="M139.7,182.4h-0.1H139.7z"/>
<path className="st20" d="M139.7,182.4L139.7,182.4z"/>
<path className="st21" d="M139.7,182.4L139.7,182.4z"/>
<path className="st20" d="M139.7,182.4L139.7,182.4z"/>
<path className="st21" d="M139.7,182.4L139.7,182.4z"/>
<line className="st1" x1="288.4" y1="286.7" x2="295.1" y2="280"/>
<path className="st5" d="M111.2,171.9c2.1-2.3,2.1-6-0.2-8.2"/>
<polyline className="st5" points="111.4,171.9 111.1,172.1 106.9,167.9 107.2,167.7 111.4,171.9 "/>
<path className="st5" d="M140.6,163.7c-2.2,2.2-2.3,5.9-0.2,8.2"/>
<polyline className="st5" points="140.4,171.9 140.6,172.1 144.8,167.9 144.6,167.7 140.4,171.9 "/>
<path className="st16" d="M109.9,183.3l-0.2-2.5v2L109.9,183.3z M109.9,180.3v3l-0.2-2.5L109.9,180.3z M109.9,183.3v-3V183.3z
	 M109.9,183.3L109.9,183.3v-3V183.3z"/>
<path className="st17" d="M109.9,183.3l-0.2-2.5v2L109.9,183.3z M109.9,180.3v3l-0.2-2.5L109.9,180.3z M109.9,183.3v-3V183.3z
	 M109.9,183.3L109.9,183.3v-3V183.3z"/>
<path className="st16" d="M110,183.5l-0.1-3.2v3L110,183.5z M110,180.2v3.4l-0.1-3.2L110,180.2z"/>
<path className="st17" d="M110,183.5l-0.1-3.2v3L110,183.5z M110,180.2v3.4l-0.1-3.2L110,180.2z"/>
<path className="st16" d="M110.2,183.6l-0.1-0.8v0.7L110.2,183.6z M110.2,183v0.6l-0.1-0.8L110.2,183z"/>
<path className="st17" d="M110.2,183.6l-0.1-0.8v0.7L110.2,183.6z M110.2,183v0.6l-0.1-0.8L110.2,183z"/>
<path className="st16" d="M110.2,183l-0.1-2.3v2L110.2,183z M110.2,180.5v2.5l-0.1-2.3L110.2,180.5z"/>
<path className="st17" d="M110.2,183l-0.1-2.3v2L110.2,183z M110.2,180.5v2.5l-0.1-2.3L110.2,180.5z"/>
<path className="st16" d="M110.3,183.6l-0.1-0.6v0.6H110.3z M110.3,183.2v0.5l-0.1-0.6L110.3,183.2z"/>
<path className="st17" d="M110.3,183.6l-0.1-0.6v0.6H110.3z M110.3,183.2v0.5l-0.1-0.6L110.3,183.2z"/>
<path className="st16" d="M110.3,183.2l-0.1-2.6v2.5L110.3,183.2z M110.3,180.4v2.8l-0.1-2.6L110.3,180.4z"/>
<path className="st17" d="M110.3,183.2l-0.1-2.6v2.5L110.3,183.2z M110.3,180.4v2.8l-0.1-2.6L110.3,180.4z"/>
<path className="st16" d="M110.3,183.6v-0.5V183.6z M110.3,183.3v0.4v-0.5V183.3z"/>
<path className="st17" d="M110.3,183.6v-0.5V183.6z M110.3,183.3v0.4v-0.5V183.3z"/>
<path className="st16" d="M110.3,183.3v-2.9v2.8V183.3z M110.3,183.3L110.3,183.3v-2.9V183.3z"/>
<path className="st17" d="M110.3,183.3v-2.9v2.8V183.3z M110.3,183.3L110.3,183.3v-2.9V183.3z"/>
<path className="st16" d="M110.3,183.6L110.3,183.6v-0.4V183.6z M110.3,183.3v0.4V183.3z"/>
<path className="st17" d="M110.3,183.6L110.3,183.6v-0.4V183.6z M110.3,183.3v0.4V183.3z"/>
<path className="st16" d="M110.3,183.3L110.3,183.3v-2.9V183.3z M110.3,180.4v2.9V180.4z"/>
<path className="st17" d="M110.3,183.3L110.3,183.3v-2.9V183.3z M110.3,180.4v2.9V180.4z"/>
<path className="st16" d="M110.3,183.6L110.3,183.6v-0.4V183.6z M110.3,183.3v0.4V183.3z"/>
<path className="st17" d="M110.3,183.6L110.3,183.6v-0.4V183.6z M110.3,183.3v0.4V183.3z"/>
<path className="st16" d="M110.3,183.3v-2.9V183.3z M110.3,180.3v3v-2.9V180.3z"/>
<path className="st17" d="M110.3,183.3v-2.9V183.3z M110.3,180.3v3v-2.9V180.3z"/>
<path className="st16" d="M110.3,180.3l-0.2-0.1v0.6L110.3,180.3z M110.3,179.9v0.4l-0.2-0.1L110.3,179.9z"/>
<path className="st17" d="M110.3,180.3l-0.2-0.1v0.6L110.3,180.3z M110.3,179.9v0.4l-0.2-0.1L110.3,179.9z"/>
<path className="st16" d="M110.3,183.3L110.3,183.3v-3V183.3z M110.3,180.3v3V180.3z"/>
<path className="st17" d="M110.3,183.3L110.3,183.3v-3V183.3z M110.3,180.3v3V180.3z"/>
<path className="st16" d="M110.3,180.3L110.3,180.3v-0.4V180.3z M110.3,179.9v0.4V179.9z"/>
<path className="st17" d="M110.3,180.3L110.3,180.3v-0.4V180.3z M110.3,179.9v0.4V179.9z"/>
<path className="st16" d="M110.6,184.5v-0.7V184.5z"/>
<path className="st17" d="M110.6,184.5v-0.7V184.5z"/>
<path className="st16" d="M110.6,183.4l-0.4-3.1v3L110.6,183.4z M110.6,180.2v3.2l-0.4-3.1L110.6,180.2z"/>
<path className="st17" d="M110.6,183.4l-0.4-3.1v3L110.6,183.4z M110.6,180.2v3.2l-0.4-3.1L110.6,180.2z"/>
<path className="st16" d="M110.6,180.2l-0.4-0.2v0.4L110.6,180.2z M110.6,179.8v0.4l-0.4-0.2L110.6,179.8z"/>
<path className="st17" d="M110.6,180.2l-0.4-0.2v0.4L110.6,180.2z M110.6,179.8v0.4l-0.4-0.2L110.6,179.8z"/>
<path className="st16" d="M110.8,184.6l-0.1-0.8v0.7L110.8,184.6z M110.8,183.8v0.8l-0.1-0.8H110.8z M110.8,184.6v-0.8V184.6z
	 M110.8,184.6L110.8,184.6v-0.8V184.6z"/>
<path className="st17" d="M110.8,184.6l-0.1-0.8v0.7L110.8,184.6z M110.8,183.8v0.8l-0.1-0.8H110.8z M110.8,184.6v-0.8V184.6z
	 M110.8,184.6L110.8,184.6v-0.8V184.6z"/>
<path className="st16" d="M110.8,183.8l-0.5-0.5v0.4L110.8,183.8z M110.8,183.4v0.4l-0.5-0.5L110.8,183.4z"/>
<path className="st17" d="M110.8,183.8l-0.5-0.5v0.4L110.8,183.8z M110.8,183.4v0.4l-0.5-0.5L110.8,183.4z"/>
<path className="st16" d="M110.8,184.6L110.8,184.6v-0.8V184.6z M110.8,183.8v0.8V183.8z"/>
<path className="st17" d="M110.8,184.6L110.8,184.6v-0.8V184.6z M110.8,183.8v0.8V183.8z"/>
<path className="st16" d="M110.8,183.8L110.8,183.8v-0.4V183.8z M110.8,183.4v0.4V183.4z"/>
<path className="st17" d="M110.8,183.8L110.8,183.8v-0.4V183.8z M110.8,183.4v0.4V183.4z"/>
<path className="st16" d="M110.8,183.4l-0.1-3.2v3.2H110.8z M110.8,180.2v3.2l-0.1-3.2H110.8z"/>
<path className="st17" d="M110.8,183.4l-0.1-3.2v3.2H110.8z M110.8,180.2v3.2l-0.1-3.2H110.8z"/>
<path className="st16" d="M110.8,184.7v-1v0.8V184.7z M110.8,184.7L110.8,184.7v-1V184.7z"/>
<path className="st17" d="M110.8,184.7v-1v0.8V184.7z M110.8,184.7L110.8,184.7v-1V184.7z"/>
<path className="st16" d="M110.8,183.8L110.8,183.8v-0.4V183.8z M110.8,183.4v0.4V183.4z"/>
<path className="st17" d="M110.8,183.8L110.8,183.8v-0.4V183.8z M110.8,183.4v0.4V183.4z"/>
<path className="st16" d="M110.8,183.4L110.8,183.4v-3.2V183.4z M110.8,180.2v3.2V180.2z"/>
<path className="st17" d="M110.8,183.4L110.8,183.4v-3.2V183.4z M110.8,180.2v3.2V180.2z"/>
<path className="st16" d="M110.8,180.2l-0.1-0.4v0.4H110.8z M110.8,179.8v0.4l-0.1-0.4H110.8z"/>
<path className="st17" d="M110.8,180.2l-0.1-0.4v0.4H110.8z M110.8,179.8v0.4l-0.1-0.4H110.8z"/>
<path className="st16" d="M110.9,184.7l-0.1-1v1H110.9z M110.9,183.8v1l-0.1-1H110.9z"/>
<path className="st17" d="M110.9,184.7l-0.1-1v1H110.9z M110.9,183.8v1l-0.1-1H110.9z"/>
<path className="st16" d="M110.9,183.8l-0.1-0.4v0.4H110.9z M110.9,183.4v0.4l-0.1-0.4H110.9z"/>
<path className="st17" d="M110.9,183.8l-0.1-0.4v0.4H110.9z M110.9,183.4v0.4l-0.1-0.4H110.9z"/>
<path className="st16" d="M110.9,183.4l-0.1-3.2v3.2H110.9z M110.9,180.2v3.2l-0.1-3.2H110.9z"/>
<path className="st17" d="M110.9,183.4l-0.1-3.2v3.2H110.9z M110.9,180.2v3.2l-0.1-3.2H110.9z"/>
<path className="st16" d="M110.9,180.2l-0.1-0.4v0.4H110.9z M110.9,179.8v0.4l-0.1-0.4H110.9z"/>
<path className="st17" d="M110.9,180.2l-0.1-0.4v0.4H110.9z M110.9,179.8v0.4l-0.1-0.4H110.9z"/>
<path className="st16" d="M110.9,184.8v-1.1v1V184.8z M110.9,184.8L110.9,184.8v-1.1V184.8z"/>
<path className="st17" d="M110.9,184.8v-1.1v1V184.8z M110.9,184.8L110.9,184.8v-1.1V184.8z"/>
<path className="st16" d="M110.9,183.8L110.9,183.8v-0.4V183.8z M110.9,183.4v0.4V183.4z"/>
<path className="st17" d="M110.9,183.8L110.9,183.8v-0.4V183.8z M110.9,183.4v0.4V183.4z"/>
<path className="st16" d="M110.9,183.4L110.9,183.4v-3.2V183.4z M110.9,180.2v3.2V180.2z"/>
<path className="st17" d="M110.9,183.4L110.9,183.4v-3.2V183.4z M110.9,180.2v3.2V180.2z"/>
<path className="st16" d="M110.9,180.2L110.9,180.2v-0.4V180.2z M110.9,179.8v0.4V179.8z"/>
<path className="st17" d="M110.9,180.2L110.9,180.2v-0.4V180.2z M110.9,179.8v0.4V179.8z"/>
<path className="st16" d="M111,184.8l-0.1-1.1v1.1H111z M111,183.8v1.1l-0.1-1.1H111z"/>
<path className="st17" d="M111,184.8l-0.1-1.1v1.1H111z M111,183.8v1.1l-0.1-1.1H111z"/>
<path className="st16" d="M111,183.8l-0.1-0.4v0.4H111z M111,183.4v0.4l-0.1-0.4H111z"/>
<path className="st17" d="M111,183.8l-0.1-0.4v0.4H111z M111,183.4v0.4l-0.1-0.4H111z"/>
<path className="st16" d="M111,183.4l-0.1-1.6v1.6H111z M111,182v1.4l-0.1-1.6L111,182z"/>
<path className="st17" d="M111,183.4l-0.1-1.6v1.6H111z M111,182v1.4l-0.1-1.6L111,182z"/>
<path className="st16" d="M111,181.6l-0.1-1.4v1.7L111,181.6z M111,180.2v1.4l-0.1-1.4H111z"/>
<path className="st17" d="M111,181.6l-0.1-1.4v1.7L111,181.6z M111,180.2v1.4l-0.1-1.4H111z"/>
<path className="st16" d="M111,180.2l-0.1-0.4v0.4H111z M111,179.8v0.4l-0.1-0.4H111z"/>
<path className="st17" d="M111,180.2l-0.1-0.4v0.4H111z M111,179.8v0.4l-0.1-0.4H111z"/>
<path className="st16" d="M111,185v-1.2v1.1V185z M111,185L111,185v-1.2V185z"/>
<path className="st17" d="M111,185v-1.2v1.1V185z M111,185L111,185v-1.2V185z"/>
<path className="st16" d="M111,183.8L111,183.8v-0.4V183.8z M111,183.4v0.4V183.4z"/>
<path className="st17" d="M111,183.8L111,183.8v-0.4V183.8z M111,183.4v0.4V183.4z"/>
<path className="st16" d="M111.1,185l-0.1-1.2v1.2H111.1z M111.1,183.8v1.2l-0.1-1.2H111.1z"/>
<path className="st17" d="M111.1,185l-0.1-1.2v1.2H111.1z M111.1,183.8v1.2l-0.1-1.2H111.1z"/>
<path className="st16" d="M111.1,183.8l-0.1-0.4v0.4H111.1z M111.1,183.4v0.4l-0.1-0.4H111.1z"/>
<path className="st17" d="M111.1,183.8l-0.1-0.4v0.4H111.1z M111.1,183.4v0.4l-0.1-0.4H111.1z"/>
<path className="st16" d="M111.1,183.4L111,182v1.4H111.1z M111.1,182v1.4L111,182H111.1z"/>
<path className="st17" d="M111.1,183.4L111,182v1.4H111.1z M111.1,182v1.4L111,182H111.1z"/>
<path className="st16" d="M111.1,181.6l-0.1-1.4v1.4H111.1z M111.1,180.2v1.4l-0.1-1.4H111.1z"/>
<path className="st17" d="M111.1,181.6l-0.1-1.4v1.4H111.1z M111.1,180.2v1.4l-0.1-1.4H111.1z"/>
<path className="st16" d="M111.2,183.4l-0.1-1.4v1.4H111.2z M111.2,182v1.4l-0.1-1.4H111.2z"/>
<path className="st17" d="M111.2,183.4l-0.1-1.4v1.4H111.2z M111.2,182v1.4l-0.1-1.4H111.2z"/>
<path className="st16" d="M111.2,181.6l-0.1-1.4v1.4H111.2z M111.2,180.2v1.4l-0.1-1.4H111.2z"/>
<path className="st17" d="M111.2,181.6l-0.1-1.4v1.4H111.2z M111.2,180.2v1.4l-0.1-1.4H111.2z"/>
<path className="st16" d="M111.4,183.4l-0.1-1.4v1.4H111.4z M111.4,181.8v1.6l-0.1-1.4L111.4,181.8z"/>
<path className="st17" d="M111.4,183.4l-0.1-1.4v1.4H111.4z M111.4,181.8v1.6l-0.1-1.4L111.4,181.8z"/>
<path className="st16" d="M111.4,181.8l-0.1-1.7v1.4L111.4,181.8z M111.4,180.2v1.7l-0.1-1.7H111.4z"/>
<path className="st17" d="M111.4,181.8l-0.1-1.7v1.4L111.4,181.8z M111.4,180.2v1.7l-0.1-1.7H111.4z"/>
<path className="st16" d="M111.5,185.1l-0.4-1.3v1.2L111.5,185.1z M111.5,183.8v1.3l-0.4-1.3H111.5z"/>
<path className="st17" d="M111.5,185.1l-0.4-1.3v1.2L111.5,185.1z M111.5,183.8v1.3l-0.4-1.3H111.5z"/>
<path className="st16" d="M111.5,183.8l-0.4-0.4v0.4H111.5z M111.5,183.4v0.4l-0.4-0.4H111.5z"/>
<path className="st17" d="M111.5,183.8l-0.4-0.4v0.4H111.5z M111.5,183.4v0.4l-0.4-0.4H111.5z"/>
<path className="st16" d="M111.5,183.4l-0.1-1.6v1.6H111.5z M111.5,181.8v1.6l-0.1-1.6H111.5z"/>
<path className="st17" d="M111.5,183.4l-0.1-1.6v1.6H111.5z M111.5,181.8v1.6l-0.1-1.6H111.5z"/>
<path className="st16" d="M111.5,181.8l-0.1-1.7v1.7H111.5z M111.5,180.2v1.7l-0.1-1.7H111.5z"/>
<path className="st17" d="M111.5,181.8l-0.1-1.7v1.7H111.5z M111.5,180.2v1.7l-0.1-1.7H111.5z"/>
<path className="st16" d="M111.6,185.1l-0.1-1.3v1.3H111.6z M111.6,183.8v1.3l-0.1-1.3H111.6z"/>
<path className="st17" d="M111.6,185.1l-0.1-1.3v1.3H111.6z M111.6,183.8v1.3l-0.1-1.3H111.6z"/>
<path className="st16" d="M111.6,183.8l-0.1-0.4v0.4H111.6z M111.6,183.4v0.4l-0.1-0.4H111.6z"/>
<path className="st17" d="M111.6,183.8l-0.1-0.4v0.4H111.6z M111.6,183.4v0.4l-0.1-0.4H111.6z"/>
<path className="st16" d="M111.6,183.4l-0.1-1.4v1.4H111.6z M111.6,182v1.4l-0.1-1.4H111.6z"/>
<path className="st17" d="M111.6,183.4l-0.1-1.4v1.4H111.6z M111.6,182v1.4l-0.1-1.4H111.6z"/>
<path className="st16" d="M111.7,185.1l-0.1-1.8v1.8H111.7z M111.7,183.3v1.8l-0.1-1.8H111.7z"/>
<path className="st17" d="M111.7,185.1l-0.1-1.8v1.8H111.7z M111.7,183.3v1.8l-0.1-1.8H111.7z"/>
<path className="st16" d="M111.7,183.3l-0.1-1.3v1.3H111.7z M111.7,182v1.3l-0.1-1.3H111.7z"/>
<path className="st17" d="M111.7,183.3l-0.1-1.3v1.3H111.7z M111.7,182v1.3l-0.1-1.3H111.7z"/>
<path className="st16" d="M111.7,181.6l-0.2-1.4v1.4H111.7z M111.7,180.2v1.4l-0.2-1.4H111.7z"/>
<path className="st17" d="M111.7,181.6l-0.2-1.4v1.4H111.7z M111.7,180.2v1.4l-0.2-1.4H111.7z"/>
<path className="st16" d="M111.7,180.2l-0.7-0.4v0.4H111.7z M111.7,179.8v0.4l-0.7-0.4H111.7z"/>
<path className="st17" d="M111.7,180.2l-0.7-0.4v0.4H111.7z M111.7,179.8v0.4l-0.7-0.4H111.7z"/>
<path className="st16" d="M112,183.3l-0.2-1.3v1.3H112z M112,182v1.3l-0.2-1.3H112z"/>
<path className="st17" d="M112,183.3l-0.2-1.3v1.3H112z M112,182v1.3l-0.2-1.3H112z"/>
<path className="st16" d="M112.1,183.3L112,182v1.3H112.1z M112.1,182v1.3L112,182H112.1z M112.1,183.3V182V183.3z M112.1,183.3
	L112.1,183.3V182V183.3z"/>
<path className="st17" d="M112.1,183.3L112,182v1.3H112.1z M112.1,182v1.3L112,182H112.1z M112.1,183.3V182V183.3z M112.1,183.3
	L112.1,183.3V182V183.3z"/>
<path className="st16" d="M112.1,181.6l-0.4-0.7v0.7H112.1z M112.1,180.9v0.7l-0.4-0.7H112.1z"/>
<path className="st17" d="M112.1,181.6l-0.4-0.7v0.7H112.1z M112.1,180.9v0.7l-0.4-0.7H112.1z"/>
<path className="st16" d="M112.2,183.3l-0.1-1.3v1.3H112.2z M112.2,182v1.3l-0.1-1.3H112.2z"/>
<path className="st17" d="M112.2,183.3l-0.1-1.3v1.3H112.2z M112.2,182v1.3l-0.1-1.3H112.2z"/>
<path className="st16" d="M112.2,181.6l-0.1-0.7v0.7H112.2z M112.2,180.9v0.7l-0.1-0.7H112.2z"/>
<path className="st17" d="M112.2,181.6l-0.1-0.7v0.7H112.2z M112.2,180.9v0.7l-0.1-0.7H112.2z"/>
<path className="st16" d="M112.3,185.1l-0.6-1.8v1.8H112.3z M112.3,183.3v1.8l-0.6-1.8H112.3z"/>
<path className="st17" d="M112.3,185.1l-0.6-1.8v1.8H112.3z M112.3,183.3v1.8l-0.6-1.8H112.3z"/>
<path className="st16" d="M112.3,183.3l-0.1-1.3v1.3H112.3z M112.3,181.8v1.4l-0.1-1.3L112.3,181.8z"/>
<path className="st17" d="M112.3,183.3l-0.1-1.3v1.3H112.3z M112.3,181.8v1.4l-0.1-1.3L112.3,181.8z"/>
<path className="st16" d="M112.3,181.8l-0.1-1v0.7L112.3,181.8z M112.3,180.9v1l-0.1-1H112.3z"/>
<path className="st17" d="M112.3,181.8l-0.1-1v0.7L112.3,181.8z M112.3,180.9v1l-0.1-1H112.3z"/>
<path className="st16" d="M112.4,185.1l-0.1-1.8v1.8H112.4z M112.4,183.3v1.8l-0.1-1.8H112.4z"/>
<path className="st17" d="M112.4,185.1l-0.1-1.8v1.8H112.4z M112.4,183.3v1.8l-0.1-1.8H112.4z"/>
<path className="st16" d="M112.4,183.3l-0.1-1.4v1.4H112.4z M112.4,181.8v1.4l-0.1-1.4H112.4z"/>
<path className="st17" d="M112.4,183.3l-0.1-1.4v1.4H112.4z M112.4,181.8v1.4l-0.1-1.4H112.4z"/>
<path className="st16" d="M112.4,181.8l-0.1-1v1H112.4z M112.4,180.9v1l-0.1-1H112.4z"/>
<path className="st17" d="M112.4,181.8l-0.1-1v1H112.4z M112.4,180.9v1l-0.1-1H112.4z"/>
<path className="st16" d="M112.4,180.9l-0.7-0.4v0.4H112.4z M112.4,180.5v0.4l-0.7-0.4H112.4z"/>
<path className="st17" d="M112.4,180.9l-0.7-0.4v0.4H112.4z M112.4,180.5v0.4l-0.7-0.4H112.4z"/>
<path className="st16" d="M112.4,180.5l-0.7-0.2v0.2H112.4z M112.4,180.3v0.2l-0.7-0.2H112.4z"/>
<path className="st17" d="M112.4,180.5l-0.7-0.2v0.2H112.4z M112.4,180.3v0.2l-0.7-0.2H112.4z"/>
<path className="st16" d="M112.4,180.3l-0.7-0.5v0.5H112.4z M112.4,179.8v0.5l-0.7-0.5H112.4z"/>
<path className="st17" d="M112.4,180.3l-0.7-0.5v0.5H112.4z M112.4,179.8v0.5l-0.7-0.5H112.4z"/>
<path className="st16" d="M112.6,185.4l-0.1-3v3H112.6z M112.6,182.4v3l-0.1-3H112.6z"/>
<path className="st17" d="M112.6,185.4l-0.1-3v3H112.6z M112.6,182.4v3l-0.1-3H112.6z"/>
<path className="st16" d="M112.6,181.1l-0.1-1.7v1.7H112.6z M112.6,179.4v1.7l-0.1-1.7H112.6z"/>
<path className="st17" d="M112.6,181.1l-0.1-1.7v1.7H112.6z M112.6,179.4v1.7l-0.1-1.7H112.6z"/>
<path className="st16" d="M112.7,185.4l-0.1-3.6v3.6H112.7z M112.7,181.8v3.6l-0.1-3.6H112.7z"/>
<path className="st17" d="M112.7,185.4l-0.1-3.6v3.6H112.7z M112.7,181.8v3.6l-0.1-3.6H112.7z"/>
<path className="st16" d="M112.7,181.8l-0.1-2.4v2.4H112.7z M112.7,179.4v2.4l-0.1-2.4H112.7z"/>
<path className="st17" d="M112.7,181.8l-0.1-2.4v2.4H112.7z M112.7,179.4v2.4l-0.1-2.4H112.7z"/>
<line className="st19" x1="110.4" y1="181.8" x2="113.9" y2="181.8"/>
<line className="st19" x1="111.6" y1="183.3" x2="111.6" y2="185.1"/>
<line className="st19" x1="111.7" y1="182" x2="111.7" y2="183.3"/>
<line className="st19" x1="111.7" y1="180.5" x2="111.7" y2="180.9"/>
<polyline className="st19" points="112.4,179.8 111.7,179.8 111.7,180.3 112.4,180.3 "/>
<line className="st19" x1="111.7" y1="180.5" x2="112.4" y2="180.5"/>
<line className="st19" x1="111.7" y1="180.9" x2="112.4" y2="180.9"/>
<line className="st19" x1="111.6" y1="183.3" x2="112.4" y2="183.3"/>
<line className="st19" x1="111.6" y1="185.1" x2="112.4" y2="185.1"/>
<line className="st19" x1="111.5" y1="181.6" x2="111.5" y2="182"/>
<path className="st19" d="M112.2,181.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2
	C112.1,182,112.2,181.9,112.2,181.8"/>
<line className="st19" x1="111.5" y1="181.6" x2="112.1" y2="181.6"/>
<line className="st19" x1="111.5" y1="182" x2="112.2" y2="182"/>
<polyline className="st19" points="112.4,181.1 112.6,181.1 112.6,182.4 112.4,182.4 "/>
<path className="st19" d="M111.2,181.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2
	C111.2,182,111.2,181.9,111.2,181.8"/>
<path className="st19" d="M109.7,182.7c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3"/>
<path className="st19" d="M110.8,179.7c-0.3,0-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.8"/>
<path className="st19" d="M110.8,180c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5"/>
<path className="st19" d="M110,182.7c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2"/>
<line className="st19" x1="110" y1="180.8" x2="110" y2="182.8"/>
<line className="st19" x1="110.8" y1="183.4" x2="111.6" y2="183.4"/>
<polyline className="st19" points="110.8,180.2 111.7,180.2 111.7,181.6 "/>
<line className="st19" x1="109.7" y1="180.8" x2="109.7" y2="182.8"/>
<path className="st19" d="M110.6,183.8c-0.2,0.3-0.1,0.7,0.1,1c0.2,0.3,0.6,0.4,0.9,0.4"/>
<line className="st19" x1="112.4" y1="179.4" x2="112.4" y2="185.4"/>
<polyline className="st19" points="112.4,179.4 112.7,179.4 112.7,185.4 112.4,185.4 "/>
<line className="st19" x1="110.8" y1="183.8" x2="111.6" y2="183.8"/>
<line className="st19" x1="110.9" y1="179.8" x2="112.4" y2="179.8"/>
<path className="st20" d="M112,182h0.1H112z"/>
<path className="st21" d="M112,182h0.1H112z"/>
<path className="st20" d="M112.1,182L112.1,182z"/>
<path className="st21" d="M112.1,182L112.1,182z"/>
<path className="st20" d="M112.1,182L112.1,182z"/>
<path className="st21" d="M112.1,182L112.1,182z"/>
<path className="st20" d="M112.1,182h0.1H112.1z"/>
<path className="st21" d="M112.1,182h0.1H112.1z"/>
<path className="st20" d="M112.1,182L112.1,182z"/>
<path className="st21" d="M112.1,182L112.1,182z"/>
<path className="st20" d="M112.1,182L112.1,182z"/>
<path className="st21" d="M112.1,182L112.1,182z"/>
<path className="st16" d="M111.7,197v-2.3V197z M111.7,194.6v2.4v-2.3V194.6z M111.7,197v-2.4V197z M111.7,197L111.7,197v-2.4V197z"/>
<path className="st22" d="M111.7,197v-2.3V197z M111.7,194.6v2.4v-2.3V194.6z M111.7,197v-2.4V197z M111.7,197L111.7,197v-2.4V197z"/>
<path className="st16" d="M111.6,197l0.1-2.4v2.4H111.6z M111.6,194.6v2.4l0.1-2.4H111.6z M111.6,197v-2.4V197z M111.6,197L111.6,197
	v-2.4V197z"/>
<path className="st22" d="M111.6,197l0.1-2.4v2.4H111.6z M111.6,194.6v2.4l0.1-2.4H111.6z M111.6,197v-2.4V197z M111.6,197L111.6,197
	v-2.4V197z"/>
<path className="st16" d="M110.2,197l1.4-2.4v2.4H110.2z M110.2,194.6v2.4l1.4-2.4H110.2z"/>
<path className="st22" d="M110.2,197l1.4-2.4v2.4H110.2z M110.2,194.6v2.4l1.4-2.4H110.2z"/>
<path className="st16" d="M109.9,197l0.2-1.2v1.2H109.9z M109.9,196.4v0.6l0.2-1.2L109.9,196.4z"/>
<path className="st22" d="M109.9,197l0.2-1.2v1.2H109.9z M109.9,196.4v0.6l0.2-1.2L109.9,196.4z"/>
<path className="st16" d="M109.9,195.2l0.2-0.6v1.2L109.9,195.2z M109.9,194.6v0.6l0.2-0.6H109.9z"/>
<path className="st22" d="M109.9,195.2l0.2-0.6v1.2L109.9,195.2z M109.9,194.6v0.6l0.2-0.6H109.9z"/>
<path className="st16" d="M109.9,197L109.9,197v-0.6V197z M109.9,196.4v0.6V196.4z"/>
<path className="st22" d="M109.9,197L109.9,197v-0.6V197z M109.9,196.4v0.6V196.4z"/>
<path className="st16" d="M109.7,196v-0.4V196z M109.7,195.5v0.5v-0.4V195.5z M109.7,196v-0.5V196z M109.7,196L109.7,196v-0.5V196z
	 M109.7,195.5v0.5V195.5z M109.7,196v-0.5V196z M109.7,196L109.7,196v-0.5V196z M109.7,195.5v0.5V195.5z"/>
<path className="st22" d="M109.7,196v-0.4V196z M109.7,195.5v0.5v-0.4V195.5z M109.7,196v-0.5V196z M109.7,196L109.7,196v-0.5V196z
	 M109.7,195.5v0.5V195.5z M109.7,196v-0.5V196z M109.7,196L109.7,196v-0.5V196z M109.7,195.5v0.5V195.5z"/>
<path className="st16" d="M109.3,195.5v0.5v-0.2V195.5z M109.7,195.5h-0.4v0.5L109.7,195.5z"/>
<path className="st22" d="M109.3,195.5v0.5v-0.2V195.5z M109.7,195.5h-0.4v0.5L109.7,195.5z"/>
<polygon className="st16" points="109.7,195.5 109.7,196 109.3,196 "/>
<polygon className="st22" points="109.7,195.5 109.7,196 109.3,196 "/>
<path className="st16" d="M109.3,194.9l0.6-0.4v0.6L109.3,194.9z M109.3,194.6v0.4l0.6-0.4H109.3z"/>
<path className="st22" d="M109.3,194.9l0.6-0.4v0.6L109.3,194.9z M109.3,194.6v0.4l0.6-0.4H109.3z"/>
<path className="st16" d="M109.3,197l0.6-0.6v0.6H109.3z M109.3,196.7v0.2l0.6-0.6L109.3,196.7z"/>
<path className="st22" d="M109.3,197l0.6-0.6v0.6H109.3z M109.3,196.7v0.2l0.6-0.6L109.3,196.7z"/>
<path className="st16" d="M108.8,197l0.5-0.2v0.2H108.8z M108.8,196.7v0.2l0.5-0.2H108.8z"/>
<path className="st22" d="M108.8,197l0.5-0.2v0.2H108.8z M108.8,196.7v0.2l0.5-0.2H108.8z"/>
<path className="st16" d="M108.8,194.9l0.5-0.4v0.4H108.8z M108.8,194.6v0.4l0.5-0.4H108.8z"/>
<path className="st22" d="M108.8,194.9l0.5-0.4v0.4H108.8z M108.8,194.6v0.4l0.5-0.4H108.8z"/>
<path className="st16" d="M108.2,195.2l0.6-0.6v0.4L108.2,195.2z M108.2,194.7v0.5l0.6-0.6L108.2,194.7z"/>
<path className="st22" d="M108.2,195.2l0.6-0.6v0.4L108.2,195.2z M108.2,194.7v0.5l0.6-0.6L108.2,194.7z"/>
<path className="st16" d="M108.1,196.8l0.7-0.1v0.2L108.1,196.8z M108.1,196.5v0.4l0.7-0.1L108.1,196.5z M108.1,196.8v-0.4V196.8z
	 M108.1,196.8L108.1,196.8v-0.4V196.8z"/>
<path className="st22" d="M108.1,196.8l0.7-0.1v0.2L108.1,196.8z M108.1,196.5v0.4l0.7-0.1L108.1,196.5z M108.1,196.8v-0.4V196.8z
	 M108.1,196.8L108.1,196.8v-0.4V196.8z"/>
<path className="st16" d="M108.1,195.2l0.1-0.5v0.5H108.1z M108.1,194.8v0.4l0.1-0.5L108.1,194.8z"/>
<path className="st22" d="M108.1,195.2l0.1-0.5v0.5H108.1z M108.1,194.8v0.4l0.1-0.5L108.1,194.8z"/>
<path className="st16" d="M107.9,196.5h0.2v0.4L107.9,196.5z M107.9,195.8v0.7h0.2L107.9,195.8z"/>
<path className="st22" d="M107.9,196.5h0.2v0.4L107.9,196.5z M107.9,195.8v0.7h0.2L107.9,195.8z"/>
<path className="st16" d="M107.9,195.8l0.2-1v0.4L107.9,195.8z M107.9,195v0.7l0.2-1L107.9,195z"/>
<path className="st22" d="M107.9,195.8l0.2-1v0.4L107.9,195.8z M107.9,195v0.7l0.2-1L107.9,195z"/>
<path className="st16" d="M107.8,196.4l0.1-1.3v1.4L107.8,196.4z M107.8,195.2v1.2l0.1-1.3L107.8,195.2z M107.8,195.3v-0.1v1.2V195.3z"
	/>
<path className="st22" d="M107.8,196.4l0.1-1.3v1.4L107.8,196.4z M107.8,195.2v1.2l0.1-1.3L107.8,195.2z M107.8,195.3v-0.1v1.2V195.3z"
	/>
<path className="st16" d="M107.8,195.2v1.2V195.2z"/>
<path className="st22" d="M107.8,195.2v1.2V195.2z"/>
<path className="st16" d="M107.6,195.4v1l-0.1-0.6L107.6,195.4z M107.8,195.3l-0.1,0.1v1L107.8,195.3z"/>
<path className="st22" d="M107.6,195.4v1l-0.1-0.6L107.6,195.4z M107.8,195.3l-0.1,0.1v1L107.8,195.3z"/>
<polygon className="st16" points="107.8,195.3 107.8,196.4 107.6,196.4 "/>
<polygon className="st22" points="107.8,195.3 107.8,196.4 107.6,196.4 "/>
<path className="st19" d="M111.5,197C111.6,197,111.6,196.9,111.5,197"/>
<line className="st19" x1="111.7" y1="197" x2="111.7" y2="194.7"/>
<path className="st19" d="M111.6,194.6C111.6,194.6,111.6,194.6,111.6,194.6"/>
<line className="st19" x1="111.6" y1="194.6" x2="108.8" y2="194.6"/>
<path className="st19" d="M108.8,194.4c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3"/>
<line className="st19" x1="108.8" y1="197" x2="111.6" y2="197"/>
<path className="st19" d="M109.6,196C109.7,196,109.7,196,109.6,196"/>
<line className="st19" x1="109.7" y1="196" x2="109.3" y2="196"/>
<path className="st19" d="M109.3,195.4c-0.1,0.2-0.1,0.4,0,0.6"/>
<line className="st19" x1="109.3" y1="195.5" x2="109.7" y2="195.5"/>
<path className="st19" d="M109.7,195.6C109.7,195.5,109.7,195.5,109.7,195.6"/>
<line className="st19" x1="109.7" y1="195.6" x2="109.7" y2="196"/>
<line className="st19" x1="109.3" y1="194.9" x2="108.8" y2="194.9"/>
<path className="st19" d="M108.8,194.8c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9"/>
<line className="st19" x1="108.8" y1="196.7" x2="109.3" y2="196.7"/>
<path className="st19" d="M109.2,196.6c0.5,0,0.8-0.4,0.8-0.9c0-0.5-0.4-0.9-0.8-0.9"/>
<path className="st5" d="M292.6,303.3c1.9,1.9,4.9,2,6.9,0.2"/>
<line className="st5" x1="296.2" y1="299.8" x2="299.6" y2="303.3"/>
<polyline className="st5" points="296.2,299.8 295.9,299.9 299.5,303.5 299.6,303.3 "/>
<path className="st5" d="M292.6,310.1c1.8-2,1.7-5-0.2-6.9"/>
<line className="st5" x1="289" y1="306.9" x2="292.6" y2="310.5"/>
<polyline className="st5" points="289,306.9 289.2,306.6 292.8,310.2 292.6,310.5 "/>
<line className="st6" x1="289.1" y1="306.8" x2="292.7" y2="310.4"/>
<line className="st6" x1="296" y1="299.9" x2="299.5" y2="303.4"/>
<path className="st5" d="M302.8,258c1.9,1.9,4.9,2,6.9,0.2"/>
<line className="st5" x1="306.4" y1="254.6" x2="310" y2="258"/>
<polyline className="st5" points="306.4,254.6 306.2,254.7 309.7,258.3 310,258 "/>
<path className="st5" d="M302.9,264.9c1.8-2,1.7-5-0.2-6.9"/>
<line className="st5" x1="299.3" y1="261.6" x2="302.9" y2="265.1"/>
<polyline className="st5" points="299.3,261.6 299.5,261.4 303,265 302.9,265.1 "/>
<line className="st6" x1="299.4" y1="261.5" x2="302.9" y2="265.1"/>
<line className="st6" x1="306.2" y1="254.7" x2="309.8" y2="258.2"/>
<path className="st5" d="M358.3,395.1c2.1-2.3,2.1-6-0.2-8.2"/>
<polyline className="st5" points="358.4,395.1 358.2,395.3 354,391.1 354.2,390.9 358.4,395.1 "/>
<path className="st5" d="M178.6,182.8c-1.8,2-1.7,5,0.2,6.9"/>
<line className="st5" x1="182.4" y1="186.2" x2="178.9" y2="182.6"/>
<polyline className="st5" points="182.4,186.2 182.3,186.3 178.7,182.8 178.9,182.6 "/>
<path className="st5" d="M178.8,189.7c-1.9-1.9-4.9-2-6.9-0.2"/>
<line className="st5" x1="175.3" y1="193.2" x2="171.8" y2="189.6"/>
<polyline className="st5" points="175.3,193.2 175.6,193 172,189.5 171.8,189.6 "/>
<line className="st6" x1="175.4" y1="193.1" x2="172" y2="189.5"/>
<line className="st6" x1="182.4" y1="186.2" x2="178.8" y2="182.7"/>
<path className="st5" d="M168.5,201.1c2.2,2.2,5.9,2.3,8.2,0.2"/>
<polyline className="st5" points="176.9,201.3 177,201 172.8,197 172.7,197.1 176.9,201.3 "/>
<line className="st2" x1="385.6" y1="385.1" x2="390" y2="380.7"/>
<path className="st5" d="M412.2,384.3c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="412.2,384.3 412.4,384.5 408.2,388.7 408,388.5 412.2,384.3 "/>
<path className="st16" d="M400.1,376.6l1.9,1.7l-1.4-1.4L400.1,376.6z M402.2,378.6l-2.2-2l1.9,1.7L402.2,378.6z M402.2,378.8v-0.1
	l-2.2-2L402.2,378.8z"/>
<path className="st17" d="M400.1,376.6l1.9,1.7l-1.4-1.4L400.1,376.6z M402.2,378.6l-2.2-2l1.9,1.7L402.2,378.6z M402.2,378.8v-0.1
	l-2.2-2L402.2,378.8z"/>
<path className="st16" d="M402.2,378.6l-2.2-2L402.2,378.6z"/>
<path className="st17" d="M402.2,378.6l-2.2-2L402.2,378.6z"/>
<path className="st16" d="M399.8,376.6l2.4,2.2l-2.2-2.2H399.8z M402.2,379l-2.4-2.4l2.4,2.2V379z"/>
<path className="st17" d="M399.8,376.6l2.4,2.2l-2.2-2.2H399.8z M402.2,379l-2.4-2.4l2.4,2.2V379z"/>
<path className="st16" d="M399.7,376.6l0.6,0.5l-0.5-0.5H399.7z M400.1,377l-0.4-0.4l0.6,0.5L400.1,377z"/>
<path className="st17" d="M399.7,376.6l0.6,0.5l-0.5-0.5H399.7z M400.1,377l-0.4-0.4l0.6,0.5L400.1,377z"/>
<path className="st16" d="M400.1,377l1.7,1.6l-1.4-1.4L400.1,377z M401.9,378.8l-1.8-1.8l1.7,1.6L401.9,378.8z"/>
<path className="st17" d="M400.1,377l1.7,1.6l-1.4-1.4L400.1,377z M401.9,378.8l-1.8-1.8l1.7,1.6L401.9,378.8z"/>
<path className="st16" d="M399.6,376.6l0.5,0.4l-0.4-0.4H399.6z M400,377l-0.4-0.4l0.5,0.4H400z"/>
<path className="st17" d="M399.6,376.6l0.5,0.4l-0.4-0.4H399.6z M400,377l-0.4-0.4l0.5,0.4H400z"/>
<path className="st16" d="M400,377l1.9,1.8l-1.8-1.8H400z M401.9,378.9L400,377l1.9,1.8V378.9z"/>
<path className="st17" d="M400,377l1.9,1.8l-1.8-1.8H400z M401.9,378.9L400,377l1.9,1.8V378.9z"/>
<path className="st16" d="M399.6,376.6l0.4,0.4L399.6,376.6z M399.8,376.8l-0.2-0.2l0.4,0.4L399.8,376.8z"/>
<path className="st17" d="M399.6,376.6l0.4,0.4L399.6,376.6z M399.8,376.8l-0.2-0.2l0.4,0.4L399.8,376.8z"/>
<path className="st16" d="M399.8,377l2,1.9L400,377H399.8z M401.9,379l-2-2l2,1.9V379z"/>
<path className="st17" d="M399.8,377l2,1.9L400,377H399.8z M401.9,379l-2-2l2,1.9V379z"/>
<path className="st16" d="M399.6,376.6L399.6,376.6l0.2,0.2L399.6,376.6z M399.8,376.8l-0.2-0.2L399.8,376.8z"/>
<path className="st17" d="M399.6,376.6L399.6,376.6l0.2,0.2L399.6,376.6z M399.8,376.8l-0.2-0.2L399.8,376.8z"/>
<path className="st16" d="M399.8,376.8l2,2.2l-2-2V376.8z M399.8,376.8L399.8,376.8l2,2.2L399.8,376.8z"/>
<path className="st17" d="M399.8,376.8l2,2.2l-2-2V376.8z M399.8,376.8L399.8,376.8l2,2.2L399.8,376.8z"/>
<path className="st16" d="M399.6,376.6L399.6,376.6l0.2,0.2L399.6,376.6z M399.8,376.8l-0.2-0.2L399.8,376.8z"/>
<path className="st17" d="M399.6,376.6L399.6,376.6l0.2,0.2L399.6,376.6z M399.8,376.8l-0.2-0.2L399.8,376.8z"/>
<path className="st16" d="M399.8,377l2,2l-2-2.2V377z M402,379l-2.2-2l2,2H402z"/>
<path className="st17" d="M399.8,377l2,2l-2-2.2V377z M402,379l-2.2-2l2,2H402z"/>
<path className="st16" d="M402,379h0.2l-0.5-0.5L402,379z M402.2,379.2L402,379h0.2V379.2z"/>
<path className="st17" d="M402,379h0.2l-0.5-0.5L402,379z M402.2,379.2L402,379h0.2V379.2z"/>
<path className="st16" d="M399.8,377L399.8,377l2.2,2L399.8,377z M402,379l-2.2-2L402,379z"/>
<path className="st17" d="M399.8,377L399.8,377l2.2,2L399.8,377z M402,379l-2.2-2L402,379z"/>
<path className="st16" d="M402,379L402,379l0.2,0.2L402,379z M402.2,379.2L402,379L402.2,379.2z"/>
<path className="st17" d="M402,379L402,379l0.2,0.2L402,379z M402.2,379.2L402,379L402.2,379.2z"/>
<polygon className="st16" points="398.8,376.4 399.2,376.8 398.6,376.4 "/>
<polygon className="st17" points="398.8,376.4 399.2,376.8 398.6,376.4 "/>
<path className="st16" d="M399.5,377.1l2.5,1.9l-2.2-2L399.5,377.1z M401.8,379.4l-2.3-2.3l2.5,1.9L401.8,379.4z"/>
<path className="st17" d="M399.5,377.1l2.5,1.9l-2.2-2L399.5,377.1z M401.8,379.4l-2.3-2.3l2.5,1.9L401.8,379.4z"/>
<path className="st16" d="M401.8,379.4l0.5-0.1L402,379L401.8,379.4z M402,379.6l-0.2-0.2l0.5-0.1L402,379.6z"/>
<path className="st17" d="M401.8,379.4l0.5-0.1L402,379L401.8,379.4z M402,379.6l-0.2-0.2l0.5-0.1L402,379.6z"/>
<path className="st16" d="M398.6,376.2l0.6,0.6l-0.6-0.5V376.2z M398.6,376.2L398.6,376.2l0.6,0.6L398.6,376.2z"/>
<path className="st17" d="M398.6,376.2l0.6,0.6l-0.6-0.5V376.2z M398.6,376.2L398.6,376.2l0.6,0.6L398.6,376.2z"/>
<path className="st16" d="M398.6,376.2l0.6,0.6L398.6,376.2z M399.1,376.8l-0.5-0.6l0.6,0.6H399.1z"/>
<path className="st17" d="M398.6,376.2l0.6,0.6L398.6,376.2z M399.1,376.8l-0.5-0.6l0.6,0.6H399.1z"/>
<path className="st16" d="M399.1,376.8h0.7l-0.2-0.2L399.1,376.8z M399.4,377.1l-0.2-0.2h0.7L399.4,377.1z"/>
<path className="st17" d="M399.1,376.8h0.7l-0.2-0.2L399.1,376.8z M399.4,377.1l-0.2-0.2h0.7L399.4,377.1z"/>
<path className="st16" d="M398.6,376.2L398.6,376.2l0.5,0.6L398.6,376.2z M399.1,376.8l-0.5-0.6L399.1,376.8z"/>
<path className="st17" d="M398.6,376.2L398.6,376.2l0.5,0.6L398.6,376.2z M399.1,376.8l-0.5-0.6L399.1,376.8z"/>
<path className="st16" d="M399.1,376.8L399.1,376.8l0.2,0.2L399.1,376.8z M399.4,377.1l-0.2-0.2L399.4,377.1z"/>
<path className="st17" d="M399.1,376.8L399.1,376.8l0.2,0.2L399.1,376.8z M399.4,377.1l-0.2-0.2L399.4,377.1z"/>
<path className="st16" d="M399.4,377.1l2.4,2.3l-2.3-2.3H399.4z M401.8,379.5l-2.4-2.4l2.4,2.3V379.5z"/>
<path className="st17" d="M399.4,377.1l2.4,2.3l-2.3-2.3H399.4z M401.8,379.5l-2.4-2.4l2.4,2.3V379.5z"/>
<path className="st16" d="M398.5,376.2l0.6,0.6l-0.5-0.6H398.5z M399.1,377l-0.6-0.7l0.6,0.6V377z"/>
<path className="st17" d="M398.5,376.2l0.6,0.6l-0.5-0.6H398.5z M399.1,377l-0.6-0.7l0.6,0.6V377z"/>
<path className="st16" d="M399.1,377l0.2,0.1l-0.2-0.2V377z M399.4,377.2l-0.2-0.2l0.2,0.1V377.2z"/>
<path className="st17" d="M399.1,377l0.2,0.1l-0.2-0.2V377z M399.4,377.2l-0.2-0.2l0.2,0.1V377.2z"/>
<path className="st16" d="M399.4,377.2l2.4,2.3l-2.4-2.4V377.2z M401.6,379.5l-2.3-2.3l2.4,2.3H401.6z"/>
<path className="st17" d="M399.4,377.2l2.4,2.3l-2.4-2.4V377.2z M401.6,379.5l-2.3-2.3l2.4,2.3H401.6z"/>
<path className="st16" d="M401.6,379.5l0.4,0.1l-0.2-0.2L401.6,379.5z M401.9,379.7l-0.2-0.2l0.4,0.1L401.9,379.7z"/>
<path className="st17" d="M401.6,379.5l0.4,0.1l-0.2-0.2L401.6,379.5z M401.9,379.7l-0.2-0.2l0.4,0.1L401.9,379.7z"/>
<path className="st16" d="M398.4,376.2l0.7,0.7l-0.6-0.7H398.4z M398.4,376.2L398.4,376.2l0.7,0.7L398.4,376.2z"/>
<path className="st17" d="M398.4,376.2l0.7,0.7l-0.6-0.7H398.4z M398.4,376.2L398.4,376.2l0.7,0.7L398.4,376.2z"/>
<path className="st16" d="M399.1,377L399.1,377l0.2,0.2L399.1,377z M399.4,377.2l-0.2-0.2L399.4,377.2z"/>
<path className="st17" d="M399.1,377L399.1,377l0.2,0.2L399.1,377z M399.4,377.2l-0.2-0.2L399.4,377.2z"/>
<path className="st16" d="M399.4,377.2L399.4,377.2l2.3,2.3L399.4,377.2z M401.6,379.5l-2.3-2.3L401.6,379.5z"/>
<path className="st17" d="M399.4,377.2L399.4,377.2l2.3,2.3L399.4,377.2z M401.6,379.5l-2.3-2.3L401.6,379.5z"/>
<path className="st16" d="M401.6,379.5L401.6,379.5l0.2,0.2L401.6,379.5z M401.9,379.7l-0.2-0.2L401.9,379.7z"/>
<path className="st17" d="M401.6,379.5L401.6,379.5l0.2,0.2L401.6,379.5z M401.9,379.7l-0.2-0.2L401.9,379.7z"/>
<path className="st16" d="M398.3,376.2l0.8,0.7l-0.7-0.7H398.3z M399,377l-0.7-0.7l0.8,0.7H399z"/>
<path className="st17" d="M398.3,376.2l0.8,0.7l-0.7-0.7H398.3z M399,377l-0.7-0.7l0.8,0.7H399z"/>
<path className="st16" d="M399,377l0.4,0.2l-0.2-0.2H399z M399.2,377.2L399,377l0.4,0.2H399.2z"/>
<path className="st17" d="M399,377l0.4,0.2l-0.2-0.2H399z M399.2,377.2L399,377l0.4,0.2H399.2z"/>
<path className="st16" d="M399.2,377.2l2.4,2.3l-2.3-2.3H399.2z M401.6,379.6l-2.4-2.4l2.4,2.3V379.6z"/>
<path className="st17" d="M399.2,377.2l2.4,2.3l-2.3-2.3H399.2z M401.6,379.6l-2.4-2.4l2.4,2.3V379.6z"/>
<path className="st16" d="M401.6,379.6l0.2,0.1l-0.2-0.2V379.6z M401.9,379.8l-0.2-0.2l0.2,0.1V379.8z"/>
<path className="st17" d="M401.6,379.6l0.2,0.1l-0.2-0.2V379.6z M401.9,379.8l-0.2-0.2l0.2,0.1V379.8z"/>
<path className="st16" d="M398.2,376.2l0.8,0.7l-0.7-0.7H398.2z M399,377.1l-0.8-0.8l0.8,0.7V377.1z"/>
<path className="st17" d="M398.2,376.2l0.8,0.7l-0.7-0.7H398.2z M399,377.1l-0.8-0.8l0.8,0.7V377.1z"/>
<path className="st16" d="M399,377.1l0.2,0.1L399,377V377.1z M399,377.1L399,377.1l0.2,0.1L399,377.1z"/>
<path className="st17" d="M399,377.1l0.2,0.1L399,377V377.1z M399,377.1L399,377.1l0.2,0.1L399,377.1z"/>
<path className="st16" d="M399.2,377.2l1.2,1.2L399.2,377.2z M400.3,378.3l-1.1-1.1l1.2,1.2L400.3,378.3z"/>
<path className="st17" d="M399.2,377.2l1.2,1.2L399.2,377.2z M400.3,378.3l-1.1-1.1l1.2,1.2L400.3,378.3z"/>
<path className="st16" d="M400.6,378.5l1.1,1.1l-1.2-1.2L400.6,378.5z M400.6,378.5L400.6,378.5l1.1,1.1L400.6,378.5z"/>
<path className="st17" d="M400.6,378.5l1.1,1.1l-1.2-1.2L400.6,378.5z M400.6,378.5L400.6,378.5l1.1,1.1L400.6,378.5z"/>
<path className="st16" d="M401.6,379.6l0.2,0.2L401.6,379.6z M401.8,379.8l-0.1-0.2l0.2,0.2H401.8z"/>
<path className="st17" d="M401.6,379.6l0.2,0.2L401.6,379.6z M401.8,379.8l-0.1-0.2l0.2,0.2H401.8z"/>
<path className="st16" d="M398.2,376.2L398.2,376.2l0.8,0.8L398.2,376.2z M399,377.1l-0.8-0.8L399,377.1z"/>
<path className="st17" d="M398.2,376.2L398.2,376.2l0.8,0.8L398.2,376.2z M399,377.1l-0.8-0.8L399,377.1z"/>
<path className="st16" d="M399,377.1l0.2,0.1L399,377.1z M399.2,377.3l-0.2-0.2l0.2,0.1V377.3z"/>
<path className="st17" d="M399,377.1l0.2,0.1L399,377.1z M399.2,377.3l-0.2-0.2l0.2,0.1V377.3z"/>
<path className="st16" d="M398,376.2l1,0.8l-0.8-0.8H398z M398.9,377.1l-0.8-0.8l1,0.8H398.9z"/>
<path className="st17" d="M398,376.2l1,0.8l-0.8-0.8H398z M398.9,377.1l-0.8-0.8l1,0.8H398.9z"/>
<path className="st16" d="M398.9,377.1l0.4,0.2l-0.2-0.2H398.9z M399.1,377.3l-0.2-0.2l0.4,0.2H399.1z"/>
<path className="st17" d="M398.9,377.1l0.4,0.2l-0.2-0.2H398.9z M399.1,377.3l-0.2-0.2l0.4,0.2H399.1z"/>
<path className="st16" d="M399.1,377.3l1.2,1l-1.1-1.1L399.1,377.3z M400.2,378.4l-1.1-1.1l1.2,1L400.2,378.4z"/>
<path className="st17" d="M399.1,377.3l1.2,1l-1.1-1.1L399.1,377.3z M400.2,378.4l-1.1-1.1l1.2,1L400.2,378.4z"/>
<path className="st16" d="M400.4,378.6l1.2,1l-1.1-1.1L400.4,378.6z M401.5,379.7l-1.1-1.1l1.2,1L401.5,379.7z"/>
<path className="st17" d="M400.4,378.6l1.2,1l-1.1-1.1L400.4,378.6z M401.5,379.7l-1.1-1.1l1.2,1L401.5,379.7z"/>
<path className="st16" d="M399,377.4l1.2,1l-1.1-1.1L399,377.4z M400.1,378.5l-1.1-1.1l1.2,1L400.1,378.5z"/>
<path className="st17" d="M399,377.4l1.2,1l-1.1-1.1L399,377.4z M400.1,378.5l-1.1-1.1l1.2,1L400.1,378.5z"/>
<path className="st16" d="M400.3,378.8l1.2,1l-1.1-1.1L400.3,378.8z M401.4,379.8l-1.1-1.1l1.2,1L401.4,379.8z"/>
<path className="st17" d="M400.3,378.8l1.2,1l-1.1-1.1L400.3,378.8z M401.4,379.8l-1.1-1.1l1.2,1L401.4,379.8z"/>
<path className="st16" d="M399,377.4l1.1,1.1L399,377.4z M400.2,378.6l-1.2-1.2l1.1,1.1L400.2,378.6z"/>
<path className="st17" d="M399,377.4l1.1,1.1L399,377.4z M400.2,378.6l-1.2-1.2l1.1,1.1L400.2,378.6z"/>
<path className="st16" d="M400.2,378.6l1.2,1.2l-1.1-1.1L400.2,378.6z M400.2,378.6L400.2,378.6l1.2,1.2L400.2,378.6z"/>
<path className="st17" d="M400.2,378.6l1.2,1.2l-1.1-1.1L400.2,378.6z M400.2,378.6L400.2,378.6l1.2,1.2L400.2,378.6z"/>
<path className="st16" d="M397.8,376.5l1.1,0.6l-0.8-0.8L397.8,376.5z M398.6,377.3l-0.8-0.8l1.1,0.6L398.6,377.3z"/>
<path className="st17" d="M397.8,376.5l1.1,0.6l-0.8-0.8L397.8,376.5z M398.6,377.3l-0.8-0.8l1.1,0.6L398.6,377.3z"/>
<path className="st16" d="M398.6,377.3h0.5l-0.2-0.2L398.6,377.3z M398.9,377.6l-0.2-0.2h0.5L398.9,377.6z"/>
<path className="st17" d="M398.6,377.3h0.5l-0.2-0.2L398.6,377.3z M398.9,377.6l-0.2-0.2h0.5L398.9,377.6z"/>
<path className="st16" d="M398.9,377.6l1.3,1.1l-1.2-1.2L398.9,377.6z M400.1,378.8l-1.2-1.2l1.3,1.1L400.1,378.8z"/>
<path className="st17" d="M398.9,377.6l1.3,1.1l-1.2-1.2L398.9,377.6z M400.1,378.8l-1.2-1.2l1.3,1.1L400.1,378.8z"/>
<path className="st16" d="M400.1,378.8l1.3,1.1l-1.2-1.2L400.1,378.8z M401.3,380l-1.2-1.2l1.3,1.1L401.3,380z"/>
<path className="st17" d="M400.1,378.8l1.3,1.1l-1.2-1.2L400.1,378.8z M401.3,380l-1.2-1.2l1.3,1.1L401.3,380z"/>
<path className="st16" d="M397.7,376.6l1,0.7l-0.8-0.8L397.7,376.6z M398.5,377.6l-0.8-1l1,0.7L398.5,377.6z"/>
<path className="st17" d="M397.7,376.6l1,0.7l-0.8-0.8L397.7,376.6z M398.5,377.6l-0.8-1l1,0.7L398.5,377.6z"/>
<path className="st16" d="M398.5,377.6h0.4l-0.2-0.2L398.5,377.6z M398.8,377.7l-0.2-0.1h0.4L398.8,377.7z"/>
<path className="st17" d="M398.5,377.6h0.4l-0.2-0.2L398.5,377.6z M398.8,377.7l-0.2-0.1h0.4L398.8,377.7z"/>
<path className="st16" d="M398.8,377.7l1.2,1l-1.1-1.1L398.8,377.7z M399.8,378.8l-1.1-1.1l1.2,1L399.8,378.8z"/>
<path className="st17" d="M398.8,377.7l1.2,1l-1.1-1.1L398.8,377.7z M399.8,378.8l-1.1-1.1l1.2,1L399.8,378.8z"/>
<path className="st16" d="M397.6,376.7l1.3,1.1l-1.3-1.2V376.7z M398.8,377.9l-1.2-1.2l1.3,1.1L398.8,377.9z"/>
<path className="st17" d="M397.6,376.7l1.3,1.1l-1.3-1.2V376.7z M398.8,377.9l-1.2-1.2l1.3,1.1L398.8,377.9z"/>
<path className="st16" d="M398.8,377.9l1.1,0.8l-1-1L398.8,377.9z M399.7,378.9l-1-1l1.1,0.8L399.7,378.9z"/>
<path className="st17" d="M398.8,377.9l1.1,0.8l-1-1L398.8,377.9z M399.7,378.9l-1-1l1.1,0.8L399.7,378.9z"/>
<path className="st16" d="M400,379.1l1.3,0.8l-1.1-1.1L400,379.1z M401,380.2l-1.1-1.1l1.3,0.8L401,380.2z"/>
<path className="st17" d="M400,379.1l1.3,0.8l-1.1-1.1L400,379.1z M401,380.2l-1.1-1.1l1.3,0.8L401,380.2z"/>
<path className="st16" d="M401,380.2l0.7-0.4l-0.1-0.2L401,380.2z M401.3,380.4l-0.2-0.2l0.7-0.4L401.3,380.4z"/>
<path className="st17" d="M401,380.2l0.7-0.4l-0.1-0.2L401,380.2z M401.3,380.4l-0.2-0.2l0.7-0.4L401.3,380.4z"/>
<path className="st16" d="M398.6,378l1.1,0.8l-1-1L398.6,378z M399.6,379l-1-1l1.1,0.8L399.6,379z"/>
<path className="st17" d="M398.6,378l1.1,0.8l-1-1L398.6,378z M399.6,379l-1-1l1.1,0.8L399.6,379z"/>
<path className="st16" d="M398.5,378.2l1.1,0.8l-1-1L398.5,378.2z M399.5,379.1l-1-1l1.1,0.8L399.5,379.1z M398.5,378.2l1,1L398.5,378.2
	z M398.5,378.2L398.5,378.2l1,1L398.5,378.2z"/>
<path className="st17" d="M398.5,378.2l1.1,0.8l-1-1L398.5,378.2z M399.5,379.1l-1-1l1.1,0.8L399.5,379.1z M398.5,378.2l1,1L398.5,378.2
	z M398.5,378.2L398.5,378.2l1,1L398.5,378.2z"/>
<path className="st16" d="M399.7,379.4l0.7,0.2l-0.5-0.5L399.7,379.4z M400.2,379.8l-0.5-0.5l0.7,0.2L400.2,379.8z"/>
<path className="st17" d="M399.7,379.4l0.7,0.2l-0.5-0.5L399.7,379.4z M400.2,379.8l-0.5-0.5l0.7,0.2L400.2,379.8z"/>
<path className="st16" d="M398.4,378.3l1.1,0.8l-1-1L398.4,378.3z M399.5,379.2l-1.1-1l1.1,0.8V379.2z"/>
<path className="st17" d="M398.4,378.3l1.1,0.8l-1-1L398.4,378.3z M399.5,379.2l-1.1-1l1.1,0.8V379.2z"/>
<path className="st16" d="M399.6,379.5l0.6,0.4l-0.5-0.5L399.6,379.5z M400.1,380l-0.5-0.5l0.6,0.4L400.1,380z"/>
<path className="st17" d="M399.6,379.5l0.6,0.4l-0.5-0.5L399.6,379.5z M400.1,380l-0.5-0.5l0.6,0.4L400.1,380z"/>
<path className="st16" d="M397.2,377.1l1.6,0.8l-1.2-1.2L397.2,377.1z M398.4,378.3l-1.2-1.2l1.6,0.8L398.4,378.3z"/>
<path className="st17" d="M397.2,377.1l1.6,0.8l-1.2-1.2L397.2,377.1z M398.4,378.3l-1.2-1.2l1.6,0.8L398.4,378.3z"/>
<path className="st16" d="M398.4,378.3l1.1,1L398.4,378.3z M399.5,379.4l-1.1-1.1l1.1,1V379.4z"/>
<path className="st17" d="M398.4,378.3l1.1,1L398.4,378.3z M399.5,379.4l-1.1-1.1l1.1,1V379.4z"/>
<path className="st16" d="M399.5,379.4l0.6,0.6l-0.5-0.5L399.5,379.4z M399.5,379.4L399.5,379.4l0.6,0.6L399.5,379.4z"/>
<path className="st17" d="M399.5,379.4l0.6,0.6l-0.5-0.5L399.5,379.4z M399.5,379.4L399.5,379.4l0.6,0.6L399.5,379.4z"/>
<path className="st16" d="M397.1,377.1l1.3,1.2l-1.2-1.2H397.1z M398.3,378.4l-1.2-1.3l1.3,1.2L398.3,378.4z"/>
<path className="st17" d="M397.1,377.1l1.3,1.2l-1.2-1.2H397.1z M398.3,378.4l-1.2-1.3l1.3,1.2L398.3,378.4z"/>
<path className="st16" d="M398.3,378.4l1.2,1l-1.1-1.1L398.3,378.4z M399.4,379.5l-1.1-1.1l1.2,1L399.4,379.5z"/>
<path className="st17" d="M398.3,378.4l1.2,1l-1.1-1.1L398.3,378.4z M399.4,379.5l-1.1-1.1l1.2,1L399.4,379.5z"/>
<path className="st16" d="M399.4,379.5l0.7,0.5l-0.6-0.6L399.4,379.5z M400,380.1l-0.6-0.6l0.7,0.5L400,380.1z"/>
<path className="st17" d="M399.4,379.5l0.7,0.5l-0.6-0.6L399.4,379.5z M400,380.1l-0.6-0.6l0.7,0.5L400,380.1z"/>
<path className="st16" d="M400,380.1l0.8-0.1l-0.4-0.4L400,380.1z M400.3,380.4l-0.4-0.4l0.8-0.1L400.3,380.4z"/>
<path className="st17" d="M400,380.1l0.8-0.1l-0.4-0.4L400,380.1z M400.3,380.4l-0.4-0.4l0.8-0.1L400.3,380.4z"/>
<path className="st16" d="M400.3,380.4l0.6-0.4l-0.1-0.1L400.3,380.4z M400.4,380.6l-0.1-0.1l0.6-0.4L400.4,380.6z"/>
<path className="st17" d="M400.3,380.4l0.6-0.4l-0.1-0.1L400.3,380.4z M400.4,380.6l-0.1-0.1l0.6-0.4L400.4,380.6z"/>
<path className="st16" d="M400.4,380.6l0.8-0.1l-0.4-0.4L400.4,380.6z M400.8,380.9l-0.4-0.4l0.8-0.1L400.8,380.9z"/>
<path className="st17" d="M400.4,380.6l0.8-0.1l-0.4-0.4L400.4,380.6z M400.8,380.9l-0.4-0.4l0.8-0.1L400.8,380.9z"/>
<path className="st16" d="M396.7,377l2.2,2l-2-2.2L396.7,377z M398.8,379.1l-2-2.2l2.2,2L398.8,379.1z"/>
<path className="st17" d="M396.7,377l2.2,2l-2-2.2L396.7,377z M398.8,379.1l-2-2.2l2.2,2L398.8,379.1z"/>
<path className="st16" d="M399.7,380l1.3,1.1l-1.2-1.1H399.7z M400.9,381.2l-1.2-1.2l1.3,1.1L400.9,381.2z"/>
<path className="st17" d="M399.7,380l1.3,1.1l-1.2-1.1H399.7z M400.9,381.2l-1.2-1.2l1.3,1.1L400.9,381.2z"/>
<path className="st16" d="M396.6,377.1l2.6,2.5l-2.5-2.6L396.6,377.1z M396.6,377.1L396.6,377.1l2.6,2.5L396.6,377.1z"/>
<path className="st17" d="M396.6,377.1l2.6,2.5l-2.5-2.6L396.6,377.1z M396.6,377.1L396.6,377.1l2.6,2.5L396.6,377.1z"/>
<path className="st16" d="M399.2,379.6l1.7,1.6L399.2,379.6z M400.8,381.3l-1.6-1.7l1.7,1.6L400.8,381.3z"/>
<path className="st17" d="M399.2,379.6l1.7,1.6L399.2,379.6z M400.8,381.3l-1.6-1.7l1.7,1.6L400.8,381.3z"/>
<line className="st19" x1="400.8" y1="378" x2="398.4" y2="380.4"/>
<line className="st19" x1="398.9" y1="377.8" x2="397.6" y2="376.6"/>
<line className="st19" x1="399.7" y1="378.9" x2="398.8" y2="377.9"/>
<line className="st19" x1="400.8" y1="380" x2="400.4" y2="379.6"/>
<polyline className="st19" points="400.8,380.9 401.3,380.4 400.9,380.1 400.4,380.6 "/>
<line className="st19" x1="400.8" y1="380" x2="400.3" y2="380.4"/>
<line className="st19" x1="400.4" y1="379.6" x2="400" y2="380.1"/>
<line className="st19" x1="398.9" y1="377.8" x2="398.3" y2="378.4"/>
<line className="st19" x1="397.6" y1="376.6" x2="397.1" y2="377.1"/>
<line className="st19" x1="400.2" y1="378.9" x2="400" y2="378.6"/>
<path className="st19" d="M399.4,379.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C399.3,379.1,399.3,379.2,399.4,379.3"/>
<line className="st19" x1="400.2" y1="378.9" x2="399.7" y2="379.4"/>
<line className="st19" x1="400" y1="378.6" x2="399.5" y2="379.1"/>
<polyline className="st19" points="399.8,380 399.7,380 398.8,379.1 398.9,379 "/>
<path className="st19" d="M400.1,378.6c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C400.1,378.4,400.1,378.5,400.1,378.6"/>
<path className="st19" d="M400.5,376.8c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3"/>
<path className="st19" d="M401.9,379.7c0.2-0.2,0.4-0.5,0.4-0.7s-0.1-0.6-0.3-0.8"/>
<path className="st19" d="M401.6,379.5c0.2-0.1,0.2-0.3,0.3-0.5c0-0.2-0.1-0.4-0.2-0.5"/>
<path className="st19" d="M400.2,377c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2"/>
<line className="st19" x1="401.8" y1="378.5" x2="400.3" y2="377.1"/>
<line className="st19" x1="399.4" y1="377.1" x2="398.8" y2="377.7"/>
<polyline className="st19" points="401.6,379.5 401,380.2 400,379.1 "/>
<line className="st19" x1="402" y1="378.3" x2="400.6" y2="376.8"/>
<path className="st19" d="M399.2,376.8c-0.1-0.3-0.4-0.6-0.7-0.6c-0.3-0.1-0.7,0.1-0.9,0.4"/>
<line className="st19" x1="401" y1="381" x2="396.8" y2="376.8"/>
<polyline className="st19" points="401,381 400.8,381.3 396.6,377.1 396.8,376.8 "/>
<line className="st19" x1="399.1" y1="376.8" x2="398.5" y2="377.6"/>
<line className="st19" x1="401.9" y1="379.7" x2="400.8" y2="380.9"/>
<path className="st20" d="M399.6,379l-0.1,0.1L399.6,379z"/>
<path className="st21" d="M399.6,379l-0.1,0.1L399.6,379z"/>
<path className="st20" d="M399.5,379.1L399.5,379.1z"/>
<path className="st21" d="M399.5,379.1L399.5,379.1z"/>
<path className="st20" d="M399.5,379.1L399.5,379.1z"/>
<path className="st21" d="M399.5,379.1L399.5,379.1z"/>
<path className="st20" d="M399.5,379.1L399.5,379.1z"/>
<path className="st21" d="M399.5,379.1L399.5,379.1z"/>
<path className="st20" d="M399.5,379.1L399.5,379.1z"/>
<path className="st21" d="M399.5,379.1L399.5,379.1z"/>
<path className="st20" d="M399.5,379.1L399.5,379.1z"/>
<path className="st21" d="M399.5,379.1L399.5,379.1z"/>
<path className="st16" d="M228,257.7l-0.2-6.7l-3.2,3.2L228,257.7z M231.2,254.3l-3.2,3.4l-0.2-6.7L231.2,254.3z"/>
<path className="st22" d="M228,257.7l-0.2-6.7l-3.2,3.2L228,257.7z M231.2,254.3l-3.2,3.4l-0.2-6.7L231.2,254.3z"/>
<path className="st16" d="M228,257.7l2.6-2.6L228,257.7z M230.5,255.2l-2.5,2.5l2.6-2.6L230.5,255.2z"/>
<path className="st22" d="M228,257.7l2.6-2.6L228,257.7z M230.5,255.2l-2.5,2.5l2.6-2.6L230.5,255.2z"/>
<path className="st16" d="M230.8,254.9l0.5-0.6l-0.6,0.7L230.8,254.9z M231.2,254.4l-0.5,0.5l0.5-0.6V254.4z"/>
<path className="st22" d="M230.8,254.9l0.5-0.6l-0.6,0.7L230.8,254.9z M231.2,254.4l-0.5,0.5l0.5-0.6V254.4z"/>
<path className="st16" d="M228.1,257.8l2.4-2.6l-2.5,2.5L228.1,257.8z M230.6,255.3l-2.5,2.5l2.4-2.6L230.6,255.3z"/>
<path className="st22" d="M228.1,257.8l2.4-2.6l-2.5,2.5L228.1,257.8z M230.6,255.3l-2.5,2.5l2.4-2.6L230.6,255.3z"/>
<path className="st16" d="M230.9,255l0.4-0.6l-0.5,0.5L230.9,255z M231.4,254.6l-0.5,0.5l0.4-0.6L231.4,254.6z"/>
<path className="st22" d="M230.9,255l0.4-0.6l-0.5,0.5L230.9,255z M231.4,254.6l-0.5,0.5l0.4-0.6L231.4,254.6z"/>
<path className="st16" d="M228.2,257.9l2.4-2.6l-2.5,2.5L228.2,257.9z M230.8,255.3l-2.5,2.6l2.4-2.6H230.8z"/>
<path className="st22" d="M228.2,257.9l2.4-2.6l-2.5,2.5L228.2,257.9z M230.8,255.3l-2.5,2.6l2.4-2.6H230.8z"/>
<path className="st16" d="M230.9,255.2l0.5-0.6l-0.5,0.5V255.2z M231.5,254.6l-0.6,0.6l0.5-0.6H231.5z"/>
<path className="st22" d="M230.9,255.2l0.5-0.6l-0.5,0.5V255.2z M231.5,254.6l-0.6,0.6l0.5-0.6H231.5z"/>
<path className="st16" d="M228.2,257.9l2.5-2.6L228.2,257.9z M230.9,255.3l-2.6,2.6l2.5-2.6H230.9z"/>
<path className="st22" d="M228.2,257.9l2.5-2.6L228.2,257.9z M230.9,255.3l-2.6,2.6l2.5-2.6H230.9z"/>
<path className="st16" d="M230.9,255.3l0.6-0.7l-0.6,0.6V255.3z M231.5,254.7l-0.6,0.6l0.6-0.7V254.7z"/>
<path className="st22" d="M230.9,255.3l0.6-0.7l-0.6,0.6V255.3z M231.5,254.7l-0.6,0.6l0.6-0.7V254.7z"/>
<path className="st16" d="M228.7,258.4l2.8-3.7l-3.2,3.2L228.7,258.4z M232,255.2l-3.2,3.2l2.8-3.7L232,255.2z"/>
<path className="st22" d="M228.7,258.4l2.8-3.7l-3.2,3.2L228.7,258.4z M232,255.2l-3.2,3.2l2.8-3.7L232,255.2z"/>
<line className="st18" x1="230.4" y1="255.2" x2="224.5" y2="254.2"/>
<polyline className="st18" points="232,255.2 228.7,258.4 224.5,254.2 227.8,251 232,255.2 "/>
<line className="st18" x1="227.8" y1="251" x2="230.5" y2="254.9"/>
<path className="st18" d="M230.8,255.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C230.6,255.3,230.8,255.3,230.8,255.2"/>
<line className="st18" x1="230.5" y1="255.4" x2="228.7" y2="258.4"/>
<path className="st16" d="M228.6,263.8l1.6-1.7l-1.6,1.6V263.8z M230.3,262.1l-1.7,1.7l1.6-1.7H230.3z M228.6,263.8l1.7-1.7L228.6,263.8
	z M228.6,263.8L228.6,263.8l1.7-1.7L228.6,263.8z M230.3,262.1l-1.7,1.7L230.3,262.1z M228.6,263.8l1.7-1.7L228.6,263.8z
	 M228.6,263.8L228.6,263.8l1.7-1.7L228.6,263.8z M230.3,262.1l-1.7,1.7L230.3,262.1z"/>
<path className="st17" d="M228.6,263.8l1.6-1.7l-1.6,1.6V263.8z M230.3,262.1l-1.7,1.7l1.6-1.7H230.3z M228.6,263.8l1.7-1.7L228.6,263.8
	z M228.6,263.8L228.6,263.8l1.7-1.7L228.6,263.8z M230.3,262.1l-1.7,1.7L230.3,262.1z M228.6,263.8l1.7-1.7L228.6,263.8z
	 M228.6,263.8L228.6,263.8l1.7-1.7L228.6,263.8z M230.3,262.1l-1.7,1.7L230.3,262.1z"/>
<path className="st16" d="M229.7,264.9l0.6-2.8l-1.7,1.7L229.7,264.9z M231.4,263.2l-1.7,1.7l0.6-2.8L231.4,263.2z"/>
<path className="st17" d="M229.7,264.9l0.6-2.8l-1.7,1.7L229.7,264.9z M231.4,263.2l-1.7,1.7l0.6-2.8L231.4,263.2z"/>
<path className="st16" d="M229.8,265l0.7-1l-0.8,0.8L229.8,265z M230.3,264.6l-0.5,0.4l0.7-1L230.3,264.6z"/>
<path className="st17" d="M229.8,265l0.7-1l-0.8,0.8L229.8,265z M230.3,264.6l-0.5,0.4l0.7-1L230.3,264.6z"/>
<path className="st16" d="M231.1,263.8l0.2-0.6l-0.8,0.8L231.1,263.8z M231.5,263.3l-0.4,0.5l0.2-0.6L231.5,263.3z"/>
<path className="st17" d="M231.1,263.8l0.2-0.6l-0.8,0.8L231.1,263.8z M231.5,263.3l-0.4,0.5l0.2-0.6L231.5,263.3z"/>
<path className="st16" d="M229.8,265L229.8,265l0.5-0.4L229.8,265z M230.3,264.6l-0.5,0.4L230.3,264.6z"/>
<path className="st17" d="M229.8,265L229.8,265l0.5-0.4L229.8,265z M230.3,264.6l-0.5,0.4L230.3,264.6z"/>
<path className="st16" d="M230.6,264.5l0.2-0.4l-0.2,0.2V264.5z M231,264.2l-0.4,0.4l0.2-0.4H231z M230.6,264.5l0.4-0.4L230.6,264.5z
	 M230.6,264.5L230.6,264.5l0.4-0.4L230.6,264.5z M231,264.2l-0.4,0.4L231,264.2z M230.6,264.5l0.4-0.4L230.6,264.5z M230.6,264.5
	L230.6,264.5l0.4-0.4L230.6,264.5z M231,264.2l-0.4,0.4L231,264.2z"/>
<path className="st17" d="M230.6,264.5l0.2-0.4l-0.2,0.2V264.5z M231,264.2l-0.4,0.4l0.2-0.4H231z M230.6,264.5l0.4-0.4L230.6,264.5z
	 M230.6,264.5L230.6,264.5l0.4-0.4L230.6,264.5z M231,264.2l-0.4,0.4L231,264.2z M230.6,264.5l0.4-0.4L230.6,264.5z M230.6,264.5
	L230.6,264.5l0.4-0.4L230.6,264.5z M231,264.2l-0.4,0.4L231,264.2z"/>
<path className="st16" d="M231.2,264.4l-0.4,0.4l0.2-0.1L231.2,264.4z M231,264.2l0.2,0.2l-0.4,0.4L231,264.2z"/>
<path className="st17" d="M231.2,264.4l-0.4,0.4l0.2-0.1L231.2,264.4z M231,264.2l0.2,0.2l-0.4,0.4L231,264.2z"/>
<polygon className="st16" points="231,264.2 230.6,264.5 230.9,264.8 "/>
<polygon className="st17" points="231,264.2 230.6,264.5 230.9,264.8 "/>
<path className="st16" d="M231.7,264l-0.2-0.7l-0.4,0.5L231.7,264z M232,263.8l-0.2,0.2l-0.2-0.7L232,263.8z"/>
<path className="st17" d="M231.7,264l-0.2-0.7l-0.4,0.5L231.7,264z M232,263.8l-0.2,0.2l-0.2-0.7L232,263.8z"/>
<path className="st16" d="M230.2,265.5l0.1-0.8l-0.5,0.4L230.2,265.5z M230.4,265.2l-0.2,0.2l0.1-0.8L230.4,265.2z"/>
<path className="st17" d="M230.2,265.5l0.1-0.8l-0.5,0.4L230.2,265.5z M230.4,265.2l-0.2,0.2l0.1-0.8L230.4,265.2z"/>
<path className="st16" d="M230.5,265.8l-0.1-0.6l-0.2,0.2L230.5,265.8z M230.8,265.6l-0.2,0.2l-0.1-0.6L230.8,265.6z"/>
<path className="st17" d="M230.5,265.8l-0.1-0.6l-0.2,0.2L230.5,265.8z M230.8,265.6l-0.2,0.2l-0.1-0.6L230.8,265.6z"/>
<path className="st16" d="M232.1,264.3l-0.1-0.5l-0.2,0.2L232.1,264.3z M232.3,264l-0.2,0.2l-0.1-0.5L232.3,264z"/>
<path className="st17" d="M232.1,264.3l-0.1-0.5l-0.2,0.2L232.1,264.3z M232.3,264l-0.2,0.2l-0.1-0.5L232.3,264z"/>
<path className="st16" d="M232.3,264.9V264l-0.2,0.2L232.3,264.9z M232.6,264.6l-0.2,0.2V264L232.6,264.6z"/>
<path className="st17" d="M232.3,264.9V264l-0.2,0.2L232.3,264.9z M232.6,264.6l-0.2,0.2V264L232.6,264.6z"/>
<path className="st16" d="M231.1,266.2l-0.4-0.6l-0.2,0.2L231.1,266.2z M231.4,265.8l-0.2,0.4l-0.4-0.6L231.4,265.8z"/>
<path className="st17" d="M231.1,266.2l-0.4-0.6l-0.2,0.2L231.1,266.2z M231.4,265.8l-0.2,0.4l-0.4-0.6L231.4,265.8z"/>
<path className="st16" d="M231.1,266.1l0.2-0.2l-0.2,0.4V266.1z M231.5,265.8l-0.4,0.2l0.2-0.2H231.5z"/>
<path className="st17" d="M231.1,266.1l0.2-0.2l-0.2,0.4V266.1z M231.5,265.8l-0.4,0.2l0.2-0.2H231.5z"/>
<path className="st16" d="M232.3,265l0.2-0.4l-0.2,0.2V265z M232.6,264.8l-0.2,0.2l0.2-0.4V264.8z"/>
<path className="st17" d="M232.3,265l0.2-0.4l-0.2,0.2V265z M232.6,264.8l-0.2,0.2l0.2-0.4V264.8z"/>
<path className="st16" d="M231.6,266.1l-0.1-0.2l-0.4,0.2H231.6z M232.1,265.6l-0.5,0.5l-0.1-0.2L232.1,265.6z"/>
<path className="st17" d="M231.6,266.1l-0.1-0.2l-0.4,0.2H231.6z M232.1,265.6l-0.5,0.5l-0.1-0.2L232.1,265.6z"/>
<path className="st16" d="M232.1,265.6l0.5-0.8l-0.2,0.2L232.1,265.6z M232.6,265.1l-0.5,0.5l0.5-0.8V265.1z"/>
<path className="st17" d="M232.1,265.6l0.5-0.8l-0.2,0.2L232.1,265.6z M232.6,265.1l-0.5,0.5l0.5-0.8V265.1z"/>
<path className="st16" d="M231.7,266.1l0.8-1l-1,1H231.7z M232.6,265.2l-0.8,0.8l0.8-1V265.2z M232.6,265.4v-0.1l-0.8,0.8L232.6,265.4z"
	/>
<path className="st17" d="M231.7,266.1l0.8-1l-1,1H231.7z M232.6,265.2l-0.8,0.8l0.8-1V265.2z M232.6,265.4v-0.1l-0.8,0.8L232.6,265.4z"
	/>
<path className="st16" d="M232.6,265.2l-0.8,0.8L232.6,265.2z"/>
<path className="st17" d="M232.6,265.2l-0.8,0.8L232.6,265.2z"/>
<path className="st16" d="M232.6,265.5l-0.7,0.6l0.5-0.4L232.6,265.5z M232.6,265.4v0.1l-0.7,0.6L232.6,265.4z"/>
<path className="st17" d="M232.6,265.5l-0.7,0.6l0.5-0.4L232.6,265.5z M232.6,265.4v0.1l-0.7,0.6L232.6,265.4z"/>
<path className="st16" d="M232.6,265.4l-0.7,0.7L232.6,265.4z"/>
<path className="st17" d="M232.6,265.4l-0.7,0.7L232.6,265.4z"/>
<path className="st19" d="M228.5,263.7C228.5,263.7,228.5,263.8,228.5,263.7"/>
<line className="st19" x1="228.6" y1="263.7" x2="230.2" y2="262.1"/>
<path className="st19" d="M230.3,262C230.2,262,230.2,262,230.3,262"/>
<line className="st19" x1="230.3" y1="262.1" x2="232.3" y2="264"/>
<path className="st19" d="M230.5,265.8c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.3,0-1.8"/>
<line className="st19" x1="230.5" y1="265.8" x2="228.6" y2="263.8"/>
<path className="st19" d="M230.5,264.4C230.5,264.4,230.5,264.5,230.5,264.4"/>
<line className="st19" x1="230.6" y1="264.5" x2="230.9" y2="264.8"/>
<path className="st19" d="M230.8,264.7c0.2,0,0.4-0.2,0.4-0.4"/>
<line className="st19" x1="231.2" y1="264.4" x2="231" y2="264.2"/>
<path className="st19" d="M231,264.1C231,264,230.9,264,231,264.1"/>
<line className="st19" x1="230.9" y1="264.2" x2="230.6" y2="264.4"/>
<line className="st19" x1="231.7" y1="264" x2="232.1" y2="264.3"/>
<path className="st19" d="M230.8,265.6c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3"/>
<line className="st19" x1="230.8" y1="265.6" x2="230.4" y2="265.2"/>
<path className="st19" d="M231.6,263.9c-0.4-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.4,0.9,0,1.2"/>
<path className="st5" d="M227.1,272.6c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="227,272.6 226.9,272.4 231.1,268.2 231.2,268.4 227,272.6 "/>
<line className="st1" x1="234.6" y1="257.7" x2="228" y2="264.3"/>
<path className="st16" d="M232.1,261.8l6.7,0.1l-3.2,3.2L232.1,261.8z M235.3,258.5l-3.2,3.2l6.7,0.1L235.3,258.5z"/>
<path className="st17" d="M232.1,261.8l6.7,0.1l-3.2,3.2L232.1,261.8z M235.3,258.5l-3.2,3.2l6.7,0.1L235.3,258.5z"/>
<path className="st16" d="M232.1,261.8l2.6-2.6L232.1,261.8z M234.6,259.1l-2.5,2.6l2.6-2.6H234.6z"/>
<path className="st17" d="M232.1,261.8l2.6-2.6L232.1,261.8z M234.6,259.1l-2.5,2.6l2.6-2.6H234.6z"/>
<path className="st16" d="M234.7,259l0.6-0.5l-0.6,0.6V259z M235.3,258.4l-0.6,0.6l0.6-0.5V258.4z"/>
<path className="st17" d="M234.7,259l0.6-0.5l-0.6,0.6V259z M235.3,258.4l-0.6,0.6l0.6-0.5V258.4z"/>
<path className="st16" d="M232,261.6l2.6-2.5l-2.5,2.6L232,261.6z M234.5,259.1l-2.5,2.5l2.6-2.5H234.5z"/>
<path className="st17" d="M232,261.6l2.6-2.5l-2.5,2.6L232,261.6z M234.5,259.1l-2.5,2.5l2.6-2.5H234.5z"/>
<path className="st16" d="M234.7,258.9l0.6-0.5l-0.6,0.6V258.9z M235.2,258.4l-0.5,0.5l0.6-0.5H235.2z"/>
<path className="st17" d="M234.7,258.9l0.6-0.5l-0.6,0.6V258.9z M235.2,258.4l-0.5,0.5l0.6-0.5H235.2z"/>
<path className="st16" d="M231.8,261.5l2.6-2.4l-2.5,2.5L231.8,261.5z M234.4,259l-2.5,2.5l2.6-2.4L234.4,259z"/>
<path className="st17" d="M231.8,261.5l2.6-2.4l-2.5,2.5L231.8,261.5z M234.4,259l-2.5,2.5l2.6-2.4L234.4,259z"/>
<path className="st16" d="M234.6,258.8l0.6-0.4l-0.5,0.5L234.6,258.8z M235.1,258.3l-0.5,0.5l0.6-0.4L235.1,258.3z"/>
<path className="st17" d="M234.6,258.8l0.6-0.4l-0.5,0.5L234.6,258.8z M235.1,258.3l-0.5,0.5l0.6-0.4L235.1,258.3z"/>
<path className="st16" d="M231.8,261.5l2.5-2.5L231.8,261.5z M234.5,258.9l-2.6,2.6l2.5-2.5L234.5,258.9z"/>
<path className="st17" d="M231.8,261.5l2.5-2.5L231.8,261.5z M234.5,258.9l-2.6,2.6l2.5-2.5L234.5,258.9z"/>
<path className="st16" d="M234.5,258.9l0.6-0.6l-0.5,0.5L234.5,258.9z M235.1,258.2l-0.6,0.7l0.6-0.6V258.2z"/>
<path className="st17" d="M234.5,258.9l0.6-0.6l-0.5,0.5L234.5,258.9z M235.1,258.2l-0.6,0.7l0.6-0.6V258.2z"/>
<path className="st16" d="M231.4,260.9l3.7-2.8l-3.2,3.4L231.4,260.9z M234.6,257.7l-3.2,3.2l3.7-2.8L234.6,257.7z"/>
<path className="st17" d="M231.4,260.9l3.7-2.8l-3.2,3.4L231.4,260.9z M234.6,257.7l-3.2,3.2l3.7-2.8L234.6,257.7z"/>
<line className="st18" x1="234.6" y1="259.4" x2="235.6" y2="265.1"/>
<polyline className="st18" points="234.6,257.7 231.4,260.9 235.6,265.1 238.8,261.9 234.6,257.7 "/>
<line className="st18" x1="238.8" y1="261.9" x2="234.8" y2="259.1"/>
<path className="st18" d="M234.7,259.1c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C234.5,259.1,234.6,259.1,234.7,259.1"/>
<line className="st18" x1="234.4" y1="259.1" x2="231.4" y2="260.9"/>
<path className="st16" d="M309.6,245.9l-6.7-0.1l3.4-3.2L309.6,245.9z M306.4,249.3l3.2-3.4l-6.7-0.1L306.4,249.3z"/>
<path className="st17" d="M309.6,245.9l-6.7-0.1l3.4-3.2L309.6,245.9z M306.4,249.3l3.2-3.4l-6.7-0.1L306.4,249.3z"/>
<path className="st16" d="M309.6,246l-2.6,2.5l2.6-2.6V246z M307.1,248.6l2.5-2.5l-2.6,2.5H307.1z"/>
<path className="st17" d="M309.6,246l-2.6,2.5l2.6-2.6V246z M307.1,248.6l2.5-2.5l-2.6,2.5H307.1z"/>
<path className="st16" d="M307,248.7l-0.6,0.6l0.6-0.7V248.7z M307,248.7L307,248.7l-0.6,0.6L307,248.7z"/>
<path className="st17" d="M307,248.7l-0.6,0.6l0.6-0.7V248.7z M307,248.7L307,248.7l-0.6,0.6L307,248.7z"/>
<path className="st16" d="M309.7,246.2l-2.6,2.4l2.5-2.5L309.7,246.2z M307.2,248.6l2.5-2.4l-2.6,2.4H307.2z"/>
<path className="st17" d="M309.7,246.2l-2.6,2.4l2.5-2.5L309.7,246.2z M307.2,248.6l2.5-2.4l-2.6,2.4H307.2z"/>
<path className="st16" d="M307,248.9l-0.6,0.4l0.6-0.6V248.9z M306.5,249.4l0.5-0.5l-0.6,0.4L306.5,249.4z"/>
<path className="st17" d="M307,248.9l-0.6,0.4l0.6-0.6V248.9z M306.5,249.4l0.5-0.5l-0.6,0.4L306.5,249.4z"/>
<path className="st16" d="M309.8,246.2l-2.6,2.4l2.5-2.4H309.8z M307.3,248.7l2.5-2.5l-2.6,2.4L307.3,248.7z"/>
<path className="st17" d="M309.8,246.2l-2.6,2.4l2.5-2.4H309.8z M307.3,248.7l2.5-2.5l-2.6,2.4L307.3,248.7z"/>
<path className="st16" d="M307.1,248.9l-0.6,0.5l0.5-0.5H307.1z M306.6,249.5l0.5-0.6l-0.6,0.5L306.6,249.5z"/>
<path className="st17" d="M307.1,248.9l-0.6,0.5l0.5-0.5H307.1z M306.6,249.5l0.5-0.6l-0.6,0.5L306.6,249.5z"/>
<path className="st16" d="M309.8,246.3l-2.5,2.4l2.5-2.5V246.3z M307.2,248.9l2.6-2.6l-2.5,2.4L307.2,248.9z"/>
<path className="st17" d="M309.8,246.3l-2.5,2.4l2.5-2.5V246.3z M307.2,248.9l2.6-2.6l-2.5,2.4L307.2,248.9z"/>
<path className="st16" d="M307.2,248.9l-0.6,0.6l0.5-0.6H307.2z M307.2,248.9L307.2,248.9l-0.6,0.6L307.2,248.9z"/>
<path className="st17" d="M307.2,248.9l-0.6,0.6l0.5-0.6H307.2z M307.2,248.9L307.2,248.9l-0.6,0.6L307.2,248.9z"/>
<path className="st16" d="M310.4,246.8l-3.8,2.8l3.2-3.2L310.4,246.8z M307.1,250l3.4-3.2l-3.8,2.8L307.1,250z"/>
<path className="st17" d="M310.4,246.8l-3.8,2.8l3.2-3.2L310.4,246.8z M307.1,250l3.4-3.2l-3.8,2.8L307.1,250z"/>
<line className="st18" x1="307.1" y1="248.4" x2="306.2" y2="242.6"/>
<polyline className="st18" points="307.1,250 310.4,246.8 306.2,242.6 302.9,245.8 307.1,250 "/>
<line className="st18" x1="302.9" y1="245.8" x2="306.8" y2="248.6"/>
<path className="st18" d="M306.9,248.5c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C307.1,248.4,307,248.4,306.9,248.5"/>
<line className="st18" x1="307.4" y1="248.6" x2="310.4" y2="246.8"/>
<path className="st16" d="M314,248.2l1.7-1.7L314,248.2z M315.7,246.5l-1.7,1.7L315.7,246.5z M315.7,246.5L315.7,246.5l-1.7,1.7
	L315.7,246.5z M314,248.2l1.7-1.7L314,248.2z"/>
<path className="st17" d="M314,248.2l1.7-1.7L314,248.2z M315.7,246.5l-1.7,1.7L315.7,246.5z M315.7,246.5L315.7,246.5l-1.7,1.7
	L315.7,246.5z M314,248.2l1.7-1.7L314,248.2z"/>
<path className="st16" d="M315.8,246.5l-1.8,1.7l1.7-1.7H315.8z M314,248.3l1.8-1.8l-1.8,1.7V248.3z M315.8,246.5l-1.8,1.8L315.8,246.5z
	 M315.8,246.5L315.8,246.5l-1.8,1.8L315.8,246.5z"/>
<path className="st17" d="M315.8,246.5l-1.8,1.7l1.7-1.7H315.8z M314,248.3l1.8-1.8l-1.8,1.7V248.3z M315.8,246.5l-1.8,1.8L315.8,246.5z
	 M315.8,246.5L315.8,246.5l-1.8,1.8L315.8,246.5z"/>
<path className="st16" d="M316.9,247.6l-2.9,0.7l1.8-1.8L316.9,247.6z M315.1,249.4l1.8-1.8l-2.9,0.7L315.1,249.4z"/>
<path className="st17" d="M316.9,247.6l-2.9,0.7l1.8-1.8L316.9,247.6z M315.1,249.4l1.8-1.8l-2.9,0.7L315.1,249.4z"/>
<path className="st16" d="M317,247.8l-1.1,0.6l1-0.8L317,247.8z M316.6,248.2l0.5-0.4l-1.1,0.6L316.6,248.2z"/>
<path className="st17" d="M317,247.8l-1.1,0.6l1-0.8L317,247.8z M316.6,248.2l0.5-0.4l-1.1,0.6L316.6,248.2z"/>
<path className="st16" d="M315.7,249l-0.6,0.4l0.8-1L315.7,249z M315.4,249.5l0.4-0.5l-0.6,0.4L315.4,249.5z"/>
<path className="st17" d="M315.7,249l-0.6,0.4l0.8-1L315.7,249z M315.4,249.5l0.4-0.5l-0.6,0.4L315.4,249.5z"/>
<path className="st16" d="M317,247.8L317,247.8l-0.5,0.4L317,247.8z M316.6,248.2l0.5-0.4L316.6,248.2z"/>
<path className="st17" d="M317,247.8L317,247.8l-0.5,0.4L317,247.8z M316.6,248.2l0.5-0.4L316.6,248.2z"/>
<path className="st16" d="M316.4,248.6l-0.2,0.4l0.2-0.2V248.6z M316.1,248.9l0.4-0.4l-0.2,0.4H316.1z M316.4,248.6l-0.4,0.4
	L316.4,248.6z M316.4,248.6L316.4,248.6l-0.4,0.4L316.4,248.6z"/>
<path className="st17" d="M316.4,248.6l-0.2,0.4l0.2-0.2V248.6z M316.1,248.9l0.4-0.4l-0.2,0.4H316.1z M316.4,248.6l-0.4,0.4
	L316.4,248.6z M316.4,248.6L316.4,248.6l-0.4,0.4L316.4,248.6z"/>
<path className="st16" d="M316.6,248.7l-0.5,0.2l0.4-0.4L316.6,248.7z M316.2,249l0.4-0.4l-0.5,0.2L316.2,249z M316.6,248.7l-0.4,0.4
	L316.6,248.7z M316.6,248.7L316.6,248.7l-0.4,0.4L316.6,248.7z"/>
<path className="st17" d="M316.6,248.7l-0.5,0.2l0.4-0.4L316.6,248.7z M316.2,249l0.4-0.4l-0.5,0.2L316.2,249z M316.6,248.7l-0.4,0.4
	L316.6,248.7z M316.6,248.7L316.6,248.7l-0.4,0.4L316.6,248.7z"/>
<path className="st16" d="M316.3,249.2l0.4-0.4l-0.1,0.2L316.3,249.2z M316.2,249l0.1,0.1l0.4-0.4L316.2,249z"/>
<path className="st17" d="M316.3,249.2l0.4-0.4l-0.1,0.2L316.3,249.2z M316.2,249l0.1,0.1l0.4-0.4L316.2,249z"/>
<polygon className="st16" points="316.2,249 316.6,248.7 316.7,248.8 "/>
<polygon className="st17" points="316.2,249 316.6,248.7 316.7,248.8 "/>
<path className="st16" d="M316,249.8l-0.6-0.2l0.4-0.5L316,249.8z M315.7,249.9l0.2-0.1l-0.6-0.2L315.7,249.9z"/>
<path className="st17" d="M316,249.8l-0.6-0.2l0.4-0.5L316,249.8z M315.7,249.9l0.2-0.1l-0.6-0.2L315.7,249.9z"/>
<path className="st16" d="M317.4,248.2h-0.8l0.5-0.4L317.4,248.2z M317.2,248.4l0.2-0.2h-0.8L317.2,248.4z"/>
<path className="st17" d="M317.4,248.2h-0.8l0.5-0.4L317.4,248.2z M317.2,248.4l0.2-0.2h-0.8L317.2,248.4z"/>
<path className="st16" d="M317.8,248.6l-0.6-0.1l0.2-0.2L317.8,248.6z M317.6,248.8l0.1-0.2l-0.6-0.1L317.6,248.8z"/>
<path className="st17" d="M317.8,248.6l-0.6-0.1l0.2-0.2L317.8,248.6z M317.6,248.8l0.1-0.2l-0.6-0.1L317.6,248.8z"/>
<path className="st16" d="M316.3,250.1l-0.6-0.2l0.2-0.1L316.3,250.1z M316.1,250.2l0.2-0.1l-0.6-0.2L316.1,250.2z"/>
<path className="st17" d="M316.3,250.1l-0.6-0.2l0.2-0.1L316.3,250.1z M316.1,250.2l0.2-0.1l-0.6-0.2L316.1,250.2z"/>
<path className="st16" d="M316.9,250.4l-0.8-0.1l0.2-0.1L316.9,250.4z M316.6,250.6l0.4-0.2l-0.8-0.1L316.6,250.6z"/>
<path className="st17" d="M316.9,250.4l-0.8-0.1l0.2-0.1L316.9,250.4z M316.6,250.6l0.4-0.2l-0.8-0.1L316.6,250.6z"/>
<path className="st16" d="M318.1,249.2l-0.5-0.4l0.1-0.2L318.1,249.2z M317.9,249.4l0.2-0.2l-0.5-0.4L317.9,249.4z M318.1,249.2
	l-0.2,0.2L318.1,249.2z M318.1,249.2L318.1,249.2l-0.2,0.2L318.1,249.2z"/>
<path className="st17" d="M318.1,249.2l-0.5-0.4l0.1-0.2L318.1,249.2z M317.9,249.4l0.2-0.2l-0.5-0.4L317.9,249.4z M318.1,249.2
	l-0.2,0.2L318.1,249.2z M318.1,249.2L318.1,249.2l-0.2,0.2L318.1,249.2z"/>
<path className="st16" d="M316.9,250.4l-0.4,0.2L316.9,250.4z M316.7,250.6l0.2-0.2l-0.4,0.2H316.7z"/>
<path className="st17" d="M316.9,250.4l-0.4,0.2L316.9,250.4z M316.7,250.6l0.2-0.2l-0.4,0.2H316.7z"/>
<path className="st16" d="M318.1,249.5l-0.2-0.1l0.2-0.2V249.5z M317.6,250.1l0.5-0.6l-0.2-0.1L317.6,250.1z"/>
<path className="st17" d="M318.1,249.5l-0.2-0.1l0.2-0.2V249.5z M317.6,250.1l0.5-0.6l-0.2-0.1L317.6,250.1z"/>
<path className="st16" d="M317.6,250.1l-1,0.5l0.2-0.2L317.6,250.1z M317,250.6l0.6-0.5l-1,0.5H317z"/>
<path className="st17" d="M317.6,250.1l-1,0.5l0.2-0.2L317.6,250.1z M317,250.6l0.6-0.5l-1,0.5H317z"/>
<path className="st16" d="M318.1,249.8l-1.1,0.8l1.1-1.1V249.8z M317.2,250.6l1-0.8l-1.1,0.8H317.2z M317.3,250.6h-0.1l1-0.8
	L317.3,250.6z"/>
<path className="st17" d="M318.1,249.8l-1.1,0.8l1.1-1.1V249.8z M317.2,250.6l1-0.8l-1.1,0.8H317.2z M317.3,250.6h-0.1l1-0.8
	L317.3,250.6z"/>
<path className="st16" d="M317.2,250.6l1-0.8L317.2,250.6z"/>
<path className="st17" d="M317.2,250.6l1-0.8L317.2,250.6z"/>
<path className="st16" d="M317.4,250.5l0.7-0.6l-0.4,0.5L317.4,250.5z M317.3,250.6l0.1-0.1l0.7-0.6L317.3,250.6z"/>
<path className="st17" d="M317.4,250.5l0.7-0.6l-0.4,0.5L317.4,250.5z M317.3,250.6l0.1-0.1l0.7-0.6L317.3,250.6z"/>
<polygon className="st16" points="317.3,250.6 318.1,249.8 318.1,249.9 "/>
<polygon className="st17" points="317.3,250.6 318.1,249.8 318.1,249.9 "/>
<path className="st19" d="M315.7,246.5C315.7,246.5,315.6,246.5,315.7,246.5"/>
<line className="st19" x1="315.7" y1="246.5" x2="314" y2="248.2"/>
<path className="st19" d="M314.1,248.1C314,248.1,314,248.2,314.1,248.1"/>
<line className="st19" x1="314" y1="248.3" x2="316.1" y2="250.2"/>
<path className="st19" d="M316,250.2c0.5,0.5,1.3,0.5,1.8,0s0.5-1.3,0-1.8"/>
<line className="st19" x1="317.8" y1="248.6" x2="315.8" y2="246.5"/>
<path className="st19" d="M316.4,248.6C316.4,248.6,316.4,248.6,316.4,248.6"/>
<line className="st19" x1="316.6" y1="248.7" x2="316.7" y2="248.8"/>
<path className="st19" d="M316.3,249.1c0.2,0,0.4-0.2,0.4-0.4"/>
<line className="st19" x1="316.3" y1="249.2" x2="316.2" y2="249"/>
<path className="st19" d="M316.1,248.8C316.1,248.8,316.1,248.9,316.1,248.8"/>
<line className="st19" x1="316.2" y1="248.9" x2="316.4" y2="248.7"/>
<line className="st19" x1="316" y1="249.8" x2="316.3" y2="250.1"/>
<path className="st19" d="M316.2,250c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3"/>
<line className="st19" x1="317.6" y1="248.8" x2="317.2" y2="248.4"/>
<path className="st19" d="M317.2,248.4c-0.4-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.4,0.9,0,1.2"/>
<path className="st5" d="M324.3,253.2c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="324.6,245.1 324.4,244.8 320.2,249 320.4,249.3 324.6,245.1 "/>
<line className="st1" x1="309.7" y1="252.5" x2="316.2" y2="246"/>
<path className="st16" d="M313.7,250.1l0.2,6.6l3.2-3.2L313.7,250.1z M310.4,253.4l3.2-3.2l0.2,6.6L310.4,253.4z"/>
<path className="st17" d="M313.7,250.1l0.2,6.6l3.2-3.2L313.7,250.1z M310.4,253.4l3.2-3.2l0.2,6.6L310.4,253.4z"/>
<path className="st16" d="M313.7,250l-2.6,2.8l2.6-2.6V250z M311.2,252.5l2.5-2.5l-2.6,2.8L311.2,252.5z"/>
<path className="st17" d="M313.7,250l-2.6,2.8l2.6-2.6V250z M311.2,252.5l2.5-2.5l-2.6,2.8L311.2,252.5z"/>
<path className="st16" d="M310.9,252.8l-0.5,0.6l0.6-0.6H310.9z M310.9,252.8L310.9,252.8l-0.5,0.6L310.9,252.8z"/>
<path className="st17" d="M310.9,252.8l-0.5,0.6l0.6-0.6H310.9z M310.9,252.8L310.9,252.8l-0.5,0.6L310.9,252.8z"/>
<path className="st16" d="M313.6,250l-2.4,2.5l2.5-2.5H313.6z M311,252.4l2.5-2.4l-2.4,2.5L311,252.4z"/>
<path className="st17" d="M313.6,250l-2.4,2.5l2.5-2.5H313.6z M311,252.4l2.5-2.4l-2.4,2.5L311,252.4z"/>
<path className="st16" d="M310.8,252.8l-0.4,0.6l0.5-0.6H310.8z M310.3,253.2l0.5-0.5l-0.4,0.6L310.3,253.2z"/>
<path className="st17" d="M310.8,252.8l-0.4,0.6l0.5-0.6H310.8z M310.3,253.2l0.5-0.5l-0.4,0.6L310.3,253.2z"/>
<path className="st16" d="M313.4,249.9l-2.4,2.5l2.5-2.4L313.4,249.9z M310.9,252.4l2.5-2.5l-2.4,2.5H310.9z"/>
<path className="st17" d="M313.4,249.9l-2.4,2.5l2.5-2.4L313.4,249.9z M310.9,252.4l2.5-2.5l-2.4,2.5H310.9z"/>
<path className="st16" d="M310.8,252.5l-0.5,0.7l0.5-0.5V252.5z M310.2,253.1l0.6-0.6l-0.5,0.7L310.2,253.1z"/>
<path className="st17" d="M310.8,252.5l-0.5,0.7l0.5-0.5V252.5z M310.2,253.1l0.6-0.6l-0.5,0.7L310.2,253.1z"/>
<path className="st16" d="M313.4,249.8l-2.5,2.6l2.5-2.5V249.8z M310.8,252.4l2.6-2.6l-2.5,2.6H310.8z"/>
<path className="st17" d="M313.4,249.8l-2.5,2.6l2.5-2.5V249.8z M310.8,252.4l2.6-2.6l-2.5,2.6H310.8z"/>
<path className="st16" d="M310.8,252.4l-0.6,0.7l0.6-0.6V252.4z M310.8,252.4L310.8,252.4l-0.6,0.7L310.8,252.4z"/>
<path className="st17" d="M310.8,252.4l-0.6,0.7l0.6-0.6V252.4z M310.8,252.4L310.8,252.4l-0.6,0.7L310.8,252.4z"/>
<path className="st16" d="M313,249.3l-2.8,3.8l3.2-3.4L313,249.3z M309.7,252.5l3.2-3.2l-2.8,3.8L309.7,252.5z"/>
<path className="st17" d="M313,249.3l-2.8,3.8l3.2-3.4L313,249.3z M309.7,252.5l3.2-3.2l-2.8,3.8L309.7,252.5z"/>
<line className="st18" x1="311.3" y1="252.5" x2="317.2" y2="253.5"/>
<polyline className="st18" points="309.7,252.5 313,249.3 317.2,253.5 313.9,256.7 309.7,252.5 "/>
<line className="st18" x1="313.9" y1="256.7" x2="311.2" y2="252.9"/>
<path className="st18" d="M310.7,252.3c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C310.9,252.3,310.8,252.3,310.7,252.3"/>
<line className="st18" x1="311.2" y1="252.3" x2="313" y2="249.3"/>
<path className="st16" d="M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z M348.1,278.9l-1.7,1.7
	L348.1,278.9z M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z M348.1,278.9l-1.7,1.7
	L348.1,278.9z M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z"/>
<path className="st17" d="M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z M348.1,278.9l-1.7,1.7
	L348.1,278.9z M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z M348.1,278.9l-1.7,1.7
	L348.1,278.9z M348.1,278.9L348.1,278.9l-1.7,1.7L348.1,278.9z M346.4,280.6l1.7-1.7L346.4,280.6z"/>
<path className="st16" d="M347,277.8l-0.6,2.8l1.7-1.7L347,277.8z M345.4,279.5l1.7-1.7l-0.6,2.8L345.4,279.5z"/>
<path className="st17" d="M347,277.8l-0.6,2.8l1.7-1.7L347,277.8z M345.4,279.5l1.7-1.7l-0.6,2.8L345.4,279.5z"/>
<path className="st16" d="M346.9,277.6l-0.7,1.1l0.8-0.8L346.9,277.6z M346.4,278.1l0.5-0.5l-0.7,1.1L346.4,278.1z"/>
<path className="st17" d="M346.9,277.6l-0.7,1.1l0.8-0.8L346.9,277.6z M346.4,278.1l0.5-0.5l-0.7,1.1L346.4,278.1z"/>
<path className="st16" d="M345.6,278.9l-0.2,0.6l0.8-0.8L345.6,278.9z M345.1,279.4l0.5-0.5l-0.2,0.6L345.1,279.4z"/>
<path className="st17" d="M345.6,278.9l-0.2,0.6l0.8-0.8L345.6,278.9z M345.1,279.4l0.5-0.5l-0.2,0.6L345.1,279.4z"/>
<path className="st16" d="M346.9,277.6L346.9,277.6l-0.5,0.5L346.9,277.6z M346.4,278.1l0.5-0.5L346.4,278.1z"/>
<path className="st17" d="M346.9,277.6L346.9,277.6l-0.5,0.5L346.9,277.6z M346.4,278.1l0.5-0.5L346.4,278.1z"/>
<path className="st16" d="M346.1,278.2l-0.4,0.4l0.4-0.2V278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z M345.7,278.6l0.4-0.4
	L345.7,278.6z M346.1,278.2l-0.4,0.4L346.1,278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z M345.7,278.6l0.4-0.4
	L345.7,278.6z M346.1,278.2l-0.4,0.4L346.1,278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z"/>
<path className="st17" d="M346.1,278.2l-0.4,0.4l0.4-0.2V278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z M345.7,278.6l0.4-0.4
	L345.7,278.6z M346.1,278.2l-0.4,0.4L346.1,278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z M345.7,278.6l0.4-0.4
	L345.7,278.6z M346.1,278.2l-0.4,0.4L346.1,278.2z M346.1,278.2L346.1,278.2l-0.4,0.4L346.1,278.2z"/>
<path className="st16" d="M345.5,278.3l0.4-0.4l-0.2,0.1L345.5,278.3z M345.7,278.6l-0.2-0.2l0.4-0.4L345.7,278.6z"/>
<path className="st17" d="M345.5,278.3l0.4-0.4l-0.2,0.1L345.5,278.3z M345.7,278.6l-0.2-0.2l0.4-0.4L345.7,278.6z"/>
<polygon className="st16" points="345.7,278.6 346.1,278.2 345.8,278 "/>
<polygon className="st17" points="345.7,278.6 346.1,278.2 345.8,278 "/>
<path className="st16" d="M345,278.7l0.1,0.7l0.5-0.5L345,278.7z M344.8,278.9l0.2-0.2l0.1,0.7L344.8,278.9z"/>
<path className="st17" d="M345,278.7l0.1,0.7l0.5-0.5L345,278.7z M344.8,278.9l0.2-0.2l0.1,0.7L344.8,278.9z"/>
<path className="st16" d="M346.4,277.2v0.8l0.5-0.5L346.4,277.2z M346.2,277.5l0.2-0.2v0.8L346.2,277.5z"/>
<path className="st17" d="M346.4,277.2v0.8l0.5-0.5L346.4,277.2z M346.2,277.5l0.2-0.2v0.8L346.2,277.5z"/>
<path className="st16" d="M346.1,276.9l0.1,0.6l0.2-0.2L346.1,276.9z M346,277.1l0.1-0.2l0.1,0.6L346,277.1z"/>
<path className="st17" d="M346.1,276.9l0.1,0.6l0.2-0.2L346.1,276.9z M346,277.1l0.1-0.2l0.1,0.6L346,277.1z"/>
<path className="st16" d="M344.6,278.4l0.1,0.5l0.2-0.2L344.6,278.4z M344.4,278.6l0.2-0.1l0.1,0.5L344.4,278.6z"/>
<path className="st17" d="M344.6,278.4l0.1,0.5l0.2-0.2L344.6,278.4z M344.4,278.6l0.2-0.1l0.1,0.5L344.4,278.6z"/>
<path className="st16" d="M344.4,277.8v0.7l0.2-0.1L344.4,277.8z M344,278.1l0.4-0.2v0.7L344,278.1z"/>
<path className="st17" d="M344.4,277.8v0.7l0.2-0.1L344.4,277.8z M344,278.1l0.4-0.2v0.7L344,278.1z"/>
<path className="st16" d="M345.6,276.5l0.4,0.6l0.1-0.2L345.6,276.5z M345.2,276.9l0.4-0.4l0.4,0.6L345.2,276.9z"/>
<path className="st17" d="M345.6,276.5l0.4,0.6l0.1-0.2L345.6,276.5z M345.2,276.9l0.4-0.4l0.4,0.6L345.2,276.9z"/>
<path className="st16" d="M345.5,276.5l-0.2,0.4l0.4-0.4H345.5z M345.2,276.8l0.2-0.2l-0.2,0.4V276.8z"/>
<path className="st17" d="M345.5,276.5l-0.2,0.4l0.4-0.4H345.5z M345.2,276.8l0.2-0.2l-0.2,0.4V276.8z"/>
<path className="st16" d="M344.3,277.7l-0.2,0.4l0.4-0.2L344.3,277.7z M344,278l0.2-0.2l-0.2,0.4V278z"/>
<path className="st17" d="M344.3,277.7l-0.2,0.4l0.4-0.2L344.3,277.7z M344,278l0.2-0.2l-0.2,0.4V278z"/>
<path className="st16" d="M345.1,276.5l0.1,0.2l0.2-0.2H345.1z M344.6,277.1l0.5-0.6l0.1,0.2L344.6,277.1z"/>
<path className="st17" d="M345.1,276.5l0.1,0.2l0.2-0.2H345.1z M344.6,277.1l0.5-0.6l0.1,0.2L344.6,277.1z"/>
<path className="st16" d="M344.6,277.1L344,278l0.2-0.2L344.6,277.1z M344,277.6l0.6-0.5L344,278V277.6z"/>
<path className="st17" d="M344.6,277.1L344,278l0.2-0.2L344.6,277.1z M344,277.6l0.6-0.5L344,278V277.6z"/>
<path className="st16" d="M345,276.5l-1,1.1l1.1-1.1H345z M344,277.5l1-1l-1,1.1V277.5z"/>
<path className="st17" d="M345,276.5l-1,1.1l1.1-1.1H345z M344,277.5l1-1l-1,1.1V277.5z"/>
<path className="st16" d="M344.9,276.5l-0.8,1l1-1H344.9z M344.2,277.4l0.7-0.8l-0.8,1L344.2,277.4z"/>
<path className="st17" d="M344.9,276.5l-0.8,1l1-1H344.9z M344.2,277.4l0.7-0.8l-0.8,1L344.2,277.4z"/>
<path className="st16" d="M344.2,277.2l0.7-0.6l-0.6,0.2L344.2,277.2z M344.2,277.4v-0.1l0.7-0.6L344.2,277.4z"/>
<path className="st17" d="M344.2,277.2l0.7-0.6l-0.6,0.2L344.2,277.2z M344.2,277.4v-0.1l0.7-0.6L344.2,277.4z"/>
<path className="st16" d="M344.2,277.4l0.7-0.7L344.2,277.4z"/>
<path className="st17" d="M344.2,277.4l0.7-0.7L344.2,277.4z"/>
<path className="st19" d="M348.1,278.9C348.1,278.9,348.1,278.8,348.1,278.9"/>
<line className="st19" x1="348.1" y1="278.9" x2="346.4" y2="280.6"/>
<path className="st19" d="M346.3,280.6C346.4,280.6,346.4,280.6,346.3,280.6"/>
<line className="st19" x1="346.4" y1="280.6" x2="344.4" y2="278.6"/>
<path className="st19" d="M346.1,276.8c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8"/>
<line className="st19" x1="346.1" y1="276.9" x2="348.1" y2="278.9"/>
<path className="st19" d="M345.9,278.2C346,278.2,346,278.1,345.9,278.2"/>
<line className="st19" x1="346.1" y1="278.2" x2="345.8" y2="278"/>
<path className="st19" d="M345.8,277.8c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="345.5" y1="278.3" x2="345.7" y2="278.6"/>
<path className="st19" d="M345.6,278.4C345.6,278.4,345.7,278.4,345.6,278.4"/>
<line className="st19" x1="345.7" y1="278.6" x2="346.1" y2="278.3"/>
<line className="st19" x1="345" y1="278.7" x2="344.6" y2="278.4"/>
<path className="st19" d="M345.8,277c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="346" y1="277.1" x2="346.2" y2="277.5"/>
<path className="st19" d="M345,278.7c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<path className="st5" d="M349.5,270c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="349.6,270.2 349.8,270.3 345.6,274.5 345.4,274.4 349.6,270.2 "/>
<line className="st1" x1="342.1" y1="285" x2="348.6" y2="278.4"/>
<path className="st16" d="M344.6,281l-6.7-0.1l3.2-3.4L344.6,281z M341.3,284.2l3.4-3.2l-6.7-0.1L341.3,284.2z"/>
<path className="st17" d="M344.6,281l-6.7-0.1l3.2-3.4L344.6,281z M341.3,284.2l3.4-3.2l-6.7-0.1L341.3,284.2z"/>
<path className="st16" d="M344.6,281l-2.6,2.6L344.6,281z M342.1,283.5l2.5-2.5l-2.6,2.6L342.1,283.5z"/>
<path className="st17" d="M344.6,281l-2.6,2.6L344.6,281z M342.1,283.5l2.5-2.5l-2.6,2.6L342.1,283.5z"/>
<path className="st16" d="M341.9,283.7l-0.6,0.5l0.7-0.6L341.9,283.7z M341.4,284.3l0.5-0.6l-0.6,0.5L341.4,284.3z"/>
<path className="st17" d="M341.9,283.7l-0.6,0.5l0.7-0.6L341.9,283.7z M341.4,284.3l0.5-0.6l-0.6,0.5L341.4,284.3z"/>
<path className="st16" d="M344.8,281.1l-2.6,2.4l2.5-2.5L344.8,281.1z M342.2,283.6l2.5-2.5l-2.6,2.4L342.2,283.6z"/>
<path className="st17" d="M344.8,281.1l-2.6,2.4l2.5-2.5L344.8,281.1z M342.2,283.6l2.5-2.5l-2.6,2.4L342.2,283.6z"/>
<path className="st16" d="M342,283.8l-0.6,0.5l0.5-0.6L342,283.8z M341.5,284.3l0.5-0.5l-0.6,0.5H341.5z"/>
<path className="st17" d="M342,283.8l-0.6,0.5l0.5-0.6L342,283.8z M341.5,284.3l0.5-0.5l-0.6,0.5H341.5z"/>
<path className="st16" d="M344.9,281.2l-2.6,2.4l2.5-2.5L344.9,281.2z M342.2,283.7l2.6-2.5l-2.6,2.4V283.7z"/>
<path className="st17" d="M344.9,281.2l-2.6,2.4l2.5-2.5L344.9,281.2z M342.2,283.7l2.6-2.5l-2.6,2.4V283.7z"/>
<path className="st16" d="M342.1,284l-0.6,0.4l0.5-0.5L342.1,284z M341.5,284.4l0.6-0.5l-0.6,0.4V284.4z"/>
<path className="st17" d="M342.1,284l-0.6,0.4l0.5-0.5L342.1,284z M341.5,284.4l0.6-0.5l-0.6,0.4V284.4z"/>
<path className="st16" d="M344.9,281.2l-2.6,2.5L344.9,281.2z M342.2,283.8l2.6-2.6l-2.6,2.5V283.8z"/>
<path className="st17" d="M344.9,281.2l-2.6,2.5L344.9,281.2z M342.2,283.8l2.6-2.6l-2.6,2.5V283.8z"/>
<path className="st16" d="M342.2,283.8l-0.7,0.6l0.6-0.5L342.2,283.8z M341.6,284.4l0.6-0.6l-0.7,0.6H341.6z"/>
<path className="st17" d="M342.2,283.8l-0.7,0.6l0.6-0.5L342.2,283.8z M341.6,284.4l0.6-0.6l-0.7,0.6H341.6z"/>
<path className="st16" d="M345.4,281.7l-3.7,2.8l3.2-3.2L345.4,281.7z M342.1,285l3.2-3.4l-3.7,2.8L342.1,285z"/>
<path className="st17" d="M345.4,281.7l-3.7,2.8l3.2-3.2L345.4,281.7z M342.1,285l3.2-3.4l-3.7,2.8L342.1,285z"/>
<line className="st18" x1="342.1" y1="283.4" x2="341.2" y2="277.5"/>
<polyline className="st18" points="342.1,285 345.4,281.7 341.2,277.5 337.9,280.8 342.1,285 "/>
<line className="st18" x1="337.9" y1="280.8" x2="341.9" y2="283.5"/>
<path className="st18" d="M341.9,283.5c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C342.1,283.5,342,283.5,341.9,283.5"/>
<line className="st18" x1="342.4" y1="283.5" x2="345.4" y2="281.7"/>
<path className="st16" d="M350.8,281.6l-1.7,1.6l1.6-1.6H350.8z M349.1,283.2l1.7-1.7l-1.7,1.6V283.2z M350.8,281.6l-1.7,1.7
	L350.8,281.6z M350.8,281.6L350.8,281.6l-1.7,1.7L350.8,281.6z M349.1,283.2l1.7-1.7L349.1,283.2z M350.8,281.6l-1.7,1.7
	L350.8,281.6z M350.8,281.6L350.8,281.6l-1.7,1.7L350.8,281.6z M349.1,283.2l1.7-1.7L349.1,283.2z"/>
<path className="st17" d="M350.8,281.6l-1.7,1.6l1.6-1.6H350.8z M349.1,283.2l1.7-1.7l-1.7,1.6V283.2z M350.8,281.6l-1.7,1.7
	L350.8,281.6z M350.8,281.6L350.8,281.6l-1.7,1.7L350.8,281.6z M349.1,283.2l1.7-1.7L349.1,283.2z M350.8,281.6l-1.7,1.7
	L350.8,281.6z M350.8,281.6L350.8,281.6l-1.7,1.7L350.8,281.6z M349.1,283.2l1.7-1.7L349.1,283.2z"/>
<path className="st16" d="M351.8,282.6l-2.8,0.6l1.7-1.7L351.8,282.6z M350.2,284.3l1.7-1.7l-2.8,0.6L350.2,284.3z"/>
<path className="st17" d="M351.8,282.6l-2.8,0.6l1.7-1.7L351.8,282.6z M350.2,284.3l1.7-1.7l-2.8,0.6L350.2,284.3z"/>
<path className="st16" d="M352,282.8l-1,0.7l0.8-0.8L352,282.8z M351.6,283.2l0.4-0.5l-1,0.7L351.6,283.2z"/>
<path className="st17" d="M352,282.8l-1,0.7l0.8-0.8L352,282.8z M351.6,283.2l0.4-0.5l-1,0.7L351.6,283.2z"/>
<path className="st16" d="M350.8,284.1l-0.6,0.2l0.8-0.8L350.8,284.1z M350.3,284.6l0.5-0.5l-0.6,0.2L350.3,284.6z"/>
<path className="st17" d="M350.8,284.1l-0.6,0.2l0.8-0.8L350.8,284.1z M350.3,284.6l0.5-0.5l-0.6,0.2L350.3,284.6z"/>
<path className="st16" d="M352,282.8L352,282.8l-0.4,0.5L352,282.8z M351.6,283.2l0.4-0.5L351.6,283.2z"/>
<path className="st17" d="M352,282.8L352,282.8l-0.4,0.5L352,282.8z M351.6,283.2l0.4-0.5L351.6,283.2z"/>
<path className="st16" d="M351.5,283.6l-0.4,0.2l0.2-0.2H351.5z M351.1,284l0.4-0.4l-0.4,0.2V284z M351.5,283.6l-0.4,0.4L351.5,283.6z
	 M351.5,283.6L351.5,283.6l-0.4,0.4L351.5,283.6z M351.1,284l0.4-0.4L351.1,284z M351.5,283.6l-0.4,0.4L351.5,283.6z M351.5,283.6
	L351.5,283.6l-0.4,0.4L351.5,283.6z M351.1,284l0.4-0.4L351.1,284z"/>
<path className="st17" d="M351.5,283.6l-0.4,0.2l0.2-0.2H351.5z M351.1,284l0.4-0.4l-0.4,0.2V284z M351.5,283.6l-0.4,0.4L351.5,283.6z
	 M351.5,283.6L351.5,283.6l-0.4,0.4L351.5,283.6z M351.1,284l0.4-0.4L351.1,284z M351.5,283.6l-0.4,0.4L351.5,283.6z M351.5,283.6
	L351.5,283.6l-0.4,0.4L351.5,283.6z M351.1,284l0.4-0.4L351.1,284z"/>
<path className="st16" d="M351.4,284.2l0.4-0.4l-0.1,0.2L351.4,284.2z M351.1,284l0.2,0.2l0.4-0.4L351.1,284z"/>
<path className="st17" d="M351.4,284.2l0.4-0.4l-0.1,0.2L351.4,284.2z M351.1,284l0.2,0.2l0.4-0.4L351.1,284z"/>
<polygon className="st16" points="351.1,284 351.5,283.6 351.7,283.8 "/>
<polygon className="st17" points="351.1,284 351.5,283.6 351.7,283.8 "/>
<path className="st16" d="M351,284.7l-0.7-0.1l0.5-0.5L351,284.7z M350.8,284.9l0.2-0.2l-0.7-0.1L350.8,284.9z"/>
<path className="st17" d="M351,284.7l-0.7-0.1l0.5-0.5L351,284.7z M350.8,284.9l0.2-0.2l-0.7-0.1L350.8,284.9z"/>
<path className="st16" d="M352.4,283.2h-0.8l0.4-0.5L352.4,283.2z M352.2,283.5l0.2-0.2h-0.8L352.2,283.5z"/>
<path className="st17" d="M352.4,283.2h-0.8l0.4-0.5L352.4,283.2z M352.2,283.5l0.2-0.2h-0.8L352.2,283.5z"/>
<path className="st16" d="M352.8,283.6l-0.6-0.1l0.2-0.2L352.8,283.6z M352.6,283.7l0.2-0.1l-0.6-0.1L352.6,283.7z"/>
<path className="st17" d="M352.8,283.6l-0.6-0.1l0.2-0.2L352.8,283.6z M352.6,283.7l0.2-0.1l-0.6-0.1L352.6,283.7z"/>
<path className="st16" d="M351.2,285l-0.5-0.1l0.2-0.2L351.2,285z M351,285.3l0.2-0.2l-0.5-0.1L351,285.3z"/>
<path className="st17" d="M351.2,285l-0.5-0.1l0.2-0.2L351.2,285z M351,285.3l0.2-0.2l-0.5-0.1L351,285.3z"/>
<path className="st16" d="M351.8,285.3H351l0.2-0.2L351.8,285.3z M351.6,285.6l0.2-0.4H351L351.6,285.6z"/>
<path className="st17" d="M351.8,285.3H351l0.2-0.2L351.8,285.3z M351.6,285.6l0.2-0.4H351L351.6,285.6z"/>
<path className="st16" d="M353.2,284.1l-0.6-0.4l0.2-0.1L353.2,284.1z M352.8,284.3l0.4-0.2l-0.6-0.4L352.8,284.3z"/>
<path className="st17" d="M353.2,284.1l-0.6-0.4l0.2-0.1L353.2,284.1z M352.8,284.3l0.4-0.2l-0.6-0.4L352.8,284.3z"/>
<path className="st16" d="M353.2,284.2l-0.4,0.1l0.4-0.2V284.2z M352.8,284.4l0.4-0.2l-0.4,0.1V284.4z"/>
<path className="st17" d="M353.2,284.2l-0.4,0.1l0.4-0.2V284.2z M352.8,284.4l0.4-0.2l-0.4,0.1V284.4z"/>
<path className="st16" d="M352,285.3l-0.4,0.4l0.2-0.4H352z M351.7,285.6l0.2-0.4l-0.4,0.4H351.7z"/>
<path className="st17" d="M352,285.3l-0.4,0.4l0.2-0.4H352z M351.7,285.6l0.2-0.4l-0.4,0.4H351.7z"/>
<path className="st16" d="M353,284.6l-0.2-0.1l0.4-0.2L353,284.6z M352.6,285l0.5-0.5l-0.2-0.1L352.6,285z"/>
<path className="st17" d="M353,284.6l-0.2-0.1l0.4-0.2L353,284.6z M352.6,285l0.5-0.5l-0.2-0.1L352.6,285z"/>
<path className="st16" d="M352.6,285l-0.8,0.6l0.2-0.4L352.6,285z M352.1,285.6l0.5-0.6l-0.8,0.6H352.1z"/>
<path className="st17" d="M352.6,285l-0.8,0.6l0.2-0.4L352.6,285z M352.1,285.6l0.5-0.6l-0.8,0.6H352.1z"/>
<path className="st16" d="M353,284.7l-1,1l1-1.1V284.7z M352.2,285.6l0.8-1l-1,1H352.2z"/>
<path className="st17" d="M353,284.7l-1,1l1-1.1V284.7z M352.2,285.6l0.8-1l-1,1H352.2z"/>
<path className="st16" d="M353,284.8l-0.8,0.8l0.8-1V284.8z M352.3,285.5l0.7-0.7l-0.8,0.8L352.3,285.5z"/>
<path className="st17" d="M353,284.8l-0.8,0.8l0.8-1V284.8z M352.3,285.5l0.7-0.7l-0.8,0.8L352.3,285.5z"/>
<path className="st16" d="M352.4,285.5l0.6-0.7l-0.4,0.5L352.4,285.5z M352.3,285.5h0.1l0.6-0.7L352.3,285.5z"/>
<path className="st17" d="M352.4,285.5l0.6-0.7l-0.4,0.5L352.4,285.5z M352.3,285.5h0.1l0.6-0.7L352.3,285.5z"/>
<path className="st16" d="M352.3,285.5l0.7-0.7L352.3,285.5z"/>
<path className="st17" d="M352.3,285.5l0.7-0.7L352.3,285.5z"/>
<path className="st19" d="M350.7,281.5C350.7,281.4,350.7,281.4,350.7,281.5"/>
<line className="st19" x1="350.6" y1="281.6" x2="349.1" y2="283.1"/>
<path className="st19" d="M349,283.1C349,283.2,349,283.2,349,283.1"/>
<line className="st19" x1="349.1" y1="283.2" x2="351" y2="285.3"/>
<path className="st19" d="M351,285.3c0.5,0.5,1.3,0.5,1.8,0s0.5-1.3,0-1.8"/>
<line className="st19" x1="352.8" y1="283.6" x2="350.8" y2="281.6"/>
<path className="st19" d="M351.5,283.6C351.4,283.6,351.4,283.6,351.5,283.6"/>
<line className="st19" x1="351.5" y1="283.6" x2="351.7" y2="283.8"/>
<path className="st19" d="M351.3,284.2c0.2,0,0.4-0.2,0.4-0.4"/>
<line className="st19" x1="351.4" y1="284.2" x2="351.1" y2="284"/>
<path className="st19" d="M351,283.9C351,283.9,351,283.9,351,283.9"/>
<line className="st19" x1="351.1" y1="283.8" x2="351.4" y2="283.6"/>
<line className="st19" x1="351" y1="284.7" x2="351.2" y2="285"/>
<path className="st19" d="M351.3,285c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3"/>
<line className="st19" x1="352.6" y1="283.7" x2="352.2" y2="283.5"/>
<path className="st19" d="M352.1,283.3c-0.4-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.4,0.9,0,1.2"/>
<path className="st5" d="M359.3,288.2c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="359.5,280 359.4,279.9 355.2,284.1 355.3,284.2 359.5,280 "/>
<line className="st1" x1="344.6" y1="287.6" x2="351.2" y2="281.1"/>
<path className="st16" d="M348.7,285l0.1,6.7l3.2-3.2L348.7,285z M345.5,288.3l3.2-3.2l0.1,6.7L345.5,288.3z"/>
<path className="st17" d="M348.7,285l0.1,6.7l3.2-3.2L348.7,285z M345.5,288.3l3.2-3.2l0.1,6.7L345.5,288.3z"/>
<path className="st16" d="M348.7,285l-2.6,2.6L348.7,285z M346.1,287.6l2.6-2.5l-2.6,2.6V287.6z"/>
<path className="st17" d="M348.7,285l-2.6,2.6L348.7,285z M346.1,287.6l2.6-2.5l-2.6,2.6V287.6z"/>
<path className="st16" d="M346,287.8l-0.5,0.5l0.6-0.6L346,287.8z M345.4,288.3l0.6-0.5l-0.5,0.5H345.4z"/>
<path className="st17" d="M346,287.8l-0.5,0.5l0.6-0.6L346,287.8z M345.4,288.3l0.6-0.5l-0.5,0.5H345.4z"/>
<path className="st16" d="M348.6,284.9l-2.5,2.6l2.6-2.5L348.6,284.9z M346.1,287.4l2.5-2.5l-2.5,2.6V287.4z"/>
<path className="st17" d="M348.6,284.9l-2.5,2.6l2.6-2.5L348.6,284.9z M346.1,287.4l2.5-2.5l-2.5,2.6V287.4z"/>
<path className="st16" d="M345.8,287.7l-0.5,0.6l0.6-0.5L345.8,287.7z M345.4,288.2l0.5-0.5l-0.5,0.6V288.2z"/>
<path className="st17" d="M345.8,287.7l-0.5,0.6l0.6-0.5L345.8,287.7z M345.4,288.2l0.5-0.5l-0.5,0.6V288.2z"/>
<path className="st16" d="M348.5,284.8l-2.4,2.6l2.5-2.5L348.5,284.8z M346,287.3l2.5-2.5l-2.4,2.6L346,287.3z"/>
<path className="st17" d="M348.5,284.8l-2.4,2.6l2.5-2.5L348.5,284.8z M346,287.3l2.5-2.5l-2.4,2.6L346,287.3z"/>
<path className="st16" d="M345.7,287.6l-0.4,0.6l0.5-0.5L345.7,287.6z M345.2,288l0.5-0.5l-0.4,0.6L345.2,288z"/>
<path className="st17" d="M345.7,287.6l-0.4,0.6l0.5-0.5L345.7,287.6z M345.2,288l0.5-0.5l-0.4,0.6L345.2,288z"/>
<path className="st16" d="M348.5,284.8l-2.5,2.5L348.5,284.8z M345.8,287.4l2.6-2.6l-2.5,2.5L345.8,287.4z"/>
<path className="st17" d="M348.5,284.8l-2.5,2.5L348.5,284.8z M345.8,287.4l2.6-2.6l-2.5,2.5L345.8,287.4z"/>
<path className="st16" d="M345.8,287.4l-0.6,0.6l0.5-0.5L345.8,287.4z M345.1,288l0.7-0.6l-0.6,0.6H345.1z"/>
<path className="st17" d="M345.8,287.4l-0.6,0.6l0.5-0.5L345.8,287.4z M345.1,288l0.7-0.6l-0.6,0.6H345.1z"/>
<path className="st16" d="M347.9,284.3l-2.8,3.7l3.4-3.2L347.9,284.3z M344.6,287.6l3.2-3.2l-2.8,3.7L344.6,287.6z"/>
<path className="st17" d="M347.9,284.3l-2.8,3.7l3.4-3.2L347.9,284.3z M344.6,287.6l3.2-3.2l-2.8,3.7L344.6,287.6z"/>
<line className="st18" x1="346.3" y1="287.6" x2="352.1" y2="288.5"/>
<polyline className="st18" points="344.6,287.6 347.9,284.3 352.1,288.5 348.8,291.8 344.6,287.6 "/>
<line className="st18" x1="348.8" y1="291.8" x2="346.1" y2="287.8"/>
<path className="st18" d="M345.8,287.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C346,287.3,345.8,287.3,345.8,287.4"/>
<line className="st18" x1="346.1" y1="287.3" x2="347.9" y2="284.3"/>
<path className="st5" d="M384.6,305.1c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="384.6,305.1 384.8,305.3 380.6,309.5 380.4,309.3 384.6,305.1 "/>
<line className="st1" x1="377" y1="320" x2="383.6" y2="313.5"/>
<path className="st16" d="M379.6,316l-6.7-0.2l3.4-3.2L379.6,316z M376.3,319.2l3.2-3.2l-6.7-0.2L376.3,319.2z"/>
<path className="st17" d="M379.6,316l-6.7-0.2l3.4-3.2L379.6,316z M376.3,319.2l3.2-3.2l-6.7-0.2L376.3,319.2z"/>
<path className="st16" d="M379.6,316l-2.6,2.5L379.6,316z M377,318.5l2.5-2.5l-2.6,2.5H377z"/>
<path className="st17" d="M379.6,316l-2.6,2.5L379.6,316z M377,318.5l2.5-2.5l-2.6,2.5H377z"/>
<path className="st16" d="M376.9,318.8l-0.6,0.5l0.6-0.7V318.8z M376.9,318.8L376.9,318.8l-0.6,0.5L376.9,318.8z"/>
<path className="st17" d="M376.9,318.8l-0.6,0.5l0.6-0.7V318.8z M376.9,318.8L376.9,318.8l-0.6,0.5L376.9,318.8z"/>
<path className="st16" d="M379.7,316.1l-2.6,2.4l2.5-2.5L379.7,316.1z M377.3,318.5l2.4-2.4l-2.6,2.4H377.3z"/>
<path className="st17" d="M379.7,316.1l-2.6,2.4l2.5-2.5L379.7,316.1z M377.3,318.5l2.4-2.4l-2.6,2.4H377.3z"/>
<path className="st16" d="M376.9,318.9l-0.6,0.4l0.6-0.5V318.9z M376.4,319.4l0.5-0.5l-0.6,0.4L376.4,319.4z"/>
<path className="st17" d="M376.9,318.9l-0.6,0.4l0.6-0.5V318.9z M376.4,319.4l0.5-0.5l-0.6,0.4L376.4,319.4z"/>
<path className="st16" d="M379.8,316.2l-2.5,2.3l2.4-2.4L379.8,316.2z M377.3,318.8l2.5-2.5l-2.5,2.3V318.8z"/>
<path className="st17" d="M379.8,316.2l-2.5,2.3l2.4-2.4L379.8,316.2z M377.3,318.8l2.5-2.5l-2.5,2.3V318.8z"/>
<path className="st16" d="M377,318.9l-0.6,0.5l0.5-0.5H377z M376.6,319.5l0.5-0.6l-0.6,0.5L376.6,319.5z"/>
<path className="st17" d="M377,318.9l-0.6,0.5l0.5-0.5H377z M376.6,319.5l0.5-0.6l-0.6,0.5L376.6,319.5z"/>
<path className="st16" d="M379.8,316.2l-2.5,2.5L379.8,316.2z M377.3,318.9l2.5-2.6l-2.5,2.5V318.9z"/>
<path className="st17" d="M379.8,316.2l-2.5,2.5L379.8,316.2z M377.3,318.9l2.5-2.6l-2.5,2.5V318.9z"/>
<path className="st16" d="M377.3,318.9l-0.7,0.6l0.5-0.6H377.3z M377.3,318.9L377.3,318.9l-0.7,0.6L377.3,318.9z"/>
<path className="st17" d="M377.3,318.9l-0.7,0.6l0.5-0.6H377.3z M377.3,318.9L377.3,318.9l-0.7,0.6L377.3,318.9z"/>
<path className="st16" d="M380.4,316.7l-3.8,2.8l3.2-3.2L380.4,316.7z M377,320l3.4-3.2l-3.8,2.8L377,320z"/>
<path className="st17" d="M380.4,316.7l-3.8,2.8l3.2-3.2L380.4,316.7z M377,320l3.4-3.2l-3.8,2.8L377,320z"/>
<line className="st18" x1="377" y1="318.4" x2="376.2" y2="312.5"/>
<polyline className="st18" points="377,320 380.4,316.7 376.2,312.5 372.8,315.8 377,320 "/>
<line className="st18" x1="372.8" y1="315.8" x2="376.8" y2="318.5"/>
<path className="st18" d="M376.9,318.6c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C377,318.5,376.9,318.5,376.9,318.6"/>
<line className="st18" x1="377.4" y1="318.5" x2="380.4" y2="316.7"/>
<path className="st16" d="M384,318.2l1.7-1.7L384,318.2z M385.7,316.5l-1.7,1.7L385.7,316.5z M385.7,316.5L385.7,316.5l-1.7,1.7
	L385.7,316.5z M384,318.2l1.7-1.7L384,318.2z"/>
<path className="st17" d="M384,318.2l1.7-1.7L384,318.2z M385.7,316.5l-1.7,1.7L385.7,316.5z M385.7,316.5L385.7,316.5l-1.7,1.7
	L385.7,316.5z M384,318.2l1.7-1.7L384,318.2z"/>
<path className="st16" d="M385.8,316.5l-1.8,1.7l1.7-1.7H385.8z M384,318.3l1.8-1.8l-1.8,1.7V318.3z M385.8,316.5l-1.8,1.8L385.8,316.5z
	 M385.8,316.5L385.8,316.5l-1.8,1.8L385.8,316.5z"/>
<path className="st17" d="M385.8,316.5l-1.8,1.7l1.7-1.7H385.8z M384,318.3l1.8-1.8l-1.8,1.7V318.3z M385.8,316.5l-1.8,1.8L385.8,316.5z
	 M385.8,316.5L385.8,316.5l-1.8,1.8L385.8,316.5z"/>
<path className="st16" d="M386.9,317.6l-2.9,0.7l1.8-1.8L386.9,317.6z M385.1,319.4l1.8-1.8l-2.9,0.7L385.1,319.4z"/>
<path className="st17" d="M386.9,317.6l-2.9,0.7l1.8-1.8L386.9,317.6z M385.1,319.4l1.8-1.8l-2.9,0.7L385.1,319.4z"/>
<path className="st16" d="M387,317.8l-1.1,0.7l1-1L387,317.8z M386.5,318.2l0.5-0.4l-1.1,0.7L386.5,318.2z"/>
<path className="st17" d="M387,317.8l-1.1,0.7l1-1L387,317.8z M386.5,318.2l0.5-0.4l-1.1,0.7L386.5,318.2z"/>
<path className="st16" d="M385.7,319.1l-0.6,0.2l0.8-0.8L385.7,319.1z M385.3,319.5l0.4-0.4l-0.6,0.2L385.3,319.5z"/>
<path className="st17" d="M385.7,319.1l-0.6,0.2l0.8-0.8L385.7,319.1z M385.3,319.5l0.4-0.4l-0.6,0.2L385.3,319.5z"/>
<path className="st16" d="M387,317.8L387,317.8l-0.5,0.4L387,317.8z M386.5,318.2l0.5-0.4L386.5,318.2z"/>
<path className="st17" d="M387,317.8L387,317.8l-0.5,0.4L387,317.8z M386.5,318.2l0.5-0.4L386.5,318.2z"/>
<path className="st16" d="M386.4,318.6l-0.2,0.2L386.4,318.6z M386,318.9l0.4-0.2l-0.2,0.2H386z M386.4,318.6l-0.4,0.2L386.4,318.6z
	 M386.4,318.6L386.4,318.6l-0.4,0.2L386.4,318.6z"/>
<path className="st17" d="M386.4,318.6l-0.2,0.2L386.4,318.6z M386,318.9l0.4-0.2l-0.2,0.2H386z M386.4,318.6l-0.4,0.2L386.4,318.6z
	 M386.4,318.6L386.4,318.6l-0.4,0.2L386.4,318.6z"/>
<path className="st16" d="M386.5,318.6l-0.5,0.2l0.4-0.2H386.5z M386.2,319l0.4-0.4l-0.5,0.2L386.2,319z M386.5,318.6l-0.4,0.4
	L386.5,318.6z M386.5,318.6L386.5,318.6l-0.4,0.4L386.5,318.6z"/>
<path className="st17" d="M386.5,318.6l-0.5,0.2l0.4-0.2H386.5z M386.2,319l0.4-0.4l-0.5,0.2L386.2,319z M386.5,318.6l-0.4,0.4
	L386.5,318.6z M386.5,318.6L386.5,318.6l-0.4,0.4L386.5,318.6z"/>
<path className="st16" d="M386.3,319.1l0.4-0.4l-0.1,0.2L386.3,319.1z M386.2,319l0.1,0.1l0.4-0.4L386.2,319z"/>
<path className="st17" d="M386.3,319.1l0.4-0.4l-0.1,0.2L386.3,319.1z M386.2,319l0.1,0.1l0.4-0.4L386.2,319z"/>
<polygon className="st16" points="386.2,319 386.5,318.6 386.6,318.8 "/>
<polygon className="st17" points="386.2,319 386.5,318.6 386.6,318.8 "/>
<path className="st16" d="M385.9,319.7l-0.6-0.2l0.4-0.4L385.9,319.7z M385.7,320l0.2-0.2l-0.6-0.2L385.7,320z"/>
<path className="st17" d="M385.9,319.7l-0.6-0.2l0.4-0.4L385.9,319.7z M385.7,320l0.2-0.2l-0.6-0.2L385.7,320z"/>
<path className="st16" d="M387.5,318.2h-1l0.5-0.4L387.5,318.2z M387.2,318.4l0.2-0.2h-1L387.2,318.4z"/>
<path className="st17" d="M387.5,318.2h-1l0.5-0.4L387.5,318.2z M387.2,318.4l0.2-0.2h-1L387.2,318.4z"/>
<path className="st16" d="M387.7,318.5l-0.5-0.1l0.2-0.2L387.7,318.5z M387.6,318.8l0.1-0.2l-0.5-0.1L387.6,318.8z"/>
<path className="st17" d="M387.7,318.5l-0.5-0.1l0.2-0.2L387.7,318.5z M387.6,318.8l0.1-0.2l-0.5-0.1L387.6,318.8z"/>
<path className="st16" d="M386.3,320.1l-0.6-0.1l0.2-0.2L386.3,320.1z M386,320.3l0.2-0.2l-0.6-0.1L386,320.3z"/>
<path className="st17" d="M386.3,320.1l-0.6-0.1l0.2-0.2L386.3,320.1z M386,320.3l0.2-0.2l-0.6-0.1L386,320.3z"/>
<path className="st16" d="M386.9,320.3H386l0.2-0.2L386.9,320.3z M386.5,320.6l0.4-0.2H386L386.5,320.6z"/>
<path className="st17" d="M386.9,320.3H386l0.2-0.2L386.9,320.3z M386.5,320.6l0.4-0.2H386L386.5,320.6z"/>
<path className="st16" d="M388.1,319.1l-0.5-0.4l0.1-0.2L388.1,319.1z M387.8,319.4l0.2-0.2l-0.5-0.4L387.8,319.4z M388.1,319.1
	l-0.2,0.2L388.1,319.1z M388.1,319.1L388.1,319.1l-0.2,0.2L388.1,319.1z"/>
<path className="st17" d="M388.1,319.1l-0.5-0.4l0.1-0.2L388.1,319.1z M387.8,319.4l0.2-0.2l-0.5-0.4L387.8,319.4z M388.1,319.1
	l-0.2,0.2L388.1,319.1z M388.1,319.1L388.1,319.1l-0.2,0.2L388.1,319.1z"/>
<path className="st16" d="M386.9,320.3l-0.4,0.2L386.9,320.3z M386.6,320.6l0.2-0.2l-0.4,0.2H386.6z"/>
<path className="st17" d="M386.9,320.3l-0.4,0.2L386.9,320.3z M386.6,320.6l0.2-0.2l-0.4,0.2H386.6z"/>
<path className="st16" d="M388.1,319.5l-0.2-0.1l0.2-0.2V319.5z M387.6,320.1l0.5-0.6l-0.2-0.1L387.6,320.1z"/>
<path className="st17" d="M388.1,319.5l-0.2-0.1l0.2-0.2V319.5z M387.6,320.1l0.5-0.6l-0.2-0.1L387.6,320.1z"/>
<path className="st16" d="M387.6,320.1l-1,0.5l0.2-0.2L387.6,320.1z M387,320.6l0.6-0.5l-1,0.5H387z"/>
<path className="st17" d="M387.6,320.1l-1,0.5l0.2-0.2L387.6,320.1z M387,320.6l0.6-0.5l-1,0.5H387z"/>
<path className="st16" d="M388.1,319.7l-1.1,0.8l1.1-1.1V319.7z M387.1,320.6l1-0.8l-1.1,0.8H387.1z M387.2,320.6h-0.1l1-0.8
	L387.2,320.6z"/>
<path className="st17" d="M388.1,319.7l-1.1,0.8l1.1-1.1V319.7z M387.1,320.6l1-0.8l-1.1,0.8H387.1z M387.2,320.6h-0.1l1-0.8
	L387.2,320.6z"/>
<path className="st16" d="M387.1,320.6l1-0.8L387.1,320.6z"/>
<path className="st17" d="M387.1,320.6l1-0.8L387.1,320.6z"/>
<path className="st16" d="M387.4,320.4l0.7-0.6l-0.4,0.5L387.4,320.4z M387.2,320.6l0.1-0.1l0.7-0.6L387.2,320.6z"/>
<path className="st17" d="M387.4,320.4l0.7-0.6l-0.4,0.5L387.4,320.4z M387.2,320.6l0.1-0.1l0.7-0.6L387.2,320.6z"/>
<path className="st16" d="M387.2,320.6l0.8-0.7L387.2,320.6z"/>
<path className="st17" d="M387.2,320.6l0.8-0.7L387.2,320.6z"/>
<path className="st19" d="M385.7,316.5C385.6,316.5,385.6,316.5,385.7,316.5"/>
<line className="st19" x1="385.7" y1="316.5" x2="384" y2="318.2"/>
<path className="st19" d="M384,318.1C384,318.1,384,318.1,384,318.1"/>
<line className="st19" x1="384" y1="318.3" x2="386" y2="320.3"/>
<path className="st19" d="M385.9,320.2c0.5,0.5,1.3,0.5,1.8,0s0.5-1.3,0-1.8"/>
<line className="st19" x1="387.7" y1="318.5" x2="385.8" y2="316.5"/>
<path className="st19" d="M386.4,318.5C386.4,318.5,386.3,318.5,386.4,318.5"/>
<line className="st19" x1="386.5" y1="318.6" x2="386.6" y2="318.8"/>
<path className="st19" d="M386.2,319.1c0.2,0,0.4-0.2,0.4-0.4"/>
<line className="st19" x1="386.3" y1="319.1" x2="386.2" y2="319"/>
<path className="st19" d="M386.1,318.8C386,318.8,386,318.8,386.1,318.8"/>
<line className="st19" x1="386.2" y1="318.9" x2="386.4" y2="318.6"/>
<line className="st19" x1="385.9" y1="319.7" x2="386.3" y2="320.1"/>
<path className="st19" d="M386.2,319.9c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3"/>
<line className="st19" x1="387.6" y1="318.8" x2="387.2" y2="318.4"/>
<path className="st19" d="M387.2,318.4c-0.4-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.4,0.9,0,1.2"/>
<path className="st5" d="M394.3,323.1c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="394.6,315 394.3,314.8 390.1,319 390.4,319.2 394.6,315 "/>
<line className="st1" x1="379.7" y1="322.6" x2="386.2" y2="316"/>
<path className="st16" d="M383.6,320.1l0.2,6.7l3.2-3.4L383.6,320.1z M380.4,323.3l3.2-3.2l0.2,6.7L380.4,323.3z"/>
<path className="st17" d="M383.6,320.1l0.2,6.7l3.2-3.4L383.6,320.1z M380.4,323.3l3.2-3.2l0.2,6.7L380.4,323.3z"/>
<path className="st16" d="M383.6,320l-2.5,2.8l2.5-2.6V320z M381.1,322.6l2.5-2.6l-2.5,2.8V322.6z"/>
<path className="st17" d="M383.6,320l-2.5,2.8l2.5-2.6V320z M381.1,322.6l2.5-2.6l-2.5,2.8V322.6z"/>
<path className="st16" d="M380.9,322.7l-0.5,0.6l0.7-0.6H380.9z M380.9,322.7L380.9,322.7l-0.5,0.6L380.9,322.7z"/>
<path className="st17" d="M380.9,322.7l-0.5,0.6l0.7-0.6H380.9z M380.9,322.7L380.9,322.7l-0.5,0.6L380.9,322.7z"/>
<path className="st16" d="M383.5,320l-2.4,2.6l2.5-2.6H383.5z M381.1,322.4l2.4-2.4l-2.4,2.6V322.4z"/>
<path className="st17" d="M383.5,320l-2.4,2.6l2.5-2.6H383.5z M381.1,322.4l2.4-2.4l-2.4,2.6V322.4z"/>
<path className="st16" d="M380.8,322.7l-0.4,0.6l0.5-0.6H380.8z M380.3,323.2l0.5-0.5l-0.4,0.6L380.3,323.2z"/>
<path className="st17" d="M380.8,322.7l-0.4,0.6l0.5-0.6H380.8z M380.3,323.2l0.5-0.5l-0.4,0.6L380.3,323.2z"/>
<path className="st16" d="M383.4,319.8l-2.3,2.5l2.4-2.4L383.4,319.8z M380.9,322.4l2.5-2.5l-2.3,2.5H380.9z"/>
<path className="st17" d="M383.4,319.8l-2.3,2.5l2.4-2.4L383.4,319.8z M380.9,322.4l2.5-2.5l-2.3,2.5H380.9z"/>
<path className="st16" d="M380.8,322.6l-0.5,0.6l0.5-0.5V322.6z M380.2,323.1l0.6-0.5l-0.5,0.6L380.2,323.1z"/>
<path className="st17" d="M380.8,322.6l-0.5,0.6l0.5-0.5V322.6z M380.2,323.1l0.6-0.5l-0.5,0.6L380.2,323.1z"/>
<path className="st16" d="M383.4,319.8l-2.5,2.5L383.4,319.8z M380.8,322.4l2.6-2.5l-2.5,2.5H380.8z"/>
<path className="st17" d="M383.4,319.8l-2.5,2.5L383.4,319.8z M380.8,322.4l2.6-2.5l-2.5,2.5H380.8z"/>
<path className="st16" d="M380.8,322.4l-0.6,0.7l0.6-0.5V322.4z M380.8,322.4L380.8,322.4l-0.6,0.7L380.8,322.4z"/>
<path className="st17" d="M380.8,322.4l-0.6,0.7l0.6-0.5V322.4z M380.8,322.4L380.8,322.4l-0.6,0.7L380.8,322.4z"/>
<path className="st16" d="M382.9,319.2l-2.8,3.8l3.2-3.2L382.9,319.2z M379.7,322.6l3.2-3.4l-2.8,3.8L379.7,322.6z"/>
<path className="st17" d="M382.9,319.2l-2.8,3.8l3.2-3.2L382.9,319.2z M379.7,322.6l3.2-3.4l-2.8,3.8L379.7,322.6z"/>
<line className="st18" x1="381.2" y1="322.6" x2="387.1" y2="323.4"/>
<polyline className="st18" points="379.7,322.6 382.9,319.2 387.1,323.4 383.9,326.8 379.7,322.6 "/>
<line className="st18" x1="383.9" y1="326.8" x2="381.1" y2="322.8"/>
<path className="st18" d="M380.7,322.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C380.9,322.3,380.8,322.3,380.7,322.4"/>
<line className="st18" x1="381.1" y1="322.2" x2="382.9" y2="319.2"/>
<path className="st16" d="M375.6,401.7l-6.7,0.1l3.4,3.4L375.6,401.7z M372.4,398.4l3.2,3.2l-6.7,0.1L372.4,398.4z"/>
<path className="st17" d="M375.6,401.7l-6.7,0.1l3.4,3.4L375.6,401.7z M372.4,398.4l3.2,3.2l-6.7,0.1L372.4,398.4z"/>
<path className="st16" d="M375.6,401.7L373,399L375.6,401.7z M373.1,399.2l2.5,2.5L373,399L373.1,399.2z"/>
<path className="st17" d="M375.6,401.7L373,399L375.6,401.7z M373.1,399.2l2.5,2.5L373,399L373.1,399.2z"/>
<path className="st16" d="M373,398.9l-0.6-0.5l0.6,0.6V398.9z M373,398.9L373,398.9l-0.6-0.5L373,398.9z"/>
<path className="st17" d="M373,398.9l-0.6-0.5l0.6,0.6V398.9z M373,398.9L373,398.9l-0.6-0.5L373,398.9z"/>
<path className="st16" d="M375.7,401.6l-2.6-2.4l2.5,2.5L375.7,401.6z M373.3,399l2.4,2.5l-2.6-2.4L373.3,399z"/>
<path className="st17" d="M375.7,401.6l-2.6-2.4l2.5,2.5L375.7,401.6z M373.3,399l2.4,2.5l-2.6-2.4L373.3,399z"/>
<path className="st16" d="M373,398.8l-0.6-0.4l0.6,0.5V398.8z M372.5,398.3l0.5,0.5l-0.6-0.4L372.5,398.3z"/>
<path className="st17" d="M373,398.8l-0.6-0.4l0.6,0.5V398.8z M372.5,398.3l0.5,0.5l-0.6-0.4L372.5,398.3z"/>
<path className="st16" d="M375.8,401.4l-2.5-2.4l2.4,2.5L375.8,401.4z M373.3,398.9l2.5,2.5l-2.5-2.4V398.9z"/>
<path className="st17" d="M375.8,401.4l-2.5-2.4l2.4,2.5L375.8,401.4z M373.3,398.9l2.5,2.5l-2.5-2.4V398.9z"/>
<path className="st16" d="M373.1,398.7l-0.6-0.4l0.5,0.5L373.1,398.7z M372.6,398.2l0.5,0.5l-0.6-0.4L372.6,398.2z"/>
<path className="st17" d="M373.1,398.7l-0.6-0.4l0.5,0.5L373.1,398.7z M372.6,398.2l0.5,0.5l-0.6-0.4L372.6,398.2z"/>
<path className="st16" d="M375.8,401.4l-2.5-2.5L375.8,401.4z M373.3,398.8l2.5,2.6l-2.5-2.5V398.8z"/>
<path className="st17" d="M375.8,401.4l-2.5-2.5L375.8,401.4z M373.3,398.8l2.5,2.6l-2.5-2.5V398.8z"/>
<path className="st16" d="M373.3,398.8l-0.7-0.6l0.5,0.5L373.3,398.8z M373.3,398.8L373.3,398.8l-0.7-0.6L373.3,398.8z"/>
<path className="st17" d="M373.3,398.8l-0.7-0.6l0.5,0.5L373.3,398.8z M373.3,398.8L373.3,398.8l-0.7-0.6L373.3,398.8z"/>
<path className="st16" d="M376.4,401l-3.8-2.8l3.2,3.2L376.4,401z M373.1,397.6l3.4,3.4l-3.8-2.8L373.1,397.6z"/>
<path className="st17" d="M376.4,401l-3.8-2.8l3.2,3.2L376.4,401z M373.1,397.6l3.4,3.4l-3.8-2.8L373.1,397.6z"/>
<line className="st18" x1="373.1" y1="399.3" x2="372.2" y2="405.2"/>
<polyline className="st18" points="373.1,397.6 376.4,401 372.2,405.2 368.9,401.8 373.1,397.6 "/>
<line className="st18" x1="368.9" y1="401.8" x2="372.8" y2="399.2"/>
<path className="st18" d="M373.1,398.7c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C373.2,398.9,373.2,398.8,373.1,398.7"/>
<line className="st18" x1="373.4" y1="399.2" x2="376.4" y2="401"/>
<path className="st16" d="M380,411l-1.7,1.9l1.4-1.4L380,411z M377.9,413.2l2.2-2.2l-1.7,1.9L377.9,413.2z M380,411l-2.2,2.2L380,411z
	 M380,411L380,411l-2.2,2.2L380,411z"/>
<path className="st17" d="M380,411l-1.7,1.9l1.4-1.4L380,411z M377.9,413.2l2.2-2.2l-1.7,1.9L377.9,413.2z M380,411l-2.2,2.2L380,411z
	 M380,411L380,411l-2.2,2.2L380,411z"/>
<path className="st16" d="M380,410.8l-2.2,2.4l2.2-2.2V410.8z M377.6,413.2l2.4-2.4l-2.2,2.4H377.6z"/>
<path className="st17" d="M380,410.8l-2.2,2.4l2.2-2.2V410.8z M377.6,413.2l2.4-2.4l-2.2,2.4H377.6z"/>
<path className="st16" d="M380,410.7l-0.5,0.6l0.5-0.5V410.7z M379.7,411l0.4-0.4l-0.5,0.6L379.7,411z"/>
<path className="st17" d="M380,410.7l-0.5,0.6l0.5-0.5V410.7z M379.7,411l0.4-0.4l-0.5,0.6L379.7,411z"/>
<path className="st16" d="M379.7,411l-1.6,1.7l1.4-1.4L379.7,411z M377.9,412.8l1.8-1.8l-1.6,1.7L377.9,412.8z"/>
<path className="st17" d="M379.7,411l-1.6,1.7l1.4-1.4L379.7,411z M377.9,412.8l1.8-1.8l-1.6,1.7L377.9,412.8z"/>
<path className="st16" d="M380,410.6l-0.4,0.5l0.4-0.4V410.6z M379.7,410.9l0.4-0.4l-0.4,0.5V410.9z"/>
<path className="st17" d="M380,410.6l-0.4,0.5l0.4-0.4V410.6z M379.7,410.9l0.4-0.4l-0.4,0.5V410.9z"/>
<path className="st16" d="M379.7,410.9l-1.8,1.9l1.8-1.8V410.9z M377.8,412.8l1.9-1.9l-1.8,1.9H377.8z"/>
<path className="st17" d="M379.7,410.9l-1.8,1.9l1.8-1.8V410.9z M377.8,412.8l1.9-1.9l-1.8,1.9H377.8z"/>
<path className="st16" d="M380,410.6l-0.4,0.4L380,410.6z M379.7,410.8l0.4-0.2l-0.4,0.4V410.8z"/>
<path className="st17" d="M380,410.6l-0.4,0.4L380,410.6z M379.7,410.8l0.4-0.2l-0.4,0.4V410.8z"/>
<path className="st16" d="M379.7,410.8l-1.9,2l1.9-1.9V410.8z M377.6,412.8l2-2l-1.9,2H377.6z"/>
<path className="st17" d="M379.7,410.8l-1.9,2l1.9-1.9V410.8z M377.6,412.8l2-2l-1.9,2H377.6z"/>
<path className="st16" d="M380,410.6L380,410.6l-0.4,0.2L380,410.6z M379.7,410.8l0.4-0.2L379.7,410.8z"/>
<path className="st17" d="M380,410.6L380,410.6l-0.4,0.2L380,410.6z M379.7,410.8l0.4-0.2L379.7,410.8z"/>
<path className="st16" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st17" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st16" d="M380,410.6L380,410.6l-0.4,0.2L380,410.6z M379.7,410.8l0.4-0.2L379.7,410.8z"/>
<path className="st17" d="M380,410.6L380,410.6l-0.4,0.2L380,410.6z M379.7,410.8l0.4-0.2L379.7,410.8z"/>
<path className="st16" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st17" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st16" d="M377.6,412.8v0.4l0.5-0.5L377.6,412.8z M377.4,413.2l0.2-0.4v0.4H377.4z"/>
<path className="st17" d="M377.6,412.8v0.4l0.5-0.5L377.6,412.8z M377.4,413.2l0.2-0.4v0.4H377.4z"/>
<path className="st16" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st17" d="M379.7,410.8L379.7,410.8l-2,2L379.7,410.8z M377.6,412.8l2-2L377.6,412.8z"/>
<path className="st16" d="M377.6,412.8l-0.2,0.4L377.6,412.8z M377.3,413.2l0.4-0.4l-0.2,0.4H377.3z"/>
<path className="st17" d="M377.6,412.8l-0.2,0.4L377.6,412.8z M377.3,413.2l0.4-0.4l-0.2,0.4H377.3z"/>
<polygon className="st16" points="380.3,409.7 379.8,410.2 380.3,409.6 "/>
<polygon className="st17" points="380.3,409.7 379.8,410.2 380.3,409.6 "/>
<path className="st16" d="M379.6,410.4l-1.9,2.4l2-2L379.6,410.4z M377.3,412.7l2.3-2.3l-1.9,2.4L377.3,412.7z"/>
<path className="st17" d="M379.6,410.4l-1.9,2.4l2-2L379.6,410.4z M377.3,412.7l2.3-2.3l-1.9,2.4L377.3,412.7z"/>
<path className="st16" d="M377.3,412.7v0.5l0.4-0.4L377.3,412.7z M377,413l0.2-0.2v0.5L377,413z"/>
<path className="st17" d="M377.3,412.7v0.5l0.4-0.4L377.3,412.7z M377,413l0.2-0.2v0.5L377,413z"/>
<path className="st16" d="M380.3,409.6l-0.5,0.6L380.3,409.6z M379.8,410.1l0.5-0.5l-0.5,0.6V410.1z"/>
<path className="st17" d="M380.3,409.6l-0.5,0.6L380.3,409.6z M379.8,410.1l0.5-0.5l-0.5,0.6V410.1z"/>
<path className="st16" d="M380.3,409.5l-0.5,0.6l0.5-0.5V409.5z M380.3,409.5L380.3,409.5l-0.5,0.6L380.3,409.5z"/>
<path className="st17" d="M380.3,409.5l-0.5,0.6l0.5-0.5V409.5z M380.3,409.5L380.3,409.5l-0.5,0.6L380.3,409.5z"/>
<path className="st16" d="M379.8,410.1l-0.1,0.7l0.4-0.2L379.8,410.1z M379.6,410.3l0.2-0.2l-0.1,0.7L379.6,410.3z"/>
<path className="st17" d="M379.8,410.1l-0.1,0.7l0.4-0.2L379.8,410.1z M379.6,410.3l0.2-0.2l-0.1,0.7L379.6,410.3z"/>
<path className="st16" d="M380.3,409.5L380.3,409.5l-0.5,0.6L380.3,409.5z M379.8,410.1l0.5-0.6L379.8,410.1z"/>
<path className="st17" d="M380.3,409.5L380.3,409.5l-0.5,0.6L380.3,409.5z M379.8,410.1l0.5-0.6L379.8,410.1z"/>
<path className="st16" d="M379.8,410.1L379.8,410.1l-0.2,0.2L379.8,410.1z M379.6,410.3l0.2-0.2L379.6,410.3z"/>
<path className="st17" d="M379.8,410.1L379.8,410.1l-0.2,0.2L379.8,410.1z M379.6,410.3l0.2-0.2L379.6,410.3z"/>
<path className="st16" d="M379.6,410.3l-2.3,2.4l2.3-2.3V410.3z M377.2,412.7l2.4-2.4l-2.3,2.4H377.2z"/>
<path className="st17" d="M379.6,410.3l-2.3,2.4l2.3-2.3V410.3z M377.2,412.7l2.4-2.4l-2.3,2.4H377.2z"/>
<path className="st16" d="M380.4,409.5l-0.6,0.6l0.5-0.6H380.4z M379.7,410.1l0.7-0.6l-0.6,0.6H379.7z"/>
<path className="st17" d="M380.4,409.5l-0.6,0.6l0.5-0.6H380.4z M379.7,410.1l0.7-0.6l-0.6,0.6H379.7z"/>
<path className="st16" d="M379.7,410.1l-0.1,0.2l0.2-0.2H379.7z M379.4,410.3l0.2-0.2l-0.1,0.2H379.4z"/>
<path className="st17" d="M379.7,410.1l-0.1,0.2l0.2-0.2H379.7z M379.4,410.3l0.2-0.2l-0.1,0.2H379.4z"/>
<path className="st16" d="M379.4,410.3l-2.3,2.4l2.4-2.4H379.4z M377.2,412.6l2.3-2.3l-2.3,2.4V412.6z"/>
<path className="st17" d="M379.4,410.3l-2.3,2.4l2.4-2.4H379.4z M377.2,412.6l2.3-2.3l-2.3,2.4V412.6z"/>
<path className="st16" d="M377.2,412.6L377,413l0.2-0.2L377.2,412.6z M376.9,412.8l0.2-0.2L377,413L376.9,412.8z"/>
<path className="st17" d="M377.2,412.6L377,413l0.2-0.2L377.2,412.6z M376.9,412.8l0.2-0.2L377,413L376.9,412.8z"/>
<path className="st16" d="M380.4,409.4l-0.7,0.7l0.7-0.6V409.4z M380.4,409.4L380.4,409.4l-0.7,0.7L380.4,409.4z"/>
<path className="st17" d="M380.4,409.4l-0.7,0.7l0.7-0.6V409.4z M380.4,409.4L380.4,409.4l-0.7,0.7L380.4,409.4z"/>
<path className="st16" d="M379.7,410.1L379.7,410.1l-0.2,0.2L379.7,410.1z M379.4,410.3l0.2-0.2L379.4,410.3z"/>
<path className="st17" d="M379.7,410.1L379.7,410.1l-0.2,0.2L379.7,410.1z M379.4,410.3l0.2-0.2L379.4,410.3z"/>
<path className="st16" d="M379.4,410.3L379.4,410.3l-2.3,2.3L379.4,410.3z M377.2,412.6l2.3-2.3L377.2,412.6z"/>
<path className="st17" d="M379.4,410.3L379.4,410.3l-2.3,2.3L379.4,410.3z M377.2,412.6l2.3-2.3L377.2,412.6z"/>
<path className="st16" d="M377.2,412.6L377.2,412.6l-0.2,0.2L377.2,412.6z M376.9,412.8l0.2-0.2L376.9,412.8z"/>
<path className="st17" d="M377.2,412.6L377.2,412.6l-0.2,0.2L377.2,412.6z M376.9,412.8l0.2-0.2L376.9,412.8z"/>
<path className="st16" d="M380.4,409.2l-0.7,0.8l0.7-0.7V409.2z M379.7,410l0.7-0.7l-0.7,0.8V410z"/>
<path className="st17" d="M380.4,409.2l-0.7,0.8l0.7-0.7V409.2z M379.7,410l0.7-0.7l-0.7,0.8V410z"/>
<path className="st16" d="M379.7,410l-0.2,0.4l0.2-0.2V410z M379.4,410.2l0.2-0.2l-0.2,0.4V410.2z"/>
<path className="st17" d="M379.7,410l-0.2,0.4l0.2-0.2V410z M379.4,410.2l0.2-0.2l-0.2,0.4V410.2z"/>
<path className="st16" d="M379.4,410.2l-2.3,2.4l2.3-2.3V410.2z M377,412.6l2.4-2.4l-2.3,2.4H377z"/>
<path className="st17" d="M379.4,410.2l-2.3,2.4l2.3-2.3V410.2z M377,412.6l2.4-2.4l-2.3,2.4H377z"/>
<path className="st16" d="M377,412.6l-0.1,0.2l0.2-0.2H377z M376.8,412.8l0.2-0.2l-0.1,0.2H376.8z"/>
<path className="st17" d="M377,412.6l-0.1,0.2l0.2-0.2H377z M376.8,412.8l0.2-0.2l-0.1,0.2H376.8z"/>
<path className="st16" d="M380.4,409.1l-0.7,0.8l0.7-0.7V409.1z M379.6,410l0.8-0.8l-0.7,0.8H379.6z"/>
<path className="st17" d="M380.4,409.1l-0.7,0.8l0.7-0.7V409.1z M379.6,410l0.8-0.8l-0.7,0.8H379.6z"/>
<path className="st16" d="M379.6,410l-0.1,0.2l0.2-0.2H379.6z M379.3,410.2l0.2-0.2l-0.1,0.2H379.3z"/>
<path className="st17" d="M379.6,410l-0.1,0.2l0.2-0.2H379.6z M379.3,410.2l0.2-0.2l-0.1,0.2H379.3z"/>
<path className="st16" d="M379.3,410.2l-1.1,1.2l1.2-1.2H379.3z M378.2,411.3l1.1-1.1l-1.1,1.2V411.3z"/>
<path className="st17" d="M379.3,410.2l-1.1,1.2l1.2-1.2H379.3z M378.2,411.3l1.1-1.1l-1.1,1.2V411.3z"/>
<path className="st16" d="M378.1,411.5l-1.1,1.1l1.2-1.2L378.1,411.5z M377,412.5l1.1-1l-1.1,1.1V412.5z"/>
<path className="st17" d="M378.1,411.5l-1.1,1.1l1.2-1.2L378.1,411.5z M377,412.5l1.1-1l-1.1,1.1V412.5z"/>
<path className="st16" d="M377,412.5l-0.2,0.4l0.2-0.2V412.5z M376.8,412.7l0.2-0.2l-0.2,0.4V412.7z"/>
<path className="st17" d="M377,412.5l-0.2,0.4l0.2-0.2V412.5z M376.8,412.7l0.2-0.2l-0.2,0.4V412.7z"/>
<path className="st16" d="M380.4,409.1L380.4,409.1l-0.8,0.8L380.4,409.1z M379.6,410l0.8-0.8L379.6,410z"/>
<path className="st17" d="M380.4,409.1L380.4,409.1l-0.8,0.8L380.4,409.1z M379.6,410l0.8-0.8L379.6,410z"/>
<path className="st16" d="M379.6,410L379.6,410l-0.2,0.2L379.6,410z M379.3,410.2l0.2-0.2L379.3,410.2z"/>
<path className="st17" d="M379.6,410L379.6,410l-0.2,0.2L379.6,410z M379.3,410.2l0.2-0.2L379.3,410.2z"/>
<path className="st16" d="M380.4,409l-0.8,1l0.8-0.8V409z M379.6,409.8l0.8-0.8l-0.8,1V409.8z"/>
<path className="st17" d="M380.4,409l-0.8,1l0.8-0.8V409z M379.6,409.8l0.8-0.8l-0.8,1V409.8z"/>
<path className="st16" d="M379.6,409.8l-0.2,0.4l0.2-0.2V409.8z M379.3,410.1l0.2-0.2l-0.2,0.4V410.1z"/>
<path className="st17" d="M379.6,409.8l-0.2,0.4l0.2-0.2V409.8z M379.3,410.1l0.2-0.2l-0.2,0.4V410.1z"/>
<path className="st16" d="M379.3,410.1l-1.1,1.2l1.1-1.1V410.1z M378.2,411.2l1.1-1.1l-1.1,1.2V411.2z"/>
<path className="st17" d="M379.3,410.1l-1.1,1.2l1.1-1.1V410.1z M378.2,411.2l1.1-1.1l-1.1,1.2V411.2z"/>
<path className="st16" d="M378,411.4l-1,1.1l1.1-1L378,411.4z M376.9,412.5l1.1-1.1l-1,1.1H376.9z"/>
<path className="st17" d="M378,411.4l-1,1.1l1.1-1L378,411.4z M376.9,412.5l1.1-1.1l-1,1.1H376.9z"/>
<path className="st16" d="M379.2,410l-1,1.2l1.1-1.1L379.2,410z M378.1,411l1.1-1.1l-1,1.2L378.1,411z"/>
<path className="st17" d="M379.2,410l-1,1.2l1.1-1.1L379.2,410z M378.1,411l1.1-1.1l-1,1.2L378.1,411z"/>
<path className="st16" d="M377.9,411.3l-1,1.2l1.1-1.1L377.9,411.3z M376.8,412.4l1.1-1.1l-1,1.2L376.8,412.4z"/>
<path className="st17" d="M377.9,411.3l-1,1.2l1.1-1.1L377.9,411.3z M376.8,412.4l1.1-1.1l-1,1.2L376.8,412.4z"/>
<path className="st16" d="M379.1,410l-1,1.1l1.1-1.1H379.1z M378,411.2l1.1-1.2l-1,1.1L378,411.2z"/>
<path className="st17" d="M379.1,410l-1,1.1l1.1-1.1H379.1z M378,411.2l1.1-1.2l-1,1.1L378,411.2z"/>
<path className="st16" d="M378,411.2l-1.2,1.2l1.1-1.1L378,411.2z M376.8,412.2l1.2-1.1l-1.2,1.2V412.2z"/>
<path className="st17" d="M378,411.2l-1.2,1.2l1.1-1.1L378,411.2z M376.8,412.2l1.2-1.1l-1.2,1.2V412.2z"/>
<path className="st16" d="M380.2,408.8l-0.6,1.1l0.8-0.8L380.2,408.8z M379.3,409.6l0.8-0.8l-0.6,1.1L379.3,409.6z"/>
<path className="st17" d="M380.2,408.8l-0.6,1.1l0.8-0.8L380.2,408.8z M379.3,409.6l0.8-0.8l-0.6,1.1L379.3,409.6z"/>
<path className="st16" d="M379.3,409.6v0.5l0.2-0.2L379.3,409.6z M379.1,409.8l0.2-0.2v0.5L379.1,409.8z"/>
<path className="st17" d="M379.3,409.6v0.5l0.2-0.2L379.3,409.6z M379.1,409.8l0.2-0.2v0.5L379.1,409.8z"/>
<path className="st16" d="M379.1,409.8l-1.1,1.3l1.1-1.2V409.8z M377.9,411l1.2-1.2l-1.1,1.3L377.9,411z"/>
<path className="st17" d="M379.1,409.8l-1.1,1.3l1.1-1.2V409.8z M377.9,411l1.2-1.2l-1.1,1.3L377.9,411z"/>
<path className="st16" d="M377.9,411l-1.1,1.2l1.2-1.1L377.9,411z M376.7,412.2l1.2-1.2l-1.1,1.2H376.7z"/>
<path className="st17" d="M377.9,411l-1.1,1.2l1.2-1.1L377.9,411z M376.7,412.2l1.2-1.2l-1.1,1.2H376.7z"/>
<path className="st16" d="M380,408.5l-0.7,1.1l0.8-0.8L380,408.5z M379.1,409.5l1-1l-0.7,1.1L379.1,409.5z"/>
<path className="st17" d="M380,408.5l-0.7,1.1l0.8-0.8L380,408.5z M379.1,409.5l1-1l-0.7,1.1L379.1,409.5z"/>
<path className="st16" d="M379.1,409.5v0.4l0.2-0.2L379.1,409.5z M378.8,409.7l0.2-0.2v0.4L378.8,409.7z"/>
<path className="st17" d="M379.1,409.5v0.4l0.2-0.2L379.1,409.5z M378.8,409.7l0.2-0.2v0.4L378.8,409.7z"/>
<path className="st16" d="M378.8,409.7l-0.8,1.2l1.1-1.1L378.8,409.7z M377.9,410.8l1-1.1l-0.8,1.2L377.9,410.8z"/>
<path className="st17" d="M378.8,409.7l-0.8,1.2l1.1-1.1L378.8,409.7z M377.9,410.8l1-1.1l-0.8,1.2L377.9,410.8z"/>
<path className="st16" d="M379.9,408.5l-1.1,1.3l1.2-1.3H379.9z M378.7,409.7l1.2-1.2l-1.1,1.3L378.7,409.7z"/>
<path className="st17" d="M379.9,408.5l-1.1,1.3l1.2-1.3H379.9z M378.7,409.7l1.2-1.2l-1.1,1.3L378.7,409.7z"/>
<path className="st16" d="M378.7,409.7l-0.8,1.1l1-1L378.7,409.7z M377.8,410.7l1-1l-0.8,1.1L377.8,410.7z"/>
<path className="st17" d="M378.7,409.7l-0.8,1.1l1-1L378.7,409.7z M377.8,410.7l1-1l-0.8,1.1L377.8,410.7z"/>
<path className="st16" d="M377.5,410.9l-0.8,1.3l1.1-1.1L377.5,410.9z M376.4,412l1.1-1.1l-0.8,1.3L376.4,412z"/>
<path className="st17" d="M377.5,410.9l-0.8,1.3l1.1-1.1L377.5,410.9z M376.4,412l1.1-1.1l-0.8,1.3L376.4,412z"/>
<path className="st16" d="M376.4,412l0.4,0.7l0.2-0.2L376.4,412z M376.2,412.2l0.2-0.2l0.4,0.7L376.2,412.2z"/>
<path className="st17" d="M376.4,412l0.4,0.7l0.2-0.2L376.4,412z M376.2,412.2l0.2-0.2l0.4,0.7L376.2,412.2z"/>
<path className="st16" d="M378.5,409.6l-0.7,1.1l1-1L378.5,409.6z M377.6,410.6l0.8-1l-0.7,1.1L377.6,410.6z"/>
<path className="st17" d="M378.5,409.6l-0.7,1.1l1-1L378.5,409.6z M377.6,410.6l0.8-1l-0.7,1.1L377.6,410.6z"/>
<path className="st16" d="M378.5,409.5l-0.8,1.1l0.8-1V409.5z M377.5,410.4l1-1l-0.8,1.1L377.5,410.4z M378.5,409.5l-1,1L378.5,409.5z
	 M378.5,409.5L378.5,409.5l-1,1L378.5,409.5z"/>
<path className="st17" d="M378.5,409.5l-0.8,1.1l0.8-1V409.5z M377.5,410.4l1-1l-0.8,1.1L377.5,410.4z M378.5,409.5l-1,1L378.5,409.5z
	 M378.5,409.5L378.5,409.5l-1,1L378.5,409.5z"/>
<path className="st16" d="M377.3,410.7l-0.2,0.7l0.5-0.5L377.3,410.7z M376.8,411.2l0.5-0.5l-0.2,0.7L376.8,411.2z"/>
<path className="st17" d="M377.3,410.7l-0.2,0.7l0.5-0.5L377.3,410.7z M376.8,411.2l0.5-0.5l-0.2,0.7L376.8,411.2z"/>
<path className="st16" d="M378.4,409.4l-0.8,1.1l1-1L378.4,409.4z M377.4,410.3l1-1l-0.8,1.1L377.4,410.3z"/>
<path className="st17" d="M378.4,409.4l-0.8,1.1l1-1L378.4,409.4z M377.4,410.3l1-1l-0.8,1.1L377.4,410.3z"/>
<path className="st16" d="M377.2,410.6l-0.4,0.6l0.5-0.5L377.2,410.6z M376.7,411l0.5-0.5l-0.4,0.6L376.7,411z"/>
<path className="st17" d="M377.2,410.6l-0.4,0.6l0.5-0.5L377.2,410.6z M376.7,411l0.5-0.5l-0.4,0.6L376.7,411z"/>
<path className="st16" d="M379.6,408l-0.8,1.7l1.2-1.2L379.6,408z M378.4,409.4l1.2-1.3l-0.8,1.7L378.4,409.4z"/>
<path className="st17" d="M379.6,408l-0.8,1.7l1.2-1.2L379.6,408z M378.4,409.4l1.2-1.3l-0.8,1.7L378.4,409.4z"/>
<path className="st16" d="M378.4,409.4l-1,1L378.4,409.4z M377.3,410.4l1.1-1.1l-1,1L377.3,410.4z"/>
<path className="st17" d="M378.4,409.4l-1,1L378.4,409.4z M377.3,410.4l1.1-1.1l-1,1L377.3,410.4z"/>
<path className="st16" d="M377.3,410.4l-0.6,0.6l0.5-0.5L377.3,410.4z M377.3,410.4L377.3,410.4l-0.6,0.6L377.3,410.4z"/>
<path className="st17" d="M377.3,410.4l-0.6,0.6l0.5-0.5L377.3,410.4z M377.3,410.4L377.3,410.4l-0.6,0.6L377.3,410.4z"/>
<path className="st16" d="M379.4,408l-1.1,1.3l1.2-1.3H379.4z M378.2,409.2l1.2-1.2l-1.1,1.3L378.2,409.2z"/>
<path className="st17" d="M379.4,408l-1.1,1.3l1.2-1.3H379.4z M378.2,409.2l1.2-1.2l-1.1,1.3L378.2,409.2z"/>
<path className="st16" d="M378.2,409.2l-1,1.2l1.1-1.1L378.2,409.2z M377.2,410.3l1.1-1.1l-1,1.2L377.2,410.3z"/>
<path className="st17" d="M378.2,409.2l-1,1.2l1.1-1.1L378.2,409.2z M377.2,410.3l1.1-1.1l-1,1.2L377.2,410.3z"/>
<path className="st16" d="M377.2,410.3l-0.5,0.7l0.6-0.6L377.2,410.3z M376.6,410.9l0.6-0.6l-0.5,0.7L376.6,410.9z"/>
<path className="st17" d="M377.2,410.3l-0.5,0.7l0.6-0.6L377.2,410.3z M376.6,410.9l0.6-0.6l-0.5,0.7L376.6,410.9z"/>
<path className="st16" d="M376.6,410.9l0.1,0.8l0.4-0.4L376.6,410.9z M376.2,411.3l0.4-0.4l0.1,0.8L376.2,411.3z"/>
<path className="st17" d="M376.6,410.9l0.1,0.8l0.4-0.4L376.6,410.9z M376.2,411.3l0.4-0.4l0.1,0.8L376.2,411.3z"/>
<path className="st16" d="M376.2,411.3l0.4,0.6l0.1-0.1L376.2,411.3z M376.1,411.4l0.1-0.1l0.4,0.6L376.1,411.4z"/>
<path className="st17" d="M376.2,411.3l0.4,0.6l0.1-0.1L376.2,411.3z M376.1,411.4l0.1-0.1l0.4,0.6L376.1,411.4z"/>
<path className="st16" d="M376.1,411.4l0.1,0.8l0.4-0.4L376.1,411.4z M375.7,411.8l0.4-0.4l0.1,0.8L375.7,411.8z"/>
<path className="st17" d="M376.1,411.4l0.1,0.8l0.4-0.4L376.1,411.4z M375.7,411.8l0.4-0.4l0.1,0.8L375.7,411.8z"/>
<path className="st16" d="M379.7,407.7l-2,2.2l2-2V407.7z M377.5,409.7l2.2-2l-2,2.2L377.5,409.7z"/>
<path className="st17" d="M379.7,407.7l-2,2.2l2-2V407.7z M377.5,409.7l2.2-2l-2,2.2L377.5,409.7z"/>
<path className="st16" d="M376.6,410.7l-1.1,1.3l1.2-1.2L376.6,410.7z M375.5,411.9l1.1-1.2l-1.1,1.3V411.9z"/>
<path className="st17" d="M376.6,410.7l-1.1,1.3l1.2-1.2L376.6,410.7z M375.5,411.9l1.1-1.2l-1.1,1.3V411.9z"/>
<path className="st16" d="M379.6,407.6l-2.5,2.6l2.6-2.5L379.6,407.6z M376.9,410.2l2.6-2.6l-2.5,2.6H376.9z"/>
<path className="st17" d="M379.6,407.6l-2.5,2.6l2.6-2.5L379.6,407.6z M376.9,410.2l2.6-2.6l-2.5,2.6H376.9z"/>
<path className="st16" d="M376.9,410.2l-1.4,1.7l1.6-1.7H376.9z M375.4,411.8l1.6-1.6l-1.4,1.7L375.4,411.8z"/>
<path className="st17" d="M376.9,410.2l-1.4,1.7l1.6-1.7H376.9z M375.4,411.8l1.6-1.6l-1.4,1.7L375.4,411.8z"/>
<line className="st19" x1="378.6" y1="411.8" x2="376.2" y2="409.4"/>
<line className="st19" x1="378.8" y1="409.8" x2="380" y2="408.5"/>
<line className="st19" x1="377.8" y1="410.7" x2="378.7" y2="409.7"/>
<line className="st19" x1="376.7" y1="411.8" x2="377" y2="411.4"/>
<polyline className="st19" points="375.7,411.8 376.2,412.2 376.6,411.9 376.1,411.4 "/>
<line className="st19" x1="376.7" y1="411.8" x2="376.2" y2="411.3"/>
<line className="st19" x1="377" y1="411.4" x2="376.6" y2="410.9"/>
<line className="st19" x1="378.8" y1="409.8" x2="378.2" y2="409.2"/>
<line className="st19" x1="380" y1="408.5" x2="379.4" y2="408"/>
<line className="st19" x1="377.8" y1="411.2" x2="378" y2="410.9"/>
<path className="st19" d="M377.2,410.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C377.4,410.3,377.3,410.3,377.2,410.4"/>
<line className="st19" x1="377.8" y1="411.2" x2="377.3" y2="410.7"/>
<line className="st19" x1="378" y1="410.9" x2="377.5" y2="410.4"/>
<polyline className="st19" points="376.7,410.8 376.6,410.7 377.5,409.7 377.6,409.8 "/>
<path className="st19" d="M377.9,411.1c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C378.1,411,378,411,377.9,411.1"/>
<path className="st19" d="M379.7,411.5c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7"/>
<path className="st19" d="M376.8,412.8c0.2,0.2,0.5,0.4,0.7,0.4s0.6-0.1,0.8-0.3"/>
<path className="st19" d="M377.1,412.6c0.1,0.2,0.3,0.2,0.5,0.3c0.2,0,0.4-0.1,0.5-0.2"/>
<path className="st19" d="M379.5,411.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.2-0.5"/>
<line className="st19" x1="378.1" y1="412.7" x2="379.6" y2="411.3"/>
<line className="st19" x1="379.6" y1="410.3" x2="378.8" y2="409.7"/>
<polyline className="st19" points="377.2,412.6 376.4,412 377.5,410.9 "/>
<line className="st19" x1="378.4" y1="413" x2="379.8" y2="411.5"/>
<path className="st19" d="M379.8,410.2c0.3-0.1,0.6-0.4,0.6-0.7c0.1-0.3-0.1-0.7-0.4-0.9"/>
<line className="st19" x1="375.5" y1="412" x2="379.7" y2="407.8"/>
<polyline className="st19" points="375.5,412 375.4,411.8 379.6,407.6 379.7,407.8 "/>
<line className="st19" x1="379.8" y1="410.1" x2="379.1" y2="409.5"/>
<line className="st19" x1="376.9" y1="412.8" x2="375.7" y2="411.8"/>
<path className="st20" d="M377.6,410.6l-0.1-0.1L377.6,410.6z"/>
<path className="st21" d="M377.6,410.6l-0.1-0.1L377.6,410.6z"/>
<path className="st20" d="M377.5,410.4L377.5,410.4z"/>
<path className="st21" d="M377.5,410.4L377.5,410.4z"/>
<path className="st20" d="M377.5,410.4L377.5,410.4z"/>
<path className="st21" d="M377.5,410.4L377.5,410.4z"/>
<path className="st20" d="M377.5,410.4L377.5,410.4z"/>
<path className="st21" d="M377.5,410.4L377.5,410.4z"/>
<path className="st20" d="M377.5,410.4L377.5,410.4z"/>
<path className="st21" d="M377.5,410.4L377.5,410.4z"/>
<path className="st20" d="M377.5,410.4L377.5,410.4z"/>
<path className="st21" d="M377.5,410.4L377.5,410.4z"/>
<line className="st1" x1="373.1" y1="397.6" x2="380.9" y2="405.4"/>
<line className="st5" x1="379.9" y1="422.2" x2="380.2" y2="422.4"/>
<line className="st5" x1="374.5" y1="428" x2="374.3" y2="427.8"/>
<line className="st5" x1="380.2" y1="422.4" x2="378.8" y2="423.6"/>
<line className="st5" x1="379.9" y1="422.2" x2="378.7" y2="423.4"/>
<path className="st5" d="M374.5,416.8c-3,3-3.1,7.8-0.2,10.9"/>
<line className="st5" x1="378.7" y1="423.4" x2="378.8" y2="423.6"/>
<polyline className="st5" points="374.3,427.8 375.6,426.5 375.8,426.8 374.5,428 "/>
<line className="st5" x1="378.8" y1="423.6" x2="375.8" y2="426.8"/>
<line className="st6" x1="375.7" y1="426.6" x2="378.7" y2="423.5"/>
<line className="st5" x1="378.7" y1="423.4" x2="375.6" y2="426.5"/>
<path className="st16" d="M381.5,315.6l1.7-1.7L381.5,315.6z M383.2,314l-1.7,1.7L383.2,314z M383.2,314L383.2,314l-1.7,1.7L383.2,314z
	 M381.5,315.6l1.7-1.7L381.5,315.6z"/>
<path className="st17" d="M381.5,315.6l1.7-1.7L381.5,315.6z M383.2,314l-1.7,1.7L383.2,314z M383.2,314L383.2,314l-1.7,1.7L383.2,314z
	 M381.5,315.6l1.7-1.7L381.5,315.6z"/>
<path className="st16" d="M383.2,313.8l-1.7,1.8l1.7-1.7V313.8z M381.4,315.6l1.8-1.8l-1.7,1.8H381.4z M383.2,313.8l-1.8,1.8
	L383.2,313.8z M383.2,313.8L383.2,313.8l-1.8,1.8L383.2,313.8z"/>
<path className="st17" d="M383.2,313.8l-1.7,1.8l1.7-1.7V313.8z M381.4,315.6l1.8-1.8l-1.7,1.8H381.4z M383.2,313.8l-1.8,1.8
	L383.2,313.8z M383.2,313.8L383.2,313.8l-1.8,1.8L383.2,313.8z"/>
<path className="st16" d="M382.1,312.8l-0.7,2.9l1.8-1.8L382.1,312.8z M380.3,314.6l1.8-1.8l-0.7,2.9L380.3,314.6z"/>
<path className="st17" d="M382.1,312.8l-0.7,2.9l1.8-1.8L382.1,312.8z M380.3,314.6l1.8-1.8l-0.7,2.9L380.3,314.6z"/>
<path className="st16" d="M381.8,312.6l-0.7,1.1l1-1L381.8,312.6z M381.5,313.1l0.4-0.5l-0.7,1.1L381.5,313.1z"/>
<path className="st17" d="M381.8,312.6l-0.7,1.1l1-1L381.8,312.6z M381.5,313.1l0.4-0.5l-0.7,1.1L381.5,313.1z"/>
<path className="st16" d="M380.5,314l-0.2,0.6l0.8-0.8L380.5,314z M380.2,314.3l0.4-0.4l-0.2,0.6L380.2,314.3z"/>
<path className="st17" d="M380.5,314l-0.2,0.6l0.8-0.8L380.5,314z M380.2,314.3l0.4-0.4l-0.2,0.6L380.2,314.3z"/>
<path className="st16" d="M381.8,312.6L381.8,312.6l-0.4,0.5L381.8,312.6z M381.5,313.1l0.4-0.5L381.5,313.1z"/>
<path className="st17" d="M381.8,312.6L381.8,312.6l-0.4,0.5L381.8,312.6z M381.5,313.1l0.4-0.5L381.5,313.1z"/>
<path className="st16" d="M380.8,313.5l0.2-0.2L380.8,313.5z M381,313.2l-0.2,0.2L381,313.2z M381,313.2L381,313.2l-0.2,0.2L381,313.2z
	 M380.8,313.5l0.2-0.2L380.8,313.5z"/>
<path className="st17" d="M380.8,313.5l0.2-0.2L380.8,313.5z M381,313.2l-0.2,0.2L381,313.2z M381,313.2L381,313.2l-0.2,0.2L381,313.2z
	 M380.8,313.5l0.2-0.2L380.8,313.5z"/>
<path className="st16" d="M381,313.1l-0.2,0.4l0.2-0.2V313.1z M380.6,313.5l0.4-0.4l-0.2,0.4H380.6z M381,313.1l-0.4,0.4L381,313.1z
	 M381,313.1L381,313.1l-0.4,0.4L381,313.1z"/>
<path className="st17" d="M381,313.1l-0.2,0.4l0.2-0.2V313.1z M380.6,313.5l0.4-0.4l-0.2,0.4H380.6z M381,313.1l-0.4,0.4L381,313.1z
	 M381,313.1L381,313.1l-0.4,0.4L381,313.1z"/>
<path className="st16" d="M380.5,313.4l0.4-0.4l-0.2,0.1L380.5,313.4z M380.6,313.5l-0.1-0.1l0.4-0.4L380.6,313.5z"/>
<path className="st17" d="M380.5,313.4l0.4-0.4l-0.2,0.1L380.5,313.4z M380.6,313.5l-0.1-0.1l0.4-0.4L380.6,313.5z"/>
<polygon className="st16" points="380.6,313.5 381,313.1 380.9,313 "/>
<polygon className="st17" points="380.6,313.5 381,313.1 380.9,313 "/>
<path className="st16" d="M379.9,313.7l0.2,0.6l0.4-0.4L379.9,313.7z M379.7,314l0.2-0.2l0.2,0.6L379.7,314z"/>
<path className="st17" d="M379.9,313.7l0.2,0.6l0.4-0.4L379.9,313.7z M379.7,314l0.2-0.2l0.2,0.6L379.7,314z"/>
<path className="st16" d="M381.5,312.2v1l0.4-0.5L381.5,312.2z M381.2,312.4l0.2-0.2v1L381.2,312.4z"/>
<path className="st17" d="M381.5,312.2v1l0.4-0.5L381.5,312.2z M381.2,312.4l0.2-0.2v1L381.2,312.4z"/>
<path className="st16" d="M381.1,311.9l0.1,0.5l0.2-0.2L381.1,311.9z M380.9,312l0.2-0.1l0.1,0.5L380.9,312z"/>
<path className="st17" d="M381.1,311.9l0.1,0.5l0.2-0.2L381.1,311.9z M380.9,312l0.2-0.1l0.1,0.5L380.9,312z"/>
<path className="st16" d="M379.6,313.4l0.1,0.6l0.2-0.2L379.6,313.4z M379.3,313.6l0.2-0.2l0.1,0.6L379.3,313.6z"/>
<path className="st17" d="M379.6,313.4l0.1,0.6l0.2-0.2L379.6,313.4z M379.3,313.6l0.2-0.2l0.1,0.6L379.3,313.6z"/>
<path className="st16" d="M379.3,312.8v0.8l0.2-0.2L379.3,312.8z M379.1,313.1l0.2-0.4v0.8L379.1,313.1z"/>
<path className="st17" d="M379.3,312.8v0.8l0.2-0.2L379.3,312.8z M379.1,313.1l0.2-0.4v0.8L379.1,313.1z"/>
<path className="st16" d="M380.5,311.6l0.4,0.5l0.2-0.1L380.5,311.6z M380.3,311.8l0.2-0.2l0.4,0.5L380.3,311.8z M380.5,311.6l-0.2,0.2
	L380.5,311.6z M380.5,311.6L380.5,311.6l-0.2,0.2L380.5,311.6z"/>
<path className="st17" d="M380.5,311.6l0.4,0.5l0.2-0.1L380.5,311.6z M380.3,311.8l0.2-0.2l0.4,0.5L380.3,311.8z M380.5,311.6l-0.2,0.2
	L380.5,311.6z M380.5,311.6L380.5,311.6l-0.2,0.2L380.5,311.6z"/>
<path className="st16" d="M379.3,312.8l-0.2,0.4L379.3,312.8z M379.1,313l0.2-0.2l-0.2,0.4V313z"/>
<path className="st17" d="M379.3,312.8l-0.2,0.4L379.3,312.8z M379.1,313l0.2-0.2l-0.2,0.4V313z"/>
<path className="st16" d="M380.2,311.6l0.1,0.2l0.2-0.2H380.2z M379.6,312l0.6-0.5l0.1,0.2L379.6,312z"/>
<path className="st17" d="M380.2,311.6l0.1,0.2l0.2-0.2H380.2z M379.6,312l0.6-0.5l0.1,0.2L379.6,312z"/>
<path className="st16" d="M379.6,312l-0.5,1l0.2-0.2L379.6,312z M379.1,312.6l0.5-0.6l-0.5,1V312.6z"/>
<path className="st17" d="M379.6,312l-0.5,1l0.2-0.2L379.6,312z M379.1,312.6l0.5-0.6l-0.5,1V312.6z"/>
<path className="st16" d="M379.9,311.6l-0.8,1.1l1.1-1.1H379.9z M379.1,312.4l0.8-0.8l-0.8,1.1V312.4z M379.9,311.6l-0.8,0.8
	L379.9,311.6z M379.9,311.6L379.9,311.6l-0.8,0.8L379.9,311.6z"/>
<path className="st17" d="M379.9,311.6l-0.8,1.1l1.1-1.1H379.9z M379.1,312.4l0.8-0.8l-0.8,1.1V312.4z M379.9,311.6l-0.8,0.8
	L379.9,311.6z M379.9,311.6L379.9,311.6l-0.8,0.8L379.9,311.6z"/>
<path className="st16" d="M379.2,312.3l0.6-0.7l-0.5,0.4L379.2,312.3z M379.1,312.4l0.1-0.1l0.6-0.7L379.1,312.4z"/>
<path className="st17" d="M379.2,312.3l0.6-0.7l-0.5,0.4L379.2,312.3z M379.1,312.4l0.1-0.1l0.6-0.7L379.1,312.4z"/>
<path className="st16" d="M379.1,312.4l0.7-0.8L379.1,312.4z"/>
<path className="st17" d="M379.1,312.4l0.7-0.8L379.1,312.4z"/>
<path className="st19" d="M383,313.9C383,313.9,383,313.9,383,313.9"/>
<line className="st19" x1="383.2" y1="314" x2="381.5" y2="315.6"/>
<path className="st19" d="M381.4,315.5C381.4,315.5,381.4,315.5,381.4,315.5"/>
<line className="st19" x1="381.4" y1="315.6" x2="379.3" y2="313.6"/>
<path className="st19" d="M381.1,311.8c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8"/>
<line className="st19" x1="381.1" y1="311.9" x2="383.2" y2="313.8"/>
<path className="st19" d="M381,313.2C381,313.2,381,313.2,381,313.2"/>
<line className="st19" x1="381" y1="313.1" x2="380.9" y2="313"/>
<path className="st19" d="M380.7,312.9c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="380.5" y1="313.4" x2="380.6" y2="313.5"/>
<path className="st19" d="M380.7,313.5C380.7,313.5,380.7,313.5,380.7,313.5"/>
<line className="st19" x1="380.8" y1="313.5" x2="381" y2="313.2"/>
<line className="st19" x1="379.9" y1="313.7" x2="379.6" y2="313.4"/>
<path className="st19" d="M380.9,312.1c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="380.9" y1="312" x2="381.2" y2="312.4"/>
<path className="st19" d="M379.9,313.6c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<path className="st5" d="M268.9,246c-2.1,2.3-2.1,6,0.2,8.2"/>
<polyline className="st5" points="269,246.2 269.2,245.9 273.4,250.1 273.2,250.4 269,246.2 "/>
<path className="st5" d="M55.3,207c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="51.8" y1="203.6" x2="55.4" y2="200"/>
<polyline className="st5" points="51.8,203.6 52.1,203.7 55.7,200.2 55.4,200 "/>
<path className="st5" d="M62.2,206.9c-2-1.8-5-1.7-6.9,0.2"/>
<line className="st5" x1="58.9" y1="210.6" x2="62.5" y2="207"/>
<polyline className="st5" points="58.9,210.6 58.8,210.4 62.3,206.9 62.5,207 "/>
<line className="st6" x1="58.9" y1="210.5" x2="62.4" y2="207"/>
<line className="st6" x1="52" y1="203.7" x2="55.6" y2="200.1"/>
<path className="st5" d="M72.8,189.6c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="69.4" y1="186.2" x2="72.8" y2="182.6"/>
<polyline className="st5" points="69.4,186.2 69.5,186.3 73.1,182.8 72.8,182.6 "/>
<path className="st5" d="M79.7,189.5c-2-1.8-5-1.7-6.9,0.2"/>
<line className="st5" x1="76.4" y1="193.2" x2="79.9" y2="189.6"/>
<polyline className="st5" points="76.4,193.2 76.2,193 79.8,189.5 79.9,189.6 "/>
<line className="st6" x1="76.3" y1="193.1" x2="79.8" y2="189.5"/>
<line className="st6" x1="69.4" y1="186.2" x2="73" y2="182.7"/>
<path className="st5" d="M74.9,201.3c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="74.9,201.3 74.8,201 79,197 79.1,197.1 74.9,201.3 "/>
<path className="st5" d="M336,326.1c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="336.1,326.1 336.2,326.3 332,330.5 331.9,330.3 336.1,326.1 "/>
<line className="st1" x1="238.3" y1="235.4" x2="229.4" y2="226.5"/>
<path className="st16" d="M289.4,281.6l1.7-1.8l-1.3,1.4L289.4,281.6z M291.6,279.5l-2.2,2l1.7-1.8L291.6,279.5z"/>
<path className="st17" d="M289.4,281.6l1.7-1.8l-1.3,1.4L289.4,281.6z M291.6,279.5l-2.2,2l1.7-1.8L291.6,279.5z"/>
<path className="st16" d="M289.4,281.7l2.2-2.2l-2.2,2V281.7z M289.4,281.7L289.4,281.7l2.2-2.2L289.4,281.7z"/>
<path className="st17" d="M289.4,281.7l2.2-2.2l-2.2,2V281.7z M289.4,281.7L289.4,281.7l2.2-2.2L289.4,281.7z"/>
<path className="st16" d="M289.4,281.9l2.2-2.4l-2.2,2.2V281.9z M291.8,279.5l-2.4,2.4l2.2-2.4H291.8z"/>
<path className="st17" d="M289.4,281.9l2.2-2.4l-2.2,2.2V281.9z M291.8,279.5l-2.4,2.4l2.2-2.4H291.8z"/>
<path className="st16" d="M289.4,282l0.5-0.6l-0.5,0.5V282z M289.8,281.7l-0.4,0.4l0.5-0.6L289.8,281.7z"/>
<path className="st17" d="M289.4,282l0.5-0.6l-0.5,0.5V282z M289.8,281.7l-0.4,0.4l0.5-0.6L289.8,281.7z"/>
<path className="st16" d="M289.8,281.7l1.6-1.7l-1.3,1.4L289.8,281.7z M291.7,279.9l-1.9,1.8l1.6-1.7L291.7,279.9z"/>
<path className="st17" d="M289.8,281.7l1.6-1.7l-1.3,1.4L289.8,281.7z M291.7,279.9l-1.9,1.8l1.6-1.7L291.7,279.9z"/>
<path className="st16" d="M289.4,282.2l0.4-0.5l-0.4,0.4V282.2z M289.8,281.8l-0.4,0.4l0.4-0.5V281.8z"/>
<path className="st17" d="M289.4,282.2l0.4-0.5l-0.4,0.4V282.2z M289.8,281.8l-0.4,0.4l0.4-0.5V281.8z"/>
<path className="st16" d="M289.8,281.8l1.9-1.9l-1.9,1.8V281.8z M291.8,279.9l-2,1.9l1.9-1.9H291.8z"/>
<path className="st17" d="M289.8,281.8l1.9-1.9l-1.9,1.8V281.8z M291.8,279.9l-2,1.9l1.9-1.9H291.8z"/>
<path className="st16" d="M289.6,282.2l0.2-0.4l-0.4,0.4H289.6z M289.8,281.9l-0.2,0.2l0.2-0.4V281.9z"/>
<path className="st17" d="M289.6,282.2l0.2-0.4l-0.4,0.4H289.6z M289.8,281.9l-0.2,0.2l0.2-0.4V281.9z"/>
<path className="st16" d="M289.8,281.9l2-2l-2,1.9V281.9z M291.8,279.8l-2,2.2l2-2V279.8z"/>
<path className="st17" d="M289.8,281.9l2-2l-2,1.9V281.9z M291.8,279.8l-2,2.2l2-2V279.8z"/>
<path className="st16" d="M289.6,282.2L289.6,282.2l0.2-0.2L289.6,282.2z M289.8,281.9l-0.2,0.2L289.8,281.9z"/>
<path className="st17" d="M289.6,282.2L289.6,282.2l0.2-0.2L289.6,282.2z M289.8,281.9l-0.2,0.2L289.8,281.9z"/>
<path className="st16" d="M289.8,281.9L289.8,281.9l2-2.2L289.8,281.9z M291.8,279.8l-2,2.2L291.8,279.8z"/>
<path className="st17" d="M289.8,281.9L289.8,281.9l2-2.2L289.8,281.9z M291.8,279.8l-2,2.2L291.8,279.8z"/>
<path className="st16" d="M289.6,282.2L289.6,282.2l0.2-0.2L289.6,282.2z M289.8,281.9l-0.2,0.2L289.8,281.9z"/>
<path className="st17" d="M289.6,282.2L289.6,282.2l0.2-0.2L289.6,282.2z M289.8,281.9l-0.2,0.2L289.8,281.9z"/>
<path className="st16" d="M289.8,281.9L289.8,281.9l2-2.2L289.8,281.9z M291.8,279.8l-2,2.2L291.8,279.8z"/>
<path className="st17" d="M289.8,281.9L289.8,281.9l2-2.2L289.8,281.9z M291.8,279.8l-2,2.2L291.8,279.8z"/>
<path className="st16" d="M291.8,279.8v-0.2l-0.5,0.5L291.8,279.8z M292.2,279.5l-0.4,0.2v-0.2H292.2z"/>
<path className="st17" d="M291.8,279.8v-0.2l-0.5,0.5L291.8,279.8z M292.2,279.5l-0.4,0.2v-0.2H292.2z"/>
<path className="st16" d="M289.8,281.9l2-2.2L289.8,281.9z M292,279.8l-2.2,2.2l2-2.2H292z"/>
<path className="st17" d="M289.8,281.9l2-2.2L289.8,281.9z M292,279.8l-2.2,2.2l2-2.2H292z"/>
<path className="st16" d="M292,279.8l0.2-0.2l-0.4,0.2H292z M292,279.8L292,279.8l0.2-0.2L292,279.8z"/>
<path className="st17" d="M292,279.8l0.2-0.2l-0.4,0.2H292z M292,279.8L292,279.8l0.2-0.2L292,279.8z"/>
<polygon className="st16" points="289.2,283 289.7,282.5 289.2,283.1 "/>
<polygon className="st17" points="289.2,283 289.7,282.5 289.2,283.1 "/>
<path className="st16" d="M289.9,282.2l2-2.4l-2.2,2.2L289.9,282.2z M292.2,280l-2.3,2.2l2-2.4L292.2,280z"/>
<path className="st17" d="M289.9,282.2l2-2.4l-2.2,2.2L289.9,282.2z M292.2,280l-2.3,2.2l2-2.4L292.2,280z"/>
<path className="st16" d="M292.2,280v-0.5l-0.2,0.2L292.2,280z M292.4,279.8l-0.2,0.2v-0.5L292.4,279.8z"/>
<path className="st17" d="M292.2,280v-0.5l-0.2,0.2L292.2,280z M292.4,279.8l-0.2,0.2v-0.5L292.4,279.8z"/>
<path className="st16" d="M289.2,283.1l0.5-0.6h0.1L289.2,283.1z M289.2,283.1L289.2,283.1l0.5-0.6L289.2,283.1z M289.7,282.5l-0.5,0.6
	L289.7,282.5z M289.2,283.1l0.5-0.6L289.2,283.1z"/>
<path className="st17" d="M289.2,283.1l0.5-0.6h0.1L289.2,283.1z M289.2,283.1L289.2,283.1l0.5-0.6L289.2,283.1z M289.7,282.5l-0.5,0.6
	L289.7,282.5z M289.2,283.1l0.5-0.6L289.2,283.1z"/>
<path className="st16" d="M289.8,282.5v-0.6l-0.2,0.2L289.8,282.5z M290,282.4l-0.2,0.1v-0.6L290,282.4z"/>
<path className="st17" d="M289.8,282.5v-0.6l-0.2,0.2L289.8,282.5z M290,282.4l-0.2,0.1v-0.6L290,282.4z"/>
<path className="st16" d="M289.2,283.1L289.2,283.1l0.6-0.6L289.2,283.1z M289.8,282.5l-0.6,0.6L289.8,282.5z"/>
<path className="st17" d="M289.2,283.1L289.2,283.1l0.6-0.6L289.2,283.1z M289.8,282.5l-0.6,0.6L289.8,282.5z"/>
<path className="st16" d="M289.8,282.5L289.8,282.5l0.2-0.1L289.8,282.5z M290,282.4l-0.2,0.1L290,282.4z"/>
<path className="st17" d="M289.8,282.5L289.8,282.5l0.2-0.1L289.8,282.5z M290,282.4l-0.2,0.1L290,282.4z"/>
<path className="st16" d="M290,282.4l2.2-2.4l-2.3,2.2L290,282.4z M292.3,280l-2.3,2.4l2.2-2.4H292.3z"/>
<path className="st17" d="M290,282.4l2.2-2.4l-2.3,2.2L290,282.4z M292.3,280l-2.3,2.4l2.2-2.4H292.3z"/>
<path className="st16" d="M289.2,283.2l0.6-0.7l-0.6,0.6V283.2z M289.8,282.6l-0.6,0.6l0.6-0.7V282.6z"/>
<path className="st17" d="M289.2,283.2l0.6-0.7l-0.6,0.6V283.2z M289.8,282.6l-0.6,0.6l0.6-0.7V282.6z"/>
<path className="st16" d="M289.8,282.6l0.2-0.2l-0.2,0.1V282.6z M289.8,282.6L289.8,282.6l0.2-0.2L289.8,282.6z"/>
<path className="st17" d="M289.8,282.6l0.2-0.2l-0.2,0.1V282.6z M289.8,282.6L289.8,282.6l0.2-0.2L289.8,282.6z"/>
<path className="st16" d="M290,282.4L290,282.4l2.3-2.4L290,282.4z M292.3,280l-2.3,2.4L292.3,280z"/>
<path className="st17" d="M290,282.4L290,282.4l2.3-2.4L290,282.4z M292.3,280l-2.3,2.4L292.3,280z"/>
<path className="st16" d="M292.3,280l0.1-0.2l-0.2,0.2H292.3z M292.6,279.9l-0.2,0.1l0.1-0.2L292.6,279.9z"/>
<path className="st17" d="M292.3,280l0.1-0.2l-0.2,0.2H292.3z M292.6,279.9l-0.2,0.1l0.1-0.2L292.6,279.9z"/>
<path className="st16" d="M289.2,283.4l0.6-0.7l-0.6,0.6V283.4z M289.2,283.4L289.2,283.4l0.6-0.7L289.2,283.4z"/>
<path className="st17" d="M289.2,283.4l0.6-0.7l-0.6,0.6V283.4z M289.2,283.4L289.2,283.4l0.6-0.7L289.2,283.4z"/>
<path className="st16" d="M289.8,282.6L289.8,282.6l0.2-0.2L289.8,282.6z M290,282.4l-0.2,0.2L290,282.4z"/>
<path className="st17" d="M289.8,282.6L289.8,282.6l0.2-0.2L289.8,282.6z M290,282.4l-0.2,0.2L290,282.4z"/>
<path className="st16" d="M290,282.4l2.3-2.4L290,282.4z M292.4,280.1l-2.4,2.3l2.3-2.4L292.4,280.1z"/>
<path className="st17" d="M290,282.4l2.3-2.4L290,282.4z M292.4,280.1l-2.4,2.3l2.3-2.4L292.4,280.1z"/>
<path className="st16" d="M292.4,280.1l0.1-0.2l-0.2,0.1L292.4,280.1z M292.7,279.9l-0.2,0.2l0.1-0.2H292.7z"/>
<path className="st17" d="M292.4,280.1l0.1-0.2l-0.2,0.1L292.4,280.1z M292.7,279.9l-0.2,0.2l0.1-0.2H292.7z"/>
<path className="st16" d="M289.1,283.5l0.7-0.8l-0.6,0.7L289.1,283.5z M289.9,282.6l-0.8,0.8l0.7-0.8H289.9z"/>
<path className="st17" d="M289.1,283.5l0.7-0.8l-0.6,0.7L289.1,283.5z M289.9,282.6l-0.8,0.8l0.7-0.8H289.9z"/>
<path className="st16" d="M289.9,282.6l0.1-0.2l-0.2,0.2H289.9z M290.2,282.5l-0.2,0.1l0.1-0.2L290.2,282.5z"/>
<path className="st17" d="M289.9,282.6l0.1-0.2l-0.2,0.2H289.9z M290.2,282.5l-0.2,0.1l0.1-0.2L290.2,282.5z"/>
<path className="st16" d="M290.2,282.5l2.3-2.4l-2.4,2.3L290.2,282.5z M290.2,282.5L290.2,282.5l2.3-2.4L290.2,282.5z"/>
<path className="st17" d="M290.2,282.5l2.3-2.4l-2.4,2.3L290.2,282.5z M290.2,282.5L290.2,282.5l2.3-2.4L290.2,282.5z"/>
<path className="st16" d="M292.4,280.1L292.4,280.1l0.2-0.2L292.4,280.1z M292.7,279.9l-0.2,0.2L292.7,279.9z"/>
<path className="st17" d="M292.4,280.1L292.4,280.1l0.2-0.2L292.4,280.1z M292.7,279.9l-0.2,0.2L292.7,279.9z"/>
<path className="st16" d="M289.1,283.5l0.8-0.8L289.1,283.5z M289.9,282.8l-0.8,0.7l0.8-0.8V282.8z"/>
<path className="st17" d="M289.1,283.5l0.8-0.8L289.1,283.5z M289.9,282.8l-0.8,0.7l0.8-0.8V282.8z"/>
<path className="st16" d="M289.9,282.8l0.2-0.2l-0.2,0.1V282.8z M289.9,282.8L289.9,282.8l0.2-0.2L289.9,282.8z"/>
<path className="st17" d="M289.9,282.8l0.2-0.2l-0.2,0.1V282.8z M289.9,282.8L289.9,282.8l0.2-0.2L289.9,282.8z"/>
<path className="st16" d="M290.2,282.5l1.1-1.2L290.2,282.5z M291.2,281.4l-1.1,1.1l1.1-1.2V281.4z"/>
<path className="st17" d="M290.2,282.5l1.1-1.2L290.2,282.5z M291.2,281.4l-1.1,1.1l1.1-1.2V281.4z"/>
<path className="st16" d="M291.4,281.2l1.1-1.1l-1.2,1.2L291.4,281.2z M291.4,281.2L291.4,281.2l1.1-1.1L291.4,281.2z"/>
<path className="st17" d="M291.4,281.2l1.1-1.1l-1.2,1.2L291.4,281.2z M291.4,281.2L291.4,281.2l1.1-1.1L291.4,281.2z"/>
<path className="st16" d="M292.4,280.1L292.4,280.1l0.2-0.2L292.4,280.1z M292.7,279.9l-0.2,0.2L292.7,279.9z"/>
<path className="st17" d="M292.4,280.1L292.4,280.1l0.2-0.2L292.4,280.1z M292.7,279.9l-0.2,0.2L292.7,279.9z"/>
<path className="st16" d="M289.1,283.6l0.8-0.8l-0.8,0.7V283.6z M289.1,283.6L289.1,283.6l0.8-0.8L289.1,283.6z"/>
<path className="st17" d="M289.1,283.6l0.8-0.8l-0.8,0.7V283.6z M289.1,283.6L289.1,283.6l0.8-0.8L289.1,283.6z"/>
<path className="st16" d="M289.9,282.8L289.9,282.8l0.2-0.2L289.9,282.8z M290.2,282.5l-0.2,0.2L290.2,282.5z"/>
<path className="st17" d="M289.9,282.8L289.9,282.8l0.2-0.2L289.9,282.8z M290.2,282.5l-0.2,0.2L290.2,282.5z"/>
<path className="st16" d="M289.2,283.7l0.7-1l-0.8,0.8L289.2,283.7z M290,282.9l-0.8,0.8l0.7-1L290,282.9z"/>
<path className="st17" d="M289.2,283.7l0.7-1l-0.8,0.8L289.2,283.7z M290,282.9l-0.8,0.8l0.7-1L290,282.9z"/>
<path className="st16" d="M290,282.9l0.1-0.4l-0.2,0.2L290,282.9z M290.3,282.6l-0.2,0.2l0.1-0.4L290.3,282.6z"/>
<path className="st17" d="M290,282.9l0.1-0.4l-0.2,0.2L290,282.9z M290.3,282.6l-0.2,0.2l0.1-0.4L290.3,282.6z"/>
<path className="st16" d="M290.3,282.6l1-1.2l-1.1,1.1L290.3,282.6z M291.2,281.6l-1,1.1l1-1.2V281.6z"/>
<path className="st17" d="M290.3,282.6l1-1.2l-1.1,1.1L290.3,282.6z M291.2,281.6l-1,1.1l1-1.2V281.6z"/>
<path className="st16" d="M291.6,281.3l0.8-1.2l-1.1,1.1L291.6,281.3z M292.6,280.2l-1,1.1l0.8-1.2L292.6,280.2z"/>
<path className="st17" d="M291.6,281.3l0.8-1.2l-1.1,1.1L291.6,281.3z M292.6,280.2l-1,1.1l0.8-1.2L292.6,280.2z"/>
<path className="st16" d="M290.4,282.6l0.8-1.1l-1,1.1H290.4z M291.4,281.7l-1,1l0.8-1.1L291.4,281.7z"/>
<path className="st17" d="M290.4,282.6l0.8-1.1l-1,1.1H290.4z M291.4,281.7l-1,1l0.8-1.1L291.4,281.7z"/>
<path className="st16" d="M291.6,281.4l1-1.2l-1,1.1V281.4z M292.7,280.4l-1.1,1.1l1-1.2L292.7,280.4z"/>
<path className="st17" d="M291.6,281.4l1-1.2l-1,1.1V281.4z M292.7,280.4l-1.1,1.1l1-1.2L292.7,280.4z"/>
<path className="st16" d="M290.4,282.8l1-1.1l-1,1V282.8z M291.6,281.6l-1.2,1.2l1-1.1L291.6,281.6z"/>
<path className="st17" d="M290.4,282.8l1-1.1l-1,1V282.8z M291.6,281.6l-1.2,1.2l1-1.1L291.6,281.6z"/>
<path className="st16" d="M291.6,281.6l1.1-1.2l-1.1,1.1V281.6z M291.6,281.6L291.6,281.6l1.1-1.2L291.6,281.6z"/>
<path className="st17" d="M291.6,281.6l1.1-1.2l-1.1,1.1V281.6z M291.6,281.6L291.6,281.6l1.1-1.2L291.6,281.6z"/>
<path className="st16" d="M289.3,284l0.7-1.1l-0.8,0.8L289.3,284z M290.3,283.1l-1,0.8l0.7-1.1L290.3,283.1z"/>
<path className="st17" d="M289.3,284l0.7-1.1l-0.8,0.8L289.3,284z M290.3,283.1l-1,0.8l0.7-1.1L290.3,283.1z"/>
<path className="st16" d="M290.3,283.1v-0.5l-0.2,0.2L290.3,283.1z M290.5,282.9l-0.2,0.2v-0.5L290.5,282.9z"/>
<path className="st17" d="M290.3,283.1v-0.5l-0.2,0.2L290.3,283.1z M290.5,282.9l-0.2,0.2v-0.5L290.5,282.9z"/>
<path className="st16" d="M290.5,282.9l1.1-1.3l-1.2,1.2L290.5,282.9z M291.6,281.7l-1.1,1.2l1.1-1.3V281.7z"/>
<path className="st17" d="M290.5,282.9l1.1-1.3l-1.2,1.2L290.5,282.9z M291.6,281.7l-1.1,1.2l1.1-1.3V281.7z"/>
<path className="st16" d="M291.6,281.7l1.1-1.3l-1.1,1.2V281.7z M292.8,280.5l-1.2,1.2l1.1-1.3L292.8,280.5z"/>
<path className="st17" d="M291.6,281.7l1.1-1.3l-1.1,1.2V281.7z M292.8,280.5l-1.2,1.2l1.1-1.3L292.8,280.5z"/>
<path className="st16" d="M289.4,284.1l0.8-1l-1,0.8L289.4,284.1z M290.4,283.2l-1,0.8l0.8-1L290.4,283.2z"/>
<path className="st17" d="M289.4,284.1l0.8-1l-1,0.8L289.4,284.1z M290.4,283.2l-1,0.8l0.8-1L290.4,283.2z"/>
<path className="st16" d="M290.4,283.2l0.1-0.4l-0.2,0.2L290.4,283.2z M290.6,283l-0.2,0.2l0.1-0.4L290.6,283z"/>
<path className="st17" d="M290.4,283.2l0.1-0.4l-0.2,0.2L290.4,283.2z M290.6,283l-0.2,0.2l0.1-0.4L290.6,283z"/>
<path className="st16" d="M290.6,283l0.8-1.2l-1,1.1L290.6,283z M291.7,281.9l-1.1,1.1l0.8-1.2L291.7,281.9z"/>
<path className="st17" d="M290.6,283l0.8-1.2l-1,1.1L290.6,283z M291.7,281.9l-1.1,1.1l0.8-1.2L291.7,281.9z"/>
<path className="st16" d="M289.6,284.2l1.2-1.3l-1.3,1.2L289.6,284.2z M290.8,283l-1.2,1.2l1.2-1.3V283z"/>
<path className="st17" d="M289.6,284.2l1.2-1.3l-1.3,1.2L289.6,284.2z M290.8,283l-1.2,1.2l1.2-1.3V283z"/>
<path className="st16" d="M290.8,283l1-1.1l-1,1V283z M291.7,282l-1,1l1-1.1V282z"/>
<path className="st17" d="M290.8,283l1-1.1l-1,1V283z M291.7,282l-1,1l1-1.1V282z"/>
<path className="st16" d="M292.1,281.8l0.7-1.3l-1,1.1L292.1,281.8z M293,280.7l-1,1.1l0.7-1.3L293,280.7z"/>
<path className="st17" d="M292.1,281.8l0.7-1.3l-1,1.1L292.1,281.8z M293,280.7l-1,1.1l0.7-1.3L293,280.7z"/>
<path className="st16" d="M293,280.7l-0.4-0.8l-0.2,0.2L293,280.7z M293.3,280.5l-0.2,0.2l-0.4-0.8L293.3,280.5z"/>
<path className="st17" d="M293,280.7l-0.4-0.8l-0.2,0.2L293,280.7z M293.3,280.5l-0.2,0.2l-0.4-0.8L293.3,280.5z"/>
<path className="st16" d="M291,283.1l0.7-1.1l-1,1L291,283.1z M292,282.2l-1,1l0.7-1.1L292,282.2z M292,282.3v-0.1l-1,1L292,282.3z"/>
<path className="st17" d="M291,283.1l0.7-1.1l-1,1L291,283.1z M292,282.2l-1,1l0.7-1.1L292,282.2z M292,282.3v-0.1l-1,1L292,282.3z"/>
<path className="st16" d="M292,282.2l-1,1L292,282.2z"/>
<path className="st17" d="M292,282.2l-1,1L292,282.2z"/>
<path className="st16" d="M291,283.2l1-1l-1,0.8V283.2z M291,283.2L291,283.2l1-1L291,283.2z"/>
<path className="st17" d="M291,283.2l1-1l-1,0.8V283.2z M291,283.2L291,283.2l1-1L291,283.2z"/>
<path className="st16" d="M292.2,282l0.2-0.7l-0.4,0.5L292.2,282z M292.7,281.6l-0.5,0.5l0.2-0.7L292.7,281.6z"/>
<path className="st17" d="M292.2,282l0.2-0.7l-0.4,0.5L292.2,282z M292.7,281.6l-0.5,0.5l0.2-0.7L292.7,281.6z"/>
<path className="st16" d="M291.1,283.2l0.8-1l-1,1H291.1z M292.1,282.3l-1,1l0.8-1H292.1z"/>
<path className="st17" d="M291.1,283.2l0.8-1l-1,1H291.1z M292.1,282.3l-1,1l0.8-1H292.1z"/>
<path className="st16" d="M292.3,282.2l0.4-0.6l-0.5,0.5L292.3,282.2z M292.8,281.6l-0.5,0.6l0.4-0.6H292.8z"/>
<path className="st17" d="M292.3,282.2l0.4-0.6l-0.5,0.5L292.3,282.2z M292.8,281.6l-0.5,0.6l0.4-0.6H292.8z"/>
<path className="st16" d="M289.9,284.6l0.8-1.6l-1.2,1.2L289.9,284.6z M291.2,283.4l-1.3,1.2l0.8-1.6L291.2,283.4z"/>
<path className="st17" d="M289.9,284.6l0.8-1.6l-1.2,1.2L289.9,284.6z M291.2,283.4l-1.3,1.2l0.8-1.6L291.2,283.4z"/>
<path className="st16" d="M291.2,283.4l0.8-1.1l-1,1L291.2,283.4z M292.2,282.3l-1,1.1l0.8-1.1H292.2z"/>
<path className="st17" d="M291.2,283.4l0.8-1.1l-1,1L291.2,283.4z M292.2,282.3l-1,1.1l0.8-1.1H292.2z"/>
<path className="st16" d="M292.2,282.3l0.6-0.7l-0.5,0.6L292.2,282.3z M292.9,281.7l-0.7,0.6l0.6-0.7L292.9,281.7z"/>
<path className="st17" d="M292.2,282.3l0.6-0.7l-0.5,0.6L292.2,282.3z M292.9,281.7l-0.7,0.6l0.6-0.7L292.9,281.7z"/>
<path className="st16" d="M290,284.7l1.2-1.3l-1.3,1.2L290,284.7z M291.2,283.5l-1.2,1.2l1.2-1.3V283.5z"/>
<path className="st17" d="M290,284.7l1.2-1.3l-1.3,1.2L290,284.7z M291.2,283.5l-1.2,1.2l1.2-1.3V283.5z"/>
<path className="st16" d="M291.2,283.5l1-1.2l-1,1.1V283.5z M292.3,282.4l-1.1,1.1l1-1.2L292.3,282.4z"/>
<path className="st17" d="M291.2,283.5l1-1.2l-1,1.1V283.5z M292.3,282.4l-1.1,1.1l1-1.2L292.3,282.4z"/>
<path className="st16" d="M292.3,282.4l0.6-0.7l-0.7,0.6L292.3,282.4z M292.9,281.8l-0.6,0.6l0.6-0.7V281.8z"/>
<path className="st17" d="M292.3,282.4l0.6-0.7l-0.7,0.6L292.3,282.4z M292.9,281.8l-0.6,0.6l0.6-0.7V281.8z"/>
<path className="st16" d="M292.9,281.8l-0.1-0.8l-0.4,0.4L292.9,281.8z M293.3,281.4l-0.4,0.4l-0.1-0.8L293.3,281.4z"/>
<path className="st17" d="M292.9,281.8l-0.1-0.8l-0.4,0.4L292.9,281.8z M293.3,281.4l-0.4,0.4l-0.1-0.8L293.3,281.4z"/>
<path className="st16" d="M293.3,281.4l-0.4-0.6l-0.1,0.1L293.3,281.4z M293.4,281.3l-0.1,0.1l-0.4-0.6L293.4,281.3z"/>
<path className="st17" d="M293.3,281.4l-0.4-0.6l-0.1,0.1L293.3,281.4z M293.4,281.3l-0.1,0.1l-0.4-0.6L293.4,281.3z"/>
<path className="st16" d="M293.4,281.3l-0.1-0.8l-0.4,0.4L293.4,281.3z M293.8,281l-0.4,0.4l-0.1-0.8L293.8,281z"/>
<path className="st17" d="M293.4,281.3l-0.1-0.8l-0.4,0.4L293.4,281.3z M293.8,281l-0.4,0.4l-0.1-0.8L293.8,281z"/>
<path className="st16" d="M289.9,285l1.9-2.2l-2,2L289.9,285z M292,282.9l-2,2.2l1.9-2.2H292z"/>
<path className="st17" d="M289.9,285l1.9-2.2l-2,2L289.9,285z M292,282.9l-2,2.2l1.9-2.2H292z"/>
<path className="st16" d="M292.9,282l1.1-1.3l-1.2,1.2L292.9,282z M294.1,280.8l-1.2,1.2l1.1-1.3L294.1,280.8z"/>
<path className="st17" d="M292.9,282l1.1-1.3l-1.2,1.2L292.9,282z M294.1,280.8l-1.2,1.2l1.1-1.3L294.1,280.8z"/>
<path className="st16" d="M289.9,285.2l2.5-2.8l-2.5,2.6V285.2z M292.6,282.5l-2.6,2.6l2.5-2.8L292.6,282.5z"/>
<path className="st17" d="M289.9,285.2l2.5-2.8l-2.5,2.6V285.2z M292.6,282.5l-2.6,2.6l2.5-2.8L292.6,282.5z"/>
<path className="st16" d="M292.6,282.5l1.6-1.7l-1.7,1.6L292.6,282.5z M294.1,281l-1.6,1.6l1.6-1.7V281z"/>
<path className="st17" d="M292.6,282.5l1.6-1.7l-1.7,1.6L292.6,282.5z M294.1,281l-1.6,1.6l1.6-1.7V281z"/>
<line className="st19" x1="290.9" y1="281" x2="293.4" y2="283.4"/>
<line className="st19" x1="290.8" y1="282.9" x2="289.4" y2="284.1"/>
<line className="st19" x1="291.7" y1="282" x2="290.8" y2="283"/>
<line className="st19" x1="292.8" y1="281" x2="292.4" y2="281.3"/>
<polyline className="st19" points="293.8,281 293.3,280.5 292.9,280.8 293.4,281.3 "/>
<line className="st19" x1="292.8" y1="281" x2="293.3" y2="281.4"/>
<line className="st19" x1="292.4" y1="281.3" x2="292.9" y2="281.8"/>
<line className="st19" x1="290.8" y1="282.9" x2="291.2" y2="283.5"/>
<line className="st19" x1="289.4" y1="284.1" x2="290" y2="284.7"/>
<line className="st19" x1="291.8" y1="281.6" x2="291.5" y2="281.8"/>
<path className="st19" d="M292.1,282.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C292,282.3,292.1,282.3,292.1,282.2"/>
<line className="st19" x1="291.8" y1="281.6" x2="292.2" y2="282"/>
<line className="st19" x1="291.5" y1="281.8" x2="292.1" y2="282.3"/>
<polyline className="st19" points="292.8,281.9 292.9,282 292,282.9 291.8,282.9 "/>
<path className="st19" d="M291.4,281.5c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C291.2,281.6,291.4,281.6,291.4,281.5"/>
<path className="st19" d="M289.6,281.1c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7"/>
<path className="st19" d="M292.6,279.8c-0.2-0.2-0.5-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3"/>
<path className="st19" d="M292.3,280c-0.1-0.2-0.3-0.2-0.5-0.3c-0.2,0-0.4,0.1-0.5,0.2"/>
<path className="st19" d="M289.9,281.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.3,0.2,0.5"/>
<line className="st19" x1="291.4" y1="280" x2="289.9" y2="281.4"/>
<line className="st19" x1="290" y1="282.4" x2="290.6" y2="283"/>
<polyline className="st19" points="292.3,280 293,280.7 292.1,281.8 "/>
<line className="st19" x1="291.1" y1="279.8" x2="289.8" y2="281.2"/>
<path className="st19" d="M289.6,282.4c-0.3,0.1-0.6,0.4-0.6,0.7c-0.1,0.3,0.1,0.7,0.4,0.9"/>
<line className="st19" x1="294" y1="280.7" x2="289.8" y2="284.9"/>
<polyline className="st19" points="294,280.7 294.1,281 289.9,285.2 289.8,284.9 "/>
<line className="st19" x1="289.8" y1="282.5" x2="290.4" y2="283.2"/>
<line className="st19" x1="292.7" y1="279.9" x2="293.8" y2="281"/>
<path className="st20" d="M292,282.2v0.1V282.2z"/>
<path className="st21" d="M292,282.2v0.1V282.2z"/>
<path className="st20" d="M292,282.2v0.1V282.2z"/>
<path className="st21" d="M292,282.2v0.1V282.2z"/>
<path className="st20" d="M292,282.2v0.1V282.2z"/>
<path className="st21" d="M292,282.2v0.1V282.2z"/>
<path className="st20" d="M292.1,282.3H292H292.1z"/>
<path className="st21" d="M292.1,282.3H292H292.1z"/>
<path className="st20" d="M292,282.3h0.1H292z"/>
<path className="st21" d="M292,282.3h0.1H292z"/>
<path className="st20" d="M292,282.3L292,282.3z"/>
<path className="st21" d="M292,282.3L292,282.3z"/>
<path className="st16" d="M229.8,226.2l0.5-0.6l-0.5,0.5V226.2z M230.3,225.8l-0.5,0.5l0.5-0.6V225.8z"/>
<path className="st22" d="M229.8,226.2l0.5-0.6l-0.5,0.5V226.2z M230.3,225.8l-0.5,0.5l0.5-0.6V225.8z"/>
<path className="st16" d="M231.5,227.6l-1.3-1.7l-0.2,0.2L231.5,227.6z M231.7,227.4l-0.2,0.1l-1.3-1.7L231.7,227.4z"/>
<path className="st22" d="M231.5,227.6l-1.3-1.7l-0.2,0.2L231.5,227.6z M231.7,227.4l-0.2,0.1l-1.3-1.7L231.7,227.4z"/>
<path className="st16" d="M231.4,227.8l0.4-0.5l-0.4,0.4V227.8z M231.8,227.3l-0.5,0.5l0.4-0.5H231.8z"/>
<path className="st22" d="M231.4,227.8l0.4-0.5l-0.4,0.4V227.8z M231.8,227.3l-0.5,0.5l0.4-0.5H231.8z"/>
<path className="st16" d="M231.8,227.3l1.4-1.6l-1.6,1.6H231.8z M233.4,225.9l-1.6,1.4l1.4-1.6L233.4,225.9z"/>
<path className="st22" d="M231.8,227.3l1.4-1.6l-1.6,1.6H231.8z M233.4,225.9l-1.6,1.4l1.4-1.6L233.4,225.9z"/>
<path className="st16" d="M231.4,227.9l0.5-0.6l-0.5,0.5V227.9z M231.8,227.4l-0.5,0.5l0.5-0.6V227.4z"/>
<path className="st22" d="M231.4,227.9l0.5-0.6l-0.5,0.5V227.9z M231.8,227.4l-0.5,0.5l0.5-0.6V227.4z"/>
<path className="st16" d="M231.5,227.9l0.4-0.5l-0.5,0.5H231.5z M232,227.4l-0.5,0.5l0.4-0.5H232z"/>
<path className="st22" d="M231.5,227.9l0.4-0.5l-0.5,0.5H231.5z M232,227.4l-0.5,0.5l0.4-0.5H232z"/>
<path className="st16" d="M231.6,227.9l0.4-0.5l-0.5,0.5H231.6z M232,227.6l-0.4,0.4l0.4-0.5V227.6z"/>
<path className="st22" d="M231.6,227.9l0.4-0.5l-0.5,0.5H231.6z M232,227.6l-0.4,0.4l0.4-0.5V227.6z"/>
<path className="st16" d="M232,227.6l1.4-1.7l-1.6,1.4L232,227.6z M233.5,226l-1.6,1.6l1.4-1.7L233.5,226z"/>
<path className="st22" d="M232,227.6l1.4-1.7l-1.6,1.4L232,227.6z M233.5,226l-1.6,1.6l1.4-1.7L233.5,226z"/>
<path className="st16" d="M232.8,229.1l-1.1-1.3l-0.1,0.1L232.8,229.1z M232.9,229.1h-0.1l-1.1-1.3L232.9,229.1z"/>
<path className="st22" d="M232.8,229.1l-1.1-1.3l-0.1,0.1L232.8,229.1z M232.9,229.1h-0.1l-1.1-1.3L232.9,229.1z"/>
<path className="st16" d="M232.9,229.1l-1.1-1.4l-0.1,0.1L232.9,229.1z M233.2,228.9l-0.2,0.2l-1.1-1.4L233.2,228.9z"/>
<path className="st22" d="M232.9,229.1l-1.1-1.4l-0.1,0.1L232.9,229.1z M233.2,228.9l-0.2,0.2l-1.1-1.4L233.2,228.9z"/>
<path className="st16" d="M233.3,228.8l0.2-2.8l-1.6,1.6L233.3,228.8z M234.7,227.2l-1.4,1.6l0.2-2.8L234.7,227.2z"/>
<path className="st22" d="M233.3,228.8l0.2-2.8l-1.6,1.6L233.3,228.8z M234.7,227.2l-1.4,1.6l0.2-2.8L234.7,227.2z"/>
<path className="st16" d="M232.8,229.2l0.5-0.5l-0.5,0.4V229.2z M232.8,229.2L232.8,229.2l0.5-0.5L232.8,229.2z"/>
<path className="st22" d="M232.8,229.2l0.5-0.5l-0.5,0.4V229.2z M232.8,229.2L232.8,229.2l0.5-0.5L232.8,229.2z"/>
<path className="st16" d="M232.8,229.4l0.5-0.6l-0.5,0.5V229.4z M233.3,228.9l-0.5,0.5l0.5-0.6V228.9z"/>
<path className="st22" d="M232.8,229.4l0.5-0.6l-0.5,0.5V229.4z M233.3,228.9l-0.5,0.5l0.5-0.6V228.9z"/>
<path className="st16" d="M232.9,229.4l0.4-0.5l-0.5,0.5H232.9z M233.4,229l-0.5,0.4l0.4-0.5L233.4,229z"/>
<path className="st22" d="M232.9,229.4l0.4-0.5l-0.5,0.5H232.9z M233.4,229l-0.5,0.4l0.4-0.5L233.4,229z"/>
<path className="st16" d="M233,229.4l0.4-0.4l-0.5,0.4H233z M233,229.4L233,229.4l0.4-0.4L233,229.4z"/>
<path className="st22" d="M233,229.4l0.4-0.4l-0.5,0.4H233z M233,229.4L233,229.4l0.4-0.4L233,229.4z"/>
<path className="st16" d="M233.4,229l1.3-1.8l-1.4,1.6L233.4,229z M235,227.4l-1.6,1.6l1.3-1.8L235,227.4z"/>
<path className="st22" d="M233.4,229l1.3-1.8l-1.4,1.6L233.4,229z M235,227.4l-1.6,1.6l1.3-1.8L235,227.4z"/>
<path className="st16" d="M233.8,229.8l-0.5-0.8l-0.1,0.2L233.8,229.8z M233.9,229.6l-0.1,0.2l-0.5-0.8L233.9,229.6z"/>
<path className="st22" d="M233.8,229.8l-0.5-0.8l-0.1,0.2L233.8,229.8z M233.9,229.6l-0.1,0.2l-0.5-0.8L233.9,229.6z"/>
<path className="st16" d="M233.8,230l0.1-0.4l-0.1,0.2V230z M234,229.7l-0.2,0.2l0.1-0.4L234,229.7z"/>
<path className="st22" d="M233.8,230l0.1-0.4l-0.1,0.2V230z M234,229.7l-0.2,0.2l0.1-0.4L234,229.7z"/>
<path className="st16" d="M233.8,230.1l0.2-0.4l-0.2,0.2V230.1z M234,229.8l-0.2,0.2l0.2-0.4V229.8z"/>
<path className="st22" d="M233.8,230.1l0.2-0.4l-0.2,0.2V230.1z M234,229.8l-0.2,0.2l0.2-0.4V229.8z"/>
<path className="st16" d="M233.5,230.6l-0.1-0.4l-0.1,0.1L233.5,230.6z M233.6,230.4l-0.1,0.1l-0.1-0.4L233.6,230.4z"/>
<path className="st22" d="M233.5,230.6l-0.1-0.4l-0.1,0.1L233.5,230.6z M233.6,230.4l-0.1,0.1l-0.1-0.4L233.6,230.4z"/>
<path className="st16" d="M233.6,230.4l0.4-0.6l-0.5,0.5L233.6,230.4z M234,230l-0.4,0.5l0.4-0.6V230z"/>
<path className="st22" d="M233.6,230.4l0.4-0.6l-0.5,0.5L233.6,230.4z M234,230l-0.4,0.5l0.4-0.6V230z"/>
<path className="st16" d="M233.5,230.6L233.5,230.6l0.1-0.1L233.5,230.6z M233.6,230.4l-0.1,0.1L233.6,230.4z"/>
<path className="st22" d="M233.5,230.6L233.5,230.6l0.1-0.1L233.5,230.6z M233.6,230.4l-0.1,0.1L233.6,230.4z"/>
<path className="st16" d="M233.6,230.4L233.6,230.4l0.4-0.5L233.6,230.4z M234,230l-0.4,0.5L234,230z"/>
<path className="st22" d="M233.6,230.4L233.6,230.4l0.4-0.5L233.6,230.4z M234,230l-0.4,0.5L234,230z"/>
<path className="st16" d="M233.6,230.6l0.4-0.6l-0.4,0.5V230.6z M233.9,230.3l-0.2,0.2l0.4-0.6L233.9,230.3z"/>
<path className="st22" d="M233.6,230.6l0.4-0.6l-0.4,0.5V230.6z M233.9,230.3l-0.2,0.2l0.4-0.6L233.9,230.3z"/>
<path className="st16" d="M233.8,230.7l-0.1-0.2l-0.1,0.1L233.8,230.7z M233.9,230.7h-0.1l-0.1-0.2L233.9,230.7z"/>
<path className="st22" d="M233.8,230.7l-0.1-0.2l-0.1,0.1L233.8,230.7z M233.9,230.7h-0.1l-0.1-0.2L233.9,230.7z"/>
<line className="st18" x1="231.5" y1="227.6" x2="229.9" y2="226.1"/>
<line className="st18" x1="231.7" y1="227.4" x2="230.2" y2="225.9"/>
<line className="st18" x1="233.9" y1="229.6" x2="233.3" y2="229"/>
<line className="st18" x1="233.8" y1="229.8" x2="233.2" y2="229.2"/>
<line className="st18" x1="233.9" y1="230.3" x2="233.6" y2="230.6"/>
<line className="st18" x1="233.8" y1="230.1" x2="233.5" y2="230.3"/>
<path className="st18" d="M233.9,230.2c0.2-0.2,0.2-0.4,0-0.6"/>
<path className="st18" d="M233.7,230c0.1-0.1,0.1-0.2,0-0.3"/>
<line className="st18" x1="233.9" y1="230.7" x2="233.8" y2="230.7"/>
<line className="st18" x1="233.4" y1="230.2" x2="233.3" y2="230.3"/>
<polyline className="st18" points="233.9,230.7 233.6,230.4 233.4,230.2 "/>
<line className="st18" x1="230.3" y1="225.8" x2="230.3" y2="225.6"/>
<polyline className="st18" points="230.3,225.8 230,226 229.8,226.2 229.8,226.1 "/>
<polyline className="st18" points="233,229.4 233.3,229.1 235,227.4 233.3,225.8 231.7,227.4 231.4,227.7 "/>
<line className="st18" x1="232" y1="227.6" x2="231.6" y2="227.9"/>
<line className="st18" x1="233.3" y1="228.8" x2="232.8" y2="229.1"/>
<path className="st18" d="M232.7,229c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0"/>
<path className="st18" d="M231.3,227.6c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0"/>
<polyline className="st18" points="231.7,227.8 232.9,229.1 231.7,227.8 "/>
<line className="st18" x1="233.2" y1="228.9" x2="231.8" y2="227.7"/>
<line className="st18" x1="233.4" y1="229" x2="231.7" y2="227.3"/>
<polyline className="st18" points="231.8,227.7 233.2,228.9 231.8,227.7 "/>
<line className="st18" x1="232.8" y1="229.1" x2="231.6" y2="227.9"/>
<path className="st16" d="M358.6,383.1l-1.7-1.7L358.6,383.1z M356.9,381.4l1.7,1.7L356.9,381.4z M356.9,381.4L356.9,381.4l1.7,1.7
	L356.9,381.4z M358.6,383.1l-1.7-1.7L358.6,383.1z"/>
<path className="st17" d="M358.6,383.1l-1.7-1.7L358.6,383.1z M356.9,381.4l1.7,1.7L356.9,381.4z M356.9,381.4L356.9,381.4l1.7,1.7
	L356.9,381.4z M358.6,383.1l-1.7-1.7L358.6,383.1z"/>
<path className="st16" d="M356.8,381.4l1.8,1.7l-1.7-1.7H356.8z M358.6,383.2l-1.8-1.8l1.8,1.7V383.2z M356.8,381.4l1.8,1.8L356.8,381.4
	z M356.8,381.4L356.8,381.4l1.8,1.8L356.8,381.4z"/>
<path className="st17" d="M356.8,381.4l1.8,1.7l-1.7-1.7H356.8z M358.6,383.2l-1.8-1.8l1.8,1.7V383.2z M356.8,381.4l1.8,1.8L356.8,381.4
	z M356.8,381.4L356.8,381.4l1.8,1.8L356.8,381.4z"/>
<path className="st16" d="M355.8,382.5l2.8,0.7l-1.8-1.8L355.8,382.5z M357.5,384.3l-1.7-1.8l2.8,0.7L357.5,384.3z"/>
<path className="st17" d="M355.8,382.5l2.8,0.7l-1.8-1.8L355.8,382.5z M357.5,384.3l-1.7-1.8l2.8,0.7L357.5,384.3z"/>
<path className="st16" d="M355.6,382.7l1.1,0.7l-0.8-1L355.6,382.7z M356,383.1l-0.5-0.4l1.1,0.7L356,383.1z"/>
<path className="st17" d="M355.6,382.7l1.1,0.7l-0.8-1L355.6,382.7z M356,383.1l-0.5-0.4l1.1,0.7L356,383.1z"/>
<path className="st16" d="M356.9,384l0.6,0.2l-0.8-0.8L356.9,384z M357.4,384.4l-0.5-0.4l0.6,0.2L357.4,384.4z"/>
<path className="st17" d="M356.9,384l0.6,0.2l-0.8-0.8L356.9,384z M357.4,384.4l-0.5-0.4l0.6,0.2L357.4,384.4z"/>
<path className="st16" d="M355.6,382.7L355.6,382.7l0.5,0.4L355.6,382.7z M356,383.1l-0.5-0.4L356,383.1z"/>
<path className="st17" d="M355.6,382.7L355.6,382.7l0.5,0.4L355.6,382.7z M356,383.1l-0.5-0.4L356,383.1z"/>
<path className="st16" d="M356.2,383.6L356.2,383.6l0.4,0.2L356.2,383.6z M356.5,383.8l-0.4-0.2L356.5,383.8z M356.2,383.6l0.4,0.2
	L356.2,383.6z M356.2,383.6L356.2,383.6l0.4,0.2L356.2,383.6z M356.5,383.8l-0.4-0.2L356.5,383.8z M356.5,383.9v-0.1l-0.4-0.2
	L356.5,383.9z"/>
<path className="st17" d="M356.2,383.6L356.2,383.6l0.4,0.2L356.2,383.6z M356.5,383.8l-0.4-0.2L356.5,383.8z M356.2,383.6l0.4,0.2
	L356.2,383.6z M356.2,383.6L356.2,383.6l0.4,0.2L356.2,383.6z M356.5,383.8l-0.4-0.2L356.5,383.8z M356.5,383.9v-0.1l-0.4-0.2
	L356.5,383.9z"/>
<path className="st16" d="M356.2,383.6L356.2,383.6l0.4,0.4L356.2,383.6z M356.5,383.9l-0.4-0.4L356.5,383.9z"/>
<path className="st17" d="M356.2,383.6L356.2,383.6l0.4,0.4L356.2,383.6z M356.5,383.9l-0.4-0.4L356.5,383.9z"/>
<path className="st16" d="M356.3,384l-0.4-0.4l0.1,0.2L356.3,384z M356.5,383.9l-0.2,0.1l-0.4-0.4L356.5,383.9z"/>
<path className="st17" d="M356.3,384l-0.4-0.4l0.1,0.2L356.3,384z M356.5,383.9l-0.2,0.1l-0.4-0.4L356.5,383.9z"/>
<polygon className="st16" points="356.5,383.9 356.2,383.6 355.9,383.7 "/>
<polygon className="st17" points="356.5,383.9 356.2,383.6 355.9,383.7 "/>
<path className="st16" d="M356.6,384.6l0.7-0.2l-0.5-0.4L356.6,384.6z M356.9,384.9l-0.2-0.2l0.7-0.2L356.9,384.9z"/>
<path className="st17" d="M356.6,384.6l0.7-0.2l-0.5-0.4L356.6,384.6z M356.9,384.9l-0.2-0.2l0.7-0.2L356.9,384.9z"/>
<path className="st16" d="M355.2,383.1h0.8l-0.5-0.4L355.2,383.1z M355.4,383.3l-0.2-0.2h0.8L355.4,383.3z"/>
<path className="st17" d="M355.2,383.1h0.8l-0.5-0.4L355.2,383.1z M355.4,383.3l-0.2-0.2h0.8L355.4,383.3z"/>
<path className="st16" d="M354.8,383.4l0.6-0.1l-0.2-0.2L354.8,383.4z M355.1,383.7l-0.2-0.2l0.6-0.1L355.1,383.7z"/>
<path className="st17" d="M354.8,383.4l0.6-0.1l-0.2-0.2L354.8,383.4z M355.1,383.7l-0.2-0.2l0.6-0.1L355.1,383.7z"/>
<path className="st16" d="M356.3,385l0.6-0.1l-0.2-0.2L356.3,385z M356.5,385.1l-0.2-0.1l0.6-0.1L356.5,385.1z"/>
<path className="st17" d="M356.3,385l0.6-0.1l-0.2-0.2L356.3,385z M356.5,385.1l-0.2-0.1l0.6-0.1L356.5,385.1z"/>
<path className="st16" d="M355.7,385.2l0.8-0.1l-0.2-0.1L355.7,385.2z M356,385.5l-0.4-0.2l0.8-0.1L356,385.5z"/>
<path className="st17" d="M355.7,385.2l0.8-0.1l-0.2-0.1L355.7,385.2z M356,385.5l-0.4-0.2l0.8-0.1L356,385.5z"/>
<path className="st16" d="M354.5,384l0.6-0.4l-0.2-0.2L354.5,384z M354.7,384.3l-0.2-0.2l0.6-0.4L354.7,384.3z M354.5,384l0.2,0.2
	L354.5,384z M354.5,384L354.5,384l0.2,0.2L354.5,384z"/>
<path className="st17" d="M354.5,384l0.6-0.4l-0.2-0.2L354.5,384z M354.7,384.3l-0.2-0.2l0.6-0.4L354.7,384.3z M354.5,384l0.2,0.2
	L354.5,384z M354.5,384L354.5,384l0.2,0.2L354.5,384z"/>
<path className="st16" d="M355.7,385.2l0.4,0.2L355.7,385.2z M355.9,385.5l-0.2-0.2l0.4,0.2H355.9z"/>
<path className="st17" d="M355.7,385.2l0.4,0.2L355.7,385.2z M355.9,385.5l-0.2-0.2l0.4,0.2H355.9z"/>
<path className="st16" d="M354.5,384.4l0.2-0.1l-0.2-0.2V384.4z M355.1,385l-0.6-0.6l0.2-0.1L355.1,385z"/>
<path className="st17" d="M354.5,384.4l0.2-0.1l-0.2-0.2V384.4z M355.1,385l-0.6-0.6l0.2-0.1L355.1,385z"/>
<path className="st16" d="M355.1,385l0.8,0.5l-0.2-0.2L355.1,385z M355.6,385.5l-0.5-0.5l0.8,0.5H355.6z"/>
<path className="st17" d="M355.1,385l0.8,0.5l-0.2-0.2L355.1,385z M355.6,385.5l-0.5-0.5l0.8,0.5H355.6z"/>
<path className="st16" d="M354.5,384.6l1.1,0.8l-1.1-1.1V384.6z M355.4,385.5l-1-0.8l1.1,0.8H355.4z M355.3,385.5h0.1l-1-0.8
	L355.3,385.5z"/>
<path className="st17" d="M354.5,384.6l1.1,0.8l-1.1-1.1V384.6z M355.4,385.5l-1-0.8l1.1,0.8H355.4z M355.3,385.5h0.1l-1-0.8
	L355.3,385.5z"/>
<path className="st16" d="M355.4,385.5l-1-0.8L355.4,385.5z"/>
<path className="st17" d="M355.4,385.5l-1-0.8L355.4,385.5z"/>
<path className="st16" d="M355.2,385.4l-0.7-0.6l0.4,0.5L355.2,385.4z M355.3,385.5l-0.1-0.1l-0.7-0.6L355.3,385.5z"/>
<path className="st17" d="M355.2,385.4l-0.7-0.6l0.4,0.5L355.2,385.4z M355.3,385.5l-0.1-0.1l-0.7-0.6L355.3,385.5z"/>
<polygon className="st16" points="355.3,385.5 354.6,384.6 354.5,384.8 "/>
<polygon className="st17" points="355.3,385.5 354.6,384.6 354.5,384.8 "/>
<path className="st19" d="M356.9,381.4C356.8,381.4,356.8,381.4,356.9,381.4"/>
<line className="st19" x1="356.9" y1="381.4" x2="358.6" y2="383.1"/>
<path className="st19" d="M358.4,383.1C358.4,383,358.4,383,358.4,383.1"/>
<line className="st19" x1="358.6" y1="383.2" x2="356.5" y2="385.1"/>
<path className="st19" d="M354.7,383.3c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="354.8" y1="383.4" x2="356.8" y2="381.4"/>
<path className="st19" d="M356.1,383.5C356.1,383.4,356.1,383.4,356.1,383.5"/>
<line className="st19" x1="356.2" y1="383.6" x2="355.9" y2="383.7"/>
<path className="st19" d="M355.8,383.6c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="356.3" y1="384" x2="356.5" y2="383.9"/>
<path className="st19" d="M356.4,383.8C356.4,383.8,356.4,383.7,356.4,383.8"/>
<line className="st19" x1="356.5" y1="383.8" x2="356.2" y2="383.6"/>
<line className="st19" x1="356.6" y1="384.6" x2="356.3" y2="385"/>
<path className="st19" d="M355,383.6c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="355.1" y1="383.7" x2="355.4" y2="383.3"/>
<path className="st19" d="M356.5,384.6c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<line className="st1" x1="359.9" y1="384.4" x2="355.7" y2="380.2"/>
<line className="st1" x1="20.9" y1="179.8" x2="29.3" y2="171.4"/>
<line className="st1" x1="28.2" y1="170.3" x2="19.8" y2="178.7"/>
<line className="st5" x1="54.7" y1="220.2" x2="54.5" y2="220.4"/>
<polyline className="st5" points="54.5,220.4 60.1,226 60.2,225.8 54.7,220.2 "/>
<path className="st5" d="M60.2,225.7c2.9-3.1,2.8-7.9-0.2-10.9"/>
<path className="st5" d="M46.3,203.7c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="38.2,203.7 37.9,203.8 42.1,208 42.4,207.9 38.2,203.7 "/>
<polyline className="st18" points="68.3,216.9 74.8,210.4 69.7,205.2 63.1,211.7 68.3,216.9 "/>
<line className="st18" x1="63.1" y1="211.7" x2="68" y2="211.1"/>
<line className="st18" x1="69.1" y1="210" x2="69.7" y2="205.2"/>
<line className="st18" x1="70.2" y1="211.1" x2="74.8" y2="210.4"/>
<polyline className="st18" points="69.1,212.3 68,211.1 69.1,210 70.2,211.1 69.1,212.3 68.3,216.9 "/>
<line className="st18" x1="69.1" y1="211.8" x2="69.8" y2="211.2"/>
<line className="st18" x1="69" y1="211.7" x2="69.7" y2="211.1"/>
<line className="st18" x1="68.9" y1="211.6" x2="69.5" y2="211"/>
<line className="st18" x1="68.8" y1="211.5" x2="69.4" y2="210.9"/>
<line className="st18" x1="68.6" y1="211.4" x2="69.2" y2="210.8"/>
<line className="st18" x1="68.5" y1="211.2" x2="69.1" y2="210.6"/>
<line className="st18" x1="68.4" y1="211.1" x2="69" y2="210.4"/>
<path className="st16" d="M140,199.6v-2.3V199.6z M140,197.2v2.4v-2.3V197.2z M140,199.6v-2.4V199.6z M140,199.6L140,199.6v-2.4V199.6z"
	/>
<path className="st17" d="M140,199.6v-2.3V199.6z M140,197.2v2.4v-2.3V197.2z M140,199.6v-2.4V199.6z M140,199.6L140,199.6v-2.4V199.6z"
	/>
<path className="st16" d="M140.2,199.6l-0.1-2.4v2.4H140.2z M140.2,197.2v2.4l-0.1-2.4H140.2z M140.2,199.6v-2.4V199.6z M140.2,199.6
	L140.2,199.6v-2.4V199.6z"/>
<path className="st17" d="M140.2,199.6l-0.1-2.4v2.4H140.2z M140.2,197.2v2.4l-0.1-2.4H140.2z M140.2,199.6v-2.4V199.6z M140.2,199.6
	L140.2,199.6v-2.4V199.6z"/>
<path className="st16" d="M141.6,199.6l-1.4-2.4v2.4H141.6z M141.6,197.2v2.4l-1.4-2.4H141.6z"/>
<path className="st17" d="M141.6,199.6l-1.4-2.4v2.4H141.6z M141.6,197.2v2.4l-1.4-2.4H141.6z"/>
<path className="st16" d="M141.8,199.6l-0.2-1.2v1.2H141.8z M141.8,199v0.6l-0.2-1.2L141.8,199z"/>
<path className="st17" d="M141.8,199.6l-0.2-1.2v1.2H141.8z M141.8,199v0.6l-0.2-1.2L141.8,199z"/>
<path className="st16" d="M141.8,197.8l-0.2-0.6v1.2L141.8,197.8z M141.8,197.2v0.6l-0.2-0.6H141.8z"/>
<path className="st17" d="M141.8,197.8l-0.2-0.6v1.2L141.8,197.8z M141.8,197.2v0.6l-0.2-0.6H141.8z"/>
<path className="st16" d="M141.8,199.6L141.8,199.6V199V199.6z M141.8,199v0.6V199z"/>
<path className="st17" d="M141.8,199.6L141.8,199.6V199V199.6z M141.8,199v0.6V199z"/>
<path className="st16" d="M142.1,198.6v-0.4V198.6z M142.1,198.2v0.5v-0.4V198.2z M142.1,198.6v-0.5V198.6z M142.1,198.6L142.1,198.6
	v-0.5V198.6z M142.1,198.2v0.5V198.2z M142.1,198.6v-0.5V198.6z M142.1,198.6L142.1,198.6v-0.5V198.6z M142.1,198.2v0.5V198.2z"/>
<path className="st17" d="M142.1,198.6v-0.4V198.6z M142.1,198.2v0.5v-0.4V198.2z M142.1,198.6v-0.5V198.6z M142.1,198.6L142.1,198.6
	v-0.5V198.6z M142.1,198.2v0.5V198.2z M142.1,198.6v-0.5V198.6z M142.1,198.6L142.1,198.6v-0.5V198.6z M142.1,198.2v0.5V198.2z"/>
<path className="st16" d="M142.4,198.2v0.5v-0.2V198.2z M142.1,198.2h0.4v0.5L142.1,198.2z"/>
<path className="st17" d="M142.4,198.2v0.5v-0.2V198.2z M142.1,198.2h0.4v0.5L142.1,198.2z"/>
<polygon className="st16" points="142.1,198.2 142.1,198.6 142.4,198.6 "/>
<polygon className="st17" points="142.1,198.2 142.1,198.6 142.4,198.6 "/>
<path className="st16" d="M142.4,197.6l-0.6-0.4v0.6L142.4,197.6z M142.4,197.2v0.4l-0.6-0.4H142.4z"/>
<path className="st17" d="M142.4,197.6l-0.6-0.4v0.6L142.4,197.6z M142.4,197.2v0.4l-0.6-0.4H142.4z"/>
<path className="st16" d="M142.4,199.6l-0.6-0.6v0.6H142.4z M142.4,199.4v0.2l-0.6-0.6L142.4,199.4z"/>
<path className="st17" d="M142.4,199.6l-0.6-0.6v0.6H142.4z M142.4,199.4v0.2l-0.6-0.6L142.4,199.4z"/>
<path className="st16" d="M142.9,199.6l-0.5-0.2v0.2H142.9z M142.9,199.4v0.2l-0.5-0.2H142.9z"/>
<path className="st17" d="M142.9,199.6l-0.5-0.2v0.2H142.9z M142.9,199.4v0.2l-0.5-0.2H142.9z"/>
<path className="st16" d="M142.9,197.6l-0.5-0.4v0.4H142.9z M142.9,197.2v0.4l-0.5-0.4H142.9z"/>
<path className="st17" d="M142.9,197.6l-0.5-0.4v0.4H142.9z M142.9,197.2v0.4l-0.5-0.4H142.9z"/>
<path className="st16" d="M143.5,197.8l-0.6-0.6v0.4L143.5,197.8z M143.5,197.3v0.5l-0.6-0.6L143.5,197.3z"/>
<path className="st17" d="M143.5,197.8l-0.6-0.6v0.4L143.5,197.8z M143.5,197.3v0.5l-0.6-0.6L143.5,197.3z"/>
<path className="st16" d="M143.6,199.5l-0.7-0.1v0.2L143.6,199.5z M143.6,199.1v0.4l-0.7-0.1L143.6,199.1z M143.6,199.5v-0.4V199.5z
	 M143.6,199.5L143.6,199.5v-0.4V199.5z"/>
<path className="st17" d="M143.6,199.5l-0.7-0.1v0.2L143.6,199.5z M143.6,199.1v0.4l-0.7-0.1L143.6,199.1z M143.6,199.5v-0.4V199.5z
	 M143.6,199.5L143.6,199.5v-0.4V199.5z"/>
<path className="st16" d="M143.6,197.8l-0.1-0.5v0.5H143.6z M143.6,197.4v0.4l-0.1-0.5L143.6,197.4z"/>
<path className="st17" d="M143.6,197.8l-0.1-0.5v0.5H143.6z M143.6,197.4v0.4l-0.1-0.5L143.6,197.4z"/>
<path className="st16" d="M143.9,199.1h-0.2v0.4L143.9,199.1z M143.9,198.4v0.7h-0.2L143.9,198.4z"/>
<path className="st17" d="M143.9,199.1h-0.2v0.4L143.9,199.1z M143.9,198.4v0.7h-0.2L143.9,198.4z"/>
<path className="st16" d="M143.9,198.4l-0.2-1v0.4L143.9,198.4z M143.9,197.7v0.7l-0.2-1L143.9,197.7z"/>
<path className="st17" d="M143.9,198.4l-0.2-1v0.4L143.9,198.4z M143.9,197.7v0.7l-0.2-1L143.9,197.7z"/>
<path className="st16" d="M144,199l-0.1-1.3v1.4L144,199z M144,197.8v1.2l-0.1-1.3L144,197.8z M144,197.9v-0.1v1.2V197.9z"/>
<path className="st17" d="M144,199l-0.1-1.3v1.4L144,199z M144,197.8v1.2l-0.1-1.3L144,197.8z M144,197.9v-0.1v1.2V197.9z"/>
<path className="st16" d="M144,197.8v1.2V197.8z"/>
<path className="st17" d="M144,197.8v1.2V197.8z"/>
<path className="st16" d="M144.1,198v1l0.1-0.6L144.1,198z M144,197.9l0.1,0.1v1L144,197.9z"/>
<path className="st17" d="M144.1,198v1l0.1-0.6L144.1,198z M144,197.9l0.1,0.1v1L144,197.9z"/>
<polygon className="st16" points="144,197.9 144,199 144.1,199 "/>
<polygon className="st17" points="144,197.9 144,199 144.1,199 "/>
<path className="st19" d="M140,199.5C140,199.6,140.1,199.6,140,199.5"/>
<line className="st19" x1="140" y1="199.6" x2="140" y2="197.3"/>
<path className="st19" d="M140.1,197.2C140.1,197.2,140,197.2,140.1,197.2"/>
<line className="st19" x1="140.2" y1="197.2" x2="142.9" y2="197.2"/>
<path className="st19" d="M142.9,199.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3"/>
<line className="st19" x1="142.9" y1="199.6" x2="140.2" y2="199.6"/>
<path className="st19" d="M142,198.6C142,198.6,142,198.6,142,198.6"/>
<line className="st19" x1="142.1" y1="198.6" x2="142.4" y2="198.6"/>
<path className="st19" d="M142.4,198.6c0.1-0.2,0.1-0.4,0-0.6"/>
<line className="st19" x1="142.4" y1="198.2" x2="142.1" y2="198.2"/>
<path className="st19" d="M142,198.2C142,198.2,142,198.2,142,198.2"/>
<line className="st19" x1="142.1" y1="198.3" x2="142.1" y2="198.6"/>
<line className="st19" x1="142.4" y1="197.6" x2="142.9" y2="197.6"/>
<path className="st19" d="M142.9,199.2c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9"/>
<line className="st19" x1="142.9" y1="199.4" x2="142.4" y2="199.4"/>
<path className="st19" d="M142.4,197.4c-0.5,0-0.8,0.4-0.8,0.9s0.4,0.9,0.8,0.9"/>
<line className="st1" x1="140" y1="201.4" x2="140" y2="187.1"/>
<path className="st16" d="M170,233.3l0.1,2.4v-1.9L170,233.3z M170,236.2v-2.9l0.1,2.4L170,236.2z"/>
<path className="st17" d="M170,233.3l0.1,2.4v-1.9L170,233.3z M170,236.2v-2.9l0.1,2.4L170,236.2z"/>
<path className="st16" d="M170,233.2v3v-2.9V233.2z M170,236.3v-3.1v3V236.3z"/>
<path className="st17" d="M170,233.2v3v-2.9V233.2z M170,236.3v-3.1v3V236.3z"/>
<path className="st16" d="M169.9,233.1l0.1,3.2v-3.1L169.9,233.1z M169.9,236.4v-3.4l0.1,3.2L169.9,236.4z"/>
<path className="st17" d="M169.9,233.1l0.1,3.2v-3.1L169.9,233.1z M169.9,236.4v-3.4l0.1,3.2L169.9,236.4z"/>
<path className="st16" d="M169.8,233l0.1,0.8v-0.7L169.8,233z M169.8,233.4V233l0.1,0.8L169.8,233.4z"/>
<path className="st17" d="M169.8,233l0.1,0.8v-0.7L169.8,233z M169.8,233.4V233l0.1,0.8L169.8,233.4z"/>
<path className="st16" d="M169.8,233.4l0.1,2.3v-1.9L169.8,233.4z M169.8,236.1v-2.6l0.1,2.3L169.8,236.1z"/>
<path className="st17" d="M169.8,233.4l0.1,2.3v-1.9L169.8,233.4z M169.8,236.1v-2.6l0.1,2.3L169.8,236.1z"/>
<path className="st16" d="M169.7,233l0.1,0.5V233H169.7z M169.7,233.3V233l0.1,0.5L169.7,233.3z"/>
<path className="st17" d="M169.7,233l0.1,0.5V233H169.7z M169.7,233.3V233l0.1,0.5L169.7,233.3z"/>
<path className="st16" d="M169.7,233.3l0.1,2.8v-2.6L169.7,233.3z M169.7,236.2v-2.9l0.1,2.8L169.7,236.2z"/>
<path className="st17" d="M169.7,233.3l0.1,2.8v-2.6L169.7,233.3z M169.7,236.2v-2.9l0.1,2.8L169.7,236.2z"/>
<path className="st16" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st17" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st16" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st17" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st16" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st17" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st16" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st17" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st16" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st17" d="M169.7,233L169.7,233v0.4V233z M169.7,233.3V233V233.3z"/>
<path className="st16" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st17" d="M169.7,233.3L169.7,233.3v2.9V233.3z M169.7,236.2v-2.9V236.2z"/>
<path className="st16" d="M169.7,236.2l0.2,0.2v-0.7L169.7,236.2z M169.7,236.7v-0.5l0.2,0.2L169.7,236.7z"/>
<path className="st17" d="M169.7,236.2l0.2,0.2v-0.7L169.7,236.2z M169.7,236.7v-0.5l0.2,0.2L169.7,236.7z"/>
<path className="st16" d="M169.7,233.3v2.9V233.3z M169.7,236.3v-3v2.9V236.3z"/>
<path className="st17" d="M169.7,233.3v2.9V233.3z M169.7,236.3v-3v2.9V236.3z"/>
<path className="st16" d="M169.7,236.3v0.4v-0.5V236.3z M169.7,236.3L169.7,236.3v0.4V236.3z"/>
<path className="st17" d="M169.7,236.3v0.4v-0.5V236.3z M169.7,236.3L169.7,236.3v0.4V236.3z"/>
<polygon className="st16" points="169.3,232.1 169.2,232.7 169.2,232 "/>
<polygon className="st17" points="169.3,232.1 169.2,232.7 169.2,232 "/>
<path className="st16" d="M169.3,233.2l0.4,3.1v-3L169.3,233.2z M169.3,236.3v-3.1l0.4,3.1H169.3z"/>
<path className="st17" d="M169.3,233.2l0.4,3.1v-3L169.3,233.2z M169.3,236.3v-3.1l0.4,3.1H169.3z"/>
<path className="st16" d="M169.3,236.3l0.4,0.4v-0.4H169.3z M169.3,236.7v-0.4l0.4,0.4H169.3z"/>
<path className="st17" d="M169.3,236.3l0.4,0.4v-0.4H169.3z M169.3,236.7v-0.4l0.4,0.4H169.3z"/>
<path className="st16" d="M169.2,232.7V232V232.7z M169.2,232v0.7V232z M169.2,232L169.2,232v0.7V232z M169.2,232.7V232V232.7z"/>
<path className="st17" d="M169.2,232.7V232V232.7z M169.2,232v0.7V232z M169.2,232L169.2,232v0.7V232z M169.2,232.7V232V232.7z"/>
<path className="st16" d="M169.2,232.7l0.5,0.6V233L169.2,232.7z M169.2,233.1v-0.4l0.5,0.6L169.2,233.1z"/>
<path className="st17" d="M169.2,232.7l0.5,0.6V233L169.2,232.7z M169.2,233.1v-0.4l0.5,0.6L169.2,233.1z"/>
<path className="st16" d="M169.2,232L169.2,232v0.7V232z M169.2,232.7V232V232.7z"/>
<path className="st17" d="M169.2,232L169.2,232v0.7V232z M169.2,232.7V232V232.7z"/>
<path className="st16" d="M169.2,232.7L169.2,232.7v0.4V232.7z M169.2,233.1v-0.4V233.1z"/>
<path className="st17" d="M169.2,232.7L169.2,232.7v0.4V232.7z M169.2,233.1v-0.4V233.1z"/>
<path className="st16" d="M169.2,233.1l0.1,3.2v-3.1L169.2,233.1z M169.2,236.4v-3.4l0.1,3.2L169.2,236.4z"/>
<path className="st17" d="M169.2,233.1l0.1,3.2v-3.1L169.2,233.1z M169.2,236.4v-3.4l0.1,3.2L169.2,236.4z"/>
<path className="st16" d="M169.1,231.9l0.1,0.8V232L169.1,231.9z M169.1,232.7v-0.8l0.1,0.8H169.1z"/>
<path className="st17" d="M169.1,231.9l0.1,0.8V232L169.1,231.9z M169.1,232.7v-0.8l0.1,0.8H169.1z"/>
<path className="st16" d="M169.1,232.7l0.1,0.4v-0.4H169.1z M169.1,233.1v-0.4l0.1,0.4H169.1z"/>
<path className="st17" d="M169.1,232.7l0.1,0.4v-0.4H169.1z M169.1,233.1v-0.4l0.1,0.4H169.1z"/>
<path className="st16" d="M169.1,233.1l0.1,3.4v-3.4H169.1z M169.1,236.4v-3.4l0.1,3.4H169.1z"/>
<path className="st17" d="M169.1,233.1l0.1,3.4v-3.4H169.1z M169.1,236.4v-3.4l0.1,3.4H169.1z"/>
<path className="st16" d="M169.1,236.4l0.2,0.2v-0.4L169.1,236.4z M169.1,236.8v-0.4l0.2,0.2L169.1,236.8z"/>
<path className="st17" d="M169.1,236.4l0.2,0.2v-0.4L169.1,236.4z M169.1,236.8v-0.4l0.2,0.2L169.1,236.8z"/>
<path className="st16" d="M169.1,231.9L169.1,231.9v0.8V231.9z M169.1,232.7v-0.8V232.7z"/>
<path className="st17" d="M169.1,231.9L169.1,231.9v0.8V231.9z M169.1,232.7v-0.8V232.7z"/>
<path className="st16" d="M169.1,232.7L169.1,232.7v0.4V232.7z M169.1,233.1v-0.4V233.1z"/>
<path className="st17" d="M169.1,232.7L169.1,232.7v0.4V232.7z M169.1,233.1v-0.4V233.1z"/>
<path className="st16" d="M169.1,233.1L169.1,233.1v3.4V233.1z M169.1,236.4v-3.4V236.4z"/>
<path className="st17" d="M169.1,233.1L169.1,233.1v3.4V233.1z M169.1,236.4v-3.4V236.4z"/>
<path className="st16" d="M169.1,236.4L169.1,236.4v0.4V236.4z M169.1,236.8v-0.4V236.8z"/>
<path className="st17" d="M169.1,236.4L169.1,236.4v0.4V236.4z M169.1,236.8v-0.4V236.8z"/>
<path className="st16" d="M169.1,231.8v1v-0.8V231.8z M169.1,231.8L169.1,231.8v1V231.8z"/>
<path className="st17" d="M169.1,231.8v1v-0.8V231.8z M169.1,231.8L169.1,231.8v1V231.8z"/>
<path className="st16" d="M169.1,232.7L169.1,232.7v0.4V232.7z M169.1,233.1v-0.4V233.1z"/>
<path className="st17" d="M169.1,232.7L169.1,232.7v0.4V232.7z M169.1,233.1v-0.4V233.1z"/>
<path className="st16" d="M169.1,233.1L169.1,233.1v3.4V233.1z M169.1,236.4v-3.4V236.4z"/>
<path className="st17" d="M169.1,233.1L169.1,233.1v3.4V233.1z M169.1,236.4v-3.4V236.4z"/>
<path className="st16" d="M169.1,236.4L169.1,236.4v0.4V236.4z M169.1,236.8v-0.4V236.8z"/>
<path className="st17" d="M169.1,236.4L169.1,236.4v0.4V236.4z M169.1,236.8v-0.4V236.8z"/>
<path className="st16" d="M169,231.6l0.1,1.1v-1L169,231.6z M169,232.7v-1.1l0.1,1.1H169z"/>
<path className="st17" d="M169,231.6l0.1,1.1v-1L169,231.6z M169,232.7v-1.1l0.1,1.1H169z"/>
<path className="st16" d="M169,232.7l0.1,0.4v-0.4H169z M169,233.1v-0.4l0.1,0.4H169z"/>
<path className="st17" d="M169,232.7l0.1,0.4v-0.4H169z M169,233.1v-0.4l0.1,0.4H169z"/>
<path className="st16" d="M169,233.1l0.1,1.7v-1.7H169z M169,234.6v-1.6l0.1,1.7L169,234.6z"/>
<path className="st17" d="M169,233.1l0.1,1.7v-1.7H169z M169,234.6v-1.6l0.1,1.7L169,234.6z"/>
<path className="st16" d="M169,234.9l0.1,1.6v-1.7L169,234.9z M169,236.4v-1.6l0.1,1.6H169z"/>
<path className="st17" d="M169,234.9l0.1,1.6v-1.7L169,234.9z M169,236.4v-1.6l0.1,1.6H169z"/>
<path className="st16" d="M169,236.4l0.1,0.4v-0.4H169z M169,236.8v-0.4l0.1,0.4H169z"/>
<path className="st17" d="M169,236.4l0.1,0.4v-0.4H169z M169,236.8v-0.4l0.1,0.4H169z"/>
<path className="st16" d="M169,231.6L169,231.6v1.1V231.6z M169,232.7v-1.1V232.7z"/>
<path className="st17" d="M169,231.6L169,231.6v1.1V231.6z M169,232.7v-1.1V232.7z"/>
<path className="st16" d="M169,232.7L169,232.7v0.4V232.7z M169,233.1v-0.4V233.1z"/>
<path className="st17" d="M169,232.7L169,232.7v0.4V232.7z M169,233.1v-0.4V233.1z"/>
<path className="st16" d="M168.8,231.6l0.1,1.1v-1.1H168.8z M168.8,232.7v-1.1l0.1,1.1H168.8z"/>
<path className="st17" d="M168.8,231.6l0.1,1.1v-1.1H168.8z M168.8,232.7v-1.1l0.1,1.1H168.8z"/>
<path className="st16" d="M168.8,232.7l0.1,0.4v-0.4H168.8z M168.8,233.1v-0.4l0.1,0.4H168.8z"/>
<path className="st17" d="M168.8,232.7l0.1,0.4v-0.4H168.8z M168.8,233.1v-0.4l0.1,0.4H168.8z"/>
<path className="st16" d="M168.8,233.1l0.1,1.6v-1.6H168.8z M168.8,234.5v-1.4l0.1,1.6L168.8,234.5z"/>
<path className="st17" d="M168.8,233.1l0.1,1.6v-1.6H168.8z M168.8,234.5v-1.4l0.1,1.6L168.8,234.5z"/>
<path className="st16" d="M168.8,235l0.1,1.4v-1.6L168.8,235z M168.8,236.4V235l0.1,1.4H168.8z"/>
<path className="st17" d="M168.8,235l0.1,1.4v-1.6L168.8,235z M168.8,236.4V235l0.1,1.4H168.8z"/>
<path className="st16" d="M168.7,233.1l0.1,1.4v-1.4H168.7z M168.7,234.6v-1.6l0.1,1.4L168.7,234.6z"/>
<path className="st17" d="M168.7,233.1l0.1,1.4v-1.4H168.7z M168.7,234.6v-1.6l0.1,1.4L168.7,234.6z"/>
<path className="st16" d="M168.7,234.9l0.1,1.6V235L168.7,234.9z M168.7,236.4v-1.6l0.1,1.6H168.7z"/>
<path className="st17" d="M168.7,234.9l0.1,1.6V235L168.7,234.9z M168.7,236.4v-1.6l0.1,1.6H168.7z"/>
<path className="st16" d="M168.6,233.1l0.1,1.6v-1.6H168.6z M168.6,234.8v-1.7l0.1,1.6L168.6,234.8z"/>
<path className="st17" d="M168.6,233.1l0.1,1.6v-1.6H168.6z M168.6,234.8v-1.7l0.1,1.6L168.6,234.8z"/>
<path className="st16" d="M168.6,234.8l0.1,1.7v-1.6L168.6,234.8z M168.6,236.4v-1.7l0.1,1.7H168.6z"/>
<path className="st17" d="M168.6,234.8l0.1,1.7v-1.6L168.6,234.8z M168.6,236.4v-1.7l0.1,1.7H168.6z"/>
<path className="st16" d="M168.5,231.5l0.4,1.2v-1.1L168.5,231.5z M168.5,232.7v-1.2l0.4,1.2H168.5z"/>
<path className="st17" d="M168.5,231.5l0.4,1.2v-1.1L168.5,231.5z M168.5,232.7v-1.2l0.4,1.2H168.5z"/>
<path className="st16" d="M168.5,232.7l0.4,0.4v-0.4H168.5z M168.5,233.1v-0.4l0.4,0.4H168.5z"/>
<path className="st17" d="M168.5,232.7l0.4,0.4v-0.4H168.5z M168.5,233.1v-0.4l0.4,0.4H168.5z"/>
<path className="st16" d="M168.5,233.1l0.1,1.7v-1.7H168.5z M168.5,234.8v-1.7l0.1,1.7H168.5z"/>
<path className="st17" d="M168.5,233.1l0.1,1.7v-1.7H168.5z M168.5,234.8v-1.7l0.1,1.7H168.5z"/>
<path className="st16" d="M168.5,234.8l0.1,1.7v-1.7H168.5z M168.5,236.4v-1.7l0.1,1.7H168.5z"/>
<path className="st17" d="M168.5,234.8l0.1,1.7v-1.7H168.5z M168.5,236.4v-1.7l0.1,1.7H168.5z"/>
<path className="st16" d="M168.4,231.5l0.1,1.2v-1.2H168.4z M168.4,232.7v-1.2l0.1,1.2H168.4z"/>
<path className="st17" d="M168.4,231.5l0.1,1.2v-1.2H168.4z M168.4,232.7v-1.2l0.1,1.2H168.4z"/>
<path className="st16" d="M168.4,232.7l0.1,0.4v-0.4H168.4z M168.4,233.1v-0.4l0.1,0.4H168.4z"/>
<path className="st17" d="M168.4,232.7l0.1,0.4v-0.4H168.4z M168.4,233.1v-0.4l0.1,0.4H168.4z"/>
<path className="st16" d="M168.4,233.1l0.1,1.6v-1.6H168.4z M168.4,234.6v-1.6l0.1,1.6H168.4z"/>
<path className="st17" d="M168.4,233.1l0.1,1.6v-1.6H168.4z M168.4,234.6v-1.6l0.1,1.6H168.4z"/>
<path className="st16" d="M168.1,231.5l0.2,1.7v-1.7H168.1z M168.1,233.2v-1.7l0.2,1.7H168.1z"/>
<path className="st17" d="M168.1,231.5l0.2,1.7v-1.7H168.1z M168.1,233.2v-1.7l0.2,1.7H168.1z"/>
<path className="st16" d="M168.1,233.2l0.2,1.4v-1.4H168.1z M168.1,234.6v-1.4l0.2,1.4H168.1z"/>
<path className="st17" d="M168.1,233.2l0.2,1.4v-1.4H168.1z M168.1,234.6v-1.4l0.2,1.4H168.1z"/>
<path className="st16" d="M168.1,235l0.4,1.4V235H168.1z M168.1,236.4V235l0.4,1.4H168.1z"/>
<path className="st17" d="M168.1,235l0.4,1.4V235H168.1z M168.1,236.4V235l0.4,1.4H168.1z"/>
<path className="st16" d="M168.1,236.4l0.8,0.4v-0.4H168.1z M168.1,236.8v-0.4l0.8,0.4H168.1z"/>
<path className="st17" d="M168.1,236.4l0.8,0.4v-0.4H168.1z M168.1,236.8v-0.4l0.8,0.4H168.1z"/>
<path className="st16" d="M167.9,233.2l0.2,1.4v-1.4H167.9z M167.9,234.6v-1.4l0.2,1.4H167.9z M167.9,234.5v0.1v-1.4V234.5z"/>
<path className="st17" d="M167.9,233.2l0.2,1.4v-1.4H167.9z M167.9,234.6v-1.4l0.2,1.4H167.9z M167.9,234.5v0.1v-1.4V234.5z"/>
<path className="st16" d="M167.9,233.2v1.3V233.2z M167.9,233.2L167.9,233.2v1.3V233.2z M167.9,234.6v-1.4V234.6z"/>
<path className="st17" d="M167.9,233.2v1.3V233.2z M167.9,233.2L167.9,233.2v1.3V233.2z M167.9,234.6v-1.4V234.6z"/>
<path className="st16" d="M167.9,235l0.2,0.6V235H167.9z M167.9,235.6V235l0.2,0.6H167.9z"/>
<path className="st17" d="M167.9,235l0.2,0.6V235H167.9z M167.9,235.6V235l0.2,0.6H167.9z"/>
<path className="st16" d="M167.8,233.2l0.1,1.3v-1.3H167.8z M167.8,234.6v-1.4l0.1,1.3L167.8,234.6z"/>
<path className="st17" d="M167.8,233.2l0.1,1.3v-1.3H167.8z M167.8,234.6v-1.4l0.1,1.3L167.8,234.6z"/>
<path className="st16" d="M167.8,234.9l0.1,0.7V235L167.8,234.9z M167.8,235.6v-0.7l0.1,0.7H167.8z"/>
<path className="st17" d="M167.8,234.9l0.1,0.7V235L167.8,234.9z M167.8,235.6v-0.7l0.1,0.7H167.8z"/>
<path className="st16" d="M167.6,231.5l0.5,1.7v-1.7H167.6z M167.6,233.2v-1.7l0.5,1.7H167.6z"/>
<path className="st17" d="M167.6,231.5l0.5,1.7v-1.7H167.6z M167.6,233.2v-1.7l0.5,1.7H167.6z"/>
<path className="st16" d="M167.6,233.2l0.1,1.4v-1.4H167.6z M167.6,234.8v-1.6l0.1,1.4L167.6,234.8z"/>
<path className="st17" d="M167.6,233.2l0.1,1.4v-1.4H167.6z M167.6,234.8v-1.6l0.1,1.4L167.6,234.8z"/>
<path className="st16" d="M167.6,234.8l0.1,0.8v-0.7L167.6,234.8z M167.6,235.6v-0.8l0.1,0.8H167.6z"/>
<path className="st17" d="M167.6,234.8l0.1,0.8v-0.7L167.6,234.8z M167.6,235.6v-0.8l0.1,0.8H167.6z"/>
<path className="st16" d="M167.5,231.5l0.1,1.7v-1.7H167.5z M167.5,233.2v-1.7l0.1,1.7H167.5z"/>
<path className="st17" d="M167.5,231.5l0.1,1.7v-1.7H167.5z M167.5,233.2v-1.7l0.1,1.7H167.5z"/>
<path className="st16" d="M167.5,233.2l0.1,1.6v-1.6H167.5z M167.5,234.8v-1.6l0.1,1.6H167.5z"/>
<path className="st17" d="M167.5,233.2l0.1,1.6v-1.6H167.5z M167.5,234.8v-1.6l0.1,1.6H167.5z"/>
<path className="st16" d="M167.5,234.8l0.1,0.8v-0.8H167.5z M167.5,235.6v-0.8l0.1,0.8H167.5z"/>
<path className="st17" d="M167.5,234.8l0.1,0.8v-0.8H167.5z M167.5,235.6v-0.8l0.1,0.8H167.5z"/>
<path className="st16" d="M167.5,235.6l0.6,0.5v-0.5H167.5z M167.5,236.1v-0.5l0.6,0.5H167.5z"/>
<path className="st17" d="M167.5,235.6l0.6,0.5v-0.5H167.5z M167.5,236.1v-0.5l0.6,0.5H167.5z"/>
<path className="st16" d="M167.5,236.1l0.6,0.2v-0.2H167.5z M167.5,236.3v-0.2l0.6,0.2H167.5z"/>
<path className="st17" d="M167.5,236.1l0.6,0.2v-0.2H167.5z M167.5,236.3v-0.2l0.6,0.2H167.5z"/>
<path className="st16" d="M167.5,236.3l0.6,0.5v-0.5H167.5z M167.5,236.8v-0.5l0.6,0.5H167.5z"/>
<path className="st17" d="M167.5,236.3l0.6,0.5v-0.5H167.5z M167.5,236.8v-0.5l0.6,0.5H167.5z"/>
<path className="st16" d="M167.4,231.2l0.1,2.9v-2.9H167.4z M167.4,234v-2.9l0.1,2.9H167.4z"/>
<path className="st17" d="M167.4,231.2l0.1,2.9v-2.9H167.4z M167.4,234v-2.9l0.1,2.9H167.4z"/>
<path className="st16" d="M167.4,235.4l0.1,1.7v-1.7H167.4z M167.4,237v-1.7l0.1,1.7H167.4z"/>
<path className="st17" d="M167.4,235.4l0.1,1.7v-1.7H167.4z M167.4,237v-1.7l0.1,1.7H167.4z"/>
<path className="st16" d="M167.3,231.2l0.1,3.6v-3.6H167.3z M167.3,234.8v-3.6l0.1,3.6H167.3z"/>
<path className="st17" d="M167.3,231.2l0.1,3.6v-3.6H167.3z M167.3,234.8v-3.6l0.1,3.6H167.3z"/>
<path className="st16" d="M167.3,234.8l0.1,2.3v-2.3H167.3z M167.3,237v-2.3l0.1,2.3H167.3z"/>
<path className="st17" d="M167.3,234.8l0.1,2.3v-2.3H167.3z M167.3,237v-2.3l0.1,2.3H167.3z"/>
<line className="st19" x1="169.6" y1="234.8" x2="166.1" y2="234.8"/>
<line className="st19" x1="168.4" y1="233.2" x2="168.4" y2="231.5"/>
<line className="st19" x1="168.1" y1="234.6" x2="168.1" y2="233.2"/>
<line className="st19" x1="168.1" y1="236.1" x2="168.1" y2="235.6"/>
<polyline className="st19" points="167.5,236.8 168.1,236.8 168.1,236.3 167.5,236.3 "/>
<line className="st19" x1="168.1" y1="236.1" x2="167.5" y2="236.1"/>
<line className="st19" x1="168.1" y1="235.6" x2="167.5" y2="235.6"/>
<line className="st19" x1="168.4" y1="233.2" x2="167.5" y2="233.2"/>
<line className="st19" x1="168.4" y1="231.5" x2="167.5" y2="231.5"/>
<line className="st19" x1="168.5" y1="235" x2="168.5" y2="234.6"/>
<path className="st19" d="M167.6,234.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
	C167.7,234.5,167.6,234.6,167.6,234.7"/>
<line className="st19" x1="168.5" y1="235" x2="167.9" y2="235"/>
<line className="st19" x1="168.5" y1="234.6" x2="167.8" y2="234.6"/>
<polyline className="st19" points="167.5,235.4 167.4,235.4 167.4,234 167.5,234 "/>
<path className="st19" d="M168.6,234.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
	C168.7,234.5,168.6,234.6,168.6,234.7"/>
<path className="st19" d="M170.2,233.7c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3"/>
<path className="st19" d="M169,236.7c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8"/>
<path className="st19" d="M169,236.3c0.2,0,0.4,0,0.5-0.2s0.2-0.3,0.2-0.5"/>
<path className="st19" d="M169.8,233.7c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2"/>
<line className="st19" x1="169.9" y1="235.7" x2="169.9" y2="233.8"/>
<line className="st19" x1="169.2" y1="233.1" x2="168.4" y2="233.1"/>
<polyline className="st19" points="169.1,236.4 168.1,236.4 168.1,235 "/>
<line className="st19" x1="170.2" y1="235.7" x2="170.2" y2="233.8"/>
<path className="st19" d="M169.2,232.7c0.2-0.3,0.1-0.7-0.1-1c-0.2-0.3-0.6-0.4-0.9-0.4"/>
<line className="st19" x1="167.5" y1="237" x2="167.5" y2="231.2"/>
<polyline className="st19" points="167.5,237 167.3,237 167.3,231.2 167.5,231.2 "/>
<line className="st19" x1="169.2" y1="232.7" x2="168.4" y2="232.7"/>
<line className="st19" x1="169.1" y1="236.8" x2="167.5" y2="236.8"/>
<path className="st20" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M167.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M167.8,234.6v-0.1V234.6z"/>
<path className="st21" d="M167.8,234.6v-0.1V234.6z"/>
<polygon className="st20" points="167.9,234.6 167.8,234.6 167.8,234.5 "/>
<polygon className="st21" points="167.9,234.6 167.8,234.6 167.8,234.5 "/>
<polygon className="st20" points="167.9,234.6 167.9,234.5 167.8,234.5 "/>
<polygon className="st21" points="167.9,234.6 167.9,234.5 167.8,234.5 "/>
<path className="st5" d="M292.4,276.4c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="289" y1="272.9" x2="292.4" y2="269.3"/>
<polyline className="st5" points="289,272.9 289.1,273 292.7,269.6 292.4,269.3 "/>
<polyline className="st1" points="193.3,190.2 183.5,180.5 182.8,181.2 "/>
<polyline className="st1" points="114.4,174.5 115.3,174.5 115.3,201.4 "/>
<line className="st1" x1="69.7" y1="205.2" x2="84.6" y2="220.1"/>
<line className="st1" x1="390.1" y1="383.1" x2="395.8" y2="377.4"/>
<path className="st5" d="M301.1,291c-2.3-2.1-6-2.1-8.2,0.2"/>
<polyline className="st5" points="301.1,291.2 301.3,291.3 297.1,295.5 296.9,295.4 301.1,291.2 "/>
<line className="st2" x1="434.8" y1="299.8" x2="444.7" y2="309.8"/>
<polyline className="st2" points="395.9,339.9 434.8,301 444.1,310.4 "/>
<line className="st2" x1="395.3" y1="339.4" x2="434.8" y2="299.8"/>
<line className="st1" x1="160.9" y1="155.1" x2="164.4" y2="162.8"/>
<line className="st1" x1="166.4" y1="160.7" x2="158.8" y2="157.1"/>
<line className="st1" x1="159.7" y1="153.9" x2="152" y2="150.4"/>
<line className="st1" x1="154.1" y1="148.4" x2="157.6" y2="156"/>
<line className="st1" x1="163.3" y1="162.9" x2="159.4" y2="162.6"/>
<line className="st1" x1="302" y1="277.5" x2="307.8" y2="285.6"/>
<line className="st1" x1="296.6" y1="264.3" x2="302.3" y2="274.1"/>
<line className="st1" x1="294.5" y1="266.3" x2="304.4" y2="272.1"/>
<line className="st1" x1="305" y1="272.7" x2="302" y2="277.5"/>
<line className="st1" x1="295.6" y1="279.5" x2="301.2" y2="273.9"/>
<line className="st1" x1="309.2" y1="303.4" x2="315" y2="313.5"/>
<line className="st1" x1="307.1" y1="305.6" x2="317.2" y2="311.3"/>
<line className="st1" x1="307" y1="306.5" x2="307" y2="310.5"/>
<line className="st1" x1="314" y1="313.6" x2="310.1" y2="313.6"/>
<line className="st1" x1="388.3" y1="382.5" x2="392.4" y2="390.9"/>
<line className="st1" x1="394.6" y1="388.7" x2="386.2" y2="384.6"/>
<line className="st1" x1="401.3" y1="395.4" x2="392.9" y2="391.4"/>
<line className="st1" x1="395" y1="389.2" x2="399.1" y2="397.6"/>
<line className="st1" x1="388.3" y1="389.4" x2="386" y2="385.6"/>
<path className="st5" d="M285.8,284.3c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="286.1,276 285.8,275.9 281.6,280.1 281.9,280.2 286.1,276 "/>
<polyline className="st12" points="224.4,291.2 224.6,290.9 216.2,282.5 216,282.8 "/>
<line className="st12" x1="216.6" y1="282.9" x2="216.4" y2="283.1"/>
<line className="st12" x1="224.4" y1="290.7" x2="224.2" y2="290.9"/>
<line className="st12" x1="216" y1="282.8" x2="224.4" y2="291.2"/>
<line className="st12" x1="222.5" y1="288.8" x2="222.2" y2="289"/>
<line className="st12" x1="222.1" y1="288.4" x2="221.9" y2="288.6"/>
<line className="st6" x1="216.5" y1="283" x2="222" y2="288.5"/>
<line className="st6" x1="222.4" y1="288.9" x2="224.3" y2="290.8"/>
<polyline className="st12" points="181.3,247.6 181.1,247.8 189.5,256.2 189.7,256 "/>
<line className="st12" x1="189.1" y1="255.9" x2="189.4" y2="255.6"/>
<line className="st12" x1="181.3" y1="248.1" x2="181.6" y2="247.8"/>
<line className="st12" x1="189.7" y1="256" x2="181.3" y2="247.6"/>
<line className="st12" x1="183.2" y1="250" x2="183.5" y2="249.8"/>
<line className="st12" x1="183.6" y1="250.2" x2="183.7" y2="250"/>
<line className="st6" x1="189.2" y1="255.8" x2="183.7" y2="250.1"/>
<line className="st6" x1="183.4" y1="249.9" x2="181.4" y2="248"/>
<polyline className="st12" points="198.7,265 198.6,265.2 206.9,273.6 207.1,273.4 "/>
<line className="st12" x1="206.6" y1="273.4" x2="206.9" y2="273.2"/>
<line className="st12" x1="198.8" y1="265.6" x2="199.1" y2="265.4"/>
<line className="st12" x1="207.1" y1="273.4" x2="198.7" y2="265"/>
<line className="st12" x1="200.8" y1="267.5" x2="201" y2="267.3"/>
<line className="st12" x1="201" y1="267.8" x2="201.2" y2="267.5"/>
<line className="st6" x1="206.8" y1="273.3" x2="201.1" y2="267.6"/>
<line className="st6" x1="200.9" y1="267.4" x2="199" y2="265.5"/>
<polyline className="st12" points="233.8,300 233.5,300.3 241.9,308.7 242.2,308.4 "/>
<line className="st12" x1="241.7" y1="308.3" x2="241.8" y2="308.1"/>
<line className="st12" x1="233.9" y1="300.5" x2="234.1" y2="300.4"/>
<line className="st12" x1="242.2" y1="308.4" x2="233.8" y2="300"/>
<line className="st12" x1="235.7" y1="302.4" x2="235.9" y2="302.2"/>
<line className="st12" x1="236" y1="302.8" x2="236.3" y2="302.6"/>
<line className="st6" x1="241.7" y1="308.2" x2="236.2" y2="302.7"/>
<line className="st6" x1="235.8" y1="302.3" x2="234" y2="300.5"/>
<polyline className="st12" points="242,326.8 241.8,327 250.2,335.3 250.4,335.2 "/>
<line className="st12" x1="250" y1="335.1" x2="250.1" y2="334.8"/>
<line className="st12" x1="242.2" y1="327.3" x2="242.4" y2="327"/>
<line className="st12" x1="250.4" y1="335.2" x2="242" y2="326.8"/>
<line className="st12" x1="244" y1="329.2" x2="244.2" y2="329"/>
<line className="st12" x1="244.3" y1="329.4" x2="244.6" y2="329.2"/>
<line className="st6" x1="250" y1="335" x2="244.4" y2="329.3"/>
<line className="st6" x1="244.1" y1="329.1" x2="242.3" y2="327.2"/>
<polyline className="st12" points="259.6,344.2 259.3,344.4 267.7,352.8 268,352.6 "/>
<line className="st12" x1="267.4" y1="352.6" x2="267.6" y2="352.4"/>
<line className="st12" x1="259.6" y1="344.8" x2="259.8" y2="344.6"/>
<line className="st12" x1="268" y1="352.6" x2="259.6" y2="344.2"/>
<line className="st12" x1="261.5" y1="346.7" x2="261.7" y2="346.5"/>
<line className="st12" x1="261.8" y1="347" x2="262.1" y2="346.7"/>
<line className="st6" x1="267.5" y1="352.5" x2="262" y2="346.8"/>
<line className="st6" x1="261.6" y1="346.6" x2="259.7" y2="344.7"/>
<polyline className="st12" points="285.2,370.4 285.5,370.1 277.1,361.7 276.8,362 "/>
<line className="st12" x1="277.3" y1="362.1" x2="277.1" y2="362.3"/>
<line className="st12" x1="285.1" y1="369.9" x2="284.9" y2="370.1"/>
<line className="st12" x1="276.8" y1="362" x2="285.2" y2="370.4"/>
<line className="st12" x1="283.2" y1="368" x2="283" y2="368.2"/>
<line className="st12" x1="283" y1="367.6" x2="282.7" y2="367.8"/>
<line className="st6" x1="277.2" y1="362.2" x2="282.8" y2="367.7"/>
<line className="st6" x1="283.1" y1="368.1" x2="285" y2="370"/>
<polyline className="st12" points="302.6,387.9 302.9,387.6 294.5,379.2 294.2,379.5 "/>
<line className="st12" x1="294.8" y1="379.5" x2="294.6" y2="379.7"/>
<line className="st12" x1="302.6" y1="387.3" x2="302.4" y2="387.5"/>
<line className="st12" x1="294.2" y1="379.5" x2="302.6" y2="387.9"/>
<line className="st12" x1="300.7" y1="385.5" x2="300.5" y2="385.7"/>
<line className="st12" x1="300.5" y1="385.1" x2="300.2" y2="385.4"/>
<line className="st6" x1="294.7" y1="379.6" x2="300.4" y2="385.2"/>
<line className="st6" x1="300.6" y1="385.6" x2="302.5" y2="387.4"/>
<polyline className="st12" points="320.2,405.4 320.4,405.2 312,396.8 311.8,397 "/>
<line className="st12" x1="312.4" y1="397" x2="312.1" y2="397.2"/>
<line className="st12" x1="320.2" y1="404.8" x2="319.9" y2="405"/>
<line className="st12" x1="311.8" y1="397" x2="320.2" y2="405.4"/>
<line className="st12" x1="318.2" y1="402.9" x2="318" y2="403.1"/>
<line className="st12" x1="317.9" y1="402.6" x2="317.6" y2="402.9"/>
<line className="st6" x1="312.2" y1="397.1" x2="317.8" y2="402.8"/>
<line className="st6" x1="318.1" y1="403" x2="320" y2="404.9"/>
<polyline className="st12" points="329.5,414.2 329.3,414.4 337.7,422.8 337.9,422.6 "/>
<line className="st12" x1="337.3" y1="422.6" x2="337.6" y2="422.3"/>
<line className="st12" x1="329.5" y1="414.8" x2="329.8" y2="414.5"/>
<line className="st12" x1="337.9" y1="422.6" x2="329.5" y2="414.2"/>
<line className="st12" x1="331.4" y1="416.7" x2="331.7" y2="416.4"/>
<line className="st12" x1="331.8" y1="416.9" x2="332" y2="416.7"/>
<line className="st6" x1="337.4" y1="422.4" x2="331.9" y2="416.8"/>
<line className="st6" x1="331.6" y1="416.6" x2="329.6" y2="414.6"/>
<polyline className="st12" points="347,431.7 346.8,431.9 355.2,440.3 355.4,440.1 "/>
<line className="st12" x1="354.8" y1="440.1" x2="355.1" y2="439.8"/>
<line className="st12" x1="347" y1="432.3" x2="347.3" y2="432"/>
<line className="st12" x1="355.4" y1="440.1" x2="347" y2="431.7"/>
<line className="st12" x1="349" y1="434.1" x2="349.2" y2="434"/>
<line className="st12" x1="349.3" y1="434.4" x2="349.4" y2="434.2"/>
<line className="st6" x1="355" y1="440" x2="349.3" y2="434.3"/>
<line className="st6" x1="349.1" y1="434.1" x2="347.2" y2="432.2"/>
<polyline className="st12" points="392.5,411.6 392.8,411.9 401.2,403.5 400.9,403.2 "/>
<line className="st12" x1="400.8" y1="403.8" x2="400.6" y2="403.6"/>
<line className="st12" x1="393" y1="411.6" x2="392.8" y2="411.4"/>
<line className="st12" x1="400.9" y1="403.2" x2="392.5" y2="411.6"/>
<line className="st12" x1="394.9" y1="409.7" x2="394.7" y2="409.5"/>
<line className="st12" x1="395.2" y1="409.4" x2="394.9" y2="409.1"/>
<line className="st6" x1="400.7" y1="403.7" x2="395" y2="409.2"/>
<line className="st6" x1="394.8" y1="409.6" x2="392.9" y2="411.5"/>
<polyline className="st12" points="360.2,260.3 360.5,260.1 352.1,251.7 351.8,251.9 "/>
<line className="st12" x1="352.4" y1="251.9" x2="352.2" y2="252.2"/>
<line className="st12" x1="360.2" y1="259.7" x2="360" y2="260"/>
<line className="st12" x1="351.8" y1="251.9" x2="360.2" y2="260.3"/>
<line className="st12" x1="358.3" y1="257.8" x2="358.1" y2="258"/>
<line className="st12" x1="358.1" y1="257.6" x2="357.8" y2="257.8"/>
<line className="st6" x1="352.3" y1="252" x2="358" y2="257.7"/>
<line className="st6" x1="358.2" y1="257.9" x2="360.1" y2="259.8"/>
<polyline className="st12" points="317.2,216.6 316.9,216.9 325.3,225.3 325.6,225 "/>
<line className="st12" x1="325" y1="224.9" x2="325.2" y2="224.8"/>
<line className="st12" x1="317.2" y1="217.2" x2="317.4" y2="217"/>
<line className="st12" x1="325.6" y1="225" x2="317.2" y2="216.6"/>
<line className="st12" x1="319.1" y1="219" x2="319.3" y2="218.8"/>
<line className="st12" x1="319.4" y1="219.4" x2="319.7" y2="219.2"/>
<line className="st6" x1="325.1" y1="224.9" x2="319.6" y2="219.3"/>
<line className="st6" x1="319.2" y1="218.9" x2="317.3" y2="217.1"/>
<polyline className="st12" points="334.7,234.2 334.4,234.4 342.8,242.8 343.1,242.6 "/>
<line className="st12" x1="342.5" y1="242.4" x2="342.7" y2="242.2"/>
<line className="st12" x1="334.7" y1="234.6" x2="334.9" y2="234.4"/>
<line className="st12" x1="343.1" y1="242.6" x2="334.7" y2="234.2"/>
<line className="st12" x1="336.6" y1="236.6" x2="336.8" y2="236.3"/>
<line className="st12" x1="336.8" y1="236.9" x2="337.1" y2="236.7"/>
<line className="st6" x1="342.6" y1="242.3" x2="337" y2="236.8"/>
<line className="st6" x1="336.7" y1="236.4" x2="334.8" y2="234.5"/>
<polyline className="st12" points="369.6,269.1 369.4,269.3 377.8,277.7 378,277.5 "/>
<line className="st12" x1="377.5" y1="277.5" x2="377.8" y2="277.2"/>
<line className="st12" x1="369.7" y1="269.7" x2="370" y2="269.4"/>
<line className="st12" x1="378" y1="277.5" x2="369.6" y2="269.1"/>
<line className="st12" x1="371.6" y1="271.6" x2="371.8" y2="271.4"/>
<line className="st12" x1="371.9" y1="271.8" x2="372.1" y2="271.6"/>
<line className="st6" x1="377.6" y1="277.4" x2="372" y2="271.7"/>
<line className="st6" x1="371.6" y1="271.5" x2="369.8" y2="269.6"/>
<polyline className="st12" points="395.3,295.2 395.5,295 387.1,286.6 386.9,286.8 "/>
<line className="st12" x1="387.4" y1="287" x2="387.2" y2="287.2"/>
<line className="st12" x1="395.2" y1="294.8" x2="394.9" y2="295"/>
<line className="st12" x1="386.9" y1="286.8" x2="395.3" y2="295.2"/>
<line className="st12" x1="393.4" y1="292.8" x2="393.1" y2="293.1"/>
<line className="st12" x1="393" y1="292.5" x2="392.8" y2="292.7"/>
<line className="st6" x1="387.4" y1="287.1" x2="392.9" y2="292.6"/>
<line className="st6" x1="393.2" y1="293" x2="395" y2="294.9"/>
<polyline className="st12" points="412.8,312.8 413,312.5 404.6,304.1 404.4,304.4 "/>
<line className="st12" x1="404.9" y1="304.5" x2="404.6" y2="304.6"/>
<line className="st12" x1="412.7" y1="312.2" x2="412.4" y2="312.4"/>
<line className="st12" x1="404.4" y1="304.4" x2="412.8" y2="312.8"/>
<line className="st12" x1="410.8" y1="310.4" x2="410.5" y2="310.6"/>
<line className="st12" x1="410.5" y1="310" x2="410.3" y2="310.2"/>
<line className="st6" x1="404.8" y1="304.6" x2="410.4" y2="310.1"/>
<line className="st6" x1="410.6" y1="310.5" x2="412.6" y2="312.3"/>
<polyline className="st12" points="231.2,179.4 231.5,179.2 223.1,170.8 222.8,171 "/>
<line className="st12" x1="223.3" y1="171.2" x2="223.1" y2="171.4"/>
<line className="st12" x1="231.1" y1="179" x2="230.9" y2="179.2"/>
<line className="st12" x1="222.8" y1="171" x2="231.2" y2="179.4"/>
<line className="st12" x1="229.2" y1="177" x2="229" y2="177.3"/>
<line className="st12" x1="229" y1="176.7" x2="228.7" y2="176.9"/>
<line className="st6" x1="223.2" y1="171.3" x2="228.8" y2="176.8"/>
<line className="st6" x1="229.1" y1="177.2" x2="231" y2="179.1"/>
<polyline className="st12" points="213.7,161.9 214,161.7 205.6,153.3 205.3,153.5 "/>
<line className="st12" x1="205.8" y1="153.6" x2="205.6" y2="153.9"/>
<line className="st12" x1="213.6" y1="161.4" x2="213.4" y2="161.7"/>
<line className="st12" x1="205.3" y1="153.5" x2="213.7" y2="161.9"/>
<line className="st12" x1="211.8" y1="159.5" x2="211.6" y2="159.8"/>
<line className="st12" x1="211.4" y1="159.3" x2="211.2" y2="159.4"/>
<line className="st6" x1="205.7" y1="153.8" x2="211.3" y2="159.4"/>
<line className="st6" x1="211.7" y1="159.6" x2="213.5" y2="161.6"/>
<polyline className="st12" points="258,205.8 257.8,206.1 266.2,214.5 266.4,214.2 "/>
<line className="st12" x1="265.9" y1="214.1" x2="266.2" y2="213.9"/>
<line className="st12" x1="258.1" y1="206.3" x2="258.4" y2="206.1"/>
<line className="st12" x1="266.4" y1="214.2" x2="258" y2="205.8"/>
<line className="st12" x1="259.9" y1="208.2" x2="260.2" y2="208"/>
<line className="st12" x1="260.3" y1="208.5" x2="260.5" y2="208.4"/>
<line className="st6" x1="266" y1="214" x2="260.4" y2="208.4"/>
<line className="st6" x1="260" y1="208.1" x2="258.2" y2="206.2"/>
<polyline className="st12" points="188,135.9 187.8,136.1 196.2,144.4 196.4,144.3 "/>
<line className="st12" x1="196" y1="144.2" x2="196.2" y2="143.9"/>
<line className="st12" x1="188.2" y1="136.4" x2="188.4" y2="136.1"/>
<line className="st12" x1="196.4" y1="144.3" x2="188" y2="135.9"/>
<line className="st12" x1="190" y1="138.3" x2="190.2" y2="138"/>
<line className="st12" x1="190.3" y1="138.5" x2="190.6" y2="138.3"/>
<line className="st6" x1="196.1" y1="144" x2="190.4" y2="138.4"/>
<line className="st6" x1="190.1" y1="138.2" x2="188.3" y2="136.2"/>
<polyline className="st12" points="170.5,118.4 170.3,118.6 178.7,127 178.9,126.8 "/>
<line className="st12" x1="178.4" y1="126.6" x2="178.7" y2="126.4"/>
<line className="st12" x1="170.6" y1="118.8" x2="170.9" y2="118.6"/>
<line className="st12" x1="178.9" y1="126.8" x2="170.5" y2="118.4"/>
<line className="st12" x1="172.6" y1="120.8" x2="172.8" y2="120.5"/>
<line className="st12" x1="172.8" y1="121.1" x2="173" y2="120.9"/>
<line className="st6" x1="178.6" y1="126.5" x2="172.9" y2="121"/>
<line className="st6" x1="172.7" y1="120.6" x2="170.8" y2="118.7"/>
<polyline className="st12" points="248.6,197 248.9,196.7 240.5,188.3 240.2,188.6 "/>
<line className="st12" x1="240.8" y1="188.6" x2="240.6" y2="188.8"/>
<line className="st12" x1="248.6" y1="196.4" x2="248.4" y2="196.6"/>
<line className="st12" x1="240.2" y1="188.6" x2="248.6" y2="197"/>
<line className="st12" x1="246.7" y1="194.6" x2="246.5" y2="194.8"/>
<line className="st12" x1="246.5" y1="194.2" x2="246.2" y2="194.4"/>
<line className="st6" x1="240.7" y1="188.7" x2="246.4" y2="194.3"/>
<line className="st6" x1="246.6" y1="194.7" x2="248.5" y2="196.5"/>
<polyline className="st12" points="275.5,223.2 275.3,223.5 283.7,231.9 283.9,231.6 "/>
<line className="st12" x1="283.3" y1="231.6" x2="283.6" y2="231.4"/>
<line className="st12" x1="275.6" y1="223.8" x2="275.8" y2="223.6"/>
<line className="st12" x1="283.9" y1="231.6" x2="275.5" y2="223.2"/>
<line className="st12" x1="277.4" y1="225.8" x2="277.7" y2="225.5"/>
<line className="st12" x1="277.8" y1="226" x2="278" y2="225.8"/>
<line className="st6" x1="283.4" y1="231.5" x2="277.9" y2="225.9"/>
<line className="st6" x1="277.6" y1="225.6" x2="275.6" y2="223.7"/>
<polyline className="st12" points="424.6,354.2 424.3,354.4 432.7,362.8 433,362.6 "/>
<line className="st12" x1="432.4" y1="362.4" x2="432.6" y2="362.2"/>
<line className="st12" x1="424.6" y1="354.6" x2="424.8" y2="354.4"/>
<line className="st12" x1="433" y1="362.6" x2="424.6" y2="354.2"/>
<line className="st12" x1="426.5" y1="356.6" x2="426.7" y2="356.3"/>
<line className="st12" x1="426.8" y1="356.9" x2="427.1" y2="356.7"/>
<line className="st6" x1="432.5" y1="362.3" x2="427" y2="356.8"/>
<line className="st6" x1="426.6" y1="356.4" x2="424.7" y2="354.5"/>
<polyline className="st12" points="442.1,371.7 441.8,371.9 450.2,380.3 450.5,380.1 "/>
<line className="st12" x1="449.9" y1="380" x2="450.1" y2="379.7"/>
<line className="st12" x1="442.1" y1="372.2" x2="442.3" y2="371.9"/>
<line className="st12" x1="450.5" y1="380.1" x2="442.1" y2="371.7"/>
<line className="st12" x1="444" y1="374.1" x2="444.2" y2="373.8"/>
<line className="st12" x1="444.4" y1="374.3" x2="444.5" y2="374.2"/>
<line className="st6" x1="450" y1="379.8" x2="444.5" y2="374.3"/>
<line className="st6" x1="444.1" y1="374" x2="442.2" y2="372"/>
<polyline className="st12" points="467.6,397.7 467.9,397.6 459.5,389.2 459.4,389.3 "/>
<line className="st12" x1="459.8" y1="389.4" x2="459.6" y2="389.7"/>
<line className="st12" x1="467.6" y1="397.2" x2="467.4" y2="397.5"/>
<line className="st12" x1="459.4" y1="389.3" x2="467.6" y2="397.7"/>
<line className="st12" x1="465.7" y1="395.3" x2="465.5" y2="395.6"/>
<line className="st12" x1="465.5" y1="395.1" x2="465.2" y2="395.3"/>
<line className="st6" x1="459.7" y1="389.6" x2="465.4" y2="395.2"/>
<line className="st6" x1="465.6" y1="395.4" x2="467.5" y2="397.4"/>
<polyline className="st12" points="20.5,179.4 20.3,179.2 28.7,170.8 28.9,171 "/>
<line className="st12" x1="28.4" y1="171.2" x2="28.7" y2="171.4"/>
<line className="st12" x1="20.6" y1="179" x2="20.9" y2="179.2"/>
<line className="st12" x1="28.9" y1="171" x2="20.5" y2="179.4"/>
<line className="st12" x1="22.6" y1="177" x2="22.8" y2="177.3"/>
<line className="st12" x1="22.8" y1="176.7" x2="23" y2="176.9"/>
<line className="st6" x1="28.6" y1="171.3" x2="22.9" y2="176.8"/>
<line className="st6" x1="22.7" y1="177.2" x2="20.8" y2="179.1"/>
<polyline className="st12" points="38,161.9 37.8,161.7 46.2,153.3 46.4,153.5 "/>
<line className="st12" x1="46" y1="153.6" x2="46.2" y2="153.9"/>
<line className="st12" x1="38.2" y1="161.4" x2="38.4" y2="161.7"/>
<line className="st12" x1="46.4" y1="153.5" x2="38" y2="161.9"/>
<line className="st12" x1="40" y1="159.5" x2="40.2" y2="159.8"/>
<line className="st12" x1="40.3" y1="159.3" x2="40.6" y2="159.4"/>
<line className="st6" x1="46.1" y1="153.8" x2="40.4" y2="159.4"/>
<line className="st6" x1="40.1" y1="159.6" x2="38.3" y2="161.6"/>
<polyline className="st12" points="63.7,135.9 64,136.1 55.6,144.4 55.3,144.3 "/>
<line className="st12" x1="55.8" y1="144.2" x2="55.7" y2="143.9"/>
<line className="st12" x1="63.6" y1="136.4" x2="63.4" y2="136.1"/>
<line className="st12" x1="55.3" y1="144.3" x2="63.7" y2="135.9"/>
<line className="st12" x1="61.8" y1="138.3" x2="61.6" y2="138"/>
<line className="st12" x1="61.4" y1="138.5" x2="61.2" y2="138.3"/>
<line className="st6" x1="55.7" y1="144" x2="61.3" y2="138.4"/>
<line className="st6" x1="61.7" y1="138.2" x2="63.5" y2="136.2"/>
<polyline className="st12" points="81.2,118.4 81.5,118.6 73.1,127 72.8,126.8 "/>
<line className="st12" x1="73.3" y1="126.6" x2="73.1" y2="126.4"/>
<line className="st12" x1="81.1" y1="118.8" x2="80.9" y2="118.6"/>
<line className="st12" x1="72.8" y1="126.8" x2="81.2" y2="118.4"/>
<line className="st12" x1="79.2" y1="120.8" x2="79" y2="120.5"/>
<line className="st12" x1="79" y1="121.1" x2="78.7" y2="120.9"/>
<line className="st6" x1="73.2" y1="126.5" x2="78.8" y2="121"/>
<line className="st6" x1="79.1" y1="120.6" x2="81" y2="118.7"/>
<line className="st12" x1="10.9" y1="188.6" x2="11.2" y2="188.8"/>
<path className="st16" d="M81.7,233.3l-0.1,2.4v-1.9L81.7,233.3z M81.7,236.2v-2.9l-0.1,2.4L81.7,236.2z"/>
<path className="st17" d="M81.7,233.3l-0.1,2.4v-1.9L81.7,233.3z M81.7,236.2v-2.9l-0.1,2.4L81.7,236.2z"/>
<path className="st16" d="M81.7,233.2v3v-2.9V233.2z M81.7,236.3v-3.1v3V236.3z"/>
<path className="st17" d="M81.7,233.2v3v-2.9V233.2z M81.7,236.3v-3.1v3V236.3z"/>
<path className="st16" d="M81.8,233.1l-0.1,3.2v-3.1L81.8,233.1z M81.8,236.4v-3.4l-0.1,3.2L81.8,236.4z"/>
<path className="st17" d="M81.8,233.1l-0.1,3.2v-3.1L81.8,233.1z M81.8,236.4v-3.4l-0.1,3.2L81.8,236.4z"/>
<path className="st16" d="M82,233l-0.1,0.8v-0.7L82,233z M82,233.4V233l-0.1,0.8L82,233.4z"/>
<path className="st17" d="M82,233l-0.1,0.8v-0.7L82,233z M82,233.4V233l-0.1,0.8L82,233.4z"/>
<path className="st16" d="M82,233.4l-0.1,2.3v-1.9L82,233.4z M82,236.1v-2.6l-0.1,2.3L82,236.1z"/>
<path className="st17" d="M82,233.4l-0.1,2.3v-1.9L82,233.4z M82,236.1v-2.6l-0.1,2.3L82,236.1z"/>
<path className="st16" d="M82.1,233l-0.1,0.5V233H82.1z M82.1,233.3V233l-0.1,0.5L82.1,233.3z"/>
<path className="st17" d="M82.1,233l-0.1,0.5V233H82.1z M82.1,233.3V233l-0.1,0.5L82.1,233.3z"/>
<path className="st16" d="M82.1,233.3l-0.1,2.8v-2.6L82.1,233.3z M82.1,236.2v-2.9l-0.1,2.8L82.1,236.2z"/>
<path className="st17" d="M82.1,233.3l-0.1,2.8v-2.6L82.1,233.3z M82.1,236.2v-2.9l-0.1,2.8L82.1,236.2z"/>
<path className="st16" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st17" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st16" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st17" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st16" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st17" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st16" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st17" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st16" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st17" d="M82.1,233L82.1,233v0.4V233z M82.1,233.3V233V233.3z"/>
<path className="st16" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st17" d="M82.1,233.3L82.1,233.3v2.9V233.3z M82.1,236.2v-2.9V236.2z"/>
<path className="st16" d="M82.1,236.2l-0.2,0.2v-0.7L82.1,236.2z M82.1,236.7v-0.5l-0.2,0.2L82.1,236.7z"/>
<path className="st17" d="M82.1,236.2l-0.2,0.2v-0.7L82.1,236.2z M82.1,236.7v-0.5l-0.2,0.2L82.1,236.7z"/>
<path className="st16" d="M82.1,233.3v2.9V233.3z M82.1,236.3v-3v2.9V236.3z"/>
<path className="st17" d="M82.1,233.3v2.9V233.3z M82.1,236.3v-3v2.9V236.3z"/>
<path className="st16" d="M82.1,236.3v0.4v-0.5V236.3z M82.1,236.3L82.1,236.3v0.4V236.3z"/>
<path className="st17" d="M82.1,236.3v0.4v-0.5V236.3z M82.1,236.3L82.1,236.3v0.4V236.3z"/>
<polygon className="st16" points="82.4,232.1 82.6,232.7 82.6,232 "/>
<polygon className="st17" points="82.4,232.1 82.6,232.7 82.6,232 "/>
<path className="st16" d="M82.4,233.2l-0.4,3.1v-3L82.4,233.2z M82.4,236.3v-3.1l-0.4,3.1H82.4z"/>
<path className="st17" d="M82.4,233.2l-0.4,3.1v-3L82.4,233.2z M82.4,236.3v-3.1l-0.4,3.1H82.4z"/>
<path className="st16" d="M82.4,236.3l-0.4,0.4v-0.4H82.4z M82.4,236.7v-0.4l-0.4,0.4H82.4z"/>
<path className="st17" d="M82.4,236.3l-0.4,0.4v-0.4H82.4z M82.4,236.7v-0.4l-0.4,0.4H82.4z"/>
<path className="st16" d="M82.6,232.7V232V232.7z M82.6,232v0.7V232z M82.6,232L82.6,232v0.7V232z M82.6,232.7V232V232.7z"/>
<path className="st17" d="M82.6,232.7V232V232.7z M82.6,232v0.7V232z M82.6,232L82.6,232v0.7V232z M82.6,232.7V232V232.7z"/>
<path className="st16" d="M82.6,232.7l-0.5,0.6V233L82.6,232.7z M82.6,233.1v-0.4l-0.5,0.6L82.6,233.1z"/>
<path className="st17" d="M82.6,232.7l-0.5,0.6V233L82.6,232.7z M82.6,233.1v-0.4l-0.5,0.6L82.6,233.1z"/>
<path className="st16" d="M82.6,232L82.6,232v0.7V232z M82.6,232.7V232V232.7z"/>
<path className="st17" d="M82.6,232L82.6,232v0.7V232z M82.6,232.7V232V232.7z"/>
<path className="st16" d="M82.6,232.7L82.6,232.7v0.4V232.7z M82.6,233.1v-0.4V233.1z"/>
<path className="st17" d="M82.6,232.7L82.6,232.7v0.4V232.7z M82.6,233.1v-0.4V233.1z"/>
<path className="st16" d="M82.6,233.1l-0.1,3.2v-3.1L82.6,233.1z M82.6,236.4v-3.4l-0.1,3.2L82.6,236.4z"/>
<path className="st17" d="M82.6,233.1l-0.1,3.2v-3.1L82.6,233.1z M82.6,236.4v-3.4l-0.1,3.2L82.6,236.4z"/>
<path className="st16" d="M82.7,231.9l-0.1,0.8V232L82.7,231.9z M82.7,232.7v-0.8l-0.1,0.8H82.7z"/>
<path className="st17" d="M82.7,231.9l-0.1,0.8V232L82.7,231.9z M82.7,232.7v-0.8l-0.1,0.8H82.7z"/>
<path className="st16" d="M82.7,232.7l-0.1,0.4v-0.4H82.7z M82.7,233.1v-0.4l-0.1,0.4H82.7z"/>
<path className="st17" d="M82.7,232.7l-0.1,0.4v-0.4H82.7z M82.7,233.1v-0.4l-0.1,0.4H82.7z"/>
<path className="st16" d="M82.7,233.1l-0.1,3.4v-3.4H82.7z M82.7,236.4v-3.4l-0.1,3.4H82.7z"/>
<path className="st17" d="M82.7,233.1l-0.1,3.4v-3.4H82.7z M82.7,236.4v-3.4l-0.1,3.4H82.7z"/>
<path className="st16" d="M82.7,236.4l-0.2,0.2v-0.4L82.7,236.4z M82.7,236.8v-0.4l-0.2,0.2L82.7,236.8z"/>
<path className="st17" d="M82.7,236.4l-0.2,0.2v-0.4L82.7,236.4z M82.7,236.8v-0.4l-0.2,0.2L82.7,236.8z"/>
<path className="st16" d="M82.7,231.9L82.7,231.9v0.8V231.9z M82.7,232.7v-0.8V232.7z"/>
<path className="st17" d="M82.7,231.9L82.7,231.9v0.8V231.9z M82.7,232.7v-0.8V232.7z"/>
<path className="st16" d="M82.7,232.7L82.7,232.7v0.4V232.7z M82.7,233.1v-0.4V233.1z"/>
<path className="st17" d="M82.7,232.7L82.7,232.7v0.4V232.7z M82.7,233.1v-0.4V233.1z"/>
<path className="st16" d="M82.7,233.1L82.7,233.1v3.4V233.1z M82.7,236.4v-3.4V236.4z"/>
<path className="st17" d="M82.7,233.1L82.7,233.1v3.4V233.1z M82.7,236.4v-3.4V236.4z"/>
<path className="st16" d="M82.7,236.4L82.7,236.4v0.4V236.4z M82.7,236.8v-0.4V236.8z"/>
<path className="st17" d="M82.7,236.4L82.7,236.4v0.4V236.4z M82.7,236.8v-0.4V236.8z"/>
<path className="st16" d="M82.7,231.8v1v-0.8V231.8z M82.7,231.8L82.7,231.8v1V231.8z"/>
<path className="st17" d="M82.7,231.8v1v-0.8V231.8z M82.7,231.8L82.7,231.8v1V231.8z"/>
<path className="st16" d="M82.7,232.7L82.7,232.7v0.4V232.7z M82.7,233.1v-0.4V233.1z"/>
<path className="st17" d="M82.7,232.7L82.7,232.7v0.4V232.7z M82.7,233.1v-0.4V233.1z"/>
<path className="st16" d="M82.7,233.1L82.7,233.1v3.4V233.1z M82.7,236.4v-3.4V236.4z"/>
<path className="st17" d="M82.7,233.1L82.7,233.1v3.4V233.1z M82.7,236.4v-3.4V236.4z"/>
<path className="st16" d="M82.7,236.4L82.7,236.4v0.4V236.4z M82.7,236.8v-0.4V236.8z"/>
<path className="st17" d="M82.7,236.4L82.7,236.4v0.4V236.4z M82.7,236.8v-0.4V236.8z"/>
<path className="st16" d="M82.8,231.6l-0.1,1.1v-1L82.8,231.6z M82.8,232.7v-1.1l-0.1,1.1H82.8z"/>
<path className="st17" d="M82.8,231.6l-0.1,1.1v-1L82.8,231.6z M82.8,232.7v-1.1l-0.1,1.1H82.8z"/>
<path className="st16" d="M82.8,232.7l-0.1,0.4v-0.4H82.8z M82.8,233.1v-0.4l-0.1,0.4H82.8z"/>
<path className="st17" d="M82.8,232.7l-0.1,0.4v-0.4H82.8z M82.8,233.1v-0.4l-0.1,0.4H82.8z"/>
<path className="st16" d="M82.8,233.1l-0.1,1.7v-1.7H82.8z M82.8,234.6v-1.6l-0.1,1.7L82.8,234.6z"/>
<path className="st17" d="M82.8,233.1l-0.1,1.7v-1.7H82.8z M82.8,234.6v-1.6l-0.1,1.7L82.8,234.6z"/>
<path className="st16" d="M82.8,234.9l-0.1,1.6v-1.7L82.8,234.9z M82.8,236.4v-1.6l-0.1,1.6H82.8z"/>
<path className="st17" d="M82.8,234.9l-0.1,1.6v-1.7L82.8,234.9z M82.8,236.4v-1.6l-0.1,1.6H82.8z"/>
<path className="st16" d="M82.8,236.4l-0.1,0.4v-0.4H82.8z M82.8,236.8v-0.4l-0.1,0.4H82.8z"/>
<path className="st17" d="M82.8,236.4l-0.1,0.4v-0.4H82.8z M82.8,236.8v-0.4l-0.1,0.4H82.8z"/>
<path className="st16" d="M82.8,231.6L82.8,231.6v1.1V231.6z M82.8,232.7v-1.1V232.7z"/>
<path className="st17" d="M82.8,231.6L82.8,231.6v1.1V231.6z M82.8,232.7v-1.1V232.7z"/>
<path className="st16" d="M82.8,232.7L82.8,232.7v0.4V232.7z M82.8,233.1v-0.4V233.1z"/>
<path className="st17" d="M82.8,232.7L82.8,232.7v0.4V232.7z M82.8,233.1v-0.4V233.1z"/>
<path className="st16" d="M82.9,231.6l-0.1,1.1v-1.1H82.9z M82.9,232.7v-1.1l-0.1,1.1H82.9z"/>
<path className="st17" d="M82.9,231.6l-0.1,1.1v-1.1H82.9z M82.9,232.7v-1.1l-0.1,1.1H82.9z"/>
<path className="st16" d="M82.9,232.7l-0.1,0.4v-0.4H82.9z M82.9,233.1v-0.4l-0.1,0.4H82.9z"/>
<path className="st17" d="M82.9,232.7l-0.1,0.4v-0.4H82.9z M82.9,233.1v-0.4l-0.1,0.4H82.9z"/>
<path className="st16" d="M82.9,233.1l-0.1,1.6v-1.6H82.9z M82.9,234.5v-1.4l-0.1,1.6L82.9,234.5z"/>
<path className="st17" d="M82.9,233.1l-0.1,1.6v-1.6H82.9z M82.9,234.5v-1.4l-0.1,1.6L82.9,234.5z"/>
<path className="st16" d="M82.9,235l-0.1,1.4v-1.6L82.9,235z M82.9,236.4V235l-0.1,1.4H82.9z"/>
<path className="st17" d="M82.9,235l-0.1,1.4v-1.6L82.9,235z M82.9,236.4V235l-0.1,1.4H82.9z"/>
<path className="st16" d="M83,233.1l-0.1,1.4v-1.4H83z M83,234.6v-1.6l-0.1,1.4L83,234.6z"/>
<path className="st17" d="M83,233.1l-0.1,1.4v-1.4H83z M83,234.6v-1.6l-0.1,1.4L83,234.6z"/>
<path className="st16" d="M83,234.9l-0.1,1.6V235L83,234.9z M83,236.4v-1.6l-0.1,1.6H83z"/>
<path className="st17" d="M83,234.9l-0.1,1.6V235L83,234.9z M83,236.4v-1.6l-0.1,1.6H83z"/>
<path className="st16" d="M83.2,233.1l-0.1,1.6v-1.6H83.2z M83.2,234.8v-1.7l-0.1,1.6L83.2,234.8z"/>
<path className="st17" d="M83.2,233.1l-0.1,1.6v-1.6H83.2z M83.2,234.8v-1.7l-0.1,1.6L83.2,234.8z"/>
<path className="st16" d="M83.2,234.8l-0.1,1.7v-1.6L83.2,234.8z M83.2,236.4v-1.7l-0.1,1.7H83.2z"/>
<path className="st17" d="M83.2,234.8l-0.1,1.7v-1.6L83.2,234.8z M83.2,236.4v-1.7l-0.1,1.7H83.2z"/>
<path className="st16" d="M83.3,231.5l-0.4,1.2v-1.1L83.3,231.5z M83.3,232.7v-1.2l-0.4,1.2H83.3z"/>
<path className="st17" d="M83.3,231.5l-0.4,1.2v-1.1L83.3,231.5z M83.3,232.7v-1.2l-0.4,1.2H83.3z"/>
<path className="st16" d="M83.3,232.7l-0.4,0.4v-0.4H83.3z M83.3,233.1v-0.4l-0.4,0.4H83.3z"/>
<path className="st17" d="M83.3,232.7l-0.4,0.4v-0.4H83.3z M83.3,233.1v-0.4l-0.4,0.4H83.3z"/>
<path className="st16" d="M83.3,233.1l-0.1,1.7v-1.7H83.3z M83.3,234.8v-1.7l-0.1,1.7H83.3z"/>
<path className="st17" d="M83.3,233.1l-0.1,1.7v-1.7H83.3z M83.3,234.8v-1.7l-0.1,1.7H83.3z"/>
<path className="st16" d="M83.3,234.8l-0.1,1.7v-1.7H83.3z M83.3,236.4v-1.7l-0.1,1.7H83.3z"/>
<path className="st17" d="M83.3,234.8l-0.1,1.7v-1.7H83.3z M83.3,236.4v-1.7l-0.1,1.7H83.3z"/>
<path className="st16" d="M83.4,231.5l-0.1,1.2v-1.2H83.4z M83.4,232.7v-1.2l-0.1,1.2H83.4z"/>
<path className="st17" d="M83.4,231.5l-0.1,1.2v-1.2H83.4z M83.4,232.7v-1.2l-0.1,1.2H83.4z"/>
<path className="st16" d="M83.4,232.7l-0.1,0.4v-0.4H83.4z M83.4,233.1v-0.4l-0.1,0.4H83.4z"/>
<path className="st17" d="M83.4,232.7l-0.1,0.4v-0.4H83.4z M83.4,233.1v-0.4l-0.1,0.4H83.4z"/>
<path className="st16" d="M83.4,233.1l-0.1,1.6v-1.6H83.4z M83.4,234.6v-1.6l-0.1,1.6H83.4z"/>
<path className="st17" d="M83.4,233.1l-0.1,1.6v-1.6H83.4z M83.4,234.6v-1.6l-0.1,1.6H83.4z"/>
<path className="st16" d="M83.6,231.5l-0.2,1.7v-1.7H83.6z M83.6,233.2v-1.7l-0.2,1.7H83.6z"/>
<path className="st17" d="M83.6,231.5l-0.2,1.7v-1.7H83.6z M83.6,233.2v-1.7l-0.2,1.7H83.6z"/>
<path className="st16" d="M83.6,233.2l-0.2,1.4v-1.4H83.6z M83.6,234.6v-1.4l-0.2,1.4H83.6z"/>
<path className="st17" d="M83.6,233.2l-0.2,1.4v-1.4H83.6z M83.6,234.6v-1.4l-0.2,1.4H83.6z"/>
<path className="st16" d="M83.6,235l-0.4,1.4V235H83.6z M83.6,236.4V235l-0.4,1.4H83.6z"/>
<path className="st17" d="M83.6,235l-0.4,1.4V235H83.6z M83.6,236.4V235l-0.4,1.4H83.6z"/>
<path className="st16" d="M83.6,236.4l-0.8,0.4v-0.4H83.6z M83.6,236.8v-0.4l-0.8,0.4H83.6z"/>
<path className="st17" d="M83.6,236.4l-0.8,0.4v-0.4H83.6z M83.6,236.8v-0.4l-0.8,0.4H83.6z"/>
<path className="st16" d="M83.9,233.2l-0.2,1.4v-1.4H83.9z M83.9,234.6v-1.4l-0.2,1.4H83.9z M83.9,234.5v0.1v-1.4V234.5z"/>
<path className="st17" d="M83.9,233.2l-0.2,1.4v-1.4H83.9z M83.9,234.6v-1.4l-0.2,1.4H83.9z M83.9,234.5v0.1v-1.4V234.5z"/>
<path className="st16" d="M83.9,233.2v1.3V233.2z M83.9,233.2L83.9,233.2v1.3V233.2z M83.9,234.6v-1.4V234.6z"/>
<path className="st17" d="M83.9,233.2v1.3V233.2z M83.9,233.2L83.9,233.2v1.3V233.2z M83.9,234.6v-1.4V234.6z"/>
<path className="st16" d="M83.9,235l-0.2,0.6V235H83.9z M83.9,235.6V235l-0.2,0.6H83.9z"/>
<path className="st17" d="M83.9,235l-0.2,0.6V235H83.9z M83.9,235.6V235l-0.2,0.6H83.9z"/>
<path className="st16" d="M84,233.2l-0.1,1.3v-1.3H84z M84,234.6v-1.4l-0.1,1.3L84,234.6z"/>
<path className="st17" d="M84,233.2l-0.1,1.3v-1.3H84z M84,234.6v-1.4l-0.1,1.3L84,234.6z"/>
<path className="st16" d="M84,234.9l-0.1,0.7V235L84,234.9z M84,235.6v-0.7l-0.1,0.7H84z"/>
<path className="st17" d="M84,234.9l-0.1,0.7V235L84,234.9z M84,235.6v-0.7l-0.1,0.7H84z"/>
<path className="st16" d="M84.1,231.5l-0.5,1.7v-1.7H84.1z M84.1,233.2v-1.7l-0.5,1.7H84.1z"/>
<path className="st17" d="M84.1,231.5l-0.5,1.7v-1.7H84.1z M84.1,233.2v-1.7l-0.5,1.7H84.1z"/>
<path className="st16" d="M84.1,233.2l-0.1,1.4v-1.4H84.1z M84.1,234.8v-1.6l-0.1,1.4L84.1,234.8z"/>
<path className="st17" d="M84.1,233.2l-0.1,1.4v-1.4H84.1z M84.1,234.8v-1.6l-0.1,1.4L84.1,234.8z"/>
<path className="st16" d="M84.1,234.8l-0.1,0.8v-0.7L84.1,234.8z M84.1,235.6v-0.8l-0.1,0.8H84.1z"/>
<path className="st17" d="M84.1,234.8l-0.1,0.8v-0.7L84.1,234.8z M84.1,235.6v-0.8l-0.1,0.8H84.1z"/>
<path className="st16" d="M84.2,231.5l-0.1,1.7v-1.7H84.2z M84.2,233.2v-1.7l-0.1,1.7H84.2z"/>
<path className="st17" d="M84.2,231.5l-0.1,1.7v-1.7H84.2z M84.2,233.2v-1.7l-0.1,1.7H84.2z"/>
<path className="st16" d="M84.2,233.2l-0.1,1.6v-1.6H84.2z M84.2,234.8v-1.6l-0.1,1.6H84.2z"/>
<path className="st17" d="M84.2,233.2l-0.1,1.6v-1.6H84.2z M84.2,234.8v-1.6l-0.1,1.6H84.2z"/>
<path className="st16" d="M84.2,234.8l-0.1,0.8v-0.8H84.2z M84.2,235.6v-0.8l-0.1,0.8H84.2z"/>
<path className="st17" d="M84.2,234.8l-0.1,0.8v-0.8H84.2z M84.2,235.6v-0.8l-0.1,0.8H84.2z"/>
<path className="st16" d="M84.2,235.6l-0.6,0.5v-0.5H84.2z M84.2,236.1v-0.5l-0.6,0.5H84.2z"/>
<path className="st17" d="M84.2,235.6l-0.6,0.5v-0.5H84.2z M84.2,236.1v-0.5l-0.6,0.5H84.2z"/>
<path className="st16" d="M84.2,236.1l-0.6,0.2v-0.2H84.2z M84.2,236.3v-0.2l-0.6,0.2H84.2z"/>
<path className="st17" d="M84.2,236.1l-0.6,0.2v-0.2H84.2z M84.2,236.3v-0.2l-0.6,0.2H84.2z"/>
<path className="st16" d="M84.2,236.3l-0.6,0.5v-0.5H84.2z M84.2,236.8v-0.5l-0.6,0.5H84.2z"/>
<path className="st17" d="M84.2,236.3l-0.6,0.5v-0.5H84.2z M84.2,236.8v-0.5l-0.6,0.5H84.2z"/>
<path className="st16" d="M84.4,231.2l-0.1,2.9v-2.9H84.4z M84.4,234v-2.9l-0.1,2.9H84.4z"/>
<path className="st17" d="M84.4,231.2l-0.1,2.9v-2.9H84.4z M84.4,234v-2.9l-0.1,2.9H84.4z"/>
<path className="st16" d="M84.4,235.4l-0.1,1.7v-1.7H84.4z M84.4,237v-1.7l-0.1,1.7H84.4z"/>
<path className="st17" d="M84.4,235.4l-0.1,1.7v-1.7H84.4z M84.4,237v-1.7l-0.1,1.7H84.4z"/>
<path className="st16" d="M84.5,231.2l-0.1,3.6v-3.6H84.5z M84.5,234.8v-3.6l-0.1,3.6H84.5z"/>
<path className="st17" d="M84.5,231.2l-0.1,3.6v-3.6H84.5z M84.5,234.8v-3.6l-0.1,3.6H84.5z"/>
<path className="st16" d="M84.5,234.8l-0.1,2.3v-2.3H84.5z M84.5,237v-2.3l-0.1,2.3H84.5z"/>
<path className="st17" d="M84.5,234.8l-0.1,2.3v-2.3H84.5z M84.5,237v-2.3l-0.1,2.3H84.5z"/>
<line className="st19" x1="82.2" y1="234.8" x2="85.7" y2="234.8"/>
<line className="st19" x1="83.4" y1="233.2" x2="83.4" y2="231.5"/>
<line className="st19" x1="83.6" y1="234.6" x2="83.6" y2="233.2"/>
<line className="st19" x1="83.6" y1="236.1" x2="83.6" y2="235.6"/>
<polyline className="st19" points="84.2,236.8 83.6,236.8 83.6,236.3 84.2,236.3 "/>
<line className="st19" x1="83.6" y1="236.1" x2="84.2" y2="236.1"/>
<line className="st19" x1="83.6" y1="235.6" x2="84.2" y2="235.6"/>
<line className="st19" x1="83.4" y1="233.2" x2="84.2" y2="233.2"/>
<line className="st19" x1="83.4" y1="231.5" x2="84.2" y2="231.5"/>
<line className="st19" x1="83.3" y1="235" x2="83.3" y2="234.6"/>
<path className="st19" d="M83.6,234.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
	C83.7,234.5,83.6,234.6,83.6,234.7"/>
<line className="st19" x1="83.3" y1="235" x2="83.9" y2="235"/>
<line className="st19" x1="83.3" y1="234.6" x2="84" y2="234.6"/>
<polyline className="st19" points="84.2,235.4 84.4,235.4 84.4,234 84.2,234 "/>
<path className="st19" d="M82.7,234.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
	C82.8,234.5,82.7,234.6,82.7,234.7"/>
<path className="st19" d="M82.5,232.7c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.5-0.3,0.7"/>
<path className="st19" d="M81.5,235.7c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3"/>
<path className="st19" d="M81.8,235.7c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2"/>
<path className="st19" d="M82.5,233.1c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5"/>
<line className="st19" x1="81.8" y1="235.7" x2="81.8" y2="233.8"/>
<line className="st19" x1="82.6" y1="233.1" x2="83.4" y2="233.1"/>
<polyline className="st19" points="82.7,236.4 83.6,236.4 83.6,235 "/>
<line className="st19" x1="81.6" y1="235.7" x2="81.6" y2="233.8"/>
<path className="st19" d="M83.4,231.4c-0.3-0.1-0.7,0.1-0.9,0.4c-0.2,0.3-0.2,0.7-0.1,1"/>
<line className="st19" x1="84.2" y1="237" x2="84.2" y2="231.2"/>
<polyline className="st19" points="84.2,237 84.5,237 84.5,231.2 84.2,231.2 "/>
<line className="st19" x1="82.6" y1="232.7" x2="83.4" y2="232.7"/>
<line className="st19" x1="82.7" y1="236.8" x2="84.2" y2="236.8"/>
<path className="st20" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st21" d="M83.9,234.6v-0.1V234.6z"/>
<path className="st20" d="M84,234.6v-0.1V234.6z"/>
<path className="st21" d="M84,234.6v-0.1V234.6z"/>
<polygon className="st20" points="83.9,234.6 84,234.6 84,234.5 "/>
<polygon className="st21" points="83.9,234.6 84,234.6 84,234.5 "/>
<polygon className="st20" points="83.9,234.6 83.9,234.5 84,234.5 "/>
<polygon className="st21" points="83.9,234.6 83.9,234.5 84,234.5 "/>
<line className="st1" x1="181.4" y1="204.5" x2="167.2" y2="220.1"/>
<line className="st1" x1="70.3" y1="204.5" x2="84.6" y2="220.1"/>
<path className="st16" d="M70,205.6l0.5,0.4l-0.5-0.5V205.6z M70.3,206.1l-0.4-0.5l0.5,0.4L70.3,206.1z"/>
<path className="st17" d="M70,205.6l0.5,0.4l-0.5-0.5V205.6z M70.3,206.1l-0.4-0.5l0.5,0.4L70.3,206.1z"/>
<path className="st16" d="M68.5,207.3l1.7-1.3l-0.1-0.2L68.5,207.3z M68.6,207.5l-0.1-0.2l1.7-1.3L68.6,207.5z"/>
<path className="st17" d="M68.5,207.3l1.7-1.3l-0.1-0.2L68.5,207.3z M68.6,207.5l-0.1-0.2l1.7-1.3L68.6,207.5z"/>
<path className="st16" d="M68.3,207.2l0.5,0.4l-0.4-0.4H68.3z M68.3,207.2L68.3,207.2l0.5,0.4L68.3,207.2z"/>
<path className="st17" d="M68.3,207.2l0.5,0.4l-0.4-0.4H68.3z M68.3,207.2L68.3,207.2l0.5,0.4L68.3,207.2z"/>
<path className="st16" d="M68.8,207.5L68.8,207.5l1.6,1.6L68.8,207.5z M70.3,209.1l-1.6-1.6L70.3,209.1z"/>
<path className="st17" d="M68.8,207.5L68.8,207.5l1.6,1.6L68.8,207.5z M70.3,209.1l-1.6-1.6L70.3,209.1z"/>
<path className="st16" d="M68.2,207.2l0.6,0.4l-0.5-0.4H68.2z M68.6,207.6l-0.5-0.5l0.6,0.4L68.6,207.6z"/>
<path className="st17" d="M68.2,207.2l0.6,0.4l-0.5-0.4H68.2z M68.6,207.6l-0.5-0.5l0.6,0.4L68.6,207.6z"/>
<path className="st16" d="M68.2,207.3l0.5,0.4l-0.5-0.5V207.3z M68.6,207.8l-0.5-0.5l0.5,0.4V207.8z"/>
<path className="st17" d="M68.2,207.3l0.5,0.4l-0.5-0.5V207.3z M68.6,207.8l-0.5-0.5l0.5,0.4V207.8z"/>
<path className="st16" d="M68.2,207.4l0.5,0.4l-0.5-0.5V207.4z M68.2,207.4L68.2,207.4l0.5,0.4L68.2,207.4z"/>
<path className="st17" d="M68.2,207.4l0.5,0.4l-0.5-0.5V207.4z M68.2,207.4L68.2,207.4l0.5,0.4L68.2,207.4z"/>
<path className="st16" d="M68.6,207.8l1.7,1.3l-1.6-1.6L68.6,207.8z M70.1,209.3l-1.4-1.6l1.7,1.3L70.1,209.3z"/>
<path className="st17" d="M68.6,207.8l1.7,1.3l-1.6-1.6L68.6,207.8z M70.1,209.3l-1.4-1.6l1.7,1.3L70.1,209.3z"/>
<path className="st16" d="M67,208.6l1.3-1.2h-0.1L67,208.6z M67.1,208.7l-0.1-0.1l1.3-1.2L67.1,208.7z"/>
<path className="st17" d="M67,208.6l1.3-1.2h-0.1L67,208.6z M67.1,208.7l-0.1-0.1l1.3-1.2L67.1,208.7z"/>
<path className="st16" d="M67.1,208.7l1.4-1.1l-0.2-0.2L67.1,208.7z M67.2,209l-0.1-0.2l1.4-1.1L67.2,209z"/>
<path className="st17" d="M67.1,208.7l1.4-1.1l-0.2-0.2L67.1,208.7z M67.2,209l-0.1-0.2l1.4-1.1L67.2,209z"/>
<path className="st16" d="M67.3,209l2.8,0.4l-1.4-1.6L67.3,209z M68.9,210.5l-1.6-1.6l2.8,0.4L68.9,210.5z"/>
<path className="st17" d="M67.3,209l2.8,0.4l-1.4-1.6L67.3,209z M68.9,210.5l-1.6-1.6l2.8,0.4L68.9,210.5z"/>
<path className="st16" d="M66.8,208.6l0.5,0.4l-0.4-0.4H66.8z M67.3,209.1l-0.5-0.5l0.5,0.4V209.1z"/>
<path className="st17" d="M66.8,208.6l0.5,0.4l-0.4-0.4H66.8z M67.3,209.1l-0.5-0.5l0.5,0.4V209.1z"/>
<path className="st16" d="M66.7,208.6l0.6,0.5l-0.5-0.5H66.7z M67.2,209.1l-0.5-0.5l0.6,0.5H67.2z"/>
<path className="st17" d="M66.7,208.6l0.6,0.5l-0.5-0.5H66.7z M67.2,209.1l-0.5-0.5l0.6,0.5H67.2z"/>
<path className="st16" d="M66.7,208.7l0.5,0.4l-0.5-0.5V208.7z M67.2,209.2l-0.5-0.5l0.5,0.4V209.2z"/>
<path className="st17" d="M66.7,208.7l0.5,0.4l-0.5-0.5V208.7z M67.2,209.2l-0.5-0.5l0.5,0.4V209.2z"/>
<path className="st16" d="M66.7,208.8l0.5,0.4l-0.5-0.5V208.8z M67.1,209.2l-0.4-0.4l0.5,0.4H67.1z"/>
<path className="st17" d="M66.7,208.8l0.5,0.4l-0.5-0.5V208.8z M67.1,209.2l-0.4-0.4l0.5,0.4H67.1z"/>
<path className="st16" d="M67.1,209.2l1.8,1.3l-1.6-1.6L67.1,209.2z M68.6,210.8l-1.6-1.6l1.8,1.3L68.6,210.8z"/>
<path className="st17" d="M67.1,209.2l1.8,1.3l-1.6-1.6L67.1,209.2z M68.6,210.8l-1.6-1.6l1.8,1.3L68.6,210.8z"/>
<path className="st16" d="M66.2,209.4l0.8-0.4l-0.2-0.1L66.2,209.4z M66.5,209.7l-0.2-0.2l0.8-0.4L66.5,209.7z"/>
<path className="st17" d="M66.2,209.4l0.8-0.4l-0.2-0.1L66.2,209.4z M66.5,209.7l-0.2-0.2l0.8-0.4L66.5,209.7z"/>
<path className="st16" d="M66.1,209.6l0.4,0.1l-0.2-0.2L66.1,209.6z M66.4,209.8l-0.2-0.2l0.4,0.1L66.4,209.8z"/>
<path className="st17" d="M66.1,209.6l0.4,0.1l-0.2-0.2L66.1,209.6z M66.4,209.8l-0.2-0.2l0.4,0.1L66.4,209.8z"/>
<path className="st16" d="M66,209.4l0.4,0.4l-0.2-0.2L66,209.4z M66.2,209.8l-0.2-0.4l0.4,0.4H66.2z"/>
<path className="st17" d="M66,209.4l0.4,0.4l-0.2-0.2L66,209.4z M66.2,209.8l-0.2-0.4l0.4,0.4H66.2z"/>
<path className="st16" d="M65.6,209.3l0.2-0.1v-0.1L65.6,209.3z M65.6,209.3L65.6,209.3l0.2-0.1L65.6,209.3z"/>
<path className="st17" d="M65.6,209.3l0.2-0.1v-0.1L65.6,209.3z M65.6,209.3L65.6,209.3l0.2-0.1L65.6,209.3z"/>
<path className="st16" d="M65.6,209.3l0.6,0.5l-0.5-0.5H65.6z M66.1,209.8l-0.5-0.5l0.6,0.5H66.1z"/>
<path className="st17" d="M65.6,209.3l0.6,0.5l-0.5-0.5H65.6z M66.1,209.8l-0.5-0.5l0.6,0.5H66.1z"/>
<path className="st16" d="M65.6,209.3L65.6,209.3l0.5,0.5L65.6,209.3z M65.6,209.3L65.6,209.3z M65.6,209.3L65.6,209.3z M66.1,209.8
	l-0.5-0.5L66.1,209.8z"/>
<path className="st17" d="M65.6,209.3L65.6,209.3l0.5,0.5L65.6,209.3z M65.6,209.3L65.6,209.3z M65.6,209.3L65.6,209.3z M66.1,209.8
	l-0.5-0.5L66.1,209.8z"/>
<path className="st16" d="M65.6,209.4l0.5,0.4l-0.5-0.5V209.4z M65.8,209.7l-0.1-0.2l0.5,0.4L65.8,209.7z"/>
<path className="st17" d="M65.6,209.4l0.5,0.4l-0.5-0.5V209.4z M65.8,209.7l-0.1-0.2l0.5,0.4L65.8,209.7z"/>
<path className="st16" d="M65.4,209.6l0.2-0.2L65.4,209.6z M65.5,209.6h-0.1l0.2-0.2L65.5,209.6z"/>
<path className="st17" d="M65.4,209.6l0.2-0.2L65.4,209.6z M65.5,209.6h-0.1l0.2-0.2L65.5,209.6z"/>
<line className="st18" x1="68.5" y1="207.3" x2="70.1" y2="205.7"/>
<line className="st18" x1="68.6" y1="207.5" x2="70.2" y2="206"/>
<line className="st18" x1="66.5" y1="209.7" x2="67.1" y2="209.1"/>
<line className="st18" x1="66.2" y1="209.4" x2="66.8" y2="209"/>
<line className="st18" x1="65.8" y1="209.7" x2="65.6" y2="209.4"/>
<line className="st18" x1="66" y1="209.4" x2="65.8" y2="209.3"/>
<path className="st18" d="M65.8,209.6c0.2,0.2,0.4,0.2,0.6,0"/>
<path className="st18" d="M65.9,209.4c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st18" x1="65.5" y1="209.6" x2="65.4" y2="209.6"/>
<line className="st18" x1="65.9" y1="209.2" x2="65.9" y2="209.1"/>
<polyline className="st18" points="65.5,209.6 65.6,209.3 65.9,209.2 "/>
<line className="st18" x1="70.3" y1="206.1" x2="70.4" y2="206"/>
<polyline className="st18" points="70.3,206.1 70.1,205.8 70,205.6 70,205.5 "/>
<polyline className="st18" points="66.7,208.8 67.1,209.1 68.6,210.8 70.3,209.1 68.6,207.4 68.4,207.2 "/>
<line className="st18" x1="68.6" y1="207.8" x2="68.2" y2="207.4"/>
<line className="st18" x1="67.3" y1="209" x2="67" y2="208.6"/>
<path className="st18" d="M66.9,208.5c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3"/>
<path className="st18" d="M68.3,207.1c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3"/>
<polyline className="st18" points="68.3,207.4 67.1,208.7 68.3,207.4 "/>
<line className="st18" x1="67.2" y1="209" x2="68.5" y2="207.6"/>
<line className="st18" x1="67.1" y1="209.2" x2="68.8" y2="207.5"/>
<polyline className="st18" points="68.5,207.6 67.2,209 68.5,207.6 "/>
<line className="st18" x1="67" y1="208.6" x2="68.2" y2="207.4"/>
<line className="st1" x1="229.4" y1="226.5" x2="237.6" y2="236"/>
<polyline className="st1" points="111.7,199.8 112.7,187.1 112.7,199.8 111.7,199.8 "/>
<polyline className="st1" points="115.3,201.4 114.4,174.5 114.4,201.4 115.3,201.4 "/>
<polyline className="st1" points="140,201.4 139.1,187.1 139.1,201.4 140,201.4 "/>
<polyline className="st1" points="165,163.4 167,161.2 170,164.3 165,163.4 "/>
<polyline className="st1" points="181.4,204.5 167.2,218.7 167.2,220.1 181.4,204.5 "/>
<polyline className="st1" points="182.8,181.2 193.3,190.2 192.6,191 182.8,181.2 "/>
<polyline className="st1" points="229.4,226.5 228.7,227.2 237.6,236 229.4,226.5 "/>
<polyline className="st1" points="226.2,262.4 232,255.2 232.7,255.9 226.2,262.4 "/>
<polyline className="st1" points="227.4,263.6 234.6,257.7 233.9,257 227.4,263.6 "/>
<polyline className="st1" points="307.8,250.7 313.7,243.5 314.4,244.1 307.8,250.7 "/>
<polyline className="st1" points="309,251.9 316.2,246 315.5,245.3 309,251.9 "/>
<polyline className="st1" points="343.9,286.8 351.2,281.1 350.5,280.4 343.9,286.8 "/>
<polyline className="st1" points="379,321.9 386.2,316 385.4,315.3 379,321.9 "/>
<polyline className="st1" points="296.6,264.3 294.5,266.3 299.5,269.2 296.6,264.3 "/>
<polyline className="st1" points="304.4,272.1 302.3,274.1 299.5,269.2 304.4,272.1 "/>
<polyline className="st1" points="289.1,287.4 295.1,280 295.8,280.7 289.1,287.4 "/>
<polyline className="st1" points="296.3,280.2 301.2,273.9 301.9,274.6 296.3,280.2 "/>
<polyline className="st1" points="305,272.7 299.9,277.7 307.8,285.6 302,277.5 305,272.7 "/>
<polyline className="st1" points="309.2,303.4 307.1,305.6 312.1,308.4 309.2,303.4 "/>
<polyline className="st1" points="317.2,311.3 315,313.5 312.1,308.4 317.2,311.3 "/>
<polyline className="st1" points="349.3,347.8 359.2,339.4 350,348.4 349.3,347.8 "/>
<polyline className="st1" points="390.1,383.1 395,376.7 389.4,382.5 390.1,383.1 "/>
<polyline className="st1" points="388.3,382.5 386.2,384.6 390.4,386.7 388.3,382.5 "/>
<polyline className="st1" points="394.6,388.7 392.4,390.9 390.4,386.7 394.6,388.7 "/>
<line className="st1" x1="395" y1="389.2" x2="399.1" y2="397.6"/>
<line className="st1" x1="401.3" y1="395.4" x2="392.9" y2="391.4"/>
<polyline className="st1" points="395,389.2 392.9,391.4 397.1,393.4 395,389.2 "/>
<polyline className="st1" points="401.3,395.4 399.1,397.6 397.1,393.4 401.3,395.4 "/>
<polyline className="st1" points="404.6,398.8 399.7,398.1 401.8,396 404.6,398.8 "/>
<polyline className="st1" points="373.8,397 380.9,405.4 381.5,404.7 373.8,397 "/>
<polyline className="st1" points="355.7,380.2 360.5,383.7 356.3,379.5 355.7,380.2 "/>
<line className="st1" x1="430.6" y1="318.8" x2="423.8" y2="311.9"/>
<line className="st1" x1="432" y1="317.3" x2="425.3" y2="310.5"/>
<line className="st1" x1="433.4" y1="315.9" x2="426.6" y2="309.2"/>
<line className="st1" x1="418.4" y1="329.9" x2="434.8" y2="313.6"/>
<line className="st1" x1="434.8" y1="314.6" x2="428" y2="307.7"/>
<line className="st1" x1="431.2" y1="310.8" x2="415.8" y2="326.3"/>
<line className="st1" x1="425" y1="324.3" x2="418.2" y2="317.6"/>
<line className="st1" x1="426.4" y1="322.8" x2="419.6" y2="316.1"/>
<line className="st1" x1="427.8" y1="321.5" x2="421.1" y2="314.7"/>
<line className="st1" x1="429.2" y1="320.1" x2="422.4" y2="313.4"/>
<line className="st1" x1="419.4" y1="329.9" x2="412.7" y2="323.1"/>
<line className="st1" x1="420.8" y1="328.5" x2="414" y2="321.8"/>
<line className="st1" x1="422.2" y1="327" x2="415.4" y2="320.3"/>
<line className="st1" x1="423.6" y1="325.7" x2="416.9" y2="318.9"/>
<polyline className="st1" points="417.8,326.3 415.8,326.3 415.8,324.3 417.8,326.3 "/>
<polyline className="st1" points="437.6,317.3 422.2,332.7 419.4,329.9 434.8,314.6 437.6,317.3 "/>
<line className="st1" x1="438.6" y1="317.3" x2="422.2" y2="333.6"/>
<line className="st1" x1="437.6" y1="317.3" x2="444.4" y2="324"/>
<line className="st1" x1="434.8" y1="313.6" x2="438.6" y2="317.3"/>
<line className="st1" x1="434.8" y1="320.1" x2="441.6" y2="326.9"/>
<line className="st1" x1="436.2" y1="318.8" x2="442.9" y2="325.5"/>
<line className="st1" x1="439.2" y1="320.9" x2="441.2" y2="322.8"/>
<polyline className="st1" points="441.2,322.8 441.2,320.9 425.9,336.3 "/>
<line className="st1" x1="441.2" y1="320.9" x2="439.2" y2="320.9"/>
<line className="st1" x1="432" y1="322.8" x2="438.7" y2="329.7"/>
<line className="st1" x1="433.4" y1="321.5" x2="440.2" y2="328.2"/>
<line className="st1" x1="426.4" y1="328.5" x2="433.2" y2="335.3"/>
<line className="st1" x1="427.8" y1="327" x2="434.5" y2="333.9"/>
<line className="st1" x1="429.2" y1="325.7" x2="436" y2="332.4"/>
<line className="st1" x1="430.6" y1="324.3" x2="437.4" y2="331.1"/>
<line className="st1" x1="430.7" y1="329.4" x2="432.6" y2="331.5"/>
<line className="st1" x1="437" y1="331.4" x2="427.8" y2="327.2"/>
<line className="st1" x1="437.4" y1="331" x2="428.2" y2="326.8"/>
<polyline className="st1" points="430.7,329.4 432.6,329.4 432.6,331.5 "/>
<line className="st1" x1="423.6" y1="331.2" x2="430.4" y2="338.1"/>
<line className="st1" x1="425" y1="329.9" x2="431.8" y2="336.6"/>
<line className="st1" x1="422.2" y1="332.7" x2="429" y2="339.5"/>
<line className="st1" x1="428.5" y1="339.9" x2="418.4" y2="329.9"/>
<polyline className="st1" points="403.8,332.9 403.3,332.4 403.8,332 404.3,332.4 403.8,332.9 "/>
<path className="st5" d="M414.6,335.8c-2-1.8-5-1.7-6.9,0.2"/>
<line className="st5" x1="411.4" y1="339.5" x2="414.8" y2="336"/>
<polyline className="st5" points="411.4,339.5 411.1,339.4 414.7,335.8 414.8,336 "/>
<path className="st5" d="M407.7,335.9c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="404.3" y1="332.4" x2="407.8" y2="329"/>
<polyline className="st5" points="404.3,332.4 404.4,332.7 408,329.1 407.8,329 "/>
<line className="st6" x1="404.4" y1="332.6" x2="407.9" y2="329"/>
<line className="st6" x1="411.2" y1="339.5" x2="414.8" y2="335.9"/>
<polyline className="st1" points="411.4,340.5 410.9,340 411.4,339.5 411.8,340 411.4,340.5 "/>
<polyline className="st1" points="411.8,340 419.6,347.8 419.2,348.3 411.4,340.5 411.8,340 "/>
<line className="st2" x1="411.6" y1="340.2" x2="419.4" y2="348"/>
<polyline className="st1" points="419.6,348.8 419.2,348.3 419.6,347.8 420.1,348.3 419.6,348.8 "/>
<path className="st5" d="M403,374.7c1.8-2,1.7-5-0.2-6.9"/>
<line className="st5" x1="399.4" y1="371.4" x2="403" y2="374.9"/>
<polyline className="st5" points="399.4,371.4 399.6,371.2 403.1,374.8 403,374.9 "/>
<path className="st5" d="M402.9,367.8c1.9,1.9,4.9,2,6.9,0.2"/>
<line className="st5" x1="406.4" y1="364.4" x2="410" y2="367.8"/>
<polyline className="st5" points="406.4,364.4 406.3,364.5 409.8,368.1 410,367.8 "/>
<path className="st16" d="M350.2,387.3l0.1,6.6l3.2-3.2L350.2,387.3z M346.8,390.5l3.4-3.2l0.1,6.6L346.8,390.5z"/>
<path className="st17" d="M350.2,387.3l0.1,6.6l3.2-3.2L350.2,387.3z M346.8,390.5l3.4-3.2l0.1,6.6L346.8,390.5z"/>
<path className="st16" d="M350,387.2l-2.5,2.8l2.6-2.6L350,387.2z M347.5,389.7l2.5-2.5l-2.5,2.8V389.7z"/>
<path className="st17" d="M350,387.2l-2.5,2.8l2.6-2.6L350,387.2z M347.5,389.7l2.5-2.5l-2.5,2.8V389.7z"/>
<path className="st16" d="M347.3,389.9l-0.5,0.6l0.7-0.6H347.3z M347.3,389.9L347.3,389.9l-0.5,0.6L347.3,389.9z"/>
<path className="st17" d="M347.3,389.9l-0.5,0.6l0.7-0.6H347.3z M347.3,389.9L347.3,389.9l-0.5,0.6L347.3,389.9z"/>
<path className="st16" d="M349.9,387l-2.4,2.6l2.5-2.5L349.9,387z M347.5,389.6l2.4-2.5l-2.4,2.6V389.6z"/>
<path className="st17" d="M349.9,387l-2.4,2.6l2.5-2.5L349.9,387z M347.5,389.6l2.4-2.5l-2.4,2.6V389.6z"/>
<path className="st16" d="M347.2,389.9l-0.4,0.6l0.5-0.6H347.2z M346.7,390.4l0.5-0.5l-0.4,0.6L346.7,390.4z"/>
<path className="st17" d="M347.2,389.9l-0.4,0.6l0.5-0.6H347.2z M346.7,390.4l0.5-0.5l-0.4,0.6L346.7,390.4z"/>
<path className="st16" d="M349.9,387l-2.4,2.5L349.9,387z M347.3,389.6l2.6-2.5l-2.4,2.5H347.3z"/>
<path className="st17" d="M349.9,387l-2.4,2.5L349.9,387z M347.3,389.6l2.6-2.5l-2.4,2.5H347.3z"/>
<path className="st16" d="M347.2,389.7l-0.5,0.7l0.5-0.5V389.7z M346.6,390.3l0.6-0.6l-0.5,0.7L346.6,390.3z"/>
<path className="st17" d="M347.2,389.7l-0.5,0.7l0.5-0.5V389.7z M346.6,390.3l0.6-0.6l-0.5,0.7L346.6,390.3z"/>
<path className="st16" d="M349.8,386.9l-2.5,2.6l2.6-2.5L349.8,386.9z M347.2,389.6l2.6-2.6l-2.5,2.6H347.2z"/>
<path className="st17" d="M349.8,386.9l-2.5,2.6l2.6-2.5L349.8,386.9z M347.2,389.6l2.6-2.6l-2.5,2.6H347.2z"/>
<path className="st16" d="M347.2,389.6l-0.6,0.7l0.6-0.6V389.6z M347.2,389.6L347.2,389.6l-0.6,0.7L347.2,389.6z"/>
<path className="st17" d="M347.2,389.6l-0.6,0.7l0.6-0.6V389.6z M347.2,389.6L347.2,389.6l-0.6,0.7L347.2,389.6z"/>
<path className="st16" d="M349.3,386.4l-2.8,3.8l3.2-3.4L349.3,386.4z M346.1,389.7l3.2-3.2l-2.8,3.8L346.1,389.7z"/>
<path className="st17" d="M349.3,386.4l-2.8,3.8l3.2-3.4L349.3,386.4z M346.1,389.7l3.2-3.2l-2.8,3.8L346.1,389.7z"/>
<line className="st18" x1="347.6" y1="389.7" x2="353.5" y2="390.6"/>
<polyline className="st18" points="346.1,389.7 349.3,386.4 353.5,390.6 350.3,393.9 346.1,389.7 "/>
<line className="st18" x1="350.3" y1="393.9" x2="347.5" y2="390"/>
<path className="st18" d="M347.1,389.5c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C347.3,389.4,347.2,389.4,347.1,389.5"/>
<line className="st18" x1="347.5" y1="389.4" x2="349.3" y2="386.4"/>
<line className="st1" x1="111.7" y1="199.8" x2="111.7" y2="187.1"/>
<polyline className="st1" points="111.7,200.6 106.9,202.2 111.7,202.2 106.9,200.6 "/>
<polyline className="st1" points="88.4,154 89,153.3 91.2,156.8 "/>
<polyline className="st1" points="92.4,156.5 90.2,161.1 93.6,157.7 89,159.9 "/>
<line className="st1" x1="151.9" y1="151.5" x2="156.6" y2="156.2"/>
<line className="st1" x1="152.5" y1="150.9" x2="157.2" y2="155.6"/>
<line className="st1" x1="151.9" y1="151.5" x2="152.2" y2="155.4"/>
<line className="st1" x1="158.6" y1="158.2" x2="163.3" y2="162.9"/>
<line className="st1" x1="163.9" y1="162.3" x2="159.2" y2="157.6"/>
<polyline className="st1" points="168.1,207.4 166.3,212.1 166.3,207.4 168.1,212.1 "/>
<line className="st1" x1="165.5" y1="207.9" x2="166.6" y2="207.9"/>
<line className="st1" x1="304.6" y1="271" x2="297.7" y2="264.2"/>
<line className="st1" x1="297.1" y1="264.8" x2="304" y2="271.6"/>
<line className="st1" x1="304.6" y1="271" x2="302.9" y2="266.7"/>
<line className="st1" x1="297.7" y1="264.2" x2="302" y2="265.8"/>
<polyline className="st1" points="267.2,256.2 265.1,260.8 268.4,257.4 263.9,259.6 "/>
<line className="st1" x1="302.4" y1="296.9" x2="302.8" y2="301.1"/>
<polyline className="st1" points="307.8,303.8 302.6,302.2 306,305.6 304.4,300.4 "/>
<line className="st1" x1="314" y1="313.6" x2="307" y2="306.5"/>
<line className="st1" x1="314.5" y1="313" x2="307.6" y2="306"/>
<polyline className="st1" points="364.8,333.6 362.6,329.1 366,332.4 361.4,330.3 "/>
<polyline className="st1" points="393.5,377.4 388.9,379.6 392.3,376.2 390.1,380.8 "/>
<line className="st1" x1="391.3" y1="391" x2="386" y2="385.6"/>
<line className="st1" x1="386.6" y1="385.1" x2="391.9" y2="390.4"/>
<line className="st1" x1="392.8" y1="392.3" x2="398" y2="397.6"/>
<line className="st1" x1="398.6" y1="397.1" x2="393.4" y2="391.7"/>
<line className="st1" x1="394.2" y1="395.4" x2="398" y2="397.6"/>
<polyline className="st1" points="298.7,297.2 314.2,281.7 302.4,296.9 302.8,301.1 298.7,297.2 "/>
<line className="st1" x1="95.2" y1="156.2" x2="97.7" y2="156.2"/>
<line className="st1" x1="94.6" y1="155.6" x2="99.2" y2="150.9"/>
<line className="st1" x1="95.2" y1="156.2" x2="99.8" y2="151.5"/>
<line className="st1" x1="92.3" y1="154.1" x2="99.7" y2="150.4"/>
<line className="st1" x1="97.9" y1="148.6" x2="94.2" y2="156"/>
<line className="st1" x1="53.4" y1="197.9" x2="55.9" y2="197.9"/>
<line className="st1" x1="52.9" y1="197.3" x2="57.5" y2="192.6"/>
<line className="st1" x1="53.4" y1="197.9" x2="58.1" y2="193.2"/>
<line className="st1" x1="167.2" y1="216.4" x2="168.1" y2="212.8"/>
<line className="st5" x1="107.2" y1="218.1" x2="107.4" y2="218.1"/>
<polyline className="st5" points="107.4,218.1 107.4,226 107.2,226 107.2,218.1 "/>
<path className="st5" d="M99.5,218c0,4.2,3.4,7.7,7.6,7.9"/>
<path className="st16" d="M158.4,220.8l-2.5,0.1h2L158.4,220.8z M155.4,220.8h3l-2.5,0.1L155.4,220.8z M158.4,220.8h-3H158.4z
	 M158.4,220.8L158.4,220.8h-3H158.4z"/>
<path className="st17" d="M158.4,220.8l-2.5,0.1h2L158.4,220.8z M155.4,220.8h3l-2.5,0.1L155.4,220.8z M158.4,220.8h-3H158.4z
	 M158.4,220.8L158.4,220.8h-3H158.4z"/>
<path className="st16" d="M158.5,220.7l-3.1,0.1h3L158.5,220.7z M155.2,220.7h3.4l-3.1,0.1L155.2,220.7z"/>
<path className="st17" d="M158.5,220.7l-3.1,0.1h3L158.5,220.7z M155.2,220.7h3.4l-3.1,0.1L155.2,220.7z"/>
<path className="st16" d="M158.8,220.6l-0.8,0.1h0.6L158.8,220.6z M158.2,220.6h0.6l-0.8,0.1L158.2,220.6z"/>
<path className="st17" d="M158.8,220.6l-0.8,0.1h0.6L158.8,220.6z M158.2,220.6h0.6l-0.8,0.1L158.2,220.6z"/>
<path className="st16" d="M158.2,220.6l-2.3,0.1h2L158.2,220.6z M155.6,220.6h2.5l-2.3,0.1L155.6,220.6z"/>
<path className="st17" d="M158.2,220.6l-2.3,0.1h2L158.2,220.6z M155.6,220.6h2.5l-2.3,0.1L155.6,220.6z"/>
<path className="st16" d="M158.8,220.5l-0.6,0.1h0.6V220.5z M158.3,220.5h0.5l-0.6,0.1L158.3,220.5z"/>
<path className="st17" d="M158.8,220.5l-0.6,0.1h0.6V220.5z M158.3,220.5h0.5l-0.6,0.1L158.3,220.5z"/>
<path className="st16" d="M158.3,220.5l-2.6,0.1h2.5L158.3,220.5z M155.5,220.5h2.8l-2.6,0.1L155.5,220.5z"/>
<path className="st17" d="M158.3,220.5l-2.6,0.1h2.5L158.3,220.5z M155.5,220.5h2.8l-2.6,0.1L155.5,220.5z"/>
<path className="st16" d="M158.8,220.5h-0.5H158.8z M158.4,220.5h0.4h-0.5H158.4z"/>
<path className="st17" d="M158.8,220.5h-0.5H158.8z M158.4,220.5h0.4h-0.5H158.4z"/>
<path className="st16" d="M158.4,220.5h-2.9h2.8H158.4z M155.4,220.5h3h-2.9H155.4z"/>
<path className="st17" d="M158.4,220.5h-2.9h2.8H158.4z M155.4,220.5h3h-2.9H155.4z"/>
<path className="st16" d="M158.8,220.5L158.8,220.5h-0.4H158.8z M158.4,220.5h0.4H158.4z"/>
<path className="st17" d="M158.8,220.5L158.8,220.5h-0.4H158.8z M158.4,220.5h0.4H158.4z"/>
<path className="st16" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st17" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st16" d="M158.8,220.5L158.8,220.5h-0.4H158.8z M158.4,220.5h0.4H158.4z"/>
<path className="st17" d="M158.8,220.5L158.8,220.5h-0.4H158.8z M158.4,220.5h0.4H158.4z"/>
<path className="st16" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st17" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st16" d="M155.4,220.5l-0.2,0.2h0.7L155.4,220.5z M155,220.5h0.4l-0.2,0.2L155,220.5z"/>
<path className="st17" d="M155.4,220.5l-0.2,0.2h0.7L155.4,220.5z M155,220.5h0.4l-0.2,0.2L155,220.5z"/>
<path className="st16" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st17" d="M158.4,220.5L158.4,220.5h-3H158.4z M155.4,220.5h3H155.4z"/>
<path className="st16" d="M155.4,220.5L155.4,220.5H155H155.4z M155,220.5h0.4H155z"/>
<path className="st17" d="M155.4,220.5L155.4,220.5H155H155.4z M155,220.5h0.4H155z"/>
<polygon className="st16" points="159.5,220.1 158.9,220 159.6,220 "/>
<polygon className="st17" points="159.5,220.1 158.9,220 159.6,220 "/>
<path className="st16" d="M158.5,220.1l-3.1,0.4h3L158.5,220.1z M155.3,220.1h3.2l-3.1,0.4L155.3,220.1z"/>
<path className="st17" d="M158.5,220.1l-3.1,0.4h3L158.5,220.1z M155.3,220.1h3.2l-3.1,0.4L155.3,220.1z"/>
<path className="st16" d="M155.3,220.1l-0.2,0.4h0.4L155.3,220.1z M154.9,220.1h0.4l-0.2,0.4L154.9,220.1z"/>
<path className="st17" d="M155.3,220.1l-0.2,0.4h0.4L155.3,220.1z M154.9,220.1h0.4l-0.2,0.4L154.9,220.1z"/>
<path className="st16" d="M159.6,220L159.6,220h-0.7H159.6z M158.9,220h0.7H158.9z"/>
<path className="st17" d="M159.6,220L159.6,220h-0.7H159.6z M158.9,220h0.7H158.9z"/>
<path className="st16" d="M159.7,220h-0.8h0.7H159.7z M159.7,220L159.7,220h-0.8H159.7z"/>
<path className="st17" d="M159.7,220h-0.8h0.7H159.7z M159.7,220L159.7,220h-0.8H159.7z"/>
<path className="st16" d="M158.9,220l-0.5,0.5h0.4L158.9,220z M158.5,220h0.4l-0.5,0.5L158.5,220z"/>
<path className="st17" d="M158.9,220l-0.5,0.5h0.4L158.9,220z M158.5,220h0.4l-0.5,0.5L158.5,220z"/>
<path className="st16" d="M159.7,220L159.7,220h-0.8H159.7z M158.9,220h0.8H158.9z"/>
<path className="st17" d="M159.7,220L159.7,220h-0.8H159.7z M158.9,220h0.8H158.9z"/>
<path className="st16" d="M158.9,220L158.9,220h-0.4H158.9z M158.5,220h0.4H158.5z"/>
<path className="st17" d="M158.9,220L158.9,220h-0.4H158.9z M158.5,220h0.4H158.5z"/>
<path className="st16" d="M158.5,220l-3.2,0.1h3.2V220z M155.3,220h3.2l-3.2,0.1V220z"/>
<path className="st17" d="M158.5,220l-3.2,0.1h3.2V220z M155.3,220h3.2l-3.2,0.1V220z"/>
<path className="st16" d="M159.8,219.9l-1,0.1h0.8L159.8,219.9z M158.9,219.9h1l-1,0.1V219.9z"/>
<path className="st17" d="M159.8,219.9l-1,0.1h0.8L159.8,219.9z M158.9,219.9h1l-1,0.1V219.9z"/>
<path className="st16" d="M158.9,219.9l-0.4,0.1h0.4V219.9z M158.5,219.9h0.4l-0.4,0.1V219.9z"/>
<path className="st17" d="M158.9,219.9l-0.4,0.1h0.4V219.9z M158.5,219.9h0.4l-0.4,0.1V219.9z"/>
<path className="st16" d="M158.5,219.9l-3.2,0.1h3.2V219.9z M155.3,219.9h3.2l-3.2,0.1V219.9z"/>
<path className="st17" d="M158.5,219.9l-3.2,0.1h3.2V219.9z M155.3,219.9h3.2l-3.2,0.1V219.9z"/>
<path className="st16" d="M155.3,219.9l-0.4,0.2h0.4V219.9z M154.9,219.9h0.4l-0.4,0.2V219.9z"/>
<path className="st17" d="M155.3,219.9l-0.4,0.2h0.4V219.9z M154.9,219.9h0.4l-0.4,0.2V219.9z"/>
<path className="st16" d="M159.8,219.9L159.8,219.9h-1H159.8z M158.9,219.9h1H158.9z"/>
<path className="st17" d="M159.8,219.9L159.8,219.9h-1H159.8z M158.9,219.9h1H158.9z"/>
<path className="st16" d="M158.9,219.9L158.9,219.9h-0.4H158.9z M158.5,219.9h0.4H158.5z"/>
<path className="st17" d="M158.9,219.9L158.9,219.9h-0.4H158.9z M158.5,219.9h0.4H158.5z"/>
<path className="st16" d="M158.5,219.9L158.5,219.9h-3.2H158.5z M155.3,219.9h3.2H155.3z"/>
<path className="st17" d="M158.5,219.9L158.5,219.9h-3.2H158.5z M155.3,219.9h3.2H155.3z"/>
<path className="st16" d="M155.3,219.9L155.3,219.9h-0.4H155.3z M154.9,219.9h0.4H154.9z"/>
<path className="st17" d="M155.3,219.9L155.3,219.9h-0.4H155.3z M154.9,219.9h0.4H154.9z"/>
<path className="st16" d="M160,219.9h-1.1h1H160z M160,219.9L160,219.9h-1.1H160z"/>
<path className="st17" d="M160,219.9h-1.1h1H160z M160,219.9L160,219.9h-1.1H160z"/>
<path className="st16" d="M158.9,219.9L158.9,219.9h-0.4H158.9z M158.5,219.9h0.4H158.5z"/>
<path className="st17" d="M158.9,219.9L158.9,219.9h-0.4H158.9z M158.5,219.9h0.4H158.5z"/>
<path className="st16" d="M158.5,219.9L158.5,219.9h-3.2H158.5z M155.3,219.9h3.2H155.3z"/>
<path className="st17" d="M158.5,219.9L158.5,219.9h-3.2H158.5z M155.3,219.9h3.2H155.3z"/>
<path className="st16" d="M155.3,219.9L155.3,219.9h-0.4H155.3z M154.9,219.9h0.4H154.9z"/>
<path className="st17" d="M155.3,219.9L155.3,219.9h-0.4H155.3z M154.9,219.9h0.4H154.9z"/>
<path className="st16" d="M160,219.8l-1.1,0.1h1.1V219.8z M158.9,219.8h1.1l-1.1,0.1V219.8z"/>
<path className="st17" d="M160,219.8l-1.1,0.1h1.1V219.8z M158.9,219.8h1.1l-1.1,0.1V219.8z"/>
<path className="st16" d="M158.9,219.8l-0.4,0.1h0.4V219.8z M158.5,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st17" d="M158.9,219.8l-0.4,0.1h0.4V219.8z M158.5,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st16" d="M158.5,219.8l-1.7,0.1h1.7V219.8z M157.1,219.8h1.4l-1.7,0.1L157.1,219.8z"/>
<path className="st17" d="M158.5,219.8l-1.7,0.1h1.7V219.8z M157.1,219.8h1.4l-1.7,0.1L157.1,219.8z"/>
<path className="st16" d="M156.7,219.8l-1.4,0.1h1.6L156.7,219.8z M155.3,219.8h1.4l-1.4,0.1V219.8z"/>
<path className="st17" d="M156.7,219.8l-1.4,0.1h1.6L156.7,219.8z M155.3,219.8h1.4l-1.4,0.1V219.8z"/>
<path className="st16" d="M155.3,219.8l-0.4,0.1h0.4V219.8z M154.9,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st17" d="M155.3,219.8l-0.4,0.1h0.4V219.8z M154.9,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st16" d="M160.1,219.8h-1.2h1.1H160.1z M160.1,219.8L160.1,219.8h-1.2H160.1z"/>
<path className="st17" d="M160.1,219.8h-1.2h1.1H160.1z M160.1,219.8L160.1,219.8h-1.2H160.1z"/>
<path className="st16" d="M158.9,219.8L158.9,219.8h-0.4H158.9z M158.5,219.8h0.4H158.5z"/>
<path className="st17" d="M158.9,219.8L158.9,219.8h-0.4H158.9z M158.5,219.8h0.4H158.5z"/>
<path className="st16" d="M160.1,219.6l-1.2,0.1h1.2V219.6z M158.9,219.6h1.2l-1.2,0.1V219.6z"/>
<path className="st17" d="M160.1,219.6l-1.2,0.1h1.2V219.6z M158.9,219.6h1.2l-1.2,0.1V219.6z"/>
<path className="st16" d="M158.9,219.6l-0.4,0.1h0.4V219.6z M158.5,219.6h0.4l-0.4,0.1V219.6z"/>
<path className="st17" d="M158.9,219.6l-0.4,0.1h0.4V219.6z M158.5,219.6h0.4l-0.4,0.1V219.6z"/>
<path className="st16" d="M158.5,219.6l-1.4,0.1h1.4V219.6z M157.1,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st17" d="M158.5,219.6l-1.4,0.1h1.4V219.6z M157.1,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st16" d="M156.7,219.6l-1.4,0.1h1.4V219.6z M155.3,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st17" d="M156.7,219.6l-1.4,0.1h1.4V219.6z M155.3,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st16" d="M158.5,219.5l-1.4,0.1h1.4V219.5z M157.1,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st17" d="M158.5,219.5l-1.4,0.1h1.4V219.5z M157.1,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st16" d="M156.7,219.5l-1.4,0.1h1.4V219.5z M155.3,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st17" d="M156.7,219.5l-1.4,0.1h1.4V219.5z M155.3,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st16" d="M158.5,219.4l-1.4,0.1h1.4V219.4z M156.8,219.4h1.7l-1.4,0.1L156.8,219.4z"/>
<path className="st17" d="M158.5,219.4l-1.4,0.1h1.4V219.4z M156.8,219.4h1.7l-1.4,0.1L156.8,219.4z"/>
<path className="st16" d="M156.8,219.4l-1.6,0.1h1.4L156.8,219.4z M155.3,219.4h1.6l-1.6,0.1V219.4z"/>
<path className="st17" d="M156.8,219.4l-1.6,0.1h1.4L156.8,219.4z M155.3,219.4h1.6l-1.6,0.1V219.4z"/>
<path className="st16" d="M160.1,219.3l-1.2,0.4h1.2V219.3z M158.9,219.3h1.2l-1.2,0.4V219.3z"/>
<path className="st17" d="M160.1,219.3l-1.2,0.4h1.2V219.3z M158.9,219.3h1.2l-1.2,0.4V219.3z"/>
<path className="st16" d="M158.9,219.3l-0.4,0.4h0.4V219.3z M158.5,219.3h0.4l-0.4,0.4V219.3z"/>
<path className="st17" d="M158.9,219.3l-0.4,0.4h0.4V219.3z M158.5,219.3h0.4l-0.4,0.4V219.3z"/>
<path className="st16" d="M158.5,219.3l-1.7,0.1h1.7V219.3z M156.8,219.3h1.7l-1.7,0.1V219.3z"/>
<path className="st17" d="M158.5,219.3l-1.7,0.1h1.7V219.3z M156.8,219.3h1.7l-1.7,0.1V219.3z"/>
<path className="st16" d="M156.8,219.3l-1.6,0.1h1.6V219.3z M155.3,219.3h1.6l-1.6,0.1V219.3z"/>
<path className="st17" d="M156.8,219.3l-1.6,0.1h1.6V219.3z M155.3,219.3h1.6l-1.6,0.1V219.3z"/>
<path className="st16" d="M160.2,219.2l-1.3,0.1h1.2L160.2,219.2z M158.9,219.2h1.3l-1.3,0.1V219.2z"/>
<path className="st17" d="M160.2,219.2l-1.3,0.1h1.2L160.2,219.2z M158.9,219.2h1.3l-1.3,0.1V219.2z"/>
<path className="st16" d="M158.9,219.2l-0.4,0.1h0.4V219.2z M158.5,219.2h0.4l-0.4,0.1V219.2z"/>
<path className="st17" d="M158.9,219.2l-0.4,0.1h0.4V219.2z M158.5,219.2h0.4l-0.4,0.1V219.2z"/>
<path className="st16" d="M158.5,219.2l-1.4,0.1h1.4V219.2z M157.1,219.2h1.4l-1.4,0.1V219.2z"/>
<path className="st17" d="M158.5,219.2l-1.4,0.1h1.4V219.2z M157.1,219.2h1.4l-1.4,0.1V219.2z"/>
<path className="st16" d="M160.2,218.9l-1.8,0.2h1.8V218.9z M158.4,218.9h1.8l-1.8,0.2V218.9z"/>
<path className="st17" d="M160.2,218.9l-1.8,0.2h1.8V218.9z M158.4,218.9h1.8l-1.8,0.2V218.9z"/>
<path className="st16" d="M158.4,218.9l-1.3,0.2h1.3V218.9z M157.1,218.9h1.3l-1.3,0.2V218.9z"/>
<path className="st17" d="M158.4,218.9l-1.3,0.2h1.3V218.9z M157.1,218.9h1.3l-1.3,0.2V218.9z"/>
<path className="st16" d="M156.7,218.9l-1.4,0.4h1.4V218.9z M155.3,218.9h1.4l-1.4,0.4V218.9z"/>
<path className="st17" d="M156.7,218.9l-1.4,0.4h1.4V218.9z M155.3,218.9h1.4l-1.4,0.4V218.9z"/>
<path className="st16" d="M155.3,218.9l-0.4,0.8h0.4V218.9z M154.9,218.9h0.4l-0.4,0.8V218.9z"/>
<path className="st17" d="M155.3,218.9l-0.4,0.8h0.4V218.9z M154.9,218.9h0.4l-0.4,0.8V218.9z"/>
<path className="st16" d="M158.4,218.7l-1.3,0.2h1.3V218.7z M157.1,218.7h1.3l-1.3,0.2V218.7z M158.4,218.7h-1.3H158.4z M158.4,218.7
	L158.4,218.7h-1.3H158.4z M157.1,218.7h1.3H157.1z M158.4,218.7h-1.3H158.4z"/>
<path className="st17" d="M158.4,218.7l-1.3,0.2h1.3V218.7z M157.1,218.7h1.3l-1.3,0.2V218.7z M158.4,218.7h-1.3H158.4z M158.4,218.7
	L158.4,218.7h-1.3H158.4z M157.1,218.7h1.3H157.1z M158.4,218.7h-1.3H158.4z"/>
<path className="st16" d="M156.7,218.7l-0.7,0.2h0.7V218.7z M156,218.7h0.7l-0.7,0.2V218.7z"/>
<path className="st17" d="M156.7,218.7l-0.7,0.2h0.7V218.7z M156,218.7h0.7l-0.7,0.2V218.7z"/>
<path className="st16" d="M158.4,218.6l-1.3,0.1h1.3V218.6z M157.1,218.6h1.3l-1.3,0.1V218.6z"/>
<path className="st17" d="M158.4,218.6l-1.3,0.1h1.3V218.6z M157.1,218.6h1.3l-1.3,0.1V218.6z"/>
<path className="st16" d="M156.7,218.6l-0.7,0.1h0.7V218.6z M156,218.6h0.7l-0.7,0.1V218.6z"/>
<path className="st17" d="M156.7,218.6l-0.7,0.1h0.7V218.6z M156,218.6h0.7l-0.7,0.1V218.6z"/>
<path className="st16" d="M160.2,218.4l-1.8,0.5h1.8V218.4z M158.4,218.4h1.8l-1.8,0.5V218.4z"/>
<path className="st17" d="M160.2,218.4l-1.8,0.5h1.8V218.4z M158.4,218.4h1.8l-1.8,0.5V218.4z"/>
<path className="st16" d="M158.4,218.4l-1.3,0.1h1.3V218.4z M156.8,218.4h1.6l-1.3,0.1L156.8,218.4z"/>
<path className="st17" d="M158.4,218.4l-1.3,0.1h1.3V218.4z M156.8,218.4h1.6l-1.3,0.1L156.8,218.4z"/>
<path className="st16" d="M156.8,218.4l-0.8,0.1h0.7L156.8,218.4z M156,218.4h0.8l-0.8,0.1V218.4z"/>
<path className="st17" d="M156.8,218.4l-0.8,0.1h0.7L156.8,218.4z M156,218.4h0.8l-0.8,0.1V218.4z"/>
<path className="st16" d="M160.2,218.3l-1.8,0.1h1.8V218.3z M158.4,218.3h1.8l-1.8,0.1V218.3z"/>
<path className="st17" d="M160.2,218.3l-1.8,0.1h1.8V218.3z M158.4,218.3h1.8l-1.8,0.1V218.3z"/>
<path className="st16" d="M158.4,218.3l-1.6,0.1h1.6V218.3z M156.8,218.3h1.6l-1.6,0.1V218.3z"/>
<path className="st17" d="M158.4,218.3l-1.6,0.1h1.6V218.3z M156.8,218.3h1.6l-1.6,0.1V218.3z"/>
<path className="st16" d="M156.8,218.3l-0.8,0.1h0.8V218.3z M156,218.3h0.8l-0.8,0.1V218.3z"/>
<path className="st17" d="M156.8,218.3l-0.8,0.1h0.8V218.3z M156,218.3h0.8l-0.8,0.1V218.3z"/>
<path className="st16" d="M156,218.3l-0.5,0.6h0.5V218.3z M155.5,218.3h0.5l-0.5,0.6V218.3z"/>
<path className="st17" d="M156,218.3l-0.5,0.6h0.5V218.3z M155.5,218.3h0.5l-0.5,0.6V218.3z"/>
<path className="st16" d="M155.5,218.3l-0.1,0.6h0.1V218.3z M155.4,218.3h0.1l-0.1,0.6V218.3z"/>
<path className="st17" d="M155.5,218.3l-0.1,0.6h0.1V218.3z M155.4,218.3h0.1l-0.1,0.6V218.3z"/>
<path className="st16" d="M155.4,218.3l-0.5,0.6h0.5V218.3z M154.9,218.3h0.5l-0.5,0.6V218.3z"/>
<path className="st17" d="M155.4,218.3l-0.5,0.6h0.5V218.3z M154.9,218.3h0.5l-0.5,0.6V218.3z"/>
<path className="st16" d="M160.6,218.2l-3,0.1h3V218.2z M157.6,218.2h3l-3,0.1V218.2z"/>
<path className="st17" d="M160.6,218.2l-3,0.1h3V218.2z M157.6,218.2h3l-3,0.1V218.2z"/>
<path className="st16" d="M156.2,218.2l-1.7,0.1h1.7V218.2z M154.6,218.2h1.7l-1.7,0.1V218.2z"/>
<path className="st17" d="M156.2,218.2l-1.7,0.1h1.7V218.2z M154.6,218.2h1.7l-1.7,0.1V218.2z"/>
<path className="st16" d="M160.6,218.1l-3.7,0.1h3.7V218.1z M156.8,218.1h3.7l-3.7,0.1V218.1z"/>
<path className="st17" d="M160.6,218.1l-3.7,0.1h3.7V218.1z M156.8,218.1h3.7l-3.7,0.1V218.1z"/>
<path className="st16" d="M156.8,218.1l-2.3,0.1h2.3V218.1z M154.6,218.1h2.3l-2.3,0.1V218.1z"/>
<path className="st17" d="M156.8,218.1l-2.3,0.1h2.3V218.1z M154.6,218.1h2.3l-2.3,0.1V218.1z"/>
<line className="st19" x1="156.8" y1="220.4" x2="156.8" y2="216.9"/>
<line className="st19" x1="158.4" y1="219.2" x2="160.2" y2="219.2"/>
<line className="st19" x1="157.1" y1="218.9" x2="158.4" y2="218.9"/>
<line className="st19" x1="155.5" y1="218.9" x2="156" y2="218.9"/>
<polyline className="st19" points="154.9,218.3 154.9,218.9 155.4,218.9 155.4,218.3 "/>
<line className="st19" x1="155.5" y1="218.9" x2="155.5" y2="218.3"/>
<line className="st19" x1="156" y1="218.9" x2="156" y2="218.3"/>
<line className="st19" x1="158.4" y1="219.2" x2="158.4" y2="218.3"/>
<line className="st19" x1="160.2" y1="219.2" x2="160.2" y2="218.3"/>
<line className="st19" x1="156.7" y1="219.3" x2="157.1" y2="219.3"/>
<path className="st19" d="M156.8,218.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
	C157,218.5,156.9,218.4,156.8,218.4"/>
<line className="st19" x1="156.7" y1="219.3" x2="156.7" y2="218.7"/>
<line className="st19" x1="157.1" y1="219.3" x2="157.1" y2="218.6"/>
<polyline className="st19" points="156.2,218.3 156.2,218.2 157.6,218.2 157.6,218.3 "/>
<path className="st19" d="M156.8,219.4c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2S156.9,219.4,156.8,219.4"/>
<path className="st19" d="M157.9,221c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7"/>
<path className="st19" d="M154.8,219.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3"/>
<path className="st19" d="M155.2,219.8c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2"/>
<path className="st19" d="M157.9,220.6c0.2,0,0.3-0.1,0.5-0.2s0.2-0.3,0.2-0.5"/>
<line className="st19" x1="155.9" y1="220.7" x2="157.9" y2="220.7"/>
<line className="st19" x1="158.5" y1="220" x2="158.5" y2="219.2"/>
<polyline className="st19" points="155.3,219.9 155.3,218.9 156.7,218.9 "/>
<line className="st19" x1="155.9" y1="221" x2="157.9" y2="221"/>
<path className="st19" d="M158.8,220c0.3,0.2,0.7,0.1,1-0.1c0.3-0.2,0.4-0.6,0.4-0.9"/>
<line className="st19" x1="154.6" y1="218.3" x2="160.6" y2="218.3"/>
<polyline className="st19" points="154.6,218.3 154.6,218.1 160.6,218.1 160.6,218.3 "/>
<line className="st19" x1="158.9" y1="220" x2="158.9" y2="219.2"/>
<line className="st19" x1="154.9" y1="219.9" x2="154.9" y2="218.3"/>
<path className="st20" d="M157.1,218.7L157.1,218.7z"/>
<path className="st21" d="M157.1,218.7L157.1,218.7z"/>
<path className="st20" d="M157.1,218.7L157.1,218.7z"/>
<path className="st21" d="M157.1,218.7L157.1,218.7z"/>
<path className="st20" d="M157.1,218.7L157.1,218.7z"/>
<path className="st21" d="M157.1,218.7L157.1,218.7z"/>
<path className="st20" d="M157.1,218.6L157.1,218.6z"/>
<path className="st21" d="M157.1,218.6L157.1,218.6z"/>
<path className="st20" d="M157.1,218.7v-0.1V218.7z"/>
<path className="st21" d="M157.1,218.7v-0.1V218.7z"/>
<path className="st20" d="M157.1,218.7v-0.1V218.7z"/>
<path className="st21" d="M157.1,218.7v-0.1V218.7z"/>
<path className="st16" d="M95,220.8l-2.5,0.1h2L95,220.8z M92,220.8h3l-2.5,0.1L92,220.8z M95,220.8h-3H95z M95,220.8L95,220.8h-3H95z"
	/>
<path className="st22" d="M95,220.8l-2.5,0.1h2L95,220.8z M92,220.8h3l-2.5,0.1L92,220.8z M95,220.8h-3H95z M95,220.8L95,220.8h-3H95z"
	/>
<path className="st16" d="M95.3,220.7l-3.2,0.1h3L95.3,220.7z M91.9,220.7h3.4l-3.2,0.1L91.9,220.7z"/>
<path className="st22" d="M95.3,220.7l-3.2,0.1h3L95.3,220.7z M91.9,220.7h3.4l-3.2,0.1L91.9,220.7z"/>
<path className="st16" d="M95.4,220.6l-0.8,0.1h0.7L95.4,220.6z M94.9,220.6h0.5l-0.8,0.1L94.9,220.6z"/>
<path className="st22" d="M95.4,220.6l-0.8,0.1h0.7L95.4,220.6z M94.9,220.6h0.5l-0.8,0.1L94.9,220.6z"/>
<path className="st16" d="M94.9,220.6l-2.4,0.1h2L94.9,220.6z M92.3,220.6h2.6l-2.4,0.1L92.3,220.6z"/>
<path className="st22" d="M94.9,220.6l-2.4,0.1h2L94.9,220.6z M92.3,220.6h2.6l-2.4,0.1L92.3,220.6z"/>
<path className="st16" d="M95.4,220.5l-0.5,0.1h0.5V220.5z M94.9,220.5h0.5l-0.5,0.1V220.5z"/>
<path className="st22" d="M95.4,220.5l-0.5,0.1h0.5V220.5z M94.9,220.5h0.5l-0.5,0.1V220.5z"/>
<path className="st16" d="M94.9,220.5l-2.6,0.1h2.6V220.5z M92.2,220.5h2.8l-2.6,0.1L92.2,220.5z"/>
<path className="st22" d="M94.9,220.5l-2.6,0.1h2.6V220.5z M92.2,220.5h2.8l-2.6,0.1L92.2,220.5z"/>
<path className="st16" d="M95.4,220.5h-0.5H95.4z M95,220.5h0.4h-0.5H95z"/>
<path className="st22" d="M95.4,220.5h-0.5H95.4z M95,220.5h0.4h-0.5H95z"/>
<path className="st16" d="M95,220.5h-2.9h2.8H95z M95,220.5L95,220.5h-2.9H95z"/>
<path className="st22" d="M95,220.5h-2.9h2.8H95z M95,220.5L95,220.5h-2.9H95z"/>
<path className="st16" d="M95.4,220.5L95.4,220.5H95H95.4z M95,220.5h0.4H95z"/>
<path className="st22" d="M95.4,220.5L95.4,220.5H95H95.4z M95,220.5h0.4H95z"/>
<path className="st16" d="M95,220.5L95,220.5h-2.9H95z M92.2,220.5H95H92.2z"/>
<path className="st22" d="M95,220.5L95,220.5h-2.9H95z M92.2,220.5H95H92.2z"/>
<path className="st16" d="M95.4,220.5L95.4,220.5H95H95.4z M95,220.5h0.4H95z"/>
<path className="st22" d="M95.4,220.5L95.4,220.5H95H95.4z M95,220.5h0.4H95z"/>
<path className="st16" d="M95,220.5h-2.9H95z M92,220.5h3h-2.9H92z"/>
<path className="st22" d="M95,220.5h-2.9H95z M92,220.5h3h-2.9H92z"/>
<path className="st16" d="M92,220.5l-0.1,0.2h0.6L92,220.5z M91.7,220.5H92l-0.1,0.2L91.7,220.5z"/>
<path className="st22" d="M92,220.5l-0.1,0.2h0.6L92,220.5z M91.7,220.5H92l-0.1,0.2L91.7,220.5z"/>
<path className="st16" d="M95,220.5L95,220.5h-3H95z M92,220.5h3H92z"/>
<path className="st22" d="M95,220.5L95,220.5h-3H95z M92,220.5h3H92z"/>
<path className="st16" d="M92,220.5L92,220.5h-0.4H92z M91.7,220.5H92H91.7z"/>
<path className="st22" d="M92,220.5L92,220.5h-0.4H92z M91.7,220.5H92H91.7z"/>
<polygon className="st16" points="96.2,220.1 95.5,220 96.2,220 "/>
<polygon className="st22" points="96.2,220.1 95.5,220 96.2,220 "/>
<path className="st16" d="M95.2,220.1l-3.1,0.4h3L95.2,220.1z M91.9,220.1h3.2l-3.1,0.4L91.9,220.1z"/>
<path className="st22" d="M95.2,220.1l-3.1,0.4h3L95.2,220.1z M91.9,220.1h3.2l-3.1,0.4L91.9,220.1z"/>
<path className="st16" d="M91.9,220.1l-0.2,0.4H92L91.9,220.1z M91.6,220.1h0.4l-0.2,0.4L91.6,220.1z"/>
<path className="st22" d="M91.9,220.1l-0.2,0.4H92L91.9,220.1z M91.6,220.1h0.4l-0.2,0.4L91.6,220.1z"/>
<path className="st16" d="M96.4,220h-0.8h0.7H96.4z M96.4,220L96.4,220h-0.8H96.4z M95.5,220h0.8H95.5z M96.4,220h-0.8H96.4z"/>
<path className="st22" d="M96.4,220h-0.8h0.7H96.4z M96.4,220L96.4,220h-0.8H96.4z M95.5,220h0.8H95.5z M96.4,220h-0.8H96.4z"/>
<path className="st16" d="M95.5,220l-0.5,0.5h0.4L95.5,220z M95.2,220h0.4l-0.5,0.5L95.2,220z"/>
<path className="st22" d="M95.5,220l-0.5,0.5h0.4L95.5,220z M95.2,220h0.4l-0.5,0.5L95.2,220z"/>
<path className="st16" d="M96.4,220L96.4,220h-0.8H96.4z M95.5,220h0.8H95.5z"/>
<path className="st22" d="M96.4,220L96.4,220h-0.8H96.4z M95.5,220h0.8H95.5z"/>
<path className="st16" d="M95.5,220L95.5,220h-0.4H95.5z M95.2,220h0.4H95.2z"/>
<path className="st22" d="M95.5,220L95.5,220h-0.4H95.5z M95.2,220h0.4H95.2z"/>
<path className="st16" d="M95.2,220l-3.2,0.1h3.2V220z M91.9,220h3.2l-3.2,0.1V220z"/>
<path className="st22" d="M95.2,220l-3.2,0.1h3.2V220z M91.9,220h3.2l-3.2,0.1V220z"/>
<path className="st16" d="M96.5,219.9l-1,0.1h0.8L96.5,219.9z M95.5,219.9h1l-1,0.1V219.9z"/>
<path className="st22" d="M96.5,219.9l-1,0.1h0.8L96.5,219.9z M95.5,219.9h1l-1,0.1V219.9z"/>
<path className="st16" d="M95.5,219.9l-0.4,0.1h0.4V219.9z M95.2,219.9h0.4l-0.4,0.1V219.9z"/>
<path className="st22" d="M95.5,219.9l-0.4,0.1h0.4V219.9z M95.2,219.9h0.4l-0.4,0.1V219.9z"/>
<path className="st16" d="M95.2,219.9l-3.2,0.1h3.2V219.9z M91.9,219.9h3.2l-3.2,0.1V219.9z"/>
<path className="st22" d="M95.2,219.9l-3.2,0.1h3.2V219.9z M91.9,219.9h3.2l-3.2,0.1V219.9z"/>
<path className="st16" d="M91.9,219.9l-0.4,0.2h0.4V219.9z M91.6,219.9h0.4l-0.4,0.2V219.9z"/>
<path className="st22" d="M91.9,219.9l-0.4,0.2h0.4V219.9z M91.6,219.9h0.4l-0.4,0.2V219.9z"/>
<path className="st16" d="M96.5,219.9L96.5,219.9h-1H96.5z M95.5,219.9h1H95.5z"/>
<path className="st22" d="M96.5,219.9L96.5,219.9h-1H96.5z M95.5,219.9h1H95.5z"/>
<path className="st16" d="M95.5,219.9L95.5,219.9h-0.4H95.5z M95.2,219.9h0.4H95.2z"/>
<path className="st22" d="M95.5,219.9L95.5,219.9h-0.4H95.5z M95.2,219.9h0.4H95.2z"/>
<path className="st16" d="M95.2,219.9L95.2,219.9h-3.2H95.2z M91.9,219.9h3.2H91.9z"/>
<path className="st22" d="M95.2,219.9L95.2,219.9h-3.2H95.2z M91.9,219.9h3.2H91.9z"/>
<path className="st16" d="M91.9,219.9L91.9,219.9h-0.4H91.9z M91.6,219.9h0.4H91.6z"/>
<path className="st22" d="M91.9,219.9L91.9,219.9h-0.4H91.9z M91.6,219.9h0.4H91.6z"/>
<path className="st16" d="M96.6,219.9h-1.1h1H96.6z M96.6,219.9L96.6,219.9h-1.1H96.6z"/>
<path className="st22" d="M96.6,219.9h-1.1h1H96.6z M96.6,219.9L96.6,219.9h-1.1H96.6z"/>
<path className="st16" d="M95.5,219.9L95.5,219.9h-0.4H95.5z M95.2,219.9h0.4H95.2z"/>
<path className="st22" d="M95.5,219.9L95.5,219.9h-0.4H95.5z M95.2,219.9h0.4H95.2z"/>
<path className="st16" d="M95.2,219.9L95.2,219.9h-3.2H95.2z M91.9,219.9h3.2H91.9z"/>
<path className="st22" d="M95.2,219.9L95.2,219.9h-3.2H95.2z M91.9,219.9h3.2H91.9z"/>
<path className="st16" d="M91.9,219.9L91.9,219.9h-0.4H91.9z M91.6,219.9h0.4H91.6z"/>
<path className="st22" d="M91.9,219.9L91.9,219.9h-0.4H91.9z M91.6,219.9h0.4H91.6z"/>
<path className="st16" d="M96.6,219.8l-1.1,0.1h1.1V219.8z M95.5,219.8h1.1l-1.1,0.1V219.8z"/>
<path className="st22" d="M96.6,219.8l-1.1,0.1h1.1V219.8z M95.5,219.8h1.1l-1.1,0.1V219.8z"/>
<path className="st16" d="M95.5,219.8l-0.4,0.1h0.4V219.8z M95.2,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st22" d="M95.5,219.8l-0.4,0.1h0.4V219.8z M95.2,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st16" d="M95.2,219.8l-1.6,0.1h1.6V219.8z M93.7,219.8h1.4l-1.6,0.1L93.7,219.8z"/>
<path className="st22" d="M95.2,219.8l-1.6,0.1h1.6V219.8z M93.7,219.8h1.4l-1.6,0.1L93.7,219.8z"/>
<path className="st16" d="M93.4,219.8l-1.4,0.1h1.7L93.4,219.8z M91.9,219.8h1.4l-1.4,0.1V219.8z"/>
<path className="st22" d="M93.4,219.8l-1.4,0.1h1.7L93.4,219.8z M91.9,219.8h1.4l-1.4,0.1V219.8z"/>
<path className="st16" d="M91.9,219.8l-0.4,0.1h0.4V219.8z M91.6,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st22" d="M91.9,219.8l-0.4,0.1h0.4V219.8z M91.6,219.8h0.4l-0.4,0.1V219.8z"/>
<path className="st16" d="M96.7,219.8h-1.2h1.1H96.7z M96.7,219.8L96.7,219.8h-1.2H96.7z"/>
<path className="st22" d="M96.7,219.8h-1.2h1.1H96.7z M96.7,219.8L96.7,219.8h-1.2H96.7z"/>
<path className="st16" d="M95.5,219.8L95.5,219.8h-0.4H95.5z M95.2,219.8h0.4H95.2z"/>
<path className="st22" d="M95.5,219.8L95.5,219.8h-0.4H95.5z M95.2,219.8h0.4H95.2z"/>
<path className="st16" d="M96.7,219.6l-1.2,0.1h1.2V219.6z M95.5,219.6h1.2l-1.2,0.1V219.6z"/>
<path className="st22" d="M96.7,219.6l-1.2,0.1h1.2V219.6z M95.5,219.6h1.2l-1.2,0.1V219.6z"/>
<path className="st16" d="M95.5,219.6l-0.4,0.1h0.4V219.6z M95.2,219.6h0.4l-0.4,0.1V219.6z"/>
<path className="st22" d="M95.5,219.6l-0.4,0.1h0.4V219.6z M95.2,219.6h0.4l-0.4,0.1V219.6z"/>
<path className="st16" d="M95.2,219.6l-1.4,0.1h1.4V219.6z M93.7,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st22" d="M95.2,219.6l-1.4,0.1h1.4V219.6z M93.7,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st16" d="M93.4,219.6l-1.4,0.1h1.4V219.6z M91.9,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st22" d="M93.4,219.6l-1.4,0.1h1.4V219.6z M91.9,219.6h1.4l-1.4,0.1V219.6z"/>
<path className="st16" d="M95.2,219.5l-1.4,0.1h1.4V219.5z M93.7,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st22" d="M95.2,219.5l-1.4,0.1h1.4V219.5z M93.7,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st16" d="M93.4,219.5l-1.4,0.1h1.4V219.5z M91.9,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st22" d="M93.4,219.5l-1.4,0.1h1.4V219.5z M91.9,219.5h1.4l-1.4,0.1V219.5z"/>
<path className="st16" d="M95.2,219.4l-1.4,0.1h1.4V219.4z M93.6,219.4h1.6l-1.4,0.1L93.6,219.4z"/>
<path className="st22" d="M95.2,219.4l-1.4,0.1h1.4V219.4z M93.6,219.4h1.6l-1.4,0.1L93.6,219.4z"/>
<path className="st16" d="M93.6,219.4l-1.7,0.1h1.4L93.6,219.4z M91.9,219.4h1.7l-1.7,0.1V219.4z"/>
<path className="st22" d="M93.6,219.4l-1.7,0.1h1.4L93.6,219.4z M91.9,219.4h1.7l-1.7,0.1V219.4z"/>
<path className="st16" d="M96.8,219.3l-1.3,0.4h1.2L96.8,219.3z M95.5,219.3h1.3l-1.3,0.4V219.3z"/>
<path className="st22" d="M96.8,219.3l-1.3,0.4h1.2L96.8,219.3z M95.5,219.3h1.3l-1.3,0.4V219.3z"/>
<path className="st16" d="M95.5,219.3l-0.4,0.4h0.4V219.3z M95.2,219.3h0.4l-0.4,0.4V219.3z"/>
<path className="st22" d="M95.5,219.3l-0.4,0.4h0.4V219.3z M95.2,219.3h0.4l-0.4,0.4V219.3z"/>
<path className="st16" d="M95.2,219.3l-1.6,0.1h1.6V219.3z M93.6,219.3h1.6l-1.6,0.1V219.3z"/>
<path className="st22" d="M95.2,219.3l-1.6,0.1h1.6V219.3z M93.6,219.3h1.6l-1.6,0.1V219.3z"/>
<path className="st16" d="M93.6,219.3l-1.7,0.1h1.7V219.3z M91.9,219.3h1.7l-1.7,0.1V219.3z"/>
<path className="st22" d="M93.6,219.3l-1.7,0.1h1.7V219.3z M91.9,219.3h1.7l-1.7,0.1V219.3z"/>
<path className="st16" d="M96.8,219.2l-1.3,0.1h1.3V219.2z M95.5,219.2h1.3l-1.3,0.1V219.2z"/>
<path className="st22" d="M96.8,219.2l-1.3,0.1h1.3V219.2z M95.5,219.2h1.3l-1.3,0.1V219.2z"/>
<path className="st16" d="M95.5,219.2l-0.4,0.1h0.4V219.2z M95.2,219.2h0.4l-0.4,0.1V219.2z"/>
<path className="st22" d="M95.5,219.2l-0.4,0.1h0.4V219.2z M95.2,219.2h0.4l-0.4,0.1V219.2z"/>
<path className="st16" d="M95.2,219.2l-1.4,0.1h1.4V219.2z M93.7,219.2h1.4l-1.4,0.1V219.2z"/>
<path className="st22" d="M95.2,219.2l-1.4,0.1h1.4V219.2z M93.7,219.2h1.4l-1.4,0.1V219.2z"/>
<path className="st16" d="M96.8,218.9l-1.8,0.2h1.8V218.9z M95,218.9h1.8l-1.8,0.2V218.9z"/>
<path className="st22" d="M96.8,218.9l-1.8,0.2h1.8V218.9z M95,218.9h1.8l-1.8,0.2V218.9z"/>
<path className="st16" d="M95,218.9l-1.3,0.2H95V218.9z M93.7,218.9H95l-1.3,0.2V218.9z"/>
<path className="st22" d="M95,218.9l-1.3,0.2H95V218.9z M93.7,218.9H95l-1.3,0.2V218.9z"/>
<path className="st16" d="M93.4,218.9l-1.4,0.4h1.4V218.9z M91.9,218.9h1.4l-1.4,0.4V218.9z"/>
<path className="st22" d="M93.4,218.9l-1.4,0.4h1.4V218.9z M91.9,218.9h1.4l-1.4,0.4V218.9z"/>
<path className="st16" d="M91.9,218.9l-0.4,0.8h0.4V218.9z M91.6,218.9h0.4l-0.4,0.8V218.9z"/>
<path className="st22" d="M91.9,218.9l-0.4,0.8h0.4V218.9z M91.6,218.9h0.4l-0.4,0.8V218.9z"/>
<path className="st16" d="M95,218.7l-1.3,0.2H95V218.7z M93.7,218.7H95l-1.3,0.2V218.7z M95,218.7h-1.3H95z M95,218.7L95,218.7h-1.3H95z
	 M93.7,218.7H95H93.7z M95,218.7h-1.3H95z"/>
<path className="st22" d="M95,218.7l-1.3,0.2H95V218.7z M93.7,218.7H95l-1.3,0.2V218.7z M95,218.7h-1.3H95z M95,218.7L95,218.7h-1.3H95z
	 M93.7,218.7H95H93.7z M95,218.7h-1.3H95z"/>
<path className="st16" d="M93.4,218.7l-0.7,0.2h0.7V218.7z M92.6,218.7h0.7l-0.7,0.2V218.7z"/>
<path className="st22" d="M93.4,218.7l-0.7,0.2h0.7V218.7z M92.6,218.7h0.7l-0.7,0.2V218.7z"/>
<path className="st16" d="M95,218.6l-1.3,0.1H95V218.6z M93.7,218.6H95l-1.3,0.1V218.6z"/>
<path className="st22" d="M95,218.6l-1.3,0.1H95V218.6z M93.7,218.6H95l-1.3,0.1V218.6z"/>
<path className="st16" d="M93.4,218.6l-0.7,0.1h0.7V218.6z M92.6,218.6h0.7l-0.7,0.1V218.6z"/>
<path className="st22" d="M93.4,218.6l-0.7,0.1h0.7V218.6z M92.6,218.6h0.7l-0.7,0.1V218.6z"/>
<path className="st16" d="M96.8,218.4l-1.8,0.5h1.8V218.4z M95,218.4h1.8l-1.8,0.5V218.4z"/>
<path className="st22" d="M96.8,218.4l-1.8,0.5h1.8V218.4z M95,218.4h1.8l-1.8,0.5V218.4z"/>
<path className="st16" d="M95,218.4l-1.3,0.1H95V218.4z M93.6,218.4H95l-1.3,0.1L93.6,218.4z"/>
<path className="st22" d="M95,218.4l-1.3,0.1H95V218.4z M93.6,218.4H95l-1.3,0.1L93.6,218.4z"/>
<path className="st16" d="M93.6,218.4l-1,0.1h0.7L93.6,218.4z M92.6,218.4h1l-1,0.1V218.4z"/>
<path className="st22" d="M93.6,218.4l-1,0.1h0.7L93.6,218.4z M92.6,218.4h1l-1,0.1V218.4z"/>
<path className="st16" d="M96.8,218.3l-1.8,0.1h1.8V218.3z M95,218.3h1.8l-1.8,0.1V218.3z"/>
<path className="st22" d="M96.8,218.3l-1.8,0.1h1.8V218.3z M95,218.3h1.8l-1.8,0.1V218.3z"/>
<path className="st16" d="M95,218.3l-1.4,0.1H95V218.3z M93.6,218.3H95l-1.4,0.1V218.3z"/>
<path className="st22" d="M95,218.3l-1.4,0.1H95V218.3z M93.6,218.3H95l-1.4,0.1V218.3z"/>
<path className="st16" d="M93.6,218.3l-1,0.1h1V218.3z M92.6,218.3h1l-1,0.1V218.3z"/>
<path className="st22" d="M93.6,218.3l-1,0.1h1V218.3z M92.6,218.3h1l-1,0.1V218.3z"/>
<path className="st16" d="M92.6,218.3l-0.4,0.6h0.4V218.3z M92.3,218.3h0.4l-0.4,0.6V218.3z"/>
<path className="st22" d="M92.6,218.3l-0.4,0.6h0.4V218.3z M92.3,218.3h0.4l-0.4,0.6V218.3z"/>
<path className="st16" d="M92.3,218.3l-0.2,0.6h0.2V218.3z M92,218.3h0.2l-0.2,0.6V218.3z"/>
<path className="st22" d="M92.3,218.3l-0.2,0.6h0.2V218.3z M92,218.3h0.2l-0.2,0.6V218.3z"/>
<path className="st16" d="M92,218.3l-0.5,0.6H92V218.3z M91.6,218.3H92l-0.5,0.6V218.3z"/>
<path className="st22" d="M92,218.3l-0.5,0.6H92V218.3z M91.6,218.3H92l-0.5,0.6V218.3z"/>
<path className="st16" d="M97.2,218.2l-3,0.1h3V218.2z M94.2,218.2h3l-3,0.1V218.2z"/>
<path className="st22" d="M97.2,218.2l-3,0.1h3V218.2z M94.2,218.2h3l-3,0.1V218.2z"/>
<path className="st16" d="M92.9,218.2l-1.7,0.1h1.7V218.2z M91.2,218.2h1.7l-1.7,0.1V218.2z"/>
<path className="st22" d="M92.9,218.2l-1.7,0.1h1.7V218.2z M91.2,218.2h1.7l-1.7,0.1V218.2z"/>
<path className="st16" d="M97.2,218.1l-3.6,0.1h3.6V218.1z M93.6,218.1h3.6l-3.6,0.1V218.1z"/>
<path className="st22" d="M97.2,218.1l-3.6,0.1h3.6V218.1z M93.6,218.1h3.6l-3.6,0.1V218.1z"/>
<path className="st16" d="M93.6,218.1l-2.4,0.1h2.4V218.1z M91.2,218.1h2.4l-2.4,0.1V218.1z"/>
<path className="st22" d="M93.6,218.1l-2.4,0.1h2.4V218.1z M91.2,218.1h2.4l-2.4,0.1V218.1z"/>
<line className="st19" x1="93.6" y1="220.4" x2="93.6" y2="216.9"/>
<line className="st19" x1="95" y1="219.2" x2="96.8" y2="219.2"/>
<line className="st19" x1="93.7" y1="218.9" x2="95" y2="218.9"/>
<line className="st19" x1="92.3" y1="218.9" x2="92.6" y2="218.9"/>
<polyline className="st19" points="91.6,218.3 91.6,218.9 92,218.9 92,218.3 "/>
<line className="st19" x1="92.3" y1="218.9" x2="92.3" y2="218.3"/>
<line className="st19" x1="92.6" y1="218.9" x2="92.6" y2="218.3"/>
<line className="st19" x1="95" y1="219.2" x2="95" y2="218.3"/>
<line className="st19" x1="96.8" y1="219.2" x2="96.8" y2="218.3"/>
<line className="st19" x1="93.4" y1="219.3" x2="93.7" y2="219.3"/>
<path className="st19" d="M93.5,218.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2C93.7,218.5,93.6,218.4,93.5,218.4"
	/>
<line className="st19" x1="93.4" y1="219.3" x2="93.4" y2="218.7"/>
<line className="st19" x1="93.7" y1="219.3" x2="93.7" y2="218.6"/>
<polyline className="st19" points="92.9,218.3 92.9,218.2 94.2,218.2 94.2,218.3 "/>
<path className="st19" d="M93.5,219.4c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2S93.6,219.4,93.5,219.4"/>
<path className="st19" d="M94.5,221c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7"/>
<path className="st19" d="M91.4,219.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3"/>
<path className="st19" d="M91.8,219.8c0,0.2,0,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2"/>
<path className="st19" d="M94.5,220.6c0.2,0,0.3-0.1,0.5-0.2s0.2-0.3,0.2-0.5"/>
<line className="st19" x1="92.5" y1="220.7" x2="94.6" y2="220.7"/>
<line className="st19" x1="95.2" y1="220" x2="95.2" y2="219.2"/>
<polyline className="st19" points="91.9,219.9 91.9,218.9 93.4,218.9 "/>
<line className="st19" x1="92.5" y1="221" x2="94.6" y2="221"/>
<path className="st19" d="M95.5,220c0.3,0.2,0.7,0.1,1-0.1c0.3-0.2,0.4-0.6,0.4-0.9"/>
<line className="st19" x1="91.2" y1="218.3" x2="97.2" y2="218.3"/>
<polyline className="st19" points="91.2,218.3 91.2,218.1 97.2,218.1 97.2,218.3 "/>
<line className="st19" x1="95.5" y1="220" x2="95.5" y2="219.2"/>
<line className="st19" x1="91.6" y1="219.9" x2="91.6" y2="218.3"/>
<path className="st20" d="M93.7,218.7L93.7,218.7z"/>
<path className="st21" d="M93.7,218.7L93.7,218.7z"/>
<path className="st20" d="M93.7,218.7L93.7,218.7z"/>
<path className="st21" d="M93.7,218.7L93.7,218.7z"/>
<path className="st20" d="M93.7,218.7L93.7,218.7z"/>
<path className="st21" d="M93.7,218.7L93.7,218.7z"/>
<path className="st20" d="M93.7,218.6L93.7,218.6z"/>
<path className="st21" d="M93.7,218.6L93.7,218.6z"/>
<path className="st20" d="M93.7,218.7v-0.1V218.7z"/>
<path className="st21" d="M93.7,218.7v-0.1V218.7z"/>
<path className="st20" d="M93.7,218.7v-0.1V218.7z"/>
<path className="st21" d="M93.7,218.7v-0.1V218.7z"/>
<path className="st16" d="M178,228.9l-9.5-9.1l9,9L178,228.9z M168.4,219.3l9.6,9.6l-9.5-9.1L168.4,219.3z"/>
<path className="st22" d="M178,228.9l-9.5-9.1l9,9L178,228.9z M168.4,219.3l9.6,9.6l-9.5-9.1L168.4,219.3z"/>
<path className="st16" d="M178.4,228.8l-10.1-9.5l9.6,9.6L178.4,228.8z M168.5,218.9l10,9.8l-10.1-9.5L168.5,218.9z"/>
<path className="st22" d="M178.4,228.8l-10.1-9.5l9.6,9.6L178.4,228.8z M168.5,218.9l10,9.8l-10.1-9.5L168.5,218.9z"/>
<path className="st16" d="M178.4,228.8l-4.2-4.2L178.4,228.8z M174.7,224.9l3.7,3.8l-4.2-4.2L174.7,224.9z"/>
<path className="st22" d="M178.4,228.8l-4.2-4.2L178.4,228.8z M174.7,224.9l3.7,3.8l-4.2-4.2L174.7,224.9z"/>
<path className="st16" d="M169.1,219.4l-0.6-0.5l0.7,0.6L169.1,219.4z M168.5,218.8l0.6,0.6l-0.6-0.5V218.8z"/>
<path className="st22" d="M169.1,219.4l-0.6-0.5l0.7,0.6L169.1,219.4z M168.5,218.8l0.6,0.6l-0.6-0.5V218.8z"/>
<path className="st16" d="M169,219l-0.5-0.2l0.6,0.6L169,219z M168.6,218.7l0.4,0.4l-0.5-0.2L168.6,218.7z"/>
<path className="st22" d="M169,219l-0.5-0.2l0.6,0.6L169,219z M168.6,218.7l0.4,0.4l-0.5-0.2L168.6,218.7z"/>
<path className="st16" d="M178.6,228.6l-3.8-3.7l3.7,3.8L178.6,228.6z M176,226.1l2.5,2.5l-3.8-3.7L176,226.1z"/>
<path className="st22" d="M178.6,228.6l-3.8-3.7l3.7,3.8L178.6,228.6z M176,226.1l2.5,2.5l-3.8-3.7L176,226.1z"/>
<path className="st16" d="M178.8,228.4l-2.8-2.3l2.5,2.5L178.8,228.4z M176.5,226.2l2.3,2.2l-2.8-2.3L176.5,226.2z"/>
<path className="st22" d="M178.8,228.4l-2.8-2.3l2.5,2.5L178.8,228.4z M176.5,226.2l2.3,2.2l-2.8-2.3L176.5,226.2z"/>
<path className="st16" d="M169.2,218.7h-0.6l0.4,0.4L169.2,218.7z M169,218.4l0.2,0.2h-0.6L169,218.4z"/>
<path className="st22" d="M169.2,218.7h-0.6l0.4,0.4L169.2,218.7z M169,218.4l0.2,0.2h-0.6L169,218.4z"/>
<path className="st16" d="M179,228.2l-2.5-1.9l2.3,2.2L179,228.2z M177,226l2,2.2l-2.5-1.9L177,226z"/>
<path className="st22" d="M179,228.2l-2.5-1.9l2.3,2.2L179,228.2z M177,226l2,2.2l-2.5-1.9L177,226z"/>
<path className="st16" d="M179.3,227.9L177,226l2,2.2L179.3,227.9z M177.1,225.9l2.2,2L177,226L177.1,225.9z"/>
<path className="st22" d="M179.3,227.9L177,226l2,2.2L179.3,227.9z M177.1,225.9l2.2,2L177,226L177.1,225.9z"/>
<path className="st16" d="M179.3,227.9l-0.6-0.6L179.3,227.9z M178.8,227.3l0.5,0.6l-0.6-0.6H178.8z"/>
<path className="st22" d="M179.3,227.9l-0.6-0.6L179.3,227.9z M178.8,227.3l0.5,0.6l-0.6-0.6H178.8z"/>
<path className="st16" d="M178.6,227.2l-1.4-1.3l1.6,1.4L178.6,227.2z M177.2,225.8l1.3,1.4l-1.4-1.3L177.2,225.8z"/>
<path className="st22" d="M178.6,227.2l-1.4-1.3l1.6,1.4L178.6,227.2z M177.2,225.8l1.3,1.4l-1.4-1.3L177.2,225.8z"/>
<path className="st16" d="M179.4,227.8l-0.6-0.5l0.5,0.6L179.4,227.8z M178.9,227.3l0.5,0.5l-0.6-0.5H178.9z"/>
<path className="st22" d="M179.4,227.8l-0.6-0.5l0.5,0.6L179.4,227.8z M178.9,227.3l0.5,0.5l-0.6-0.5H178.9z"/>
<path className="st16" d="M178.7,227.1l-1.4-1.3l1.3,1.4L178.7,227.1z M177.4,225.6l1.3,1.4l-1.4-1.3L177.4,225.6z"/>
<path className="st22" d="M178.7,227.1l-1.4-1.3l1.3,1.4L178.7,227.1z M177.4,225.6l1.3,1.4l-1.4-1.3L177.4,225.6z"/>
<path className="st16" d="M179.5,227.7l-0.6-0.4l0.5,0.5L179.5,227.7z M179,227.2l0.5,0.5l-0.6-0.4L179,227.2z"/>
<path className="st22" d="M179.5,227.7l-0.6-0.4l0.5,0.5L179.5,227.7z M179,227.2l0.5,0.5l-0.6-0.4L179,227.2z"/>
<path className="st16" d="M178.8,227l-1.4-1.3l1.3,1.4L178.8,227z M178.8,227L178.8,227l-1.4-1.3L178.8,227z"/>
<path className="st22" d="M178.8,227l-1.4-1.3l1.3,1.4L178.8,227z M178.8,227L178.8,227l-1.4-1.3L178.8,227z"/>
<path className="st16" d="M179.5,227.7l-0.5-0.5L179.5,227.7z M178.9,227.1l0.6,0.6l-0.5-0.5L178.9,227.1z"/>
<path className="st22" d="M179.5,227.7l-0.5-0.5L179.5,227.7z M178.9,227.1l0.6,0.6l-0.5-0.5L178.9,227.1z"/>
<path className="st16" d="M178.9,227.1l-1.6-1.4l1.4,1.3L178.9,227.1z M177.5,225.5l1.4,1.6l-1.6-1.4L177.5,225.5z"/>
<path className="st22" d="M178.9,227.1l-1.6-1.4l1.4,1.3L178.9,227.1z M177.5,225.5l1.4,1.6l-1.6-1.4L177.5,225.5z"/>
<path className="st16" d="M179.8,227.4l-2.3-1.9l2,2.2L179.8,227.4z M177.7,225.3l2,2.2l-2.3-1.9L177.7,225.3z"/>
<path className="st22" d="M179.8,227.4l-2.3-1.9l2,2.2L179.8,227.4z M177.7,225.3l2,2.2l-2.3-1.9L177.7,225.3z"/>
<polygon className="st16" points="173,220.7 173,220.6 173.2,220.7 "/>
<polygon className="st22" points="173,220.7 173,220.6 173.2,220.7 "/>
<path className="st16" d="M179.9,227.3l-0.7-0.5l0.6,0.6L179.9,227.3z M179.3,226.8l0.6,0.5l-0.7-0.5H179.3z"/>
<path className="st22" d="M179.9,227.3l-0.7-0.5l0.6,0.6L179.9,227.3z M179.3,226.8l0.6,0.5l-0.7-0.5H179.3z"/>
<path className="st16" d="M179.2,226.7l-1.4-1.4l1.4,1.6V226.7z M179.2,226.7L179.2,226.7l-1.4-1.4L179.2,226.7z"/>
<path className="st22" d="M179.2,226.7l-1.4-1.4l1.4,1.6V226.7z M179.2,226.7L179.2,226.7l-1.4-1.4L179.2,226.7z"/>
<path className="st16" d="M173.2,220.7L173.2,220.7l-0.1-0.1L173.2,220.7z M173,220.6l0.1,0.1L173,220.6z"/>
<path className="st22" d="M173.2,220.7L173.2,220.7l-0.1-0.1L173.2,220.7z M173,220.6l0.1,0.1L173,220.6z"/>
<path className="st16" d="M179.9,227.3l-0.6-0.5L179.9,227.3z M179.4,226.8l0.5,0.5l-0.6-0.5H179.4z"/>
<path className="st22" d="M179.9,227.3l-0.6-0.5L179.9,227.3z M179.4,226.8l0.5,0.5l-0.6-0.5H179.4z"/>
<path className="st16" d="M179.2,226.5l-1.4-1.2l1.4,1.4V226.5z M177.8,225.2l1.3,1.3l-1.4-1.2L177.8,225.2z"/>
<path className="st22" d="M179.2,226.5l-1.4-1.2l1.4,1.4V226.5z M177.8,225.2l1.3,1.3l-1.4-1.2L177.8,225.2z"/>
<path className="st16" d="M173.3,220.7l-0.2-0.1l0.1,0.1H173.3z M173,220.5l0.2,0.2l-0.2-0.1V220.5z"/>
<path className="st22" d="M173.3,220.7l-0.2-0.1l0.1,0.1H173.3z M173,220.5l0.2,0.2l-0.2-0.1V220.5z"/>
<path className="st16" d="M173.4,220.6l-0.4-0.1l0.2,0.2L173.4,220.6z M173.2,220.4l0.2,0.2l-0.4-0.1L173.2,220.4z"/>
<path className="st22" d="M173.4,220.6l-0.4-0.1l0.2,0.2L173.4,220.6z M173.2,220.4l0.2,0.2l-0.4-0.1L173.2,220.4z"/>
<polygon className="st16" points="173.3,220.4 173.4,220.5 173.3,220.5 "/>
<polygon className="st22" points="173.3,220.4 173.4,220.5 173.3,220.5 "/>
<path className="st16" d="M180,227.2l-0.6-0.4l0.5,0.5L180,227.2z M179.5,226.7l0.5,0.5l-0.6-0.4L179.5,226.7z"/>
<path className="st22" d="M180,227.2l-0.6-0.4l0.5,0.5L180,227.2z M179.5,226.7l0.5,0.5l-0.6-0.4L179.5,226.7z"/>
<path className="st16" d="M179.3,226.5l-1.4-1.3l1.3,1.3H179.3z M178,225l1.3,1.4l-1.4-1.3L178,225z"/>
<path className="st22" d="M179.3,226.5l-1.4-1.3l1.3,1.3H179.3z M178,225l1.3,1.4l-1.4-1.3L178,225z"/>
<path className="st16" d="M173.4,220.5l-0.2-0.1l0.2,0.2V220.5z M173.3,220.4l0.1,0.1l-0.2-0.1H173.3z"/>
<path className="st22" d="M173.4,220.5l-0.2-0.1l0.2,0.2V220.5z M173.3,220.4l0.1,0.1l-0.2-0.1H173.3z"/>
<path className="st16" d="M180,227.2l-0.5-0.5L180,227.2z M179.5,226.6l0.5,0.6l-0.5-0.5V226.6z"/>
<path className="st22" d="M180,227.2l-0.5-0.5L180,227.2z M179.5,226.6l0.5,0.6l-0.5-0.5V226.6z"/>
<path className="st16" d="M179.4,226.5L178,225l1.3,1.4H179.4z M179.4,226.5L179.4,226.5L178,225L179.4,226.5z"/>
<path className="st22" d="M179.4,226.5L178,225l1.3,1.4H179.4z M179.4,226.5L179.4,226.5L178,225L179.4,226.5z"/>
<path className="st16" d="M180.1,227.1l-0.6-0.5l0.5,0.6L180.1,227.1z M179.4,226.5l0.7,0.6l-0.6-0.5L179.4,226.5z"/>
<path className="st22" d="M180.1,227.1l-0.6-0.5l0.5,0.6L180.1,227.1z M179.4,226.5l0.7,0.6l-0.6-0.5L179.4,226.5z"/>
<path className="st16" d="M179.4,226.5L179.4,226.5L178,225L179.4,226.5z M178,225l1.4,1.4L178,225z"/>
<path className="st22" d="M179.4,226.5L179.4,226.5L178,225L179.4,226.5z M178,225l1.4,1.4L178,225z"/>
<path className="st16" d="M180.2,226.8L178,225l2.2,2L180.2,226.8z M178.2,224.8l2,2L178,225L178.2,224.8z"/>
<path className="st22" d="M180.2,226.8L178,225l2.2,2L180.2,226.8z M178.2,224.8l2,2L178,225L178.2,224.8z"/>
<path className="st16" d="M180.4,226.8l-0.7-0.6l0.6,0.6H180.4z M179.9,226.4l0.5,0.5l-0.7-0.6L179.9,226.4z"/>
<path className="st22" d="M180.4,226.8l-0.7-0.6l0.6,0.6H180.4z M179.9,226.4l0.5,0.5l-0.7-0.6L179.9,226.4z"/>
<path className="st16" d="M179.6,226.1l-1.4-1.3l1.4,1.4V226.1z M179.6,226.1L179.6,226.1l-1.4-1.3L179.6,226.1z"/>
<path className="st22" d="M179.6,226.1l-1.4-1.3l1.4,1.4V226.1z M179.6,226.1L179.6,226.1l-1.4-1.3L179.6,226.1z"/>
<path className="st16" d="M180.5,226.7l-0.6-0.4l0.5,0.5L180.5,226.7z M180,226.2l0.5,0.5l-0.6-0.4L180,226.2z"/>
<path className="st22" d="M180.5,226.7l-0.6-0.4l0.5,0.5L180.5,226.7z M180,226.2l0.5,0.5l-0.6-0.4L180,226.2z"/>
<path className="st16" d="M179.6,226l-1.4-1.2l1.4,1.3V226z M178.3,224.7l1.3,1.3l-1.4-1.2L178.3,224.7z"/>
<path className="st22" d="M179.6,226l-1.4-1.2l1.4,1.3V226z M178.3,224.7l1.3,1.3l-1.4-1.2L178.3,224.7z"/>
<path className="st16" d="M180.5,226.7l-0.5-0.5L180.5,226.7z M180,226.1l0.5,0.6l-0.5-0.5V226.1z"/>
<path className="st22" d="M180.5,226.7l-0.5-0.5L180.5,226.7z M180,226.1l0.5,0.6l-0.5-0.5V226.1z"/>
<path className="st16" d="M179.9,226l-1.6-1.3l1.3,1.3H179.9z M178.4,224.6l1.4,1.4l-1.6-1.3L178.4,224.6z"/>
<path className="st22" d="M179.9,226l-1.6-1.3l1.3,1.3H179.9z M178.4,224.6l1.4,1.4l-1.6-1.3L178.4,224.6z"/>
<path className="st16" d="M180.6,226.6l-0.6-0.5l0.5,0.6L180.6,226.6z M180,226l0.6,0.6l-0.6-0.5V226z"/>
<path className="st22" d="M180.6,226.6l-0.6-0.5l0.5,0.6L180.6,226.6z M180,226l0.6,0.6l-0.6-0.5V226z"/>
<path className="st16" d="M180,226l-1.6-1.4l1.4,1.4H180z M180,226L180,226l-1.6-1.4L180,226z"/>
<path className="st22" d="M180,226l-1.6-1.4l1.4,1.4H180z M180,226L180,226l-1.6-1.4L180,226z"/>
<path className="st16" d="M180.8,226.4l-2.4-1.8l2.2,2L180.8,226.4z M178.7,224.3l2.2,2l-2.4-1.8L178.7,224.3z"/>
<path className="st22" d="M180.8,226.4l-2.4-1.8l2.2,2L180.8,226.4z M178.7,224.3l2.2,2l-2.4-1.8L178.7,224.3z"/>
<path className="st16" d="M171.2,216.5l-2.3,1.9l0.2,0.2L171.2,216.5z M171,216.3l0.2,0.2l-2.3,1.9L171,216.3z"/>
<path className="st22" d="M171.2,216.5l-2.3,1.9l0.2,0.2L171.2,216.5z M171,216.3l0.2,0.2l-2.3,1.9L171,216.3z"/>
<path className="st16" d="M181.1,226.1l-2.4-1.8l2.2,2L181.1,226.1z M178.9,223.8l2.2,2.3l-2.4-1.8L178.9,223.8z"/>
<path className="st22" d="M181.1,226.1l-2.4-1.8l2.2,2L181.1,226.1z M178.9,223.8l2.2,2.3l-2.4-1.8L178.9,223.8z"/>
<path className="st16" d="M181.2,226l-2.3-2.2l2.2,2.3L181.2,226z M178.7,223.5l2.5,2.5l-2.3-2.2L178.7,223.5z"/>
<path className="st22" d="M181.2,226l-2.3-2.2l2.2,2.3L181.2,226z M178.7,223.5l2.5,2.5l-2.3-2.2L178.7,223.5z"/>
<path className="st16" d="M171.7,216.3H171l0.2,0.2L171.7,216.3z M171.4,215.9l0.4,0.4H171L171.4,215.9z"/>
<path className="st22" d="M171.7,216.3H171l0.2,0.2L171.7,216.3z M171.4,215.9l0.4,0.4H171L171.4,215.9z"/>
<path className="st16" d="M181.4,225.8l-2.8-2.3l2.5,2.5L181.4,225.8z M177.6,222l3.8,3.7l-2.8-2.3L177.6,222z"/>
<path className="st22" d="M181.4,225.8l-2.8-2.3l2.5,2.5L181.4,225.8z M177.6,222l3.8,3.7l-2.8-2.3L177.6,222z"/>
<path className="st16" d="M172.1,216.4l-0.7-0.5l0.4,0.4L172.1,216.4z M171.5,215.9l0.6,0.5l-0.7-0.5H171.5z"/>
<path className="st22" d="M172.1,216.4l-0.7-0.5l0.4,0.4L172.1,216.4z M171.5,215.9l0.6,0.5l-0.7-0.5H171.5z"/>
<path className="st16" d="M181.4,225.8l-3.8-3.7L181.4,225.8z M177.2,221.6l4.2,4.2l-3.8-3.7L177.2,221.6z"/>
<path className="st22" d="M181.4,225.8l-3.8-3.7L181.4,225.8z M177.2,221.6l4.2,4.2l-3.8-3.7L177.2,221.6z"/>
<path className="st16" d="M172.2,216.5l-0.7-0.6l0.6,0.5L172.2,216.5z M171.5,215.8l0.7,0.7l-0.7-0.6V215.8z"/>
<path className="st22" d="M172.2,216.5l-0.7-0.6l0.6,0.5L172.2,216.5z M171.5,215.8l0.7,0.7l-0.7-0.6V215.8z"/>
<path className="st16" d="M181.6,225.3l-10.1-9.5l10,10L181.6,225.3z M172,215.7l9.6,9.6l-10.1-9.5L172,215.7z"/>
<path className="st22" d="M181.6,225.3l-10.1-9.5l10,10L181.6,225.3z M172,215.7l9.6,9.6l-10.1-9.5L172,215.7z"/>
<path className="st16" d="M181.4,224.9l-9.5-9.2l9.6,9.6L181.4,224.9z M172.4,215.9l9,9l-9.5-9.2L172.4,215.9z"/>
<path className="st22" d="M181.4,224.9l-9.5-9.2l9.6,9.6L181.4,224.9z M172.4,215.9l9,9l-9.5-9.2L172.4,215.9z"/>
<line className="st18" x1="177.5" y1="228.8" x2="168.5" y2="219.8"/>
<line className="st18" x1="168.5" y1="218.8" x2="171.5" y2="215.9"/>
<line className="st18" x1="172.4" y1="215.9" x2="181.4" y2="224.9"/>
<line className="st18" x1="181.4" y1="225.8" x2="178.4" y2="228.8"/>
<line className="st18" x1="178.7" y1="224.3" x2="177" y2="226"/>
<line className="st18" x1="169.2" y1="218.7" x2="171.2" y2="216.5"/>
<line className="st18" x1="172.2" y1="216.5" x2="177.2" y2="221.6"/>
<polyline className="st18" points="176,226.1 174.2,224.6 169.2,219.5 "/>
<line className="st18" x1="177.2" y1="221.6" x2="178.7" y2="223.5"/>
<path className="st18" d="M172.1,216.5c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M169.2,218.5c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M176,226c0.3,0.2,0.6,0.2,0.9,0"/>
<path className="st18" d="M178.6,224.2c0.2-0.2,0.3-0.6,0-0.9"/>
<path className="st18" d="M181.4,225.7c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M177.4,228.7c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M168.4,218.7c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M172.4,215.7c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M179.9,225.9c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C180,226.1,180,226,179.9,225.9"/>
<path className="st18" d="M178.9,227c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C178.9,227.2,178.9,227.1,178.9,227"/>
<path className="st18" d="M179.3,226.5c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C179.4,226.7,179.4,226.6,179.3,226.5"/>
<path className="st18" d="M173.2,220.4c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C173.3,220.6,173.3,220.5,173.2,220.4"/>
<line className="st23" x1="175.7" y1="221.9" x2="175.9" y2="222"/>
<line className="st23" x1="176" y1="222.2" x2="176.3" y2="222.4"/>
<line className="st23" x1="176.4" y1="222.5" x2="176.5" y2="222.8"/>
<polyline className="st23" points="176.6,222.9 176.9,223.1 176.6,223.2 "/>
<line className="st23" x1="176.5" y1="223.4" x2="176.4" y2="223.6"/>
<line className="st23" x1="176.3" y1="223.7" x2="176" y2="224"/>
<polyline className="st23" points="175.9,224.1 175.7,224.2 175.6,224.1 "/>
<line className="st23" x1="175.4" y1="224" x2="175.2" y2="223.7"/>
<line className="st23" x1="175.1" y1="223.6" x2="174.8" y2="223.4"/>
<polyline className="st23" points="174.7,223.2 174.6,223.1 174.7,222.9 "/>
<line className="st23" x1="174.8" y1="222.8" x2="175.1" y2="222.5"/>
<line className="st23" x1="175.2" y1="222.4" x2="175.4" y2="222.2"/>
<line className="st23" x1="175.6" y1="222" x2="175.7" y2="221.9"/>
<path className="st16" d="M73.8,228.9l9.5-9.1l-9,9L73.8,228.9z M83.4,219.3l-9.6,9.6l9.5-9.1L83.4,219.3z"/>
<path className="st17" d="M73.8,228.9l9.5-9.1l-9,9L73.8,228.9z M83.4,219.3l-9.6,9.6l9.5-9.1L83.4,219.3z"/>
<path className="st16" d="M73.3,228.8l10.1-9.5l-9.6,9.6L73.3,228.8z M83.3,218.9l-10,9.8l10.1-9.5L83.3,218.9z"/>
<path className="st17" d="M73.3,228.8l10.1-9.5l-9.6,9.6L73.3,228.8z M83.3,218.9l-10,9.8l10.1-9.5L83.3,218.9z"/>
<path className="st16" d="M73.3,228.8l4.2-4.2L73.3,228.8z M77,224.9l-3.7,3.8l4.2-4.2L77,224.9z"/>
<path className="st17" d="M73.3,228.8l4.2-4.2L73.3,228.8z M77,224.9l-3.7,3.8l4.2-4.2L77,224.9z"/>
<path className="st16" d="M82.7,219.4l0.6-0.5l-0.7,0.6L82.7,219.4z M83.3,218.8l-0.6,0.6l0.6-0.5V218.8z"/>
<path className="st17" d="M82.7,219.4l0.6-0.5l-0.7,0.6L82.7,219.4z M83.3,218.8l-0.6,0.6l0.6-0.5V218.8z"/>
<path className="st16" d="M82.8,219l0.5-0.2l-0.6,0.6L82.8,219z M83.2,218.7l-0.4,0.4l0.5-0.2L83.2,218.7z"/>
<path className="st17" d="M82.8,219l0.5-0.2l-0.6,0.6L82.8,219z M83.2,218.7l-0.4,0.4l0.5-0.2L83.2,218.7z"/>
<path className="st16" d="M73.2,228.6l3.8-3.7l-3.7,3.8L73.2,228.6z M75.7,226.1l-2.5,2.5l3.8-3.7L75.7,226.1z"/>
<path className="st17" d="M73.2,228.6l3.8-3.7l-3.7,3.8L73.2,228.6z M75.7,226.1l-2.5,2.5l3.8-3.7L75.7,226.1z"/>
<path className="st16" d="M73,228.4l2.8-2.3l-2.5,2.5L73,228.4z M75.2,226.2l-2.3,2.2l2.8-2.3L75.2,226.2z"/>
<path className="st17" d="M73,228.4l2.8-2.3l-2.5,2.5L73,228.4z M75.2,226.2l-2.3,2.2l2.8-2.3L75.2,226.2z"/>
<path className="st16" d="M82.6,218.7h0.6l-0.4,0.4L82.6,218.7z M82.8,218.4l-0.2,0.2h0.6L82.8,218.4z"/>
<path className="st17" d="M82.6,218.7h0.6l-0.4,0.4L82.6,218.7z M82.8,218.4l-0.2,0.2h0.6L82.8,218.4z"/>
<path className="st16" d="M72.7,228.2l2.5-1.9l-2.3,2.2L72.7,228.2z M74.8,226l-2,2.2l2.5-1.9L74.8,226z"/>
<path className="st17" d="M72.7,228.2l2.5-1.9l-2.3,2.2L72.7,228.2z M74.8,226l-2,2.2l2.5-1.9L74.8,226z"/>
<path className="st16" d="M72.5,227.9l2.3-1.9l-2,2.2L72.5,227.9z M74.6,225.9l-2.2,2l2.3-1.9L74.6,225.9z"/>
<path className="st17" d="M72.5,227.9l2.3-1.9l-2,2.2L72.5,227.9z M74.6,225.9l-2.2,2l2.3-1.9L74.6,225.9z"/>
<path className="st16" d="M72.5,227.9l0.6-0.6L72.5,227.9z M73,227.3l-0.5,0.6l0.6-0.6H73z"/>
<path className="st17" d="M72.5,227.9l0.6-0.6L72.5,227.9z M73,227.3l-0.5,0.6l0.6-0.6H73z"/>
<path className="st16" d="M73.2,227.2l1.4-1.3l-1.6,1.4L73.2,227.2z M74.5,225.8l-1.3,1.4l1.4-1.3L74.5,225.8z"/>
<path className="st17" d="M73.2,227.2l1.4-1.3l-1.6,1.4L73.2,227.2z M74.5,225.8l-1.3,1.4l1.4-1.3L74.5,225.8z"/>
<path className="st16" d="M72.4,227.8l0.6-0.5l-0.5,0.6L72.4,227.8z M72.8,227.3l-0.5,0.5l0.6-0.5H72.8z"/>
<path className="st17" d="M72.4,227.8l0.6-0.5l-0.5,0.6L72.4,227.8z M72.8,227.3l-0.5,0.5l0.6-0.5H72.8z"/>
<path className="st16" d="M73.1,227.1l1.4-1.3l-1.3,1.4L73.1,227.1z M74.4,225.6l-1.3,1.4l1.4-1.3L74.4,225.6z"/>
<path className="st17" d="M73.1,227.1l1.4-1.3l-1.3,1.4L73.1,227.1z M74.4,225.6l-1.3,1.4l1.4-1.3L74.4,225.6z"/>
<path className="st16" d="M72.2,227.7l0.6-0.4l-0.5,0.5L72.2,227.7z M72.7,227.2l-0.5,0.5l0.6-0.4L72.7,227.2z"/>
<path className="st17" d="M72.2,227.7l0.6-0.4l-0.5,0.5L72.2,227.7z M72.7,227.2l-0.5,0.5l0.6-0.4L72.7,227.2z"/>
<path className="st16" d="M73,227l1.4-1.3l-1.3,1.4L73,227z M73,227L73,227l1.4-1.3L73,227z"/>
<path className="st17" d="M73,227l1.4-1.3l-1.3,1.4L73,227z M73,227L73,227l1.4-1.3L73,227z"/>
<path className="st16" d="M72.2,227.7l0.5-0.5L72.2,227.7z M72.8,227.1l-0.6,0.6l0.5-0.5L72.8,227.1z"/>
<path className="st17" d="M72.2,227.7l0.5-0.5L72.2,227.7z M72.8,227.1l-0.6,0.6l0.5-0.5L72.8,227.1z"/>
<path className="st16" d="M72.8,227.1l1.6-1.4L73,227L72.8,227.1z M74.3,225.5l-1.4,1.6l1.6-1.4L74.3,225.5z"/>
<path className="st17" d="M72.8,227.1l1.6-1.4L73,227L72.8,227.1z M74.3,225.5l-1.4,1.6l1.6-1.4L74.3,225.5z"/>
<path className="st16" d="M72,227.4l2.3-1.9l-2,2.2L72,227.4z M74,225.3l-2,2.2l2.3-1.9L74,225.3z"/>
<path className="st17" d="M72,227.4l2.3-1.9l-2,2.2L72,227.4z M74,225.3l-2,2.2l2.3-1.9L74,225.3z"/>
<polygon className="st16" points="78.7,220.7 78.7,220.6 78.6,220.7 "/>
<polygon className="st17" points="78.7,220.7 78.7,220.6 78.6,220.7 "/>
<path className="st16" d="M71.9,227.3l0.7-0.5l-0.6,0.6L71.9,227.3z M72.5,226.8l-0.6,0.5l0.7-0.5H72.5z"/>
<path className="st17" d="M71.9,227.3l0.7-0.5l-0.6,0.6L71.9,227.3z M72.5,226.8l-0.6,0.5l0.7-0.5H72.5z"/>
<path className="st16" d="M72.6,226.7l1.4-1.4l-1.4,1.6V226.7z M72.6,226.7L72.6,226.7l1.4-1.4L72.6,226.7z"/>
<path className="st17" d="M72.6,226.7l1.4-1.4l-1.4,1.6V226.7z M72.6,226.7L72.6,226.7l1.4-1.4L72.6,226.7z"/>
<path className="st16" d="M78.6,220.7L78.6,220.7l0.1-0.1L78.6,220.7z M78.7,220.6l-0.1,0.1L78.7,220.6z"/>
<path className="st17" d="M78.6,220.7L78.6,220.7l0.1-0.1L78.6,220.7z M78.7,220.6l-0.1,0.1L78.7,220.6z"/>
<path className="st16" d="M71.9,227.3l0.6-0.5L71.9,227.3z M72.4,226.8l-0.5,0.5l0.6-0.5H72.4z"/>
<path className="st17" d="M71.9,227.3l0.6-0.5L71.9,227.3z M72.4,226.8l-0.5,0.5l0.6-0.5H72.4z"/>
<path className="st16" d="M72.6,226.5l1.4-1.2l-1.4,1.4V226.5z M73.9,225.2l-1.3,1.3l1.4-1.2L73.9,225.2z"/>
<path className="st17" d="M72.6,226.5l1.4-1.2l-1.4,1.4V226.5z M73.9,225.2l-1.3,1.3l1.4-1.2L73.9,225.2z"/>
<path className="st16" d="M78.5,220.7l0.2-0.1l-0.1,0.1H78.5z M78.7,220.5l-0.2,0.2l0.2-0.1V220.5z"/>
<path className="st17" d="M78.5,220.7l0.2-0.1l-0.1,0.1H78.5z M78.7,220.5l-0.2,0.2l0.2-0.1V220.5z"/>
<path className="st16" d="M78.4,220.6l0.4-0.1l-0.2,0.2L78.4,220.6z M78.6,220.4l-0.2,0.2l0.4-0.1L78.6,220.4z"/>
<path className="st17" d="M78.4,220.6l0.4-0.1l-0.2,0.2L78.4,220.6z M78.6,220.4l-0.2,0.2l0.4-0.1L78.6,220.4z"/>
<polygon className="st16" points="78.5,220.4 78.4,220.5 78.5,220.5 "/>
<polygon className="st17" points="78.5,220.4 78.4,220.5 78.5,220.5 "/>
<path className="st16" d="M71.8,227.2l0.6-0.4l-0.5,0.5L71.8,227.2z M72.2,226.7l-0.5,0.5l0.6-0.4L72.2,226.7z"/>
<path className="st17" d="M71.8,227.2l0.6-0.4l-0.5,0.5L71.8,227.2z M72.2,226.7l-0.5,0.5l0.6-0.4L72.2,226.7z"/>
<path className="st16" d="M72.5,226.5l1.4-1.3l-1.3,1.3H72.5z M73.8,225l-1.3,1.4l1.4-1.3L73.8,225z"/>
<path className="st17" d="M72.5,226.5l1.4-1.3l-1.3,1.3H72.5z M73.8,225l-1.3,1.4l1.4-1.3L73.8,225z"/>
<path className="st16" d="M78.4,220.5l0.2-0.1l-0.2,0.2V220.5z M78.5,220.4l-0.1,0.1l0.2-0.1H78.5z"/>
<path className="st17" d="M78.4,220.5l0.2-0.1l-0.2,0.2V220.5z M78.5,220.4l-0.1,0.1l0.2-0.1H78.5z"/>
<path className="st16" d="M71.8,227.2l0.5-0.5L71.8,227.2z M72.2,226.6l-0.5,0.6l0.5-0.5V226.6z"/>
<path className="st17" d="M71.8,227.2l0.5-0.5L71.8,227.2z M72.2,226.6l-0.5,0.6l0.5-0.5V226.6z"/>
<path className="st16" d="M72.4,226.5l1.4-1.4l-1.3,1.4H72.4z M72.4,226.5L72.4,226.5l1.4-1.4L72.4,226.5z"/>
<path className="st17" d="M72.4,226.5l1.4-1.4l-1.3,1.4H72.4z M72.4,226.5L72.4,226.5l1.4-1.4L72.4,226.5z"/>
<path className="st16" d="M71.6,227.1l0.6-0.5l-0.5,0.6L71.6,227.1z M72.4,226.5l-0.7,0.6l0.6-0.5L72.4,226.5z"/>
<path className="st17" d="M71.6,227.1l0.6-0.5l-0.5,0.6L71.6,227.1z M72.4,226.5l-0.7,0.6l0.6-0.5L72.4,226.5z"/>
<path className="st16" d="M72.4,226.5L72.4,226.5l1.4-1.4L72.4,226.5z M73.8,225l-1.4,1.4L73.8,225z"/>
<path className="st17" d="M72.4,226.5L72.4,226.5l1.4-1.4L72.4,226.5z M73.8,225l-1.4,1.4L73.8,225z"/>
<path className="st16" d="M71.5,226.8l2.3-1.8l-2.2,2L71.5,226.8z M73.6,224.8l-2,2l2.3-1.8L73.6,224.8z"/>
<path className="st17" d="M71.5,226.8l2.3-1.8l-2.2,2L71.5,226.8z M73.6,224.8l-2,2l2.3-1.8L73.6,224.8z"/>
<path className="st16" d="M71.4,226.8l0.7-0.6l-0.6,0.6H71.4z M71.9,226.4l-0.5,0.5l0.7-0.6L71.9,226.4z"/>
<path className="st17" d="M71.4,226.8l0.7-0.6l-0.6,0.6H71.4z M71.9,226.4l-0.5,0.5l0.7-0.6L71.9,226.4z"/>
<path className="st16" d="M72.1,226.1l1.4-1.3l-1.4,1.4V226.1z M72.1,226.1L72.1,226.1l1.4-1.3L72.1,226.1z"/>
<path className="st17" d="M72.1,226.1l1.4-1.3l-1.4,1.4V226.1z M72.1,226.1L72.1,226.1l1.4-1.3L72.1,226.1z"/>
<path className="st16" d="M71.3,226.7l0.6-0.4l-0.5,0.5L71.3,226.7z M71.8,226.2l-0.5,0.5l0.6-0.4L71.8,226.2z"/>
<path className="st17" d="M71.3,226.7l0.6-0.4l-0.5,0.5L71.3,226.7z M71.8,226.2l-0.5,0.5l0.6-0.4L71.8,226.2z"/>
<path className="st16" d="M72.1,226l1.4-1.2l-1.4,1.3V226z M73.4,224.7l-1.3,1.3l1.4-1.2L73.4,224.7z"/>
<path className="st17" d="M72.1,226l1.4-1.2l-1.4,1.3V226z M73.4,224.7l-1.3,1.3l1.4-1.2L73.4,224.7z"/>
<path className="st16" d="M71.3,226.7l0.5-0.5L71.3,226.7z M71.8,226.1l-0.5,0.6l0.5-0.5V226.1z"/>
<path className="st17" d="M71.3,226.7l0.5-0.5L71.3,226.7z M71.8,226.1l-0.5,0.6l0.5-0.5V226.1z"/>
<path className="st16" d="M71.9,226l1.6-1.3l-1.3,1.3H71.9z M73.3,224.6l-1.4,1.4l1.6-1.3L73.3,224.6z"/>
<path className="st17" d="M71.9,226l1.6-1.3l-1.3,1.3H71.9z M73.3,224.6l-1.4,1.4l1.6-1.3L73.3,224.6z"/>
<path className="st16" d="M71.2,226.6l0.6-0.5l-0.5,0.6L71.2,226.6z M71.8,226l-0.6,0.6l0.6-0.5V226z"/>
<path className="st17" d="M71.2,226.6l0.6-0.5l-0.5,0.6L71.2,226.6z M71.8,226l-0.6,0.6l0.6-0.5V226z"/>
<path className="st16" d="M71.8,226l1.6-1.4l-1.4,1.4H71.8z M71.8,226L71.8,226l1.6-1.4L71.8,226z"/>
<path className="st17" d="M71.8,226l1.6-1.4l-1.4,1.4H71.8z M71.8,226L71.8,226l1.6-1.4L71.8,226z"/>
<path className="st16" d="M70.9,226.4l2.4-1.8l-2.2,2L70.9,226.4z M73.1,224.3l-2.2,2l2.4-1.8L73.1,224.3z"/>
<path className="st17" d="M70.9,226.4l2.4-1.8l-2.2,2L70.9,226.4z M73.1,224.3l-2.2,2l2.4-1.8L73.1,224.3z"/>
<path className="st16" d="M80.5,216.5l2.3,1.9l-0.2,0.2L80.5,216.5z M80.8,216.3l-0.2,0.2l2.3,1.9L80.8,216.3z"/>
<path className="st17" d="M80.5,216.5l2.3,1.9l-0.2,0.2L80.5,216.5z M80.8,216.3l-0.2,0.2l2.3,1.9L80.8,216.3z"/>
<path className="st16" d="M70.7,226.1l2.4-1.8l-2.2,2L70.7,226.1z M72.8,223.8l-2.2,2.3l2.4-1.8L72.8,223.8z"/>
<path className="st17" d="M70.7,226.1l2.4-1.8l-2.2,2L70.7,226.1z M72.8,223.8l-2.2,2.3l2.4-1.8L72.8,223.8z"/>
<path className="st16" d="M70.6,226l2.3-2.2l-2.2,2.3L70.6,226z M73.1,223.5l-2.5,2.5l2.3-2.2L73.1,223.5z"/>
<path className="st17" d="M70.6,226l2.3-2.2l-2.2,2.3L70.6,226z M73.1,223.5l-2.5,2.5l2.3-2.2L73.1,223.5z"/>
<path className="st16" d="M80,216.3h0.7l-0.2,0.2L80,216.3z M80.4,215.9l-0.4,0.4h0.7L80.4,215.9z"/>
<path className="st17" d="M80,216.3h0.7l-0.2,0.2L80,216.3z M80.4,215.9l-0.4,0.4h0.7L80.4,215.9z"/>
<path className="st16" d="M70.3,225.8l2.8-2.3l-2.5,2.5L70.3,225.8z M74.2,222l-3.8,3.7l2.8-2.3L74.2,222z"/>
<path className="st17" d="M70.3,225.8l2.8-2.3l-2.5,2.5L70.3,225.8z M74.2,222l-3.8,3.7l2.8-2.3L74.2,222z"/>
<path className="st16" d="M79.7,216.4l0.7-0.5l-0.4,0.4L79.7,216.4z M80.3,215.9l-0.6,0.5l0.7-0.5H80.3z"/>
<path className="st17" d="M79.7,216.4l0.7-0.5l-0.4,0.4L79.7,216.4z M80.3,215.9l-0.6,0.5l0.7-0.5H80.3z"/>
<path className="st16" d="M70.3,225.8l3.8-3.7L70.3,225.8z M74.5,221.6l-4.2,4.2l3.8-3.7L74.5,221.6z"/>
<path className="st17" d="M70.3,225.8l3.8-3.7L70.3,225.8z M74.5,221.6l-4.2,4.2l3.8-3.7L74.5,221.6z"/>
<path className="st16" d="M79.6,216.5l0.7-0.6l-0.6,0.5L79.6,216.5z M80.3,215.8l-0.7,0.7l0.7-0.6V215.8z"/>
<path className="st17" d="M79.6,216.5l0.7-0.6l-0.6,0.5L79.6,216.5z M80.3,215.8l-0.7,0.7l0.7-0.6V215.8z"/>
<path className="st16" d="M70.2,225.3l10.1-9.5l-10,10L70.2,225.3z M79.8,215.7l-9.6,9.6l10.1-9.5L79.8,215.7z"/>
<path className="st17" d="M70.2,225.3l10.1-9.5l-10,10L70.2,225.3z M79.8,215.7l-9.6,9.6l10.1-9.5L79.8,215.7z"/>
<path className="st16" d="M70.3,224.9l9.5-9.2l-9.6,9.6L70.3,224.9z M79.3,215.9l-9,9l9.5-9.2L79.3,215.9z"/>
<path className="st17" d="M70.3,224.9l9.5-9.2l-9.6,9.6L70.3,224.9z M79.3,215.9l-9,9l9.5-9.2L79.3,215.9z"/>
<line className="st18" x1="74.3" y1="228.8" x2="83.3" y2="219.8"/>
<line className="st18" x1="83.3" y1="218.8" x2="80.3" y2="215.9"/>
<line className="st18" x1="79.3" y1="215.9" x2="70.3" y2="224.9"/>
<line className="st18" x1="70.3" y1="225.8" x2="73.3" y2="228.8"/>
<line className="st18" x1="73.1" y1="224.3" x2="74.8" y2="226"/>
<line className="st18" x1="82.6" y1="218.7" x2="80.5" y2="216.5"/>
<line className="st18" x1="79.6" y1="216.5" x2="74.5" y2="221.6"/>
<polyline className="st18" points="75.7,226.1 77.5,224.6 82.6,219.5 "/>
<line className="st18" x1="74.5" y1="221.6" x2="73.1" y2="223.5"/>
<path className="st18" d="M80.4,216.5c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M82.5,219.4c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M74.7,225.9c0.2,0.2,0.6,0.3,0.9,0"/>
<path className="st18" d="M73,223.4c-0.2,0.3-0.2,0.6,0,0.9"/>
<path className="st18" d="M70.3,224.7c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M73.3,228.7c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M83.2,219.7c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M80.2,215.7c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M71.9,226.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C71.8,226.3,71.9,226.3,71.9,226.2"/>
<path className="st18" d="M73,227.3c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3C72.8,227.3,73,227.3,73,227.3
	"/>
<path className="st18" d="M72.5,226.8c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C72.4,226.9,72.5,226.9,72.5,226.8"/>
<path className="st18" d="M78.7,220.7c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C78.5,220.7,78.6,220.7,78.7,220.7"/>
<line className="st23" x1="76.1" y1="221.9" x2="75.8" y2="222"/>
<line className="st23" x1="75.7" y1="222.2" x2="75.5" y2="222.4"/>
<line className="st23" x1="75.4" y1="222.5" x2="75.2" y2="222.8"/>
<polyline className="st23" points="75.1,222.9 74.9,223.1 75.1,223.2 "/>
<line className="st23" x1="75.2" y1="223.4" x2="75.4" y2="223.6"/>
<line className="st23" x1="75.5" y1="223.7" x2="75.7" y2="224"/>
<polyline className="st23" points="75.8,224.1 76.1,224.2 76.2,224.1 "/>
<line className="st23" x1="76.3" y1="224" x2="76.6" y2="223.7"/>
<line className="st23" x1="76.7" y1="223.6" x2="76.9" y2="223.4"/>
<polyline className="st23" points="77,223.2 77.2,223.1 77,222.9 "/>
<line className="st23" x1="76.9" y1="222.8" x2="76.7" y2="222.5"/>
<line className="st23" x1="76.6" y1="222.4" x2="76.3" y2="222.2"/>
<line className="st23" x1="76.2" y1="222" x2="76.1" y2="221.9"/>
<line className="st5" x1="211.3" y1="234.4" x2="211.4" y2="234.6"/>
<polyline className="st5" points="211.4,234.6 205.9,240.2 205.7,240 211.3,234.4 "/>
<path className="st5" d="M205.8,229c-3,3-3.1,7.8-0.2,10.9"/>
<path className="st5" d="M363,339c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="363,339 362.9,338.9 367.1,334.7 367.2,334.8 363,339 "/>
<path className="st5" d="M368.5,345.5c2.2,2.2,5.9,2.3,8.2,0.2"/>
<polyline className="st5" points="376.8,345.8 376.9,345.5 372.7,341.3 372.6,341.6 376.8,345.8 "/>
<path className="st16" d="M354.8,347.3l-1.7,1.7l1.7-1.6V347.3z M353.2,349.1l1.7-1.8l-1.7,1.7V349.1z M354.8,347.3l-1.7,1.8
	L354.8,347.3z M354.8,347.3L354.8,347.3l-1.7,1.8L354.8,347.3z"/>
<path className="st17" d="M354.8,347.3l-1.7,1.7l1.7-1.6V347.3z M353.2,349.1l1.7-1.8l-1.7,1.7V349.1z M354.8,347.3l-1.7,1.8
	L354.8,347.3z M354.8,347.3L354.8,347.3l-1.7,1.8L354.8,347.3z"/>
<path className="st16" d="M355,347.4l-1.8,1.7l1.7-1.8L355,347.4z M355,347.4L355,347.4l-1.8,1.7L355,347.4z M353.2,349.1l1.8-1.7
	L353.2,349.1z M355,347.4l-1.8,1.7L355,347.4z"/>
<path className="st17" d="M355,347.4l-1.8,1.7l1.7-1.8L355,347.4z M355,347.4L355,347.4l-1.8,1.7L355,347.4z M353.2,349.1l1.8-1.7
	L353.2,349.1z M355,347.4l-1.8,1.7L355,347.4z"/>
<path className="st16" d="M355.9,348.5l-2.8,0.6l1.8-1.7L355.9,348.5z M354.2,350.2l1.7-1.7l-2.8,0.6L354.2,350.2z"/>
<path className="st17" d="M355.9,348.5l-2.8,0.6l1.8-1.7L355.9,348.5z M354.2,350.2l1.7-1.7l-2.8,0.6L354.2,350.2z"/>
<path className="st16" d="M356.2,348.6l-1.1,0.7l0.8-0.8L356.2,348.6z M355.7,349.1l0.5-0.5l-1.1,0.7L355.7,349.1z"/>
<path className="st17" d="M356.2,348.6l-1.1,0.7l0.8-0.8L356.2,348.6z M355.7,349.1l0.5-0.5l-1.1,0.7L355.7,349.1z"/>
<path className="st16" d="M354.8,350l-0.6,0.2l0.8-0.8L354.8,350z M354.5,350.3l0.4-0.4l-0.6,0.2L354.5,350.3z"/>
<path className="st17" d="M354.8,350l-0.6,0.2l0.8-0.8L354.8,350z M354.5,350.3l0.4-0.4l-0.6,0.2L354.5,350.3z"/>
<path className="st16" d="M356.2,348.6L356.2,348.6l-0.5,0.5L356.2,348.6z M355.7,349.1l0.5-0.5L355.7,349.1z"/>
<path className="st17" d="M356.2,348.6L356.2,348.6l-0.5,0.5L356.2,348.6z M355.7,349.1l0.5-0.5L355.7,349.1z"/>
<path className="st16" d="M355.6,349.5l-0.4,0.2L355.6,349.5z M355.2,349.8l0.4-0.4l-0.4,0.2V349.8z M355.6,349.5l-0.4,0.4L355.6,349.5z
	 M355.6,349.5L355.6,349.5l-0.4,0.4L355.6,349.5z"/>
<path className="st17" d="M355.6,349.5l-0.4,0.2L355.6,349.5z M355.2,349.8l0.4-0.4l-0.4,0.2V349.8z M355.6,349.5l-0.4,0.4L355.6,349.5z
	 M355.6,349.5L355.6,349.5l-0.4,0.4L355.6,349.5z"/>
<path className="st16" d="M355.7,349.5l-0.5,0.4l0.4-0.4H355.7z M355.7,349.5L355.7,349.5l-0.5,0.4L355.7,349.5z M355.2,349.8l0.5-0.4
	L355.2,349.8z M355.7,349.5l-0.5,0.4L355.7,349.5z"/>
<path className="st17" d="M355.7,349.5l-0.5,0.4l0.4-0.4H355.7z M355.7,349.5L355.7,349.5l-0.5,0.4L355.7,349.5z M355.2,349.8l0.5-0.4
	L355.2,349.8z M355.7,349.5l-0.5,0.4L355.7,349.5z"/>
<path className="st16" d="M355.4,350.1l0.4-0.4l-0.1,0.1L355.4,350.1z M355.2,349.8l0.2,0.2l0.4-0.4L355.2,349.8z"/>
<path className="st17" d="M355.4,350.1l0.4-0.4l-0.1,0.1L355.4,350.1z M355.2,349.8l0.2,0.2l0.4-0.4L355.2,349.8z"/>
<polygon className="st16" points="355.2,349.8 355.7,349.5 355.8,349.7 "/>
<polygon className="st17" points="355.2,349.8 355.7,349.5 355.8,349.7 "/>
<path className="st16" d="M355.1,350.6l-0.6-0.2l0.4-0.4L355.1,350.6z M354.8,350.8l0.2-0.2l-0.6-0.2L354.8,350.8z"/>
<path className="st17" d="M355.1,350.6l-0.6-0.2l0.4-0.4L355.1,350.6z M354.8,350.8l0.2-0.2l-0.6-0.2L354.8,350.8z"/>
<path className="st16" d="M356.5,349l-0.8,0.1l0.5-0.5L356.5,349z M356.3,349.2l0.2-0.2l-0.8,0.1L356.3,349.2z"/>
<path className="st17" d="M356.5,349l-0.8,0.1l0.5-0.5L356.5,349z M356.3,349.2l0.2-0.2l-0.8,0.1L356.3,349.2z"/>
<path className="st16" d="M356.9,349.4l-0.6-0.1l0.2-0.2L356.9,349.4z M356.8,349.6l0.1-0.2l-0.6-0.1L356.8,349.6z"/>
<path className="st17" d="M356.9,349.4l-0.6-0.1l0.2-0.2L356.9,349.4z M356.8,349.6l0.1-0.2l-0.6-0.1L356.8,349.6z"/>
<path className="st16" d="M355.4,350.9l-0.6-0.1l0.2-0.2L355.4,350.9z M355.2,351.2l0.2-0.2l-0.6-0.1L355.2,351.2z"/>
<path className="st17" d="M355.4,350.9l-0.6-0.1l0.2-0.2L355.4,350.9z M355.2,351.2l0.2-0.2l-0.6-0.1L355.2,351.2z"/>
<path className="st16" d="M356,351.2h-0.8l0.2-0.2L356,351.2z M355.7,351.4l0.4-0.2h-0.8L355.7,351.4z"/>
<path className="st17" d="M356,351.2h-0.8l0.2-0.2L356,351.2z M355.7,351.4l0.4-0.2h-0.8L355.7,351.4z"/>
<path className="st16" d="M357.2,350l-0.5-0.4l0.1-0.2L357.2,350z M357,350.2l0.2-0.2l-0.5-0.4L357,350.2z M357,350.3v-0.1l0.2-0.2
	L357,350.3z"/>
<path className="st17" d="M357.2,350l-0.5-0.4l0.1-0.2L357.2,350z M357,350.2l0.2-0.2l-0.5-0.4L357,350.2z M357,350.3v-0.1l0.2-0.2
	L357,350.3z"/>
<path className="st16" d="M357,350.2l0.2-0.2L357,350.2z"/>
<path className="st17" d="M357,350.2l0.2-0.2L357,350.2z"/>
<path className="st16" d="M356,351.2l-0.4,0.2L356,351.2z M355.8,351.4l0.2-0.2l-0.4,0.2H355.8z"/>
<path className="st17" d="M356,351.2l-0.4,0.2L356,351.2z M355.8,351.4l0.2-0.2l-0.4,0.2H355.8z"/>
<path className="st16" d="M357.2,350.4l-0.2-0.1l0.2-0.4V350.4z M356.8,350.9l0.5-0.5l-0.2-0.1L356.8,350.9z"/>
<path className="st17" d="M357.2,350.4l-0.2-0.1l0.2-0.4V350.4z M356.8,350.9l0.5-0.5l-0.2-0.1L356.8,350.9z"/>
<path className="st16" d="M356.8,350.9l-1,0.5l0.2-0.2L356.8,350.9z M356.2,351.4l0.6-0.5l-1,0.5H356.2z"/>
<path className="st17" d="M356.8,350.9l-1,0.5l0.2-0.2L356.8,350.9z M356.2,351.4l0.6-0.5l-1,0.5H356.2z"/>
<path className="st16" d="M357.2,350.6l-1.1,0.8l1.1-1V350.6z M356.3,351.4l1-0.8l-1.1,0.8H356.3z M356.4,351.4h-0.1l1-0.8L356.4,351.4z
	"/>
<path className="st17" d="M357.2,350.6l-1.1,0.8l1.1-1V350.6z M356.3,351.4l1-0.8l-1.1,0.8H356.3z M356.4,351.4h-0.1l1-0.8L356.4,351.4z
	"/>
<path className="st16" d="M356.3,351.4l1-0.8L356.3,351.4z"/>
<path className="st17" d="M356.3,351.4l1-0.8L356.3,351.4z"/>
<path className="st16" d="M356.5,351.4l0.7-0.7l-0.4,0.5L356.5,351.4z M356.4,351.4h0.1l0.7-0.7L356.4,351.4z"/>
<path className="st17" d="M356.5,351.4l0.7-0.7l-0.4,0.5L356.5,351.4z M356.4,351.4h0.1l0.7-0.7L356.4,351.4z"/>
<polygon className="st16" points="356.4,351.4 357.1,350.7 357.2,350.7 "/>
<polygon className="st17" points="356.4,351.4 357.1,350.7 357.2,350.7 "/>
<path className="st19" d="M354.8,347.3C354.8,347.3,354.8,347.3,354.8,347.3"/>
<line className="st19" x1="354.8" y1="347.4" x2="353.2" y2="349"/>
<path className="st19" d="M353.2,349C353.2,349,353.2,349.1,353.2,349"/>
<line className="st19" x1="353.2" y1="349.1" x2="355.2" y2="351.2"/>
<path className="st19" d="M355.1,351.1c0.5,0.5,1.3,0.5,1.8,0s0.5-1.3,0-1.8"/>
<line className="st19" x1="356.9" y1="349.4" x2="355" y2="347.4"/>
<path className="st19" d="M355.5,349.4C355.5,349.4,355.5,349.4,355.5,349.4"/>
<line className="st19" x1="355.7" y1="349.5" x2="355.8" y2="349.7"/>
<path className="st19" d="M355.4,350.1c0.2,0,0.4-0.2,0.4-0.4"/>
<line className="st19" x1="355.4" y1="350.1" x2="355.2" y2="349.8"/>
<path className="st19" d="M355.2,349.7C355.2,349.8,355.2,349.8,355.2,349.7"/>
<line className="st19" x1="355.2" y1="349.7" x2="355.6" y2="349.5"/>
<line className="st19" x1="355.1" y1="350.6" x2="355.4" y2="350.9"/>
<path className="st19" d="M355.3,350.9c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3"/>
<line className="st19" x1="356.8" y1="349.6" x2="356.3" y2="349.2"/>
<path className="st19" d="M356.3,349.2c-0.4-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.4,0.9,0,1.2"/>
<line className="st1" x1="351.8" y1="350.3" x2="360.4" y2="341.9"/>
<path className="st16" d="M358.7,343.6l-1.9,1.8L358.7,343.6z M356.8,345.4l1.9-1.8L356.8,345.4z M356.8,345.4L356.8,345.4l1.9-1.8
	L356.8,345.4z M358.7,343.6l-1.9,1.8L358.7,343.6z"/>
<path className="st17" d="M358.7,343.6l-1.9,1.8L358.7,343.6z M356.8,345.4l1.9-1.8L356.8,345.4z M356.8,345.4L356.8,345.4l1.9-1.8
	L356.8,345.4z M358.7,343.6l-1.9,1.8L358.7,343.6z"/>
<path className="st16" d="M356.9,345.6l1.8-2l-1.9,1.8L356.9,345.6z M358.9,343.7l-2,1.9l1.8-2L358.9,343.7z"/>
<path className="st17" d="M356.9,345.6l1.8-2l-1.9,1.8L356.9,345.6z M358.9,343.7l-2,1.9l1.8-2L358.9,343.7z"/>
<path className="st16" d="M357,345.6l1-1l-1.1,1H357z M357.8,344.7l-0.8,1l1-1H357.8z"/>
<path className="st17" d="M357,345.6l1-1l-1.1,1H357z M357.8,344.7l-0.8,1l1-1H357.8z"/>
<path className="st16" d="M358,344.7L358,344.7l1-1L358,344.7z M358.9,343.7l-1,1L358.9,343.7z"/>
<path className="st17" d="M358,344.7L358,344.7l1-1L358,344.7z M358.9,343.7l-1,1L358.9,343.7z"/>
<path className="st16" d="M357,345.6L357,345.6l0.1-0.2L357,345.6z M357.1,345.4l-0.1,0.2L357.1,345.4z"/>
<path className="st17" d="M357,345.6L357,345.6l0.1-0.2L357,345.6z M357.1,345.4l-0.1,0.2L357.1,345.4z"/>
<path className="st16" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st17" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st16" d="M357.1,345.4L357.1,345.4l0.7-0.7L357.1,345.4z M357.8,344.7l-0.7,0.7L357.8,344.7z"/>
<path className="st17" d="M357.1,345.4L357.1,345.4l0.7-0.7L357.1,345.4z M357.8,344.7l-0.7,0.7L357.8,344.7z"/>
<path className="st16" d="M358,344.7L358,344.7l0.7-0.8L358,344.7z M358.7,343.8l-0.7,0.8L358.7,343.8z"/>
<path className="st17" d="M358,344.7L358,344.7l0.7-0.8L358,344.7z M358.7,343.8l-0.7,0.8L358.7,343.8z"/>
<path className="st16" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st17" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st16" d="M357,345.6L357,345.6l0.1-0.2L357,345.6z M357.1,345.4l-0.1,0.2L357.1,345.4z"/>
<path className="st17" d="M357,345.6L357,345.6l0.1-0.2L357,345.6z M357.1,345.4l-0.1,0.2L357.1,345.4z"/>
<path className="st16" d="M357.1,345.4L357.1,345.4l0.7-0.7L357.1,345.4z M357.8,344.7l-0.7,0.7L357.8,344.7z"/>
<path className="st17" d="M357.1,345.4L357.1,345.4l0.7-0.7L357.1,345.4z M357.8,344.7l-0.7,0.7L357.8,344.7z"/>
<path className="st16" d="M358,344.7L358,344.7l0.7-0.8L358,344.7z M358.7,343.8l-0.7,0.8L358.7,343.8z"/>
<path className="st17" d="M358,344.7L358,344.7l0.7-0.8L358,344.7z M358.7,343.8l-0.7,0.8L358.7,343.8z"/>
<path className="st16" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st17" d="M358.7,343.8L358.7,343.8l0.2-0.1L358.7,343.8z M358.9,343.7l-0.2,0.1L358.9,343.7z"/>
<path className="st16" d="M357,345.6L357,345.6l0.8-1L357,345.6z M357.8,344.7l-0.8,1L357.8,344.7z"/>
<path className="st17" d="M357,345.6L357,345.6l0.8-1L357,345.6z M357.8,344.7l-0.8,1L357.8,344.7z"/>
<path className="st16" d="M358,344.6l1-0.8l-1,1V344.6z M358,344.6L358,344.6l1-0.8L358,344.6z"/>
<path className="st17" d="M358,344.6l1-0.8l-1,1V344.6z M358,344.6L358,344.6l1-0.8L358,344.6z"/>
<path className="st16" d="M357,345.8l0.8-1.1l-0.8,1V345.8z M358,344.8l-1,1l0.8-1.1L358,344.8z"/>
<path className="st17" d="M357,345.8l0.8-1.1l-0.8,1V345.8z M358,344.8l-1,1l0.8-1.1L358,344.8z"/>
<path className="st16" d="M358.1,344.7l0.8-1l-1,0.8L358.1,344.7z M358.1,344.7L358.1,344.7l0.8-1L358.1,344.7z"/>
<path className="st17" d="M358.1,344.7l0.8-1l-1,0.8L358.1,344.7z M358.1,344.7L358.1,344.7l0.8-1L358.1,344.7z"/>
<path className="st16" d="M357,345.8L357,345.8l1-1L357,345.8z M358,344.8l-1,1L358,344.8z"/>
<path className="st17" d="M357,345.8L357,345.8l1-1L357,345.8z M358,344.8l-1,1L358,344.8z"/>
<path className="st16" d="M358.1,344.7l0.8-1L358.1,344.7z M359,343.8l-1,0.8l0.8-1L359,343.8z"/>
<path className="st17" d="M358.1,344.7l0.8-1L358.1,344.7z M359,343.8l-1,0.8l0.8-1L359,343.8z"/>
<path className="st16" d="M357,345.8L357,345.8l1-1L357,345.8z M358,344.8l-1,1L358,344.8z"/>
<path className="st17" d="M357,345.8L357,345.8l1-1L357,345.8z M358,344.8l-1,1L358,344.8z"/>
<path className="st16" d="M358.1,344.7L358.1,344.7l1-0.8L358.1,344.7z M359,343.8l-1,0.8L359,343.8z"/>
<path className="st17" d="M358.1,344.7L358.1,344.7l1-0.8L358.1,344.7z M359,343.8l-1,0.8L359,343.8z"/>
<path className="st16" d="M357.1,345.8l0.8-1l-1,1H357.1z M358.1,344.8l-1,1l0.8-1H358.1z"/>
<path className="st17" d="M357.1,345.8l0.8-1l-1,1H357.1z M358.1,344.8l-1,1l0.8-1H358.1z"/>
<path className="st16" d="M358.1,344.8l1-1l-1,0.8V344.8z M358.1,344.8L358.1,344.8l1-1L358.1,344.8z"/>
<path className="st17" d="M358.1,344.8l1-1l-1,0.8V344.8z M358.1,344.8L358.1,344.8l1-1L358.1,344.8z"/>
<path className="st16" d="M357.2,346l1.8-2.2l-1.9,1.9L357.2,346z M359.3,344l-2,2l1.8-2.2L359.3,344z"/>
<path className="st17" d="M357.2,346l1.8-2.2l-1.9,1.9L357.2,346z M359.3,344l-2,2l1.8-2.2L359.3,344z"/>
<polygon className="st16" points="358.3,345 358.3,344.9 358.2,345.2 "/>
<polygon className="st17" points="358.3,345 358.3,344.9 358.2,345.2 "/>
<path className="st16" d="M357.2,346.1l1-1.2l-1,1.1V346.1z M357.5,345.9l-0.2,0.2l1-1.2L357.5,345.9z"/>
<path className="st17" d="M357.2,346.1l1-1.2l-1,1.1V346.1z M357.5,345.9l-0.2,0.2l1-1.2L357.5,345.9z"/>
<path className="st16" d="M359,344.2l0.2-0.2l-1.1,1L359,344.2z M359,344.2L359,344.2l0.2-0.2L359,344.2z"/>
<path className="st17" d="M359,344.2l0.2-0.2l-1.1,1L359,344.2z M359,344.2L359,344.2l0.2-0.2L359,344.2z"/>
<path className="st16" d="M358.2,345.2l0.1-0.2L358.2,345.2z M358.4,344.9l-0.2,0.2l0.1-0.2H358.4z"/>
<path className="st17" d="M358.2,345.2l0.1-0.2L358.2,345.2z M358.4,344.9l-0.2,0.2l0.1-0.2H358.4z"/>
<path className="st16" d="M358.2,345.2L358.2,345.2l0.1-0.1L358.2,345.2z M358.3,345l-0.1,0.1L358.3,345z"/>
<path className="st17" d="M358.2,345.2L358.2,345.2l0.1-0.1L358.2,345.2z M358.3,345l-0.1,0.1L358.3,345z"/>
<path className="st16" d="M358.3,345L358.3,345l0.1-0.1L358.3,345z M358.4,344.9l-0.1,0.1L358.4,344.9z"/>
<path className="st17" d="M358.3,345L358.3,345l0.1-0.1L358.3,345z M358.4,344.9l-0.1,0.1L358.4,344.9z"/>
<path className="st16" d="M357.2,346.1L357.2,346.1l0.2-0.2L357.2,346.1z M357.5,345.9l-0.2,0.2L357.5,345.9z"/>
<path className="st17" d="M357.2,346.1L357.2,346.1l0.2-0.2L357.2,346.1z M357.5,345.9l-0.2,0.2L357.5,345.9z"/>
<path className="st16" d="M359.2,344.2l0.1-0.2l-0.2,0.2H359.2z M359.4,344.1l-0.2,0.1l0.1-0.2L359.4,344.1z"/>
<path className="st17" d="M359.2,344.2l0.1-0.2l-0.2,0.2H359.2z M359.4,344.1l-0.2,0.1l0.1-0.2L359.4,344.1z"/>
<path className="st16" d="M358.2,345.3l0.1-0.2l-0.1,0.1V345.3z M358.3,345.2l-0.1,0.1l0.1-0.2V345.2z"/>
<path className="st17" d="M358.2,345.3l0.1-0.2l-0.1,0.1V345.3z M358.3,345.2l-0.1,0.1l0.1-0.2V345.2z"/>
<path className="st16" d="M358.4,345v-0.1l-0.1,0.1H358.4z M358.4,345L358.4,345v-0.1V345z"/>
<path className="st17" d="M358.4,345v-0.1l-0.1,0.1H358.4z M358.4,345L358.4,345v-0.1V345z"/>
<path className="st16" d="M359.2,344.2L359.2,344.2l0.2-0.1L359.2,344.2z M359.4,344.1l-0.2,0.1L359.4,344.1z"/>
<path className="st17" d="M359.2,344.2L359.2,344.2l0.2-0.1L359.2,344.2z M359.4,344.1l-0.2,0.1L359.4,344.1z"/>
<path className="st16" d="M359.2,344.2L359.2,344.2h0.1H359.2z M359.3,344.2h-0.1H359.3z"/>
<path className="st17" d="M359.2,344.2L359.2,344.2h0.1H359.2z M359.3,344.2h-0.1H359.3z"/>
<path className="st16" d="M359.3,344.2L359.3,344.2l0.1-0.1L359.3,344.2z M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st17" d="M359.3,344.2L359.3,344.2l0.1-0.1L359.3,344.2z M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st16" d="M357.4,346.1l0.1-0.2l-0.2,0.2H357.4z M357.5,346l-0.1,0.1l0.1-0.2V346z M357.4,346.1l0.1-0.1L357.4,346.1z
	 M357.4,346.1L357.4,346.1l0.1-0.1L357.4,346.1z"/>
<path className="st17" d="M357.4,346.1l0.1-0.2l-0.2,0.2H357.4z M357.5,346l-0.1,0.1l0.1-0.2V346z M357.4,346.1l0.1-0.1L357.4,346.1z
	 M357.4,346.1L357.4,346.1l0.1-0.1L357.4,346.1z"/>
<path className="st16" d="M357.5,346L357.5,346z M357.5,346L357.5,346z"/>
<path className="st17" d="M357.5,346L357.5,346z M357.5,346L357.5,346z"/>
<path className="st16" d="M357.4,346.1L357.4,346.1l0.1-0.1L357.4,346.1z M357.5,346l-0.1,0.1L357.5,346z"/>
<path className="st17" d="M357.4,346.1L357.4,346.1l0.1-0.1L357.4,346.1z M357.5,346l-0.1,0.1L357.5,346z"/>
<path className="st16" d="M357.5,346L357.5,346z M357.5,346L357.5,346z"/>
<path className="st17" d="M357.5,346L357.5,346z M357.5,346L357.5,346z"/>
<path className="st16" d="M359.3,344.2L359.3,344.2z"/>
<path className="st17" d="M359.3,344.2L359.3,344.2z"/>
<path className="st16" d="M359.3,344.2h-0.1H359.3z"/>
<path className="st17" d="M359.3,344.2h-0.1H359.3z"/>
<path className="st16" d="M359.3,344.2L359.3,344.2l0.1-0.1L359.3,344.2z M359.3,344.2L359.3,344.2z M359.3,344.2L359.3,344.2z
	 M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st17" d="M359.3,344.2L359.3,344.2l0.1-0.1L359.3,344.2z M359.3,344.2L359.3,344.2z M359.3,344.2L359.3,344.2z
	 M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st16" d="M357.4,346.2l0.1-0.2l-0.1,0.1V346.2z M357.5,346.1l-0.1,0.1l0.1-0.2V346.1z"/>
<path className="st17" d="M357.4,346.2l0.1-0.2l-0.1,0.1V346.2z M357.5,346.1l-0.1,0.1l0.1-0.2V346.1z"/>
<path className="st16" d="M359.4,344.1l-0.1,0.1L359.4,344.1z M359.3,344.2l0.1-0.1L359.3,344.2z M359.3,344.2L359.3,344.2l0.1-0.1
	L359.3,344.2z M359.3,344.2L359.3,344.2z M359.3,344.2L359.3,344.2z M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st17" d="M359.4,344.1l-0.1,0.1L359.4,344.1z M359.3,344.2l0.1-0.1L359.3,344.2z M359.3,344.2L359.3,344.2l0.1-0.1
	L359.3,344.2z M359.3,344.2L359.3,344.2z M359.3,344.2L359.3,344.2z M359.4,344.1l-0.1,0.1L359.4,344.1z"/>
<path className="st16" d="M359.3,344.3L359.3,344.3v-0.1V344.3z M359.3,344.2v0.1V344.2z"/>
<path className="st17" d="M359.3,344.3L359.3,344.3v-0.1V344.3z M359.3,344.2v0.1V344.2z"/>
<path className="st16" d="M359.3,344.2l0.1-0.1L359.3,344.2z M359.5,344.1l-0.2,0.1l0.1-0.1H359.5z"/>
<path className="st17" d="M359.3,344.2l0.1-0.1L359.3,344.2z M359.5,344.1l-0.2,0.1l0.1-0.1H359.5z"/>
<path className="st16" d="M359.3,344.3L359.3,344.3v-0.1V344.3z M359.3,344.2v0.1V344.2z"/>
<path className="st17" d="M359.3,344.3L359.3,344.3v-0.1V344.3z M359.3,344.2v0.1V344.2z"/>
<path className="st16" d="M359.5,344.1l-0.2,0.1L359.5,344.1z M359.3,344.2l0.2-0.1L359.3,344.2z M359.3,344.2L359.3,344.2l0.2-0.1
	L359.3,344.2z M359.5,344.1l-0.2,0.1L359.5,344.1z"/>
<path className="st17" d="M359.5,344.1l-0.2,0.1L359.5,344.1z M359.3,344.2l0.2-0.1L359.3,344.2z M359.3,344.2L359.3,344.2l0.2-0.1
	L359.3,344.2z M359.5,344.1l-0.2,0.1L359.5,344.1z"/>
<path className="st16" d="M357.4,346.2L357.4,346.2l0.1-0.1L357.4,346.2z M357.5,346.1l-0.1,0.1L357.5,346.1z"/>
<path className="st17" d="M357.4,346.2L357.4,346.2l0.1-0.1L357.4,346.2z M357.5,346.1l-0.1,0.1L357.5,346.1z"/>
<path className="st16" d="M357.5,346.1L357.5,346.1V346V346.1z M357.5,346v0.1V346z"/>
<path className="st17" d="M357.5,346.1L357.5,346.1V346V346.1z M357.5,346v0.1V346z"/>
<path className="st16" d="M359.4,344.3h-0.1H359.4z"/>
<path className="st17" d="M359.4,344.3h-0.1H359.4z"/>
<path className="st16" d="M357.5,346.1h0.1H357.5z"/>
<path className="st17" d="M357.5,346.1h0.1H357.5z"/>
<path className="st16" d="M357.5,346.1L357.5,346.1V346V346.1z M357.5,346v0.1V346z"/>
<path className="st17" d="M357.5,346.1L357.5,346.1V346V346.1z M357.5,346v0.1V346z"/>
<path className="st16" d="M358.3,345.3v-0.1l-0.1,0.1H358.3z M358.3,345.3L358.3,345.3v-0.1V345.3z"/>
<path className="st17" d="M358.3,345.3v-0.1l-0.1,0.1H358.3z M358.3,345.3L358.3,345.3v-0.1V345.3z"/>
<path className="st16" d="M358.6,345l-0.1-0.1v0.1H358.6z M358.6,345L358.6,345l-0.1-0.1L358.6,345z"/>
<path className="st17" d="M358.6,345l-0.1-0.1v0.1H358.6z M358.6,345L358.6,345l-0.1-0.1L358.6,345z"/>
<path className="st16" d="M359.3,344.3v-0.1V344.3z M359.4,344.3h-0.1v-0.1L359.4,344.3z"/>
<path className="st17" d="M359.3,344.3v-0.1V344.3z M359.4,344.3h-0.1v-0.1L359.4,344.3z"/>
<path className="st16" d="M357.5,346.2v-0.1l-0.1,0.1H357.5z M357.6,346.1l-0.1,0.1v-0.1H357.6z"/>
<path className="st17" d="M357.5,346.2v-0.1l-0.1,0.1H357.5z M357.6,346.1l-0.1,0.1v-0.1H357.6z"/>
<path className="st16" d="M359.4,344.3l0.1-0.2l-0.2,0.1L359.4,344.3z M359.5,344.2l-0.1,0.1l0.1-0.2V344.2z M359.4,344.3l0.1-0.1
	L359.4,344.3z M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z"/>
<path className="st17" d="M359.4,344.3l0.1-0.2l-0.2,0.1L359.4,344.3z M359.5,344.2l-0.1,0.1l0.1-0.2V344.2z M359.4,344.3l0.1-0.1
	L359.4,344.3z M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z"/>
<path className="st16" d="M357.6,346.1l-0.1,0.1L357.6,346.1z M357.5,346.2l0.1-0.1L357.5,346.2z M357.5,346.2L357.5,346.2l0.1-0.1
	L357.5,346.2z M357.6,346.1l-0.1,0.1L357.6,346.1z"/>
<path className="st17" d="M357.6,346.1l-0.1,0.1L357.6,346.1z M357.5,346.2l0.1-0.1L357.5,346.2z M357.5,346.2L357.5,346.2l0.1-0.1
	L357.5,346.2z M357.6,346.1l-0.1,0.1L357.6,346.1z"/>
<path className="st16" d="M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z M359.5,344.2l-0.1,0.1L359.5,344.2z"/>
<path className="st17" d="M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z M359.5,344.2l-0.1,0.1L359.5,344.2z"/>
<path className="st16" d="M357.5,346.2L357.5,346.2l0.1-0.1L357.5,346.2z M357.6,346.1l-0.1,0.1L357.6,346.1z"/>
<path className="st17" d="M357.5,346.2L357.5,346.2l0.1-0.1L357.5,346.2z M357.6,346.1l-0.1,0.1L357.6,346.1z"/>
<path className="st16" d="M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z M359.5,344.2l-0.1,0.1L359.5,344.2z"/>
<path className="st17" d="M359.4,344.3L359.4,344.3l0.1-0.1L359.4,344.3z M359.5,344.2l-0.1,0.1L359.5,344.2z"/>
<path className="st16" d="M358.4,345.4L358.4,345.4l-0.1-0.1L358.4,345.4z M358.3,345.3l0.1,0.1L358.3,345.3z"/>
<path className="st17" d="M358.4,345.4L358.4,345.4l-0.1-0.1L358.4,345.4z M358.3,345.3l0.1,0.1L358.3,345.3z"/>
<path className="st16" d="M358.6,345.2V345V345.2z M358.7,345.2h-0.1V345L358.7,345.2z"/>
<path className="st17" d="M358.6,345.2V345V345.2z M358.7,345.2h-0.1V345L358.7,345.2z"/>
<path className="st16" d="M358.4,345.4l0.1-0.1L358.4,345.4z"/>
<path className="st17" d="M358.4,345.4l0.1-0.1L358.4,345.4z"/>
<path className="st16" d="M358.4,345.4L358.4,345.4z"/>
<path className="st17" d="M358.4,345.4L358.4,345.4z"/>
<path className="st16" d="M358.6,345.3l0.1-0.1h-0.1V345.3z M358.6,345.3L358.6,345.3l0.1-0.1L358.6,345.3z"/>
<path className="st17" d="M358.6,345.3l0.1-0.1h-0.1V345.3z M358.6,345.3L358.6,345.3l0.1-0.1L358.6,345.3z"/>
<path className="st16" d="M358.6,345.3l-0.1,0.1L358.6,345.3z M358.4,345.4l0.1-0.1L358.4,345.4z M358.7,345.2l-0.2,0.2l0.1-0.1
	L358.7,345.2z"/>
<path className="st17" d="M358.6,345.3l-0.1,0.1L358.6,345.3z M358.4,345.4l0.1-0.1L358.4,345.4z M358.7,345.2l-0.2,0.2l0.1-0.1
	L358.7,345.2z"/>
<path className="st16" d="M358.6,345.3L358.6,345.3l0.1-0.1L358.6,345.3z M358.7,345.2l-0.1,0.1L358.7,345.2z"/>
<path className="st17" d="M358.6,345.3L358.6,345.3l0.1-0.1L358.6,345.3z M358.7,345.2l-0.1,0.1L358.7,345.2z"/>
<path className="st16" d="M357.6,346.4v-0.2l-0.1,0.1L357.6,346.4z M357.8,346.2l-0.2,0.1v-0.2L357.8,346.2z"/>
<path className="st17" d="M357.6,346.4v-0.2l-0.1,0.1L357.6,346.4z M357.8,346.2l-0.2,0.1v-0.2L357.8,346.2z"/>
<path className="st16" d="M359.5,344.6v-0.4l-0.1,0.1L359.5,344.6z M359.6,344.4l-0.1,0.1v-0.4L359.6,344.4z"/>
<path className="st17" d="M359.5,344.6v-0.4l-0.1,0.1L359.5,344.6z M359.6,344.4l-0.1,0.1v-0.4L359.6,344.4z"/>
<path className="st16" d="M358,346.5l-0.1-0.2l-0.2,0.1L358,346.5z M358.1,346.4l-0.1,0.1l-0.1-0.2L358.1,346.4z"/>
<path className="st17" d="M358,346.5l-0.1-0.2l-0.2,0.1L358,346.5z M358.1,346.4l-0.1,0.1l-0.1-0.2L358.1,346.4z"/>
<path className="st16" d="M359.5,344.9l0.1-0.5l-0.1,0.1V344.9z M359.8,344.7l-0.2,0.2l0.1-0.5L359.8,344.7z"/>
<path className="st17" d="M359.5,344.9l0.1-0.5l-0.1,0.1V344.9z M359.8,344.7l-0.2,0.2l0.1-0.5L359.8,344.7z"/>
<path className="st16" d="M358.2,346.6l-0.1-0.2l-0.1,0.1L358.2,346.6z M358.3,346.4l-0.1,0.2l-0.1-0.2H358.3z"/>
<path className="st17" d="M358.2,346.6l-0.1-0.2l-0.1,0.1L358.2,346.6z M358.3,346.4l-0.1,0.2l-0.1-0.2H358.3z"/>
<path className="st16" d="M359.6,345l0.1-0.4l-0.2,0.2L359.6,345z M359.8,344.9l-0.1,0.1l0.1-0.4V344.9z"/>
<path className="st17" d="M359.6,345l0.1-0.4l-0.2,0.2L359.6,345z M359.8,344.9l-0.1,0.1l0.1-0.4V344.9z"/>
<path className="st16" d="M358.2,346.6L358.2,346.6l0.1-0.2L358.2,346.6z M358.3,346.4l-0.1,0.2L358.3,346.4z"/>
<path className="st17" d="M358.2,346.6L358.2,346.6l0.1-0.2L358.2,346.6z M358.3,346.4l-0.1,0.2L358.3,346.4z"/>
<path className="st16" d="M359.6,345.2l0.1-0.2l-0.1,0.1V345.2z M359.6,345.2L359.6,345.2l0.1-0.2L359.6,345.2z"/>
<path className="st17" d="M359.6,345.2l0.1-0.2l-0.1,0.1V345.2z M359.6,345.2L359.6,345.2l0.1-0.2L359.6,345.2z"/>
<path className="st16" d="M358.4,346.6l-0.1-0.2l-0.1,0.2H358.4z M358.8,346.1l-0.4,0.5l-0.1-0.2L358.8,346.1z"/>
<path className="st17" d="M358.4,346.6l-0.1-0.2l-0.1,0.2H358.4z M358.8,346.1l-0.4,0.5l-0.1-0.2L358.8,346.1z"/>
<path className="st16" d="M359.4,345.5l0.4-0.6l-0.1,0.2L359.4,345.5z M359.8,345.2l-0.4,0.4l0.4-0.6V345.2z"/>
<path className="st17" d="M359.4,345.5l0.4-0.6l-0.1,0.2L359.4,345.5z M359.8,345.2l-0.4,0.4l0.4-0.6V345.2z"/>
<path className="st16" d="M358.7,346.5l0.1-0.4l-0.4,0.5L358.7,346.5z M359.3,346l-0.6,0.5l0.1-0.4L359.3,346z"/>
<path className="st17" d="M358.7,346.5l0.1-0.4l-0.4,0.5L358.7,346.5z M359.3,346l-0.6,0.5l0.1-0.4L359.3,346z"/>
<path className="st16" d="M359.3,346l0.5-0.8l-0.4,0.4L359.3,346z M359.8,345.4l-0.5,0.6l0.5-0.8V345.4z"/>
<path className="st17" d="M359.3,346l0.5-0.8l-0.4,0.4L359.3,346z M359.8,345.4l-0.5,0.6l0.5-0.8V345.4z"/>
<path className="st16" d="M358.7,346.5L358.7,346.5l1.1-1.1L358.7,346.5z M359.8,345.4l-1.1,1.1L359.8,345.4z"/>
<path className="st17" d="M358.7,346.5L358.7,346.5l1.1-1.1L358.7,346.5z M359.8,345.4l-1.1,1.1L359.8,345.4z"/>
<path className="st16" d="M358.9,346.5l0.8-1.1l-1.1,1.1H358.9z M359.6,345.6l-0.7,0.8l0.8-1.1L359.6,345.6z"/>
<path className="st17" d="M358.9,346.5l0.8-1.1l-1.1,1.1H358.9z M359.6,345.6l-0.7,0.8l0.8-1.1L359.6,345.6z"/>
<path className="st16" d="M359.5,345.9l-0.4,0.4l0.2-0.1L359.5,345.9z M359.6,345.6l-0.1,0.2l-0.4,0.4L359.6,345.6z"/>
<path className="st17" d="M359.5,345.9l-0.4,0.4l0.2-0.1L359.5,345.9z M359.6,345.6l-0.1,0.2l-0.4,0.4L359.6,345.6z"/>
<polygon className="st16" points="359.6,345.6 358.9,346.5 359.2,346.2 "/>
<polygon className="st17" points="359.6,345.6 358.9,346.5 359.2,346.2 "/>
<path className="st18" d="M358.7,343.8c-0.3,0.2-0.6,0.4-0.9,0.7"/>
<path className="st18" d="M359.2,344.1c-0.1-0.1-0.3-0.2-0.4-0.3"/>
<path className="st18" d="M359.3,344.2c0,0-0.1-0.1-0.1-0.1"/>
<path className="st18" d="M358.8,343.7c-0.1,0-0.1,0-0.2,0"/>
<path className="st18" d="M357.1,345.5c0.1,0.1,0.2,0.3,0.3,0.4"/>
<path className="st18" d="M357.4,345.9c0,0,0.1,0.1,0.1,0.1"/>
<path className="st18" d="M357.7,344.5c-0.3,0.3-0.5,0.6-0.7,0.9"/>
<path className="st18" d="M357,345.4c0,0.1,0,0.1,0,0.2"/>
<path className="st18" d="M357.5,345.9c0,0.1,0,0.2,0,0.2"/>
<path className="st18" d="M359,344.2c-0.6,0.4-1.2,1-1.6,1.6"/>
<path className="st18" d="M359.2,344.3c-0.1-0.1-0.1-0.1-0.2,0"/>
<polyline className="st18" points="359.4,344.3 359.4,344.4 359.5,344.6 359.6,344.7 359.6,344.9 359.6,345.2 359.6,345.4 359.5,345.5 
	359.4,345.8 359.3,345.9 359.3,346 359.2,346 358.9,346.2 358.8,346.2 358.6,346.4 358.4,346.4 358.2,346.4 358,346.4 357.8,346.2 
	357.6,346.2 357.6,346.1 "/>
<polygon className="st20" points="357.6,345.5 357.1,345.6 357,346.1 357.1,346.6 357.6,346.7 358.1,346.6 358.2,346.1 358.1,345.6 "/>
<polygon className="st4" points="357.6,345.5 357.1,345.6 357,346.1 357.1,346.6 357.6,346.7 358.1,346.6 358.2,346.1 358.1,345.6 "/>
<polygon className="st20" points="357.5,345.3 357,345.4 356.9,345.9 357,346.4 357.5,346.5 358,346.4 358.1,345.9 358,345.4 "/>
<polygon className="st4" points="357.5,345.3 357,345.4 356.9,345.9 357,346.4 357.5,346.5 358,346.4 358.1,345.9 358,345.4 "/>
<polygon className="st20" points="359,343.6 358.6,343.7 358.4,344.2 358.6,344.7 359,344.8 359.5,344.7 359.6,344.2 359.5,343.7 "/>
<polygon className="st4" points="359,343.6 358.6,343.7 358.4,344.2 358.6,344.7 359,344.8 359.5,344.7 359.6,344.2 359.5,343.7 "/>
<polygon className="st20" points="359.4,343.7 358.9,343.8 358.8,344.3 358.9,344.8 359.4,344.9 359.9,344.8 360,344.3 359.9,343.8 "/>
<polygon className="st4" points="359.4,343.7 358.9,343.8 358.8,344.3 358.9,344.8 359.4,344.9 359.9,344.8 360,344.3 359.9,343.8 "/>
<polygon className="st20" points="359.3,345.4 358.8,345.5 358.7,346 358.8,346.5 359.3,346.6 359.8,346.5 359.9,346 359.8,345.5 "/>
<polygon className="st4" points="359.3,345.4 358.8,345.5 358.7,346 358.8,346.5 359.3,346.6 359.8,346.5 359.9,346 359.8,345.5 "/>
<path className="st18" d="M357.3,346c0,0.1,0.1,0.1,0.1,0.1"/>
<path className="st18" d="M356.7,345.3c0.1,0.2,0.3,0.5,0.5,0.7"/>
<polyline className="st18" points="356.8,345.4 357.7,344.4 358.7,343.6 "/>
<path className="st18" d="M359.3,343.9c-0.2-0.2-0.5-0.3-0.7-0.5"/>
<path className="st18" d="M359.4,344.2c0-0.1-0.1-0.1-0.1-0.1"/>
<polyline className="st18" points="359.5,344.2 359.8,344.8 359.8,345.5 359.4,346.1 358.8,346.5 358.1,346.6 357.5,346.2 "/>
<polygon className="st20" points="357.5,345.6 357,345.8 356.9,346.2 357,346.7 357.5,346.8 358,346.7 358.1,346.2 358,345.8 "/>
<polygon className="st4" points="357.5,345.6 357,345.8 356.9,346.2 357,346.7 357.5,346.8 358,346.7 358.1,346.2 358,345.8 "/>
<polygon className="st20" points="357.2,345.5 356.8,345.6 356.6,346.1 356.8,346.6 357.2,346.7 357.7,346.6 357.8,346.1 357.7,345.6 
	"/>
<polygon className="st4" points="357.2,345.5 356.8,345.6 356.6,346.1 356.8,346.6 357.2,346.7 357.7,346.6 357.8,346.1 357.7,345.6 "/>
<line className="st18" x1="356.8" y1="345.4" x2="356.8" y2="345.4"/>
<polygon className="st20" points="357.7,343.8 357.2,344 357.1,344.4 357.2,344.9 357.7,345 358.2,344.9 358.3,344.4 358.2,344 "/>
<polygon className="st4" points="357.7,343.8 357.2,344 357.1,344.4 357.2,344.9 357.7,345 358.2,344.9 358.3,344.4 358.2,344 "/>
<line className="st18" x1="358.7" y1="343.6" x2="358.7" y2="343.6"/>
<polygon className="st20" points="359.4,343.5 358.9,343.6 358.8,344.1 358.9,344.6 359.4,344.7 359.9,344.6 360,344.1 359.9,343.6 "/>
<polygon className="st4" points="359.4,343.5 358.9,343.6 358.8,344.1 358.9,344.6 359.4,344.7 359.9,344.6 360,344.1 359.9,343.6 "/>
<polygon className="st20" points="359.5,343.6 359,343.7 358.9,344.2 359,344.7 359.5,344.8 360,344.7 360.1,344.2 360,343.7 "/>
<polygon className="st4" points="359.5,343.6 359,343.7 358.9,344.2 359,344.7 359.5,344.8 360,344.7 360.1,344.2 360,343.7 "/>
<polygon className="st20" points="359.4,345.5 358.9,345.6 358.8,346.1 358.9,346.6 359.4,346.7 359.9,346.6 360,346.1 359.9,345.6 "/>
<polygon className="st4" points="359.4,345.5 358.9,345.6 358.8,346.1 358.9,346.6 359.4,346.7 359.9,346.6 360,346.1 359.9,345.6 "/>
<path className="st18" d="M358.5,345.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C358.3,345.3,358.4,345.3,358.5,345.2"/>
<polygon className="st20" points="358.3,344.7 357.8,344.8 357.7,345.3 357.8,345.8 358.3,345.9 358.8,345.8 358.9,345.3 358.8,344.8 
	"/>
<polygon className="st4" points="358.3,344.7 357.8,344.8 357.7,345.3 357.8,345.8 358.3,345.9 358.8,345.8 358.9,345.3 358.8,344.8 "/>
<path className="st18" d="M358.5,345.2c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C358.3,345.3,358.4,345.3,358.5,345.2"/>
<polygon className="st20" points="358.3,344.7 357.8,344.8 357.7,345.3 357.8,345.8 358.3,345.9 358.8,345.8 358.9,345.3 358.8,344.8 
	"/>
<polygon className="st4" points="358.3,344.7 357.8,344.8 357.7,345.3 357.8,345.8 358.3,345.9 358.8,345.8 358.9,345.3 358.8,344.8 "/>
<path className="st18" d="M357.9,344.7C358,344.6,358,344.6,357.9,344.7C357.9,344.6,357.9,344.6,357.9,344.7
	C357.8,344.6,357.8,344.6,357.9,344.7C357.9,344.7,357.9,344.7,357.9,344.7"/>
<polygon className="st20" points="358,344.2 357.5,344.3 357.4,344.8 357.5,345.3 358,345.4 358.4,345.3 358.6,344.8 358.4,344.3 "/>
<polygon className="st4" points="358,344.2 357.5,344.3 357.4,344.8 357.5,345.3 358,345.4 358.4,345.3 358.6,344.8 358.4,344.3 "/>
<polyline className="st1" points="351.8,350.3 359.6,341.2 351.2,349.6 351.8,350.3 "/>
<path className="st5" d="M365.9,356.4c2.1-2.3,2.1-6-0.2-8.2"/>
<polyline className="st5" points="366,356.6 365.8,356.7 361.6,352.5 361.8,352.4 366,356.6 "/>
<path className="st5" d="M60.2,182.4c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="56.8" y1="179" x2="60.2" y2="175.5"/>
<polyline className="st5" points="56.8,179 56.9,179.2 60.5,175.6 60.2,175.5 "/>
<path className="st16" d="M63.1,185.4l-1.8-1.9L63.1,185.4z M61.3,183.5l1.8,1.9L61.3,183.5z M61.3,183.5L61.3,183.5l1.8,1.9L61.3,183.5
	z M63.1,185.4l-1.8-1.9L63.1,185.4z"/>
<path className="st17" d="M63.1,185.4l-1.8-1.9L63.1,185.4z M61.3,183.5l1.8,1.9L61.3,183.5z M61.3,183.5L61.3,183.5l1.8,1.9L61.3,183.5
	z M63.1,185.4l-1.8-1.9L63.1,185.4z"/>
<path className="st16" d="M61.4,183.3l1.7,2.2l-1.8-1.9L61.4,183.3z M63.4,185.3l-1.9-2l1.7,2.2L63.4,185.3z"/>
<path className="st17" d="M61.4,183.3l1.7,2.2l-1.8-1.9L61.4,183.3z M63.4,185.3l-1.9-2l1.7,2.2L63.4,185.3z"/>
<path className="st16" d="M61.4,183.3l1,1.1L61.4,183.3z M62.4,184.2l-1-1l1,1.1V184.2z"/>
<path className="st17" d="M61.4,183.3l1,1.1L61.4,183.3z M62.4,184.2l-1-1l1,1.1V184.2z"/>
<path className="st16" d="M62.4,184.4L62.4,184.4l1,1L62.4,184.4z M63.4,185.3l-1-1L63.4,185.3z"/>
<path className="st17" d="M62.4,184.4L62.4,184.4l1,1L62.4,184.4z M63.4,185.3l-1-1L63.4,185.3z"/>
<path className="st16" d="M61.4,183.3L61.4,183.3l0.2,0.2L61.4,183.3z M61.7,183.5l-0.2-0.2L61.7,183.5z"/>
<path className="st17" d="M61.4,183.3L61.4,183.3l0.2,0.2L61.4,183.3z M61.7,183.5l-0.2-0.2L61.7,183.5z"/>
<path className="st16" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st17" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st16" d="M61.7,183.5L61.7,183.5l0.7,0.7L61.7,183.5z M62.4,184.2l-0.7-0.7L62.4,184.2z"/>
<path className="st17" d="M61.7,183.5L61.7,183.5l0.7,0.7L61.7,183.5z M62.4,184.2l-0.7-0.7L62.4,184.2z"/>
<path className="st16" d="M62.4,184.4L62.4,184.4l0.8,0.7L62.4,184.4z M63.2,185.1l-0.8-0.7L63.2,185.1z"/>
<path className="st17" d="M62.4,184.4L62.4,184.4l0.8,0.7L62.4,184.4z M63.2,185.1l-0.8-0.7L63.2,185.1z"/>
<path className="st16" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st17" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st16" d="M61.4,183.3L61.4,183.3l0.2,0.2L61.4,183.3z M61.7,183.5l-0.2-0.2L61.7,183.5z"/>
<path className="st17" d="M61.4,183.3L61.4,183.3l0.2,0.2L61.4,183.3z M61.7,183.5l-0.2-0.2L61.7,183.5z"/>
<path className="st16" d="M61.7,183.5L61.7,183.5l0.7,0.7L61.7,183.5z M62.4,184.2l-0.7-0.7L62.4,184.2z"/>
<path className="st17" d="M61.7,183.5L61.7,183.5l0.7,0.7L61.7,183.5z M62.4,184.2l-0.7-0.7L62.4,184.2z"/>
<path className="st16" d="M62.4,184.4L62.4,184.4l0.8,0.7L62.4,184.4z M63.2,185.1l-0.8-0.7L63.2,185.1z"/>
<path className="st17" d="M62.4,184.4L62.4,184.4l0.8,0.7L62.4,184.4z M63.2,185.1l-0.8-0.7L63.2,185.1z"/>
<path className="st16" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st17" d="M63.2,185.1L63.2,185.1l0.1,0.2L63.2,185.1z M63.4,185.3l-0.1-0.2L63.4,185.3z"/>
<path className="st16" d="M61.4,183.3L61.4,183.3l1,1L61.4,183.3z M62.4,184.2l-1-1L62.4,184.2z"/>
<path className="st17" d="M61.4,183.3L61.4,183.3l1,1L61.4,183.3z M62.4,184.2l-1-1L62.4,184.2z"/>
<path className="st16" d="M62.5,184.4l0.8,1l-1-1H62.5z M63.4,185.2l-0.8-0.8l0.8,1V185.2z"/>
<path className="st17" d="M62.5,184.4l0.8,1l-1-1H62.5z M63.4,185.2l-0.8-0.8l0.8,1V185.2z"/>
<path className="st16" d="M61.4,183.3l1,1L61.4,183.3z M62.4,184.1l-1-0.8l1,1V184.1z"/>
<path className="st17" d="M61.4,183.3l1,1L61.4,183.3z M62.4,184.1l-1-0.8l1,1V184.1z"/>
<path className="st16" d="M62.5,184.4l0.8,0.8L62.5,184.4z M63.5,185.2l-1-0.8l0.8,0.8H63.5z"/>
<path className="st17" d="M62.5,184.4l0.8,0.8L62.5,184.4z M63.5,185.2l-1-0.8l0.8,0.8H63.5z"/>
<path className="st16" d="M61.6,183.2l0.8,1l-1-0.8L61.6,183.2z M62.5,184.1l-1-1l0.8,1H62.5z"/>
<path className="st17" d="M61.6,183.2l0.8,1l-1-0.8L61.6,183.2z M62.5,184.1l-1-1l0.8,1H62.5z"/>
<path className="st16" d="M62.6,184.2l0.8,1l-1-0.8L62.6,184.2z M62.6,184.2L62.6,184.2l0.8,1L62.6,184.2z"/>
<path className="st17" d="M62.6,184.2l0.8,1l-1-0.8L62.6,184.2z M62.6,184.2L62.6,184.2l0.8,1L62.6,184.2z"/>
<path className="st16" d="M61.6,183.2L61.6,183.2l1,1L61.6,183.2z M62.5,184.1l-1-1L62.5,184.1z"/>
<path className="st17" d="M61.6,183.2L61.6,183.2l1,1L61.6,183.2z M62.5,184.1l-1-1L62.5,184.1z"/>
<path className="st16" d="M62.6,184.2L62.6,184.2l0.8,1L62.6,184.2z M63.5,185.2l-0.8-1L63.5,185.2z"/>
<path className="st17" d="M62.6,184.2L62.6,184.2l0.8,1L62.6,184.2z M63.5,185.2l-0.8-1L63.5,185.2z"/>
<path className="st16" d="M61.6,183.2L61.6,183.2l1,1L61.6,183.2z M62.5,184.1l-1-1L62.5,184.1z"/>
<path className="st17" d="M61.6,183.2L61.6,183.2l1,1L61.6,183.2z M62.5,184.1l-1-1L62.5,184.1z"/>
<path className="st16" d="M62.5,184.1l1,1.1l-0.8-1L62.5,184.1z M63.6,185.2l-1.1-1.1l1,1.1H63.6z"/>
<path className="st17" d="M62.5,184.1l1,1.1l-0.8-1L62.5,184.1z M63.6,185.2l-1.1-1.1l1,1.1H63.6z"/>
<path className="st16" d="M61.7,182.9l1.9,2.3l-2-2L61.7,182.9z M63.7,185l-2-2l1.9,2.3L63.7,185z"/>
<path className="st17" d="M61.7,182.9l1.9,2.3l-2-2L61.7,182.9z M63.7,185l-2-2l1.9,2.3L63.7,185z"/>
<polygon className="st16" points="62.8,184 62.9,184 62.8,183.9 "/>
<polygon className="st17" points="62.8,184 62.9,184 62.8,183.9 "/>
<path className="st16" d="M61.8,182.9l1,1.1l-1.1-1.1H61.8z M62,183.2l-0.2-0.2l1,1.1L62,183.2z"/>
<path className="st17" d="M61.8,182.9l1,1.1l-1.1-1.1H61.8z M62,183.2l-0.2-0.2l1,1.1L62,183.2z"/>
<path className="st16" d="M63.6,184.7l0.1,0.2l-1-1L63.6,184.7z M63.8,185l-0.2-0.2l0.1,0.2H63.8z"/>
<path className="st17" d="M63.6,184.7l0.1,0.2l-1-1L63.6,184.7z M63.8,185l-0.2-0.2l0.1,0.2H63.8z"/>
<path className="st16" d="M62.8,183.9L62.8,183.9l0.1,0.1L62.8,183.9z M62.9,184l-0.1-0.1L62.9,184z M62.6,183.8l0.2,0.2l-0.1-0.1
	L62.6,183.8z M62.6,183.8L62.6,183.8l0.2,0.2L62.6,183.8z M62.8,183.9l-0.1-0.1L62.8,183.9z M62.6,183.8l0.1,0.1L62.6,183.8z"/>
<path className="st17" d="M62.8,183.9L62.8,183.9l0.1,0.1L62.8,183.9z M62.9,184l-0.1-0.1L62.9,184z M62.6,183.8l0.2,0.2l-0.1-0.1
	L62.6,183.8z M62.6,183.8L62.6,183.8l0.2,0.2L62.6,183.8z M62.8,183.9l-0.1-0.1L62.8,183.9z M62.6,183.8l0.1,0.1L62.6,183.8z"/>
<path className="st16" d="M61.8,182.8l0.2,0.4l-0.2-0.2V182.8z M62,183l-0.2-0.2l0.2,0.4V183z"/>
<path className="st17" d="M61.8,182.8l0.2,0.4l-0.2-0.2V182.8z M62,183l-0.2-0.2l0.2,0.4V183z"/>
<path className="st16" d="M63.7,184.7l0.1,0.2l-0.2-0.2H63.7z M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z"/>
<path className="st17" d="M63.7,184.7l0.1,0.2l-0.2-0.2H63.7z M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z"/>
<path className="st16" d="M62.8,183.8v0.1l-0.1-0.1H62.8z M62.8,183.8L62.8,183.8v0.1V183.8z"/>
<path className="st17" d="M62.8,183.8v0.1l-0.1-0.1H62.8z M62.8,183.8L62.8,183.8v0.1V183.8z"/>
<path className="st16" d="M62.9,184H63H62.9z"/>
<path className="st17" d="M62.9,184H63H62.9z"/>
<path className="st16" d="M62.9,184l-0.1-0.1L62.9,184z"/>
<path className="st17" d="M62.9,184l-0.1-0.1L62.9,184z"/>
<path className="st16" d="M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z M63.8,185l-0.1-0.2L63.8,185z"/>
<path className="st17" d="M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z M63.8,185l-0.1-0.2L63.8,185z"/>
<path className="st16" d="M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z M63.7,184.7L63.7,184.7z M63.7,184.7L63.7,184.7z M63.8,185
	l-0.1-0.2L63.8,185z"/>
<path className="st17" d="M63.7,184.7L63.7,184.7l0.1,0.2L63.7,184.7z M63.7,184.7L63.7,184.7z M63.7,184.7L63.7,184.7z M63.8,185
	l-0.1-0.2L63.8,185z"/>
<path className="st16" d="M61.8,182.8L62,183L61.8,182.8z M61.9,183l-0.1-0.2L62,183H61.9z"/>
<path className="st17" d="M61.8,182.8L62,183L61.8,182.8z M61.9,183l-0.1-0.2L62,183H61.9z"/>
<path className="st16" d="M61.8,182.8L61.8,182.8l0.1,0.1L61.8,182.8z M61.9,182.9l-0.1-0.1L61.9,182.9z"/>
<path className="st17" d="M61.8,182.8L61.8,182.8l0.1,0.1L61.8,182.8z M61.9,182.9l-0.1-0.1L61.9,182.9z"/>
<path className="st16" d="M61.9,182.9L61.9,182.9v0.1V182.9z M61.9,183v-0.1V183z"/>
<path className="st17" d="M61.9,182.9L61.9,182.9v0.1V182.9z M61.9,183v-0.1V183z"/>
<path className="st16" d="M61.8,182.8L61.8,182.8l0.1,0.1L61.8,182.8z M61.9,182.9l-0.1-0.1L61.9,182.9z"/>
<path className="st17" d="M61.8,182.8L61.8,182.8l0.1,0.1L61.8,182.8z M61.9,182.9l-0.1-0.1L61.9,182.9z"/>
<path className="st16" d="M61.9,182.9L61.9,182.9v0.1V182.9z M61.9,183v-0.1V183z"/>
<path className="st17" d="M61.9,182.9L61.9,182.9v0.1V182.9z M61.9,183v-0.1V183z"/>
<path className="st16" d="M63.7,184.7L63.7,184.7z M63.7,184.7L63.7,184.7z"/>
<path className="st17" d="M63.7,184.7L63.7,184.7z M63.7,184.7L63.7,184.7z"/>
<path className="st16" d="M63.7,184.7h0.1H63.7z"/>
<path className="st17" d="M63.7,184.7h0.1H63.7z"/>
<path className="st16" d="M63.7,184.7L63.7,184.7z"/>
<path className="st17" d="M63.7,184.7L63.7,184.7z"/>
<path className="st16" d="M63.8,184.7v0.2l-0.1-0.2H63.8z M64,184.8l-0.1-0.1v0.2L64,184.8z"/>
<path className="st17" d="M63.8,184.7v0.2l-0.1-0.2H63.8z M64,184.8l-0.1-0.1v0.2L64,184.8z"/>
<path className="st16" d="M61.8,182.8l0.1,0.1L61.8,182.8z M62,182.9l-0.2-0.1l0.1,0.1H62z"/>
<path className="st17" d="M61.8,182.8l0.1,0.1L61.8,182.8z M62,182.9l-0.2-0.1l0.1,0.1H62z"/>
<path className="st16" d="M63.8,184.7L63.8,184.7z"/>
<path className="st17" d="M63.8,184.7L63.8,184.7z"/>
<path className="st16" d="M63.8,184.7h-0.1H63.8z"/>
<path className="st17" d="M63.8,184.7h-0.1H63.8z"/>
<path className="st16" d="M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7z
	 M63.8,184.7L63.8,184.7z M64,184.8l-0.1-0.1L64,184.8z M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7
	z M64,184.8l-0.1-0.1L64,184.8z"/>
<path className="st17" d="M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7z
	 M63.8,184.7L63.8,184.7z M64,184.8l-0.1-0.1L64,184.8z M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7
	z M64,184.8l-0.1-0.1L64,184.8z"/>
<path className="st16" d="M64,184.8l-0.1-0.1L64,184.8z M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7z
	 M63.8,184.7L63.8,184.7z M63.8,184.7L63.8,184.7z M64,184.8l-0.1-0.1L64,184.8z"/>
<path className="st17" d="M64,184.8l-0.1-0.1L64,184.8z M63.8,184.7l0.1,0.1L63.8,184.7z M63.8,184.7L63.8,184.7l0.1,0.1L63.8,184.7z
	 M63.8,184.7L63.8,184.7z M63.8,184.7L63.8,184.7z M64,184.8l-0.1-0.1L64,184.8z"/>
<path className="st16" d="M61.8,182.8L61.8,182.8l0.2,0.1L61.8,182.8z M62,182.9l-0.2-0.1L62,182.9z"/>
<path className="st17" d="M61.8,182.8L61.8,182.8l0.2,0.1L61.8,182.8z M62,182.9l-0.2-0.1L62,182.9z"/>
<path className="st16" d="M62,182.9L62,182.9h-0.1H62z M61.9,182.9H62H61.9z"/>
<path className="st17" d="M62,182.9L62,182.9h-0.1H62z M61.9,182.9H62H61.9z"/>
<path className="st16" d="M63.8,184.7v-0.1V184.7z"/>
<path className="st17" d="M63.8,184.7v-0.1V184.7z"/>
<path className="st16" d="M62,182.9v-0.1V182.9z"/>
<path className="st17" d="M62,182.9v-0.1V182.9z"/>
<path className="st16" d="M62,182.9L62,182.9z M62,182.9L62,182.9z"/>
<path className="st17" d="M62,182.9L62,182.9z M62,182.9L62,182.9z"/>
<path className="st16" d="M62.8,183.6L62.8,183.6v0.1V183.6z M62.8,183.8v-0.1V183.8z"/>
<path className="st17" d="M62.8,183.6L62.8,183.6v0.1V183.6z M62.8,183.8v-0.1V183.8z"/>
<path className="st16" d="M63,183.9v0.1h-0.1L63,183.9z M63.1,184l-0.1-0.1v0.1H63.1z"/>
<path className="st17" d="M63,183.9v0.1h-0.1L63,183.9z M63.1,184l-0.1-0.1v0.1H63.1z"/>
<path className="st16" d="M63.8,184.7L63.8,184.7z M63.8,184.7L63.8,184.7z"/>
<path className="st17" d="M63.8,184.7L63.8,184.7z M63.8,184.7L63.8,184.7z"/>
<path className="st16" d="M61.9,182.7l0.1,0.2l-0.2-0.1L61.9,182.7z M62,182.8l-0.1-0.1l0.1,0.2V182.8z"/>
<path className="st17" d="M61.9,182.7l0.1,0.2l-0.2-0.1L61.9,182.7z M62,182.8l-0.1-0.1l0.1,0.2V182.8z"/>
<path className="st16" d="M63.8,184.6l0.1,0.2l-0.1-0.1V184.6z M63.8,184.6L63.8,184.6l0.1,0.2L63.8,184.6z M64,184.8l-0.1-0.2L64,184.8
	z M63.8,184.6l0.1,0.2L63.8,184.6z"/>
<path className="st17" d="M63.8,184.6l0.1,0.2l-0.1-0.1V184.6z M63.8,184.6L63.8,184.6l0.1,0.2L63.8,184.6z M64,184.8l-0.1-0.2L64,184.8
	z M63.8,184.6l0.1,0.2L63.8,184.6z"/>
<path className="st16" d="M62,182.8l-0.1-0.1L62,182.8z M61.9,182.7l0.1,0.1L61.9,182.7z M61.9,182.7L61.9,182.7l0.1,0.1L61.9,182.7z
	 M62,182.8l-0.1-0.1L62,182.8z"/>
<path className="st17" d="M62,182.8l-0.1-0.1L62,182.8z M61.9,182.7l0.1,0.1L61.9,182.7z M61.9,182.7L61.9,182.7l0.1,0.1L61.9,182.7z
	 M62,182.8l-0.1-0.1L62,182.8z"/>
<path className="st16" d="M63.8,184.6L63.8,184.6l0.1,0.2L63.8,184.6z M64,184.8l-0.1-0.2L64,184.8z"/>
<path className="st17" d="M63.8,184.6L63.8,184.6l0.1,0.2L63.8,184.6z M64,184.8l-0.1-0.2L64,184.8z"/>
<path className="st16" d="M61.9,182.7L61.9,182.7l0.1,0.1L61.9,182.7z M62,182.8l-0.1-0.1L62,182.8z"/>
<path className="st17" d="M61.9,182.7L61.9,182.7l0.1,0.1L61.9,182.7z M62,182.8l-0.1-0.1L62,182.8z"/>
<path className="st16" d="M63.8,184.6l0.1,0.2L63.8,184.6z M64.1,184.7l-0.2-0.1l0.1,0.2L64.1,184.7z"/>
<path className="st17" d="M63.8,184.6l0.1,0.2L63.8,184.6z M64.1,184.7l-0.2-0.1l0.1,0.2L64.1,184.7z"/>
<path className="st16" d="M62.9,183.5l-0.1,0.1L62.9,183.5z M62.9,183.6v-0.1l-0.1,0.1H62.9z"/>
<path className="st17" d="M62.9,183.5l-0.1,0.1L62.9,183.5z M62.9,183.6v-0.1l-0.1,0.1H62.9z"/>
<path className="st16" d="M63.1,183.8v0.2l-0.1-0.1L63.1,183.8z M63.1,183.9v-0.1v0.2V183.9z"/>
<path className="st17" d="M63.1,183.8v0.2l-0.1-0.1L63.1,183.8z M63.1,183.9v-0.1v0.2V183.9z"/>
<path className="st16" d="M62.9,183.5v0.1V183.5z M63,183.6l-0.1-0.1v0.1H63z"/>
<path className="st17" d="M62.9,183.5v0.1V183.5z M63,183.6l-0.1-0.1v0.1H63z"/>
<path className="st16" d="M63.1,183.8l-0.1-0.1l0.1,0.2V183.8z M63.1,183.9v-0.1l-0.1-0.1L63.1,183.9z"/>
<path className="st17" d="M63.1,183.8l-0.1-0.1l0.1,0.2V183.8z M63.1,183.9v-0.1l-0.1-0.1L63.1,183.9z"/>
<polygon className="st16" points="63.1,183.8 62.9,183.5 63.1,183.6 "/>
<polygon className="st17" points="63.1,183.8 62.9,183.5 63.1,183.6 "/>
<path className="st16" d="M62.9,183.5L62.9,183.5l0.1,0.1L62.9,183.5z M63,183.6l-0.1-0.1L63,183.6z"/>
<path className="st17" d="M62.9,183.5L62.9,183.5l0.1,0.1L62.9,183.5z M63,183.6l-0.1-0.1L63,183.6z"/>
<path className="st16" d="M63,183.6L63,183.6l0.1,0.1L63,183.6z M63.1,183.8l-0.1-0.1L63.1,183.8z"/>
<path className="st17" d="M63,183.6L63,183.6l0.1,0.1L63,183.6z M63.1,183.8l-0.1-0.1L63.1,183.8z"/>
<path className="st16" d="M62.2,182.6l-0.1,0.2l-0.1-0.1L62.2,182.6z M62.4,182.7l-0.2-0.1l-0.1,0.2L62.4,182.7z"/>
<path className="st17" d="M62.2,182.6l-0.1,0.2l-0.1-0.1L62.2,182.6z M62.4,182.7l-0.2-0.1l-0.1,0.2L62.4,182.7z"/>
<path className="st16" d="M64,184.4l0.1,0.4l-0.2-0.1L64,184.4z M64.2,184.6l-0.2-0.2l0.1,0.4L64.2,184.6z"/>
<path className="st17" d="M64,184.4l0.1,0.4l-0.2-0.1L64,184.4z M64.2,184.6l-0.2-0.2l0.1,0.4L64.2,184.6z"/>
<path className="st16" d="M62.4,182.4v0.2l-0.2-0.1L62.4,182.4z M62.6,182.7l-0.2-0.2v0.2H62.6z"/>
<path className="st17" d="M62.4,182.4v0.2l-0.2-0.1L62.4,182.4z M62.6,182.7l-0.2-0.2v0.2H62.6z"/>
<path className="st16" d="M64.1,184.1l0.1,0.5l-0.2-0.2L64.1,184.1z M64.2,184.4l-0.1-0.2l0.1,0.5V184.4z"/>
<path className="st17" d="M64.1,184.1l0.1,0.5l-0.2-0.2L64.1,184.1z M64.2,184.4l-0.1-0.2l0.1,0.5V184.4z"/>
<path className="st16" d="M62.6,182.4v0.2l-0.2-0.2H62.6z M62.9,182.6l-0.2-0.1v0.2L62.9,182.6z"/>
<path className="st17" d="M62.6,182.4v0.2l-0.2-0.2H62.6z M62.9,182.6l-0.2-0.1v0.2L62.9,182.6z"/>
<path className="st16" d="M64.1,183.9l0.1,0.5l-0.1-0.2V183.9z M64.3,184l-0.2-0.1l0.1,0.5L64.3,184z"/>
<path className="st17" d="M64.1,183.9l0.1,0.5l-0.1-0.2V183.9z M64.3,184l-0.2-0.1l0.1,0.5L64.3,184z"/>
<path className="st16" d="M62.6,182.4L62.6,182.4l0.2,0.1L62.6,182.4z M62.9,182.6l-0.2-0.1L62.9,182.6z"/>
<path className="st17" d="M62.6,182.4L62.6,182.4l0.2,0.1L62.6,182.4z M62.9,182.6l-0.2-0.1L62.9,182.6z"/>
<path className="st16" d="M64.1,183.9L64.1,183.9l0.2,0.1L64.1,183.9z M64.3,184l-0.2-0.1L64.3,184z"/>
<path className="st17" d="M64.1,183.9L64.1,183.9l0.2,0.1L64.1,183.9z M64.3,184l-0.2-0.1L64.3,184z"/>
<path className="st16" d="M62.9,182.4v0.1l-0.2-0.1H62.9z M63.4,182.8l-0.5-0.4v0.1L63.4,182.8z"/>
<path className="st17" d="M62.9,182.4v0.1l-0.2-0.1H62.9z M63.4,182.8l-0.5-0.4v0.1L63.4,182.8z"/>
<path className="st16" d="M64,183.4l0.4,0.6l-0.2-0.1L64,183.4z M64.3,183.8l-0.4-0.4l0.4,0.6V183.8z"/>
<path className="st17" d="M64,183.4l0.4,0.6l-0.2-0.1L64,183.4z M64.3,183.8l-0.4-0.4l0.4,0.6V183.8z"/>
<path className="st16" d="M63.1,182.4l0.2,0.4l-0.5-0.4H63.1z M63.7,183l-0.6-0.6l0.2,0.4L63.7,183z"/>
<path className="st17" d="M63.1,182.4l0.2,0.4l-0.5-0.4H63.1z M63.7,183l-0.6-0.6l0.2,0.4L63.7,183z"/>
<path className="st16" d="M63.7,183l0.6,0.7l-0.4-0.4L63.7,183z M64.3,183.5l-0.6-0.5l0.6,0.7V183.5z"/>
<path className="st17" d="M63.7,183l0.6,0.7l-0.4-0.4L63.7,183z M64.3,183.5l-0.6-0.5l0.6,0.7V183.5z"/>
<path className="st16" d="M63.2,182.4l1.1,1.1l-1.2-1.1H63.2z M64.2,183.5l-1-1.1l1.1,1.1H64.2z"/>
<path className="st17" d="M63.2,182.4l1.1,1.1l-1.2-1.1H63.2z M64.2,183.5l-1-1.1l1.1,1.1H64.2z"/>
<path className="st16" d="M63.5,182.6l0.7,1l-1-1.1L63.5,182.6z M64.2,183.3l-0.7-0.7l0.7,1V183.3z"/>
<path className="st17" d="M63.5,182.6l0.7,1l-1-1.1L63.5,182.6z M64.2,183.3l-0.7-0.7l0.7,1V183.3z"/>
<path className="st16" d="M64.1,183l-0.5-0.4l0.2,0.1L64.1,183z M64.2,183.3l-0.1-0.2l-0.5-0.4L64.2,183.3z"/>
<path className="st17" d="M64.1,183l-0.5-0.4l0.2,0.1L64.1,183z M64.2,183.3l-0.1-0.2l-0.5-0.4L64.2,183.3z"/>
<polygon className="st16" points="64.2,183.3 63.5,182.6 63.6,182.7 "/>
<polygon className="st17" points="64.2,183.3 63.5,182.6 63.6,182.7 "/>
<path className="st18" d="M62.2,184.3c0.3,0.3,0.6,0.5,0.9,0.7"/>
<path className="st18" d="M63.2,185c0.1-0.1,0.3-0.2,0.4-0.3"/>
<path className="st18" d="M63.7,184.7c0,0,0.1-0.1,0.1-0.1"/>
<path className="st18" d="M63.1,185.1c0.1,0,0.1,0,0.2,0"/>
<path className="st18" d="M61.9,182.9c-0.1,0.1-0.2,0.3-0.3,0.4"/>
<path className="st18" d="M62,182.7c0,0-0.1,0.1-0.1,0.1"/>
<path className="st18" d="M61.5,183.4c0.2,0.3,0.4,0.6,0.7,0.9"/>
<path className="st18" d="M61.6,183.2c0,0.1,0,0.1,0,0.2"/>
<path className="st18" d="M62,182.8c-0.1,0.1-0.1,0.1,0,0.2"/>
<path className="st18" d="M61.9,183c0.4,0.6,1,1.2,1.6,1.6"/>
<path className="st18" d="M63.6,184.6c0.1,0,0.2,0,0.2,0"/>
<polyline className="st18" points="63.8,184.6 64,184.6 64.1,184.4 64.1,184.2 64.1,184 64.1,183.8 64.1,183.6 64.1,183.4 64,183.3 
	63.7,183 63.6,182.9 63.5,182.8 63.2,182.7 63.1,182.6 62.9,182.6 62.6,182.6 62.5,182.7 62.3,182.7 62.2,182.8 62,182.8 "/>
<polygon className="st20" points="62,182.2 61.6,182.3 61.4,182.8 61.6,183.3 62,183.4 62.5,183.3 62.6,182.8 62.5,182.3 "/>
<polygon className="st4" points="62,182.2 61.6,182.3 61.4,182.8 61.6,183.3 62,183.4 62.5,183.3 62.6,182.8 62.5,182.3 "/>
<polygon className="st20" points="62,182.6 61.6,182.7 61.4,183.2 61.6,183.6 62,183.8 62.5,183.6 62.6,183.2 62.5,182.7 "/>
<polygon className="st4" points="62,182.6 61.6,182.7 61.4,183.2 61.6,183.6 62,183.8 62.5,183.6 62.6,183.2 62.5,182.7 "/>
<polygon className="st20" points="63.6,184.1 63.1,184.2 63,184.7 63.1,185.2 63.6,185.3 64.1,185.2 64.2,184.7 64.1,184.2 "/>
<polygon className="st4" points="63.6,184.1 63.1,184.2 63,184.7 63.1,185.2 63.6,185.3 64.1,185.2 64.2,184.7 64.1,184.2 "/>
<polygon className="st20" points="63.8,184 63.4,184.1 63.2,184.6 63.4,185.1 63.8,185.2 64.3,185.1 64.4,184.6 64.3,184.1 "/>
<polygon className="st4" points="63.8,184 63.4,184.1 63.2,184.6 63.4,185.1 63.8,185.2 64.3,185.1 64.4,184.6 64.3,184.1 "/>
<polygon className="st20" points="63.7,182.4 63.2,182.6 63.1,183 63.2,183.5 63.7,183.6 64.2,183.5 64.3,183 64.2,182.6 "/>
<polygon className="st4" points="63.7,182.4 63.2,182.6 63.1,183 63.2,183.5 63.7,183.6 64.2,183.5 64.3,183 64.2,182.6 "/>
<path className="st18" d="M61.9,182.6c-0.1,0-0.1,0.1-0.1,0.1"/>
<path className="st18" d="M61.7,182.8c-0.2,0.2-0.3,0.5-0.5,0.7"/>
<polyline className="st18" points="61.3,183.5 62.3,184.5 63.1,185.4 "/>
<path className="st18" d="M63.1,185.4c0.2-0.1,0.5-0.3,0.7-0.5"/>
<path className="st18" d="M63.7,184.8c0.1,0,0.1-0.1,0.1-0.1"/>
<polyline className="st18" points="64.1,184.7 64.3,184.1 64.2,183.4 63.8,182.8 63.2,182.4 62.5,182.4 61.9,182.7 "/>
<polygon className="st20" points="61.9,182.1 61.4,182.2 61.3,182.7 61.4,183.2 61.9,183.3 62.4,183.2 62.5,182.7 62.4,182.2 "/>
<polygon className="st4" points="61.9,182.1 61.4,182.2 61.3,182.7 61.4,183.2 61.9,183.3 62.4,183.2 62.5,182.7 62.4,182.2 "/>
<polygon className="st20" points="61.8,182.2 61.3,182.3 61.2,182.8 61.3,183.3 61.8,183.4 62.3,183.3 62.4,182.8 62.3,182.3 "/>
<polygon className="st4" points="61.8,182.2 61.3,182.3 61.2,182.8 61.3,183.3 61.8,183.4 62.3,183.3 62.4,182.8 62.3,182.3 "/>
<line className="st18" x1="61.3" y1="183.5" x2="61.3" y2="183.5"/>
<polygon className="st20" points="62.3,183.9 61.8,184 61.7,184.5 61.8,185 62.3,185.1 62.8,185 62.9,184.5 62.8,184 "/>
<polygon className="st4" points="62.3,183.9 61.8,184 61.7,184.5 61.8,185 62.3,185.1 62.8,185 62.9,184.5 62.8,184 "/>
<line className="st18" x1="63.1" y1="185.4" x2="63.1" y2="185.4"/>
<polygon className="st20" points="63.8,184.4 63.4,184.5 63.2,185 63.4,185.4 63.8,185.6 64.3,185.4 64.4,185 64.3,184.5 "/>
<polygon className="st4" points="63.8,184.4 63.4,184.5 63.2,185 63.4,185.4 63.8,185.6 64.3,185.4 64.4,185 64.3,184.5 "/>
<polygon className="st20" points="64.1,184.1 63.6,184.2 63.5,184.7 63.6,185.2 64.1,185.3 64.6,185.2 64.7,184.7 64.6,184.2 "/>
<polygon className="st4" points="64.1,184.1 63.6,184.2 63.5,184.7 63.6,185.2 64.1,185.3 64.6,185.2 64.7,184.7 64.6,184.2 "/>
<polygon className="st20" points="63.8,182.2 63.4,182.3 63.2,182.8 63.4,183.3 63.8,183.4 64.3,183.3 64.4,182.8 64.3,182.3 "/>
<polygon className="st4" points="63.8,182.2 63.4,182.3 63.2,182.8 63.4,183.3 63.8,183.4 64.3,183.3 64.4,182.8 64.3,182.3 "/>
<path className="st18" d="M62.7,183.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C62.6,183.6,62.6,183.8,62.7,183.8"/>
<polygon className="st20" points="62.8,183 62.3,183.2 62.2,183.6 62.3,184.1 62.8,184.2 63.2,184.1 63.4,183.6 63.2,183.2 "/>
<polygon className="st4" points="62.8,183 62.3,183.2 62.2,183.6 62.3,184.1 62.8,184.2 63.2,184.1 63.4,183.6 63.2,183.2 "/>
<path className="st18" d="M62.7,183.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C62.6,183.6,62.6,183.8,62.7,183.8"/>
<polygon className="st20" points="62.8,183 62.3,183.2 62.2,183.6 62.3,184.1 62.8,184.2 63.2,184.1 63.4,183.6 63.2,183.2 "/>
<polygon className="st4" points="62.8,183 62.3,183.2 62.2,183.6 62.3,184.1 62.8,184.2 63.2,184.1 63.4,183.6 63.2,183.2 "/>
<path className="st18" d="M62.4,184.2C62.4,184.2,62.5,184.2,62.4,184.2C62.5,184.2,62.5,184.2,62.4,184.2
	C62.5,184.1,62.4,184.1,62.4,184.2C62.4,184.2,62.4,184.2,62.4,184.2"/>
<polygon className="st20" points="62.4,183.5 61.9,183.6 61.8,184.1 61.9,184.6 62.4,184.7 62.9,184.6 63,184.1 62.9,183.6 "/>
<polygon className="st4" points="62.4,183.5 61.9,183.6 61.8,184.1 61.9,184.6 62.4,184.7 62.9,184.6 63,184.1 62.9,183.6 "/>
<polyline className="st1" points="58.4,190.2 62.6,186 59.2,191 "/>
<path className="st16" d="M61.4,187.4l-1.7,1.7L61.4,187.4z M59.8,189l1.7-1.7L59.8,189z M59.8,189L59.8,189l1.7-1.7L59.8,189z
	 M61.4,187.4l-1.7,1.7L61.4,187.4z"/>
<path className="st17" d="M61.4,187.4l-1.7,1.7L61.4,187.4z M59.8,189l1.7-1.7L59.8,189z M59.8,189L59.8,189l1.7-1.7L59.8,189z
	 M61.4,187.4l-1.7,1.7L61.4,187.4z"/>
<path className="st16" d="M59.6,189l1.8-1.7l-1.7,1.7H59.6z M61.4,187.2l-1.8,1.8l1.8-1.7V187.2z M59.6,189l1.8-1.8L59.6,189z M59.6,189
	L59.6,189l1.8-1.8L59.6,189z"/>
<path className="st17" d="M59.6,189l1.8-1.7l-1.7,1.7H59.6z M61.4,187.2l-1.8,1.8l1.8-1.7V187.2z M59.6,189l1.8-1.8L59.6,189z M59.6,189
	L59.6,189l1.8-1.8L59.6,189z"/>
<path className="st16" d="M58.6,188l2.9-0.7l-1.8,1.8L58.6,188z M60.4,186.2l-1.8,1.8l2.9-0.7L60.4,186.2z"/>
<path className="st17" d="M58.6,188l2.9-0.7l-1.8,1.8L58.6,188z M60.4,186.2l-1.8,1.8l2.9-0.7L60.4,186.2z"/>
<path className="st16" d="M58.4,187.7l1-0.6l-0.8,0.8L58.4,187.7z M58.8,187.4l-0.4,0.4l1-0.6L58.8,187.4z"/>
<path className="st17" d="M58.4,187.7l1-0.6l-0.8,0.8L58.4,187.7z M58.8,187.4l-0.4,0.4l1-0.6L58.8,187.4z"/>
<path className="st16" d="M59.8,186.5l0.6-0.4l-1,1L59.8,186.5z M60.1,186l-0.4,0.5l0.6-0.4L60.1,186z"/>
<path className="st17" d="M59.8,186.5l0.6-0.4l-1,1L59.8,186.5z M60.1,186l-0.4,0.5l0.6-0.4L60.1,186z"/>
<path className="st16" d="M58.4,187.7L58.4,187.7l0.4-0.4L58.4,187.7z M58.8,187.4l-0.4,0.4L58.8,187.4z"/>
<path className="st17" d="M58.4,187.7L58.4,187.7l0.4-0.4L58.4,187.7z M58.8,187.4l-0.4,0.4L58.8,187.4z"/>
<path className="st16" d="M59,187l0.2-0.4l-0.2,0.2V187z M59,187L59,187l0.2-0.4L59,187z M59.3,186.6L59,187L59.3,186.6z M59,187
	l0.2-0.4L59,187z"/>
<path className="st17" d="M59,187l0.2-0.4l-0.2,0.2V187z M59,187L59,187l0.2-0.4L59,187z M59.3,186.6L59,187L59.3,186.6z M59,187
	l0.2-0.4L59,187z"/>
<path className="st16" d="M58.9,186.9l0.4-0.2L59,187L58.9,186.9z M59.3,186.5l-0.4,0.4l0.4-0.2V186.5z M58.9,186.9l0.4-0.4L58.9,186.9z
	 M58.9,186.9L58.9,186.9l0.4-0.4L58.9,186.9z"/>
<path className="st17" d="M58.9,186.9l0.4-0.2L59,187L58.9,186.9z M59.3,186.5l-0.4,0.4l0.4-0.2V186.5z M58.9,186.9l0.4-0.4L58.9,186.9z
	 M58.9,186.9L58.9,186.9l0.4-0.4L58.9,186.9z"/>
<path className="st16" d="M59.2,186.4l-0.5,0.4l0.2-0.2L59.2,186.4z M59.3,186.5l-0.1-0.1l-0.5,0.4L59.3,186.5z"/>
<path className="st17" d="M59.2,186.4l-0.5,0.4l0.2-0.2L59.2,186.4z M59.3,186.5l-0.1-0.1l-0.5,0.4L59.3,186.5z"/>
<polygon className="st16" points="59.3,186.5 58.9,186.9 58.7,186.8 "/>
<polygon className="st17" points="59.3,186.5 58.9,186.9 58.7,186.8 "/>
<path className="st16" d="M59.5,185.9l0.6,0.1l-0.4,0.5L59.5,185.9z M59.8,185.7l-0.2,0.2l0.6,0.1L59.8,185.7z"/>
<path className="st17" d="M59.5,185.9l0.6,0.1l-0.4,0.5L59.5,185.9z M59.8,185.7l-0.2,0.2l0.6,0.1L59.8,185.7z"/>
<path className="st16" d="M58,187.4h0.8l-0.4,0.4L58,187.4z M58.2,187.1l-0.2,0.2h0.8L58.2,187.1z"/>
<path className="st17" d="M58,187.4h0.8l-0.4,0.4L58,187.4z M58.2,187.1l-0.2,0.2h0.8L58.2,187.1z"/>
<path className="st16" d="M57.6,187l0.6,0.1l-0.2,0.2L57.6,187z M57.8,186.8l-0.2,0.2l0.6,0.1L57.8,186.8z"/>
<path className="st17" d="M57.6,187l0.6,0.1l-0.2,0.2L57.6,187z M57.8,186.8l-0.2,0.2l0.6,0.1L57.8,186.8z"/>
<path className="st16" d="M59.2,185.4l0.6,0.2l-0.2,0.2L59.2,185.4z M59.4,185.3l-0.2,0.1l0.6,0.2L59.4,185.3z"/>
<path className="st17" d="M59.2,185.4l0.6,0.2l-0.2,0.2L59.2,185.4z M59.4,185.3l-0.2,0.1l0.6,0.2L59.4,185.3z"/>
<path className="st16" d="M58.6,185.2l0.8,0.1l-0.2,0.1L58.6,185.2z M58.8,185l-0.2,0.2l0.8,0.1L58.8,185z"/>
<path className="st17" d="M58.6,185.2l0.8,0.1l-0.2,0.1L58.6,185.2z M58.8,185l-0.2,0.2l0.8,0.1L58.8,185z"/>
<path className="st16" d="M57.4,186.4l0.5,0.4l-0.2,0.2L57.4,186.4z M57.6,186.2l-0.2,0.2l0.5,0.4L57.6,186.2z M57.4,186.4l0.2-0.2
	L57.4,186.4z M57.4,186.4L57.4,186.4l0.2-0.2L57.4,186.4z"/>
<path className="st17" d="M57.4,186.4l0.5,0.4l-0.2,0.2L57.4,186.4z M57.6,186.2l-0.2,0.2l0.5,0.4L57.6,186.2z M57.4,186.4l0.2-0.2
	L57.4,186.4z M57.4,186.4L57.4,186.4l0.2-0.2L57.4,186.4z"/>
<path className="st16" d="M58.6,185.2L58.6,185.2l0.2-0.2L58.6,185.2z M58.8,185l-0.2,0.2L58.8,185z"/>
<path className="st17" d="M58.6,185.2L58.6,185.2l0.2-0.2L58.6,185.2z M58.8,185l-0.2,0.2L58.8,185z"/>
<path className="st16" d="M57.4,186l0.2,0.1l-0.2,0.2V186z M57.8,185.4l-0.5,0.6l0.2,0.1L57.8,185.4z"/>
<path className="st17" d="M57.4,186l0.2,0.1l-0.2,0.2V186z M57.8,185.4l-0.5,0.6l0.2,0.1L57.8,185.4z"/>
<path className="st16" d="M57.8,185.4l1-0.5l-0.2,0.2L57.8,185.4z M58.4,185l-0.6,0.5l1-0.5H58.4z"/>
<path className="st17" d="M57.8,185.4l1-0.5l-0.2,0.2L57.8,185.4z M58.4,185l-0.6,0.5l1-0.5H58.4z"/>
<path className="st16" d="M57.4,185.8l1.1-0.8l-1.1,1.1V185.8z M58.2,185l-0.8,0.8l1.1-0.8H58.2z M57.4,185.8l0.8-0.8L57.4,185.8z
	 M57.4,185.8L57.4,185.8l0.8-0.8L57.4,185.8z"/>
<path className="st17" d="M57.4,185.8l1.1-0.8l-1.1,1.1V185.8z M58.2,185l-0.8,0.8l1.1-0.8H58.2z M57.4,185.8l0.8-0.8L57.4,185.8z
	 M57.4,185.8L57.4,185.8l0.8-0.8L57.4,185.8z"/>
<path className="st16" d="M58.1,185.1l-0.7,0.6l0.4-0.5L58.1,185.1z M58.2,185l-0.1,0.1l-0.7,0.6L58.2,185z"/>
<path className="st17" d="M58.1,185.1l-0.7,0.6l0.4-0.5L58.1,185.1z M58.2,185l-0.1,0.1l-0.7,0.6L58.2,185z"/>
<polygon className="st16" points="58.2,185 57.4,185.8 57.4,185.7 "/>
<polygon className="st17" points="58.2,185 57.4,185.8 57.4,185.7 "/>
<path className="st19" d="M59.7,188.9C59.7,188.9,59.7,188.9,59.7,188.9"/>
<line className="st19" x1="59.8" y1="189" x2="61.4" y2="187.4"/>
<path className="st19" d="M61.3,187.3C61.3,187.3,61.3,187.3,61.3,187.3"/>
<line className="st19" x1="61.4" y1="187.2" x2="59.4" y2="185.3"/>
<path className="st19" d="M59.4,185.2c-0.5-0.5-1.3-0.5-1.8,0c-0.5,0.5-0.5,1.3,0,1.8"/>
<line className="st19" x1="57.6" y1="187" x2="59.6" y2="189"/>
<path className="st19" d="M58.9,186.9C59,186.9,59,186.9,58.9,186.9"/>
<line className="st19" x1="58.9" y1="186.9" x2="58.7" y2="186.8"/>
<path className="st19" d="M59,186.3c-0.2,0-0.4,0.2-0.4,0.4"/>
<line className="st19" x1="59.2" y1="186.4" x2="59.3" y2="186.5"/>
<path className="st19" d="M59.3,186.6C59.3,186.6,59.3,186.6,59.3,186.6"/>
<line className="st19" x1="59.3" y1="186.6" x2="59" y2="186.9"/>
<line className="st19" x1="59.5" y1="185.9" x2="59.2" y2="185.4"/>
<path className="st19" d="M59.1,185.5c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3"/>
<line className="st19" x1="57.8" y1="186.8" x2="58.2" y2="187.1"/>
<path className="st19" d="M58.2,187c0.4,0.3,0.9,0.3,1.2,0c0.3-0.3,0.4-0.9,0-1.2"/>
<path className="st16" d="M74.5,153.6l1.7-1.8l-1.4,1.3L74.5,153.6z M76.7,151.6l-2.2,2l1.7-1.8L76.7,151.6z M74.5,153.6l2.2-2
	L74.5,153.6z M74.5,153.6L74.5,153.6l2.2-2L74.5,153.6z"/>
<path className="st17" d="M74.5,153.6l1.7-1.8l-1.4,1.3L74.5,153.6z M76.7,151.6l-2.2,2l1.7-1.8L76.7,151.6z M74.5,153.6l2.2-2
	L74.5,153.6z M74.5,153.6L74.5,153.6l2.2-2L74.5,153.6z"/>
<path className="st16" d="M74.5,153.9l2.2-2.3l-2.2,2V153.9z M76.9,151.6l-2.4,2.3l2.2-2.3H76.9z"/>
<path className="st17" d="M74.5,153.9l2.2-2.3l-2.2,2V153.9z M76.9,151.6l-2.4,2.3l2.2-2.3H76.9z"/>
<path className="st16" d="M74.5,154.1l0.5-0.7l-0.5,0.5V154.1z M74.9,153.8l-0.4,0.4l0.5-0.7L74.9,153.8z"/>
<path className="st17" d="M74.5,154.1l0.5-0.7l-0.5,0.5V154.1z M74.9,153.8l-0.4,0.4l0.5-0.7L74.9,153.8z"/>
<path className="st16" d="M74.9,153.8l1.6-1.7l-1.4,1.3L74.9,153.8z M76.8,152l-1.9,1.8l1.6-1.7L76.8,152z"/>
<path className="st17" d="M74.9,153.8l1.6-1.7l-1.4,1.3L74.9,153.8z M76.8,152l-1.9,1.8l1.6-1.7L76.8,152z"/>
<path className="st16" d="M74.5,154.2l0.4-0.5l-0.4,0.4V154.2z M74.9,153.9l-0.4,0.4l0.4-0.5V153.9z"/>
<path className="st17" d="M74.5,154.2l0.4-0.5l-0.4,0.4V154.2z M74.9,153.9l-0.4,0.4l0.4-0.5V153.9z"/>
<path className="st16" d="M74.9,153.9l1.9-1.9l-1.9,1.8V153.9z M76.8,151.8l-1.9,2l1.9-1.9V151.8z"/>
<path className="st17" d="M74.9,153.9l1.9-1.9l-1.9,1.8V153.9z M76.8,151.8l-1.9,2l1.9-1.9V151.8z"/>
<path className="st16" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st17" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st16" d="M74.9,153.9l1.9-2L74.9,153.9z M76.9,151.8l-2,2l1.9-2H76.9z"/>
<path className="st17" d="M74.9,153.9l1.9-2L74.9,153.9z M76.9,151.8l-2,2l1.9-2H76.9z"/>
<path className="st16" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st17" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st16" d="M74.9,153.9L74.9,153.9l2-2L74.9,153.9z M76.9,151.8l-2,2L76.9,151.8z"/>
<path className="st17" d="M74.9,153.9L74.9,153.9l2-2L74.9,153.9z M76.9,151.8l-2,2L76.9,151.8z"/>
<path className="st16" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st17" d="M74.5,154.2L74.5,154.2l0.4-0.4L74.5,154.2z M74.9,153.9l-0.4,0.4L74.9,153.9z"/>
<path className="st16" d="M74.9,154l2-2.2l-2,2V154z M74.9,154L74.9,154l2-2.2L74.9,154z"/>
<path className="st17" d="M74.9,154l2-2.2l-2,2V154z M74.9,154L74.9,154l2-2.2L74.9,154z"/>
<path className="st16" d="M76.9,151.8v-0.2l-0.5,0.5L76.9,151.8z M77.3,151.6l-0.4,0.2v-0.2H77.3z"/>
<path className="st17" d="M76.9,151.8v-0.2l-0.5,0.5L76.9,151.8z M77.3,151.6l-0.4,0.2v-0.2H77.3z"/>
<path className="st16" d="M74.9,154L74.9,154l2-2.2L74.9,154z M76.9,151.8l-2,2.2L76.9,151.8z"/>
<path className="st17" d="M74.9,154L74.9,154l2-2.2L74.9,154z M76.9,151.8l-2,2.2L76.9,151.8z"/>
<path className="st16" d="M76.9,151.8L76.9,151.8l0.4-0.2L76.9,151.8z M77.3,151.6l-0.4,0.2L77.3,151.6z"/>
<path className="st17" d="M76.9,151.8L76.9,151.8l0.4-0.2L76.9,151.8z M77.3,151.6l-0.4,0.2L77.3,151.6z"/>
<polygon className="st16" points="74.3,155 74.8,154.6 74.3,155.1 "/>
<polygon className="st17" points="74.3,155 74.8,154.6 74.3,155.1 "/>
<path className="st16" d="M75,154.2l1.9-2.4l-2,2.2L75,154.2z M77.3,152l-2.3,2.3l1.9-2.4L77.3,152z"/>
<path className="st17" d="M75,154.2l1.9-2.4l-2,2.2L75,154.2z M77.3,152l-2.3,2.3l1.9-2.4L77.3,152z"/>
<path className="st16" d="M77.3,152v-0.4l-0.4,0.2L77.3,152z M77.5,151.7l-0.2,0.2v-0.4L77.5,151.7z"/>
<path className="st17" d="M77.3,152v-0.4l-0.4,0.2L77.3,152z M77.5,151.7l-0.2,0.2v-0.4L77.5,151.7z"/>
<path className="st16" d="M74.3,155.2l0.5-0.6l-0.5,0.5V155.2z M74.3,155.2L74.3,155.2l0.5-0.6L74.3,155.2z M74.8,154.6l-0.5,0.6
	L74.8,154.6z M74.3,155.2l0.5-0.6L74.3,155.2z"/>
<path className="st17" d="M74.3,155.2l0.5-0.6l-0.5,0.5V155.2z M74.3,155.2L74.3,155.2l0.5-0.6L74.3,155.2z M74.8,154.6l-0.5,0.6
	L74.8,154.6z M74.3,155.2l0.5-0.6L74.3,155.2z"/>
<path className="st16" d="M74.8,154.6l0.1-0.7l-0.4,0.4L74.8,154.6z M75,154.4l-0.2,0.2l0.1-0.7L75,154.4z"/>
<path className="st17" d="M74.8,154.6l0.1-0.7l-0.4,0.4L74.8,154.6z M75,154.4l-0.2,0.2l0.1-0.7L75,154.4z"/>
<path className="st16" d="M74.3,155.2L74.3,155.2l0.5-0.6L74.3,155.2z M74.8,154.6l-0.5,0.6L74.8,154.6z"/>
<path className="st17" d="M74.3,155.2L74.3,155.2l0.5-0.6L74.3,155.2z M74.8,154.6l-0.5,0.6L74.8,154.6z"/>
<path className="st16" d="M74.8,154.6L74.8,154.6l0.2-0.2L74.8,154.6z M75,154.4l-0.2,0.2L75,154.4z"/>
<path className="st17" d="M74.8,154.6L74.8,154.6l0.2-0.2L74.8,154.6z M75,154.4l-0.2,0.2L75,154.4z"/>
<path className="st16" d="M75,154.4l2.3-2.4l-2.3,2.3V154.4z M77.4,152.1l-2.4,2.3l2.3-2.4L77.4,152.1z"/>
<path className="st17" d="M75,154.4l2.3-2.4l-2.3,2.3V154.4z M77.4,152.1l-2.4,2.3l2.3-2.4L77.4,152.1z"/>
<path className="st16" d="M74.2,155.3l0.6-0.7l-0.5,0.6L74.2,155.3z M74.9,154.7l-0.7,0.6l0.6-0.7L74.9,154.7z"/>
<path className="st17" d="M74.2,155.3l0.6-0.7l-0.5,0.6L74.2,155.3z M74.9,154.7l-0.7,0.6l0.6-0.7L74.9,154.7z"/>
<path className="st16" d="M74.9,154.7l0.1-0.4l-0.2,0.2L74.9,154.7z M75.1,154.5l-0.2,0.2l0.1-0.4L75.1,154.5z"/>
<path className="st17" d="M74.9,154.7l0.1-0.4l-0.2,0.2L74.9,154.7z M75.1,154.5l-0.2,0.2l0.1-0.4L75.1,154.5z"/>
<path className="st16" d="M75.1,154.5l2.3-2.4l-2.4,2.3L75.1,154.5z M75.1,154.5L75.1,154.5l2.3-2.4L75.1,154.5z"/>
<path className="st17" d="M75.1,154.5l2.3-2.4l-2.4,2.3L75.1,154.5z M75.1,154.5L75.1,154.5l2.3-2.4L75.1,154.5z"/>
<path className="st16" d="M77.4,152.1l0.1-0.4l-0.2,0.2L77.4,152.1z M77.6,151.8l-0.2,0.2l0.1-0.4L77.6,151.8z"/>
<path className="st17" d="M77.4,152.1l0.1-0.4l-0.2,0.2L77.4,152.1z M77.6,151.8l-0.2,0.2l0.1-0.4L77.6,151.8z"/>
<path className="st16" d="M74.2,155.3L74.2,155.3l0.7-0.6L74.2,155.3z M74.9,154.7l-0.7,0.6L74.9,154.7z"/>
<path className="st17" d="M74.2,155.3L74.2,155.3l0.7-0.6L74.2,155.3z M74.9,154.7l-0.7,0.6L74.9,154.7z"/>
<path className="st16" d="M74.9,154.7L74.9,154.7l0.2-0.2L74.9,154.7z M75.1,154.5l-0.2,0.2L75.1,154.5z"/>
<path className="st17" d="M74.9,154.7L74.9,154.7l0.2-0.2L74.9,154.7z M75.1,154.5l-0.2,0.2L75.1,154.5z"/>
<path className="st16" d="M75.1,154.5L75.1,154.5l2.3-2.4L75.1,154.5z M77.4,152.1l-2.3,2.4L77.4,152.1z"/>
<path className="st17" d="M75.1,154.5L75.1,154.5l2.3-2.4L75.1,154.5z M77.4,152.1l-2.3,2.4L77.4,152.1z"/>
<path className="st16" d="M77.4,152.1L77.4,152.1l0.2-0.2L77.4,152.1z M77.6,151.8l-0.2,0.2L77.6,151.8z"/>
<path className="st17" d="M77.4,152.1L77.4,152.1l0.2-0.2L77.4,152.1z M77.6,151.8l-0.2,0.2L77.6,151.8z"/>
<path className="st16" d="M74.2,155.4l0.7-0.7l-0.7,0.6V155.4z M74.2,155.4L74.2,155.4l0.7-0.7L74.2,155.4z"/>
<path className="st17" d="M74.2,155.4l0.7-0.7l-0.7,0.6V155.4z M74.2,155.4L74.2,155.4l0.7-0.7L74.2,155.4z"/>
<path className="st16" d="M74.9,154.7L74.9,154.7l0.2-0.2L74.9,154.7z M75.1,154.5l-0.2,0.2L75.1,154.5z"/>
<path className="st17" d="M74.9,154.7L74.9,154.7l0.2-0.2L74.9,154.7z M75.1,154.5l-0.2,0.2L75.1,154.5z"/>
<path className="st16" d="M75.1,154.5l2.3-2.4L75.1,154.5z M77.5,152.2l-2.4,2.3l2.3-2.4L77.5,152.2z"/>
<path className="st17" d="M75.1,154.5l2.3-2.4L75.1,154.5z M77.5,152.2l-2.4,2.3l2.3-2.4L77.5,152.2z"/>
<path className="st16" d="M77.5,152.2l0.1-0.4l-0.2,0.2L77.5,152.2z M77.8,152l-0.2,0.2l0.1-0.4L77.8,152z"/>
<path className="st17" d="M77.5,152.2l0.1-0.4l-0.2,0.2L77.5,152.2z M77.8,152l-0.2,0.2l0.1-0.4L77.8,152z"/>
<path className="st16" d="M74.2,155.6l0.7-0.8l-0.7,0.7V155.6z M75,154.8l-0.8,0.7l0.7-0.8L75,154.8z"/>
<path className="st17" d="M74.2,155.6l0.7-0.8l-0.7,0.7V155.6z M75,154.8l-0.8,0.7l0.7-0.8L75,154.8z"/>
<path className="st16" d="M75,154.8l0.1-0.4l-0.2,0.2L75,154.8z M75.2,154.6l-0.2,0.2l0.1-0.4L75.2,154.6z"/>
<path className="st17" d="M75,154.8l0.1-0.4l-0.2,0.2L75,154.8z M75.2,154.6l-0.2,0.2l0.1-0.4L75.2,154.6z"/>
<path className="st16" d="M75.2,154.6l1.1-1.2l-1.2,1.1L75.2,154.6z M76.3,153.5l-1.1,1.1l1.1-1.2V153.5z"/>
<path className="st17" d="M75.2,154.6l1.1-1.2l-1.2,1.1L75.2,154.6z M76.3,153.5l-1.1,1.1l1.1-1.2V153.5z"/>
<path className="st16" d="M76.4,153.3l1.1-1.1l-1.2,1.2L76.4,153.3z M76.4,153.3L76.4,153.3l1.1-1.1L76.4,153.3z"/>
<path className="st17" d="M76.4,153.3l1.1-1.1l-1.2,1.2L76.4,153.3z M76.4,153.3L76.4,153.3l1.1-1.1L76.4,153.3z"/>
<path className="st16" d="M77.5,152.2L77.5,152.2l0.2-0.2L77.5,152.2z M77.8,152l-0.2,0.2L77.8,152z"/>
<path className="st17" d="M77.5,152.2L77.5,152.2l0.2-0.2L77.5,152.2z M77.8,152l-0.2,0.2L77.8,152z"/>
<path className="st16" d="M74.2,155.7l0.8-0.8l-0.8,0.7V155.7z M74.2,155.7L74.2,155.7l0.8-0.8L74.2,155.7z"/>
<path className="st17" d="M74.2,155.7l0.8-0.8l-0.8,0.7V155.7z M74.2,155.7L74.2,155.7l0.8-0.8L74.2,155.7z"/>
<path className="st16" d="M75,154.8L75,154.8l0.2-0.2L75,154.8z M75.2,154.6l-0.2,0.2L75.2,154.6z"/>
<path className="st17" d="M75,154.8L75,154.8l0.2-0.2L75,154.8z M75.2,154.6l-0.2,0.2L75.2,154.6z"/>
<path className="st16" d="M74.3,155.7l0.7-0.8l-0.8,0.8H74.3z M75.1,154.8l-0.8,0.8l0.7-0.8H75.1z"/>
<path className="st17" d="M74.3,155.7l0.7-0.8l-0.8,0.8H74.3z M75.1,154.8l-0.8,0.8l0.7-0.8H75.1z"/>
<path className="st16" d="M75.1,154.8l0.1-0.2l-0.2,0.2H75.1z M75.1,154.8L75.1,154.8l0.1-0.2L75.1,154.8z"/>
<path className="st17" d="M75.1,154.8l0.1-0.2l-0.2,0.2H75.1z M75.1,154.8L75.1,154.8l0.1-0.2L75.1,154.8z"/>
<path className="st16" d="M75.2,154.6l1.1-1.1L75.2,154.6z M76.3,153.6l-1.1,1l1.1-1.1V153.6z"/>
<path className="st17" d="M75.2,154.6l1.1-1.1L75.2,154.6z M76.3,153.6l-1.1,1l1.1-1.1V153.6z"/>
<path className="st16" d="M76.6,153.4l1-1.2l-1.1,1.1L76.6,153.4z M77.6,152.3l-1.1,1.1l1-1.2L77.6,152.3z"/>
<path className="st17" d="M76.6,153.4l1-1.2l-1.1,1.1L76.6,153.4z M77.6,152.3l-1.1,1.1l1-1.2L77.6,152.3z"/>
<path className="st16" d="M75.4,154.7l1-1.1l-1.1,1L75.4,154.7z M76.4,153.6l-1.1,1.1l1-1.1H76.4z"/>
<path className="st17" d="M75.4,154.7l1-1.1l-1.1,1L75.4,154.7z M76.4,153.6l-1.1,1.1l1-1.1H76.4z"/>
<path className="st16" d="M76.7,153.5l1-1.2l-1.1,1.1L76.7,153.5z M77.8,152.4l-1.1,1.1l1-1.2L77.8,152.4z"/>
<path className="st17" d="M76.7,153.5l1-1.2l-1.1,1.1L76.7,153.5z M77.8,152.4l-1.1,1.1l1-1.2L77.8,152.4z"/>
<path className="st16" d="M75.5,154.8l1-1.2l-1.1,1.1L75.5,154.8z M76.6,153.6l-1.1,1.2l1-1.2H76.6z"/>
<path className="st17" d="M75.5,154.8l1-1.2l-1.1,1.1L75.5,154.8z M76.6,153.6l-1.1,1.2l1-1.2H76.6z"/>
<path className="st16" d="M76.6,153.6l1.2-1.2l-1.1,1.1L76.6,153.6z M76.6,153.6L76.6,153.6l1.2-1.2L76.6,153.6z"/>
<path className="st17" d="M76.6,153.6l1.2-1.2l-1.1,1.1L76.6,153.6z M76.6,153.6L76.6,153.6l1.2-1.2L76.6,153.6z"/>
<path className="st16" d="M74.4,156l0.7-1.2l-0.8,0.8L74.4,156z M75.2,155.1l-0.8,1l0.7-1.2L75.2,155.1z"/>
<path className="st17" d="M74.4,156l0.7-1.2l-0.8,0.8L74.4,156z M75.2,155.1l-0.8,1l0.7-1.2L75.2,155.1z"/>
<path className="st16" d="M75.2,155.1v-0.5l-0.1,0.2L75.2,155.1z M75.5,154.8l-0.2,0.2v-0.5L75.5,154.8z"/>
<path className="st17" d="M75.2,155.1v-0.5l-0.1,0.2L75.2,155.1z M75.5,154.8l-0.2,0.2v-0.5L75.5,154.8z"/>
<path className="st16" d="M75.5,154.8l1.1-1.2L75.5,154.8z M76.7,153.8l-1.2,1.1l1.1-1.2L76.7,153.8z"/>
<path className="st17" d="M75.5,154.8l1.1-1.2L75.5,154.8z M76.7,153.8l-1.2,1.1l1.1-1.2L76.7,153.8z"/>
<path className="st16" d="M76.7,153.8l1.1-1.3l-1.2,1.2L76.7,153.8z M77.9,152.6l-1.2,1.2l1.1-1.3L77.9,152.6z"/>
<path className="st17" d="M76.7,153.8l1.1-1.3l-1.2,1.2L76.7,153.8z M77.9,152.6l-1.2,1.2l1.1-1.3L77.9,152.6z"/>
<path className="st16" d="M74.5,156.2l0.7-1.1l-0.8,1L74.5,156.2z M75.5,155.3l-1,0.8l0.7-1.1L75.5,155.3z"/>
<path className="st17" d="M74.5,156.2l0.7-1.1l-0.8,1L74.5,156.2z M75.5,155.3l-1,0.8l0.7-1.1L75.5,155.3z"/>
<path className="st16" d="M75.5,155.3v-0.5l-0.2,0.2L75.5,155.3z M75.7,155.1l-0.2,0.2v-0.5L75.7,155.1z"/>
<path className="st17" d="M75.5,155.3v-0.5l-0.2,0.2L75.5,155.3z M75.7,155.1l-0.2,0.2v-0.5L75.7,155.1z"/>
<path className="st16" d="M75.7,155.1l0.8-1.2l-1.1,1L75.7,155.1z M76.7,154l-1,1.1l0.8-1.2L76.7,154z"/>
<path className="st17" d="M75.7,155.1l0.8-1.2l-1.1,1L75.7,155.1z M76.7,154l-1,1.1l0.8-1.2L76.7,154z"/>
<path className="st16" d="M74.6,156.3l1.1-1.3l-1.2,1.2L74.6,156.3z M75.8,155.1l-1.2,1.2l1.1-1.3L75.8,155.1z"/>
<path className="st17" d="M74.6,156.3l1.1-1.3l-1.2,1.2L74.6,156.3z M75.8,155.1l-1.2,1.2l1.1-1.3L75.8,155.1z"/>
<path className="st16" d="M75.8,155.1l0.8-1.1l-1,1L75.8,155.1z M76.8,154.1l-1,1l0.8-1.1L76.8,154.1z"/>
<path className="st17" d="M75.8,155.1l0.8-1.1l-1,1L75.8,155.1z M76.8,154.1l-1,1l0.8-1.1L76.8,154.1z"/>
<path className="st16" d="M77,153.8l0.8-1.2l-1.1,1L77,153.8z M78.1,152.8l-1.1,1l0.8-1.2L78.1,152.8z"/>
<path className="st17" d="M77,153.8l0.8-1.2l-1.1,1L77,153.8z M78.1,152.8l-1.1,1l0.8-1.2L78.1,152.8z"/>
<path className="st16" d="M78.1,152.8l-0.4-0.8l-0.2,0.2L78.1,152.8z M78.4,152.6l-0.2,0.2l-0.4-0.8L78.4,152.6z"/>
<path className="st17" d="M78.1,152.8l-0.4-0.8l-0.2,0.2L78.1,152.8z M78.4,152.6l-0.2,0.2l-0.4-0.8L78.4,152.6z"/>
<path className="st16" d="M76.1,155.2l0.7-1.1l-1,1L76.1,155.2z M77,154.2l-1,1l0.7-1.1L77,154.2z M76.1,155.2l1-1L76.1,155.2z
	 M76.1,155.2L76.1,155.2l1-1L76.1,155.2z"/>
<path className="st17" d="M76.1,155.2l0.7-1.1l-1,1L76.1,155.2z M77,154.2l-1,1l0.7-1.1L77,154.2z M76.1,155.2l1-1L76.1,155.2z
	 M76.1,155.2L76.1,155.2l1-1L76.1,155.2z"/>
<path className="st16" d="M76.1,155.2l1-1L76.1,155.2z M77,154.4l-1,0.8l1-1V154.4z"/>
<path className="st17" d="M76.1,155.2l1-1L76.1,155.2z M77,154.4l-1,0.8l1-1V154.4z"/>
<path className="st16" d="M77.3,154l0.2-0.6l-0.5,0.4L77.3,154z M77.8,153.5l-0.5,0.5l0.2-0.6L77.8,153.5z"/>
<path className="st17" d="M77.3,154l0.2-0.6l-0.5,0.4L77.3,154z M77.8,153.5l-0.5,0.5l0.2-0.6L77.8,153.5z"/>
<path className="st16" d="M76.2,155.3l0.8-1l-1,0.8L76.2,155.3z M77.2,154.4l-1,1l0.8-1H77.2z"/>
<path className="st17" d="M76.2,155.3l0.8-1l-1,0.8L76.2,155.3z M77.2,154.4l-1,1l0.8-1H77.2z"/>
<path className="st16" d="M77.4,154.2l0.4-0.7l-0.5,0.5L77.4,154.2z M77.9,153.6l-0.5,0.6l0.4-0.7L77.9,153.6z"/>
<path className="st17" d="M77.4,154.2l0.4-0.7l-0.5,0.5L77.4,154.2z M77.9,153.6l-0.5,0.6l0.4-0.7L77.9,153.6z"/>
<path className="st16" d="M75,156.6l0.8-1.6l-1.2,1.2L75,156.6z M76.2,155.4l-1.2,1.2l0.8-1.6L76.2,155.4z"/>
<path className="st17" d="M75,156.6l0.8-1.6l-1.2,1.2L75,156.6z M76.2,155.4l-1.2,1.2l0.8-1.6L76.2,155.4z"/>
<path className="st16" d="M76.2,155.4l1-1.1l-1,1V155.4z M77.3,154.4l-1.1,1.1l1-1.1H77.3z"/>
<path className="st17" d="M76.2,155.4l1-1.1l-1,1V155.4z M77.3,154.4l-1.1,1.1l1-1.1H77.3z"/>
<path className="st16" d="M77.3,154.4l0.6-0.7l-0.5,0.6L77.3,154.4z M77.9,153.8l-0.6,0.6l0.6-0.7V153.8z"/>
<path className="st17" d="M77.3,154.4l0.6-0.7l-0.5,0.6L77.3,154.4z M77.9,153.8l-0.6,0.6l0.6-0.7V153.8z"/>
<path className="st16" d="M75.1,156.8l1.1-1.3l-1.2,1.2L75.1,156.8z M76.3,155.4l-1.2,1.3l1.1-1.3H76.3z"/>
<path className="st17" d="M75.1,156.8l1.1-1.3l-1.2,1.2L75.1,156.8z M76.3,155.4l-1.2,1.3l1.1-1.3H76.3z"/>
<path className="st16" d="M76.3,155.4l1-1.1l-1.1,1.1H76.3z M77.4,154.4l-1.1,1.1l1-1.1H77.4z"/>
<path className="st17" d="M76.3,155.4l1-1.1l-1.1,1.1H76.3z M77.4,154.4l-1.1,1.1l1-1.1H77.4z"/>
<path className="st16" d="M77.4,154.4l0.5-0.6l-0.6,0.6H77.4z M78,153.8l-0.6,0.6l0.5-0.6H78z"/>
<path className="st17" d="M77.4,154.4l0.5-0.6l-0.6,0.6H77.4z M78,153.8l-0.6,0.6l0.5-0.6H78z"/>
<path className="st16" d="M78,153.8l-0.1-0.7l-0.4,0.4L78,153.8z M78.4,153.5l-0.4,0.2l-0.1-0.7L78.4,153.5z"/>
<path className="st17" d="M78,153.8l-0.1-0.7l-0.4,0.4L78,153.8z M78.4,153.5l-0.4,0.2l-0.1-0.7L78.4,153.5z"/>
<path className="st16" d="M78.4,153.5l-0.4-0.6l-0.1,0.1L78.4,153.5z M78.5,153.3l-0.1,0.2l-0.4-0.6L78.5,153.3z"/>
<path className="st17" d="M78.4,153.5l-0.4-0.6l-0.1,0.1L78.4,153.5z M78.5,153.3l-0.1,0.2l-0.4-0.6L78.5,153.3z"/>
<path className="st16" d="M78.5,153.3l-0.1-0.7l-0.4,0.4L78.5,153.3z M78.8,153l-0.4,0.2l-0.1-0.7L78.8,153z"/>
<path className="st17" d="M78.5,153.3l-0.1-0.7l-0.4,0.4L78.5,153.3z M78.8,153l-0.4,0.2l-0.1-0.7L78.8,153z"/>
<path className="st16" d="M74.9,157.1l2-2.3l-2,2.2V157.1z M77,155l-2.2,2.2l2-2.3L77,155z"/>
<path className="st17" d="M74.9,157.1l2-2.3l-2,2.2V157.1z M77,155l-2.2,2.2l2-2.3L77,155z"/>
<path className="st16" d="M78,154l1.1-1.2l-1.2,1.2H78z M79.1,152.9L78,154l1.1-1.2V152.9z"/>
<path className="st17" d="M78,154l1.1-1.2l-1.2,1.2H78z M79.1,152.9L78,154l1.1-1.2V152.9z"/>
<path className="st16" d="M75,157.1l2.5-2.6l-2.6,2.6H75z M77.6,154.6l-2.6,2.5l2.5-2.6L77.6,154.6z"/>
<path className="st17" d="M75,157.1l2.5-2.6l-2.6,2.6H75z M77.6,154.6l-2.6,2.5l2.5-2.6L77.6,154.6z"/>
<path className="st16" d="M77.6,154.6l1.4-1.7l-1.6,1.6L77.6,154.6z M79.2,152.9l-1.6,1.7l1.4-1.7H79.2z"/>
<path className="st17" d="M77.6,154.6l1.4-1.7l-1.6,1.6L77.6,154.6z M79.2,152.9l-1.6,1.7l1.4-1.7H79.2z"/>
<line className="st19" x1="76" y1="153" x2="78.5" y2="155.4"/>
<line className="st19" x1="75.7" y1="155" x2="74.5" y2="156.2"/>
<line className="st19" x1="76.8" y1="154.1" x2="75.8" y2="155.1"/>
<line className="st19" x1="77.9" y1="153" x2="77.5" y2="153.4"/>
<polyline className="st19" points="78.8,153 78.4,152.6 78,152.9 78.5,153.3 "/>
<line className="st19" x1="77.9" y1="153" x2="78.4" y2="153.5"/>
<line className="st19" x1="77.5" y1="153.4" x2="78" y2="153.8"/>
<line className="st19" x1="75.7" y1="155" x2="76.3" y2="155.4"/>
<line className="st19" x1="74.5" y1="156.2" x2="75.1" y2="156.8"/>
<line className="st19" x1="76.8" y1="153.5" x2="76.6" y2="153.9"/>
<path className="st19" d="M77.2,154.3c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C77,154.4,77.2,154.4,77.2,154.3"/>
<line className="st19" x1="76.8" y1="153.5" x2="77.3" y2="154"/>
<line className="st19" x1="76.6" y1="153.9" x2="77.2" y2="154.4"/>
<polyline className="st19" points="77.9,154 78,154 77,155 76.9,154.8 "/>
<path className="st19" d="M76.5,153.6c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C76.3,153.7,76.4,153.7,76.5,153.6"/>
<path className="st19" d="M74.7,153.1c-0.2,0.2-0.3,0.5-0.3,0.7s0.1,0.5,0.3,0.7"/>
<path className="st19" d="M77.7,151.9c-0.2-0.2-0.5-0.4-0.7-0.4c-0.3,0-0.6,0.1-0.8,0.3"/>
<path className="st19" d="M77.4,152.1c-0.1-0.2-0.3-0.2-0.5-0.3c-0.2,0-0.4,0.1-0.5,0.2"/>
<path className="st19" d="M75,153.4c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.3,0.2,0.5"/>
<line className="st19" x1="76.4" y1="152.1" x2="75" y2="153.4"/>
<line className="st19" x1="75" y1="154.4" x2="75.7" y2="155.1"/>
<polyline className="st19" points="77.4,152.1 78.1,152.8 77,153.8 "/>
<line className="st19" x1="76.2" y1="151.8" x2="74.8" y2="153.2"/>
<path className="st19" d="M74.7,154.5c-0.3,0.1-0.6,0.4-0.6,0.7c-0.1,0.3,0.1,0.7,0.4,0.9"/>
<line className="st19" x1="79.1" y1="152.8" x2="74.9" y2="157"/>
<polyline className="st19" points="79.1,152.8 79.2,152.9 75,157.1 74.9,157 "/>
<line className="st19" x1="74.8" y1="154.6" x2="75.5" y2="155.3"/>
<line className="st19" x1="77.6" y1="151.8" x2="78.8" y2="153"/>
<path className="st20" d="M77,154.2L77,154.2z"/>
<path className="st21" d="M77,154.2L77,154.2z"/>
<path className="st20" d="M77,154.2v0.1V154.2z"/>
<path className="st21" d="M77,154.2v0.1V154.2z"/>
<path className="st20" d="M77,154.2v0.1V154.2z"/>
<path className="st21" d="M77,154.2v0.1V154.2z"/>
<path className="st20" d="M77,154.4h0.1H77z"/>
<path className="st21" d="M77,154.4h0.1H77z"/>
<path className="st20" d="M77,154.4L77,154.4z"/>
<path className="st21" d="M77,154.4L77,154.4z"/>
<path className="st20" d="M77,154.4L77,154.4z"/>
<path className="st21" d="M77,154.4L77,154.4z"/>
<path className="st16" d="M203.8,198.2l1.9,1.7l-1.4-1.3L203.8,198.2z M205.9,200.3l-2.2-2.2l1.9,1.7L205.9,200.3z M203.8,198.2l2.2,2.2
	L203.8,198.2z M203.8,198.2L203.8,198.2l2.2,2.2L203.8,198.2z"/>
<path className="st22" d="M203.8,198.2l1.9,1.7l-1.4-1.3L203.8,198.2z M205.9,200.3l-2.2-2.2l1.9,1.7L205.9,200.3z M203.8,198.2l2.2,2.2
	L203.8,198.2z M203.8,198.2L203.8,198.2l2.2,2.2L203.8,198.2z"/>
<path className="st16" d="M203.5,198.2l2.4,2.2l-2.2-2.2H203.5z M205.9,200.6l-2.4-2.4l2.4,2.2V200.6z"/>
<path className="st22" d="M203.5,198.2l2.4,2.2l-2.2-2.2H203.5z M205.9,200.6l-2.4-2.4l2.4,2.2V200.6z"/>
<path className="st16" d="M203.3,198.2l0.7,0.5l-0.5-0.5H203.3z M203.6,198.5l-0.4-0.4l0.7,0.5L203.6,198.5z"/>
<path className="st22" d="M203.3,198.2l0.7,0.5l-0.5-0.5H203.3z M203.6,198.5l-0.4-0.4l0.7,0.5L203.6,198.5z"/>
<path className="st16" d="M203.6,198.5l1.8,1.6l-1.4-1.3L203.6,198.5z M205.6,200.4l-1.9-1.9l1.8,1.6L205.6,200.4z"/>
<path className="st22" d="M203.6,198.5l1.8,1.6l-1.4-1.3L203.6,198.5z M205.6,200.4l-1.9-1.9l1.8,1.6L205.6,200.4z"/>
<path className="st16" d="M203.3,198.2L203.3,198.2l0.4,0.4L203.3,198.2z M203.6,198.5l-0.4-0.4L203.6,198.5z"/>
<path className="st22" d="M203.3,198.2L203.3,198.2l0.4,0.4L203.3,198.2z M203.6,198.5l-0.4-0.4L203.6,198.5z"/>
<path className="st16" d="M203.6,198.5l1.9,1.9L203.6,198.5z M205.6,200.6l-1.9-2l1.9,1.9V200.6z"/>
<path className="st22" d="M203.6,198.5l1.9,1.9L203.6,198.5z M205.6,200.6l-1.9-2l1.9,1.9V200.6z"/>
<path className="st16" d="M203.3,198.3l0.4,0.2l-0.4-0.4V198.3z M203.5,198.5l-0.2-0.2l0.4,0.2H203.5z"/>
<path className="st22" d="M203.3,198.3l0.4,0.2l-0.4-0.4V198.3z M203.5,198.5l-0.2-0.2l0.4,0.2H203.5z"/>
<path className="st16" d="M203.5,198.5l2,2l-1.9-2H203.5z M203.5,198.5L203.5,198.5l2,2L203.5,198.5z"/>
<path className="st22" d="M203.5,198.5l2,2l-1.9-2H203.5z M203.5,198.5L203.5,198.5l2,2L203.5,198.5z"/>
<path className="st16" d="M203.3,198.3L203.3,198.3l0.2,0.2L203.3,198.3z M203.5,198.5l-0.2-0.2L203.5,198.5z"/>
<path className="st22" d="M203.3,198.3L203.3,198.3l0.2,0.2L203.3,198.3z M203.5,198.5l-0.2-0.2L203.5,198.5z"/>
<path className="st16" d="M203.5,198.5L203.5,198.5l2,2L203.5,198.5z M205.6,200.6l-2-2L205.6,200.6z"/>
<path className="st22" d="M203.5,198.5L203.5,198.5l2,2L203.5,198.5z M205.6,200.6l-2-2L205.6,200.6z"/>
<path className="st16" d="M203.3,198.3L203.3,198.3l0.2,0.2L203.3,198.3z M203.5,198.5l-0.2-0.2L203.5,198.5z"/>
<path className="st22" d="M203.3,198.3L203.3,198.3l0.2,0.2L203.3,198.3z M203.5,198.5l-0.2-0.2L203.5,198.5z"/>
<path className="st16" d="M203.5,198.5L203.5,198.5l2,2L203.5,198.5z M205.6,200.6l-2-2L205.6,200.6z"/>
<path className="st22" d="M203.5,198.5L203.5,198.5l2,2L203.5,198.5z M205.6,200.6l-2-2L205.6,200.6z"/>
<path className="st16" d="M205.6,200.6h0.4l-0.5-0.5L205.6,200.6z M205.9,200.9l-0.4-0.4h0.4V200.9z"/>
<path className="st22" d="M205.6,200.6h0.4l-0.5-0.5L205.6,200.6z M205.9,200.9l-0.4-0.4h0.4V200.9z"/>
<path className="st16" d="M203.5,198.5l2,2L203.5,198.5z M205.6,200.7l-2-2.2l2,2V200.7z"/>
<path className="st22" d="M203.5,198.5l2,2L203.5,198.5z M205.6,200.7l-2-2.2l2,2V200.7z"/>
<path className="st16" d="M205.6,200.7l0.4,0.2l-0.4-0.4V200.7z M205.6,200.7L205.6,200.7l0.4,0.2L205.6,200.7z"/>
<path className="st22" d="M205.6,200.7l0.4,0.2l-0.4-0.4V200.7z M205.6,200.7L205.6,200.7l0.4,0.2L205.6,200.7z"/>
<polygon className="st16" points="202.4,197.9 202.9,198.4 202.3,197.9 "/>
<polygon className="st22" points="202.4,197.9 202.9,198.4 202.3,197.9 "/>
<path className="st16" d="M203.2,198.6l2.4,2l-2-2.2L203.2,198.6z M205.4,200.9l-2.3-2.3l2.4,2L205.4,200.9z"/>
<path className="st22" d="M203.2,198.6l2.4,2l-2-2.2L203.2,198.6z M205.4,200.9l-2.3-2.3l2.4,2L205.4,200.9z"/>
<path className="st16" d="M205.4,200.9h0.5l-0.4-0.2L205.4,200.9z M205.7,201.2l-0.2-0.2h0.5L205.7,201.2z"/>
<path className="st22" d="M205.4,200.9h0.5l-0.4-0.2L205.4,200.9z M205.7,201.2l-0.2-0.2h0.5L205.7,201.2z"/>
<path className="st16" d="M202.3,197.9l0.6,0.5L202.3,197.9z M202.8,198.4l-0.5-0.5l0.6,0.5H202.8z"/>
<path className="st22" d="M202.3,197.9l0.6,0.5L202.3,197.9z M202.8,198.4l-0.5-0.5l0.6,0.5H202.8z"/>
<path className="st16" d="M202.2,197.9l0.6,0.5l-0.5-0.5H202.2z M202.8,198.5l-0.6-0.6l0.6,0.5V198.5z"/>
<path className="st22" d="M202.2,197.9l0.6,0.5l-0.5-0.5H202.2z M202.8,198.5l-0.6-0.6l0.6,0.5V198.5z"/>
<path className="st16" d="M202.8,198.5h0.7l-0.2-0.2L202.8,198.5z M203,198.8l-0.2-0.2h0.7L203,198.8z"/>
<path className="st22" d="M202.8,198.5h0.7l-0.2-0.2L202.8,198.5z M203,198.8l-0.2-0.2h0.7L203,198.8z"/>
<path className="st16" d="M202.2,197.9L202.2,197.9l0.6,0.6L202.2,197.9z M202.8,198.5l-0.6-0.6L202.8,198.5z"/>
<path className="st22" d="M202.2,197.9L202.2,197.9l0.6,0.6L202.2,197.9z M202.8,198.5l-0.6-0.6L202.8,198.5z"/>
<path className="st16" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st22" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st16" d="M203,198.8l2.4,2.2l-2.3-2.3L203,198.8z M205.4,201l-2.4-2.3l2.4,2.2V201z"/>
<path className="st22" d="M203,198.8l2.4,2.2l-2.3-2.3L203,198.8z M205.4,201l-2.4-2.3l2.4,2.2V201z"/>
<path className="st16" d="M202.1,197.9l0.7,0.6l-0.6-0.6H202.1z M202.1,197.9L202.1,197.9l0.7,0.6L202.1,197.9z"/>
<path className="st22" d="M202.1,197.9l0.7,0.6l-0.6-0.6H202.1z M202.1,197.9L202.1,197.9l0.7,0.6L202.1,197.9z"/>
<path className="st16" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st22" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st16" d="M203,198.8l2.4,2.3L203,198.8z M205.3,201l-2.3-2.3l2.4,2.3H205.3z"/>
<path className="st22" d="M203,198.8l2.4,2.3L203,198.8z M205.3,201l-2.3-2.3l2.4,2.3H205.3z"/>
<path className="st16" d="M205.3,201l0.4,0.1l-0.2-0.2L205.3,201z M205.6,201.3l-0.2-0.2l0.4,0.1L205.6,201.3z"/>
<path className="st22" d="M205.3,201l0.4,0.1l-0.2-0.2L205.3,201z M205.6,201.3l-0.2-0.2l0.4,0.1L205.6,201.3z"/>
<path className="st16" d="M202.1,197.9L202.1,197.9l0.7,0.6L202.1,197.9z M202.8,198.5l-0.7-0.6L202.8,198.5z"/>
<path className="st22" d="M202.1,197.9L202.1,197.9l0.7,0.6L202.1,197.9z M202.8,198.5l-0.7-0.6L202.8,198.5z"/>
<path className="st16" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st22" d="M202.8,198.5L202.8,198.5l0.2,0.2L202.8,198.5z M203,198.8l-0.2-0.2L203,198.8z"/>
<path className="st16" d="M203,198.8l2.3,2.3L203,198.8z M205.3,201.2l-2.3-2.4l2.3,2.3V201.2z"/>
<path className="st22" d="M203,198.8l2.3,2.3L203,198.8z M205.3,201.2l-2.3-2.4l2.3,2.3V201.2z"/>
<path className="st16" d="M205.3,201.2l0.2,0.1l-0.2-0.2V201.2z M205.6,201.4l-0.2-0.2l0.2,0.1V201.4z"/>
<path className="st22" d="M205.3,201.2l0.2,0.1l-0.2-0.2V201.2z M205.6,201.4l-0.2-0.2l0.2,0.1V201.4z"/>
<path className="st16" d="M202,197.8l0.8,0.7l-0.7-0.6L202,197.8z M202.7,198.6l-0.7-0.8l0.8,0.7L202.7,198.6z"/>
<path className="st22" d="M202,197.8l0.8,0.7l-0.7-0.6L202,197.8z M202.7,198.6l-0.7-0.8l0.8,0.7L202.7,198.6z"/>
<path className="st16" d="M202.7,198.6l0.4,0.1l-0.2-0.2L202.7,198.6z M202.9,198.9l-0.2-0.2l0.4,0.1L202.9,198.9z"/>
<path className="st22" d="M202.7,198.6l0.4,0.1l-0.2-0.2L202.7,198.6z M202.9,198.9l-0.2-0.2l0.4,0.1L202.9,198.9z"/>
<path className="st16" d="M202.9,198.9l2.4,2.3l-2.3-2.4L202.9,198.9z M202.9,198.9L202.9,198.9l2.4,2.3L202.9,198.9z"/>
<path className="st22" d="M202.9,198.9l2.4,2.3l-2.3-2.4L202.9,198.9z M202.9,198.9L202.9,198.9l2.4,2.3L202.9,198.9z"/>
<path className="st16" d="M205.3,201.2L205.3,201.2l0.2,0.2L205.3,201.2z M205.6,201.4l-0.2-0.2L205.6,201.4z"/>
<path className="st22" d="M205.3,201.2L205.3,201.2l0.2,0.2L205.3,201.2z M205.6,201.4l-0.2-0.2L205.6,201.4z"/>
<path className="st16" d="M201.8,197.8l0.8,0.8l-0.7-0.8H201.8z M201.8,197.8L201.8,197.8l0.8,0.8L201.8,197.8z"/>
<path className="st22" d="M201.8,197.8l0.8,0.8l-0.7-0.8H201.8z M201.8,197.8L201.8,197.8l0.8,0.8L201.8,197.8z"/>
<path className="st16" d="M202.7,198.6L202.7,198.6l0.2,0.2L202.7,198.6z M202.9,198.9l-0.2-0.2L202.9,198.9z"/>
<path className="st22" d="M202.7,198.6L202.7,198.6l0.2,0.2L202.7,198.6z M202.9,198.9l-0.2-0.2L202.9,198.9z"/>
<path className="st16" d="M202.9,198.9l1.2,1.1L202.9,198.9z M204,200l-1.1-1.1l1.2,1.1H204z"/>
<path className="st22" d="M202.9,198.9l1.2,1.1L202.9,198.9z M204,200l-1.1-1.1l1.2,1.1H204z"/>
<path className="st16" d="M204.1,200.1l1.2,1.1l-1.2-1.2V200.1z M205.2,201.2l-1.1-1.1l1.2,1.1H205.2z"/>
<path className="st22" d="M204.1,200.1l1.2,1.1l-1.2-1.2V200.1z M205.2,201.2l-1.1-1.1l1.2,1.1H205.2z"/>
<path className="st16" d="M205.2,201.2l0.4,0.2l-0.2-0.2H205.2z M205.4,201.4l-0.2-0.2l0.4,0.2H205.4z"/>
<path className="st22" d="M205.2,201.2l0.4,0.2l-0.2-0.2H205.2z M205.4,201.4l-0.2-0.2l0.4,0.2H205.4z"/>
<path className="st16" d="M201.8,197.8L201.8,197.8l0.8,0.8L201.8,197.8z M202.7,198.6l-0.8-0.8L202.7,198.6z"/>
<path className="st22" d="M201.8,197.8L201.8,197.8l0.8,0.8L201.8,197.8z M202.7,198.6l-0.8-0.8L202.7,198.6z"/>
<path className="st16" d="M202.7,198.6L202.7,198.6l0.2,0.2L202.7,198.6z M202.9,198.9l-0.2-0.2L202.9,198.9z"/>
<path className="st22" d="M202.7,198.6L202.7,198.6l0.2,0.2L202.7,198.6z M202.9,198.9l-0.2-0.2L202.9,198.9z"/>
<path className="st16" d="M201.7,197.9l1,0.7l-0.8-0.8L201.7,197.9z M202.6,198.8l-0.8-0.8l1,0.7L202.6,198.8z"/>
<path className="st22" d="M201.7,197.9l1,0.7l-0.8-0.8L201.7,197.9z M202.6,198.8l-0.8-0.8l1,0.7L202.6,198.8z"/>
<path className="st16" d="M202.6,198.8l0.4,0.1l-0.2-0.2L202.6,198.8z M202.8,199l-0.2-0.2l0.4,0.1L202.8,199z"/>
<path className="st22" d="M202.6,198.8l0.4,0.1l-0.2-0.2L202.6,198.8z M202.8,199l-0.2-0.2l0.4,0.1L202.8,199z"/>
<path className="st16" d="M202.8,199l1.2,1l-1.1-1.1L202.8,199z M203.9,200l-1.1-1l1.2,1H203.9z"/>
<path className="st22" d="M202.8,199l1.2,1l-1.1-1.1L202.8,199z M203.9,200l-1.1-1l1.2,1H203.9z"/>
<path className="st16" d="M204.1,200.3l1.1,0.8l-1.1-1.1V200.3z M205.2,201.3l-1.1-1l1.1,0.8V201.3z"/>
<path className="st22" d="M204.1,200.3l1.1,0.8l-1.1-1.1V200.3z M205.2,201.3l-1.1-1l1.1,0.8V201.3z"/>
<path className="st16" d="M202.7,199.1l1.2,0.8l-1.1-1L202.7,199.1z M203.8,200.1l-1.1-1l1.2,0.8L203.8,200.1z"/>
<path className="st22" d="M202.7,199.1l1.2,0.8l-1.1-1L202.7,199.1z M203.8,200.1l-1.1-1l1.2,0.8L203.8,200.1z"/>
<path className="st16" d="M204,200.3l1.2,1l-1.1-1H204z M205.1,201.4l-1.1-1.1l1.2,1L205.1,201.4z"/>
<path className="st22" d="M204,200.3l1.2,1l-1.1-1H204z M205.1,201.4l-1.1-1.1l1.2,1L205.1,201.4z"/>
<path className="st16" d="M202.7,199.1l1.1,1L202.7,199.1z M203.9,200.3l-1.2-1.2l1.1,1L203.9,200.3z"/>
<path className="st22" d="M202.7,199.1l1.1,1L202.7,199.1z M203.9,200.3l-1.2-1.2l1.1,1L203.9,200.3z"/>
<path className="st16" d="M203.9,200.3l1.2,1.1l-1.1-1.1H203.9z M205,201.4l-1.1-1.1l1.2,1.1H205z"/>
<path className="st22" d="M203.9,200.3l1.2,1.1l-1.1-1.1H203.9z M205,201.4l-1.1-1.1l1.2,1.1H205z"/>
<path className="st16" d="M201.5,198l1.1,0.7l-0.8-0.8L201.5,198z M202.3,199l-0.8-1l1.1,0.7L202.3,199z"/>
<path className="st22" d="M201.5,198l1.1,0.7l-0.8-0.8L201.5,198z M202.3,199l-0.8-1l1.1,0.7L202.3,199z"/>
<path className="st16" d="M202.3,199h0.5l-0.2-0.2L202.3,199z M202.6,199.2l-0.2-0.2h0.5L202.6,199.2z"/>
<path className="st22" d="M202.3,199h0.5l-0.2-0.2L202.3,199z M202.6,199.2l-0.2-0.2h0.5L202.6,199.2z"/>
<path className="st16" d="M202.6,199.2l1.3,1.1l-1.2-1.2L202.6,199.2z M203.8,200.3l-1.2-1.1l1.3,1.1H203.8z"/>
<path className="st22" d="M202.6,199.2l1.3,1.1l-1.2-1.2L202.6,199.2z M203.8,200.3l-1.2-1.1l1.3,1.1H203.8z"/>
<path className="st16" d="M203.8,200.3l1.2,1.1l-1.1-1.1H203.8z M205,201.5l-1.2-1.2l1.2,1.1V201.5z"/>
<path className="st22" d="M203.8,200.3l1.2,1.1l-1.1-1.1H203.8z M205,201.5l-1.2-1.2l1.2,1.1V201.5z"/>
<path className="st16" d="M201.2,198.2l1.1,0.8l-0.8-1L201.2,198.2z M202.2,199.1l-1-1l1.1,0.8L202.2,199.1z"/>
<path className="st22" d="M201.2,198.2l1.1,0.8l-0.8-1L201.2,198.2z M202.2,199.1l-1-1l1.1,0.8L202.2,199.1z"/>
<path className="st16" d="M202.2,199.1l0.4,0.1l-0.2-0.2L202.2,199.1z M202.4,199.4l-0.2-0.2l0.4,0.1L202.4,199.4z"/>
<path className="st22" d="M202.2,199.1l0.4,0.1l-0.2-0.2L202.2,199.1z M202.4,199.4l-0.2-0.2l0.4,0.1L202.4,199.4z"/>
<path className="st16" d="M202.4,199.4l1.2,0.8l-1.1-1L202.4,199.4z M203.5,200.4l-1.1-1.1l1.2,0.8L203.5,200.4z"/>
<path className="st22" d="M202.4,199.4l1.2,0.8l-1.1-1L202.4,199.4z M203.5,200.4l-1.1-1.1l1.2,0.8L203.5,200.4z"/>
<path className="st16" d="M201.1,198.3l1.4,1.2l-1.3-1.3L201.1,198.3z M202.4,199.5l-1.3-1.2l1.4,1.2H202.4z"/>
<path className="st22" d="M201.1,198.3l1.4,1.2l-1.3-1.3L201.1,198.3z M202.4,199.5l-1.3-1.2l1.4,1.2H202.4z"/>
<path className="st16" d="M202.4,199.5l1.1,1l-1-1H202.4z M203.4,200.4l-1-1l1.1,1H203.4z"/>
<path className="st22" d="M202.4,199.5l1.1,1l-1-1H202.4z M203.4,200.4l-1-1l1.1,1H203.4z"/>
<path className="st16" d="M203.6,200.8l1.3,0.7l-1.1-1L203.6,200.8z M204.7,201.8l-1.1-1l1.3,0.7L204.7,201.8z"/>
<path className="st22" d="M203.6,200.8l1.3,0.7l-1.1-1L203.6,200.8z M204.7,201.8l-1.1-1l1.3,0.7L204.7,201.8z"/>
<path className="st16" d="M204.7,201.8l0.7-0.4l-0.2-0.2L204.7,201.8z M205,202l-0.2-0.2l0.7-0.4L205,202z"/>
<path className="st22" d="M204.7,201.8l0.7-0.4l-0.2-0.2L204.7,201.8z M205,202l-0.2-0.2l0.7-0.4L205,202z"/>
<path className="st16" d="M202.3,199.7l1.1,0.7l-1-1L202.3,199.7z M203.2,200.7l-0.8-1l1.1,0.7L203.2,200.7z"/>
<path className="st22" d="M202.3,199.7l1.1,0.7l-1-1L202.3,199.7z M203.2,200.7l-0.8-1l1.1,0.7L203.2,200.7z"/>
<path className="st16" d="M202.2,199.7l1,1l-0.8-1H202.2z M202.2,199.7L202.2,199.7l1,1L202.2,199.7z M203.2,200.7l-1-1L203.2,200.7z
	 M202.2,199.7l1,1L202.2,199.7z"/>
<path className="st22" d="M202.2,199.7l1,1l-0.8-1H202.2z M202.2,199.7L202.2,199.7l1,1L202.2,199.7z M203.2,200.7l-1-1L203.2,200.7z
	 M202.2,199.7l1,1L202.2,199.7z"/>
<path className="st16" d="M203.4,200.9l0.7,0.2l-0.5-0.4L203.4,200.9z M203.9,201.4l-0.5-0.5l0.7,0.2L203.9,201.4z"/>
<path className="st22" d="M203.4,200.9l0.7,0.2l-0.5-0.4L203.4,200.9z M203.9,201.4l-0.5-0.5l0.7,0.2L203.9,201.4z"/>
<path className="st16" d="M202.1,199.8l1.1,0.8l-1-1L202.1,199.8z M203,200.8l-1-1l1.1,0.8L203,200.8z"/>
<path className="st22" d="M202.1,199.8l1.1,0.8l-1-1L202.1,199.8z M203,200.8l-1-1l1.1,0.8L203,200.8z"/>
<path className="st16" d="M203.3,201l0.6,0.4l-0.5-0.5L203.3,201z M203.8,201.5l-0.5-0.5l0.6,0.4L203.8,201.5z"/>
<path className="st22" d="M203.3,201l0.6,0.4l-0.5-0.5L203.3,201z M203.8,201.5l-0.5-0.5l0.6,0.4L203.8,201.5z"/>
<path className="st16" d="M200.8,198.6l1.7,0.8l-1.3-1.2L200.8,198.6z M202.1,200l-1.3-1.3l1.7,0.8L202.1,200z"/>
<path className="st22" d="M200.8,198.6l1.7,0.8l-1.3-1.2L200.8,198.6z M202.1,200l-1.3-1.3l1.7,0.8L202.1,200z"/>
<path className="st16" d="M202.1,200l1,0.8l-1-1V200z M203.2,200.9l-1.1-1l1,0.8L203.2,200.9z"/>
<path className="st22" d="M202.1,200l1,0.8l-1-1V200z M203.2,200.9l-1.1-1l1,0.8L203.2,200.9z"/>
<path className="st16" d="M203.2,200.9l0.6,0.6l-0.5-0.5L203.2,200.9z M203.8,201.6l-0.6-0.7l0.6,0.6V201.6z"/>
<path className="st22" d="M203.2,200.9l0.6,0.6l-0.5-0.5L203.2,200.9z M203.8,201.6l-0.6-0.7l0.6,0.6V201.6z"/>
<path className="st16" d="M200.8,198.8l1.3,1.2l-1.3-1.3V198.8z M202,200l-1.2-1.2l1.3,1.2H202z"/>
<path className="st22" d="M200.8,198.8l1.3,1.2l-1.3-1.3V198.8z M202,200l-1.2-1.2l1.3,1.2H202z"/>
<path className="st16" d="M202,200l1.2,1l-1.1-1H202z M203,201L202,200l1.2,1L203,201z"/>
<path className="st22" d="M202,200l1.2,1l-1.1-1H202z M203,201L202,200l1.2,1L203,201z"/>
<path className="st16" d="M203,201l0.7,0.6l-0.6-0.7L203,201z M203.6,201.6L203,201l0.7,0.6H203.6z"/>
<path className="st22" d="M203,201l0.7,0.6l-0.6-0.7L203,201z M203.6,201.6L203,201l0.7,0.6H203.6z"/>
<path className="st16" d="M203.6,201.6l0.8-0.1l-0.4-0.4L203.6,201.6z M204,202l-0.4-0.4l0.8-0.1L204,202z"/>
<path className="st22" d="M203.6,201.6l0.8-0.1l-0.4-0.4L203.6,201.6z M204,202l-0.4-0.4l0.8-0.1L204,202z"/>
<path className="st16" d="M204,202l0.6-0.4l-0.1-0.1L204,202z M204.1,202.1L204,202l0.6-0.4L204.1,202.1z"/>
<path className="st22" d="M204,202l0.6-0.4l-0.1-0.1L204,202z M204.1,202.1L204,202l0.6-0.4L204.1,202.1z"/>
<path className="st16" d="M204.1,202.1l0.8-0.1l-0.4-0.4L204.1,202.1z M204.5,202.5l-0.4-0.4l0.8-0.1L204.5,202.5z"/>
<path className="st22" d="M204.1,202.1l0.8-0.1l-0.4-0.4L204.1,202.1z M204.5,202.5l-0.4-0.4l0.8-0.1L204.5,202.5z"/>
<path className="st16" d="M200.4,198.6l2.2,1.9l-2-2L200.4,198.6z M202.4,200.7l-2-2l2.2,1.9L202.4,200.7z"/>
<path className="st22" d="M200.4,198.6l2.2,1.9l-2-2L200.4,198.6z M202.4,200.7l-2-2l2.2,1.9L202.4,200.7z"/>
<path className="st16" d="M203.4,201.6l1.3,1.1l-1.2-1.2L203.4,201.6z M204.6,202.8l-1.2-1.2l1.3,1.1L204.6,202.8z"/>
<path className="st22" d="M203.4,201.6l1.3,1.1l-1.2-1.2L203.4,201.6z M204.6,202.8l-1.2-1.2l1.3,1.1L204.6,202.8z"/>
<path className="st16" d="M200.3,198.6l2.6,2.5l-2.5-2.5H200.3z M202.8,201.3l-2.5-2.6l2.6,2.5L202.8,201.3z"/>
<path className="st22" d="M200.3,198.6l2.6,2.5l-2.5-2.5H200.3z M202.8,201.3l-2.5-2.6l2.6,2.5L202.8,201.3z"/>
<path className="st16" d="M202.8,201.3l1.8,1.6l-1.7-1.7L202.8,201.3z M204.5,202.8l-1.7-1.6l1.8,1.6H204.5z"/>
<path className="st22" d="M202.8,201.3l1.8,1.6l-1.7-1.7L202.8,201.3z M204.5,202.8l-1.7-1.6l1.8,1.6H204.5z"/>
<line className="st19" x1="204.5" y1="199.6" x2="202.1" y2="202.1"/>
<line className="st19" x1="202.6" y1="199.5" x2="201.2" y2="198.2"/>
<line className="st19" x1="203.4" y1="200.4" x2="202.4" y2="199.5"/>
<line className="st19" x1="204.5" y1="201.5" x2="204.1" y2="201.2"/>
<polyline className="st19" points="204.5,202.5 205,202 204.6,201.6 204.1,202.1 "/>
<line className="st19" x1="204.5" y1="201.5" x2="204" y2="202"/>
<line className="st19" x1="204.1" y1="201.2" x2="203.6" y2="201.6"/>
<line className="st19" x1="202.6" y1="199.5" x2="202" y2="200"/>
<line className="st19" x1="201.2" y1="198.2" x2="200.8" y2="198.8"/>
<line className="st19" x1="203.9" y1="200.6" x2="203.6" y2="200.2"/>
<path className="st19" d="M203.1,200.9c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C203,200.7,203,200.8,203.1,200.9"/>
<line className="st19" x1="203.9" y1="200.6" x2="203.4" y2="200.9"/>
<line className="st19" x1="203.6" y1="200.2" x2="203" y2="200.8"/>
<polyline className="st19" points="203.5,201.5 203.4,201.6 202.4,200.7 202.6,200.6 "/>
<path className="st19" d="M203.8,200.1c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C203.7,200,203.7,200.1,203.8,200.1"/>
<path className="st19" d="M204.2,198.3c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3"/>
<path className="st19" d="M205.5,201.3c0.2-0.2,0.4-0.5,0.4-0.7s-0.1-0.6-0.3-0.8"/>
<path className="st19" d="M205.3,201c0.2-0.1,0.2-0.3,0.3-0.5c0-0.2-0.1-0.4-0.2-0.5"/>
<path className="st19" d="M203.9,198.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2"/>
<line className="st19" x1="205.4" y1="200.1" x2="204" y2="198.6"/>
<line className="st19" x1="203" y1="198.8" x2="202.4" y2="199.4"/>
<polyline className="st19" points="205.3,201 204.7,201.8 203.6,200.8 "/>
<line className="st19" x1="205.7" y1="199.8" x2="204.2" y2="198.5"/>
<path className="st19" d="M202.8,198.3c-0.1-0.3-0.4-0.6-0.7-0.6c-0.3-0.1-0.7,0.1-0.9,0.4"/>
<line className="st19" x1="204.7" y1="202.7" x2="200.5" y2="198.5"/>
<polyline className="st19" points="204.7,202.7 204.5,202.8 200.3,198.6 200.5,198.5 "/>
<line className="st19" x1="202.8" y1="198.5" x2="202.2" y2="199.1"/>
<line className="st19" x1="205.6" y1="201.4" x2="204.5" y2="202.5"/>
<path className="st20" d="M203.2,200.7L203.2,200.7z"/>
<path className="st21" d="M203.2,200.7L203.2,200.7z"/>
<path className="st20" d="M203.2,200.7L203.2,200.7z"/>
<path className="st21" d="M203.2,200.7L203.2,200.7z"/>
<path className="st20" d="M203.2,200.7L203.2,200.7z"/>
<path className="st21" d="M203.2,200.7L203.2,200.7z"/>
<polygon className="st20" points="203.2,200.8 203.2,200.7 203,200.8 "/>
<polygon className="st21" points="203.2,200.8 203.2,200.7 203,200.8 "/>
<path className="st20" d="M203.2,200.7v0.1V200.7z"/>
<path className="st21" d="M203.2,200.7v0.1V200.7z"/>
<path className="st20" d="M203.2,200.7L203.2,200.7z"/>
<path className="st21" d="M203.2,200.7L203.2,200.7z"/>
<path className="st16" d="M160.7,137.1l1.8,1.7l-1.4-1.4L160.7,137.1z M162.7,139.2l-2-2.2l1.8,1.7L162.7,139.2z M160.7,137.1l2,2.2
	L160.7,137.1z M160.7,137.1L160.7,137.1l2,2.2L160.7,137.1z"/>
<path className="st22" d="M160.7,137.1l1.8,1.7l-1.4-1.4L160.7,137.1z M162.7,139.2l-2-2.2l1.8,1.7L162.7,139.2z M160.7,137.1l2,2.2
	L160.7,137.1z M160.7,137.1L160.7,137.1l2,2.2L160.7,137.1z"/>
<path className="st16" d="M160.3,137.1l2.4,2.2l-2-2.2H160.3z M162.7,139.5l-2.4-2.4l2.4,2.2V139.5z"/>
<path className="st22" d="M160.3,137.1l2.4,2.2l-2-2.2H160.3z M162.7,139.5l-2.4-2.4l2.4,2.2V139.5z"/>
<path className="st16" d="M160.2,137.1l0.7,0.5l-0.6-0.5H160.2z M160.6,137.4l-0.4-0.4l0.7,0.5L160.6,137.4z"/>
<path className="st22" d="M160.2,137.1l0.7,0.5l-0.6-0.5H160.2z M160.6,137.4l-0.4-0.4l0.7,0.5L160.6,137.4z"/>
<path className="st16" d="M160.6,137.4l1.7,1.6l-1.3-1.4L160.6,137.4z M162.4,139.2l-1.8-1.8l1.7,1.6L162.4,139.2z"/>
<path className="st22" d="M160.6,137.4l1.7,1.6l-1.3-1.4L160.6,137.4z M162.4,139.2l-1.8-1.8l1.7,1.6L162.4,139.2z"/>
<path className="st16" d="M160.1,137.1l0.5,0.4l-0.4-0.4H160.1z M160.4,137.4l-0.4-0.4l0.5,0.4H160.4z"/>
<path className="st22" d="M160.1,137.1l0.5,0.4l-0.4-0.4H160.1z M160.4,137.4l-0.4-0.4l0.5,0.4H160.4z"/>
<path className="st16" d="M160.4,137.4l1.9,1.8l-1.8-1.8H160.4z M162.5,139.4l-2-1.9l1.9,1.8L162.5,139.4z"/>
<path className="st22" d="M160.4,137.4l1.9,1.8l-1.8-1.8H160.4z M162.5,139.4l-2-1.9l1.9,1.8L162.5,139.4z"/>
<path className="st16" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st22" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st16" d="M160.4,137.4l2,1.9L160.4,137.4z M162.5,139.5l-2-2l2,1.9V139.5z"/>
<path className="st22" d="M160.4,137.4l2,1.9L160.4,137.4z M162.5,139.5l-2-2l2,1.9V139.5z"/>
<path className="st16" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st22" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st16" d="M160.4,137.4L160.4,137.4l2,2L160.4,137.4z M162.5,139.5l-2-2L162.5,139.5z"/>
<path className="st22" d="M160.4,137.4L160.4,137.4l2,2L160.4,137.4z M162.5,139.5l-2-2L162.5,139.5z"/>
<path className="st16" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st22" d="M160.1,137.1L160.1,137.1l0.4,0.4L160.1,137.1z M160.4,137.4l-0.4-0.4L160.4,137.4z"/>
<path className="st16" d="M160.3,137.4l2.2,2l-2-2H160.3z M160.3,137.4L160.3,137.4l2.2,2L160.3,137.4z"/>
<path className="st22" d="M160.3,137.4l2.2,2l-2-2H160.3z M160.3,137.4L160.3,137.4l2.2,2L160.3,137.4z"/>
<path className="st16" d="M162.5,139.5h0.2l-0.5-0.5L162.5,139.5z M162.7,139.7l-0.2-0.2h0.2V139.7z"/>
<path className="st22" d="M162.5,139.5h0.2l-0.5-0.5L162.5,139.5z M162.7,139.7l-0.2-0.2h0.2V139.7z"/>
<path className="st16" d="M160.3,137.4L160.3,137.4l2.2,2L160.3,137.4z M162.5,139.5l-2.2-2L162.5,139.5z"/>
<path className="st22" d="M160.3,137.4L160.3,137.4l2.2,2L160.3,137.4z M162.5,139.5l-2.2-2L162.5,139.5z"/>
<path className="st16" d="M162.5,139.5l0.2,0.2L162.5,139.5z M162.7,139.8l-0.2-0.4l0.2,0.2V139.8z"/>
<path className="st22" d="M162.5,139.5l0.2,0.2L162.5,139.5z M162.7,139.8l-0.2-0.4l0.2,0.2V139.8z"/>
<polygon className="st16" points="159.4,136.8 159.7,137.3 159.2,136.8 "/>
<polygon className="st22" points="159.4,136.8 159.7,137.3 159.2,136.8 "/>
<path className="st16" d="M160.1,137.6l2.4,1.9l-2.2-2L160.1,137.6z M162.2,139.8l-2.2-2.3l2.4,1.9L162.2,139.8z"/>
<path className="st22" d="M160.1,137.6l2.4,1.9l-2.2-2L160.1,137.6z M162.2,139.8l-2.2-2.3l2.4,1.9L162.2,139.8z"/>
<path className="st16" d="M162.2,139.8h0.5l-0.2-0.4L162.2,139.8z M162.6,140.1l-0.4-0.2h0.5L162.6,140.1z"/>
<path className="st22" d="M162.2,139.8h0.5l-0.2-0.4L162.2,139.8z M162.6,140.1l-0.4-0.2h0.5L162.6,140.1z"/>
<path className="st16" d="M159.1,136.8l0.6,0.5l-0.5-0.5H159.1z M159.1,136.8L159.1,136.8l0.6,0.5L159.1,136.8z M159.7,137.3l-0.6-0.5
	L159.7,137.3z M159.1,136.8l0.6,0.5L159.1,136.8z"/>
<path className="st22" d="M159.1,136.8l0.6,0.5l-0.5-0.5H159.1z M159.1,136.8L159.1,136.8l0.6,0.5L159.1,136.8z M159.7,137.3l-0.6-0.5
	L159.7,137.3z M159.1,136.8l0.6,0.5L159.1,136.8z"/>
<path className="st16" d="M159.7,137.3l0.7,0.1l-0.4-0.4L159.7,137.3z M160,137.6l-0.2-0.2l0.7,0.1L160,137.6z"/>
<path className="st22" d="M159.7,137.3l0.7,0.1l-0.4-0.4L159.7,137.3z M160,137.6l-0.2-0.2l0.7,0.1L160,137.6z"/>
<path className="st16" d="M159.1,136.8L159.1,136.8l0.6,0.5L159.1,136.8z M159.7,137.3l-0.6-0.5L159.7,137.3z"/>
<path className="st22" d="M159.1,136.8L159.1,136.8l0.6,0.5L159.1,136.8z M159.7,137.3l-0.6-0.5L159.7,137.3z"/>
<path className="st16" d="M159.7,137.3L159.7,137.3l0.2,0.2L159.7,137.3z M160,137.6l-0.2-0.2L160,137.6z"/>
<path className="st22" d="M159.7,137.3L159.7,137.3l0.2,0.2L159.7,137.3z M160,137.6l-0.2-0.2L160,137.6z"/>
<path className="st16" d="M160,137.6l2.3,2.3l-2.2-2.3H160z M162.2,140l-2.3-2.4l2.3,2.3V140z"/>
<path className="st22" d="M160,137.6l2.3,2.3l-2.2-2.3H160z M162.2,140l-2.3-2.4l2.3,2.3V140z"/>
<path className="st16" d="M159,136.7l0.7,0.6l-0.6-0.5L159,136.7z M159.6,137.4l-0.6-0.7l0.7,0.6L159.6,137.4z"/>
<path className="st22" d="M159,136.7l0.7,0.6l-0.6-0.5L159,136.7z M159.6,137.4l-0.6-0.7l0.7,0.6L159.6,137.4z"/>
<path className="st16" d="M159.6,137.4l0.4,0.1l-0.2-0.2L159.6,137.4z M159.8,137.7l-0.2-0.2l0.4,0.1L159.8,137.7z"/>
<path className="st22" d="M159.6,137.4l0.4,0.1l-0.2-0.2L159.6,137.4z M159.8,137.7l-0.2-0.2l0.4,0.1L159.8,137.7z"/>
<path className="st16" d="M159.8,137.7l2.4,2.3l-2.3-2.4L159.8,137.7z M159.8,137.7L159.8,137.7l2.4,2.3L159.8,137.7z"/>
<path className="st22" d="M159.8,137.7l2.4,2.3l-2.3-2.4L159.8,137.7z M159.8,137.7L159.8,137.7l2.4,2.3L159.8,137.7z"/>
<path className="st16" d="M162.2,140l0.4,0.1l-0.4-0.2V140z M162.5,140.2l-0.2-0.2l0.4,0.1L162.5,140.2z"/>
<path className="st22" d="M162.2,140l0.4,0.1l-0.4-0.2V140z M162.5,140.2l-0.2-0.2l0.4,0.1L162.5,140.2z"/>
<path className="st16" d="M159,136.7L159,136.7l0.6,0.7L159,136.7z M159.6,137.4l-0.6-0.7L159.6,137.4z"/>
<path className="st22" d="M159,136.7L159,136.7l0.6,0.7L159,136.7z M159.6,137.4l-0.6-0.7L159.6,137.4z"/>
<path className="st16" d="M159.6,137.4L159.6,137.4l0.2,0.2L159.6,137.4z M159.8,137.7l-0.2-0.2L159.8,137.7z"/>
<path className="st22" d="M159.6,137.4L159.6,137.4l0.2,0.2L159.6,137.4z M159.8,137.7l-0.2-0.2L159.8,137.7z"/>
<path className="st16" d="M159.8,137.7L159.8,137.7l2.4,2.3L159.8,137.7z M162.2,140l-2.4-2.3L162.2,140z"/>
<path className="st22" d="M159.8,137.7L159.8,137.7l2.4,2.3L159.8,137.7z M162.2,140l-2.4-2.3L162.2,140z"/>
<path className="st16" d="M162.2,140l0.2,0.2L162.2,140z M162.4,140.2l-0.1-0.2l0.2,0.2H162.4z"/>
<path className="st22" d="M162.2,140l0.2,0.2L162.2,140z M162.4,140.2l-0.1-0.2l0.2,0.2H162.4z"/>
<path className="st16" d="M158.9,136.7l0.7,0.7l-0.6-0.7H158.9z M158.9,136.7L158.9,136.7l0.7,0.7L158.9,136.7z"/>
<path className="st22" d="M158.9,136.7l0.7,0.7l-0.6-0.7H158.9z M158.9,136.7L158.9,136.7l0.7,0.7L158.9,136.7z"/>
<path className="st16" d="M159.6,137.4L159.6,137.4l0.2,0.2L159.6,137.4z M159.8,137.7l-0.2-0.2L159.8,137.7z"/>
<path className="st22" d="M159.6,137.4L159.6,137.4l0.2,0.2L159.6,137.4z M159.8,137.7l-0.2-0.2L159.8,137.7z"/>
<path className="st16" d="M159.8,137.7l2.4,2.3L159.8,137.7z M162.1,140.1l-2.3-2.4l2.4,2.3L162.1,140.1z"/>
<path className="st22" d="M159.8,137.7l2.4,2.3L159.8,137.7z M162.1,140.1l-2.3-2.4l2.4,2.3L162.1,140.1z"/>
<path className="st16" d="M162.1,140.1l0.2,0.1l-0.1-0.2L162.1,140.1z M162.4,140.3l-0.2-0.2l0.2,0.1V140.3z"/>
<path className="st22" d="M162.1,140.1l0.2,0.1l-0.1-0.2L162.1,140.1z M162.4,140.3l-0.2-0.2l0.2,0.1V140.3z"/>
<path className="st16" d="M158.8,136.7l0.8,0.7l-0.7-0.7H158.8z M159.5,137.6l-0.7-0.8l0.8,0.7L159.5,137.6z"/>
<path className="st22" d="M158.8,136.7l0.8,0.7l-0.7-0.7H158.8z M159.5,137.6l-0.7-0.8l0.8,0.7L159.5,137.6z"/>
<path className="st16" d="M159.5,137.6l0.4,0.1l-0.2-0.2L159.5,137.6z M159.7,137.8l-0.2-0.2l0.4,0.1L159.7,137.8z"/>
<path className="st22" d="M159.5,137.6l0.4,0.1l-0.2-0.2L159.5,137.6z M159.7,137.8l-0.2-0.2l0.4,0.1L159.7,137.8z"/>
<path className="st16" d="M159.7,137.8l1.2,1.1l-1.1-1.2L159.7,137.8z M160.8,138.9l-1.1-1.1l1.2,1.1H160.8z"/>
<path className="st22" d="M159.7,137.8l1.2,1.1l-1.1-1.2L159.7,137.8z M160.8,138.9l-1.1-1.1l1.2,1.1H160.8z"/>
<path className="st16" d="M161,139l1.1,1.1l-1.2-1.2L161,139z M161,139L161,139l1.1,1.1L161,139z"/>
<path className="st22" d="M161,139l1.1,1.1l-1.2-1.2L161,139z M161,139L161,139l1.1,1.1L161,139z"/>
<path className="st16" d="M162.1,140.1L162.1,140.1l0.2,0.2L162.1,140.1z M162.4,140.3l-0.2-0.2L162.4,140.3z"/>
<path className="st22" d="M162.1,140.1L162.1,140.1l0.2,0.2L162.1,140.1z M162.4,140.3l-0.2-0.2L162.4,140.3z"/>
<path className="st16" d="M158.6,136.7l0.8,0.8l-0.7-0.8H158.6z M158.6,136.7L158.6,136.7l0.8,0.8L158.6,136.7z"/>
<path className="st22" d="M158.6,136.7l0.8,0.8l-0.7-0.8H158.6z M158.6,136.7L158.6,136.7l0.8,0.8L158.6,136.7z"/>
<path className="st16" d="M159.5,137.6L159.5,137.6l0.2,0.2L159.5,137.6z M159.7,137.8l-0.2-0.2L159.7,137.8z"/>
<path className="st22" d="M159.5,137.6L159.5,137.6l0.2,0.2L159.5,137.6z M159.7,137.8l-0.2-0.2L159.7,137.8z"/>
<path className="st16" d="M158.6,136.7L158.6,136.7l0.8,0.8L158.6,136.7z M159.5,137.6l-0.8-0.8L159.5,137.6z"/>
<path className="st22" d="M158.6,136.7L158.6,136.7l0.8,0.8L158.6,136.7z M159.5,137.6l-0.8-0.8L159.5,137.6z"/>
<path className="st16" d="M159.5,137.6l0.2,0.2L159.5,137.6z M159.6,137.8l-0.1-0.2l0.2,0.2H159.6z"/>
<path className="st22" d="M159.5,137.6l0.2,0.2L159.5,137.6z M159.6,137.8l-0.1-0.2l0.2,0.2H159.6z"/>
<path className="st16" d="M159.6,137.8l1.2,1.1l-1.1-1.1H159.6z M160.7,138.9l-1.1-1.1l1.2,1.1H160.7z"/>
<path className="st22" d="M159.6,137.8l1.2,1.1l-1.1-1.1H159.6z M160.7,138.9l-1.1-1.1l1.2,1.1H160.7z"/>
<path className="st16" d="M160.9,139.1l1.2,1L161,139L160.9,139.1z M162,140.2l-1.1-1.1l1.2,1L162,140.2z"/>
<path className="st22" d="M160.9,139.1l1.2,1L161,139L160.9,139.1z M162,140.2l-1.1-1.1l1.2,1L162,140.2z"/>
<path className="st16" d="M159.6,137.9l1.1,1l-1.1-1.1V137.9z M160.7,139l-1.1-1.1l1.1,1V139z"/>
<path className="st22" d="M159.6,137.9l1.1,1l-1.1-1.1V137.9z M160.7,139l-1.1-1.1l1.1,1V139z"/>
<path className="st16" d="M160.8,139.2l1.2,1l-1.1-1.1L160.8,139.2z M161.9,140.3l-1.1-1.1l1.2,1L161.9,140.3z"/>
<path className="st22" d="M160.8,139.2l1.2,1l-1.1-1.1L160.8,139.2z M161.9,140.3l-1.1-1.1l1.2,1L161.9,140.3z"/>
<path className="st16" d="M159.5,138l1.2,1l-1.1-1.1L159.5,138z M160.7,139.1l-1.2-1.1l1.2,1V139.1z"/>
<path className="st22" d="M159.5,138l1.2,1l-1.1-1.1L159.5,138z M160.7,139.1l-1.2-1.1l1.2,1V139.1z"/>
<path className="st16" d="M160.7,139.1l1.2,1.2l-1.1-1.1L160.7,139.1z M160.7,139.1L160.7,139.1l1.2,1.2L160.7,139.1z"/>
<path className="st22" d="M160.7,139.1l1.2,1.2l-1.1-1.1L160.7,139.1z M160.7,139.1L160.7,139.1l1.2,1.2L160.7,139.1z"/>
<path className="st16" d="M158.3,137l1.2,0.6l-0.8-0.8L158.3,137z M159.2,137.8l-1-0.8l1.2,0.6L159.2,137.8z"/>
<path className="st22" d="M158.3,137l1.2,0.6l-0.8-0.8L158.3,137z M159.2,137.8l-1-0.8l1.2,0.6L159.2,137.8z"/>
<path className="st16" d="M159.2,137.8h0.4l-0.1-0.2L159.2,137.8z M159.5,138l-0.2-0.2h0.4L159.5,138z"/>
<path className="st22" d="M159.2,137.8h0.4l-0.1-0.2L159.2,137.8z M159.5,138l-0.2-0.2h0.4L159.5,138z"/>
<path className="st16" d="M159.5,138l1.2,1.1L159.5,138z M160.6,139.2l-1.1-1.2l1.2,1.1L160.6,139.2z"/>
<path className="st22" d="M159.5,138l1.2,1.1L159.5,138z M160.6,139.2l-1.1-1.2l1.2,1.1L160.6,139.2z"/>
<path className="st16" d="M160.6,139.2l1.3,1.1l-1.2-1.2L160.6,139.2z M161.8,140.4l-1.2-1.2l1.3,1.1L161.8,140.4z"/>
<path className="st22" d="M160.6,139.2l1.3,1.1l-1.2-1.2L160.6,139.2z M161.8,140.4l-1.2-1.2l1.3,1.1L161.8,140.4z"/>
<path className="st16" d="M158.2,137.1l1.1,0.7l-1-0.8L158.2,137.1z M159,138l-0.8-1l1.1,0.7L159,138z"/>
<path className="st22" d="M158.2,137.1l1.1,0.7l-1-0.8L158.2,137.1z M159,138l-0.8-1l1.1,0.7L159,138z"/>
<path className="st16" d="M159,138h0.5l-0.2-0.2L159,138z M159.2,138.3L159,138h0.5L159.2,138.3z"/>
<path className="st22" d="M159,138h0.5l-0.2-0.2L159,138z M159.2,138.3L159,138h0.5L159.2,138.3z"/>
<path className="st16" d="M159.2,138.3l1.2,0.8l-1-1.1L159.2,138.3z M160.3,139.2l-1.1-1l1.2,0.8L160.3,139.2z"/>
<path className="st22" d="M159.2,138.3l1.2,0.8l-1-1.1L159.2,138.3z M160.3,139.2l-1.1-1l1.2,0.8L160.3,139.2z"/>
<path className="st16" d="M158,137.2l1.3,1.1l-1.2-1.2L158,137.2z M159.2,138.4l-1.2-1.2l1.3,1.1L159.2,138.4z"/>
<path className="st22" d="M158,137.2l1.3,1.1l-1.2-1.2L158,137.2z M159.2,138.4l-1.2-1.2l1.3,1.1L159.2,138.4z"/>
<path className="st16" d="M159.2,138.4l1.1,0.8l-1-1L159.2,138.4z M160.2,139.4l-1-1l1.1,0.8L160.2,139.4z"/>
<path className="st22" d="M159.2,138.4l1.1,0.8l-1-1L159.2,138.4z M160.2,139.4l-1-1l1.1,0.8L160.2,139.4z"/>
<path className="st16" d="M160.6,139.6l1.2,0.8l-1-1.1L160.6,139.6z M161.5,140.7l-1-1.1l1.2,0.8L161.5,140.7z"/>
<path className="st22" d="M160.6,139.6l1.2,0.8l-1-1.1L160.6,139.6z M161.5,140.7l-1-1.1l1.2,0.8L161.5,140.7z"/>
<path className="st16" d="M161.5,140.7l0.8-0.4l-0.2-0.2L161.5,140.7z M161.8,140.9l-0.2-0.2l0.8-0.4L161.8,140.9z"/>
<path className="st22" d="M161.5,140.7l0.8-0.4l-0.2-0.2L161.5,140.7z M161.8,140.9l-0.2-0.2l0.8-0.4L161.8,140.9z"/>
<path className="st16" d="M159.1,138.6l1.1,0.7l-1-1L159.1,138.6z M160.1,139.5l-1-0.8l1.1,0.7L160.1,139.5z M160.1,139.6v-0.1l-1-0.8
	L160.1,139.6z"/>
<path className="st22" d="M159.1,138.6l1.1,0.7l-1-1L159.1,138.6z M160.1,139.5l-1-0.8l1.1,0.7L160.1,139.5z M160.1,139.6v-0.1l-1-0.8
	L160.1,139.6z"/>
<path className="st16" d="M160.1,139.5l-1-0.8L160.1,139.5z"/>
<path className="st22" d="M160.1,139.5l-1-0.8L160.1,139.5z"/>
<path className="st16" d="M159,138.6l1.1,1l-1-1H159z M160,139.6l-1-1l1.1,1H160z"/>
<path className="st22" d="M159,138.6l1.1,1l-1-1H159z M160,139.6l-1-1l1.1,1H160z"/>
<path className="st16" d="M160.3,139.8l0.6,0.2l-0.4-0.5L160.3,139.8z M160.7,140.3l-0.4-0.5l0.6,0.2L160.7,140.3z"/>
<path className="st22" d="M160.3,139.8l0.6,0.2l-0.4-0.5L160.3,139.8z M160.7,140.3l-0.4-0.5l0.6,0.2L160.7,140.3z"/>
<path className="st16" d="M159,138.8l1,0.8l-1-1V138.8z M160,139.7l-1-1l1,0.8V139.7z"/>
<path className="st22" d="M159,138.8l1,0.8l-1-1V138.8z M160,139.7l-1-1l1,0.8V139.7z"/>
<path className="st16" d="M160.1,140l0.6,0.4l-0.4-0.5L160.1,140z M160.7,140.4l-0.6-0.5l0.6,0.4V140.4z"/>
<path className="st22" d="M160.1,140l0.6,0.4l-0.4-0.5L160.1,140z M160.7,140.4l-0.6-0.5l0.6,0.4V140.4z"/>
<path className="st16" d="M157.7,137.6l1.6,0.8l-1.2-1.2L157.7,137.6z M158.9,138.8l-1.2-1.2l1.6,0.8L158.9,138.8z"/>
<path className="st22" d="M157.7,137.6l1.6,0.8l-1.2-1.2L157.7,137.6z M158.9,138.8l-1.2-1.2l1.6,0.8L158.9,138.8z"/>
<path className="st16" d="M158.9,138.8l1.1,1l-1-1H158.9z M160,139.8l-1.1-1.1l1.1,1V139.8z"/>
<path className="st22" d="M158.9,138.8l1.1,1l-1-1H158.9z M160,139.8l-1.1-1.1l1.1,1V139.8z"/>
<path className="st16" d="M160,139.8l0.7,0.6l-0.6-0.5L160,139.8z M160.6,140.4l-0.6-0.6l0.7,0.6H160.6z"/>
<path className="st22" d="M160,139.8l0.7,0.6l-0.6-0.5L160,139.8z M160.6,140.4l-0.6-0.6l0.7,0.6H160.6z"/>
<path className="st16" d="M157.6,137.7l1.3,1.1l-1.2-1.2L157.6,137.7z M158.9,138.9l-1.3-1.2l1.3,1.1V138.9z"/>
<path className="st22" d="M157.6,137.7l1.3,1.1l-1.2-1.2L157.6,137.7z M158.9,138.9l-1.3-1.2l1.3,1.1V138.9z"/>
<path className="st16" d="M158.9,138.9l1.1,1l-1.1-1.1V138.9z M159.8,140l-1-1.1l1.1,1L159.8,140z"/>
<path className="st22" d="M158.9,138.9l1.1,1l-1.1-1.1V138.9z M159.8,140l-1-1.1l1.1,1L159.8,140z"/>
<path className="st16" d="M159.8,140l0.7,0.5l-0.6-0.6L159.8,140z M160.6,140.6l-0.7-0.6l0.7,0.5V140.6z"/>
<path className="st22" d="M159.8,140l0.7,0.5l-0.6-0.6L159.8,140z M160.6,140.6l-0.7-0.6l0.7,0.5V140.6z"/>
<path className="st16" d="M160.6,140.6l0.7-0.1l-0.4-0.4L160.6,140.6z M160.8,140.9l-0.2-0.4l0.7-0.1L160.8,140.9z"/>
<path className="st22" d="M160.6,140.6l0.7-0.1l-0.4-0.4L160.6,140.6z M160.8,140.9l-0.2-0.4l0.7-0.1L160.8,140.9z"/>
<path className="st16" d="M160.8,140.9l0.6-0.4l-0.1-0.1L160.8,140.9z M160.9,141l-0.1-0.1l0.6-0.4L160.9,141z"/>
<path className="st22" d="M160.8,140.9l0.6-0.4l-0.1-0.1L160.8,140.9z M160.9,141l-0.1-0.1l0.6-0.4L160.9,141z"/>
<path className="st16" d="M160.9,141l0.8-0.1l-0.4-0.4L160.9,141z M161.3,141.4l-0.4-0.4l0.8-0.1L161.3,141.4z"/>
<path className="st22" d="M160.9,141l0.8-0.1l-0.4-0.4L160.9,141z M161.3,141.4l-0.4-0.4l0.8-0.1L161.3,141.4z"/>
<path className="st16" d="M157.2,137.4l2.3,2l-2.2-2H157.2z M159.4,139.6l-2.2-2.2l2.3,2L159.4,139.6z"/>
<path className="st22" d="M157.2,137.4l2.3,2l-2.2-2H157.2z M159.4,139.6l-2.2-2.2l2.3,2L159.4,139.6z"/>
<path className="st16" d="M160.3,140.6l1.2,1.1l-1.2-1.2V140.6z M161.4,141.6l-1.1-1.1l1.2,1.1H161.4z"/>
<path className="st22" d="M160.3,140.6l1.2,1.1l-1.2-1.2V140.6z M161.4,141.6l-1.1-1.1l1.2,1.1H161.4z"/>
<path className="st16" d="M157.2,137.6l2.6,2.5l-2.6-2.6V137.6z M159.7,140.2l-2.5-2.6l2.6,2.5L159.7,140.2z"/>
<path className="st22" d="M157.2,137.6l2.6,2.5l-2.6-2.6V137.6z M159.7,140.2l-2.5-2.6l2.6,2.5L159.7,140.2z"/>
<path className="st16" d="M159.7,140.2l1.7,1.4l-1.6-1.6L159.7,140.2z M161.4,141.8l-1.7-1.6l1.7,1.4V141.8z"/>
<path className="st22" d="M159.7,140.2l1.7,1.4l-1.6-1.6L159.7,140.2z M161.4,141.8l-1.7-1.6l1.7,1.4V141.8z"/>
<line className="st19" x1="161.3" y1="138.5" x2="158.9" y2="140.9"/>
<line className="st19" x1="159.4" y1="138.3" x2="158.2" y2="137.1"/>
<line className="st19" x1="160.2" y1="139.4" x2="159.2" y2="138.4"/>
<line className="st19" x1="161.3" y1="140.4" x2="160.9" y2="140.1"/>
<polyline className="st19" points="161.3,141.4 161.8,140.9 161.4,140.6 160.9,141 "/>
<line className="st19" x1="161.3" y1="140.4" x2="160.8" y2="140.9"/>
<line className="st19" x1="160.9" y1="140.1" x2="160.6" y2="140.6"/>
<line className="st19" x1="159.4" y1="138.3" x2="158.9" y2="138.9"/>
<line className="st19" x1="158.2" y1="137.1" x2="157.6" y2="137.7"/>
<line className="st19" x1="160.8" y1="139.4" x2="160.4" y2="139.1"/>
<path className="st19" d="M159.9,139.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C159.8,139.6,159.8,139.7,159.9,139.8"/>
<line className="st19" x1="160.8" y1="139.4" x2="160.3" y2="139.8"/>
<line className="st19" x1="160.4" y1="139.1" x2="160" y2="139.6"/>
<polyline className="st19" points="160.3,140.4 160.3,140.6 159.4,139.6 159.5,139.5 "/>
<path className="st19" d="M160.6,139.1c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C160.5,138.9,160.5,139,160.6,139.1"/>
<path className="st19" d="M161.1,137.3c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3"/>
<path className="st19" d="M162.3,140.2c0.2-0.2,0.4-0.5,0.4-0.7s-0.1-0.6-0.3-0.8"/>
<path className="st19" d="M162.1,139.9c0.2-0.1,0.2-0.3,0.3-0.5c0-0.2-0.1-0.4-0.2-0.5"/>
<path className="st19" d="M160.8,137.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2"/>
<line className="st19" x1="162.2" y1="139" x2="160.9" y2="137.6"/>
<line className="st19" x1="160" y1="137.6" x2="159.2" y2="138.3"/>
<polyline className="st19" points="162.2,140 161.5,140.7 160.6,139.6 "/>
<line className="st19" x1="162.5" y1="138.8" x2="161" y2="137.3"/>
<path className="st19" d="M159.7,137.2c-0.1-0.3-0.4-0.6-0.7-0.6c-0.3-0.1-0.7,0.1-0.9,0.4"/>
<line className="st19" x1="161.5" y1="141.6" x2="157.3" y2="137.4"/>
<polyline className="st19" points="161.5,141.6 161.4,141.8 157.2,137.6 157.3,137.4 "/>
<line className="st19" x1="159.7" y1="137.3" x2="159" y2="138"/>
<line className="st19" x1="162.4" y1="140.2" x2="161.3" y2="141.4"/>
<path className="st20" d="M160.1,139.5v0.1V139.5z"/>
<path className="st21" d="M160.1,139.5v0.1V139.5z"/>
<path className="st20" d="M160.1,139.6H160H160.1z"/>
<path className="st21" d="M160.1,139.6H160H160.1z"/>
<path className="st20" d="M160.1,139.6H160H160.1z"/>
<path className="st21" d="M160.1,139.6H160H160.1z"/>
<path className="st20" d="M160,139.6L160,139.6z"/>
<path className="st21" d="M160,139.6L160,139.6z"/>
<path className="st20" d="M160,139.6L160,139.6z"/>
<path className="st21" d="M160,139.6L160,139.6z"/>
<path className="st20" d="M160,139.6L160,139.6z"/>
<path className="st21" d="M160,139.6L160,139.6z"/>
<path className="st16" d="M46.4,199.7l-1.9-1.7l1.4,1.4L46.4,199.7z M44.3,197.6l2.2,2.2l-1.9-1.7L44.3,197.6z M46.4,199.7l-2.2-2.2
	L46.4,199.7z M46.4,199.7L46.4,199.7l-2.2-2.2L46.4,199.7z"/>
<path className="st22" d="M46.4,199.7l-1.9-1.7l1.4,1.4L46.4,199.7z M44.3,197.6l2.2,2.2l-1.9-1.7L44.3,197.6z M46.4,199.7l-2.2-2.2
	L46.4,199.7z M46.4,199.7L46.4,199.7l-2.2-2.2L46.4,199.7z"/>
<path className="st16" d="M46.7,199.7l-2.4-2.2l2.2,2.2H46.7z M44.3,197.3l2.4,2.4l-2.4-2.2V197.3z"/>
<path className="st22" d="M46.7,199.7l-2.4-2.2l2.2,2.2H46.7z M44.3,197.3l2.4,2.4l-2.4-2.2V197.3z"/>
<path className="st16" d="M46.8,199.7l-0.6-0.5l0.5,0.5H46.8z M46.4,199.4l0.4,0.4l-0.6-0.5L46.4,199.4z"/>
<path className="st22" d="M46.8,199.7l-0.6-0.5l0.5,0.5H46.8z M46.4,199.4l0.4,0.4l-0.6-0.5L46.4,199.4z"/>
<path className="st16" d="M46.4,199.4l-1.7-1.6l1.4,1.4L46.4,199.4z M44.6,197.4l1.8,1.9l-1.7-1.6L44.6,197.4z"/>
<path className="st22" d="M46.4,199.4l-1.7-1.6l1.4,1.4L46.4,199.4z M44.6,197.4l1.8,1.9l-1.7-1.6L44.6,197.4z"/>
<path className="st16" d="M46.9,199.7l-0.5-0.4l0.4,0.4H46.9z M46.6,199.4l0.4,0.4l-0.5-0.4H46.6z"/>
<path className="st22" d="M46.9,199.7l-0.5-0.4l0.4,0.4H46.9z M46.6,199.4l0.4,0.4l-0.5-0.4H46.6z"/>
<path className="st16" d="M46.6,199.4l-1.9-1.9l1.8,1.9H46.6z M46.6,199.4L46.6,199.4l-1.9-1.9L46.6,199.4z"/>
<path className="st22" d="M46.6,199.4l-1.9-1.9l1.8,1.9H46.6z M46.6,199.4L46.6,199.4l-1.9-1.9L46.6,199.4z"/>
<path className="st16" d="M46.9,199.7l-0.4-0.4L46.9,199.7z M46.7,199.4l0.2,0.4l-0.4-0.4H46.7z"/>
<path className="st22" d="M46.9,199.7l-0.4-0.4L46.9,199.7z M46.7,199.4l0.2,0.4l-0.4-0.4H46.7z"/>
<path className="st16" d="M46.7,199.4l-2-1.9l1.9,1.9H46.7z M44.6,197.3l2,2l-2-1.9V197.3z"/>
<path className="st22" d="M46.7,199.4l-2-1.9l1.9,1.9H46.7z M44.6,197.3l2,2l-2-1.9V197.3z"/>
<path className="st16" d="M46.9,199.7L46.9,199.7l-0.2-0.4L46.9,199.7z M46.7,199.4l0.2,0.4L46.7,199.4z"/>
<path className="st22" d="M46.9,199.7L46.9,199.7l-0.2-0.4L46.9,199.7z M46.7,199.4l0.2,0.4L46.7,199.4z"/>
<path className="st16" d="M46.7,199.4L46.7,199.4l-2-2L46.7,199.4z M44.6,197.3l2,2L44.6,197.3z"/>
<path className="st22" d="M46.7,199.4L46.7,199.4l-2-2L46.7,199.4z M44.6,197.3l2,2L44.6,197.3z"/>
<path className="st16" d="M46.9,199.7L46.9,199.7l-0.2-0.4L46.9,199.7z M46.7,199.4l0.2,0.4L46.7,199.4z"/>
<path className="st22" d="M46.9,199.7L46.9,199.7l-0.2-0.4L46.9,199.7z M46.7,199.4l0.2,0.4L46.7,199.4z"/>
<path className="st16" d="M46.7,199.4l-2-2L46.7,199.4z M44.5,197.3l2.2,2l-2-2H44.5z"/>
<path className="st22" d="M46.7,199.4l-2-2L46.7,199.4z M44.5,197.3l2.2,2l-2-2H44.5z"/>
<path className="st16" d="M44.5,197.3h-0.2l0.5,0.5L44.5,197.3z M44.3,197l0.2,0.4h-0.2V197z"/>
<path className="st22" d="M44.5,197.3h-0.2l0.5,0.5L44.5,197.3z M44.3,197l0.2,0.4h-0.2V197z"/>
<path className="st16" d="M46.7,199.4L46.7,199.4l-2.2-2L46.7,199.4z M44.5,197.3l2.2,2L44.5,197.3z"/>
<path className="st22" d="M46.7,199.4L46.7,199.4l-2.2-2L46.7,199.4z M44.5,197.3l2.2,2L44.5,197.3z"/>
<path className="st16" d="M44.5,197.3L44.5,197.3l-0.2-0.4L44.5,197.3z M44.3,197l0.2,0.4L44.3,197z"/>
<path className="st22" d="M44.5,197.3L44.5,197.3l-0.2-0.4L44.5,197.3z M44.3,197l0.2,0.4L44.3,197z"/>
<polygon className="st16" points="47.8,200 47.3,199.5 47.9,200 "/>
<polygon className="st22" points="47.8,200 47.3,199.5 47.9,200 "/>
<path className="st16" d="M47,199.2l-2.5-1.9l2.2,2L47,199.2z M44.8,197l2.3,2.3l-2.5-1.9L44.8,197z"/>
<path className="st22" d="M47,199.2l-2.5-1.9l2.2,2L47,199.2z M44.8,197l2.3,2.3l-2.5-1.9L44.8,197z"/>
<path className="st16" d="M44.8,197h-0.5l0.2,0.4L44.8,197z M44.5,196.7l0.2,0.2h-0.5L44.5,196.7z"/>
<path className="st22" d="M44.8,197h-0.5l0.2,0.4L44.8,197z M44.5,196.7l0.2,0.2h-0.5L44.5,196.7z"/>
<path className="st16" d="M47.9,200l-0.6-0.5h0.1L47.9,200z M47.9,200L47.9,200l-0.6-0.5L47.9,200z M47.3,199.5l0.6,0.5L47.3,199.5z
	 M47.9,200l-0.6-0.5L47.9,200z"/>
<path className="st22" d="M47.9,200l-0.6-0.5h0.1L47.9,200z M47.9,200L47.9,200l-0.6-0.5L47.9,200z M47.3,199.5l0.6,0.5L47.3,199.5z
	 M47.9,200l-0.6-0.5L47.9,200z"/>
<path className="st16" d="M47.4,199.5l-0.7-0.1l0.2,0.4L47.4,199.5z M47.2,199.2l0.2,0.2l-0.7-0.1L47.2,199.2z"/>
<path className="st22" d="M47.4,199.5l-0.7-0.1l0.2,0.4L47.4,199.5z M47.2,199.2l0.2,0.2l-0.7-0.1L47.2,199.2z"/>
<path className="st16" d="M47.9,200L47.9,200l-0.5-0.5L47.9,200z M47.4,199.5l0.5,0.5L47.4,199.5z"/>
<path className="st22" d="M47.9,200L47.9,200l-0.5-0.5L47.9,200z M47.4,199.5l0.5,0.5L47.4,199.5z"/>
<path className="st16" d="M47.4,199.5L47.4,199.5l-0.2-0.2L47.4,199.5z M47.2,199.2l0.2,0.2L47.2,199.2z"/>
<path className="st22" d="M47.4,199.5L47.4,199.5l-0.2-0.2L47.4,199.5z M47.2,199.2l0.2,0.2L47.2,199.2z"/>
<path className="st16" d="M47.2,199.2l-2.4-2.3l2.3,2.3H47.2z M44.8,196.8l2.4,2.4l-2.4-2.3V196.8z"/>
<path className="st22" d="M47.2,199.2l-2.4-2.3l2.3,2.3H47.2z M44.8,196.8l2.4,2.4l-2.4-2.3V196.8z"/>
<path className="st16" d="M48,200.1l-0.6-0.6l0.5,0.5L48,200.1z M47.4,199.4l0.6,0.7l-0.6-0.6V199.4z"/>
<path className="st22" d="M48,200.1l-0.6-0.6l0.5,0.5L48,200.1z M47.4,199.4l0.6,0.7l-0.6-0.6V199.4z"/>
<path className="st16" d="M47.4,199.4l-0.2-0.1l0.2,0.2V199.4z M47.2,199.1l0.2,0.2l-0.2-0.1V199.1z"/>
<path className="st22" d="M47.4,199.4l-0.2-0.1l0.2,0.2V199.4z M47.2,199.1l0.2,0.2l-0.2-0.1V199.1z"/>
<path className="st16" d="M47.2,199.1l-2.4-2.3l2.4,2.4V199.1z M44.9,196.8l2.3,2.3l-2.4-2.3H44.9z"/>
<path className="st22" d="M47.2,199.1l-2.4-2.3l2.4,2.4V199.1z M44.9,196.8l2.3,2.3l-2.4-2.3H44.9z"/>
<path className="st16" d="M44.9,196.8l-0.4-0.1l0.2,0.2L44.9,196.8z M44.6,196.6l0.2,0.2l-0.4-0.1L44.6,196.6z"/>
<path className="st22" d="M44.9,196.8l-0.4-0.1l0.2,0.2L44.9,196.8z M44.6,196.6l0.2,0.2l-0.4-0.1L44.6,196.6z"/>
<path className="st16" d="M48.1,200.1l-0.7-0.7l0.6,0.7H48.1z M48.1,200.1L48.1,200.1l-0.7-0.7L48.1,200.1z"/>
<path className="st22" d="M48.1,200.1l-0.7-0.7l0.6,0.7H48.1z M48.1,200.1L48.1,200.1l-0.7-0.7L48.1,200.1z"/>
<path className="st16" d="M47.4,199.4L47.4,199.4l-0.2-0.2L47.4,199.4z M47.2,199.1l0.2,0.2L47.2,199.1z"/>
<path className="st22" d="M47.4,199.4L47.4,199.4l-0.2-0.2L47.4,199.4z M47.2,199.1l0.2,0.2L47.2,199.1z"/>
<path className="st16" d="M47.2,199.1L47.2,199.1l-2.3-2.3L47.2,199.1z M44.9,196.8l2.3,2.3L44.9,196.8z"/>
<path className="st22" d="M47.2,199.1L47.2,199.1l-2.3-2.3L47.2,199.1z M44.9,196.8l2.3,2.3L44.9,196.8z"/>
<path className="st16" d="M44.9,196.8L44.9,196.8l-0.2-0.2L44.9,196.8z M44.6,196.6l0.2,0.2L44.6,196.6z"/>
<path className="st22" d="M44.9,196.8L44.9,196.8l-0.2-0.2L44.9,196.8z M44.6,196.6l0.2,0.2L44.6,196.6z"/>
<path className="st16" d="M48.2,200.1l-0.8-0.7l0.7,0.7H48.2z M47.5,199.4l0.7,0.7l-0.8-0.7H47.5z"/>
<path className="st22" d="M48.2,200.1l-0.8-0.7l0.7,0.7H48.2z M47.5,199.4l0.7,0.7l-0.8-0.7H47.5z"/>
<path className="st16" d="M47.5,199.4l-0.4-0.2l0.2,0.2H47.5z M47.3,199.1l0.2,0.2l-0.4-0.2H47.3z"/>
<path className="st22" d="M47.5,199.4l-0.4-0.2l0.2,0.2H47.5z M47.3,199.1l0.2,0.2l-0.4-0.2H47.3z"/>
<path className="st16" d="M47.3,199.1l-2.4-2.3l2.3,2.3H47.3z M44.9,196.7l2.4,2.4l-2.4-2.3V196.7z"/>
<path className="st22" d="M47.3,199.1l-2.4-2.3l2.3,2.3H47.3z M44.9,196.7l2.4,2.4l-2.4-2.3V196.7z"/>
<path className="st16" d="M44.9,196.7l-0.2-0.1l0.2,0.2V196.7z M44.6,196.5l0.2,0.2l-0.2-0.1V196.5z"/>
<path className="st22" d="M44.9,196.7l-0.2-0.1l0.2,0.2V196.7z M44.6,196.5l0.2,0.2l-0.2-0.1V196.5z"/>
<path className="st16" d="M48.4,200.1l-0.8-0.7l0.7,0.7H48.4z M47.5,199.2l0.8,0.8l-0.8-0.7V199.2z"/>
<path className="st22" d="M48.4,200.1l-0.8-0.7l0.7,0.7H48.4z M47.5,199.2l0.8,0.8l-0.8-0.7V199.2z"/>
<path className="st16" d="M47.5,199.2l-0.2-0.1l0.2,0.2V199.2z M47.3,199l0.2,0.2l-0.2-0.1V199z"/>
<path className="st22" d="M47.5,199.2l-0.2-0.1l0.2,0.2V199.2z M47.3,199l0.2,0.2l-0.2-0.1V199z"/>
<path className="st16" d="M47.3,199l-1.2-1.1l1.2,1.2V199z M46.2,197.9l1.1,1.1l-1.2-1.1H46.2z"/>
<path className="st22" d="M47.3,199l-1.2-1.1l1.2,1.2V199z M46.2,197.9l1.1,1.1l-1.2-1.1H46.2z"/>
<path className="st16" d="M46,197.8l-1.1-1.1l1.2,1.2L46,197.8z M46,197.8L46,197.8l-1.1-1.1L46,197.8z"/>
<path className="st22" d="M46,197.8l-1.1-1.1l1.2,1.2L46,197.8z M46,197.8L46,197.8l-1.1-1.1L46,197.8z"/>
<path className="st16" d="M44.9,196.7l-0.2-0.2L44.9,196.7z M44.8,196.5l0.1,0.2l-0.2-0.2H44.8z"/>
<path className="st22" d="M44.9,196.7l-0.2-0.2L44.9,196.7z M44.8,196.5l0.1,0.2l-0.2-0.2H44.8z"/>
<path className="st16" d="M48.4,200.1L48.4,200.1l-0.8-0.8L48.4,200.1z M47.5,199.2l0.8,0.8L47.5,199.2z"/>
<path className="st22" d="M48.4,200.1L48.4,200.1l-0.8-0.8L48.4,200.1z M47.5,199.2l0.8,0.8L47.5,199.2z"/>
<path className="st16" d="M47.5,199.2L47.5,199.2l-0.2-0.2L47.5,199.2z M47.3,199l0.2,0.2L47.3,199z"/>
<path className="st22" d="M47.5,199.2L47.5,199.2l-0.2-0.2L47.5,199.2z M47.3,199l0.2,0.2L47.3,199z"/>
<path className="st16" d="M48.5,200l-1-0.7l0.8,0.8L48.5,200z M47.6,199.1l0.8,0.8l-1-0.7L47.6,199.1z"/>
<path className="st22" d="M48.5,200l-1-0.7l0.8,0.8L48.5,200z M47.6,199.1l0.8,0.8l-1-0.7L47.6,199.1z"/>
<path className="st16" d="M47.6,199.1l-0.4-0.1l0.2,0.2L47.6,199.1z M47.4,198.9l0.2,0.2l-0.4-0.1L47.4,198.9z"/>
<path className="st22" d="M47.6,199.1l-0.4-0.1l0.2,0.2L47.6,199.1z M47.4,198.9l0.2,0.2l-0.4-0.1L47.4,198.9z"/>
<path className="st16" d="M47.4,198.9l-1.2-1l1.1,1.1L47.4,198.9z M46.3,197.9l1.1,1l-1.2-1H46.3z"/>
<path className="st22" d="M47.4,198.9l-1.2-1l1.1,1.1L47.4,198.9z M46.3,197.9l1.1,1l-1.2-1H46.3z"/>
<path className="st16" d="M46.1,197.7l-1.2-1l1.1,1.1L46.1,197.7z M45,196.6l1.1,1.1l-1.2-1L45,196.6z"/>
<path className="st22" d="M46.1,197.7l-1.2-1l1.1,1.1L46.1,197.7z M45,196.6l1.1,1.1l-1.2-1L45,196.6z"/>
<path className="st16" d="M47.5,198.9l-1.2-1l1.1,1H47.5z M46.4,197.8l1.1,1.1l-1.2-1L46.4,197.8z"/>
<path className="st22" d="M47.5,198.9l-1.2-1l1.1,1H47.5z M46.4,197.8l1.1,1.1l-1.2-1L46.4,197.8z"/>
<path className="st16" d="M46.2,197.6l-1.2-1l1.1,1.1L46.2,197.6z M45.1,196.5l1.1,1.1l-1.2-1L45.1,196.5z"/>
<path className="st22" d="M46.2,197.6l-1.2-1l1.1,1.1L46.2,197.6z M45.1,196.5l1.1,1.1l-1.2-1L45.1,196.5z"/>
<path className="st16" d="M47.5,198.8l-1.1-1l1.1,1.1V198.8z M46.3,197.7l1.2,1.1l-1.1-1L46.3,197.7z"/>
<path className="st22" d="M47.5,198.8l-1.1-1l1.1,1.1V198.8z M46.3,197.7l1.2,1.1l-1.1-1L46.3,197.7z"/>
<path className="st16" d="M46.3,197.7l-1.2-1.2l1.1,1.1L46.3,197.7z M46.3,197.7L46.3,197.7l-1.2-1.2L46.3,197.7z"/>
<path className="st22" d="M46.3,197.7l-1.2-1.2l1.1,1.1L46.3,197.7z M46.3,197.7L46.3,197.7l-1.2-1.2L46.3,197.7z"/>
<path className="st16" d="M48.7,199.8l-1.1-0.7l0.8,0.8L48.7,199.8z M47.9,199l0.8,0.8l-1.1-0.7L47.9,199z"/>
<path className="st22" d="M48.7,199.8l-1.1-0.7l0.8,0.8L48.7,199.8z M47.9,199l0.8,0.8l-1.1-0.7L47.9,199z"/>
<path className="st16" d="M47.9,199l-0.5-0.1l0.2,0.2L47.9,199z M47.6,198.8l0.2,0.2l-0.5-0.1L47.6,198.8z"/>
<path className="st22" d="M47.9,199l-0.5-0.1l0.2,0.2L47.9,199z M47.6,198.8l0.2,0.2l-0.5-0.1L47.6,198.8z"/>
<path className="st16" d="M47.6,198.8l-1.3-1.1l1.2,1.1H47.6z M46.4,197.6l1.2,1.2l-1.3-1.1L46.4,197.6z"/>
<path className="st22" d="M47.6,198.8l-1.3-1.1l1.2,1.1H47.6z M46.4,197.6l1.2,1.2l-1.3-1.1L46.4,197.6z"/>
<path className="st16" d="M46.4,197.6l-1.3-1.1l1.2,1.2L46.4,197.6z M45.2,196.4l1.2,1.2l-1.3-1.1L45.2,196.4z"/>
<path className="st22" d="M46.4,197.6l-1.3-1.1l1.2,1.2L46.4,197.6z M45.2,196.4l1.2,1.2l-1.3-1.1L45.2,196.4z"/>
<path className="st16" d="M48.8,199.7l-1-0.7l0.8,0.8L48.8,199.7z M48,198.8l0.8,1l-1-0.7L48,198.8z"/>
<path className="st22" d="M48.8,199.7l-1-0.7l0.8,0.8L48.8,199.7z M48,198.8l0.8,1l-1-0.7L48,198.8z"/>
<path className="st16" d="M48,198.8h-0.4l0.2,0.2L48,198.8z M47.8,198.5l0.2,0.2h-0.4L47.8,198.5z"/>
<path className="st22" d="M48,198.8h-0.4l0.2,0.2L48,198.8z M47.8,198.5l0.2,0.2h-0.4L47.8,198.5z"/>
<path className="st16" d="M47.8,198.5l-1.2-0.8l1.1,1.1L47.8,198.5z M46.7,197.6l1.1,1l-1.2-0.8L46.7,197.6z"/>
<path className="st22" d="M47.8,198.5l-1.2-0.8l1.1,1.1L47.8,198.5z M46.7,197.6l1.1,1l-1.2-0.8L46.7,197.6z"/>
<path className="st16" d="M49,199.6l-1.3-1.1l1.3,1.2V199.6z M47.8,198.4l1.2,1.2l-1.3-1.1L47.8,198.4z"/>
<path className="st22" d="M49,199.6l-1.3-1.1l1.3,1.2V199.6z M47.8,198.4l1.2,1.2l-1.3-1.1L47.8,198.4z"/>
<path className="st16" d="M47.8,198.4l-1.1-0.8l1,1L47.8,198.4z M46.8,197.4l1,1l-1.1-0.8L46.8,197.4z"/>
<path className="st22" d="M47.8,198.4l-1.1-0.8l1,1L47.8,198.4z M46.8,197.4l1,1l-1.1-0.8L46.8,197.4z"/>
<path className="st16" d="M46.6,197.2l-1.3-0.8l1.1,1.1L46.6,197.2z M45.5,196.1l1.1,1.1l-1.3-0.8L45.5,196.1z"/>
<path className="st22" d="M46.6,197.2l-1.3-0.8l1.1,1.1L46.6,197.2z M45.5,196.1l1.1,1.1l-1.3-0.8L45.5,196.1z"/>
<path className="st16" d="M45.5,196.1l-0.7,0.4l0.1,0.2L45.5,196.1z M45.2,195.9l0.2,0.2l-0.7,0.4L45.2,195.9z"/>
<path className="st22" d="M45.5,196.1l-0.7,0.4l0.1,0.2L45.5,196.1z M45.2,195.9l0.2,0.2l-0.7,0.4L45.2,195.9z"/>
<path className="st16" d="M47.9,198.2l-1.1-0.7l1,1L47.9,198.2z M46.9,197.2l1,1l-1.1-0.7L46.9,197.2z"/>
<path className="st22" d="M47.9,198.2l-1.1-0.7l1,1L47.9,198.2z M46.9,197.2l1,1l-1.1-0.7L46.9,197.2z"/>
<path className="st16" d="M48,198.2l-1.1-1l1,1H48z M47,197.2l1,1l-1.1-1H47z M48,198.2l-1-1L48,198.2z M48,198.2L48,198.2l-1-1
	L48,198.2z"/>
<path className="st22" d="M48,198.2l-1.1-1l1,1H48z M47,197.2l1,1l-1.1-1H47z M48,198.2l-1-1L48,198.2z M48,198.2L48,198.2l-1-1
	L48,198.2z"/>
<path className="st16" d="M46.8,197l-0.7-0.2l0.5,0.5L46.8,197z M46.3,196.5l0.5,0.5l-0.7-0.2L46.3,196.5z"/>
<path className="st22" d="M46.8,197l-0.7-0.2l0.5,0.5L46.8,197z M46.3,196.5l0.5,0.5l-0.7-0.2L46.3,196.5z"/>
<path className="st16" d="M48.1,198l-1.1-0.8l1,1L48.1,198z M47,197.1l1.1,1l-1.1-0.8V197.1z"/>
<path className="st22" d="M48.1,198l-1.1-0.8l1,1L48.1,198z M47,197.1l1.1,1l-1.1-0.8V197.1z"/>
<path className="st16" d="M46.9,196.8l-0.6-0.4l0.5,0.5L46.9,196.8z M46.4,196.4l0.5,0.5l-0.6-0.4L46.4,196.4z"/>
<path className="st22" d="M46.9,196.8l-0.6-0.4l0.5,0.5L46.9,196.8z M46.4,196.4l0.5,0.5l-0.6-0.4L46.4,196.4z"/>
<path className="st16" d="M49.3,199.2l-1.6-0.8l1.2,1.2L49.3,199.2z M48.1,198l1.2,1.2l-1.6-0.8L48.1,198z"/>
<path className="st22" d="M49.3,199.2l-1.6-0.8l1.2,1.2L49.3,199.2z M48.1,198l1.2,1.2l-1.6-0.8L48.1,198z"/>
<path className="st16" d="M48.1,198l-1.1-1L48.1,198z M47,197l1.1,1.1l-1.1-1V197z"/>
<path className="st22" d="M48.1,198l-1.1-1L48.1,198z M47,197l1.1,1.1l-1.1-1V197z"/>
<path className="st16" d="M47,197l-0.6-0.6l0.5,0.5L47,197z M47,197L47,197l-0.6-0.6L47,197z"/>
<path className="st22" d="M47,197l-0.6-0.6l0.5,0.5L47,197z M47,197L47,197l-0.6-0.6L47,197z"/>
<path className="st16" d="M49.4,199.1l-1.3-1.1l1.2,1.2L49.4,199.1z M48.2,197.9l1.2,1.2l-1.3-1.1L48.2,197.9z"/>
<path className="st22" d="M49.4,199.1l-1.3-1.1l1.2,1.2L49.4,199.1z M48.2,197.9l1.2,1.2l-1.3-1.1L48.2,197.9z"/>
<path className="st16" d="M48.2,197.9l-1.2-1l1.1,1.1L48.2,197.9z M47.2,196.8l1.1,1.1l-1.2-1L47.2,196.8z"/>
<path className="st22" d="M48.2,197.9l-1.2-1l1.1,1.1L48.2,197.9z M47.2,196.8l1.1,1.1l-1.2-1L47.2,196.8z"/>
<path className="st16" d="M47.2,196.8l-0.7-0.5L47,197L47.2,196.8z M46.6,196.2l0.6,0.6l-0.7-0.5L46.6,196.2z"/>
<path className="st22" d="M47.2,196.8l-0.7-0.5L47,197L47.2,196.8z M46.6,196.2l0.6,0.6l-0.7-0.5L46.6,196.2z"/>
<path className="st16" d="M46.6,196.2l-0.8,0.1l0.4,0.4L46.6,196.2z M46.2,195.9l0.4,0.4l-0.8,0.1L46.2,195.9z"/>
<path className="st22" d="M46.6,196.2l-0.8,0.1l0.4,0.4L46.6,196.2z M46.2,195.9l0.4,0.4l-0.8,0.1L46.2,195.9z"/>
<path className="st16" d="M46.2,195.9l-0.6,0.4l0.1,0.1L46.2,195.9z M46.1,195.8l0.1,0.1l-0.6,0.4L46.1,195.8z"/>
<path className="st22" d="M46.2,195.9l-0.6,0.4l0.1,0.1L46.2,195.9z M46.1,195.8l0.1,0.1l-0.6,0.4L46.1,195.8z"/>
<path className="st16" d="M46.1,195.8l-0.8,0.1l0.4,0.4L46.1,195.8z M45.7,195.4l0.4,0.4l-0.8,0.1L45.7,195.4z"/>
<path className="st22" d="M46.1,195.8l-0.8,0.1l0.4,0.4L46.1,195.8z M45.7,195.4l0.4,0.4l-0.8,0.1L45.7,195.4z"/>
<path className="st16" d="M49.8,199.4l-2.2-2l2,2H49.8z M47.8,197.2l2,2.2l-2.2-2L47.8,197.2z"/>
<path className="st22" d="M49.8,199.4l-2.2-2l2,2H49.8z M47.8,197.2l2,2.2l-2.2-2L47.8,197.2z"/>
<path className="st16" d="M46.8,196.2l-1.3-1.1l1.2,1.2L46.8,196.2z M45.6,195.2l1.2,1.1l-1.3-1.1H45.6z"/>
<path className="st22" d="M46.8,196.2l-1.3-1.1l1.2,1.2L46.8,196.2z M45.6,195.2l1.2,1.1l-1.3-1.1H45.6z"/>
<path className="st16" d="M49.9,199.2l-2.6-2.5l2.5,2.6L49.9,199.2z M47.3,196.6l2.6,2.6l-2.6-2.5V196.6z"/>
<path className="st22" d="M49.9,199.2l-2.6-2.5l2.5,2.6L49.9,199.2z M47.3,196.6l2.6,2.6l-2.6-2.5V196.6z"/>
<path className="st16" d="M47.3,196.6l-1.7-1.4l1.7,1.6V196.6z M45.7,195l1.6,1.6l-1.7-1.4L45.7,195z"/>
<path className="st22" d="M47.3,196.6l-1.7-1.4l1.7,1.6V196.6z M45.7,195l1.6,1.6l-1.7-1.4L45.7,195z"/>
<line className="st19" x1="45.7" y1="198.3" x2="48.1" y2="195.8"/>
<line className="st19" x1="47.6" y1="198.5" x2="49" y2="199.7"/>
<line className="st19" x1="46.8" y1="197.4" x2="47.8" y2="198.4"/>
<line className="st19" x1="45.7" y1="196.4" x2="46.1" y2="196.7"/>
<polyline className="st19" points="45.7,195.4 45.2,195.9 45.6,196.2 46.1,195.8 "/>
<line className="st19" x1="45.7" y1="196.4" x2="46.2" y2="195.9"/>
<line className="st19" x1="46.1" y1="196.7" x2="46.6" y2="196.2"/>
<line className="st19" x1="47.6" y1="198.5" x2="48.2" y2="197.9"/>
<line className="st19" x1="49" y1="199.7" x2="49.4" y2="199.1"/>
<line className="st19" x1="46.3" y1="197.4" x2="46.6" y2="197.7"/>
<path className="st19" d="M47,196.9c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0C47.1,197.1,47.1,197,47,196.9
	"/>
<line className="st19" x1="46.3" y1="197.4" x2="46.8" y2="197"/>
<line className="st19" x1="46.6" y1="197.7" x2="47" y2="197.1"/>
<polyline className="st19" points="46.7,196.4 46.8,196.2 47.8,197.2 47.6,197.3 "/>
<path className="st19" d="M46.3,197.6c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C46.3,197.8,46.3,197.7,46.3,197.6"/>
<path className="st19" d="M45.9,199.4c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3"/>
<path className="st19" d="M44.5,196.5c-0.2,0.2-0.4,0.5-0.4,0.7s0.1,0.6,0.3,0.8"/>
<path className="st19" d="M44.8,196.7c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.2,0.1,0.4,0.2,0.5"/>
<path className="st19" d="M46.2,199.2c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2"/>
<line className="st19" x1="44.8" y1="197.8" x2="46.2" y2="199.2"/>
<line className="st19" x1="47.2" y1="199.2" x2="47.8" y2="198.5"/>
<polyline className="st19" points="44.9,196.8 45.5,196.1 46.6,197.2 "/>
<line className="st19" x1="44.5" y1="198" x2="46" y2="199.5"/>
<path className="st19" d="M47.2,199.4c0.1,0.3,0.4,0.6,0.7,0.6c0.3,0.1,0.7-0.1,0.9-0.4"/>
<line className="st19" x1="45.5" y1="195.2" x2="49.7" y2="199.4"/>
<polyline className="st19" points="45.5,195.2 45.7,195 49.9,199.2 49.7,199.4 "/>
<line className="st19" x1="47.4" y1="199.5" x2="48" y2="198.8"/>
<line className="st19" x1="44.6" y1="196.6" x2="45.7" y2="195.4"/>
<path className="st20" d="M46.9,197.2H47H46.9z"/>
<path className="st21" d="M46.9,197.2H47H46.9z"/>
<path className="st20" d="M47,197.2L47,197.2z"/>
<path className="st21" d="M47,197.2L47,197.2z"/>
<path className="st20" d="M47,197.2L47,197.2z"/>
<path className="st21" d="M47,197.2L47,197.2z"/>
<path className="st20" d="M47,197.2v-0.1V197.2z"/>
<path className="st21" d="M47,197.2v-0.1V197.2z"/>
<path className="st20" d="M47,197.2L47,197.2z"/>
<path className="st21" d="M47,197.2L47,197.2z"/>
<path className="st20" d="M47,197.2L47,197.2z"/>
<path className="st21" d="M47,197.2L47,197.2z"/>
<line className="st1" x1="51" y1="199.1" x2="56.2" y2="190.2"/>
<line className="st1" x1="49.1" y1="197.3" x2="58" y2="192.2"/>
<polyline className="st1" points="56.2,190.2 53.9,194.4 58,192.2 56.2,190.2 "/>
<polyline className="st1" points="49.1,197.3 53.9,194.4 51,199.1 49.1,197.3 "/>
<line className="st1" x1="131.2" y1="201.8" x2="120.6" y2="201.8"/>
<line className="st2" x1="120.6" y1="201.8" x2="131.2" y2="201.8"/>
<line className="st2" x1="100" y1="253.7" x2="100" y2="252.2"/>
<line className="st2" x1="109.9" y1="253.7" x2="109.9" y2="252.2"/>
<line className="st2" x1="121" y1="253.7" x2="121" y2="252.2"/>
<line className="st2" x1="130.8" y1="253.7" x2="130.8" y2="252.2"/>
<line className="st2" x1="141.8" y1="253.7" x2="141.8" y2="252.2"/>
<line className="st2" x1="151.8" y1="253.7" x2="151.8" y2="252.2"/>
<line className="st2" x1="153.7" y1="253.8" x2="153.7" y2="252.2"/>
<line className="st2" x1="163.7" y1="253.8" x2="163.7" y2="252.2"/>
<polyline className="st12" points="130.8,253 130.8,252.8 121,252.8 121,253 "/>
<line className="st12" x1="121.3" y1="252.8" x2="121.3" y2="253"/>
<line className="st12" x1="130.4" y1="252.8" x2="130.4" y2="253"/>
<line className="st12" x1="121" y1="253" x2="130.8" y2="253"/>
<line className="st12" x1="128.3" y1="252.8" x2="128.3" y2="253"/>
<line className="st12" x1="127.9" y1="252.8" x2="127.9" y2="253"/>
<line className="st6" x1="121.3" y1="252.9" x2="127.9" y2="252.9"/>
<line className="st6" x1="128.3" y1="252.9" x2="130.4" y2="252.9"/>
<polyline className="st12" points="151.8,253 151.8,252.8 141.8,252.8 141.8,253 "/>
<line className="st12" x1="142.3" y1="252.8" x2="142.3" y2="253"/>
<line className="st12" x1="151.3" y1="252.8" x2="151.3" y2="253"/>
<line className="st12" x1="141.8" y1="253" x2="151.8" y2="253"/>
<line className="st12" x1="149.3" y1="252.8" x2="149.3" y2="253"/>
<line className="st12" x1="148.8" y1="252.8" x2="148.8" y2="253"/>
<line className="st6" x1="142.3" y1="252.9" x2="148.8" y2="252.9"/>
<line className="st6" x1="149.3" y1="252.9" x2="151.3" y2="252.9"/>
<line className="st1" x1="153.7" y1="253.8" x2="163.7" y2="253.8"/>
<line className="st1" x1="153.7" y1="252.2" x2="163.7" y2="252.2"/>
<polyline className="st12" points="163.7,253 163.7,252.8 153.7,252.8 153.7,253 "/>
<line className="st12" x1="154.2" y1="252.8" x2="154.2" y2="253"/>
<line className="st12" x1="163.2" y1="252.8" x2="163.2" y2="253"/>
<line className="st12" x1="153.7" y1="253" x2="163.7" y2="253"/>
<line className="st12" x1="161.2" y1="252.8" x2="161.2" y2="253"/>
<line className="st12" x1="160.7" y1="252.8" x2="160.7" y2="253"/>
<line className="st6" x1="154.2" y1="252.9" x2="160.7" y2="252.9"/>
<line className="st6" x1="161.2" y1="252.9" x2="163.2" y2="252.9"/>
<line className="st1" x1="98" y1="253.8" x2="88.1" y2="253.8"/>
<line className="st1" x1="98" y1="252.2" x2="88.1" y2="252.2"/>
<polyline className="st12" points="109.9,253 109.9,252.8 100,252.8 100,253 "/>
<line className="st12" x1="100.4" y1="252.8" x2="100.4" y2="253"/>
<line className="st12" x1="109.4" y1="252.8" x2="109.4" y2="253"/>
<line className="st12" x1="100" y1="253" x2="109.9" y2="253"/>
<line className="st12" x1="107.4" y1="252.8" x2="107.4" y2="253"/>
<line className="st12" x1="106.9" y1="252.8" x2="106.9" y2="253"/>
<line className="st6" x1="100.4" y1="252.9" x2="106.9" y2="252.9"/>
<line className="st6" x1="107.4" y1="252.9" x2="109.4" y2="252.9"/>
<polyline className="st12" points="98,253 98,252.8 88.1,252.8 88.1,253 "/>
<line className="st12" x1="88.6" y1="252.8" x2="88.6" y2="253"/>
<line className="st12" x1="97.6" y1="252.8" x2="97.6" y2="253"/>
<line className="st12" x1="88.1" y1="253" x2="98" y2="253"/>
<line className="st12" x1="95.5" y1="252.8" x2="95.5" y2="253"/>
<line className="st12" x1="95" y1="252.8" x2="95" y2="253"/>
<line className="st6" x1="88.6" y1="252.9" x2="95" y2="252.9"/>
<line className="st6" x1="95.5" y1="252.9" x2="97.6" y2="252.9"/>
<path className="st16" d="M134.6,183.3l-0.1-2.5v2L134.6,183.3z M134.6,180.3v3l-0.1-2.5L134.6,180.3z M134.6,183.3v-3V183.3z
	 M134.6,183.3L134.6,183.3v-3V183.3z"/>
<path className="st22" d="M134.6,183.3l-0.1-2.5v2L134.6,183.3z M134.6,180.3v3l-0.1-2.5L134.6,180.3z M134.6,183.3v-3V183.3z
	 M134.6,183.3L134.6,183.3v-3V183.3z"/>
<path className="st16" d="M134.8,183.5l-0.1-3.2v3L134.8,183.5z M134.8,180.2v3.4l-0.1-3.2L134.8,180.2z"/>
<path className="st22" d="M134.8,183.5l-0.1-3.2v3L134.8,183.5z M134.8,180.2v3.4l-0.1-3.2L134.8,180.2z"/>
<path className="st16" d="M134.9,183.6l-0.1-0.8v0.7L134.9,183.6z M134.9,183v0.6l-0.1-0.8L134.9,183z"/>
<path className="st22" d="M134.9,183.6l-0.1-0.8v0.7L134.9,183.6z M134.9,183v0.6l-0.1-0.8L134.9,183z"/>
<path className="st16" d="M134.9,183l-0.1-2.3v2L134.9,183z M134.9,180.5v2.5l-0.1-2.3L134.9,180.5z"/>
<path className="st22" d="M134.9,183l-0.1-2.3v2L134.9,183z M134.9,180.5v2.5l-0.1-2.3L134.9,180.5z"/>
<path className="st16" d="M135,183.6l-0.1-0.6v0.6H135z M135,183.2v0.5l-0.1-0.6L135,183.2z"/>
<path className="st22" d="M135,183.6l-0.1-0.6v0.6H135z M135,183.2v0.5l-0.1-0.6L135,183.2z"/>
<path className="st16" d="M135,183.2l-0.1-2.6v2.5L135,183.2z M135,180.4v2.8l-0.1-2.6L135,180.4z"/>
<path className="st22" d="M135,183.2l-0.1-2.6v2.5L135,183.2z M135,180.4v2.8l-0.1-2.6L135,180.4z"/>
<path className="st16" d="M135,183.6v-0.5V183.6z M135,183.3v0.4v-0.5V183.3z"/>
<path className="st22" d="M135,183.6v-0.5V183.6z M135,183.3v0.4v-0.5V183.3z"/>
<path className="st16" d="M135,183.3v-2.9v2.8V183.3z M135,183.3L135,183.3v-2.9V183.3z"/>
<path className="st22" d="M135,183.3v-2.9v2.8V183.3z M135,183.3L135,183.3v-2.9V183.3z"/>
<path className="st16" d="M135,183.6L135,183.6v-0.4V183.6z M135,183.3v0.4V183.3z"/>
<path className="st22" d="M135,183.6L135,183.6v-0.4V183.6z M135,183.3v0.4V183.3z"/>
<path className="st16" d="M135,183.3L135,183.3v-2.9V183.3z M135,180.4v2.9V180.4z"/>
<path className="st22" d="M135,183.3L135,183.3v-2.9V183.3z M135,180.4v2.9V180.4z"/>
<path className="st16" d="M135,183.6L135,183.6v-0.4V183.6z M135,183.3v0.4V183.3z"/>
<path className="st22" d="M135,183.6L135,183.6v-0.4V183.6z M135,183.3v0.4V183.3z"/>
<path className="st16" d="M135,183.3v-2.9V183.3z M135,180.3v3v-2.9V180.3z"/>
<path className="st22" d="M135,183.3v-2.9V183.3z M135,180.3v3v-2.9V180.3z"/>
<path className="st16" d="M135,180.3l-0.2-0.1v0.6L135,180.3z M135,179.9v0.4l-0.2-0.1L135,179.9z"/>
<path className="st22" d="M135,180.3l-0.2-0.1v0.6L135,180.3z M135,179.9v0.4l-0.2-0.1L135,179.9z"/>
<path className="st16" d="M135,183.3L135,183.3v-3V183.3z M135,180.3v3V180.3z"/>
<path className="st22" d="M135,183.3L135,183.3v-3V183.3z M135,180.3v3V180.3z"/>
<path className="st16" d="M135,180.3L135,180.3v-0.4V180.3z M135,179.9v0.4V179.9z"/>
<path className="st22" d="M135,180.3L135,180.3v-0.4V180.3z M135,179.9v0.4V179.9z"/>
<polygon className="st16" points="135.4,184.5 135.5,183.8 135.5,184.5 "/>
<polygon className="st22" points="135.4,184.5 135.5,183.8 135.5,184.5 "/>
<path className="st16" d="M135.4,183.4l-0.4-3.1v3L135.4,183.4z M135.4,180.2v3.2l-0.4-3.1L135.4,180.2z"/>
<path className="st22" d="M135.4,183.4l-0.4-3.1v3L135.4,183.4z M135.4,180.2v3.2l-0.4-3.1L135.4,180.2z"/>
<path className="st16" d="M135.4,180.2l-0.4-0.2v0.4L135.4,180.2z M135.4,179.8v0.4l-0.4-0.2L135.4,179.8z"/>
<path className="st22" d="M135.4,180.2l-0.4-0.2v0.4L135.4,180.2z M135.4,179.8v0.4l-0.4-0.2L135.4,179.8z"/>
<path className="st16" d="M135.5,184.6v-0.8v0.7V184.6z M135.5,184.6L135.5,184.6v-0.8V184.6z M135.5,183.8v0.8V183.8z M135.5,184.6
	v-0.8V184.6z"/>
<path className="st22" d="M135.5,184.6v-0.8v0.7V184.6z M135.5,184.6L135.5,184.6v-0.8V184.6z M135.5,183.8v0.8V183.8z M135.5,184.6
	v-0.8V184.6z"/>
<path className="st16" d="M135.5,183.8l-0.5-0.5v0.4L135.5,183.8z M135.5,183.4v0.4l-0.5-0.5L135.5,183.4z"/>
<path className="st22" d="M135.5,183.8l-0.5-0.5v0.4L135.5,183.8z M135.5,183.4v0.4l-0.5-0.5L135.5,183.4z"/>
<path className="st16" d="M135.5,184.6L135.5,184.6v-0.8V184.6z M135.5,183.8v0.8V183.8z"/>
<path className="st22" d="M135.5,184.6L135.5,184.6v-0.8V184.6z M135.5,183.8v0.8V183.8z"/>
<path className="st16" d="M135.5,183.8L135.5,183.8v-0.4V183.8z M135.5,183.4v0.4V183.4z"/>
<path className="st22" d="M135.5,183.8L135.5,183.8v-0.4V183.8z M135.5,183.4v0.4V183.4z"/>
<path className="st16" d="M135.5,183.4l-0.1-3.2v3.2H135.5z M135.5,180.2v3.2l-0.1-3.2H135.5z"/>
<path className="st22" d="M135.5,183.4l-0.1-3.2v3.2H135.5z M135.5,180.2v3.2l-0.1-3.2H135.5z"/>
<path className="st16" d="M135.6,184.7l-0.1-1v0.8L135.6,184.7z M135.6,183.8v1l-0.1-1H135.6z"/>
<path className="st22" d="M135.6,184.7l-0.1-1v0.8L135.6,184.7z M135.6,183.8v1l-0.1-1H135.6z"/>
<path className="st16" d="M135.6,183.8l-0.1-0.4v0.4H135.6z M135.6,183.4v0.4l-0.1-0.4H135.6z"/>
<path className="st22" d="M135.6,183.8l-0.1-0.4v0.4H135.6z M135.6,183.4v0.4l-0.1-0.4H135.6z"/>
<path className="st16" d="M135.6,183.4l-0.1-3.2v3.2H135.6z M135.6,180.2v3.2l-0.1-3.2H135.6z"/>
<path className="st22" d="M135.6,183.4l-0.1-3.2v3.2H135.6z M135.6,180.2v3.2l-0.1-3.2H135.6z"/>
<path className="st16" d="M135.6,180.2l-0.2-0.4v0.4H135.6z M135.6,179.8v0.4l-0.2-0.4H135.6z"/>
<path className="st22" d="M135.6,180.2l-0.2-0.4v0.4H135.6z M135.6,179.8v0.4l-0.2-0.4H135.6z"/>
<path className="st16" d="M135.6,184.7L135.6,184.7v-1V184.7z M135.6,183.8v1V183.8z"/>
<path className="st22" d="M135.6,184.7L135.6,184.7v-1V184.7z M135.6,183.8v1V183.8z"/>
<path className="st16" d="M135.6,183.8L135.6,183.8v-0.4V183.8z M135.6,183.4v0.4V183.4z"/>
<path className="st22" d="M135.6,183.8L135.6,183.8v-0.4V183.8z M135.6,183.4v0.4V183.4z"/>
<path className="st16" d="M135.6,183.4L135.6,183.4v-3.2V183.4z M135.6,180.2v3.2V180.2z"/>
<path className="st22" d="M135.6,183.4L135.6,183.4v-3.2V183.4z M135.6,180.2v3.2V180.2z"/>
<path className="st16" d="M135.6,180.2L135.6,180.2v-0.4V180.2z M135.6,179.8v0.4V179.8z"/>
<path className="st22" d="M135.6,180.2L135.6,180.2v-0.4V180.2z M135.6,179.8v0.4V179.8z"/>
<path className="st16" d="M135.6,184.8v-1.1v1V184.8z M135.6,184.8L135.6,184.8v-1.1V184.8z"/>
<path className="st22" d="M135.6,184.8v-1.1v1V184.8z M135.6,184.8L135.6,184.8v-1.1V184.8z"/>
<path className="st16" d="M135.6,183.8L135.6,183.8v-0.4V183.8z M135.6,183.4v0.4V183.4z"/>
<path className="st22" d="M135.6,183.8L135.6,183.8v-0.4V183.8z M135.6,183.4v0.4V183.4z"/>
<path className="st16" d="M135.6,183.4L135.6,183.4v-3.2V183.4z M135.6,180.2v3.2V180.2z"/>
<path className="st22" d="M135.6,183.4L135.6,183.4v-3.2V183.4z M135.6,180.2v3.2V180.2z"/>
<path className="st16" d="M135.6,180.2L135.6,180.2v-0.4V180.2z M135.6,179.8v0.4V179.8z"/>
<path className="st22" d="M135.6,180.2L135.6,180.2v-0.4V180.2z M135.6,179.8v0.4V179.8z"/>
<path className="st16" d="M135.7,184.8l-0.1-1.1v1.1H135.7z M135.7,183.8v1.1l-0.1-1.1H135.7z"/>
<path className="st22" d="M135.7,184.8l-0.1-1.1v1.1H135.7z M135.7,183.8v1.1l-0.1-1.1H135.7z"/>
<path className="st16" d="M135.7,183.8l-0.1-0.4v0.4H135.7z M135.7,183.4v0.4l-0.1-0.4H135.7z"/>
<path className="st22" d="M135.7,183.8l-0.1-0.4v0.4H135.7z M135.7,183.4v0.4l-0.1-0.4H135.7z"/>
<path className="st16" d="M135.7,183.4l-0.1-1.6v1.6H135.7z M135.7,182v1.4l-0.1-1.6L135.7,182z"/>
<path className="st22" d="M135.7,183.4l-0.1-1.6v1.6H135.7z M135.7,182v1.4l-0.1-1.6L135.7,182z"/>
<path className="st16" d="M135.7,181.6l-0.1-1.4v1.7L135.7,181.6z M135.7,180.2v1.4l-0.1-1.4H135.7z"/>
<path className="st22" d="M135.7,181.6l-0.1-1.4v1.7L135.7,181.6z M135.7,180.2v1.4l-0.1-1.4H135.7z"/>
<path className="st16" d="M135.7,180.2l-0.1-0.4v0.4H135.7z M135.7,179.8v0.4l-0.1-0.4H135.7z"/>
<path className="st22" d="M135.7,180.2l-0.1-0.4v0.4H135.7z M135.7,179.8v0.4l-0.1-0.4H135.7z"/>
<path className="st16" d="M135.7,185v-1.2v1.1V185z M135.7,185L135.7,185v-1.2V185z"/>
<path className="st22" d="M135.7,185v-1.2v1.1V185z M135.7,185L135.7,185v-1.2V185z"/>
<path className="st16" d="M135.7,183.8L135.7,183.8v-0.4V183.8z M135.7,183.4v0.4V183.4z"/>
<path className="st22" d="M135.7,183.8L135.7,183.8v-0.4V183.8z M135.7,183.4v0.4V183.4z"/>
<path className="st16" d="M135.8,185l-0.1-1.2v1.2H135.8z M135.8,183.8v1.2l-0.1-1.2H135.8z"/>
<path className="st22" d="M135.8,185l-0.1-1.2v1.2H135.8z M135.8,183.8v1.2l-0.1-1.2H135.8z"/>
<path className="st16" d="M135.8,183.8l-0.1-0.4v0.4H135.8z M135.8,183.4v0.4l-0.1-0.4H135.8z"/>
<path className="st22" d="M135.8,183.8l-0.1-0.4v0.4H135.8z M135.8,183.4v0.4l-0.1-0.4H135.8z"/>
<path className="st16" d="M135.8,183.4l-0.1-1.4v1.4H135.8z M135.8,182v1.4l-0.1-1.4H135.8z"/>
<path className="st22" d="M135.8,183.4l-0.1-1.4v1.4H135.8z M135.8,182v1.4l-0.1-1.4H135.8z"/>
<path className="st16" d="M135.8,181.6l-0.1-1.4v1.4H135.8z M135.8,180.2v1.4l-0.1-1.4H135.8z"/>
<path className="st22" d="M135.8,181.6l-0.1-1.4v1.4H135.8z M135.8,180.2v1.4l-0.1-1.4H135.8z"/>
<path className="st16" d="M136,183.4l-0.1-1.4v1.4H136z M136,182v1.4l-0.1-1.4H136z"/>
<path className="st22" d="M136,183.4l-0.1-1.4v1.4H136z M136,182v1.4l-0.1-1.4H136z"/>
<path className="st16" d="M136,181.6l-0.1-1.4v1.4H136z M136,180.2v1.4l-0.1-1.4H136z"/>
<path className="st22" d="M136,181.6l-0.1-1.4v1.4H136z M136,180.2v1.4l-0.1-1.4H136z"/>
<path className="st16" d="M136.1,183.4L136,182v1.4H136.1z M136.1,181.8v1.6L136,182L136.1,181.8z"/>
<path className="st22" d="M136.1,183.4L136,182v1.4H136.1z M136.1,181.8v1.6L136,182L136.1,181.8z"/>
<path className="st16" d="M136.1,181.8l-0.1-1.7v1.4L136.1,181.8z M136.1,180.2v1.7l-0.1-1.7H136.1z"/>
<path className="st22" d="M136.1,181.8l-0.1-1.7v1.4L136.1,181.8z M136.1,180.2v1.7l-0.1-1.7H136.1z"/>
<path className="st16" d="M136.2,185.1l-0.4-1.3v1.2L136.2,185.1z M136.2,183.8v1.3l-0.4-1.3H136.2z"/>
<path className="st22" d="M136.2,185.1l-0.4-1.3v1.2L136.2,185.1z M136.2,183.8v1.3l-0.4-1.3H136.2z"/>
<path className="st16" d="M136.2,183.8l-0.4-0.4v0.4H136.2z M136.2,183.4v0.4l-0.4-0.4H136.2z"/>
<path className="st22" d="M136.2,183.8l-0.4-0.4v0.4H136.2z M136.2,183.4v0.4l-0.4-0.4H136.2z"/>
<path className="st16" d="M136.2,183.4l-0.1-1.6v1.6H136.2z M136.2,181.8v1.6l-0.1-1.6H136.2z"/>
<path className="st22" d="M136.2,183.4l-0.1-1.6v1.6H136.2z M136.2,181.8v1.6l-0.1-1.6H136.2z"/>
<path className="st16" d="M136.2,181.8l-0.1-1.7v1.7H136.2z M136.2,180.2v1.7l-0.1-1.7H136.2z"/>
<path className="st22" d="M136.2,181.8l-0.1-1.7v1.7H136.2z M136.2,180.2v1.7l-0.1-1.7H136.2z"/>
<path className="st16" d="M136.3,185.1l-0.1-1.3v1.3H136.3z M136.3,183.8v1.3l-0.1-1.3H136.3z"/>
<path className="st22" d="M136.3,185.1l-0.1-1.3v1.3H136.3z M136.3,183.8v1.3l-0.1-1.3H136.3z"/>
<path className="st16" d="M136.3,183.8l-0.1-0.4v0.4H136.3z M136.3,183.4v0.4l-0.1-0.4H136.3z"/>
<path className="st22" d="M136.3,183.8l-0.1-0.4v0.4H136.3z M136.3,183.4v0.4l-0.1-0.4H136.3z"/>
<path className="st16" d="M136.3,183.4l-0.1-1.4v1.4H136.3z M136.3,182v1.4l-0.1-1.4H136.3z"/>
<path className="st22" d="M136.3,183.4l-0.1-1.4v1.4H136.3z M136.3,182v1.4l-0.1-1.4H136.3z"/>
<path className="st16" d="M136.6,185.1l-0.2-1.8v1.8H136.6z M136.6,183.3v1.8l-0.2-1.8H136.6z"/>
<path className="st22" d="M136.6,185.1l-0.2-1.8v1.8H136.6z M136.6,183.3v1.8l-0.2-1.8H136.6z"/>
<path className="st16" d="M136.6,183.3l-0.2-1.3v1.3H136.6z M136.6,182v1.3l-0.2-1.3H136.6z"/>
<path className="st22" d="M136.6,183.3l-0.2-1.3v1.3H136.6z M136.6,182v1.3l-0.2-1.3H136.6z"/>
<path className="st16" d="M136.6,181.6l-0.4-1.4v1.4H136.6z M136.6,180.2v1.4l-0.4-1.4H136.6z"/>
<path className="st22" d="M136.6,181.6l-0.4-1.4v1.4H136.6z M136.6,180.2v1.4l-0.4-1.4H136.6z"/>
<path className="st16" d="M136.6,180.2l-0.8-0.4v0.4H136.6z M136.6,179.8v0.4l-0.8-0.4H136.6z"/>
<path className="st22" d="M136.6,180.2l-0.8-0.4v0.4H136.6z M136.6,179.8v0.4l-0.8-0.4H136.6z"/>
<path className="st16" d="M136.8,183.3l-0.2-1.3v1.3H136.8z M136.8,182v1.3l-0.2-1.3H136.8z M136.8,183.3V182V183.3z M136.8,183.3
	L136.8,183.3V182V183.3z M136.8,182v1.3V182z M136.8,183.3V182V183.3z"/>
<path className="st22" d="M136.8,183.3l-0.2-1.3v1.3H136.8z M136.8,182v1.3l-0.2-1.3H136.8z M136.8,183.3V182V183.3z M136.8,183.3
	L136.8,183.3V182V183.3z M136.8,182v1.3V182z M136.8,183.3V182V183.3z"/>
<path className="st16" d="M136.8,181.6l-0.2-0.7v0.7H136.8z M136.8,180.9v0.7l-0.2-0.7H136.8z"/>
<path className="st22" d="M136.8,181.6l-0.2-0.7v0.7H136.8z M136.8,180.9v0.7l-0.2-0.7H136.8z"/>
<path className="st16" d="M136.9,183.3l-0.1-1.3v1.3H136.9z M136.9,182v1.3l-0.1-1.3H136.9z"/>
<path className="st22" d="M136.9,183.3l-0.1-1.3v1.3H136.9z M136.9,182v1.3l-0.1-1.3H136.9z"/>
<path className="st16" d="M136.9,181.6l-0.1-0.7v0.7H136.9z M136.9,180.9v0.7l-0.1-0.7H136.9z"/>
<path className="st22" d="M136.9,181.6l-0.1-0.7v0.7H136.9z M136.9,180.9v0.7l-0.1-0.7H136.9z"/>
<path className="st16" d="M137,185.1l-0.5-1.8v1.8H137z M137,183.3v1.8l-0.5-1.8H137z"/>
<path className="st22" d="M137,185.1l-0.5-1.8v1.8H137z M137,183.3v1.8l-0.5-1.8H137z"/>
<path className="st16" d="M137,183.3l-0.1-1.3v1.3H137z M137,181.8v1.4l-0.1-1.3L137,181.8z"/>
<path className="st22" d="M137,183.3l-0.1-1.3v1.3H137z M137,181.8v1.4l-0.1-1.3L137,181.8z"/>
<path className="st16" d="M137,181.8l-0.1-1v0.7L137,181.8z M137,180.9v1l-0.1-1H137z"/>
<path className="st22" d="M137,181.8l-0.1-1v0.7L137,181.8z M137,180.9v1l-0.1-1H137z"/>
<path className="st16" d="M137.2,185.1l-0.1-1.8v1.8H137.2z M137.2,183.3v1.8l-0.1-1.8H137.2z"/>
<path className="st22" d="M137.2,185.1l-0.1-1.8v1.8H137.2z M137.2,183.3v1.8l-0.1-1.8H137.2z"/>
<path className="st16" d="M137.2,183.3l-0.1-1.4v1.4H137.2z M137.2,181.8v1.4l-0.1-1.4H137.2z"/>
<path className="st22" d="M137.2,183.3l-0.1-1.4v1.4H137.2z M137.2,181.8v1.4l-0.1-1.4H137.2z"/>
<path className="st16" d="M137.2,181.8l-0.1-1v1H137.2z M137.2,180.9v1l-0.1-1H137.2z"/>
<path className="st22" d="M137.2,181.8l-0.1-1v1H137.2z M137.2,180.9v1l-0.1-1H137.2z"/>
<path className="st16" d="M137.2,180.9l-0.6-0.4v0.4H137.2z M137.2,180.5v0.4l-0.6-0.4H137.2z"/>
<path className="st22" d="M137.2,180.9l-0.6-0.4v0.4H137.2z M137.2,180.5v0.4l-0.6-0.4H137.2z"/>
<path className="st16" d="M137.2,180.5l-0.6-0.2v0.2H137.2z M137.2,180.3v0.2l-0.6-0.2H137.2z"/>
<path className="st22" d="M137.2,180.5l-0.6-0.2v0.2H137.2z M137.2,180.3v0.2l-0.6-0.2H137.2z"/>
<path className="st16" d="M137.2,180.3l-0.6-0.5v0.5H137.2z M137.2,179.8v0.5l-0.6-0.5H137.2z"/>
<path className="st22" d="M137.2,180.3l-0.6-0.5v0.5H137.2z M137.2,179.8v0.5l-0.6-0.5H137.2z"/>
<path className="st16" d="M137.3,185.4l-0.1-3v3H137.3z M137.3,182.4v3l-0.1-3H137.3z"/>
<path className="st22" d="M137.3,185.4l-0.1-3v3H137.3z M137.3,182.4v3l-0.1-3H137.3z"/>
<path className="st16" d="M137.3,181.1l-0.1-1.7v1.7H137.3z M137.3,179.4v1.7l-0.1-1.7H137.3z"/>
<path className="st22" d="M137.3,181.1l-0.1-1.7v1.7H137.3z M137.3,179.4v1.7l-0.1-1.7H137.3z"/>
<path className="st16" d="M137.4,185.4l-0.1-3.6v3.6H137.4z M137.4,181.8v3.6l-0.1-3.6H137.4z"/>
<path className="st22" d="M137.4,185.4l-0.1-3.6v3.6H137.4z M137.4,181.8v3.6l-0.1-3.6H137.4z"/>
<path className="st16" d="M137.4,181.8l-0.1-2.4v2.4H137.4z M137.4,179.4v2.4l-0.1-2.4H137.4z"/>
<path className="st22" d="M137.4,181.8l-0.1-2.4v2.4H137.4z M137.4,179.4v2.4l-0.1-2.4H137.4z"/>
<line className="st19" x1="135.1" y1="181.8" x2="138.6" y2="181.8"/>
<line className="st19" x1="136.3" y1="183.3" x2="136.3" y2="185.1"/>
<line className="st19" x1="136.6" y1="182" x2="136.6" y2="183.3"/>
<line className="st19" x1="136.6" y1="180.5" x2="136.6" y2="180.9"/>
<polyline className="st19" points="137.2,179.8 136.6,179.8 136.6,180.3 137.2,180.3 "/>
<line className="st19" x1="136.6" y1="180.5" x2="137.2" y2="180.5"/>
<line className="st19" x1="136.6" y1="180.9" x2="137.2" y2="180.9"/>
<line className="st19" x1="136.3" y1="183.3" x2="137.2" y2="183.3"/>
<line className="st19" x1="136.3" y1="185.1" x2="137.2" y2="185.1"/>
<line className="st19" x1="136.2" y1="181.6" x2="136.2" y2="182"/>
<path className="st19" d="M136.9,181.8c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2
	C136.8,182,136.9,181.9,136.9,181.8"/>
<line className="st19" x1="136.2" y1="181.6" x2="136.8" y2="181.6"/>
<line className="st19" x1="136.2" y1="182" x2="136.9" y2="182"/>
<polyline className="st19" points="137.2,181.1 137.3,181.1 137.3,182.4 137.2,182.4 "/>
<path className="st19" d="M136,181.8c0-0.1-0.1-0.2-0.2-0.2s-0.2,0.1-0.2,0.2s0.1,0.2,0.2,0.2S136,181.9,136,181.8"/>
<path className="st19" d="M134.4,182.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3"/>
<path className="st19" d="M135.5,179.7c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8"/>
<path className="st19" d="M135.5,180c-0.2,0-0.4,0-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5"/>
<path className="st19" d="M134.8,182.7c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2"/>
<line className="st19" x1="134.8" y1="180.8" x2="134.8" y2="182.8"/>
<line className="st19" x1="135.5" y1="183.4" x2="136.3" y2="183.4"/>
<polyline className="st19" points="135.6,180.2 136.6,180.2 136.6,181.6 "/>
<line className="st19" x1="134.5" y1="180.8" x2="134.5" y2="182.8"/>
<path className="st19" d="M135.3,183.8c-0.2,0.3-0.1,0.7,0.1,1c0.2,0.3,0.6,0.4,0.9,0.4"/>
<line className="st19" x1="137.2" y1="179.4" x2="137.2" y2="185.4"/>
<polyline className="st19" points="137.2,179.4 137.4,179.4 137.4,185.4 137.2,185.4 "/>
<line className="st19" x1="135.5" y1="183.8" x2="136.3" y2="183.8"/>
<line className="st19" x1="135.6" y1="179.8" x2="137.2" y2="179.8"/>
<path className="st20" d="M136.8,182L136.8,182z"/>
<path className="st21" d="M136.8,182L136.8,182z"/>
<path className="st20" d="M136.8,182L136.8,182z"/>
<path className="st21" d="M136.8,182L136.8,182z"/>
<path className="st20" d="M136.8,182L136.8,182z"/>
<path className="st21" d="M136.8,182L136.8,182z"/>
<path className="st20" d="M136.9,182L136.9,182z"/>
<path className="st21" d="M136.9,182L136.9,182z"/>
<path className="st20" d="M136.8,182h0.1H136.8z"/>
<path className="st21" d="M136.8,182h0.1H136.8z"/>
<path className="st20" d="M136.8,182h0.1H136.8z"/>
<path className="st21" d="M136.8,182h0.1H136.8z"/>
<polygon className="st16" points="301.1,243.4 301.1,243.3 301.2,243.5 "/>
<polygon className="st17" points="301.1,243.4 301.1,243.3 301.2,243.5 "/>
<path className="st16" d="M302.5,244.8l-3.1-2.5l2.8,2.8L302.5,244.8z M299.8,242.1l2.8,2.8l-3.1-2.5L299.8,242.1z"/>
<path className="st17" d="M302.5,244.8l-3.1-2.5l2.8,2.8L302.5,244.8z M299.8,242.1l2.8,2.8l-3.1-2.5L299.8,242.1z"/>
<path className="st16" d="M302.5,244.8l-1.4-1.4L302.5,244.8z M301.2,243.5l1.3,1.3l-1.4-1.4L301.2,243.5z"/>
<path className="st17" d="M302.5,244.8l-1.4-1.4L302.5,244.8z M301.2,243.5l1.3,1.3l-1.4-1.4L301.2,243.5z"/>
<path className="st16" d="M301.1,243.3l-1.3-1.2l1.3,1.3V243.3z M299.8,242l1.3,1.3l-1.3-1.2V242z"/>
<path className="st17" d="M301.1,243.3l-1.3-1.2l1.3,1.3V243.3z M299.8,242l1.3,1.3l-1.3-1.2V242z"/>
<path className="st16" d="M302.6,244.7l-1.4-1.2l1.3,1.3L302.6,244.7z M301.4,243.4l1.2,1.3l-1.4-1.2L301.4,243.4z"/>
<path className="st17" d="M302.6,244.7l-1.4-1.2l1.3,1.3L302.6,244.7z M301.4,243.4l1.2,1.3l-1.4-1.2L301.4,243.4z"/>
<path className="st16" d="M301.4,243.4l-0.4-0.1l0.1,0.2L301.4,243.4z M301.1,243.2l0.4,0.2l-0.4-0.1V243.2z"/>
<path className="st17" d="M301.4,243.4l-0.4-0.1l0.1,0.2L301.4,243.4z M301.1,243.2l0.4,0.2l-0.4-0.1V243.2z"/>
<path className="st16" d="M301.1,243.2l-1.3-1.2l1.3,1.3V243.2z M299.9,241.8l1.2,1.3l-1.3-1.2L299.9,241.8z"/>
<path className="st17" d="M301.1,243.2l-1.3-1.2l1.3,1.3V243.2z M299.9,241.8l1.2,1.3l-1.3-1.2L299.9,241.8z"/>
<path className="st16" d="M302.6,244.7L302.6,244.7l-1.2-1.3L302.6,244.7z M301.4,243.4l1.2,1.3L301.4,243.4z"/>
<path className="st17" d="M302.6,244.7L302.6,244.7l-1.2-1.3L302.6,244.7z M301.4,243.4l1.2,1.3L301.4,243.4z"/>
<path className="st16" d="M301.1,243.2L301.1,243.2l-1.2-1.3L301.1,243.2z M299.9,241.8l1.2,1.3L299.9,241.8z"/>
<path className="st17" d="M301.1,243.2L301.1,243.2l-1.2-1.3L301.1,243.2z M299.9,241.8l1.2,1.3L299.9,241.8z"/>
<path className="st16" d="M301.2,243.2V243V243.2z"/>
<path className="st17" d="M301.2,243.2V243V243.2z"/>
<path className="st16" d="M301.4,243.4v-0.1V243.4z"/>
<path className="st17" d="M301.4,243.4v-0.1V243.4z"/>
<path className="st16" d="M302.6,244.6l-1.2-1.2l1.2,1.3V244.6z M302.6,244.6L302.6,244.6l-1.2-1.2L302.6,244.6z"/>
<path className="st17" d="M302.6,244.6l-1.2-1.2l1.2,1.3V244.6z M302.6,244.6L302.6,244.6l-1.2-1.2L302.6,244.6z"/>
<path className="st16" d="M301.2,243.2l-1.3-1.3l1.2,1.3H301.2z M301.2,243.2L301.2,243.2l-1.3-1.3L301.2,243.2z"/>
<path className="st17" d="M301.2,243.2l-1.3-1.3l1.2,1.3H301.2z M301.2,243.2L301.2,243.2l-1.3-1.3L301.2,243.2z"/>
<path className="st16" d="M302.8,244.6l-1.3-1.2l1.2,1.2H302.8z M302.8,244.6L302.8,244.6l-1.3-1.2L302.8,244.6z"/>
<path className="st17" d="M302.8,244.6l-1.3-1.2l1.2,1.2H302.8z M302.8,244.6L302.8,244.6l-1.3-1.2L302.8,244.6z"/>
<path className="st16" d="M301.4,243.3l-0.4-0.1l0.4,0.2V243.3z M301.2,243.2l0.2,0.1l-0.4-0.1H301.2z"/>
<path className="st17" d="M301.4,243.3l-0.4-0.1l0.4,0.2V243.3z M301.2,243.2l0.2,0.1l-0.4-0.1H301.2z"/>
<path className="st16" d="M301.2,243l-1.3-1.2l1.3,1.3V243z M301.2,243L301.2,243l-1.3-1.2L301.2,243z"/>
<path className="st17" d="M301.2,243l-1.3-1.2l1.3,1.3V243z M301.2,243L301.2,243l-1.3-1.2L301.2,243z"/>
<path className="st16" d="M301.4,243.3L301.4,243.3z"/>
<path className="st17" d="M301.4,243.3L301.4,243.3z"/>
<path className="st16" d="M301.4,243.3v0.1V243.3z"/>
<path className="st17" d="M301.4,243.3v0.1V243.3z"/>
<path className="st16" d="M301.4,243.3L301.4,243.3l-0.2-0.1L301.4,243.3z M301.2,243.2l0.2,0.1L301.2,243.2z"/>
<path className="st17" d="M301.4,243.3L301.4,243.3l-0.2-0.1L301.4,243.3z M301.2,243.2l0.2,0.1L301.2,243.2z"/>
<path className="st16" d="M301.2,243L301.2,243z"/>
<path className="st17" d="M301.2,243L301.2,243z"/>
<path className="st16" d="M301.2,243v0.1V243z"/>
<path className="st17" d="M301.2,243v0.1V243z"/>
<path className="st16" d="M301.4,243.3L301.4,243.3z M301.4,243.3L301.4,243.3z"/>
<path className="st17" d="M301.4,243.3L301.4,243.3z M301.4,243.3L301.4,243.3z"/>
<path className="st16" d="M301.4,243.3l-0.2-0.1L301.4,243.3z M301.3,243.2l0.1,0.1l-0.2-0.1H301.3z"/>
<path className="st17" d="M301.4,243.3l-0.2-0.1L301.4,243.3z M301.3,243.2l0.1,0.1l-0.2-0.1H301.3z"/>
<path className="st16" d="M301.3,243.2L301.3,243.2l-0.1-0.1L301.3,243.2z M301.2,243l0.1,0.1L301.2,243z"/>
<path className="st17" d="M301.3,243.2L301.3,243.2l-0.1-0.1L301.3,243.2z M301.2,243l0.1,0.1L301.2,243z"/>
<path className="st16" d="M301.6,243.3h-0.1H301.6z M301.4,243.2l0.1,0.1h-0.1V243.2z"/>
<path className="st17" d="M301.6,243.3h-0.1H301.6z M301.4,243.2l0.1,0.1h-0.1V243.2z"/>
<path className="st16" d="M301.4,243.2h-0.1l0.1,0.1V243.2z M301.4,243.2L301.4,243.2h-0.1H301.4z"/>
<path className="st17" d="M301.4,243.2h-0.1l0.1,0.1V243.2z M301.4,243.2L301.4,243.2h-0.1H301.4z"/>
<path className="st16" d="M301.3,243.2L301.3,243.2l-0.1-0.1L301.3,243.2z M301.2,243l0.1,0.1L301.2,243z"/>
<path className="st17" d="M301.3,243.2L301.3,243.2l-0.1-0.1L301.3,243.2z M301.2,243l0.1,0.1L301.2,243z"/>
<path className="st16" d="M301.6,243.3L301.6,243.3l-0.1-0.1L301.6,243.3z M301.4,243.2l0.1,0.1L301.4,243.2z"/>
<path className="st17" d="M301.6,243.3L301.6,243.3l-0.1-0.1L301.6,243.3z M301.4,243.2l0.1,0.1L301.4,243.2z"/>
<path className="st16" d="M301.4,243.2l-0.2-0.1l0.1,0.1H301.4z M301.4,243.2L301.4,243.2l-0.2-0.1L301.4,243.2z"/>
<path className="st17" d="M301.4,243.2l-0.2-0.1l0.1,0.1H301.4z M301.4,243.2L301.4,243.2l-0.2-0.1L301.4,243.2z"/>
<path className="st16" d="M302.9,244.5l-1.4-1.1l1.3,1.2L302.9,244.5z M301.6,243.2l1.3,1.3l-1.4-1.1L301.6,243.2z"/>
<path className="st17" d="M302.9,244.5l-1.4-1.1l1.3,1.2L302.9,244.5z M301.6,243.2l1.3,1.3l-1.4-1.1L301.6,243.2z"/>
<path className="st16" d="M301.6,243.2l-0.4-0.1l0.4,0.2V243.2z M301.3,242.9l0.2,0.2l-0.4-0.1L301.3,242.9z"/>
<path className="st17" d="M301.6,243.2l-0.4-0.1l0.4,0.2V243.2z M301.3,242.9l0.2,0.2l-0.4-0.1L301.3,242.9z"/>
<path className="st16" d="M301.3,242.9l-1.4-1.1l1.3,1.2L301.3,242.9z M300.1,241.6l1.2,1.3l-1.4-1.1L300.1,241.6z"/>
<path className="st17" d="M301.3,242.9l-1.4-1.1l1.3,1.2L301.3,242.9z M300.1,241.6l1.2,1.3l-1.4-1.1L300.1,241.6z"/>
<path className="st16" d="M301.8,242.9h-0.5l0.2,0.2L301.8,242.9z M301.6,242.7l0.2,0.2h-0.5L301.6,242.7z"/>
<path className="st17" d="M301.8,242.9h-0.5l0.2,0.2L301.8,242.9z M301.6,242.7l0.2,0.2h-0.5L301.6,242.7z"/>
<polygon className="st16" points="301.8,242.7 301.7,242.6 301.9,242.8 "/>
<polygon className="st17" points="301.8,242.7 301.7,242.6 301.9,242.8 "/>
<path className="st16" d="M302,242.7l-0.4-0.1l0.2,0.2L302,242.7z M301.8,242.4l0.2,0.2l-0.4-0.1L301.8,242.4z"/>
<path className="st17" d="M302,242.7l-0.4-0.1l0.2,0.2L302,242.7z M301.8,242.4l0.2,0.2l-0.4-0.1L301.8,242.4z"/>
<path className="st16" d="M301.9,242.4l0.2,0.1l-0.1-0.1H301.9z M301.8,242.4h0.1l0.2,0.1L301.8,242.4z"/>
<path className="st17" d="M301.9,242.4l0.2,0.1l-0.1-0.1H301.9z M301.8,242.4h0.1l0.2,0.1L301.8,242.4z"/>
<polygon className="st16" points="301.8,242.4 302,242.7 302.2,242.6 "/>
<polygon className="st17" points="301.8,242.4 302,242.7 302.2,242.6 "/>
<path className="st16" d="M303.6,243.8l-1,0.5l0.2,0.2L303.6,243.8z M303.4,243.5l0.2,0.2l-1,0.5L303.4,243.5z"/>
<path className="st17" d="M303.6,243.8l-1,0.5l0.2,0.2L303.6,243.8z M303.4,243.5l0.2,0.2l-1,0.5L303.4,243.5z"/>
<path className="st16" d="M301.1,241.1l-1,0.5l0.2,0.2L301.1,241.1z M300.8,240.9l0.2,0.2l-1,0.5L300.8,240.9z"/>
<path className="st17" d="M301.1,241.1l-1,0.5l0.2,0.2L301.1,241.1z M300.8,240.9l0.2,0.2l-1,0.5L300.8,240.9z"/>
<path className="st16" d="M303.8,243.3l-0.5,0.2l0.2,0.2L303.8,243.3z M303.6,243l0.2,0.2l-0.5,0.2L303.6,243z"/>
<path className="st17" d="M303.8,243.3l-0.5,0.2l0.2,0.2L303.8,243.3z M303.6,243l0.2,0.2l-0.5,0.2L303.6,243z"/>
<path className="st16" d="M301.6,241l-0.7-0.1l0.2,0.2L301.6,241z M301.2,240.6l0.4,0.4l-0.7-0.1L301.2,240.6z"/>
<path className="st17" d="M301.6,241l-0.7-0.1l0.2,0.2L301.6,241z M301.2,240.6l0.4,0.4l-0.7-0.1L301.2,240.6z"/>
<path className="st16" d="M303.8,243.3l-0.2-0.2L303.8,243.3z M303.6,242.9l0.2,0.4l-0.2-0.2V242.9z"/>
<path className="st17" d="M303.8,243.3l-0.2-0.2L303.8,243.3z M303.6,242.9l0.2,0.4l-0.2-0.2V242.9z"/>
<path className="st16" d="M301.6,241l-0.4-0.4L301.6,241z M301.3,240.6l0.2,0.4l-0.4-0.4H301.3z"/>
<path className="st17" d="M301.6,241l-0.4-0.4L301.6,241z M301.3,240.6l0.2,0.4l-0.4-0.4H301.3z"/>
<path className="st16" d="M303.8,243l-0.2-0.1l0.2,0.4V243z M303.4,242.6l0.5,0.5l-0.2-0.1L303.4,242.6z"/>
<path className="st17" d="M303.8,243l-0.2-0.1l0.2,0.4V243z M303.4,242.6l0.5,0.5l-0.2-0.1L303.4,242.6z"/>
<path className="st16" d="M302,241.1l-0.7-0.5l0.2,0.4L302,241.1z M301.4,240.6l0.6,0.5l-0.7-0.5H301.4z"/>
<path className="st17" d="M302,241.1l-0.7-0.5l0.2,0.4L302,241.1z M301.4,240.6l0.6,0.5l-0.7-0.5H301.4z"/>
<path className="st16" d="M303.8,242.8l-2.4-2.2l2.4,2.4V242.8z M301.8,240.6l2,2.2l-2.4-2.2H301.8z"/>
<path className="st17" d="M303.8,242.8l-2.4-2.2l2.4,2.4V242.8z M301.8,240.6l2,2.2l-2.4-2.2H301.8z"/>
<path className="st16" d="M303.6,242.3l-1.8-1.7l2,2.2L303.6,242.3z M302.2,240.9l1.4,1.4l-1.8-1.7L302.2,240.9z"/>
<path className="st17" d="M303.6,242.3l-1.8-1.7l2,2.2L303.6,242.3z M302.2,240.9l1.4,1.4l-1.8-1.7L302.2,240.9z"/>
<line className="st18" x1="302.6" y1="244.2" x2="301.6" y2="243.2"/>
<line className="st18" x1="303.4" y1="242.6" x2="302" y2="241.1"/>
<line className="st18" x1="303.4" y1="243.5" x2="302.6" y2="244.2"/>
<line className="st18" x1="302.3" y1="243.9" x2="301.6" y2="243.2"/>
<line className="st18" x1="303.6" y1="242.3" x2="302.2" y2="240.9"/>
<line className="st18" x1="303.6" y1="243.8" x2="302.2" y2="245.1"/>
<line className="st18" x1="301.8" y1="242.9" x2="301.6" y2="242.7"/>
<path className="st18" d="M301.3,243.1c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C301.3,243.3,301.3,243.2,301.3,243.1"/>
<line className="st18" x1="301.8" y1="242.9" x2="301.4" y2="243.4"/>
<line className="st18" x1="301.6" y1="242.7" x2="301.2" y2="243.2"/>
<path className="st18" d="M302,242.4c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C302.1,242.6,302.1,242.4,302,242.4"/>
<polyline className="st18" points="301.3,242.9 300.4,241.8 301.1,241.1 "/>
<polyline className="st18" points="300.8,240.9 299.4,242.3 302.2,245.1 "/>
<path className="st18" d="M303.3,243.4c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M302,241.1c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M303.5,243.7c0.4-0.4,0.4-1,0-1.4"/>
<path className="st18" d="M302.2,240.8c-0.4-0.4-1-0.4-1.4,0"/>
<polygon className="st16" points="316.3,258.6 316.4,258.6 316.2,258.4 "/>
<polygon className="st17" points="316.3,258.6 316.4,258.6 316.2,258.4 "/>
<path className="st16" d="M314.9,257.2l2.5,3.1l-2.9-2.9L314.9,257.2z M317.6,260l-2.8-2.8l2.5,3.1L317.6,260z"/>
<path className="st17" d="M314.9,257.2l2.5,3.1l-2.9-2.9L314.9,257.2z M317.6,260l-2.8-2.8l2.5,3.1L317.6,260z"/>
<path className="st16" d="M314.9,257.2l1.4,1.4L314.9,257.2z M316.2,258.4l-1.3-1.2l1.4,1.4L316.2,258.4z"/>
<path className="st17" d="M314.9,257.2l1.4,1.4L314.9,257.2z M316.2,258.4l-1.3-1.2l1.4,1.4L316.2,258.4z"/>
<path className="st16" d="M316.4,258.6l1.2,1.3l-1.3-1.3H316.4z M317.8,260l-1.3-1.3l1.2,1.3H317.8z"/>
<path className="st17" d="M316.4,258.6l1.2,1.3l-1.3-1.3H316.4z M317.8,260l-1.3-1.3l1.2,1.3H317.8z"/>
<path className="st16" d="M315,257.1l1.2,1.3l-1.3-1.2L315,257.1z M316.3,258.3l-1.3-1.2l1.2,1.3L316.3,258.3z"/>
<path className="st17" d="M315,257.1l1.2,1.3l-1.3-1.2L315,257.1z M316.3,258.3l-1.3-1.2l1.2,1.3L316.3,258.3z"/>
<path className="st16" d="M316.3,258.3l0.1,0.4l-0.2-0.2L316.3,258.3z M316.6,258.6l-0.2-0.4l0.1,0.4H316.6z"/>
<path className="st17" d="M316.3,258.3l0.1,0.4l-0.2-0.2L316.3,258.3z M316.6,258.6l-0.2-0.4l0.1,0.4H316.6z"/>
<path className="st16" d="M316.6,258.6l1.2,1.3l-1.3-1.3H316.6z M317.8,259.8l-1.2-1.2l1.2,1.3V259.8z"/>
<path className="st17" d="M316.6,258.6l1.2,1.3l-1.3-1.3H316.6z M317.8,259.8l-1.2-1.2l1.2,1.3V259.8z"/>
<path className="st16" d="M315,257.1L315,257.1l1.3,1.2L315,257.1z M316.3,258.3l-1.3-1.2L316.3,258.3z"/>
<path className="st17" d="M315,257.1L315,257.1l1.3,1.2L315,257.1z M316.3,258.3l-1.3-1.2L316.3,258.3z"/>
<path className="st16" d="M316.6,258.5l1.2,1.3l-1.2-1.2V258.5z M317.9,259.8l-1.3-1.3l1.2,1.3H317.9z"/>
<path className="st17" d="M316.6,258.5l1.2,1.3l-1.2-1.2V258.5z M317.9,259.8l-1.3-1.3l1.2,1.3H317.9z"/>
<path className="st16" d="M316.6,258.5L316.6,258.5z"/>
<path className="st17" d="M316.6,258.5L316.6,258.5z"/>
<path className="st16" d="M316.3,258.3L316.3,258.3z"/>
<path className="st17" d="M316.3,258.3L316.3,258.3z"/>
<path className="st16" d="M315,257l1.3,1.3l-1.3-1.2V257z M315,257L315,257l1.3,1.3L315,257z"/>
<path className="st17" d="M315,257l1.3,1.3l-1.3-1.2V257z M315,257L315,257l1.3,1.3L315,257z"/>
<path className="st16" d="M316.6,258.5L316.6,258.5l1.3,1.3L316.6,258.5z M317.9,259.8l-1.3-1.3L317.9,259.8z"/>
<path className="st17" d="M316.6,258.5L316.6,258.5l1.3,1.3L316.6,258.5z M317.9,259.8l-1.3-1.3L317.9,259.8z"/>
<path className="st16" d="M315.1,257l1.2,1.3L315,257H315.1z M315.1,257L315.1,257l1.2,1.3L315.1,257z"/>
<path className="st17" d="M315.1,257l1.2,1.3L315,257H315.1z M315.1,257L315.1,257l1.2,1.3L315.1,257z"/>
<path className="st16" d="M316.3,258.3l0.2,0.4L316.3,258.3z M316.6,258.5l-0.2-0.2l0.2,0.4V258.5z"/>
<path className="st17" d="M316.3,258.3l0.2,0.4L316.3,258.3z M316.6,258.5l-0.2-0.2l0.2,0.4V258.5z"/>
<path className="st16" d="M316.6,258.5L316.6,258.5l1.3,1.3L316.6,258.5z M317.9,259.8l-1.3-1.3L317.9,259.8z"/>
<path className="st17" d="M316.6,258.5L316.6,258.5l1.3,1.3L316.6,258.5z M317.9,259.8l-1.3-1.3L317.9,259.8z"/>
<path className="st16" d="M316.3,258.2v0.1V258.2z M316.4,258.3l-0.1-0.1v0.1H316.4z"/>
<path className="st17" d="M316.3,258.2v0.1V258.2z M316.4,258.3l-0.1-0.1v0.1H316.4z"/>
<path className="st16" d="M316.4,258.3l0.1,0.2l-0.2-0.2H316.4z M316.6,258.4l-0.1-0.1l0.1,0.2V258.4z"/>
<path className="st17" d="M316.4,258.3l0.1,0.2l-0.2-0.2H316.4z M316.6,258.4l-0.1-0.1l0.1,0.2V258.4z"/>
<path className="st16" d="M316.6,258.4v0.1V258.4z M316.7,258.5l-0.1-0.1v0.1H316.7z"/>
<path className="st17" d="M316.6,258.4v0.1V258.4z M316.7,258.5l-0.1-0.1v0.1H316.7z"/>
<path className="st16" d="M316.3,258.2L316.3,258.2l0.1,0.1L316.3,258.2z M316.4,258.3l-0.1-0.1L316.4,258.3z"/>
<path className="st17" d="M316.3,258.2L316.3,258.2l0.1,0.1L316.3,258.2z M316.4,258.3l-0.1-0.1L316.4,258.3z"/>
<path className="st16" d="M316.4,258.3L316.4,258.3l0.1,0.1L316.4,258.3z M316.6,258.4l-0.1-0.1L316.6,258.4z"/>
<path className="st17" d="M316.4,258.3L316.4,258.3l0.1,0.1L316.4,258.3z M316.6,258.4l-0.1-0.1L316.6,258.4z"/>
<path className="st16" d="M316.6,258.4L316.6,258.4l0.1,0.1L316.6,258.4z M316.7,258.5l-0.1-0.1L316.7,258.5z"/>
<path className="st17" d="M316.6,258.4L316.6,258.4l0.1,0.1L316.6,258.4z M316.7,258.5l-0.1-0.1L316.7,258.5z"/>
<path className="st16" d="M316.4,258.2v0.1l-0.1-0.1H316.4z M316.4,258.2L316.4,258.2v0.1V258.2z"/>
<path className="st17" d="M316.4,258.2v0.1l-0.1-0.1H316.4z M316.4,258.2L316.4,258.2v0.1V258.2z"/>
<path className="st16" d="M316.6,258.3v0.1l-0.1-0.1H316.6z M316.6,258.3L316.6,258.3v0.1V258.3z"/>
<path className="st17" d="M316.6,258.3v0.1l-0.1-0.1H316.6z M316.6,258.3L316.6,258.3v0.1V258.3z"/>
<path className="st16" d="M316.6,258.4l0.1,0.1L316.6,258.4z M316.7,258.4h-0.1l0.1,0.1V258.4z"/>
<path className="st17" d="M316.6,258.4l0.1,0.1L316.6,258.4z M316.7,258.4h-0.1l0.1,0.1V258.4z"/>
<path className="st16" d="M316.4,258.2L316.4,258.2l0.1,0.1L316.4,258.2z M316.6,258.3l-0.1-0.1L316.6,258.3z"/>
<path className="st17" d="M316.4,258.2L316.4,258.2l0.1,0.1L316.4,258.2z M316.6,258.3l-0.1-0.1L316.6,258.3z"/>
<path className="st16" d="M316.6,258.3l0.1,0.1h-0.1V258.3z M316.6,258.3L316.6,258.3l0.1,0.1L316.6,258.3z"/>
<path className="st17" d="M316.6,258.3l0.1,0.1h-0.1V258.3z M316.6,258.3L316.6,258.3l0.1,0.1L316.6,258.3z"/>
<path className="st16" d="M315.2,256.8l1.1,1.4l-1.2-1.3L315.2,256.8z M316.4,258l-1.2-1.2l1.1,1.4L316.4,258z"/>
<path className="st17" d="M315.2,256.8l1.1,1.4l-1.2-1.3L315.2,256.8z M316.4,258l-1.2-1.2l1.1,1.4L316.4,258z"/>
<path className="st16" d="M316.4,258l0.2,0.4l-0.2-0.2V258z M316.8,258.4l-0.4-0.4l0.2,0.4H316.8z"/>
<path className="st17" d="M316.4,258l0.2,0.4l-0.2-0.2V258z M316.8,258.4l-0.4-0.4l0.2,0.4H316.8z"/>
<path className="st16" d="M316.8,258.4l1.1,1.4l-1.3-1.3L316.8,258.4z M318,259.6l-1.2-1.2l1.1,1.4L318,259.6z"/>
<path className="st17" d="M316.8,258.4l1.1,1.4l-1.3-1.3L316.8,258.4z M318,259.6l-1.2-1.2l1.1,1.4L318,259.6z"/>
<path className="st16" d="M316.7,257.8l0.1,0.6l-0.4-0.4L316.7,257.8z M317,258.2l-0.4-0.4l0.1,0.6L317,258.2z"/>
<path className="st17" d="M316.7,257.8l0.1,0.6l-0.4-0.4L316.7,257.8z M317,258.2l-0.4-0.4l0.1,0.6L317,258.2z"/>
<polygon className="st16" points="316.9,257.9 317.2,257.9 316.9,257.8 "/>
<polygon className="st17" points="316.9,257.9 317.2,257.9 316.9,257.8 "/>
<path className="st16" d="M316.9,257.6l0.2,0.4l-0.2-0.1V257.6z M317.3,257.9l-0.4-0.4l0.2,0.4H317.3z"/>
<path className="st17" d="M316.9,257.6l0.2,0.4l-0.2-0.1V257.6z M317.3,257.9l-0.4-0.4l0.2,0.4H317.3z"/>
<path className="st16" d="M317.3,257.8l-0.1-0.2h0.1V257.8z M317.3,257.9v-0.1l-0.1-0.2L317.3,257.9z"/>
<path className="st17" d="M317.3,257.8l-0.1-0.2h0.1V257.8z M317.3,257.9v-0.1l-0.1-0.2L317.3,257.9z"/>
<polygon className="st16" points="317.3,257.9 316.9,257.6 317.2,257.6 "/>
<polygon className="st17" points="317.3,257.9 316.9,257.6 317.2,257.6 "/>
<path className="st16" d="M316,256.1l-0.5,1l-0.2-0.2L316,256.1z M316.2,256.4l-0.2-0.2l-0.5,1L316.2,256.4z"/>
<path className="st17" d="M316,256.1l-0.5,1l-0.2-0.2L316,256.1z M316.2,256.4l-0.2-0.2l-0.5,1L316.2,256.4z"/>
<path className="st16" d="M318.6,258.6l-0.6,1l-0.2-0.2L318.6,258.6z M318.7,258.9l-0.1-0.2l-0.6,1L318.7,258.9z"/>
<path className="st17" d="M318.6,258.6l-0.6,1l-0.2-0.2L318.6,258.6z M318.7,258.9l-0.1-0.2l-0.6,1L318.7,258.9z"/>
<path className="st16" d="M316.4,255.9l-0.2,0.5l-0.2-0.2L316.4,255.9z M316.7,256.1l-0.2-0.2l-0.2,0.5L316.7,256.1z"/>
<path className="st17" d="M316.4,255.9l-0.2,0.5l-0.2-0.2L316.4,255.9z M316.7,256.1l-0.2-0.2l-0.2,0.5L316.7,256.1z"/>
<path className="st16" d="M318.7,258.2v0.7l-0.1-0.2L318.7,258.2z M319,258.4l-0.2-0.2v0.7L319,258.4z"/>
<path className="st17" d="M318.7,258.2v0.7l-0.1-0.2L318.7,258.2z M319,258.4l-0.2-0.2v0.7L319,258.4z"/>
<path className="st16" d="M316.4,255.8l0.2,0.4l-0.2-0.2V255.8z M316.8,256.1l-0.4-0.4l0.2,0.4H316.8z"/>
<path className="st17" d="M316.4,255.8l0.2,0.4l-0.2-0.2V255.8z M316.8,256.1l-0.4-0.4l0.2,0.4H316.8z"/>
<path className="st16" d="M318.7,258.2l0.2,0.2L318.7,258.2z M319.1,258.4l-0.4-0.2l0.2,0.2H319.1z"/>
<path className="st17" d="M318.7,258.2l0.2,0.2L318.7,258.2z M319.1,258.4l-0.4-0.2l0.2,0.2H319.1z"/>
<path className="st16" d="M316.7,255.8l0.1,0.4l-0.4-0.4H316.7z M317.2,256.4l-0.5-0.6l0.1,0.4L317.2,256.4z"/>
<path className="st17" d="M316.7,255.8l0.1,0.4l-0.4-0.4H316.7z M317.2,256.4l-0.5-0.6l0.1,0.4L317.2,256.4z"/>
<path className="st16" d="M318.6,257.7l0.5,0.7l-0.4-0.2L318.6,257.7z M319.1,258.2l-0.5-0.5l0.5,0.7V258.2z"/>
<path className="st17" d="M318.6,257.7l0.5,0.7l-0.4-0.2L318.6,257.7z M319.1,258.2l-0.5-0.5l0.5,0.7V258.2z"/>
<path className="st16" d="M316.9,255.8l2.2,2.4l-2.4-2.4H316.9z M319.1,257.9l-2.2-2.2l2.2,2.4V257.9z"/>
<path className="st17" d="M316.9,255.8l2.2,2.4l-2.4-2.4H316.9z M319.1,257.9l-2.2-2.2l2.2,2.4V257.9z"/>
<path className="st16" d="M317.4,256.1l1.7,1.8l-2.2-2.2L317.4,256.1z M318.7,257.4l-1.3-1.3l1.7,1.8L318.7,257.4z"/>
<path className="st17" d="M317.4,256.1l1.7,1.8l-2.2-2.2L317.4,256.1z M318.7,257.4l-1.3-1.3l1.7,1.8L318.7,257.4z"/>
<line className="st18" x1="315.5" y1="257.1" x2="316.4" y2="258"/>
<line className="st18" x1="317.2" y1="256.4" x2="318.6" y2="257.7"/>
<line className="st18" x1="316.2" y1="256.4" x2="315.5" y2="257.1"/>
<line className="st18" x1="315.8" y1="257.4" x2="316.4" y2="258"/>
<line className="st18" x1="317.4" y1="256.1" x2="318.7" y2="257.4"/>
<line className="st18" x1="316" y1="256.1" x2="314.5" y2="257.4"/>
<line className="st18" x1="316.7" y1="257.8" x2="317" y2="258.2"/>
<path className="st18" d="M316.3,258.5c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C316.2,258.3,316.2,258.4,316.3,258.5"/>
<line className="st18" x1="316.7" y1="257.8" x2="316.3" y2="258.3"/>
<line className="st18" x1="317" y1="258.2" x2="316.6" y2="258.5"/>
<path className="st18" d="M317,257.9c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C316.9,257.7,316.9,257.8,317,257.9"/>
<polyline className="st18" points="316.8,258.4 317.8,259.4 318.6,258.6 "/>
<polyline className="st18" points="318.7,258.9 317.4,260.3 314.5,257.4 "/>
<path className="st18" d="M317.1,256.2c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M318.5,258.6c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M317.3,255.9c-0.4-0.4-1-0.4-1.4,0"/>
<path className="st18" d="M318.8,258.8c0.4-0.4,0.4-1,0-1.4"/>
<polygon className="st16" points="336.1,278.4 336,278.3 336.2,278.4 "/>
<polygon className="st17" points="336.1,278.4 336,278.3 336.2,278.4 "/>
<path className="st16" d="M337.4,279.8l-3-2.5l2.8,2.9L337.4,279.8z M334.7,277l2.8,2.8l-3-2.5L334.7,277z"/>
<path className="st17" d="M337.4,279.8l-3-2.5l2.8,2.9L337.4,279.8z M334.7,277l2.8,2.8l-3-2.5L334.7,277z"/>
<path className="st16" d="M337.6,279.8l-1.4-1.3l1.3,1.3H337.6z M336.2,278.4l1.3,1.3l-1.4-1.3H336.2z"/>
<path className="st17" d="M337.6,279.8l-1.4-1.3l1.3,1.3H337.6z M336.2,278.4l1.3,1.3l-1.4-1.3H336.2z"/>
<path className="st16" d="M336,278.3l-1.3-1.3l1.4,1.4L336,278.3z M336,278.3L336,278.3l-1.3-1.3L336,278.3z"/>
<path className="st17" d="M336,278.3l-1.3-1.3l1.4,1.4L336,278.3z M336,278.3L336,278.3l-1.3-1.3L336,278.3z"/>
<path className="st16" d="M337.7,279.6l-1.4-1.2l1.3,1.3L337.7,279.6z M336.4,278.4l1.3,1.2l-1.4-1.2H336.4z"/>
<path className="st17" d="M337.7,279.6l-1.4-1.2l1.3,1.3L337.7,279.6z M336.4,278.4l1.3,1.2l-1.4-1.2H336.4z"/>
<path className="st16" d="M336.4,278.4l-0.4-0.1l0.2,0.1H336.4z M336.1,278.1l0.2,0.4l-0.4-0.1L336.1,278.1z"/>
<path className="st17" d="M336.4,278.4l-0.4-0.1l0.2,0.1H336.4z M336.1,278.1l0.2,0.4l-0.4-0.1L336.1,278.1z"/>
<path className="st16" d="M336.1,278.1l-1.4-1.1l1.3,1.3L336.1,278.1z M334.8,276.9l1.3,1.2l-1.4-1.1L334.8,276.9z"/>
<path className="st17" d="M336.1,278.1l-1.4-1.1l1.3,1.3L336.1,278.1z M334.8,276.9l1.3,1.2l-1.4-1.1L334.8,276.9z"/>
<path className="st16" d="M337.7,279.6L337.7,279.6l-1.3-1.2L337.7,279.6z M336.4,278.4l1.3,1.2L336.4,278.4z"/>
<path className="st17" d="M337.7,279.6L337.7,279.6l-1.3-1.2L337.7,279.6z M336.4,278.4l1.3,1.2L336.4,278.4z"/>
<path className="st16" d="M336.1,278.1L336.1,278.1l-1.3-1.2L336.1,278.1z M334.8,276.9l1.3,1.2L334.8,276.9z"/>
<path className="st17" d="M336.1,278.1L336.1,278.1l-1.3-1.2L336.1,278.1z M334.8,276.9l1.3,1.2L334.8,276.9z"/>
<path className="st16" d="M336.1,278.1h0.1H336.1z"/>
<path className="st17" d="M336.1,278.1h0.1H336.1z"/>
<path className="st16" d="M336.4,278.3h0.1H336.4z"/>
<path className="st17" d="M336.4,278.3h0.1H336.4z"/>
<path className="st16" d="M337.7,279.6l-1.3-1.2L337.7,279.6z M336.4,278.3l1.3,1.3l-1.3-1.2V278.3z"/>
<path className="st17" d="M337.7,279.6l-1.3-1.2L337.7,279.6z M336.4,278.3l1.3,1.3l-1.3-1.2V278.3z"/>
<path className="st16" d="M336.1,278.1l-1.3-1.2L336.1,278.1z M334.9,276.9l1.2,1.2l-1.3-1.2H334.9z"/>
<path className="st17" d="M336.1,278.1l-1.3-1.2L336.1,278.1z M334.9,276.9l1.2,1.2l-1.3-1.2H334.9z"/>
<path className="st16" d="M337.7,279.6l-1.3-1.3L337.7,279.6z M336.5,278.3l1.2,1.3l-1.3-1.3H336.5z"/>
<path className="st17" d="M337.7,279.6l-1.3-1.3L337.7,279.6z M336.5,278.3l1.2,1.3l-1.3-1.3H336.5z"/>
<path className="st16" d="M336.4,278.3l-0.2-0.2l0.2,0.4V278.3z M336.2,278.1l0.1,0.2l-0.2-0.2H336.2z"/>
<path className="st17" d="M336.4,278.3l-0.2-0.2l0.2,0.4V278.3z M336.2,278.1l0.1,0.2l-0.2-0.2H336.2z"/>
<path className="st16" d="M336.1,278.1l-1.2-1.2L336.1,278.1z M334.9,276.8l1.2,1.3l-1.2-1.2V276.8z"/>
<path className="st17" d="M336.1,278.1l-1.2-1.2L336.1,278.1z M334.9,276.8l1.2,1.3l-1.2-1.2V276.8z"/>
<path className="st16" d="M336.5,278.3L336.5,278.3h-0.1H336.5z M336.4,278.3h0.1H336.4z"/>
<path className="st17" d="M336.5,278.3L336.5,278.3h-0.1H336.5z M336.4,278.3h0.1H336.4z"/>
<path className="st16" d="M336.4,278.2l-0.1-0.1l0.1,0.2V278.2z M336.4,278.2L336.4,278.2l-0.1-0.1L336.4,278.2z"/>
<path className="st17" d="M336.4,278.2l-0.1-0.1l0.1,0.2V278.2z M336.4,278.2L336.4,278.2l-0.1-0.1L336.4,278.2z"/>
<path className="st16" d="M336.2,278.1L336.2,278.1h-0.1H336.2z M336.1,278.1h0.1H336.1z"/>
<path className="st17" d="M336.2,278.1L336.2,278.1h-0.1H336.2z M336.1,278.1h0.1H336.1z"/>
<path className="st16" d="M336.5,278.3l-0.1-0.1L336.5,278.3z"/>
<path className="st17" d="M336.5,278.3l-0.1-0.1L336.5,278.3z"/>
<path className="st16" d="M336.5,278.3L336.5,278.3z"/>
<path className="st17" d="M336.5,278.3L336.5,278.3z"/>
<path className="st16" d="M336.4,278.2L336.4,278.2l-0.1-0.1L336.4,278.2z M336.2,278.1l0.1,0.1L336.2,278.1z"/>
<path className="st17" d="M336.4,278.2L336.4,278.2l-0.1-0.1L336.4,278.2z M336.2,278.1l0.1,0.1L336.2,278.1z"/>
<path className="st16" d="M336.2,278.1L336.2,278.1z M336.2,278.1L336.2,278.1z"/>
<path className="st17" d="M336.2,278.1L336.2,278.1z M336.2,278.1L336.2,278.1z"/>
<path className="st16" d="M336.5,278.3L336.5,278.3l-0.1-0.1L336.5,278.3z M336.4,278.2l0.1,0.1L336.4,278.2z"/>
<path className="st17" d="M336.5,278.3L336.5,278.3l-0.1-0.1L336.5,278.3z M336.4,278.2l0.1,0.1L336.4,278.2z"/>
<path className="st16" d="M336.4,278.2l-0.1-0.1L336.4,278.2z M336.4,278.1v0.1l-0.1-0.1H336.4z"/>
<path className="st17" d="M336.4,278.2l-0.1-0.1L336.4,278.2z M336.4,278.1v0.1l-0.1-0.1H336.4z"/>
<path className="st16" d="M336.2,278.1V278V278.1z"/>
<path className="st17" d="M336.2,278.1V278V278.1z"/>
<path className="st16" d="M336.2,278.1L336.2,278.1z"/>
<path className="st17" d="M336.2,278.1L336.2,278.1z"/>
<path className="st16" d="M336.5,278.3l-0.1-0.1L336.5,278.3z M336.4,278.1l0.1,0.2l-0.1-0.1V278.1z"/>
<path className="st17" d="M336.5,278.3l-0.1-0.1L336.5,278.3z M336.4,278.1l0.1,0.2l-0.1-0.1V278.1z"/>
<path className="st16" d="M336.4,278.1L336.4,278.1l-0.1-0.1L336.4,278.1z M336.2,278l0.1,0.1L336.2,278z"/>
<path className="st17" d="M336.4,278.1L336.4,278.1l-0.1-0.1L336.4,278.1z M336.2,278l0.1,0.1L336.2,278z"/>
<path className="st16" d="M337.8,279.4l-1.3-1.1l1.2,1.3L337.8,279.4z M336.6,278.2l1.2,1.2l-1.3-1.1L336.6,278.2z"/>
<path className="st17" d="M337.8,279.4l-1.3-1.1l1.2,1.3L337.8,279.4z M336.6,278.2l1.2,1.2l-1.3-1.1L336.6,278.2z"/>
<path className="st16" d="M336.6,278.2l-0.4-0.2l0.2,0.4L336.6,278.2z M336.4,278l0.2,0.2l-0.4-0.2H336.4z"/>
<path className="st17" d="M336.6,278.2l-0.4-0.2l0.2,0.4L336.6,278.2z M336.4,278l0.2,0.2l-0.4-0.2H336.4z"/>
<path className="st16" d="M336.4,278l-1.4-1.2l1.2,1.3L336.4,278z M335,276.6l1.3,1.3l-1.4-1.2L335,276.6z"/>
<path className="st17" d="M336.4,278l-1.4-1.2l1.2,1.3L336.4,278z M335,276.6l1.3,1.3l-1.4-1.2L335,276.6z"/>
<path className="st16" d="M336.8,278h-0.5l0.2,0.2L336.8,278z M336.6,277.7l0.2,0.2h-0.5L336.6,277.7z"/>
<path className="st17" d="M336.8,278h-0.5l0.2,0.2L336.8,278z M336.6,277.7l0.2,0.2h-0.5L336.6,277.7z"/>
<polygon className="st16" points="336.7,277.7 336.7,277.6 337,277.7 "/>
<polygon className="st17" points="336.7,277.7 336.7,277.6 337,277.7 "/>
<path className="st16" d="M337.1,277.7l-0.4-0.1l0.2,0.1H337.1z M336.7,277.5l0.4,0.2l-0.4-0.1V277.5z"/>
<path className="st17" d="M337.1,277.7l-0.4-0.1l0.2,0.1H337.1z M336.7,277.5l0.4,0.2l-0.4-0.1V277.5z"/>
<path className="st16" d="M337,277.4l0.1,0.2v-0.1L337,277.4z M336.7,277.5l0.2-0.1l0.1,0.2L336.7,277.5z"/>
<path className="st17" d="M337,277.4l0.1,0.2v-0.1L337,277.4z M336.7,277.5l0.2-0.1l0.1,0.2L336.7,277.5z"/>
<polygon className="st16" points="336.7,277.5 337.1,277.7 337.1,277.6 "/>
<polygon className="st17" points="336.7,277.5 337.1,277.7 337.1,277.6 "/>
<path className="st16" d="M338.6,278.7l-1,0.5l0.1,0.2L338.6,278.7z M338.4,278.4l0.2,0.2l-1,0.5L338.4,278.4z"/>
<path className="st17" d="M338.6,278.7l-1,0.5l0.1,0.2L338.6,278.7z M338.4,278.4l0.2,0.2l-1,0.5L338.4,278.4z"/>
<path className="st16" d="M336,276.2l-1,0.5l0.2,0.2L336,276.2z M335.8,275.9l0.2,0.2l-1,0.5L335.8,275.9z"/>
<path className="st17" d="M336,276.2l-1,0.5l0.2,0.2L336,276.2z M335.8,275.9l0.2,0.2l-1,0.5L335.8,275.9z"/>
<path className="st16" d="M338.9,278.3l-0.5,0.1l0.2,0.2L338.9,278.3z M338.5,278l0.4,0.4l-0.5,0.1L338.5,278z"/>
<path className="st17" d="M338.9,278.3l-0.5,0.1l0.2,0.2L338.9,278.3z M338.5,278l0.4,0.4l-0.5,0.1L338.5,278z"/>
<path className="st16" d="M336.5,275.9h-0.7l0.2,0.2L336.5,275.9z M336.2,275.7l0.2,0.2h-0.7L336.2,275.7z"/>
<path className="st17" d="M336.5,275.9h-0.7l0.2,0.2L336.5,275.9z M336.2,275.7l0.2,0.2h-0.7L336.2,275.7z"/>
<path className="st16" d="M338.9,278.2l-0.4-0.2l0.4,0.4V278.2z M338.9,278.2L338.9,278.2l-0.4-0.2L338.9,278.2z"/>
<path className="st17" d="M338.9,278.2l-0.4-0.2l0.4,0.4V278.2z M338.9,278.2L338.9,278.2l-0.4-0.2L338.9,278.2z"/>
<path className="st16" d="M336.6,275.9l-0.4-0.2l0.2,0.2H336.6z M336.6,275.9L336.6,275.9l-0.4-0.2L336.6,275.9z"/>
<path className="st17" d="M336.6,275.9l-0.4-0.2l0.2,0.2H336.6z M336.6,275.9L336.6,275.9l-0.4-0.2L336.6,275.9z"/>
<path className="st16" d="M338.9,278.1l-0.4-0.1l0.4,0.2V278.1z M338.4,277.5l0.5,0.6l-0.4-0.1L338.4,277.5z"/>
<path className="st17" d="M338.9,278.1l-0.4-0.1l0.4,0.2V278.1z M338.4,277.5l0.5,0.6l-0.4-0.1L338.4,277.5z"/>
<path className="st16" d="M337,276.2l-0.7-0.5l0.4,0.2L337,276.2z M336.5,275.7l0.5,0.5l-0.7-0.5H336.5z"/>
<path className="st17" d="M337,276.2l-0.7-0.5l0.4,0.2L337,276.2z M336.5,275.7l0.5,0.5l-0.7-0.5H336.5z"/>
<path className="st16" d="M338.9,277.7l-2.4-2l2.4,2.4V277.7z M336.7,275.7l2.2,2l-2.4-2H336.7z"/>
<path className="st17" d="M338.9,277.7l-2.4-2l2.4,2.4V277.7z M336.7,275.7l2.2,2l-2.4-2H336.7z"/>
<path className="st16" d="M338.6,277.2l-1.9-1.6l2.2,2L338.6,277.2z M337.2,275.9l1.4,1.3l-1.9-1.6L337.2,275.9z"/>
<path className="st17" d="M338.6,277.2l-1.9-1.6l2.2,2L338.6,277.2z M337.2,275.9l1.4,1.3l-1.9-1.6L337.2,275.9z"/>
<line className="st18" x1="337.7" y1="279.2" x2="336.6" y2="278.2"/>
<line className="st18" x1="338.4" y1="277.5" x2="337" y2="276.2"/>
<line className="st18" x1="338.4" y1="278.4" x2="337.7" y2="279.2"/>
<line className="st18" x1="337.3" y1="278.9" x2="336.6" y2="278.2"/>
<line className="st18" x1="338.6" y1="277.2" x2="337.2" y2="275.9"/>
<line className="st18" x1="338.6" y1="278.7" x2="337.2" y2="280.1"/>
<line className="st18" x1="336.8" y1="278" x2="336.6" y2="277.7"/>
<path className="st18" d="M336.3,278.1c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C336.4,278.3,336.4,278.2,336.3,278.1"/>
<line className="st18" x1="336.8" y1="278" x2="336.4" y2="278.3"/>
<line className="st18" x1="336.6" y1="277.7" x2="336.1" y2="278.1"/>
<path className="st18" d="M337,277.4c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C337.1,277.6,337.1,277.5,337,277.4"/>
<polyline className="st18" points="336.4,278 335.3,276.9 336,276.2 "/>
<polyline className="st18" points="335.8,275.9 334.4,277.2 337.2,280.1 "/>
<path className="st18" d="M338.3,278.4c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M336.9,276.1c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M338.6,278.6c0.4-0.4,0.4-1,0-1.4"/>
<path className="st18" d="M337.1,275.9c-0.4-0.4-1-0.4-1.4,0"/>
<polygon className="st16" points="351.2,293.6 351.4,293.7 351.2,293.4 "/>
<polygon className="st17" points="351.2,293.6 351.4,293.7 351.2,293.4 "/>
<path className="st16" d="M349.8,292.1l2.5,3.1l-2.8-2.8L349.8,292.1z M352.7,295l-2.9-2.9l2.5,3.1L352.7,295z"/>
<path className="st17" d="M349.8,292.1l2.5,3.1l-2.8-2.8L349.8,292.1z M352.7,295l-2.9-2.9l2.5,3.1L352.7,295z"/>
<path className="st16" d="M349.9,292.1l1.3,1.4l-1.4-1.4H349.9z M351.2,293.4l-1.3-1.3l1.3,1.4V293.4z"/>
<path className="st17" d="M349.9,292.1l1.3,1.4l-1.4-1.4H349.9z M351.2,293.4l-1.3-1.3l1.3,1.4V293.4z"/>
<path className="st16" d="M351.4,293.7l1.3,1.3l-1.4-1.4L351.4,293.7z M352.7,294.9l-1.3-1.2l1.3,1.3V294.9z"/>
<path className="st17" d="M351.4,293.7l1.3,1.3l-1.4-1.4L351.4,293.7z M352.7,294.9l-1.3-1.2l1.3,1.3V294.9z"/>
<path className="st16" d="M350,292l1.2,1.4l-1.3-1.3L350,292z M351.2,293.3L350,292l1.2,1.4V293.3z"/>
<path className="st17" d="M350,292l1.2,1.4l-1.3-1.3L350,292z M351.2,293.3L350,292l1.2,1.4V293.3z"/>
<path className="st16" d="M351.2,293.3l0.1,0.4l-0.1-0.2V293.3z M351.5,293.6l-0.2-0.2l0.1,0.4L351.5,293.6z"/>
<path className="st17" d="M351.2,293.3l0.1,0.4l-0.1-0.2V293.3z M351.5,293.6l-0.2-0.2l0.1,0.4L351.5,293.6z"/>
<path className="st16" d="M351.5,293.6l1.2,1.3l-1.3-1.2L351.5,293.6z M352.8,294.9l-1.3-1.3l1.2,1.3H352.8z"/>
<path className="st17" d="M351.5,293.6l1.2,1.3l-1.3-1.2L351.5,293.6z M352.8,294.9l-1.3-1.3l1.2,1.3H352.8z"/>
<path className="st16" d="M350,292L350,292l1.2,1.3L350,292z M351.2,293.3L350,292L351.2,293.3z"/>
<path className="st17" d="M350,292L350,292l1.2,1.3L350,292z M351.2,293.3L350,292L351.2,293.3z"/>
<path className="st16" d="M351.6,293.6l1.2,1.3l-1.3-1.3H351.6z M352.8,294.8l-1.2-1.2l1.2,1.3V294.8z"/>
<path className="st17" d="M351.6,293.6l1.2,1.3l-1.3-1.3H351.6z M352.8,294.8l-1.2-1.2l1.2,1.3V294.8z"/>
<path className="st16" d="M351.6,293.6v-0.1V293.6z"/>
<path className="st17" d="M351.6,293.6v-0.1V293.6z"/>
<path className="st16" d="M351.2,293.3l0.1-0.1L351.2,293.3z"/>
<path className="st17" d="M351.2,293.3l0.1-0.1L351.2,293.3z"/>
<path className="st16" d="M350,292L350,292l1.2,1.3L350,292z M351.2,293.3L350,292L351.2,293.3z"/>
<path className="st17" d="M350,292L350,292l1.2,1.3L350,292z M351.2,293.3L350,292L351.2,293.3z"/>
<path className="st16" d="M351.6,293.6L351.6,293.6l1.2,1.2L351.6,293.6z M352.8,294.8l-1.2-1.2L352.8,294.8z"/>
<path className="st17" d="M351.6,293.6L351.6,293.6l1.2,1.2L351.6,293.6z M352.8,294.8l-1.2-1.2L352.8,294.8z"/>
<path className="st16" d="M350,292l1.2,1.3L350,292z M351.4,293.2L350,292l1.2,1.3L351.4,293.2z"/>
<path className="st17" d="M350,292l1.2,1.3L350,292z M351.4,293.2L350,292l1.2,1.3L351.4,293.2z"/>
<path className="st16" d="M351.4,293.2l0.1,0.4l-0.2-0.2L351.4,293.2z M351.6,293.4l-0.2-0.2l0.1,0.4L351.6,293.4z"/>
<path className="st17" d="M351.4,293.2l0.1,0.4l-0.2-0.2L351.4,293.2z M351.6,293.4l-0.2-0.2l0.1,0.4L351.6,293.4z"/>
<path className="st16" d="M351.6,293.6L351.6,293.6l1.2,1.2L351.6,293.6z M352.8,294.8l-1.2-1.2L352.8,294.8z"/>
<path className="st17" d="M351.6,293.6L351.6,293.6l1.2,1.2L351.6,293.6z M352.8,294.8l-1.2-1.2L352.8,294.8z"/>
<path className="st16" d="M351.4,293.2L351.4,293.2l0.2,0.2L351.4,293.2z M351.4,293.2L351.4,293.2z M351.4,293.2L351.4,293.2z
	 M351.6,293.4l-0.2-0.2L351.6,293.4z"/>
<path className="st17" d="M351.4,293.2L351.4,293.2l0.2,0.2L351.4,293.2z M351.4,293.2L351.4,293.2z M351.4,293.2L351.4,293.2z
	 M351.6,293.4l-0.2-0.2L351.6,293.4z"/>
<path className="st16" d="M351.6,293.4L351.6,293.4v0.1V293.4z M351.6,293.6v-0.1V293.6z"/>
<path className="st17" d="M351.6,293.4L351.6,293.4v0.1V293.4z M351.6,293.6v-0.1V293.6z"/>
<path className="st16" d="M351.4,293.2h0.1H351.4z"/>
<path className="st17" d="M351.4,293.2h0.1H351.4z"/>
<path className="st16" d="M351.4,293.2L351.4,293.2z"/>
<path className="st17" d="M351.4,293.2L351.4,293.2z"/>
<path className="st16" d="M351.5,293.2l0.1,0.2l-0.2-0.2H351.5z M351.5,293.2L351.5,293.2l0.1,0.2L351.5,293.2z"/>
<path className="st17" d="M351.5,293.2l0.1,0.2l-0.2-0.2H351.5z M351.5,293.2L351.5,293.2l0.1,0.2L351.5,293.2z"/>
<path className="st16" d="M351.6,293.4L351.6,293.4z M351.6,293.4L351.6,293.4z"/>
<path className="st17" d="M351.6,293.4L351.6,293.4z M351.6,293.4L351.6,293.4z"/>
<path className="st16" d="M351.4,293.2L351.4,293.2h0.1H351.4z M351.5,293.2h-0.1H351.5z"/>
<path className="st17" d="M351.4,293.2L351.4,293.2h0.1H351.4z M351.5,293.2h-0.1H351.5z"/>
<path className="st16" d="M351.5,293.3l0.1,0.1l-0.1-0.2V293.3z M351.6,293.3h-0.1l0.1,0.1V293.3z"/>
<path className="st17" d="M351.5,293.3l0.1,0.1l-0.1-0.2V293.3z M351.6,293.3h-0.1l0.1,0.1V293.3z"/>
<path className="st16" d="M351.6,293.3L351.6,293.3v0.1V293.3z M351.6,293.4v-0.1V293.4z"/>
<path className="st17" d="M351.6,293.3L351.6,293.3v0.1V293.3z M351.6,293.4v-0.1V293.4z"/>
<path className="st16" d="M351.4,293.2L351.4,293.2l0.1,0.1L351.4,293.2z M351.5,293.3l-0.1-0.1L351.5,293.3z"/>
<path className="st17" d="M351.4,293.2L351.4,293.2l0.1,0.1L351.4,293.2z M351.5,293.3l-0.1-0.1L351.5,293.3z"/>
<path className="st16" d="M351.5,293.3l0.1,0.1v-0.1H351.5z M351.5,293.3L351.5,293.3l0.1,0.1L351.5,293.3z"/>
<path className="st17" d="M351.5,293.3l0.1,0.1v-0.1H351.5z M351.5,293.3L351.5,293.3l0.1,0.1L351.5,293.3z"/>
<path className="st16" d="M350.2,291.8l1.2,1.4L350,292L350.2,291.8z M351.5,293.1l-1.3-1.3l1.2,1.4L351.5,293.1z"/>
<path className="st17" d="M350.2,291.8l1.2,1.4L350,292L350.2,291.8z M351.5,293.1l-1.3-1.3l1.2,1.4L351.5,293.1z"/>
<path className="st16" d="M351.5,293.1l0.1,0.4l-0.2-0.2L351.5,293.1z M351.7,293.3l-0.2-0.2l0.1,0.4L351.7,293.3z"/>
<path className="st17" d="M351.5,293.1l0.1,0.4l-0.2-0.2L351.5,293.1z M351.7,293.3l-0.2-0.2l0.1,0.4L351.7,293.3z"/>
<path className="st16" d="M351.7,293.3l1.1,1.4l-1.2-1.2L351.7,293.3z M353,294.6l-1.3-1.3l1.1,1.4L353,294.6z"/>
<path className="st17" d="M351.7,293.3l1.1,1.4l-1.2-1.2L351.7,293.3z M353,294.6l-1.3-1.3l1.1,1.4L353,294.6z"/>
<path className="st16" d="M351.7,292.8v0.5l-0.2-0.2L351.7,292.8z M352,293.1l-0.2-0.2v0.5L352,293.1z"/>
<path className="st17" d="M351.7,292.8v0.5l-0.2-0.2L351.7,292.8z M352,293.1l-0.2-0.2v0.5L352,293.1z"/>
<polygon className="st16" points="352,292.8 352.1,293 351.8,292.7 "/>
<polygon className="st17" points="352,292.8 352.1,293 351.8,292.7 "/>
<path className="st16" d="M352,292.6l0.1,0.4l-0.2-0.2L352,292.6z M352.2,292.8l-0.2-0.2l0.1,0.4L352.2,292.8z"/>
<path className="st17" d="M352,292.6l0.1,0.4l-0.2-0.2L352,292.6z M352.2,292.8l-0.2-0.2l0.1,0.4L352.2,292.8z"/>
<path className="st16" d="M352.3,292.7l-0.2-0.1h0.1L352.3,292.7z M352.2,292.8l0.1-0.1l-0.2-0.1L352.2,292.8z"/>
<path className="st17" d="M352.3,292.7l-0.2-0.1h0.1L352.3,292.7z M352.2,292.8l0.1-0.1l-0.2-0.1L352.2,292.8z"/>
<polygon className="st16" points="352.2,292.8 352,292.6 352.1,292.6 "/>
<polygon className="st17" points="352.2,292.8 352,292.6 352.1,292.6 "/>
<path className="st16" d="M351,291l-0.6,1l-0.2-0.2L351,291z M351.2,291.3L351,291l-0.6,1L351.2,291.3z"/>
<path className="st17" d="M351,291l-0.6,1l-0.2-0.2L351,291z M351.2,291.3L351,291l-0.6,1L351.2,291.3z"/>
<path className="st16" d="M353.5,293.7l-0.5,1l-0.2-0.2L353.5,293.7z M353.8,293.9l-0.2-0.2l-0.5,1L353.8,293.9z"/>
<path className="st17" d="M353.5,293.7l-0.5,1l-0.2-0.2L353.5,293.7z M353.8,293.9l-0.2-0.2l-0.5,1L353.8,293.9z"/>
<path className="st16" d="M351.4,290.8l-0.1,0.5L351,291L351.4,290.8z M351.7,291.2l-0.4-0.4l-0.1,0.5L351.7,291.2z"/>
<path className="st17" d="M351.4,290.8l-0.1,0.5L351,291L351.4,290.8z M351.7,291.2l-0.4-0.4l-0.1,0.5L351.7,291.2z"/>
<path className="st16" d="M353.8,293.2v0.7l-0.2-0.2L353.8,293.2z M354,293.4l-0.2-0.2v0.7L354,293.4z"/>
<path className="st17" d="M353.8,293.2v0.7l-0.2-0.2L353.8,293.2z M354,293.4l-0.2-0.2v0.7L354,293.4z"/>
<path className="st16" d="M351.4,290.8L351.4,290.8l0.4,0.4L351.4,290.8z M351.7,291.2l-0.4-0.4L351.7,291.2z"/>
<path className="st17" d="M351.4,290.8L351.4,290.8l0.4,0.4L351.4,290.8z M351.7,291.2l-0.4-0.4L351.7,291.2z"/>
<path className="st16" d="M353.6,293.1l0.4,0.4l-0.2-0.2L353.6,293.1z M353.6,293.1L353.6,293.1l0.4,0.4L353.6,293.1z"/>
<path className="st17" d="M353.6,293.1l0.4,0.4l-0.2-0.2L353.6,293.1z M353.6,293.1L353.6,293.1l0.4,0.4L353.6,293.1z"/>
<path className="st16" d="M351.6,290.8l0.1,0.4l-0.4-0.4H351.6z M352.1,291.3l-0.5-0.5l0.1,0.4L352.1,291.3z"/>
<path className="st17" d="M351.6,290.8l0.1,0.4l-0.4-0.4H351.6z M352.1,291.3l-0.5-0.5l0.1,0.4L352.1,291.3z"/>
<path className="st16" d="M353.5,292.7l0.5,0.7l-0.4-0.4L353.5,292.7z M354,293.2l-0.5-0.5l0.5,0.7V293.2z"/>
<path className="st17" d="M353.5,292.7l0.5,0.7l-0.4-0.4L353.5,292.7z M354,293.2l-0.5-0.5l0.5,0.7V293.2z"/>
<path className="st16" d="M352,290.8l2,2.4l-2.4-2.4H352z M354,293l-2-2.2l2,2.4V293z"/>
<path className="st17" d="M352,290.8l2,2.4l-2.4-2.4H352z M354,293l-2-2.2l2,2.4V293z"/>
<path className="st16" d="M352.3,291l1.7,1.9l-2-2.2L352.3,291z M353.8,292.5l-1.4-1.4l1.7,1.9L353.8,292.5z"/>
<path className="st17" d="M352.3,291l1.7,1.9l-2-2.2L352.3,291z M353.8,292.5l-1.4-1.4l1.7,1.9L353.8,292.5z"/>
<line className="st18" x1="350.4" y1="292" x2="351.5" y2="293.1"/>
<line className="st18" x1="352.1" y1="291.3" x2="353.5" y2="292.7"/>
<line className="st18" x1="351.2" y1="291.3" x2="350.4" y2="292"/>
<line className="st18" x1="350.8" y1="292.4" x2="351.5" y2="293.1"/>
<line className="st18" x1="352.3" y1="291" x2="353.8" y2="292.5"/>
<line className="st18" x1="351" y1="291" x2="349.6" y2="292.5"/>
<line className="st18" x1="351.7" y1="292.8" x2="352" y2="293.1"/>
<path className="st18" d="M351.2,293.5c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C351.1,293.3,351.1,293.4,351.2,293.5"/>
<line className="st18" x1="351.7" y1="292.8" x2="351.2" y2="293.3"/>
<line className="st18" x1="352" y1="293.1" x2="351.6" y2="293.6"/>
<path className="st18" d="M351.9,292.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C351.8,292.6,351.8,292.7,351.9,292.8"/>
<polyline className="st18" points="351.7,293.3 352.8,294.4 353.5,293.7 "/>
<polyline className="st18" points="353.8,293.9 352.3,295.2 349.6,292.5 "/>
<path className="st18" d="M352.1,291.2c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M353.4,293.6c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M352.4,291c-0.4-0.4-1-0.4-1.4,0"/>
<path className="st18" d="M353.7,293.9c0.4-0.4,0.4-1,0-1.4"/>
<polygon className="st16" points="371,313.4 371,313.2 371.2,313.5 "/>
<polygon className="st17" points="371,313.4 371,313.2 371.2,313.5 "/>
<path className="st16" d="M372.5,314.8l-3.1-2.5l2.9,2.8L372.5,314.8z M369.7,312l2.8,2.8l-3.1-2.5L369.7,312z"/>
<path className="st17" d="M372.5,314.8l-3.1-2.5l2.9,2.8L372.5,314.8z M369.7,312l2.8,2.8l-3.1-2.5L369.7,312z"/>
<path className="st16" d="M372.5,314.8l-1.4-1.4L372.5,314.8z M371.2,313.5l1.3,1.3l-1.4-1.4L371.2,313.5z"/>
<path className="st17" d="M372.5,314.8l-1.4-1.4L372.5,314.8z M371.2,313.5l1.3,1.3l-1.4-1.4L371.2,313.5z"/>
<path className="st16" d="M371,313.2l-1.3-1.2l1.3,1.3V313.2z M369.7,311.9l1.3,1.3l-1.3-1.2V311.9z"/>
<path className="st17" d="M371,313.2l-1.3-1.2l1.3,1.3V313.2z M369.7,311.9l1.3,1.3l-1.3-1.2V311.9z"/>
<path className="st16" d="M372.6,314.7l-1.4-1.2l1.3,1.3L372.6,314.7z M371.4,313.4l1.2,1.3l-1.4-1.2L371.4,313.4z"/>
<path className="st17" d="M372.6,314.7l-1.4-1.2l1.3,1.3L372.6,314.7z M371.4,313.4l1.2,1.3l-1.4-1.2L371.4,313.4z"/>
<path className="st16" d="M371.4,313.4l-0.4-0.1l0.1,0.2L371.4,313.4z M371,313.1l0.4,0.2l-0.4-0.1V313.1z"/>
<path className="st17" d="M371.4,313.4l-0.4-0.1l0.1,0.2L371.4,313.4z M371,313.1l0.4,0.2l-0.4-0.1V313.1z"/>
<path className="st16" d="M371,313.1l-1.3-1.2l1.3,1.3V313.1z M369.8,311.9l1.2,1.2l-1.3-1.2H369.8z"/>
<path className="st17" d="M371,313.1l-1.3-1.2l1.3,1.3V313.1z M369.8,311.9l1.2,1.2l-1.3-1.2H369.8z"/>
<path className="st16" d="M372.6,314.7L372.6,314.7l-1.2-1.3L372.6,314.7z M371.4,313.4l1.2,1.3L371.4,313.4z"/>
<path className="st17" d="M372.6,314.7L372.6,314.7l-1.2-1.3L372.6,314.7z M371.4,313.4l1.2,1.3L371.4,313.4z"/>
<path className="st16" d="M371,313.1l-1.2-1.2L371,313.1z M369.8,311.8l1.2,1.3l-1.2-1.2V311.8z"/>
<path className="st17" d="M371,313.1l-1.2-1.2L371,313.1z M369.8,311.8l1.2,1.3l-1.2-1.2V311.8z"/>
<path className="st16" d="M371.2,313.1L371.2,313.1z"/>
<path className="st17" d="M371.2,313.1L371.2,313.1z"/>
<path className="st16" d="M371.4,313.4L371.4,313.4z"/>
<path className="st17" d="M371.4,313.4L371.4,313.4z"/>
<path className="st16" d="M372.6,314.7L372.6,314.7l-1.2-1.3L372.6,314.7z M371.4,313.4l1.2,1.3L371.4,313.4z"/>
<path className="st17" d="M372.6,314.7L372.6,314.7l-1.2-1.3L372.6,314.7z M371.4,313.4l1.2,1.3L371.4,313.4z"/>
<path className="st16" d="M371.2,313.1l-1.3-1.3l1.2,1.3H371.2z M371.2,313.1L371.2,313.1l-1.3-1.3L371.2,313.1z"/>
<path className="st17" d="M371.2,313.1l-1.3-1.3l1.2,1.3H371.2z M371.2,313.1L371.2,313.1l-1.3-1.3L371.2,313.1z"/>
<path className="st16" d="M372.7,314.6l-1.3-1.2l1.2,1.3L372.7,314.6z M372.7,314.6L372.7,314.6l-1.3-1.2L372.7,314.6z"/>
<path className="st17" d="M372.7,314.6l-1.3-1.2l1.2,1.3L372.7,314.6z M372.7,314.6L372.7,314.6l-1.3-1.2L372.7,314.6z"/>
<path className="st16" d="M371.4,313.4l-0.4-0.2L371.4,313.4z M371.2,313.1l0.2,0.2l-0.4-0.2H371.2z"/>
<path className="st17" d="M371.4,313.4l-0.4-0.2L371.4,313.4z M371.2,313.1l0.2,0.2l-0.4-0.2H371.2z"/>
<path className="st16" d="M371.2,313.1L371.2,313.1l-1.3-1.3L371.2,313.1z M369.8,311.8l1.3,1.3L369.8,311.8z"/>
<path className="st17" d="M371.2,313.1L371.2,313.1l-1.3-1.3L371.2,313.1z M369.8,311.8l1.3,1.3L369.8,311.8z"/>
<path className="st16" d="M371.4,313.2L371.4,313.2v0.1V313.2z M371.4,313.4v-0.1V313.4z"/>
<path className="st17" d="M371.4,313.2L371.4,313.2v0.1V313.2z M371.4,313.4v-0.1V313.4z"/>
<path className="st16" d="M371.4,313.2l-0.2-0.1l0.2,0.2V313.2z M371.3,313.1l0.1,0.1l-0.2-0.1H371.3z"/>
<path className="st17" d="M371.4,313.2l-0.2-0.1l0.2,0.2V313.2z M371.3,313.1l0.1,0.1l-0.2-0.1H371.3z"/>
<path className="st16" d="M371.2,313.1V313V313.1z"/>
<path className="st17" d="M371.2,313.1V313V313.1z"/>
<path className="st16" d="M371.2,313.1L371.2,313.1z"/>
<path className="st17" d="M371.2,313.1L371.2,313.1z"/>
<path className="st16" d="M371.4,313.2L371.4,313.2l-0.1-0.1L371.4,313.2z M371.4,313.2L371.4,313.2z M371.4,313.2L371.4,313.2z
	 M371.3,313.1l0.1,0.1L371.3,313.1z"/>
<path className="st17" d="M371.4,313.2L371.4,313.2l-0.1-0.1L371.4,313.2z M371.4,313.2L371.4,313.2z M371.4,313.2L371.4,313.2z
	 M371.3,313.1l0.1,0.1L371.3,313.1z"/>
<path className="st16" d="M371.3,313.1l-0.1-0.1v0.1H371.3z M371.3,313.1L371.3,313.1l-0.1-0.1L371.3,313.1z"/>
<path className="st17" d="M371.3,313.1l-0.1-0.1v0.1H371.3z M371.3,313.1L371.3,313.1l-0.1-0.1L371.3,313.1z"/>
<path className="st16" d="M371.5,313.2L371.5,313.2h-0.1H371.5z M371.4,313.2h0.1H371.4z"/>
<path className="st17" d="M371.5,313.2L371.5,313.2h-0.1H371.5z M371.4,313.2h0.1H371.4z"/>
<path className="st16" d="M371.4,313.1h-0.1l0.1,0.1V313.1z M371.4,313.1L371.4,313.1h-0.1H371.4z"/>
<path className="st17" d="M371.4,313.1h-0.1l0.1,0.1V313.1z M371.4,313.1L371.4,313.1h-0.1H371.4z"/>
<path className="st16" d="M371.3,313.1L371.3,313.1l-0.1-0.1L371.3,313.1z M371.2,313l0.1,0.1L371.2,313z"/>
<path className="st17" d="M371.3,313.1L371.3,313.1l-0.1-0.1L371.3,313.1z M371.2,313l0.1,0.1L371.2,313z"/>
<path className="st16" d="M371.5,313.2L371.5,313.2l-0.1-0.1L371.5,313.2z M371.4,313.1l0.1,0.1L371.4,313.1z"/>
<path className="st17" d="M371.5,313.2L371.5,313.2l-0.1-0.1L371.5,313.2z M371.4,313.1l0.1,0.1L371.4,313.1z"/>
<path className="st16" d="M371.4,313.1l-0.2-0.1l0.1,0.1H371.4z M371.4,313.1L371.4,313.1l-0.2-0.1L371.4,313.1z"/>
<path className="st17" d="M371.4,313.1l-0.2-0.1l0.1,0.1H371.4z M371.4,313.1L371.4,313.1l-0.2-0.1L371.4,313.1z"/>
<path className="st16" d="M372.8,314.4l-1.4-1.1l1.3,1.2L372.8,314.4z M371.5,313.1l1.3,1.3l-1.4-1.1L371.5,313.1z"/>
<path className="st17" d="M372.8,314.4l-1.4-1.1l1.3,1.2L372.8,314.4z M371.5,313.1l1.3,1.3l-1.4-1.1L371.5,313.1z"/>
<path className="st16" d="M371.5,313.1l-0.4-0.1l0.4,0.2V313.1z M371.3,312.9l0.2,0.2l-0.4-0.1L371.3,312.9z"/>
<path className="st17" d="M371.5,313.1l-0.4-0.1l0.4,0.2V313.1z M371.3,312.9l0.2,0.2l-0.4-0.1L371.3,312.9z"/>
<path className="st16" d="M371.3,312.9l-1.4-1.1l1.3,1.3L371.3,312.9z M370.1,311.7l1.2,1.2l-1.4-1.1L370.1,311.7z"/>
<path className="st17" d="M371.3,312.9l-1.4-1.1l1.3,1.3L371.3,312.9z M370.1,311.7l1.2,1.2l-1.4-1.1L370.1,311.7z"/>
<path className="st16" d="M371.8,312.9h-0.5l0.2,0.2L371.8,312.9z M371.5,312.6l0.2,0.2h-0.5L371.5,312.6z"/>
<path className="st17" d="M371.8,312.9h-0.5l0.2,0.2L371.8,312.9z M371.5,312.6l0.2,0.2h-0.5L371.5,312.6z"/>
<polygon className="st16" points="371.8,312.8 371.8,312.5 371.9,312.8 "/>
<polygon className="st17" points="371.8,312.8 371.8,312.5 371.9,312.8 "/>
<path className="st16" d="M372,312.8l-0.2-0.2l0.1,0.2H372z M371.8,312.4l0.2,0.4l-0.2-0.2V312.4z"/>
<path className="st17" d="M372,312.8l-0.2-0.2l0.1,0.2H372z M371.8,312.4l0.2,0.4l-0.2-0.2V312.4z"/>
<path className="st16" d="M371.9,312.4l0.2,0.1l-0.1-0.1H371.9z M371.8,312.4h0.1l0.2,0.1L371.8,312.4z"/>
<path className="st17" d="M371.9,312.4l0.2,0.1l-0.1-0.1H371.9z M371.8,312.4h0.1l0.2,0.1L371.8,312.4z"/>
<polygon className="st16" points="371.8,312.4 372,312.8 372.1,312.5 "/>
<polygon className="st17" points="371.8,312.4 372,312.8 372.1,312.5 "/>
<path className="st16" d="M373.6,313.7l-1,0.5l0.2,0.2L373.6,313.7z M373.3,313.5l0.2,0.2l-1,0.5L373.3,313.5z"/>
<path className="st17" d="M373.6,313.7l-1,0.5l0.2,0.2L373.6,313.7z M373.3,313.5l0.2,0.2l-1,0.5L373.3,313.5z"/>
<path className="st16" d="M371,311.1l-1,0.6l0.2,0.2L371,311.1z M370.8,310.8l0.2,0.2l-1,0.6L370.8,310.8z"/>
<path className="st17" d="M371,311.1l-1,0.6l0.2,0.2L371,311.1z M370.8,310.8l0.2,0.2l-1,0.6L370.8,310.8z"/>
<path className="st16" d="M373.8,313.2l-0.5,0.2l0.2,0.2L373.8,313.2z M373.6,313l0.2,0.2l-0.5,0.2L373.6,313z"/>
<path className="st17" d="M373.8,313.2l-0.5,0.2l0.2,0.2L373.8,313.2z M373.6,313l0.2,0.2l-0.5,0.2L373.6,313z"/>
<path className="st16" d="M371.5,311l-0.7-0.1l0.2,0.2L371.5,311z M371.2,310.6l0.4,0.4l-0.7-0.1L371.2,310.6z"/>
<path className="st17" d="M371.5,311l-0.7-0.1l0.2,0.2L371.5,311z M371.2,310.6l0.4,0.4l-0.7-0.1L371.2,310.6z"/>
<path className="st16" d="M373.8,313.2l-0.2-0.2L373.8,313.2z M373.6,312.9l0.2,0.4l-0.2-0.2V312.9z"/>
<path className="st17" d="M373.8,313.2l-0.2-0.2L373.8,313.2z M373.6,312.9l0.2,0.4l-0.2-0.2V312.9z"/>
<path className="st16" d="M371.5,311l-0.4-0.4L371.5,311z M371.3,310.6l0.2,0.4l-0.4-0.4H371.3z"/>
<path className="st17" d="M371.5,311l-0.4-0.4L371.5,311z M371.3,310.6l0.2,0.4l-0.4-0.4H371.3z"/>
<path className="st16" d="M373.8,313l-0.2-0.1l0.2,0.4V313z M373.3,312.5l0.5,0.5l-0.2-0.1L373.3,312.5z"/>
<path className="st17" d="M373.8,313l-0.2-0.1l0.2,0.4V313z M373.3,312.5l0.5,0.5l-0.2-0.1L373.3,312.5z"/>
<path className="st16" d="M372,311.1l-0.7-0.5l0.2,0.4L372,311.1z M371.5,310.6l0.5,0.5l-0.7-0.5H371.5z"/>
<path className="st17" d="M372,311.1l-0.7-0.5l0.2,0.4L372,311.1z M371.5,310.6l0.5,0.5l-0.7-0.5H371.5z"/>
<path className="st16" d="M373.8,312.8l-2.3-2.2l2.3,2.4V312.8z M371.8,310.6l2,2.2l-2.3-2.2H371.8z"/>
<path className="st17" d="M373.8,312.8l-2.3-2.2l2.3,2.4V312.8z M371.8,310.6l2,2.2l-2.3-2.2H371.8z"/>
<path className="st16" d="M373.6,312.3l-1.8-1.7l2,2.2L373.6,312.3z M372.2,310.8l1.3,1.4l-1.8-1.7L372.2,310.8z"/>
<path className="st17" d="M373.6,312.3l-1.8-1.7l2,2.2L373.6,312.3z M372.2,310.8l1.3,1.4l-1.8-1.7L372.2,310.8z"/>
<line className="st18" x1="372.6" y1="314.2" x2="371.5" y2="313.1"/>
<line className="st18" x1="373.3" y1="312.5" x2="372" y2="311.1"/>
<line className="st18" x1="373.3" y1="313.5" x2="372.6" y2="314.2"/>
<line className="st18" x1="372.2" y1="313.8" x2="371.5" y2="313.1"/>
<line className="st18" x1="373.6" y1="312.3" x2="372.2" y2="310.8"/>
<line className="st18" x1="373.6" y1="313.7" x2="372.2" y2="315"/>
<line className="st18" x1="371.8" y1="312.9" x2="371.5" y2="312.6"/>
<path className="st18" d="M371.2,313c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C371.3,313.2,371.3,313.1,371.2,313"/>
<line className="st18" x1="371.8" y1="312.9" x2="371.4" y2="313.4"/>
<line className="st18" x1="371.5" y1="312.6" x2="371.2" y2="313.1"/>
<path className="st18" d="M371.9,312.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C372,312.5,372,312.4,371.9,312.3"/>
<polyline className="st18" points="371.3,312.9 370.3,311.9 371,311.1 "/>
<polyline className="st18" points="370.8,310.8 369.4,312.3 372.2,315 "/>
<path className="st18" d="M373.2,313.4c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M371.9,311c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M373.5,313.7c0.4-0.4,0.4-1,0-1.4"/>
<path className="st18" d="M372.2,310.8c-0.4-0.4-1-0.4-1.4,0"/>
<polygon className="st16" points="386.3,328.6 386.4,328.6 386.2,328.5 "/>
<polygon className="st17" points="386.3,328.6 386.4,328.6 386.2,328.5 "/>
<path className="st16" d="M384.8,327.2l2.5,3.1l-2.8-2.9L384.8,327.2z M387.6,329.9l-2.8-2.8l2.5,3.1L387.6,329.9z"/>
<path className="st17" d="M384.8,327.2l2.5,3.1l-2.8-2.9L384.8,327.2z M387.6,329.9l-2.8-2.8l2.5,3.1L387.6,329.9z"/>
<path className="st16" d="M384.8,327.2l1.4,1.4L384.8,327.2z M386.2,328.5l-1.3-1.3l1.4,1.4L386.2,328.5z"/>
<path className="st17" d="M384.8,327.2l1.4,1.4L384.8,327.2z M386.2,328.5l-1.3-1.3l1.4,1.4L386.2,328.5z"/>
<path className="st16" d="M386.4,328.6l1.2,1.3l-1.3-1.3H386.4z M387.7,329.9l-1.3-1.3l1.2,1.3H387.7z"/>
<path className="st17" d="M386.4,328.6l1.2,1.3l-1.3-1.3H386.4z M387.7,329.9l-1.3-1.3l1.2,1.3H387.7z"/>
<path className="st16" d="M385,327l1.2,1.4l-1.3-1.3L385,327z M386.3,328.2L385,327l1.2,1.4L386.3,328.2z"/>
<path className="st17" d="M385,327l1.2,1.4l-1.3-1.3L385,327z M386.3,328.2L385,327l1.2,1.4L386.3,328.2z"/>
<path className="st16" d="M386.3,328.2l0.1,0.4l-0.2-0.1L386.3,328.2z M386.5,328.6l-0.2-0.4l0.1,0.4H386.5z"/>
<path className="st17" d="M386.3,328.2l0.1,0.4l-0.2-0.1L386.3,328.2z M386.5,328.6l-0.2-0.4l0.1,0.4H386.5z"/>
<path className="st16" d="M386.5,328.6l1.2,1.3l-1.3-1.3H386.5z M387.7,329.8l-1.2-1.2l1.2,1.3V329.8z"/>
<path className="st17" d="M386.5,328.6l1.2,1.3l-1.3-1.3H386.5z M387.7,329.8l-1.2-1.2l1.2,1.3V329.8z"/>
<path className="st16" d="M385,327L385,327l1.3,1.2L385,327z M386.3,328.2L385,327L386.3,328.2z"/>
<path className="st17" d="M385,327L385,327l1.3,1.2L385,327z M386.3,328.2L385,327L386.3,328.2z"/>
<path className="st16" d="M386.5,328.6l1.2,1.2L386.5,328.6z M387.8,329.8l-1.3-1.2l1.2,1.2H387.8z"/>
<path className="st17" d="M386.5,328.6l1.2,1.2L386.5,328.6z M387.8,329.8l-1.3-1.2l1.2,1.2H387.8z"/>
<path className="st16" d="M386.5,328.5L386.5,328.5z"/>
<path className="st17" d="M386.5,328.5L386.5,328.5z"/>
<path className="st16" d="M386.3,328.2L386.3,328.2z"/>
<path className="st17" d="M386.3,328.2L386.3,328.2z"/>
<path className="st16" d="M385,327L385,327l1.3,1.2L385,327z M386.3,328.2L385,327L386.3,328.2z"/>
<path className="st17" d="M385,327L385,327l1.3,1.2L385,327z M386.3,328.2L385,327L386.3,328.2z"/>
<path className="st16" d="M386.5,328.5l1.3,1.3l-1.3-1.2V328.5z M386.5,328.5L386.5,328.5l1.3,1.3L386.5,328.5z"/>
<path className="st17" d="M386.5,328.5l1.3,1.3l-1.3-1.2V328.5z M386.5,328.5L386.5,328.5l1.3,1.3L386.5,328.5z"/>
<path className="st16" d="M385.1,326.9l1.2,1.3L385,327L385.1,326.9z M385.1,326.9L385.1,326.9l1.2,1.3L385.1,326.9z"/>
<path className="st17" d="M385.1,326.9l1.2,1.3L385,327L385.1,326.9z M385.1,326.9L385.1,326.9l1.2,1.3L385.1,326.9z"/>
<path className="st16" d="M386.3,328.2l0.2,0.4L386.3,328.2z M386.5,328.5l-0.2-0.2l0.2,0.4V328.5z"/>
<path className="st17" d="M386.3,328.2l0.2,0.4L386.3,328.2z M386.5,328.5l-0.2-0.2l0.2,0.4V328.5z"/>
<path className="st16" d="M386.5,328.5L386.5,328.5l1.3,1.3L386.5,328.5z M387.8,329.8l-1.3-1.3L387.8,329.8z"/>
<path className="st17" d="M386.5,328.5L386.5,328.5l1.3,1.3L386.5,328.5z M387.8,329.8l-1.3-1.3L387.8,329.8z"/>
<path className="st16" d="M386.3,328.2h0.1H386.3z"/>
<path className="st17" d="M386.3,328.2h0.1H386.3z"/>
<path className="st16" d="M386.3,328.2L386.3,328.2z"/>
<path className="st17" d="M386.3,328.2L386.3,328.2z"/>
<path className="st16" d="M386.4,328.2l0.1,0.2l-0.2-0.2H386.4z M386.5,328.4l-0.1-0.1l0.1,0.2V328.4z"/>
<path className="st17" d="M386.4,328.2l0.1,0.2l-0.2-0.2H386.4z M386.5,328.4l-0.1-0.1l0.1,0.2V328.4z"/>
<path className="st16" d="M386.5,328.5h0.1H386.5z"/>
<path className="st17" d="M386.5,328.5h0.1H386.5z"/>
<path className="st16" d="M386.5,328.5L386.5,328.5z"/>
<path className="st17" d="M386.5,328.5L386.5,328.5z"/>
<path className="st16" d="M386.4,328.2L386.4,328.2z"/>
<path className="st17" d="M386.4,328.2L386.4,328.2z"/>
<path className="st16" d="M386.4,328.2h-0.1H386.4z"/>
<path className="st17" d="M386.4,328.2h-0.1H386.4z"/>
<path className="st16" d="M386.4,328.2L386.4,328.2l0.1,0.1L386.4,328.2z M386.5,328.4l-0.1-0.1L386.5,328.4z"/>
<path className="st17" d="M386.4,328.2L386.4,328.2l0.1,0.1L386.4,328.2z M386.5,328.4l-0.1-0.1L386.5,328.4z"/>
<path className="st16" d="M386.5,328.4l0.1,0.1h-0.1V328.4z M386.5,328.4L386.5,328.4l0.1,0.1L386.5,328.4z"/>
<path className="st17" d="M386.5,328.4l0.1,0.1h-0.1V328.4z M386.5,328.4L386.5,328.4l0.1,0.1L386.5,328.4z"/>
<path className="st16" d="M386.4,328.1L386.4,328.1v0.1V328.1z M386.4,328.2v-0.1V328.2z"/>
<path className="st17" d="M386.4,328.1L386.4,328.1v0.1V328.1z M386.4,328.2v-0.1V328.2z"/>
<path className="st16" d="M386.5,328.2v0.1l-0.1-0.1H386.5z M386.5,328.2L386.5,328.2v0.1V328.2z"/>
<path className="st17" d="M386.5,328.2v0.1l-0.1-0.1H386.5z M386.5,328.2L386.5,328.2v0.1V328.2z"/>
<path className="st16" d="M386.5,328.4L386.5,328.4l0.1,0.1L386.5,328.4z M386.6,328.5l-0.1-0.1L386.6,328.5z"/>
<path className="st17" d="M386.5,328.4L386.5,328.4l0.1,0.1L386.5,328.4z M386.6,328.5l-0.1-0.1L386.6,328.5z"/>
<path className="st16" d="M386.4,328.1L386.4,328.1l0.1,0.1L386.4,328.1z M386.5,328.2l-0.1-0.1L386.5,328.2z"/>
<path className="st17" d="M386.4,328.1L386.4,328.1l0.1,0.1L386.4,328.1z M386.5,328.2l-0.1-0.1L386.5,328.2z"/>
<path className="st16" d="M386.5,328.2l0.1,0.2l-0.1-0.1V328.2z M386.6,328.4l-0.1-0.1l0.1,0.2V328.4z"/>
<path className="st17" d="M386.5,328.2l0.1,0.2l-0.1-0.1V328.2z M386.6,328.4l-0.1-0.1l0.1,0.2V328.4z"/>
<path className="st16" d="M385.2,326.8l1.1,1.4l-1.2-1.3L385.2,326.8z M386.5,328.1l-1.3-1.3l1.1,1.4L386.5,328.1z"/>
<path className="st17" d="M385.2,326.8l1.1,1.4l-1.2-1.3L385.2,326.8z M386.5,328.1l-1.3-1.3l1.1,1.4L386.5,328.1z"/>
<path className="st16" d="M386.5,328.1l0.1,0.2l-0.2-0.2H386.5z M386.8,328.4l-0.2-0.2l0.1,0.2H386.8z"/>
<path className="st17" d="M386.5,328.1l0.1,0.2l-0.2-0.2H386.5z M386.8,328.4l-0.2-0.2l0.1,0.2H386.8z"/>
<path className="st16" d="M386.8,328.4l1.1,1.4l-1.3-1.3L386.8,328.4z M388,329.6l-1.2-1.2l1.1,1.4L388,329.6z"/>
<path className="st17" d="M386.8,328.4l1.1,1.4l-1.3-1.3L386.8,328.4z M388,329.6l-1.2-1.2l1.1,1.4L388,329.6z"/>
<path className="st16" d="M386.8,327.9v0.5l-0.2-0.2L386.8,327.9z M387,328.1l-0.2-0.2v0.5L387,328.1z"/>
<path className="st17" d="M386.8,327.9v0.5l-0.2-0.2L386.8,327.9z M387,328.1l-0.2-0.2v0.5L387,328.1z"/>
<polygon className="st16" points="386.9,327.9 387.1,327.9 386.9,327.8 "/>
<polygon className="st17" points="386.9,327.9 387.1,327.9 386.9,327.8 "/>
<path className="st16" d="M386.9,327.6l0.2,0.2l-0.2-0.1V327.6z M387.2,327.9l-0.4-0.2l0.2,0.2H387.2z"/>
<path className="st17" d="M386.9,327.6l0.2,0.2l-0.2-0.1V327.6z M387.2,327.9l-0.4-0.2l0.2,0.2H387.2z"/>
<path className="st16" d="M387.2,327.8l-0.1-0.2l0.1,0.1V327.8z M387.2,327.9v-0.1l-0.1-0.2L387.2,327.9z"/>
<path className="st17" d="M387.2,327.8l-0.1-0.2l0.1,0.1V327.8z M387.2,327.9v-0.1l-0.1-0.2L387.2,327.9z"/>
<polygon className="st16" points="387.2,327.9 386.9,327.6 387.1,327.5 "/>
<polygon className="st17" points="387.2,327.9 386.9,327.6 387.1,327.5 "/>
<path className="st16" d="M385.9,326.1l-0.5,1l-0.2-0.2L385.9,326.1z M386.2,326.3l-0.2-0.2l-0.5,1L386.2,326.3z"/>
<path className="st17" d="M385.9,326.1l-0.5,1l-0.2-0.2L385.9,326.1z M386.2,326.3l-0.2-0.2l-0.5,1L386.2,326.3z"/>
<path className="st16" d="M388.6,328.6l-0.6,1l-0.2-0.2L388.6,328.6z M388.8,328.8l-0.2-0.2l-0.6,1L388.8,328.8z"/>
<path className="st17" d="M388.6,328.6l-0.6,1l-0.2-0.2L388.6,328.6z M388.8,328.8l-0.2-0.2l-0.6,1L388.8,328.8z"/>
<path className="st16" d="M386.4,325.8l-0.2,0.5l-0.2-0.2L386.4,325.8z M386.6,326.1l-0.2-0.2l-0.2,0.5L386.6,326.1z"/>
<path className="st17" d="M386.4,325.8l-0.2,0.5l-0.2-0.2L386.4,325.8z M386.6,326.1l-0.2-0.2l-0.2,0.5L386.6,326.1z"/>
<path className="st16" d="M388.7,328.1l0.1,0.7l-0.2-0.2L388.7,328.1z M389,328.5l-0.4-0.4l0.1,0.7L389,328.5z"/>
<path className="st17" d="M388.7,328.1l0.1,0.7l-0.2-0.2L388.7,328.1z M389,328.5l-0.4-0.4l0.1,0.7L389,328.5z"/>
<path className="st16" d="M386.4,325.8l0.2,0.2L386.4,325.8z M386.8,326.1l-0.4-0.2l0.2,0.2H386.8z"/>
<path className="st17" d="M386.4,325.8l0.2,0.2L386.4,325.8z M386.8,326.1l-0.4-0.2l0.2,0.2H386.8z"/>
<path className="st16" d="M388.7,328.1l0.4,0.4L388.7,328.1z M389,328.4l-0.4-0.2l0.4,0.4V328.4z"/>
<path className="st17" d="M388.7,328.1l0.4,0.4L388.7,328.1z M389,328.4l-0.4-0.2l0.4,0.4V328.4z"/>
<path className="st16" d="M386.6,325.8l0.1,0.2l-0.4-0.2H386.6z M387.1,326.3l-0.5-0.5l0.1,0.2L387.1,326.3z"/>
<path className="st17" d="M386.6,325.8l0.1,0.2l-0.4-0.2H386.6z M387.1,326.3l-0.5-0.5l0.1,0.2L387.1,326.3z"/>
<path className="st16" d="M388.6,327.6l0.5,0.7l-0.4-0.2L388.6,327.6z M389,328.1l-0.5-0.5l0.5,0.7V328.1z"/>
<path className="st17" d="M388.6,327.6l0.5,0.7l-0.4-0.2L388.6,327.6z M389,328.1l-0.5-0.5l0.5,0.7V328.1z"/>
<path className="st16" d="M386.9,325.8l2.2,2.3l-2.4-2.3H386.9z M389,327.9l-2.2-2l2.2,2.3V327.9z"/>
<path className="st17" d="M386.9,325.8l2.2,2.3l-2.4-2.3H386.9z M389,327.9l-2.2-2l2.2,2.3V327.9z"/>
<path className="st16" d="M387.4,326.1l1.7,1.8l-2.2-2L387.4,326.1z M388.8,327.4l-1.4-1.3l1.7,1.8L388.8,327.4z"/>
<path className="st17" d="M387.4,326.1l1.7,1.8l-2.2-2L387.4,326.1z M388.8,327.4l-1.4-1.3l1.7,1.8L388.8,327.4z"/>
<line className="st18" x1="385.4" y1="327" x2="386.5" y2="328.1"/>
<line className="st18" x1="387.1" y1="326.3" x2="388.6" y2="327.6"/>
<line className="st18" x1="386.2" y1="326.3" x2="385.4" y2="327"/>
<line className="st18" x1="385.8" y1="327.4" x2="386.5" y2="328.1"/>
<line className="st18" x1="387.4" y1="326.1" x2="388.8" y2="327.4"/>
<line className="st18" x1="385.9" y1="326.1" x2="384.6" y2="327.4"/>
<line className="st18" x1="386.8" y1="327.9" x2="387" y2="328.1"/>
<path className="st18" d="M386.2,328.5c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C386.1,328.4,386.1,328.5,386.2,328.5"/>
<line className="st18" x1="386.8" y1="327.9" x2="386.3" y2="328.2"/>
<line className="st18" x1="387" y1="328.1" x2="386.5" y2="328.5"/>
<path className="st18" d="M386.9,327.8c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C386.9,327.6,386.9,327.8,386.9,327.8"/>
<polyline className="st18" points="386.8,328.4 387.7,329.3 388.6,328.6 "/>
<polyline className="st18" points="388.8,328.8 387.4,330.3 384.6,327.4 "/>
<path className="st18" d="M387,326.3c-0.3-0.3-0.7-0.3-0.9,0"/>
<path className="st18" d="M388.5,328.5c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M387.3,326c-0.4-0.4-1-0.4-1.4,0"/>
<path className="st18" d="M388.7,328.8c0.4-0.4,0.4-1,0-1.4"/>
<polygon className="st16" points="367.1,404.2 367.2,404.2 367.1,404.3 "/>
<polygon className="st17" points="367.1,404.2 367.2,404.2 367.1,404.3 "/>
<path className="st16" d="M365.8,405.6l2.5-3.1l-2.9,2.9L365.8,405.6z M368.5,402.9l-2.8,2.8l2.5-3.1L368.5,402.9z"/>
<path className="st17" d="M365.8,405.6l2.5-3.1l-2.9,2.9L365.8,405.6z M368.5,402.9l-2.8,2.8l2.5-3.1L368.5,402.9z"/>
<path className="st16" d="M365.8,405.6l1.3-1.4L365.8,405.6z M367.1,404.3l-1.3,1.3l1.3-1.4V404.3z"/>
<path className="st17" d="M365.8,405.6l1.3-1.4L365.8,405.6z M367.1,404.3l-1.3,1.3l1.3-1.4V404.3z"/>
<path className="st16" d="M367.2,404.2l1.3-1.3l-1.4,1.3H367.2z M367.2,404.2L367.2,404.2l1.3-1.3L367.2,404.2z"/>
<path className="st17" d="M367.2,404.2l1.3-1.3l-1.4,1.3H367.2z M367.2,404.2L367.2,404.2l1.3-1.3L367.2,404.2z"/>
<path className="st16" d="M365.9,405.8l1.2-1.4l-1.3,1.3L365.9,405.8z M367.1,404.6l-1.2,1.2l1.2-1.4V404.6z"/>
<path className="st17" d="M365.9,405.8l1.2-1.4l-1.3,1.3L365.9,405.8z M367.1,404.6l-1.2,1.2l1.2-1.4V404.6z"/>
<path className="st16" d="M367.1,404.6l0.1-0.4l-0.1,0.1V404.6z M367.4,404.2l-0.4,0.4l0.1-0.4H367.4z"/>
<path className="st17" d="M367.1,404.6l0.1-0.4l-0.1,0.1V404.6z M367.4,404.2l-0.4,0.4l0.1-0.4H367.4z"/>
<path className="st16" d="M367.4,404.2l1.1-1.3l-1.3,1.3H367.4z M368.6,403l-1.2,1.2l1.1-1.3L368.6,403z"/>
<path className="st17" d="M367.4,404.2l1.1-1.3l-1.3,1.3H367.4z M368.6,403l-1.2,1.2l1.1-1.3L368.6,403z"/>
<path className="st16" d="M365.9,405.8L365.9,405.8l1.2-1.2L365.9,405.8z M367.1,404.6l-1.2,1.2L367.1,404.6z"/>
<path className="st17" d="M365.9,405.8L365.9,405.8l1.2-1.2L365.9,405.8z M367.1,404.6l-1.2,1.2L367.1,404.6z"/>
<path className="st16" d="M367.4,404.3l1.2-1.3l-1.2,1.2V404.3z M367.4,404.3L367.4,404.3l1.2-1.3L367.4,404.3z M367.4,404.3
	L367.4,404.3z"/>
<path className="st17" d="M367.4,404.3l1.2-1.3l-1.2,1.2V404.3z M367.4,404.3L367.4,404.3l1.2-1.3L367.4,404.3z M367.4,404.3
	L367.4,404.3z"/>
<path className="st16" d="M367.2,404.6L367.2,404.6z"/>
<path className="st17" d="M367.2,404.6L367.2,404.6z"/>
<path className="st16" d="M365.9,405.8l1.2-1.2L365.9,405.8z M367.2,404.6l-1.3,1.2l1.2-1.2H367.2z"/>
<path className="st17" d="M365.9,405.8l1.2-1.2L365.9,405.8z M367.2,404.6l-1.3,1.2l1.2-1.2H367.2z"/>
<path className="st16" d="M367.4,404.3L367.4,404.3l1.2-1.3L367.4,404.3z M368.6,403l-1.2,1.3L368.6,403z"/>
<path className="st17" d="M367.4,404.3L367.4,404.3l1.2-1.3L367.4,404.3z M368.6,403l-1.2,1.3L368.6,403z"/>
<path className="st16" d="M365.9,405.9l1.3-1.3l-1.3,1.2V405.9z M365.9,405.9L365.9,405.9l1.3-1.3L365.9,405.9z"/>
<path className="st17" d="M365.9,405.9l1.3-1.3l-1.3,1.2V405.9z M365.9,405.9L365.9,405.9l1.3-1.3L365.9,405.9z"/>
<path className="st16" d="M367.2,404.6l0.2-0.4l-0.4,0.4H367.2z M367.4,404.3l-0.2,0.2l0.2-0.4V404.3z"/>
<path className="st17" d="M367.2,404.6l0.2-0.4l-0.4,0.4H367.2z M367.4,404.3l-0.2,0.2l0.2-0.4V404.3z"/>
<path className="st16" d="M367.4,404.3l1.2-1.3L367.4,404.3z M368.8,403l-1.3,1.3l1.2-1.3H368.8z"/>
<path className="st17" d="M367.4,404.3l1.2-1.3L367.4,404.3z M368.8,403l-1.3,1.3l1.2-1.3H368.8z"/>
<path className="st16" d="M367.2,404.6L367.2,404.6z M367.2,404.6L367.2,404.6z"/>
<path className="st17" d="M367.2,404.6L367.2,404.6z M367.2,404.6L367.2,404.6z"/>
<path className="st16" d="M367.3,404.6l0.1-0.2l-0.2,0.2H367.3z M367.4,404.4l-0.1,0.1l0.1-0.2V404.4z"/>
<path className="st17" d="M367.3,404.6l0.1-0.2l-0.2,0.2H367.3z M367.4,404.4l-0.1,0.1l0.1-0.2V404.4z"/>
<path className="st16" d="M367.4,404.3L367.4,404.3z M367.4,404.3L367.4,404.3z"/>
<path className="st17" d="M367.4,404.3L367.4,404.3z M367.4,404.3L367.4,404.3z"/>
<path className="st16" d="M367.2,404.6h0.1H367.2z"/>
<path className="st17" d="M367.2,404.6h0.1H367.2z"/>
<path className="st16" d="M367.2,404.6L367.2,404.6z"/>
<path className="st17" d="M367.2,404.6L367.2,404.6z"/>
<path className="st16" d="M367.3,404.6L367.3,404.6l0.1-0.1L367.3,404.6z M367.4,404.4l-0.1,0.1L367.4,404.4z"/>
<path className="st17" d="M367.3,404.6L367.3,404.6l0.1-0.1L367.3,404.6z M367.4,404.4l-0.1,0.1L367.4,404.4z"/>
<path className="st16" d="M367.4,404.4L367.4,404.4v-0.1V404.4z M367.4,404.3v0.1V404.3z"/>
<path className="st17" d="M367.4,404.4L367.4,404.4v-0.1V404.4z M367.4,404.3v0.1V404.3z"/>
<path className="st16" d="M367.2,404.7l0.1-0.1h-0.1V404.7z M367.2,404.7L367.2,404.7l0.1-0.1L367.2,404.7z"/>
<path className="st17" d="M367.2,404.7l0.1-0.1h-0.1V404.7z M367.2,404.7L367.2,404.7l0.1-0.1L367.2,404.7z"/>
<path className="st16" d="M367.3,404.6L367.3,404.6l0.1-0.1L367.3,404.6z M367.4,404.4l-0.1,0.1L367.4,404.4z"/>
<path className="st17" d="M367.3,404.6L367.3,404.6l0.1-0.1L367.3,404.6z M367.4,404.4l-0.1,0.1L367.4,404.4z"/>
<path className="st16" d="M367.4,404.4v-0.1V404.4z M367.6,404.3l-0.1,0.1v-0.1H367.6z"/>
<path className="st17" d="M367.4,404.4v-0.1V404.4z M367.6,404.3l-0.1,0.1v-0.1H367.6z"/>
<path className="st16" d="M367.2,404.7l0.1-0.1L367.2,404.7z M367.4,404.6l-0.2,0.1l0.1-0.1H367.4z"/>
<path className="st17" d="M367.2,404.7l0.1-0.1L367.2,404.7z M367.4,404.6l-0.2,0.1l0.1-0.1H367.4z"/>
<path className="st16" d="M367.4,404.6l0.1-0.2l-0.1,0.1V404.6z M367.6,404.4l-0.1,0.1l0.1-0.2V404.4z"/>
<path className="st17" d="M367.4,404.6l0.1-0.2l-0.1,0.1V404.6z M367.6,404.4l-0.1,0.1l0.1-0.2V404.4z"/>
<path className="st16" d="M366.1,406l1.1-1.4l-1.3,1.3L366.1,406z M367.3,404.8l-1.2,1.2l1.1-1.4L367.3,404.8z"/>
<path className="st17" d="M366.1,406l1.1-1.4l-1.3,1.3L366.1,406z M367.3,404.8l-1.2,1.2l1.1-1.4L367.3,404.8z"/>
<path className="st16" d="M367.3,404.8l0.2-0.4l-0.4,0.2L367.3,404.8z M367.3,404.8L367.3,404.8l0.2-0.4L367.3,404.8z"/>
<path className="st17" d="M367.3,404.8l0.2-0.4l-0.4,0.2L367.3,404.8z M367.3,404.8L367.3,404.8l0.2-0.4L367.3,404.8z"/>
<path className="st16" d="M367.6,404.4l1.2-1.4l-1.3,1.3L367.6,404.4z M368.9,403.2l-1.3,1.2l1.2-1.4L368.9,403.2z"/>
<path className="st17" d="M367.6,404.4l1.2-1.4l-1.3,1.3L367.6,404.4z M368.9,403.2l-1.3,1.2l1.2-1.4L368.9,403.2z"/>
<path className="st16" d="M367.6,404.9v-0.5l-0.2,0.4L367.6,404.9z M367.8,404.7l-0.2,0.2v-0.5L367.8,404.7z"/>
<path className="st17" d="M367.6,404.9v-0.5l-0.2,0.4L367.6,404.9z M367.8,404.7l-0.2,0.2v-0.5L367.8,404.7z"/>
<polygon className="st16" points="367.8,404.9 367.9,404.9 367.8,405 "/>
<polygon className="st17" points="367.8,404.9 367.9,404.9 367.8,405 "/>
<path className="st16" d="M367.8,405.2l0.1-0.2l-0.1,0.1V405.2z M368,404.9l-0.2,0.2l0.1-0.2H368z"/>
<path className="st17" d="M367.8,405.2l0.1-0.2l-0.1,0.1V405.2z M368,404.9l-0.2,0.2l0.1-0.2H368z"/>
<path className="st16" d="M368.2,405l-0.2,0.2l0.1-0.1L368.2,405z M368,404.9l0.1,0.1l-0.2,0.2L368,404.9z"/>
<path className="st17" d="M368.2,405l-0.2,0.2l0.1-0.1L368.2,405z M368,404.9l0.1,0.1l-0.2,0.2L368,404.9z"/>
<polygon className="st16" points="368,404.9 367.8,405.2 367.9,405.3 "/>
<polygon className="st17" points="368,404.9 367.8,405.2 367.9,405.3 "/>
<path className="st16" d="M366.8,406.7l-0.5-1l-0.2,0.2L366.8,406.7z M367.1,406.5l-0.2,0.2l-0.5-1L367.1,406.5z"/>
<path className="st17" d="M366.8,406.7l-0.5-1l-0.2,0.2L366.8,406.7z M367.1,406.5l-0.2,0.2l-0.5-1L367.1,406.5z"/>
<path className="st16" d="M369.4,404.2l-0.5-1l-0.2,0.2L369.4,404.2z M369.6,404l-0.2,0.2l-0.5-1L369.6,404z"/>
<path className="st17" d="M369.4,404.2l-0.5-1l-0.2,0.2L369.4,404.2z M369.6,404l-0.2,0.2l-0.5-1L369.6,404z"/>
<path className="st16" d="M367.2,407l-0.1-0.5l-0.2,0.2L367.2,407z M367.6,406.7l-0.4,0.2l-0.1-0.5L367.6,406.7z"/>
<path className="st17" d="M367.2,407l-0.1-0.5l-0.2,0.2L367.2,407z M367.6,406.7l-0.4,0.2l-0.1-0.5L367.6,406.7z"/>
<path className="st16" d="M369.6,404.7V404l-0.2,0.2L369.6,404.7z M369.8,404.3l-0.2,0.4V404L369.8,404.3z"/>
<path className="st17" d="M369.6,404.7V404l-0.2,0.2L369.6,404.7z M369.8,404.3l-0.2,0.4V404L369.8,404.3z"/>
<path className="st16" d="M367.3,407l0.2-0.2l-0.4,0.2H367.3z M367.3,407L367.3,407l0.2-0.2L367.3,407z"/>
<path className="st17" d="M367.3,407l0.2-0.2l-0.4,0.2H367.3z M367.3,407L367.3,407l0.2-0.2L367.3,407z"/>
<path className="st16" d="M369.6,404.7l0.2-0.4L369.6,404.7z M369.8,404.4l-0.2,0.2l0.2-0.4V404.4z"/>
<path className="st17" d="M369.6,404.7l0.2-0.4L369.6,404.7z M369.8,404.4l-0.2,0.2l0.2-0.4V404.4z"/>
<path className="st16" d="M367.6,407v-0.2l-0.2,0.2H367.6z M368,406.5l-0.5,0.5v-0.2L368,406.5z"/>
<path className="st17" d="M367.6,407v-0.2l-0.2,0.2H367.6z M368,406.5l-0.5,0.5v-0.2L368,406.5z"/>
<path className="st16" d="M369.4,405.2l0.5-0.7l-0.2,0.2L369.4,405.2z M369.8,404.7l-0.5,0.5l0.5-0.7V404.7z"/>
<path className="st17" d="M369.4,405.2l0.5-0.7l-0.2,0.2L369.4,405.2z M369.8,404.7l-0.5,0.5l0.5-0.7V404.7z"/>
<path className="st16" d="M367.8,407l2-2.3l-2.3,2.3H367.8z M369.8,404.9l-2,2l2-2.3V404.9z"/>
<path className="st17" d="M367.8,407l2-2.3l-2.3,2.3H367.8z M369.8,404.9l-2,2l2-2.3V404.9z"/>
<path className="st16" d="M368.3,406.7l1.6-1.8l-2,2L368.3,406.7z M369.6,405.4l-1.3,1.3l1.6-1.8L369.6,405.4z"/>
<path className="st17" d="M368.3,406.7l1.6-1.8l-2,2L368.3,406.7z M369.6,405.4l-1.3,1.3l1.6-1.8L369.6,405.4z"/>
<line className="st18" x1="366.4" y1="405.8" x2="367.3" y2="404.8"/>
<line className="st18" x1="368" y1="406.5" x2="369.4" y2="405.2"/>
<line className="st18" x1="367.1" y1="406.5" x2="366.4" y2="405.8"/>
<line className="st18" x1="366.6" y1="405.4" x2="367.3" y2="404.8"/>
<line className="st18" x1="368.3" y1="406.7" x2="369.6" y2="405.4"/>
<line className="st18" x1="366.8" y1="406.7" x2="365.4" y2="405.4"/>
<line className="st18" x1="367.6" y1="404.9" x2="367.8" y2="404.7"/>
<path className="st18" d="M367.3,404.4c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C367.1,404.5,367.2,404.5,367.3,404.4"/>
<line className="st18" x1="367.6" y1="404.9" x2="367.2" y2="404.6"/>
<line className="st18" x1="367.8" y1="404.7" x2="367.4" y2="404.3"/>
<path className="st18" d="M368,405.1c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C367.8,405.2,367.9,405.2,368,405.1"/>
<polyline className="st18" points="367.6,404.4 368.6,403.5 369.4,404.2 "/>
<polyline className="st18" points="369.6,404 368.3,402.5 365.4,405.4 "/>
<path className="st18" d="M367,406.4c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M369.3,405.1c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M366.8,406.7c0.4,0.4,1,0.4,1.4,0"/>
<path className="st18" d="M369.5,405.3c0.4-0.4,0.4-1,0-1.4"/>
<polygon className="st16" points="362.8,386.6 362.9,386.7 362.6,386.4 "/>
<polygon className="st17" points="362.8,386.6 362.9,386.7 362.6,386.4 "/>
<path className="st16" d="M361.4,385.1l3,2.5l-2.8-2.8L361.4,385.1z M364.2,387.9l-2.8-2.8l3,2.5L364.2,387.9z"/>
<path className="st17" d="M361.4,385.1l3,2.5l-2.8-2.8L361.4,385.1z M364.2,387.9l-2.8-2.8l3,2.5L364.2,387.9z"/>
<path className="st16" d="M361.3,385.1l1.4,1.4l-1.3-1.4H361.3z M362.6,386.4l-1.3-1.3l1.4,1.4L362.6,386.4z"/>
<path className="st17" d="M361.3,385.1l1.4,1.4l-1.3-1.4H361.3z M362.6,386.4l-1.3-1.3l1.4,1.4L362.6,386.4z"/>
<path className="st16" d="M362.9,386.7l1.3,1.2l-1.4-1.3L362.9,386.7z M364.2,388l-1.3-1.3l1.3,1.2V388z"/>
<path className="st17" d="M362.9,386.7l1.3,1.2l-1.4-1.3L362.9,386.7z M364.2,388l-1.3-1.3l1.3,1.2V388z"/>
<path className="st16" d="M361.3,385.2l1.3,1.2l-1.3-1.3V385.2z M362.5,386.6l-1.2-1.3l1.3,1.2L362.5,386.6z"/>
<path className="st17" d="M361.3,385.2l1.3,1.2l-1.3-1.3V385.2z M362.5,386.6l-1.2-1.3l1.3,1.2L362.5,386.6z"/>
<path className="st16" d="M362.5,386.6l0.4,0.1l-0.2-0.2L362.5,386.6z M362.8,386.8l-0.2-0.2l0.4,0.1L362.8,386.8z"/>
<path className="st17" d="M362.5,386.6l0.4,0.1l-0.2-0.2L362.5,386.6z M362.8,386.8l-0.2-0.2l0.4,0.1L362.8,386.8z"/>
<path className="st16" d="M362.8,386.8l1.4,1.2l-1.3-1.3L362.8,386.8z M364.1,388l-1.3-1.2l1.4,1.2H364.1z"/>
<path className="st17" d="M362.8,386.8l1.4,1.2l-1.3-1.3L362.8,386.8z M364.1,388l-1.3-1.2l1.4,1.2H364.1z"/>
<path className="st16" d="M361.2,385.2l1.3,1.3l-1.2-1.3H361.2z M361.2,385.2L361.2,385.2l1.3,1.3L361.2,385.2z"/>
<path className="st17" d="M361.2,385.2l1.3,1.3l-1.2-1.3H361.2z M361.2,385.2L361.2,385.2l1.3,1.3L361.2,385.2z"/>
<path className="st16" d="M362.8,386.8l1.3,1.2L362.8,386.8z M364.1,388.1l-1.3-1.3l1.3,1.2V388.1z"/>
<path className="st17" d="M362.8,386.8l1.3,1.2L362.8,386.8z M364.1,388.1l-1.3-1.3l1.3,1.2V388.1z"/>
<path className="st16" d="M362.8,386.8L362.8,386.8z"/>
<path className="st17" d="M362.8,386.8L362.8,386.8z"/>
<path className="st16" d="M362.5,386.6L362.5,386.6z"/>
<path className="st17" d="M362.5,386.6L362.5,386.6z"/>
<path className="st16" d="M361.2,385.2L361.2,385.2l1.3,1.3L361.2,385.2z M362.5,386.6l-1.3-1.3L362.5,386.6z"/>
<path className="st17" d="M361.2,385.2L361.2,385.2l1.3,1.3L361.2,385.2z M362.5,386.6l-1.3-1.3L362.5,386.6z"/>
<path className="st16" d="M362.8,386.8L362.8,386.8l1.3,1.3L362.8,386.8z M364.1,388.1l-1.3-1.3L364.1,388.1z"/>
<path className="st17" d="M362.8,386.8L362.8,386.8l1.3,1.3L362.8,386.8z M364.1,388.1l-1.3-1.3L364.1,388.1z"/>
<path className="st16" d="M361.2,385.4l1.3,1.2l-1.3-1.3V385.4z M361.2,385.4L361.2,385.4l1.3,1.2L361.2,385.4z"/>
<path className="st17" d="M361.2,385.4l1.3,1.2l-1.3-1.3V385.4z M361.2,385.4L361.2,385.4l1.3,1.2L361.2,385.4z"/>
<path className="st16" d="M362.5,386.6L362.5,386.6l0.2,0.2L362.5,386.6z M362.8,386.8l-0.2-0.2L362.8,386.8z"/>
<path className="st17" d="M362.5,386.6L362.5,386.6l0.2,0.2L362.5,386.6z M362.8,386.8l-0.2-0.2L362.8,386.8z"/>
<path className="st16" d="M362.8,386.8l1.3,1.3L362.8,386.8z M364,388.1l-1.2-1.3l1.3,1.3H364z"/>
<path className="st17" d="M362.8,386.8l1.3,1.3L362.8,386.8z M364,388.1l-1.2-1.3l1.3,1.3H364z"/>
<path className="st16" d="M362.4,386.6h0.1H362.4z M362.5,386.7l-0.1-0.1h0.1V386.7z"/>
<path className="st17" d="M362.4,386.6h0.1H362.4z M362.5,386.7l-0.1-0.1h0.1V386.7z"/>
<path className="st16" d="M362.5,386.7l0.2,0.1l-0.2-0.2V386.7z M362.6,386.8l-0.1-0.1l0.2,0.1H362.6z"/>
<path className="st17" d="M362.5,386.7l0.2,0.1l-0.2-0.2V386.7z M362.6,386.8l-0.1-0.1l0.2,0.1H362.6z"/>
<path className="st16" d="M362.6,386.8h0.1H362.6z M362.8,386.9l-0.1-0.1h0.1V386.9z"/>
<path className="st17" d="M362.6,386.8h0.1H362.6z M362.8,386.9l-0.1-0.1h0.1V386.9z"/>
<path className="st16" d="M362.4,386.7h0.1l-0.1-0.1V386.7z M362.4,386.7L362.4,386.7h0.1H362.4z"/>
<path className="st17" d="M362.4,386.7h0.1l-0.1-0.1V386.7z M362.4,386.7L362.4,386.7h0.1H362.4z"/>
<path className="st16" d="M362.5,386.7L362.5,386.7l0.1,0.1L362.5,386.7z M362.6,386.8l-0.1-0.1L362.6,386.8z"/>
<path className="st17" d="M362.5,386.7L362.5,386.7l0.1,0.1L362.5,386.7z M362.6,386.8l-0.1-0.1L362.6,386.8z"/>
<path className="st16" d="M362.6,386.8l0.1,0.1L362.6,386.8z M362.6,386.9v-0.1l0.1,0.1H362.6z"/>
<path className="st17" d="M362.6,386.8l0.1,0.1L362.6,386.8z M362.6,386.9v-0.1l0.1,0.1H362.6z"/>
<path className="st16" d="M362.4,386.7L362.4,386.7h0.1H362.4z M362.5,386.7h-0.1H362.5z"/>
<path className="st17" d="M362.4,386.7L362.4,386.7h0.1H362.4z M362.5,386.7h-0.1H362.5z"/>
<path className="st16" d="M362.5,386.8h0.1l-0.1-0.1V386.8z M362.5,386.8L362.5,386.8h0.1H362.5z"/>
<path className="st17" d="M362.5,386.8h0.1l-0.1-0.1V386.8z M362.5,386.8L362.5,386.8h0.1H362.5z"/>
<path className="st16" d="M362.6,386.8L362.6,386.8v0.1V386.8z M362.6,386.9v-0.1V386.9z"/>
<path className="st17" d="M362.6,386.8L362.6,386.8v0.1V386.8z M362.6,386.9v-0.1V386.9z"/>
<path className="st16" d="M362.4,386.7L362.4,386.7l0.1,0.1L362.4,386.7z M362.5,386.8l-0.1-0.1L362.5,386.8z"/>
<path className="st17" d="M362.4,386.7L362.4,386.7l0.1,0.1L362.4,386.7z M362.5,386.8l-0.1-0.1L362.5,386.8z"/>
<path className="st16" d="M362.5,386.8L362.5,386.8l0.1,0.1L362.5,386.8z M362.6,386.9l-0.1-0.1L362.6,386.9z"/>
<path className="st17" d="M362.5,386.8L362.5,386.8l0.1,0.1L362.5,386.8z M362.6,386.9l-0.1-0.1L362.6,386.9z"/>
<path className="st16" d="M361.1,385.5l1.4,1.1l-1.3-1.2L361.1,385.5z M362.3,386.8l-1.2-1.3l1.4,1.1L362.3,386.8z"/>
<path className="st17" d="M361.1,385.5l1.4,1.1l-1.3-1.2L361.1,385.5z M362.3,386.8l-1.2-1.3l1.4,1.1L362.3,386.8z"/>
<path className="st16" d="M362.3,386.8l0.4,0.1l-0.2-0.2L362.3,386.8z M362.5,387l-0.2-0.2l0.4,0.1L362.5,387z"/>
<path className="st17" d="M362.3,386.8l0.4,0.1l-0.2-0.2L362.3,386.8z M362.5,387l-0.2-0.2l0.4,0.1L362.5,387z"/>
<path className="st16" d="M362.5,387l1.4,1.1l-1.2-1.3L362.5,387z M363.8,388.2l-1.3-1.2l1.4,1.1L363.8,388.2z"/>
<path className="st17" d="M362.5,387l1.4,1.1l-1.2-1.3L362.5,387z M363.8,388.2l-1.3-1.2l1.4,1.1L363.8,388.2z"/>
<path className="st16" d="M362,387h0.5l-0.2-0.2L362,387z M362.3,387.3L362,387h0.5L362.3,387.3z"/>
<path className="st17" d="M362,387h0.5l-0.2-0.2L362,387z M362.3,387.3L362,387h0.5L362.3,387.3z"/>
<polygon className="st16" points="362.2,387.2 362.2,387.4 362,387.2 "/>
<polygon className="st17" points="362.2,387.2 362.2,387.4 362,387.2 "/>
<path className="st16" d="M361.8,387.2l0.4,0.2l-0.1-0.2H361.8z M362.2,387.5l-0.4-0.4l0.4,0.2V387.5z"/>
<path className="st17" d="M361.8,387.2l0.4,0.2l-0.1-0.2H361.8z M362.2,387.5l-0.4-0.4l0.4,0.2V387.5z"/>
<path className="st16" d="M362,387.5l-0.2-0.1v0.1H362z M362.2,387.5H362l-0.2-0.1L362.2,387.5z"/>
<path className="st17" d="M362,387.5l-0.2-0.1v0.1H362z M362.2,387.5H362l-0.2-0.1L362.2,387.5z"/>
<polygon className="st16" points="362.2,387.5 361.8,387.2 361.8,387.4 "/>
<polygon className="st17" points="362.2,387.5 361.8,387.2 361.8,387.4 "/>
<path className="st16" d="M360.2,386.2l1.1-0.5l-0.2-0.2L360.2,386.2z M360.5,386.4l-0.2-0.2l1.1-0.5L360.5,386.4z"/>
<path className="st17" d="M360.2,386.2l1.1-0.5l-0.2-0.2L360.2,386.2z M360.5,386.4l-0.2-0.2l1.1-0.5L360.5,386.4z"/>
<path className="st16" d="M362.9,388.8l1-0.6l-0.2-0.2L362.9,388.8z M363.1,389.1l-0.2-0.2l1-0.6L363.1,389.1z"/>
<path className="st17" d="M362.9,388.8l1-0.6l-0.2-0.2L362.9,388.8z M363.1,389.1l-0.2-0.2l1-0.6L363.1,389.1z"/>
<path className="st16" d="M360,386.7l0.5-0.2l-0.2-0.2L360,386.7z M360.4,386.9l-0.4-0.2l0.5-0.2L360.4,386.9z"/>
<path className="st17" d="M360,386.7l0.5-0.2l-0.2-0.2L360,386.7z M360.4,386.9l-0.4-0.2l0.5-0.2L360.4,386.9z"/>
<path className="st16" d="M362.4,389l0.7,0.1l-0.2-0.2L362.4,389z M362.6,389.3l-0.2-0.4l0.7,0.1L362.6,389.3z"/>
<path className="st17" d="M362.4,389l0.7,0.1l-0.2-0.2L362.4,389z M362.6,389.3l-0.2-0.4l0.7,0.1L362.6,389.3z"/>
<path className="st16" d="M360,386.7l0.4,0.2L360,386.7z M360.4,387l-0.4-0.4l0.4,0.2V387z"/>
<path className="st17" d="M360,386.7l0.4,0.2L360,386.7z M360.4,387l-0.4-0.4l0.4,0.2V387z"/>
<path className="st16" d="M362.3,389l0.4,0.4l-0.2-0.4H362.3z M362.3,389L362.3,389l0.4,0.4L362.3,389z"/>
<path className="st17" d="M362.3,389l0.4,0.4l-0.2-0.4H362.3z M362.3,389L362.3,389l0.4,0.4L362.3,389z"/>
<path className="st16" d="M360,386.9l0.4,0.1l-0.4-0.4V386.9z M360.5,387.4l-0.5-0.5l0.4,0.1L360.5,387.4z"/>
<path className="st17" d="M360,386.9l0.4,0.1l-0.4-0.4V386.9z M360.5,387.4l-0.5-0.5l0.4,0.1L360.5,387.4z"/>
<path className="st16" d="M361.9,388.8l0.7,0.5l-0.4-0.4L361.9,388.8z M362.4,389.3l-0.5-0.5l0.7,0.5H362.4z"/>
<path className="st17" d="M361.9,388.8l0.7,0.5l-0.4-0.4L361.9,388.8z M362.4,389.3l-0.5-0.5l0.7,0.5H362.4z"/>
<path className="st16" d="M360,387.2l2.4,2.2l-2.4-2.4V387.2z M362.2,389.3l-2.2-2.2l2.4,2.2H362.2z"/>
<path className="st17" d="M360,387.2l2.4,2.2l-2.4-2.4V387.2z M362.2,389.3l-2.2-2.2l2.4,2.2H362.2z"/>
<path className="st16" d="M360.2,387.6l1.9,1.7l-2.2-2.2L360.2,387.6z M361.7,389.1l-1.4-1.4l1.9,1.7L361.7,389.1z"/>
<path className="st17" d="M360.2,387.6l1.9,1.7l-2.2-2.2L360.2,387.6z M361.7,389.1l-1.4-1.4l1.9,1.7L361.7,389.1z"/>
<line className="st18" x1="361.3" y1="385.7" x2="362.3" y2="386.8"/>
<line className="st18" x1="360.5" y1="387.4" x2="361.9" y2="388.8"/>
<line className="st18" x1="360.5" y1="386.4" x2="361.3" y2="385.7"/>
<line className="st18" x1="361.7" y1="386.1" x2="362.3" y2="386.8"/>
<line className="st18" x1="360.2" y1="387.6" x2="361.7" y2="389.1"/>
<line className="st18" x1="360.2" y1="386.2" x2="361.7" y2="384.9"/>
<line className="st18" x1="362" y1="387" x2="362.3" y2="387.3"/>
<path className="st18" d="M362.5,386.7c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C362.4,386.6,362.4,386.7,362.5,386.7"/>
<line className="st18" x1="362" y1="387" x2="362.5" y2="386.6"/>
<line className="st18" x1="362.3" y1="387.3" x2="362.8" y2="386.8"/>
<path className="st18" d="M361.9,387.5c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C361.8,387.3,361.8,387.4,361.9,387.5"/>
<polyline className="st18" points="362.5,387 363.6,388 362.9,388.8 "/>
<polyline className="st18" points="363.1,389.1 364.4,387.6 361.7,384.9 "/>
<path className="st18" d="M360.4,386.4c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M361.9,388.8c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M360.2,386.1c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M361.6,389c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="240.4,264 240.4,264.2 240.2,264 "/>
<polygon className="st17" points="240.4,264 240.4,264.2 240.2,264 "/>
<path className="st16" d="M238.9,262.6l3.1,2.5l-2.8-2.8L238.9,262.6z M241.8,265.5l-2.9-2.9l3.1,2.5L241.8,265.5z"/>
<path className="st17" d="M238.9,262.6l3.1,2.5l-2.8-2.8L238.9,262.6z M241.8,265.5l-2.9-2.9l3.1,2.5L241.8,265.5z"/>
<path className="st16" d="M238.9,262.7l1.4,1.3l-1.4-1.4V262.7z M240.2,264l-1.3-1.3l1.4,1.3H240.2z"/>
<path className="st17" d="M238.9,262.7l1.4,1.3l-1.4-1.4V262.7z M240.2,264l-1.3-1.3l1.4,1.3H240.2z"/>
<path className="st16" d="M240.4,264.2l1.4,1.3l-1.4-1.4V264.2z M241.7,265.5l-1.3-1.3l1.4,1.3H241.7z"/>
<path className="st17" d="M240.4,264.2l1.4,1.3l-1.4-1.4V264.2z M241.7,265.5l-1.3-1.3l1.4,1.3H241.7z"/>
<path className="st16" d="M238.8,262.8l1.4,1.2l-1.3-1.3L238.8,262.8z M240.1,264l-1.3-1.2l1.4,1.2H240.1z"/>
<path className="st17" d="M238.8,262.8l1.4,1.2l-1.3-1.3L238.8,262.8z M240.1,264l-1.3-1.2l1.4,1.2H240.1z"/>
<path className="st16" d="M240.1,264l0.2,0.1l-0.1-0.1H240.1z M240.4,264.3l-0.2-0.2l0.2,0.1V264.3z"/>
<path className="st17" d="M240.1,264l0.2,0.1l-0.1-0.1H240.1z M240.4,264.3l-0.2-0.2l0.2,0.1V264.3z"/>
<path className="st16" d="M240.4,264.3l1.3,1.2l-1.3-1.3V264.3z M241.7,265.6l-1.3-1.3l1.3,1.2V265.6z"/>
<path className="st17" d="M240.4,264.3l1.3,1.2l-1.3-1.3V264.3z M241.7,265.6l-1.3-1.3l1.3,1.2V265.6z"/>
<path className="st16" d="M238.8,262.8L238.8,262.8l1.3,1.2L238.8,262.8z M240.1,264l-1.3-1.2L240.1,264z"/>
<path className="st17" d="M238.8,262.8L238.8,262.8l1.3,1.2L238.8,262.8z M240.1,264l-1.3-1.2L240.1,264z"/>
<path className="st16" d="M240.4,264.4l1.3,1.2l-1.3-1.3V264.4z M241.6,265.6l-1.2-1.2l1.3,1.2H241.6z"/>
<path className="st17" d="M240.4,264.4l1.3,1.2l-1.3-1.3V264.4z M241.6,265.6l-1.2-1.2l1.3,1.2H241.6z"/>
<path className="st16" d="M240.4,264.4h-0.1H240.4z"/>
<path className="st17" d="M240.4,264.4h-0.1H240.4z"/>
<path className="st16" d="M240,264.2L240,264.2z"/>
<path className="st17" d="M240,264.2L240,264.2z"/>
<path className="st16" d="M238.8,262.8l1.3,1.2L238.8,262.8z M240,264.2l-1.2-1.3l1.3,1.2L240,264.2z"/>
<path className="st17" d="M238.8,262.8l1.3,1.2L238.8,262.8z M240,264.2l-1.2-1.3l1.3,1.2L240,264.2z"/>
<path className="st16" d="M240.4,264.4L240.4,264.4l1.2,1.2L240.4,264.4z M241.6,265.6l-1.2-1.2L241.6,265.6z"/>
<path className="st17" d="M240.4,264.4L240.4,264.4l1.2,1.2L240.4,264.4z M241.6,265.6l-1.2-1.2L241.6,265.6z"/>
<path className="st16" d="M238.8,262.8L238.8,262.8l1.2,1.3L238.8,262.8z M240,264.2l-1.2-1.3L240,264.2z"/>
<path className="st17" d="M238.8,262.8L238.8,262.8l1.2,1.3L238.8,262.8z M240,264.2l-1.2-1.3L240,264.2z"/>
<path className="st16" d="M240,264.2l0.4,0.1l-0.2-0.2L240,264.2z M240.2,264.4l-0.2-0.2l0.4,0.1L240.2,264.4z"/>
<path className="st17" d="M240,264.2l0.4,0.1l-0.2-0.2L240,264.2z M240.2,264.4l-0.2-0.2l0.4,0.1L240.2,264.4z"/>
<path className="st16" d="M240.2,264.4l1.3,1.2l-1.2-1.2H240.2z M240.2,264.4L240.2,264.4l1.3,1.2L240.2,264.4z"/>
<path className="st17" d="M240.2,264.4l1.3,1.2l-1.2-1.2H240.2z M240.2,264.4L240.2,264.4l1.3,1.2L240.2,264.4z"/>
<path className="st16" d="M240,264.2L240,264.2l0.2,0.2L240,264.2z M240,264.2L240,264.2z M240,264.2L240,264.2z M240.2,264.4l-0.2-0.2
	L240.2,264.4z"/>
<path className="st17" d="M240,264.2L240,264.2l0.2,0.2L240,264.2z M240,264.2L240,264.2z M240,264.2L240,264.2z M240.2,264.4l-0.2-0.2
	L240.2,264.4z"/>
<path className="st16" d="M240.2,264.4L240.2,264.4z M240.2,264.4L240.2,264.4z"/>
<path className="st17" d="M240.2,264.4L240.2,264.4z M240.2,264.4L240.2,264.4z"/>
<path className="st16" d="M240,264.2v0.1V264.2z"/>
<path className="st17" d="M240,264.2v0.1V264.2z"/>
<path className="st16" d="M240,264.2L240,264.2z"/>
<path className="st17" d="M240,264.2L240,264.2z"/>
<path className="st16" d="M240,264.3l0.2,0.1l-0.2-0.2V264.3z M240,264.3L240,264.3l0.2,0.1L240,264.3z"/>
<path className="st17" d="M240,264.3l0.2,0.1l-0.2-0.2V264.3z M240,264.3L240,264.3l0.2,0.1L240,264.3z"/>
<path className="st16" d="M240.2,264.4L240.2,264.4z M240.2,264.4L240.2,264.4z"/>
<path className="st17" d="M240.2,264.4L240.2,264.4z M240.2,264.4L240.2,264.4z"/>
<path className="st16" d="M240,264.2L240,264.2v0.1V264.2z M240,264.3v-0.1V264.3z"/>
<path className="st17" d="M240,264.2L240,264.2v0.1V264.2z M240,264.3v-0.1V264.3z"/>
<path className="st16" d="M240.1,264.3l0.1,0.1l-0.2-0.1H240.1z M240.1,264.4v-0.1l0.1,0.1H240.1z"/>
<path className="st17" d="M240.1,264.3l0.1,0.1l-0.2-0.1H240.1z M240.1,264.4v-0.1l0.1,0.1H240.1z"/>
<path className="st16" d="M240.1,264.4L240.1,264.4h0.1H240.1z M240.2,264.4h-0.1H240.2z"/>
<path className="st17" d="M240.1,264.4L240.1,264.4h0.1H240.1z M240.2,264.4h-0.1H240.2z"/>
<path className="st16" d="M240,264.2L240,264.2l0.1,0.1L240,264.2z M240.1,264.3l-0.1-0.1L240.1,264.3z"/>
<path className="st17" d="M240,264.2L240,264.2l0.1,0.1L240,264.2z M240.1,264.3l-0.1-0.1L240.1,264.3z"/>
<path className="st16" d="M240.1,264.3l0.1,0.1h-0.1V264.3z M240.2,264.5l-0.1-0.2l0.1,0.1V264.5z"/>
<path className="st17" d="M240.1,264.3l0.1,0.1h-0.1V264.3z M240.2,264.5l-0.1-0.2l0.1,0.1V264.5z"/>
<path className="st16" d="M238.6,263.1l1.4,1.1l-1.2-1.3L238.6,263.1z M239.9,264.3l-1.3-1.2l1.4,1.1L239.9,264.3z"/>
<path className="st17" d="M238.6,263.1l1.4,1.1l-1.2-1.3L238.6,263.1z M239.9,264.3l-1.3-1.2l1.4,1.1L239.9,264.3z"/>
<path className="st16" d="M239.9,264.3l0.4,0.2l-0.2-0.4L239.9,264.3z M240.1,264.5l-0.2-0.2l0.4,0.2H240.1z"/>
<path className="st17" d="M239.9,264.3l0.4,0.2l-0.2-0.4L239.9,264.3z M240.1,264.5l-0.2-0.2l0.4,0.2H240.1z"/>
<path className="st16" d="M240.1,264.5l1.4,1.1l-1.3-1.2L240.1,264.5z M241.4,265.8l-1.3-1.3l1.4,1.1L241.4,265.8z"/>
<path className="st17" d="M240.1,264.5l1.4,1.1l-1.3-1.2L240.1,264.5z M241.4,265.8l-1.3-1.3l1.4,1.1L241.4,265.8z"/>
<path className="st16" d="M239.6,264.5h0.5l-0.2-0.2L239.6,264.5z M239.9,264.8l-0.2-0.2h0.5L239.9,264.8z"/>
<path className="st17" d="M239.6,264.5h0.5l-0.2-0.2L239.6,264.5z M239.9,264.8l-0.2-0.2h0.5L239.9,264.8z"/>
<polygon className="st16" points="239.6,264.8 239.8,264.9 239.5,264.6 "/>
<polygon className="st17" points="239.6,264.8 239.8,264.9 239.5,264.6 "/>
<path className="st16" d="M239.4,264.8l0.4,0.1l-0.2-0.2L239.4,264.8z M239.6,265l-0.2-0.2l0.4,0.1L239.6,265z"/>
<path className="st17" d="M239.4,264.8l0.4,0.1l-0.2-0.2L239.4,264.8z M239.6,265l-0.2-0.2l0.4,0.1L239.6,265z"/>
<path className="st16" d="M239.5,265.1l-0.2-0.2l0.1,0.1L239.5,265.1z M239.6,265l-0.1,0.1l-0.2-0.2L239.6,265z"/>
<path className="st17" d="M239.5,265.1l-0.2-0.2l0.1,0.1L239.5,265.1z M239.6,265l-0.1,0.1l-0.2-0.2L239.6,265z"/>
<polygon className="st16" points="239.6,265 239.4,264.8 239.3,264.9 "/>
<polygon className="st17" points="239.6,265 239.4,264.8 239.3,264.9 "/>
<path className="st16" d="M237.8,263.8l1-0.6l-0.2-0.1L237.8,263.8z M238.1,264l-0.2-0.2l1-0.6L238.1,264z"/>
<path className="st17" d="M237.8,263.8l1-0.6l-0.2-0.1L237.8,263.8z M238.1,264l-0.2-0.2l1-0.6L238.1,264z"/>
<path className="st16" d="M240.4,266.3l1.1-0.5l-0.2-0.2L240.4,266.3z M240.6,266.6l-0.2-0.2l1.1-0.5L240.6,266.6z"/>
<path className="st17" d="M240.4,266.3l1.1-0.5l-0.2-0.2L240.4,266.3z M240.6,266.6l-0.2-0.2l1.1-0.5L240.6,266.6z"/>
<path className="st16" d="M237.6,264.2l0.5-0.1l-0.2-0.2L237.6,264.2z M237.8,264.5l-0.2-0.4l0.5-0.1L237.8,264.5z"/>
<path className="st17" d="M237.6,264.2l0.5-0.1l-0.2-0.2L237.6,264.2z M237.8,264.5l-0.2-0.4l0.5-0.1L237.8,264.5z"/>
<path className="st16" d="M240,266.6h0.6l-0.2-0.2L240,266.6z M240.2,266.8l-0.2-0.2h0.6L240.2,266.8z"/>
<path className="st17" d="M240,266.6h0.6l-0.2-0.2L240,266.6z M240.2,266.8l-0.2-0.2h0.6L240.2,266.8z"/>
<path className="st16" d="M237.6,264.2l0.2,0.4L237.6,264.2z M238,264.5l-0.4-0.4l0.2,0.4H238z"/>
<path className="st17" d="M237.6,264.2l0.2,0.4L237.6,264.2z M238,264.5l-0.4-0.4l0.2,0.4H238z"/>
<path className="st16" d="M239.9,266.6l0.4,0.2l-0.2-0.2H239.9z M239.9,266.6L239.9,266.6l0.4,0.2L239.9,266.6z"/>
<path className="st17" d="M239.9,266.6l0.4,0.2l-0.2-0.2H239.9z M239.9,266.6L239.9,266.6l0.4,0.2L239.9,266.6z"/>
<path className="st16" d="M237.6,264.4l0.4,0.1l-0.4-0.4V264.4z M238.1,264.9l-0.5-0.5l0.4,0.1L238.1,264.9z"/>
<path className="st17" d="M237.6,264.4l0.4,0.1l-0.4-0.4V264.4z M238.1,264.9l-0.5-0.5l0.4,0.1L238.1,264.9z"/>
<path className="st16" d="M239.5,266.3l0.7,0.5l-0.4-0.2L239.5,266.3z M240,266.8l-0.5-0.5l0.7,0.5H240z"/>
<path className="st17" d="M239.5,266.3l0.7,0.5l-0.4-0.2L239.5,266.3z M240,266.8l-0.5-0.5l0.7,0.5H240z"/>
<path className="st16" d="M237.6,264.8l2.4,2l-2.4-2.4V264.8z M239.6,266.8l-2-2l2.4,2H239.6z"/>
<path className="st17" d="M237.6,264.8l2.4,2l-2.4-2.4V264.8z M239.6,266.8l-2-2l2.4,2H239.6z"/>
<path className="st16" d="M237.8,265.1l1.8,1.7l-2-2L237.8,265.1z M239.3,266.6l-1.4-1.4l1.8,1.7L239.3,266.6z"/>
<path className="st17" d="M237.8,265.1l1.8,1.7l-2-2L237.8,265.1z M239.3,266.6l-1.4-1.4l1.8,1.7L239.3,266.6z"/>
<line className="st18" x1="238.8" y1="263.2" x2="239.9" y2="264.3"/>
<line className="st18" x1="238.1" y1="264.9" x2="239.5" y2="266.3"/>
<line className="st18" x1="238.1" y1="264" x2="238.8" y2="263.2"/>
<line className="st18" x1="239.2" y1="263.6" x2="239.9" y2="264.3"/>
<line className="st18" x1="237.8" y1="265.1" x2="239.3" y2="266.6"/>
<line className="st18" x1="237.8" y1="263.8" x2="239.3" y2="262.4"/>
<line className="st18" x1="239.6" y1="264.5" x2="239.9" y2="264.8"/>
<path className="st18" d="M240.1,264.2c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C240,264,240,264.2,240.1,264.2"/>
<line className="st18" x1="239.6" y1="264.5" x2="240" y2="264.2"/>
<line className="st18" x1="239.9" y1="264.8" x2="240.4" y2="264.4"/>
<path className="st18" d="M239.3,264.9c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C239.3,264.8,239.3,264.9,239.3,264.9"/>
<polyline className="st18" points="240.1,264.5 241.2,265.6 240.4,266.3 "/>
<polyline className="st18" points="240.6,266.6 242,265.1 239.3,262.4 "/>
<path className="st18" d="M238,264c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M239.5,266.2c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M237.8,263.7c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M239.2,266.5c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="225.7,249.4 225.7,249.5 225.5,249.3 "/>
<polygon className="st17" points="225.7,249.4 225.7,249.5 225.5,249.3 "/>
<path className="st16" d="M224.3,248l3.1,2.5l-2.9-2.8L224.3,248z M227,250.7l-2.8-2.8l3.1,2.5L227,250.7z"/>
<path className="st17" d="M224.3,248l3.1,2.5l-2.9-2.8L224.3,248z M227,250.7l-2.8-2.8l3.1,2.5L227,250.7z"/>
<path className="st16" d="M224.3,248l1.4,1.4L224.3,248z M225.5,249.3l-1.2-1.3l1.4,1.4L225.5,249.3z"/>
<path className="st17" d="M224.3,248l1.4,1.4L224.3,248z M225.5,249.3l-1.2-1.3l1.4,1.4L225.5,249.3z"/>
<path className="st16" d="M225.7,249.5l1.3,1.2l-1.3-1.3V249.5z M227,250.8l-1.3-1.3l1.3,1.2V250.8z"/>
<path className="st17" d="M225.7,249.5l1.3,1.2l-1.3-1.3V249.5z M227,250.8l-1.3-1.3l1.3,1.2V250.8z"/>
<path className="st16" d="M224.2,248.1l1.3,1.2l-1.2-1.3L224.2,248.1z M225.4,249.4l-1.2-1.3l1.3,1.2L225.4,249.4z"/>
<path className="st17" d="M224.2,248.1l1.3,1.2l-1.2-1.3L224.2,248.1z M225.4,249.4l-1.2-1.3l1.3,1.2L225.4,249.4z"/>
<path className="st16" d="M225.4,249.4l0.4,0.1l-0.2-0.2L225.4,249.4z M225.7,249.6l-0.4-0.2l0.4,0.1V249.6z"/>
<path className="st17" d="M225.4,249.4l0.4,0.1l-0.2-0.2L225.4,249.4z M225.7,249.6l-0.4-0.2l0.4,0.1V249.6z"/>
<path className="st16" d="M225.7,249.6l1.3,1.2l-1.3-1.3V249.6z M226.9,250.8l-1.2-1.2l1.3,1.2H226.9z"/>
<path className="st17" d="M225.7,249.6l1.3,1.2l-1.3-1.3V249.6z M226.9,250.8l-1.2-1.2l1.3,1.2H226.9z"/>
<path className="st16" d="M224.2,248.1L224.2,248.1l1.2,1.3L224.2,248.1z M225.4,249.4l-1.2-1.3L225.4,249.4z"/>
<path className="st17" d="M224.2,248.1L224.2,248.1l1.2,1.3L224.2,248.1z M225.4,249.4l-1.2-1.3L225.4,249.4z"/>
<path className="st16" d="M225.6,249.6l1.3,1.2l-1.2-1.2H225.6z M226.9,251l-1.3-1.3l1.3,1.2V251z"/>
<path className="st17" d="M225.6,249.6l1.3,1.2l-1.2-1.2H225.6z M226.9,251l-1.3-1.3l1.3,1.2V251z"/>
<path className="st16" d="M225.6,249.6L225.6,249.6z"/>
<path className="st17" d="M225.6,249.6L225.6,249.6z"/>
<path className="st16" d="M225.4,249.4L225.4,249.4z"/>
<path className="st17" d="M225.4,249.4L225.4,249.4z"/>
<path className="st16" d="M224,248.2l1.3,1.2l-1.2-1.3L224,248.2z M224,248.2L224,248.2l1.3,1.2L224,248.2z"/>
<path className="st17" d="M224,248.2l1.3,1.2l-1.2-1.3L224,248.2z M224,248.2L224,248.2l1.3,1.2L224,248.2z"/>
<path className="st16" d="M225.6,249.6L225.6,249.6l1.3,1.3L225.6,249.6z M226.9,251l-1.3-1.3L226.9,251z"/>
<path className="st17" d="M225.6,249.6L225.6,249.6l1.3,1.3L225.6,249.6z M226.9,251l-1.3-1.3L226.9,251z"/>
<path className="st16" d="M224,248.2L224,248.2l1.3,1.2L224,248.2z M225.4,249.4l-1.3-1.2L225.4,249.4z"/>
<path className="st17" d="M224,248.2L224,248.2l1.3,1.2L224,248.2z M225.4,249.4l-1.3-1.2L225.4,249.4z"/>
<path className="st16" d="M225.4,249.4l0.4,0.2L225.4,249.4z M225.6,249.6l-0.2-0.2l0.4,0.2H225.6z"/>
<path className="st17" d="M225.4,249.4l0.4,0.2L225.4,249.4z M225.6,249.6l-0.2-0.2l0.4,0.2H225.6z"/>
<path className="st16" d="M225.6,249.6L225.6,249.6l1.3,1.3L225.6,249.6z M226.9,251l-1.3-1.3L226.9,251z"/>
<path className="st17" d="M225.6,249.6L225.6,249.6l1.3,1.3L225.6,249.6z M226.9,251l-1.3-1.3L226.9,251z"/>
<path className="st16" d="M225.2,249.5l0.1-0.1L225.2,249.5z M225.4,249.5h-0.1l0.1-0.1V249.5z"/>
<path className="st17" d="M225.2,249.5l0.1-0.1L225.2,249.5z M225.4,249.5h-0.1l0.1-0.1V249.5z"/>
<path className="st16" d="M225.4,249.5l0.2,0.1l-0.2-0.2V249.5z M225.5,249.6l-0.1-0.1l0.2,0.1H225.5z"/>
<path className="st17" d="M225.4,249.5l0.2,0.1l-0.2-0.2V249.5z M225.5,249.6l-0.1-0.1l0.2,0.1H225.5z"/>
<path className="st16" d="M225.5,249.6h0.1H225.5z M225.6,249.8l-0.1-0.1h0.1V249.8z"/>
<path className="st17" d="M225.5,249.6h0.1H225.5z M225.6,249.8l-0.1-0.1h0.1V249.8z"/>
<path className="st16" d="M225.2,249.5L225.2,249.5h0.1H225.2z M225.4,249.5h-0.1H225.4z"/>
<path className="st17" d="M225.2,249.5L225.2,249.5h0.1H225.2z M225.4,249.5h-0.1H225.4z"/>
<path className="st16" d="M225.4,249.5L225.4,249.5l0.1,0.1L225.4,249.5z M225.5,249.6l-0.1-0.1L225.5,249.6z"/>
<path className="st17" d="M225.4,249.5L225.4,249.5l0.1,0.1L225.4,249.5z M225.5,249.6l-0.1-0.1L225.5,249.6z"/>
<path className="st16" d="M225.5,249.6L225.5,249.6l0.1,0.1L225.5,249.6z M225.6,249.8l-0.1-0.1L225.6,249.8z"/>
<path className="st17" d="M225.5,249.6L225.5,249.6l0.1,0.1L225.5,249.6z M225.6,249.8l-0.1-0.1L225.6,249.8z"/>
<path className="st16" d="M225.2,249.5L225.2,249.5h0.1H225.2z M225.4,249.5h-0.1H225.4z"/>
<path className="st17" d="M225.2,249.5L225.2,249.5h0.1H225.2z M225.4,249.5h-0.1H225.4z"/>
<path className="st16" d="M225.4,249.6h0.1l-0.1-0.1V249.6z M225.4,249.6L225.4,249.6h0.1H225.4z"/>
<path className="st17" d="M225.4,249.6h0.1l-0.1-0.1V249.6z M225.4,249.6L225.4,249.6h0.1H225.4z"/>
<path className="st16" d="M225.5,249.6l0.1,0.1L225.5,249.6z M225.5,249.8v-0.1l0.1,0.1H225.5z"/>
<path className="st17" d="M225.5,249.6l0.1,0.1L225.5,249.6z M225.5,249.8v-0.1l0.1,0.1H225.5z"/>
<path className="st16" d="M225.2,249.5L225.2,249.5l0.1,0.1L225.2,249.5z M225.4,249.6l-0.1-0.1L225.4,249.6z"/>
<path className="st17" d="M225.2,249.5L225.2,249.5l0.1,0.1L225.2,249.5z M225.4,249.6l-0.1-0.1L225.4,249.6z"/>
<path className="st16" d="M225.4,249.6l0.1,0.1v-0.1H225.4z M225.4,249.6L225.4,249.6l0.1,0.1L225.4,249.6z"/>
<path className="st17" d="M225.4,249.6l0.1,0.1v-0.1H225.4z M225.4,249.6L225.4,249.6l0.1,0.1L225.4,249.6z"/>
<path className="st16" d="M223.9,248.3l1.4,1.1l-1.3-1.2L223.9,248.3z M225.1,249.6l-1.2-1.3l1.4,1.1L225.1,249.6z"/>
<path className="st17" d="M223.9,248.3l1.4,1.1l-1.3-1.2L223.9,248.3z M225.1,249.6l-1.2-1.3l1.4,1.1L225.1,249.6z"/>
<path className="st16" d="M225.1,249.6l0.4,0.1l-0.2-0.2L225.1,249.6z M225.5,249.9l-0.4-0.2l0.4,0.1V249.9z"/>
<path className="st17" d="M225.1,249.6l0.4,0.1l-0.2-0.2L225.1,249.6z M225.5,249.9l-0.4-0.2l0.4,0.1V249.9z"/>
<path className="st16" d="M225.5,249.9l1.4,1.1l-1.3-1.3L225.5,249.9z M226.7,251.1l-1.2-1.2l1.4,1.1L226.7,251.1z"/>
<path className="st17" d="M225.5,249.9l1.4,1.1l-1.3-1.3L225.5,249.9z M226.7,251.1l-1.2-1.2l1.4,1.1L226.7,251.1z"/>
<path className="st16" d="M224.9,249.9h0.6l-0.4-0.2L224.9,249.9z M225.2,250.1l-0.4-0.2h0.6L225.2,250.1z"/>
<path className="st17" d="M224.9,249.9h0.6l-0.4-0.2L224.9,249.9z M225.2,250.1l-0.4-0.2h0.6L225.2,250.1z"/>
<polygon className="st16" points="225,250.1 225,250.2 224.9,250 "/>
<polygon className="st17" points="225,250.1 225,250.2 224.9,250 "/>
<path className="st16" d="M224.6,250.1l0.4,0.1l-0.1-0.2L224.6,250.1z M225,250.4l-0.4-0.2l0.4,0.1V250.4z"/>
<path className="st17" d="M224.6,250.1l0.4,0.1l-0.1-0.2L224.6,250.1z M225,250.4l-0.4-0.2l0.4,0.1V250.4z"/>
<path className="st16" d="M224.9,250.4l-0.2-0.1v0.1H224.9z M225,250.4h-0.1l-0.2-0.1L225,250.4z"/>
<path className="st17" d="M224.9,250.4l-0.2-0.1v0.1H224.9z M225,250.4h-0.1l-0.2-0.1L225,250.4z"/>
<polygon className="st16" points="225,250.4 224.6,250.1 224.6,250.2 "/>
<polygon className="st17" points="225,250.4 224.6,250.1 224.6,250.2 "/>
<path className="st16" d="M223.2,249l1-0.5l-0.2-0.2L223.2,249z M223.4,249.3l-0.2-0.2l1-0.5L223.4,249.3z"/>
<path className="st17" d="M223.2,249l1-0.5l-0.2-0.2L223.2,249z M223.4,249.3l-0.2-0.2l1-0.5L223.4,249.3z"/>
<path className="st16" d="M225.7,251.7l1-0.6l-0.2-0.2L225.7,251.7z M226,251.9l-0.2-0.2l1-0.6L226,251.9z"/>
<path className="st17" d="M225.7,251.7l1-0.6l-0.2-0.2L225.7,251.7z M226,251.9l-0.2-0.2l1-0.6L226,251.9z"/>
<path className="st16" d="M223,249.5l0.5-0.2l-0.2-0.2L223,249.5z M223.2,249.8l-0.2-0.2l0.5-0.2L223.2,249.8z"/>
<path className="st17" d="M223,249.5l0.5-0.2l-0.2-0.2L223,249.5z M223.2,249.8l-0.2-0.2l0.5-0.2L223.2,249.8z"/>
<path className="st16" d="M225.2,251.8l0.7,0.1l-0.2-0.2L225.2,251.8z M225.5,252.2l-0.2-0.4l0.7,0.1L225.5,252.2z"/>
<path className="st17" d="M225.2,251.8l0.7,0.1l-0.2-0.2L225.2,251.8z M225.5,252.2l-0.2-0.4l0.7,0.1L225.5,252.2z"/>
<path className="st16" d="M222.8,249.5l0.4,0.2l-0.2-0.2H222.8z M223.2,249.9l-0.4-0.4l0.4,0.2V249.9z"/>
<path className="st17" d="M222.8,249.5l0.4,0.2l-0.2-0.2H222.8z M223.2,249.9l-0.4-0.4l0.4,0.2V249.9z"/>
<path className="st16" d="M225.2,251.8L225.2,251.8l0.2,0.4L225.2,251.8z M225.5,252.2l-0.2-0.4L225.5,252.2z"/>
<path className="st17" d="M225.2,251.8L225.2,251.8l0.2,0.4L225.2,251.8z M225.5,252.2l-0.2-0.4L225.5,252.2z"/>
<path className="st16" d="M222.8,249.8l0.4,0.1l-0.4-0.4V249.8z M223.4,250.2l-0.6-0.5l0.4,0.1L223.4,250.2z"/>
<path className="st17" d="M222.8,249.8l0.4,0.1l-0.4-0.4V249.8z M223.4,250.2l-0.6-0.5l0.4,0.1L223.4,250.2z"/>
<path className="st16" d="M224.8,251.7l0.7,0.5l-0.2-0.4L224.8,251.7z M225.2,252.2l-0.5-0.5l0.7,0.5H225.2z"/>
<path className="st17" d="M224.8,251.7l0.7,0.5l-0.2-0.4L224.8,251.7z M225.2,252.2l-0.5-0.5l0.7,0.5H225.2z"/>
<path className="st16" d="M222.8,250l2.4,2.2l-2.4-2.4V250z M225,252.2l-2.2-2.2l2.4,2.2H225z"/>
<path className="st17" d="M222.8,250l2.4,2.2l-2.4-2.4V250z M225,252.2l-2.2-2.2l2.4,2.2H225z"/>
<path className="st16" d="M223.2,250.5l1.8,1.7l-2.2-2.2L223.2,250.5z M224.5,251.9l-1.3-1.4l1.8,1.7L224.5,251.9z"/>
<path className="st17" d="M223.2,250.5l1.8,1.7l-2.2-2.2L223.2,250.5z M224.5,251.9l-1.3-1.4l1.8,1.7L224.5,251.9z"/>
<line className="st18" x1="224.2" y1="248.6" x2="225.1" y2="249.6"/>
<line className="st18" x1="223.4" y1="250.2" x2="224.8" y2="251.7"/>
<line className="st18" x1="223.4" y1="249.3" x2="224.2" y2="248.6"/>
<line className="st18" x1="224.5" y1="248.9" x2="225.1" y2="249.6"/>
<line className="st18" x1="223.2" y1="250.5" x2="224.5" y2="251.9"/>
<line className="st18" x1="223.2" y1="249" x2="224.5" y2="247.7"/>
<line className="st18" x1="224.9" y1="249.9" x2="225.2" y2="250.1"/>
<path className="st18" d="M225.3,249.6c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C225.2,249.4,225.2,249.5,225.3,249.6"/>
<line className="st18" x1="224.9" y1="249.9" x2="225.4" y2="249.4"/>
<line className="st18" x1="225.2" y1="250.1" x2="225.6" y2="249.6"/>
<path className="st18" d="M224.7,250.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C224.6,250.1,224.6,250.2,224.7,250.3"/>
<polyline className="st18" points="225.5,249.9 226.4,250.8 225.7,251.7 "/>
<polyline className="st18" points="226,251.9 227.4,250.5 224.5,247.7 "/>
<path className="st18" d="M223.3,249.2c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M224.7,251.6c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M223,249c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M224.5,251.9c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="236.6,219.9 236.8,219.8 236.5,220 "/>
<polygon className="st17" points="236.6,219.9 236.8,219.8 236.5,220 "/>
<path className="st16" d="M235.2,221.2l2.5-3L235,221L235.2,221.2z M238,218.4l-2.8,2.8l2.5-3L238,218.4z"/>
<path className="st17" d="M235.2,221.2l2.5-3L235,221L235.2,221.2z M238,218.4l-2.8,2.8l2.5-3L238,218.4z"/>
<path className="st16" d="M235.2,221.3l1.4-1.4l-1.4,1.3V221.3z M236.5,220l-1.3,1.3l1.4-1.4L236.5,220z"/>
<path className="st17" d="M235.2,221.3l1.4-1.4l-1.4,1.3V221.3z M236.5,220l-1.3,1.3l1.4-1.4L236.5,220z"/>
<path className="st16" d="M236.8,219.8l1.2-1.3l-1.3,1.4L236.8,219.8z M238.1,218.4l-1.3,1.3l1.2-1.3H238.1z"/>
<path className="st17" d="M236.8,219.8l1.2-1.3l-1.3,1.4L236.8,219.8z M238.1,218.4l-1.3,1.3l1.2-1.3H238.1z"/>
<path className="st16" d="M235.3,221.4l1.2-1.4l-1.3,1.3L235.3,221.4z M236.6,220.1l-1.3,1.3l1.2-1.4L236.6,220.1z"/>
<path className="st17" d="M235.3,221.4l1.2-1.4l-1.3,1.3L235.3,221.4z M236.6,220.1l-1.3,1.3l1.2-1.4L236.6,220.1z"/>
<path className="st16" d="M236.6,220.1l0.1-0.4l-0.2,0.2L236.6,220.1z M236.9,219.9l-0.2,0.2l0.1-0.4L236.9,219.9z"/>
<path className="st17" d="M236.6,220.1l0.1-0.4l-0.2,0.2L236.6,220.1z M236.9,219.9l-0.2,0.2l0.1-0.4L236.9,219.9z"/>
<path className="st16" d="M236.9,219.9l1.2-1.4l-1.3,1.3L236.9,219.9z M238.2,218.6l-1.3,1.3l1.2-1.4L238.2,218.6z"/>
<path className="st17" d="M236.9,219.9l1.2-1.4l-1.3,1.3L236.9,219.9z M238.2,218.6l-1.3,1.3l1.2-1.4L238.2,218.6z"/>
<path className="st16" d="M235.3,221.4L235.3,221.4l1.3-1.3L235.3,221.4z M236.6,220.1l-1.3,1.3L236.6,220.1z"/>
<path className="st17" d="M235.3,221.4L235.3,221.4l1.3-1.3L235.3,221.4z M236.6,220.1l-1.3,1.3L236.6,220.1z"/>
<path className="st16" d="M236.9,219.9L236.9,219.9l1.3-1.3L236.9,219.9z M238.2,218.6l-1.3,1.3L238.2,218.6z"/>
<path className="st17" d="M236.9,219.9L236.9,219.9l1.3-1.3L236.9,219.9z M238.2,218.6l-1.3,1.3L238.2,218.6z"/>
<polygon className="st16" points="236.9,219.9 237,219.9 236.9,220 "/>
<polygon className="st17" points="236.9,219.9 237,219.9 236.9,220 "/>
<polygon className="st16" points="236.6,220.1 236.8,220.1 236.6,220.2 "/>
<polygon className="st17" points="236.6,220.1 236.8,220.1 236.6,220.2 "/>
<path className="st16" d="M235.4,221.4l1.2-1.3l-1.3,1.3H235.4z M235.4,221.4L235.4,221.4l1.2-1.3L235.4,221.4z"/>
<path className="st17" d="M235.4,221.4l1.2-1.3l-1.3,1.3H235.4z M235.4,221.4L235.4,221.4l1.2-1.3L235.4,221.4z"/>
<path className="st16" d="M236.9,219.9l1.3-1.3L236.9,219.9z M238.2,218.7l-1.3,1.2l1.3-1.3V218.7z"/>
<path className="st17" d="M236.9,219.9l1.3-1.3L236.9,219.9z M238.2,218.7l-1.3,1.2l1.3-1.3V218.7z"/>
<path className="st16" d="M235.4,221.4l1.2-1.3L235.4,221.4z M236.6,220.2l-1.2,1.2l1.2-1.3V220.2z"/>
<path className="st17" d="M235.4,221.4l1.2-1.3L235.4,221.4z M236.6,220.2l-1.2,1.2l1.2-1.3V220.2z"/>
<path className="st16" d="M236.8,220.1l0.1-0.2l-0.2,0.2H236.8z M236.9,220l-0.1,0.1l0.1-0.2V220z"/>
<path className="st17" d="M236.8,220.1l0.1-0.2l-0.2,0.2H236.8z M236.9,220l-0.1,0.1l0.1-0.2V220z"/>
<path className="st16" d="M237,219.9l1.2-1.2l-1.3,1.2H237z M237,219.9L237,219.9l1.2-1.2L237,219.9z"/>
<path className="st17" d="M237,219.9l1.2-1.2l-1.3,1.2H237z M237,219.9L237,219.9l1.2-1.2L237,219.9z"/>
<path className="st16" d="M236.8,220.2v-0.1l-0.1,0.1H236.8z M236.8,220.2L236.8,220.2v-0.1V220.2z"/>
<path className="st17" d="M236.8,220.2v-0.1l-0.1,0.1H236.8z M236.8,220.2L236.8,220.2v-0.1V220.2z"/>
<path className="st16" d="M236.8,220.1L236.8,220.1l0.1-0.1L236.8,220.1z M236.9,220l-0.1,0.1L236.9,220z"/>
<path className="st17" d="M236.8,220.1L236.8,220.1l0.1-0.1L236.8,220.1z M236.9,220l-0.1,0.1L236.9,220z"/>
<path className="st16" d="M237,220v-0.1l-0.1,0.1H237z M237,220L237,220v-0.1V220z"/>
<path className="st17" d="M237,220v-0.1l-0.1,0.1H237z M237,220L237,220v-0.1V220z"/>
<path className="st16" d="M236.8,220.2v-0.1V220.2z"/>
<path className="st17" d="M236.8,220.2v-0.1V220.2z"/>
<path className="st16" d="M236.8,220.2L236.8,220.2z"/>
<path className="st17" d="M236.8,220.2L236.8,220.2z"/>
<path className="st16" d="M236.8,220.1L236.8,220.1l0.1-0.1L236.8,220.1z M236.9,220l-0.1,0.1L236.9,220z"/>
<path className="st17" d="M236.8,220.1L236.8,220.1l0.1-0.1L236.8,220.1z M236.9,220l-0.1,0.1L236.9,220z"/>
<path className="st16" d="M236.9,220L236.9,220h0.1H236.9z M237,220h-0.1H237z"/>
<path className="st17" d="M236.9,220L236.9,220h0.1H236.9z M237,220h-0.1H237z"/>
<path className="st16" d="M236.8,220.2v-0.1V220.2z M236.9,220.1l-0.1,0.1v-0.1H236.9z"/>
<path className="st17" d="M236.8,220.2v-0.1V220.2z M236.9,220.1l-0.1,0.1v-0.1H236.9z"/>
<path className="st16" d="M236.9,220.1V220l-0.1,0.1H236.9z M236.9,220.1L236.9,220.1V220V220.1z"/>
<path className="st17" d="M236.9,220.1V220l-0.1,0.1H236.9z M236.9,220.1L236.9,220.1V220V220.1z"/>
<path className="st16" d="M236.9,220L236.9,220h0.1H236.9z M237,220h-0.1H237z"/>
<path className="st17" d="M236.9,220L236.9,220h0.1H236.9z M237,220h-0.1H237z"/>
<path className="st16" d="M236.8,220.2L236.8,220.2l0.1-0.1L236.8,220.2z M236.9,220.1l-0.1,0.1L236.9,220.1z"/>
<path className="st17" d="M236.8,220.2L236.8,220.2l0.1-0.1L236.8,220.2z M236.9,220.1l-0.1,0.1L236.9,220.1z"/>
<path className="st16" d="M236.9,220.1L236.9,220.1l0.1-0.1L236.9,220.1z M237,220l-0.1,0.1L237,220z"/>
<path className="st17" d="M236.9,220.1L236.9,220.1l0.1-0.1L236.9,220.1z M237,220l-0.1,0.1L237,220z"/>
<path className="st16" d="M235.6,221.6l1.1-1.3l-1.2,1.2L235.6,221.6z M236.9,220.4l-1.3,1.2l1.1-1.3L236.9,220.4z"/>
<path className="st17" d="M235.6,221.6l1.1-1.3l-1.2,1.2L235.6,221.6z M236.9,220.4l-1.3,1.2l1.1-1.3L236.9,220.4z"/>
<path className="st16" d="M236.9,220.4l0.1-0.4l-0.2,0.2L236.9,220.4z M237.1,220.1l-0.2,0.2l0.1-0.4L237.1,220.1z"/>
<path className="st17" d="M236.9,220.4l0.1-0.4l-0.2,0.2L236.9,220.4z M237.1,220.1l-0.2,0.2l0.1-0.4L237.1,220.1z"/>
<path className="st16" d="M237.1,220.1l1.1-1.4l-1.2,1.2L237.1,220.1z M238.4,218.8l-1.3,1.3l1.1-1.4L238.4,218.8z"/>
<path className="st17" d="M237.1,220.1l1.1-1.4l-1.2,1.2L237.1,220.1z M238.4,218.8l-1.3,1.3l1.1-1.4L238.4,218.8z"/>
<path className="st16" d="M237.1,220.6v-0.5l-0.2,0.2L237.1,220.6z M237.4,220.4l-0.2,0.2v-0.5L237.4,220.4z"/>
<path className="st17" d="M237.1,220.6v-0.5l-0.2,0.2L237.1,220.6z M237.4,220.4l-0.2,0.2v-0.5L237.4,220.4z"/>
<polygon className="st16" points="237.4,220.5 237.5,220.5 237.2,220.7 "/>
<polygon className="st17" points="237.4,220.5 237.5,220.5 237.2,220.7 "/>
<path className="st16" d="M237.4,220.8l0.1-0.4l-0.2,0.2L237.4,220.8z M237.6,220.5l-0.2,0.4l0.1-0.4H237.6z"/>
<path className="st17" d="M237.4,220.8l0.1-0.4l-0.2,0.2L237.4,220.8z M237.6,220.5l-0.2,0.4l0.1-0.4H237.6z"/>
<path className="st16" d="M237.6,220.7l-0.1,0.1h0.1V220.7z M237.6,220.5v0.2l-0.1,0.1L237.6,220.5z"/>
<path className="st17" d="M237.6,220.7l-0.1,0.1h0.1V220.7z M237.6,220.5v0.2l-0.1,0.1L237.6,220.5z"/>
<polygon className="st16" points="237.6,220.5 237.4,220.8 237.5,220.8 "/>
<polygon className="st17" points="237.6,220.5 237.4,220.8 237.5,220.8 "/>
<path className="st16" d="M236.3,222.4l-0.5-1l-0.2,0.1L236.3,222.4z M236.5,222.2l-0.2,0.2l-0.5-1L236.5,222.2z"/>
<path className="st17" d="M236.3,222.4l-0.5-1l-0.2,0.1L236.3,222.4z M236.5,222.2l-0.2,0.2l-0.5-1L236.5,222.2z"/>
<path className="st16" d="M238.9,219.8l-0.5-1l-0.2,0.2L238.9,219.8z M239.2,219.5l-0.2,0.2l-0.5-1L239.2,219.5z"/>
<path className="st17" d="M238.9,219.8l-0.5-1l-0.2,0.2L238.9,219.8z M239.2,219.5l-0.2,0.2l-0.5-1L239.2,219.5z"/>
<path className="st16" d="M236.8,222.6l-0.2-0.5l-0.2,0.2L236.8,222.6z M237,222.3l-0.2,0.4l-0.2-0.5L237,222.3z"/>
<path className="st17" d="M236.8,222.6l-0.2-0.5l-0.2,0.2L236.8,222.6z M237,222.3l-0.2,0.4l-0.2-0.5L237,222.3z"/>
<path className="st16" d="M239,220.2l0.1-0.7l-0.2,0.2L239,220.2z M239.4,220l-0.4,0.2l0.1-0.7L239.4,220z"/>
<path className="st17" d="M239,220.2l0.1-0.7l-0.2,0.2L239,220.2z M239.4,220l-0.4,0.2l0.1-0.7L239.4,220z"/>
<path className="st16" d="M236.8,222.6l0.2-0.4L236.8,222.6z M237.1,222.3l-0.4,0.4l0.2-0.4H237.1z"/>
<path className="st17" d="M236.8,222.6l0.2-0.4L236.8,222.6z M237.1,222.3l-0.4,0.4l0.2-0.4H237.1z"/>
<path className="st16" d="M239,220.4l0.4-0.4l-0.4,0.2V220.4z M239,220.4L239,220.4l0.4-0.4L239,220.4z"/>
<path className="st17" d="M239,220.4l0.4-0.4l-0.4,0.2V220.4z M239,220.4L239,220.4l0.4-0.4L239,220.4z"/>
<path className="st16" d="M237,222.6l0.1-0.4l-0.4,0.4H237z M237.5,222.2l-0.5,0.5l0.1-0.4L237.5,222.2z"/>
<path className="st17" d="M237,222.6l0.1-0.4l-0.4,0.4H237z M237.5,222.2l-0.5,0.5l0.1-0.4L237.5,222.2z"/>
<path className="st16" d="M238.9,220.7l0.5-0.7l-0.4,0.4L238.9,220.7z M239.4,220.2l-0.5,0.5l0.5-0.7V220.2z"/>
<path className="st17" d="M238.9,220.7l0.5-0.7l-0.4,0.4L238.9,220.7z M239.4,220.2l-0.5,0.5l0.5-0.7V220.2z"/>
<path className="st16" d="M237.2,222.6l2.2-2.4l-2.4,2.4H237.2z M239.4,220.5l-2.2,2.2l2.2-2.4V220.5z"/>
<path className="st17" d="M237.2,222.6l2.2-2.4l-2.4,2.4H237.2z M239.4,220.5l-2.2,2.2l2.2-2.4V220.5z"/>
<path className="st16" d="M237.7,222.4l1.7-1.9l-2.2,2.2L237.7,222.4z M239.2,221l-1.4,1.4l1.7-1.9L239.2,221z"/>
<path className="st17" d="M237.7,222.4l1.7-1.9l-2.2,2.2L237.7,222.4z M239.2,221l-1.4,1.4l1.7-1.9L239.2,221z"/>
<line className="st18" x1="235.8" y1="221.4" x2="236.9" y2="220.4"/>
<line className="st18" x1="237.5" y1="222.2" x2="238.9" y2="220.7"/>
<line className="st18" x1="236.5" y1="222.2" x2="235.8" y2="221.4"/>
<line className="st18" x1="236.2" y1="221.1" x2="236.9" y2="220.4"/>
<line className="st18" x1="237.7" y1="222.4" x2="239.2" y2="221"/>
<line className="st18" x1="236.3" y1="222.4" x2="235" y2="221"/>
<line className="st18" x1="237.1" y1="220.6" x2="237.4" y2="220.4"/>
<path className="st18" d="M236.8,220.1c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C236.6,220.1,236.8,220.1,236.8,220.1"/>
<line className="st18" x1="237.1" y1="220.6" x2="236.6" y2="220.1"/>
<line className="st18" x1="237.4" y1="220.4" x2="236.9" y2="219.9"/>
<path className="st18" d="M237.5,220.8c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3
	C237.4,220.9,237.5,220.9,237.5,220.8"/>
<polyline className="st18" points="237.1,220.1 238.2,219 238.9,219.8 "/>
<polyline className="st18" points="239.2,219.5 237.7,218.2 235,221 "/>
<path className="st18" d="M236.5,222.1c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M238.8,220.6c0.3-0.3,0.3-0.7,0-0.9"/>
<path className="st18" d="M236.2,222.3c0.4,0.4,1,0.4,1.4,0"/>
<path className="st18" d="M239.1,220.9c0.4-0.4,0.4-1,0-1.4"/>
<polyline className="st3" points="19.1,178 19.6,178.5 28,170.1 19.1,178 "/>
<polyline className="st3" points="19.1,178 19.6,178.5 28,170.1 19.1,178 "/>
<polyline className="st3" points="19.1,178 19.6,178.5 28,170.1 19.1,178 "/>
<polyline className="st3" points="19.1,178 27.5,169.6 28,170.1 19.1,178 "/>
<polyline className="st3" points="19.1,178 27.5,169.6 28,170.1 19.1,178 "/>
<polyline className="st3" points="19.1,178 27.5,169.6 28,170.1 19.1,178 "/>
<polyline className="st14" points="19.6,178.5 28,170.1 27.5,169.6 19.1,178 19.6,178.5 "/>
<polyline className="st3" points="36.6,160.6 37.1,161.1 45.5,152.7 36.6,160.6 "/>
<line className="st4" x1="25.4" y1="116.2" x2="0" y2="141.6"/>
<polyline className="st3" points="36.6,160.6 37.1,161.1 45.5,152.7 36.6,160.6 "/>
<line className="st13" x1="50.9" y1="141.6" x2="25.4" y2="167.1"/>
<line className="st13" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="36.6,160.6 37.1,161.1 45.5,152.7 36.6,160.6 "/>
<polyline className="st3" points="36.6,160.6 45,152.2 45.5,152.7 36.6,160.6 "/>
<line className="st4" x1="25.4" y1="116.2" x2="0" y2="141.6"/>
<polyline className="st3" points="36.6,160.6 45,152.2 45.5,152.7 36.6,160.6 "/>
<line className="st13" x1="50.9" y1="141.6" x2="25.4" y2="167.1"/>
<line className="st13" x1="76.3" y1="167.1" x2="50.9" y2="192.5"/>
<polyline className="st3" points="36.6,160.6 45,152.2 45.5,152.7 36.6,160.6 "/>
<polyline className="st14" points="37.1,161.1 45.5,152.7 45,152.2 36.6,160.6 37.1,161.1 "/>
<polyline className="st3" points="54.1,143.1 54.6,143.6 63,135.2 54.1,143.1 "/>
<line className="st4" x1="50.9" y1="90.8" x2="25.4" y2="116.2"/>
<polyline className="st3" points="54.1,143.1 54.6,143.6 63,135.2 54.1,143.1 "/>
<line className="st13" x1="76.3" y1="116.2" x2="50.9" y2="141.6"/>
<line className="st13" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="54.1,143.1 54.6,143.6 63,135.2 54.1,143.1 "/>
<polyline className="st3" points="54.1,143.1 62.5,134.7 63,135.2 54.1,143.1 "/>
<line className="st4" x1="50.9" y1="90.8" x2="25.4" y2="116.2"/>
<polyline className="st3" points="54.1,143.1 62.5,134.7 63,135.2 54.1,143.1 "/>
<line className="st13" x1="76.3" y1="116.2" x2="50.9" y2="141.6"/>
<line className="st13" x1="101.8" y1="141.6" x2="76.3" y2="167.1"/>
<polyline className="st3" points="54.1,143.1 62.5,134.7 63,135.2 54.1,143.1 "/>
<polyline className="st14" points="54.6,143.6 63,135.2 62.5,134.7 54.1,143.1 54.6,143.6 "/>
<polyline className="st3" points="71.6,125.6 72.1,126 80.5,117.6 71.6,125.6 "/>
<line className="st4" x1="76.3" y1="65.3" x2="25.4" y2="116.2"/>
<polyline className="st3" points="71.6,125.6 72.1,126 80.5,117.6 71.6,125.6 "/>
<line className="st13" x1="101.8" y1="90.8" x2="50.9" y2="141.6"/>
<line className="st13" x1="127.2" y1="116.2" x2="76.3" y2="167.1"/>
<polyline className="st3" points="71.6,125.6 72.1,126 80.5,117.6 71.6,125.6 "/>
<polyline className="st3" points="71.6,125.6 80,117.2 80.5,117.6 71.6,125.6 "/>
<line className="st4" x1="76.3" y1="65.3" x2="25.4" y2="116.2"/>
<polyline className="st3" points="71.6,125.6 80,117.2 80.5,117.6 71.6,125.6 "/>
<line className="st13" x1="101.8" y1="90.8" x2="50.9" y2="141.6"/>
<line className="st13" x1="127.2" y1="116.2" x2="76.3" y2="167.1"/>
<polyline className="st3" points="71.6,125.6 80,117.2 80.5,117.6 71.6,125.6 "/>
<polyline className="st14" points="72.1,126 80.5,117.6 80,117.2 71.6,125.6 72.1,126 "/>
<polyline className="st3" points="188.2,257.4 188.6,257 180.2,248.6 188.2,257.4 "/>
<line className="st4" x1="178.1" y1="218.1" x2="152.6" y2="243.5"/>
<polyline className="st3" points="188.2,257.4 188.6,257 180.2,248.6 188.2,257.4 "/>
<line className="st13" x1="203.6" y1="243.5" x2="178.1" y2="269"/>
<polyline className="st3" points="188.2,257.4 188.6,257 180.2,248.6 188.2,257.4 "/>
<polyline className="st3" points="188.2,257.4 179.8,249 180.2,248.6 188.2,257.4 "/>
<line className="st4" x1="178.1" y1="218.1" x2="152.6" y2="243.5"/>
<polyline className="st3" points="188.2,257.4 179.8,249 180.2,248.6 188.2,257.4 "/>
<line className="st13" x1="203.6" y1="243.5" x2="178.1" y2="269"/>
<polyline className="st3" points="188.2,257.4 179.8,249 180.2,248.6 188.2,257.4 "/>
<polyline className="st14" points="188.6,257 180.2,248.6 179.8,249 188.2,257.4 188.6,257 "/>
<polyline className="st3" points="205.7,275 206.2,274.5 197.8,266.1 205.7,275 "/>
<line className="st4" x1="203.6" y1="243.5" x2="178.1" y2="269"/>
<polyline className="st3" points="205.7,275 206.2,274.5 197.8,266.1 205.7,275 "/>
<line className="st13" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="205.7,275 206.2,274.5 197.8,266.1 205.7,275 "/>
<polyline className="st3" points="205.7,275 197.3,266.6 197.8,266.1 205.7,275 "/>
<line className="st4" x1="203.6" y1="243.5" x2="178.1" y2="269"/>
<polyline className="st3" points="205.7,275 197.3,266.6 197.8,266.1 205.7,275 "/>
<line className="st13" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="205.7,275 197.3,266.6 197.8,266.1 205.7,275 "/>
<polyline className="st14" points="206.2,274.5 197.8,266.1 197.3,266.6 205.7,275 206.2,274.5 "/>
<polyline className="st3" points="223.2,292.5 223.7,292 215.3,283.6 223.2,292.5 "/>
<line className="st4" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="223.2,292.5 223.7,292 215.3,283.6 223.2,292.5 "/>
<line className="st13" x1="254.5" y1="294.4" x2="229.1" y2="319.8"/>
<polyline className="st3" points="223.2,292.5 223.7,292 215.3,283.6 223.2,292.5 "/>
<polyline className="st3" points="223.2,292.5 214.8,284.1 215.3,283.6 223.2,292.5 "/>
<line className="st4" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="223.2,292.5 214.8,284.1 215.3,283.6 223.2,292.5 "/>
<line className="st13" x1="254.5" y1="294.4" x2="229.1" y2="319.8"/>
<polyline className="st3" points="223.2,292.5 214.8,284.1 215.3,283.6 223.2,292.5 "/>
<polyline className="st14" points="223.7,292 215.3,283.6 214.8,284.1 223.2,292.5 223.7,292 "/>
<polyline className="st3" points="240.7,309.9 241.1,309.5 232.7,301.1 240.7,309.9 "/>
<line className="st4" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="240.7,309.9 241.1,309.5 232.7,301.1 240.7,309.9 "/>
<line className="st13" x1="254.5" y1="294.4" x2="229.1" y2="319.8"/>
<line className="st13" x1="280" y1="319.8" x2="254.5" y2="345.3"/>
<polyline className="st3" points="240.7,309.9 241.1,309.5 232.7,301.1 240.7,309.9 "/>
<polyline className="st3" points="240.7,309.9 232.3,301.5 232.7,301.1 240.7,309.9 "/>
<line className="st4" x1="229.1" y1="269" x2="203.6" y2="294.4"/>
<polyline className="st3" points="240.7,309.9 232.3,301.5 232.7,301.1 240.7,309.9 "/>
<line className="st13" x1="254.5" y1="294.4" x2="229.1" y2="319.8"/>
<line className="st13" x1="280" y1="319.8" x2="254.5" y2="345.3"/>
<polyline className="st3" points="240.7,309.9 232.3,301.5 232.7,301.1 240.7,309.9 "/>
<polyline className="st14" points="241.1,309.5 232.7,301.1 232.3,301.5 240.7,309.9 241.1,309.5 "/>
<polyline className="st3" points="249,336.6 249.4,336.2 241,327.8 249,336.6 "/>
<line className="st4" x1="254.5" y1="294.4" x2="203.6" y2="345.3"/>
<polyline className="st3" points="249,336.6 249.4,336.2 241,327.8 249,336.6 "/>
<line className="st13" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="249,336.6 249.4,336.2 241,327.8 249,336.6 "/>
<polyline className="st3" points="249,336.6 240.6,328.2 241,327.8 249,336.6 "/>
<line className="st4" x1="254.5" y1="294.4" x2="203.6" y2="345.3"/>
<polyline className="st3" points="249,336.6 240.6,328.2 241,327.8 249,336.6 "/>
<line className="st13" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="249,336.6 240.6,328.2 241,327.8 249,336.6 "/>
<polyline className="st14" points="249.4,336.2 241,327.8 240.6,328.2 249,336.6 249.4,336.2 "/>
<polyline className="st3" points="266.4,354.2 266.9,353.7 258.5,345.3 266.4,354.2 "/>
<line className="st4" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="266.4,354.2 266.9,353.7 258.5,345.3 266.4,354.2 "/>
<line className="st13" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<polyline className="st3" points="266.4,354.2 266.9,353.7 258.5,345.3 266.4,354.2 "/>
<polyline className="st3" points="266.4,354.2 258,345.8 258.5,345.3 266.4,354.2 "/>
<line className="st4" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="266.4,354.2 258,345.8 258.5,345.3 266.4,354.2 "/>
<line className="st13" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<polyline className="st3" points="266.4,354.2 258,345.8 258.5,345.3 266.4,354.2 "/>
<polyline className="st14" points="266.9,353.7 258.5,345.3 258,345.8 266.4,354.2 266.9,353.7 "/>
<polyline className="st3" points="283.9,371.7 284.4,371.2 276,362.8 283.9,371.7 "/>
<line className="st4" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="283.9,371.7 284.4,371.2 276,362.8 283.9,371.7 "/>
<line className="st13" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<line className="st13" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="283.9,371.7 284.4,371.2 276,362.8 283.9,371.7 "/>
<polyline className="st3" points="283.9,371.7 275.5,363.3 276,362.8 283.9,371.7 "/>
<line className="st4" x1="280" y1="319.8" x2="229.1" y2="370.7"/>
<polyline className="st3" points="283.9,371.7 275.5,363.3 276,362.8 283.9,371.7 "/>
<line className="st13" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<line className="st13" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="283.9,371.7 275.5,363.3 276,362.8 283.9,371.7 "/>
<polyline className="st14" points="284.4,371.2 276,362.8 275.5,363.3 283.9,371.7 284.4,371.2 "/>
<polyline className="st3" points="301.6,389.1 302,388.6 293.6,380.2 301.6,389.1 "/>
<line className="st4" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<polyline className="st3" points="301.6,389.1 302,388.6 293.6,380.2 301.6,389.1 "/>
<line className="st13" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="301.6,389.1 302,388.6 293.6,380.2 301.6,389.1 "/>
<polyline className="st3" points="301.6,389.1 293.2,380.7 293.6,380.2 301.6,389.1 "/>
<line className="st4" x1="305.4" y1="345.3" x2="254.5" y2="396.2"/>
<polyline className="st3" points="301.6,389.1 293.2,380.7 293.6,380.2 301.6,389.1 "/>
<line className="st13" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="301.6,389.1 293.2,380.7 293.6,380.2 301.6,389.1 "/>
<polyline className="st14" points="302,388.6 293.6,380.2 293.2,380.7 301.6,389.1 302,388.6 "/>
<polyline className="st3" points="319,406.6 319.4,406.1 311,397.7 319,406.6 "/>
<line className="st4" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="319,406.6 319.4,406.1 311,397.7 319,406.6 "/>
<line className="st13" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<polyline className="st3" points="319,406.6 319.4,406.1 311,397.7 319,406.6 "/>
<polyline className="st3" points="319,406.6 310.6,398.2 311,397.7 319,406.6 "/>
<line className="st4" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="319,406.6 310.6,398.2 311,397.7 319,406.6 "/>
<line className="st13" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<polyline className="st3" points="319,406.6 310.6,398.2 311,397.7 319,406.6 "/>
<polyline className="st14" points="319.4,406.1 311,397.7 310.6,398.2 319,406.6 319.4,406.1 "/>
<polyline className="st3" points="336.4,424.1 336.8,423.6 328.4,415.2 336.4,424.1 "/>
<line className="st4" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="336.4,424.1 336.8,423.6 328.4,415.2 336.4,424.1 "/>
<line className="st13" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<line className="st13" x1="381.8" y1="421.7" x2="330.8" y2="472.6"/>
<polyline className="st3" points="336.4,424.1 336.8,423.6 328.4,415.2 336.4,424.1 "/>
<polyline className="st3" points="336.4,424.1 328,415.7 328.4,415.2 336.4,424.1 "/>
<line className="st4" x1="330.8" y1="370.7" x2="280" y2="421.7"/>
<polyline className="st3" points="336.4,424.1 328,415.7 328.4,415.2 336.4,424.1 "/>
<line className="st13" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<line className="st13" x1="381.8" y1="421.7" x2="330.8" y2="472.6"/>
<polyline className="st3" points="336.4,424.1 328,415.7 328.4,415.2 336.4,424.1 "/>
<polyline className="st14" points="336.8,423.6 328.4,415.2 328,415.7 336.4,424.1 336.8,423.6 "/>
<polyline className="st3" points="353.9,441.6 354.4,441.2 346,432.8 353.9,441.6 "/>
<line className="st4" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<polyline className="st3" points="353.9,441.6 354.4,441.2 346,432.8 353.9,441.6 "/>
<line className="st13" x1="381.8" y1="421.7" x2="330.8" y2="472.6"/>
<polyline className="st3" points="353.9,441.6 354.4,441.2 346,432.8 353.9,441.6 "/>
<polyline className="st3" points="353.9,441.6 345.5,433.2 346,432.8 353.9,441.6 "/>
<line className="st4" x1="356.3" y1="396.2" x2="305.4" y2="447.2"/>
<polyline className="st3" points="353.9,441.6 345.5,433.2 346,432.8 353.9,441.6 "/>
<line className="st13" x1="381.8" y1="421.7" x2="330.8" y2="472.6"/>
<polyline className="st3" points="353.9,441.6 345.5,433.2 346,432.8 353.9,441.6 "/>
<polyline className="st14" points="354.4,441.2 346,432.8 345.5,433.2 353.9,441.6 354.4,441.2 "/>
<line className="st5" x1="338.9" y1="363.2" x2="338.8" y2="363"/>
<polyline className="st5" points="338.8,363 333.1,368.6 333.4,368.8 338.9,363.2 "/>
<path className="st5" d="M333.3,368.7c3.1,2.9,7.9,2.8,10.9-0.2"/>
<path className="st5" d="M347.1,376.9c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="338.9,376.8 338.8,377 343,381.2 343.1,381 338.9,376.8 "/>
<line className="st5" x1="336.5" y1="360.8" x2="336.6" y2="360.9"/>
<polyline className="st5" points="336.6,360.9 331.1,366.5 330.8,366.3 336.5,360.8 "/>
<path className="st5" d="M331,355.3c-3,3-3.1,7.8-0.2,10.9"/>
<line className="st5" x1="304" y1="328.2" x2="303.7" y2="328"/>
<polyline className="st5" points="303.7,328 298.1,333.6 298.3,333.8 304,328.2 "/>
<path className="st5" d="M298.3,333.7c3.1,2.9,7.9,2.8,10.9-0.2"/>
<path className="st5" d="M322.8,360.6c2.1-2.3,2.1-6-0.2-8.2"/>
<polyline className="st5" points="322.8,360.8 322.7,360.9 318.5,356.7 318.6,356.6 322.8,360.8 "/>
<path className="st5" d="M312.1,342c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="304,341.8 303.7,342 307.9,346.2 308.2,346 304,341.8 "/>
<line className="st5" x1="301.4" y1="325.7" x2="301.7" y2="326"/>
<polyline className="st5" points="301.7,326 296,331.5 295.8,331.4 301.4,325.7 "/>
<path className="st5" d="M296.1,320.3c-3,3-3.1,7.8-0.2,10.9"/>
<line className="st5" x1="268.9" y1="293.2" x2="268.8" y2="293.1"/>
<polyline className="st5" points="268.8,293.1 263.2,298.6 263.3,298.8 268.9,293.2 "/>
<path className="st5" d="M263.4,298.8c3.1,2.9,7.9,2.8,10.9-0.2"/>
<path className="st5" d="M287.8,325.7c2.1-2.3,2.1-6-0.2-8.2"/>
<polyline className="st5" points="287.9,325.7 287.6,326 283.4,321.8 283.7,321.5 287.9,325.7 "/>
<path className="st16" d="M280.9,306.6l1.7,1.7l-1.6-1.7H280.9z M280.9,306.6L280.9,306.6l1.7,1.7L280.9,306.6z M282.6,308.3l-1.7-1.7
	L282.6,308.3z M280.9,306.6l1.7,1.7L280.9,306.6z M282.6,308.4l-1.7-1.8l1.7,1.7V308.4z"/>
<path className="st17" d="M280.9,306.6l1.7,1.7l-1.6-1.7H280.9z M280.9,306.6L280.9,306.6l1.7,1.7L280.9,306.6z M282.6,308.3l-1.7-1.7
	L282.6,308.3z M280.9,306.6l1.7,1.7L280.9,306.6z M282.6,308.4l-1.7-1.8l1.7,1.7V308.4z"/>
<path className="st16" d="M280.9,306.6l1.7,1.8L280.9,306.6z M280.9,306.6L280.9,306.6l1.7,1.8L280.9,306.6z M282.6,308.3l-1.7-1.7
	L282.6,308.3z"/>
<path className="st17" d="M280.9,306.6l1.7,1.8L280.9,306.6z M280.9,306.6L280.9,306.6l1.7,1.8L280.9,306.6z M282.6,308.3l-1.7-1.7
	L282.6,308.3z"/>
<path className="st16" d="M279.8,307.7l2.8,0.7l-1.7-1.8L279.8,307.7z M281.5,309.5l-1.7-1.8l2.8,0.7L281.5,309.5z"/>
<path className="st17" d="M279.8,307.7l2.8,0.7l-1.7-1.8L279.8,307.7z M281.5,309.5l-1.7-1.8l2.8,0.7L281.5,309.5z"/>
<path className="st16" d="M279.6,308l1.1,0.7l-0.8-1L279.6,308z M280.1,308.3l-0.5-0.4l1.1,0.7L280.1,308.3z"/>
<path className="st17" d="M279.6,308l1.1,0.7l-0.8-1L279.6,308z M280.1,308.3l-0.5-0.4l1.1,0.7L280.1,308.3z"/>
<path className="st16" d="M280.9,309.3l0.6,0.2l-0.8-0.8L280.9,309.3z M281.4,309.6l-0.5-0.4l0.6,0.2L281.4,309.6z"/>
<path className="st17" d="M280.9,309.3l0.6,0.2l-0.8-0.8L280.9,309.3z M281.4,309.6l-0.5-0.4l0.6,0.2L281.4,309.6z"/>
<path className="st16" d="M279.6,308L279.6,308l0.5,0.4L279.6,308z M280.1,308.3l-0.5-0.4L280.1,308.3z"/>
<path className="st17" d="M279.6,308L279.6,308l0.5,0.4L279.6,308z M280.1,308.3l-0.5-0.4L280.1,308.3z"/>
<path className="st16" d="M280.2,308.8l0.4,0.2l-0.2-0.2H280.2z M280.2,308.8L280.2,308.8l0.4,0.2L280.2,308.8z M280.6,309l-0.4-0.2
	L280.6,309z M280.2,308.8l0.4,0.2L280.2,308.8z M280.6,309.2l-0.4-0.4l0.4,0.2V309.2z"/>
<path className="st17" d="M280.2,308.8l0.4,0.2l-0.2-0.2H280.2z M280.2,308.8L280.2,308.8l0.4,0.2L280.2,308.8z M280.6,309l-0.4-0.2
	L280.6,309z M280.2,308.8l0.4,0.2L280.2,308.8z M280.6,309.2l-0.4-0.4l0.4,0.2V309.2z"/>
<path className="st16" d="M280.2,308.8l0.4,0.4L280.2,308.8z M280.2,308.8L280.2,308.8l0.4,0.4L280.2,308.8z M280.6,309l-0.4-0.2
	L280.6,309z"/>
<path className="st17" d="M280.2,308.8l0.4,0.4L280.2,308.8z M280.2,308.8L280.2,308.8l0.4,0.4L280.2,308.8z M280.6,309l-0.4-0.2
	L280.6,309z"/>
<path className="st16" d="M280.3,309.3l-0.4-0.4l0.1,0.2L280.3,309.3z M280.6,309.2l-0.2,0.1l-0.4-0.4L280.6,309.2z"/>
<path className="st17" d="M280.3,309.3l-0.4-0.4l0.1,0.2L280.3,309.3z M280.6,309.2l-0.2,0.1l-0.4-0.4L280.6,309.2z"/>
<polygon className="st16" points="280.6,309.2 280.2,308.8 280,308.9 "/>
<polygon className="st17" points="280.6,309.2 280.2,308.8 280,308.9 "/>
<path className="st16" d="M280.7,309.9l0.7-0.2l-0.5-0.4L280.7,309.9z M280.9,310.1l-0.2-0.2l0.7-0.2L280.9,310.1z"/>
<path className="st17" d="M280.7,309.9l0.7-0.2l-0.5-0.4L280.7,309.9z M280.9,310.1l-0.2-0.2l0.7-0.2L280.9,310.1z"/>
<path className="st16" d="M279.2,308.3h0.8l-0.5-0.4L279.2,308.3z M279.5,308.6l-0.2-0.2h0.8L279.5,308.6z"/>
<path className="st17" d="M279.2,308.3h0.8l-0.5-0.4L279.2,308.3z M279.5,308.6l-0.2-0.2h0.8L279.5,308.6z"/>
<path className="st16" d="M278.9,308.7l0.6-0.1l-0.2-0.2L278.9,308.7z M279.1,308.9l-0.2-0.2l0.6-0.1L279.1,308.9z"/>
<path className="st17" d="M278.9,308.7l0.6-0.1l-0.2-0.2L278.9,308.7z M279.1,308.9l-0.2-0.2l0.6-0.1L279.1,308.9z"/>
<path className="st16" d="M280.4,310.2l0.5-0.1l-0.2-0.2L280.4,310.2z M280.6,310.5l-0.1-0.2l0.5-0.1L280.6,310.5z"/>
<path className="st17" d="M280.4,310.2l0.5-0.1l-0.2-0.2L280.4,310.2z M280.6,310.5l-0.1-0.2l0.5-0.1L280.6,310.5z"/>
<path className="st16" d="M279.8,310.5h0.7l-0.1-0.2L279.8,310.5z M280.1,310.7l-0.2-0.2h0.7L280.1,310.7z"/>
<path className="st17" d="M279.8,310.5h0.7l-0.1-0.2L279.8,310.5z M280.1,310.7l-0.2-0.2h0.7L280.1,310.7z"/>
<path className="st16" d="M278.5,309.3l0.6-0.4l-0.2-0.2L278.5,309.3z M278.9,309.5l-0.4-0.2l0.6-0.4L278.9,309.5z M278.5,309.3l0.4,0.2
	L278.5,309.3z M278.5,309.3L278.5,309.3l0.4,0.2L278.5,309.3z"/>
<path className="st17" d="M278.5,309.3l0.6-0.4l-0.2-0.2L278.5,309.3z M278.9,309.5l-0.4-0.2l0.6-0.4L278.9,309.5z M278.5,309.3l0.4,0.2
	L278.5,309.3z M278.5,309.3L278.5,309.3l0.4,0.2L278.5,309.3z"/>
<path className="st16" d="M279.7,310.5l0.4,0.2l-0.2-0.2H279.7z M280,310.7l-0.2-0.2l0.4,0.2H280z"/>
<path className="st17" d="M279.7,310.5l0.4,0.2l-0.2-0.2H279.7z M280,310.7l-0.2-0.2l0.4,0.2H280z"/>
<path className="st16" d="M278.6,309.6l0.2-0.1l-0.4-0.2L278.6,309.6z M279.1,310.2l-0.5-0.6l0.2-0.1L279.1,310.2z"/>
<path className="st17" d="M278.6,309.6l0.2-0.1l-0.4-0.2L278.6,309.6z M279.1,310.2l-0.5-0.6l0.2-0.1L279.1,310.2z"/>
<path className="st16" d="M279.1,310.2l0.8,0.5l-0.2-0.2L279.1,310.2z M279.6,310.7l-0.5-0.5l0.8,0.5H279.6z"/>
<path className="st17" d="M279.1,310.2l0.8,0.5l-0.2-0.2L279.1,310.2z M279.6,310.7l-0.5-0.5l0.8,0.5H279.6z"/>
<path className="st16" d="M278.6,309.9l1,0.8l-1-1.1V309.9z M279.5,310.7l-0.8-0.8l1,0.8H279.5z M279.4,310.7h0.1l-0.8-0.8L279.4,310.7z
	"/>
<path className="st17" d="M278.6,309.9l1,0.8l-1-1.1V309.9z M279.5,310.7l-0.8-0.8l1,0.8H279.5z M279.4,310.7h0.1l-0.8-0.8L279.4,310.7z
	"/>
<path className="st16" d="M279.5,310.7l-0.8-0.8L279.5,310.7z"/>
<path className="st17" d="M279.5,310.7l-0.8-0.8L279.5,310.7z"/>
<path className="st16" d="M279.2,310.6l-0.6-0.6l0.4,0.5L279.2,310.6z M279.4,310.7l-0.1-0.1l-0.6-0.6L279.4,310.7z"/>
<path className="st17" d="M279.2,310.6l-0.6-0.6l0.4,0.5L279.2,310.6z M279.4,310.7l-0.1-0.1l-0.6-0.6L279.4,310.7z"/>
<polygon className="st16" points="279.4,310.7 278.6,309.9 278.6,310 "/>
<polygon className="st17" points="279.4,310.7 278.6,309.9 278.6,310 "/>
<path className="st19" d="M280.9,306.7C280.9,306.6,280.8,306.6,280.9,306.7"/>
<line className="st19" x1="281" y1="306.6" x2="282.6" y2="308.3"/>
<path className="st19" d="M282.6,308.3C282.6,308.3,282.6,308.2,282.6,308.3"/>
<line className="st19" x1="282.6" y1="308.4" x2="280.6" y2="310.5"/>
<path className="st19" d="M278.8,308.6c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="278.9" y1="308.7" x2="280.9" y2="306.6"/>
<path className="st19" d="M280.2,308.7C280.2,308.7,280.1,308.7,280.2,308.7"/>
<line className="st19" x1="280.2" y1="308.8" x2="280" y2="308.9"/>
<path className="st19" d="M279.9,308.9c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="280.3" y1="309.3" x2="280.6" y2="309.2"/>
<path className="st19" d="M280.5,309C280.6,309,280.6,309,280.5,309"/>
<line className="st19" x1="280.6" y1="309" x2="280.3" y2="308.8"/>
<line className="st19" x1="280.7" y1="309.9" x2="280.4" y2="310.2"/>
<path className="st19" d="M279,308.8c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="279.1" y1="308.9" x2="279.5" y2="308.6"/>
<path className="st19" d="M280.7,309.8c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<path className="st5" d="M277.1,306.9c-2.2-2.2-5.9-2.3-8.2-0.2"/>
<polyline className="st5" points="268.9,306.9 268.8,307 273,311.2 273.1,311.1 268.9,306.9 "/>
<line className="st1" x1="283.9" y1="309.6" x2="279.7" y2="305.4"/>
<path className="st16" d="M286.3,312L286.3,312l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z M286.3,312l1.7,1.7L286.3,312z
	 M286.3,312L286.3,312l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z M286.3,312l1.7,1.7L286.3,312z M286.3,312L286.3,312
	l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z"/>
<path className="st17" d="M286.3,312L286.3,312l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z M286.3,312l1.7,1.7L286.3,312z
	 M286.3,312L286.3,312l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z M286.3,312l1.7,1.7L286.3,312z M286.3,312L286.3,312
	l1.7,1.7L286.3,312z M288,313.7l-1.7-1.7L288,313.7z"/>
<path className="st16" d="M285.2,313.1l2.8,0.6l-1.7-1.7L285.2,313.1z M286.9,314.8l-1.7-1.7l2.8,0.6L286.9,314.8z"/>
<path className="st17" d="M285.2,313.1l2.8,0.6l-1.7-1.7L285.2,313.1z M286.9,314.8l-1.7-1.7l2.8,0.6L286.9,314.8z"/>
<path className="st16" d="M285,313.2l1.1,0.7l-0.8-0.8L285,313.2z M285.5,313.7l-0.5-0.5l1.1,0.7L285.5,313.7z"/>
<path className="st17" d="M285,313.2l1.1,0.7l-0.8-0.8L285,313.2z M285.5,313.7l-0.5-0.5l1.1,0.7L285.5,313.7z"/>
<path className="st16" d="M286.3,314.6l0.6,0.2l-0.8-0.8L286.3,314.6z M286.8,315l-0.5-0.5l0.6,0.2L286.8,315z"/>
<path className="st17" d="M286.3,314.6l0.6,0.2l-0.8-0.8L286.3,314.6z M286.8,315l-0.5-0.5l0.6,0.2L286.8,315z"/>
<path className="st16" d="M285,313.2L285,313.2l0.5,0.5L285,313.2z M285.5,313.7l-0.5-0.5L285.5,313.7z"/>
<path className="st17" d="M285,313.2L285,313.2l0.5,0.5L285,313.2z M285.5,313.7l-0.5-0.5L285.5,313.7z"/>
<path className="st16" d="M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z M285.6,314.1l0.4,0.4
	L285.6,314.1z M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z M285.6,314.1l0.4,0.4L285.6,314.1z
	 M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z"/>
<path className="st17" d="M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z M285.6,314.1l0.4,0.4
	L285.6,314.1z M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z M285.6,314.1l0.4,0.4L285.6,314.1z
	 M285.6,314.1L285.6,314.1l0.4,0.4L285.6,314.1z M286,314.4l-0.4-0.4L286,314.4z"/>
<path className="st16" d="M285.7,314.7l-0.4-0.4l0.1,0.2L285.7,314.7z M286,314.4l-0.2,0.2l-0.4-0.4L286,314.4z"/>
<path className="st17" d="M285.7,314.7l-0.4-0.4l0.1,0.2L285.7,314.7z M286,314.4l-0.2,0.2l-0.4-0.4L286,314.4z"/>
<polygon className="st16" points="286,314.4 285.6,314.1 285.4,314.3 "/>
<polygon className="st17" points="286,314.4 285.6,314.1 285.4,314.3 "/>
<path className="st16" d="M286.1,315.2l0.7-0.1l-0.5-0.5L286.1,315.2z M286.3,315.4l-0.2-0.2l0.7-0.1L286.3,315.4z"/>
<path className="st17" d="M286.1,315.2l0.7-0.1l-0.5-0.5L286.1,315.2z M286.3,315.4l-0.2-0.2l0.7-0.1L286.3,315.4z"/>
<path className="st16" d="M284.6,313.7h0.8l-0.5-0.5L284.6,313.7z M284.9,314l-0.2-0.2h0.8L284.9,314z"/>
<path className="st17" d="M284.6,313.7h0.8l-0.5-0.5L284.6,313.7z M284.9,314l-0.2-0.2h0.8L284.9,314z"/>
<path className="st16" d="M284.3,314.1l0.6-0.1l-0.2-0.2L284.3,314.1z M284.5,314.3l-0.2-0.2l0.6-0.1L284.5,314.3z"/>
<path className="st17" d="M284.3,314.1l0.6-0.1l-0.2-0.2L284.3,314.1z M284.5,314.3l-0.2-0.2l0.6-0.1L284.5,314.3z"/>
<path className="st16" d="M285.7,315.5l0.6-0.1l-0.2-0.2L285.7,315.5z M286,315.8l-0.2-0.2l0.6-0.1L286,315.8z"/>
<path className="st17" d="M285.7,315.5l0.6-0.1l-0.2-0.2L285.7,315.5z M286,315.8l-0.2-0.2l0.6-0.1L286,315.8z"/>
<path className="st16" d="M285.2,315.8h0.7l-0.2-0.2L285.2,315.8z M285.5,316.1l-0.2-0.4h0.7L285.5,316.1z"/>
<path className="st17" d="M285.2,315.8h0.7l-0.2-0.2L285.2,315.8z M285.5,316.1l-0.2-0.4h0.7L285.5,316.1z"/>
<path className="st16" d="M283.9,314.6l0.6-0.2l-0.2-0.2L283.9,314.6z M284.2,314.9l-0.2-0.4l0.6-0.2L284.2,314.9z"/>
<path className="st17" d="M283.9,314.6l0.6-0.2l-0.2-0.2L283.9,314.6z M284.2,314.9l-0.2-0.4l0.6-0.2L284.2,314.9z"/>
<path className="st16" d="M283.9,314.7l0.2,0.2l-0.2-0.4V314.7z M283.9,314.7L283.9,314.7l0.2,0.2L283.9,314.7z"/>
<path className="st17" d="M283.9,314.7l0.2,0.2l-0.2-0.4V314.7z M283.9,314.7L283.9,314.7l0.2,0.2L283.9,314.7z"/>
<path className="st16" d="M285.1,315.9l0.4,0.2l-0.2-0.4L285.1,315.9z M285.4,316.1l-0.2-0.2l0.4,0.2H285.4z"/>
<path className="st17" d="M285.1,315.9l0.4,0.2l-0.2-0.4L285.1,315.9z M285.4,316.1l-0.2-0.2l0.4,0.2H285.4z"/>
<path className="st16" d="M283.9,315l0.2-0.1l-0.2-0.2V315z M284.5,315.5l-0.6-0.5l0.2-0.1L284.5,315.5z"/>
<path className="st17" d="M283.9,315l0.2-0.1l-0.2-0.2V315z M284.5,315.5l-0.6-0.5l0.2-0.1L284.5,315.5z"/>
<path className="st16" d="M284.5,315.5l0.8,0.6l-0.2-0.2L284.5,315.5z M285,316.1l-0.5-0.6l0.8,0.6H285z"/>
<path className="st17" d="M284.5,315.5l0.8,0.6l-0.2-0.2L284.5,315.5z M285,316.1l-0.5-0.6l0.8,0.6H285z"/>
<path className="st16" d="M283.9,315.2l1.1,1l-1.1-1.1V315.2z M284.9,316.1l-1-1l1.1,1H284.9z"/>
<path className="st17" d="M283.9,315.2l1.1,1l-1.1-1.1V315.2z M284.9,316.1l-1-1l1.1,1H284.9z"/>
<path className="st16" d="M283.9,315.3l1,0.8l-1-1V315.3z M284.8,316.1l-0.8-0.8l1,0.8H284.8z"/>
<path className="st17" d="M283.9,315.3l1,0.8l-1-1V315.3z M284.8,316.1l-0.8-0.8l1,0.8H284.8z"/>
<path className="st16" d="M284.6,316l-0.7-0.7l0.4,0.6L284.6,316z M284.8,316.1l-0.1-0.1l-0.7-0.7L284.8,316.1z"/>
<path className="st17" d="M284.6,316l-0.7-0.7l0.4,0.6L284.6,316z M284.8,316.1l-0.1-0.1l-0.7-0.7L284.8,316.1z"/>
<polygon className="st16" points="284.8,316.1 284,315.3 283.9,315.3 "/>
<polygon className="st17" points="284.8,316.1 284,315.3 283.9,315.3 "/>
<path className="st19" d="M286.3,311.9C286.3,311.9,286.2,311.9,286.3,311.9"/>
<line className="st19" x1="286.3" y1="312" x2="288" y2="313.7"/>
<path className="st19" d="M288,313.7C288,313.7,288,313.6,288,313.7"/>
<line className="st19" x1="288" y1="313.7" x2="286" y2="315.8"/>
<path className="st19" d="M284.2,314c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="284.3" y1="314.1" x2="286.3" y2="312"/>
<path className="st19" d="M285.6,314.1C285.6,314.1,285.5,314.1,285.6,314.1"/>
<line className="st19" x1="285.6" y1="314.1" x2="285.4" y2="314.3"/>
<path className="st19" d="M285.3,314.3c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="285.7" y1="314.7" x2="286" y2="314.4"/>
<path className="st19" d="M285.8,314.4C285.8,314.4,285.8,314.4,285.8,314.4"/>
<line className="st19" x1="286" y1="314.4" x2="285.6" y2="314.1"/>
<line className="st19" x1="286.1" y1="315.2" x2="285.7" y2="315.5"/>
<path className="st19" d="M284.4,314.2c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="284.5" y1="314.3" x2="284.9" y2="314"/>
<path className="st19" d="M286.1,315.1c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<line className="st1" x1="289.3" y1="314.9" x2="285.1" y2="310.7"/>
<path className="st16" d="M317.6,343.4l-1.7-1.7L317.6,343.4z M316,341.7l1.7,1.7L316,341.7z M316,341.7L316,341.7l1.7,1.7L316,341.7z
	 M317.6,343.4l-1.7-1.7L317.6,343.4z"/>
<path className="st17" d="M317.6,343.4l-1.7-1.7L317.6,343.4z M316,341.7l1.7,1.7L316,341.7z M316,341.7L316,341.7l1.7,1.7L316,341.7z
	 M317.6,343.4l-1.7-1.7L317.6,343.4z"/>
<path className="st16" d="M315.8,341.7l1.8,1.7l-1.7-1.7H315.8z M315.8,341.7L315.8,341.7l1.8,1.7L315.8,341.7z M317.6,343.4l-1.8-1.7
	L317.6,343.4z M315.8,341.7l1.8,1.7L315.8,341.7z"/>
<path className="st17" d="M315.8,341.7l1.8,1.7l-1.7-1.7H315.8z M315.8,341.7L315.8,341.7l1.8,1.7L315.8,341.7z M317.6,343.4l-1.8-1.7
	L317.6,343.4z M315.8,341.7l1.8,1.7L315.8,341.7z"/>
<path className="st16" d="M314.8,342.8l2.9,0.6l-1.8-1.7L314.8,342.8z M316.6,344.4l-1.8-1.7l2.9,0.6L316.6,344.4z"/>
<path className="st17" d="M314.8,342.8l2.9,0.6l-1.8-1.7L314.8,342.8z M316.6,344.4l-1.8-1.7l2.9,0.6L316.6,344.4z"/>
<path className="st16" d="M314.6,342.9l1.1,0.7l-1-0.8L314.6,342.9z M315.1,343.4l-0.5-0.5l1.1,0.7L315.1,343.4z"/>
<path className="st17" d="M314.6,342.9l1.1,0.7l-1-0.8L314.6,342.9z M315.1,343.4l-0.5-0.5l1.1,0.7L315.1,343.4z"/>
<path className="st16" d="M316,344.2l0.6,0.2l-0.8-0.8L316,344.2z M316.3,344.7l-0.4-0.5l0.6,0.2L316.3,344.7z"/>
<path className="st17" d="M316,344.2l0.6,0.2l-0.8-0.8L316,344.2z M316.3,344.7l-0.4-0.5l0.6,0.2L316.3,344.7z"/>
<path className="st16" d="M314.6,342.9L314.6,342.9l0.5,0.5L314.6,342.9z M315.1,343.4l-0.5-0.5L315.1,343.4z"/>
<path className="st17" d="M314.6,342.9L314.6,342.9l0.5,0.5L314.6,342.9z M315.1,343.4l-0.5-0.5L315.1,343.4z"/>
<path className="st16" d="M315.2,343.7l0.2,0.2L315.2,343.7z M315.6,344.1l-0.4-0.4l0.2,0.2L315.6,344.1z M315.2,343.7l0.4,0.4
	L315.2,343.7z M315.2,343.7L315.2,343.7l0.4,0.4L315.2,343.7z"/>
<path className="st17" d="M315.2,343.7l0.2,0.2L315.2,343.7z M315.6,344.1l-0.4-0.4l0.2,0.2L315.6,344.1z M315.2,343.7l0.4,0.4
	L315.2,343.7z M315.2,343.7L315.2,343.7l0.4,0.4L315.2,343.7z"/>
<path className="st16" d="M315.1,343.7l0.5,0.4l-0.4-0.4H315.1z M315.5,344.1l-0.4-0.4l0.5,0.4H315.5z M315.1,343.7l0.4,0.4L315.1,343.7
	z M315.1,343.7L315.1,343.7l0.4,0.4L315.1,343.7z"/>
<path className="st17" d="M315.1,343.7l0.5,0.4l-0.4-0.4H315.1z M315.5,344.1l-0.4-0.4l0.5,0.4H315.5z M315.1,343.7l0.4,0.4L315.1,343.7
	z M315.1,343.7L315.1,343.7l0.4,0.4L315.1,343.7z"/>
<path className="st16" d="M315.4,344.3L315,344l0.1,0.2L315.4,344.3z M315.5,344.1l-0.1,0.2L315,344L315.5,344.1z"/>
<path className="st17" d="M315.4,344.3L315,344l0.1,0.2L315.4,344.3z M315.5,344.1l-0.1,0.2L315,344L315.5,344.1z"/>
<polygon className="st16" points="315.5,344.1 315.1,343.7 315,344 "/>
<polygon className="st17" points="315.5,344.1 315.1,343.7 315,344 "/>
<path className="st16" d="M315.7,344.8l0.6-0.1l-0.4-0.5L315.7,344.8z M316,345l-0.2-0.2l0.6-0.1L316,345z"/>
<path className="st17" d="M315.7,344.8l0.6-0.1l-0.4-0.5L315.7,344.8z M316,345l-0.2-0.2l0.6-0.1L316,345z"/>
<path className="st16" d="M314.3,343.4h0.8l-0.5-0.5L314.3,343.4z M314.5,343.6l-0.2-0.2h0.8L314.5,343.6z"/>
<path className="st17" d="M314.3,343.4h0.8l-0.5-0.5L314.3,343.4z M314.5,343.6l-0.2-0.2h0.8L314.5,343.6z"/>
<path className="st16" d="M313.9,343.7l0.6-0.1l-0.2-0.2L313.9,343.7z M314,343.8l-0.1-0.1l0.6-0.1L314,343.8z"/>
<path className="st17" d="M313.9,343.7l0.6-0.1l-0.2-0.2L313.9,343.7z M314,343.8l-0.1-0.1l0.6-0.1L314,343.8z"/>
<path className="st16" d="M315.4,345.2l0.6-0.1l-0.2-0.2L315.4,345.2z M315.6,345.4l-0.2-0.2l0.6-0.1L315.6,345.4z"/>
<path className="st17" d="M315.4,345.2l0.6-0.1l-0.2-0.2L315.4,345.2z M315.6,345.4l-0.2-0.2l0.6-0.1L315.6,345.4z"/>
<path className="st16" d="M314.8,345.4h0.8l-0.2-0.2L314.8,345.4z M315.1,345.8l-0.4-0.4h0.8L315.1,345.8z"/>
<path className="st17" d="M314.8,345.4h0.8l-0.2-0.2L314.8,345.4z M315.1,345.8l-0.4-0.4h0.8L315.1,345.8z"/>
<path className="st16" d="M313.6,344.2l0.5-0.4l-0.1-0.1L313.6,344.2z M313.8,344.6l-0.2-0.4l0.5-0.4L313.8,344.6z"/>
<path className="st17" d="M313.6,344.2l0.5-0.4l-0.1-0.1L313.6,344.2z M313.8,344.6l-0.2-0.4l0.5-0.4L313.8,344.6z"/>
<path className="st16" d="M313.6,344.3l0.2,0.2l-0.2-0.4V344.3z M313.6,344.3L313.6,344.3l0.2,0.2L313.6,344.3z"/>
<path className="st17" d="M313.6,344.3l0.2,0.2l-0.2-0.4V344.3z M313.6,344.3L313.6,344.3l0.2,0.2L313.6,344.3z"/>
<path className="st16" d="M314.8,345.5l0.4,0.2l-0.4-0.4V345.5z M315,345.8l-0.2-0.2l0.4,0.2H315z"/>
<path className="st17" d="M314.8,345.5l0.4,0.2l-0.4-0.4V345.5z M315,345.8l-0.2-0.2l0.4,0.2H315z"/>
<path className="st16" d="M313.6,344.7l0.2-0.1l-0.2-0.2V344.7z M314,345.2l-0.5-0.5l0.2-0.1L314,345.2z"/>
<path className="st17" d="M313.6,344.7l0.2-0.1l-0.2-0.2V344.7z M314,345.2l-0.5-0.5l0.2-0.1L314,345.2z"/>
<path className="st16" d="M314,345.2l1,0.6l-0.2-0.2L314,345.2z M314.6,345.8l-0.6-0.6l1,0.6H314.6z"/>
<path className="st17" d="M314,345.2l1,0.6l-0.2-0.2L314,345.2z M314.6,345.8l-0.6-0.6l1,0.6H314.6z"/>
<path className="st16" d="M313.6,344.8l1.1,1l-1.1-1.1V344.8z M314.5,345.8l-1-1l1.1,1H314.5z"/>
<path className="st17" d="M313.6,344.8l1.1,1l-1.1-1.1V344.8z M314.5,345.8l-1-1l1.1,1H314.5z"/>
<path className="st16" d="M313.6,344.9l1,0.8l-1-1V344.9z M314.4,345.6l-0.8-0.7l1,0.8L314.4,345.6z"/>
<path className="st17" d="M313.6,344.9l1,0.8l-1-1V344.9z M314.4,345.6l-0.8-0.7l1,0.8L314.4,345.6z"/>
<path className="st16" d="M314.3,345.6l-0.7-0.7l0.4,0.5L314.3,345.6z M314.4,345.6h-0.1l-0.7-0.7L314.4,345.6z"/>
<path className="st17" d="M314.3,345.6l-0.7-0.7l0.4,0.5L314.3,345.6z M314.4,345.6h-0.1l-0.7-0.7L314.4,345.6z"/>
<path className="st16" d="M314.4,345.6l-0.8-0.7L314.4,345.6z"/>
<path className="st17" d="M314.4,345.6l-0.8-0.7L314.4,345.6z"/>
<path className="st19" d="M315.9,341.6C315.9,341.6,315.9,341.6,315.9,341.6"/>
<line className="st19" x1="316" y1="341.7" x2="317.6" y2="343.4"/>
<path className="st19" d="M317.5,343.3C317.5,343.3,317.5,343.3,317.5,343.3"/>
<line className="st19" x1="317.6" y1="343.4" x2="315.6" y2="345.4"/>
<path className="st19" d="M313.8,343.6c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="313.9" y1="343.7" x2="315.8" y2="341.7"/>
<path className="st19" d="M315.2,343.7C315.2,343.7,315.2,343.7,315.2,343.7"/>
<line className="st19" x1="315.1" y1="343.7" x2="315" y2="344"/>
<path className="st19" d="M314.9,343.9c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="315.4" y1="344.3" x2="315.5" y2="344.1"/>
<path className="st19" d="M315.5,344.1C315.5,344,315.5,344,315.5,344.1"/>
<line className="st19" x1="315.5" y1="344" x2="315.2" y2="343.7"/>
<line className="st19" x1="315.7" y1="344.8" x2="315.4" y2="345.2"/>
<path className="st19" d="M314.1,343.9c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="314" y1="343.8" x2="314.5" y2="343.6"/>
<path className="st19" d="M315.6,344.7c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<line className="st1" x1="318.8" y1="344.6" x2="314.6" y2="340.4"/>
<path className="st16" d="M321.2,347.1l1.7,1.6l-1.6-1.6H321.2z M323,348.8l-1.8-1.7l1.7,1.6L323,348.8z M321.2,347.1l1.8,1.7
	L321.2,347.1z M321.2,347.1L321.2,347.1l1.8,1.7L321.2,347.1z"/>
<path className="st17" d="M321.2,347.1l1.7,1.6l-1.6-1.6H321.2z M323,348.8l-1.8-1.7l1.7,1.6L323,348.8z M321.2,347.1l1.8,1.7
	L321.2,347.1z M321.2,347.1L321.2,347.1l1.8,1.7L321.2,347.1z"/>
<path className="st16" d="M321.2,347.1l1.8,1.7L321.2,347.1z M322.9,348.8l-1.7-1.7l1.8,1.7H322.9z M321.2,347.1l1.7,1.7L321.2,347.1z
	 M321.2,347.1L321.2,347.1l1.7,1.7L321.2,347.1z"/>
<path className="st17" d="M321.2,347.1l1.8,1.7L321.2,347.1z M322.9,348.8l-1.7-1.7l1.8,1.7H322.9z M321.2,347.1l1.7,1.7L321.2,347.1z
	 M321.2,347.1L321.2,347.1l1.7,1.7L321.2,347.1z"/>
<path className="st16" d="M320.2,348.2l2.8,0.6l-1.7-1.7L320.2,348.2z M322,349.8l-1.8-1.7l2.8,0.6L322,349.8z"/>
<path className="st17" d="M320.2,348.2l2.8,0.6l-1.7-1.7L320.2,348.2z M322,349.8l-1.8-1.7l2.8,0.6L322,349.8z"/>
<path className="st16" d="M320,348.3l1,0.7l-0.8-0.8L320,348.3z M320.4,348.8l-0.4-0.5l1,0.7L320.4,348.8z"/>
<path className="st17" d="M320,348.3l1,0.7l-0.8-0.8L320,348.3z M320.4,348.8l-0.4-0.5l1,0.7L320.4,348.8z"/>
<path className="st16" d="M321.2,349.6l0.7,0.2l-1-0.8L321.2,349.6z M321.7,350l-0.5-0.4l0.7,0.2L321.7,350z"/>
<path className="st17" d="M321.2,349.6l0.7,0.2l-1-0.8L321.2,349.6z M321.7,350l-0.5-0.4l0.7,0.2L321.7,350z"/>
<path className="st16" d="M320,348.3L320,348.3l0.4,0.5L320,348.3z M320.4,348.8l-0.4-0.5L320.4,348.8z"/>
<path className="st17" d="M320,348.3L320,348.3l0.4,0.5L320,348.3z M320.4,348.8l-0.4-0.5L320.4,348.8z"/>
<path className="st16" d="M320.6,349.1l0.2,0.2L320.6,349.1z M320.9,349.5l-0.2-0.4l0.2,0.2V349.5z M320.6,349.1l0.2,0.4L320.6,349.1z
	 M320.6,349.1L320.6,349.1l0.2,0.4L320.6,349.1z"/>
<path className="st17" d="M320.6,349.1l0.2,0.2L320.6,349.1z M320.9,349.5l-0.2-0.4l0.2,0.2V349.5z M320.6,349.1l0.2,0.4L320.6,349.1z
	 M320.6,349.1L320.6,349.1l0.2,0.4L320.6,349.1z"/>
<path className="st16" d="M320.5,349.1l0.4,0.4l-0.2-0.4H320.5z M320.5,349.1L320.5,349.1l0.4,0.4L320.5,349.1z M320.9,349.5l-0.4-0.4
	L320.9,349.5z M320.5,349.1l0.4,0.4L320.5,349.1z"/>
<path className="st17" d="M320.5,349.1l0.4,0.4l-0.2-0.4H320.5z M320.5,349.1L320.5,349.1l0.4,0.4L320.5,349.1z M320.9,349.5l-0.4-0.4
	L320.9,349.5z M320.5,349.1l0.4,0.4L320.5,349.1z"/>
<path className="st16" d="M320.6,349.7l-0.4-0.4l0.2,0.2L320.6,349.7z M320.9,349.5l-0.2,0.2l-0.4-0.4L320.9,349.5z"/>
<path className="st17" d="M320.6,349.7l-0.4-0.4l0.2,0.2L320.6,349.7z M320.9,349.5l-0.2,0.2l-0.4-0.4L320.9,349.5z"/>
<polygon className="st16" points="320.9,349.5 320.5,349.1 320.3,349.4 "/>
<polygon className="st17" points="320.9,349.5 320.5,349.1 320.3,349.4 "/>
<path className="st16" d="M321.1,350.2l0.6-0.2l-0.5-0.4L321.1,350.2z M321.4,350.4l-0.2-0.2l0.6-0.2L321.4,350.4z"/>
<path className="st17" d="M321.1,350.2l0.6-0.2l-0.5-0.4L321.1,350.2z M321.4,350.4l-0.2-0.2l0.6-0.2L321.4,350.4z"/>
<path className="st16" d="M319.6,348.6l0.8,0.1l-0.4-0.5L319.6,348.6z M319.8,348.9l-0.2-0.2l0.8,0.1L319.8,348.9z"/>
<path className="st17" d="M319.6,348.6l0.8,0.1l-0.4-0.5L319.6,348.6z M319.8,348.9l-0.2-0.2l0.8,0.1L319.8,348.9z"/>
<path className="st16" d="M319.2,349l0.6-0.1l-0.2-0.2L319.2,349z M319.4,349.2l-0.2-0.2l0.6-0.1L319.4,349.2z"/>
<path className="st17" d="M319.2,349l0.6-0.1l-0.2-0.2L319.2,349z M319.4,349.2l-0.2-0.2l0.6-0.1L319.4,349.2z"/>
<path className="st16" d="M320.8,350.6l0.6-0.1l-0.2-0.2L320.8,350.6z M321,350.8l-0.2-0.2l0.6-0.1L321,350.8z"/>
<path className="st17" d="M320.8,350.6l0.6-0.1l-0.2-0.2L320.8,350.6z M321,350.8l-0.2-0.2l0.6-0.1L321,350.8z"/>
<path className="st16" d="M320.2,350.8h0.8l-0.2-0.2L320.2,350.8z M320.4,351l-0.2-0.2h0.8L320.4,351z"/>
<path className="st17" d="M320.2,350.8h0.8l-0.2-0.2L320.2,350.8z M320.4,351l-0.2-0.2h0.8L320.4,351z"/>
<path className="st16" d="M319,349.6l0.5-0.4l-0.2-0.2L319,349.6z M319.2,349.8l-0.2-0.2l0.5-0.4L319.2,349.8z M319.2,350v-0.1l-0.2-0.2
	L319.2,350z"/>
<path className="st17" d="M319,349.6l0.5-0.4l-0.2-0.2L319,349.6z M319.2,349.8l-0.2-0.2l0.5-0.4L319.2,349.8z M319.2,350v-0.1l-0.2-0.2
	L319.2,350z"/>
<path className="st16" d="M319.2,349.8l-0.2-0.2L319.2,349.8z"/>
<path className="st17" d="M319.2,349.8l-0.2-0.2L319.2,349.8z"/>
<path className="st16" d="M320.2,350.8L320.2,350.8l0.2,0.2L320.2,350.8z M320.4,351l-0.2-0.2L320.4,351z"/>
<path className="st17" d="M320.2,350.8L320.2,350.8l0.2,0.2L320.2,350.8z M320.4,351l-0.2-0.2L320.4,351z"/>
<path className="st16" d="M319,350.1l0.2-0.1l-0.2-0.4V350.1z M319.4,350.6l-0.5-0.5l0.2-0.1L319.4,350.6z"/>
<path className="st17" d="M319,350.1l0.2-0.1l-0.2-0.4V350.1z M319.4,350.6l-0.5-0.5l0.2-0.1L319.4,350.6z"/>
<path className="st16" d="M319.4,350.6l1,0.5l-0.2-0.2L319.4,350.6z M320,351l-0.6-0.5l1,0.5H320z"/>
<path className="st17" d="M319.4,350.6l1,0.5l-0.2-0.2L319.4,350.6z M320,351l-0.6-0.5l1,0.5H320z"/>
<path className="st16" d="M319,350.2l1.1,0.8l-1.1-1V350.2z M319.8,351.2l-0.8-1l1.1,0.8L319.8,351.2z M319.7,351l0.1,0.1l-0.8-1
	L319.7,351z"/>
<path className="st17" d="M319,350.2l1.1,0.8l-1.1-1V350.2z M319.8,351.2l-0.8-1l1.1,0.8L319.8,351.2z M319.7,351l0.1,0.1l-0.8-1
	L319.7,351z"/>
<path className="st16" d="M319.8,351.2l-0.8-1L319.8,351.2z"/>
<path className="st17" d="M319.8,351.2l-0.8-1L319.8,351.2z"/>
<path className="st16" d="M319.7,351l-0.7-0.7l0.4,0.5L319.7,351z M319,350.3l0.7,0.7L319,350.3z M319,350.3L319,350.3l0.7,0.7
	L319,350.3z"/>
<path className="st17" d="M319.7,351l-0.7-0.7l0.4,0.5L319.7,351z M319,350.3l0.7,0.7L319,350.3z M319,350.3L319,350.3l0.7,0.7
	L319,350.3z"/>
<path className="st19" d="M321.2,347C321.2,347,321.2,347,321.2,347"/>
<line className="st19" x1="321.4" y1="347.1" x2="322.9" y2="348.6"/>
<path className="st19" d="M322.9,348.7C322.9,348.7,322.9,348.7,322.9,348.7"/>
<line className="st19" x1="322.9" y1="348.8" x2="321" y2="350.8"/>
<path className="st19" d="M319.2,349c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="319.2" y1="349" x2="321.2" y2="347.1"/>
<path className="st19" d="M320.6,349C320.6,349,320.6,349,320.6,349"/>
<line className="st19" x1="320.5" y1="349.1" x2="320.3" y2="349.4"/>
<path className="st19" d="M320.2,349.3c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="320.6" y1="349.7" x2="320.9" y2="349.5"/>
<path className="st19" d="M320.9,349.5C320.9,349.4,320.9,349.4,320.9,349.5"/>
<line className="st19" x1="320.9" y1="349.4" x2="320.6" y2="349.1"/>
<line className="st19" x1="321.1" y1="350.2" x2="320.8" y2="350.6"/>
<path className="st19" d="M319.5,349.3c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="319.4" y1="349.2" x2="319.8" y2="348.9"/>
<path className="st19" d="M321,350.1c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<line className="st1" x1="324.2" y1="350" x2="320" y2="345.8"/>
<path className="st16" d="M350.9,376.6l1.7,1.7l-1.6-1.6L350.9,376.6z M352.6,378.4l-1.7-1.8l1.7,1.7V378.4z M350.9,376.6l1.7,1.8
	L350.9,376.6z M350.9,376.6L350.9,376.6l1.7,1.8L350.9,376.6z"/>
<path className="st17" d="M350.9,376.6l1.7,1.7l-1.6-1.6L350.9,376.6z M352.6,378.4l-1.7-1.8l1.7,1.7V378.4z M350.9,376.6l1.7,1.8
	L350.9,376.6z M350.9,376.6L350.9,376.6l1.7,1.8L350.9,376.6z"/>
<path className="st16" d="M350.9,376.7l1.7,1.7l-1.7-1.8V376.7z M350.9,376.7L350.9,376.7l1.7,1.7L350.9,376.7z M352.6,378.4l-1.7-1.7
	L352.6,378.4z M350.9,376.7l1.7,1.7L350.9,376.7z"/>
<path className="st17" d="M350.9,376.7l1.7,1.7l-1.7-1.8V376.7z M350.9,376.7L350.9,376.7l1.7,1.7L350.9,376.7z M352.6,378.4l-1.7-1.7
	L352.6,378.4z M350.9,376.7l1.7,1.7L350.9,376.7z"/>
<path className="st16" d="M349.8,377.7l2.8,0.7l-1.7-1.7L349.8,377.7z M351.5,379.5l-1.7-1.8l2.8,0.7L351.5,379.5z"/>
<path className="st17" d="M349.8,377.7l2.8,0.7l-1.7-1.7L349.8,377.7z M351.5,379.5l-1.7-1.8l2.8,0.7L351.5,379.5z"/>
<path className="st16" d="M349.7,377.9l1,0.7l-0.8-1L349.7,377.9z M350,378.4l-0.4-0.5l1,0.7L350,378.4z"/>
<path className="st17" d="M349.7,377.9l1,0.7l-0.8-1L349.7,377.9z M350,378.4l-0.4-0.5l1,0.7L350,378.4z"/>
<path className="st16" d="M350.9,379.2l0.6,0.2l-0.8-0.8L350.9,379.2z M351.4,379.6l-0.5-0.4l0.6,0.2L351.4,379.6z"/>
<path className="st17" d="M350.9,379.2l0.6,0.2l-0.8-0.8L350.9,379.2z M351.4,379.6l-0.5-0.4l0.6,0.2L351.4,379.6z"/>
<path className="st16" d="M349.7,377.9L349.7,377.9l0.4,0.5L349.7,377.9z M350,378.4l-0.4-0.5L350,378.4z"/>
<path className="st17" d="M349.7,377.9L349.7,377.9l0.4,0.5L349.7,377.9z M350,378.4l-0.4-0.5L350,378.4z"/>
<path className="st16" d="M350.2,378.8l0.4,0.2l-0.2-0.2H350.2z M350.2,378.8L350.2,378.8l0.4,0.2L350.2,378.8z M350.5,379l-0.4-0.2
	L350.5,379z M350.2,378.8l0.4,0.2L350.2,378.8z M350.5,379.1l-0.4-0.4l0.4,0.2V379.1z"/>
<path className="st17" d="M350.2,378.8l0.4,0.2l-0.2-0.2H350.2z M350.2,378.8L350.2,378.8l0.4,0.2L350.2,378.8z M350.5,379l-0.4-0.2
	L350.5,379z M350.2,378.8l0.4,0.2L350.2,378.8z M350.5,379.1l-0.4-0.4l0.4,0.2V379.1z"/>
<path className="st16" d="M350.2,378.8l0.4,0.4L350.2,378.8z M350.2,378.8L350.2,378.8l0.4,0.4L350.2,378.8z M350.5,379l-0.4-0.2
	L350.5,379z"/>
<path className="st17" d="M350.2,378.8l0.4,0.4L350.2,378.8z M350.2,378.8L350.2,378.8l0.4,0.4L350.2,378.8z M350.5,379l-0.4-0.2
	L350.5,379z"/>
<path className="st16" d="M350.3,379.4l-0.4-0.5l0.1,0.2L350.3,379.4z M350.5,379.1l-0.2,0.2l-0.4-0.5L350.5,379.1z"/>
<path className="st17" d="M350.3,379.4l-0.4-0.5l0.1,0.2L350.3,379.4z M350.5,379.1l-0.2,0.2l-0.4-0.5L350.5,379.1z"/>
<polygon className="st16" points="350.5,379.1 350.2,378.8 349.9,378.9 "/>
<polygon className="st17" points="350.5,379.1 350.2,378.8 349.9,378.9 "/>
<path className="st16" d="M350.8,379.8l0.6-0.2l-0.5-0.4L350.8,379.8z M350.9,380.1l-0.1-0.2l0.6-0.2L350.9,380.1z"/>
<path className="st17" d="M350.8,379.8l0.6-0.2l-0.5-0.4L350.8,379.8z M350.9,380.1l-0.1-0.2l0.6-0.2L350.9,380.1z"/>
<path className="st16" d="M349.2,378.3l0.8,0.1l-0.4-0.5L349.2,378.3z M349.4,378.5l-0.2-0.2l0.8,0.1L349.4,378.5z"/>
<path className="st17" d="M349.2,378.3l0.8,0.1l-0.4-0.5L349.2,378.3z M349.4,378.5l-0.2-0.2l0.8,0.1L349.4,378.5z"/>
<path className="st16" d="M348.8,378.6l0.6-0.1l-0.2-0.2L348.8,378.6z M349.1,378.9l-0.2-0.2l0.6-0.1L349.1,378.9z"/>
<path className="st17" d="M348.8,378.6l0.6-0.1l-0.2-0.2L348.8,378.6z M349.1,378.9l-0.2-0.2l0.6-0.1L349.1,378.9z"/>
<path className="st16" d="M350.4,380.2l0.5-0.1l-0.1-0.2L350.4,380.2z M350.6,380.4l-0.2-0.2l0.5-0.1L350.6,380.4z"/>
<path className="st17" d="M350.4,380.2l0.5-0.1l-0.1-0.2L350.4,380.2z M350.6,380.4l-0.2-0.2l0.5-0.1L350.6,380.4z"/>
<path className="st16" d="M349.8,380.4h0.8l-0.2-0.2L349.8,380.4z M350,380.7l-0.2-0.2h0.8L350,380.7z"/>
<path className="st17" d="M349.8,380.4h0.8l-0.2-0.2L349.8,380.4z M350,380.7l-0.2-0.2h0.8L350,380.7z"/>
<path className="st16" d="M348.6,379.2l0.5-0.4l-0.2-0.2L348.6,379.2z M348.8,379.5l-0.2-0.2l0.5-0.4L348.8,379.5z M348.6,379.2l0.2,0.2
	L348.6,379.2z M348.6,379.2L348.6,379.2l0.2,0.2L348.6,379.2z"/>
<path className="st17" d="M348.6,379.2l0.5-0.4l-0.2-0.2L348.6,379.2z M348.8,379.5l-0.2-0.2l0.5-0.4L348.8,379.5z M348.6,379.2l0.2,0.2
	L348.6,379.2z M348.6,379.2L348.6,379.2l0.2,0.2L348.6,379.2z"/>
<path className="st16" d="M349.7,380.4l0.4,0.2l-0.2-0.2H349.7z M349.9,380.7l-0.2-0.2l0.4,0.2H349.9z"/>
<path className="st17" d="M349.7,380.4l0.4,0.2l-0.2-0.2H349.7z M349.9,380.7l-0.2-0.2l0.4,0.2H349.9z"/>
<path className="st16" d="M348.6,379.7l0.2-0.2l-0.2-0.2V379.7z M349.1,380.2l-0.5-0.5l0.2-0.2L349.1,380.2z"/>
<path className="st17" d="M348.6,379.7l0.2-0.2l-0.2-0.2V379.7z M349.1,380.2l-0.5-0.5l0.2-0.2L349.1,380.2z"/>
<path className="st16" d="M349.1,380.2l0.8,0.5l-0.2-0.2L349.1,380.2z M349.6,380.7l-0.5-0.5l0.8,0.5H349.6z"/>
<path className="st17" d="M349.1,380.2l0.8,0.5l-0.2-0.2L349.1,380.2z M349.6,380.7l-0.5-0.5l0.8,0.5H349.6z"/>
<path className="st16" d="M348.6,379.8l1,0.8l-1-1V379.8z M349.4,380.7l-0.8-0.8l1,0.8H349.4z M349.3,380.7h0.1l-0.8-0.8L349.3,380.7z"
	/>
<path className="st17" d="M348.6,379.8l1,0.8l-1-1V379.8z M349.4,380.7l-0.8-0.8l1,0.8H349.4z M349.3,380.7h0.1l-0.8-0.8L349.3,380.7z"
	/>
<path className="st16" d="M349.4,380.7l-0.8-0.8L349.4,380.7z"/>
<path className="st17" d="M349.4,380.7l-0.8-0.8L349.4,380.7z"/>
<path className="st16" d="M349.2,380.7l-0.6-0.7l0.4,0.5L349.2,380.7z M349.3,380.7h-0.1l-0.6-0.7L349.3,380.7z"/>
<path className="st17" d="M349.2,380.7l-0.6-0.7l0.4,0.5L349.2,380.7z M349.3,380.7h-0.1l-0.6-0.7L349.3,380.7z"/>
<path className="st16" d="M349.3,380.7l-0.7-0.7L349.3,380.7z"/>
<path className="st17" d="M349.3,380.7l-0.7-0.7L349.3,380.7z"/>
<path className="st19" d="M350.9,376.6C350.8,376.6,350.8,376.6,350.9,376.6"/>
<line className="st19" x1="351" y1="376.7" x2="352.6" y2="378.3"/>
<path className="st19" d="M352.5,378.4C352.6,378.4,352.6,378.3,352.5,378.4"/>
<line className="st19" x1="352.6" y1="378.4" x2="350.6" y2="380.4"/>
<path className="st19" d="M348.7,378.5c-0.5,0.5-0.5,1.3,0,1.8c0.5,0.5,1.3,0.5,1.8,0"/>
<line className="st19" x1="348.8" y1="378.6" x2="350.9" y2="376.7"/>
<path className="st19" d="M350.1,378.7C350.1,378.6,350.1,378.6,350.1,378.7"/>
<line className="st19" x1="350.2" y1="378.8" x2="349.9" y2="378.9"/>
<path className="st19" d="M349.8,378.9c0,0.2,0.2,0.4,0.4,0.4"/>
<line className="st19" x1="350.3" y1="379.4" x2="350.5" y2="379.1"/>
<path className="st19" d="M350.5,379C350.5,379,350.5,378.9,350.5,379"/>
<line className="st19" x1="350.5" y1="379" x2="350.3" y2="378.8"/>
<line className="st19" x1="350.8" y1="379.8" x2="350.4" y2="380.2"/>
<path className="st19" d="M349,378.8c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0"/>
<line className="st19" x1="349.1" y1="378.9" x2="349.4" y2="378.5"/>
<path className="st19" d="M350.7,379.8c0.3-0.4,0.3-0.9,0-1.2c-0.3-0.3-0.9-0.4-1.2,0"/>
<polyline className="st1" points="353.9,379.6 349.7,375.4 354.6,378.9 350.4,374.7 349.7,375.4 "/>
<polyline className="st1" points="320,345.8 325,349.2 320.8,345 320,345.8 "/>
<polyline className="st1" points="314.6,340.4 319.6,343.8 315.4,339.6 314.6,340.4 "/>
<polyline className="st1" points="285.1,310.7 289.9,314.3 285.7,310.1 285.1,310.7 "/>
<polyline className="st1" points="279.7,305.4 284.6,308.9 280.4,304.7 279.7,305.4 "/>
<path className="st16" d="M346.8,385.1l-6.7-0.1l3.4-3.4L346.8,385.1z M343.6,388.4l3.2-3.2l-6.7-0.1L343.6,388.4z"/>
<path className="st17" d="M346.8,385.1l-6.7-0.1l3.4-3.4L346.8,385.1z M343.6,388.4l3.2-3.2l-6.7-0.1L343.6,388.4z"/>
<path className="st16" d="M346.8,385.1l-2.6,2.6L346.8,385.1z M344.3,387.6l2.5-2.5l-2.6,2.6L344.3,387.6z"/>
<path className="st17" d="M346.8,385.1l-2.6,2.6L346.8,385.1z M344.3,387.6l2.5-2.5l-2.6,2.6L344.3,387.6z"/>
<path className="st16" d="M344.2,387.9l-0.6,0.5l0.6-0.6V387.9z M344.2,387.9L344.2,387.9l-0.6,0.5L344.2,387.9z"/>
<path className="st17" d="M344.2,387.9l-0.6,0.5l0.6-0.6V387.9z M344.2,387.9L344.2,387.9l-0.6,0.5L344.2,387.9z"/>
<path className="st16" d="M346.9,385.2l-2.6,2.4l2.5-2.5L346.9,385.2z M344.4,387.8l2.5-2.5l-2.6,2.4L344.4,387.8z"/>
<path className="st17" d="M346.9,385.2l-2.6,2.4l2.5-2.5L346.9,385.2z M344.4,387.8l2.5-2.5l-2.6,2.4L344.4,387.8z"/>
<path className="st16" d="M344.2,388l-0.6,0.4l0.6-0.5V388z M343.7,388.5l0.5-0.5l-0.6,0.4L343.7,388.5z"/>
<path className="st17" d="M344.2,388l-0.6,0.4l0.6-0.5V388z M343.7,388.5l0.5-0.5l-0.6,0.4L343.7,388.5z"/>
<path className="st16" d="M347,385.4l-2.6,2.4l2.5-2.5L347,385.4z M344.5,387.9l2.5-2.5l-2.6,2.4L344.5,387.9z"/>
<path className="st17" d="M347,385.4l-2.6,2.4l2.5-2.5L347,385.4z M344.5,387.9l2.5-2.5l-2.6,2.4L344.5,387.9z"/>
<path className="st16" d="M344.3,388.1l-0.6,0.4l0.5-0.5L344.3,388.1z M343.8,388.6l0.5-0.5l-0.6,0.4L343.8,388.6z"/>
<path className="st17" d="M344.3,388.1l-0.6,0.4l0.5-0.5L344.3,388.1z M343.8,388.6l0.5-0.5l-0.6,0.4L343.8,388.6z"/>
<path className="st16" d="M347,385.4l-2.5,2.5L347,385.4z M344.4,388l2.6-2.6l-2.5,2.5L344.4,388z"/>
<path className="st17" d="M347,385.4l-2.5,2.5L347,385.4z M344.4,388l2.6-2.6l-2.5,2.5L344.4,388z"/>
<path className="st16" d="M344.4,388l-0.6,0.6l0.5-0.5L344.4,388z M344.4,388L344.4,388l-0.6,0.6L344.4,388z"/>
<path className="st17" d="M344.4,388l-0.6,0.6l0.5-0.5L344.4,388z M344.4,388L344.4,388l-0.6,0.6L344.4,388z"/>
<path className="st16" d="M347.6,385.8l-3.8,2.8l3.2-3.2L347.6,385.8z M344.3,389.2l3.4-3.4l-3.8,2.8L344.3,389.2z"/>
<path className="st17" d="M347.6,385.8l-3.8,2.8l3.2-3.2L347.6,385.8z M344.3,389.2l3.4-3.4l-3.8,2.8L344.3,389.2z"/>
<line className="st18" x1="344.3" y1="387.5" x2="343.4" y2="381.6"/>
<polyline className="st18" points="344.3,389.2 347.6,385.8 343.4,381.6 340.1,385 344.3,389.2 "/>
<line className="st18" x1="340.1" y1="385" x2="344" y2="387.6"/>
<path className="st18" d="M344.1,387.7c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C344.3,387.6,344.2,387.6,344.1,387.7"/>
<line className="st18" x1="344.6" y1="387.6" x2="347.6" y2="385.8"/>
<path className="st16" d="M314.5,352.8l0.1,6.7l3.4-3.4L314.5,352.8z M311.3,356.1l3.2-3.2l0.1,6.7L311.3,356.1z"/>
<path className="st17" d="M314.5,352.8l0.1,6.7l3.4-3.4L314.5,352.8z M311.3,356.1l3.2-3.2l0.1,6.7L311.3,356.1z"/>
<path className="st16" d="M314.5,352.8l-2.6,2.6L314.5,352.8z M312,355.4l2.5-2.5l-2.6,2.6L312,355.4z"/>
<path className="st17" d="M314.5,352.8l-2.6,2.6L314.5,352.8z M312,355.4l2.5-2.5l-2.6,2.6L312,355.4z"/>
<path className="st16" d="M311.8,355.5l-0.5,0.6l0.6-0.6H311.8z M311.8,355.5L311.8,355.5l-0.5,0.6L311.8,355.5z"/>
<path className="st17" d="M311.8,355.5l-0.5,0.6l0.6-0.6H311.8z M311.8,355.5L311.8,355.5l-0.5,0.6L311.8,355.5z"/>
<path className="st16" d="M314.4,352.7l-2.4,2.6l2.5-2.5L314.4,352.7z M311.9,355.2l2.5-2.5l-2.4,2.6L311.9,355.2z"/>
<path className="st17" d="M314.4,352.7l-2.4,2.6l2.5-2.5L314.4,352.7z M311.9,355.2l2.5-2.5l-2.4,2.6L311.9,355.2z"/>
<path className="st16" d="M311.6,355.5l-0.4,0.6l0.5-0.6H311.6z M311.2,356l0.5-0.5l-0.4,0.6L311.2,356z"/>
<path className="st17" d="M311.6,355.5l-0.4,0.6l0.5-0.6H311.6z M311.2,356l0.5-0.5l-0.4,0.6L311.2,356z"/>
<path className="st16" d="M314.3,352.6l-2.4,2.6l2.5-2.5L314.3,352.6z M311.8,355.1l2.5-2.5l-2.4,2.6L311.8,355.1z"/>
<path className="st17" d="M314.3,352.6l-2.4,2.6l2.5-2.5L314.3,352.6z M311.8,355.1l2.5-2.5l-2.4,2.6L311.8,355.1z"/>
<path className="st16" d="M311.5,355.4l-0.4,0.6l0.5-0.5L311.5,355.4z M311,355.8l0.5-0.5l-0.4,0.6L311,355.8z"/>
<path className="st17" d="M311.5,355.4l-0.4,0.6l0.5-0.5L311.5,355.4z M311,355.8l0.5-0.5l-0.4,0.6L311,355.8z"/>
<path className="st16" d="M314.3,352.6l-2.5,2.5L314.3,352.6z M311.6,355.2l2.6-2.6l-2.5,2.5L311.6,355.2z"/>
<path className="st17" d="M314.3,352.6l-2.5,2.5L314.3,352.6z M311.6,355.2l2.6-2.6l-2.5,2.5L311.6,355.2z"/>
<path className="st16" d="M311.6,355.2l-0.6,0.6l0.5-0.5L311.6,355.2z M311.6,355.2L311.6,355.2l-0.6,0.6L311.6,355.2z"/>
<path className="st17" d="M311.6,355.2l-0.6,0.6l0.5-0.5L311.6,355.2z M311.6,355.2L311.6,355.2l-0.6,0.6L311.6,355.2z"/>
<path className="st16" d="M313.8,352l-2.8,3.8l3.2-3.2L313.8,352z M310.4,355.4l3.4-3.4l-2.8,3.8L310.4,355.4z"/>
<path className="st17" d="M313.8,352l-2.8,3.8l3.2-3.2L313.8,352z M310.4,355.4l3.4-3.4l-2.8,3.8L310.4,355.4z"/>
<line className="st18" x1="312.1" y1="355.4" x2="318" y2="356.2"/>
<polyline className="st18" points="310.4,355.4 313.8,352 318,356.2 314.6,359.6 310.4,355.4 "/>
<line className="st18" x1="314.6" y1="359.6" x2="312" y2="355.6"/>
<path className="st18" d="M311.6,355.2c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C311.8,355.1,311.6,355.1,311.6,355.2"/>
<line className="st18" x1="312" y1="355" x2="313.8" y2="352"/>
<path className="st16" d="M311.9,350.1l-6.7-0.1l3.2-3.2L311.9,350.1z M308.5,353.3l3.4-3.2l-6.7-0.1L308.5,353.3z"/>
<path className="st17" d="M311.9,350.1l-6.7-0.1l3.2-3.2L311.9,350.1z M308.5,353.3l3.4-3.2l-6.7-0.1L308.5,353.3z"/>
<path className="st16" d="M311.9,350.2l-2.6,2.5l2.6-2.6V350.2z M309.4,352.7l2.5-2.5l-2.6,2.5H309.4z"/>
<path className="st17" d="M311.9,350.2l-2.6,2.5l2.6-2.6V350.2z M309.4,352.7l2.5-2.5l-2.6,2.5H309.4z"/>
<path className="st16" d="M309.1,352.8l-0.6,0.5l0.7-0.6L309.1,352.8z M308.6,353.4l0.5-0.6l-0.6,0.5L308.6,353.4z"/>
<path className="st17" d="M309.1,352.8l-0.6,0.5l0.7-0.6L309.1,352.8z M308.6,353.4l0.5-0.6l-0.6,0.5L308.6,353.4z"/>
<path className="st16" d="M312,350.2l-2.6,2.5l2.5-2.5H312z M309.5,352.7l2.5-2.5l-2.6,2.5H309.5z"/>
<path className="st17" d="M312,350.2l-2.6,2.5l2.5-2.5H312z M309.5,352.7l2.5-2.5l-2.6,2.5H309.5z"/>
<path className="st16" d="M309.2,353.1l-0.6,0.4l0.5-0.6L309.2,353.1z M308.6,353.6l0.6-0.5l-0.6,0.4V353.6z"/>
<path className="st17" d="M309.2,353.1l-0.6,0.4l0.5-0.6L309.2,353.1z M308.6,353.6l0.6-0.5l-0.6,0.4V353.6z"/>
<path className="st16" d="M312,350.3l-2.5,2.4l2.5-2.5V350.3z M309.5,352.8l2.5-2.5l-2.5,2.4V352.8z"/>
<path className="st17" d="M312,350.3l-2.5,2.4l2.5-2.5V350.3z M309.5,352.8l2.5-2.5l-2.5,2.4V352.8z"/>
<path className="st16" d="M309.4,353.1l-0.7,0.5l0.6-0.5H309.4z M308.8,353.6l0.6-0.5l-0.7,0.5H308.8z"/>
<path className="st17" d="M309.4,353.1l-0.7,0.5l0.6-0.5H309.4z M308.8,353.6l0.6-0.5l-0.7,0.5H308.8z"/>
<path className="st16" d="M312.1,350.4l-2.6,2.4l2.5-2.5L312.1,350.4z M309.5,353.1l2.6-2.6l-2.6,2.4V353.1z"/>
<path className="st17" d="M312.1,350.4l-2.6,2.4l2.5-2.5L312.1,350.4z M309.5,353.1l2.6-2.6l-2.6,2.4V353.1z"/>
<path className="st16" d="M309.5,353.1l-0.7,0.5l0.6-0.5H309.5z M308.9,353.7l0.6-0.6l-0.7,0.5L308.9,353.7z"/>
<path className="st17" d="M309.5,353.1l-0.7,0.5l0.6-0.5H309.5z M308.9,353.7l0.6-0.6l-0.7,0.5L308.9,353.7z"/>
<path className="st16" d="M312.6,350.9l-3.7,2.8l3.2-3.2L312.6,350.9z M309.4,354.2l3.2-3.2l-3.7,2.8L309.4,354.2z"/>
<path className="st17" d="M312.6,350.9l-3.7,2.8l3.2-3.2L312.6,350.9z M309.4,354.2l3.2-3.2l-3.7,2.8L309.4,354.2z"/>
<line className="st18" x1="309.4" y1="352.6" x2="308.4" y2="346.7"/>
<polyline className="st18" points="309.4,354.2 312.6,350.9 308.4,346.7 305.2,350 309.4,354.2 "/>
<line className="st18" x1="305.2" y1="350" x2="309.1" y2="352.7"/>
<path className="st18" d="M309.2,352.6c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C309.4,352.6,309.2,352.6,309.2,352.6"/>
<line className="st18" x1="309.6" y1="352.7" x2="312.6" y2="350.9"/>
<path className="st16" d="M279.6,317.8l0.1,6.7l3.2-3.2L279.6,317.8z M276.2,321.2l3.4-3.4l0.1,6.7L276.2,321.2z"/>
<path className="st17" d="M279.6,317.8l0.1,6.7l3.2-3.2L279.6,317.8z M276.2,321.2l3.4-3.4l0.1,6.7L276.2,321.2z"/>
<path className="st16" d="M279.5,317.8l-2.5,2.6l2.6-2.6H279.5z M277,320.3l2.5-2.5l-2.5,2.6V320.3z"/>
<path className="st17" d="M279.5,317.8l-2.5,2.6l2.6-2.6H279.5z M277,320.3l2.5-2.5l-2.5,2.6V320.3z"/>
<path className="st16" d="M276.8,320.6l-0.6,0.6l0.7-0.7L276.8,320.6z M276.2,321l0.6-0.5l-0.6,0.6V321z"/>
<path className="st17" d="M276.8,320.6l-0.6,0.6l0.7-0.7L276.8,320.6z M276.2,321l0.6-0.5l-0.6,0.6V321z"/>
<path className="st16" d="M279.4,317.7l-2.4,2.6l2.5-2.5L279.4,317.7z M277,320.2l2.4-2.5l-2.4,2.6V320.2z"/>
<path className="st17" d="M279.4,317.7l-2.4,2.6l2.5-2.5L279.4,317.7z M277,320.2l2.4-2.5l-2.4,2.6V320.2z"/>
<path className="st16" d="M276.6,320.4l-0.4,0.6l0.6-0.5L276.6,320.4z M276.1,320.9l0.5-0.5l-0.4,0.6L276.1,320.9z"/>
<path className="st17" d="M276.6,320.4l-0.4,0.6l0.6-0.5L276.6,320.4z M276.1,320.9l0.5-0.5l-0.4,0.6L276.1,320.9z"/>
<path className="st16" d="M279.4,317.6l-2.4,2.6l2.4-2.5V317.6z M276.8,320.1l2.5-2.5l-2.4,2.6L276.8,320.1z"/>
<path className="st17" d="M279.4,317.6l-2.4,2.6l2.4-2.5V317.6z M276.8,320.1l2.5-2.5l-2.4,2.6L276.8,320.1z"/>
<path className="st16" d="M276.6,320.3l-0.5,0.6l0.5-0.5V320.3z M276,320.9l0.6-0.6l-0.5,0.6H276z"/>
<path className="st17" d="M276.6,320.3l-0.5,0.6l0.5-0.5V320.3z M276,320.9l0.6-0.6l-0.5,0.6H276z"/>
<path className="st16" d="M279.2,317.6l-2.4,2.5l2.5-2.5H279.2z M276.6,320.2l2.6-2.6l-2.4,2.5L276.6,320.2z"/>
<path className="st17" d="M279.2,317.6l-2.4,2.5l2.5-2.5H279.2z M276.6,320.2l2.6-2.6l-2.4,2.5L276.6,320.2z"/>
<path className="st16" d="M276.6,320.2l-0.6,0.7l0.6-0.6V320.2z M276,320.8l0.6-0.6l-0.6,0.7V320.8z"/>
<path className="st17" d="M276.6,320.2l-0.6,0.7l0.6-0.6V320.2z M276,320.8l0.6-0.6l-0.6,0.7V320.8z"/>
<path className="st16" d="M278.8,317.1l-2.8,3.7l3.2-3.2L278.8,317.1z M275.5,320.3l3.2-3.2l-2.8,3.7L275.5,320.3z"/>
<path className="st17" d="M278.8,317.1l-2.8,3.7l3.2-3.2L278.8,317.1z M275.5,320.3l3.2-3.2l-2.8,3.7L275.5,320.3z"/>
<line className="st18" x1="277.1" y1="320.3" x2="283" y2="321.3"/>
<polyline className="st18" points="275.5,320.3 278.8,317.1 283,321.3 279.7,324.5 275.5,320.3 "/>
<line className="st18" x1="279.7" y1="324.5" x2="277" y2="320.6"/>
<path className="st18" d="M276.7,320.1c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C276.8,320.1,276.7,320.1,276.7,320.1"/>
<line className="st18" x1="277" y1="320.1" x2="278.8" y2="317.1"/>
<path className="st16" d="M276.8,315.2l-6.7-0.2l3.2-3.2L276.8,315.2z M273.6,318.4l3.2-3.2l-6.7-0.2L273.6,318.4z"/>
<path className="st17" d="M276.8,315.2l-6.7-0.2l3.2-3.2L276.8,315.2z M273.6,318.4l3.2-3.2l-6.7-0.2L273.6,318.4z"/>
<path className="st16" d="M276.8,315.2l-2.6,2.6L276.8,315.2z M274.3,317.7l2.5-2.5l-2.6,2.6L274.3,317.7z"/>
<path className="st17" d="M276.8,315.2l-2.6,2.6L276.8,315.2z M274.3,317.7l2.5-2.5l-2.6,2.6L274.3,317.7z"/>
<path className="st16" d="M274.2,317.9l-0.6,0.5l0.6-0.6V317.9z M274.2,317.9L274.2,317.9l-0.6,0.5L274.2,317.9z"/>
<path className="st17" d="M274.2,317.9l-0.6,0.5l0.6-0.6V317.9z M274.2,317.9L274.2,317.9l-0.6,0.5L274.2,317.9z"/>
<path className="st16" d="M277,315.3l-2.6,2.4l2.5-2.5L277,315.3z M274.4,317.8l2.5-2.5l-2.6,2.4L274.4,317.8z"/>
<path className="st17" d="M277,315.3l-2.6,2.4l2.5-2.5L277,315.3z M274.4,317.8l2.5-2.5l-2.6,2.4L274.4,317.8z"/>
<path className="st16" d="M274.2,318l-0.6,0.4l0.6-0.5V318z M273.7,318.5l0.5-0.5l-0.6,0.4L273.7,318.5z"/>
<path className="st17" d="M274.2,318l-0.6,0.4l0.6-0.5V318z M273.7,318.5l0.5-0.5l-0.6,0.4L273.7,318.5z"/>
<path className="st16" d="M277.1,315.4l-2.6,2.4l2.5-2.5L277.1,315.4z M274.6,317.9l2.5-2.5l-2.6,2.4L274.6,317.9z"/>
<path className="st17" d="M277.1,315.4l-2.6,2.4l2.5-2.5L277.1,315.4z M274.6,317.9l2.5-2.5l-2.6,2.4L274.6,317.9z"/>
<path className="st16" d="M274.3,318l-0.6,0.5l0.5-0.5H274.3z M273.8,318.6l0.5-0.6l-0.6,0.5L273.8,318.6z"/>
<path className="st17" d="M274.3,318l-0.6,0.5l0.5-0.5H274.3z M273.8,318.6l0.5-0.6l-0.6,0.5L273.8,318.6z"/>
<path className="st16" d="M277.1,315.4l-2.5,2.5L277.1,315.4z M274.4,318l2.6-2.6l-2.5,2.5L274.4,318z"/>
<path className="st17" d="M277.1,315.4l-2.5,2.5L277.1,315.4z M274.4,318l2.6-2.6l-2.5,2.5L274.4,318z"/>
<path className="st16" d="M274.4,318l-0.6,0.6l0.5-0.6H274.4z M274.4,318L274.4,318l-0.6,0.6L274.4,318z"/>
<path className="st17" d="M274.4,318l-0.6,0.6l0.5-0.6H274.4z M274.4,318L274.4,318l-0.6,0.6L274.4,318z"/>
<path className="st16" d="M277.6,315.9l-3.7,2.8l3.2-3.2L277.6,315.9z M274.3,319.1l3.2-3.2l-3.7,2.8L274.3,319.1z"/>
<path className="st17" d="M277.6,315.9l-3.7,2.8l3.2-3.2L277.6,315.9z M274.3,319.1l3.2-3.2l-3.7,2.8L274.3,319.1z"/>
<line className="st18" x1="274.3" y1="317.6" x2="273.4" y2="311.7"/>
<polyline className="st18" points="274.3,319.1 277.6,315.9 273.4,311.7 270.1,314.9 274.3,319.1 "/>
<line className="st18" x1="270.1" y1="314.9" x2="274.1" y2="317.7"/>
<path className="st18" d="M274.1,317.7c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C274.3,317.7,274.2,317.7,274.1,317.7"/>
<line className="st18" x1="274.6" y1="317.7" x2="277.6" y2="315.9"/>
<polygon className="st16" points="347.5,372.4 347.6,372.5 347.4,372.4 "/>
<polygon className="st17" points="347.5,372.4 347.6,372.5 347.4,372.4 "/>
<path className="st16" d="M346.1,371l3.1,2.5l-2.8-2.8L346.1,371z M349,373.8l-2.9-2.9l3.1,2.5L349,373.8z"/>
<path className="st17" d="M346.1,371l3.1,2.5l-2.8-2.8L346.1,371z M349,373.8l-2.9-2.9l3.1,2.5L349,373.8z"/>
<path className="st16" d="M346.1,371.1l1.4,1.3l-1.4-1.4V371.1z M347.4,372.4l-1.3-1.3l1.4,1.3H347.4z"/>
<path className="st17" d="M346.1,371.1l1.4,1.3l-1.4-1.4V371.1z M347.4,372.4l-1.3-1.3l1.4,1.3H347.4z"/>
<path className="st16" d="M347.6,372.5l1.3,1.3l-1.4-1.4L347.6,372.5z M348.8,373.8l-1.2-1.3l1.3,1.3H348.8z"/>
<path className="st17" d="M347.6,372.5l1.3,1.3l-1.4-1.4L347.6,372.5z M348.8,373.8l-1.2-1.3l1.3,1.3H348.8z"/>
<path className="st16" d="M346,371.1l1.4,1.3l-1.3-1.3H346z M347.3,372.4l-1.3-1.3l1.4,1.3H347.3z"/>
<path className="st17" d="M346,371.1l1.4,1.3l-1.3-1.3H346z M347.3,372.4l-1.3-1.3l1.4,1.3H347.3z"/>
<path className="st16" d="M347.3,372.4l0.4,0.1l-0.2-0.1H347.3z M347.5,372.6l-0.2-0.2l0.4,0.1L347.5,372.6z"/>
<path className="st17" d="M347.3,372.4l0.4,0.1l-0.2-0.1H347.3z M347.5,372.6l-0.2-0.2l0.4,0.1L347.5,372.6z"/>
<path className="st16" d="M347.5,372.6l1.3,1.2l-1.2-1.3L347.5,372.6z M348.8,374l-1.3-1.3l1.3,1.2V374z"/>
<path className="st17" d="M347.5,372.6l1.3,1.2l-1.2-1.3L347.5,372.6z M348.8,374l-1.3-1.3l1.3,1.2V374z"/>
<path className="st16" d="M346,371.2l1.3,1.2l-1.3-1.3V371.2z M346,371.2L346,371.2l1.3,1.2L346,371.2z"/>
<path className="st17" d="M346,371.2l1.3,1.2l-1.3-1.3V371.2z M346,371.2L346,371.2l1.3,1.2L346,371.2z"/>
<path className="st16" d="M347.5,372.6l1.3,1.3L347.5,372.6z M348.7,374l-1.2-1.3l1.3,1.3H348.7z"/>
<path className="st17" d="M347.5,372.6l1.3,1.3L347.5,372.6z M348.7,374l-1.2-1.3l1.3,1.3H348.7z"/>
<path className="st16" d="M347.5,372.8h-0.1H347.5z"/>
<path className="st17" d="M347.5,372.8h-0.1H347.5z"/>
<path className="st16" d="M347.2,372.4v0.1V372.4z"/>
<path className="st17" d="M347.2,372.4v0.1V372.4z"/>
<path className="st16" d="M346,371.2l1.3,1.2L346,371.2z M347.2,372.4l-1.2-1.2l1.3,1.2H347.2z"/>
<path className="st17" d="M346,371.2l1.3,1.2L346,371.2z M347.2,372.4l-1.2-1.2l1.3,1.2H347.2z"/>
<path className="st16" d="M347.5,372.8l1.2,1.2l-1.2-1.3V372.8z M347.5,372.8L347.5,372.8l1.2,1.2L347.5,372.8z"/>
<path className="st17" d="M347.5,372.8l1.2,1.2l-1.2-1.3V372.8z M347.5,372.8L347.5,372.8l1.2,1.2L347.5,372.8z"/>
<path className="st16" d="M346,371.2l1.2,1.2L346,371.2z M347.2,372.5l-1.2-1.3l1.2,1.2V372.5z"/>
<path className="st17" d="M346,371.2l1.2,1.2L346,371.2z M347.2,372.5l-1.2-1.3l1.2,1.2V372.5z"/>
<path className="st16" d="M347.2,372.5l0.4,0.1l-0.2-0.2L347.2,372.5z M347.4,372.8l-0.2-0.2l0.4,0.1L347.4,372.8z"/>
<path className="st17" d="M347.2,372.5l0.4,0.1l-0.2-0.2L347.2,372.5z M347.4,372.8l-0.2-0.2l0.4,0.1L347.4,372.8z"/>
<path className="st16" d="M347.5,372.8L347.5,372.8l1.2,1.2L347.5,372.8z M348.7,374l-1.2-1.2L348.7,374z"/>
<path className="st17" d="M347.5,372.8L347.5,372.8l1.2,1.2L347.5,372.8z M348.7,374l-1.2-1.2L348.7,374z"/>
<path className="st16" d="M347.2,372.5L347.2,372.5l0.2,0.2L347.2,372.5z M347.2,372.5L347.2,372.5z M347.2,372.5L347.2,372.5z
	 M347.4,372.8l-0.2-0.2L347.4,372.8z"/>
<path className="st17" d="M347.2,372.5L347.2,372.5l0.2,0.2L347.2,372.5z M347.2,372.5L347.2,372.5z M347.2,372.5L347.2,372.5z
	 M347.4,372.8l-0.2-0.2L347.4,372.8z"/>
<path className="st16" d="M347.4,372.8L347.4,372.8h0.1H347.4z M347.5,372.8h-0.1H347.5z"/>
<path className="st17" d="M347.4,372.8L347.4,372.8h0.1H347.4z M347.5,372.8h-0.1H347.5z"/>
<path className="st16" d="M347.2,372.5L347.2,372.5l0.2,0.2L347.2,372.5z M347.2,372.5L347.2,372.5z M347.2,372.5L347.2,372.5z
	 M347.4,372.8l-0.2-0.2L347.4,372.8z"/>
<path className="st17" d="M347.2,372.5L347.2,372.5l0.2,0.2L347.2,372.5z M347.2,372.5L347.2,372.5z M347.2,372.5L347.2,372.5z
	 M347.4,372.8l-0.2-0.2L347.4,372.8z"/>
<path className="st16" d="M347.4,372.8L347.4,372.8z M347.4,372.8L347.4,372.8z"/>
<path className="st17" d="M347.4,372.8L347.4,372.8z M347.4,372.8L347.4,372.8z"/>
<path className="st16" d="M347.2,372.5v0.1V372.5z"/>
<path className="st17" d="M347.2,372.5v0.1V372.5z"/>
<path className="st16" d="M347.2,372.5L347.2,372.5z"/>
<path className="st17" d="M347.2,372.5L347.2,372.5z"/>
<path className="st16" d="M347.3,372.6l0.1,0.1l-0.2-0.2L347.3,372.6z M347.3,372.6L347.3,372.6l0.1,0.1L347.3,372.6z"/>
<path className="st17" d="M347.3,372.6l0.1,0.1l-0.2-0.2L347.3,372.6z M347.3,372.6L347.3,372.6l0.1,0.1L347.3,372.6z"/>
<path className="st16" d="M347.3,372.8L347.3,372.8h0.1H347.3z M347.4,372.8h-0.1H347.4z"/>
<path className="st17" d="M347.3,372.8L347.3,372.8h0.1H347.3z M347.4,372.8h-0.1H347.4z"/>
<path className="st16" d="M347.2,372.5L347.2,372.5l0.1,0.1L347.2,372.5z M347.3,372.6l-0.1-0.1L347.3,372.6z"/>
<path className="st17" d="M347.2,372.5L347.2,372.5l0.1,0.1L347.2,372.5z M347.3,372.6l-0.1-0.1L347.3,372.6z"/>
<path className="st16" d="M347.3,372.6L347.3,372.6l0.1,0.1L347.3,372.6z M347.4,372.8l-0.1-0.1L347.4,372.8z"/>
<path className="st17" d="M347.3,372.6L347.3,372.6l0.1,0.1L347.3,372.6z M347.4,372.8l-0.1-0.1L347.4,372.8z"/>
<path className="st16" d="M345.7,371.3l1.4,1.2l-1.2-1.3L345.7,371.3z M347,372.6l-1.3-1.3l1.4,1.2L347,372.6z"/>
<path className="st17" d="M345.7,371.3l1.4,1.2l-1.2-1.3L345.7,371.3z M347,372.6l-1.3-1.3l1.4,1.2L347,372.6z"/>
<path className="st16" d="M347,372.6l0.4,0.1l-0.2-0.2L347,372.6z M347.3,372.9l-0.2-0.2l0.4,0.1L347.3,372.9z"/>
<path className="st17" d="M347,372.6l0.4,0.1l-0.2-0.2L347,372.6z M347.3,372.9l-0.2-0.2l0.4,0.1L347.3,372.9z"/>
<path className="st16" d="M347.3,372.9l1.4,1.1l-1.2-1.2L347.3,372.9z M348.6,374.2l-1.3-1.3l1.4,1.1L348.6,374.2z"/>
<path className="st17" d="M347.3,372.9l1.4,1.1l-1.2-1.2L347.3,372.9z M348.6,374.2l-1.3-1.3l1.4,1.1L348.6,374.2z"/>
<path className="st16" d="M346.8,372.9h0.5l-0.2-0.2L346.8,372.9z M347,373.1l-0.2-0.2h0.5L347,373.1z"/>
<path className="st17" d="M346.8,372.9h0.5l-0.2-0.2L346.8,372.9z M347,373.1l-0.2-0.2h0.5L347,373.1z"/>
<polygon className="st16" points="346.8,373.1 346.9,373.2 346.7,373 "/>
<polygon className="st17" points="346.8,373.1 346.9,373.2 346.7,373 "/>
<path className="st16" d="M346.6,373.1l0.4,0.1l-0.2-0.2L346.6,373.1z M346.8,373.4l-0.2-0.2l0.4,0.1L346.8,373.4z"/>
<path className="st17" d="M346.6,373.1l0.4,0.1l-0.2-0.2L346.6,373.1z M346.8,373.4l-0.2-0.2l0.4,0.1L346.8,373.4z"/>
<path className="st16" d="M346.7,373.5l-0.1-0.2v0.1L346.7,373.5z M346.8,373.4l-0.1,0.1l-0.1-0.2L346.8,373.4z"/>
<path className="st17" d="M346.7,373.5l-0.1-0.2v0.1L346.7,373.5z M346.8,373.4l-0.1,0.1l-0.1-0.2L346.8,373.4z"/>
<polygon className="st16" points="346.8,373.4 346.6,373.1 346.6,373.2 "/>
<polygon className="st17" points="346.8,373.4 346.6,373.1 346.6,373.2 "/>
<path className="st16" d="M345,372.2l1-0.6l-0.2-0.2L345,372.2z M345.2,372.4l-0.2-0.2l1-0.6L345.2,372.4z"/>
<path className="st17" d="M345,372.2l1-0.6l-0.2-0.2L345,372.2z M345.2,372.4l-0.2-0.2l1-0.6L345.2,372.4z"/>
<path className="st16" d="M347.6,374.7l1-0.5l-0.2-0.2L347.6,374.7z M347.8,374.9l-0.1-0.2l1-0.5L347.8,374.9z"/>
<path className="st17" d="M347.6,374.7l1-0.5l-0.2-0.2L347.6,374.7z M347.8,374.9l-0.1-0.2l1-0.5L347.8,374.9z"/>
<path className="st16" d="M344.8,372.5l0.5-0.1l-0.2-0.2L344.8,372.5z M345,372.8l-0.2-0.2l0.5-0.1L345,372.8z"/>
<path className="st17" d="M344.8,372.5l0.5-0.1l-0.2-0.2L344.8,372.5z M345,372.8l-0.2-0.2l0.5-0.1L345,372.8z"/>
<path className="st16" d="M347.2,374.9h0.6l-0.1-0.2L347.2,374.9z M347.4,375.2l-0.2-0.2h0.6L347.4,375.2z"/>
<path className="st17" d="M347.2,374.9h0.6l-0.1-0.2L347.2,374.9z M347.4,375.2l-0.2-0.2h0.6L347.4,375.2z"/>
<path className="st16" d="M344.8,372.5l0.2,0.2L344.8,372.5z M345.1,372.9l-0.4-0.4l0.2,0.2L345.1,372.9z"/>
<path className="st17" d="M344.8,372.5l0.2,0.2L344.8,372.5z M345.1,372.9l-0.4-0.4l0.2,0.2L345.1,372.9z"/>
<path className="st16" d="M347,374.8l0.4,0.4l-0.2-0.2L347,374.8z M347,374.8L347,374.8l0.4,0.4L347,374.8z"/>
<path className="st17" d="M347,374.8l0.4,0.4l-0.2-0.2L347,374.8z M347,374.8L347,374.8l0.4,0.4L347,374.8z"/>
<path className="st16" d="M344.8,372.8l0.4,0.1l-0.4-0.4V372.8z M345.2,373.2l-0.5-0.5l0.4,0.1L345.2,373.2z"/>
<path className="st17" d="M344.8,372.8l0.4,0.1l-0.4-0.4V372.8z M345.2,373.2l-0.5-0.5l0.4,0.1L345.2,373.2z"/>
<path className="st16" d="M346.7,374.7l0.7,0.5l-0.4-0.4L346.7,374.7z M347.2,375.2l-0.5-0.5l0.7,0.5H347.2z"/>
<path className="st17" d="M346.7,374.7l0.7,0.5l-0.4-0.4L346.7,374.7z M347.2,375.2l-0.5-0.5l0.7,0.5H347.2z"/>
<path className="st16" d="M344.8,373.1l2.4,2l-2.4-2.4V373.1z M346.9,375.2l-2.2-2l2.4,2H346.9z"/>
<path className="st17" d="M344.8,373.1l2.4,2l-2.4-2.4V373.1z M346.9,375.2l-2.2-2l2.4,2H346.9z"/>
<path className="st16" d="M345,373.5l1.9,1.7l-2.2-2L345,373.5z M346.4,374.9l-1.4-1.4l1.9,1.7L346.4,374.9z"/>
<path className="st17" d="M345,373.5l1.9,1.7l-2.2-2L345,373.5z M346.4,374.9l-1.4-1.4l1.9,1.7L346.4,374.9z"/>
<line className="st18" x1="346" y1="371.6" x2="347" y2="372.6"/>
<line className="st18" x1="345.2" y1="373.2" x2="346.7" y2="374.7"/>
<line className="st18" x1="345.2" y1="372.4" x2="346" y2="371.6"/>
<line className="st18" x1="346.3" y1="371.9" x2="347" y2="372.6"/>
<line className="st18" x1="345" y1="373.5" x2="346.4" y2="374.9"/>
<line className="st18" x1="345" y1="372.2" x2="346.4" y2="370.7"/>
<line className="st18" x1="346.8" y1="372.9" x2="347" y2="373.1"/>
<path className="st18" d="M347.2,372.6c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C347.1,372.4,347.1,372.5,347.2,372.6"/>
<line className="st18" x1="346.8" y1="372.9" x2="347.2" y2="372.4"/>
<line className="st18" x1="347" y1="373.1" x2="347.5" y2="372.8"/>
<path className="st18" d="M346.5,373.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C346.4,373.1,346.4,373.2,346.5,373.3"/>
<polyline className="st18" points="347.3,372.9 348.4,374 347.6,374.7 "/>
<polyline className="st18" points="347.8,374.9 349.2,373.5 346.4,370.7 "/>
<path className="st18" d="M345.2,372.2c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M346.6,374.6c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M344.9,372c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M346.4,374.9c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="327.2,352.1 327.4,352.2 327.1,352 "/>
<polygon className="st17" points="327.2,352.1 327.4,352.2 327.1,352 "/>
<path className="st16" d="M325.8,350.7l3.1,2.5l-2.8-2.8L325.8,350.7z M328.7,353.6l-2.9-2.9l3.1,2.5L328.7,353.6z"/>
<path className="st17" d="M325.8,350.7l3.1,2.5l-2.8-2.8L325.8,350.7z M328.7,353.6l-2.9-2.9l3.1,2.5L328.7,353.6z"/>
<path className="st16" d="M325.8,350.8l1.4,1.3l-1.4-1.4V350.8z M327.1,352l-1.3-1.2l1.4,1.3L327.1,352z"/>
<path className="st17" d="M325.8,350.8l1.4,1.3l-1.4-1.4V350.8z M327.1,352l-1.3-1.2l1.4,1.3L327.1,352z"/>
<path className="st16" d="M327.4,352.2l1.3,1.3l-1.4-1.4L327.4,352.2z M328.6,353.6l-1.2-1.3l1.3,1.3H328.6z"/>
<path className="st17" d="M327.4,352.2l1.3,1.3l-1.4-1.4L327.4,352.2z M328.6,353.6l-1.2-1.3l1.3,1.3H328.6z"/>
<path className="st16" d="M325.7,350.8l1.4,1.2l-1.3-1.2H325.7z M327,352.1l-1.3-1.3l1.4,1.2L327,352.1z"/>
<path className="st17" d="M325.7,350.8l1.4,1.2l-1.3-1.2H325.7z M327,352.1l-1.3-1.3l1.4,1.2L327,352.1z"/>
<path className="st16" d="M327,352.1l0.4,0.1l-0.2-0.2L327,352.1z M327.2,352.4l-0.2-0.2l0.4,0.1L327.2,352.4z"/>
<path className="st17" d="M327,352.1l0.4,0.1l-0.2-0.2L327,352.1z M327.2,352.4l-0.2-0.2l0.4,0.1L327.2,352.4z"/>
<path className="st16" d="M327.2,352.4l1.3,1.2l-1.2-1.3L327.2,352.4z M328.6,353.7l-1.3-1.3l1.3,1.2V353.7z"/>
<path className="st17" d="M327.2,352.4l1.3,1.2l-1.2-1.3L327.2,352.4z M328.6,353.7l-1.3-1.3l1.3,1.2V353.7z"/>
<path className="st16" d="M325.7,350.9l1.3,1.2l-1.3-1.3V350.9z M325.7,350.9L325.7,350.9l1.3,1.2L325.7,350.9z"/>
<path className="st17" d="M325.7,350.9l1.3,1.2l-1.3-1.3V350.9z M325.7,350.9L325.7,350.9l1.3,1.2L325.7,350.9z"/>
<path className="st16" d="M327.2,352.4l1.3,1.3L327.2,352.4z M328.4,353.7l-1.2-1.3l1.3,1.3H328.4z"/>
<path className="st17" d="M327.2,352.4l1.3,1.3L327.2,352.4z M328.4,353.7l-1.2-1.3l1.3,1.3H328.4z"/>
<path className="st16" d="M327.2,352.5h-0.1H327.2z"/>
<path className="st17" d="M327.2,352.5h-0.1H327.2z"/>
<path className="st16" d="M326.9,352.1v0.1V352.1z"/>
<path className="st17" d="M326.9,352.1v0.1V352.1z"/>
<path className="st16" d="M325.7,350.9l1.3,1.2L325.7,350.9z M326.9,352.1l-1.2-1.2l1.3,1.2H326.9z"/>
<path className="st17" d="M325.7,350.9l1.3,1.2L325.7,350.9z M326.9,352.1l-1.2-1.2l1.3,1.2H326.9z"/>
<path className="st16" d="M327.2,352.5l1.2,1.2l-1.2-1.3V352.5z M327.2,352.5L327.2,352.5l1.2,1.2L327.2,352.5z"/>
<path className="st17" d="M327.2,352.5l1.2,1.2l-1.2-1.3V352.5z M327.2,352.5L327.2,352.5l1.2,1.2L327.2,352.5z"/>
<path className="st16" d="M325.7,350.9L325.7,350.9l1.2,1.2L325.7,350.9z M326.9,352.1l-1.2-1.2L326.9,352.1z"/>
<path className="st17" d="M325.7,350.9L325.7,350.9l1.2,1.2L325.7,350.9z M326.9,352.1l-1.2-1.2L326.9,352.1z"/>
<path className="st16" d="M326.9,352.2l0.4,0.1l-0.2-0.2L326.9,352.2z M327.1,352.5l-0.2-0.2l0.4,0.1L327.1,352.5z"/>
<path className="st17" d="M326.9,352.2l0.4,0.1l-0.2-0.2L326.9,352.2z M327.1,352.5l-0.2-0.2l0.4,0.1L327.1,352.5z"/>
<path className="st16" d="M327.1,352.5l1.3,1.2l-1.2-1.2H327.1z M327.1,352.5L327.1,352.5l1.3,1.2L327.1,352.5z"/>
<path className="st17" d="M327.1,352.5l1.3,1.2l-1.2-1.2H327.1z M327.1,352.5L327.1,352.5l1.3,1.2L327.1,352.5z"/>
<path className="st16" d="M326.9,352.2L326.9,352.2z"/>
<path className="st17" d="M326.9,352.2L326.9,352.2z"/>
<path className="st16" d="M326.9,352.2v-0.1V352.2z"/>
<path className="st17" d="M326.9,352.2v-0.1V352.2z"/>
<path className="st16" d="M326.9,352.2L326.9,352.2l0.2,0.2L326.9,352.2z M327.1,352.5l-0.2-0.2L327.1,352.5z"/>
<path className="st17" d="M326.9,352.2L326.9,352.2l0.2,0.2L326.9,352.2z M327.1,352.5l-0.2-0.2L327.1,352.5z"/>
<path className="st16" d="M327.1,352.5L327.1,352.5z M327.1,352.5L327.1,352.5z"/>
<path className="st17" d="M327.1,352.5L327.1,352.5z M327.1,352.5L327.1,352.5z"/>
<path className="st16" d="M326.9,352.2L326.9,352.2l0.2,0.2L326.9,352.2z M326.9,352.2L326.9,352.2z M326.9,352.2L326.9,352.2z
	 M327.1,352.5l-0.2-0.2L327.1,352.5z"/>
<path className="st17" d="M326.9,352.2L326.9,352.2l0.2,0.2L326.9,352.2z M326.9,352.2L326.9,352.2z M326.9,352.2L326.9,352.2z
	 M327.1,352.5l-0.2-0.2L327.1,352.5z"/>
<path className="st16" d="M327.1,352.5L327.1,352.5z M327.1,352.5L327.1,352.5z"/>
<path className="st17" d="M327.1,352.5L327.1,352.5z M327.1,352.5L327.1,352.5z"/>
<path className="st16" d="M326.9,352.2v0.1V352.2z"/>
<path className="st17" d="M326.9,352.2v0.1V352.2z"/>
<path className="st16" d="M326.9,352.2L326.9,352.2z"/>
<path className="st17" d="M326.9,352.2L326.9,352.2z"/>
<path className="st16" d="M327,352.4l0.1,0.1l-0.2-0.2L327,352.4z M327,352.4L327,352.4l0.1,0.1L327,352.4z"/>
<path className="st17" d="M327,352.4l0.1,0.1l-0.2-0.2L327,352.4z M327,352.4L327,352.4l0.1,0.1L327,352.4z"/>
<path className="st16" d="M327,352.5L327,352.5h0.1H327z M327.1,352.5H327H327.1z"/>
<path className="st17" d="M327,352.5L327,352.5h0.1H327z M327.1,352.5H327H327.1z"/>
<path className="st16" d="M326.9,352.2L326.9,352.2l0.1,0.1L326.9,352.2z M327,352.4l-0.1-0.1L327,352.4z"/>
<path className="st17" d="M326.9,352.2L326.9,352.2l0.1,0.1L326.9,352.2z M327,352.4l-0.1-0.1L327,352.4z"/>
<path className="st16" d="M327,352.4L327,352.4l0.1,0.1L327,352.4z M327.1,352.5l-0.1-0.1L327.1,352.5z"/>
<path className="st17" d="M327,352.4L327,352.4l0.1,0.1L327,352.4z M327.1,352.5l-0.1-0.1L327.1,352.5z"/>
<path className="st16" d="M325.4,351l1.4,1.1l-1.2-1.2L325.4,351z M326.8,352.4l-1.3-1.3l1.4,1.1L326.8,352.4z"/>
<path className="st17" d="M325.4,351l1.4,1.1l-1.2-1.2L325.4,351z M326.8,352.4l-1.3-1.3l1.4,1.1L326.8,352.4z"/>
<path className="st16" d="M326.8,352.4l0.4,0.1l-0.2-0.2L326.8,352.4z M327,352.6l-0.2-0.2l0.4,0.1L327,352.6z"/>
<path className="st17" d="M326.8,352.4l0.4,0.1l-0.2-0.2L326.8,352.4z M327,352.6l-0.2-0.2l0.4,0.1L327,352.6z"/>
<path className="st16" d="M327,352.6l1.4,1.1l-1.3-1.2L327,352.6z M328.3,353.9l-1.3-1.3l1.4,1.1L328.3,353.9z"/>
<path className="st17" d="M327,352.6l1.4,1.1l-1.3-1.2L327,352.6z M328.3,353.9l-1.3-1.3l1.4,1.1L328.3,353.9z"/>
<path className="st16" d="M326.5,352.6h0.5l-0.2-0.2L326.5,352.6z M326.8,352.8l-0.2-0.2h0.5L326.8,352.8z"/>
<path className="st17" d="M326.5,352.6h0.5l-0.2-0.2L326.5,352.6z M326.8,352.8l-0.2-0.2h0.5L326.8,352.8z"/>
<polygon className="st16" points="326.5,352.8 326.6,353 326.4,352.7 "/>
<polygon className="st17" points="326.5,352.8 326.6,353 326.4,352.7 "/>
<path className="st16" d="M326.3,352.8l0.4,0.1l-0.2-0.2L326.3,352.8z M326.5,353.1l-0.2-0.2l0.4,0.1L326.5,353.1z"/>
<path className="st17" d="M326.3,352.8l0.4,0.1l-0.2-0.2L326.3,352.8z M326.5,353.1l-0.2-0.2l0.4,0.1L326.5,353.1z"/>
<path className="st16" d="M326.4,353.2l-0.2-0.2l0.1,0.1L326.4,353.2z M326.5,353.1l-0.1,0.1l-0.2-0.2L326.5,353.1z"/>
<path className="st17" d="M326.4,353.2l-0.2-0.2l0.1,0.1L326.4,353.2z M326.5,353.1l-0.1,0.1l-0.2-0.2L326.5,353.1z"/>
<polygon className="st16" points="326.5,353.1 326.3,352.8 326.2,353 "/>
<polygon className="st17" points="326.5,353.1 326.3,352.8 326.2,353 "/>
<path className="st16" d="M324.7,351.9l1-0.6l-0.2-0.2L324.7,351.9z M325,352l-0.2-0.1l1-0.6L325,352z"/>
<path className="st17" d="M324.7,351.9l1-0.6l-0.2-0.2L324.7,351.9z M325,352l-0.2-0.1l1-0.6L325,352z"/>
<path className="st16" d="M327.2,354.4l1.1-0.5l-0.2-0.2L327.2,354.4z M327.5,354.6l-0.2-0.2l1.1-0.5L327.5,354.6z"/>
<path className="st17" d="M327.2,354.4l1.1-0.5l-0.2-0.2L327.2,354.4z M327.5,354.6l-0.2-0.2l1.1-0.5L327.5,354.6z"/>
<path className="st16" d="M324.5,352.2l0.5-0.2l-0.2-0.1L324.5,352.2z M324.7,352.5l-0.2-0.2l0.5-0.2L324.7,352.5z"/>
<path className="st17" d="M324.5,352.2l0.5-0.2l-0.2-0.1L324.5,352.2z M324.7,352.5l-0.2-0.2l0.5-0.2L324.7,352.5z"/>
<path className="st16" d="M326.9,354.6h0.6l-0.2-0.2L326.9,354.6z M327.1,354.9l-0.2-0.2h0.6L327.1,354.9z"/>
<path className="st17" d="M326.9,354.6h0.6l-0.2-0.2L326.9,354.6z M327.1,354.9l-0.2-0.2h0.6L327.1,354.9z"/>
<path className="st16" d="M324.5,352.2l0.2,0.2L324.5,352.2z M324.8,352.6l-0.4-0.4l0.2,0.2L324.8,352.6z"/>
<path className="st17" d="M324.5,352.2l0.2,0.2L324.5,352.2z M324.8,352.6l-0.4-0.4l0.2,0.2L324.8,352.6z"/>
<path className="st16" d="M326.8,354.5l0.4,0.4l-0.2-0.2L326.8,354.5z M326.8,354.5L326.8,354.5l0.4,0.4L326.8,354.5z"/>
<path className="st17" d="M326.8,354.5l0.4,0.4l-0.2-0.2L326.8,354.5z M326.8,354.5L326.8,354.5l0.4,0.4L326.8,354.5z"/>
<path className="st16" d="M324.5,352.5l0.4,0.1l-0.4-0.4V352.5z M325,353l-0.5-0.5l0.4,0.1L325,353z"/>
<path className="st17" d="M324.5,352.5l0.4,0.1l-0.4-0.4V352.5z M325,353l-0.5-0.5l0.4,0.1L325,353z"/>
<path className="st16" d="M326.4,354.4l0.7,0.5l-0.4-0.4L326.4,354.4z M326.9,354.9l-0.5-0.5l0.7,0.5H326.9z"/>
<path className="st17" d="M326.4,354.4l0.7,0.5l-0.4-0.4L326.4,354.4z M326.9,354.9l-0.5-0.5l0.7,0.5H326.9z"/>
<path className="st16" d="M324.5,352.8l2.4,2l-2.4-2.4V352.8z M326.5,354.9l-2-2l2.4,2H326.5z"/>
<path className="st17" d="M324.5,352.8l2.4,2l-2.4-2.4V352.8z M326.5,354.9l-2-2l2.4,2H326.5z"/>
<path className="st16" d="M324.7,353.2l1.8,1.7l-2-2L324.7,353.2z M326.2,354.6l-1.4-1.4l1.8,1.7L326.2,354.6z"/>
<path className="st17" d="M324.7,353.2l1.8,1.7l-2-2L324.7,353.2z M326.2,354.6l-1.4-1.4l1.8,1.7L326.2,354.6z"/>
<line className="st18" x1="325.7" y1="351.3" x2="326.8" y2="352.4"/>
<line className="st18" x1="325" y1="353" x2="326.4" y2="354.4"/>
<line className="st18" x1="325" y1="352" x2="325.7" y2="351.3"/>
<line className="st18" x1="326" y1="351.6" x2="326.8" y2="352.4"/>
<line className="st18" x1="324.7" y1="353.2" x2="326.2" y2="354.6"/>
<line className="st18" x1="324.7" y1="351.9" x2="326.2" y2="350.4"/>
<line className="st18" x1="326.5" y1="352.6" x2="326.8" y2="352.8"/>
<path className="st18" d="M326.9,352.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C326.9,352.1,326.9,352.2,326.9,352.3"/>
<line className="st18" x1="326.5" y1="352.6" x2="326.9" y2="352.1"/>
<line className="st18" x1="326.8" y1="352.8" x2="327.2" y2="352.5"/>
<path className="st18" d="M326.2,353c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C326.1,352.8,326.1,353,326.2,353"/>
<polyline className="st18" points="327,352.6 328.1,353.7 327.2,354.4 "/>
<polyline className="st18" points="327.5,354.6 328.9,353.2 326.2,350.4 "/>
<path className="st18" d="M324.9,351.9c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M326.4,354.3c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M324.7,351.7c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M326.1,354.6c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="312.6,337.4 312.6,337.6 312.4,337.4 "/>
<polygon className="st17" points="312.6,337.4 312.6,337.6 312.4,337.4 "/>
<path className="st16" d="M311.2,336l3.1,2.5l-2.9-2.9L311.2,336z M313.9,338.8l-2.8-2.8l3.1,2.5L313.9,338.8z"/>
<path className="st17" d="M311.2,336l3.1,2.5l-2.9-2.9L311.2,336z M313.9,338.8l-2.8-2.8l3.1,2.5L313.9,338.8z"/>
<path className="st16" d="M311.2,336l1.4,1.3L311.2,336z M312.4,337.4l-1.2-1.3l1.4,1.3H312.4z"/>
<path className="st17" d="M311.2,336l1.4,1.3L311.2,336z M312.4,337.4l-1.2-1.3l1.4,1.3H312.4z"/>
<path className="st16" d="M312.6,337.6l1.3,1.2l-1.3-1.4V337.6z M312.6,337.6L312.6,337.6l1.3,1.2L312.6,337.6z"/>
<path className="st17" d="M312.6,337.6l1.3,1.2l-1.3-1.4V337.6z M312.6,337.6L312.6,337.6l1.3,1.2L312.6,337.6z"/>
<path className="st16" d="M311,336.2l1.3,1.2l-1.2-1.3L311,336.2z M312.2,337.4l-1.2-1.2l1.3,1.2H312.2z"/>
<path className="st17" d="M311,336.2l1.3,1.2l-1.2-1.3L311,336.2z M312.2,337.4l-1.2-1.2l1.3,1.2H312.2z"/>
<path className="st16" d="M312.2,337.4l0.4,0.2l-0.2-0.2H312.2z M312.6,337.7l-0.4-0.4l0.4,0.2V337.7z"/>
<path className="st17" d="M312.2,337.4l0.4,0.2l-0.2-0.2H312.2z M312.6,337.7l-0.4-0.4l0.4,0.2V337.7z"/>
<path className="st16" d="M312.6,337.7l1.3,1.1l-1.3-1.2V337.7z M313.8,338.9l-1.2-1.2l1.3,1.1L313.8,338.9z"/>
<path className="st17" d="M312.6,337.7l1.3,1.1l-1.3-1.2V337.7z M313.8,338.9l-1.2-1.2l1.3,1.1L313.8,338.9z"/>
<path className="st16" d="M311,336.2l1.2,1.2L311,336.2z M312.2,337.5l-1.2-1.3l1.2,1.2V337.5z"/>
<path className="st17" d="M311,336.2l1.2,1.2L311,336.2z M312.2,337.5l-1.2-1.3l1.2,1.2V337.5z"/>
<path className="st16" d="M312.5,337.7l1.3,1.2l-1.2-1.2H312.5z M312.5,337.7L312.5,337.7l1.3,1.2L312.5,337.7z M312.5,337.7
	L312.5,337.7z"/>
<path className="st17" d="M312.5,337.7l1.3,1.2l-1.2-1.2H312.5z M312.5,337.7L312.5,337.7l1.3,1.2L312.5,337.7z M312.5,337.7
	L312.5,337.7z"/>
<path className="st16" d="M312.2,337.5L312.2,337.5z"/>
<path className="st17" d="M312.2,337.5L312.2,337.5z"/>
<path className="st16" d="M310.9,336.2l1.3,1.3l-1.2-1.3H310.9z M310.9,336.2L310.9,336.2l1.3,1.3L310.9,336.2z"/>
<path className="st17" d="M310.9,336.2l1.3,1.3l-1.2-1.3H310.9z M310.9,336.2L310.9,336.2l1.3,1.3L310.9,336.2z"/>
<path className="st16" d="M312.5,337.7l1.3,1.2L312.5,337.7z M313.8,339l-1.3-1.3l1.3,1.2V339z"/>
<path className="st17" d="M312.5,337.7l1.3,1.2L312.5,337.7z M313.8,339l-1.3-1.3l1.3,1.2V339z"/>
<path className="st16" d="M310.9,336.2L310.9,336.2l1.3,1.3L310.9,336.2z M312.2,337.5l-1.3-1.3L312.2,337.5z"/>
<path className="st17" d="M310.9,336.2L310.9,336.2l1.3,1.3L310.9,336.2z M312.2,337.5l-1.3-1.3L312.2,337.5z"/>
<path className="st16" d="M312.2,337.5l0.4,0.2l-0.4-0.4V337.5z M312.5,337.7l-0.2-0.2l0.4,0.2H312.5z"/>
<path className="st17" d="M312.2,337.5l0.4,0.2l-0.4-0.4V337.5z M312.5,337.7l-0.2-0.2l0.4,0.2H312.5z"/>
<path className="st16" d="M312.5,337.7L312.5,337.7l1.3,1.3L312.5,337.7z M313.8,339l-1.3-1.3L313.8,339z"/>
<path className="st17" d="M312.5,337.7L312.5,337.7l1.3,1.3L312.5,337.7z M313.8,339l-1.3-1.3L313.8,339z"/>
<path className="st16" d="M312.1,337.5h0.1H312.1z M312.2,337.6l-0.1-0.1h0.1V337.6z"/>
<path className="st17" d="M312.1,337.5h0.1H312.1z M312.2,337.6l-0.1-0.1h0.1V337.6z"/>
<path className="st16" d="M312.2,337.6l0.2,0.1l-0.2-0.2V337.6z M312.4,337.7l-0.1-0.1l0.2,0.1H312.4z"/>
<path className="st17" d="M312.2,337.6l0.2,0.1l-0.2-0.2V337.6z M312.4,337.7l-0.1-0.1l0.2,0.1H312.4z"/>
<path className="st16" d="M312.4,337.7h0.1H312.4z M312.5,337.8l-0.1-0.1h0.1V337.8z"/>
<path className="st17" d="M312.4,337.7h0.1H312.4z M312.5,337.8l-0.1-0.1h0.1V337.8z"/>
<path className="st16" d="M312.1,337.5L312.1,337.5l0.1,0.1L312.1,337.5z M312.2,337.6l-0.1-0.1L312.2,337.6z"/>
<path className="st17" d="M312.1,337.5L312.1,337.5l0.1,0.1L312.1,337.5z M312.2,337.6l-0.1-0.1L312.2,337.6z"/>
<path className="st16" d="M312.2,337.6L312.2,337.6l0.1,0.1L312.2,337.6z M312.4,337.7l-0.1-0.1L312.4,337.7z"/>
<path className="st17" d="M312.2,337.6L312.2,337.6l0.1,0.1L312.2,337.6z M312.4,337.7l-0.1-0.1L312.4,337.7z"/>
<path className="st16" d="M312.4,337.7L312.4,337.7l0.1,0.1L312.4,337.7z M312.5,337.8l-0.1-0.1L312.5,337.8z"/>
<path className="st17" d="M312.4,337.7L312.4,337.7l0.1,0.1L312.4,337.7z M312.5,337.8l-0.1-0.1L312.5,337.8z"/>
<path className="st16" d="M312.1,337.6h0.1l-0.1-0.1V337.6z M312.1,337.6L312.1,337.6h0.1H312.1z"/>
<path className="st17" d="M312.1,337.6h0.1l-0.1-0.1V337.6z M312.1,337.6L312.1,337.6h0.1H312.1z"/>
<path className="st16" d="M312.2,337.6L312.2,337.6l0.1,0.1L312.2,337.6z M312.4,337.7l-0.1-0.1L312.4,337.7z"/>
<path className="st17" d="M312.2,337.6L312.2,337.6l0.1,0.1L312.2,337.6z M312.4,337.7l-0.1-0.1L312.4,337.7z"/>
<path className="st16" d="M312.4,337.7l0.1,0.1L312.4,337.7z M312.4,337.8v-0.1l0.1,0.1H312.4z"/>
<path className="st17" d="M312.4,337.7l0.1,0.1L312.4,337.7z M312.4,337.8v-0.1l0.1,0.1H312.4z"/>
<path className="st16" d="M312.1,337.6h0.1H312.1z M312.2,337.7l-0.1-0.1h0.1V337.7z"/>
<path className="st17" d="M312.1,337.6h0.1H312.1z M312.2,337.7l-0.1-0.1h0.1V337.7z"/>
<path className="st16" d="M312.2,337.7l0.1,0.1v-0.1H312.2z M312.2,337.7L312.2,337.7l0.1,0.1L312.2,337.7z"/>
<path className="st17" d="M312.2,337.7l0.1,0.1v-0.1H312.2z M312.2,337.7L312.2,337.7l0.1,0.1L312.2,337.7z"/>
<path className="st16" d="M310.8,336.4l1.4,1.1l-1.3-1.3L310.8,336.4z M312,337.6l-1.2-1.2l1.4,1.1L312,337.6z"/>
<path className="st17" d="M310.8,336.4l1.4,1.1l-1.3-1.3L310.8,336.4z M312,337.6l-1.2-1.2l1.4,1.1L312,337.6z"/>
<path className="st16" d="M312,337.6l0.4,0.2l-0.2-0.2H312z M312.4,338l-0.4-0.4l0.4,0.2V338z"/>
<path className="st17" d="M312,337.6l0.4,0.2l-0.2-0.2H312z M312.4,338l-0.4-0.4l0.4,0.2V338z"/>
<path className="st16" d="M312.4,338l1.4,1.1l-1.3-1.3L312.4,338z M313.6,339.2l-1.2-1.2l1.4,1.1L313.6,339.2z"/>
<path className="st17" d="M312.4,338l1.4,1.1l-1.3-1.3L312.4,338z M313.6,339.2l-1.2-1.2l1.4,1.1L313.6,339.2z"/>
<path className="st16" d="M311.8,337.8l0.6,0.1l-0.4-0.4L311.8,337.8z M312.1,338.2l-0.4-0.4l0.6,0.1L312.1,338.2z"/>
<path className="st17" d="M311.8,337.8l0.6,0.1l-0.4-0.4L311.8,337.8z M312.1,338.2l-0.4-0.4l0.6,0.1L312.1,338.2z"/>
<polygon className="st16" points="311.9,338.1 311.9,338.2 311.8,338.1 "/>
<polygon className="st17" points="311.9,338.1 311.9,338.2 311.8,338.1 "/>
<path className="st16" d="M311.5,338.1l0.4,0.1l-0.1-0.1H311.5z M311.9,338.4l-0.4-0.4l0.4,0.1V338.4z"/>
<path className="st17" d="M311.5,338.1l0.4,0.1l-0.1-0.1H311.5z M311.9,338.4l-0.4-0.4l0.4,0.1V338.4z"/>
<path className="st16" d="M311.8,338.4l-0.2-0.2v0.2H311.8z M311.9,338.4h-0.1l-0.2-0.2L311.9,338.4z"/>
<path className="st17" d="M311.8,338.4l-0.2-0.2v0.2H311.8z M311.9,338.4h-0.1l-0.2-0.2L311.9,338.4z"/>
<polygon className="st16" points="311.9,338.4 311.5,338.1 311.5,338.2 "/>
<polygon className="st17" points="311.9,338.4 311.5,338.1 311.5,338.2 "/>
<path className="st16" d="M310.1,337.1l1-0.5l-0.2-0.2L310.1,337.1z M310.3,337.4l-0.2-0.2l1-0.5L310.3,337.4z"/>
<path className="st17" d="M310.1,337.1l1-0.5l-0.2-0.2L310.1,337.1z M310.3,337.4l-0.2-0.2l1-0.5L310.3,337.4z"/>
<path className="st16" d="M312.6,339.6l1-0.5l-0.2-0.2L312.6,339.6z M312.8,339.9l-0.2-0.2l1-0.5L312.8,339.9z"/>
<path className="st17" d="M312.6,339.6l1-0.5l-0.2-0.2L312.6,339.6z M312.8,339.9l-0.2-0.2l1-0.5L312.8,339.9z"/>
<path className="st16" d="M309.8,337.6l0.5-0.2l-0.2-0.2L309.8,337.6z M310.1,337.8l-0.2-0.2l0.5-0.2L310.1,337.8z"/>
<path className="st17" d="M309.8,337.6l0.5-0.2l-0.2-0.2L309.8,337.6z M310.1,337.8l-0.2-0.2l0.5-0.2L310.1,337.8z"/>
<path className="st16" d="M312.1,339.9h0.7l-0.2-0.2L312.1,339.9z M312.4,340.1l-0.2-0.2h0.7L312.4,340.1z"/>
<path className="st17" d="M312.1,339.9h0.7l-0.2-0.2L312.1,339.9z M312.4,340.1l-0.2-0.2h0.7L312.4,340.1z"/>
<path className="st16" d="M309.7,337.6l0.4,0.2l-0.2-0.2H309.7z M309.7,337.6L309.7,337.6l0.4,0.2L309.7,337.6z"/>
<path className="st17" d="M309.7,337.6l0.4,0.2l-0.2-0.2H309.7z M309.7,337.6L309.7,337.6l0.4,0.2L309.7,337.6z"/>
<path className="st16" d="M312.1,339.9l0.2,0.2L312.1,339.9z M312.4,340.2l-0.2-0.4l0.2,0.2V340.2z"/>
<path className="st17" d="M312.1,339.9l0.2,0.2L312.1,339.9z M312.4,340.2l-0.2-0.4l0.2,0.2V340.2z"/>
<path className="st16" d="M309.7,337.8h0.4l-0.4-0.2V337.8z M310.3,338.3l-0.6-0.5h0.4L310.3,338.3z"/>
<path className="st17" d="M309.7,337.8h0.4l-0.4-0.2V337.8z M310.3,338.3l-0.6-0.5h0.4L310.3,338.3z"/>
<path className="st16" d="M311.6,339.6l0.7,0.6l-0.2-0.4L311.6,339.6z M312.1,340.2l-0.5-0.6l0.7,0.6H312.1z"/>
<path className="st17" d="M311.6,339.6l0.7,0.6l-0.2-0.4L311.6,339.6z M312.1,340.2l-0.5-0.6l0.7,0.6H312.1z"/>
<path className="st16" d="M309.7,338.1l2.4,2.2l-2.4-2.4V338.1z M311.9,340.2l-2.2-2.2l2.4,2.2H311.9z"/>
<path className="st17" d="M309.7,338.1l2.4,2.2l-2.4-2.4V338.1z M311.9,340.2l-2.2-2.2l2.4,2.2H311.9z"/>
<path className="st16" d="M310.1,338.6l1.8,1.7l-2.2-2.2L310.1,338.6z M311.4,339.9l-1.3-1.3l1.8,1.7L311.4,339.9z"/>
<path className="st17" d="M310.1,338.6l1.8,1.7l-2.2-2.2L310.1,338.6z M311.4,339.9l-1.3-1.3l1.8,1.7L311.4,339.9z"/>
<line className="st18" x1="311" y1="336.6" x2="312" y2="337.6"/>
<line className="st18" x1="310.3" y1="338.3" x2="311.6" y2="339.6"/>
<line className="st18" x1="310.3" y1="337.4" x2="311" y2="336.6"/>
<line className="st18" x1="311.4" y1="337" x2="312" y2="337.6"/>
<line className="st18" x1="310.1" y1="338.6" x2="311.4" y2="339.9"/>
<line className="st18" x1="310.1" y1="337.1" x2="311.4" y2="335.7"/>
<line className="st18" x1="311.8" y1="337.8" x2="312.1" y2="338.2"/>
<path className="st18" d="M312.2,337.7c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C312.1,337.5,312.1,337.6,312.2,337.7"/>
<line className="st18" x1="311.8" y1="337.8" x2="312.2" y2="337.5"/>
<line className="st18" x1="312.1" y1="338.2" x2="312.5" y2="337.7"/>
<path className="st18" d="M311.6,338.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C311.5,338.1,311.5,338.2,311.6,338.3"/>
<polyline className="st18" points="312.4,338 313.3,338.9 312.6,339.6 "/>
<polyline className="st18" points="312.8,339.9 314.3,338.6 311.4,335.7 "/>
<path className="st18" d="M310.2,337.3c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M311.6,339.7c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M309.9,337.1c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M311.3,339.9c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="292.2,317.1 292.3,317.2 292.1,317.1 "/>
<polygon className="st17" points="292.2,317.1 292.3,317.2 292.1,317.1 "/>
<path className="st16" d="M290.9,315.8l3,2.5l-2.8-2.9L290.9,315.8z M293.6,318.5l-2.8-2.8l3,2.5L293.6,318.5z"/>
<path className="st17" d="M290.9,315.8l3,2.5l-2.8-2.9L290.9,315.8z M293.6,318.5l-2.8-2.8l3,2.5L293.6,318.5z"/>
<path className="st16" d="M290.8,315.8l1.4,1.3l-1.3-1.3H290.8z M292.1,317.1l-1.3-1.3l1.4,1.3H292.1z"/>
<path className="st17" d="M290.8,315.8l1.4,1.3l-1.3-1.3H290.8z M292.1,317.1l-1.3-1.3l1.4,1.3H292.1z"/>
<path className="st16" d="M292.3,317.2l1.3,1.3l-1.4-1.4L292.3,317.2z M292.3,317.2L292.3,317.2l1.3,1.3L292.3,317.2z"/>
<path className="st17" d="M292.3,317.2l1.3,1.3l-1.4-1.4L292.3,317.2z M292.3,317.2L292.3,317.2l1.3,1.3L292.3,317.2z"/>
<path className="st16" d="M290.8,315.9l1.3,1.2l-1.3-1.3V315.9z M292,317.1l-1.2-1.2l1.3,1.2H292z"/>
<path className="st17" d="M290.8,315.9l1.3,1.2l-1.3-1.3V315.9z M292,317.1l-1.2-1.2l1.3,1.2H292z"/>
<path className="st16" d="M292,317.1l0.4,0.1l-0.2-0.1H292z M292.2,317.4l-0.2-0.4l0.4,0.1L292.2,317.4z"/>
<path className="st17" d="M292,317.1l0.4,0.1l-0.2-0.1H292z M292.2,317.4l-0.2-0.4l0.4,0.1L292.2,317.4z"/>
<path className="st16" d="M292.2,317.4l1.4,1.1l-1.3-1.3L292.2,317.4z M293.5,318.6l-1.3-1.2l1.4,1.1L293.5,318.6z"/>
<path className="st17" d="M292.2,317.4l1.4,1.1l-1.3-1.3L292.2,317.4z M293.5,318.6l-1.3-1.2l1.4,1.1L293.5,318.6z"/>
<path className="st16" d="M290.6,315.9l1.3,1.2l-1.2-1.2H290.6z M292,317.2l-1.3-1.3l1.3,1.2V317.2z"/>
<path className="st17" d="M290.6,315.9l1.3,1.2l-1.2-1.2H290.6z M292,317.2l-1.3-1.3l1.3,1.2V317.2z"/>
<path className="st16" d="M292.2,317.4L292.2,317.4l1.3,1.2L292.2,317.4z M293.5,318.6l-1.3-1.2L293.5,318.6z"/>
<path className="st17" d="M292.2,317.4L292.2,317.4l1.3,1.2L292.2,317.4z M293.5,318.6l-1.3-1.2L293.5,318.6z"/>
<path className="st16" d="M292.2,317.4L292.2,317.4z"/>
<path className="st17" d="M292.2,317.4L292.2,317.4z"/>
<path className="st16" d="M292,317.2L292,317.2z"/>
<path className="st17" d="M292,317.2L292,317.2z"/>
<path className="st16" d="M290.6,315.9L290.6,315.9l1.3,1.3L290.6,315.9z M292,317.2l-1.3-1.3L292,317.2z"/>
<path className="st17" d="M290.6,315.9L290.6,315.9l1.3,1.3L290.6,315.9z M292,317.2l-1.3-1.3L292,317.2z"/>
<path className="st16" d="M292.2,317.4l1.3,1.2L292.2,317.4z M293.5,318.8l-1.3-1.3l1.3,1.2V318.8z"/>
<path className="st17" d="M292.2,317.4l1.3,1.2L292.2,317.4z M293.5,318.8l-1.3-1.3l1.3,1.2V318.8z"/>
<path className="st16" d="M290.6,315.9L290.6,315.9l1.3,1.3L290.6,315.9z M292,317.2l-1.3-1.3L292,317.2z"/>
<path className="st17" d="M290.6,315.9L290.6,315.9l1.3,1.3L290.6,315.9z M292,317.2l-1.3-1.3L292,317.2z"/>
<path className="st16" d="M292,317.2l0.2,0.2l-0.2-0.4V317.2z M292,317.2L292,317.2l0.2,0.2L292,317.2z"/>
<path className="st17" d="M292,317.2l0.2,0.2l-0.2-0.4V317.2z M292,317.2L292,317.2l0.2,0.2L292,317.2z"/>
<path className="st16" d="M292.2,317.4l1.3,1.3L292.2,317.4z M293.4,318.8l-1.2-1.3l1.3,1.3H293.4z"/>
<path className="st17" d="M292.2,317.4l1.3,1.3L292.2,317.4z M293.4,318.8l-1.2-1.3l1.3,1.3H293.4z"/>
<path className="st16" d="M291.8,317.2L291.8,317.2h0.1H291.8z M292,317.2h-0.1H292z"/>
<path className="st17" d="M291.8,317.2L291.8,317.2h0.1H291.8z M292,317.2h-0.1H292z"/>
<path className="st16" d="M292,317.3l0.2,0.1l-0.2-0.2V317.3z M292.1,317.4l-0.1-0.1l0.2,0.1H292.1z"/>
<path className="st17" d="M292,317.3l0.2,0.1l-0.2-0.2V317.3z M292.1,317.4l-0.1-0.1l0.2,0.1H292.1z"/>
<path className="st16" d="M292.1,317.4L292.1,317.4h0.1H292.1z M292.2,317.4h-0.1H292.2z"/>
<path className="st17" d="M292.1,317.4L292.1,317.4h0.1H292.1z M292.2,317.4h-0.1H292.2z"/>
<path className="st16" d="M291.8,317.2h0.1H291.8z M292,317.3l-0.1-0.1h0.1V317.3z"/>
<path className="st17" d="M291.8,317.2h0.1H291.8z M292,317.3l-0.1-0.1h0.1V317.3z"/>
<path className="st16" d="M292,317.3L292,317.3l0.1,0.1L292,317.3z M292.1,317.4l-0.1-0.1L292.1,317.4z"/>
<path className="st17" d="M292,317.3L292,317.3l0.1,0.1L292,317.3z M292.1,317.4l-0.1-0.1L292.1,317.4z"/>
<path className="st16" d="M292.1,317.4h0.1H292.1z M292.2,317.6l-0.1-0.1h0.1V317.6z"/>
<path className="st17" d="M292.1,317.4h0.1H292.1z M292.2,317.6l-0.1-0.1h0.1V317.6z"/>
<path className="st16" d="M291.8,317.2L291.8,317.2l0.1,0.1L291.8,317.2z M292,317.3l-0.1-0.1L292,317.3z"/>
<path className="st17" d="M291.8,317.2L291.8,317.2l0.1,0.1L291.8,317.2z M292,317.3l-0.1-0.1L292,317.3z"/>
<path className="st16" d="M292,317.3l0.1,0.1L292,317.3z M292,317.4v-0.1l0.1,0.1H292z"/>
<path className="st17" d="M292,317.3l0.1,0.1L292,317.3z M292,317.4v-0.1l0.1,0.1H292z"/>
<path className="st16" d="M292.1,317.4l0.1,0.1L292.1,317.4z M292.1,317.6v-0.1l0.1,0.1H292.1z"/>
<path className="st17" d="M292.1,317.4l0.1,0.1L292.1,317.4z M292.1,317.6v-0.1l0.1,0.1H292.1z"/>
<path className="st16" d="M291.8,317.3h0.1l-0.1-0.1V317.3z M292,317.4l-0.1-0.1h0.1V317.4z"/>
<path className="st17" d="M291.8,317.3h0.1l-0.1-0.1V317.3z M292,317.4l-0.1-0.1h0.1V317.4z"/>
<path className="st16" d="M292,317.4L292,317.4l0.1,0.1L292,317.4z M292.1,317.6l-0.1-0.1L292.1,317.6z"/>
<path className="st17" d="M292,317.4L292,317.4l0.1,0.1L292,317.4z M292.1,317.6l-0.1-0.1L292.1,317.6z"/>
<path className="st16" d="M290.5,316.1l1.4,1.1l-1.3-1.3L290.5,316.1z M291.7,317.3l-1.2-1.2l1.4,1.1L291.7,317.3z"/>
<path className="st17" d="M290.5,316.1l1.4,1.1l-1.3-1.3L290.5,316.1z M291.7,317.3l-1.2-1.2l1.4,1.1L291.7,317.3z"/>
<path className="st16" d="M291.7,317.3l0.4,0.2l-0.2-0.2H291.7z M292,317.7l-0.2-0.4l0.4,0.2L292,317.7z"/>
<path className="st17" d="M291.7,317.3l0.4,0.2l-0.2-0.2H291.7z M292,317.7l-0.2-0.4l0.4,0.2L292,317.7z"/>
<path className="st16" d="M292,317.7l1.4,1.1l-1.2-1.3L292,317.7z M293.3,318.9l-1.3-1.2l1.4,1.1L293.3,318.9z"/>
<path className="st17" d="M292,317.7l1.4,1.1l-1.2-1.3L292,317.7z M293.3,318.9l-1.3-1.2l1.4,1.1L293.3,318.9z"/>
<path className="st16" d="M291.5,317.6l0.5,0.1l-0.2-0.4L291.5,317.6z M291.8,317.8l-0.4-0.2l0.5,0.1L291.8,317.8z"/>
<path className="st17" d="M291.5,317.6l0.5,0.1l-0.2-0.4L291.5,317.6z M291.8,317.8l-0.4-0.2l0.5,0.1L291.8,317.8z"/>
<polygon className="st16" points="291.6,317.8 291.6,317.9 291.5,317.8 "/>
<polygon className="st17" points="291.6,317.8 291.6,317.9 291.5,317.8 "/>
<path className="st16" d="M291.2,317.8l0.4,0.1l-0.1-0.1H291.2z M291.6,318.2l-0.4-0.4l0.4,0.1V318.2z"/>
<path className="st17" d="M291.2,317.8l0.4,0.1l-0.1-0.1H291.2z M291.6,318.2l-0.4-0.4l0.4,0.1V318.2z"/>
<path className="st16" d="M291.5,318.2l-0.2-0.2v0.2H291.5z M291.6,318.2h-0.1l-0.2-0.2L291.6,318.2z"/>
<path className="st17" d="M291.5,318.2l-0.2-0.2v0.2H291.5z M291.6,318.2h-0.1l-0.2-0.2L291.6,318.2z"/>
<polygon className="st16" points="291.6,318.2 291.2,317.8 291.2,317.9 "/>
<polygon className="st17" points="291.6,318.2 291.2,317.8 291.2,317.9 "/>
<path className="st16" d="M289.8,316.8l1-0.5l-0.2-0.2L289.8,316.8z M289.9,317.1l-0.1-0.2l1-0.5L289.9,317.1z"/>
<path className="st17" d="M289.8,316.8l1-0.5l-0.2-0.2L289.8,316.8z M289.9,317.1l-0.1-0.2l1-0.5L289.9,317.1z"/>
<path className="st16" d="M292.3,319.4l1-0.5l-0.2-0.2L292.3,319.4z M292.6,319.6l-0.2-0.2l1-0.5L292.6,319.6z"/>
<path className="st17" d="M292.3,319.4l1-0.5l-0.2-0.2L292.3,319.4z M292.6,319.6l-0.2-0.2l1-0.5L292.6,319.6z"/>
<path className="st16" d="M289.4,317.2l0.5-0.1l-0.1-0.2L289.4,317.2z M289.8,317.6l-0.4-0.4l0.5-0.1L289.8,317.6z"/>
<path className="st17" d="M289.4,317.2l0.5-0.1l-0.1-0.2L289.4,317.2z M289.8,317.6l-0.4-0.4l0.5-0.1L289.8,317.6z"/>
<path className="st16" d="M291.8,319.6h0.7l-0.2-0.2L291.8,319.6z M292.1,319.8l-0.2-0.2h0.7L292.1,319.8z"/>
<path className="st17" d="M291.8,319.6h0.7l-0.2-0.2L291.8,319.6z M292.1,319.8l-0.2-0.2h0.7L292.1,319.8z"/>
<path className="st16" d="M289.4,317.3l0.4,0.2l-0.4-0.4V317.3z M289.4,317.3L289.4,317.3l0.4,0.2L289.4,317.3z"/>
<path className="st17" d="M289.4,317.3l0.4,0.2l-0.4-0.4V317.3z M289.4,317.3L289.4,317.3l0.4,0.2L289.4,317.3z"/>
<path className="st16" d="M291.7,319.6l0.4,0.2l-0.2-0.2H291.7z M291.7,319.6L291.7,319.6l0.4,0.2L291.7,319.6z"/>
<path className="st17" d="M291.7,319.6l0.4,0.2l-0.2-0.2H291.7z M291.7,319.6L291.7,319.6l0.4,0.2L291.7,319.6z"/>
<path className="st16" d="M289.4,317.6h0.4l-0.4-0.2V317.6z M289.9,318l-0.5-0.5h0.4L289.9,318z"/>
<path className="st17" d="M289.4,317.6h0.4l-0.4-0.2V317.6z M289.9,318l-0.5-0.5h0.4L289.9,318z"/>
<path className="st16" d="M291.4,319.4l0.7,0.5l-0.4-0.2L291.4,319.4z M291.8,319.8l-0.5-0.5l0.7,0.5H291.8z"/>
<path className="st17" d="M291.4,319.4l0.7,0.5l-0.4-0.2L291.4,319.4z M291.8,319.8l-0.5-0.5l0.7,0.5H291.8z"/>
<path className="st16" d="M289.4,317.8l2.4,2l-2.4-2.3V317.8z M291.6,319.8l-2.2-2l2.4,2H291.6z"/>
<path className="st17" d="M289.4,317.8l2.4,2l-2.4-2.3V317.8z M291.6,319.8l-2.2-2l2.4,2H291.6z"/>
<path className="st16" d="M289.8,318.3l1.8,1.6l-2.2-2L289.8,318.3z M291.1,319.6l-1.3-1.3l1.8,1.6L291.1,319.6z"/>
<path className="st17" d="M289.8,318.3l1.8,1.6l-2.2-2L289.8,318.3z M291.1,319.6l-1.3-1.3l1.8,1.6L291.1,319.6z"/>
<line className="st18" x1="290.8" y1="316.4" x2="291.7" y2="317.3"/>
<line className="st18" x1="289.9" y1="318" x2="291.4" y2="319.4"/>
<line className="st18" x1="289.9" y1="317.1" x2="290.8" y2="316.4"/>
<line className="st18" x1="291.1" y1="316.7" x2="291.7" y2="317.3"/>
<line className="st18" x1="289.8" y1="318.3" x2="291.1" y2="319.6"/>
<line className="st18" x1="289.8" y1="316.8" x2="291.1" y2="315.4"/>
<line className="st18" x1="291.5" y1="317.6" x2="291.8" y2="317.8"/>
<path className="st18" d="M291.9,317.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C291.8,317.1,291.8,317.2,291.9,317.3"/>
<line className="st18" x1="291.5" y1="317.6" x2="292" y2="317.2"/>
<line className="st18" x1="291.8" y1="317.8" x2="292.2" y2="317.4"/>
<path className="st18" d="M291.3,318c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C291.2,317.8,291.2,317.9,291.3,318"/>
<polyline className="st18" points="292,317.7 293,318.6 292.3,319.4 "/>
<polyline className="st18" points="292.6,319.6 293.9,318.3 291.1,315.4 "/>
<path className="st18" d="M289.9,317c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M291.3,319.3c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M289.6,316.8c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M291.1,319.5c0.4,0.4,1,0.4,1.4,0"/>
<polygon className="st16" points="277.6,302.4 277.7,302.6 277.4,302.3 "/>
<polygon className="st17" points="277.6,302.4 277.7,302.6 277.4,302.3 "/>
<path className="st16" d="M276.1,301l3.1,2.5l-2.8-2.8L276.1,301z M279,303.9l-2.9-2.9l3.1,2.5L279,303.9z"/>
<path className="st17" d="M276.1,301l3.1,2.5l-2.8-2.8L276.1,301z M279,303.9l-2.9-2.9l3.1,2.5L279,303.9z"/>
<path className="st16" d="M276.1,301.1l1.4,1.3l-1.4-1.4V301.1z M277.4,302.3l-1.3-1.2l1.4,1.3L277.4,302.3z"/>
<path className="st17" d="M276.1,301.1l1.4,1.3l-1.4-1.4V301.1z M277.4,302.3l-1.3-1.2l1.4,1.3L277.4,302.3z"/>
<path className="st16" d="M277.7,302.6l1.3,1.3l-1.4-1.4L277.7,302.6z M278.9,303.9l-1.2-1.3l1.3,1.3H278.9z"/>
<path className="st17" d="M277.7,302.6l1.3,1.3l-1.4-1.4L277.7,302.6z M278.9,303.9l-1.2-1.3l1.3,1.3H278.9z"/>
<path className="st16" d="M276,301.1l1.4,1.2l-1.3-1.2H276z M277.3,302.4l-1.3-1.3l1.4,1.2L277.3,302.4z"/>
<path className="st17" d="M276,301.1l1.4,1.2l-1.3-1.2H276z M277.3,302.4l-1.3-1.3l1.4,1.2L277.3,302.4z"/>
<path className="st16" d="M277.3,302.4l0.4,0.1l-0.2-0.2L277.3,302.4z M277.6,302.7l-0.2-0.2l0.4,0.1L277.6,302.7z"/>
<path className="st17" d="M277.3,302.4l0.4,0.1l-0.2-0.2L277.3,302.4z M277.6,302.7l-0.2-0.2l0.4,0.1L277.6,302.7z"/>
<path className="st16" d="M277.6,302.7l1.3,1.2l-1.2-1.3L277.6,302.7z M278.9,304l-1.3-1.3l1.3,1.2V304z"/>
<path className="st17" d="M277.6,302.7l1.3,1.2l-1.2-1.3L277.6,302.7z M278.9,304l-1.3-1.3l1.3,1.2V304z"/>
<path className="st16" d="M276,301.2l1.3,1.2l-1.3-1.3V301.2z M276,301.2L276,301.2l1.3,1.2L276,301.2z"/>
<path className="st17" d="M276,301.2l1.3,1.2l-1.3-1.3V301.2z M276,301.2L276,301.2l1.3,1.2L276,301.2z"/>
<path className="st16" d="M277.6,302.7l1.3,1.3L277.6,302.7z M278.8,304l-1.2-1.3l1.3,1.3H278.8z"/>
<path className="st17" d="M277.6,302.7l1.3,1.3L277.6,302.7z M278.8,304l-1.2-1.3l1.3,1.3H278.8z"/>
<path className="st16" d="M277.6,302.7l-0.1,0.1L277.6,302.7z"/>
<path className="st17" d="M277.6,302.7l-0.1,0.1L277.6,302.7z"/>
<path className="st16" d="M277.2,302.4v0.1V302.4z"/>
<path className="st17" d="M277.2,302.4v0.1V302.4z"/>
<path className="st16" d="M276,301.2l1.3,1.2L276,301.2z M277.2,302.4l-1.2-1.2l1.3,1.2H277.2z"/>
<path className="st17" d="M276,301.2l1.3,1.2L276,301.2z M277.2,302.4l-1.2-1.2l1.3,1.2H277.2z"/>
<path className="st16" d="M277.6,302.7L277.6,302.7l1.2,1.3L277.6,302.7z M278.8,304l-1.2-1.3L278.8,304z"/>
<path className="st17" d="M277.6,302.7L277.6,302.7l1.2,1.3L277.6,302.7z M278.8,304l-1.2-1.3L278.8,304z"/>
<path className="st16" d="M276,301.2L276,301.2l1.2,1.2L276,301.2z M277.2,302.4l-1.2-1.2L277.2,302.4z"/>
<path className="st17" d="M276,301.2L276,301.2l1.2,1.2L276,301.2z M277.2,302.4l-1.2-1.2L277.2,302.4z"/>
<path className="st16" d="M277.2,302.6l0.4,0.1l-0.2-0.2L277.2,302.6z M277.4,302.8l-0.2-0.2l0.4,0.1L277.4,302.8z"/>
<path className="st17" d="M277.2,302.6l0.4,0.1l-0.2-0.2L277.2,302.6z M277.4,302.8l-0.2-0.2l0.4,0.1L277.4,302.8z"/>
<path className="st16" d="M277.4,302.8l1.3,1.2l-1.2-1.3L277.4,302.8z M277.4,302.8L277.4,302.8l1.3,1.2L277.4,302.8z"/>
<path className="st17" d="M277.4,302.8l1.3,1.2l-1.2-1.3L277.4,302.8z M277.4,302.8L277.4,302.8l1.3,1.2L277.4,302.8z"/>
<path className="st16" d="M277.2,302.6L277.2,302.6z"/>
<path className="st17" d="M277.2,302.6L277.2,302.6z"/>
<path className="st16" d="M277.2,302.6v-0.1V302.6z"/>
<path className="st17" d="M277.2,302.6v-0.1V302.6z"/>
<path className="st16" d="M277.2,302.6L277.2,302.6l0.2,0.2L277.2,302.6z M277.4,302.8l-0.2-0.2L277.4,302.8z"/>
<path className="st17" d="M277.2,302.6L277.2,302.6l0.2,0.2L277.2,302.6z M277.4,302.8l-0.2-0.2L277.4,302.8z"/>
<path className="st16" d="M277.4,302.8L277.4,302.8z M277.4,302.8L277.4,302.8z"/>
<path className="st17" d="M277.4,302.8L277.4,302.8z M277.4,302.8L277.4,302.8z"/>
<path className="st16" d="M277.2,302.6L277.2,302.6l0.2,0.2L277.2,302.6z M277.2,302.6L277.2,302.6z M277.2,302.6L277.2,302.6z
	 M277.4,302.8l-0.2-0.2L277.4,302.8z"/>
<path className="st17" d="M277.2,302.6L277.2,302.6l0.2,0.2L277.2,302.6z M277.2,302.6L277.2,302.6z M277.2,302.6L277.2,302.6z
	 M277.4,302.8l-0.2-0.2L277.4,302.8z"/>
<path className="st16" d="M277.4,302.8L277.4,302.8z M277.4,302.8L277.4,302.8z"/>
<path className="st17" d="M277.4,302.8L277.4,302.8z M277.4,302.8L277.4,302.8z"/>
<path className="st16" d="M277.2,302.6v0.1V302.6z"/>
<path className="st17" d="M277.2,302.6v0.1V302.6z"/>
<path className="st16" d="M277.2,302.6L277.2,302.6z"/>
<path className="st17" d="M277.2,302.6L277.2,302.6z"/>
<path className="st16" d="M277.3,302.7l0.1,0.1l-0.2-0.2L277.3,302.7z M277.3,302.7L277.3,302.7l0.1,0.1L277.3,302.7z"/>
<path className="st17" d="M277.3,302.7l0.1,0.1l-0.2-0.2L277.3,302.7z M277.3,302.7L277.3,302.7l0.1,0.1L277.3,302.7z"/>
<path className="st16" d="M277.3,302.8L277.3,302.8h0.1H277.3z M277.4,302.8h-0.1H277.4z"/>
<path className="st17" d="M277.3,302.8L277.3,302.8h0.1H277.3z M277.4,302.8h-0.1H277.4z"/>
<path className="st16" d="M277.2,302.6L277.2,302.6l0.1,0.1L277.2,302.6z M277.3,302.7l-0.1-0.1L277.3,302.7z"/>
<path className="st17" d="M277.2,302.6L277.2,302.6l0.1,0.1L277.2,302.6z M277.3,302.7l-0.1-0.1L277.3,302.7z"/>
<path className="st16" d="M277.3,302.7L277.3,302.7l0.1,0.1L277.3,302.7z M277.4,302.8l-0.1-0.1L277.4,302.8z"/>
<path className="st17" d="M277.3,302.7L277.3,302.7l0.1,0.1L277.3,302.7z M277.4,302.8l-0.1-0.1L277.4,302.8z"/>
<path className="st16" d="M275.8,301.4l1.4,1.1l-1.2-1.2L275.8,301.4z M277.1,302.7l-1.3-1.3l1.4,1.1L277.1,302.7z"/>
<path className="st17" d="M275.8,301.4l1.4,1.1l-1.2-1.2L275.8,301.4z M277.1,302.7l-1.3-1.3l1.4,1.1L277.1,302.7z"/>
<path className="st16" d="M277.1,302.7l0.4,0.1l-0.2-0.2L277.1,302.7z M277.3,302.9l-0.2-0.2l0.4,0.1L277.3,302.9z"/>
<path className="st17" d="M277.1,302.7l0.4,0.1l-0.2-0.2L277.1,302.7z M277.3,302.9l-0.2-0.2l0.4,0.1L277.3,302.9z"/>
<path className="st16" d="M277.3,302.9l1.4,1.1l-1.3-1.2L277.3,302.9z M278.6,304.2l-1.3-1.3l1.4,1.1L278.6,304.2z"/>
<path className="st17" d="M277.3,302.9l1.4,1.1l-1.3-1.2L277.3,302.9z M278.6,304.2l-1.3-1.3l1.4,1.1L278.6,304.2z"/>
<path className="st16" d="M276.8,302.9h0.5l-0.2-0.2L276.8,302.9z M277.1,303.2l-0.2-0.2h0.5L277.1,303.2z"/>
<path className="st17" d="M276.8,302.9h0.5l-0.2-0.2L276.8,302.9z M277.1,303.2l-0.2-0.2h0.5L277.1,303.2z"/>
<polygon className="st16" points="276.8,303.2 277,303.3 276.7,303 "/>
<polygon className="st17" points="276.8,303.2 277,303.3 276.7,303 "/>
<path className="st16" d="M276.6,303.2l0.4,0.1l-0.2-0.2L276.6,303.2z M276.8,303.4l-0.2-0.2l0.4,0.1L276.8,303.4z"/>
<path className="st17" d="M276.6,303.2l0.4,0.1l-0.2-0.2L276.6,303.2z M276.8,303.4l-0.2-0.2l0.4,0.1L276.8,303.4z"/>
<path className="st16" d="M276.7,303.5l-0.2-0.2l0.1,0.1L276.7,303.5z M276.8,303.4l-0.1,0.1l-0.2-0.2L276.8,303.4z"/>
<path className="st17" d="M276.7,303.5l-0.2-0.2l0.1,0.1L276.7,303.5z M276.8,303.4l-0.1,0.1l-0.2-0.2L276.8,303.4z"/>
<polygon className="st16" points="276.8,303.4 276.6,303.2 276.5,303.3 "/>
<polygon className="st17" points="276.8,303.4 276.6,303.2 276.5,303.3 "/>
<path className="st16" d="M275,302.2l1-0.6l-0.2-0.2L275,302.2z M275.3,302.3l-0.2-0.1l1-0.6L275.3,302.3z"/>
<path className="st17" d="M275,302.2l1-0.6l-0.2-0.2L275,302.2z M275.3,302.3l-0.2-0.1l1-0.6L275.3,302.3z"/>
<path className="st16" d="M277.6,304.7l1.1-0.5l-0.2-0.2L277.6,304.7z M277.8,305l-0.2-0.2l1.1-0.5L277.8,305z"/>
<path className="st17" d="M277.6,304.7l1.1-0.5l-0.2-0.2L277.6,304.7z M277.8,305l-0.2-0.2l1.1-0.5L277.8,305z"/>
<path className="st16" d="M274.8,302.6l0.5-0.2l-0.2-0.1L274.8,302.6z M275,302.8l-0.2-0.2l0.5-0.2L275,302.8z"/>
<path className="st17" d="M274.8,302.6l0.5-0.2l-0.2-0.1L274.8,302.6z M275,302.8l-0.2-0.2l0.5-0.2L275,302.8z"/>
<path className="st16" d="M277.2,304.8l0.6,0.1l-0.2-0.2L277.2,304.8z M277.4,305.2l-0.2-0.4l0.6,0.1L277.4,305.2z"/>
<path className="st17" d="M277.2,304.8l0.6,0.1l-0.2-0.2L277.2,304.8z M277.4,305.2l-0.2-0.4l0.6,0.1L277.4,305.2z"/>
<path className="st16" d="M274.8,302.6l0.2,0.2L274.8,302.6z M275.2,302.9l-0.4-0.4l0.2,0.2L275.2,302.9z"/>
<path className="st17" d="M274.8,302.6l0.2,0.2L274.8,302.6z M275.2,302.9l-0.4-0.4l0.2,0.2L275.2,302.9z"/>
<path className="st16" d="M277.1,304.8l0.4,0.4l-0.2-0.4H277.1z M277.1,304.8L277.1,304.8l0.4,0.4L277.1,304.8z"/>
<path className="st17" d="M277.1,304.8l0.4,0.4l-0.2-0.4H277.1z M277.1,304.8L277.1,304.8l0.4,0.4L277.1,304.8z"/>
<path className="st16" d="M274.8,302.8l0.4,0.1l-0.4-0.4V302.8z M275.3,303.3l-0.5-0.5l0.4,0.1L275.3,303.3z"/>
<path className="st17" d="M274.8,302.8l0.4,0.1l-0.4-0.4V302.8z M275.3,303.3l-0.5-0.5l0.4,0.1L275.3,303.3z"/>
<path className="st16" d="M276.7,304.7l0.7,0.5l-0.4-0.4L276.7,304.7z M277.2,305.2l-0.5-0.5l0.7,0.5H277.2z"/>
<path className="st17" d="M276.7,304.7l0.7,0.5l-0.4-0.4L276.7,304.7z M277.2,305.2l-0.5-0.5l0.7,0.5H277.2z"/>
<path className="st16" d="M274.8,303l2.4,2.2l-2.4-2.4V303z M276.8,305.2l-2-2.2l2.4,2.2H276.8z"/>
<path className="st17" d="M274.8,303l2.4,2.2l-2.4-2.4V303z M276.8,305.2l-2-2.2l2.4,2.2H276.8z"/>
<path className="st16" d="M275,303.5l1.8,1.7l-2-2.2L275,303.5z M276.5,305l-1.4-1.4l1.8,1.7L276.5,305z"/>
<path className="st17" d="M275,303.5l1.8,1.7l-2-2.2L275,303.5z M276.5,305l-1.4-1.4l1.8,1.7L276.5,305z"/>
<line className="st18" x1="276" y1="301.6" x2="277.1" y2="302.7"/>
<line className="st18" x1="275.3" y1="303.3" x2="276.7" y2="304.7"/>
<line className="st18" x1="275.3" y1="302.3" x2="276" y2="301.6"/>
<line className="st18" x1="276.4" y1="302" x2="277.1" y2="302.7"/>
<line className="st18" x1="275" y1="303.5" x2="276.5" y2="305"/>
<line className="st18" x1="275" y1="302.2" x2="276.5" y2="300.8"/>
<line className="st18" x1="276.8" y1="302.9" x2="277.1" y2="303.2"/>
<path className="st18" d="M277.3,302.6c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C277.2,302.4,277.2,302.6,277.3,302.6"/>
<line className="st18" x1="276.8" y1="302.9" x2="277.2" y2="302.4"/>
<line className="st18" x1="277.1" y1="303.2" x2="277.6" y2="302.7"/>
<path className="st18" d="M276.5,303.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C276.5,303.2,276.5,303.3,276.5,303.3"/>
<polyline className="st18" points="277.3,302.9 278.4,304 277.6,304.7 "/>
<polyline className="st18" points="277.8,305 279.2,303.5 276.5,300.8 "/>
<path className="st18" d="M275.2,302.3c-0.3,0.3-0.3,0.7,0,0.9"/>
<path className="st18" d="M276.7,304.6c0.3,0.3,0.7,0.3,0.9,0"/>
<path className="st18" d="M275,302c-0.4,0.4-0.4,1,0,1.4"/>
<path className="st18" d="M276.4,304.9c0.4,0.4,1,0.4,1.4,0"/>
<path className="st5" d="M407.9,342.6c-2-1.8-5-1.7-6.9,0.2"/>
<line className="st5" x1="404.6" y1="346.4" x2="408.1" y2="342.8"/>
<polyline className="st5" points="404.6,346.4 404.4,346.1 408,342.6 408.1,342.8 "/>
<path className="st5" d="M400.9,342.7c1.9-1.9,2-4.9,0.2-6.9"/>
<line className="st5" x1="397.4" y1="339.3" x2="401" y2="335.7"/>
<polyline className="st5" points="397.4,339.3 397.7,339.4 401.3,335.9 401,335.7 "/>
<line className="st6" x1="397.6" y1="339.3" x2="401.2" y2="335.8"/>
<line className="st6" x1="404.5" y1="346.2" x2="408" y2="342.6"/>
<line className="st1" x1="28.2" y1="170.3" x2="37.3" y2="161.2"/>
<line className="st1" x1="45.7" y1="152.8" x2="54.8" y2="143.7"/>
<line className="st1" x1="63.2" y1="135.3" x2="72.2" y2="126.2"/>
<line className="st1" x1="80.6" y1="117.9" x2="84.7" y2="113.8"/>
<line className="st1" x1="55.9" y1="144.8" x2="54.8" y2="143.7"/>
<line className="st1" x1="64.3" y1="136.4" x2="63.2" y2="135.3"/>
<line className="st1" x1="38.4" y1="162.3" x2="37.3" y2="161.2"/>
<line className="st1" x1="46.8" y1="153.9" x2="45.7" y2="152.8"/>
<line className="st1" x1="29.3" y1="171.4" x2="28.2" y2="170.3"/>
<line className="st1" x1="73.4" y1="127.4" x2="72.2" y2="126.2"/>
<line className="st1" x1="81.8" y1="119" x2="80.6" y2="117.9"/>
<line className="st1" x1="149.6" y1="179.3" x2="150.5" y2="179.3"/>
<line className="st1" x1="232.7" y1="255.9" x2="223.8" y2="247"/>
<line className="st1" x1="372.2" y1="395.3" x2="372.6" y2="395.8"/>
<line className="st1" x1="365.6" y1="388.8" x2="366.6" y2="389.7"/>
<line className="st1" x1="372.7" y1="394.7" x2="373.2" y2="395.2"/>
<polyline className="st1" points="366.2,388.2 367.2,389.2 366.6,389.7 "/>
<line className="st1" x1="372.2" y1="395.3" x2="372.7" y2="394.7"/>
<line className="st1" x1="356.3" y1="379.5" x2="360.5" y2="383.7"/>
<polyline className="st1" points="365.8,387.8 356.9,378.9 366.2,388.2 "/>
<line className="st1" x1="363.4" y1="391.1" x2="365.6" y2="388.8"/>
<polyline className="st1" points="362.9,390.6 363.4,391.1 362.9,390.6 365.2,388.4 "/>
<polyline className="st1" points="361,384.2 358.2,386.9 357.7,386.4 360.5,383.7 "/>
<line className="st1" x1="307.8" y1="250.7" x2="298.9" y2="241.8"/>
<line className="st2" x1="111.7" y1="202.2" x2="111.7" y2="200.6"/>
<line className="st1" x1="342.8" y1="285.6" x2="334" y2="276.9"/>
<line className="st1" x1="377.8" y1="320.7" x2="368.9" y2="311.8"/>
<line className="st1" x1="361" y1="384.2" x2="365.2" y2="388.4"/>
<line className="st1" x1="323.6" y1="322.1" x2="324.1" y2="322.6"/>
<line className="st1" x1="100" y1="253.8" x2="100" y2="253"/>
<line className="st1" x1="109.9" y1="253.8" x2="109.9" y2="253"/>
<line className="st1" x1="121" y1="253.8" x2="121" y2="253"/>
<line className="st1" x1="130.8" y1="253.8" x2="130.8" y2="253"/>
<line className="st1" x1="141.8" y1="253.8" x2="141.8" y2="253"/>
<line className="st1" x1="151.8" y1="253.8" x2="151.8" y2="253"/>
<line className="st1" x1="92.3" y1="154.1" x2="97.9" y2="148.6"/>
<line className="st1" x1="91.7" y1="153.5" x2="97.9" y2="147.4"/>
<line className="st1" x1="99.7" y1="150.4" x2="97.9" y2="148.6"/>
<line className="st1" x1="100.3" y1="149.8" x2="97.9" y2="147.4"/>
<line className="st2" x1="445.3" y1="325.5" x2="421.1" y2="349.6"/>
<line className="st2" x1="171.1" y1="117.9" x2="167.2" y2="113.9"/>
<line className="st2" x1="188.5" y1="135.3" x2="179.5" y2="126.2"/>
<line className="st2" x1="206" y1="152.8" x2="196.9" y2="143.7"/>
<line className="st2" x1="223.6" y1="170.3" x2="214.4" y2="161.2"/>
<line className="st2" x1="241.1" y1="187.8" x2="232" y2="178.7"/>
<line className="st2" x1="258.5" y1="205.2" x2="249.5" y2="196.2"/>
<line className="st2" x1="276" y1="222.8" x2="266.9" y2="213.6"/>
<line className="st2" x1="317.6" y1="216.2" x2="312.6" y2="211"/>
<line className="st2" x1="335.2" y1="233.7" x2="326" y2="224.6"/>
<line className="st2" x1="352.7" y1="251.1" x2="343.6" y2="242.1"/>
<line className="st2" x1="370.1" y1="268.6" x2="361.1" y2="259.5"/>
<line className="st2" x1="387.6" y1="286.1" x2="378.5" y2="277"/>
<line className="st2" x1="405.1" y1="303.6" x2="396" y2="294.5"/>
<line className="st2" x1="425.2" y1="353.6" x2="421.1" y2="349.6"/>
<line className="st2" x1="442.6" y1="371.1" x2="433.6" y2="362"/>
<line className="st2" x1="460.1" y1="388.6" x2="451" y2="379.5"/>
<line className="st2" x1="249.6" y1="335.9" x2="258.7" y2="345"/>
<line className="st2" x1="267.1" y1="353.4" x2="276.2" y2="362.6"/>
<line className="st2" x1="284.6" y1="371" x2="293.8" y2="380.1"/>
<line className="st2" x1="302.2" y1="388.5" x2="311.3" y2="397.5"/>
<line className="st2" x1="319.6" y1="405.9" x2="328.7" y2="415"/>
<line className="st2" x1="337.1" y1="423.4" x2="346.2" y2="432.5"/>
<line className="st2" x1="354.6" y1="440.9" x2="359.8" y2="446.1"/>
<line className="st2" x1="241.3" y1="309.3" x2="245.4" y2="313.2"/>
<line className="st2" x1="223.9" y1="291.8" x2="232.9" y2="300.9"/>
<line className="st2" x1="206.4" y1="274.2" x2="215.5" y2="283.4"/>
<line className="st2" x1="188.9" y1="256.7" x2="198" y2="265.8"/>
<line className="st2" x1="151.8" y1="253.8" x2="153.7" y2="253.8"/>
<line className="st2" x1="130.8" y1="253.8" x2="141.8" y2="253.8"/>
<line className="st2" x1="109.9" y1="253.8" x2="121" y2="253.8"/>
<line className="st2" x1="195.8" y1="144.8" x2="196.9" y2="143.7"/>
<line className="st2" x1="187.4" y1="136.4" x2="188.5" y2="135.3"/>
<line className="st2" x1="401.2" y1="403.5" x2="401.8" y2="404.1"/>
<line className="st2" x1="392.8" y1="411.9" x2="393.4" y2="412.5"/>
<line className="st2" x1="213.4" y1="162.3" x2="214.4" y2="161.2"/>
<line className="st2" x1="205" y1="153.9" x2="206" y2="152.8"/>
<line className="st2" x1="230.9" y1="179.8" x2="232" y2="178.7"/>
<line className="st2" x1="222.5" y1="171.4" x2="223.6" y2="170.3"/>
<line className="st2" x1="248.3" y1="197.3" x2="249.5" y2="196.2"/>
<line className="st2" x1="240" y1="188.9" x2="241.1" y2="187.8"/>
<line className="st2" x1="265.8" y1="214.8" x2="266.9" y2="213.6"/>
<line className="st2" x1="257.4" y1="206.4" x2="258.5" y2="205.2"/>
<line className="st2" x1="283.3" y1="232.2" x2="284.4" y2="231.2"/>
<line className="st2" x1="274.9" y1="223.8" x2="276" y2="222.8"/>
<line className="st2" x1="178.3" y1="127.4" x2="179.5" y2="126.2"/>
<line className="st2" x1="169.9" y1="119" x2="171.1" y2="117.9"/>
<line className="st2" x1="325" y1="225.6" x2="326" y2="224.6"/>
<line className="st2" x1="316.6" y1="217.2" x2="317.6" y2="216.2"/>
<line className="st2" x1="342.5" y1="243.2" x2="343.6" y2="242.1"/>
<line className="st2" x1="334.1" y1="234.8" x2="335.2" y2="233.7"/>
<line className="st2" x1="360" y1="260.7" x2="361.1" y2="259.5"/>
<line className="st2" x1="351.6" y1="252.3" x2="352.7" y2="251.1"/>
<line className="st2" x1="377.4" y1="278.1" x2="378.5" y2="277"/>
<line className="st2" x1="369" y1="269.7" x2="370.1" y2="268.6"/>
<line className="st2" x1="394.9" y1="295.6" x2="396" y2="294.5"/>
<line className="st2" x1="386.5" y1="287.2" x2="387.6" y2="286.1"/>
<line className="st2" x1="412.4" y1="313.1" x2="413.5" y2="312"/>
<line className="st2" x1="404" y1="304.7" x2="405.1" y2="303.6"/>
<line className="st2" x1="432.4" y1="363.2" x2="433.6" y2="362"/>
<line className="st2" x1="424" y1="354.8" x2="425.2" y2="353.6"/>
<line className="st2" x1="467.4" y1="398.1" x2="468.5" y2="397"/>
<line className="st2" x1="459" y1="389.7" x2="460.1" y2="388.6"/>
<line className="st2" x1="449.9" y1="380.7" x2="451" y2="379.5"/>
<line className="st2" x1="441.5" y1="372.3" x2="442.6" y2="371.1"/>
<line className="st2" x1="190.1" y1="255.6" x2="188.9" y2="256.7"/>
<line className="st2" x1="181.7" y1="247.2" x2="180.5" y2="248.3"/>
<line className="st2" x1="207.5" y1="273" x2="206.4" y2="274.2"/>
<line className="st2" x1="199.1" y1="264.8" x2="198" y2="265.8"/>
<line className="st2" x1="225" y1="290.6" x2="223.9" y2="291.8"/>
<line className="st2" x1="216.6" y1="282.2" x2="215.5" y2="283.4"/>
<line className="st2" x1="242.5" y1="308.1" x2="241.3" y2="309.3"/>
<line className="st2" x1="234.1" y1="299.7" x2="232.9" y2="300.9"/>
<line className="st2" x1="250.8" y1="334.8" x2="249.6" y2="335.9"/>
<line className="st2" x1="242.4" y1="326.4" x2="241.2" y2="327.5"/>
<line className="st2" x1="268.3" y1="352.2" x2="267.1" y2="353.4"/>
<line className="st2" x1="259.9" y1="343.8" x2="258.7" y2="345"/>
<line className="st2" x1="285.7" y1="369.8" x2="284.6" y2="371"/>
<line className="st2" x1="277.3" y1="361.4" x2="276.2" y2="362.6"/>
<line className="st2" x1="303.2" y1="387.3" x2="302.2" y2="388.5"/>
<line className="st2" x1="294.8" y1="378.9" x2="293.8" y2="380.1"/>
<line className="st2" x1="320.8" y1="404.8" x2="319.6" y2="405.9"/>
<line className="st2" x1="312.4" y1="396.4" x2="311.3" y2="397.5"/>
<line className="st2" x1="338.3" y1="422.3" x2="337.1" y2="423.4"/>
<line className="st2" x1="329.9" y1="413.9" x2="328.7" y2="415"/>
<line className="st2" x1="355.8" y1="439.7" x2="354.6" y2="440.9"/>
<line className="st2" x1="347.4" y1="431.3" x2="346.2" y2="432.5"/>
<line className="st2" x1="149.6" y1="173.4" x2="150.5" y2="173.4"/>
<line className="st2" x1="102.1" y1="179.3" x2="101.3" y2="179.3"/>
<line className="st2" x1="102.1" y1="173.4" x2="101.3" y2="173.4"/>
<line className="st2" x1="155.6" y1="138.2" x2="156.2" y2="138.8"/>
<line className="st2" x1="144" y1="186.3" x2="139.1" y2="186.3"/>
<line className="st2" x1="149.6" y1="186.3" x2="148.9" y2="186.3"/>
<line className="st2" x1="144" y1="187.1" x2="139.1" y2="187.1"/>
<line className="st2" x1="149.6" y1="187.1" x2="148.9" y2="187.1"/>
<line className="st2" x1="139.2" y1="161.2" x2="141.1" y2="163.1"/>
<line className="st2" x1="146.4" y1="169.5" x2="149.6" y2="172.7"/>
<line className="st2" x1="150.5" y1="173.4" x2="150.5" y2="172.4"/>
<line className="st2" x1="150.5" y1="198.2" x2="150.5" y2="179.3"/>
<line className="st2" x1="144" y1="187.1" x2="144" y2="186.3"/>
<line className="st2" x1="148.9" y1="187.1" x2="148.9" y2="186.3"/>
<line className="st2" x1="149.6" y1="173.4" x2="149.6" y2="172.7"/>
<line className="st2" x1="149.6" y1="199" x2="149.6" y2="187.1"/>
<line className="st2" x1="107.8" y1="186.3" x2="112.7" y2="186.3"/>
<line className="st2" x1="102.1" y1="186.3" x2="102.8" y2="186.3"/>
<line className="st2" x1="107.8" y1="187.1" x2="112.7" y2="187.1"/>
<line className="st2" x1="102.1" y1="187.1" x2="102.8" y2="187.1"/>
<line className="st2" x1="281.3" y1="279.6" x2="281.6" y2="280.1"/>
<line className="st2" x1="85.4" y1="252.2" x2="85.4" y2="219.5"/>
<line className="st2" x1="84.6" y1="251.4" x2="84.6" y2="218.7"/>
<line className="st2" x1="107.4" y1="218.1" x2="112.7" y2="218.1"/>
<line className="st2" x1="85.4" y1="218.1" x2="99.5" y2="218.1"/>
<line className="st2" x1="85.4" y1="217.2" x2="99.5" y2="217.2"/>
<line className="st2" x1="107.4" y1="217.2" x2="112.7" y2="217.2"/>
<line className="st2" x1="99.5" y1="218.1" x2="99.5" y2="217.2"/>
<line className="st2" x1="107.4" y1="218.1" x2="107.4" y2="217.2"/>
<polyline className="st2" points="46.9,204.4 46.3,203.8 46.9,204.4 "/>
<line className="st2" x1="70.3" y1="204.5" x2="60.1" y2="214.8"/>
<line className="st2" x1="69.7" y1="203.9" x2="61.1" y2="212.7"/>
<line className="st2" x1="112.7" y1="162.2" x2="111.1" y2="163.7"/>
<line className="st2" x1="105.4" y1="202.2" x2="106.9" y2="202.2"/>
<line className="st2" x1="104.5" y1="201.4" x2="106.3" y2="201.4"/>
<line className="st2" x1="166.3" y1="252.2" x2="166.3" y2="219.5"/>
<line className="st2" x1="167.2" y1="251.4" x2="167.2" y2="218.7"/>
<line className="st2" x1="157.6" y1="156" x2="157.2" y2="155.6"/>
<polyline className="st2" points="88.3,161.8 88.9,162.4 88.3,161.8 "/>
<line className="st2" x1="84.7" y1="166.6" x2="84.1" y2="166"/>
<line className="st2" x1="83" y1="167.1" x2="74" y2="158.1"/>
<line className="st2" x1="83.6" y1="166.5" x2="75.2" y2="158.1"/>
<line className="st2" x1="84" y1="148.2" x2="74" y2="158.1"/>
<line className="st2" x1="84.6" y1="148.7" x2="75.2" y2="158.1"/>
<line className="st2" x1="112.6" y1="161.2" x2="110.6" y2="163.1"/>
<line className="st2" x1="105.4" y1="169.5" x2="102.1" y2="172.7"/>
<line className="st2" x1="101.3" y1="173.4" x2="101.3" y2="172.4"/>
<line className="st2" x1="101.3" y1="198.2" x2="101.3" y2="179.3"/>
<line className="st2" x1="107.8" y1="187.1" x2="107.8" y2="186.3"/>
<line className="st2" x1="102.8" y1="187.1" x2="102.8" y2="186.3"/>
<line className="st2" x1="102.1" y1="173.4" x2="102.1" y2="172.7"/>
<line className="st2" x1="102.1" y1="199" x2="102.1" y2="187.1"/>
<line className="st2" x1="139.1" y1="162.2" x2="140.6" y2="163.7"/>
<line className="st2" x1="146.4" y1="202.2" x2="139.1" y2="202.2"/>
<line className="st2" x1="147.2" y1="201.4" x2="139.1" y2="201.4"/>
<polyline className="st2" points="137.4,202.2 114.4,202.2 137.4,202.2 "/>
<line className="st2" x1="114.4" y1="201.4" x2="115.3" y2="201.4"/>
<polyline className="st2" points="312.6,211 288.4,235.1 284.4,231.2 "/>
<line className="st2" x1="193.2" y1="192.8" x2="204.8" y2="204.4"/>
<line className="st2" x1="193.8" y1="192.2" x2="205.4" y2="203.8"/>
<line className="st2" x1="181" y1="179.3" x2="192.6" y2="191"/>
<line className="st2" x1="180.4" y1="179.9" x2="184.6" y2="184.1"/>
<line className="st2" x1="206.5" y1="228.4" x2="199.4" y2="221.4"/>
<line className="st2" x1="210.7" y1="210.3" x2="217.7" y2="217.2"/>
<polyline className="st2" points="218.3,216.6 211.2,209.7 218.3,216.6 "/>
<line className="st2" x1="198.8" y1="222" x2="205.9" y2="229"/>
<line className="st2" x1="217.7" y1="217.2" x2="218.3" y2="216.6"/>
<line className="st2" x1="206.5" y1="228.4" x2="205.9" y2="229"/>
<line className="st2" x1="337.8" y1="337.4" x2="338.4" y2="336.8"/>
<line className="st2" x1="333.6" y1="333.2" x2="334.2" y2="332.6"/>
<line className="st2" x1="373.2" y1="372.8" x2="389.8" y2="356.2"/>
<line className="st2" x1="373.2" y1="371.7" x2="388.7" y2="356.2"/>
<line className="st2" x1="372.6" y1="340.2" x2="372.6" y2="340.2"/>
<line className="st2" x1="351.2" y1="349.6" x2="357.2" y2="355.7"/>
<line className="st2" x1="373.2" y1="340.8" x2="373.2" y2="340.8"/>
<line className="st2" x1="372.6" y1="340.2" x2="372.6" y2="340.2"/>
<line className="st2" x1="371.3" y1="338.9" x2="371.9" y2="338.3"/>
<line className="st2" x1="367.1" y1="334.7" x2="367.7" y2="334.1"/>
<line className="st2" x1="339.6" y1="306.2" x2="340.1" y2="306.5"/>
<line className="st2" x1="336.2" y1="303.9" x2="339.5" y2="307.1"/>
<polyline className="st2" points="331.6,299.2 332,299.7 332.6,299.1 332.2,298.6 "/>
<polyline className="st2" points="347.9,315.5 347.5,315 348,314.6 348.5,314.9 "/>
<polyline className="st2" points="340,307.6 343.3,310.8 343.8,310.4 336.8,303.3 "/>
<line className="st2" x1="322" y1="289.6" x2="306" y2="305.6"/>
<line className="st2" x1="322.6" y1="290.1" x2="309.7" y2="302.9"/>
<line className="st2" x1="351.5" y1="331.6" x2="351.8" y2="331.2"/>
<line className="st2" x1="353.8" y1="321.4" x2="354.4" y2="320.8"/>
<line className="st2" x1="349.6" y1="317.2" x2="350.2" y2="316.6"/>
<line className="st2" x1="351.8" y1="331.2" x2="359.6" y2="338.9"/>
<polyline className="st2" points="359.2,339.4 351.5,331.6 359.2,339.4 "/>
<line className="st2" x1="338.4" y1="336.8" x2="350" y2="348.4"/>
<line className="st2" x1="333.7" y1="332.1" x2="334.2" y2="332.6"/>
<line className="st2" x1="353.8" y1="321.4" x2="361.6" y2="329.2"/>
<line className="st2" x1="349.1" y1="316.7" x2="349.6" y2="317.2"/>
<line className="st2" x1="371.3" y1="338.9" x2="372.6" y2="340.2"/>
<line className="st2" x1="366.6" y1="334.2" x2="367.1" y2="334.7"/>
<line className="st2" x1="350.6" y1="350.2" x2="367" y2="366.5"/>
<line className="st2" x1="371.3" y1="338.9" x2="372.5" y2="340.1"/>
<line className="st2" x1="366.6" y1="334.2" x2="367.1" y2="334.7"/>
<line className="st2" x1="371.9" y1="338.3" x2="383.5" y2="350"/>
<line className="st2" x1="367.2" y1="333.6" x2="367.7" y2="334.1"/>
<line className="st2" x1="351.2" y1="349.6" x2="357.2" y2="355.7"/>
<line className="st2" x1="337.8" y1="337.4" x2="349.4" y2="349"/>
<line className="st2" x1="333.1" y1="332.7" x2="333.6" y2="333.2"/>
<line className="st2" x1="354.4" y1="320.8" x2="362.6" y2="329.1"/>
<line className="st2" x1="349.7" y1="316.2" x2="350.2" y2="316.6"/>
<line className="st2" x1="305" y1="272.7" x2="299.9" y2="277.7"/>
<line className="st2" x1="298.7" y1="297.2" x2="302.8" y2="301.1"/>
<line className="st2" x1="314.2" y1="281.7" x2="322" y2="289.6"/>
<line className="st2" x1="324.6" y1="323" x2="327.8" y2="326.3"/>
<line className="st2" x1="307.1" y1="305.6" x2="307.6" y2="306"/>
<line className="st2" x1="339.5" y1="307.1" x2="324.1" y2="322.6"/>
<line className="st2" x1="340" y1="307.6" x2="324.6" y2="323"/>
<line className="st2" x1="330" y1="297.6" x2="330.5" y2="298"/>
<polyline className="st2" points="322.6,290.1 325.8,293.4 326.4,292.8 "/>
<line className="st2" x1="298.1" y1="297.8" x2="302.6" y2="302.2"/>
<line className="st2" x1="289.7" y1="288" x2="292.9" y2="291.3"/>
<line className="st2" x1="373.2" y1="372.8" x2="380.5" y2="365.6"/>
<line className="st2" x1="145.3" y1="144.9" x2="145.9" y2="144.4"/>
<line className="st2" x1="141.1" y1="140.7" x2="141.7" y2="140.2"/>
<line className="st2" x1="155.6" y1="138.2" x2="156.7" y2="137.1"/>
<line className="st2" x1="147.7" y1="146.1" x2="151.4" y2="142.4"/>
<line className="st2" x1="156.2" y1="138.8" x2="156.7" y2="138.3"/>
<line className="st2" x1="148.3" y1="146.7" x2="152" y2="143"/>
<line className="st2" x1="141.7" y1="140.2" x2="141.2" y2="139.7"/>
<line className="st2" x1="166.6" y1="147" x2="156.7" y2="137.1"/>
<line className="st2" x1="166" y1="147.6" x2="156.7" y2="138.3"/>
<line className="st2" x1="157.1" y1="156.6" x2="156.6" y2="156.2"/>
<polyline className="st2" points="181,179.3 180.4,179.9 181,179.3 "/>
<polyline className="st2" points="174.5,174 176.2,175.7 176.8,175.1 176.3,174.6 "/>
<polyline className="st2" points="204.8,204.4 205.4,203.8 204.8,204.4 "/>
<line className="st2" x1="209.6" y1="208" x2="209" y2="208.6"/>
<polyline className="st2" points="437.5,438.3 402.6,403.2 401.8,404.1 "/>
<line className="st2" x1="386.2" y1="384.6" x2="386.6" y2="385.1"/>
<polyline className="st2" points="468.5,397 473.6,402.2 437.5,438.3 "/>
<line className="st2" x1="398" y1="397.6" x2="401.9" y2="401.6"/>
<line className="st2" x1="249.7" y1="272.9" x2="250.2" y2="273.3"/>
<line className="st2" x1="250.3" y1="272.3" x2="250.8" y2="272.8"/>
<polyline className="st2" points="243.4,265.2 244.7,266.7 244.2,267.3 "/>
<line className="st2" x1="249.7" y1="272.9" x2="250.3" y2="272.3"/>
<line className="st2" x1="227" y1="226.6" x2="223.3" y2="222.9"/>
<line className="st2" x1="169.8" y1="169.4" x2="163.3" y2="162.9"/>
<line className="st2" x1="176.6" y1="244.5" x2="180.5" y2="248.3"/>
<polyline className="st2" points="245.4,313.2 236.2,322.5 241.2,327.5 "/>
<line className="st2" x1="417.5" y1="316" x2="413.5" y2="312"/>
<line className="st2" x1="169.8" y1="169.4" x2="170.4" y2="168.9"/>
<line className="st2" x1="209" y1="208.6" x2="209.5" y2="209.1"/>
<line className="st2" x1="203" y1="202.6" x2="204.8" y2="204.4"/>
<line className="st2" x1="192.2" y1="214.2" x2="191.6" y2="214.8"/>
<line className="st2" x1="197.9" y1="219.8" x2="197.3" y2="220.4"/>
<line className="st2" x1="216.8" y1="239.9" x2="216.4" y2="239.6"/>
<line className="st2" x1="223.4" y1="246.5" x2="222.5" y2="245.6"/>
<line className="st2" x1="217.4" y1="239.4" x2="217" y2="239"/>
<polyline className="st2" points="233.3,255.3 224.4,246.4 223.1,245 "/>
<polyline className="st2" points="242.9,264.8 234.5,256.5 243.4,265.2 "/>
<line className="st2" x1="212" y1="234" x2="215.8" y2="237.8"/>
<line className="st2" x1="211.4" y1="234.6" x2="215.2" y2="238.4"/>
<line className="st2" x1="223.8" y1="222.3" x2="227.6" y2="226"/>
<line className="st2" x1="211.4" y1="234.6" x2="215.2" y2="238.4"/>
<line className="st2" x1="222.5" y1="245.6" x2="223.1" y2="245"/>
<line className="st2" x1="216.8" y1="239.9" x2="217.4" y2="239.4"/>
<line className="st2" x1="223.8" y1="222.3" x2="227.6" y2="226"/>
<line className="st2" x1="212" y1="234" x2="211.4" y2="234.6"/>
<line className="st2" x1="223.3" y1="222.9" x2="223.8" y2="222.3"/>
<line className="st2" x1="209.6" y1="208" x2="210.1" y2="208.5"/>
<line className="st2" x1="203.6" y1="202.1" x2="205.4" y2="203.8"/>
<line className="st2" x1="197.3" y1="220.4" x2="197.8" y2="220.8"/>
<line className="st2" x1="181.4" y1="204.5" x2="191.6" y2="214.8"/>
<line className="st2" x1="170.4" y1="168.9" x2="168.6" y2="167"/>
<line className="st2" x1="175.7" y1="175.2" x2="174.2" y2="173.8"/>
<line className="st2" x1="197.9" y1="219.8" x2="198.4" y2="220.2"/>
<line className="st2" x1="182" y1="203.9" x2="192.2" y2="214.2"/>
<line className="st2" x1="70.3" y1="204.5" x2="61.1" y2="213.9"/>
<line className="st2" x1="63.4" y1="186.8" x2="59.2" y2="191"/>
<line className="st2" x1="70.8" y1="179.3" x2="64" y2="186.2"/>
<line className="st2" x1="71.4" y1="179.9" x2="67.2" y2="184.1"/>
<line className="st2" x1="103.1" y1="148.2" x2="102.6" y2="147.6"/>
<polyline className="st2" points="111.1,140.3 107.3,144 106.8,143.4 110.5,139.7 "/>
<polyline className="st2" points="70.8,179.3 71.4,179.9 70.8,179.3 "/>
<polyline className="st2" points="76.1,175.2 75.6,175.7 75,175.1 75.5,174.6 "/>
<line className="st2" x1="176.5" y1="244.5" x2="167.3" y2="253.8"/>
<line className="st2" x1="84.5" y1="253.8" x2="88.1" y2="253.8"/>
<line className="st2" x1="82" y1="169.4" x2="81.4" y2="168.9"/>
<line className="st2" x1="88.3" y1="161.8" x2="89.2" y2="161"/>
<line className="st2" x1="82" y1="169.4" x2="84.7" y2="166.6"/>
<line className="st2" x1="83.6" y1="166.5" x2="84.1" y2="166"/>
<line className="st2" x1="144.4" y1="218.1" x2="139.1" y2="218.1"/>
<line className="st2" x1="166.3" y1="218.1" x2="152.3" y2="218.1"/>
<line className="st2" x1="166.3" y1="217.2" x2="152.3" y2="217.2"/>
<line className="st2" x1="144.4" y1="217.2" x2="139.1" y2="217.2"/>
<line className="st2" x1="152.3" y1="218.1" x2="152.3" y2="217.2"/>
<line className="st2" x1="144.4" y1="218.1" x2="144.4" y2="217.2"/>
<polyline className="st2" points="77.3,174 77.8,173.6 77.2,173.1 76.7,173.4 "/>
<polyline className="st2" points="175.7,175.2 174,173.6 174.6,173.1 175.1,173.4 "/>
<line className="st2" x1="60.1" y1="214.8" x2="59.5" y2="214.2"/>
<line className="st2" x1="265.4" y1="241.2" x2="266" y2="240.6"/>
<line className="st2" x1="261.2" y1="237" x2="261.8" y2="236.4"/>
<line className="st2" x1="265.4" y1="241.2" x2="273.8" y2="249.6"/>
<line className="st2" x1="257.5" y1="233.3" x2="261.2" y2="237"/>
<line className="st2" x1="266" y1="240.6" x2="274.4" y2="249"/>
<line className="st2" x1="258.1" y1="232.7" x2="261.8" y2="236.4"/>
<line className="st2" x1="330" y1="297.6" x2="330.6" y2="297"/>
<line className="st2" x1="140.8" y1="140.3" x2="141.1" y2="140.7"/>
<line className="st2" x1="157.1" y1="156.6" x2="156.6" y2="156.2"/>
<line className="st2" x1="331.1" y1="297.5" x2="330.6" y2="297"/>
<line className="st2" x1="304.4" y1="272.1" x2="302.3" y2="274.1"/>
<line className="st2" x1="296.6" y1="264.3" x2="297.1" y2="264.8"/>
<line className="st2" x1="357.8" y1="356.2" x2="367.6" y2="365.9"/>
<line className="st2" x1="373.2" y1="340.8" x2="382.9" y2="350.6"/>
<line className="st2" x1="384.7" y1="351.2" x2="389.8" y2="356.2"/>
<line className="st2" x1="384.1" y1="351.8" x2="388.7" y2="356.2"/>
<line className="st2" x1="368.6" y1="367.1" x2="373.2" y2="371.7"/>
<line className="st2" x1="368.2" y1="367.7" x2="373.2" y2="372.8"/>
<line className="st2" x1="393.4" y1="412.5" x2="359.8" y2="446.1"/>
<line className="st2" x1="83" y1="167.1" x2="81.4" y2="168.9"/>
<line className="st2" x1="90.2" y1="161.1" x2="88.9" y2="162.4"/>
<polyline className="st2" points="217.8,254 217.3,253.5 223.8,247 "/>
<polyline className="st2" points="217.3,254.4 216.4,253.5 223.4,246.5 "/>
<line className="st2" x1="221.5" y1="258.6" x2="222" y2="258.2"/>
<line className="st2" x1="217.3" y1="254.4" x2="217.8" y2="254"/>
<line className="st2" x1="225.7" y1="262.8" x2="221.5" y2="258.6"/>
<line className="st2" x1="226.2" y1="262.4" x2="222" y2="258.2"/>
<line className="st2" x1="389.6" y1="412.8" x2="390.1" y2="413.3"/>
<line className="st2" x1="382" y1="405.2" x2="384.1" y2="407.2"/>
<polyline className="st2" points="383.3,405.3 384.7,406.6 384.1,407.2 "/>
<line className="st2" x1="389.6" y1="412.8" x2="390.2" y2="412.2"/>
<line className="st2" x1="373.8" y1="397" x2="381.5" y2="404.7"/>
<polyline className="st2" points="383.3,405.3 374.4,396.4 383.3,405.3 "/>
<polyline className="st2" points="372,413.3 372.5,413.8 381.5,404.7 "/>
<line className="st2" x1="372.8" y1="414.3" x2="382" y2="405.2"/>
<line className="st2" x1="367.3" y1="409.6" x2="367.8" y2="409.1"/>
<line className="st2" x1="371.5" y1="413.8" x2="372" y2="413.3"/>
<line className="st2" x1="364.2" y1="406.5" x2="367.3" y2="409.6"/>
<line className="st2" x1="364.7" y1="406" x2="367.8" y2="409.1"/>
<line className="st2" x1="390.2" y1="412.2" x2="390.7" y2="412.7"/>
<line className="st2" x1="371.5" y1="413.8" x2="374.5" y2="416.8"/>
<polyline className="st2" points="372.8,414.3 375,416.3 374.5,416.8 "/>
<polyline className="st2" points="380.6,422.8 380.2,422.4 380.6,422 381,422.4 "/>
<line className="st2" x1="324.8" y1="267.8" x2="325.3" y2="268.2"/>
<line className="st2" x1="324.2" y1="268.4" x2="324.7" y2="268.8"/>
<polyline className="st2" points="317.3,261.3 318.7,262.7 319.2,262.1 "/>
<line className="st2" x1="324.8" y1="267.8" x2="324.2" y2="268.4"/>
<line className="st2" x1="292" y1="234.9" x2="291.5" y2="234.4"/>
<line className="st2" x1="298.4" y1="241.4" x2="297.6" y2="240.4"/>
<line className="st2" x1="291.4" y1="235.5" x2="290.9" y2="235"/>
<polyline className="st2" points="307.2,251.3 298.3,242.4 297,241 "/>
<polyline className="st2" points="316.8,260.8 308.4,252.4 317.3,261.3 "/>
<line className="st2" x1="297.6" y1="240.4" x2="297" y2="241"/>
<line className="st2" x1="292" y1="234.9" x2="291.4" y2="235.5"/>
<polyline className="st2" points="306,235.7 305.5,235.4 298.9,241.8 "/>
<polyline className="st2" points="306.4,235.4 305.5,234.4 298.4,241.4 "/>
<line className="st2" x1="310.6" y1="239.6" x2="310.2" y2="239.9"/>
<line className="st2" x1="306.4" y1="235.4" x2="306" y2="235.7"/>
<line className="st2" x1="314.8" y1="243.8" x2="310.6" y2="239.6"/>
<line className="st2" x1="314.4" y1="244.1" x2="310.2" y2="239.9"/>
<line className="st2" x1="359.9" y1="302.7" x2="360.2" y2="303.2"/>
<line className="st2" x1="359.3" y1="303.3" x2="359.8" y2="303.8"/>
<polyline className="st2" points="352.2,296.3 353.6,297.8 354.2,297.2 "/>
<line className="st2" x1="359.9" y1="302.7" x2="359.3" y2="303.3"/>
<line className="st2" x1="327" y1="269.8" x2="326.5" y2="269.3"/>
<line className="st2" x1="326.4" y1="270.4" x2="325.9" y2="269.9"/>
<polyline className="st2" points="342.2,286.2 333.4,277.4 331.9,276 "/>
<polyline className="st2" points="351.8,295.8 343.4,287.4 352.2,296.3 "/>
<line className="st2" x1="332.5" y1="275.4" x2="331.9" y2="276"/>
<line className="st2" x1="327" y1="269.8" x2="326.4" y2="270.4"/>
<line className="st2" x1="394.8" y1="337.7" x2="395.3" y2="338.2"/>
<line className="st2" x1="394.2" y1="338.3" x2="394.7" y2="338.8"/>
<polyline className="st2" points="387.2,331.2 388.7,332.7 389.2,332.1 "/>
<line className="st2" x1="394.8" y1="337.7" x2="394.2" y2="338.3"/>
<line className="st2" x1="361.9" y1="304.8" x2="361.4" y2="304.4"/>
<line className="st2" x1="361.3" y1="305.4" x2="360.8" y2="305"/>
<polyline className="st2" points="377.2,321.3 368.3,312.4 367,311 "/>
<polyline className="st2" points="386.8,330.9 378.4,322.5 387.2,331.2 "/>
<line className="st2" x1="367.6" y1="310.5" x2="367" y2="311"/>
<line className="st2" x1="361.9" y1="304.8" x2="361.3" y2="305.4"/>
<line className="st2" x1="172.3" y1="246.2" x2="167.2" y2="241"/>
<line className="st2" x1="172" y1="246.6" x2="167.2" y2="242"/>
<line className="st2" x1="244.6" y1="243" x2="245" y2="243.5"/>
<line className="st2" x1="238.1" y1="236.4" x2="239" y2="237.4"/>
<line className="st2" x1="244.1" y1="243.6" x2="244.4" y2="244"/>
<polyline className="st2" points="237,236.6 238.4,238 239,237.4 "/>
<line className="st2" x1="244.6" y1="243" x2="244.1" y2="243.6"/>
<line className="st2" x1="228.7" y1="227.2" x2="237.6" y2="236"/>
<polyline className="st2" points="236.5,236.1 228.1,227.7 237,236.6 "/>
<polyline className="st2" points="246.6,226.1 247.1,226.6 237.6,236 "/>
<polyline className="st2" points="247.1,225.6 248,226.6 238.1,236.4 "/>
<line className="st2" x1="242.9" y1="221.4" x2="242.4" y2="221.9"/>
<line className="st2" x1="247.1" y1="225.6" x2="246.6" y2="226.1"/>
<line className="st2" x1="238.7" y1="217.2" x2="242.9" y2="221.4"/>
<line className="st2" x1="238.2" y1="217.7" x2="242.4" y2="221.9"/>
<line className="st2" x1="106.9" y1="167.9" x2="106.4" y2="167.3"/>
<line className="st2" x1="111.1" y1="163.7" x2="110.6" y2="163.1"/>
<line className="st2" x1="106.9" y1="167.9" x2="106.6" y2="168.4"/>
<line className="st2" x1="106.4" y1="167.3" x2="101.3" y2="172.4"/>
<line className="st2" x1="144.8" y1="167.9" x2="145.3" y2="167.3"/>
<line className="st2" x1="140.6" y1="163.7" x2="141.1" y2="163.1"/>
<line className="st2" x1="144.8" y1="167.9" x2="145.2" y2="168.4"/>
<polyline className="st2" points="150.5,172.4 145.3,167.3 150.5,172.4 "/>
<line className="st2" x1="144.8" y1="167.9" x2="145.2" y2="168.4"/>
<line className="st2" x1="297" y1="296.6" x2="296.5" y2="296.1"/>
<line className="st2" x1="180.8" y1="202.7" x2="173.9" y2="195.9"/>
<line className="st2" x1="184.6" y1="184.1" x2="182.4" y2="186.2"/>
<line className="st2" x1="191.3" y1="213.3" x2="193.3" y2="211.2"/>
<line className="st2" x1="190.7" y1="212.7" x2="192.8" y2="210.6"/>
<line className="st2" x1="185" y1="184.6" x2="183" y2="186.8"/>
<line className="st2" x1="180.2" y1="203.3" x2="173.3" y2="196.5"/>
<line className="st2" x1="182.4" y1="186.2" x2="183" y2="186.8"/>
<line className="st2" x1="199.9" y1="203.6" x2="200.4" y2="204.2"/>
<line className="st2" x1="192.8" y1="210.6" x2="193.3" y2="211.2"/>
<line className="st2" x1="200.4" y1="204.2" x2="202.6" y2="202.1"/>
<line className="st2" x1="199.9" y1="203.6" x2="202" y2="201.5"/>
<line className="st2" x1="166.3" y1="217.2" x2="166.3" y2="212.1"/>
<line className="st2" x1="166.3" y1="202.2" x2="168.1" y2="200.6"/>
<line className="st2" x1="167.2" y1="202.6" x2="168.6" y2="201"/>
<polyline className="st2" points="175.3,193.2 175.9,193.8 173.9,195.9 "/>
<line className="st2" x1="167.2" y1="216.4" x2="167.2" y2="212.8"/>
<line className="st2" x1="172.8" y1="197" x2="172.3" y2="196.4"/>
<line className="st2" x1="168.6" y1="201" x2="168.1" y2="200.6"/>
<line className="st2" x1="172.8" y1="197" x2="173.3" y2="196.5"/>
<line className="st2" x1="172.3" y1="196.4" x2="175.3" y2="193.2"/>
<line className="st2" x1="394.6" y1="376.2" x2="403.4" y2="385.1"/>
<line className="st2" x1="395.2" y1="375.6" x2="404" y2="384.5"/>
<polyline className="st2" points="408.1,389.7 407.6,389.3 408.2,388.7 "/>
<line className="st2" x1="411.4" y1="386.4" x2="414.7" y2="389.8"/>
<line className="st2" x1="408.1" y1="389.7" x2="402.4" y2="395.4"/>
<polyline className="st2" points="414.7,389.8 414.1,390.4 411.4,387.6 403,396 "/>
<line className="st2" x1="403.4" y1="385.1" x2="404" y2="384.5"/>
<polyline className="st2" points="235.8,272 236.2,272.4 242.8,265.8 233.9,257 "/>
<polyline className="st2" points="235.3,272.4 236.2,273.3 243.2,266.3 244.2,267.3 "/>
<line className="st2" x1="231.1" y1="268.2" x2="231.6" y2="267.8"/>
<line className="st2" x1="235.3" y1="272.4" x2="235.8" y2="272"/>
<line className="st2" x1="226.9" y1="264" x2="231.1" y2="268.2"/>
<line className="st2" x1="227.4" y1="263.6" x2="231.6" y2="267.8"/>
<polyline className="st2" points="323.9,253.7 324.4,254.2 317.9,260.7 309,251.9 "/>
<polyline className="st2" points="324.4,253.2 325.3,254.2 318.4,261.2 319.2,262.1 "/>
<line className="st2" x1="320.2" y1="249" x2="319.7" y2="249.5"/>
<line className="st2" x1="324.4" y1="253.2" x2="323.9" y2="253.7"/>
<line className="st2" x1="316" y1="244.8" x2="320.2" y2="249"/>
<line className="st2" x1="315.5" y1="245.3" x2="319.7" y2="249.5"/>
<line className="st2" x1="333.5" y1="276.4" x2="332.5" y2="275.4"/>
<polyline className="st2" points="340.9,270.8 340.4,270.3 334,276.9 "/>
<polyline className="st2" points="341.4,270.3 340.4,269.3 333.5,276.4 "/>
<line className="st2" x1="345.6" y1="274.5" x2="345.1" y2="275"/>
<line className="st2" x1="341.4" y1="270.3" x2="340.9" y2="270.8"/>
<line className="st2" x1="349.8" y1="278.7" x2="345.6" y2="274.5"/>
<line className="st2" x1="349.3" y1="279.2" x2="345.1" y2="275"/>
<polyline className="st2" points="358.9,288.8 359.4,289.2 352.8,295.7 343.9,286.8 "/>
<polyline className="st2" points="359.4,288.3 360.2,289.2 353.3,296.2 354.2,297.2 "/>
<line className="st2" x1="355.2" y1="284.1" x2="354.7" y2="284.6"/>
<line className="st2" x1="359.4" y1="288.3" x2="358.9" y2="288.8"/>
<line className="st2" x1="351" y1="279.9" x2="355.2" y2="284.1"/>
<line className="st2" x1="350.5" y1="280.4" x2="354.7" y2="284.6"/>
<line className="st2" x1="368.4" y1="311.3" x2="367.6" y2="310.5"/>
<polyline className="st2" points="376,305.8 375.5,305.3 368.9,311.8 "/>
<polyline className="st2" points="376.4,305.3 375.5,304.4 368.4,311.3 "/>
<line className="st2" x1="380.6" y1="309.5" x2="380.2" y2="310"/>
<line className="st2" x1="376.4" y1="305.3" x2="376" y2="305.8"/>
<line className="st2" x1="384.8" y1="313.7" x2="380.6" y2="309.5"/>
<line className="st2" x1="384.4" y1="314.2" x2="380.2" y2="310"/>
<polyline className="st2" points="393.8,323.7 394.3,324.2 387.8,330.8 379,321.9 "/>
<polyline className="st2" points="394.3,323.2 395.3,324.2 388.3,331.2 389.2,332.1 "/>
<line className="st2" x1="390.1" y1="319" x2="389.6" y2="319.5"/>
<line className="st2" x1="394.3" y1="323.2" x2="393.8" y2="323.7"/>
<line className="st2" x1="385.9" y1="314.8" x2="390.1" y2="319"/>
<line className="st2" x1="385.4" y1="315.3" x2="389.6" y2="319.5"/>
<line className="st2" x1="314.6" y1="281.2" x2="326.4" y2="292.8"/>
<line className="st2" x1="70.9" y1="202.7" x2="77.9" y2="195.9"/>
<line className="st2" x1="67.2" y1="184.1" x2="69.4" y2="186.2"/>
<line className="st2" x1="60.5" y1="213.3" x2="58.4" y2="211.2"/>
<line className="st2" x1="61.1" y1="212.7" x2="58.9" y2="210.6"/>
<line className="st2" x1="66.7" y1="184.6" x2="68.8" y2="186.8"/>
<line className="st2" x1="71.5" y1="203.3" x2="78.5" y2="196.5"/>
<line className="st2" x1="69.4" y1="186.2" x2="68.8" y2="186.8"/>
<line className="st2" x1="51.8" y1="203.6" x2="51.4" y2="204.2"/>
<line className="st2" x1="58.9" y1="210.6" x2="58.4" y2="211.2"/>
<line className="st2" x1="51.4" y1="204.2" x2="49.2" y2="202.1"/>
<line className="st2" x1="51.8" y1="203.6" x2="49.8" y2="201.5"/>
<polyline className="st2" points="85.4,217.2 85.4,202.2 83.6,200.6 "/>
<line className="st2" x1="84.6" y1="202.6" x2="83.2" y2="201"/>
<polyline className="st2" points="76.4,193.2 75.8,193.8 77.9,195.9 "/>
<line className="st2" x1="84.6" y1="216.4" x2="84.6" y2="202.6"/>
<line className="st2" x1="79" y1="197" x2="79.4" y2="196.4"/>
<line className="st2" x1="83.2" y1="201" x2="83.6" y2="200.6"/>
<line className="st2" x1="79" y1="197" x2="78.5" y2="196.5"/>
<line className="st2" x1="79.4" y1="196.4" x2="76.4" y2="193.2"/>
<line className="st2" x1="332" y1="330.5" x2="331.4" y2="331.1"/>
<line className="st2" x1="327.8" y1="326.3" x2="327.2" y2="326.9"/>
<line className="st2" x1="331.4" y1="331.1" x2="331.9" y2="331.5"/>
<line className="st2" x1="332" y1="330.5" x2="332.5" y2="331"/>
<line className="st2" x1="320.9" y1="319.2" x2="324.1" y2="322.6"/>
<line className="st2" x1="320.3" y1="319.8" x2="327.2" y2="326.9"/>
<line className="st2" x1="297.2" y1="263.7" x2="297.7" y2="264.2"/>
<line className="st2" x1="20.9" y1="179.8" x2="19.8" y2="178.7"/>
<line className="st2" x1="11.8" y1="188.9" x2="10.7" y2="187.8"/>
<polyline className="st2" points="46.9,204.4 46.3,203.8 46.9,204.4 "/>
<line className="st2" x1="42.1" y1="208" x2="42.7" y2="208.6"/>
<line className="st2" x1="42.7" y1="208.6" x2="42.2" y2="209.1"/>
<line className="st2" x1="59.5" y1="214.2" x2="60.1" y2="214.8"/>
<line className="st2" x1="53.9" y1="219.8" x2="54.5" y2="220.4"/>
<line className="st2" x1="42.1" y1="208" x2="41.6" y2="208.5"/>
<line className="st2" x1="54.5" y1="220.4" x2="54" y2="220.8"/>
<line className="st2" x1="53.9" y1="219.8" x2="53.4" y2="220.2"/>
<line className="st2" x1="75.1" y1="244.5" x2="84.5" y2="253.8"/>
<line className="st2" x1="79.4" y1="246.2" x2="84.6" y2="241"/>
<line className="st2" x1="79.8" y1="246.6" x2="84.6" y2="242"/>
<line className="st2" x1="51.4" y1="204.2" x2="49.2" y2="202.1"/>
<line className="st2" x1="51.8" y1="203.6" x2="49.8" y2="201.5"/>
<line className="st2" x1="288" y1="272.9" x2="288.5" y2="273.3"/>
<polyline className="st2" points="288.5,272.4 289,272.9 288.5,273.3 "/>
<polyline className="st2" points="295.8,280.7 292,276.9 292.4,276.4 296.3,280.2 "/>
<line className="st2" x1="296.5" y1="296.1" x2="297.1" y2="295.5"/>
<line className="st2" x1="292.3" y1="291.9" x2="292.9" y2="291.3"/>
<line className="st2" x1="297.1" y1="295.5" x2="297.6" y2="296"/>
<line className="st2" x1="292.3" y1="291.9" x2="285.4" y2="284.9"/>
<polyline className="st2" points="445.2,309.2 434.8,298.7 417.5,316 "/>
<line className="st2" x1="147.7" y1="146.1" x2="145.9" y2="144.4"/>
<line className="st2" x1="164.4" y1="162.8" x2="166.4" y2="160.7"/>
<polyline className="st2" points="165,163.4 167,161.2 170,164.3 "/>
<line className="st2" x1="171.2" y1="164.3" x2="161.4" y2="154.5"/>
<line className="st2" x1="170" y1="164.3" x2="168" y2="166.4"/>
<line className="st2" x1="166.4" y1="160.7" x2="160.9" y2="155.1"/>
<line className="st2" x1="171.2" y1="164.3" x2="168.6" y2="167"/>
<polyline className="st2" points="152,150.4 154.1,148.4 159.7,153.9 "/>
<polyline className="st2" points="151.4,149.8 154.1,147.2 160.3,153.3 "/>
<line className="st2" x1="168" y1="166.4" x2="165" y2="163.4"/>
<line className="st2" x1="164.4" y1="162.8" x2="163.9" y2="162.3"/>
<line className="st2" x1="151.4" y1="149.8" x2="148.3" y2="146.7"/>
<line className="st2" x1="294" y1="266.8" x2="301.9" y2="274.6"/>
<line className="st2" x1="294.5" y1="266.3" x2="302.3" y2="274.1"/>
<line className="st2" x1="299.4" y1="278.2" x2="307.3" y2="286.1"/>
<line className="st2" x1="299.9" y1="277.7" x2="307.8" y2="285.6"/>
<line className="st2" x1="299.4" y1="278.2" x2="289.7" y2="288"/>
<line className="st2" x1="305" y1="272.7" x2="313" y2="280.6"/>
<line className="st2" x1="301.9" y1="274.6" x2="296.3" y2="280.2"/>
<line className="st2" x1="295.8" y1="280.7" x2="289.1" y2="287.4"/>
<line className="st2" x1="309.2" y1="303.4" x2="317.2" y2="311.3"/>
<line className="st2" x1="309.7" y1="302.9" x2="317.6" y2="310.8"/>
<line className="st2" x1="309.2" y1="303.4" x2="307.1" y2="305.6"/>
<line className="st2" x1="404.6" y1="398.8" x2="395" y2="389.2"/>
<line className="st2" x1="405.7" y1="398.8" x2="388.9" y2="381.9"/>
<line className="st2" x1="392.4" y1="390.9" x2="394.6" y2="388.7"/>
<line className="st2" x1="392.9" y1="391.4" x2="395" y2="389.2"/>
<line className="st2" x1="394.6" y1="388.7" x2="388.3" y2="382.5"/>
<line className="st2" x1="401.3" y1="395.4" x2="399.1" y2="397.6"/>
<line className="st2" x1="399.7" y1="398.1" x2="402.5" y2="401"/>
<line className="st2" x1="392.9" y1="391.4" x2="393.4" y2="391.7"/>
<line className="st2" x1="281.2" y1="280.7" x2="281.6" y2="280.1"/>
<line className="st2" x1="285.4" y1="284.9" x2="285.8" y2="284.3"/>
<line className="st2" x1="281.2" y1="280.7" x2="280.7" y2="280.2"/>
<line className="st2" x1="285.8" y1="284.3" x2="289.1" y2="287.4"/>
<line className="st2" x1="139.1" y1="161.2" x2="139.2" y2="161.2"/>
<line className="st2" x1="112.7" y1="161.2" x2="112.6" y2="161.2"/>
<line className="st2" x1="149.6" y1="186.3" x2="149.6" y2="179.3"/>
<line className="st2" x1="102.1" y1="186.3" x2="102.1" y2="179.3"/>
<line className="st2" x1="185" y1="184.6" x2="192" y2="191.6"/>
<line className="st2" x1="66.7" y1="184.6" x2="59.8" y2="191.6"/>
<line className="st2" x1="49.2" y1="202.1" x2="46.9" y2="204.4"/>
<line className="st2" x1="60.5" y1="213.3" x2="59.5" y2="214.2"/>
<line className="st2" x1="178.9" y1="126.8" x2="188" y2="135.9"/>
<line className="st2" x1="178.3" y1="127.4" x2="187.4" y2="136.4"/>
<line className="st2" x1="196.4" y1="144.3" x2="201" y2="148.7"/>
<line className="st2" x1="195.8" y1="144.8" x2="199.8" y2="148.7"/>
<line className="st2" x1="214" y1="161.7" x2="218.5" y2="166.2"/>
<line className="st2" x1="213.4" y1="162.3" x2="217.3" y2="166.2"/>
<line className="st2" x1="231.5" y1="179.2" x2="235.9" y2="183.8"/>
<line className="st2" x1="230.9" y1="179.8" x2="234.8" y2="183.8"/>
<line className="st2" x1="248.9" y1="196.7" x2="253.4" y2="201.3"/>
<line className="st2" x1="248.3" y1="197.3" x2="252.4" y2="201.3"/>
<line className="st2" x1="266.4" y1="214.2" x2="271" y2="218.8"/>
<line className="st2" x1="265.8" y1="214.8" x2="269.8" y2="218.8"/>
<line className="st2" x1="283.3" y1="232.2" x2="288.5" y2="237.4"/>
<line className="st2" x1="283.9" y1="231.6" x2="288.5" y2="236.2"/>
<line className="st2" x1="325.6" y1="225" x2="330.1" y2="229.6"/>
<line className="st2" x1="325" y1="225.6" x2="328.9" y2="229.6"/>
<line className="st2" x1="343.1" y1="242.6" x2="347.6" y2="247.1"/>
<line className="st2" x1="342.5" y1="243.2" x2="346.4" y2="247.1"/>
<line className="st2" x1="360.5" y1="260.1" x2="365" y2="264.6"/>
<line className="st2" x1="360" y1="260.7" x2="364" y2="264.6"/>
<line className="st2" x1="378" y1="277.5" x2="382.6" y2="282"/>
<line className="st2" x1="377.4" y1="278.1" x2="381.4" y2="282"/>
<line className="st2" x1="395.5" y1="295" x2="400.1" y2="299.6"/>
<line className="st2" x1="394.9" y1="295.6" x2="398.9" y2="299.6"/>
<line className="st2" x1="413" y1="312.5" x2="417.6" y2="317.1"/>
<line className="st2" x1="404" y1="304.7" x2="400.1" y2="300.8"/>
<line className="st2" x1="433" y1="362.6" x2="442.1" y2="371.7"/>
<line className="st2" x1="432.4" y1="363.2" x2="441.5" y2="372.3"/>
<line className="st2" x1="450.5" y1="380.1" x2="459.5" y2="389.2"/>
<line className="st2" x1="449.9" y1="380.7" x2="453.8" y2="384.6"/>
<line className="st2" x1="467.9" y1="397.6" x2="472.4" y2="402"/>
<line className="st2" x1="467.4" y1="398.1" x2="471.4" y2="402"/>
<line className="st2" x1="250.8" y1="334.8" x2="254.8" y2="338.8"/>
<line className="st2" x1="250.2" y1="335.3" x2="259.3" y2="344.4"/>
<line className="st2" x1="267.7" y1="352.8" x2="276.8" y2="362"/>
<line className="st2" x1="268.3" y1="352.2" x2="272.3" y2="356.2"/>
<line className="st2" x1="285.7" y1="369.8" x2="289.7" y2="373.7"/>
<line className="st2" x1="285.2" y1="370.4" x2="294.2" y2="379.5"/>
<line className="st2" x1="303.2" y1="387.3" x2="307.2" y2="391.2"/>
<line className="st2" x1="302.6" y1="387.9" x2="311.8" y2="397"/>
<line className="st2" x1="320.8" y1="404.8" x2="324.7" y2="408.8"/>
<line className="st2" x1="320.2" y1="405.4" x2="329.3" y2="414.4"/>
<line className="st2" x1="338.3" y1="422.3" x2="342.2" y2="426.3"/>
<line className="st2" x1="337.7" y1="422.8" x2="346.8" y2="431.9"/>
<line className="st2" x1="355.8" y1="439.7" x2="359.8" y2="443.7"/>
<line className="st2" x1="355.2" y1="440.3" x2="359.8" y2="444.9"/>
<line className="st2" x1="242.5" y1="308.1" x2="246.5" y2="312"/>
<line className="st2" x1="241.9" y1="308.7" x2="246.5" y2="313.2"/>
<line className="st2" x1="225" y1="290.6" x2="229" y2="294.5"/>
<line className="st2" x1="224.4" y1="291.2" x2="233.5" y2="300.3"/>
<line className="st2" x1="207.5" y1="273" x2="211.4" y2="277.1"/>
<line className="st2" x1="206.9" y1="273.6" x2="216" y2="282.8"/>
<line className="st2" x1="181.7" y1="247.2" x2="177.7" y2="243.3"/>
<line className="st2" x1="189.5" y1="256.2" x2="198.6" y2="265.2"/>
<line className="st2" x1="109.9" y1="253" x2="113.5" y2="253"/>
<line className="st2" x1="100" y1="252.2" x2="98" y2="252.2"/>
<line className="st2" x1="37.8" y1="161.7" x2="33.2" y2="166.2"/>
<line className="st2" x1="38.4" y1="162.3" x2="34.4" y2="166.2"/>
<line className="st2" x1="55.3" y1="144.3" x2="50.8" y2="148.7"/>
<line className="st2" x1="55.9" y1="144.8" x2="52" y2="148.7"/>
<line className="st2" x1="72.8" y1="126.8" x2="63.7" y2="135.9"/>
<line className="st2" x1="73.4" y1="127.4" x2="64.3" y2="136.4"/>
<line className="st2" x1="151.8" y1="252.2" x2="153.7" y2="252.2"/>
<line className="st2" x1="141.8" y1="253" x2="138.2" y2="253"/>
<line className="st2" x1="130.8" y1="252.2" x2="137.4" y2="252.2"/>
<line className="st2" x1="130.8" y1="253" x2="138.2" y2="253"/>
<line className="st2" x1="147.6" y1="202.2" x2="150.5" y2="199.4"/>
<line className="st2" x1="148.7" y1="202.2" x2="150.5" y2="200.4"/>
<line className="st2" x1="146.4" y1="202.2" x2="150.5" y2="198.2"/>
<line className="st2" x1="280.7" y1="280.2" x2="299.3" y2="261.6"/>
<line className="st2" x1="280.1" y1="279.6" x2="298.7" y2="261"/>
<line className="st2" x1="279.5" y1="279" x2="298.1" y2="260.4"/>
<line className="st2" x1="245.6" y1="245.2" x2="271" y2="219.9"/>
<line className="st2" x1="263.2" y1="262.7" x2="265.1" y2="260.8"/>
<line className="st2" x1="262" y1="261.5" x2="267.2" y2="256.2"/>
<line className="st2" x1="262.6" y1="262.1" x2="264.5" y2="260.2"/>
<line className="st2" x1="245" y1="244.6" x2="271" y2="218.8"/>
<line className="st2" x1="244.4" y1="244" x2="269.8" y2="218.8"/>
<line className="st2" x1="59.5" y1="214.2" x2="60.1" y2="214.8"/>
<polyline className="st2" points="106,167.8 112.7,174.5 114.4,174.5 "/>
<line className="st2" x1="113.5" y1="176.6" x2="105.4" y2="168.4"/>
<line className="st2" x1="104.2" y1="202.2" x2="101.3" y2="199.4"/>
<line className="st2" x1="103.1" y1="202.2" x2="101.3" y2="200.4"/>
<line className="st2" x1="105.4" y1="202.2" x2="101.3" y2="198.2"/>
<line className="st2" x1="112.7" y1="176.9" x2="104.8" y2="169"/>
<line className="st2" x1="114.4" y1="162.9" x2="114.4" y2="161.2"/>
<polyline className="st2" points="114.4,202.2 114.4,201.4 114.4,174.5 "/>
<line className="st2" x1="113.5" y1="162.9" x2="113.5" y2="161.2"/>
<polyline className="st2" points="113.5,202.2 113.5,201.4 113.5,174.5 "/>
<line className="st2" x1="112.7" y1="162.9" x2="112.7" y2="161.2"/>
<polyline className="st2" points="112.7,202.2 112.7,201.4 112.7,176.9 "/>
<line className="st2" x1="137.4" y1="174.5" x2="137.4" y2="174.5"/>
<line className="st2" x1="139.1" y1="174.5" x2="145.8" y2="167.8"/>
<line className="st2" x1="138.2" y1="176.6" x2="146.4" y2="168.4"/>
<line className="st2" x1="139.1" y1="176.9" x2="147" y2="169"/>
<line className="st2" x1="137.4" y1="162.9" x2="137.4" y2="161.2"/>
<line className="st2" x1="137.4" y1="202.2" x2="137.4" y2="174.5"/>
<line className="st2" x1="138.2" y1="162.9" x2="138.2" y2="161.2"/>
<polyline className="st2" points="138.2,202.2 138.2,201.4 138.2,174.5 "/>
<line className="st2" x1="139.1" y1="162.9" x2="139.1" y2="161.2"/>
<polyline className="st2" points="139.1,202.2 139.1,201.4 139.1,176.9 "/>
<line className="st2" x1="112.7" y1="162.9" x2="139.1" y2="162.9"/>
<line className="st2" x1="130.9" y1="162" x2="139.1" y2="162"/>
<line className="st2" x1="130.9" y1="161.2" x2="139.1" y2="161.2"/>
<line className="st2" x1="137.4" y1="162" x2="112.7" y2="162"/>
<line className="st2" x1="138.2" y1="161.2" x2="112.7" y2="161.2"/>
<line className="st2" x1="166" y1="113.8" x2="170.5" y2="118.4"/>
<polyline className="st2" points="193.2,192.8 192,191.6 217.3,166.2 "/>
<line className="st2" x1="192.6" y1="192.2" x2="218.5" y2="166.2"/>
<line className="st2" x1="193.2" y1="192.8" x2="218.5" y2="167.4"/>
<line className="st2" x1="192.6" y1="192.2" x2="192.6" y2="192.2"/>
<line className="st2" x1="166.3" y1="219.5" x2="182" y2="203.9"/>
<line className="st2" x1="166.3" y1="217.2" x2="180.8" y2="202.7"/>
<line className="st2" x1="166.3" y1="218.4" x2="181.4" y2="203.3"/>
<line className="st2" x1="250.8" y1="272.8" x2="252" y2="273.9"/>
<line className="st2" x1="238.4" y1="322.5" x2="242.4" y2="326.4"/>
<line className="st2" x1="165.5" y1="114.4" x2="169.9" y2="119"/>
<line className="st2" x1="399.4" y1="371.4" x2="398.2" y2="370.2"/>
<line className="st2" x1="406.4" y1="364.4" x2="405.2" y2="363.2"/>
<line className="st2" x1="384.5" y1="384" x2="384.5" y2="384"/>
<line className="st2" x1="384.5" y1="384" x2="385.6" y2="385.1"/>
<line className="st2" x1="406.4" y1="364.4" x2="420" y2="350.8"/>
<line className="st2" x1="385.6" y1="385.1" x2="390" y2="380.7"/>
<line className="st2" x1="385" y1="384.6" x2="389.4" y2="380.2"/>
<line className="st2" x1="384.5" y1="384" x2="388.9" y2="379.6"/>
<polyline className="st2" points="365.4,335.3 367.2,333.6 350.6,350.2 "/>
<line className="st2" x1="350" y1="349.6" x2="366.6" y2="333"/>
<line className="st2" x1="349.4" y1="349" x2="366" y2="332.4"/>
<line className="st2" x1="365.4" y1="335.3" x2="367.2" y2="333.6"/>
<line className="st2" x1="384.1" y1="351.8" x2="384.7" y2="351.2"/>
<line className="st2" x1="368.2" y1="367.7" x2="368.6" y2="367.1"/>
<line className="st2" x1="383.5" y1="351.2" x2="384.1" y2="350.6"/>
<line className="st2" x1="367.6" y1="367.1" x2="368.2" y2="366.5"/>
<line className="st2" x1="382.9" y1="350.6" x2="383.5" y2="350"/>
<line className="st2" x1="367" y1="366.5" x2="367.6" y2="365.9"/>
<line className="st2" x1="366" y1="332.4" x2="367.2" y2="333.6"/>
<line className="st2" x1="349.4" y1="349" x2="350.6" y2="350.2"/>
<line className="st2" x1="333.1" y1="332.7" x2="349.7" y2="316.1"/>
<line className="st2" x1="314.5" y1="314.1" x2="315.6" y2="315.2"/>
<line className="st2" x1="332.5" y1="332.1" x2="349.1" y2="315.5"/>
<line className="st2" x1="331.9" y1="331.5" x2="348.5" y2="314.9"/>
<line className="st2" x1="331.1" y1="297.5" x2="332.2" y2="298.6"/>
<polyline className="st2" points="314.5,314.1 315.6,315.2 332.2,298.6 "/>
<line className="st2" x1="315" y1="314.6" x2="331.6" y2="298"/>
<line className="st2" x1="314.5" y1="314.1" x2="331.1" y2="297.5"/>
<polyline className="st2" points="313.6,280 314.6,281.2 296,299.8 "/>
<line className="st2" x1="295.4" y1="299.2" x2="314.2" y2="280.6"/>
<line className="st2" x1="295" y1="298.6" x2="313.6" y2="280"/>
<line className="st2" x1="298.1" y1="260.4" x2="299.3" y2="261.6"/>
<line className="st2" x1="279.5" y1="279" x2="280.7" y2="280.2"/>
<line className="st2" x1="166.3" y1="252.2" x2="198.4" y2="220.2"/>
<line className="st2" x1="166.7" y1="253" x2="198.8" y2="220.8"/>
<polyline className="st2" points="402.5,401 421.2,419.6 437.5,435.9 "/>
<line className="st2" x1="402.5" y1="402" x2="437.5" y2="437.1"/>
<line className="st2" x1="453.8" y1="384.6" x2="459" y2="389.7"/>
<line className="st2" x1="420" y1="350.8" x2="424" y2="354.8"/>
<line className="st2" x1="454.4" y1="385.1" x2="455" y2="384.6"/>
<line className="st2" x1="420" y1="419.6" x2="420.6" y2="419"/>
<line className="st2" x1="453.8" y1="384.6" x2="454.4" y2="384"/>
<line className="st2" x1="419.4" y1="419" x2="420" y2="418.4"/>
<line className="st2" x1="421.2" y1="419.6" x2="420.6" y2="420.2"/>
<line className="st2" x1="455.5" y1="385.1" x2="455" y2="385.7"/>
<line className="st2" x1="420" y1="349.6" x2="424.6" y2="354.2"/>
<line className="st2" x1="472.4" y1="402" x2="437.5" y2="437.1"/>
<line className="st2" x1="471.4" y1="402" x2="437.5" y2="435.9"/>
<line className="st2" x1="264" y1="330.8" x2="254.8" y2="339.9"/>
<line className="st2" x1="209.5" y1="209.1" x2="234.8" y2="183.8"/>
<line className="st2" x1="210.1" y1="209.7" x2="235.9" y2="183.8"/>
<polyline className="st2" points="211.2,209.7 235.9,185 210.7,210.3 "/>
<line className="st2" x1="416.4" y1="317.1" x2="412.4" y2="313.1"/>
<line className="st2" x1="324.7" y1="268.8" x2="346.4" y2="247.1"/>
<line className="st2" x1="330.1" y1="230.8" x2="308.4" y2="252.4"/>
<line className="st2" x1="305.8" y1="254" x2="330.1" y2="229.6"/>
<line className="st2" x1="307.2" y1="251.3" x2="328.9" y2="229.6"/>
<line className="st2" x1="251.4" y1="273.3" x2="229" y2="295.7"/>
<line className="st2" x1="230.2" y1="295.7" x2="252" y2="273.9"/>
<line className="st2" x1="251.4" y1="273.3" x2="229" y2="295.7"/>
<line className="st2" x1="250.8" y1="272.8" x2="229" y2="294.5"/>
<line className="st2" x1="325.9" y1="410" x2="356.9" y2="378.9"/>
<line className="st2" x1="356.3" y1="378.3" x2="324.7" y2="410"/>
<polyline className="st2" points="356.9,378.9 355.8,377.7 324.7,408.8 "/>
<line className="st2" x1="343.4" y1="427.4" x2="374.4" y2="396.4"/>
<line className="st2" x1="373.8" y1="395.8" x2="342.2" y2="427.4"/>
<line className="st2" x1="373.2" y1="395.2" x2="342.2" y2="426.3"/>
<line className="st2" x1="308.4" y1="392.4" x2="338.9" y2="362"/>
<line className="st2" x1="338.3" y1="361.4" x2="307.2" y2="392.4"/>
<line className="st2" x1="337.7" y1="360.8" x2="307.2" y2="391.2"/>
<line className="st2" x1="290.9" y1="374.9" x2="321.4" y2="344.4"/>
<line className="st2" x1="320.8" y1="343.8" x2="289.7" y2="374.9"/>
<line className="st2" x1="320.2" y1="343.4" x2="289.7" y2="373.7"/>
<line className="st2" x1="320.8" y1="342.8" x2="322" y2="343.8"/>
<line className="st2" x1="273.4" y1="357.4" x2="303.8" y2="327"/>
<line className="st2" x1="303.2" y1="326.4" x2="272.3" y2="357.4"/>
<line className="st2" x1="302.6" y1="325.8" x2="272.3" y2="356.2"/>
<line className="st2" x1="343.4" y1="427.4" x2="347.4" y2="431.3"/>
<line className="st2" x1="325.9" y1="410" x2="329.9" y2="413.9"/>
<line className="st2" x1="308.4" y1="392.4" x2="312.4" y2="396.4"/>
<line className="st2" x1="290.9" y1="374.9" x2="294.8" y2="378.9"/>
<line className="st2" x1="273.4" y1="357.4" x2="277.3" y2="361.4"/>
<line className="st2" x1="256" y1="339.9" x2="259.9" y2="343.8"/>
<line className="st2" x1="237.2" y1="322.5" x2="241.8" y2="327"/>
<line className="st2" x1="194" y1="259.6" x2="190.1" y2="255.6"/>
<line className="st2" x1="230.2" y1="295.7" x2="234.1" y2="299.7"/>
<line className="st2" x1="212.6" y1="278.2" x2="216.6" y2="282.2"/>
<line className="st2" x1="195.1" y1="260.7" x2="199.1" y2="264.8"/>
<line className="st2" x1="176.5" y1="243.3" x2="181.1" y2="247.8"/>
<polyline className="st2" points="396.4,340.4 394.7,338.8 416.4,317.1 "/>
<line className="st2" x1="378.4" y1="322.5" x2="400.1" y2="300.8"/>
<polyline className="st2" points="377.8,321.9 400.1,299.6 404.6,304.1 "/>
<line className="st2" x1="343.4" y1="287.4" x2="342.2" y2="286.2"/>
<polyline className="st2" points="398.9,299.6 377.2,321.3 398.9,299.6 "/>
<line className="st2" x1="382.6" y1="283.2" x2="386.5" y2="287.2"/>
<polyline className="st2" points="360.2,304.4 382.6,282 387.1,286.6 "/>
<line className="st2" x1="359.8" y1="303.8" x2="381.4" y2="282"/>
<line className="st2" x1="377.2" y1="321.3" x2="398.9" y2="299.6"/>
<polyline className="st2" points="382.6,282 360.2,304.4 382.6,282 "/>
<line className="st2" x1="359.8" y1="303.8" x2="381.4" y2="282"/>
<polyline className="st2" points="343.4,287.4 365,265.7 369,269.7 "/>
<polyline className="st2" points="342.8,286.8 365,264.6 369.6,269.1 "/>
<line className="st2" x1="342.2" y1="286.2" x2="364" y2="264.6"/>
<polyline className="st2" points="325.9,269.9 347.6,248.2 351.6,252.3 "/>
<polyline className="st2" points="325.3,269.3 347.6,247.1 352.1,251.7 "/>
<line className="st2" x1="306.4" y1="254.6" x2="305.2" y2="253.4"/>
<line className="st2" x1="325.3" y1="269.3" x2="347.6" y2="247.1"/>
<line className="st2" x1="324.7" y1="268.8" x2="346.4" y2="247.1"/>
<polyline className="st2" points="306.4,254.6 330.1,230.8 334.1,234.8 "/>
<line className="st2" x1="330.1" y1="229.6" x2="334.7" y2="234.2"/>
<line className="st2" x1="288.5" y1="237.4" x2="312.6" y2="213.3"/>
<line className="st2" x1="305.2" y1="253.4" x2="328.9" y2="229.6"/>
<line className="st2" x1="312.6" y1="213.3" x2="316.6" y2="217.2"/>
<polyline className="st2" points="287.9,236.8 312.6,212.1 317.2,216.6 "/>
<line className="st2" x1="405.7" y1="398.8" x2="403.1" y2="401.6"/>
<line className="st2" x1="404.6" y1="398.8" x2="402.5" y2="401"/>
<line className="st2" x1="288.4" y1="306.3" x2="264" y2="330.8"/>
<line className="st2" x1="256" y1="339.9" x2="289" y2="306.9"/>
<line className="st2" x1="287.8" y1="305.7" x2="254.8" y2="338.8"/>
<line className="st2" x1="238.4" y1="322.5" x2="269.4" y2="291.4"/>
<line className="st2" x1="268.9" y1="290.8" x2="237.2" y2="322.5"/>
<line className="st2" x1="268.3" y1="290.2" x2="246.5" y2="312"/>
<line className="st2" x1="233.9" y1="255.9" x2="211.4" y2="278.2"/>
<line className="st2" x1="233.3" y1="255.3" x2="211.4" y2="277.1"/>
<line className="st2" x1="212.6" y1="278.2" x2="234.5" y2="256.5"/>
<line className="st2" x1="271" y1="219.9" x2="274.9" y2="223.8"/>
<line className="st2" x1="271" y1="218.8" x2="275.5" y2="223.2"/>
<line className="st2" x1="216.4" y1="238.4" x2="194" y2="260.7"/>
<line className="st2" x1="215.8" y1="237.8" x2="194" y2="259.6"/>
<line className="st2" x1="195.1" y1="260.7" x2="217" y2="239"/>
<polyline className="st2" points="228.1,227.7 253.4,202.5 257.4,206.4 "/>
<polyline className="st2" points="227.6,227.2 253.4,201.3 258,205.8 "/>
<line className="st2" x1="227" y1="226.6" x2="252.4" y2="201.3"/>
<line className="st2" x1="177.7" y1="243.3" x2="199.4" y2="221.4"/>
<line className="st2" x1="157.6" y1="157.1" x2="182.9" y2="131.9"/>
<line className="st2" x1="157.1" y1="156.6" x2="182.3" y2="131.3"/>
<line className="st2" x1="175.1" y1="174.6" x2="201" y2="148.7"/>
<line className="st2" x1="175.7" y1="175.2" x2="201" y2="149.9"/>
<line className="st2" x1="174.5" y1="174" x2="199.8" y2="148.7"/>
<line className="st2" x1="158.2" y1="157.7" x2="183.5" y2="132.4"/>
<line className="st2" x1="177.7" y1="243.3" x2="199.4" y2="221.4"/>
<line className="st2" x1="218.5" y1="167.4" x2="222.5" y2="171.4"/>
<line className="st2" x1="218.5" y1="166.2" x2="223.1" y2="170.8"/>
<line className="st2" x1="201" y1="149.9" x2="205" y2="153.9"/>
<polyline className="st2" points="201,148.7 175.1,174.6 201,148.7 205.6,153.3 "/>
<line className="st2" x1="157.6" y1="157.1" x2="182.9" y2="131.9"/>
<line className="st2" x1="85.8" y1="113.8" x2="81.2" y2="118.4"/>
<line className="st2" x1="58.6" y1="192.8" x2="59.8" y2="191.6"/>
<line className="st2" x1="69.7" y1="203.9" x2="70.9" y2="202.7"/>
<line className="st2" x1="59.8" y1="191.6" x2="34.4" y2="166.2"/>
<line className="st2" x1="58.6" y1="192.8" x2="33.2" y2="167.4"/>
<line className="st2" x1="58.6" y1="192.8" x2="58.6" y2="192.8"/>
<line className="st2" x1="59.2" y1="192.2" x2="59.2" y2="192.2"/>
<line className="st2" x1="85.4" y1="219.5" x2="69.7" y2="203.9"/>
<line className="st2" x1="85.4" y1="217.2" x2="70.9" y2="202.7"/>
<line className="st2" x1="85.4" y1="218.4" x2="70.3" y2="203.3"/>
<line className="st2" x1="139.1" y1="252.2" x2="139.1" y2="217.2"/>
<line className="st2" x1="138.2" y1="253" x2="138.2" y2="217.2"/>
<line className="st2" x1="137.4" y1="252.2" x2="137.4" y2="217.2"/>
<line className="st2" x1="85.8" y1="115" x2="81.8" y2="119"/>
<line className="st2" x1="113.5" y1="253" x2="121" y2="253"/>
<line className="st2" x1="114.4" y1="252.2" x2="121" y2="252.2"/>
<line className="st2" x1="166.7" y1="253" x2="163.7" y2="253"/>
<line className="st2" x1="139.1" y1="252.2" x2="141.8" y2="252.2"/>
<line className="st2" x1="85.1" y1="253" x2="88.1" y2="253"/>
<line className="st2" x1="112.7" y1="252.2" x2="112.7" y2="217.2"/>
<line className="st2" x1="113.5" y1="253" x2="113.5" y2="217.2"/>
<line className="st2" x1="114.4" y1="252.2" x2="114.4" y2="217.2"/>
<line className="st2" x1="112.7" y1="252.2" x2="109.9" y2="252.2"/>
<line className="st2" x1="33.2" y1="166.2" x2="59.2" y2="192.2"/>
<line className="st2" x1="76.1" y1="175.2" x2="77.3" y2="174"/>
<line className="st2" x1="94.2" y1="157.1" x2="68.9" y2="131.9"/>
<line className="st2" x1="94.7" y1="156.6" x2="69.5" y2="131.3"/>
<line className="st2" x1="76.7" y1="174.6" x2="50.8" y2="148.7"/>
<line className="st2" x1="76.1" y1="175.2" x2="50.8" y2="149.9"/>
<line className="st2" x1="77.3" y1="174" x2="52" y2="148.7"/>
<line className="st2" x1="93.6" y1="157.7" x2="68.3" y2="132.4"/>
<line className="st2" x1="33.2" y1="167.4" x2="29.3" y2="171.4"/>
<line className="st2" x1="33.2" y1="166.2" x2="28.7" y2="170.8"/>
<line className="st2" x1="50.8" y1="149.9" x2="46.8" y2="153.9"/>
<polyline className="st2" points="50.8,148.7 76.7,174.6 50.8,148.7 46.2,153.3 "/>
<line className="st2" x1="94.2" y1="157.1" x2="68.9" y2="131.9"/>
<line className="st2" x1="235.9" y1="183.8" x2="240.5" y2="188.3"/>
<line className="st2" x1="235.9" y1="185" x2="240" y2="188.9"/>
<line className="st2" x1="360.8" y1="305" x2="382.6" y2="283.2"/>
<line className="st2" x1="158.2" y1="157.7" x2="157.1" y2="156.6"/>
<line className="st2" x1="199.4" y1="221.4" x2="198.4" y2="220.2"/>
<line className="st2" x1="215.8" y1="237.8" x2="217" y2="239"/>
<line className="st2" x1="234.5" y1="256.5" x2="233.3" y2="255.3"/>
<line className="st2" x1="349.7" y1="316.1" x2="348.5" y2="314.9"/>
<line className="st2" x1="331.9" y1="331.5" x2="333.1" y2="332.7"/>
<line className="st2" x1="383.5" y1="350" x2="384.7" y2="351.2"/>
<line className="st2" x1="382.9" y1="350.6" x2="384.1" y2="351.8"/>
<line className="st2" x1="367.6" y1="365.9" x2="368.6" y2="367.1"/>
<line className="st2" x1="367" y1="366.5" x2="368.2" y2="367.7"/>
<line className="st2" x1="373.2" y1="395.2" x2="374.4" y2="396.4"/>
<line className="st2" x1="392.8" y1="411.9" x2="359.8" y2="444.9"/>
<polyline className="st2" points="392.8,411.9 392.2,411.3 359.8,443.7 "/>
<line className="st2" x1="400.6" y1="402.9" x2="401.2" y2="403.5"/>
<line className="st2" x1="93.6" y1="157.7" x2="94.7" y2="156.6"/>
<line className="st2" x1="101.3" y1="198.2" x2="101.3" y2="200.4"/>
<line className="st2" x1="105.4" y1="202.2" x2="103.1" y2="202.2"/>
<line className="st2" x1="112.7" y1="202.2" x2="114.4" y2="202.2"/>
<line className="st2" x1="139.1" y1="202.2" x2="137.4" y2="202.2"/>
<line className="st2" x1="146.4" y1="202.2" x2="148.7" y2="202.2"/>
<line className="st2" x1="112.7" y1="217.2" x2="114.4" y2="217.2"/>
<line className="st2" x1="139.1" y1="217.2" x2="137.4" y2="217.2"/>
<line className="st2" x1="150.5" y1="198.2" x2="150.5" y2="200.4"/>
<line className="st2" x1="85.4" y1="219.5" x2="85.4" y2="217.2"/>
<line className="st2" x1="166.3" y1="219.5" x2="166.3" y2="217.2"/>
<line className="st2" x1="360.8" y1="305" x2="359.8" y2="303.8"/>
<line className="st2" x1="378.4" y1="322.5" x2="377.2" y2="321.3"/>
<line className="st2" x1="325.9" y1="269.9" x2="324.7" y2="268.8"/>
<line className="st2" x1="227" y1="226.6" x2="228.1" y2="227.7"/>
<line className="st2" x1="244.4" y1="244" x2="245.6" y2="245.2"/>
<line className="st2" x1="289" y1="306.9" x2="287.8" y2="305.7"/>
<line className="st2" x1="296" y1="299.8" x2="295" y2="298.6"/>
<line className="st2" x1="405.8" y1="363.8" x2="444.7" y2="324.9"/>
<line className="st2" x1="405.2" y1="363.2" x2="444.1" y2="324.3"/>
<line className="st2" x1="210.7" y1="210.3" x2="209.5" y2="209.1"/>
<line className="st2" x1="273.4" y1="250.1" x2="274.4" y2="249"/>
<line className="st2" x1="274" y1="250.7" x2="275" y2="249.6"/>
<polyline className="st2" points="273.4,250.1 274.6,251.3 275.6,250.2 "/>
<line className="st2" x1="269.2" y1="254.3" x2="270.4" y2="255.5"/>
<line className="st2" x1="180.8" y1="202.7" x2="182" y2="203.9"/>
<line className="st2" x1="262" y1="261.5" x2="263.2" y2="262.7"/>
<line className="st2" x1="20.3" y1="179.2" x2="15.8" y2="183.8"/>
<line className="st2" x1="20.9" y1="179.8" x2="16.9" y2="183.8"/>
<line className="st2" x1="85.4" y1="252.2" x2="53.4" y2="220.2"/>
<line className="st2" x1="85.1" y1="253" x2="52.9" y2="220.8"/>
<line className="st2" x1="42.2" y1="209.1" x2="16.9" y2="183.8"/>
<line className="st2" x1="41.6" y1="209.7" x2="15.8" y2="183.8"/>
<polyline className="st2" points="40.6,209.7 15.8,185 41,210.3 "/>
<polyline className="st2" points="52.3,221.4 74,243.3 52.3,221.4 "/>
<line className="st2" x1="52.3" y1="221.4" x2="53.4" y2="220.2"/>
<line className="st2" x1="41" y1="210.3" x2="42.2" y2="209.1"/>
<line className="st2" x1="100" y1="253" x2="100" y2="252.2"/>
<line className="st2" x1="109.9" y1="253" x2="109.9" y2="252.2"/>
<line className="st2" x1="121" y1="253" x2="121" y2="252.2"/>
<line className="st2" x1="130.8" y1="253" x2="130.8" y2="252.2"/>
<line className="st2" x1="141.8" y1="253" x2="141.8" y2="252.2"/>
<line className="st2" x1="151.8" y1="253" x2="151.8" y2="252.2"/>
<line className="st2" x1="56.2" y1="190.2" x2="49.1" y2="197.3"/>
<line className="st2" x1="55.6" y1="189.8" x2="48.6" y2="196.7"/>
<polyline className="st2" points="408.2,388.7 408.7,389.2 411.4,386.4 "/>
<line className="st2" x1="403.1" y1="401.6" x2="401.2" y2="403.5"/>
<line className="st2" x1="402.5" y1="401" x2="400.6" y2="402.9"/>
<line className="st2" x1="401.8" y1="396" x2="399.7" y2="398.1"/>
<line className="st2" x1="245.6" y1="245.2" x2="262" y2="261.5"/>
<line className="st2" x1="246.2" y1="244.6" x2="262.6" y2="260.9"/>
<line className="st2" x1="274.4" y1="249" x2="275.6" y2="250.2"/>
<line className="st1" x1="350.3" y1="394.8" x2="354" y2="391.1"/>
<line className="st1" x1="350.3" y1="393.9" x2="353.5" y2="390.6"/>
<line className="st1" x1="345.6" y1="390.2" x2="350.3" y2="394.8"/>
<line className="st1" x1="353.5" y1="390.6" x2="354" y2="391.1"/>
<line className="st1" x1="346.1" y1="389.7" x2="350.3" y2="393.9"/>
<polyline className="st2" points="115.3,201.4 136.4,201.4 137.4,201.4 "/>
<line className="st2" x1="106.9" y1="200.6" x2="111.7" y2="200.6"/>
<line className="st2" x1="106.3" y1="199.8" x2="112.7" y2="199.8"/>
<line className="st2" x1="106.9" y1="202.2" x2="106.9" y2="200.6"/>
<line className="st2" x1="106.3" y1="201.4" x2="106.3" y2="199.8"/>
<line className="st2" x1="111.7" y1="202.2" x2="112.7" y2="202.2"/>
<line className="st2" x1="92.4" y1="156.5" x2="89" y2="159.9"/>
<line className="st2" x1="91.9" y1="156" x2="88.1" y2="159.9"/>
<line className="st2" x1="90.2" y1="161.1" x2="89" y2="159.9"/>
<line className="st2" x1="89.2" y1="161" x2="88.1" y2="159.9"/>
<line className="st2" x1="156.6" y1="156.2" x2="157.2" y2="155.6"/>
<polyline className="st2" points="145.3,144.9 151.9,151.5 152.5,150.9 152,150.4 "/>
<line className="st2" x1="163.3" y1="162.9" x2="163.9" y2="162.3"/>
<polyline className="st2" points="158.2,157.7 158.6,158.2 159.2,157.6 158.8,157.1 "/>
<line className="st2" x1="168.1" y1="215.6" x2="168.1" y2="212.8"/>
<line className="st2" x1="168.7" y1="214.8" x2="168.7" y2="206.7"/>
<line className="st2" x1="166.3" y1="207.4" x2="168.1" y2="207.4"/>
<line className="st2" x1="166.3" y1="212.1" x2="168.1" y2="212.1"/>
<line className="st2" x1="167.2" y1="206.7" x2="168.7" y2="206.7"/>
<line className="st2" x1="167.2" y1="212.8" x2="168.1" y2="212.8"/>
<line className="st2" x1="168.1" y1="212.1" x2="168.1" y2="207.4"/>
<line className="st2" x1="167.2" y1="206.7" x2="167.2" y2="202.6"/>
<line className="st2" x1="166.3" y1="207.4" x2="166.3" y2="202.2"/>
<line className="st2" x1="304" y1="271.6" x2="304.6" y2="271"/>
<line className="st2" x1="297.1" y1="264.8" x2="297.7" y2="264.2"/>
<line className="st2" x1="304" y1="271.6" x2="304.4" y2="272.1"/>
<line className="st2" x1="304.6" y1="271" x2="313.6" y2="280"/>
<line className="st2" x1="267.2" y1="255.3" x2="267.7" y2="255.8"/>
<line className="st2" x1="314" y1="313.6" x2="314.5" y2="313"/>
<line className="st2" x1="262.1" y1="260.6" x2="267.2" y2="255.3"/>
<line className="st2" x1="267.2" y1="256.2" x2="268.4" y2="257.4"/>
<line className="st2" x1="263.9" y1="259.6" x2="265.1" y2="260.8"/>
<line className="st2" x1="267.8" y1="256.8" x2="269.8" y2="254.9"/>
<line className="st2" x1="267.2" y1="256.2" x2="269.2" y2="254.3"/>
<line className="st2" x1="268.4" y1="257.4" x2="270.4" y2="255.5"/>
<line className="st2" x1="304.4" y1="300.4" x2="302.6" y2="302.2"/>
<line className="st2" x1="304.4" y1="299.4" x2="302.8" y2="301.1"/>
<line className="st2" x1="304.4" y1="300.4" x2="307.8" y2="303.8"/>
<line className="st2" x1="304.4" y1="299.4" x2="308.2" y2="303.3"/>
<polyline className="st2" points="306,305.6 307,306.5 307.6,306 "/>
<line className="st2" x1="314.5" y1="313" x2="315" y2="313.5"/>
<line className="st2" x1="314" y1="313.6" x2="314.5" y2="314.1"/>
<line className="st2" x1="361.4" y1="330.3" x2="364.8" y2="333.6"/>
<line className="st2" x1="360.5" y1="330.3" x2="364.3" y2="334.1"/>
<line className="st2" x1="361.4" y1="330.3" x2="362.6" y2="329.1"/>
<line className="st2" x1="360.5" y1="330.3" x2="361.6" y2="329.2"/>
<line className="st2" x1="390.1" y1="380.8" x2="388.9" y2="379.6"/>
<line className="st2" x1="393.5" y1="377.4" x2="392.3" y2="376.2"/>
<line className="st2" x1="388.9" y1="382" x2="394.7" y2="376.2"/>
<line className="st2" x1="389.4" y1="382.5" x2="395" y2="376.7"/>
<line className="st2" x1="392.8" y1="376.8" x2="398.8" y2="370.8"/>
<line className="st2" x1="392.3" y1="376.2" x2="398.2" y2="370.2"/>
<line className="st2" x1="393.4" y1="377.3" x2="399.4" y2="371.4"/>
<line className="st2" x1="386" y1="385.6" x2="386.6" y2="385.1"/>
<line className="st2" x1="392.9" y1="391.4" x2="395" y2="389.2"/>
<polyline className="st2" points="391.3,391 391.9,390.4 392.4,390.9 "/>
<line className="st2" x1="385.6" y1="385.1" x2="386" y2="385.6"/>
<line className="st2" x1="392.8" y1="392.3" x2="393.4" y2="391.7"/>
<polyline className="st2" points="398,397.6 398.6,397.1 399.1,397.6 "/>
<line className="st2" x1="391.3" y1="391" x2="392.8" y2="392.3"/>
<line className="st1" x1="100.3" y1="149.8" x2="102.6" y2="147.6"/>
<line className="st1" x1="94.2" y1="156" x2="94.6" y2="155.6"/>
<line className="st2" x1="94.7" y1="156.6" x2="95.2" y2="156.2"/>
<line className="st2" x1="99.8" y1="151.5" x2="99.2" y2="150.9"/>
<line className="st2" x1="95.2" y1="156.2" x2="94.6" y2="155.6"/>
<line className="st2" x1="99.8" y1="151.5" x2="103.1" y2="148.2"/>
<line className="st1" x1="99.2" y1="150.9" x2="99.7" y2="150.4"/>
<line className="st2" x1="58.6" y1="192.8" x2="58.1" y2="193.2"/>
<polyline className="st2" points="58,192.2 57.5,192.6 58.1,193.2 "/>
<line className="st2" x1="53.4" y1="197.9" x2="52.9" y2="197.3"/>
<line className="st2" x1="50.4" y1="199.7" x2="46.3" y2="203.8"/>
<line className="st2" x1="52.9" y1="197.3" x2="51" y2="199.1"/>
<line className="st2" x1="53.4" y1="197.9" x2="49.8" y2="201.5"/>
<line className="st2" x1="115.3" y1="201.4" x2="136.4" y2="201.4"/>
<line className="st2" x1="361" y1="351.9" x2="361.6" y2="352.5"/>
<line className="st2" x1="365.2" y1="347.8" x2="365.8" y2="348.3"/>
<line className="st2" x1="360.1" y1="340.7" x2="366.1" y2="346.7"/>
<line className="st2" x1="359.6" y1="341.2" x2="365.8" y2="347.2"/>
<line className="st2" x1="372.7" y1="341.3" x2="373.2" y2="340.8"/>
<line className="st2" x1="372.6" y1="340.2" x2="372.6" y2="340.2"/>
<line className="st2" x1="368" y1="344.9" x2="368.5" y2="345.5"/>
<line className="st2" x1="372.2" y1="340.7" x2="372.7" y2="341.3"/>
<line className="st2" x1="365.8" y1="348.3" x2="368.5" y2="345.5"/>
<line className="st2" x1="357.8" y1="356.2" x2="361.6" y2="352.5"/>
<line className="st2" x1="372.2" y1="340.7" x2="372.6" y2="340.2"/>
<line className="st2" x1="365.2" y1="347.8" x2="365.8" y2="347.2"/>
<line className="st2" x1="357.2" y1="355.7" x2="361" y2="351.9"/>
<line className="st2" x1="359.6" y1="341.2" x2="365.8" y2="347.2"/>
<line className="st2" x1="373.2" y1="340.8" x2="373.2" y2="340.8"/>
<line className="st2" x1="372.6" y1="340.2" x2="372.6" y2="340.2"/>
<line className="st2" x1="366.1" y1="346.7" x2="368" y2="344.9"/>
<line className="st2" x1="50.9" y1="182.7" x2="57.5" y2="176.1"/>
<line className="st2" x1="51.5" y1="183.3" x2="55.7" y2="179.1"/>
<line className="st2" x1="56.8" y1="179" x2="56.2" y2="179.6"/>
<polyline className="st2" points="57,176.7 50.9,182.7 57.5,176.1 "/>
<line className="st2" x1="56.8" y1="179" x2="56.3" y2="178.5"/>
<line className="st2" x1="64" y1="186.2" x2="60.2" y2="182.6"/>
<line className="st2" x1="56.2" y1="179.6" x2="55.7" y2="179.1"/>
<line className="st2" x1="63.4" y1="186.8" x2="59.6" y2="183.2"/>
<polyline className="st2" points="56.3,178.5 58.1,176.7 57.5,176.1 "/>
<line className="st2" x1="60.2" y1="182.6" x2="59.6" y2="183.2"/>
<line className="st2" x1="50.4" y1="199.7" x2="45.1" y2="194.4"/>
<polyline className="st2" points="51,199.1 45.7,193.8 45.1,194.4 "/>
<line className="st2" x1="120.6" y1="202.2" x2="120.6" y2="201.4"/>
<line className="st2" x1="131.2" y1="202.2" x2="131.2" y2="201.4"/>
<line className="st2" x1="120.6" y1="202.2" x2="120.6" y2="201.4"/>
<line className="st2" x1="131.2" y1="202.2" x2="131.2" y2="201.4"/>
<line className="st2" x1="137.4" y1="174.5" x2="139.1" y2="174.5"/>
<line className="st2" x1="88.1" y1="253.8" x2="88.1" y2="252.2"/>
<line className="st2" x1="98" y1="253.8" x2="98" y2="252.2"/>
<line className="st2" x1="163.7" y1="253.8" x2="167.3" y2="253.8"/>
<line className="st2" x1="153.7" y1="253" x2="151.8" y2="253"/>
<line className="st2" x1="163.7" y1="252.2" x2="166.3" y2="252.2"/>
<line className="st2" x1="98" y1="253.8" x2="100" y2="253.8"/>
<line className="st2" x1="98" y1="253" x2="100" y2="253"/>
<line className="st2" x1="88.1" y1="252.2" x2="85.4" y2="252.2"/>
<line className="st2" x1="302.8" y1="324.8" x2="304.9" y2="326.9"/>
<line className="st2" x1="269.9" y1="291.9" x2="268.3" y2="290.2"/>
<line className="st2" x1="285.7" y1="307.7" x2="275.5" y2="297.5"/>
<line className="st2" x1="344.3" y1="368.6" x2="345.5" y2="367.5"/>
<line className="st2" x1="285.2" y1="308.3" x2="274.9" y2="298"/>
<line className="st1" x1="339.2" y1="385" x2="343" y2="381.2"/>
<line className="st1" x1="340.1" y1="385" x2="343.4" y2="381.6"/>
<line className="st1" x1="343.8" y1="389.6" x2="339.2" y2="385"/>
<line className="st1" x1="344.3" y1="389.2" x2="340.1" y2="385"/>
<line className="st1" x1="314.6" y1="360.4" x2="318.5" y2="356.7"/>
<line className="st1" x1="314.6" y1="359.6" x2="318" y2="356.2"/>
<line className="st1" x1="310.1" y1="355.8" x2="314.6" y2="360.4"/>
<line className="st1" x1="318" y1="356.2" x2="318.5" y2="356.7"/>
<line className="st1" x1="304.2" y1="350" x2="307.9" y2="346.2"/>
<line className="st1" x1="305.2" y1="350" x2="308.4" y2="346.7"/>
<line className="st1" x1="308.9" y1="354.6" x2="304.2" y2="350"/>
<line className="st1" x1="308.4" y1="346.7" x2="307.9" y2="346.2"/>
<line className="st1" x1="309.4" y1="354.2" x2="305.2" y2="350"/>
<line className="st1" x1="279.7" y1="325.5" x2="283.4" y2="321.8"/>
<line className="st1" x1="279.7" y1="324.5" x2="283" y2="321.3"/>
<line className="st1" x1="273.8" y1="319.6" x2="269.2" y2="314.9"/>
<line className="st1" x1="274.3" y1="319.1" x2="270.1" y2="314.9"/>
<line className="st2" x1="355.8" y1="377.7" x2="346.3" y2="368.3"/>
<line className="st2" x1="339.4" y1="362.4" x2="337.2" y2="360.3"/>
<polyline className="st2" points="338.8,363 339.8,361.8 337.8,359.8 "/>
<line className="st2" x1="355.8" y1="377.7" x2="345.5" y2="367.5"/>
<line className="st2" x1="343" y1="371.9" x2="345.2" y2="369.5"/>
<polyline className="st2" points="343.4,372.4 343,371.9 343.4,372.4 345.7,370 "/>
<polyline className="st2" points="349.9,374.2 347.2,377 347.6,377.4 350.4,374.7 "/>
<line className="st2" x1="355.2" y1="378.3" x2="344.9" y2="368.1"/>
<line className="st2" x1="310" y1="333" x2="331.6" y2="354.8"/>
<polyline className="st2" points="310.4,332.4 332.2,354.2 331.1,355.4 "/>
<line className="st2" x1="336.6" y1="360.9" x2="337.8" y2="359.8"/>
<line className="st2" x1="327.7" y1="356.7" x2="330.1" y2="354.4"/>
<line className="st2" x1="327.2" y1="356.2" x2="329.6" y2="353.9"/>
<polyline className="st2" points="325.4,349.7 322.7,352.5 322.2,352 325,349.2 "/>
<line className="st2" x1="307.9" y1="336.9" x2="310.3" y2="334.6"/>
<polyline className="st2" points="308.4,337.4 307.9,336.9 308.4,337.4 310.7,335.1 "/>
<polyline className="st2" points="314.9,339.3 312.1,342 312.6,342.5 315.4,339.6 "/>
<line className="st2" x1="286.3" y1="309.5" x2="296.6" y2="319.7"/>
<polyline className="st2" points="286.9,308.9 297.2,319.1 296,320.3 "/>
<line className="st2" x1="301.7" y1="326" x2="302.8" y2="324.8"/>
<line className="st2" x1="292.8" y1="321.8" x2="295.1" y2="319.4"/>
<line className="st2" x1="292.3" y1="321.3" x2="294.6" y2="318.9"/>
<polyline className="st2" points="290.4,314.7 287.6,317.6 287.2,317.1 289.9,314.3 "/>
<polyline className="st2" points="274.9,298 275.5,297.5 274.3,298.6 "/>
<line className="st2" x1="273" y1="302" x2="275.3" y2="299.6"/>
<polyline className="st2" points="273.4,302.3 273,302 273.4,302.3 275.8,300 "/>
<polyline className="st2" points="280,304.2 277.2,307 277.6,307.5 280.4,304.7 "/>
<line className="st2" x1="269.3" y1="292.5" x2="268.9" y2="292"/>
<line className="st2" x1="268.8" y1="293.1" x2="269.9" y2="291.9"/>
<line className="st2" x1="338.8" y1="363" x2="338.3" y2="362.6"/>
<line className="st2" x1="354.6" y1="378.9" x2="344.3" y2="368.6"/>
<line className="st2" x1="336.6" y1="360.9" x2="337.1" y2="361.4"/>
<line className="st2" x1="320.8" y1="345" x2="331.1" y2="355.4"/>
<line className="st2" x1="319.6" y1="343.8" x2="309.4" y2="333.6"/>
<line className="st2" x1="285.7" y1="310.1" x2="296" y2="320.3"/>
<line className="st2" x1="284.6" y1="308.9" x2="274.3" y2="298.6"/>
<line className="st2" x1="268.8" y1="293.1" x2="268.3" y2="292.6"/>
<line className="st2" x1="301.7" y1="326" x2="302.2" y2="326.4"/>
<polyline className="st2" points="304.9,326.9 304.3,327.4 302.2,325.4 "/>
<polyline className="st2" points="304.3,327.4 303.7,328 303.2,327.5 "/>
<line className="st1" x1="269.2" y1="314.9" x2="273" y2="311.2"/>
<polyline className="st1" points="270.1,314.9 273.4,311.7 273,311.2 "/>
<line className="st2" x1="292.8" y1="321.8" x2="292.3" y2="321.3"/>
<line className="st2" x1="283.4" y1="321.8" x2="283" y2="321.3"/>
<line className="st2" x1="343.4" y1="381.6" x2="343" y2="381.2"/>
<line className="st1" x1="327.2" y1="356.2" x2="327.7" y2="356.7"/>
<line className="st2" x1="309.4" y1="333.6" x2="310.4" y2="332.4"/>
<polyline className="st2" points="396.4,340.4 397.4,339.3 397.1,338.8 "/>
<line className="st2" x1="397" y1="339.8" x2="396.5" y2="339.4"/>
<polyline className="st2" points="413.4,355.1 404.6,346.4 403.4,347.4 412.2,356.2 "/>
<line className="st2" x1="404" y1="347" x2="412.8" y2="355.7"/>
<line className="st1" x1="444.1" y1="310.4" x2="451.1" y2="317.3"/>
<polyline className="st1" points="445.3,325.5 453.4,317.4 445.2,309.3 "/>
<line className="st1" x1="451.1" y1="317.3" x2="444.1" y2="324.3"/>
<polyline className="st12" points="444.7,324.9 444.5,324.6 452,317.1 452.3,317.3 "/>
<line className="st12" x1="451.8" y1="317.4" x2="452" y2="317.6"/>
<line className="st12" x1="444.8" y1="324.4" x2="445" y2="324.6"/>
<line className="st12" x1="452.3" y1="317.3" x2="444.7" y2="324.9"/>
<line className="st12" x1="446.6" y1="322.5" x2="446.9" y2="322.7"/>
<line className="st12" x1="447" y1="322.1" x2="447.2" y2="322.4"/>
<line className="st6" x1="451.9" y1="317.4" x2="447.1" y2="322.2"/>
<line className="st6" x1="446.8" y1="322.6" x2="445" y2="324.5"/>
<polyline className="st12" points="444.7,309.8 444.5,310 452,317.6 452.3,317.3 "/>
<line className="st12" x1="451.8" y1="317.2" x2="452" y2="317"/>
<line className="st12" x1="444.8" y1="310.2" x2="445" y2="310"/>
<line className="st12" x1="452.3" y1="317.3" x2="444.7" y2="309.8"/>
<line className="st12" x1="446.6" y1="312.2" x2="446.9" y2="311.9"/>
<line className="st12" x1="447" y1="312.5" x2="447.2" y2="312.3"/>
<line className="st6" x1="451.9" y1="317.1" x2="447.1" y2="312.4"/>
<line className="st6" x1="446.8" y1="312" x2="445" y2="310.1"/>
<line className="st1" x1="444.1" y1="324.3" x2="445.3" y2="325.5"/>
<line className="st1" x1="445.2" y1="309.3" x2="444.1" y2="310.4"/>
<path className="st24" d="M269.2,314.9l0.7-4.8l2.2,2L269.2,314.9z M267.1,312.9l2,2l0.7-4.8L267.1,312.9z"/>
<path className="st25" d="M269.2,314.9l0.7-4.8l2.2,2L269.2,314.9z M267.1,312.9l2,2l0.7-4.8L267.1,312.9z"/>
<polyline className="st5" points="267.1,312.9 269.2,314.9 272,312.2 269.9,310.1 267.1,312.9 272,312.2 269.9,310.1 269.2,314.9 "/>
<polyline className="st5" points="267.8,314.2 267.8,314.7 267.4,314.2 267.8,314.2 "/>
<path className="st24" d="M267.1,312.9l0.7-4.9l2,2.2L267.1,312.9z M265,310.7l2.2,2.2l0.7-4.9L265,310.7z"/>
<path className="st25" d="M267.1,312.9l0.7-4.9l2,2.2L267.1,312.9z M265,310.7l2.2,2.2l0.7-4.9L265,310.7z"/>
<polyline className="st5" points="265,310.7 267.1,312.9 269.9,310.1 267.8,308 265,310.7 269.9,310.1 267.8,308 267.1,312.9 "/>
<polyline className="st5" points="265.7,312.2 265.8,312.6 265.3,312.2 265.7,312.2 "/>
<path className="st24" d="M283.9,329.7l0.7-4.9l2,2.2L283.9,329.7z M281.8,327.5l2.2,2.2l0.7-4.9L281.8,327.5z"/>
<path className="st25" d="M283.9,329.7l0.7-4.9l2,2.2L283.9,329.7z M281.8,327.5l2.2,2.2l0.7-4.9L281.8,327.5z"/>
<polyline className="st5" points="281.8,327.5 283.9,329.7 286.7,326.9 284.6,324.8 281.8,327.5 286.7,326.9 284.6,324.8 283.9,329.7 
	"/>
<polyline className="st5" points="282.5,329 282.6,329.4 282,329 282.5,329 "/>
<path className="st24" d="M281.8,327.5l0.7-4.8l2.2,2L281.8,327.5z M279.7,325.5l2,2l0.7-4.8L279.7,325.5z"/>
<path className="st25" d="M281.8,327.5l0.7-4.8l2.2,2L281.8,327.5z M279.7,325.5l2,2l0.7-4.8L279.7,325.5z"/>
<polyline className="st5" points="279.7,325.5 281.8,327.5 284.6,324.8 282.5,322.7 279.7,325.5 284.6,324.8 282.5,322.7 281.8,327.5 
	"/>
<polyline className="st5" points="280.4,326.8 280.4,327.3 280,326.8 280.4,326.8 "/>
<path className="st24" d="M304.2,350l0.7-4.9l2,2.2L304.2,350z M302.2,347.8l2,2.2l0.7-4.9L302.2,347.8z"/>
<path className="st25" d="M304.2,350l0.7-4.9l2,2.2L304.2,350z M302.2,347.8l2,2.2l0.7-4.9L302.2,347.8z"/>
<polyline className="st5" points="302.2,347.8 304.2,350 307,347.2 304.9,345 302.2,347.8 307,347.2 304.9,345 304.2,350 "/>
<polyline className="st5" points="302.8,349.2 302.9,349.7 302.4,349.2 302.8,349.2 "/>
<path className="st24" d="M302.2,347.8l0.6-4.8l2.2,2L302.2,347.8z M300,345.8l2.2,2l0.6-4.8L300,345.8z"/>
<path className="st25" d="M302.2,347.8l0.6-4.8l2.2,2L302.2,347.8z M300,345.8l2.2,2l0.6-4.8L300,345.8z"/>
<polyline className="st5" points="300,345.8 302.2,347.8 304.9,345 302.8,343 300,345.8 304.9,345 302.8,343 302.2,347.8 "/>
<polyline className="st5" points="300.7,347.2 300.7,347.6 300.2,347.1 300.7,347.2 "/>
<path className="st24" d="M318.8,364.6l0.7-4.8l2.2,2L318.8,364.6z M316.8,362.6l2,2l0.7-4.8L316.8,362.6z"/>
<path className="st25" d="M318.8,364.6l0.7-4.8l2.2,2L318.8,364.6z M316.8,362.6l2,2l0.7-4.8L316.8,362.6z"/>
<polyline className="st5" points="316.8,362.6 318.8,364.6 321.7,361.8 319.6,359.8 316.8,362.6 321.7,361.8 319.6,359.8 318.8,364.6 
	"/>
<polyline className="st5" points="317.5,364 317.5,364.4 317,363.9 317.5,364 "/>
<path className="st24" d="M316.8,362.6l0.7-4.9l2,2.2L316.8,362.6z M314.6,360.4l2.2,2.2l0.7-4.9L314.6,360.4z"/>
<path className="st25" d="M316.8,362.6l0.7-4.9l2,2.2L316.8,362.6z M314.6,360.4l2.2,2.2l0.7-4.9L314.6,360.4z"/>
<polyline className="st5" points="314.6,360.4 316.8,362.6 319.6,359.8 317.5,357.6 314.6,360.4 319.6,359.8 317.5,357.6 316.8,362.6 
	"/>
<polyline className="st5" points="315.4,361.8 315.5,362.3 315,361.8 315.4,361.8 "/>
<path className="st24" d="M339.2,385l0.6-4.9l2.2,2L339.2,385z M337.1,382.8l2.2,2.2l0.6-4.9L337.1,382.8z"/>
<path className="st25" d="M339.2,385l0.6-4.9l2.2,2L339.2,385z M337.1,382.8l2.2,2.2l0.6-4.9L337.1,382.8z"/>
<polyline className="st5" points="337.1,382.8 339.2,385 342,382.1 339.8,380.1 337.1,382.8 342,382.1 339.8,380.1 339.2,385 "/>
<polyline className="st5" points="337.8,384.3 337.8,384.6 337.3,384.2 337.8,384.3 "/>
<path className="st24" d="M337.1,382.8l0.7-4.9l2,2.2L337.1,382.8z M335,380.8l2,2l0.7-4.9L335,380.8z"/>
<path className="st25" d="M337.1,382.8l0.7-4.9l2,2.2L337.1,382.8z M335,380.8l2,2l0.7-4.9L335,380.8z"/>
<polyline className="st5" points="335,380.8 337.1,382.8 339.8,380.1 337.8,377.9 335,380.8 339.8,380.1 337.8,377.9 337.1,382.8 "/>
<polyline className="st5" points="335.6,382.1 335.8,382.6 335.3,382.1 335.6,382.1 "/>
<path className="st24" d="M354.5,399l0.7-4.9l2,2.2L354.5,399z M352.3,397l2.2,2l0.7-4.9L352.3,397z"/>
<path className="st25" d="M354.5,399l0.7-4.9l2,2.2L354.5,399z M352.3,397l2.2,2l0.7-4.9L352.3,397z"/>
<polyline className="st5" points="352.3,397 354.5,399 357.2,396.3 355.2,394.1 352.3,397 357.2,396.3 355.2,394.1 354.5,399 "/>
<polyline className="st5" points="353,398.3 353,398.8 352.6,398.3 353,398.3 "/>
<path className="st24" d="M352.3,397l0.7-4.9l2.2,2L352.3,397z M350.3,394.8l2,2.2l0.7-4.9L350.3,394.8z"/>
<path className="st25" d="M352.3,397l0.7-4.9l2.2,2L352.3,397z M350.3,394.8l2,2.2l0.7-4.9L350.3,394.8z"/>
<polyline className="st5" points="350.3,394.8 352.3,397 355.2,394.1 353,392.1 350.3,394.8 355.2,394.1 353,392.1 352.3,397 "/>
<polyline className="st5" points="351,396.3 351,396.8 350.5,396.3 351,396.3 "/>
<line className="st1" x1="275" y1="320.8" x2="279.7" y2="325.5"/>
<path className="st16" d="M91.2,156.8h-5.6l2.9,2.8L91.2,156.8z M88.4,154l2.8,2.8h-5.6L88.4,154z"/>
<path className="st17" d="M91.2,156.8h-5.6l2.9,2.8L91.2,156.8z M88.4,154l2.8,2.8h-5.6L88.4,154z"/>
<line className="st19" x1="88.1" y1="155.7" x2="89.6" y2="157.4"/>
<line className="st19" x1="87.4" y1="156.4" x2="89" y2="158.1"/>
<line className="st19" x1="87.7" y1="156" x2="89.3" y2="157.7"/>
<line className="st19" x1="86.5" y1="157.1" x2="88.2" y2="158.8"/>
<line className="st19" x1="87.2" y1="157" x2="88.3" y2="158.1"/>
<line className="st19" x1="90.4" y1="156.5" x2="88.7" y2="154.8"/>
<line className="st19" x1="89.8" y1="156.6" x2="88.7" y2="155.6"/>
<path className="st19" d="M88.6,156.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0
	C88.7,156.9,88.7,156.7,88.6,156.6"/>
<line className="st19" x1="87.2" y1="156.8" x2="88.4" y2="155.6"/>
<path className="st19" d="M88.7,154.8c-0.1-0.1-0.3-0.1-0.4,0"/>
<path className="st19" d="M88.6,155.5c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st19" d="M86.5,156.6c-0.1,0.1-0.1,0.3,0,0.4"/>
<path className="st19" d="M87.2,156.7c-0.1,0.1-0.1,0.2,0,0.3"/>
<line className="st19" x1="88.6" y1="158.1" x2="89.8" y2="157"/>
<line className="st19" x1="88.7" y1="158.8" x2="90.4" y2="157"/>
<path className="st19" d="M90.4,156.9c0.1-0.1,0.1-0.3,0-0.4"/>
<path className="st19" d="M89.7,156.8c0.1-0.1,0.1-0.2,0-0.3"/>
<path className="st19" d="M88.2,158.7c0.1,0.1,0.3,0.1,0.4,0"/>
<path className="st19" d="M88.3,158c0.1,0.1,0.2,0.1,0.3,0"/>
<path className="st19" d="M88.3,158c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st19" x1="86.5" y1="156.6" x2="88.2" y2="154.8"/>
<path className="st19" d="M88.5,156.7c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C88.6,156.9,88.6,156.8,88.5,156.7"/>
<line className="st19" x1="88.8" y1="157.1" x2="88.1" y2="156.5"/>
<line className="st19" x1="88.8" y1="156.5" x2="88.1" y2="157.1"/>
<polyline className="st19" points="88.4,159.5 85.6,156.8 88.4,154 91.2,156.8 88.4,159.5 "/>
<path className="st16" d="M216.1,210.5l1.9,1.7l-1.4-1.3L216.1,210.5z M218.3,212.7l-2.2-2.2l1.9,1.7L218.3,212.7z M216.1,210.5l2.2,2.2
	L216.1,210.5z M216.1,210.5L216.1,210.5l2.2,2.2L216.1,210.5z"/>
<path className="st17" d="M216.1,210.5l1.9,1.7l-1.4-1.3L216.1,210.5z M218.3,212.7l-2.2-2.2l1.9,1.7L218.3,212.7z M216.1,210.5l2.2,2.2
	L216.1,210.5z M216.1,210.5L216.1,210.5l2.2,2.2L216.1,210.5z"/>
<path className="st16" d="M215.9,210.5l2.4,2.2l-2.2-2.2H215.9z M218.3,212.9l-2.4-2.4l2.4,2.2V212.9z"/>
<path className="st17" d="M215.9,210.5l2.4,2.2l-2.2-2.2H215.9z M218.3,212.9l-2.4-2.4l2.4,2.2V212.9z"/>
<path className="st16" d="M215.6,210.5l0.7,0.6l-0.5-0.6H215.6z M216,210.9l-0.4-0.4l0.7,0.6L216,210.9z"/>
<path className="st17" d="M215.6,210.5l0.7,0.6l-0.5-0.6H215.6z M216,210.9l-0.4-0.4l0.7,0.6L216,210.9z"/>
<path className="st16" d="M216,210.9l1.8,1.6l-1.4-1.3L216,210.9z M217.9,212.8l-1.9-1.9l1.8,1.6L217.9,212.8z"/>
<path className="st17" d="M216,210.9l1.8,1.6l-1.4-1.3L216,210.9z M217.9,212.8l-1.9-1.9l1.8,1.6L217.9,212.8z"/>
<path className="st16" d="M215.6,210.5L215.6,210.5l0.4,0.4L215.6,210.5z M216,210.9l-0.4-0.4L216,210.9z"/>
<path className="st17" d="M215.6,210.5L215.6,210.5l0.4,0.4L215.6,210.5z M216,210.9l-0.4-0.4L216,210.9z"/>
<path className="st16" d="M216,210.9l1.9,1.9L216,210.9z M217.9,212.9l-1.9-2l1.9,1.9V212.9z"/>
<path className="st17" d="M216,210.9l1.9,1.9L216,210.9z M217.9,212.9l-1.9-2l1.9,1.9V212.9z"/>
<path className="st16" d="M215.6,210.6l0.4,0.2l-0.4-0.4V210.6z M215.9,210.9l-0.2-0.2l0.4,0.2H215.9z"/>
<path className="st17" d="M215.6,210.6l0.4,0.2l-0.4-0.4V210.6z M215.9,210.9l-0.2-0.2l0.4,0.2H215.9z"/>
<path className="st16" d="M215.9,210.9l2,2l-1.9-2H215.9z M215.9,210.9L215.9,210.9l2,2L215.9,210.9z"/>
<path className="st17" d="M215.9,210.9l2,2l-1.9-2H215.9z M215.9,210.9L215.9,210.9l2,2L215.9,210.9z"/>
<path className="st16" d="M215.6,210.6L215.6,210.6l0.2,0.2L215.6,210.6z M215.9,210.9l-0.2-0.2L215.9,210.9z"/>
<path className="st17" d="M215.6,210.6L215.6,210.6l0.2,0.2L215.6,210.6z M215.9,210.9l-0.2-0.2L215.9,210.9z"/>
<path className="st16" d="M215.9,210.9L215.9,210.9l2,2L215.9,210.9z M217.9,212.9l-2-2L217.9,212.9z"/>
<path className="st17" d="M215.9,210.9L215.9,210.9l2,2L215.9,210.9z M217.9,212.9l-2-2L217.9,212.9z"/>
<path className="st16" d="M215.6,210.6L215.6,210.6l0.2,0.2L215.6,210.6z M215.9,210.9l-0.2-0.2L215.9,210.9z"/>
<path className="st17" d="M215.6,210.6L215.6,210.6l0.2,0.2L215.6,210.6z M215.9,210.9l-0.2-0.2L215.9,210.9z"/>
<path className="st16" d="M215.9,210.9L215.9,210.9l2,2L215.9,210.9z M217.9,212.9l-2-2L217.9,212.9z"/>
<path className="st17" d="M215.9,210.9L215.9,210.9l2,2L215.9,210.9z M217.9,212.9l-2-2L217.9,212.9z"/>
<path className="st16" d="M217.9,212.9h0.4l-0.5-0.5L217.9,212.9z M218.3,213.3l-0.4-0.4h0.4V213.3z"/>
<path className="st17" d="M217.9,212.9h0.4l-0.5-0.5L217.9,212.9z M218.3,213.3l-0.4-0.4h0.4V213.3z"/>
<path className="st16" d="M215.9,210.9l2,2L215.9,210.9z M217.9,213l-2-2.2l2,2V213z"/>
<path className="st17" d="M215.9,210.9l2,2L215.9,210.9z M217.9,213l-2-2.2l2,2V213z"/>
<path className="st16" d="M217.9,213l0.4,0.2l-0.4-0.4V213z M217.9,213L217.9,213l0.4,0.2L217.9,213z"/>
<path className="st17" d="M217.9,213l0.4,0.2l-0.4-0.4V213z M217.9,213L217.9,213l0.4,0.2L217.9,213z"/>
<polygon className="st16" points="214.8,210.3 215.3,210.8 214.7,210.3 "/>
<polygon className="st17" points="214.8,210.3 215.3,210.8 214.7,210.3 "/>
<path className="st16" d="M215.5,211l2.4,2l-2-2.2L215.5,211z M217.8,213.3l-2.3-2.3l2.4,2L217.8,213.3z"/>
<path className="st17" d="M215.5,211l2.4,2l-2-2.2L215.5,211z M217.8,213.3l-2.3-2.3l2.4,2L217.8,213.3z"/>
<path className="st16" d="M217.8,213.3h0.5l-0.4-0.2L217.8,213.3z M218,213.5l-0.2-0.2h0.5L218,213.5z"/>
<path className="st17" d="M217.8,213.3h0.5l-0.4-0.2L217.8,213.3z M218,213.5l-0.2-0.2h0.5L218,213.5z"/>
<path className="st16" d="M214.7,210.3l0.6,0.5L214.7,210.3z M215.2,210.9l-0.5-0.6l0.6,0.5L215.2,210.9z"/>
<path className="st17" d="M214.7,210.3l0.6,0.5L214.7,210.3z M215.2,210.9l-0.5-0.6l0.6,0.5L215.2,210.9z"/>
<path className="st16" d="M214.6,210.3l0.6,0.6l-0.5-0.6H214.6z M214.6,210.3L214.6,210.3l0.6,0.6L214.6,210.3z"/>
<path className="st17" d="M214.6,210.3l0.6,0.6l-0.5-0.6H214.6z M214.6,210.3L214.6,210.3l0.6,0.6L214.6,210.3z"/>
<path className="st16" d="M215.2,210.9h0.7l-0.2-0.2L215.2,210.9z M215.4,211.1l-0.2-0.2h0.7L215.4,211.1z"/>
<path className="st17" d="M215.2,210.9h0.7l-0.2-0.2L215.2,210.9z M215.4,211.1l-0.2-0.2h0.7L215.4,211.1z"/>
<path className="st16" d="M214.6,210.3L214.6,210.3l0.6,0.6L214.6,210.3z M215.2,210.9l-0.6-0.6L215.2,210.9z"/>
<path className="st17" d="M214.6,210.3L214.6,210.3l0.6,0.6L214.6,210.3z M215.2,210.9l-0.6-0.6L215.2,210.9z"/>
<path className="st16" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st17" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st16" d="M215.4,211.1l2.4,2.2l-2.3-2.3L215.4,211.1z M217.8,213.4l-2.4-2.3l2.4,2.2V213.4z"/>
<path className="st17" d="M215.4,211.1l2.4,2.2l-2.3-2.3L215.4,211.1z M217.8,213.4l-2.4-2.3l2.4,2.2V213.4z"/>
<path className="st16" d="M214.4,210.3l0.7,0.6l-0.6-0.6H214.4z M214.4,210.3L214.4,210.3l0.7,0.6L214.4,210.3z"/>
<path className="st17" d="M214.4,210.3l0.7,0.6l-0.6-0.6H214.4z M214.4,210.3L214.4,210.3l0.7,0.6L214.4,210.3z"/>
<path className="st16" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st17" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st16" d="M215.4,211.1l2.4,2.3L215.4,211.1z M217.7,213.5l-2.3-2.4l2.4,2.3L217.7,213.5z"/>
<path className="st17" d="M215.4,211.1l2.4,2.3L215.4,211.1z M217.7,213.5l-2.3-2.4l2.4,2.3L217.7,213.5z"/>
<path className="st16" d="M217.7,213.5h0.4l-0.2-0.2L217.7,213.5z M217.9,213.6l-0.2-0.1h0.4L217.9,213.6z"/>
<path className="st17" d="M217.7,213.5h0.4l-0.2-0.2L217.7,213.5z M217.9,213.6l-0.2-0.1h0.4L217.9,213.6z"/>
<path className="st16" d="M214.4,210.3L214.4,210.3l0.7,0.6L214.4,210.3z M215.2,210.9l-0.7-0.6L215.2,210.9z"/>
<path className="st17" d="M214.4,210.3L214.4,210.3l0.7,0.6L214.4,210.3z M215.2,210.9l-0.7-0.6L215.2,210.9z"/>
<path className="st16" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st17" d="M215.2,210.9L215.2,210.9l0.2,0.2L215.2,210.9z M215.4,211.1l-0.2-0.2L215.4,211.1z"/>
<path className="st16" d="M215.4,211.1L215.4,211.1l2.3,2.4L215.4,211.1z M217.7,213.5l-2.3-2.4L217.7,213.5z"/>
<path className="st17" d="M215.4,211.1L215.4,211.1l2.3,2.4L215.4,211.1z M217.7,213.5l-2.3-2.4L217.7,213.5z"/>
<path className="st16" d="M217.7,213.5l0.2,0.1L217.7,213.5z M217.9,213.8l-0.2-0.2l0.2,0.1V213.8z"/>
<path className="st17" d="M217.7,213.5l0.2,0.1L217.7,213.5z M217.9,213.8l-0.2-0.2l0.2,0.1V213.8z"/>
<path className="st16" d="M214.3,210.2l0.8,0.7l-0.7-0.6L214.3,210.2z M215,211l-0.7-0.8l0.8,0.7L215,211z"/>
<path className="st17" d="M214.3,210.2l0.8,0.7l-0.7-0.6L214.3,210.2z M215,211l-0.7-0.8l0.8,0.7L215,211z"/>
<path className="st16" d="M215,211l0.4,0.1l-0.2-0.2L215,211z M215.3,211.2L215,211l0.4,0.1L215.3,211.2z"/>
<path className="st17" d="M215,211l0.4,0.1l-0.2-0.2L215,211z M215.3,211.2L215,211l0.4,0.1L215.3,211.2z"/>
<path className="st16" d="M215.3,211.2l2.4,2.3l-2.3-2.4L215.3,211.2z M215.3,211.2L215.3,211.2l2.4,2.3L215.3,211.2z"/>
<path className="st17" d="M215.3,211.2l2.4,2.3l-2.3-2.4L215.3,211.2z M215.3,211.2L215.3,211.2l2.4,2.3L215.3,211.2z"/>
<path className="st16" d="M217.7,213.5L217.7,213.5l0.2,0.2L217.7,213.5z M217.9,213.8l-0.2-0.2L217.9,213.8z"/>
<path className="st17" d="M217.7,213.5L217.7,213.5l0.2,0.2L217.7,213.5z M217.9,213.8l-0.2-0.2L217.9,213.8z"/>
<path className="st16" d="M214.2,210.2l0.8,0.8l-0.7-0.8H214.2z M214.2,210.2L214.2,210.2l0.8,0.8L214.2,210.2z"/>
<path className="st17" d="M214.2,210.2l0.8,0.8l-0.7-0.8H214.2z M214.2,210.2L214.2,210.2l0.8,0.8L214.2,210.2z"/>
<path className="st16" d="M215,211L215,211l0.2,0.2L215,211z M215.3,211.2L215,211L215.3,211.2z"/>
<path className="st17" d="M215,211L215,211l0.2,0.2L215,211z M215.3,211.2L215,211L215.3,211.2z"/>
<path className="st16" d="M215.3,211.2l1.2,1.1L215.3,211.2z M216.4,212.3l-1.1-1.1l1.2,1.1H216.4z"/>
<path className="st17" d="M215.3,211.2l1.2,1.1L215.3,211.2z M216.4,212.3l-1.1-1.1l1.2,1.1H216.4z"/>
<path className="st16" d="M216.5,212.6l1.2,1l-1.2-1.2V212.6z M217.6,213.5l-1.1-1l1.2,1H217.6z"/>
<path className="st17" d="M216.5,212.6l1.2,1l-1.2-1.2V212.6z M217.6,213.5l-1.1-1l1.2,1H217.6z"/>
<path className="st16" d="M217.6,213.5l0.4,0.2l-0.2-0.2H217.6z M217.8,213.8l-0.2-0.2l0.4,0.2H217.8z"/>
<path className="st17" d="M217.6,213.5l0.4,0.2l-0.2-0.2H217.6z M217.8,213.8l-0.2-0.2l0.4,0.2H217.8z"/>
<path className="st16" d="M214.2,210.2L214.2,210.2l0.8,0.8L214.2,210.2z M215,211l-0.8-0.8L215,211z"/>
<path className="st17" d="M214.2,210.2L214.2,210.2l0.8,0.8L214.2,210.2z M215,211l-0.8-0.8L215,211z"/>
<path className="st16" d="M215,211L215,211l0.2,0.2L215,211z M215.3,211.2L215,211L215.3,211.2z"/>
<path className="st17" d="M215,211L215,211l0.2,0.2L215,211z M215.3,211.2L215,211L215.3,211.2z"/>
<path className="st16" d="M214.1,210.3l1,0.7l-0.8-0.8L214.1,210.3z M214.9,211.1l-0.8-0.8l1,0.7L214.9,211.1z"/>
<path className="st17" d="M214.1,210.3l1,0.7l-0.8-0.8L214.1,210.3z M214.9,211.1l-0.8-0.8l1,0.7L214.9,211.1z"/>
<path className="st16" d="M214.9,211.1l0.4,0.1L215,211L214.9,211.1z M215.2,211.4l-0.2-0.2l0.4,0.1L215.2,211.4z"/>
<path className="st17" d="M214.9,211.1l0.4,0.1L215,211L214.9,211.1z M215.2,211.4l-0.2-0.2l0.4,0.1L215.2,211.4z"/>
<path className="st16" d="M215.2,211.4l1.2,1l-1.1-1.1L215.2,211.4z M216.2,212.3l-1.1-1l1.2,1H216.2z"/>
<path className="st17" d="M215.2,211.4l1.2,1l-1.1-1.1L215.2,211.4z M216.2,212.3l-1.1-1l1.2,1H216.2z"/>
<path className="st16" d="M216.5,212.7l1.1,0.8l-1.1-1V212.7z M217.6,213.6l-1.1-1l1.1,0.8V213.6z"/>
<path className="st17" d="M216.5,212.7l1.1,0.8l-1.1-1V212.7z M217.6,213.6l-1.1-1l1.1,0.8V213.6z"/>
<path className="st16" d="M215,211.5l1.2,0.8l-1.1-1L215,211.5z M216.1,212.6l-1.1-1.1l1.2,0.8L216.1,212.6z"/>
<path className="st17" d="M215,211.5l1.2,0.8l-1.1-1L215,211.5z M216.1,212.6l-1.1-1.1l1.2,0.8L216.1,212.6z"/>
<path className="st16" d="M216.4,212.7l1.2,1l-1.1-1H216.4z M217.4,213.8l-1.1-1.1l1.2,1L217.4,213.8z"/>
<path className="st17" d="M216.4,212.7l1.2,1l-1.1-1H216.4z M217.4,213.8l-1.1-1.1l1.2,1L217.4,213.8z"/>
<path className="st16" d="M215,211.5l1.1,1.1L215,211.5z M216.2,212.7l-1.2-1.2l1.1,1.1L216.2,212.7z"/>
<path className="st17" d="M215,211.5l1.1,1.1L215,211.5z M216.2,212.7l-1.2-1.2l1.1,1.1L216.2,212.7z"/>
<path className="st16" d="M216.2,212.7l1.2,1.1l-1.1-1.1H216.2z M217.3,213.8l-1.1-1.1l1.2,1.1H217.3z"/>
<path className="st17" d="M216.2,212.7l1.2,1.1l-1.1-1.1H216.2z M217.3,213.8l-1.1-1.1l1.2,1.1H217.3z"/>
<path className="st16" d="M213.8,210.4l1.1,0.7l-0.8-0.8L213.8,210.4z M214.7,211.4l-0.8-1l1.1,0.7L214.7,211.4z"/>
<path className="st17" d="M213.8,210.4l1.1,0.7l-0.8-0.8L213.8,210.4z M214.7,211.4l-0.8-1l1.1,0.7L214.7,211.4z"/>
<path className="st16" d="M214.7,211.4h0.5l-0.2-0.2L214.7,211.4z M214.9,211.6l-0.2-0.2h0.5L214.9,211.6z"/>
<path className="st17" d="M214.7,211.4h0.5l-0.2-0.2L214.7,211.4z M214.9,211.6l-0.2-0.2h0.5L214.9,211.6z"/>
<path className="st16" d="M214.9,211.6l1.3,1.1l-1.2-1.2L214.9,211.6z M216.1,212.7l-1.2-1.1l1.3,1.1H216.1z"/>
<path className="st17" d="M214.9,211.6l1.3,1.1l-1.2-1.2L214.9,211.6z M216.1,212.7l-1.2-1.1l1.3,1.1H216.1z"/>
<path className="st16" d="M216.1,212.7l1.2,1.1l-1.1-1.1H216.1z M217.3,213.9l-1.2-1.2l1.2,1.1V213.9z"/>
<path className="st17" d="M216.1,212.7l1.2,1.1l-1.1-1.1H216.1z M217.3,213.9l-1.2-1.2l1.2,1.1V213.9z"/>
<path className="st16" d="M213.6,210.5l1.1,0.8l-0.8-1L213.6,210.5z M214.6,211.5l-1-1l1.1,0.8L214.6,211.5z"/>
<path className="st17" d="M213.6,210.5l1.1,0.8l-0.8-1L213.6,210.5z M214.6,211.5l-1-1l1.1,0.8L214.6,211.5z"/>
<path className="st16" d="M214.6,211.5l0.4,0.1l-0.2-0.2L214.6,211.5z M214.8,211.7l-0.2-0.2l0.4,0.1L214.8,211.7z"/>
<path className="st17" d="M214.6,211.5l0.4,0.1l-0.2-0.2L214.6,211.5z M214.8,211.7l-0.2-0.2l0.4,0.1L214.8,211.7z"/>
<path className="st16" d="M214.8,211.7l1.2,0.8l-1.1-1L214.8,211.7z M215.9,212.8l-1.1-1.1l1.2,0.8L215.9,212.8z"/>
<path className="st17" d="M214.8,211.7l1.2,0.8l-1.1-1L214.8,211.7z M215.9,212.8l-1.1-1.1l1.2,0.8L215.9,212.8z"/>
<path className="st16" d="M213.5,210.6l1.4,1.2l-1.3-1.3L213.5,210.6z M214.8,211.8l-1.3-1.2l1.4,1.2H214.8z"/>
<path className="st17" d="M213.5,210.6l1.4,1.2l-1.3-1.3L213.5,210.6z M214.8,211.8l-1.3-1.2l1.4,1.2H214.8z"/>
<path className="st16" d="M214.8,211.8l1.1,1l-1-1H214.8z M215.8,212.8l-1-1l1.1,1H215.8z"/>
<path className="st17" d="M214.8,211.8l1.1,1l-1-1H214.8z M215.8,212.8l-1-1l1.1,1H215.8z"/>
<path className="st16" d="M216,213.2l1.3,0.7l-1.1-1L216,213.2z M217.1,214.1l-1.1-1l1.3,0.7L217.1,214.1z"/>
<path className="st17" d="M216,213.2l1.3,0.7l-1.1-1L216,213.2z M217.1,214.1l-1.1-1l1.3,0.7L217.1,214.1z"/>
<path className="st16" d="M217.1,214.1l0.7-0.4l-0.2-0.2L217.1,214.1z M217.3,214.4l-0.2-0.2l0.7-0.4L217.3,214.4z"/>
<path className="st17" d="M217.1,214.1l0.7-0.4l-0.2-0.2L217.1,214.1z M217.3,214.4l-0.2-0.2l0.7-0.4L217.3,214.4z"/>
<path className="st16" d="M214.7,212.1l1.1,0.7l-1-1L214.7,212.1z M215.5,213l-0.8-1l1.1,0.7L215.5,213z"/>
<path className="st17" d="M214.7,212.1l1.1,0.7l-1-1L214.7,212.1z M215.5,213l-0.8-1l1.1,0.7L215.5,213z"/>
<path className="st16" d="M214.6,212.1l1,1l-0.8-1H214.6z M214.6,212.1L214.6,212.1l1,1L214.6,212.1z M215.5,213l-1-1L215.5,213z
	 M214.6,212.1l1,1L214.6,212.1z"/>
<path className="st17" d="M214.6,212.1l1,1l-0.8-1H214.6z M214.6,212.1L214.6,212.1l1,1L214.6,212.1z M215.5,213l-1-1L215.5,213z
	 M214.6,212.1l1,1L214.6,212.1z"/>
<path className="st16" d="M215.8,213.3l0.7,0.2l-0.5-0.4L215.8,213.3z M216.2,213.8l-0.5-0.5l0.7,0.2L216.2,213.8z"/>
<path className="st17" d="M215.8,213.3l0.7,0.2l-0.5-0.4L215.8,213.3z M216.2,213.8l-0.5-0.5l0.7,0.2L216.2,213.8z"/>
<path className="st16" d="M214.4,212.2l1.1,0.8l-1-1L214.4,212.2z M215.4,213.2l-1-1l1.1,0.8L215.4,213.2z"/>
<path className="st17" d="M214.4,212.2l1.1,0.8l-1-1L214.4,212.2z M215.4,213.2l-1-1l1.1,0.8L215.4,213.2z"/>
<path className="st16" d="M215.6,213.4l0.6,0.4l-0.5-0.5L215.6,213.4z M216.1,213.9l-0.5-0.5l0.6,0.4L216.1,213.9z"/>
<path className="st17" d="M215.6,213.4l0.6,0.4l-0.5-0.5L215.6,213.4z M216.1,213.9l-0.5-0.5l0.6,0.4L216.1,213.9z"/>
<path className="st16" d="M213.1,211l1.7,0.8l-1.3-1.2L213.1,211z M214.4,212.3l-1.3-1.3l1.7,0.8L214.4,212.3z"/>
<path className="st17" d="M213.1,211l1.7,0.8l-1.3-1.2L213.1,211z M214.4,212.3l-1.3-1.3l1.7,0.8L214.4,212.3z"/>
<path className="st16" d="M214.4,212.3l1,0.8l-1-1V212.3z M215.5,213.3l-1.1-1l1,0.8L215.5,213.3z"/>
<path className="st17" d="M214.4,212.3l1,0.8l-1-1V212.3z M215.5,213.3l-1.1-1l1,0.8L215.5,213.3z"/>
<path className="st16" d="M215.5,213.3l0.6,0.6l-0.5-0.5L215.5,213.3z M216.1,214l-0.6-0.7l0.6,0.6V214z"/>
<path className="st17" d="M215.5,213.3l0.6,0.6l-0.5-0.5L215.5,213.3z M216.1,214l-0.6-0.7l0.6,0.6V214z"/>
<path className="st16" d="M213.1,211.1l1.3,1.2l-1.3-1.3V211.1z M214.3,212.3l-1.2-1.2l1.3,1.2H214.3z"/>
<path className="st17" d="M213.1,211.1l1.3,1.2l-1.3-1.3V211.1z M214.3,212.3l-1.2-1.2l1.3,1.2H214.3z"/>
<path className="st16" d="M214.3,212.3l1.2,1l-1.1-1H214.3z M215.4,213.4l-1.1-1.1l1.2,1L215.4,213.4z"/>
<path className="st17" d="M214.3,212.3l1.2,1l-1.1-1H214.3z M215.4,213.4l-1.1-1.1l1.2,1L215.4,213.4z"/>
<path className="st16" d="M215.4,213.4l0.7,0.6l-0.6-0.7L215.4,213.4z M216,214l-0.6-0.6l0.7,0.6H216z"/>
<path className="st17" d="M215.4,213.4l0.7,0.6l-0.6-0.7L215.4,213.4z M216,214l-0.6-0.6l0.7,0.6H216z"/>
<path className="st16" d="M216,214l0.8-0.1l-0.4-0.4L216,214z M216.4,214.4L216,214l0.8-0.1L216.4,214.4z"/>
<path className="st17" d="M216,214l0.8-0.1l-0.4-0.4L216,214z M216.4,214.4L216,214l0.8-0.1L216.4,214.4z"/>
<path className="st16" d="M216.4,214.4l0.6-0.4l-0.1-0.1L216.4,214.4z M216.5,214.5l-0.1-0.1l0.6-0.4L216.5,214.5z"/>
<path className="st17" d="M216.4,214.4l0.6-0.4l-0.1-0.1L216.4,214.4z M216.5,214.5l-0.1-0.1l0.6-0.4L216.5,214.5z"/>
<path className="st16" d="M216.5,214.5l0.8-0.1L217,214L216.5,214.5z M216.8,214.8l-0.4-0.4l0.8-0.1L216.8,214.8z"/>
<path className="st17" d="M216.5,214.5l0.8-0.1L217,214L216.5,214.5z M216.8,214.8l-0.4-0.4l0.8-0.1L216.8,214.8z"/>
<path className="st16" d="M212.8,211l2.2,1.9l-2-2L212.8,211z M214.8,213l-2-2l2.2,1.9L214.8,213z"/>
<path className="st17" d="M212.8,211l2.2,1.9l-2-2L212.8,211z M214.8,213l-2-2l2.2,1.9L214.8,213z"/>
<path className="st16" d="M215.8,214l1.3,1.1l-1.2-1.2L215.8,214z M217,215.2l-1.2-1.2l1.3,1.1L217,215.2z"/>
<path className="st17" d="M215.8,214l1.3,1.1l-1.2-1.2L215.8,214z M217,215.2l-1.2-1.2l1.3,1.1L217,215.2z"/>
<path className="st16" d="M212.6,211l2.6,2.5l-2.5-2.5H212.6z M215.3,213.6l-2.6-2.6l2.6,2.5V213.6z"/>
<path className="st17" d="M212.6,211l2.6,2.5l-2.5-2.5H212.6z M215.3,213.6l-2.6-2.6l2.6,2.5V213.6z"/>
<path className="st16" d="M215.3,213.6l1.7,1.6l-1.7-1.7V213.6z M216.8,215.2l-1.6-1.6l1.7,1.6H216.8z"/>
<path className="st17" d="M215.3,213.6l1.7,1.6l-1.7-1.7V213.6z M216.8,215.2l-1.6-1.6l1.7,1.6H216.8z"/>
<line className="st19" x1="216.8" y1="212" x2="214.4" y2="214.5"/>
<line className="st19" x1="214.9" y1="211.8" x2="213.6" y2="210.5"/>
<line className="st19" x1="215.8" y1="212.8" x2="214.8" y2="211.8"/>
<line className="st19" x1="216.8" y1="213.9" x2="216.5" y2="213.5"/>
<polyline className="st19" points="216.8,214.8 217.3,214.4 217,214 216.5,214.5 "/>
<line className="st19" x1="216.8" y1="213.9" x2="216.4" y2="214.4"/>
<line className="st19" x1="216.5" y1="213.5" x2="216" y2="214"/>
<line className="st19" x1="214.9" y1="211.8" x2="214.3" y2="212.3"/>
<line className="st19" x1="213.6" y1="210.5" x2="213.1" y2="211.1"/>
<line className="st19" x1="216.2" y1="212.9" x2="216" y2="212.6"/>
<path className="st19" d="M215.5,213.2c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C215.4,213,215.4,213.2,215.5,213.2"/>
<line className="st19" x1="216.2" y1="212.9" x2="215.8" y2="213.3"/>
<line className="st19" x1="216" y1="212.6" x2="215.4" y2="213.2"/>
<polyline className="st19" points="215.9,213.9 215.8,214 214.8,213 214.9,212.9 "/>
<path className="st19" d="M216.2,212.6c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0
	C216.1,212.4,216.1,212.6,216.2,212.6"/>
<path className="st19" d="M216.5,210.7c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3"/>
<path className="st19" d="M217.9,213.7c0.2-0.2,0.4-0.5,0.4-0.7s-0.1-0.6-0.3-0.8"/>
<path className="st19" d="M217.7,213.4c0.2-0.1,0.2-0.3,0.3-0.5c0-0.2-0.1-0.4-0.2-0.5"/>
<path className="st19" d="M216.3,210.9c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.5,0.2"/>
<line className="st19" x1="217.8" y1="212.4" x2="216.4" y2="211.1"/>
<line className="st19" x1="215.4" y1="211.1" x2="214.8" y2="211.7"/>
<polyline className="st19" points="217.7,213.5 217.1,214.1 216,213.2 "/>
<line className="st19" x1="218" y1="212.2" x2="216.6" y2="210.9"/>
<path className="st19" d="M215.1,210.7c-0.1-0.3-0.4-0.6-0.7-0.6c-0.3-0.1-0.7,0.1-0.9,0.4"/>
<line className="st19" x1="217.1" y1="215.1" x2="212.9" y2="210.9"/>
<polyline className="st19" points="217.1,215.1 216.8,215.2 212.6,211 212.9,210.9 "/>
<line className="st19" x1="215.2" y1="210.9" x2="214.6" y2="211.5"/>
<line className="st19" x1="217.9" y1="213.8" x2="216.8" y2="214.8"/>
<path className="st20" d="M215.5,213L215.5,213z"/>
<path className="st21" d="M215.5,213L215.5,213z"/>
<path className="st20" d="M215.5,213L215.5,213z"/>
<path className="st21" d="M215.5,213L215.5,213z"/>
<path className="st20" d="M215.5,213L215.5,213z"/>
<path className="st21" d="M215.5,213L215.5,213z"/>
<polygon className="st20" points="215.5,213.2 215.5,213 215.4,213.2 "/>
<polygon className="st21" points="215.5,213.2 215.5,213 215.4,213.2 "/>
<path className="st20" d="M215.5,213v0.1V213z"/>
<path className="st21" d="M215.5,213v0.1V213z"/>
<path className="st20" d="M215.5,213L215.5,213z"/>
<path className="st21" d="M215.5,213L215.5,213z"/>
<path className="st16" d="M91.2,156.8h-5.6l2.9,2.8L91.2,156.8z M88.4,154l2.8,2.8h-5.6L88.4,154z"/>
<path className="st22" d="M91.2,156.8h-5.6l2.9,2.8L91.2,156.8z M88.4,154l2.8,2.8h-5.6L88.4,154z"/>
<line className="st19" x1="88.1" y1="155.7" x2="89.6" y2="157.4"/>
<line className="st19" x1="87.4" y1="156.4" x2="89" y2="158.1"/>
<line className="st19" x1="87.7" y1="156" x2="89.3" y2="157.7"/>
<line className="st19" x1="86.5" y1="157.1" x2="88.2" y2="158.8"/>
<line className="st19" x1="87.2" y1="157" x2="88.3" y2="158.1"/>
<line className="st19" x1="90.4" y1="156.5" x2="88.7" y2="154.8"/>
<line className="st19" x1="89.8" y1="156.6" x2="88.7" y2="155.6"/>
<path className="st19" d="M88.6,156.6c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.3,0.1,0.4,0
	C88.7,156.9,88.7,156.7,88.6,156.6"/>
<line className="st19" x1="87.2" y1="156.8" x2="88.4" y2="155.6"/>
<path className="st19" d="M88.7,154.8c-0.1-0.1-0.3-0.1-0.4,0"/>
<path className="st19" d="M88.6,155.5c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st19" d="M86.5,156.6c-0.1,0.1-0.1,0.3,0,0.4"/>
<path className="st19" d="M87.2,156.7c-0.1,0.1-0.1,0.2,0,0.3"/>
<line className="st19" x1="88.6" y1="158.1" x2="89.8" y2="157"/>
<line className="st19" x1="88.7" y1="158.8" x2="90.4" y2="157"/>
<path className="st19" d="M90.4,156.9c0.1-0.1,0.1-0.3,0-0.4"/>
<path className="st19" d="M89.7,156.8c0.1-0.1,0.1-0.2,0-0.3"/>
<path className="st19" d="M88.2,158.7c0.1,0.1,0.3,0.1,0.4,0"/>
<path className="st19" d="M88.3,158c0.1,0.1,0.2,0.1,0.3,0"/>
<path className="st19" d="M88.3,158c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st19" x1="86.5" y1="156.6" x2="88.2" y2="154.8"/>
<path className="st19" d="M88.5,156.7c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C88.6,156.9,88.6,156.8,88.5,156.7"/>
<line className="st19" x1="88.8" y1="157.1" x2="88.1" y2="156.5"/>
<line className="st19" x1="88.8" y1="156.5" x2="88.1" y2="157.1"/>
<polyline className="st19" points="88.4,159.5 85.6,156.8 88.4,154 91.2,156.8 88.4,159.5 "/>
<path className="st16" d="M394.3,378.9v5.5l2.8-2.8L394.3,378.9z M391.4,381.6l2.9-2.8v5.5L391.4,381.6z"/>
<path className="st17" d="M394.3,378.9v5.5l2.8-2.8L394.3,378.9z M391.4,381.6l2.9-2.8v5.5L391.4,381.6z"/>
<line className="st19" x1="393.2" y1="382" x2="394.8" y2="380.3"/>
<line className="st19" x1="393.8" y1="382.7" x2="395.5" y2="381"/>
<line className="st19" x1="393.5" y1="382.4" x2="395.2" y2="380.7"/>
<line className="st19" x1="394.6" y1="383.4" x2="396.2" y2="381.8"/>
<line className="st19" x1="394.4" y1="382.8" x2="395.6" y2="381.6"/>
<line className="st19" x1="394.1" y1="379.6" x2="392.4" y2="381.3"/>
<line className="st19" x1="394.2" y1="380.2" x2="393" y2="381.4"/>
<path className="st19" d="M394,381.2c-0.1,0.1-0.1,0.3,0,0.4s0.3,0.1,0.4,0s0.1-0.3,0-0.4S394.2,381.1,394,381.2"/>
<line className="st19" x1="394.2" y1="382.8" x2="393.1" y2="381.6"/>
<path className="st19" d="M392.4,381.2c-0.1,0.1-0.1,0.3,0,0.4"/>
<path className="st19" d="M393.1,381.3c-0.1,0.1-0.1,0.2,0,0.3"/>
<path className="st19" d="M394,383.3c0.1,0.1,0.3,0.1,0.4,0"/>
<path className="st19" d="M394.3,382.7c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st19" x1="395.6" y1="381.4" x2="394.4" y2="380.2"/>
<line className="st19" x1="396.2" y1="381.3" x2="394.6" y2="379.6"/>
<path className="st19" d="M394.5,379.6c-0.1-0.1-0.3-0.1-0.4,0"/>
<path className="st19" d="M394.4,380.2c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st19" d="M396.2,381.7c0.1-0.1,0.1-0.3,0-0.4"/>
<path className="st19" d="M395.6,381.6c0.1-0.1,0.1-0.2,0-0.3"/>
<path className="st19" d="M395.6,381.6c0.1-0.1,0.1-0.2,0-0.3"/>
<line className="st19" x1="394.1" y1="383.4" x2="392.4" y2="381.8"/>
<path className="st19" d="M394.1,381.3c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C394.3,381.3,394.2,381.3,394.1,381.3"/>
<line className="st19" x1="394.7" y1="381.2" x2="394" y2="381.9"/>
<line className="st19" x1="394" y1="381.2" x2="394.7" y2="381.9"/>
<polyline className="st19" points="397.1,381.6 394.3,384.4 391.4,381.6 394.3,378.9 397.1,381.6 "/>
<path className="st16" d="M394.3,378.9v5.5l2.8-2.8L394.3,378.9z M391.4,381.6l2.9-2.8v5.5L391.4,381.6z"/>
<path className="st17" d="M394.3,378.9v5.5l2.8-2.8L394.3,378.9z M391.4,381.6l2.9-2.8v5.5L391.4,381.6z"/>
<line className="st19" x1="393.2" y1="382" x2="394.8" y2="380.3"/>
<line className="st19" x1="393.8" y1="382.7" x2="395.5" y2="381"/>
<line className="st19" x1="393.5" y1="382.4" x2="395.2" y2="380.7"/>
<line className="st19" x1="394.6" y1="383.4" x2="396.2" y2="381.8"/>
<line className="st19" x1="394.4" y1="382.8" x2="395.6" y2="381.6"/>
<line className="st19" x1="394.1" y1="379.6" x2="392.4" y2="381.3"/>
<line className="st19" x1="394.2" y1="380.2" x2="393" y2="381.4"/>
<path className="st19" d="M394,381.2c-0.1,0.1-0.1,0.3,0,0.4s0.3,0.1,0.4,0s0.1-0.3,0-0.4S394.2,381.1,394,381.2"/>
<line className="st19" x1="394.2" y1="382.8" x2="393.1" y2="381.6"/>
<path className="st19" d="M392.4,381.2c-0.1,0.1-0.1,0.3,0,0.4"/>
<path className="st19" d="M393.1,381.3c-0.1,0.1-0.1,0.2,0,0.3"/>
<path className="st19" d="M394,383.3c0.1,0.1,0.3,0.1,0.4,0"/>
<path className="st19" d="M394.3,382.7c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st19" x1="395.6" y1="381.4" x2="394.4" y2="380.2"/>
<line className="st19" x1="396.2" y1="381.3" x2="394.6" y2="379.6"/>
<path className="st19" d="M394.5,379.6c-0.1-0.1-0.3-0.1-0.4,0"/>
<path className="st19" d="M394.4,380.2c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st19" d="M396.2,381.7c0.1-0.1,0.1-0.3,0-0.4"/>
<path className="st19" d="M395.6,381.6c0.1-0.1,0.1-0.2,0-0.3"/>
<path className="st19" d="M395.6,381.6c0.1-0.1,0.1-0.2,0-0.3"/>
<line className="st19" x1="394.1" y1="383.4" x2="392.4" y2="381.8"/>
<path className="st19" d="M394.1,381.3c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C394.3,381.3,394.2,381.3,394.1,381.3"/>
<line className="st19" x1="394.7" y1="381.2" x2="394" y2="381.9"/>
<line className="st19" x1="394" y1="381.2" x2="394.7" y2="381.9"/>
<polyline className="st19" points="397.1,381.6 394.3,384.4 391.4,381.6 394.3,378.9 397.1,381.6 "/>
<line className="st2" x1="334.4" y1="313.2" x2="342.4" y2="321.2"/>
<line className="st2" x1="334.9" y1="312.8" x2="342.8" y2="320.7"/>
<path className="st16" d="M302.2,281.1h-5.5l2.8,2.8L302.2,281.1z M299.4,278.2l2.8,2.9h-5.5L299.4,278.2z"/>
<path className="st17" d="M302.2,281.1h-5.5l2.8,2.8L302.2,281.1z M299.4,278.2l2.8,2.9h-5.5L299.4,278.2z"/>
<line className="st19" x1="299" y1="279.9" x2="300.7" y2="281.6"/>
<line className="st19" x1="298.3" y1="280.6" x2="300" y2="282.3"/>
<line className="st19" x1="298.7" y1="280.2" x2="300.4" y2="281.9"/>
<line className="st19" x1="297.6" y1="281.3" x2="299.3" y2="283"/>
<line className="st19" x1="298.2" y1="281.2" x2="299.4" y2="282.4"/>
<line className="st19" x1="301.4" y1="280.8" x2="299.8" y2="279.2"/>
<line className="st19" x1="300.8" y1="281" x2="299.6" y2="279.8"/>
<path className="st19" d="M299.7,280.8c-0.1-0.1-0.3-0.1-0.4,0s-0.1,0.3,0,0.4s0.3,0.1,0.4,0S299.8,280.9,299.7,280.8"/>
<line className="st19" x1="298.2" y1="281" x2="299.4" y2="279.9"/>
<path className="st19" d="M299.7,279.1c-0.1-0.1-0.3-0.1-0.4,0"/>
<path className="st19" d="M299.6,279.8c-0.1-0.1-0.2-0.1-0.3,0"/>
<path className="st19" d="M297.6,280.8c-0.1,0.1-0.1,0.3,0,0.4"/>
<path className="st19" d="M298.3,280.9c-0.1,0.1-0.1,0.2,0,0.3"/>
<line className="st19" x1="299.6" y1="282.4" x2="300.8" y2="281.2"/>
<line className="st19" x1="299.8" y1="283" x2="301.4" y2="281.3"/>
<path className="st19" d="M301.4,281.2c0.1-0.1,0.1-0.3,0-0.4"/>
<path className="st19" d="M300.7,281.1c0.1-0.1,0.1-0.2,0-0.3"/>
<path className="st19" d="M299.2,282.9c0.1,0.1,0.3,0.1,0.4,0"/>
<path className="st19" d="M299.3,282.3c0.1,0.1,0.2,0.1,0.3,0"/>
<path className="st19" d="M299.3,282.3c0.1,0.1,0.2,0.1,0.3,0"/>
<line className="st19" x1="297.6" y1="280.8" x2="299.3" y2="279.2"/>
<path className="st19" d="M299.6,280.9c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0
	C299.7,281.1,299.7,281,299.6,280.9"/>
<line className="st19" x1="299.9" y1="281.4" x2="299.2" y2="280.7"/>
<line className="st19" x1="299.9" y1="280.7" x2="299.2" y2="281.4"/>
<polyline className="st19" points="299.4,283.8 296.6,281.1 299.4,278.2 302.2,281.1 299.4,283.8 "/>
<line className="st2" x1="151.4" y1="142.4" x2="152" y2="143"/>
<line className="st1" x1="82.4" y1="138.6" x2="96.7" y2="138.6"/>
<text transform="matrix(1 0 0 1 82.4401 137.0767)" className="st9 st26">3.44</text>
<line className="st1" x1="60.6" y1="151.2" x2="74.9" y2="151.2"/>
<text transform="matrix(1 0 0 1 60.5998 149.6773)" className="st9 st26">3.45</text>
<line className="st1" x1="46.2" y1="170.8" x2="60.6" y2="170.8"/>
<text transform="matrix(1 0 0 1 46.1999 169.2368)" className="st9 st26">3.47</text>
<line className="st1" x1="28.9" y1="189.6" x2="43.2" y2="189.6"/>
<text transform="matrix(1 0 0 1 28.9201 188.0772)" className="st9 st26">3.82</text>
<line className="st1" x1="60.5" y1="221.2" x2="74.9" y2="221.2"/>
<text transform="matrix(1 0 0 1 60.4802 219.6372)" className="st9 st26">3.48</text>
<line className="st1" x1="89.9" y1="241.5" x2="104.3" y2="241.5"/>
<text transform="matrix(1 0 0 1 89.8801 239.917)" className="st9 st26">3.83</text>
<line className="st1" x1="118.6" y1="241" x2="132.8" y2="241"/>
<text transform="matrix(1 0 0 1 118.5598 239.437)" className="st9 st26">3.84</text>
<line className="st1" x1="145.3" y1="240.6" x2="159.6" y2="240.6"/>
<text transform="matrix(1 0 0 1 145.3195 239.0767)" className="st9 st26">3.42</text>
<line className="st1" x1="170.6" y1="235.1" x2="184.9" y2="235.1"/>
<text transform="matrix(1 0 0 1 170.6398 233.5567)" className="st9 st26">3.07</text>
<line className="st1" x1="97.8" y1="180" x2="110.5" y2="180"/>
<text transform="matrix(1 0 0 1 97.8 179.0762)" className="st9 st27">3.85.2</text>
<line className="st1" x1="141.1" y1="179.2" x2="154" y2="179.2"/>
<text transform="matrix(1 0 0 1 141.1203 178.2368)" className="st9 st27">3.85.4</text>
<line className="st1" x1="100.6" y1="124.8" x2="109.6" y2="124.8"/>
<text transform="matrix(1 0 0 1 100.5598 123.8765)" className="st9 st27">3.43</text>
<line className="st1" x1="140.4" y1="124.8" x2="153.2" y2="124.8"/>
<text transform="matrix(1 0 0 1 140.3996 123.8765)" className="st9 st27">3.86.1</text>
<line className="st1" x1="119" y1="174.3" x2="133.3" y2="174.3"/>
<text transform="matrix(1 0 0 1 119.0402 172.7168)" className="st9 st26">3.85</text>
<line className="st1" x1="158.3" y1="134.6" x2="172.7" y2="134.6"/>
<text transform="matrix(1 0 0 1 158.2795 132.9966)" className="st9 st26">3.03</text>
<line className="st5" x1="129.6" y1="218.1" x2="129.8" y2="218.1"/>
<polyline className="st5" points="129.8,218.1 129.8,226 129.6,226 129.6,218.1 "/>
<path className="st5" d="M121.9,218c0,4.2,3.4,7.7,7.6,7.9"/>
<line className="st5" x1="129.6" y1="218.1" x2="129.8" y2="218.1"/>
<polyline className="st5" points="129.8,218.1 129.8,226 129.6,226 129.6,218.1 "/>
<path className="st5" d="M121.9,218c0,4.2,3.4,7.7,7.6,7.9"/>
<line className="st2" x1="129.8" y1="218.1" x2="137.4" y2="218.1"/>
<line className="st2" x1="114.4" y1="218.1" x2="121.9" y2="218.1"/>
<line className="st2" x1="110.2" y1="217.2" x2="121.9" y2="217.2"/>
<line className="st2" x1="129.8" y1="217.2" x2="137.4" y2="217.2"/>
<line className="st2" x1="121.9" y1="218.1" x2="121.9" y2="217.2"/>
<line className="st2" x1="129.8" y1="218.1" x2="129.8" y2="217.2"/>
<line className="st1" x1="171.8" y1="155.6" x2="186.2" y2="155.6"/>
<text transform="matrix(1 0 0 1 171.84 153.9971)" className="st9 st26">3.04</text>
<line className="st1" x1="189.5" y1="173" x2="203.8" y2="173"/>
<text transform="matrix(1 0 0 1 189.4797 171.397)" className="st9 st26">3.06</text>
<line className="st1" x1="207.7" y1="190.2" x2="222.1" y2="190.2"/>
<text transform="matrix(1 0 0 1 207.7199 188.6768)" className="st9 st26">3.41</text>
<line className="st1" x1="223.3" y1="208.6" x2="237.6" y2="208.6"/>
<text transform="matrix(1 0 0 1 223.3195 207.0366)" className="st9 st26">3.08</text>
<line className="st1" x1="247.8" y1="222.2" x2="262.1" y2="222.2"/>
<text transform="matrix(1 0 0 1 247.8 220.5967)" className="st9 st26">3.09</text>
<line className="st1" x1="265.9" y1="238.4" x2="280.2" y2="238.4"/>
<text transform="matrix(1 0 0 1 265.9201 236.7969)" className="st9 st26">3.10</text>
<line className="st1" x1="247.7" y1="250" x2="268.2" y2="250"/>
<text transform="matrix(1 0 0 1 247.6799 248.4365)" className="st9 st26">3.10.1</text>
<line className="st1" x1="185.8" y1="246.9" x2="200" y2="246.9"/>
<text transform="matrix(1 0 0 1 185.76 245.3164)" className="st9 st26">3.40</text>
<line className="st1" x1="200.9" y1="266.2" x2="215.2" y2="266.2"/>
<text transform="matrix(1 0 0 1 200.8801 264.6367)" className="st9 st26">3.39</text>
<line className="st1" x1="221" y1="283.6" x2="235.4" y2="283.6"/>
<text transform="matrix(1 0 0 1 221.0402 282.0371)" className="st9 st26">3.38</text>
<line className="st1" x1="239.2" y1="297" x2="253.6" y2="297"/>
<text transform="matrix(1 0 0 1 239.1604 295.4761)" className="st9 st26">3.37</text>
<line className="st1" x1="304.8" y1="232" x2="319.2" y2="232"/>
<text transform="matrix(1 0 0 1 304.8 230.3164)" className="st9 st26">3.11</text>
<line className="st1" x1="325" y1="250" x2="339.2" y2="250"/>
<text transform="matrix(1 0 0 1 324.9601 248.4365)" className="st9 st26">3.12</text>
<line className="st1" x1="338.2" y1="266.4" x2="352.4" y2="266.4"/>
<text transform="matrix(1 0 0 1 338.1603 264.8765)" className="st9 st26">3.13</text>
<line className="st1" x1="359" y1="284.6" x2="373.4" y2="284.6"/>
<text transform="matrix(1 0 0 1 359.0402 282.9971)" className="st9 st26">3.14</text>
<line className="st1" x1="373.8" y1="300.6" x2="388.1" y2="300.6"/>
<text transform="matrix(1 0 0 1 373.8 299.0762)" className="st9 st26">3.15</text>
<line className="st1" x1="394.3" y1="319.4" x2="408.7" y2="319.4"/>
<text transform="matrix(1 0 0 1 394.3205 317.7964)" className="st9 st26">3.16</text>
<line className="st1" x1="426.1" y1="402.2" x2="440.4" y2="402.2"/>
<text transform="matrix(1 0 0 1 426.1203 400.5962)" className="st9 st26">3.19</text>
<line className="st1" x1="425.5" y1="410.9" x2="440.6" y2="410.9"/>
<text transform="matrix(1 0 0 1 425.5197 409.357)" className="st9 st26">ПИТ</text>
<line className="st1" x1="354.1" y1="431.2" x2="368.5" y2="431.2"/>
<text transform="matrix(1 0 0 1 354.1203 429.6363)" className="st9 st26">3.22</text>
<line className="st1" x1="335.4" y1="412" x2="349.8" y2="412"/>
<text transform="matrix(1 0 0 1 335.3996 410.437)" className="st9 st26">3.23</text>
<line className="st1" x1="317.4" y1="395.2" x2="331.7" y2="395.2"/>
<text transform="matrix(1 0 0 1 317.3996 393.6363)" className="st9 st26">3.24</text>
<line className="st1" x1="301.3" y1="376.1" x2="315.6" y2="376.1"/>
<text transform="matrix(1 0 0 1 301.3195 374.5562)" className="st9 st26">3.25</text>
<line className="st1" x1="284.2" y1="359.3" x2="298.4" y2="359.3"/>
<text transform="matrix(1 0 0 1 284.1603 357.7564)" className="st9 st26">3.26</text>
<line className="st1" x1="267.2" y1="340.8" x2="281.6" y2="340.8"/>
<text transform="matrix(1 0 0 1 267.2404 339.2769)" className="st9 st26">3.27</text>
<line className="st1" x1="248.6" y1="325.5" x2="262.9" y2="325.5"/>
<text transform="matrix(1 0 0 1 248.6398 323.916)" className="st9 st26">3.28</text>
<line className="st1" x1="367.9" y1="361" x2="382.3" y2="361"/>
<text transform="matrix(1 0 0 1 367.9201 359.4366)" className="st9 st26">3.29</text>
<line className="st1" x1="341.6" y1="335" x2="356" y2="335"/>
<text transform="matrix(1 0 0 1 341.6398 333.3965)" className="st9 st26">3.30</text>
<line className="st1" x1="337" y1="316" x2="352.3" y2="316"/>
<text transform="matrix(1 0 0 1 336.9601 314.7969)" className="st9 st28">3.31.1</text>
<line className="st1" x1="313.4" y1="303.9" x2="324.1" y2="303.9"/>
<text transform="matrix(1 0 0 1 313.4406 302.6763)" className="st9 st28">3.20</text>
<line className="st1" x1="293.3" y1="289.6" x2="304" y2="289.6"/>
<text transform="matrix(1 0 0 1 293.2795 288.397)" className="st9 st28">3.33</text>
<line className="st1" x1="277.8" y1="286.2" x2="288.6" y2="286.2"/>
<text transform="matrix(1 0 0 1 277.8 285.0362)" className="st9 st28">3.34</text>
<line className="st1" x1="162.4" y1="206.6" x2="177.7" y2="206.6"/>
<text transform="matrix(1 0 0 1 162.3596 205.3569)" className="st9 st28">3.86.2</text>
<line className="st1" x1="80.8" y1="209.1" x2="91.6" y2="209.1"/>
<text transform="matrix(1 0 0 1 80.76 207.877)" className="st9 st28">3.02</text>
<line className="st1" x1="323.2" y1="312.6" x2="334" y2="312.6"/>
<text transform="matrix(1 0 0 1 323.1603 311.4361)" className="st9 st28">3.35</text>
<line className="st2" x1="316.2" y1="314.6" x2="316.7" y2="315"/>
<line className="st2" x1="315.6" y1="315.2" x2="316.1" y2="315.6"/>
<path className="st16" d="M338.4,308.3l-1.9,1.9L338.4,308.3z M336.5,310.1l1.9-1.8l-1.9,1.9V310.1z M338.4,308.3l-1.9,1.8L338.4,308.3z
	 M336.5,310.2l1.9-1.9l-1.9,1.8V310.2z"/>
<path className="st17" d="M338.4,308.3l-1.9,1.9L338.4,308.3z M336.5,310.1l1.9-1.8l-1.9,1.9V310.1z M338.4,308.3l-1.9,1.8L338.4,308.3z
	 M336.5,310.2l1.9-1.9l-1.9,1.8V310.2z"/>
<path className="st16" d="M338.3,308.1l-1.8,2l1.9-1.8L338.3,308.1z M336.2,310l2-1.9l-1.8,2L336.2,310z"/>
<path className="st17" d="M338.3,308.1l-1.8,2l1.9-1.8L338.3,308.1z M336.2,310l2-1.9l-1.8,2L336.2,310z"/>
<path className="st16" d="M338.2,308.1l-1,1l1.1-1H338.2z M337.3,309l0.8-1l-1,1H337.3z"/>
<path className="st17" d="M338.2,308.1l-1,1l1.1-1H338.2z M337.3,309l0.8-1l-1,1H337.3z"/>
<path className="st16" d="M337.2,309L337.2,309l-1,1L337.2,309z M336.2,310l1-1L336.2,310z"/>
<path className="st17" d="M337.2,309L337.2,309l-1,1L337.2,309z M336.2,310l1-1L336.2,310z"/>
<path className="st16" d="M338.2,308.1L338.2,308.1l-0.1,0.2L338.2,308.1z M338,308.3l0.1-0.2L338,308.3z"/>
<path className="st17" d="M338.2,308.1L338.2,308.1l-0.1,0.2L338.2,308.1z M338,308.3l0.1-0.2L338,308.3z"/>
<path className="st16" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st17" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st16" d="M338,308.3L338,308.3l-0.7,0.7L338,308.3z M337.3,309l0.7-0.7L337.3,309z"/>
<path className="st17" d="M338,308.3L338,308.3l-0.7,0.7L338,308.3z M337.3,309l0.7-0.7L337.3,309z"/>
<path className="st16" d="M337.2,309L337.2,309l-0.7,0.8L337.2,309z M336.5,309.9l0.7-0.8L336.5,309.9z"/>
<path className="st17" d="M337.2,309L337.2,309l-0.7,0.8L337.2,309z M336.5,309.9l0.7-0.8L336.5,309.9z"/>
<path className="st16" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st17" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st16" d="M338.2,308.1L338.2,308.1l-0.1,0.2L338.2,308.1z M338,308.3l0.1-0.2L338,308.3z"/>
<path className="st17" d="M338.2,308.1L338.2,308.1l-0.1,0.2L338.2,308.1z M338,308.3l0.1-0.2L338,308.3z"/>
<path className="st16" d="M338,308.3L338,308.3l-0.7,0.7L338,308.3z M337.3,309l0.7-0.7L337.3,309z"/>
<path className="st17" d="M338,308.3L338,308.3l-0.7,0.7L338,308.3z M337.3,309l0.7-0.7L337.3,309z"/>
<path className="st16" d="M337.2,309L337.2,309l-0.7,0.8L337.2,309z M336.5,309.9l0.7-0.8L336.5,309.9z"/>
<path className="st17" d="M337.2,309L337.2,309l-0.7,0.8L337.2,309z M336.5,309.9l0.7-0.8L336.5,309.9z"/>
<path className="st16" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st17" d="M336.5,309.9L336.5,309.9l-0.2,0.1L336.5,309.9z M336.2,310l0.2-0.1L336.2,310z"/>
<path className="st16" d="M338.2,308.1L338.2,308.1l-0.8,1L338.2,308.1z M337.3,309l0.8-1L337.3,309z"/>
<path className="st17" d="M338.2,308.1L338.2,308.1l-0.8,1L338.2,308.1z M337.3,309l0.8-1L337.3,309z"/>
<path className="st16" d="M337.2,309.2l-1,0.8l1-1V309.2z M337.2,309.2L337.2,309.2l-1,0.8L337.2,309.2z"/>
<path className="st17" d="M337.2,309.2l-1,0.8l1-1V309.2z M337.2,309.2L337.2,309.2l-1,0.8L337.2,309.2z"/>
<path className="st16" d="M338.2,308l-0.8,1.1l0.8-1V308z M337.2,308.9l1-1l-0.8,1.1L337.2,308.9z"/>
<path className="st17" d="M338.2,308l-0.8,1.1l0.8-1V308z M337.2,308.9l1-1l-0.8,1.1L337.2,308.9z"/>
<path className="st16" d="M337.1,309l-0.8,1l1-0.8L337.1,309z M337.1,309L337.1,309l-0.8,1L337.1,309z"/>
<path className="st17" d="M337.1,309l-0.8,1l1-0.8L337.1,309z M337.1,309L337.1,309l-0.8,1L337.1,309z"/>
<path className="st16" d="M338.2,308L338.2,308l-1,1L338.2,308z M337.2,308.9l1-1L337.2,308.9z"/>
<path className="st17" d="M338.2,308L338.2,308l-1,1L338.2,308z M337.2,308.9l1-1L337.2,308.9z"/>
<path className="st16" d="M337.1,309l-0.8,1L337.1,309z M336.1,310l1-1l-0.8,1H336.1z"/>
<path className="st17" d="M337.1,309l-0.8,1L337.1,309z M336.1,310l1-1l-0.8,1H336.1z"/>
<path className="st16" d="M338.2,308L338.2,308l-1,1L338.2,308z M337.2,308.9l1-1L337.2,308.9z"/>
<path className="st17" d="M338.2,308L338.2,308l-1,1L338.2,308z M337.2,308.9l1-1L337.2,308.9z"/>
<path className="st16" d="M337.1,309l-1,1L337.1,309z M336.1,309.9l1-0.8l-1,1V309.9z"/>
<path className="st17" d="M337.1,309l-1,1L337.1,309z M336.1,309.9l1-0.8l-1,1V309.9z"/>
<path className="st16" d="M338,308l-0.8,1l1-1H338z M337.1,308.9l1-1l-0.8,1H337.1z"/>
<path className="st17" d="M338,308l-0.8,1l1-1H338z M337.1,308.9l1-1l-0.8,1H337.1z"/>
<path className="st16" d="M337.1,308.9l-1,1l1-0.8V308.9z M337.1,308.9L337.1,308.9l-1,1L337.1,308.9z"/>
<path className="st17" d="M337.1,308.9l-1,1l1-0.8V308.9z M337.1,308.9L337.1,308.9l-1,1L337.1,308.9z"/>
<path className="st16" d="M337.9,307.7l-1.8,2.2l1.9-1.9L337.9,307.7z M335.9,309.8l2-2l-1.8,2.2L335.9,309.8z"/>
<path className="st17" d="M337.9,307.7l-1.8,2.2l1.9-1.9L337.9,307.7z M335.9,309.8l2-2l-1.8,2.2L335.9,309.8z"/>
<polygon className="st16" points="336.8,308.7 336.8,308.8 337,308.6 "/>
<polygon className="st17" points="336.8,308.7 336.8,308.8 337,308.6 "/>
<path className="st16" d="M337.9,307.6l-1,1.2l1-1.1V307.6z M337.7,307.8l0.2-0.2l-1,1.2L337.7,307.8z"/>
<path className="st17" d="M337.9,307.6l-1,1.2l1-1.1V307.6z M337.7,307.8l0.2-0.2l-1,1.2L337.7,307.8z"/>
<path className="st16" d="M336.1,309.5l-0.2,0.2l1.1-1L336.1,309.5z M336.1,309.5L336.1,309.5l-0.2,0.2L336.1,309.5z"/>
<path className="st17" d="M336.1,309.5l-0.2,0.2l1.1-1L336.1,309.5z M336.1,309.5L336.1,309.5l-0.2,0.2L336.1,309.5z"/>
<path className="st16" d="M337,308.6l-0.1,0.2L337,308.6z M336.7,308.8l0.2-0.2l-0.1,0.2H336.7z"/>
<path className="st17" d="M337,308.6l-0.1,0.2L337,308.6z M336.7,308.8l0.2-0.2l-0.1,0.2H336.7z"/>
<path className="st16" d="M337,308.6L337,308.6l-0.1,0.1L337,308.6z M336.8,308.7l0.1-0.1L336.8,308.7z"/>
<path className="st17" d="M337,308.6L337,308.6l-0.1,0.1L337,308.6z M336.8,308.7l0.1-0.1L336.8,308.7z"/>
<path className="st16" d="M336.8,308.7L336.8,308.7l-0.1,0.1L336.8,308.7z M336.7,308.8l0.1-0.1L336.7,308.8z"/>
<path className="st17" d="M336.8,308.7L336.8,308.7l-0.1,0.1L336.8,308.7z M336.7,308.8l0.1-0.1L336.7,308.8z"/>
<path className="st16" d="M337.9,307.6L337.9,307.6l-0.2,0.2L337.9,307.6z M337.7,307.8l0.2-0.2L337.7,307.8z"/>
<path className="st17" d="M337.9,307.6L337.9,307.6l-0.2,0.2L337.9,307.6z M337.7,307.8l0.2-0.2L337.7,307.8z"/>
<path className="st16" d="M336,309.5l-0.1,0.2l0.2-0.2H336z M335.8,309.6l0.2-0.1l-0.1,0.2L335.8,309.6z"/>
<path className="st17" d="M336,309.5l-0.1,0.2l0.2-0.2H336z M335.8,309.6l0.2-0.1l-0.1,0.2L335.8,309.6z"/>
<path className="st16" d="M337,308.6L337,308.6l-0.1,0.1L337,308.6z M336.8,308.7l0.1-0.1L336.8,308.7z"/>
<path className="st17" d="M337,308.6L337,308.6l-0.1,0.1L337,308.6z M336.8,308.7l0.1-0.1L336.8,308.7z"/>
<path className="st16" d="M336.7,308.7v0.1l0.1-0.1H336.7z M336.7,308.7L336.7,308.7v0.1V308.7z"/>
<path className="st17" d="M336.7,308.7v0.1l0.1-0.1H336.7z M336.7,308.7L336.7,308.7v0.1V308.7z"/>
<path className="st16" d="M336,309.5L336,309.5l-0.2,0.1L336,309.5z M335.8,309.6l0.2-0.1L335.8,309.6z"/>
<path className="st17" d="M336,309.5L336,309.5l-0.2,0.1L336,309.5z M335.8,309.6l0.2-0.1L335.8,309.6z"/>
<path className="st16" d="M336,309.5L336,309.5h-0.1H336z M335.9,309.5h0.1H335.9z"/>
<path className="st17" d="M336,309.5L336,309.5h-0.1H336z M335.9,309.5h0.1H335.9z"/>
<path className="st16" d="M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st17" d="M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st16" d="M337.9,307.6l-0.2,0.2L337.9,307.6z M337.7,307.7l0.2-0.1l-0.2,0.2V307.7z M337.9,307.6l-0.2,0.1L337.9,307.6z
	 M337.9,307.6L337.9,307.6l-0.2,0.1L337.9,307.6z"/>
<path className="st17" d="M337.9,307.6l-0.2,0.2L337.9,307.6z M337.7,307.7l0.2-0.1l-0.2,0.2V307.7z M337.9,307.6l-0.2,0.1L337.9,307.6z
	 M337.9,307.6L337.9,307.6l-0.2,0.1L337.9,307.6z"/>
<path className="st16" d="M337.7,307.7L337.7,307.7z M337.7,307.7L337.7,307.7z"/>
<path className="st17" d="M337.7,307.7L337.7,307.7z M337.7,307.7L337.7,307.7z"/>
<path className="st16" d="M337.8,307.6l-0.1,0.1l0.2-0.1H337.8z M337.8,307.6L337.8,307.6l-0.1,0.1L337.8,307.6z"/>
<path className="st17" d="M337.8,307.6l-0.1,0.1l0.2-0.1H337.8z M337.8,307.6L337.8,307.6l-0.1,0.1L337.8,307.6z"/>
<path className="st16" d="M337.7,307.7L337.7,307.7z M337.7,307.7L337.7,307.7z"/>
<path className="st17" d="M337.7,307.7L337.7,307.7z M337.7,307.7L337.7,307.7z"/>
<path className="st16" d="M335.9,309.5L335.9,309.5z"/>
<path className="st17" d="M335.9,309.5L335.9,309.5z"/>
<path className="st16" d="M335.9,309.5h0.1H335.9z"/>
<path className="st17" d="M335.9,309.5h0.1H335.9z"/>
<path className="st16" d="M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z
	 M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st17" d="M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z
	 M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st16" d="M337.8,307.5l-0.1,0.2l0.1-0.1V307.5z M337.8,307.5L337.8,307.5l-0.1,0.2L337.8,307.5z"/>
<path className="st17" d="M337.8,307.5l-0.1,0.2l0.1-0.1V307.5z M337.8,307.5L337.8,307.5l-0.1,0.2L337.8,307.5z"/>
<path className="st16" d="M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5
	z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5
	l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st17" d="M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5
	z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5
	l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st16" d="M335.8,309.6l0.1-0.1L335.8,309.6z M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5l-0.1,0.1
	L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st17" d="M335.8,309.6l0.1-0.1L335.8,309.6z M335.9,309.5l-0.1,0.1L335.9,309.5z M335.9,309.5L335.9,309.5l-0.1,0.1
	L335.9,309.5z M335.9,309.5L335.9,309.5z M335.9,309.5L335.9,309.5z M335.8,309.6l0.1-0.1L335.8,309.6z"/>
<path className="st16" d="M337.8,307.5l-0.1,0.2L337.8,307.5z M337.7,307.6l0.1-0.1l-0.1,0.2V307.6z"/>
<path className="st17" d="M337.8,307.5l-0.1,0.2L337.8,307.5z M337.7,307.6l0.1-0.1l-0.1,0.2V307.6z"/>
<path className="st16" d="M337.7,307.6L337.7,307.6v0.1V307.6z M337.7,307.7v-0.1V307.7z"/>
<path className="st17" d="M337.7,307.6L337.7,307.6v0.1V307.6z M337.7,307.7v-0.1V307.7z"/>
<polygon className="st16" points="335.9,309.5 335.9,309.4 335.8,309.4 "/>
<polygon className="st17" points="335.9,309.5 335.9,309.4 335.8,309.4 "/>
<path className="st16" d="M337.7,307.6h-0.1H337.7z"/>
<path className="st17" d="M337.7,307.6h-0.1H337.7z"/>
<path className="st16" d="M337.7,307.6L337.7,307.6v0.1V307.6z M337.7,307.7v-0.1V307.7z"/>
<path className="st17" d="M337.7,307.6L337.7,307.6v0.1V307.6z M337.7,307.7v-0.1V307.7z"/>
<path className="st16" d="M336.8,308.4L336.8,308.4l0.1,0.1L336.8,308.4z M337,308.6l-0.1-0.1L337,308.6z"/>
<path className="st17" d="M336.8,308.4L336.8,308.4l0.1,0.1L336.8,308.4z M337,308.6l-0.1-0.1L337,308.6z"/>
<path className="st16" d="M336.6,308.7l0.1,0.1v-0.1H336.6z M336.6,308.7L336.6,308.7l0.1,0.1L336.6,308.7z"/>
<path className="st17" d="M336.6,308.7l0.1,0.1v-0.1H336.6z M336.6,308.7L336.6,308.7l0.1,0.1L336.6,308.7z"/>
<path className="st16" d="M335.9,309.4L335.9,309.4v0.1V309.4z M335.9,309.5v-0.1V309.5z"/>
<path className="st17" d="M335.9,309.4L335.9,309.4v0.1V309.4z M335.9,309.5v-0.1V309.5z"/>
<path className="st16" d="M337.7,307.5v0.1l0.1-0.1H337.7z M337.6,307.6l0.1-0.1v0.1H337.6z"/>
<path className="st17" d="M337.7,307.5v0.1l0.1-0.1H337.7z M337.6,307.6l0.1-0.1v0.1H337.6z"/>
<path className="st16" d="M335.8,309.4v0.2l0.1-0.1L335.8,309.4z M335.6,309.5l0.1-0.1v0.2L335.6,309.5z M335.8,309.4l-0.1,0.1
	L335.8,309.4z M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z"/>
<path className="st17" d="M335.8,309.4v0.2l0.1-0.1L335.8,309.4z M335.6,309.5l0.1-0.1v0.2L335.6,309.5z M335.8,309.4l-0.1,0.1
	L335.8,309.4z M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z"/>
<path className="st16" d="M337.6,307.6l0.1-0.1L337.6,307.6z M337.7,307.5l-0.1,0.1L337.7,307.5z M337.7,307.5L337.7,307.5l-0.1,0.1
	L337.7,307.5z M337.6,307.6l0.1-0.1L337.6,307.6z"/>
<path className="st17" d="M337.6,307.6l0.1-0.1L337.6,307.6z M337.7,307.5l-0.1,0.1L337.7,307.5z M337.7,307.5L337.7,307.5l-0.1,0.1
	L337.7,307.5z M337.6,307.6l0.1-0.1L337.6,307.6z"/>
<path className="st16" d="M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z M335.6,309.5l0.1-0.1L335.6,309.5z"/>
<path className="st17" d="M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z M335.6,309.5l0.1-0.1L335.6,309.5z"/>
<path className="st16" d="M337.7,307.5L337.7,307.5l-0.1,0.1L337.7,307.5z M337.6,307.6l0.1-0.1L337.6,307.6z"/>
<path className="st17" d="M337.7,307.5L337.7,307.5l-0.1,0.1L337.7,307.5z M337.6,307.6l0.1-0.1L337.6,307.6z"/>
<path className="st16" d="M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z M335.6,309.5l0.1-0.1L335.6,309.5z"/>
<path className="st17" d="M335.8,309.4L335.8,309.4l-0.1,0.1L335.8,309.4z M335.6,309.5l0.1-0.1L335.6,309.5z"/>
<path className="st16" d="M336.8,308.3v0.1V308.3z M336.7,308.4l0.1-0.1v0.1H336.7z"/>
<path className="st17" d="M336.8,308.3v0.1V308.3z M336.7,308.4l0.1-0.1v0.1H336.7z"/>
<path className="st16" d="M336.6,308.6v0.1V308.6z M336.5,308.6h0.1v0.1L336.5,308.6z"/>
<path className="st17" d="M336.6,308.6v0.1V308.6z M336.5,308.6h0.1v0.1L336.5,308.6z"/>
<path className="st16" d="M336.7,308.3v0.1l0.1-0.1H336.7z M336.6,308.4l0.1-0.1v0.1H336.6z"/>
<path className="st17" d="M336.7,308.3v0.1l0.1-0.1H336.7z M336.6,308.4l0.1-0.1v0.1H336.6z"/>
<path className="st16" d="M336.6,308.4l-0.1,0.1h0.1V308.4z M336.6,308.4L336.6,308.4l-0.1,0.1L336.6,308.4z"/>
<path className="st17" d="M336.6,308.4l-0.1,0.1h0.1V308.4z M336.6,308.4L336.6,308.4l-0.1,0.1L336.6,308.4z"/>
<path className="st16" d="M336.6,308.4l0.1-0.1L336.6,308.4z M336.7,308.3l-0.1,0.1L336.7,308.3z M336.5,308.6l0.2-0.2l-0.1,0.1
	L336.5,308.6z"/>
<path className="st17" d="M336.6,308.4l0.1-0.1L336.6,308.4z M336.7,308.3l-0.1,0.1L336.7,308.3z M336.5,308.6l0.2-0.2l-0.1,0.1
	L336.5,308.6z"/>
<path className="st16" d="M336.6,308.4L336.6,308.4l-0.1,0.1L336.6,308.4z M336.5,308.6l0.1-0.1L336.5,308.6z"/>
<path className="st17" d="M336.6,308.4L336.6,308.4l-0.1,0.1L336.6,308.4z M336.5,308.6l0.1-0.1L336.5,308.6z"/>
<path className="st16" d="M337.6,307.4v0.2l0.1-0.1L337.6,307.4z M337.3,307.5l0.2-0.1v0.2L337.3,307.5z"/>
<path className="st17" d="M337.6,307.4v0.2l0.1-0.1L337.6,307.4z M337.3,307.5l0.2-0.1v0.2L337.3,307.5z"/>
<path className="st16" d="M335.6,309.2v0.4l0.1-0.1L335.6,309.2z M335.5,309.3l0.1-0.1v0.4L335.5,309.3z"/>
<path className="st17" d="M335.6,309.2v0.4l0.1-0.1L335.6,309.2z M335.5,309.3l0.1-0.1v0.4L335.5,309.3z"/>
<path className="st16" d="M337.2,307.2l0.1,0.2l0.2-0.1L337.2,307.2z M337.1,307.5l0.1-0.2l0.1,0.2H337.1z"/>
<path className="st17" d="M337.2,307.2l0.1,0.2l0.2-0.1L337.2,307.2z M337.1,307.5l0.1-0.2l0.1,0.2H337.1z"/>
<path className="st16" d="M335.6,308.9l-0.1,0.4l0.1-0.1V308.9z M335.4,309l0.2-0.1l-0.1,0.4L335.4,309z"/>
<path className="st17" d="M335.6,308.9l-0.1,0.4l0.1-0.1V308.9z M335.4,309l0.2-0.1l-0.1,0.4L335.4,309z"/>
<path className="st16" d="M337,307.1l0.1,0.4l0.1-0.2L337,307.1z M336.8,307.4l0.1-0.2l0.1,0.4L336.8,307.4z"/>
<path className="st17" d="M337,307.1l0.1,0.4l0.1-0.2L337,307.1z M336.8,307.4l0.1-0.2l0.1,0.4L336.8,307.4z"/>
<path className="st16" d="M335.5,308.7l-0.1,0.4l0.2-0.1L335.5,308.7z M335.4,308.8l0.1-0.1l-0.1,0.4V308.8z"/>
<path className="st17" d="M335.5,308.7l-0.1,0.4l0.2-0.1L335.5,308.7z M335.4,308.8l0.1-0.1l-0.1,0.4V308.8z"/>
<path className="st16" d="M337,307.1L337,307.1l-0.1,0.2L337,307.1z M336.8,307.4l0.1-0.2L336.8,307.4z"/>
<path className="st17" d="M337,307.1L337,307.1l-0.1,0.2L337,307.1z M336.8,307.4l0.1-0.2L336.8,307.4z"/>
<path className="st16" d="M335.5,308.6l-0.1,0.2l0.1-0.1V308.6z M335.5,308.6L335.5,308.6l-0.1,0.2L335.5,308.6z"/>
<path className="st17" d="M335.5,308.6l-0.1,0.2l0.1-0.1V308.6z M335.5,308.6L335.5,308.6l-0.1,0.2L335.5,308.6z"/>
<path className="st16" d="M336.7,307.1l0.1,0.2l0.1-0.2H336.7z M336.4,307.6l0.4-0.5l0.1,0.2L336.4,307.6z"/>
<path className="st17" d="M336.7,307.1l0.1,0.2l0.1-0.2H336.7z M336.4,307.6l0.4-0.5l0.1,0.2L336.4,307.6z"/>
<path className="st16" d="M335.8,308.2l-0.4,0.6l0.1-0.2L335.8,308.2z M335.4,308.6l0.4-0.4l-0.4,0.6V308.6z"/>
<path className="st17" d="M335.8,308.2l-0.4,0.6l0.1-0.2L335.8,308.2z M335.4,308.6l0.4-0.4l-0.4,0.6V308.6z"/>
<path className="st16" d="M336.5,307.2l-0.1,0.4l0.4-0.5L336.5,307.2z M335.9,307.7l0.6-0.5l-0.1,0.4L335.9,307.7z"/>
<path className="st17" d="M336.5,307.2l-0.1,0.4l0.4-0.5L336.5,307.2z M335.9,307.7l0.6-0.5l-0.1,0.4L335.9,307.7z"/>
<path className="st16" d="M335.9,307.7l-0.5,0.8l0.4-0.4L335.9,307.7z M335.4,308.3l0.5-0.6l-0.5,0.8V308.3z"/>
<path className="st17" d="M335.9,307.7l-0.5,0.8l0.4-0.4L335.9,307.7z M335.4,308.3l0.5-0.6l-0.5,0.8V308.3z"/>
<path className="st16" d="M336.5,307.2L336.5,307.2l-1.1,1.1L336.5,307.2z M335.4,308.3l1.1-1.1L335.4,308.3z"/>
<path className="st17" d="M336.5,307.2L336.5,307.2l-1.1,1.1L336.5,307.2z M335.4,308.3l1.1-1.1L335.4,308.3z"/>
<path className="st16" d="M336.2,307.4l-0.8,1l1.1-1.1L336.2,307.4z M335.5,308.1l0.7-0.7l-0.8,1L335.5,308.1z"/>
<path className="st17" d="M336.2,307.4l-0.8,1l1.1-1.1L336.2,307.4z M335.5,308.1l0.7-0.7l-0.8,1L335.5,308.1z"/>
<path className="st16" d="M335.6,307.8l0.4-0.4l-0.2,0.1L335.6,307.8z M335.5,308.1l0.1-0.2l0.4-0.4L335.5,308.1z"/>
<path className="st17" d="M335.6,307.8l0.4-0.4l-0.2,0.1L335.6,307.8z M335.5,308.1l0.1-0.2l0.4-0.4L335.5,308.1z"/>
<polygon className="st16" points="335.5,308.1 336.2,307.4 336,307.5 "/>
<polygon className="st17" points="335.5,308.1 336.2,307.4 336,307.5 "/>
<path className="st18" d="M336.4,309.8c0.3-0.2,0.6-0.4,0.9-0.7"/>
<path className="st18" d="M335.9,309.5c0.1,0.1,0.3,0.2,0.4,0.3"/>
<path className="st18" d="M335.7,309.3c0,0,0.1,0.1,0.1,0.1"/>
<path className="st18" d="M336.2,309.8c0.1,0,0.1,0,0.2,0"/>
<path className="st18" d="M338,308.1c-0.1-0.1-0.2-0.3-0.3-0.4"/>
<path className="st18" d="M337.6,307.7c0,0-0.1-0.1-0.1-0.1"/>
<path className="st18" d="M337.3,309.1c0.3-0.3,0.5-0.6,0.7-0.9"/>
<path className="st18" d="M338,308.2c0-0.1,0-0.1,0-0.2"/>
<path className="st18" d="M337.5,307.7c0-0.1,0-0.2,0-0.2"/>
<path className="st18" d="M336,309.4c0.6-0.4,1.2-1,1.6-1.6"/>
<path className="st18" d="M335.8,309.3c0.1,0.1,0.1,0.1,0.2,0"/>
<polyline className="st18" points="335.8,309.4 335.8,309.3 335.6,309.2 335.5,309 335.5,308.8 335.5,308.6 335.5,308.4 335.6,308.2 
	335.8,308 335.9,307.8 335.9,307.7 336,307.7 336.2,307.6 336.4,307.5 336.6,307.4 336.7,307.4 337,307.4 337.2,307.4 337.3,307.5 
	337.6,307.5 337.6,307.6 "/>
<polygon className="st20" points="337.6,307 337.1,307.1 337,307.6 337.1,308.1 337.6,308.2 338,308.1 338.2,307.6 338,307.1 "/>
<polygon className="st4" points="337.6,307 337.1,307.1 337,307.6 337.1,308.1 337.6,308.2 338,308.1 338.2,307.6 338,307.1 "/>
<polygon className="st20" points="337.7,307.2 337.2,307.4 337.1,307.8 337.2,308.3 337.7,308.4 338.2,308.3 338.3,307.8 338.2,307.4 
	"/>
<polygon className="st4" points="337.7,307.2 337.2,307.4 337.1,307.8 337.2,308.3 337.7,308.4 338.2,308.3 338.3,307.8 338.2,307.4 "/>
<polygon className="st20" points="336.1,308.9 335.6,309 335.5,309.5 335.6,310 336.1,310.1 336.6,310 336.7,309.5 336.6,309 "/>
<polygon className="st4" points="336.1,308.9 335.6,309 335.5,309.5 335.6,310 336.1,310.1 336.6,310 336.7,309.5 336.6,309 "/>
<polygon className="st20" points="335.8,308.8 335.3,308.9 335.2,309.4 335.3,309.9 335.8,310 336.2,309.9 336.4,309.4 336.2,308.9 "/>
<polygon className="st4" points="335.8,308.8 335.3,308.9 335.2,309.4 335.3,309.9 335.8,310 336.2,309.9 336.4,309.4 336.2,308.9 "/>
<polygon className="st20" points="335.9,307.1 335.4,307.2 335.3,307.7 335.4,308.2 335.9,308.3 336.4,308.2 336.5,307.7 336.4,307.2 
	"/>
<polygon className="st4" points="335.9,307.1 335.4,307.2 335.3,307.7 335.4,308.2 335.9,308.3 336.4,308.2 336.5,307.7 336.4,307.2 "/>
<path className="st18" d="M337.7,307.6c0-0.1-0.1-0.1-0.1-0.1"/>
<path className="st18" d="M338.3,308.3c-0.1-0.2-0.3-0.5-0.5-0.7"/>
<polyline className="st18" points="338.4,308.3 337.4,309.3 336.5,310.1 "/>
<path className="st18" d="M335.7,309.7c0.2,0.2,0.5,0.3,0.7,0.5"/>
<path className="st18" d="M335.6,309.4c0,0.1,0.1,0.1,0.1,0.1"/>
<polyline className="st18" points="335.6,309.5 335.4,308.9 335.4,308.2 335.8,307.6 336.4,307.2 337.1,307.2 337.7,307.5 "/>
<polygon className="st20" points="337.7,306.9 337.2,307 337.1,307.5 337.2,308 337.7,308.1 338.2,308 338.3,307.5 338.2,307 "/>
<polygon className="st4" points="337.7,306.9 337.2,307 337.1,307.5 337.2,308 337.7,308.1 338.2,308 338.3,307.5 338.2,307 "/>
<polygon className="st20" points="337.9,307 337.4,307.1 337.3,307.6 337.4,308.1 337.9,308.2 338.4,308.1 338.5,307.6 338.4,307.1 "/>
<polygon className="st4" points="337.9,307 337.4,307.1 337.3,307.6 337.4,308.1 337.9,308.2 338.4,308.1 338.5,307.6 338.4,307.1 "/>
<line className="st18" x1="338.4" y1="308.3" x2="338.4" y2="308.3"/>
<polygon className="st20" points="337.4,308.7 337,308.8 336.8,309.3 337,309.8 337.4,309.9 337.9,309.8 338,309.3 337.9,308.8 "/>
<polygon className="st4" points="337.4,308.7 337,308.8 336.8,309.3 337,309.8 337.4,309.9 337.9,309.8 338,309.3 337.9,308.8 "/>
<line className="st18" x1="336.5" y1="310.1" x2="336.5" y2="310.1"/>
<polygon className="st20" points="335.8,309 335.3,309.2 335.2,309.6 335.3,310.1 335.8,310.2 336.2,310.1 336.4,309.6 336.2,309.2 "/>
<polygon className="st4" points="335.8,309 335.3,309.2 335.2,309.6 335.3,310.1 335.8,310.2 336.2,310.1 336.4,309.6 336.2,309.2 "/>
<polygon className="st20" points="335.6,308.9 335.2,309 335,309.5 335.2,310 335.6,310.1 336.1,310 336.2,309.5 336.1,309 "/>
<polygon className="st4" points="335.6,308.9 335.2,309 335,309.5 335.2,310 335.6,310.1 336.1,310 336.2,309.5 336.1,309 "/>
<polygon className="st20" points="335.8,307 335.3,307.1 335.2,307.6 335.3,308.1 335.8,308.2 336.2,308.1 336.4,307.6 336.2,307.1 "/>
<polygon className="st4" points="335.8,307 335.3,307.1 335.2,307.6 335.3,308.1 335.8,308.2 336.2,308.1 336.4,307.6 336.2,307.1 "/>
<path className="st18" d="M336.5,308.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C336.7,308.3,336.6,308.3,336.5,308.4"/>
<polygon className="st20" points="336.8,307.8 336.4,308 336.2,308.4 336.4,308.9 336.8,309 337.3,308.9 337.4,308.4 337.3,308 "/>
<polygon className="st4" points="336.8,307.8 336.4,308 336.2,308.4 336.4,308.9 336.8,309 337.3,308.9 337.4,308.4 337.3,308 "/>
<path className="st18" d="M336.5,308.4c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.1,0.2,0.1,0.3,0c0.1-0.1,0.1-0.2,0-0.3
	C336.7,308.3,336.6,308.3,336.5,308.4"/>
<polygon className="st20" points="336.8,307.8 336.4,308 336.2,308.4 336.4,308.9 336.8,309 337.3,308.9 337.4,308.4 337.3,308 "/>
<polygon className="st4" points="336.8,307.8 336.4,308 336.2,308.4 336.4,308.9 336.8,309 337.3,308.9 337.4,308.4 337.3,308 "/>
<path className="st18" d="M337.1,308.9C337.1,309,337.1,309,337.1,308.9C337.1,309,337.2,309,337.1,308.9
	C337.2,309,337.2,309,337.1,308.9C337.2,308.9,337.1,308.9,337.1,308.9"/>
<polygon className="st20" points="337.2,308.3 336.7,308.4 336.6,308.9 336.7,309.4 337.2,309.5 337.7,309.4 337.8,308.9 337.7,308.4 
	"/>
<polygon className="st4" points="337.2,308.3 336.7,308.4 336.6,308.9 336.7,309.4 337.2,309.5 337.7,309.4 337.8,308.9 337.7,308.4 "/>
<line className="st2" x1="332.2" y1="298.6" x2="332.6" y2="299.1"/>
<path className="st5" d="M328,304c2.3,2.1,6,2.1,8.2-0.2"/>
<polyline className="st5" points="328.1,304.1 327.8,303.9 332,299.7 332.3,299.9 328.1,304.1 "/>
<line className="st2" x1="336.2" y1="303.9" x2="336.8" y2="303.3"/>
<polyline className="st2" points="332,299.7 332.6,299.1 332.2,298.6 "/>
<line className="st2" x1="316.7" y1="315" x2="316.1" y2="315.6"/>
<path className="st5" d="M320.8,319.2c2.2-2.2,2.3-5.9,0.2-8.2"/>
<polyline className="st5" points="321.1,311.1 320.9,310.8 316.7,315 316.9,315.3 321.1,311.1 "/>
<line className="st2" x1="320.3" y1="319.8" x2="320.9" y2="319.2"/>
<line className="st1" x1="328.6" y1="324.3" x2="339.4" y2="324.3"/>
<text transform="matrix(1 0 0 1 328.5598 323.1968)" className="st9 st28">3.31</text>
<line className="st1" x1="97.1" y1="277.4" x2="129" y2="277.4"/>
<text transform="matrix(1 0 0 1 97.0803 274.4766)" className="st9 st29">КХО </text>
<line className="st1" x1="129" y1="277.4" x2="151.4" y2="277.4"/>
<text transform="matrix(1 0 0 1 129.0002 274.4766)" className="st9 st29">№2</text>
<line className="st1" x1="325.2" y1="222" x2="344.5" y2="202.7"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 324.1192 220.9574)" className="st9 st26">Палата </text>
<line className="st1" x1="344.5" y1="202.7" x2="358.8" y2="188.4"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 343.4396 201.6369)" className="st9 st26">№310</text>
<line className="st1" x1="343.7" y1="238.6" x2="363.1" y2="219.3"/>
<text transform="matrix(0.7093 -0.7049 0.7049 0.7093 342.6004 237.5165)" className="st9 st26">Палата </text>
<line className="st1" x1="363.1" y1="219.3" x2="377.4" y2="205"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 361.9203 218.1966)" className="st9 st26">№311</text>
<line className="st1" x1="360.1" y1="257.7" x2="379.4" y2="238.4"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 359.0395 256.5972)" className="st9 st26">Палата </text>
<line className="st1" x1="379.4" y1="238.4" x2="393.7" y2="224.1"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 378.3599 237.2767)" className="st9 st26">№312</text>
<line className="st1" x1="378.5" y1="274.6" x2="397.8" y2="255.3"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 377.2796 273.5171)" className="st9 st26">Палата </text>
<line className="st1" x1="397.8" y1="255.3" x2="412.1" y2="241"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 396.6 254.1966)" className="st9 st26">№313</text>
<line className="st1" x1="394.9" y1="292.4" x2="414.2" y2="273"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 393.8401 291.2764)" className="st9 st26">Палата </text>
<line className="st1" x1="414.2" y1="273" x2="428.5" y2="258.8"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 413.1601 271.8366)" className="st9 st26">№314</text>
<line className="st1" x1="412.8" y1="308.7" x2="432.1" y2="289.4"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 411.7199 307.4767)" className="st9 st26">Палата </text>
<line className="st1" x1="432.1" y1="289.4" x2="446.5" y2="275.1"/>
<text transform="matrix(0.7101 -0.7041 0.7041 0.7101 431.0406 288.1563)" className="st9 st26">№315</text>
<line className="st1" x1="327" y1="463" x2="350.2" y2="440"/>
<text transform="matrix(0.7089 -0.7053 0.7053 0.7089 325.9196 461.9168)" className="st9 st26">Изолятор</text>
<line className="st1" x1="296.5" y1="456" x2="315.8" y2="436.7"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 295.4398 454.9571)" className="st9 st26">Палата </text>
<line className="st1" x1="315.8" y1="436.7" x2="330.1" y2="422.4"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 314.7602 435.6366)" className="st9 st26">№309</text>
<line className="st1" x1="282.4" y1="440.1" x2="301.7" y2="420.8"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 281.2795 438.9973)" className="st9 st26">Палата </text>
<line className="st1" x1="301.7" y1="420.8" x2="316" y2="406.5"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 300.5999 419.6768)" className="st9 st26">№308</text>
<line className="st1" x1="262.8" y1="422.6" x2="282.1" y2="403.2"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 261.7201 421.4763)" className="st9 st26">Палата </text>
<line className="st1" x1="282.1" y1="403.2" x2="296.5" y2="389"/>
<text transform="matrix(0.7101 -0.7041 0.7041 0.7101 281.0403 402.1566)" className="st9 st26">№307</text>
<line className="st1" x1="246.8" y1="406.6" x2="266.2" y2="387.3"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 245.7603 405.5166)" className="st9 st26">Палата </text>
<line className="st1" x1="266.2" y1="387.3" x2="280.4" y2="373"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 265.0791 386.1972)" className="st9 st26">№306</text>
<line className="st1" x1="227.6" y1="388.1" x2="247" y2="368.8"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 226.5598 387.037)" className="st9 st26">Палата </text>
<line className="st1" x1="247" y1="368.8" x2="261.2" y2="354.5"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 245.8797 367.5972)" className="st9 st26">№305</text>
<line className="st1" x1="209.8" y1="371.2" x2="229.2" y2="351.9"/>
<text transform="matrix(0.7093 -0.7049 0.7049 0.7093 208.68 370.1174)" className="st9 st26">Палата </text>
<line className="st1" x1="229.2" y1="351.9" x2="243.5" y2="337.6"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 228.0003 350.7964)" className="st9 st26">№304</text>
<line className="st1" x1="185.9" y1="327.4" x2="205.2" y2="308.1"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 184.7999 326.3164)" className="st9 st26">Палата </text>
<line className="st1" x1="205.2" y1="308.1" x2="219.5" y2="293.8"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 204.1198 306.9968)" className="st9 st26">№303</text>
<line className="st1" x1="169.3" y1="308.8" x2="188.6" y2="289.5"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 168.1197 307.7166)" className="st9 st26">Палата </text>
<line className="st1" x1="188.6" y1="289.5" x2="202.9" y2="275.2"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 187.4404 288.3963)" className="st9 st26">№302</text>
<line className="st1" x1="267.5" y1="210" x2="286.8" y2="190.7"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 266.2796 208.9569)" className="st9 st26">Палата </text>
<line className="st1" x1="286.8" y1="190.7" x2="301.1" y2="176.4"/>
<text transform="matrix(0.7071 -0.7071 0.7071 0.7071 285.7196 189.6372)" className="st9 st26">№301</text>
<polyline className="st2" points="67.2,184.1 85.4,202.2 85.4,217.2 166.3,217.2 166.3,202.2 184.6,184.1 137.4,137 114.4,137 
	67.2,184.1 "/>
<polyline className="st2" points="101.3,172.4 101.3,200.4 103.1,202.2 148.7,202.2 150.5,200.4 150.5,172.4 139.2,161.2 112.6,161.2 
	101.3,172.4 "/>
<polyline className="st2" points="81.7,54.8 112.7,54.8 112.7,74.6 81.7,74.6 81.7,54.8 "/>
<polyline className="st2" points="138.1,54.8 170,54.8 170,93.8 138.1,93.8 138.1,54.8 "/>
<polyline className="st2" points="81.7,75.4 112.7,75.4 112.7,108.4 81.7,108.4 81.7,75.4 "/>

{/* так проверять легче */}
{/* { 
  sectors && sectors.map((s, j) => {
    let coords = JSON.parse(s.map_point);
	if (coords.x && coords.y) {
      return <>
	    <text key={j} className="st9 st26" id={`position_text_${s.sector}`} x={coords.x} y={coords.y}  textAnchor="middle" stroke="black"  strokeWidth="1px"  alignmentBaseline="middle"  > {s.sector} </text>
        <circle key={j} id={`position_${s.sector}`} cx={coords.x} cy={coords.y} r="18" fill="#63ff4a4f" />
      </>
	}
  })
} */}
{
  wards && wards.map((w, i) => {
    return  w.appliances.map((app) => {
      return <DinamicGraphicPosition  sectors={sectors} ind={app.id} key={i} currentStatus={w.status} info={w.info} number={w.number} device={app} norms={(app.patient.indicators && app.patient.indicators.length > 0) ?  `{${app.patient.indicators.map((ind)=> { return `"${ind.option_name}": "${ind.value}"` } ).join()}}` : norms} />
    })
  })
}

</svg>

    </>
  );
};

export default MiniMapClear;