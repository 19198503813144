import React, {useState} from 'react';
import classNames from "classnames";
import styles from './MenuList.module.scss';


const MenuList = ({links, user, user_logout, user_root}) => {
    const [openMenu, setOpenMenu] = useState(true)
    return (
      <div className={styles.MenuListWrap}>
        <Logo />
        { user && <p className={styles.MenuUser} > 
            <a href={user_root}><span className={styles.MenuUserName}>{user}</span></a>
            <span dangerouslySetInnerHTML={{ __html: user_logout }}></span>
          </p> }
        <ul className={styles.MenuList}>
          {  
            links && links.map((menu_item, i) => {  
              return <li key={i} className={classNames(styles.MenuListItem, menu_item.children && menu_item.children.length ? styles.MenuListItemWithChildren : '', menu_item.children && menu_item.children.length && openMenu ? 'open' : '')}>
                  <a href={menu_item.path} title={menu_item.name} onClick={(ev)=>{  
                    if (menu_item.children && menu_item.children.length) { 
                      ev.preventDefault(); 
                      setOpenMenu(!openMenu); 
                    } else {
                      setOpenMenu(false);
                    }
                    }}>{menu_item.name}</a>
                  { menu_item.children && menu_item.children.length && openMenu ? <ul className={styles.MenuListChildren}> 
                    { menu_item.children.map((menu_child_item, j) => { 
                      return <li key={j} className={styles.MenuListChildrenItem}>
                              <a href={menu_child_item.path} title={menu_child_item.name}>{menu_child_item.name}</a>
                            </li> 
                    }) }
                  </ul>: '' 
                  } 
              </li> 
            })
          }
        </ul>
      </div>
    );
};

export default MenuList;