import React, {useState, useRef, useEffect } from 'react';
import styles from './Profile.module.scss';
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import Switch from "react-custom-checkbox/switch";
import classNames from 'classnames';
import Qwe from '../../../assets/images/qwe.svg';


const ProfileForm = ({user, openForm, refreshUser,  permissions }) => {
    
    const curForm = useRef();
    const tooltipeWrap = useRef();
    const curFormAlert = useRef(); 
    const curFormCall = useRef();
     
    const [errorMsg, setErrorMsg] = useState('');
    const [hintMsg, setHintMsg] = useState('');
    const [showPassword, togglePasswordForm] = useState(false);
    const [showTooltipe, setShowTooltipe] = useState(false);
    
    const [userAlert0, setUserAlert0] = useState(user.alert && user.alert.split(',').includes('0'));
    const [userAlert1, setUserAlert1] = useState(user.alert && user.alert.split(',').includes('1'));
    const [userAlert2, setUserAlert2] = useState(user.alert && user.alert.split(',').includes('2'));
    const [userAlert, setUserAlert] = useState(user.alert);

    const [userCall0, setUserCall0] = useState(user.call && user.call.split(',').includes('0'));
    const [userCall1, setUserCall1] = useState(user.call && user.call.split(',').includes('1'));

    const [userCall, setUserCall] = useState(user.call);

    const role_option = [
      { label: "Администратор", value: "0" },
      { label: "Персонал", value: "1" },
      { label: "Техник", value: "2" }
    ]
    const [selected, setSelected] = useState(role_option.filter((r) => { if (user.role.split(",").includes(r.value)) { return r} }));

    const select_localization = {
      "allItemsAreSelected": "Выбраны все элементы.",
      "clearSearch": "Очистить поиск",
      "clearSelected": "Очистить выбранное",
      "noOptions": "Без вариантов",
      "search": "Поиск",
      "selectAll": "все",
      "selectAllFiltered": "Выбрать всех (отфильтровано)",
      "selectSomeItems": " -- Выберете роли -- ",
      "create": "Создавать",
    };
    
    const checkTgId = (ev) => {
      let curVal = ev.target.value;
      curVal = curVal.replace(/[^0-9]+/g, "");
      if (curVal.length < 10) {
        setErrorMsg('Исправьте ошибки в поле Telegram');
        ev.target.classList.add('with-error');
      }
      ev.target.value = curVal
    }

    const checkPassword = (ev) => {
      // let curVal = ev.target.value;
      let password = curForm.current.querySelector("[name='user[password]']");
      let passwordConfirm = curForm.current.querySelector("[name='user[password_confirmation]']");
      if (password.value.length < 6) {
        setErrorMsg('Длина пароля должна быть не менее 6 символов.');
        password.classList.add('with-error');
      }
      
      if (password.value === passwordConfirm.value && password.value.length > 5) {
        setErrorMsg('');
        password.classList.remove('with-error');
        passwordConfirm.classList.remove('with-error');
      } else {
        password.classList.add('with-error');
        if (password.value.length < 6) {
          setErrorMsg('Длина пароля должна быть не менее 6 символов.');
        } 
        if (password.value !== passwordConfirm.value) {
          setErrorMsg('Пароли не совпадают');
          password.classList.add('with-error');
          passwordConfirm.classList.add('with-error');
        }
      }
      
    }

    const checkMask = (ev) => {
      // console.log(' ... checkMask ... ', ev._reactName);
      let mask = "+* (***) *** ** **";
      let curVal = ev.target.value;
      if (curVal.startsWith('+')) {
        curVal = curVal.replace('+7','7');
      }
      if (curVal.startsWith('8')) {
        curVal = curVal.replace('8','7');
      }
      curVal = curVal.replace(/[^0-9]+/g, "");

      if (curVal.startsWith('2')) {
        // для Хабаровска
        if (curVal.length === 6) {
          curVal = '74212'+curVal
        }
        // Для Красноярска
        if (curVal.length === 7) {
          curVal = '7391'+curVal
        }
        //
      }

      let i = 0;
      let result = mask.replaceAll("*", () => curVal[i++] || "");
      
      // if (result.length !== 18) {
      if (result.length <= 17) {
        if (result === '()--') { result = '' }
        setErrorMsg('Исправьте ошибки в поле телефон');
        ev.target.classList.add('with-error');
      } else {
        setErrorMsg('');
        ev.target.classList.remove('with-error');
      }

      ev.target.value = result
    }
    const clearMessage = (ev) => {
      setErrorMsg('');
      setHintMsg('');
      ev.target.classList.remove('with-error');
    }

    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      if (curForm.current.querySelectorAll(".with-error")) {

      }
      return false;
    }

    const SendRequest = (ev) => {
      ev.preventDefault();
      setAlertAndCallCheckboxes();
      const body = new FormData(curForm.current);
      const HasError = CheckValidForm(curForm.current);
      if (!HasError) {
        setHintMsg('... загрузка  ...');
        axios.post(`/persons/${user.id}/update`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            if (response.data.phone_update) {
              setHintMsg('')
              setErrorMsg('Был изменен номер телефона, и на указанный новый номер совершается автоматический звонок. Пожалуйста, для подтверждения нового номера следуйте инструкциям! система уведомления не будет работать корректно пока номер не будет подтвержден.');
              refreshUser(response.data.updated);
              clearInterval(confirmedUserTimer);
              confirmedUserTimer = setInterval(() => checkUserConfirmed(response.data.updated.phone), 10000);
            } else {
              setHintMsg(response.data.message);
              refreshUser(response.data.updated);
              setTimeout(() => openForm(false), 4000);
            }
            
          } else {
            setHintMsg('');
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
            setTimeout(() => openForm(false), 4000);
          }
        }).catch(error => {
          setHintMsg('');
          console.log(error);
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          setTimeout(() => openForm(false), 4000);
        })
        .finally(() => { });
      } 
    }
    
    // for checkbox
    const checkedTrackStyle = {
      opacity: 1,
      background: 'linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2,#FF4E4E) 96.67%)',
      transition: 'all 0.25s ease-in-out',
    }
    
    const checkedIndicatorStyle = {
      transform: 'translateX(1rem)',
    }
    
    const checkedIconStyle = {
      opacity: 1,
      transition: 'all 0.25s ease-in-out',
    }
    
    const indicatorStyle = {
      alignItems: 'center',
      background: '#ffffff',      // red
      borderRadius: '1.126rem',
      bottom: '0.25rem',
      display: 'flex',
      height: '1.126rem',
      justifyContent: 'center',
      left: '0.25rem',
      outline: 'solid 2px transparent',
      position: 'absolute',
      transition: '0.25s',
      width: '1.126rem',
    }
    
    const trackStyle = {
      background: '#CAD1D8',        //gray
      border: '1px solid #CAD1D8',
      borderRadius: '0.93rem',
      cursor: 'pointer',
      display: 'flex',
      height: '1.75rem',
      marginRight: '0.75rem',
      position: 'relative',
      width: '2.75rem',
    }

    // end for checkbox
    useEffect(() => {
      if (tooltipeWrap.current) {
        document.addEventListener( 'click', (e) => {
          const withinBoundaries = e.composedPath().includes(tooltipeWrap.current);
          if (( ! withinBoundaries ) && showTooltipe) {
            setShowTooltipe(false);
          }
        });
      }
    }, [tooltipeWrap, showTooltipe, setShowTooltipe]);
    
    const toggleElementOnArr = (resultArr, flag, val) => {
      if (flag) {
        resultArr = resultArr.includes(val) ? resultArr : [ ...resultArr, val];
      } else {
        resultArr =  resultArr.filter(item => item !== val);
      } 
      return resultArr;
    }

    const setAlertAndCallCheckboxes = () => {
      setUserAlert((userAlert)=> { 
        let userAlertCurrent = userAlert.split(',');
        userAlertCurrent = toggleElementOnArr(userAlertCurrent, userAlert0, '0');
        userAlertCurrent = toggleElementOnArr(userAlertCurrent, userAlert1, '1');
        userAlertCurrent = toggleElementOnArr(userAlertCurrent, userAlert2, '2');
        userAlert = userAlertCurrent.length > 1 ? userAlertCurrent.join(',') : userAlertCurrent[0];
        return userAlert
      });
      
      setUserCall((userCall)=> { 
        let userCallCurrent = userCall.split(',');
        userCallCurrent = toggleElementOnArr(userCallCurrent, userCall0, '0');
        userCallCurrent = toggleElementOnArr(userCallCurrent, userCall1, '1');
        userCall = userCallCurrent.length > 1 ? userCallCurrent.join(',') : userCallCurrent[0];
        return userCall;
      });
    }
    
    useEffect(() => {
      setAlertAndCallCheckboxes();
    }, [ setUserCall, userCall0, userCall1, setUserAlert, userAlert0, userAlert1, userAlert2 ] );
    
    
    return ( 
      <div className={styles.ProfileWrap}>
        <form ref={curForm} name="user" action={`/persons/update`} acceptCharset="UTF-8" data-remote="true" method="post" className={styles.ProfileForm}>
          <div className="with-border">
            <div className={classNames("field-light", styles.ProfileFormItem)}>
              <label htmlFor='user[name]'>ФИO</label>
              <input type="text" name='user[name]' required onChange={clearMessage} defaultValue={user.name}  />
            </div>
            <div className={classNames("field-light", styles.ProfileFormItem)}>
              <label htmlFor='user[role]'>Роль</label>
              <input type="hidden" name='user[role]'  value={selected.map(v => { return v.value })} />
              <MultiSelect
                options={role_option}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                overrideStrings={select_localization}
              />
            </div>
          
            <div className={classNames("field-light", styles.ProfileFormItem)}>
              <label htmlFor='user[phone]'>Телефон</label>
              <input type="text" name='user[phone]' required onKeyDown={(ev)=> { 
                  if ((ev.keyCode !== 46)&&(ev.keyCode !== 8)) { 
                    checkMask(ev); 
                    clearMessage; 
                  } 
                }} 
                onBlur={(ev)=> { 
                  checkMask(ev); 
                  clearMessage; 
                }}  defaultValue={user.phone} />
            </div>
            <div className={classNames("field-light", styles.ProfileFormItem)}>
              <label htmlFor='user[email]'>email</label>
              <input autoFocus="autofocus" autoComplete="email" input_html="required true" type="email" defaultValue={user.email} name="user[email]" />
            </div>
            <div className="field-light">
              <label htmlFor='user[telegram]'>Telegram</label>
              <div className={styles.ProfileTgInput}>
                <input type="text" name='user[telegram]' required onChange={clearMessage} onBlur={checkTgId} defaultValue={user.telegram} />
                <span  ref={tooltipeWrap}  className={classNames("tooltipe-wrap", styles.IcoQwe)} onClick={() => { setShowTooltipe(!showTooltipe)}}>
                  <img src={Qwe} /> 
                  { showTooltipe ? <div className="tooltipe tooltipe-right"> Укажите пожалуйста здесь свой чат-ID, <br/> для получения уведомлений от телеграм-бота. <br/> <a href="https://docs.google.com/document/d/1rd2NLaKIOaoj-niwUUBPf6Q9QzxJkgSZ47VOp0LnJaU/edit?usp=sharing" target="_blank">Как это сделать?</a></div> : <></> }
                </span>
                
              </div>
            </div>
            <div className={classNames("field-light", styles.ProfileFormItem)}>
              <label htmlFor='user[post]'>Должность</label>
              <input type="text" name='user[post]' required onChange={clearMessage} defaultValue={user.post} />
            </div>
          </div>
          
          <div className={"with-border"}>
            <span className={"grey-link"}>Забыли пароль?</span>
            <span className={"link"} onClick={(ev) => { togglePasswordForm(!showPassword) }}> { showPassword ? 'Скрыть пароль' : 'Сменить пароль' }</span>
            {
              showPassword ?  <>
                <div className="field-light">
                  <label for="user_password">Новый пароль</label>
                  <input input_html="required true" autoComplete="new-password" onBlur={(ev)=> {  checkPassword(ev); }} type="password" name="user[password]" />
                </div>
                <div className="field-light">
                  <label for="user_password_confirmation">Повторить</label>
                  <input input_html="required true" autoComplete="new-password" onBlur={checkPassword} type="password" name="user[password_confirmation]" />
                </div>
              </>: <></>
            }
          </div>
          <div>
            <input type="hidden" name='user[call]'  ref={curFormCall}  value={userCall} />
            <label className="field-with-checkbox"> 
              <span>Звонить, если критическая ситуация</span>
              <Switch 
                checked={userCall0}
                onChange={() => setUserCall0(!userCall0)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
            </label>
            <label className="field-with-checkbox"> 
              <span>Звонить, если нестабильная ситуация</span>
              <Switch 
                checked={userCall1}
                onChange={() => setUserCall1(!userCall1)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
            </label>
          </div>
          
          <div>
            <input type="hidden" name='user[alert]'  ref={curFormAlert}   value={userAlert} />
            <label className="field-with-checkbox"> 
              <span>Сообщение, если критическая ситуация</span>
              <Switch 
                checked={userAlert0}
                onChange={() => setUserAlert0(!userAlert0)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
            </label>
            <label className="field-with-checkbox"> 
              <span>Сообщение, если нестабильная ситуация</span>
              <Switch 
                checked={userAlert1}
                onChange={() => setUserAlert1(!userAlert1)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
            </label>
            <label className="field-with-checkbox"> 
              <span>Сообщение, если низкий заряд батарейки</span>
              <Switch 
                checked={userAlert2}
                onChange={() => setUserAlert2(!userAlert2)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
            </label>
          </div>
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
          <button type="reset" className="btn-border" onClick={(ev)=> { openForm( false); }}><span>Не сохранять</span></button>
        </form>
      </div>
    );
};

export default ProfileForm;