import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';
// import DinamicGraphicPosition from './DinamicGraphPosition.jsx';
import styles from './Position.module.scss';
import MiniMapClear from './MiniMapClear.jsx';

const DinamicGraphPositionWards = ({wards, norms, position_sectors }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [activeItems, setActiveItem] = useState([]);
  // const HandleCardToggle = (key) => {
  //   activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
  // }
  const wardRefs = useRef([]);

  const SendRequest = function(status, ident) {
    axios.post(`/device-simulator`, { status:status, device: ident}, { headers: { 
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    } },
    ).then(response => {
      if (response.statusText !== 'OK') {
        console.log(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
      console.log(error);
    });
  }
  // const startPanic = function(ev, w) {
  //   ev.preventDefault();
  //   SendRequest('panic', w.appliances[0].identifier);
  // }
  // const startWarning = function(ev, w) {
  //   ev.preventDefault();
  //   SendRequest('warning', w.appliances[0].identifier);
  // }
  // const startLowBattery = function(ev, w) {
  //   ev.preventDefault();
  //   SendRequest('low_battery', w.appliances[0].identifier);
  // }
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (wardRefs.current) {
        if (wardRefs.current.includes(event.target) || wardRefs.current.includes(event.target.parentElement)) {
          const key = wardRefs.current.indexOf(event.target.parentElement);
          activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
        } else {
          setActiveItem([]);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wardRefs]);
   
  return (
      <div className={classNames("position-image-wrap", "columns_wrap_left_nobg_inside")}>
        <div className={"position-image"}>
          <MiniMapClear PositionSectors={position_sectors} wards={wards} norms={norms} />
        </div>
      </div>
  );
};

export default DinamicGraphPositionWards;