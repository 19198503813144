import React, { useState } from 'react';
import styles from './Patient.module.scss';
import classNames from 'classnames';
import Cogwheel from '../../../assets/images/cogwheel.svg';

const Patient = ({patient, index, openForm, deleteItem, permissions, isLast=false}) => {
  const [isHovering, setIsHovering] = useState('');
  const curPatient = patient.highlight ? patient._source : patient;
  const highlightFullName = `${patient.highlight && patient.highlight.last_name ? patient.highlight.last_name[0] : curPatient.last_name} ${patient.highlight && patient.highlight.first_name ? patient.highlight.first_name[0] : curPatient.first_name}`;
  const fullName = patient.highlight ? highlightFullName : `${curPatient.last_name} ${curPatient.first_name}`;
  // const date_of_birth = new Date(curPatient.date_of_birth).toLocaleString('ru',
  // {
  //   day: 'numeric',
  //   month: 'numeric',
  //   year: 'numeric'
  // });
  const appsList =  patient && patient.appliances && patient.appliances.map((app)=> { return app.act } ).join(', ');

  return (
    <li key={index} className={ isLast ? styles.PatientListItemNoBrd : styles.PatientListItem }  >
      { (patient.highlight) ? <>
        <span className={styles.PatientListWard}>{patient.ward && patient.ward[0]}</span>
        <span> <a href={`/patients/${curPatient.id}`} dangerouslySetInnerHTML={{ __html: fullName }} ></a> </span>
        <span className="center-text" dangerouslySetInnerHTML={{ __html: patient.highlight.age ? patient.highlight.age : curPatient.age }}></span>
        <span>{appsList}</span>
      </> : <>
        <span className={styles.PatientListWard}>{patient.ward && patient.ward[0]}</span>
        <span> <a href={`/patients/${curPatient.id}`}>{fullName}</a> </span>
        <span className="center-text">{curPatient.age}</span>
        <span>{appsList}</span>
      </> }
      
      <span>{curPatient.sex && curPatient.sex == 1 ? "жен." : "муж."}</span>
      <div>
        { permissions.delete ? <button className="btn-border btn-delete" onClick={(ev)=>{  ev.preventDefault(); deleteItem(index) }}> <span>Удалить</span> </button> : <></> }
        { permissions.update ? <button className="btn-gradient btn-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(index, true) }}>
            <img src={Cogwheel} alt="edit" />
            Редактировать
          </button> : <></> }
      </div>
      
    </li>
      
  );
};

export default Patient;