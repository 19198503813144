import React, {useState, useRef} from 'react';
import classNames from "classnames";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const CustomSliderWards = ({customClass, min, max, value, labelText, inputNameMin, inputNameMax, setParentValues = null, step = 1 }) => {
    const minW = min && min[0] +' - '+ min.substring(1);
    const maxW = max && max[0] +' - '+ max.substring(1);
    const [rangeCurrent, setRangeCurrent] = useState(value);
    const [rangeFirstForm, setRangeFirstForm] = useState(false);
    const [rangeSecondForm, setRangeSecondForm] = useState(false);

    const r1 = rangeCurrent && rangeCurrent[0].toString()[0] +'-'+ rangeCurrent[0].toString().substring(1);
    const r2 = rangeCurrent && rangeCurrent[1].toString()[0] +'-'+ rangeCurrent[1].toString().substring(1);

    const [rangeStrCurrent, setRangeStrCurrent] = useState([r1, r2]);

    if (setParentValues) {
      setParentValues(rangeCurrent);
      // rangeCurrent[0].toString().substring(1)
    }
    
    const SetRangeHandler = (val) => {
      console.log(` from SetRangeHandler `);
      // setRangeCurrent(val);
      setRangeCurrent(rangeCurrent => { 
        rangeCurrent = val;
        return rangeCurrent; 
      }); 
      console.log(rangeCurrent, val);
      const r1 = val && val[0].toString()[0] +'-'+ val[0].toString().substring(1);
      const r2 = val && val[1].toString()[0] +'-'+ val[1].toString().substring(1);
      setRangeStrCurrent([r1, r2]);
      console.log(rangeStrCurrent, [r1, r2]);
      setRangeStrCurrent((rangeStrCurrent) => { return rangeStrCurrent });
    }

    const setRangeFormInput = (ev, setRange, index) => {
      let input = ev.target;
      let val = input.value.replace('-','');

      if (parseInt(val) < parseInt(min) ) {
        val = min;
        input.value = min.toString()[0] +'-'+  min.toString().substring(1);
      }

      if ((parseInt(val) > parseInt(max))) {
        val = max;
        input.value = max.toString()[0] +'-'+  max.toString().substring(1);
      }
      
      setRange(false);

      if (index === 0) {
        console.log(`${index} ${parseInt(val)} < ${rangeCurrent[1]}`);
        if (parseInt(val) > parseInt(rangeCurrent[1])) {
          SetRangeHandler([rangeCurrent[0], parseInt(val)]);
        } else {
          SetRangeHandler([parseInt(val), rangeCurrent[1]]);
        }
      }

      if (index === 1) {
        console.log(`${index} ${parseInt(val)} < ${rangeCurrent[0]}`);
        if (parseInt(val) < parseInt(rangeCurrent[0])) {
          SetRangeHandler([parseInt(val), rangeCurrent[1]]);
        } else {
          SetRangeHandler([rangeCurrent[0], parseInt(val)]);
        }
      }
      console.log(rangeCurrent);
      console.log(rangeStrCurrent);
    }
    
    return (
      <div className={classNames("custom-range-slider-input", customClass)}>
        <label className="custom-range-slider_label">{labelText}</label>
        <input type="hidden" name={inputNameMin}  value={rangeStrCurrent[0]} /> 
        <input type="hidden" name={inputNameMax}  value={rangeStrCurrent[1]} /> 
        <span className="custom-range-slider_min">{minW}</span>
        <span className="custom-range-slider_max">{maxW}</span>
        <RangeSlider min={min} max={max} step={step} value={rangeCurrent} onInput={(val) => { SetRangeHandler(val) }} />
        {/* <span>{rangeStrCurrent[0]}</span>—<span>{rangeStrCurrent[1]}</span> */}
        { rangeFirstForm ? <input type="text" defaultValue={rangeStrCurrent[0]} className="custom-range-slider-input_short" onBlur={(ev)=> { setRangeFormInput(ev, setRangeFirstForm, 0); } }  onKeyDown={(ev)=> { if (ev.keyCode === 13) {  setRangeFormInput(ev, setRangeFirstForm, 0); } }} /> : <span onClick={(ev)=> { setRangeFirstForm(true) } }>{rangeStrCurrent[0]}</span>}
        —
        { rangeSecondForm ? <input type="text" defaultValue={rangeStrCurrent[1]} className="custom-range-slider-input_short" onBlur={(ev)=> { setRangeFormInput(ev, setRangeSecondForm, 1); } }  onKeyDown={(ev)=> { if (ev.keyCode === 13) {  setRangeFormInput(ev, setRangeSecondForm, 1); } }} /> : <span onClick={(ev)=> { setRangeSecondForm(true) } }>{rangeStrCurrent[1]}</span>}
      </div>
    );
};

export default CustomSliderWards;