import React, {useState, useRef, useEffect} from 'react';
import '@szhsin/react-menu/dist/index.css';
import styles from './Profile.module.scss';
import CogwheelIco from '../../../assets/images/cogwheel.svg';
import axios from 'axios';
import classNames from "classnames";


const WidgetListHeader = ({}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [align, setAlign] = useState('center');
  const [position, setPosition] = useState('anchor');
  const [viewScroll, setViewScroll] = useState('auto');
  const [currentTheme, setCurrentTheme] = useState();
  const [openMenu, setMenuOpened] = useState(true);

  const setTheme = (theme) => {
    axios.post(`/set-color-theme/${theme}`, {}, { headers: { 
      'X-CSRF-Token': token
    } },
    ).then(response => {
      if (response.statusText === 'OK') {
          console.log(' !!! OK ');
          document.querySelector('html').setAttribute("data-theme", theme);
          setCookie('theme', theme, {});
          setCurrentTheme(theme)
        } else {
          deleteCookie('theme');
          console.log(' что-то пошло не так ');
        }
      }).catch(error => {
        console.log('is error');
      })
      .finally(() => {  });
  };
  useEffect(() => {
    setCurrentTheme(document.querySelector('html').getAttribute("data-theme"));
  }, [setCurrentTheme]);

  // const themes = [{name:'default', label:'Стандартная тема'}, {name:'dark', label:'Темная тема'}, {name: 'light', label:'Светлая тема'}];

  const themes = [{name:'default', label:'Стандартная тема'},  {name: 'light', label:'Светлая тема'}];

  return (
    <>
      <div className={classNames("hdr-menu-item", "with-submenu", openMenu ? "active" : "")}>
        <span onClick={(ev)=> { setMenuOpened(!openMenu) }}><img className="hdr-menu-icon-no-brd" src={CogwheelIco} title=''/>Настройки</span>
        <ul className="hdr-menu-sub">
          {
            themes.map((theme) => (
              <li className={ classNames("hdr-menu-sub-item", openMenu && (currentTheme === theme.name) ? "active" : "") } key={theme.name} onClick={(ev)=> { setTheme(theme.name) }}>{theme.label}</li>
            ))
          }
        </ul>
      </div>
      
    </>
  );
};
export default WidgetListHeader;