// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Department-module__DepartmentsListItem___21Qdq{grid-gap:2.8rem 1.5rem;display:grid;grid-gap:.8rem 1.5rem;gap:.8rem 1.5rem;grid-auto-flow:dense;grid-template-columns:repeat(3, 2fr);margin:1rem 0 .8rem}.Department-module__DepartmentsListItem___21Qdq .Department-module__btn-small___TRgwQ{text-align:center}.Department-module__DepartmentsListHeader___3ujIR{color:#838e9b;color:var(--clr-key-03, #838e9b);font-size:.75rem;padding:.25rem 0 .15rem}.Department-module__DepartmentsForm___1rmfr{display:block}", "",{"version":3,"sources":["/mnt/c/Users/elena/Documents/projects/kardio2/kardio/app/javascript/components/departments/Department.module.scss"],"names":[],"mappings":"AACA,gDACI,sBAAA,CACA,YAAA,CACA,qBAAA,CAAA,gBAAA,CACA,oBAAA,CACA,oCAAA,CACA,mBAAA,CACA,sFACE,iBAAA,CAGJ,kDACE,aAAA,CAAA,gCAAA,CACA,gBAAA,CACA,uBAAA,CAEF,4CACE,aAAA","file":"Department.module.scss","sourcesContent":["\r\n.DepartmentsListItem {\r\n    grid-gap: 2.8rem 1.5rem;\r\n    display: grid;\r\n    gap: 0.8rem 1.5rem;\r\n    grid-auto-flow: dense;\r\n    grid-template-columns: repeat(3, 2fr);\r\n    margin: 1rem 0 0.8rem;\r\n    .btn-small {\r\n      text-align: center;\r\n    }\r\n  }\r\n  .DepartmentsListHeader {\r\n    color: var(--clr-key-03, #838e9b);\r\n    font-size: 0.75rem;\r\n    padding: 0.25rem 0 0.15rem;\r\n  }\r\n  .DepartmentsForm {\r\n    display: block;\r\n  }"]}]);
// Exports
exports.locals = {
	"DepartmentsListItem": "Department-module__DepartmentsListItem___21Qdq",
	"btn-small": "Department-module__btn-small___TRgwQ",
	"DepartmentsListHeader": "Department-module__DepartmentsListHeader___3ujIR",
	"DepartmentsForm": "Department-module__DepartmentsForm___1rmfr"
};
module.exports = exports;
