import React, {useState, useRef, useEffect} from 'react';
import classNames from "classnames";
import styles from './Search.module.scss';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomSlider from '../elements/CustomSlider.jsx';
import IcoSearch from '../../../assets/images/search_ico.svg';

const Search = ({searchPath, searchPage = false, defaultRequest = '', defaultFilters = [], departments = [], date_range = [] }) => {
    const [result, refreshResult] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [hintMsg, setHintMsg] = useState('');
    const [searchPositionLeft, setSearchPositionLeft] = useState(true);
    
    const curForm = useRef();
    const curFormInput = useRef();
    const curFormBtn = useRef();
    const [ageValues, setAgeValues] = useState([17, 50]);

    const [patientFilter, setPatientFilter] = useState(defaultFilters.includes('patient') || defaultFilters.length === 0);
    const [personalFilter, setPersonalFilter] = useState(defaultFilters.includes('personal') || defaultFilters.length === 0);
    const [wardFilter, setWardFilter] = useState(defaultFilters.includes('ward') || defaultFilters.length === 0);
    const [departmentsFilters, setDepartmentsFilters] = useState(departments);
    const [filters, setFilters] = useState(defaultFilters);
    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
   
    const setCorrectDate = (date_range, i) => {
      let correctDate = (i===0) ? new Date('01/01/1925') : new Date();
      if (date_range.length > 0 &&  date_range[i].length > 0 && !isNaN(Date.parse(date_range[i]))) {
        correctDate = new Date(date_range[i])
      }
      return correctDate;
    }
    const [searchRangeFrom, setSearchRangeFrom] = useState(setCorrectDate(date_range, 0));
    const [searchRangeTo, setSearchRangeTo] = useState(setCorrectDate(date_range, 1));

    const setFiltersHandler = (state, ident) => {
      if (state) {
        setFilters(filters => {
          return [ ...filters, ident ];
        });
      } else {
        setFilters(filters => {
          return filters.filter(item => item !== ident);
        });
      }
    }
    
    const setDepartmentsFiltersHandler = (w, event) => {
      if (event.target.checked) {
        setDepartmentsFilters(departmentsFilters => {
          return [ ...departmentsFilters, w ];
        });
      } else {
        setDepartmentsFilters(departmentsFilters => {
          return departmentsFilters.filter(item => item !== w);
        });
      }
    }

    // const ChangeSlider = (ev, val) => {
    //   console.log('on ChangeSlider ', ev, val);
    // }

   

    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      setHintMsg('... загрузка  ...');
      if (searchPage) {
        let path = `/search?request=${curFormInput.current && curFormInput.current.value}`;
        if (filters && (filters.length > 0)) {
          path += `&filters=${filters}`;
        }
        if (departmentsFilters && departmentsFilters.length > 0) {
          path += `&departments=${departmentsFilters}`;
        }
        if ((searchRangeFrom.toLocaleDateString() !== (new Date).toLocaleDateString()) || (searchRangeTo.toLocaleDateString() !== (new Date).toLocaleDateString())) {
          path += `&date_from=${searchRangeFrom.toLocaleDateString()}&date_to=${searchRangeTo.toLocaleDateString()}`;
        }
        if ((ageValues)) {
          path += `&age_from=${ageValues[0]}&age_to=${ageValues[1]}`
        }
        window.location.replace(path);
      } else {
        axios.post(searchPath, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setHintMsg('');
            setErrorMsg('');
            refreshResult(response.data.updated);
          } else {
            setHintMsg('');
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error);
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        })
        .finally(() => { 
          setTimeout(() => { 
            setHintMsg('');
            setErrorMsg('');
            refreshResult('');
            curForm.current.reset();
           }, 100000);
        });
      }
    }

    const setAgeValuesHandler = (vals) => {
      setAgeValues(vals)
    }

    useEffect(() => {
      if (curFormInput.current && curFormBtn.current)  {
          
        const Button = curFormBtn.current;
        const Input = curFormInput.current;
        if (Input.value.length > 0) {
          setSearchPositionLeft(false);
        }
        Input.addEventListener("click", (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          setSearchPositionLeft(false);
        });
        
        document.addEventListener( 'click', (e) => {
          const withinBoundaries = e.composedPath().includes(Button);
          if (( ! withinBoundaries ) && (Input.value.length === 0)) {
            setSearchPositionLeft(true);
          }
        });
      }
    }, [curFormInput, curFormBtn]);
    

    return (
      <div className={styles.SearchWrap}>
        <div className={styles.SearchFormWrap}>
          <form ref={curForm} className={styles.SearchForm}  name="search" action={searchPath} acceptCharset="UTF-8" data-remote="true" method="post" >
            <div className={styles.SearchFormStr}>
              <input type="search" id="site-search" name="search[request]" defaultValue={defaultRequest} className={styles.SearchInput} ref={curFormInput} />
              <span className={searchPositionLeft ? styles.SearchBtnLeft : styles.SearchBtn} onClick={(ev)=>{  SendRequest(ev) }} ref={curFormBtn} >
                <img className="search-icon" src={IcoSearch} alt="Найти" />
              </span>
            </div>
            { searchPage ? <div>
              <div className={classNames(styles.SearchFormLabels)}>
                <span></span>
                <label className={styles.SearchFormLabelsCheckbox}> <input type="checkbox" name='search[filters][]' value='patient' onChange={() => { setPatientFilter(!patientFilter); setFiltersHandler(!patientFilter, 'patient'); } } checked={patientFilter} /> <span>Пациенты</span></label>
                <label className={styles.SearchFormLabelsCheckbox}> <input type="checkbox" name='search[filters][]' value='personal' onChange={() => { setPersonalFilter(!personalFilter); setFiltersHandler(!personalFilter, 'personal') }} checked={personalFilter} /> <span>Персонал</span></label>
                <label className={styles.SearchFormLabelsCheckbox}> <input type="checkbox" name='search[filters][]' value='ward' onChange={() => { setWardFilter(!wardFilter); setFiltersHandler(!wardFilter, 'ward'); } } checked={wardFilter} /> <span>Отделения</span></label>
                <span></span>
              </div>
              <CustomSlider customClass={'field-light-range'} min={0} max={100} value={ageValues} labelText={'Возраст'} inputNameMin={'search[age_from]'} inputNameMax={'search[age_to]'} setParentValues={setAgeValuesHandler} />
              
              <div className={classNames("field-light", styles.SearchFormLabels)}>
                <span></span>
                {departments.map((w, i) => {
                  return <label className={styles.SearchFormLabelsCheckbox}> <input type="checkbox" name={`search[departments][${w}]`} value={w} onChange={(event) => { setDepartmentsFiltersHandler(w, event); } } checked={departmentsFilters.includes(w)} /> <span>{w}</span></label>
                })}
                <span></span>
              </div>
              <div className={classNames(styles.SearchFormDates)}>
                <span></span>
                <div className="field-light">
                  <label>Дата до</label>
                  <input type="hidden" name='search[date_from]'  value={searchRangeFrom} /> 
                  <div className="custom_datepicker_wrapper">
                    <DatePicker selected={searchRangeFrom} onChange={(date) => setSearchRangeFrom(date)} />
                  </div>
                </div>
                <div className="field-light">
                  <label>Дата после</label>
                  <input type="hidden" name='search[date_to]'  value={searchRangeTo} /> 
                  <div className="custom_datepicker_wrapper">
                    <DatePicker selected={searchRangeTo} onChange={(date) => setSearchRangeTo(date)} />
                  </div>
                </div>
                <span></span>
              </div>
              </div>: <></> }
            <p className={classNames(styles.SearchHint, 'errors')}>{errorMsg}</p>
            <p className={classNames(styles.SearchHint, 'hint')}>{hintMsg}</p>
          </form>
        </div>
        
        { result && !searchPage ? 
          result.all ? <div className={classNames(styles.SearchResults)}>
            <label for="site-search">Найдено:</label>
            <div className={styles.SearchResultsItem}> 
              {result.patients && result.patients > 0 ? <span className={styles.SearchResultsItemCount}> <a href={`/search?request=${curFormInput.current && curFormInput.current.value}&filters=patient`} title=""> {result.patients}</a> пациентов </span> : <></>} 
              {result.personal && result.personal > 0 ? <span className={styles.SearchResultsItemCount}> <a href={`/search?request=${curFormInput.current && curFormInput.current.value}&filters=personal`} title=""> {result.personal} </a> персонала </span> : <></>}
              {result.wards && result.wards > 0 ? <span className={styles.SearchResultsItemCount}> <a href={`/search?request=${curFormInput.current && curFormInput.current.value}&filters=ward`} title=""> {result.wards}</a> палат </span> : <></>}
              {result.all && <a href={`/search?request=${curFormInput.current && curFormInput.current.value}`} title="" className={styles.SearchResultsItemAll}> смотреть все </a> }
            </div>
          </div> : <div>Ничего не найдено</div> 
          : <></>
        }
        
      </div>
    );
};

export default Search;