const Global = () => { }

export default Global;

Global.devices = [];

Global.changeDeviceStatus = (value) => {
  Global.devices = Global.devices.filter((res) => (res.ident !== value.ident) );
  Global.devices.push(value);
  
  // if (Global.devices.filter((res) => (res.ident === value.ident) ).length > 1) {
  //   Global.devices = Global.devices.filter((res) => (res.ident !== value.ident) );
  //   Global.devices.push(value);
  // } else {
  //   let ind = Global.devices.findIndex((obj => obj.ident === value.ident))
  //   if (ind >= 0) {
  //     // debugger
  //     Global.devices[ind].status = value.status
  //     Global.devices[ind].battery = value.battery
  //     Global.devices[ind].ward = value.ward
  //     Global.devices[ind].patient = value.patient
  //   } else {
  //     Global.devices.push(value);
  //   }
  // }

}

Global.showDevice = (ident) => {
  let result = null;
  result = Global.devices.find((res) => (res.ident === ident));
  return result;
}
