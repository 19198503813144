import React, {useState, useRef, useEffect} from 'react';
import styles from './Position.module.scss';
import axios from 'axios';
import classNames from "classnames";
import Switch from "react-custom-checkbox/switch";
import MiniMap from './MiniMap.jsx';

const AddPositionSector = ({ PositionSectorsOnMap, index, openForm, freeSectors, refreshSector,  permissions }) => {
  const [openFormNewSector, setOpenFormNewSector] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [hintMsg, setHintMsg] = useState('');
  const curForm = useRef();
  const mapPointInput = useRef();
  const [mapPoint, setMapPoint] = useState({'x':0, 'y':0});
  const [currentSector, setCurrentSector]= useState();
  const [isValid, setIsValid] = useState(false);
  const isSectorEmpty = () => {
    mapPoint.x && mapPoint.y
  }
  const [deleteSector, setDeleteSector] = useState(mapPoint.x && mapPoint.y);
  const [isChargingPlace, setIsChargingPlace] = useState(false);
  
  const isChargingPlaceHandler = () => {
    setIsChargingPlace(!isChargingPlace);
  }

  const deleteSectorHandler = () => {
      if (!deleteSector) {
        setMapPoint((mapPoint) => { 
          mapPoint.x = 0; 
          mapPoint.y = 0; 
          return mapPoint; 
        });
      }
      mapPointInput.current.value = JSON.stringify(mapPoint);
      setDeleteSector(!deleteSector);
    }

    const setCurrentSectorPosition = (x, y) => {
      setMapPoint((mapPoint) => { 
        mapPoint.x = parseInt(x); 
        mapPoint.y = parseInt(y); 
        return mapPoint; 
      });
      mapPointInput.current.value = JSON.stringify(mapPoint);
      if (deleteSector) {
        setDeleteSector(!deleteSector);
      }
    }
    
    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    
    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      if (isValid) {
        setHintMsg('... загрузка  ...')
        axios.post(`/sectors/${currentSector ? currentSector.id : '0'}/update`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setErrorMsg('');
            setHintMsg(response.data.message);
            refreshSector(index, response.data.updated);
            openForm(index, false);
            setOpenFormNewSector(false);
          } else {
            setHintMsg('');
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error);
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        })
        .finally(() => {  });
      } else {
        setHintMsg('');
        setErrorMsg('Выберите область');
      }
    }

    // for checkbox
    const checkedTrackStyle = {
      opacity: 1,
      background: 'linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2,#FF4E4E) 96.67%)',
      transition: 'all 0.25s ease-in-out',
    }
   
    const checkedIndicatorStyle = {
      transform: 'translateX(1rem)',
    }
   
    const checkedIconStyle = {
      opacity: 1,
      transition: 'all 0.25s ease-in-out',
    }
   
    const indicatorStyle = {
      alignItems: 'center',
      background: '#ffffff',      // red
      borderRadius: '1.126rem',
      bottom: '0.25rem',
      display: 'flex',
      height: '1.126rem',
      justifyContent: 'center',
      left: '0.25rem',
      outline: 'solid 2px transparent',
      position: 'absolute',
      transition: '0.25s',
      width: '1.126rem',
    }
   
    const trackStyle = {
      background: '#CAD1D8',        //gray
      border: '1px solid #CAD1D8',
      borderRadius: '0.93rem',
      cursor: 'pointer',
      display: 'flex',
      height: '1.75rem',
      marginRight: '0.75rem',
      position: 'relative',
      width: '2.75rem',
    }
    // end for checkbox
    const ClearAndOpenForm = () => {
        if (curForm.current) {
          curForm.current.reset();
          // const select_ward = curForm.current.querySelector('select[name="patient[ward]"]');
          // select_ward.value = 'true';
        }
        // setSelected([role_option[1]]);
        setHintMsg('');
        setErrorMsg('');
        setOpenFormNewSector(true);
      }
    const onChangeHandler = (ev) => {
      let val = ev.target.value;  
      setCurrentSector(freeSectors.find((s)=>{return (s.sector == val)}));
      setIsValid(!isNaN(parseInt(ev.target.value)));
    }
    
    return (
      <li key={index} className={styles.SectorFormItem}>
        { openFormNewSector ?  <form ref={curForm} name="sector" action={`/sectors/${currentSector ? currentSector.id : '0'}/update`} acceptCharset="UTF-8" data-remote="true" method="post" className={styles.DoctorForm}>
          <div className={classNames("field-light", styles.SectorFormFiled)}>
            <div className={styles.SectorFormInput}>
              <select name='sector[sector]' onChange={(ev) => { onChangeHandler(ev) }} >
                <option defaultValue > -- Выберите область -- </option>
                {
                  freeSectors && freeSectors.sort((a, b) => a.sector - b.sector).map((fs, i) => {
                    return <option key={i} value={fs.sector}>{fs.sector}</option>
                  })
                } 
              </select>
              <div className="field-with-checkbox_wrapper">
                <label className="field-with-checkbox"> 
                  <span>Убрать область с карты</span>
                  <Switch 
                    checked={deleteSector}
                    onChange={deleteSectorHandler}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
              </div>
              <div className="field-with-checkbox_wrapper">
                <label className="field-with-checkbox"> 
                  <span>Это место зарядки</span>
                  <Switch 
                    checked={isChargingPlace}
                    onChange={isChargingPlaceHandler}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className={classNames("field-light", styles.SectorFormFiled)}>
            <div className={styles.SectorFormInput}>
              <input type='text' name='sector[map_point]' defaultValue={JSON.stringify(mapPoint)}  ref={mapPointInput} style={{width: '12rem'}} />
            </div>
          </div>
          <div className={styles.SectorMapWrapper}>
            <MiniMap  PositionSectors={PositionSectorsOnMap}   setCurrentSectorPosition={setCurrentSectorPosition}  />
          </div>
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={ (ev)=>{ SendRequest(ev) } } />
          <button type="reset" className="btn-border" onClick={(ev)=> { setOpenFormNewSector(false); }}> <span>Не сохранять</span> </button>
        </form> : <div  className={styles.PositionSectorListItemNoBrd} >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          { freeSectors ? <button className="btn-border btn-create" onClick={(ev)=>{ ClearAndOpenForm() }} > <span className="icon-plus">+</span><span>добавить область</span> </button> : <></> }
        </div> 
        }
      </li>
    );
};

export default AddPositionSector;