import React from 'react';
import styles from './Position.module.scss';
import Cogwheel from '../../../assets/images/cogwheel.svg';

const PositionSector = ({currentSector, index, openForm, deleteItem, permissions, isLast = false}) => {
  const coords = JSON.parse(currentSector.map_point);
  return (
    <li key={index} className={isLast ? styles.PositionSectorListItemNoBrd : styles.PositionSectorListItem } >
      <span>{currentSector.sector}</span>
      <span>{`x:${coords.x} y:${coords.y}`}</span>
      <div>
        { permissions.delete ? <button className="btn-border btn-delete" onClick={(ev)=>{  ev.preventDefault(); deleteItem(index) }}> <span>Удалить</span> </button> : <></> }
        { permissions.update ? <button className="btn-gradient btn-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(index, true) }}>
            <img src={Cogwheel} alt="edit" />
            Редактировать
        </button> : <></> }
      </div>
    </li>
  );
};

export default PositionSector;