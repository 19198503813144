import React, {useState} from 'react';
import { MultiSelect } from "react-multi-select-component";

const Roles = ({defaultRoles, inputName, exceptRole = null }) => {
  let role_option = [
    { label: "Администратор", value: "0" },
    { label: "Персонал", value: "1" },
    { label: "Техник", value: "2" }
  ]

  if (exceptRole) {
    role_option = role_option.filter((item) => { return item.value !== "0" })
  }
    
  const [selected, setSelected] = useState(role_option.filter((r) => { if (defaultRoles.split(",").includes(r.value)) { return r} }));
  
  const select_localization = {
    "allItemsAreSelected": "Выбраны все элементы.",
    "clearSearch": "Очистить поиск",
    "clearSelected": "Очистить выбранное",
    "noOptions": "Без вариантов",
    "search": "Поиск",
    "selectAll": "все",
    "selectAllFiltered": "Выбрать всех (отфильтровано)",
    "selectSomeItems": " -- Выберете роли -- ",
    "create": "Создавать",
  };
    
    return (
        <>
          <input type="hidden" name={inputName}  value={selected.map(v => { return v.value })} />
          <MultiSelect
            options={role_option}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
            overrideStrings={select_localization}
          />
        </>
        
      
    );
};

export default Roles;