import React, {useState, useRef} from 'react';
import styles from './Appliance.module.scss';
import axios from 'axios';

const ApplianceForm = ({userId, appliance, index, openForm, refreshAppliance, patients, wards, permissions }) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [hintMsg, setHintMsg] = useState('');
    const curForm = useRef();
    
    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      return false;
    }

    // const checkMask = (ev) => {
    //   let mask = "********-****-****-****-************";
    //   let curVal = ev.target.value;
    //   curVal = curVal.replaceAll('-','')
    //   // curVal = curVal.replace(/[^0-9]+/g, "");

    //   let i = 0;
    //   let result = mask.replaceAll("*", () => curVal[i++] || "*");
    //   if ((result.length !== 36) || result.includes('*')) {
    //     if (result === '----') { result = '' }
    //     setErrorMsg('Исправьте ошибки в поле ID устройства, идетификатор должен содержать 36 цифр');
    //     ev.target.classList.add('with-error');
    //   } else {
    //     setErrorMsg('')
    //   }
    //   ev.target.value = result
    // }

    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      const HasError = CheckValidForm(curForm.current);
      if (!HasError) {
        setHintMsg('... загрузка  ...')
        axios.post(`/private/${userId}/devices/${appliance.id}/update`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setHintMsg(response.data.message);
            refreshAppliance(index, response.data.updated);
            openForm(index, false);
          } else {
            setErrorMsg(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
            setHintMsg('');
          }
        }).catch(error => {
          console.log(error);
          setErrorMsg(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
          setHintMsg('');
        })
        .finally(() => { setHintMsg(''); });
      } 
    }
    
    return (
      <li key={index} className={styles.AppliancesFormItem}>
        <form ref={curForm} name="appliance" action={`/private/${userId}/devices/${appliance.id}/update`} acceptCharset="UTF-8" data-remote="true" method="post" className={styles.ApplianceForm}>
          <input type="hidden" name='appliance[archived]' value={appliance.archived} />
          <div className="field-light">
            <label htmlFor='appliance[identifier]'>ID устройства</label>
            <input type="hidden" name='appliance[identifier]' value={appliance.identifier} />
            <input type="text" name='appliance[act]' required   defaultValue={appliance.act}  />
          </div>
          <div className="field-light">
            <label htmlFor='appliance[identifier]'>Пациент</label>
            <select name='appliance[patient_id]' defaultValue={appliance.patient && appliance.patient.id}>
              <option selected value=''> -- Выберете пациента -- </option>
              {patients.map((p, i) => {
                  return <option key={i} value={p.id}>{`${p.first_name} ${p.last_name}`}</option>
                })} 
            </select>
          </div>
          {/* <div className="field-light">
            <label>Палата</label>
            <select name='appliance[ward_id]' defaultValue={appliance.ward && appliance.ward.id}>
              <option selected value=''> -- Выберете палату -- </option>
              {wards.map((w, i) => {
                  return <option key={i} value={w.id}>{w.number}</option>
                })} 
            </select>
          </div> */}
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
          <button type="reset" className="btn-border"onClick={(ev)=> { openForm(index, false); }}><span>Не сохранять</span></button>
        </form>
      </li>
    );
};

export default ApplianceForm;