import React, {useRef, useState, useEffect} from 'react';
import DinamicGraphic from './DinamicGraphic.jsx';
import classNames from 'classnames/bind';
import styles from './DinamicWard.module.scss';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import axios from 'axios';

const DinamicWard = ({ind, number, currentStatus,  showModal, deviceIds, norms }) => {
    // ++++++++++++++++++++++++++++++ start
  // const maxArrayLength = 10
  const maxArrayLength = 5

  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const connectionPath= "ws://demo.thingsboard.io/api/ws/plugins";
  const tokenPath= "http://demo.thingsboard.io/api/auth/login";
  const tokenParams= {"username":"palutova.elena@gmail.com", "password":"Qwerty)8"};
  const indicators = JSON.parse(norms);
  const devices = deviceIds;

  const [currentDevice, SetCurrentDevice] = useState(devices[devices.length - 1]);
  const [otherDevice, SetOtherDevice] = useState(devices[0]);
  const [status, setStatus] = useState(currentStatus);

  const [testDataPulse, setTestDataPulse] = useState([]);
  const [testDataSaturation, setTestDataSaturation] = useState([]);
  const [testLabels, setTestLabels] = useState([]);
  const [battery, setBattery] = useState(80);

  const [reserveDataPulse, setReserveDataPulse] = useState([]);
  const [reserveDataSaturation, setReserveDataSaturation] = useState([]);
  const [reserveLabels, setReserveLabels] = useState([]);
  const [reserveBattery, setReserveBattery] = useState(80);

  const [cardTitle, setCardTitle] = useState('');
  const [cardText, setCardText] = useState('');

  const [modalClassObj, setModalClassObj] = useState('');
  const [ShowTogglerElement, setShowTogglerElement] = useState(false);

  const togglerElement = <p onClick={(ev) => changeDeviceForWard(ev, true)} className={styles.PatientCardLink}>
      {`Показать данные пациента ${otherDevice.patient.last_name} ${otherDevice.patient.first_name}`}
    </p>;

  const [jwtToken, setJWTToken] = useState('');
  
  const [socketUrl, setSocketUrl] = useState(`${connectionPath}/telemetry?token=${jwtToken}`);
  const [messageHistory, setMessageHistory] = useState([]);
  
  const switchTextsAfterStatus = (device, number, localStatus) => {
    // console.log('  ....  switchTextsAfterStatus  ....  ');
    switch (localStatus) {
      case 'low_battery':
        setCardTitle('Внимание');
        setCardText('Низкий заряд батареи. Пожалуйста подзарядите устройство. ');
        setModalClassObj(styles.LowBattery);
        break;
      case 'warning':
        setCardTitle('Внимание');
        setCardText(`Палата №${number} <b>${device.patient.last_name} ${device.patient.first_name}</b> Возраст: ${device.patient.age} `);
        setModalClassObj(styles.Warning);
        break;
      case 'panic':
        setCardTitle('Внимание');
        setCardText(`Палата №${number} <b>${device.patient.last_name} ${device.patient.first_name}</b> Возраст: ${device.patient.age} `);
        setModalClassObj(showModal ? styles.PanicOpen : styles.Panic);
        break;
      default:
        setCardTitle(`Палата №${number}`);
        setCardText(`<b>${device.patient.last_name} ${device.patient.first_name}</b> Возраст: ${device.patient.age} `);
        setModalClassObj(styles.Normal);
    }
  }

  const checkStatus = (device, pulse, saturation, battery) => {
    let localStatus = status;
    console.log(` ............. before status ${localStatus} in ${number} `);
    if (indicators) {
      const d = new Date;

      const isLowBattery = parseInt(battery) < parseInt(indicators.battery_min);

      if (isLowBattery)  {  localStatus = 'low_battery';  } 

      const isPulseWarn = (parseInt(pulse.val) < parseInt(indicators.pulse_warn_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_warn_max));
      const isSaturationWarn = (parseInt(saturation.val) < parseInt(indicators.saturation_warn_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_warn_max));

      if  (isPulseWarn || isSaturationWarn) {
        localStatus = 'warning';
      }
      
      console.log(` warning ${d.toLocaleTimeString()} if ${isPulseWarn} ${isSaturationWarn}`);
      
      const isPulsePanic = ((parseInt(pulse.val) < parseInt(indicators.pulse_panic_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_panic_max)));
      const isSaturationPanic = ((parseInt(saturation.val) < parseInt(indicators.saturation_panic_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_panic_max)));
      
      if ( isPulsePanic || isSaturationPanic ) {
        localStatus = 'panic';
        // showModal = false;
      }

      if (!(isPulseWarn || isSaturationWarn || isPulsePanic || isSaturationPanic || isLowBattery)) {
        localStatus = '';
        showModal = false;
      }
       console.log(` panic ${d.toLocaleTimeString()} if ${isPulsePanic} ${isSaturationPanic}`);
    }

    console.log(` ............. after status ${localStatus} in ${number} ${pulse.val}`); 
    
    return localStatus;
  }

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    onClose: (event) => {
      if (event.code===1006) {
        GetJWTTOken()
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  
  //
  const GetJWTTOken = (ev) => {
    axios.post(tokenPath, tokenParams, { headers: { 
      // 'Content-Type': 'multipart/form-data',
      // 'X-CSRF-Token': token
      'Accept': 'application/json'
    } },
    ).then(response => {
      if (response.status === 200) {
        setJWTToken((jwtToken) => { 
          return response.data.token;
        });
        setSocketUrl(socketUrl => {
          return `${connectionPath}/telemetry?token=${jwtToken}`;
        });
      } else {
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
        console.log(error);
    });
  }

  const sendError = (error) => {
    // TODO uncomment it after
    // axios.post('/send-error-msg', { data: error }, { headers: { 
    //   'Accept': 'application/json',
    //   'X-CSRF-Token': token
    //  } },
    //  ).then(response => {
    //    if (response.status === 200) {
    //     console.log(response.message);
    //    } else {
    //      console.log('Произошли ошибки, пожалуйста попробуйте позже.');
    //    }
    //  }).catch(error => {
    //      console.log(error);
    //  });
  }

  useEffect(() => {
    if (lastMessage !== null) {
      const result = JSON.parse(lastMessage.data);
      const device = devices[result.subscriptionId];
      const devId = device.identifier;
      if (!result.errorCode && (result.errorCode !== 2) && (result.errorCode !== 1)) {
        if (result.data.status_battery_level) {
          setBattery(`${result.data.status_battery_level[0][1]} в ${new Date(result.data.status_battery_level[0][0]).toLocaleTimeString()} `);
        }
        const temperature_date = new Date(result.latestValues.temperature).toLocaleTimeString();
        const temperature = result.data && result.data.temperature ? { time: new Date(result.data.temperature[0][0]).toLocaleTimeString(), val: result.data.temperature[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        // console.log(` ${temperature_date} в ${temperature.time} ${temperature.val}`);
  
        const saturation_date = new Date(result.latestValues.saturation).toLocaleTimeString();
        const saturation = result.data && result.data.saturation ? { time: new Date(result.data.saturation[0][0]).toLocaleTimeString(), val: result.data.saturation[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        // console.log(` s ${saturation_date} в ${saturation.time} ${saturation.val}`);
        
        const pulse_date = new Date(result.latestValues.pulse).toLocaleTimeString();
        const pulse = result.data && result.data.pulse ? { time: new Date(result.data.pulse[0][0]).toLocaleTimeString(), val: result.data.pulse[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        const rssi_date = new Date(result.latestValues.rssi).toLocaleTimeString();
        const rssi =  result.data && result.data.rssi ? { time: new Date(result.data.rssi[0][0]).toLocaleTimeString(), val: (-1)*result.data.rssi[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        // console.log(` ${rssi_date} в ${rssi.time} ${rssi.val}`);
        console.log(" ____________________________________ ");
        
        let localStatus = checkStatus(device, pulse, saturation, battery);
        
        setStatus(localStatus);


       if (( status!=='low_battery' || status!=='panic' || status!=='warning') && (devices.length > 1) && (currentDevice.identifier !== otherDevice.identifier)) {
         setShowTogglerElement(true);
       } else {
         setShowTogglerElement(false);
       }

       if (((localStatus === 'warning') || (localStatus === 'panic') || (localStatus === 'low_battery')) && ((devices.length > 1) && (device.identifier !== currentDevice.identifier))) {
         changeDeviceForWard();
       }

       switchTextsAfterStatus(currentDevice, number, localStatus)

        if (device.identifier === currentDevice.identifier) {
          setTestDataSaturation(testDataSaturation => {
            let v = [ ...testDataSaturation, saturation.val];
            v.map(item => item.group).slice(-1*maxArrayLength);
            return v;
          });

          setTestDataPulse(testDataPulse => {
            let v = [ ...testDataPulse, pulse.val];
            v.map(item => item.group).slice(-1*maxArrayLength);
            return v;
          });

          setTestLabels(testLabels => {
            let l = [ ...testLabels, saturation.time];
            l.map(item => item.group).slice(-1*maxArrayLength);
            return l;
          });

        } else {

          setReserveDataSaturation(reserveDataSaturation => {
            let v = [ ...reserveDataSaturation, saturation.val];
            v.map(item => item.group).slice(-1*maxArrayLength);
            return v;
          });

          setReserveDataPulse(reserveDataPulse => {
            let v = [ ...reserveDataPulse, pulse.val];
            v.map(item => item.group).slice(-1*maxArrayLength);
            return v;
          });

          setReserveLabels(reserveLabels => {
            let l = [ ...reserveLabels, saturation.time];
            l.map(item => item.group).slice(-1*maxArrayLength);
            return l;
          });

          // console.log(` telemetry is not correct for device ${device.identifier}`)
        }
        setMessageHistory((prev) => prev.concat(lastMessage));

      } else {
        sendError(`${result.errorMsg} deviceId = ${devId}`);
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    console.log(' start useEffect only once !!!')
    if (readyState === ReadyState.CONNECTING) {
      getDataTelemetry();
    }
  }, []);

  const changeDeviceForWard = (ev, changeData = false) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    
    if (currentDevice !== otherDevice) {
      let currentDeviceLocal = currentDevice,
          otherDeviceLocal = otherDevice;

      SetCurrentDevice(currentDevice => {
        let l = otherDeviceLocal;
        return l;
      });

      SetOtherDevice(otherDevice => {
        let l = currentDeviceLocal;
        return l;
      });
      
      if (changeData) {
        // debugger
        switchTextsAfterStatus(otherDevice, number, '');
        
        setTestDataSaturation(testDataSaturation => {
          let v = reserveDataSaturation;
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });

        setTestDataPulse(testDataPulse => {
          let v = reserveDataPulse;
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });

        setTestLabels(testLabels => {
          let l = reserveLabels;
          l.map(item => item.group).slice(-1*maxArrayLength);
          return l;
        });
        
      }

    }
    // console.log(` !!! current Device patient is ${currentDevice.patient.first_name}`);
  }


  const objects = devices.map((dev, index)=>{
    return {
      tsSubCmds: [
       {
          entityType: "DEVICE",
          entityId: dev.identifier,
          scope: "LATEST_TELEMETRY",
          cmdId: index
        } 
      ],
      historyCmds: [],
      attrSubCmds: []
    }
  });
  
  // const dataMsg = JSON.stringify(objects);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const getDataTelemetry = () => {
    console.log('start getDataTelemetry only once');
    objects.map((obj)=>{
       const dataMsg = JSON.stringify(obj);
       sendMessage(dataMsg);
     });
  }
  // ++++++++++++++++++++++++++++++ end

  const curItem = useRef();
  const curItemCard = useRef();
  const [leftSide, setLeftSide] = useState(false);
    
  useEffect(() => {
    
    if (curItem && curItem.current )  { 
      if (curItemCard && curItemCard.current) {
        const graphicLeftPos = curItem.current.closest(".ward-wrap").getBoundingClientRect().left + 45 - curItem.current.getBoundingClientRect().left;
        const graphicWidth = curItem.current.closest(".ward-wrap").offsetWidth - 85;
        curItemCard.current.setAttribute('style', `left: ${graphicLeftPos}px; width: ${graphicWidth}px; `);
        curItem.current.parentNode.setAttribute('style', `margin-bottom: ${showModal ? 404 : 0 }px`);
      } else {
        // TODO fix it later
        curItem.current.parentNode.setAttribute('style', `margin-bottom: 0px`);
      }
    }

    if (status==='low_battery' && showModal) {
      // curItemCard.current.removeAttribute('style');
      // curItem.current.parentNode.removeAttribute('style');
      const leftSide = ((curItem.current.getBoundingClientRect().left - curItem.current.closest(".ward-wrap").getBoundingClientRect().left - curItem.current.closest(".ward-wrap").offsetWidth/2) > 0);
      setLeftSide(leftSide);
    }
  },[curItem, curItemCard, showModal, status]);
  
    return (
        <div key={ind}  ref={curItem} data-number={number} styles={{marginBottom: `${showModal ? 404 : 0}px`}} className={classNames(styles.Ward, modalClassObj)}>
          { ( cardTitle && cardText && status!=='low_battery' ) ? <div className={showModal ? styles.PatientCardOpen : styles.PatientCard} ref={curItemCard}  >
            <div className={styles.PatientCardTitle}>{cardTitle}</div>
            {ShowTogglerElement ? togglerElement : <></>}
            <div className={styles.PatientCardText} dangerouslySetInnerHTML={{ __html: cardText }}></div>

            <div className={styles.PatientGraphicWrap}>
              <div className={styles.PatientGraphic}> 
                <DinamicGraphic maxArrayLength={maxArrayLength} labels={testLabels}  saturation={null} pulse={testDataPulse} norms={norms} number={number} /> 
              </div>
              <div className={styles.PatientGraphic}> 
                <DinamicGraphic maxArrayLength={maxArrayLength} labels={testLabels} saturation={testDataSaturation} pulse={null} norms={norms} number={number} /> 
              </div>
            </div>
            </div> : (cardTitle && cardText && (status==='low_battery') ) ? <div className={showModal ? (leftSide ? styles.PatientCardOpenSmallRight : styles.PatientCardOpenSmall) : styles.PatientCardSmall }>
              <div className={styles.PatientCardTitle}>{cardTitle}</div>
              <div className={styles.PatientCardText} dangerouslySetInnerHTML={{ __html: cardText }}></div>
            </div> : <></>
          }
        </div>
      
    );
};

export default DinamicWard;