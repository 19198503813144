import React, {useState, useEffect} from 'react';
import styles from './Department.module.scss';
import Department from './Department.jsx';
import DepartmentForm from './DepartmentForm.jsx';
import axios from 'axios';
import classNames from "classnames";

const DepartmentList = ({departments}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [departmentsList, setDepartmentsList] = useState(departments);
  const [editableComponents, setEditableComponents] = useState([]);
  
  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }
  const refreshDepartment = (j, params) => {
    console.log(departments)
    setDepartmentsList(departmentsList => {
      return departmentsList.map((department, i) => {
        if (j === i) {
          department = params;
        }
        return department
      });
    });
  }
  
  const deleteItem = (i) => {
    console.log('не разрешено удалять отделение пока');
    // const department = departmentsList[i];
    // axios.post(`/departments/${department.id}/delete`, {}, { headers: { 
    //   'X-CSRF-Token': token
    // } },
    // ).then(response => {
    //   if (response.statusText === 'OK') {
    //     console.log(response.data.message);
    //     setDepartmentsList(departmentsList => {
    //       return departmentsList.filter(item => item.id !== department.id);
    //     });
    //   } else {
    //     console.log('Произошли ошибки, пожалуйста попробуйте позже.');
    //   }
    // }).catch(error => {
    //   console.log(error);
    //   console.log('Произошли ошибки, пожалуйста попробуйте позже.');
    // });
  }
  
  const result = departmentsList.map((department, i) => {
    if (editableComponents.includes(i) ) { 
      return <DepartmentForm index={i} department={department} openForm={openForm} refreshDepartment={refreshDepartment}/> }
    else { 
      return <Department index={i} department={department} openForm={openForm} deleteItem={deleteItem}/> 
    }  
  });
  
    return (
      <ul className={styles.DepartmentsList}>
        <li className={classNames(styles.DepartmentsListItem, styles.DepartmentsListHeader)}>
          <span>Название</span>
          <span></span>
          <span></span>
        </li>
        { result }
      </ul>
    );
};

export default DepartmentList;