import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import Ward from './Ward.jsx';

const WardList = ({wards}) => {
    
    const [activeItems, setActiveItem] = useState([]);
    const HandleCardToggle = (key) => {
      activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
    }
    const wardRefs = useRef([]);

    useEffect(() => {
        function handleClickOutside(event) {
          event.stopPropagation();
          event.stopImmediatePropagation();
          if (wardRefs.current) {
            if (wardRefs.current.includes(event.target) || wardRefs.current.includes(event.target.parentElement)) {
              const key = wardRefs.current.indexOf(event.target.parentElement);
              activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
            } else {
              setActiveItem([]);
            }
          }
          
        }
        // Bind the event listener
        document.addEventListener("click", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("click", handleClickOutside);
        };
    }, [wardRefs]);
   
    return (
      <div className="ward-wrap">
         {  
            wards && wards.map((w, i) => {  
              return <div key={i} ref={(el) => (wardRefs.current[w.key] = el)} onClick={(ev) => { HandleCardToggle(w.key) }}> <Ward ind={i} status={w.status} info={w.info} number={w.number} showModal={activeItems.includes(w.key)}  />  </div>
            })  
          }
      </div>
      
    );
};

export default WardList;