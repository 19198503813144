import Global from "../Global.jsx";
import React, {useRef, useState, useEffect, useMemo} from 'react';
import classNames from 'classnames';
import styles from './Position.module.scss';
import BatteryLevel from '../battery/BatteryLevel.jsx';
// import WebSocket from 'ws';
import axios from 'axios';
import ManSmiley from './ManSmiley.jsx';
import ManSad from './ManSad.jsx';
import CirclePosition from './CirclePosition.jsx';

const DinamicGraphicPosition = ({ ind, number, currentStatus, showModal = false, device, norms, sectors }) => {
    // ++++++++++++++++++++++++++++++ start
  // Вынести логику модалки сюда
    // ++++++++++++++++++++++++++++++ start
    const maxArrayLength = 10

    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
   
    const tokenParams =  {
      "username": process.env.TOKEN_USER,
      "password": process.env.TOKEN_PASSWORD
    };
    const connectionPath = process.env.CONNECTION_PATH;
    const tokenPath =  process.env.TOKEN_PATH;
    
    const indicators = JSON.parse(norms);
    const curDevice = device;
  
    const [readyState, setReadyState ] = useState();
    const [status, setStatus] = useState(currentStatus);
  
    const [testDataPulse, setTestDataPulse] = useState(['99', '107', '107', '140', '110']);
    const [testDataSaturation, setTestDataSaturation] = useState(['92', '95', '99', '100', '101']);
    const [testLabels, setTestLabels] = useState(['13:54:25', '13:55:29', '13:54:30', '13:55:31', '13:54:32']);
    const [testDataPosition, setTestDataPosition] = useState(['0', '0']);
    
    // const [testDataPulse, setTestDataPulse] = useState(['99', '107', '107', '140', '110']);
    // const [testDataSaturation, setTestDataSaturation] = useState(['92', '95', '99', '100', '101']);
    // const [testLabels, setTestLabels] = useState(['13:54:25', '13:55:29', '13:54:30', '13:55:31', '13:54:32']);
    const [battery, setBattery] = useState(80);
    const [statusClass, setStatusClass] = useState('');
  
    const [reserveDataPulse, setReserveDataPulse] = useState([]);
    const [reserveDataSaturation, setReserveDataSaturation] = useState([]);
    const [reserveLabels, setReserveLabels] = useState([]);
    const [reserveBattery, setReserveBattery] = useState(80);
    const [count, setCount] = useState(0);
  
    const [cardTitle, setCardTitle] = useState('');
    const [cardText, setCardText] = useState('');
    // const [positionCoords, setPositionCoords] = useState({"x":59+ind*10,"y":38+ind*10});
    const [positionCoords, setPositionCoords] = useState({"x":0,"y":0});
    let positionCoordsLatest = []
    const [modalClassObj, setModalClassObj] = useState('');
    const [ShowTogglerElement, setShowTogglerElement] = useState(false);
    
    // const [jwtToken, setJWTToken] = useState('eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwYWx1dG92YS5lbGVuYUBnbWFpbC5jb20iLCJ1c2VySWQiOiIzOGMxMTI1MC1jOTRmLTExZWQtOWIxNS1kZDJkYWM1MDU0OGYiLCJzY29wZXMiOlsiVEVOQU5UX0FETUlOIl0sInNlc3Npb25JZCI6ImM4YzQxN2U4LTI1Y2YtNGVlMC1iNGJiLWYwY2Y0YjBhZWUzOSIsImlzcyI6InRoaW5nc2JvYXJkLmlvIiwiaWF0IjoxNjk4MTUzMzY3LCJleHAiOjE2OTk5NTMzNjcsImZpcnN0TmFtZSI6ImVsZW5hIiwibGFzdE5hbWUiOiJwYWx1dG92YSIsImVuYWJsZWQiOnRydWUsInByaXZhY3lQb2xpY3lBY2NlcHRlZCI6dHJ1ZSwiaXNQdWJsaWMiOmZhbHNlLCJ0ZW5hbnRJZCI6IjM2ZWI4ZjAwLWM5NGYtMTFlZC05YjE1LWRkMmRhYzUwNTQ4ZiIsImN1c3RvbWVySWQiOiIxMzgxNDAwMC0xZGQyLTExYjItODA4MC04MDgwODA4MDgwODAifQ.GlDiNNDI9QX-k2fG1SVDsfz4KOZ_MWhhYYLCWawitempmQCq7i3TTt3qSzBuubdiFAquCTSPKXvO3kN7Z4JNdw');
    const [jwtToken, setJWTToken] = useState('');
    const [getJWTTokenFlag, setJWTTokenFlag] = useState(true);
    const [socketUrl, setSocketUrl] = useState(`${connectionPath}/telemetry?token=${jwtToken}`);
  
    // Создал отдельный состояние для токена
    const [jwtTokenCorrect, setJwtTokenCorrect] = useState(null);
  
    const [messageHistory, setMessageHistory] = useState([]);
    const [lastMessage, setLastMessage] = useState([]);
    
    const ws = useRef(null);
    
    const checkStatus = (pulse, saturation, battery) => {
      let localStatus = status;
      // if (number === '1-10') {
      //   console.log(` ............. before status ${localStatus} in ${number} `);
      // }
      
      if (indicators) {
        const d = new Date;
  
        const isLowBattery = parseInt(battery) < parseInt(indicators.battery_min);
  
        if (isLowBattery)  {  
          localStatus = 'low_battery'; 
          setStatusClass(styles.PositionManImageLow);
        } 
  
        const isPulseWarn = (parseInt(pulse.val) < parseInt(indicators.pulse_warn_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_warn_max));
        const isSaturationWarn = (parseInt(saturation.val) < parseInt(indicators.saturation_warn_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_warn_max));
        
        if  (isPulseWarn || isSaturationWarn) {
          localStatus = 'warning';
          setStatusClass(styles.PositionManImageWarning)
        }
        
        const isPulsePanic = ((parseInt(pulse.val) < parseInt(indicators.pulse_panic_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_panic_max)));
        const isSaturationPanic = ((parseInt(saturation.val) < parseInt(indicators.saturation_panic_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_panic_max)));
        
        if ( isPulsePanic || isSaturationPanic ) {
          localStatus = 'panic';
          // showModal = false;
          setStatusClass(styles.PositionManImagePanic)
        }
  
        if (!(isPulseWarn || isSaturationWarn || isPulsePanic || isSaturationPanic || isLowBattery)) {
          localStatus = '';
          // Нельзя менять состояние useState напрямую, React не сможет отследить изменение и не совершит рендер. Закоментил.
          // showModal = false;
        }
        
      }
      
           
      return localStatus;
    }
 
  
    // Меняем socket url при изменении jwt токена
    const socketUrlCorrect = useMemo(() => `${connectionPath}/telemetry?token=${jwtTokenCorrect}`, [jwtTokenCorrect]);
  
    function GetJWTTOken(ev) {
        axios.post(tokenPath, tokenParams, { 
          // withCredentials: true, 
          // origin: true, 
          headers: { 
          // 'Content-Type': 'multipart/form-data',
          // 'X-CSRF-Token': token,
          // "Authorization": token,
          'Access-Control-Allow-Origin': true,
          "Access-Control-Allow-Methods": '*',
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          'Accept': 'application/json'
        } },
        ).then(response => {
          if (response.status === 200) {
            setJwtTokenCorrect(response.data.token);
            // setJWTToken((jwtToken) => { 
            //   return response.data.token;
            // });
            // setSocketUrl(socketUrl => {
            //   return `${connectionPath}/telemetry?token=${jwtToken}`;
            // });
            // setJWTTokenFlag(false);
          } else {
            console.log('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
            console.log(error);
        });
    }
    
    useEffect(() => {
      // При рендере компоненты идем за токеном если его нет
      if (!jwtTokenCorrect) {
        GetJWTTOken();
      }
    }, []);
  
    const sendError = (error) => {
      // TODO uncomment it after
      // axios.post('/send-error-msg', { data: error }, { headers: { 
      //   'Accept': 'application/json',
      //   'X-CSRF-Token': token
      //  } },
      //  ).then(response => {
      //    if (response.status === 200) {
      //     console.log(response.message);
      //    } else {
      //      console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      //    }
      //  }).catch(error => {
      //     console.log(error);
      //  });
    }
  
    const [isPaused, setIsPaused] = useState(false);
  
    // Вынес разбор данных из сообщения в отдельную ф-ию
    const parseMessage = (msg) => {
      
      if (!msg) return;
      
      const result = JSON.parse(msg);
      const devId = curDevice.identifier;
      if (!result.errorCode && (result.errorCode !== 2) && (result.errorCode !== 1)) {
        if (result.data.status_battery_level) {
          setBattery(parseInt(result.data.status_battery_level[0][1]))
          // setBattery(`${result.data.status_battery_level[0][1]} в ${new Date(result.data.status_battery_level[0][0]).toLocaleTimeString()} `);
        }
        const temperature_date = new Date(result.latestValues.temperature).toLocaleTimeString();
        const temperature = result.data && result.data.temperature ? { time: new Date(result.data.temperature[0][0]).toLocaleTimeString(), val: result.data.temperature[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        // console.log(` ${temperature_date} в ${temperature.time} ${temperature.val}`);
  
        const saturation_date = new Date(result.latestValues.SpO2).toLocaleTimeString();
        const saturation = result.data && result.data.SpO2 ? { time: new Date(result.data.SpO2[0][0]).toLocaleTimeString(), val: result.data.SpO2[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        const saturation_valid = result.data && result.data.Valid_SpO2 ? { time: new Date(result.data.Valid_SpO2[0][0]).toLocaleTimeString(), val: result.data.Valid_SpO2[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        const pulse_date = new Date(result.latestValues.HR).toLocaleTimeString();
        const pulse = result.data && result.data.HR ? { time: new Date(result.data.HR[0][0]).toLocaleTimeString(), val: result.data.HR[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        const pulse_valid = result.data && result.data.Valid_HR ? { time: new Date(result.data.Valid_HR[0][0]).toLocaleTimeString(), val: result.data.Valid_HR[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        const rssi_date = new Date(result.latestValues.rssi).toLocaleTimeString();
        const rssi =  result.data && result.data.rssi ? { time: new Date(result.data.rssi[0][0]).toLocaleTimeString(), val: (-1)*result.data.rssi[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        const position_date = new Date(result.latestValues.Area_ID).toLocaleTimeString();
        const position =  result.data && result.data.Area_ID ? { time: new Date(result.data.Area_ID[0][0]).toLocaleTimeString(), val: result.data.Area_ID[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
        
        let localStatus = checkStatus(pulse, saturation, battery);
        const cur_sector = sectors && sectors.find((s)=>{return (s.sector == position.val)})
        
        positionCoordsLatest =  [ ...positionCoordsLatest, position.val].slice(-1*4);
        
        const CheckIfCountOfArrayCorrect = (numbers) => {
          // изменение координаты произойдет только в том случае если 3 раза придет значение сектора
          // let avg = positionCoordsLatest.length ? positionCoordsLatest.reduce((a, b) => (parseInt(a) + parseInt(b))) / positionCoordsLatest.length : -1;
          // let median = positionCoordsLatest.sort((a,b) => parseInt(a) - parseInt(b))[3]
          // median === parseInt(position.val)
          // в массиве из 4х чисел встречается чаще 2х раз
          const countItems = {}; 
          for (const item of numbers) {
            countItems[item] = countItems[item] ? countItems[item] + 1 : 1;
          }
          // обрабатываем ключи объекта, отфильтровываем все, что меньше 1
          const result = Object.keys(countItems).filter((item) => countItems[item] > 2);
          return result.length > 0
        }
        if (cur_sector && CheckIfCountOfArrayCorrect(positionCoordsLatest)) {
          setPositionCoords(cur_sector.map_point);
          // console.log(` ${number} ......... ${position.val} ................ ${cur_sector.map_point} ......... `);
        }
        
        setStatus(localStatus);
  
        setTestDataSaturation(testDataSaturation => {
          let v = [ ...testDataSaturation, saturation.val];
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });
  
        setTestDataPulse(testDataPulse => {
          let v = [ ...testDataPulse, pulse.val];
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });
  
        setTestLabels(testLabels => {
          let l = [ ...testLabels, saturation.time];
          l.map(item => item.group).slice(-1*maxArrayLength);
          return l;
        });
        
        Global.changeDeviceStatus({ ident: device.identifier, status:localStatus, battery: battery, ward: number, patient: `${device.patient.last_name} ${device.patient.first_name}` })
        // let currentParams = Global.showDevice(device.identifier);
        // if (currentParams.ward === '1-6') {
        //   console.log('from Graphic Item', (new Date).toLocaleTimeString(), currentParams.battery);
        // }
  
        setMessageHistory((prev) => prev.concat(lastMessage));
  
      } else {
        sendError(`${result.errorMsg} deviceId = ${devId}`);
      }
    }
  
    const [isConnect, setIsConnect] = useState(false);

    const style = {
      left: `${testDataPosition[0]}%`,
      top: `${testDataPosition[1]}%`
    }
  
    const createWebsocketConnect = () => {
        ws.current = new WebSocket(socketUrlCorrect); // создаем ws соединение
  
        ws.current.onopen = () => { 
          console.log("Соединение открыто", ws.current.readyState); 
  
          setIsConnect(true);
  
          const dataMsg = JSON.stringify(object);
  
          if (ws.current.readyState === 1) {
            ws.current.send(dataMsg);
            // console.log("Message is sent: " + dataMsg);
          }
          
        }	
        // callback на ивент открытия соединения
  
        ws.current.onclosing = () => {
          console.log("Соединение закрывается .... "); 
        }
        ws.current.onclose = (event) => {
          console.log("Соединение закрыто");
  
          setIsConnect(false);
  
          if (event.code === 1006) {
            console.log(' ..... inside event.code===1006  ..... ');
            GetJWTTOken();
          }
  
        } // callback на ивент закрытия соединения
        ws.current.onmessage = (msg) => {
          // console.log('from Position Item 1 ', (new Date).toLocaleTimeString(), msg);
          parseMessage(msg.data);
        }
    }
  
    // console.log(lastMessage);
  
    useEffect(() => {
      if (jwtTokenCorrect) {
        if (!isPaused) {
          createWebsocketConnect();
        } else {
          console.log(' !!!!!!!!!!!!!!!!!!!!! ');
        }
      }
  
    }, [jwtTokenCorrect]);
    
    useEffect(() => {
     
    }, [lastMessage, setMessageHistory, setPositionCoords]);
  
    const object = {
      tsSubCmds: [
         {
            entityType: "DEVICE",
            entityId: device.identifier,
            scope: "LATEST_TELEMETRY",
            cmdId: ind
          } 
      ],
      historyCmds: [],
      attrSubCmds: []
    };
    
    const connectionStatus = {
       [0]: 'Connecting',
       [1]: 'Open',
       [2]: 'Closing',
       [3]: 'Closed',
    }[readyState];
  
    const curItem = useRef();
    const curItemCard = useRef();
    const [leftSide, setLeftSide] = useState(false);
      
    useEffect(() => {
      if (status=== 'low_battery' && showModal) {
        const leftSide = ((curItem.current.getBoundingClientRect().left - curItem.current.closest(".ward-wrap")?.getBoundingClientRect().left - curItem.current.closest(".ward-wrap")?.offsetWidth/2) > 0);
        setLeftSide(leftSide);
      }
    },[curItem, curItemCard, showModal, status]);
    
    return (
      <>
        <CirclePosition  positionCoords={positionCoords} status={status} index={ind} number={number} />
        {/* { status ? <ManSad positionCoords={positionCoords} status={status} /> :  <ManSmiley  positionCoords={positionCoords}  /> } */}
      </>
    );
};

export default DinamicGraphicPosition;