import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import DinamicWard from './DinamicWard.jsx';
import axios from 'axios';

const DinamicWardList = ({wards, norms, allowBtns = false }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [activeItems, setActiveItem] = useState([]);
  const HandleCardToggle = (key) => {
    activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
  }
  const wardRefs = useRef([]);

  const SendRequest = function(status, ident) {
    axios.post(`/device-simulator`, { status:status, device: ident}, { headers: { 
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    } },
    ).then(response => {
      if (response.statusText !== 'OK') {
        console.log(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
      console.log(error);
    });
  }
  const startPanic = function(ev, w) {
    ev.preventDefault();
    SendRequest('panic', w.appliances[0].identifier);
  }
  const startWarning = function(ev, w) {
    ev.preventDefault();
    SendRequest('warning', w.appliances[0].identifier);
  }
  const startLowBattery = function(ev, w) {
    ev.preventDefault();
    SendRequest('low_battery', w.appliances[0].identifier);
  }
  
  useEffect(() => {
    function handleClickOutside(event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      if (wardRefs.current) {
        if (wardRefs.current.includes(event.target) || wardRefs.current.includes(event.target.parentElement)) {
          const key = wardRefs.current.indexOf(event.target.parentElement);
          activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
        } else {
          setActiveItem([]);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wardRefs]);
   
  return (
    <div>
      {
        wards && wards.length && allowBtns > 0 && <div>
            <button className="btn-small is--red" onClick={(ev) => { startPanic(ev, wards[0])} }>Start Panic {wards[0].number}</button>
            <button className="btn-small" onClick={(ev) => { startWarning(ev, wards[1]) } }>Start Warning {wards[1].number}</button>
            <button className="btn-small is--green" onClick={(ev) => { startLowBattery(ev, wards[0]) } }>Set Low Battery {wards[0].number}</button>
          </div>
      }
      <div className="ward-wrap">
        {
          wards && wards.map((w, i) => {  
            return <div key={i} ref={(el) => (wardRefs.current[w.key] = el)} onClick={(ev) => { HandleCardToggle(w.key) }}>
              <DinamicWard ind={i} currentStatus={w.status} info={w.info} number={w.number} showModal={activeItems.includes(w.key)}  deviceIds={w.appliances} norms={norms} />
            </div>
          })  
        }
      </div>
    </div>
  );
};

export default DinamicWardList;