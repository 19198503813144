import React, {useState} from 'react';
import styles from './Patient.module.scss';
import Patient from './Patient.jsx';
import PatientForm from './PatientForm.jsx';
import AddPatient from './AddPatient.jsx';
import axios from 'axios';
import classNames from "classnames";

const PatientList = ({patients, wards, doctors, permission, appliances, current_doctor = null}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  patients = patients.map((item)=> { if (item.ward.length === 0) { item.ward.push('N-N') }  return item });
  const [patientsList, setPatientsList] = useState(patients);
  const [editableComponents, setEditableComponents] = useState([]);

  const permissions = JSON.parse(permission);
  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }
  
  const refreshPatient = (j, params) => {
    setPatientsList(patientsList => {
      return patientsList.filter((item)=> { 
          if ((item.id === params.id) && (item.discharge !== params.discharge)) {
            console.log(`убрать из списка ${item.id} ${params.id} ${item.first_name} ${item.last_name}  ${params.first_name} ${params.last_name}`)
          } else {
            return item.id !== params.id || ((item.id === params.id) && (item.discharge === params.discharge))
          }
        } ).map((patient, i) => {
        if (patient.id === params.id) {
          patient = params;
        }
        return patient
      });
    });
    
  }

  const AddNewPatient = (params) => {
    setPatientsList(patientsList => {
      return [ ...patientsList, params ];
    });
  }
  
  const deleteItem = (i) => {
    const patient = patientsList[i];
    let confirmation = confirm(`Уверены что хотите удалить ${patient.last_name} ${patient.first_name}?`);
    if (confirmation) {
      axios.post(`/patients/${patient.id}/delete`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          setPatientsList(patientsList => {
            return patientsList.filter(item => item.id !== patient.id);
          });
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
  }
  
  const result = patientsList.sort((a, b) => { 
    return (parseInt(b.ward[0].replace('-','')) - parseInt(a.ward[0].replace('-',''))) > 0 ? -1 : 1
  }).map((patient, i) => {
    if (editableComponents.includes(i) ) { 
      return <PatientForm key={i} index={i} patient={patient} openForm={openForm} refreshPatient={refreshPatient} wards={wards} doctors={doctors} permissions={permissions} appliances={appliances} /> }
    else { 
      return <Patient key={i} index={i} patient={patient} openForm={openForm} deleteItem={deleteItem} permissions={permissions} isLast={(i === (patientsList.length - 1))} />
    }  
  });
  
  // const result = patientsList.map((patient, i) => {
  //   if (editableComponents.includes(i) ) { 
  //     return <PatientForm index={i} patient={patient} openForm={openForm} refreshPatient={refreshPatient} wards={wards} doctors={doctors} /> }
  //   else { 
  //     return <Patient index={i} patient={patient} openForm={openForm} deleteItem={deleteItem}/>
  //   }  
  // });

  return (
    <ul className="">
      <li className={classNames(styles.PatientListItemNoBrd, styles.PatientListHeader)} key={-2}>
        <span>палата</span>
        <span>ФИО</span>
        <span className="center-text">Возраст</span>
        <span>Устройства</span>
        <span className="center-text">Пол</span>
        <span></span>
      </li>
      { result }
      
      { permissions.create ? <AddPatient index='new'  AddNewPatient={AddNewPatient} wards={wards} doctors={doctors} currentDoctor={current_doctor} /> : <></> }
    </ul>
  );
};

export default PatientList;