import React from 'react';
import styles from './Profile.module.scss';
import Cogwheel from '../../../assets/images/cogwheel.svg';
import classNames from 'classnames';

const Profile = ({user, openForm, deleteItem, permissions, unconfirmed, patients }) => {
    const role_option = [
        { label: "Администратор", value: "0" },
        { label: "Персонал", value: "1" },
        { label: "Техник", value: "2" }
      ]
    // user.unconfirmed_phone
    return (
      <div className={styles.ProfileWrap}>
        <ul  className={styles.ProfileList}>
          <li className={styles.ProfileListItem}>
            <span>ФИО:</span>
            <span>{user.name}</span>
          </li>
          <li className={styles.ProfileListItem}>
            <span>Роль:</span>
            <span>{role_option.filter((r) => { if (user.role.split(",").includes(r.value)) { return r} }).map((k) => k.label).join(',')}</span>
          </li>
          <li className={styles.ProfileListItem}>
            <span>Должность:</span>
            <span>{user.post}</span>
          </li>
          <li className={(user.unconfirmed_phone) ? styles.ProfileListItemPhone : styles.ProfileListItem }>
            <span>Телефон:</span>
            <span>{user.phone}</span>
            {(user.unconfirmed_phone) ? <span className={styles.PanicIco} title="Телефон не подтвержден, кликни чтобы исправить." onClick={()=> { makePhoneCall(user.phone, 'Внимание! На указанный номер телефона совершается автоматический звонок. Пожалуйста, для подтверждения телефона следуйте инструкциям!', 'Произошли ошибки. Пожалуйста повторите попытку позже.') }}>!</span> : <></>}
          </li>
          <li className={styles.ProfileListItem}>
            <span>Email:</span>
            <span>{user.email}</span>
          </li>
          <li className={styles.ProfileListItem}>
            <span>Telegram Chat ID:</span>
            <span>{user.telegram}</span>
          </li>
        </ul>
        <div className={styles.ProfileCounterWrap}>
          {unconfirmed ? <div className={styles.ProfileCounterItem}>
            <div className={styles.ProfileCounterNum}>{unconfirmed}</div>
            <div className={styles.ProfileCounterText}>неподтвержденных аккаунтов</div>
          </div>: <></>}

          {patients ? <div className={styles.ProfileCounterItem}>
            <div className={styles.ProfileCounterNum}>{patients}</div>
            <div className={styles.ProfileCounterText}>прикрепленных пациентов</div>
          </div> : <></>}

        </div>
                
        { <span className="link-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(true) }}> <img src={Cogwheel} alt="edit" /> <span className="link">Редактировать</span> </span>  }
        { permissions.delete ? <span className="link" onClick={(ev)=>{ ev.preventDefault(); deleteItem() }}>Удалить</span> : <></> }
      </div>
      
    );
};

export default Profile;