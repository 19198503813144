import classNames from 'classnames';
import React from 'react';
import styles from './Position.module.scss'; 

const ManSad = ({ positionCoords, status }) => {
  const coords = (typeof(positionCoords) === 'string') ? JSON.parse(positionCoords) : positionCoords;
  let curClass;
  switch(status) {
    case 'panic':
      curClass = styles.PositionManImagePanic;
      break;
    case 'warning':
      curClass = styles.PositionManImageWarning;
      break;
    case 'low_battery':
      curClass = styles.PositionManImageLow;
      break;
    default:
      curClass = styles.PositionManImageTest;
  }
  return (
    <>
      { 
        coords.x && coords.y ? 
          <svg className={classNames("man-ico", curClass)} width="30px" height="30px" overflow="hidden" version="1.1" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg" x={coords.x-15} y={coords.y-15} xmlnsXlink="http://www.w3.org/1999/xlink"><path d=" M 68.5 47 C 68.1 47 67.8 47 67.4 47.1 C 67.1 45.9 66.3 44.8 65 44.3 L 56.5 40.9 L 51 28.2 L 51 28.2 C 49.9 25.2 47 23 43.5 23 C 41.7 23 40.1 23.6 38.8 24.5 L 38.8 24.5 L 25.3 33.6 C 24.5 34.2 23.9 35 23.6 36 L 20.6 49 C 20.1 51.2 21.4 53.3 23.6 53.8 C 23.9 53.9 24.2 53.9 24.5 53.9 C 26.3 53.9 28 52.6 28.4 50.8 L 31 39.4 L 33.5 37.7 C 32.5 44.5 32.4 49.9 32.4 50.8 L 31.4 68.3 L 27.5 86 C 27 88.2 28.4 90.3 30.5 90.8 C 30.8 90.9 31.1 90.9 31.4 90.9 C 33.2 90.9 34.9 89.6 35.3 87.8 L 39.3 69.8 C 39.3 69.6 39.4 69.4 39.4 69.2 L 39.9 59.4 L 46.4 66.6 L 47.3 85.2 C 47.4 87.3 49.2 89 51.3 89 C 51.4 89 51.4 89 51.5 89 C 53.7 88.9 55.4 87 55.3 84.8 L 54.3 64.8 C 54.3 63.9 53.9 63 53.3 62.3 L 46.3 54.6 L 46.3 38.4 C 46.3 38.4 46.4 38.4 46.4 38.3 L 49.6 45.6 C 50 46.6 50.8 47.3 51.8 47.7 L 61.7 51.7 C 61.4 52.4 61.3 53.2 61.3 54 C 61.3 56.2 62.3 58.1 63.9 59.4 C 63.9 59.4 64 59.5 64 59.5 C 64.3 59.8 64.8 60 65.3 60 C 66.4 60 67.3 59.1 67.3 58 C 67.3 57.3 67 56.7 66.5 56.4 C 66 55.8 65.5 55 65.5 54 C 65.5 52.3 66.8 51 68.5 51 C 70.2 51 71.5 52.3 71.5 54 L 71.5 60.3 L 71.5 63 L 71.5 89 C 71.5 90.1 72.4 91 73.5 91 C 74.6 91 75.5 90.1 75.5 89 L 75.5 54 C 75.5 50.1 72.4 47 68.5 47 Z"/><circle cx="49" cy="14" r="8"/></svg> 
          : <></> }
    </> );
};
  
export default ManSad;