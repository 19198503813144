import React, {useState, useRef} from 'react';
import classNames from "classnames";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

const CustomSlider = ({customClass, min, max, value, labelText, inputNameMin, inputNameMax, setParentValues = null, step = 1 }) => {
    const [rangeCurrent, setRangeCurrent] = useState(value);
    const [rangeFirstForm, setRangeFirstForm] = useState(false);
    const [rangeSecondForm, setRangeSecondForm] = useState(false);
    if (setParentValues) {
      setParentValues(rangeCurrent)
    }
    const setRangeFormInput = (ev, setRange, index) => {
      let input = ev.target;
      let val = input.value;
      if (parseInt(val) < parseInt(min) ) {
        input.value = parseInt(min);
      }
      if ((parseInt(val) > parseInt(max))) {
        input.value = parseInt(max);
      }
      
      setRange(false);

      if (index === 0) {
        if (parseInt(input.value) > rangeCurrent[1]) {
          setRangeCurrent([rangeCurrent[0], parseInt(input.value)]);
        } else {
          setRangeCurrent([parseInt(input.value), rangeCurrent[1]]);
        }
      }

      if (index === 1) {
        if (parseInt(input.value) < rangeCurrent[0]) {
          setRangeCurrent([parseInt(input.value), rangeCurrent[1]]);
        } else {
          setRangeCurrent([rangeCurrent[0], parseInt(input.value)]);
        }
      }
    }

    return (
        <div className={classNames("custom-range-slider-input", customClass)}>
            <label className="custom-range-slider_label">{labelText}</label>
            <input type="hidden" name={inputNameMin}  value={rangeCurrent[0]} /> 
            <input type="hidden" name={inputNameMax}  value={rangeCurrent[1]} /> 
            <span className="custom-range-slider_min">{min}</span>
            <span className="custom-range-slider_max">{max}</span>
            <RangeSlider min={min} max={max} value={rangeCurrent} onInput={setRangeCurrent} />
            { rangeFirstForm ? <input type="text" defaultValue={rangeCurrent[0]} className="custom-range-slider-input_short" onBlur={(ev)=> { setRangeFormInput(ev, setRangeFirstForm, 0); } }  onKeyDown={(ev)=> { if (ev.keyCode === 13) {  setRangeFormInput(ev, setRangeFirstForm, 0); } }} /> : <span onClick={(ev)=> { setRangeFirstForm(true) } }>{rangeCurrent[0]}</span>}
            —
            { rangeSecondForm ? <input type="text" defaultValue={rangeCurrent[1]} className="custom-range-slider-input_short" onBlur={(ev)=> { setRangeFormInput(ev, setRangeSecondForm, 1); } }  onKeyDown={(ev)=> { if (ev.keyCode === 13) {  setRangeFormInput(ev, setRangeSecondForm, 1); } }} /> : <span onClick={(ev)=> { setRangeSecondForm(true) } }>{rangeCurrent[1]}</span>}
            
        </div>
    );
};

export default CustomSlider;