import React from 'react';
import styles from './Department.module.scss';

const Department = ({department, index, openForm, deleteItem}) => {
  return (
    <li key={index} className={styles.DepartmentsListItem} >
            <span>{department}</span>
            <button className="btn-small is--green" onClick={(ev)=>{ ev.preventDefault(); openForm(index, true) }}>Редактировать</button>
            <span></span>
            {/* <button className="btn-small is--red" onClick={(ev)=>{ ev.preventDefault(); deleteItem(index) }}>Удалить</button> */}
          </li>
    
  );
};

export default Department;