import React, { useState, useRef, useEffect }  from 'react';
import styles from './Doctor.module.scss';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import Switch from "react-custom-checkbox/switch";
import Qwe from '../../../assets/images/qwe.svg';

const AddDoctor = ({ AddNewDoctor }) => {
    const [openFormNewDoctor, setOpenFormNewDoctor] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [hintMsg, setHintMsg] = useState('');
    const curForm = useRef();
    const tooltipeWrap = useRef();

    const [showTooltipe, setShowTooltipe] = useState(false);
    
    const [doctorAlert0, setDoctorAlert0] = useState(false);
    const [doctorAlert1, setDoctorAlert1] = useState(false);
    const [doctorAlert2, setDoctorAlert2] = useState(false);

    const [doctorCall0, setDoctorCall0] = useState(false);
    const [doctorCall1, setDoctorCall1] = useState(false);

    const role_option = [
      { label: "Администратор", value: "0" },
      { label: "Персонал", value: "1" },
      { label: "Техник", value: "2" }
    ]
    const [selected, setSelected] = useState([role_option[1]]);

    const select_localization = {
      "allItemsAreSelected": "Выбраны все элементы.",
      "clearSearch": "Очистить поиск",
      "clearSelected": "Очистить выбранное",
      "noOptions": "Без вариантов",
      "search": "Поиск",
      "selectAll": "все",
      "selectAllFiltered": "Выбрать всех (отфильтровано)",
      "selectSomeItems": " -- Выберете роли -- ",
      "create": "Создавать",
    };
    
    const checkTgId = (ev) => {
      let curVal = ev.target.value;
      curVal = curVal.replace(/[^0-9]+/g, "");
      if ((curVal.length < 5) || (curVal.length > 12)) {
        setErrorMsg('Исправьте ошибки в поле Telegram');
        ev.target.classList.add('with-error');
      }
      ev.target.value = curVal
    }

    const checkMask = (ev) => {
      // console.log(' ... checkMask ... ', ev._reactName);
      let mask = "+* (***) *** ** **";
      let curVal = ev.target.value;
      if (curVal.startsWith('+')) {
        curVal = curVal.replace('+7', '7');
      }
      if (curVal.startsWith('8')) {
        curVal = curVal.replace('8', '7');
      }
      curVal = curVal.replace(/[^0-9]+/g, "");

      if (curVal.startsWith('2')) {
        // для Хабаровска
        if (curVal.length === 6) {
          curVal = '74212'+curVal;
        }
        // Для Красноярска
        if (curVal.length === 7) {
          curVal = '7391'+curVal;
        }
        //
      }

      let i = 0;
      let result = mask.replaceAll("*", () => curVal[i++] || "");
      if (result.length !== 17) {
        if (result === '()--') { result = '' }
        setErrorMsg('Исправьте ошибки в поле телефон');
        ev.target.classList.add('with-error');
      } else {
        setErrorMsg('');
        ev.target.classList.remove('with-error');
      }
      ev.target.value = result
    }

    const clearMessage = (ev) => {
      setErrorMsg('');
      ev.target.classList.remove('with-error');
    }

    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      return false;
    }

    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      const HasError = CheckValidForm(curForm.current);
      if (!HasError) {
        setHintMsg('... загрузка  ...')
        axios.post(`/doctors/new`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setHintMsg(response.data.message);
            AddNewDoctor(response.data.updated);
          } else {
            console.log(response?.responseText && response.responseText);  
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error?.request?.responseText && error.request.responseText);
          console.log();
          let msg = JSON.parse(error.request.responseText);
          let subMsg; 
          if (msg.split('{:email=>["').length > 0) {
            subMsg = msg.split('"]}') ? msg.split('"]}')[0] : "";
          }
          setErrorMsg(`Произошли ошибки, пожалуйста попробуйте позже.${subMsg}`);
        })
        .finally(() => { 
          setTimeout(() => {
            setOpenFormNewDoctor(false); 
          }, 5000);
        });
      } 
      
    }

  const ClearAndOpenForm = () => {
    if (curForm.current) {
      curForm.current.reset();
      // const select_ward = curForm.current.querySelector('select[name="patient[ward]"]');
      // select_ward.value = 'true';
    }
    setSelected([role_option[1]]);
    setHintMsg('');
    setErrorMsg('');
    setOpenFormNewDoctor(true);
  }
  // for checkbox
  const checkedTrackStyle = {
    opacity: 1,
    background: 'linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2,#FF4E4E) 96.67%)',
    transition: 'all 0.25s ease-in-out',
  }
 
  const checkedIndicatorStyle = {
    transform: 'translateX(1rem)',
  }
 
  const checkedIconStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
  }
 
  const indicatorStyle = {
    alignItems: 'center',
    background: '#ffffff',      // red
    borderRadius: '1.126rem',
    bottom: '0.25rem',
    display: 'flex',
    height: '1.126rem',
    justifyContent: 'center',
    left: '0.25rem',
    outline: 'solid 2px transparent',
    position: 'absolute',
    transition: '0.25s',
    width: '1.126rem',
  }
 
  const trackStyle = {
    background: '#CAD1D8',        //gray
    border: '1px solid #CAD1D8',
    borderRadius: '0.93rem',
    cursor: 'pointer',
    display: 'flex',
    height: '1.75rem',
    marginRight: '0.75rem',
    position: 'relative',
    width: '2.75rem',
  }

  useEffect(() => {
    if (tooltipeWrap.current) {
      document.addEventListener( 'click', (e) => {
        const withinBoundaries = e.composedPath().includes(tooltipeWrap.current);
        if (( ! withinBoundaries ) && showTooltipe) {
          setShowTooltipe(false);
        }
      });
    }
  }, [tooltipeWrap, showTooltipe, setShowTooltipe]);
  // end for checkbox


  return (
    <li key='new' className={classNames(styles.NewDoctorWrapper, styles.DoctorListItemLast)}>
      { openFormNewDoctor ? <form ref={curForm} name="doctor" action={`/doctors/new`} acceptCharset="UTF-8" data-remote="true" method="post" className={styles.DoctorForm}>
          <div className="field-light">
            <label htmlFor='doctor[name]'>ФИO</label>
            <input type="text" name='doctor[name]' required onChange={clearMessage} placeholder="Иванов Иван Иванович"  />
          </div>
          <div className="field-light">
            <label htmlFor='doctor[phone]'>Роль</label>
            <input type="hidden" name='doctor[role]'  value={selected.map(v => { return v.value })} />
            <MultiSelect
              options={role_option}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              overrideStrings={select_localization}
            />
          </div>
          <div className="field-light">
            <label htmlFor='doctor[phone]'>Телефон</label>
            <div className={styles.DoctorFormInput}>
              <input type="text" name='doctor[phone]' required onChange={clearMessage} onBlur={checkMask} placeholder="+7 (222) 222-22-22" />
              <div className="field-with-checkbox_wrapper">
                <label className="field-with-checkbox">
                  <span>Звонить, если критическая ситуация</span>
                  <Switch
                    checked={doctorCall0}
                    onChange={() => setDoctorCall0(!doctorCall0)}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
                <label className="field-with-checkbox">
                  <span>Звонить, если нестабильная ситуация</span>
                  <Switch
                    checked={doctorCall1}
                    onChange={() => setDoctorCall1(!doctorCall1)}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-light">
            <label htmlFor='doctor[telegram]'>Telegram</label>
            <div className={styles.DoctorFormInput}>
              <div className={styles.ProfileTgInput}>
                <input type="text" name='doctor[telegram]' required onChange={clearMessage} onBlur={checkTgId}  placeholder="111111111" />
                <span  ref={tooltipeWrap}  className={classNames("tooltipe-wrap", styles.IcoQwe)} onClick={() => { setShowTooltipe(!showTooltipe)}}>
                  <img src={Qwe} /> 
                  { showTooltipe ? <div className="tooltipe tooltipe-left"> Укажите пожалуйста здесь свой чат-ID, <br/> для получения уведомлений от телеграм-бота. <br/> <a href="https://docs.google.com/document/d/1rd2NLaKIOaoj-niwUUBPf6Q9QzxJkgSZ47VOp0LnJaU/edit?usp=sharing" target="_blank">Как это сделать?</a></div> : <></> }
                </span>
              </div>
              <div className="field-with-checkbox_wrapper">
                <label className="field-with-checkbox">
                  <span>Сообщение, если критическая ситуация</span>
                  <Switch
                    checked={doctorAlert0}
                    onChange={() => setDoctorAlert0(!doctorAlert0)}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
                <label className="field-with-checkbox">
                  <span>Сообщение, если нестабильная ситуация</span>
                  <Switch
                    checked={doctorAlert1}
                    onChange={() => setDoctorAlert1(!doctorAlert1)}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
                <label className="field-with-checkbox">
                  <span>Сообщение, если низкий заряд батарейки</span>
                  <Switch
                    checked={doctorAlert2}
                    onChange={() => setDoctorAlert2(!doctorAlert2)}
                    indicatorStyle={indicatorStyle}
                    trackStyle={trackStyle}
                    checkedIconStyle={checkedIconStyle}
                    checkedIndicatorStyle={checkedIndicatorStyle}
                    checkedTrackStyle={checkedTrackStyle}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="field-light">
            <label htmlFor='doctor[post]'>Должность</label>
            <input type="text" name='doctor[post]' required onChange={clearMessage} placeholder="врач" />
          </div>
          <div className="field-light">
            <label htmlFor='user[email]'>Email</label>
            <input type="text" name='user[email]' required onChange={clearMessage} placeholder="example@gmail.com"  />
          </div>
          <div className="field-light">
            <label htmlFor='user[password]'>Пароль</label>
            <input type="password" name='user[password]' required onChange={clearMessage}  />
          </div>
          <div className="field-light">
            <label htmlFor='user[password_confirmation]'>Повторить пароль</label>
            <input type="password" name='user[password_confirmation]' required onChange={clearMessage}  />
          </div>
          
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
          <button type="reset" className="btn-border" onClick={(ev)=> { setOpenFormNewDoctor(false); }} ><span>Не сохранять</span></button>
        </form> : <div className={styles.DoctorsListItemNoBrd} >
          <span></span>
          <span></span>
          <span></span>
          <div className="center-text">
            <button className="btn-border btn-create" onClick={(ev)=>{ ClearAndOpenForm() }} > <span className="icon-plus">+</span><span>добавить сотрудника</span> </button>
          </div>
        </div>
      }
    </li>
  );
};

export default AddDoctor;