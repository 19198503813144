// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Test-module__Test___2sb1E{background:red}", "",{"version":3,"sources":["/mnt/c/Users/elena/Documents/projects/kardio2/kardio/app/javascript/components/tests/Test.module.scss"],"names":[],"mappings":"AAAA,2BACI,cAAA","file":"Test.module.scss","sourcesContent":[".Test {\r\n    background: red;\r\n}"]}]);
// Exports
exports.locals = {
	"Test": "Test-module__Test___2sb1E"
};
module.exports = exports;
