import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import styles from './Battery.module.scss';

const BatteryLevel = ({val, norms, isDark = false, showToolTipe = true, showToolTipeInCharge = false }) => {
  
  const isLow = (val && norms && (parseInt(val) < (parseInt(norms) + parseInt(norms)*0.25)));
  const isExtremelyLow = val && norms && (parseInt(val) < parseInt(norms)) || !val;
  const [toolTipeIsOpen, setToolTipeIsOpen] = useState();
  
  return (
    <span className={showToolTipe ? styles.BatteryLevelToolTipeWrap : ''}>
      { showToolTipeInCharge ? <span className={classNames(styles.BatteryLevelToolTipe, toolTipeIsOpen ? styles.BatteryLevelToolTipeOpen : '')}> Заряжается </span> : <></>}
      { showToolTipe && !showToolTipeInCharge ? <span className={classNames(styles.BatteryLevelToolTipe, toolTipeIsOpen ? styles.BatteryLevelToolTipeOpen : '')}>{val && val}</span> : <></> }
      <span className={classNames(isDark ? styles.BatteryLevelDark : styles.BatteryLevel, isExtremelyLow ? styles.ExtremelyLow : (isLow ? styles.Low : ''))} 
            onMouseEnter={() => { setToolTipeIsOpen(true) }}
            onMouseLeave={() => { setToolTipeIsOpen(false) }} ></span>
    </span>
   
  );
};

export default BatteryLevel;