import React, { useState, useRef }  from 'react';
import styles from './Indicators.module.scss';
import axios from 'axios';


const IndicatorForm = ({indicator, index, openForm, refreshIndicator, permissions }) => {
  const [errorMsg, setErrorMsg] = useState('')
    const [hintMsg, setHintMsg] = useState('')
    const curForm = useRef()
    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    
    const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      return false;
    }

    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      const HasError = CheckValidForm(curForm.current);
      if (!HasError) {
        setHintMsg('... загрузка  ...')
        axios.post(`/indicators/${indicator.id}/update`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setHintMsg(response.data.message);
            refreshIndicator(index, response.data.updated);
            openForm(index, false);
          } else {
            setHintMsg('');
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error);
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        })
        // .finally(() => {  });
      }
  }

  return (
    <li key={index}>
      <form ref={curForm} name="indicator" action={`/indicators/${indicator.id}/update`} acceptCharset="UTF-8" dataRemote="true" method="post"  className={styles.IndicatorForm}>
        <div className="field-light">
          <label className="max">{indicator.name}</label>
          <input type="text" required name='indicator[value]' defaultValue={indicator.value} />
        </div>
        <p className='errors'>{errorMsg}</p>
        <p className='hint'>{hintMsg}</p>
        <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
        <button type="reset" className="btn-border"onClick={(ev)=> { openForm(index, false); }} ><span>Не сохранять</span></button>
      </form>
    </li>
  );
};

export default IndicatorForm;