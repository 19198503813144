

import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import styles from './MonoFilters.module.scss';
import CustomSlider  from '../elements/CustomSlider.jsx';
import CustomSliderWards from '../elements/CustomSliderWards.jsx';

const MonoFiltersSmall = ({showSex = false, showWard = false, showAge = false, departmentFilter = false, showCustomer = false, resultHandler}) => {
  const paramsFromUrl = new URLSearchParams(window.location.search);
  
  const [withoutCustomer, setWithoutCustomer] = useState(paramsFromUrl.get('customer') && (paramsFromUrl.get('customer') === 'none'));
  const [sexFilter, setSexFilter] = useState(paramsFromUrl.get('sex-filter') ? parseInt(paramsFromUrl.get('sex-filter')) : 2);
  
  const [wardValues, setWardValues] = useState((paramsFromUrl.get('ward-from')) && (paramsFromUrl.get('ward-to')) ? [paramsFromUrl.get('ward-from').replace('-', ''), paramsFromUrl.get('ward-to').replace('-', '')] : ['300', '305']);

  const setWardValuesHandler = (vals) => {
    setWardValues(() => { return vals });
  }

  const [ageValues, setAgeValues] = useState((paramsFromUrl.get('age-from')) && (paramsFromUrl.get('age-to')) ? [paramsFromUrl.get('age-from'), paramsFromUrl.get('age-to')] : [14, 88]);

  const setAgeValuesHandler = (vals) => {
    setAgeValues(vals)
  }

  const setSexFilterHandler = (val) => {
    // console.log('val', val, 'sexFilter', sexFilter);
    if (sexFilter === val) {
      setSexFilter(2);
    } else {
      setSexFilter(val);
    }
    
  }

  const searchHandler = () => {
    let options = {}
    
    if (showSex && sexFilter!=2) {
      options['sex-filter'] = sexFilter;
    }

    if (showAge) {
      options['age-from'] = ageValues[0];
      options['age-to'] = ageValues[1];
    }

    if (showWard) {
      const w1 = wardValues && wardValues[0].toString()[0] +'-'+ wardValues[0].toString().substring(1);
      const w2 = wardValues && wardValues[1].toString()[0] +'-'+ wardValues[1].toString().substring(1);
      options['ward-from'] = w1
      options['ward-to'] = w2;
    }

    if (showCustomer && withoutCustomer) {
      options['customer'] = 'none';
    } 

    eval(resultHandler)(options);

  }
  
  return (
    <div className="with-filter_filter">
      <div className="h2-title">Поиск:</div>
      {
        showWard ? <div>
          <CustomSliderWards customClass={'field-light-range'} min={'300'} max={'310'} step={'1'} value={wardValues} labelText={'Палаты'} inputNameMin={'confirm[age_from]'} inputNameMax={'confirm[age_to]'} setParentValues={setWardValuesHandler} />
        </div> : <></>
      }
      {
        showAge ?
        <div>
          <CustomSlider customClass={'field-light-range'} min={'17'} max={'100'} value={ageValues} labelText={'Возраст'} inputNameMin={'confirm[age_from]'} inputNameMax={'confirm[age_to]'} setParentValues={setAgeValuesHandler} />
        </div>
        : <></>
      }
      {
        showSex ? <div className="filter-btn-checkbox_wrap">
          <label className={ sexFilter && (sexFilter === 1) ? classNames("filter-btn-checkbox", "active") : classNames("filter-btn-checkbox") } onClick={()=> { setSexFilterHandler(1) }}> <span>Женский</span> </label>
          <label className={ (sexFilter === 0) ? classNames("filter-btn-checkbox", "active") : classNames("filter-btn-checkbox") } onClick={()=> { setSexFilterHandler(0) }}> <span>Мужской</span> </label>
        </div> : <></>
      }
      {
        departmentFilter ? <div className="filter-btn-checkbox_wrap">  Фильтры по Отделению пока не работают.  </div> : <></>
      }
      {
        showCustomer ? <div className="filter-btn-checkbox_wrap">
          <label className={ (withoutCustomer === false) ? classNames("filter-btn-checkbox", "active") : classNames("filter-btn-checkbox") } onClick={()=> { setWithoutCustomer(false) }}> <span>Активные</span> </label>
          <label className={ withoutCustomer ? classNames("filter-btn-checkbox", "active") : classNames("filter-btn-checkbox") } onClick={()=> { setWithoutCustomer(true) }}> <span>Не привязанные</span> </label>
        </div> : <></>
      }
      <button className="btn-gradient" onClick={()=>{searchHandler()}}>Искать</button>

    </div>
  );
};

export default MonoFiltersSmall;