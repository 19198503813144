// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Loader-module__LoaderBg___1klhm{position:fixed;z-index:99999;top:0;left:0;width:100%;height:100%;pointer-events:none;display:flex;flex-direction:column;justify-content:center;align-items:center;opacity:1;transition:all .3s ease;cursor:default}", "",{"version":3,"sources":["/mnt/c/Users/elena/Documents/projects/kardio2/kardio/app/javascript/components/loaded/Loader.module.scss"],"names":[],"mappings":"AAAA,iCACE,cAAA,CACA,aAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,SAAA,CACA,uBAAA,CACA,cAAA","file":"Loader.module.scss","sourcesContent":[".LoaderBg {\r\n  position: fixed;\r\n  z-index: 99999;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  pointer-events: none;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  opacity: 1;\r\n  transition: all 0.3s ease;\r\n  cursor: default;\r\n  & > canvas,\r\n  & > img { \r\n    //width: 135px; \r\n    //height: 140px;\r\n    //filter: var(--clr-font-main, #1F1F1F);\r\n    // background: linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2, #FF4E4E) 96.67%);\r\n  }\r\n}\r\n\r\n"]}]);
// Exports
exports.locals = {
	"LoaderBg": "Loader-module__LoaderBg___1klhm"
};
module.exports = exports;
