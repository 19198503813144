import React, {useState, useEffect} from 'react';
import styles from './Profile.module.scss';
import ProfilePatient from './ProfilePatient.jsx';
import ProfilePatientForm from './ProfilePatientForm.jsx';
import axios from 'axios';
import classNames from "classnames";


const ProfilePatientMain = ({patient, permission, unconfirmed, doctors, appliances, wards }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [user, setUser] = useState(patient);
  const [userWasUpdated, setUserWasUpdated] = useState(true);
  const [editableComponent, setEditableComponent] = useState(false);
  const permissions = JSON.parse(permission);
 
  const refreshPatientProfile = (j, params) => {
    setUser(params);
    // setUser((user) => {
    //   let n2 = params;
    //   let n1 = user;
    //   for (const [key, value] of Object.entries(n2)) {
    //     let res = params[key]
    //     // debugger
    //     if (key!=='id' && res &&  n1[key] !== res) {
    //       n1[key] =  res
    //     }
    //   }
    //   user = n1;
    //   return user;
    // });
    setUserWasUpdated(true);
    setEditableComponent(false);
  }

  const deleteItem = (user) => {
    let confirmation = confirm(`Уверены что хотите удалить аккаунт?`);
    if (confirmation) {
      axios.post(`/persons/${user.id}/destroy`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
          
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
    
  }

  // TODO Поправить это позже сделать редактируемым Сецчас формы не работают

  return (
    <div className={styles.ProfileItem}>
      { editableComponent ?
          <ProfilePatientForm  key={user.id} index={user.id} patient={user} openForm={setEditableComponent} refreshPatient={refreshPatientProfile}  wards={wards} doctors={doctors} permissions={permissions} appliances={appliances} />
          : <ProfilePatient key={user.id} patient={user} openForm={setEditableComponent} deleteItem={deleteItem} permissions={permissions} unconfirmed={unconfirmed} doctors={doctors} />
      }
    </div>
  );
};

export default ProfilePatientMain;
