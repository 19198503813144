import Global from "../Global";
import React, {useRef, useState, useEffect, useMemo} from 'react';
import DinamicGraphicBlack from './DinamicGraphicBlack.jsx';
import classNames from 'classnames/bind';
import styles from './BlackWard.module.scss';
import BatteryLevel from '../battery/BatteryLevel.jsx';
// import WebSocket from 'ws';
import axios from 'axios';

const DinamicGraphicItem = ({ind, number, currentStatus, showModal = false, device, norms, PositionSectors }) => {
  // Вынести логику модалки сюда
    // ++++++++++++++++++++++++++++++ start
  const maxArrayLength = 10
  
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
 
  // const tokenParams =  {
  //   "username": process.env.TOKEN_USER,
  //   "password": process.env.TOKEN_PASSWORD
  // };
  // const connectionPath = process.env.CONNECTION_PATH;
  // const tokenPath =  process.env.TOKEN_PATH;
  const tokenParams =  {
    "username": 'Oxic@rts-iot.ru',
    "password": 'oxic666'
  };
  const connectionPath = 'wss://tb.rts-iot.ru/api/ws/plugins';
  const tokenPath =  'https://tb.rts-iot.ru/api/auth/login';
  
  const indicators = JSON.parse(norms);

  const curDevice = device;

  const [readyState, setReadyState ] = useState();
  const [status, setStatus] = useState(currentStatus);
  let isValid = false;

  const [testDataPulse, setTestDataPulse] = useState([]);
  const [testDataSaturation, setTestDataSaturation] = useState([]);
  const [testLabels, setTestLabels] = useState([]);
  const [battery, setBattery] = useState(80);

  const [reserveDataPulse, setReserveDataPulse] = useState([]);
  const [reserveDataSaturation, setReserveDataSaturation] = useState([]);
  const [reserveLabels, setReserveLabels] = useState([]);
  const [reserveBattery, setReserveBattery] = useState(80);
  const [count, setCount] = useState(0);

  const [cardTitle, setCardTitle] = useState('');
  const [cardText, setCardText] = useState('');

  const [modalClassObj, setModalClassObj] = useState('');
  const [ShowTogglerElement, setShowTogglerElement] = useState(false);
  
  const [jwtToken, setJWTToken] = useState('');
  const [getJWTTokenFlag, setJWTTokenFlag] = useState(true);
  const [socketUrl, setSocketUrl] = useState(`${connectionPath}/telemetry?token=${jwtToken}`);

  // Создал отдельный состояние для токена
  const [jwtTokenCorrect, setJwtTokenCorrect] = useState(null);

  const [messageHistory, setMessageHistory] = useState([]);
  const [lastMessage, setLastMessage] = useState([]);
  
  const ws = useRef(null);
  const [graphIsValid, setGraphIsValid] = useState(true);
  const [graphIsInIsChargingPlace, setGraphIsInIsChargingPlace] = useState(false);
  const [graphIsBroken, setGraphIsBroken] = useState(false);

  let sectors = JSON.parse(PositionSectors); 
  sectors = sectors && sectors.sort((a, b) => a.sector - b.sector);

  const checkStatus = (pulse, saturation, battery) => {
    let localStatus = status;
    
    if (indicators) {
      const d = new Date;

      const isLowBattery = parseInt(battery) < parseInt(indicators.battery_min);

      if (isLowBattery)  {  localStatus = 'low_battery';  } 

      const isPulseWarn = (parseInt(pulse.val) < parseInt(indicators.pulse_warn_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_warn_max));
      const isSaturationWarn = (parseInt(saturation.val) < parseInt(indicators.saturation_warn_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_warn_max));

      if  (isPulseWarn || isSaturationWarn) {
        localStatus = 'warning';
      }
      
      const isPulsePanic = ((parseInt(pulse.val) < parseInt(indicators.pulse_panic_min)) || (parseInt(pulse.val) > parseInt(indicators.pulse_panic_max)));
      const isSaturationPanic = ((parseInt(saturation.val) < parseInt(indicators.saturation_panic_min)) || (parseInt(saturation.val) > parseInt(indicators.saturation_panic_max)));
      
      if ( isPulsePanic || isSaturationPanic ) {
        localStatus = 'panic';
      }

      if (!(isPulseWarn || isSaturationWarn || isPulsePanic || isSaturationPanic || isLowBattery)) {
        localStatus = '';
        // Нельзя менять состояние useState напрямую, React не сможет отследить изменение и не совершит рендер. Закоментил.
        // showModal = false;
      }
    }
    
    return localStatus;
  }

  // Меняем socket url при изменении jwt токена
  const socketUrlCorrect = useMemo(() => `${connectionPath}/telemetry?token=${jwtTokenCorrect}`, [jwtTokenCorrect]);

  function GetJWTTOken(ev) {
      axios.post(tokenPath, tokenParams, { 
        // withCredentials: true, 
        // origin: true, 
        headers: { 
        // 'Content-Type': 'multipart/form-data',
        // 'X-CSRF-Token': token,
        // "Authorization": token,
        'Access-Control-Allow-Origin': true,
        "Access-Control-Allow-Methods": '*',
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        'Accept': 'application/json'
      } },
      ).then(response => {
        if (response.status === 200) {
          setJwtTokenCorrect(response.data.token);
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
          console.log(error);
      });
  }
  
  useEffect(() => {
    // При рендере компоненты идем за токеном если его нет
    if (!jwtTokenCorrect) {
      GetJWTTOken();
    }
  }, []);

  const sendError = (error) => {
    // TODO uncomment it after
    console.log("  !!!!!!!!!!!!!!!!!!!!!  ", error);
    // axios.post('/send-error-msg', { data: error }, { headers: { 
    //   'Accept': 'application/json',
    //   'X-CSRF-Token': token
    //  } },
    //  ).then(response => {
    //    if (response.status === 200) {
    //     console.log(response.message);
    //    } else {
    //      console.log('Произошли ошибки, пожалуйста попробуйте позже.');
    //    }
    //  }).catch(error => {
    //     console.log(error);
    //  });
  }

  const [isPaused, setIsPaused] = useState(false);
  
  const isTimeActual = (time, minutesCounter) => {
    let d1 = new Date(),
    d2 = new Date ( d1 );
    d2.setMinutes ( d1.getMinutes() - minutesCounter );
    return  (d2 < time);
  }

  let checkIsDeviseInChargingPlaceCounter = 0;
  let checkIsDeviseInChargingPlaceDate = new Date();
  
  const checkIsDeviseInChargingPlace = (Area_ID) => {
    let result = false;
    let intermediateResult = false;
    
    let foundedSector = sectors.find((ps) => (ps.sector === Area_ID));
    if (isTimeActual(checkIsDeviseInChargingPlaceDate, 1) && checkIsDeviseInChargingPlaceCounter < 3) {
      intermediateResult = false;
      checkIsDeviseInChargingPlaceCounter += 1;
      // console.log(` +++ !!! ............. this is actual message ............. `, checkIsDeviseInChargingPlaceCounter.toLocaleTimeString, new Date().toLocaleTimeString());
    } else {
      intermediateResult = true;
      checkIsDeviseInChargingPlaceCounter = 0;
      // console.log(` +++ !!! ............. this is NOT actual message ............. `, checkIsDeviseInChargingPlaceCounter.toLocaleTimeString, new Date().toLocaleTimeString());
    }
    
    if (foundedSector && foundedSector.is_charging_place) {
      setGraphIsInIsChargingPlace(intermediateResult);
      result = intermediateResult;
    } else {
      setGraphIsInIsChargingPlace(false);
      setGraphIsBroken(GraphIsBroken => { 
        GraphIsBroken = intermediateResult;
        // console.log(number, 'intermediateResult GraphIsBroken ', GraphIsBroken);
        return GraphIsBroken; 
      } );
    }
    return result;
  }

  
  // Вынес разбор данных из сообщения в отдельную ф-ию
  const parseMessage = (msg) => {
    
    if (!msg) {
      setGraphIsValid(false);
      return;
    }

    const result = JSON.parse(msg);
    const devId = curDevice.identifier;
    let localBattery;

    if (!result.errorCode && (result.errorCode !== 2) && (result.errorCode !== 1) && (Object.keys(result.data).length !== 0)) {
      
      if (result.data.Battery) {
        localBattery = parseInt(result.data.Battery[0][1]);
        setBattery(parseInt(result.data.Battery[0][1]));
      }
      
      let graphIsInIsChargingPlaceLocal = (result.data.Area_ID) ? checkIsDeviseInChargingPlace(result.data.Area_ID[0][1]) : false;
      
      if  (!isTimeActual(new Date(result.data.Valid_SpO2[0][0]), 3)) {
          setGraphIsValid(false);
          return;
      }
      
      const saturation_date = new Date(result.latestValues.SpO2).toLocaleTimeString();
      const saturation = result.data && result.data.SpO2 ? { time: new Date(result.data.SpO2[0][0]).toLocaleTimeString(), val: result.data.SpO2[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      
      const pulse_date = new Date(result.latestValues.HR).toLocaleTimeString();
      const pulse = result.data && result.data.HR ? { time: new Date(result.data.HR[0][0]).toLocaleTimeString(), val: result.data.HR[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      
      const saturation_valid = result.data && result.data.Valid_SpO2 ? { time: new Date(result.data.Valid_SpO2[0][0]).toLocaleTimeString(), val: result.data.Valid_SpO2[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      
      const pulse_valid = result.data && result.data.Valid_HR ? { time: new Date(result.data.Valid_HR[0][0]).toLocaleTimeString(), val: result.data.Valid_HR[0][1] } : { time: new Date().toLocaleTimeString(), val: '' }
      
      let curGraphIsPulseValid = ((pulse_valid.val === "1") && (parseInt(pulse.val) > 3) && (pulse.val !=='false') );
      let curGraphIsSaturationValid = ((saturation_valid.val === "1") && (parseInt(saturation.val) > 3) && (saturation.val!=='false'));

      let curGraphIsValid = (curGraphIsPulseValid && curGraphIsSaturationValid);
      
      setGraphIsValid(curGraphIsValid);
      
      if (curGraphIsValid) {
        let localStatus = checkStatus(pulse, saturation, battery);
        setStatus(localStatus);
        setTestDataSaturation(testDataSaturation => {
          let v = [ ...testDataSaturation, saturation.val];
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });
  
        setTestDataPulse(testDataPulse => {
          let v = [ ...testDataPulse, pulse.val];
          v.map(item => item.group).slice(-1*maxArrayLength);
          return v;
        });
  
        setTestLabels(testLabels => {
          let l = [ ...testLabels, saturation.time];
          l.map(item => item.group).slice(-1*maxArrayLength);
          return l;
        });
        Global.changeDeviceStatus({ ident: device.identifier, status:localStatus, battery: localBattery, ward: number, patient: `${device.patient.last_name} ${device.patient.first_name}`, graphIsInChargingPlace: graphIsInIsChargingPlaceLocal })
      } else {
        Global.changeDeviceStatus({ ident: device.identifier, status:'', battery: battery, ward: number, patient: `${device.patient.last_name} ${device.patient.first_name}`, graphIsInChargingPlace: graphIsInIsChargingPlaceLocal })
      }
      setMessageHistory((prev) => prev.concat(lastMessage));
    } else {
      sendError(`${result.errorMsg} deviceId = ${devId}`);
      setGraphIsValid(false);
    }
  }

  const [isConnect, setIsConnect] = useState(false);

  useEffect(() => {
    if (jwtTokenCorrect) {
      if (!isPaused) {
        ws.current = new WebSocket(socketUrlCorrect); // создаем ws соединение

        ws.current.onopen = () => { 
          console.log("Соединение открыто", number, ws.current.readyState); 

          setIsConnect(true);

          const dataMsg = JSON.stringify(object);

          if (ws.current.readyState === 1) {
            ws.current.send(dataMsg);
            // console.log("Message is sent: " + dataMsg);
          }
        }

        // callback на ивент открытия соединения

        ws.current.onclosing = () => {
          console.log("Соединение закрывается .... "); 
        }
          
        ws.current.onclose = (event) => {
          console.log("Соединение закрыто", number);
          setGraphIsValid(false);
          setIsConnect(false);

          if (event.code === 1006) {
            console.log(' ..... inside event.code===1006  ..... ');
            GetJWTTOken();
          }
          
        } // callback на ивент закрытия соединения
        ws.current.onmessage = (msg) => {
          parseMessage(msg.data);
        }
      } else {
        console.log(' !!!!!!!!!!!!!!!!!!!!! ');
      }
    }

    // кода меняется isPaused - соединение закрывается
  }, [jwtTokenCorrect]);
  
  const object = {
    tsSubCmds: [
       {
          entityType: "DEVICE",
          entityId: device.identifier,
          scope: "LATEST_TELEMETRY",
          cmdId: ind
        } 
    ],
    historyCmds: [],
    attrSubCmds: []
  };
  
  const connectionStatus = {
     [0]: 'Connecting',
     [1]: 'Open',
     [2]: 'Closing',
     [3]: 'Closed',
  }[readyState];

  const curItem = useRef();
  const curItemCard = useRef();
  const [leftSide, setLeftSide] = useState(false);
    
  useEffect(() => {
    
  }, [ graphIsBroken, setGraphIsBroken ]);

    return (
      graphIsInIsChargingPlace ? <></> :
        <div key={ind}> 
          <div ref={curItem} data-number={number} className={styles.PatientCard}>
            <header className={styles.Header}>
              <div className={classNames(styles.CardNumber, status === 'panic' ? styles.Panic : '', status === 'warning' ? styles.Warn : '')}>{number}</div>

              <a className={styles.Name} href={`/patients/${device.patient && device.patient.id}`}> {`${device.patient.last_name} ${device.patient.first_name}`} <small>({device && device.act})</small></a>

              <div className={styles.Battery}>
                <span>Заряд устройства</span>

                <BatteryLevel val={parseInt(battery)} norms={parseInt(indicators.battery_min)} />
              </div>
            </header>

            <div style={{ height: "20px" }}>
              {
                graphIsValid ? <></> : <div className={styles.PatientCardNoText}> Данные появятся позже </div>
              }
            </div>
                { isConnect &&
                  <div className={styles.PatientGraphic}>
                    <DinamicGraphicBlack
                      maxArrayLength={maxArrayLength}
                      labels={testLabels}
                      saturation={null}
                      pulse={testDataPulse}
                      norms={norms}
                      number={number}
                      status={status}
                    />

                    <DinamicGraphicBlack
                      maxArrayLength={maxArrayLength}
                      labels={testLabels}
                      saturation={testDataSaturation}
                      pulse={null}
                      norms={norms}
                      number={number}
                      status={status}
                    />
                  </div>}
          </div> 
        </div>
    );
};

export default DinamicGraphicItem;
