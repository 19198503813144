

import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames';
import Loader from '../../../assets/images/animated_loader.svg';

const PageLoaded = ({show = false}) => {
  
  return (
    <div id="page-loaded" className={classNames('loader-bg', show ? '' : 'hided')} >
      <img src={Loader} alt="loading ..." />
    </div>
  );
};

export default PageLoaded;