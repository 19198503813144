
import classNames from 'classnames';
import React, {useState} from 'react';
import styles from './Position.module.scss'; 

const CirclePosition = ({ positionCoords, status, index, number }) => {
  const coords = (typeof(positionCoords) === 'string') ? JSON.parse(positionCoords) : positionCoords;
  // const [curColor, setCurColor] = useState("#3289F0");
  // let curClass = styles.PositionManImageTest;
  let curClass = 'position-image-default';
  let curClassMain = 'position-point-default';
  let curColor = "#3289F0";
  let curClassJsToggle = 'js-show-default-in-position';
  switch(status) {
    case 'panic':
      curClass = 'position-image-panic';
      curClassJsToggle = 'js-show-panic-in-position';
      curClassMain = 'position-point-panic';
      curColor = "#FF1C60";
      break;
    case 'warning':
      curClass = 'position-image-warning';
      curClassJsToggle = 'js-show-warning-in-position';
      curClassMain = 'position-point-warning';
      curColor = "#F8A426";
      break;
    case 'low_battery':
      curClass = 'position-image-low';
      curClassJsToggle = 'js-show-low-in-position';
      curClassMain = 'position-point-low';
      curColor = "#6adc26";
      break;
    default:
      curClass = 'position-image-default';
      curClassJsToggle = 'js-show-default-in-position';
      curClassMain = 'position-point-default';
      curColor = "#3289F0";
  }
  
  return (
    <>
      { 
        coords.x && coords.y ? 
            <>
              <circle cx={coords.x} cy={coords.y} r="28.5"  fill={curColor} fillOpacity="0.1" className={ classNames(curClass, curClassJsToggle, 'svg-animation-pulse')}/>
              <circle cx={coords.x} cy={coords.y} r="21.5204"  fill={curColor} fillOpacity="0.2" className={ classNames(curClass, curClassJsToggle, 'svg-animation-pulse-second')}/>
              <rect x={coords.x-37+0.41254} y={coords.y+0.4071} fill={curColor} fillOpacity="0.2" width="74.1" height="35.0143" rx="17.5071" stroke="white" strokeWidth="0.814286" className={classNames('js-show-text-in-position', curClassJsToggle)}/>
              <circle cx={coords.x} cy={coords.y} r="8.14286" stroke="white" fill={curColor} strokeWidth="1.62857" className={curClassJsToggle} />
              <text x={coords.x} y={coords.y+24}  textAnchor="middle" stroke="black"  strokeWidth="1px"  alignmentBaseline="middle" className={classNames('js-show-text-in-position','st-new', curClassJsToggle)}> {number} </text>
            </>
           : <></> 
        }
 </> );
};

export default CirclePosition;