import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import axios from 'axios';
import styles from './Position.module.scss';
import PositionSector from './PositionSector.jsx';
import PositionSectorForm from './PositionSectorForm.jsx';
import AddPositionSector from './AddPositionSector.jsx';

const PositionSectorList = ({ PositionSectors, freePositionSectors, currentPermissions }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  
  let sectors = JSON.parse(PositionSectors); 
  sectors = sectors && sectors.sort((a, b) => a.sector - b.sector);
  let freeSectors = JSON.parse(freePositionSectors);
  freeSectors = freeSectors && freeSectors.sort((a, b) => a.sector - b.sector);
  const [sectorsList, setSectorsList] = useState(sectors);
  const [editableComponents, setEditableComponents] = useState([]);
  const permissions = JSON.parse(currentPermissions);

  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }

  const refreshSector= (j, params) => {
    if (j == 'new') {
      AddNewSector(params)
    } else {
      setSectorsList(sectorsList => {
        return sectorsList.map((sector, i) => {
          if (j === i) {
            sector = params;
          } 
          return sector
        });
      });
    }
  }
  
  const AddNewSector = (params) => {
    setSectorsList(sectorsList => {
      return [ ...sectorsList, params ];
    });
  }
  
  const deleteItem = (i) => {
    const sector = sectorsList[i];
    let confirmation = confirm(`Уверены что хотите удалить область c ID ${sector.sector}?`);
    if (confirmation) {
      axios.post(`/sectors/${sector.id}/delete`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
          setSectorsList(sectorsList => {
            return sectorsList.filter(item => item.id !== sector.id);
          });
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
    
  }

  const result = sectorsList.sort((a, b) => a.sector - b.sector).map((ps, i) => {
    if (editableComponents.includes(i) ) { 
      return <PositionSectorForm PositionSectorsOnMap={sectorsList} currentSector={ps} index={i} openForm={openForm} refreshSector={refreshSector} freeSectors={freeSectors} permissions={permissions}/>  
      }
    else { 
      return <PositionSector currentSector={ps} index={i} openForm={openForm} deleteItem={deleteItem} permissions={permissions} isLast={(i === (sectors.length - 1))} /> 
    }  
  });

  return (
    <ul className={styles.PositionSectorList}>
      <li key={-1} className={classNames(styles.PositionSectorListItem, styles.PositionSectorListHeader)}>
          <span>ID области</span>
          <span>координаты</span>
          <span></span>
      </li>
      { result }
      { permissions.create &&  freeSectors && freeSectors.length ? <AddPositionSector  index='new' PositionSectorsOnMap={sectorsList}  permissions={permissions} AddNewSector={AddNewSector} freeSectors={freeSectors}  openForm={openForm} refreshSector={refreshSector}   /> : <></>  } 
    </ul>
  );
 
};

export default PositionSectorList;