import React, {useState, useEffect} from 'react';
import styles from './Profile.module.scss';
import Cogwheel from '../../../assets/images/cogwheel.svg';

const ProfilePatient = ({ patient, openForm, deleteItem, permissions }) => {
  
    return (
      <>
        <h1 class="h2-title profile-title">{patient.first_name} {patient.last_name}</h1>
        <p class="profile-intro"> На данный момент пациент находится {patient.discharge_text}</p>
        <div className={styles.ProfileWrap}>
          <ul  className={styles.ProfileList}>
            <li className={styles.ProfileListItem}>
              <span>ФИО:</span>
              <span>{patient.first_name} {patient.last_name}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>пол:</span>
              <span>{patient.sex_name}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>Дата рождения:</span>
              <span>{patient.date_of_birth_format}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>Возраст:</span>
              <span>{patient.age}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>На данный момент:</span>
              <span>{patient.discharge}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>Отделение:</span>
              <span>{patient.department}</span>
            </li>
            <li className={styles.ProfileListItem}>
              <span>Палата:</span>
              <span>{patient.ward}</span>
            </li>
            
          </ul>

          { permissions.update ? <span className="link-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(true) }}> <img src={Cogwheel} alt="edit" /> <span className="link">Редактировать</span> </span> : <></>  }
          { permissions.delete ? <span className="link" onClick={(ev)=>{ ev.preventDefault(); deleteItem() }}>Удалить</span> : <></> }
        </div>
      </>
      
      
    );
};

export default ProfilePatient;