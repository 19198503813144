import React, {useState, useEffect} from 'react';
import styles from './Profile.module.scss';
import Profile from './Profile.jsx';
import ProfileForm from './ProfileForm.jsx';
import axios from 'axios';
import classNames from "classnames";


const ProfileMain = ({currentUser, permission, unconfirmed, patients }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [user, setUser] = useState(currentUser);
  const [editableComponent, setEditableComponent] = useState(false);
  const permissions = JSON.parse(permission);
 
  const deleteItem = () => {
    let confirmation = confirm(`Уверены что хотите удалить аккаунт?`);
    if (confirmation) {
      axios.post(`/persons/${user.id}/destroy`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          window.location.replace("/");
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
    
  }

  const result = editableComponent ? 
    <ProfileForm user={user} openForm={setEditableComponent} refreshUser={setUser}  permissions={permissions}/> 
    : <Profile user={user} openForm={setEditableComponent} deleteItem={deleteItem} permissions={permissions} unconfirmed={unconfirmed} patients={patients} /> 
  return (
    <div className={styles.ProfileItem}>
      { result }
    </div>
  );
};

export default ProfileMain;
