import React from 'react';
import styles from './Indicators.module.scss';
import classNames from "classnames";

const Indicator = ({indicator, index, openForm, deleteItem, permissions}) => {
    return (
      <li key={index} className={classNames(styles.IndicatorListItem, permissions.update ? styles.IndicatorListItemWithHover : '')} title={permissions.update ? 'Нажми чтобы редактировать' : ''} onClick={permissions.update ?  (ev)=>{ ev.preventDefault(); openForm(index, true) } : console.log('no premission')} >
        <span>{indicator.name}</span>
        <span className={styles.IndicatorListValue}>{indicator.value} </span>
        
        {/* permissions.update ? 
          <button className="btn-gradient" ><span>Редактировать</span></button>
          : <></> */}
        {/* <button className="btn-border" onClick={(ev)=>{  ev.preventDefault(); deleteItem(index) }}><span>Удалить</span></button> */}

    </li>
      
    );
};

export default Indicator;