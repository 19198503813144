import React, {useState, useEffect, useRef} from 'react';
import styles from './BlackWard.module.scss';
import classNames from 'classnames/bind';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    plugins,
    // Legend
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

const DinamicGraphicBlack = ({ labels, saturation, pulse, norms, number, maxArrayLength, status }) => {
  
  
  let BorderClr, gradientColors;

  switch(status) {
    case 'panic':  
      gradientColors = [ 'rgba(255, 28, 96, 1)', 'rgba(255, 28, 96, .5)', 'rgba(255, 28, 96, 0)' ];
      BorderClr = '#F51E6B';
      break;
    case 'warning':  
      gradientColors = [ 'rgba(255, 169, 40, 1)', 'rgba(255, 169, 40, .5)', 'rgba(255, 169, 40, 0)' ];
      BorderClr = '#F8A426';
      break;
    default:
      BorderClr = '#6ADC26';
      gradientColors = [ 'rgba(106, 220, 38, 1)', 'rgba(106, 220, 38, .5)', 'rgba(106, 220, 38, 0)' ];
      break;
  }

  const chartRef = useRef(null);
  const indicators = JSON.parse(norms);
  // const curPulse = pulse ? pulse.slice(-1 * maxArrayLength) : []
  // const curSaturation = saturation ? saturation.slice(-1 * maxArrayLength) : []
  const curLabels = labels ? labels.slice(-1 * maxArrayLength) : []

  let currentInd = {}
  if (saturation) {
    currentInd = { 
      panic_min: indicators.saturation_panic_min, 
      warn_min: indicators.saturation_warn_min,
      warn_max: indicators.saturation_warn_max,
      panic_max: indicators.saturation_panic_max
    }
  }

  if (pulse) {
    currentInd = { 
      panic_min: indicators.pulse_panic_min, 
      warn_min: indicators.pulse_warn_min,
      warn_max: indicators.pulse_warn_max,
      panic_max: indicators.pulse_panic_max
    }
  }
  
  const GradientBgPluginbeforeDrawHandler = (chart, canvas, chartArea, currentPlInd) => {
    //chart.clear();
    // console.log('++++++++++++++++++++++++');
    // console.log(chart);
    // console.log(canvas.id , currentPlInd.panic_max)
    let maxPosition =  chartArea.top; // это максимальная точка на графике 100
    let minPosition = chartArea.bottom - chartArea.top;  // это минимальная точка на графике 0
    //console.log(maxPosition, minPosition, minYPointCalc);
    let ctx = canvas.getContext("2d");
    
    // TODO временно 1 цвет blue
    //ctx.fillStyle = 'rgba(213,235,248,1)';
    //ctx.fillRect(chartArea.left, maxPosition, chartArea.right - chartArea.left, minPosition);

    // // set pink for min Panic rgba(251,221,221,1)
    // // let minPanicStart = minWarnStop;
    let minPanicStart = maxPosition;
    let minPanicStop =  minPosition;
    ctx.fillStyle = 'rgba(251,221,221,1)';
    ctx.fillRect(chartArea.left, minPanicStart, chartArea.right - chartArea.left, minPanicStop);
 
    // // set yellow for min Warn  rgba(252,244,219,1)
    // // let minWarnStart = maxPanicStop;
     let minWarnStart = maxPosition;
     let minWarnStop =  minPosition - minPosition * (currentPlInd.panic_min - minYPoint) / (maxYPoint - minYPoint) ;
    //  console.log(`${minPosition} - ${minPosition} * ${currentPlInd.panic_min} / ${minYPointCalc}`)
     ctx.fillStyle = 'rgba(252,244,219,1)';
     ctx.fillRect(chartArea.left, minWarnStart, chartArea.right - chartArea.left, minWarnStop);

    // // set blue  rgba(213,235,248,1)
    let normalStart = maxPosition;
    // let normalStop =  minPosition - minPosition * currentPlInd.warn_min / minYPointCalc;
    let normalStop =  minPosition - minPosition * (currentPlInd.warn_min - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(213,235,248,1)';
    ctx.fillRect(chartArea.left, normalStart, chartArea.right - chartArea.left, normalStop);
     
    // // set yellow for max Warn  rgba(252,244,219,1)
    let maxWarnStart = maxPosition;
    // let maxWarnStop =  minPosition - minPosition * currentPlInd.warn_max / minYPointCalc;
    let maxWarnStop =  minPosition - minPosition * (currentPlInd.warn_max - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(252,244,219,1)';
    ctx.fillRect(chartArea.left, maxWarnStart, chartArea.right - chartArea.left, maxWarnStop);

    // // set pink for max Panic rgba(251,221,221,1)
    let maxPanicStart = maxPosition;
    // let maxPanicStop =  minPosition - minPosition * currentPlInd.panic_max / minYPointCalc;
    let maxPanicStop =  minPosition - minPosition * (currentPlInd.panic_max - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(251,221,221,1)';
    ctx.fillRect(chartArea.left, maxPanicStart, chartArea.right - chartArea.left, maxPanicStop);
    //// ctx.restore();
  }

  

  // useEffect(() => {
  //   if (curSaturation) {
  //     setInfo({title:'SaO2:', unit:'%', lastVal: curSaturation[curSaturation.length - 1]})
  //   } 
      
  //   if (curPulse) {
  //     setInfo({title:'Pulse:', unit:'bpm', lastVal:curPulse[curPulse.length - 1]})
  //   }

  // }, [curSaturation,  curPulse, setInfo]);
 
  
  const minYPoint = parseInt(currentInd.panic_min) - 30;
  const maxYPoint = parseInt(currentInd.panic_max) + (pulse ? 30 : 10);
  // это сколько процентов от общей шкалы minYPoint*100/maxYPoint;
  // const procentBeforeZerro = minYPoint*100/maxYPoint;
  // const minYPointCalc = maxYPoint + minYPoint*100/maxYPoint;
  
  // const [info, setInfo] = useState({title:'', unit:'', lastVal:''});

  const info = saturation ? { title:'SaO2', unit:'%', lastVal: saturation[saturation.length - 1] } : { title:'Пульс', unit:'bpm', lastVal: pulse[pulse.length - 1] };
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
  );

  ChartJS.defaults.font = {
    family: "Roboto Flex",
    size: 10,
    swcMinify: false
  };

  ChartJS.defaults.color = '#97A3B1';

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      swcMinify: false,
      animation: false, // disable animation
      format: {
        style: 'currency',
        currency: 'INR',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        // maximumFractionDigits: 2,
      },
      elements: {
        point:{
            radius: 0
        }
      },
      scales: {
        y: {
          max: maxYPoint,
          min: minYPoint,
          beginAtZero: false,
          ticks: {
            callback: function(val, index) {
                return val;
            } 
          }
        },
        x: {
          ticks: {
            maxTicksLimit: 5
          }
        }
      }
    };

    const plugins = [{
      id: saturation ? `custom-bg-grad-${number}_sat` : `custom-bg-grad-${number}`,
      title: false,
       beforeDraw: function(chart, args, options) {
        //  chart.clear();
        //  const ctx = chart.ctx;
        //  ctx.save();
        //  const canvas = chart.canvas;
        //  const chartArea = chart.chartArea;
        //  GradientBgPluginbeforeDrawHandler(chart, canvas, chartArea, currentInd);
        //  ctx.restore();
        //  chart.update();
      }
    }]

    const  datasets = [
      {
        // label: 'Сатурация(SaO2)',
        data: saturation ? saturation.slice(-1 * maxArrayLength) : pulse.slice(-1 * maxArrayLength),
        //data: saturation ? saturation : pulse,
        borderColor: BorderClr,
        borderWidth: 1,
        cubicInterpolationMode: 'default',
        backgroundColor: (context) => {
          if(!context.chart.chartArea) {
            return;
          }
          const chartArea = context.chart.chartArea;
          const { ctx, data, chartArea: {left, top, right, bottom} } = context.chart;
          // const gradientBg = ctx.createLinearGradient(x, y, w, h);
          // const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          const gradientBg = ctx.createLinearGradient(left, 0, (right - left), 0);

          gradientBg.addColorStop(0, gradientColors[0]);
          gradientBg.addColorStop(1, gradientColors[2]);
          
          return gradientBg;
        },
        // backgroundColor: [
        //   'rgba(156, 204, 44, 0.5)',
        //   'rgba(251,221,221, 0.75)'
        // ],
        fill: 'start',
        tension: 0.4
      },
      {
        // label: 'SaO2 p min',
        // data: Array(15).fill(currentInd.panic_min),
        data: Array(maxArrayLength).fill(currentInd.panic_min),
        borderWidth: 1,
        borderDash: [1, 2],
        borderColor: 'white',
        // backgroundColor: 'rgba(251,221,221, 0.75)',
        // fill: 'start'
      },
      {
        // label: 'SaO2 w min',
        data: Array(maxArrayLength).fill(currentInd.warn_min),
        borderWidth: 1,
        borderDash: [1, 2],
        borderColor: 'white',
        // backgroundColor: 'rgba(252, 244, 219, 0.75)',
        // fill:  'start',
      },
      {
        // label: 'SaO2 p max',
        data: Array(maxArrayLength).fill(currentInd.panic_max),
        borderWidth: 1,
        borderDash: [1, 2],
        borderColor: 'white',
        // backgroundColor: 'rgba(251,221,221, 0.75)',
        // fill: 'end',
      },
      {
        // label: 'SaO2 w max',
        data: Array(maxArrayLength).fill(currentInd.warn_max),
        borderWidth: 1,
        borderDash: [1, 2],
        borderColor: 'white',
        // backgroundColor: 'rgba(252, 244, 219, 0.75)',
        // fill:  'end',
      }
    ]
      // const labels_pulse = info && info.pulse && info.pulse.map((p) => { const d = new Date(p.last_update*1000); return d.toLocaleTimeString() });
     
      // цвет желтый  rgb(204, 169, 44)
      // красный rgb(255, 99, 132)

      // зеленый rgb(156, 204, 44) сат 
      // синий rgb(109, 207, 246) пульс
      
      const data =  {
        labels: curLabels,
        datasets: datasets
      };

    return (
      <div className={classNames(styles.GraphicCurrentWrap, saturation ? styles.GraphicCurrentSaturation : '')}>
        <div className={classNames(styles.GraphicWrapper)}>
            {/* <Line ref={chartRef} options={options} plugins={plugins} data={data} id={saturation ? `line-${number}_sat` : `line-${number}`} redraw={true} updateMode={"active"} /> */}
            <Line ref={chartRef} options={options} plugins={plugins} data={data} id={saturation ? `line-${number}_sat` : `line-${number}`} redraw={true} updateMode={"active"} />
            {/* <Line ref={chartRef} data={data} id={saturation ? `line-${number}_sat` : `line-${number}`} redraw={true} updateMode={"active"} /> */}
        </div>

        {
         <div className={styles.GraphicValsWrap}>



            <div className={styles.Indicator}>
              <span className={styles.IndicatorName}>{info.title}</span>

              <div className={saturation ? styles.Saturation : styles.Pulse}>
                { saturation ?
                  <>{info.lastVal ? <span>{info.lastVal}</span>: <span>&mdash;</span> }</> : <>{info.lastVal ? <span>{info.lastVal}</span> : <span>&mdash;</span> }</> }
                </div>
            </div>

            <div className={styles.Range}>
              <div className={styles.GraphicVals}>
                <div className={styles.GraphicValsWarn}> min:
                  <div className={styles.RangeValues}>
                    <span className={styles.MinValue}>{currentInd.warn_min}</span> - <span className={styles.MaxValue}>{currentInd.panic_min}</span>
                  </div>
                </div>

                {
                  pulse && (
                    <div className={styles.GraphicValsPanic}> max:
                    <div className={styles.RangeValues}>
                      <span className={styles.MinValue}>{currentInd.warn_max}</span> - <span className={styles.MaxValue}>{currentInd.panic_max}</span>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        }
      </div>
    );
};

export default DinamicGraphicBlack;
