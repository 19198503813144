import React, {useState, useRef, useEffect} from 'react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import styles from './Profile.module.scss';
import CogwheelIco from '../../../assets/images/cogwheel.svg';
import axios from 'axios';
import classNames from "classnames";


const WidgetList = ({}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [align, setAlign] = useState('center');
  const [position, setPosition] = useState('anchor');
  const [viewScroll, setViewScroll] = useState('auto');
  const setTheme = (theme) => {
    // debugger
    axios.post(`/set-color-theme/${theme}`, {}, { headers: { 
      'X-CSRF-Token': token
    } },
    ).then(response => {
      //debugger
      if (response.statusText === 'OK') {
          console.log(' !!! OK ');
          document.querySelector('html').setAttribute("data-theme", theme);
          setCookie('theme', theme, {});
        } else {
          deleteCookie('theme');
          console.log(' что-то пошло не так ');
        }
      }).catch(error => {
        console.log('is error')
        // console.log(error?.request?.responseText && error.request.responseText);
        // setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
      })
      .finally(() => {  });
  };
  return (
    <>
      <Menu
        menuButton={<MenuButton className={classNames('widget-btn', styles.WidgetBtn)}><img src={CogwheelIco} title=''/> Настроить виджет</MenuButton>}
        key={'top'}
        direction={'top'}
        align={align}
        position={position}
        viewScroll={viewScroll}
        arrow={true}
        gap={0}
        shift={0}
      >
        {[{name:'default', label:'Стандартная тема'}, {name:'dark', label:'Темная тема'}, {name: 'light', label:'Светлая тема'}].map((theme) => (
          <MenuItem key={theme.name} onClick={(ev)=> { setTheme(theme.name) }}>{theme.label}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default WidgetList;