import React, {useState, useEffect, useRef} from 'react';
import styles from './DinamicWard.module.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    plugins,
    // Legend
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

const DinamicGraphic = ({labels, saturation, pulse, norms, number, maxArrayLength}) => {
  const chartRef = useRef(null);
  const indicators = JSON.parse(norms);
  // const curPulse = pulse ? pulse.slice(-1 * maxArrayLength) : []
  // const curSaturation = saturation ? saturation.slice(-1 * maxArrayLength) : []
  const curLabels = labels ? labels.slice(-1 * maxArrayLength) : []
  
  let currentInd = {}
  if (saturation) {
    currentInd = { 
      panic_min: indicators.saturation_panic_min, 
      warn_min: indicators.saturation_warn_min,
      warn_max: indicators.saturation_warn_max,
      // panic_max: indicators.saturation_panic_max
      panic_max: 140
    }
  }

  if (pulse) {
    currentInd = { 
      panic_min: indicators.pulse_panic_min, 
      warn_min: indicators.pulse_warn_min,
      warn_max: indicators.pulse_warn_max,
      panic_max: indicators.pulse_panic_max
    }
  }
  
  const GradientBgPluginbeforeDrawHandler = (chart, canvas, chartArea, currentPlInd) => {
    //chart.clear();
    // console.log('++++++++++++++++++++++++');
    // console.log(chart);
    // console.log(canvas.id , currentPlInd.panic_max)
    let maxPosition =  chartArea.top; // это максимальная точка на графике 100
    let minPosition = chartArea.bottom - chartArea.top;  // это минимальная точка на графике 0
    //console.log(maxPosition, minPosition, minYPointCalc);
    let ctx = canvas.getContext("2d");
    
    // TODO временно 1 цвет blue
    //ctx.fillStyle = 'rgba(213,235,248,1)';
    //ctx.fillRect(chartArea.left, maxPosition, chartArea.right - chartArea.left, minPosition);

    // // set pink for min Panic rgba(251,221,221,1)
    // // let minPanicStart = minWarnStop;
    let minPanicStart = maxPosition;
    let minPanicStop =  minPosition;
    ctx.fillStyle = 'rgba(251,221,221,1)';
    ctx.fillRect(chartArea.left, minPanicStart, chartArea.right - chartArea.left, minPanicStop);
 
    // // set yellow for min Warn  rgba(252,244,219,1)
    // // let minWarnStart = maxPanicStop;
     let minWarnStart = maxPosition;
     let minWarnStop =  minPosition - minPosition * (currentPlInd.panic_min - minYPoint) / (maxYPoint - minYPoint) ;
    //  console.log(`${minPosition} - ${minPosition} * ${currentPlInd.panic_min} / ${minYPointCalc}`)
     ctx.fillStyle = 'rgba(252,244,219,1)';
     ctx.fillRect(chartArea.left, minWarnStart, chartArea.right - chartArea.left, minWarnStop);

    // // set blue  rgba(213,235,248,1)
    let normalStart = maxPosition;
    // let normalStop =  minPosition - minPosition * currentPlInd.warn_min / minYPointCalc;
    let normalStop =  minPosition - minPosition * (currentPlInd.warn_min - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(213,235,248,1)';
    ctx.fillRect(chartArea.left, normalStart, chartArea.right - chartArea.left, normalStop);
     
    // // set yellow for max Warn  rgba(252,244,219,1)
    let maxWarnStart = maxPosition;
    // let maxWarnStop =  minPosition - minPosition * currentPlInd.warn_max / minYPointCalc;
    let maxWarnStop =  minPosition - minPosition * (currentPlInd.warn_max - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(252,244,219,1)';
    ctx.fillRect(chartArea.left, maxWarnStart, chartArea.right - chartArea.left, maxWarnStop);

    // // set pink for max Panic rgba(251,221,221,1)
    let maxPanicStart = maxPosition;
    // let maxPanicStop =  minPosition - minPosition * currentPlInd.panic_max / minYPointCalc;
    let maxPanicStop =  minPosition - minPosition * (currentPlInd.panic_max - minYPoint) / (maxYPoint - minYPoint);
    ctx.fillStyle = 'rgba(251,221,221,1)';
    ctx.fillRect(chartArea.left, maxPanicStart, chartArea.right - chartArea.left, maxPanicStop);
    //// ctx.restore();
  }

  
  const minYPoint = parseInt(currentInd.panic_min) - 30;
  const maxYPoint = parseInt(currentInd.panic_max) + (pulse ? 60 : 10);
  // это сколько проццентов от общей шкалы minYPoint*100/maxYPoint;
  // const procentBeforeZerro = minYPoint*100/maxYPoint;
  // const minYPointCalc = maxYPoint + minYPoint*100/maxYPoint;
  
  // const [info, setInfo] = useState({title:'', unit:'', lastVal:''});

  const info = saturation ? { title:'SaO2:', unit:'%', lastVal: saturation[saturation.length - 1] } : { title:'Pulse:', unit:'bpm', lastVal: pulse[pulse.length - 1] };
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
  );
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          max: maxYPoint,
          min: minYPoint,
          beginAtZero: false
        }
        // x: {
        //   type: 'time'
        // }
      }
    };
    const plugins = [{
      id: saturation ? `custom-bg-grad-${number}_sat` : `custom-bg-grad-${number}`,
      title: false,
      beforeDraw: function(chart, args, options) {
        //chart.clear();
        const ctx = chart.ctx;
        ctx.save();
        const canvas = chart.canvas;
        const chartArea = chart.chartArea;
        GradientBgPluginbeforeDrawHandler(chart, canvas, chartArea, currentInd);
        // ctx.restore();
        chart.update();
      }
    }]
      let  datasets = [
        {
          // label: 'Сатурация(SaO2)',
          data: saturation ? saturation.slice(-1 * maxArrayLength) : pulse.slice(-1 * maxArrayLength),
          //data: saturation ? saturation : pulse,
          borderColor: 'rgb(156, 204, 44)',
          backgroundColor: 'rgba(156, 204, 44, 0.5)',
        },
        {
          // label: 'SaO2 p min',
          // data: Array(15).fill(currentInd.panic_min),
          data: Array(maxArrayLength).fill(currentInd.panic_min),
          borderWidth: 1,
          borderDash: [10, 5],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(251,221,221, 0.75)',
          // fill: 'start'
        },
        {
          // label: 'SaO2 w min',
          data: Array(maxArrayLength).fill(currentInd.warn_min),
          borderWidth: 1,
          borderDash: [10, 5],
          borderColor: 'rgb(252, 244, 219)',
          backgroundColor: 'rgba(252, 244, 219, 0.75)',
          // fill:  'start',
        },
        {
          // label: 'SaO2 p max',
          data: Array(maxArrayLength).fill(currentInd.panic_max),
          borderWidth: 1,
          borderDash: [10, 5],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(251,221,221, 0.75)',
          // fill: 'end',
        },
        {
          // label: 'SaO2 w max',
          data: Array(maxArrayLength).fill(currentInd.warn_max),
          borderWidth: 1,
          borderDash: [10, 5],
          borderColor: 'rgb(252, 244, 219)',
          backgroundColor: 'rgba(252, 244, 219, 0.75)',
          // fill:  'end',
        }
      ]
      // const labels_pulse = info && info.pulse && info.pulse.map((p) => { const d = new Date(p.last_update*1000); return d.toLocaleTimeString() });
     
      // цвет желтый  rgb(204, 169, 44)
      // красный rgb(255, 99, 132)

      // зеленый rgb(156, 204, 44) сат 
      // синий rgb(109, 207, 246) пульс
      
      const data =  {
        labels: curLabels,
        datasets: datasets
      };

    return (
      <div  className={styles.GraphicCurrentWrap}>
        {
         <div className={styles.GraphicValsWrap}>
            <div className={styles.GraphicVals}>
            <div className={styles.GraphicValsTitleSaturation}>{info.title}</div>
              <div className={styles.GraphicValsMax}>
                <div className={styles.GraphicValsWarn}> min: <b>{currentInd.warn_min}</b> {info.unit} </div>
                <div className={styles.GraphicValsPanic}> min: <b>{currentInd.panic_min}</b> {info.unit} </div>
              </div>
              <div className={styles.GraphicValCurrentSaturation}>  <b>{info.lastVal}</b> {info.unit} </div>
              <div className={styles.GraphicValsMax}>
                <div className={styles.GraphicValsWarn}> max: <b>{currentInd.warn_max}</b> {info.unit} </div>
                <div className={styles.GraphicValsPanic}> max: <b>{currentInd.panic_max}</b> {info.unit} </div>
              </div>
            </div>
          </div>
        }
        <Line ref={chartRef} options={options} plugins={plugins} data={data} id={saturation ? `line-${number}_sat` : `line-${number}`} redraw={true} updateMode={"active"} />
      </div>
        
    );
};

export default DinamicGraphic;