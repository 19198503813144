import Global from "../Global";
import React, {useState, useEffect} from 'react';
import styles from './Appliance.module.scss';
import classNames from "classnames";
import BatteryLevel from '../battery/BatteryLevel.jsx';

const AsList = ({ userId, appliances, permission, norms }) => {
   const [appliancesList, setAppliancesList] = useState(appliances);
   const permissions = JSON.parse(permission);
   const currentNorm = JSON.parse(norms);
   const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => {
      appliancesList.map((appliance, i) => {
        let currentParams = Global.showDevice(appliance.identifier);
        if (currentParams) {
          setStatuses((statuses) => { 
            
            let curVar;
            if ((statuses.length === 0) || (statuses.findIndex(obj => obj.ident === currentParams.ident) == -1)) {
              curVar = [...statuses, currentParams];
            } else {
              curVar = statuses.map((st)=> {
                if (st.ident == currentParams.ident) {
                  st = currentParams;
                }
                return st;
              });
            }
            
            return curVar;

          });
        }
        
      });
    }, 1000);
    return () => clearInterval(timer);
  }, [setStatuses, statuses]);
  
    return (
        <>
          <ul className={styles.AppliancesAsList}>
            {
              appliancesList.map((appliance, i) => {
                
                let curStatus = statuses && statuses.filter((res) => (res.ident === appliance.identifier))[0];
                let status = curStatus && curStatus.status;
                let battery = curStatus && curStatus.battery ? curStatus.battery : appliance.charge_level;
                let graphIsInChargingPlace = curStatus && curStatus.graphIsInChargingPlace;
                
                const result = appliance.patient && !appliance.patient.discharge ? <li className={classNames(styles.AppliancesAsListItem)} key={i}>
                    <span className={(status==='panic') ? styles.AppliancesAsListWardPanic : ((status==='warning') ? styles.AppliancesAsListWardWarning : styles.AppliancesAsListWard)}>{appliance && appliance.ward && appliance.ward.number}</span>
                    <span className={styles.Name}><a href={`/patients/${appliance.patient && appliance.patient.id}`}>{` ${appliance.patient && appliance.patient.last_name} ${appliance.patient && appliance.patient.first_name}`}</a></span>
                    <span className="right-text">
                      {(status==='low_battery') ? <span className={styles.PanicIco}>!</span> : <></>}
                      { graphIsInChargingPlace ? <span className={styles.InChargingIco}>+</span> : <></>}
                      <BatteryLevel val={battery ? battery : 0 } showToolTipeInCharge={graphIsInChargingPlace} norms={parseInt(currentNorm ? currentNorm.battery_min : "50")} isDark={true} showToolTipe={true} test={true} />
                    </span>
                </li> : <></>

                return result

              })
            }
          </ul>
        </>
      
    );
};

export default AsList;
