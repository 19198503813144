import React, {useState} from 'react';
import styles from './Indicators.module.scss';
import Indicator from './Indicator.jsx';
import IndicatorForm from './IndicatorForm.jsx';
import IndicatorsInfoCopy from './IndicatorsInfoCopy.jsx';
import axios from 'axios';
import classNames from "classnames";

const IndicatorList = ({indicators, permission}) => {

  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [indicatorsList, setIndicatorsList] = useState(indicators);
  const [editableComponents, setEditableComponents] = useState([]);
  const [changeIndicators, setChangeIndicators] = useState(true);
  const permissions = JSON.parse(permission);
  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }
  const refreshIndicator = (j, params) => {
    console.log('refreshIndicator')
    setChangeIndicators(true);
    setIndicatorsList(indicatorsList => {
      return indicatorsList.map((Indicator, i) => {
        if (j === i) {
          Indicator = params;
        }
        return Indicator
      });
    });
  }
  
  const deleteItem = (i) => {
    const Indicator = indicatorsList[i];
    axios.post(`/indicators/${Indicator.id}/delete`, {}, { headers: { 
      'X-CSRF-Token': token
    } },
    ).then(response => {
      if (response.statusText === 'OK') {
        console.log(response.data.message);
        setIndicatorsList(indicatorsList => {
          return indicatorsList.filter(item => item.id !== Indicator.id);
        });
      } else {
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
      console.log(error);
      console.log('Произошли ошибки, пожалуйста попробуйте позже.');
    });
  }
  
  const result = indicatorsList.sort((a, b) => a.prior - b.prior).map((indicator, i) => {
    if (editableComponents.includes(i) ) { 
      return <IndicatorForm index={i} indicator={indicator} openForm={openForm} refreshIndicator={refreshIndicator} permissions={permissions} /> }
    else { 
      return <Indicator index={i} indicator={indicator} openForm={openForm} deleteItem={deleteItem} permissions={permissions} />
    }  
  });

  return (
    <div className={styles.IndicatorInfoWrap}>
      <IndicatorsInfoCopy changeIndicators={changeIndicators} setChangeIndicators={setChangeIndicators} />
      <div className={styles.IndicatorListWrap}>
        <div className="h1-title">Настройка сигнатур</div>
        <ul className={styles.IndicatorList}>
          <li key={-1} className={classNames(styles.IndicatorListItem, styles.IndicatorListHeader)}>
            <span>Наименование</span>
            <span className="center-text">Значение</span>
            <span></span>
          </li>
          { result }
        </ul>
      </div>
      
      
    </div>
  );
};

export default IndicatorList;