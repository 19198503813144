import React from 'react';
import styles from './Doctor.module.scss';
import Cogwheel from '../../../assets/images/cogwheel.svg';

const Doctor = ({doctor, index, openForm, deleteItem, permissions, isLast=false}) => {
    return (
      <li key={index} className={ isLast ? styles.DoctorsListItemNoBrd : styles.DoctorsListItem } >
        <span className={styles.DoctorPost}>{doctor.post}</span>
        <span><a href={`/doctors/${doctor.id}/${doctor.model}`}>{doctor.name}</a></span>
        <span>{doctor.phone}</span>
        
        <div>
          { permissions.delete ? <button className="btn-border btn-delete" onClick={(ev)=>{  ev.preventDefault(); deleteItem(index) }}> <span>Удалить</span> </button> : <></> }
          { permissions.update ? <button className="btn-gradient btn-edit" onClick={(ev)=>{ ev.preventDefault(); openForm(index, true) }}>
            <img src={Cogwheel} alt="edit" />
            Редактировать
          </button> : <></> }
        </div>
      </li>
      
    );
};

export default Doctor;