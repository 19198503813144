import React, { useState, useRef }  from 'react';
import styles from './Patient.module.scss';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";

const AddPatient = ({ AddNewPatient, wards, doctors, current_doctor = null}) => {
  const [dateOfBirthday, setDateOfBirthday] = useState(new Date());
  const [openFormNewPatient, setOpenFormNewPatient] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [hintMsg, setHintMsg] = useState('');
  const curForm = useRef()
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const doctors_option = doctors && doctors.map((d) => {
    return { label: d, value: d }
  });
  const [selectedDoctors, setSelectedDoctors] = useState(current_doctor ? [{ label: current_doctor.name, value: current_doctor.name }] : []);

  const select_localization = {
    "allItemsAreSelected": "Выбраны все элементы.",
    "clearSearch": "Очистить поиск",
    "clearSelected": "Очистить выбранное",
    "noOptions": "Без вариантов",
    "search": "Поиск",
    "selectAll": "все",
    "selectAllFiltered": "всех (отфильтровано)",
    "selectSomeItems": " -- Выберете врачей -- ",
    "create": "Создавать",
  };

  const CheckValidForm = () => {
    const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
    if (emptyInputs.length > 0) {
      emptyInputs.forEach(element => {
        element.classList.add('with-error');
      });
      setErrorMsg('Заполните обязательные поля');
      return true;
    }
    return false;
  }

  const ClearAndOpenForm = () => {
    if (curForm.current) {
      curForm.current.reset();
      const select_ward = curForm.current.querySelector('select[name="patient[ward]"]');
      select_ward.value = 'true';
    }
    setDateOfBirthday(new Date());
    setSelectedDoctors([]);
    setHintMsg('');
    setErrorMsg('');
    setOpenFormNewPatient(true);
  }

  const AddPatient = (ev) => {
    ev.preventDefault();
    const body = new FormData(curForm.current);
    const HasError = CheckValidForm(curForm.current);
    if (!HasError) {
      setHintMsg('... загрузка  ...')
      axios.post(`/patients/new`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
      ).then(response => {
        if (response.statusText === 'OK') {
          setHintMsg(response.data.message);
          AddNewPatient(response.data.new);
          setOpenFormNewPatient(false);
        } else {
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
          console.log(error);
          setHintMsg('');
          setErrorMsg(error?.response?.data?.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      })
      .finally(() => {  });
    }
  }

  return (
    <li key='new' className={classNames(styles.NewPatientWrapper, styles.PatientListItemLast) }>
      { openFormNewPatient ? 
       <form ref={curForm} name="patient" action={`/patients/new`} acceptCharset="UTF-8" data-remote="true" method="post"  className={styles.PatientForm}>
        <div className="field-light">
          <label>Фамилия</label>
          <input type="text" required name='patient[last_name]'  placeholder='Иванов' />
        </div>
        <div className="field-light">
          <label>Имя Отчество</label>
          <input type="text" required name='patient[first_name]' placeholder='Иван Иванович' />
        </div>
        <div className="field-light">
          <label>Пол</label>
          <select name='patient[sex]' defaultValue={0}>
            <option value="0">Мужской</option>
            <option value="1">Женский</option>
          </select>
        </div>
        <div className="field-light">
          <label>Возраст</label>
          <input type="text" required name='patient[age]' placeholder="100" />
        </div>
        <div className="field-light">
          <label>Дата рождения</label>
          <input type="hidden" name='patient[date_of_birth]' defaultValue={dateOfBirthday}  /> 
          <div className="custom_datepicker_wrapper">
            <DatePicker selected={dateOfBirthday} onChange={(date) => setDateOfBirthday(date)} />
          </div>
        </div>
        <div className="field-light">
          <label>Палата</label>
          <select name='patient[ward]'>
           <option disabled selected defaultValue> -- Выберете палату -- </option>
           {wards.map((w, i) => {
              return <option key={i} defaultValue={w}>{w}</option>
            })} 
          </select>
        </div>
        <div className="field-light">
          <label>Доктора</label>
          <input type="hidden" name='patient[doctors]'  defaultValue={selectedDoctors && selectedDoctors.map(v => { return v.value })} />
          <MultiSelect
            options={doctors_option}
            value={selectedDoctors}
            onChange={setSelectedDoctors}
            labelledBy="Select"
            overrideStrings={select_localization}
          />
        </div>
        <p className='errors'>{errorMsg}</p>
        <p className='hint'>{hintMsg}</p>
        <input type="submit" className="btn-gradient" defaultValue="Сохранить изменения" onClick={(ev)=>{ AddPatient(ev)}} />
        <button type="reset" className="btn-border" onClick={(ev)=> { ClearAndOpenForm(); setOpenFormNewPatient(false); }}><span>Не сохранять</span></button>
      </form> : <div className={styles.PatientListItemNoBrd} >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <div className="center-text">
            <button className="btn-border btn-create" onClick={(ev)=>{ ClearAndOpenForm();  }} > <span className="icon-plus">+</span><span>добавить пациента</span> </button>
          </div>
        </div>
      }
    </li>
  );
};

export default AddPatient;