import React, {useState, useRef, useEffect} from 'react';
import styles from './Position.module.scss';
import axios from 'axios';
import classNames from "classnames";
import Switch from "react-custom-checkbox/switch";
import MiniMap from './MiniMap.jsx';

const DinamicGraphPositionFilters = ({ PositionSectorsOnMap, index, openForm, freeSectors, refreshSector,  permissions }) => {
  const [showNumbers, setShowNumbers] = useState(true);
  const [showPanic, setShowPanic] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showLow, setShowLow] = useState(false);
  const [showAll, setShowAll] = useState(true);
  
  const showNumbersHandler = () => {
    setShowNumbers(!showNumbers);
    checkStatusesHandler();
  }

  const checkStatusesHandler = () => {
    if (showAll) {
      togglePositionElemsInsideSVG(true, showNumbers, 'js-show-default-in-position');
      togglePositionElemsInsideSVG(true, showNumbers, 'js-show-panic-in-position');
      togglePositionElemsInsideSVG(true, showNumbers, 'js-show-warning-in-position');
      togglePositionElemsInsideSVG(true, showNumbers, 'js-show-low-in-position');
    } else {
      togglePositionElemsInsideSVG(false, showNumbers, 'js-show-default-in-position');
      togglePositionElemsInsideSVG(showPanic, showNumbers, 'js-show-panic-in-position');
      togglePositionElemsInsideSVG(showWarning, showNumbers, 'js-show-warning-in-position');
      togglePositionElemsInsideSVG(showLow, showNumbers, 'js-show-low-in-position');
    }
  }

  const showAllHandler = () => {
    setShowWarning(showAll);
    setShowPanic(showAll);
    setShowLow(showAll);
    setShowAll(!showAll);
    checkStatusesHandler();
  }
  
  const showLowHandler = () => {
    setShowAll(!showLow && showPanic && showWarning);
    setShowLow(!showLow);
    checkStatusesHandler();
  }

  const showPanicHandler = () => {
    setShowAll((showLow && !showPanic && showWarning));
    setShowPanic(!showPanic);
    checkStatusesHandler();
  }

  const showWarningHandler = () => {
    setShowAll((showLow && showPanic && !showWarning));
    setShowWarning(!showWarning);
    checkStatusesHandler();
  }

  

  // for checkbox
  const checkedTrackStyle = {
    opacity: 1,
    background: 'linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2,#FF4E4E) 96.67%)',
    transition: 'all 0.25s ease-in-out',
  }
   
  const checkedIndicatorStyle = {
    transform: 'translateX(1rem)',
  }
   
  const checkedIconStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
  }
   
  const indicatorStyle = {
    alignItems: 'center',
    background: '#ffffff',      // red
    borderRadius: '1.126rem',
    bottom: '0.25rem',
    display: 'flex',
    height: '1.126rem',
    justifyContent: 'center',
    left: '0.25rem',
    outline: 'solid 2px transparent',
    position: 'absolute',
    transition: '0.25s',
    width: '1.126rem',
  }

  const trackStyle = {
    background: '#CAD1D8',        //gray
    border: '1px solid #CAD1D8',
    borderRadius: '0.93rem',
    cursor: 'pointer',
    display: 'flex',
    height: '1.75rem',
    marginRight: '0.75rem',
    position: 'relative',
    width: '2.75rem',
  }
  useEffect(() => {
    checkStatusesHandler();
  }, [checkStatusesHandler]);

  return (
    <>
    <div className="field-light-nobg-first">
        <div className="field-with-checkbox_wrapper">
            <label className="field-with-checkbox"> 
                <span>Показывать всех</span>
                <Switch 
                checked={showAll}
                onChange={showAllHandler}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
                />
            </label>
        </div>
    </div>
    <div className="field-light-nobg">
        <div className="field-with-checkbox_wrapper">
            <label className="field-with-checkbox"> 
                <span>Критическое состояние</span>
                <Switch 
                checked={showPanic}
                onChange={showPanicHandler}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
                />
            </label>
        </div>
    </div>
    <div className="field-light-nobg">
        <div className="field-with-checkbox_wrapper">
            <label className="field-with-checkbox"> 
                <span>Нестабильное состояние</span>
                <Switch 
                checked={showWarning}
                onChange={showWarningHandler}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
                />
            </label>
        </div>
    </div>
    <div className="field-light-nobg">
        <div className="field-with-checkbox_wrapper">
            <label className="field-with-checkbox"> 
                <span>Низкий заряд батарейки</span>
                <Switch 
                checked={showLow}
                onChange={showLowHandler}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
                />
            </label>
        </div>
    </div>
    <div className="field-light-nobg">
        <div className="field-with-checkbox_wrapper">
            <label className="field-with-checkbox"> 
                <span>Показать номера палат</span>
                <Switch 
                checked={showNumbers}
                onChange={showNumbersHandler}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
                />
            </label>
        </div>
    </div>
    </>
  );
};

export default DinamicGraphPositionFilters;