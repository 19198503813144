import React, {useRef, useState, useEffect} from 'react';
import classNames from 'classnames/bind';
import DinamicGraphicItem from './DinamicGraphicItem.jsx';
import axios from 'axios';

const DinamicGraphList = ({ wards, norms, PositionSectors }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';

  // Вынести логику появления модалки в компоненту элемента
  // Иначе при обновление состояния activeItems произойдет ререндер всех элементов (что вызовет повторный запрос токена и переподключение вебсокетов)
  // const [activeItems, setActiveItem] = useState([]);

  const HandleCardToggle = (key) => {
    activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
  }

  const isChargingPlaces = [];
  const wardRefs = useRef([]);
  const [readyState, setReadyState ] = useState();

  const SendRequest = function(status, ident) {
    axios.post(`/device-simulator`, { status:status, device: ident }, { headers: { 
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    } },
    ).then(response => {
      if (response.statusText !== 'OK') {
        console.log(error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      }
    }).catch(error => {
      console.log(error);
    })
  }

  const startPanic = function(ev, w) {
    ev.preventDefault();
    SendRequest('panic', w.appliances[0].identifier);
  }

  const startWarning = function(ev, w) {
    ev.preventDefault();
    SendRequest('warning', w.appliances[0].identifier);
  }

  const startLowBattery = function(ev, w) {
    ev.preventDefault();
    SendRequest('low_battery', w.appliances[0].identifier);
  }

  const startNoData = function(ev, w) {
    ev.preventDefault();
    SendRequest('no_data', w.appliances[0].identifier);
  }

  const startInvalid = function(ev, w) {
    ev.preventDefault();
    SendRequest('invalid', w.appliances[0].identifier);
  }

  const startNonCorrect = function(ev, w) {
    ev.preventDefault();
    SendRequest('non_correct', w.appliances[0].identifier);
  }

  // Отключил появление модалки
  // При клике в любое место на экране происходит обновление состояние activeItems, что вызывает перерендер и переподключение всех элементов
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     event.stopPropagation();
  //     event.stopImmediatePropagation();

  //     if (wardRefs.current) {

  //       // Если клик по элементу
  //       if (wardRefs.current.includes(event.target) || wardRefs.current.includes(event.target.parentElement)) {
  //         const key = wardRefs.current.indexOf(event.target.parentElement);
      
  //         // Если элемент активный то удаляем его из списка активных
  //         // Если элемент не активный добавляем
  //         activeItems.includes(key) ? setActiveItem(activeItems.filter(p => p !== key)) : setActiveItem([...activeItems, key]);
  //       } else {
  //         setActiveItem([]);
  //       }
  //     }
  //   }
  //   // Bind the event listener
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [wardRefs]);
   
  return (
    <div>
      {
        //  wards && wards.length > 0 && <div>
        //      <button className="btn-small is--red" onClick={(ev) => { startPanic(ev, wards[1])} }>Start Panic {wards[1].number}</button>
        //      <button className="btn-small" onClick={(ev) => { wards[1] && startWarning(ev, wards[1]) } }>Start Warning {wards[1] && wards[1].number}</button>
        //      <button className="btn-small is--green" onClick={(ev) => { startLowBattery(ev, wards[3]) } }>Set Low Battery {wards[3].number}</button>
        //      <button className="btn-small is--red" onClick={(ev) => { startNoData(ev, wards[1])} }> No Data {wards[1].number} </button>
        //      <button className="btn-small" onClick={(ev) => { startInvalid(ev, wards[3]) } }> Invalid {wards[3].number} </button>
        //      <button className="btn-small is--green" onClick={(ev) => { startNonCorrect(ev, wards[1]) } }> Non Correct {wards[1].number} </button>
        //    </div>
      }
      
      <div className="ward-wrap-graphic">
        {
          wards && wards.map((w, i) => {
            // Для понимания работаю с одним элементом (удалить)
              return  w.appliances.map((app, j) => {

                let currentNorms =  norms;

                if (app.patient.indicators && app.patient.indicators.length > 0) {
                  // let n1 = JSON.parse(`{${app.patient.indicators.map((ind)=> { return `"${ind.option_name}": "${ind.value}"` } ).join()}}`)
                  // let n2 = JSON.parse(norms)
                  let n2 = JSON.parse(`{${app.patient.indicators.map((ind)=> { return `"${ind.option_name}": "${ind.value}"` } ).join()}}`);
                  let n1 = JSON.parse(norms);
                  for (const [key, value] of Object.entries(n2)) {
                    let res = app.patient.indicators.filter((o)=> o.option_name===key )
                    if (res && res[0] && res[0]['value']) {
                      n1[key] =  res[0]['value']
                    }
                  }
                  currentNorms = JSON.stringify(n1);
                }
                const result = !app.patient.discharge  ? <DinamicGraphicItem PositionSectors={PositionSectors} ind={app.id} currentStatus={w.status} info={w.info} number={w.number} device={app} norms={currentNorms}  /> : <></>
                return result
              })
          })
        }
      </div>
    </div>
  );
};

export default DinamicGraphList;