import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import BatteryLevel from '../battery/BatteryLevel.jsx';

const IndicatorsInfoCopy = ({changeIndicators, setChangeIndicators }) => {
  const [data, setData] = useState(null)
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';

  const resetNormsHandler = (ev) => {
    ev.preventDefault();
    let confirmation = confirm(`Уверены что хотите удалить индивидуальные Сигнатуры для всех пациентов ?`);
    if (confirmation) {
      axios.post(`/reset-norms`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
  }

  const GetData = () => {
    axios.post(`/indicators/get-info`, {}, { headers: { 
          'X-CSRF-Token': token
    } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setData(response.data.indicators[0]);
          } else {
            console.log('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error);
        })
        .finally(() => { 
          setChangeIndicators(false)
         });
  }
  if (changeIndicators) {
    GetData();
 }
  
  // useEffect(() => {
  //   console.log(` useEffect ${changeIndicators}`)
  // //   if (changeIndicators) {
  // //     GetData();
  // //  }
  // }, changeIndicators);

  return (
    <div className="indicators-info-long">
      <div className="indicators-info-column_first">
        <div className="h1-title">Параметры сигнатур:</div>
        <p>Система мониторинга предоставляет список палат и имеет ряд меняющихся индикаторов в зависимости от состояния пациента.</p>
        <p>Каждый из показателей регулярно проверяется на соответствие определенному коридору, и, в зависимости от этого, индикатор палаты меняет состояние и цвет.</p>
        <p> <span className="indicators-info-norm"></span> Коридор значений для зеленой зоны: у больного все в порядке.</p>
        <p> В данном разделе вы можете Сбросить все сигнатуры, заданные для Пациентов и оставить только общие, воспользовавшись ссылкой: <a href="/reset-norms" title="Сбросить параметры сигнатур у всех пациентов" class="reset-norms" onClick={(ev)=> { resetNormsHandler(ev) } }> Сбросить параметры сигнатур у всех </a></p>
      </div>
      <div className="indicators-info-column_second">
        <div className="h1-title">Значения:</div>
        <div className="indicators-info-point-wrap">
        <p className="indicators-info-point"> <span className="indicators-info-norm"></span> { `Коридор значений для зеленой зоны: у больного все в порядке. ${data && data.battery_min}%, устройство требует зарядки(замены)` } </p>
          <p className="indicators-info-point"> <span className="indicators-info-warn"></span> { `Коридор значений для желтой зоны (показатели вышли из нормы, но не критичны): пульс выше ${data && data.pulse_warn_max} или ниже ${data && data.pulse_warn_min}, сатурация ниже ${data && data.saturation_warn_min}.` } </p>
          <p className="indicators-info-point"> <span className="indicators-info-panic"></span> { `Коридор значений для красной зоны (срочно требуется помощь): пульс выше ${data && data.pulse_panic_max} или ниже ${data && data.pulse_panic_min}, сатурация ниже ${data && data.saturation_panic_min}.` } </p>
          <p className="indicators-info-point"> 
            <BatteryLevel val={parseInt(100)} norms={parseInt(data && data.battery_min)} isDark={true} showToolTipe={false} />
            { `В качестве индикатора батарейки, используется этот значок. Если он красного цвета это означает, что заряд аккумулятора ниже нормы ${data && data.battery_min}%, устройство требует зарядки(замены), если желтого - приближен к такому состоянию.` } 
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndicatorsInfoCopy;