import React, { useState, useRef }  from 'react';
import styles from './Patient.module.scss';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { MultiSelect } from "react-multi-select-component";
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";
import Switch from "react-custom-checkbox/switch";

const PatientForm = ({patient, index, openForm, refreshPatient, doctors, wards, appliances, permissions}) => {
  const curPatient = patient.highlight ? patient._source : patient;

  const [dateOfBirthday, setDateOfBirthday] = useState(new Date(curPatient.date_of_birth));
  const [errorMsg, setErrorMsg] = useState('');
  const [hintMsg, setHintMsg] = useState('');
  const [errorMsgIndicators, setErrorMsgIndicators] = useState('');
  const [hintMsgIndicators, setHintMsgIndicators] = useState('');
  const [indicatorsShow, setIndicatorsShow] = useState(false);
  const [dischargePatient, setDischargePatient] = useState(curPatient.discharge);
  const curForm = useRef();
  const curIndicatorsForm = useRef();
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  
  const [selected, setSelected] = useState(patient?.doctors?.map((d)=>{ return { label: d, value: d } }));
  const doctors_option = doctors && doctors.map((d) => {
    return { label: d, value: d }
  });
  const select_localization_doctors = {
    "allItemsAreSelected": "Выбраны все врачи.",
    "clearSearch": "Очистить поиск",
    "clearSelected": "Очистить выбранное",
    "noOptions": "Без вариантов",
    "search": "Поиск",
    "selectAll": "Выбрать все",
    "selectAllFiltered": "Выбрать всех (отфильтровано)",
    "selectSomeItems": " -- Выберете врачей -- ",
    "create": "Создавать",
  };

  const [selectedAppliances, setSelectedAppliances] = useState(patient.appliances.map((a)=>{ return { label: a.act, value: a.id } }));
  const appliance_option = appliances.map((a) => {
    return { label: a.act, value: a.id }
  });
  
  const select_localization_appliances = {
    "allItemsAreSelected": "Выбраны все устройства.",
    "clearSearch": "Очистить поиск",
    "clearSelected": "Очистить выбранное",
    "noOptions": "Без вариантов",
    "search": "Поиск",
    "selectAll": "все",
    "selectAllFiltered": "Выбрать всех (отфильтровано)",
    "selectSomeItems": " -- Выберете устройства -- ",
    "create": "Создавать",
  };
    
  const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      return false;
  }

  const SendRequest = (ev) => {
    ev.preventDefault();
    const body = new FormData(curForm.current);
    const HasError = CheckValidForm(curForm.current);
    if (!HasError) {
      setHintMsg('... загрузка  ...');
      axios.post(`/patients/${curPatient.id}/update`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          setHintMsg(response.data.message);
          refreshPatient(index, response.data.updated);
          openForm(index, false);
        } else {
          setHintMsg('');
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error?.response?.data?.message ? error.response.data.message : error);
        setHintMsg('');
        setErrorMsg(error?.response?.data?.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      })
        // .finally(() => {  });
    }
  }

  const UpdateIndicators = (ev) => {
    ev.preventDefault();
    const body = new FormData(curIndicatorsForm.current);
    const HasError = CheckValidForm(curIndicatorsForm.current);
    if (!HasError) {
      setHintMsgIndicators('... загрузка  ...');
      axios.post(`/patients/${curPatient.id}/update-indicators`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          setHintMsg(response.data.message);
          refreshPatient(index, response.data.updated);
          openForm(index, false);
        } else {
          setHintMsgIndicators('');
          setErrorMsgIndicators('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error?.response?.data?.message ? error.response.data.message : error);
        setHintMsgIndicators('');
        setErrorMsgIndicators(error?.response?.data?.message ? error.response.data.message : 'Произошли ошибки, пожалуйста попробуйте позже.');
      })
      .finally(() => { setIndicatorsShow(false) });
    }
  }

  const checkMinMax = (item, min, max) => {
    if (item.value < min) {
      item.value = min;
    }
    if (item.value > max) {
      item.value = max;
    }
  }
  const checkedTrackStyle = {
    opacity: 1,
    background: 'linear-gradient(246.02deg, var(--before-app-bg-1, #FF46D6) -40.31%, var(--before-app-bg-2,#FF4E4E) 96.67%)',
    transition: 'all 0.25s ease-in-out',
  }
 
  const checkedIndicatorStyle = {
    transform: 'translateX(1rem)',
  }
 
  const checkedIconStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
  }
 
  const indicatorStyle = {
    alignItems: 'center',
    background: '#ffffff',      // red
    borderRadius: '1.126rem',
    bottom: '0.25rem',
    display: 'flex',
    height: '1.126rem',
    justifyContent: 'center',
    left: '0.25rem',
    outline: 'solid 2px transparent',
    position: 'absolute',
    transition: '0.25s',
    width: '1.126rem',
  }
 
  const trackStyle = {
    background: '#CAD1D8',        //gray
    border: '1px solid #CAD1D8',
    borderRadius: '0.93rem',
    cursor: 'pointer',
    display: 'flex',
    height: '1.75rem',
    marginRight: '0.75rem',
    position: 'relative',
    width: '2.75rem',
  }

  return (
    <li key={index} className={styles.PatientFormWrapper}>
      <form ref={curForm} name="patient" action={`/patients/${curPatient.id}/update`} acceptCharset="UTF-8" data-remote="true" method="post"  className={styles.PatientForm}>
        <div className="field-light">
          <label>Фамилия</label>
          <input type="text" required name='patient[last_name]' defaultValue={curPatient.last_name} />
        </div>
        <div className="field-light">
          <label>Имя Отчество</label>
          <input type="text" required name='patient[first_name]' defaultValue={curPatient.first_name} />
        </div>
        <div className="field-light">
          <label>Пол</label>
          <select name='patient[sex]' defaultValue={curPatient.sex}>
            <option value="0">Мужской</option>
            <option value="1">Женский</option>
          </select>
        </div>
        <div className="field-light">
          <label>Возраст</label>
          <input type="text" required name='patient[age]' defaultValue={curPatient.age} />
        </div>
        <div className="field-light">
          <label>Дата рождения</label>
          <input type="hidden" name='patient[date_of_birth]'  value={dateOfBirthday} /> 
          <div className="custom_datepicker_wrapper">
            <DatePicker selected={dateOfBirthday} onChange={(date) => setDateOfBirthday(date)} />
          </div>
        </div>
        <div className="field-light">
          <label>Палата</label>
          <select name='patient[ward]' defaultValue={patient.ward[0]}>
           <option defaultValue value> -- Выберете палату -- </option>
           {wards.map((w, i) => {
              return <option key={i} value={w}>{w}</option>
            })} 
          </select>
        </div>
        {
          permissions.discharge ?  <div className="field-light">
            <label className="field-with-checkbox-discharge"> 
              <input type="hidden" name='patient[discharge]' value={dischargePatient}  />
              {/* <span style={{marginBottom: '16px'}}>{ curPatient.discharge ? "Отменить выписку": "Выписать пациента" }</span> */}
              <span style={{marginBottom: '16px'}}>{ "Выписать пациента" }</span>
              <Switch
                checked={dischargePatient}
                onChange={() => setDischargePatient(!dischargePatient)}
                indicatorStyle={indicatorStyle}
                trackStyle={trackStyle}
                checkedIconStyle={checkedIconStyle}
                checkedIndicatorStyle={checkedIndicatorStyle}
                checkedTrackStyle={checkedTrackStyle}
              />
              {/* <input type="checkbox" name='patient[discharge]' value={!curPatient.discharge} onChange={() => setDischargePatient(!dischargePatient) } checked={dischargePatient} /> <span> { curPatient.discharge ? "Отменить выписку": "Выписать пациента" } </span> */}
            </label>
          </div> : <></>
        }
       
       {
        doctors_option && <div className="field-light">
          <label>Доктора</label>
          <input type="hidden" name='patient[doctors]'  value={selected.map(v => { return v.value })} /> 
          <MultiSelect
            options={doctors_option}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
            overrideStrings={select_localization_doctors}
          />
        </div>
       }

        <div className="field-light">
          <label>Устройства</label>
          <input type="hidden" name='patient[appliances]'  value={selectedAppliances.map(v => { return v.value })} /> 
          <MultiSelect
            options={appliance_option}
            value={selectedAppliances}
            onChange={setSelectedAppliances}
            labelledBy="Select"
            overrideStrings={select_localization_appliances}
          />
        </div>

        <div>
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <button type="submit" className="btn-gradient"  onClick={(ev)=>{ SendRequest(ev); UpdateIndicators(ev)}}><span>Сохранить</span></button>
          <button className="btn-border" type="reset" onClick={(ev)=> { openForm(index, false); }}><span>Не сохранять</span></button>

        </div>
         {/* <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ UpdateIndicators(ev)}} />
          <button type="reset" className="btn-border" onClick={(ev)=>{openForm(index, false);}}><span>Не сохранять</span></button> */}

      </form>
      
      {
        indicatorsShow ? <form ref={curIndicatorsForm} name="patient" action={`/patients/${curPatient.id}/update-indicators`} acceptCharset="UTF-8" data-remote="true" method="post"  className={classNames(styles.PatientForm, "hided")}>
          <div className="zone-inputs-wrapper">
            <h2 className="h3-title">Красная зона.</h2>
            <div className="zone-inputs">
              <label className="max small-text no-padding-left" title="(все что меньше или больше указанных значений, требует срочного внимания)">Пульс.</label>
              <div>
                <input type="hidden" name='patient_indicators[pulse_panic_min_label]' value="Коридор значений для красной зоны. Пульс Нижнее значение (все что меньше требует срочного внимания)" />
                <input type="number" min={12} max={200} required name='patient_indicators[pulse_panic_min]' onChange={(ev)=> { checkMinMax(ev.target, 12, 250) }} placeholder='не указано' defaultValue={patient.indicators.pulse_panic_min} />
                —
                <input type="hidden" name='patient_indicators[pulse_panic_max_label]' value="Коридор значений для красной зоны. Пульс Верхнее значение (все что больше требует срочного внимания)" />
                <input type="number" min={50} max={300} required name='patient_indicators[pulse_panic_max]' onChange={(ev)=> { checkMinMax(ev.target, 50, 300) }} placeholder='не указано' defaultValue={patient.indicators.pulse_panic_max} />
              </div>
            </div>
            <div className="zone-inputs">
              <label className="max small-text no-padding-left" title="(если меньше, требует срочного внимания)">Сатурация. Низ </label>
              <input type="hidden" name='patient_indicators[saturation_panic_min_label]' value="Коридор значений для красной зоны. Сатурация Нижнее значение (все что меньше требует срочного внимания)" />
              <input type="number" min={50} max={100}  required name='patient_indicators[saturation_panic_min]' onChange={(ev)=> { checkMinMax(ev.target, 50, 100) }} placeholder='не указано' defaultValue={patient.indicators.saturation_panic_min} />
            </div>
          </div>
          <div className="zone-inputs-wrapper">
            <h2 className="h3-title">Желтая зона.</h2>
            <div className="zone-inputs">
              <label className="max small-text no-padding-left" title="(все что меньше или больше указанных значений, требует срочного внимания)">Пульс.</label>
              <div>
                <input type="hidden" name='patient_indicators[pulse_warn_min_label]' value="Коридор значений для желтой зоны. Пульс Нижнее значение (все что меньше требует внимания)" />
                <input type="number" min={12} max={200} required name='patient_indicators[pulse_warn_min]' onChange={(ev)=> { checkMinMax(ev.target, 12, 250) }} placeholder='не указано' defaultValue={patient.indicators.pulse_warn_min} />
                —
                <input type="hidden" name='patient_indicators[pulse_warn_max_label]' value="Коридор значений для желтой зоны. Пульс Верхнее значение (все что больше требует внимания)" />
                <input type="number" min={50} max={300} required name='patient_indicators[pulse_warn_max]' onChange={(ev)=> { checkMinMax(ev.target, 50, 300) }} placeholder='не указано' defaultValue={patient.indicators.pulse_warn_max} />
              </div>
            </div>
            <div className="zone-inputs">
              <label className="max small-text no-padding-left" title="(если меньше, требует внимания)">Сатурация. Низ </label>
              <input type="hidden" name='patient_indicators[saturation_warn_min_label]' value="Коридор значений для желтой зоны Сатурация Нижнее значение (все что меньше требует внимания)" />
              <input type="number" min={50} max={100} required name='patient_indicators[saturation_warn_min]' onChange={(ev)=> { checkMinMax(ev.target, 50, 100) }} placeholder='не указано' defaultValue={patient.indicators.saturation_warn_min} />
            </div>
          </div>
          <div className="zone-inputs-wrapper">
            <h2 className="h3-title">Критичный уровень заряда батарейки.</h2>
            <div className="zone-inputs">
              <input type="hidden" name='patient_indicators[battery_min_label]' value="Критичный уровень заряда батарейки (все что меньше требует заряда аккумулятора)" />
              <input type="number" min={20} max={100} required name='patient_indicators[battery_min]' onChange={(ev)=> { checkMinMax(ev.target, 20, 100) }} placeholder='не указано' defaultValue={patient.indicators.battery_min} />
            </div>
          </div>
          <p className='errors'>{errorMsgIndicators}</p>
          <p className='hint'>{hintMsgIndicators}</p>
          {/* <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ UpdateIndicators(ev)}} />
          <button type="reset" className="btn-border" onClick={(ev)=>{openForm(index, false);}}><span>Не сохранять</span></button> */}
        </form>
        : <p onClick={setIndicatorsShow(true)} className={styles.PersonalIndicators}>Персональные параметры сигнатур</p>
      }
      
    </li>
  );
};

export default PatientForm;