import React, {useRef, useState, useEffect} from 'react';
import Graphic from './Graphic.jsx';
import classNames from 'classnames/bind';
import styles from './Ward.module.scss';

const Ward = ({ind, number, status, info,  showModal}) => {
  
  const curItem = useRef();
  const curItemCard = useRef();
  const [leftSide, setLeftSide] = useState(false);
    // info && info.data && console.log("!!!!");
    // info && info.data && console.log(info.data);
   
  useEffect(() => {
    if ( curItem && curItem.current && curItemCard && curItemCard.current )  { 
      const graphicLeftPos = curItem.current.closest(".ward-wrap").getBoundingClientRect().left + 45 - curItem.current.getBoundingClientRect().left;
      const graphicWidth = curItem.current.closest(".ward-wrap").offsetWidth - 85;
      curItemCard.current.setAttribute('style', `left: ${graphicLeftPos}px; width: ${graphicWidth}px; `);
      curItem.current.parentNode.setAttribute('style', `margin-bottom: ${showModal ? 404 : 0 }px`);
    };
    if (status==='low_battery' && showModal) {
      const leftSide = ((curItem.current.getBoundingClientRect().left - curItem.current.closest(".ward-wrap").getBoundingClientRect().left - curItem.current.closest(".ward-wrap").offsetWidth/2) > 0);
      setLeftSide(leftSide);
    }
  },[curItem, curItemCard, showModal, status]);


    return (
        <div key={ind}  ref={curItem} data-number={number} styles={{marginBottom: `${showModal ? 404 : 0}px`}} className={classNames(styles.Ward, (status==='low_battery') ? styles.LowBattery : '', (status==='warning') ? styles.Warning : '', (status==='panic') ? (showModal ? styles.PanicOpen : styles.Panic ) : '')}> 
          { (info && ( (status==='warning') || (status==='panic') )) ? <div className={showModal ? styles.PatientCardOpen : styles.PatientCard} ref={curItemCard}  >
            <div className={styles.PatientCardTitle}>{info && info.title}</div>
            <div className={styles.PatientCardText} dangerouslySetInnerHTML={{ __html: info.text }}></div>
            <div className={styles.PatientGraphicWrap}>  <div className={styles.PatientGraphic}> <Graphic info={info && (Object.keys(info.data).length > 0) ? info.data : '' } /> </div> </div>
            </div> : (info && (status==='low_battery') ) ? <div className={showModal ? (leftSide ? styles.PatientCardOpenSmallRight : styles.PatientCardOpenSmall) : styles.PatientCardSmall }>
              <div className={styles.PatientCardTitle}>{info && info.title}</div>
              <div className={styles.PatientCardText} dangerouslySetInnerHTML={{ __html: info.text }}></div>
            </div> : <></>
          }
        </div>
      
    );
};

export default Ward;