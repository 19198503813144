import React, {useState, useEffect} from 'react';
import styles from './Appliance.module.scss';
import Appliance from './Appliance.jsx';
import ApplianceForm from './ApplianceForm.jsx';
import axios from 'axios';
import classNames from "classnames";
import AddAppliance from './AddAppliance';

const ApplianceList = ({ userId, appliances, permission, patients, wards, norms, freeIdentifiers }) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [appliancesList, setAppliancesList] = useState(appliances);
  const [editableComponents, setEditableComponents] = useState([]);
  const permissions = JSON.parse(permission);
  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }
  const refreshAppliance = (j, params) => {
    setAppliancesList(appliancesList => {
      return appliancesList.map((appliance, i) => {
        if (j === i) {
          appliance = params;
        }
        return appliance
      });
    });
  }
  
  const AddNewAppliance = (params) => {
    setAppliancesList(appliancesList => {
      return [ ...appliancesList, params ];
    });
  }
  
  const deleteItem = (i) => {
    const appliance = appliancesList[i];
    let confirmation = confirm(`Уверены что хотите удалить ${appliance.act}?`);
    if (confirmation) {
      axios.post(`/private/${userId}/devices/${appliance.id}/delete`, {}, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
          setAppliancesList(appliancesList => {
            return appliancesList.filter(item => item.id !== appliance.id);
          });
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
    
  }
  
  const result = appliancesList.map((appliance, i) => {
    if (editableComponents.includes(i) ) { 
      return <ApplianceForm userId={userId} index={i} appliance={appliance} openForm={openForm} refreshAppliance={refreshAppliance} patients={patients} wards={wards} permissions={permissions}/> }
    else { 
      return <Appliance userId={userId} index={i} appliance={appliance} openForm={openForm} deleteItem={deleteItem} permissions={permissions} batteryMin={norms && norms.battery_min} isLast={(i === (appliancesList.length - 1))} /> 
    }  
  });
  
    return (
      <ul className={styles.AppliancesList}>
        <li key={-1} className={classNames(styles.AppliancesListItemNoBrd, styles.AppliancesListHeader)}>
          <span>ID устройства</span>
          <span>палата</span>
          <span>пациент</span>
          <span className="center-text">уровень заряда</span>
          <span></span>
        </li>
        { result }
        { permissions.create &&  freeIdentifiers && freeIdentifiers.length ? <AddAppliance userId={userId} index='new' patients={patients} wards={wards}  permissions={permissions} AddNewAppliance={AddNewAppliance} freeIdentifiers={freeIdentifiers} /> : <></> }
      </ul>
    );
};

export default ApplianceList;
