import React, { useState, useRef }  from 'react';
import styles from './Appliance.module.scss';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import classNames from "classnames";

const AddAppliance = ({ userId, AddNewAppliance, permission, patients, wards, norms, freeIdentifiers  }) => {
  const [openFormNewAppliance, setOpenFormNewAppliance] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [hintMsg, setHintMsg] = useState('');
    const curForm = useRef();

    const clearMessage = (ev) => {
      setErrorMsg('');
      ev.target.classList.remove('with-error');
    }

    const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
    const CheckValidForm = () => {
      const emptyInputs = Array.from(curForm.current.querySelectorAll("[required]")).filter((el) => { return el.value === ''; })
      if (emptyInputs.length > 0) {
        emptyInputs.forEach(element => {
          element.classList.add('with-error');
        });
        setErrorMsg('Заполните обязательные поля');
        return true;
      }
      return false;
    }

    const SendRequest = (ev) => {
      ev.preventDefault();
      const body = new FormData(curForm.current);
      const HasError = CheckValidForm(curForm.current);
      if (!HasError) {
        setHintMsg('... загрузка  ...')
        axios.post(`/private/${userId}/devices/new`, body, { headers: { 
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': token
        } },
        ).then(response => {
          if (response.statusText === 'OK') {
            setHintMsg(response.data.message);
            AddNewAppliance(response.data.updated);
          } else {
            console.log(response?.responseText && response.responseText);  
            setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
          }
        }).catch(error => {
          console.log(error?.request?.responseText && error.request.responseText);
          setErrorMsg('Произошли ошибки, пожалуйста попробуйте позже.');
        })
        .finally(() => { 
          setTimeout(() => {
            setOpenFormNewAppliance(false); 
          }, 5000);
        });
      } 
      
    }

  const ClearAndOpenForm = () => {
    if (curForm.current) {
      curForm.current.reset();
      // const select_ward = curForm.current.querySelector('select[name="patient[ward]"]');
      // select_ward.value = 'true';
    }
    // setSelected([role_option[1]]);
    setHintMsg('');
    setErrorMsg('');
    setOpenFormNewAppliance(true);
  }

  return (
    <li key='new' className={classNames(styles.NewApplianceWrapper, styles.AppliancesListItemLast) }>
      { openFormNewAppliance ? <form ref={curForm} name="appliance" action={`/private/${userId}/devices/new`} acceptCharset="UTF-8" data-remote="true" method="post" className={styles.ApplianceForm}>
          <div className="field-light">
            <label htmlFor='appliance[act]'>Номер устройства</label>
            <select name='appliance[act]' >
              <option selected value> -- Выберете устройство -- </option>
              {freeIdentifiers.map((ident, i) => {
                  return <option key={i} value={ident}>{ident}</option>
                })} 
            </select>
          </div>
          <div className="field-light">
            <label htmlFor='appliance[identifier]'>ФИO</label>
            <select name='appliance[patient_id]' >
              <option selected value> -- Выберете пациента -- </option>
              {patients.map((p, i) => {
                  return <option key={i} value={p.id}>{`${p.first_name} ${p.last_name}`}</option>
                })} 
            </select>
          </div>
          <div className="field-light">
            <label>Палата</label>
            <select name='appliance[ward_id]' >
              <option selected value> -- Выберете палату -- </option>
              {wards.map((w, i) => {
                  return <option key={i} value={w.id}>{w.number}</option>
                })} 
            </select>
          </div>
          <p className='errors'>{errorMsg}</p>
          <p className='hint'>{hintMsg}</p>
          <input type="submit" className="btn-gradient" value="Сохранить изменения" onClick={(ev)=>{ SendRequest(ev)}} />
          <button type="reset" className="btn-border" onClick={(ev)=> { setOpenFormNewAppliance(false); }}><span>Не сохранять</span></button>
        </form> : <div  className={styles.AppliancesListItemNoBrd} >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          { freeIdentifiers ? <button className="btn-border btn-create" onClick={(ev)=>{ ClearAndOpenForm(); setOpenFormNewAppliance(true); }} > <span className="icon-plus">+</span><span>добавить устройство</span> </button> : <></> }
          
        </div>
      }
    </li>
  );
};

export default AddAppliance;