import React, {useState, useEffect} from 'react';
import styles from './Doctor.module.scss';
import Doctor from './Doctor.jsx';
import DoctorForm from './DoctorForm.jsx';
import axios from 'axios';
import classNames from "classnames";
import AddDoctor from './AddDoctor';


const DoctorList = ({doctors, permission}) => {
  const token =  document.querySelector("meta[name='csrf-token']") ? document.querySelector("meta[name='csrf-token']").getAttribute('content') : '';
  const [doctorsList, setDoctorsList] = useState(doctors);
  const [editableComponents, setEditableComponents] = useState([]);
  const permissions = JSON.parse(permission);
  const openForm = (i, edit) => {
    if (edit) {
      setEditableComponents(editableComponents => {
        return [ ...editableComponents, i ];
      });
    } else {
      setEditableComponents(editableComponents => {
        return editableComponents.filter(item => item !== i);
      });
    }
  }
  const refreshDoctor = (j, params) => {
    setDoctorsList(doctorsList => {
      return doctorsList.map((doctor, i) => {
        if (j === i) {
          doctor = params;
        }
        return doctor
      });
    });
  }
  
  const AddNewDoctor = (params) => {
    setDoctorsList(doctorsList => {
      return [ ...doctorsList, params ];
    });
  }
  
  const deleteItem = (i) => {
    const doctor = doctorsList[i];
    let confirmation = confirm(`Уверены что хотите удалить ${doctor.name}?`);
    if (confirmation) {
      axios.post(`/doctors/${doctor.id}/delete`, { model: doctor.model }, { headers: { 
        'X-CSRF-Token': token
      } },
      ).then(response => {
        if (response.statusText === 'OK') {
          console.log(response.data.message);
          setDoctorsList(doctorsList => {
            return doctorsList.filter(item => item.id !== doctor.id);
          });
        } else {
          console.log('Произошли ошибки, пожалуйста попробуйте позже.');
        }
      }).catch(error => {
        console.log(error);
        console.log('Произошли ошибки, пожалуйста попробуйте позже.');
      });
    }
    
  }
  
  const result = doctorsList.map((doctor, i) => {
    if (editableComponents.includes(i) ) { 
      return <DoctorForm index={i} doctor={doctor} openForm={openForm} refreshDoctor={refreshDoctor}  permissions={permissions}/> }
    else { 
      return <Doctor index={i} doctor={doctor} openForm={openForm} deleteItem={deleteItem} permissions={permissions} isLast={(i === (doctorsList.length - 1))} /> 
    }  
  });
  
    return (
      <ul className={styles.DoctorsList}>
        <li key={-1} className={classNames(styles.DoctorsListItemNoBrd, styles.DoctorsListHeader)}>
          <span>должность</span>
          <span>ФИО</span>
          <span>телефон</span>
          <span></span>
          <span></span>
        </li>
        { result }
        { permissions.create ? <AddDoctor index='new'  AddNewDoctor={AddNewDoctor}  /> : <></> }
      </ul>
    );
};

export default DoctorList;
